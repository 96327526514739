import MapWriter from "./MapWriter"
//import { ResLayout, ResArea } from "../api/data/FullLayout"
import { ResLayout, ResArea } from "../api/data/analysis/FullLayout"
import { AREA_BORDER_COLOR } from "./ColorUtil"
import AreaEditPicWriter from "./AreaEditPicWriter"

// 虫眼鏡の横幅（セル数）
const MAG_WIDTH_BY_CELL = 36
// 虫眼鏡枠の縦の長さ（セル数）
const MAG_HEIGHT_BY_CELL = 26
// 虫眼鏡枠線太さ
const MAG_BOX_LINE_SIZE = 4
// 虫眼鏡本体線太さ
const MAG_ARC_LIEN_SIZE = 10
// 移動量
const MOVE_VOLUME = 2
const SKIP_VOLUME = 10
// 虫眼鏡のサイズ変更量
const MAG_ZOOM_STEP = 1.2
// 倍率の最大値
const MAG_ZOOM_MAX = 3.5
// 倍率の最小値
const MAG_ZOOM_MIN = 0.3

class AreaMagGlassPicWriter extends MapWriter {

    // 小さい窓用
    public magStartX: number        // 虫眼鏡枠の初期表示時点のImage左上X座標
    public magStartY: number        // 　〃　　　　　　　　　　　　     Y座標
    public magWidth: number         // 虫眼鏡の横幅（pixel）
    public magHeight: number        // 　〃　　縦長さ（pixel）
    public slaveWriter: AreaEditPicWriter | null   // 従属Writer
    public memoX: number = 0        // ドラッグ開始時のmagStartXの記録
    public memoY: number = 0        // 　〃                   Y
    public magZoomVol: number = 1 // 虫眼鏡の倍率
    
    /**
     * コンストラクタ
     * @param layout 
     * @param areaList sy
     * @param canvasWidth 
     * @param canvasHeight 
     */
    constructor(layout: ResLayout, areaList: ResArea[], canvasWidth: number, canvasHeight: number) {
        super(layout, areaList, canvasWidth, canvasHeight)
        // 虫眼鏡の初期表示位置は原点とする
        this.magStartX = this.origin_x
        this.magStartY = this.origin_y
        this.magWidth = MAG_WIDTH_BY_CELL * this.area_unitcell_pixel * this.magZoomVol
        this.magHeight = MAG_HEIGHT_BY_CELL * this.area_unitcell_pixel * this.magZoomVol
        this.slaveWriter = null
        //console.log("init magStartX,magStartY:",this.magStartX,this.magStartY)
    }

    // 虫眼鏡の動きに連動させるWriterの登録
    public setSlaveWriter(slaveWriter: AreaEditPicWriter) {
        this.slaveWriter = slaveWriter
        this.slaveWriter.setMagScale(this.magWidth, this.magHeight, this.origin_x, this.origin_y)
    }

    /**
     * 虫眼鏡を描きます
     * @param glassCanvas 
     * @param moveCanvas 
     */
    async drawMagnifyingGlass(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement) {
        // 一番下層のキャンバスに虫眼鏡を描画する
        const glassCtx = glassCanvas.getContext("2d")
        const moveCtx = moveCanvas.getContext("2d")
        if (glassCtx && moveCtx) {
            // スケール設定
            glassCtx.save()
            glassCtx.scale(this.imageScale, this.imageScale)
            // クリア
            this.clearCanvas(glassCtx)
            // 枠を描画
            const px = this.magStartX + this.vtLeft
            const py = this.magStartY + this.vtTop
            console.log("drawMagnigyingGlass px,py:", px, py)
            glassCtx.strokeStyle = AREA_BORDER_COLOR
            glassCtx.lineWidth = MAG_BOX_LINE_SIZE
            glassCtx.beginPath()
            glassCtx.strokeRect(px, py, this.magWidth, this.magHeight)
            glassCtx.stroke()
            // 円を描画
            const x = px + this.magWidth / 2
            const y = py + this.magHeight / 2
            const r = this.magHeight * 0.25
            glassCtx.beginPath()
            glassCtx.strokeStyle = AREA_BORDER_COLOR
            glassCtx.lineWidth = MAG_ARC_LIEN_SIZE
            glassCtx.arc(x, y, r, 0, 2 * Math.PI)
            glassCtx.stroke()
            // 棒を描画
            const st = r * Math.sin(Math.PI / 4)
            const len = this.magHeight /2 - 10 
            glassCtx.beginPath()
            glassCtx.strokeStyle = AREA_BORDER_COLOR
            glassCtx.lineWidth = MAG_ARC_LIEN_SIZE
            glassCtx.moveTo(x + st, y + st)
            glassCtx.lineTo(x + len, y + len)
            glassCtx.stroke()
            // スケール戻す
            glassCtx.restore()
            // 一番上層のキャンバスに虫眼鏡をコピーする
            this.clearCanvas(moveCtx)
            moveCtx.drawImage(glassCanvas, 0, 0, this.canvasWidth, this.canvasHeight, 0, 0, this.canvasWidth, this.canvasHeight)
        }
    }

    /**
     * 虫眼鏡をつまんだままマウスの移動に合わせてマップを再描画。
     * 一番後ろの虫眼鏡を一番上にコピーする。
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     */
    public mouseMoveOnGlassMagnifying(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // ドラッグ処理（座標計算）
                this.mouseDragX = (event.clientX - rect.left)
                this.mouseDragY = (event.clientY - rect.top)
                //console.log("ドラッグ位置x,y:",this.mouseDragX, this.mouseDragY)
                const moveX = (this.mouseDragX - this.mouseMoveX)
                const moveY = (this.mouseDragY - this.mouseMoveY)
                //console.log("移動量x,y;scaledX,Y:", moveX, moveY, moveX / this.imageScale, moveY / this.imageScale)
                this.magStartX = this.memoX + moveX / this.imageScale
                this.magStartY = this.memoY + moveY / this.imageScale
                //console.log("magStartX,magStartY:",this.magStartX,this.magStartY)
                // 子供に移動量を伝達
                if (this.slaveWriter) this.slaveWriter.moveSlave(this.magStartX, this.magStartY)
                // Imageの移動(Canvas間Copy)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.canvasWidth - Math.abs(moveX)
                    const h = this.canvasHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)
                }
                this.isMousePress = true
            } else {
                // 移動座標の記録
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
                //console.log("移動開始位置x,y:", this.mouseMoveX, this.mouseMoveY)
                this.memoX = this.magStartX
                this.memoY = this.magStartY
                //console.log("magStart記録:",this.memoX,this.memoY)
            }
        }
    }

    /**
     * 指定されたエリアを画面中央に描画できるように虫眼鏡の位置を配置します。
     * @param areaId 
     */
    async setPosition(areaId: number) {
        if (areaId) {
            // エリアの中心位置を求めます。
            // もしも、エリアが画面に収まりきらないぐらい大きい時は、左上の番号が表示される位置にします。
            const area = this.area_list.find(el => el.area_id === areaId)
            if (area) {
                const lines = this.convertCells2DrawLines(area.cell_ids)
                //console.log("lines,area_unitcell_pixel:",lines, this.area_unitcell_pixel)
                if (lines.length > 0) {
                    let maxX = undefined
                    let maxY = undefined
                    let minX = undefined
                    let minY = undefined
                    for await (let line of lines) {
                        const [stX, edX, stY, edY] = line.map(e => e * this.area_unitcell_pixel)
                        if (minX === undefined || minX > stX) minX = stX
                        if (minY === undefined || minY > stY) minY = stY
                        if (maxX === undefined || maxX < edX) maxX = edX
                        if (maxY === undefined || maxY < edY) maxY = edY
                    }
                    if (minX && minY && maxX && maxY) {
                        let dx = (this.magWidth - (maxX - minX)) / 2
                        if (dx < 0) dx = this.magWidth * 0.1
                        const x = this.origin_x + minX - dx
                        let dy = (this.magHeight - (maxY - minY)) / 2
                        if (dy < 0) dy = this.magHeight * 0.1
                        const y = this.origin_y + minY - dy
                        this.magStartX = x
                        this.magStartY = y
                        // 子供に移動を伝達
                        if (this.slaveWriter) this.slaveWriter.moveSlave(this.magStartX, this.magStartY)
                    }
                }
            }
        }
    }

    private resizing(zoom: number, glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        this.magZoomVol = zoom
        this.magWidth = MAG_WIDTH_BY_CELL * this.area_unitcell_pixel * this.magZoomVol
        this.magHeight = MAG_HEIGHT_BY_CELL * this.area_unitcell_pixel * this.magZoomVol
        this.drawMagnifyingGlass(glassCanvas, moveCanvas)
        if (this.slaveWriter) {
            this.slaveWriter.setMagScale(this.magWidth, this.magHeight, this.magStartX, this.magStartY)
            this.slaveWriter.drawImage4Editor(lgImgCtx, lgDrwCtx)
        }
    }

    public magSmaller(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        const zoom = this.magZoomVol / MAG_ZOOM_STEP
        if (MAG_ZOOM_MIN < zoom) {
            this.resizing(zoom, glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public magReset(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magZoomVol !== 1) {
            this.resizing(1, glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public magEnlarge(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        const zoom = this.magZoomVol * MAG_ZOOM_STEP
        if (zoom < MAG_ZOOM_MAX) {
            this.resizing(zoom, glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }

    private moving(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        this.drawMagnifyingGlass(glassCanvas, moveCanvas)
        if (this.slaveWriter) {
            this.slaveWriter.moveSlave(this.magStartX, this.magStartY)
            this.slaveWriter.drawImage4Editor(lgImgCtx, lgDrwCtx)
        }
    }

    public moveUp(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartY > 0) {
            this.magStartY = this.magStartY - this.area_unitcell_pixel * MOVE_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public moveRight(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartX + this.magWidth < this.pixel_width) {
            this.magStartX = this.magStartX + this.area_unitcell_pixel * MOVE_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public moveDown(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartY + this.magHeight < this.pixel_height) {
            this.magStartY = this.magStartY + this.area_unitcell_pixel * MOVE_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public moveLeft(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartX > 0) {
            this.magStartX = this.magStartX - this.area_unitcell_pixel * MOVE_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public skipUp(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartY > 0) {
            this.magStartY = this.magStartY - this.area_unitcell_pixel * SKIP_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public skipRight(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartX + this.magWidth < this.pixel_width) {
            this.magStartX = this.magStartX + this.area_unitcell_pixel * SKIP_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public skipDown(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartY + this.magHeight < this.pixel_height) {
            this.magStartY = this.magStartY + this.area_unitcell_pixel * SKIP_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
    public skipLeft(glassCanvas: HTMLCanvasElement, moveCanvas: HTMLCanvasElement, lgImgCtx: CanvasRenderingContext2D, lgDrwCtx: CanvasRenderingContext2D) {
        if (this.magStartX > 0) {
            this.magStartX = this.magStartX - this.area_unitcell_pixel * SKIP_VOLUME
            this.moving(glassCanvas, moveCanvas, lgImgCtx, lgDrwCtx)
        }
    }
    
}
export default AreaMagGlassPicWriter