import React from "react"

import style from "./ZoomButtonSet.module.css"

interface Props {
    onZoomOut: () => void
    onZoomReset: () => void
    onZoomIn: () => void
}

export const ZoomButtonSet: React.FC<Props> = (props) => {
    return (
        <div className={style.main}>
            <div className={style.zoomOut} onClick={props.onZoomOut}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOVBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADLcPMfAAAAEnRSTlMA9me4kaKrBULdCFzKOBkYHHz/jp8xAAAAcUlEQVQY021OWRbEIAiLFgTa2oX7H3bQZ9vZ8kEkYAjQsBAteEBupZjT1a9pa7SldcxTFJEoidDId8wSmLF797PHzHKsUAF0Yp4UKPxP+PnyZnr411k7P4Id5rXnuKOf1fVyy8w5SIciQ5ZbQbvSH1pfBNYD/buC7mkAAAAASUVORK5CYII=" alt="zoom_out" />
            </div>
            <div className={style.zoomReset} onClick={props.onZoomReset}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAM1BMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADbQS4qAAAAEHRSTlMASeqxQZ7xBFkCUqt6yguzNRj5bgAAAGlJREFUGNNtj0kWgCAMQ8NcBDX3P61MxYVmA/y8pgFAwlTWk4CEINiip3V3pN+Aw5VG8gLkNcg7NZ0oe0FXggv/QKwiHeHR7HYzXHm+k/6uGtGKlRJZaXb9Wd1o2axrPPVHKfXUDDnx0QPD+QMz6ukCzAAAAABJRU5ErkJggg==" alt="zoom_reset" />
            </div>
            <div className={style.zoomIn} onClick={props.onZoomIn}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAM1BMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADbQS4qAAAAEHRSTlMA9hhnn5Gr3kMDClw4ynwcLWIQRAAAAHRJREFUGNNtjtEWwyAIQ6OCQGtb/v9ri1a3nW15SPSiANC1M+94i11qFed1P1LukdMx62lVEsMiPGODalj20U+6E3WXEk+4AkoipEClf+Dny0fTy7/GyjnGrsUu8faAtfrZXCdBISoRGqRrUrMXibPZqGi7AcEqA6zY98hBAAAAAElFTkSuQmCC" alt="zoom_in" />
            </div>
        </div>
    )
}