import React from "react"

import { CustomCheckbox } from "../custom_checkbox/CustomCheckbox"
import { Array7 } from ".."


import style from './weekdaysSelector.module.css'

interface WeekdaysObject {
    name: string
    short: string
    value: number
    word: string
}

const WeekdaysKey = {
    Sun: "su",
    Mon: "m",
    Tue: "tu",
    Wed: "w",
    Thu: "th",
    Fri: "f",
    Sat: "sa"
} as const
type WeekdaysKeys = typeof WeekdaysKey[keyof typeof WeekdaysKey]

const WeekdaysDic: WeekdaysObject[] = [
    { name: "sunday", short: "su", value: 0, word: "sun" },
    { name: "monday", short: "m", value: 1, word: "mon" },
    { name: "tuesday", short: "tu", value: 2, word: "tue" },
    { name: "wednesday", short: "w", value: 3, word: "wed" },
    { name: "thursday", short: "th", value: 4, word: "thur" },
    { name: "friday", short: "f", value: 5, word: "fri" },
    { name: "saturday", short: "sa", value: 6, word: "sat" }
]

//export type Array7<T> = [T, T, T, T, T, T, T]

interface Props {
    weekdays: Array7<boolean>
    onChange: (weekdays: Array7<boolean>) => void
}

/**
 * JavaScriptのgetDayはSun=0としているので、内部的にはArrayの内容もSundayを0としている。
 * 
 * @param props 
 * @returns 
 */
export const WeekdaysSelector: React.FC<Props> = (props) => {

    //console.log("WeekdaysSelector props:", props)

    const handleCheck = (idx: number) => {
        const newary: Array7<boolean> = [ ...props.weekdays ]
        newary[idx] = !newary[idx]
        props.onChange(newary)
    }

    // 表示順は月曜スタートとする
    const order: Array7<number> = [1,2,3,4,5,6,0]
    return (
        <div className={style.border}>
            {order.map((pt, idx) => {
                const chk = props.weekdays[pt]
                return (
                    <div className={style.series} key={idx}>
                        <CustomCheckbox
                            label={WeekdaysDic[pt].word}
                            value={pt.toString()}
                            check={chk}
                            onChange={e => handleCheck(pt)}                            
                        />
                        &nbsp;
                    </div>
                )
            })}
        </div>
    )
}
