import React, { useState, useRef, useMemo, useEffect } from "react"
import {
    Chart as ChartJS,
    ChartOptions,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Chart } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import { AnalyzeParametersType } from "../../types/Analyze"
import { ContentsDistributor, MainCompoProps } from "./ContentsDistributor"
import { SwitchItems } from "./TimelineGraph"
import { SeriesSwitcher, BtnProp, SwitcherFontType } from "../../component/series_switcher/SeriesSwitcher"
import { AnalyzeViewType } from "../../types/Analyze"
import { LINE_COLOR_BLUE, LINE_COLOR_GREEN } from "../../lib/ColorUtil"
import { TimeUnit, TimeUnitType } from "../../api/data/core/Enums"
import Utils from "../../lib/Utils"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./ServiceGraph.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
)

export type ServicesAllDatas = {
    numberOfVisitors: {
        hour: number[]
        day: number[]
        week: number[]
        month: number[]
    },
    numberOfCustomers: {
        hour: number[]
        day: number[]
        week: number[]
        month: number[]
    },
    purchasers: {
        hour: number[]
        day: number[]
        week: number[]
        month: number[]
    }
}

export type ServicesAllSizes = {
    hour: number
    day: number
    week: number
    month: number
}

export type ServicesAllLabels = {
    hour: string[]
    day: string[]
    week: string[]
    month: string[]
}

export type ServicesDataType = {
    label: ServicesAllLabels
    data: ServicesAllDatas
    size: ServicesAllSizes
}

type TableDataType = {
    label: string[]
    enter: number[]
    purchase: number[]
    service: number[]
    purcRate: number[]
    svcRate: number[]
}

export const ServiceGraphCore: React.FC<MainCompoProps> = (props) => {

	const { t } = useTranslation()
    //const [graphSpan, setGraphSpan] = useState<GraphSpan>(GraphSpanType.Days)
    const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnitType.Day)
    const [tableData, setTableData] = useState<TableDataType | undefined>(undefined)
    const chartRef = useRef<ChartJS<'line', number[], string>>(null)

    const isSingle: boolean = useMemo(() => {
        return (props.view === AnalyzeViewType.Single) ? true : false
    }, [props.view])

    const options: ChartOptions<'line'> = useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: { display: false },
                tooltip: {
                    callbacks: {
                        beforeLabel: (context: any) => {
                            const idx = context.dataIndex
                            const val = context.dataset.data[idx]
                            context.formattedValue = Utils.formatCommaPointOne(val) + "%"
                            return ""
                        }
                    }
                }
            },
            interaction: {
                mode: 'index',
                intersect: false
            },
            layout: {
                padding: { left: 5, right: 0, top: 10, bottom: 10 }
            },
            elements: {
                point: { radius: 4 },
                line: { borderWidth: 2, tension: 0.1 }
            },
            scales: {
                x: {
                    ticks: { display: true },
                    grid: { display: false, drawBorder: false },
                },
                y: {
                    ticks: { display: true },
                    grid: { display: true, drawBorder: false },
                    afterTickToLabelConversion: (axis: LinearScale) => {
                        axis.ticks.forEach(el => {
                            el.label = el.value + "%"
                        })
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        //if (tableData === undefined) {
            const dataAry = props.data?.get_purchaser_timeline(timeUnit)
            const tableDt: TableDataType = {
                label: [],
                enter: [],
                purchase: [],
                service: [],
                purcRate: [],
                svcRate: [],
            }
            if (dataAry) {
                for (let dt of dataAry) {
                    tableDt.label.push(dt.time_label)
                    tableDt.enter.push(dt.enter_count)
                    tableDt.purchase.push(dt.purchaser_count)
                    tableDt.service.push(dt.serviced_count)
                    tableDt.purcRate.push(Math.round(dt.purchaser_count / dt.enter_count * 1000) / 10)
                    tableDt.svcRate.push(Math.round(dt.serviced_count / dt.enter_count * 1000) / 10)
                }
                setTableData(tableDt)
            }
        //}    
    }, [props.data, timeUnit])
    
    const chartData = useMemo(() => {
        return {
            labels: tableData?.label,
            datasets: [
                {
                    label: "serviceGraph",
                    borderColor: LINE_COLOR_BLUE,
                    backgroundColor: "rgb(255,255,255)",
                    data: tableData?.svcRate
                },
                {
                    label: "purchaseRate",
                    borderColor: LINE_COLOR_GREEN,
                    backgroundColor: "rgb(255,255,255)",
                    data: tableData?.purcRate
                }
            ]
        }
    }, [tableData])

    const changeTimeUnit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        switch (nm) {
            case "hour":
                setTimeUnit(TimeUnitType.Hour)
                break
            case "day":
                setTimeUnit(TimeUnitType.Day)
                break
            case "week":
                setTimeUnit(TimeUnitType.Week)
                break
            case "month":
                setTimeUnit(TimeUnitType.Month)
                break
            default:
				const msg = t("msgUnknownButtonPressed")
                throw new Error(msg + "nm:" + nm)
        }
    }

    const getButtonList = ():BtnProp[] => {
        const list = SwitchItems.map((el, i) => {
            return {name: el.type, label:el.label} as BtnProp
        })
        return list
    }

    const getDataRows = (data: TableDataType | undefined): React.ReactNode => {
        const result = []
        if (data) {
            const len = data.label.length
            for (let i = 0; i < len; i++) {
                const itm = (
                    <tr key={i}>
                        <td className={style.tdDate}>{data.label[i]}</td>
                        <td className={style.tdVisi}>{Utils.comma(data.enter[i])}</td>
                        <td className={style.tdPurc}>{Utils.comma(data.purchase[i])}</td>
                        <td className={style.tdCust}>{Utils.comma(data.service[i])}</td>
                        <td className={style.tdCsRt}>{Utils.formatCommaPointOne(data.svcRate[i])}%</td>
                        <td className={style.tdPrRt}>{Utils.formatCommaPointOne(data.purcRate[i])}%</td>
                    </tr>
                )
                result.push(itm)
            }
        }
        return result
    }

    return (
        <div className={style.content}>
            <div className={isSingle ? style.single : style.compare}>
                <div className={isSingle ? style.head : style.headCmp}>
                    <div className={style.title}>
                        {t("header.purchaseAndCustomerServiceRate")}
                        {isProduction ? (<TrackingPageView page_title="analyze-purchaseAndCustomerServiceRate" />) : (null)}
                    </div>
                    <div className={style.dummy}></div>
                    <div className={style.switch}>
                        <SeriesSwitcher buttonList={getButtonList()} onClick={e => changeTimeUnit(e)} activeName={timeUnit} fontType={SwitcherFontType.MulishMini} />
                    </div>
                </div>
                <div className={isSingle ? style.graph : style.graphCmp}>
                    <Chart ref={chartRef} type="line" data={chartData} options={options} />
                </div>
            </div>
            <div className={style.list}>
                <div className={isSingle ? style.caption : style.captionCmp}>
                    {t("dataList")}
                </div>
                <div className={isSingle ? style.tablePane : style.tablePaneCmp}>
                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th className={style.thDate}>{ t("date") }</th>
                                <th className={style.thNum}>{ t("visitors") }</th>
                                <th className={style.thNum}>{ t("purchasers") }</th>
                                <th className={style.thNum}>{ t("customers") }</th>
                                <th className={style.thRatio}>{ t("serviceGraph") }</th>
                                <th className={style.thRatio}>{ t("purchaseRate") }</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getDataRows(tableData)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const ServiceGraph: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={ServiceGraphCore} />
    )
}