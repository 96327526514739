import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { AcceptButtonType, CustomDialog } from "../custom_dialog/CustomDialog"

import style from "./TranscriptionDialog.module.css"

type Props = {
    requestOpen: boolean
    name: string
    guideMessage: string
    onCancel: () => void
    onAccept: () => void
}

export const TranscriptionDialog: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    
    const width = 550

    const height = 190

    const handleAccept = () => {
        props.onAccept()
    }

    const handleCancel = () => {
        props.onCancel()
    }

    return (
        <CustomDialog
            requestOpen={props.requestOpen}
            width={width}
            height={height}
            guideMessage={t("button.transcription")}
            onAccept={handleAccept}
            onCancel={handleCancel}
            buttonType={AcceptButtonType.Ok}
        >
            <div className={style.message}>{props.guideMessage}</div>
        </CustomDialog>
    )
}