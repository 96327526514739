import { ResArea, ResCompArea, ResCompAreaSet } from "../../data/analysis/FullLayout";
import { ResAreaSet } from "../../data/analysis/FullLayout";
import { Coord, CoordAndWidth } from "../../data/core/Coord";

export type Array3<T> = [T, T, T]

/**
 * エリアのセル情報を行ごとにまとめる
 * @param crdAry 
 * @returns 
 */
export const GetRowMap = (crdAry: Coord[]): Promise<Record<number, Coord[]>> => {
    return new Promise((resolve, reject) => {
        const result: Record<number, Coord[]> = {}
        for (let crd of crdAry) {
            const y = crd.y
            if (result[y] === undefined) {
                result[y] = [crd]
            } else {
                result[y].push(crd)
            }
        }
        resolve(result)
    })
}

/**
 * １行分のエリアセル情報をArray3形式に変換する。途中で途切れていることも考慮し、配列で返す
 * @param oneRow 
 * @returns 
 */
export const ToArray3 = async (oneRow: Coord[]): Promise<Array3<number>[]> => {
    if (oneRow.length === 1) {
        const ary: Array3<number> = [oneRow[0].x, oneRow[0].y, 1]
        return [ary]
    }
    const result: Array3<number>[] = []
    const sorted = oneRow.sort((a, b) => a.x - b.x)
    const y = sorted[0].y
    let prevX = 0
    let startX = 0
    let width = 0
    for await (let crd of sorted) {
        if (width === 0) {
            prevX = crd.x
            startX = crd.x
            width = 1
        } else {
            if (prevX + 1 === crd.x) {
                prevX = crd.x
                width++
            } else {
                const ary: Array3<number> = [startX, y, width]
                console.log("ToArray3 ary:", ary)
                result.push(ary)
                prevX = crd.x
                startX = crd.x
                width = 1
            }
        }
    }
    const ary:Array3<number> = [startX, y, width]
    result.push(ary)
    return result
}

/**
 * １行分のエリアセル情報をCoordAndWidth形式に変換する。途中で途切れていることも考慮し、配列で返す
 * @param oneRow 
 * @returns 
 */
export const ToCoordAndWidth = async (oneRow: Coord[]): Promise<CoordAndWidth[]> => {
    if (oneRow.length === 1) {
        const obj: CoordAndWidth = {x: oneRow[0].x, y: oneRow[0].y, w: 1}
        return [obj]
    }
    const result: CoordAndWidth[] = []
    const sorted = oneRow.sort((a, b) => a.x - b.x)
    const y = sorted[0].y
    let prevX = 0
    let startX = 0
    let width = 0
    for await (let crd of sorted) {
        if (width === 0) {
            prevX = crd.x
            startX = crd.x
            width = 1
        } else {
            if (prevX + 1 === crd.x) {
                prevX = crd.x
                width++
            } else {
                const obj: CoordAndWidth = {
                    x: startX,
                    y: y,
                    w: width
                }
                console.log("ToCoordAndWidth obj:", obj)
                result.push(obj)
                prevX = crd.x
                startX = crd.x
                width = 1
            }
        }
    }
    const obj: CoordAndWidth = {
        x: startX,
        y: y,
        w: width
    }
    result.push(obj)
    return result
}

export const ConvertToCompArea = (area: ResArea): Promise<ResCompArea> => {
    return new Promise((resolve, reject) => {
        const result: ResCompArea = {
            area_id: area.area_id,
            area_number: area.area_number,
            name: area.name,
            cell_ids: [],
            area_type: area.area_type,
            group_id: area.group_id
        }
        GetRowMap(area.cell_ids).then(stack => {
            const keys = Object.keys(stack)
            //const prmAry: Promise<CoordAndWidth[]>[] = keys.map(el => {
            const prmAry: Promise<Array3<number>[]>[] = keys.map(el => {
                const y = parseInt(el)
                //return ToCoordAndWidth(stack[y])
                return ToArray3(stack[y])
            })
            Promise.all(prmAry).then(resAry => {
                //let cellIds: CoordAndWidth[] = []
                let cellIds: Array3<number>[] = []
                resAry.forEach(el => {
                    cellIds = [...cellIds, ...el]
                })
                result.cell_ids = cellIds
                resolve(result)
            })
        })
    })
}

export class ConvUtil {
    /**
     * エリアの形式をResAreaSetの[x,y]から圧縮エリア形式のResCompAreaSet[x,y,w]に変換する
     * @param areaSet 
     * @returns 
     */
    static ConvertToCompAreaSet(areaSet: ResAreaSet): Promise<ResCompAreaSet> {
        return new Promise((resolve, reject) => {
            const result: ResCompAreaSet = {
                area_set_id: areaSet.area_set_id,
                start: areaSet.start,
                area_defs: [],
                inserttime: areaSet.inserttime,
                updatetime: areaSet.updatetime
            }
            const prmAry: Promise<ResCompArea>[] = areaSet.area_defs.map(el => ConvertToCompArea(el))
            Promise.all(prmAry).then(res => {
                result.area_defs = res
                resolve(result)
            })
        })
    }

}

export default ConvUtil