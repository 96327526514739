import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { TailSpin } from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import { Header } from "../common/Header"
import { Button, StyleType } from "../../component/button/Button"
import { useAuthUserContext } from "../../providers"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./PasswordReset.module.css"

interface Props {}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export const PasswordReset: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    const param = useParams()
    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"

    const { performPasswordReset } = useAuthUserContext()
    // 成功判定
    const [success, setSuccess] = useState<boolean>(false)
    // 送信
    const [submitted, setSubmitted] = useState<boolean>(false)
    // スピナー表示
    const [isWaiting, setIsWaiting] = useState<boolean>(true)
    // 新パスワード
    const [newPassword, setNewPassword] = useState<string>("")

    const handleGoLogin = () => {
        navigate(baseUrl + "login")
    }

    const getContents = () => {
        if (isWaiting) {
            return (
                <div className={style.text}>
                    <span>{t("msgIdentification")}</span>
                    <br></br>
                    <span>{t("msgWait")}</span>
                    <br></br>
                    <span>&nbsp;</span>
                    <br></br>
                    <span>&nbsp;</span>
                </div>
            )
        } else {
            if (success) {
                return (
                    <div className={style.text}>
                        <span>{t("msgCompIdentification")}</span>
                        <br></br>
                        <span>{t("msgNewPass1")}<span className={style.newPassword}>{newPassword}</span>{t("msgNewPass2")}</span>
                        <br></br>
                        <span>{t("msgLeavePageNotice")}</span>
                        <br></br>
                        <span>{t("msgPleaseNote")}</span>
                    </div>
                )
            } else {
                return (
                    <div className={style.text}>
                        <span>{t("msgCouldNotVerified")}</span>
                        <br></br>
                        <span>{t("msgAlreadyExpired")}</span>
                        <br></br>
                        <span>{t("msgReissuePasswordAgain")}</span>
                        <br></br>
                        <span>&nbsp;</span>
                    </div>
                )
            }
        }
    }

    useEffect(() => {
        const apicall = async (code: string) => {
            try {
                const newPw = await performPasswordReset(code)
                console.log("新パスワードは、'" + newPw + "'です。")
                setNewPassword(newPw)
                setSuccess(true)
                if (isWaiting) setIsWaiting(false)
            } catch (err) {
                console.error("APIでエラー発生", err)
                setSuccess(false)
                if (isWaiting) setIsWaiting(false)
                window.alert("API呼び出しエラー")
            }
        }

        // React18からStrictModeが加わり、開発モードでは意図しない副作用を検出するためにコンポーネントを２回呼び出す。
        // ここではタイマーをつかってAPIが２回呼び出されることを回避している。
        let timer: number | undefined
        if (param && param.certification_code) {
            if (submitted === false) {
                setSubmitted(true)
                timer = window.setTimeout(() => {
                    console.log("APIコール")
                    if (param.certification_code) apicall(param.certification_code)
                }, 1000)
            }
        } else {
            console.log("認証コードなし")
            if (isWaiting) setIsWaiting(false)
        }

        // コンポーネントUNMOUNT時にタイマーをクリアしAPIコールを回避
        return () => {
            //console.log("UNMOUNT")
            if (timer) window.clearTimeout(timer)
        }
    }, [])

    return (
        <div className={style.main}>
            <Header />
            <div className={style.pane}>
                <div className={style.head}>
                    <div className={style.title}>{t("header.resetPassword")}</div>
                    {isProduction ? (<TrackingPageView page_title="reset-password" />) : (null)}
                </div>
                <div className={style.body}>
                    {
                        getContents()
                    }
                    <div className={style.button}>
                        <Button
                            label="backLogin"
                            name="login"
                            onClick={handleGoLogin}
                            styleType={StyleType.Normal}
                        />
                    </div>
                </div>
            </div>
            {
                (isWaiting) ? (
                    <div className={style.spinner}>
                        <div className={style.overlay}></div>
                        <div className={style.waiting}>
                            <TailSpin color="#1E5EFF" width={25} height={25} />
                        </div>                        
                    </div>
                ): (
                    null
                )
            }
        </div>
    )
}