import React, { useMemo, MouseEvent, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { Pages } from "../../types"
import { Permissions } from "../../types/Permissions"
import { Button, StyleType } from "../../component/button/Button"
import { BaseFrame } from "./BaseFrame"
import { BeAbleToAccess } from "../auth/BeAbleToAccess"

import style from "./ListFrame.module.css"

interface Props {
    children: React.ReactNode
    page: Pages
    title: string
    permission: Permissions    // アクセスコントロール

    companySelect: boolean  // 企業選択プルダウンの有無

    shopSelect: boolean     // 店舗選択プルダウンの有無

    newEntry: boolean       // 新規登録ボタンの有無
    onClickNew?: () => void

    copyEntry: boolean      // 複製登録ボタンの有無
    onClickCopy?: () => void
}

export const ListFrame: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    const { companyId, changeCompany, companyList, shopId, changeShop, errorOccurred } = useManagementDataContext()

    const { accessableShops } = useAccessControlContext()

    // 自身のアクセス出来る店舗リスト
    const accessableShopList = useMemo(() => { return accessableShops(props.permission) } ,[accessableShops, props.permission])
    
    /**
     * 企業がひとつしか存在しない
     */
    const onlyOneCompany = useMemo(() => {
        return (companyList && companyList.length === 1)
    }, [companyList])

    /**
     * 新規登録ボタン押下時の処理
     * @param event 
     */
    const handleClickNew = useCallback((event: MouseEvent) => {
        event.preventDefault()
        if (props.newEntry && props.onClickNew) props.onClickNew() 
    }, [props])
    
    /**
     * 複写登録ボタン押下時の処理
     * @param event 
     */
    const handleClickCopy = useCallback((event: MouseEvent) => {
        event.preventDefault()
        if (props.copyEntry && props.onClickCopy) props.onClickCopy()
    }, [props])

    /**
     * 企業選択変更時の処理
     * @param event 
     */
    const handleCompanySelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target.value
        const id = parseInt(val)
        changeShop(undefined)
        changeCompany(id ? id : undefined)
    }

    /**
     * 店舗選択変更時の処理
     * @param event 
     */
    const handleShopSelected = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target.value
        const id = parseInt(val)
        changeShop(id ? id : undefined)
    }, [changeShop])

    /**
     * 企業選択Optionを作成
     */
    const companySelectOptons = useMemo(() => {
        const elm = (<option key="company-select-x" value={0}>{t("msgSelectCompany")}</option>)
        if (companyList) {
            const ops = companyList.map((el, i) => {
                return (<option key={'company-select-' + (i + 1)} value={el.company_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [companyList, t])

    /**
     * 店舗選択Optionを作成
     */
    const shopSelectOptions = useMemo(() => {
        const elm = (<option key="shop-select-x" value={0}>{t("msgSelectStore")}</option>)
        let key = 0
        let res = [elm]
        if (companyId && accessableShopList) {
            for (let shop of accessableShopList) {
                const itm = (<option key={'shop-select-' + (key++)} value={shop.shop_id}>{shop.name}</option>)
                res.push(itm)
            }
        }
        return res
    }, [companyId, t, accessableShopList])

    const shopViewElements = useMemo(() => {
        if (props.shopSelect) {
            if (companyId && accessableShopList) {
                if (accessableShopList.length === 0) {
                    // アクセス可能な店舗がない
                    const msg = t("msgHasNoStore")
                    return (<div className={style.shopSelectText}><span className={style.hasNoShop}>{msg}</span></div>)
                } else {
                    // 通常の店舗選択プルダウン
                    return (
                        <div className={style.shopSelection}>
                            <label htmlFor="shop_select">{t("selectStore")}：</label>
                            <select id="shop_select" className={style.shopSelect} value={shopId ? shopId : 0} onChange={handleShopSelected}>
                                {shopSelectOptions}
                            </select>
                        </div>
                    )
                }
            } else {
                return (
                    <div className={style.shopSelectDisabled}></div>
                )
            }
        }
        return (<></>)
    }, [props, companyId, shopId, handleShopSelected, accessableShopList, shopSelectOptions, t])

    /**
     * ボタンの表示・非表示制御
     * 企業選択と店舗選択が決定したらtrue
     */
    const isViewingButtons = useMemo(() => {
        let isView = false
        if (props.companySelect && props.shopSelect) {
            if (companyId && shopId) isView = true
        } else if (props.companySelect && !props.shopSelect) {
            if (companyId) isView = true
        } else {
            isView = true
        }
        return isView
    }, [props, companyId, shopId])

    /**
     * ボタンの表示がない場合にtrue
     */
    const disableButtonRow = useMemo(() => {
        return (isViewingButtons && (props.newEntry || props.copyEntry)) ? false : true
    }, [props, isViewingButtons])

    /**
     * 新規登録ボタン
     */
    const newEntryButton = useMemo(() => {
        return (props.newEntry && isViewingButtons) ? (<Button label="newEntry" name="newEntry" styleType={StyleType.Normal} onClick={(e) => handleClickNew(e)} />) : (<></>)
    }, [props, isViewingButtons, handleClickNew])

    /**
     * 複製登録ボタン
     */
    const copyButton = useMemo(() => {
        return (props.copyEntry && isViewingButtons) ? (<Button label="copyEntry" name="copyEntry" styleType={StyleType.Normal} onClick={(e) => handleClickCopy(e)} />) : (<></>)
    }, [props, isViewingButtons, handleClickCopy])

    return (
        <BaseFrame actPage={props.page} >
            <div className={style.panel}>
                <div className={style.title}>{props.title}</div>
                {
                    errorOccurred ? (<div className={style.error}>{t("msgErrorOccured")}: {errorOccurred}</div>) : (<></>)
                }
                <BeAbleToAccess permission={props.permission} >
                    <div className={style.filter}>
                        {
                            props.companySelect ? (
                                (onlyOneCompany) ? (
                                    <div className={style.comSelection}>
                                        <label>{t("companyName") }：</label><span className={style.companyName}>{companyList[0].name}</span>
                                    </div>
                                ) : (
                                <div className={style.comSelection}>
                                    <label htmlFor="company_select">{t("selectCompany")}：</label>
                                    <select id="company_select" className={style.companySelect} value={companyId} onChange={handleCompanySelected}>
                                        {companySelectOptons}
                                    </select>
                                </div>)
                            ) : (null)
                        }
                        {shopViewElements}
                    </div>
                    {
                        disableButtonRow ? (null) : (
                            <div className={style.control}>
                                {newEntryButton}
                                <span className={style.btnSpace}>&nbsp;</span>
                                {copyButton}
                            </div>
                        )
                    }
                    <div className={style.listing}>
                        {props.children}
                    </div>
                </BeAbleToAccess>
            </div>
        </BaseFrame>
    )
}