import React, { useMemo, useRef } from "react"
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom"
import { useCookies } from "react-cookie";

import { useAuthUserContext, COOKIE_SESSION_KEY } from "./AuthUser"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

export const ProtectedLayout = () => {
    const { userInfo, timedOut } = useAuthUserContext()
    //console.log("userInfo, sessin:", userInfo, session)
    const [searchParams] = useSearchParams()
    const reset_keyword = searchParams.get("reset")
    const [cookies] = useCookies([COOKIE_SESSION_KEY])

    const session = useMemo(() => {
        if (mockOn) return true
        console.log("cookies:", cookies)
        if (cookies) {
            const v = cookies[COOKIE_SESSION_KEY]
            if (v) return true
        }
        //return (cookies && cookies[COOKIE_SESSION_KEY])
        return false
    }, [cookies])

    const location = useLocation()
    const stateObj = { from: location }
    const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"

    if (reset_keyword) {
        // パスワードリセットへ
        console.log("パスワードリセットへ。reest_keyword:", reset_keyword)
        return (<Navigate to={"/reset/" + reset_keyword} />)
    } else if (!userInfo) {
        //ログイン画面へ
        console.log("goto Login. userInfo,session:",userInfo, session)
        return (<Navigate to={baseUrl + "login"} state={stateObj} replace={false} />)
    } else if (timedOut) {
        //タイムアウト画面へ
        console.log("goto Timeout")
        return (<Navigate to={baseUrl + "login"} state={stateObj} replace={false} />)
    }
    console.log("goto contents")
    //コンテンツへ
    return (
        <>
            <Outlet />
        </>
    )
}
