import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useCookies } from 'react-cookie'
import { useTranslation } from "react-i18next";

import { LoginStatus, LoginStatusType } from "../feature/auth/Login";
import { useLocalStorage } from "./useLocalStrage";
import { ResLoginInfo, ResShop } from "../api/data/login/LoginInfo";
import { LoginAPIImpl } from "../api/api_impl/LoginAPIImpl";
import ApiMock from "../api_mock/ApiMock";
import { Role } from "../api/data/core/Enums";
import { Permissions } from "../types/Permissions";
import { rules } from "./Rules";
import { EditAPIMock } from "../api_mock/EditAPIMock";
import { ReqUpdateUser } from "../api/data/edit/Req";
import { EditAPI } from "../api/api_impl/edit/EditAPI";
import Utils from "../lib/Utils";

export const STORAGE_ITEM_KEY = "loginInfo"
export const COOKIE_SESSION_KEY = "session"

const ERR_MESSAGE: string = "msgMissingUser"
const NETWORK_ERROR: string = "msgNetworkError"

type StatusWithErrMsg = {
    status: LoginStatus
    errorMessage: any | undefined
}

export type AuthUserContextType = {
    // ログイン・ログアウト系
    userInfo: ResLoginInfo | undefined
    signin: (id: string, pw: string) => void
    signout: (callback: () => void) => void
    authStatus: StatusWithErrMsg
    // タイムアウト系
    timedOut: boolean
    callTimeout: () => void
    // 店舗並び替え系
    orderedShopList: ResShop[] | undefined
    updateDashbordDisplayOrder: (newOrder: number[]) => void
    orderStatus: StatusWithErrMsg
    orderStatusClear: () => void
    // パスワードリセット系
    passwordReissueProcedure: (email: string) => Promise<boolean>
    performPasswordReset: (code: string) => Promise<string>
    changePassword: (oldPassword: string, newPassword: string) => Promise<boolean>
    // ロール・権限チェック
    ownAccessCheck: (action: Permissions) => boolean
    otherAccessCheck: (action: Permissions) => boolean
    unlimitedAccessCheck: (action: Permissions) => boolean
    summarizeAccessCheck: (action: Permissions) => boolean
    // 管理画面からログイン情報の操作
    updateLoginInfo: (newLoginInfo: ResLoginInfo) => void
}
const AuthUserContext = React.createContext<AuthUserContextType>({} as AuthUserContextType)

export const useAuthUserContext = (): AuthUserContextType => {
    return React.useContext<AuthUserContextType>(AuthUserContext)
}

type Props = {
    children: React.ReactNode
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export const AuthUserProvider = (props: Props) => {

	const { t } = useTranslation()
    // ログインユーザー情報
    const [userInfo, setUserInfo] = useState<ResLoginInfo | undefined>(undefined)
    // セッション情報
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_SESSION_KEY])
    // ログイン処理情報
    const [authStatus, setAuthStatus] = useState<StatusWithErrMsg>({ status: LoginStatusType.Idle, errorMessage: undefined })
    // 店舗並び順リスト
    const [orderedShopList, setOrderedShopList] = useState<ResShop[] | undefined>(undefined)
    // 店舗並び替えステータス
    const [orderStatus, setOrderStatus] = useState<StatusWithErrMsg>({ status: LoginStatusType.Idle, errorMessage: undefined })
    // タイムアウトフラグ
    const [timedOut, setTimedOut] = useState<boolean>(false)
    // ストレージ
    const [storageValue, setStorageValue, removeValue] = useLocalStorage({ keyName: STORAGE_ITEM_KEY })

    // セッションの有無
    const session = useMemo(() => {
        if (mockOn) return true
        console.log("cookies:", cookies)
        if (cookies) {
            const val = cookies[COOKIE_SESSION_KEY]
            if (val) return true
        }
        return false
    }, [cookies])

    // セッションを削除します
    const deleteSession = useCallback(() => {
        if (mockOn) return
        if (session) removeCookie(COOKIE_SESSION_KEY)
    }, [session, removeCookie])

    /**
     * 自社所属店舗、自社他店舗、他社のいずれかにその権限が存在するかどうかをチェックします。
     * これは、サイドメニューなどのような所属が決められない部分で利用するための包括的なチェックです。
     * @param action 
     * @param data 
     * @returns 
     */
    const summarizeAccessCheck = (action: Permissions, data?: Object): boolean => {
        if (userInfo) {
            // UserTypeからロールを取得
            const myRole: Role = Utils.userType2Role(userInfo.user.user_type)
            // ルールテーブルから自身の権限を取得
            const myPermissions = rules[myRole]
            // ルールがない場合はfalse
            if (!myPermissions) return false

            // staticのown,other.outerのどれかにpermisshonが存在するかどうか
            const outer = myPermissions.static.outer
            const otherStatic = myPermissions.static.otherShop
            const ownStatic = myPermissions.static.ownShop
            if (outer && outer.includes(action)) return true
            if (otherStatic && otherStatic.includes(action)) return true
            if (ownStatic && ownStatic.includes(action)) return true
        }
        return false
    }

    /**
     * 自身の所属する店舗のアクセスコントロール処理
     */
    const ownAccessCheck = useCallback((action: Permissions, data?: Object): boolean => {
        if (userInfo) {
            // UserTypeからロールを取得
            const myRole: Role = Utils.userType2Role(userInfo.user.user_type)
            // ルールテーブルから自身の権限を取得
            const myPermissions = rules[myRole]
            // ルールがない場合はfalse
            if (!myPermissions) return false

            // staticのown,other.outerのどれかにpermisshonが存在するかどうか
            const ownStatic = myPermissions.static.ownShop
            if (ownStatic && ownStatic.includes(action)) return true
        }
        return false
    }, [userInfo])

    /**
     * 他店のアクセスコントロール処理
     * @param action 
     * @param data 
     * @returns 
     */
    const otherAccessCheck = useCallback((action: Permissions, data?: Object): boolean => {
        if (userInfo) {
            // UserTypeからロールを取得
            const myRole: Role = Utils.userType2Role(userInfo.user.user_type)
            // ルールテーブルから自身の権限を取得
            const myPermissions = rules[myRole]
            // ルールがない場合はfalse
            if (!myPermissions) return false

            // staticのown,other.outerのどれかにpermisshonが存在するかどうか
            const otherStatic = myPermissions.static.otherShop                      
            if (otherStatic && otherStatic.includes(action)) return true
        }
        return false
    }, [userInfo])

    /**
     * 他社へのアクセス権限があるかどうかをチェックします。
     */
    const unlimitedAccessCheck = useCallback((action: Permissions, data?: Object): boolean => {
        if (userInfo) {
            // UserTypeからロールを取得
            const myRole: Role = Utils.userType2Role(userInfo.user.user_type)
            // ルールテーブルから自身の権限を取得
            const myPermissions = rules[myRole]
            // ルールがない場合はfalse
            if (!myPermissions) return false

            // staticのown,other.outerのどれかにpermisshonが存在するかどうか
            const outers = myPermissions.static.outer
            if (outers && outers.includes(action)) return true
        }
        return false
    }, [userInfo])

    /**
     * 店舗並び順の更新処理
     * 
     * userInfo.shopsには、ダッシュボード表示する店舗の情報が入っている。
     */
    const updateOrderedShopList = useCallback(async () => {
        if (userInfo) {
            if (userInfo.user.dashboard_order && userInfo.user.dashboard_order.length > 0) {
                // 並び順があるとき
                const newList: ResShop[] = []
                for await (let sid of userInfo.user.dashboard_order) {
                    // 店舗リストを作成する
                    const shop = userInfo.shops.find(el => el.shop_id === sid)
                    if (shop) {
                        // Hotレイアウトセットにレイアウトが存在する店舗のみを抽出
                        if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                            newList.push(shop)
                        }
                    }
                }
                if (userInfo.user.dashboard_order.length < userInfo.shops.length) {
                    // 並び順の数が店舗数よりも少ないとき
                    const idList = userInfo.shops.map(el => el.shop_id)
                    for await (let sid of idList) {
                        // 足りない店舗を追加する
                        if (!userInfo.user.dashboard_order.includes(sid)) {
                            const shop = userInfo.shops.find(el => el.shop_id === sid)
                            if (shop) {
                                // Hotレイアウトセットにレイアウトが存在する店舗のみを抽出
                                if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                                    newList.push(shop)
                                }
                            }
                        }
                    }
                }
                setOrderedShopList(newList)
            } else {
                // 並び順がないとき
                const newList: ResShop[] = []
                for await (let shop of userInfo.shops) {
                    // Hotレイアウトセットにレイアウトが存在する店舗のみを抽出
                    if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                        newList.push(shop)
                    }
                }
                setOrderedShopList(newList)
            }
        }
    }, [userInfo])

    /**
     * サインイン（ログイン）処理
     * @param userId 
     * @param passWd 
     */
    const signin = (userId: string, passWd: string) => {
        try {
            setAuthStatus({ status: LoginStatusType.Wait, errorMessage: undefined })
            if (isProduction) {
                const api = new LoginAPIImpl()
                api.login(userId, passWd).then(info => {
                    console.log("login result is :", info)
                    console.log("session:", session)
                    // UserInfo格納
                    setUserInfo(info)
                    // ストレージ保存
                    setStorageValue(info)
                    // タイムアウトフラグをクリア
                    if (timedOut) setTimedOut(false)
                    // ステータス更新
                    setAuthStatus({ status: LoginStatusType.Success, errorMessage: undefined })
                    // 店舗並び順更新
                    updateOrderedShopList()
                }).catch(err => {
                    console.error(err)
                    if (err.message === "Network Error") {
                        setAuthStatus({ status: LoginStatusType.Error, errorMessage: t(NETWORK_ERROR) })
                    } else {
                        setAuthStatus({ status: LoginStatusType.Error, errorMessage: t(ERR_MESSAGE) })
                    }
                })
            } else {
                ApiMock.instance.login(userId, passWd).then(info => {
                    console.log("login result is :", info)
                    // UserInfo格納
                    setUserInfo(info)
                    // ストレージ保存
                    setStorageValue(info)
                    // タイムアウトフラグをクリア
                    if (timedOut) setTimedOut(false)
                    // ステータス更新
                    setAuthStatus({ status: LoginStatusType.Success, errorMessage: undefined })
                    // 店舗並び順更新
                    updateOrderedShopList()
                }).catch(err => {
                    console.error(err)
                    if (err.message === "Network Error") {
                        setAuthStatus({ status: LoginStatusType.Error, errorMessage: t(NETWORK_ERROR) })
                    } else {
                        setAuthStatus({ status: LoginStatusType.Error, errorMessage: t(ERR_MESSAGE) })
                    }
                })
            }
        } catch (err) {
            console.error(err)
            setAuthStatus({ status: LoginStatusType.Error, errorMessage: err + "" })
        }
    }

    /**
     * サインアウト（ログアウト）処理
     * @param callback 
     */
    const signout = (callback: () => void) => {
        try {
            console.log("signout")
            if (isProduction) {
                const api = new LoginAPIImpl()
                api.logout().then(res => {
                    if (!res) console.error("logout result is :", res)
                    if (!timedOut) setTimedOut(true)
                }).catch(err => {
                    console.error(err)
                    console.log("logout error")
                    //throw err このPromiseエラーはtry-catchでキャッチできない
                })
            } else {
                ApiMock.instance.logout().then(res => {
                    if (!res) console.error("logout result is :", res)
                    if (!timedOut) setTimedOut(true)
                }).catch(err => {
                    console.error(err)
                    console.log("logout error")
                    //throw err このPromiseエラーはtry-catchでキャッチできない
                })
            }
            
            removeValue(null)
            setUserInfo(undefined)
            deleteSession()
            setAuthStatus({ status: LoginStatusType.Idle, errorMessage: undefined })
            setOrderedShopList(undefined)
            // コールバックを実行
            callback()
            
        } catch (err: any) {
            console.error(err)
            console.log("signout error")
            if (err.messages) {
                setAuthStatus({ status: LoginStatusType.Error, errorMessage: err.message })
            } else {
                setAuthStatus({ status: LoginStatusType.Error, errorMessage: "Signout Error!" })
            }
        }
    }

    /**
     * タイムアウト処理
     */
    const callTimeout = () => {
        setAuthStatus({ status: LoginStatusType.Idle, errorMessage: undefined })
        setTimedOut(true)
    }

    /**
     * 店舗並び順の保存
     * @param newOrder 
     */
    const updateDashbordDisplayOrder = (newOrder: number[]) => {
        try {
            setOrderStatus({ status: LoginStatusType.Wait, errorMessage: undefined })        
            if (mockOn) {
                if (userInfo) {
                    const api = new EditAPIMock(userInfo)
                    const user = userInfo.user
                    const me: ReqUpdateUser = new ReqUpdateUser(user.user_id, user.login_id, user.name, user.user_type_id, newOrder)
                    api.update_user([me]).then(res => {
                        console.log("display order updated.", res)
                        // UserInfoの更新
                        if (userInfo) {
                            const newInfo: ResLoginInfo = { ...userInfo }
                            const user = userInfo?.user
                            if (user) user.dashboard_order = newOrder
                            setUserInfo(newInfo)
                            // ストレージの更新
                            setStorageValue(newInfo)
                            // 店舗並び順再作成
                            updateOrderedShopList()
                            // ステータス更新
                            setOrderStatus({ status: LoginStatusType.Success, errorMessage: undefined })
                        }
                    }).catch(err => {
                        console.error(err)
                        console.log("me, newOrder", me, newOrder)
                        setOrderStatus({ status: LoginStatusType.Error, errorMessage: err + "" })
                    })                    
                }
            } else {
                const api = new EditAPI()
                if (userInfo) {
                    const req = new ReqUpdateUser(
                        userInfo.user.user_id,
                        userInfo.user.login_id,
                        userInfo.user.name,
                        userInfo.user.user_type_id,
                        newOrder
                    )
                    console.log("update_user req:", req)
                    const users: ReqUpdateUser[] = [req]
                    api.update_user(users).then(resUsr => {
                        console.log("update_uer res:", resUsr)
                        // UserInfoの更新
                        if (userInfo) {
                            const newInfo: ResLoginInfo = { ...userInfo }
                            userInfo.user = resUsr[0]
                            setUserInfo(newInfo)
                            // ストレージの更新
                            setStorageValue(newInfo)
                            // 店舗並び順再作成
                            updateOrderedShopList()
                            // ステータス更新
                            setOrderStatus({ status: LoginStatusType.Success, errorMessage: undefined })
                        }
                    }).catch(err => {
                        console.error(err)
                        console.log("users", users)
                        setOrderStatus({ status: LoginStatusType.Error, errorMessage: err + "" })
                    })
                }
            }
        } catch (err) {
            console.error(err)
            setOrderStatus({ status: LoginStatusType.Error, errorMessage: err + "" })            
        }
    }

    const orderStatusClear = () => {
        setOrderStatus({ status: LoginStatusType.Idle, errorMessage: undefined })
    }

    const passwordReissueProcedure = async (email: string): Promise<boolean> => {
        if (isProduction) {
            const api = new LoginAPIImpl()
            const res = await api.requireResetPassword(email)
            return res
        } else {
            const res = await ApiMock.instance.requireResetPassword(email)
            return res
        }
    }

    const performPasswordReset = async (code: string): Promise<string> => {
        if (isProduction) {
            const api = new LoginAPIImpl()
            const res = await api.resetPassword(code)
            return res
        } else {
            const res = await ApiMock.instance.resetPassword(code)
            return res
        }
    }

    /**
     * パスワード変更処理
     * @param oldPassword 
     * @param newPassword 
     * @returns 
     */
    const changePassword = async (oldPassword: string, newPassword: string) => {
        if (isProduction) {
            const api = new LoginAPIImpl()
            const res = await api.changePassword(oldPassword, newPassword)
            return res
        } else {
            const res = await ApiMock.instance.changePassword(oldPassword, newPassword)
            return res
        }
    }

    /**
     * 管理画面でレイアウトセットが削除された時、あるいはHotが更新されたときにこちらのUserInfoも書き換える。
     * ただし、userとcompanyが異なるとき(rootが顧客の代理で作業したとき)は無視される。
     * 
     * @param newLoginInfo 
     */
    const updateLoginInfo = (newLoginInfo: ResLoginInfo) => {
        if (userInfo) {
            if (userInfo.user.user_id === newLoginInfo.user.user_id && userInfo.company.company_id === newLoginInfo.company.company_id) {
                // UserInfo格納
                setUserInfo(newLoginInfo)
                // ストレージ保存
                setStorageValue(newLoginInfo)
                console.log("updateLoginInfo userInfoとstorageを更新")
            }
        }
    }

    useEffect(() => {
        // 初期化（ストレージ処理の遅延をカバー）
        if (userInfo === undefined) {
            // ページ初期化直後はstorageValue=undefinedとなっている。つまりこれは遅延中であるということ。
            if (storageValue !== undefined) {
                //userInfoの型をチェック(古いLoginInfo2にはshopListはあるがshopsやgroupsは存在しない)
                // さらに、セッションが有効であるかどうかも加える。
                console.log("storageValue, session:", storageValue, session)
                if ((storageValue !== null && storageValue.shopList) || cookies[COOKIE_SESSION_KEY]===undefined) {
                    removeValue()       // 古い形式のストレージ情報は強制削除
                    console.log("Storageクリア")
                } else {
                    setUserInfo(storageValue)
                    console.log("LoginInfo復活")
                }
            }
        }
        if (orderedShopList === undefined) {
            if (userInfo) {
                // 店舗並び順再作成
                updateOrderedShopList()
            }
        }
    }, [session, userInfo, storageValue, orderedShopList, cookies, updateOrderedShopList, removeValue])

    const value: AuthUserContextType = {
        userInfo,
        signin,
        signout,
        authStatus,
        timedOut,
        callTimeout,
        orderedShopList,
        updateDashbordDisplayOrder,
        orderStatus,
        orderStatusClear,
        passwordReissueProcedure,
        performPasswordReset,
        changePassword,
        ownAccessCheck,
        otherAccessCheck,
        unlimitedAccessCheck,
        summarizeAccessCheck,
        updateLoginInfo
    }
    return (
        <AuthUserContext.Provider value={value}>
            {props.children}
        </AuthUserContext.Provider>
    )
}
