import React, { useEffect } from "react"

import { Pages } from "../../types"
import { useWindowSize } from "../../component/useWindowSize"
import { BaseFrame } from "./BaseFrame"

import style from "./ModalFrame.module.css"

interface Props {
    children: React.ReactNode
    page: Pages // 背景のメニューのハイライトするページ
    title: string   // 背景のパネルのタイトル
    width: number   // モーダルの幅
    height: number  // モーダルの高さ
}

export const ModalFrame: React.FC<Props> = (props) => {

    const winSize = useWindowSize()

    const ref = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref && ref.current) {
            const elm = document.getElementById("root")
            if (elm) elm.style.overflow = "hidden"
            if (winSize) {
                const widthNe = winSize.width ? winSize.width * 0.85 < props.width : false
                const heightNe = winSize.height ? winSize.height * 0.85 < props.height : false
                if (widthNe || heightNe) {
                    ref.current.style.overflow = "scroll"
                } else {
                    ref.current.style.overflow = ""
                }
                if (heightNe && winSize.height) {
                    ref.current.style.height = `${winSize.height * 0.85}px`
                } else {
                    ref.current.style.height = ""
                }
            }
        }

        return () => {
            const elm = document.getElementById("root")
            if (elm) elm.style.overflow = ""
        }
    }, [winSize, ref, props.width, props.height])

    return (
        <div className={style.wrap}>
            <BaseFrame actPage={props.page}>
                <div className={style.underPane}>
                    <div className={style.listTitle}>{props.title}</div>
                </div>
            </BaseFrame>
            <div className={style.container}>
                <div className={style.overlay}></div>
                <div className={style.children} ref={ref}>{props.children}</div>
            </div>
        </div>
    )
}