import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { PencilIcon } from "@primer/octicons-react"
import { useTranslation } from "react-i18next"
import { format, addDays } from "date-fns"

import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { PageName } from "../../types"
import { AreaModel, AreaPackModel, EditModeType, UrlParameterType, ViewModeType } from "../../types/Management"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { StyleType as ModalStyleType, useModal } from "../../component/modal/Modal"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { HoverEffectButton } from "../../component/hover_effect_button/HoverEffectButton"
import { useClickAway } from "../../component/useClickAway"
import { useKeypress } from "../../component/useKeypress"
import { CustomDialog, AcceptButtonType } from "../../component/custom_dialog/CustomDialog"
import { ModalFrame } from "../common/ModalFrame"
import AreaDetailPicWriter from "../../lib/AreaDetailPicWriter"
import { useMouseMove } from "../../lib/useMouseMove"
import Utils from "../../lib/Utils"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { STR_YMD_FORMAT } from "../../constants"
import { PermissionTypes } from "../../types/Permissions"
import { AREA_ID_SHIFT_VALUE } from "../../constants"
import { TrackingPageView } from "../common/TrackingPageView"


import style from "./AreaDetail.module.css"


const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

type AreaSelProps = {
    areaList: ResArea[] | undefined
    isOpen: boolean
    onSelectedAreaId: (area_id: number) => void
    onCancel: () => void
}

const AreaSelectMenu: React.FC<AreaSelProps> = (props) => {
    const { t } = useTranslation()

    const ref = useRef<HTMLDivElement>(null)
    const subRef = useRef<HTMLDivElement>(null)

    const handleClick = useCallback((event: React.MouseEvent, areaId: number) => {
        event.preventDefault()
        props.onSelectedAreaId(areaId)
    }, [props])
    
    useClickAway(ref, () => {
        props.onCancel()
    })

    useKeypress('Escape', () => {
        props.onCancel()
    })

    const items = useMemo(() => {
        const result = []
        if (props.areaList) {
            for (let area of props.areaList) {
                const nm = area.area_number + " : " + area.name
                const itm = (<li key={area.area_id} onClick={e => handleClick(e, area.area_id)}>{Utils.textEllipsis(nm, 40)}</li>)
                result.push(itm)
            }
        }
        return result
    }, [props.areaList, handleClick])

    useEffect(() => {
        if (props.areaList) {
            if (props.areaList.length > 14) {
                const elm = subRef.current
                if (elm) {
                    elm.style.overflow = 'scroll'
                }
            }
        }
    }, [props, subRef])

    return (
        <div className={style.areaSelect} ref={ref}>
            {
                (props.isOpen && props.areaList) ? (
                    <div className={style.itemSelect} ref={subRef}>
                        <label>{t("msgSelectAreaEdit")}</label>
                        <ul className={style.listSelect}>
                            {items}
                        </ul>
                    </div>
                ): (null)
            }
        </div>                
    )
}

type AreaInfoType = {
    area: ResArea
    px: number
    py: number
}

// キャンバスの大きさ
const MAP_WIDTH = 620
const MAP_HEIGHT = 440

interface Props {}

export const AreaDetail: React.FC<Props> = (props) => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    
    const { shopList, shopId, layoutId, layoutList, changeAreaPack, areaPackId, areaPackList, saveAreaPack, deleteAreaPack, editingAreaPack, updateEditingAreaPack, groupList, isModifiedAP } = useManagementDataContext()

    const { ableToUpdate, ableToCreate, ableToDelete, ableToRead } = useAccessControlContext()
    
    const [Modal, open, close, isOpen, resize] = useModal('root', { preventScroll: false, closeOnOverlayClick: false, styleType: ModalStyleType.Page })
    
    const [writer, setWriter] = useState<AreaDetailPicWriter | undefined>(undefined)
    const [areaOnMouse, setAreaOnMouse] = useState<AreaInfoType | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [areaMenuOpen, setAreaMenuOpen] = useState<boolean>(false)
    const [noSaveDialogOpen, setNoSaveDialogOpen] = useState<boolean>(false)

    const ref = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const cssRef = useRef<HTMLDivElement>(null)
    const refDpk = useRef<HTMLDivElement>(null)
    const refFirst = useRef(true)

    const location = useLocation()
    console.log("location:", location)
    const search = location.search
    const state = location.state
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => {
        const m = queryParams.get(UrlParameterType.Mode)
        if (m) return m
        return state ? state.Mode : undefined
    }, [queryParams, state])
    const areaSetId = useMemo(() => {
        const asi = queryParams.get(UrlParameterType.AreaSetId)
        if (asi) return asi
        return state ? state.AreaSetId : undefined
    }, [queryParams, state])

    //ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageArea_Delete) }, [ableToDelete])
    const canChange = useMemo(() => { return ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit)) }, [mode, ableNew, ableEdit])
    const ableCategoryView = useMemo(() => { return ableToRead(PermissionTypes.ManageAreaCateg_View) }, [ableToRead])

    // パラメータチェック
    if (layoutId === undefined) {
        navigate("/area_list")
    }

    // 初期化
    // 1) areaPackIdの設定
    // 2) editingAreaPackの設定
    //     ※ area_list の area_id は、すべて10000を加算した値にする。AreaEdit画面で追加されたエリアのarea_idは1～10000の値となる。
    useEffect(() => {
        if (areaPackId === undefined && mode) {
            // 新規登録時(開始日は他のエリアパックと被らないように)
            let dt = new Date()
            let ymd = format(dt, STR_YMD_FORMAT)
            if (areaPackList && areaPackList.length > 0) {
                const excludes = areaPackList.map(el => {return el.startYmd})
                while (excludes.includes(ymd)) {
                    dt = addDays(dt, 1)
                    ymd = format(dt, STR_YMD_FORMAT)
                }
            }
            const tm = dt.getTime()
            if (mode === EditModeType.New) {
                // 新規モード
                changeAreaPack(0)
                const ap: AreaPackModel = {
                    area_set_id: 0,
                    startYmd: ymd,
                    area_list: [],
                    created_at: tm,
                    modified_at: tm
                }
                updateEditingAreaPack(ap)
            } else if (mode === EditModeType.Copy && areaSetId) {
                // コピーモード
                const srcAreaSetId = parseInt(areaSetId)
                changeAreaPack(0)
                if (areaPackList) {
                    const srcAp = areaPackList.find(el => el.area_set_id === srcAreaSetId)
                    if (srcAp) {
                        const dstAp: AreaPackModel = {
                            area_set_id: 0,
                            startYmd: ymd,
                            area_list: srcAp.area_list.map(el => {
                                const area: AreaModel = {
                                    area_id: el.area_id + AREA_ID_SHIFT_VALUE,
                                    area_number: el.area_number,
                                    name: el.name,
                                    cell_ids: [...el.cell_ids],
                                    area_type: el.area_type,
                                    group_id: el.group_id
                                }
                                return area
                            }),
                            created_at: tm,
                            modified_at: tm
                        }
                        updateEditingAreaPack(dstAp)
                    }
                }
            } else if ((mode === EditModeType.Edit || mode === EditModeType.View) && areaSetId) {
                // 編集モード
                const asid = parseInt(areaSetId)
                changeAreaPack(asid)
                if (areaPackList) {
                    const ap = areaPackList.find(el => el.area_set_id === asid)
                    if (ap) {
                        const cloneAp: AreaPackModel = {
                            area_set_id: asid,
                            startYmd: ap.startYmd,
                            area_list: ap.area_list.map(el => {
                                const cloneCells = el.cell_ids ? [...el.cell_ids] : []
                                const area: AreaModel = {
                                    area_id: el.area_id + AREA_ID_SHIFT_VALUE,
                                    area_number: el.area_number,
                                    name: el.name,
                                    cell_ids: cloneCells,
                                    area_type: el.area_type,
                                    group_id: el.group_id
                                }
                                return area
                            }),
                            created_at: ap.created_at,
                            modified_at: ap.modified_at
                        }
                        updateEditingAreaPack(cloneAp)
                    }
                }
            }
        }
    }, [mode, areaSetId, areaPackId, areaPackList, changeAreaPack, updateEditingAreaPack])
    
    // 選択された店舗
    const shop = useMemo(() => {
        if (shopId && shopList) {
            const sh = shopList.find(el => el.shop_id === shopId)
            if (sh) return sh
        }
        return undefined
    }, [shopList, shopId])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // 選択されたエリアリスト
    const areaList = useMemo(() => {
        if (editingAreaPack) {
            return editingAreaPack.area_list
        }
        return undefined
    }, [editingAreaPack])

    // 保存ボタンのdisable制御
    const disableSaveButton = useMemo(() => {
        console.log("isModifiedAP:", isModifiedAP)
        return !isModifiedAP
    }, [isModifiedAP])

    // 開始日
    const startDate = useMemo(() => {
        if (editingAreaPack) {
            const dt = new Date(editingAreaPack.startYmd)
            //console.log(dt)
            return dt
        }
    }, [editingAreaPack])

    // 除外すべき開始日リスト
    const excludeDates = useMemo(() => {
        const result: Date[] = []
        if (areaPackId && areaPackList) {
            for (let ap of areaPackList) {
                if (ap.area_set_id !== areaPackId) {
                    const st = new Date(ap.startYmd)
                    result.push(st)
                }
            }
        }
        return result
    }, [areaPackId, areaPackList])

    // 画面Closeボタン押下時の処理
    const handleClose = () => {
        if (isModifiedAP && mode !== EditModeType.View) {
            // 保存されていない場合は確認ダイアローグを表示
            setNoSaveDialogOpen(true)
        } else {
            // ひとつ前のページに戻る
            navigate("/area_list")
        }
    }

    // このエリアパックを削除します。
    const handleDeleteAllAreas = () => {
        if (!ableDelete) return
        if (areaSetId) {
            const id = parseInt(areaSetId)
            deleteAreaPack(id)
            changeAreaPack(undefined)
        }
        setDialogOpen(false)
        navigate("/area_list")
    }

    /**
     * 保存ボタン押下時の処理
     */
    const handleSave = () => {
        saveAreaPack()
        navigate("/area_list")
    }

    // 新規エリア登録へ遷移
    const handleAreaAdd = useCallback(() => {
        if (mode && areaSetId) {
            navigate("/area_edit?" + UrlParameterType.Mode + "=" + EditModeType.New
                + "&" + UrlParameterType.PrvMode + "=" + mode
                + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                { state: { Mode: EditModeType.New, PrvMode: mode, AreaSetId: areaSetId, from: location } })
        }
    }, [mode, areaSetId, navigate, location])

    // エリア編集へ遷移
    const handleGoEdit = useCallback((areaId: number) => {
        if (mode && areaSetId) {
            const nextPageMode = (mode === EditModeType.Edit || mode === EditModeType.New) ? EditModeType.Edit : EditModeType.View
            navigate("/area_edit?" + UrlParameterType.Mode + "=" + nextPageMode
                + "&" + UrlParameterType.PrvMode + "=" + mode
                + "&" + UrlParameterType.AreaSetId + "=" + areaSetId
                + "&" + UrlParameterType.AreaId + "=" + areaId,
                { state: { Mode: EditModeType.Edit, PrvMode: mode, AreaSetId: areaSetId, AreaId: areaId, from: location } })
        }
    }, [mode, areaSetId, navigate, location])

    // キャンバス上ダブルクリックの処理
    const handleOnDoubleClick = () => {
        // エリア選択されていればエリア編集へ進む
        if (mode && areaOnMouse) {
            const nextPageMode = (mode === EditModeType.Edit || mode === EditModeType.New) ? EditModeType.Edit : EditModeType.View
            navigate("/area_edit?" + UrlParameterType.Mode + "=" + nextPageMode
                + "&" + UrlParameterType.PrvMode + "=" + mode
                + "&" + UrlParameterType.AreaSetId + "=" + areaSetId
                + "&" + UrlParameterType.AreaId + "=" + areaOnMouse.area.area_id,
                { state: { Mode: EditModeType.Edit, PrvMode: mode, AreaSetId: areaSetId, AreaId: areaOnMouse.area.area_id, from: location } })
        }
    }

    // エリアカテゴリ関連付け画面を開きます。
    const handleAreaCategory = () => {
        if (isModifiedAP) {
            // 保存されていない場合は保存する
            saveAreaPack()
        }
        navigate("/area_category?" + UrlParameterType.Mode + "=" + ViewModeType.Dialog,
            { state: { Mode: ViewModeType.Dialog, from: location } })
    }
    
    // 編集ボタン押下時の処理
    // エリア選択メニューをON/OFF
    const handleEditBefore = useCallback(() => {
        if (areaMenuOpen === false) setAreaMenuOpen(true)
    }, [areaMenuOpen])

    // エリア選択メニューを閉じる
    const handleCloseAreaMenu = useCallback(() => {
        setAreaMenuOpen(false)
    }, [])
    
    // エリア選択メニューでエリアが選ばれた場合の処理
    const handleSelectedAreaToGoEdit = useCallback((areaId: number) => {
        // メニューを閉じる
        setAreaMenuOpen(false)
        // エリア編集へ
        handleGoEdit(areaId)
    }, [handleGoEdit] )

    // モーダル一覧を開きます。
    const handleViewAreas = () => {
        if (!isOpen) open()
    }

    // 全エリア削除ボタン押下時の処理
    const handleOpenDialogDelete = () => {
        if (!isOpen && !dialogOpen && ableDelete) {
            setDialogOpen(true)
        }
    }

    // 全エリア削除の確認ダイアローグを閉じる
    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    /**
     * 開始日を変更します。
     * @param event 
     */
    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        if (event && editingAreaPack) {
            const dt = (event as Date)
            const ymd = format(dt, STR_YMD_FORMAT)
            const clone = { ...editingAreaPack }
            clone.startYmd = ymd
            updateEditingAreaPack(clone)
        }
    }

    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageWithArea(ctx)
        }
    }
    
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageWithArea(ctx)
        }
    }
    
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageWithArea(ctx)
        }
    }

    const handleModalClosing = () => {
        if (isOpen) close()
    }
    
    const handleNoSaveDialogAccept = () => {
        if (noSaveDialogOpen) setNoSaveDialogOpen(false)
        navigate("/area_list")
    }

    const handleNoSaveDialogCancel = () => {
        if (noSaveDialogOpen) setNoSaveDialogOpen(false)
    }
    
    const handleRowOver = (event: React.MouseEvent) => {
        const target = event.currentTarget
        target.classList.add(style.rowOver)
    }

    const handleRowOut = (event: React.MouseEvent) => {
        const target = event.currentTarget
        target.classList.remove(style.rowOver)
    }
    
    const mousePress = useMouseMove(
        aniRef,
        (event: MouseEvent) => {
            // マウスドラッグ中の処理
            if (writer) {
                const srcCanvas = ref.current
                const dstCanvas = aniRef.current
                if (srcCanvas && dstCanvas) writer.mouseMoveWithArea(event, mousePress, srcCanvas, dstCanvas, (area: ResArea, px: number, py: number) => {
                    // マウスがエリアの上にあるときの処理
                    //console.log("mouseInArea:", area)
                    const info: AreaInfoType = {area: area, px: px, py: py}
                    setAreaOnMouse(info)
                    const ctx = dstCanvas.getContext("2d")
                    if (ctx) writer.activeAreaDraw(ctx, area.area_id)
                }, () => {
                    // マウスはエリア外
                    setAreaOnMouse(undefined)
                    const ctx = dstCanvas.getContext("2d")
                    if (ctx) writer.clearCanvas(ctx)
                })
            }
        }, (event: MouseEvent) => {
            // マウスボタンを離したときの処理
            if (writer) {
                writer.mouseRelease()
                // 再描画
                const ctx = ref.current?.getContext("2d")
                if (ctx) writer.drawImageWithArea(ctx)
                // ドラッグ領域をクリア
                const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current?.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
            }
        }, (event: MouseEvent) => {
            // マウス押下処理
            if (writer) {
                writer.mousePress()
            }
        }
    )

    /**
     * 一覧の行データを作成します 
     */
    const areaItems = useMemo(() => {
        const items: any = []
        if (areaList && groupList) {
            for (let area of areaList) {
                const grp = groupList.find(el => el.group_id === area.group_id)
                const itm = (
                    <tr key={area.area_id} onMouseOver={handleRowOver} onMouseOut={handleRowOut} onClick={e => handleGoEdit(area.area_id)}>
                        <td>{area.area_number}</td>
                        <td className={style.dataColEllipsis}>{area.name}</td>
                        <td className={style.dataColEllipsis}>{grp?.name}</td>
                        <td>
                            <span className={style.chevron}>
                                <PencilIcon size={16} />
                            </span>
                        </td>
                    </tr>
                )
                items.push(itm)
            }
        }
        return items
    }, [areaList, groupList, handleGoEdit])

    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP render")
                return
            }
        }
        
        // MapWriterをつくる
        if (layout && layout.mapping && areaList) {
            if (writer === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                //console.log("writer init layout, areaList, areaPackForEditing:", layout, areaList, areaPackForEditing)
                const mapwriter = new AreaDetailPicWriter(resLay, areaList, MAP_WIDTH, MAP_HEIGHT)
                mapwriter.pathDepth = (mode === EditModeType.New) ? 1 : 1
                setWriter(mapwriter)
            } else {
                // writerが設定出来たら描画する
                const ctx = ref.current?.getContext("2d")
                if (ctx) writer.drawImageWithArea(ctx)
            }
        }
    }, [mode, layout, areaList, writer])

    useEffect(() => {
        if (areaOnMouse) {
            if (cssRef && cssRef.current) {
                const elm = cssRef.current
                elm.style.left = (areaOnMouse.px + 20) + "px"
                elm.style.top = areaOnMouse.py + "px"
                //console.log("elm", elm)
            }
        }
    }, [areaOnMouse, cssRef])

    // モーダルのスクロールON/OFF
    useEffect(() => {
        const calcModalSize = () => {
            let result = 0
            if (isOpen) {
                if (areaList && areaList.length > 20) {
                    result = 100 + areaList.length * 30
                }
            }
            return result
        }
        const val = calcModalSize()
        resize({ width: undefined, height: val })
    }, [resize, areaList, isOpen])

    const addAndEditButtonElements = useMemo(() => {
        if (areaList === undefined || areaList.length === 0) {
            return (
                <div className={style.btnRow1st}>
                    <Button label="addArea" name="area_add" styleType={StyleType.Normal} onClick={handleAreaAdd} />
                    <div className={style.spaceW}></div>
                </div>
            )
        } else if (canChange) {
            return (
                <div className={style.btnRow1st}>
                    <Button label="addArea" name="area_add" styleType={StyleType.Normal} onClick={handleAreaAdd} disabled={areaMenuOpen} />
                    <div className={style.space}></div>
                    <Button label="edit" name="do_edit" styleType={StyleType.Normal} onClick={handleEditBefore} disabled={areaMenuOpen} />
                    <AreaSelectMenu isOpen={areaMenuOpen} areaList={areaList} onCancel={handleCloseAreaMenu} onSelectedAreaId={handleSelectedAreaToGoEdit} />
                </div>
            )
        } else {
            return (
                <div className={style.btnRow1st}>
                </div>
            )
        }
    }, [areaMenuOpen, areaList, canChange, handleAreaAdd, handleEditBefore, handleCloseAreaMenu, handleSelectedAreaToGoEdit])

    const popupAreaDetail = useMemo(() => {
        if (areaOnMouse) {
            const num = areaOnMouse.area.area_number
            const name = areaOnMouse.area.name
            const key = "areaType." + areaOnMouse.area.area_type
            const type = t(key)
            return (
                <div className={style.areaInformation} ref={cssRef}>
                    <div className={style.areaNumber}><label>{t("number")}：</label><span>{num}</span></div>
                    <div className={style.areaName}><label>{t("name")}：</label><span>{name}</span></div>
                    <div className={style.areaType}><label>{t("type")}：</label><span>{type}</span></div>
                </div>
            )
        } else {
            return (null)
        }
    }, [t, areaOnMouse])

    const layoutStartDate = useMemo(() => {
        if (layout) {
            const dt = new Date(layout.start)
            return dt
        }
    }, [layout])

    //const dispId = useMemo(() => { return mode===EditModeType.New ? t("auto") : layoutId }, [mode, layoutId, t])
    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    return (
        <ModalFrame page={PageName.InfoArea} title={t("header.areaRegistrationEdit")} width={1050} height={690}>
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>
                        {t("header.areaDetails")}
                        {isProduction ? (<TrackingPageView page_title="bsinfo-area-detail" />) : (null)}
                    </div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.body}>
                    <div className={style.leftside}>
                        <div className={style.lefttop}>
                            <table className={style.table}>
                                <tbody>
                                    <tr>
                                        <td className={style.lsHead}>{t("mapId")}</td>
                                        <td className={style.lsData}>{layoutId}</td>
                                    </tr>
                                    <tr>
                                        <td className={style.lsHead}>{t("storeName")}</td>
                                        <td className={style.lsData}>{shop ? shop.name : ""}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={style.leftbtm}>
                            <div className={style.section}>{t("registeredArea")}</div>
                            <div className={style.map}>
                                {
                                    (mode === EditModeType.New) ? (
                                        <div className={style.unregistered}>
                                            <span className={style.text}>{t("unregisterdArea")}</span>
                                        </div>
                                    ): (
                                        <div className={style.legend}>
                                            <span className={style.yellow}>99</span><span className={style.text}>{t("areaName")}</span>
                                        </div>                                        
                                    )
                                }
                                <div className={style.canvasWrap}>
                                    <canvas className={(mode === EditModeType.New) ? style.canvasBorder : style.canvas} width={MAP_WIDTH} height={MAP_HEIGHT} ref={ref}></canvas>
                                    <canvas className={style.aniCanvas} width={MAP_WIDTH} height={MAP_HEIGHT} ref={aniRef} onDoubleClick={handleOnDoubleClick}></canvas>
                                    <div className={style.zoomBtn}>
                                        <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                                    </div>
                                    {popupAreaDetail}
                                </div>
                                <div className={style.memo}>
                                    <span className={style.memoText}>{areaOnMouse ? "※" + t("msgDoubleClickToEdit") : null }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.rightside}>
                        {
                            (!canChange) ? (
                                <div className={style.rightTop}></div>        
                            ) : (
                                <div className={style.rightTop}>
                                    <Button label={t("cancel")} name="cancel" styleType={StyleType.Reverse} onClick={handleClose} />
                                    <span className={style.spc}></span>
                                    <Button label={t("save")} name="save" styleType={StyleType.Normal} onClick={handleSave} disabled={disableSaveButton} />
                                </div>                                    
                            )
                        }
                        <div className={style.rightMid}>
                            <div className={style.startDate}>
                                <label className={style.startLabel}>{t("startDate")}</label>
                                <span className={style.startBorder}>
                                    <FupDatepicker 
                                        type={DpType.Micro}
                                        onChange={handleChangeStartDate}
                                        divRef={refDpk}
                                        selected={(startDate && layoutStartDate && startDate < layoutStartDate) ? layoutStartDate : startDate}
                                        locale={i18n.language}
                                        dateFormat={datepickFormat}
                                        dateFormatCalendar={datepickFormatCalendar}
                                        excludeDates={excludeDates}
                                        disabled={mode === EditModeType.View}
                                        minDate={layoutStartDate}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={style.rightBottom}>
                            <div className={style.buttons}>
                                {addAndEditButtonElements}
                                <div className={style.btnRow2nd}>
                                    {
                                        (areaList === undefined || areaList.length === 0) ? (null) : (
                                            <Button label="listView" name="view_areas" styleType={StyleType.Outline} onClick={handleViewAreas} disabled={areaMenuOpen} />
                                        )
                                    }                                    
                                </div>
                                <div className={style.btnRow3rd}>
                                    {
                                        (mode === EditModeType.Edit && ableCategoryView) ? (
                                            <Button label="menu.relationAreaAndCategory" name="category" styleType={StyleType.Outline} onClick={handleAreaCategory} />
                                        ) : (null)
                                    }
                                </div>
                                <div className={style.btnBottom}>
                                    {
                                        (mode === EditModeType.Edit && ableDelete) ? (
                                            <HoverEffectButton labelBefore="deleteArea" labelAfter="Danger" onClick={handleOpenDialogDelete} />
                                        ) : (null)
                                    }                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal>
                <div className={style.modalMain}>
                    <div className={style.top}>
                        <div className={style.title}>
                            <label>{t("areaView")}</label>
                        </div>
                        <div className={style.closer}>
                            <CircleCloseButton onClose={handleModalClosing} />
                        </div>
                    </div>
                    <div className={style.modalBody}>
                        <table className={style.modalTable}>
                            <thead>
                                <tr>
                                    <th className={style.thId}>{t("areaId")}</th>
                                    <th className={style.thName}>{t("targetArea")}</th>
                                    <th className={style.thName}>{t("groupName")}</th>
                                    <th className={style.thLink}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {areaItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <DeleteDialog paneSize={PaneSizeType.forAreaDetail} guideMessage={t("msgConfirmAllAreaDelete")} requestOpen={dialogOpen} onCancel={handleDialogClose} onDelete={handleDeleteAllAreas} />
            <CustomDialog requestOpen={noSaveDialogOpen} buttonType={AcceptButtonType.Ok} guideMessage="保存せずに閉じますか？" onAccept={handleNoSaveDialogAccept} onCancel={handleNoSaveDialogCancel} width={300} height={165} />
        </ModalFrame>
    )
}