import { Timeline } from "../../data/analysis/AnalysisResult";
import { TimeUnit } from "../../data/core/Enums";
import { OSResTimeline, OSResTimelines } from "../AnalysisDataImpl";
import { CalcUtils } from "./calc_utils";

export class TimelineImpl implements Timeline {
    time_label: string;
    stay_count: number;
    pass_count: number;

    constructor(time_label: string, stayTL: OSResTimeline | null, passTL: OSResTimeline | null) {
        this.time_label = time_label
        this.stay_count = (stayTL == null) ? 0 : stayTL.doc_count
        this.pass_count = (passTL == null) ? 0 : (passTL.doc_count - this.stay_count)
    }

    static mkTl(start_date: string, end_date: string, time_unit: TimeUnit, areaKey: string, stayTl: OSResTimelines, passTl: OSResTimelines): TimelineImpl[] {
        const tls: TimelineImpl[] = []
        const time2tls = CalcUtils.matchingDicts(
            CalcUtils.list2dic(stayTl.aggregations["timeline-histgram"].buckets, tl => tl.key_as_string),
            CalcUtils.list2dic(passTl.aggregations["timeline-histgram"].buckets, tl => tl.key_as_string),
        )
        for (const timeLabel of CalcUtils.listupDate(start_date, end_date, time_unit)) {
            let [tl1, tl2]: [(OSResTimeline | null), (OSResTimeline | null)] = [null, null]
            if (time2tls.hasOwnProperty(timeLabel)) {
                [tl1, tl2] = time2tls[timeLabel]
            }
            tls.push(new TimelineImpl(timeLabel, tl1, tl2))
        }
        tls.sort((a, b) => {
            if (a.time_label < b.time_label) { return -1 }
            if (a.time_label > b.time_label) { return 1 }
            return 0
        })
        return tls
    }
}