import { FC, useMemo, ChangeEvent, useCallback } from "react"
import { DashIcon, PlusIcon } from "@primer/octicons-react"

import style from "./VerticalSlider.module.css"

interface Props {
    value: number
    min?: number
    max?: number
    step?: number
    onChange: (value: number) => void
    withButton?: boolean
    withBorder?: boolean
    buttonStep?: number
    size?: "m" | "s" | "xs"
    dirOfMin: "up" | "down"
}

export const VerticalSlider: FC<Props> = (props) => {

    const btnStp = useMemo(() => {
        return (props.buttonStep) ? props.buttonStep : 1
    }, [props])
    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        const newValue = parseInt(v)
        props.onChange(newValue)
    }

    const handleMinus = useCallback(() => {
        props.onChange(props.value - btnStp)
    }, [props, btnStp])

    const handlePlus = useCallback(() => {
        props.onChange(props.value + btnStp)
    }, [props, btnStp])

    const clzPane = useMemo(() => {
        if (props.size === "s") {
            if (props.withBorder) {
                return `${style.panelS} ${style.withBorderS}`
            } else {
                return `${style.panelS}`
            }
        } else if (props.size === "xs") {
            if (props.withBorder) {
                return `${style.panelXS} ${style.withBorderXS}`
            } else {
                return `${style.panelXS}`
            }
        } else {
            if (props.withBorder) {
                return `${style.panel} ${style.withBorder}`
            } else {
                return `${style.panel}`
            }
        }
    }, [props])

    const clz = useMemo(() => {
        if (props.size === "s") {
            if (props.dirOfMin === "up") {
                return `${style.rangeS} ${style.upMinS}`
            } else {
                return `${style.rangeS} ${style.downMinS}`
            }
        } else if (props.size === "xs") {
            if (props.dirOfMin === "up") {
                return `${style.rangeXS} ${style.upMinXS}`
            } else {
                return `${style.rangeXS} ${style.downMinXS}`
            }
        } else {
            if (props.dirOfMin === "up") {
                return `${style.range} ${style.upMin}`
            } else {
                return `${style.range} ${style.downMin}`
            }
        }
    }, [props])
    
    const btnUpper = useMemo(() => {
        if (props.withButton) {
            if (props.size === "s") {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconTopS} onClick={handleMinus}><DashIcon size={16} /></span>)
                } else {
                    return (<span className={style.iconTopS} onClick={handlePlus}><PlusIcon size={16} /></span>)
                }
            }else if (props.size === "xs") {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconTopXS} onClick={handleMinus}><DashIcon size={12} /></span>)
                } else {
                    return (<span className={style.iconTopXS} onClick={handlePlus}><PlusIcon size={12} /></span>)
                }
            } else {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconTop} onClick={handleMinus}><DashIcon size={24} /></span>)
                } else {
                    return (<span className={style.iconTop} onClick={handlePlus}><PlusIcon size={24} /></span>)
                }
            }
        } else {
            return (<span className={style.iconTop}>　</span>)
        }
    }, [props, handleMinus, handlePlus])

    const btnLower = useMemo(() => {
        if (props.withButton) {
            if (props.size === "s") {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconBottomS} onClick={handlePlus}><PlusIcon size={16} /></span>)
                } else {
                    return (<span className={style.iconBottomS} onClick={handleMinus}><DashIcon size={16} /></span>)
                }
            } else if (props.size === "xs") {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconBottomXS} onClick={handlePlus}><PlusIcon size={12} /></span>)
                } else {
                    return (<span className={style.iconBottomXS} onClick={handleMinus}><DashIcon size={12} /></span>)
                }
            } else {
                if (props.dirOfMin === "up") {
                    return (<span className={style.iconBottom} onClick={handlePlus}><PlusIcon size={24} /></span>)
                } else {
                    return (<span className={style.iconBottom} onClick={handleMinus}><DashIcon size={24} /></span>)
                }
            }
        } else {
            return (<span className={style.iconBottom}>　</span>)
        }
    }, [props, handleMinus, handlePlus])


    return (
        <div className={clzPane}>
            {
                btnUpper
            }
            <span className={style.main}>
                <input type="range" className={clz} onChange={handleChange} value={props.value} step={props.step} min={props.min} max={props.max} />
            </span>
            {
                btnLower
            }
        </div>
    )
}