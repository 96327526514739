import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, StyleType } from "../../component/button/Button"
import { useAuthUserContext } from "../../providers"
import { TrackingPageView } from "../common/TrackingPageView"
import { PageName } from "../../types"
import { BaseFrame } from "../common/BaseFrame"
import { CustomCheckbox } from "../../component/custom_checkbox/CustomCheckbox"
import { OverlaySpinner, SpinerStyleType } from "../../component/overlaySpinner/OverlaySpinner"
import { AcceptButtonType, CustomDialog } from "../../component/custom_dialog/CustomDialog"

import style from "./ChangePassword.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props { }

export const ChangePassword: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const { changePassword } = useAuthUserContext()

    const [oldPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [errorOld, setErrorOld] = useState("")
    const [errorNew, setErrorNew] = useState("")
    const [errorConfirm, setErrorConfirm] = useState("")
    const [wait, setWait] = useState(false)
    const [viewPassword, setViewPassword] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [apiError, setApiError] = useState(false)

    // 数字、大文字、小文字を含む8文字以上
    const pattern=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

    const validationCheck = (): boolean => {
        setErrorOld("")
        setErrorNew("")
        setErrorConfirm("")
        let isOk = true
        if (oldPassword === "") {
            setErrorOld(t("changePassword.passwordEmpty"))
            isOk = false
        }
        if (password === "") {
            setErrorNew(t("changePassword.passwordEmpty"))
            isOk = false
        } else if (!password.match(pattern)) {
            setErrorNew(t("changePassword.passwordNotValid"))
            isOk = false
        }
        if (passwordConfirm === "") {
            setErrorConfirm(t("changePassword.passwordEmpty"))
            isOk = false
        } else if (password !== passwordConfirm) {
            setErrorConfirm(t("changePassword.passwordNotMatch"))
            isOk = false
        }
        return isOk
    }

    // パスワード変更処理
    const handleSubmit = async () => {
        if (validationCheck()) {
            setWait(true)
            changePassword(oldPassword, password).then((res) => {
                setWait(false)
                if (res) {
                    //成功
                    setDialogOpen(true)
                } else {
                    //失敗(現在のパスワード間違い)
                    setErrorOld(t("changePassword.passwordIsIncorrect"))
                }
            }).catch((err) => {
                setWait(false)
                console.log(err)
                setApiError(true)
            })
        }
    }

    const handleCancel = () => { }

    const handleAccept = () => {
        setDialogOpen(false)
        setPassword("")
        setPasswordConfirm("")
        setOldPassword("")
    }

    return (
        <BaseFrame actPage={PageName.ChangePassword}>
            <div className={style.main}>
                <div className={style.head}>
                    <div className={style.title}>
                        {t("header.changePassword")}
                        {isProduction && <TrackingPageView page_title="change-password" />}
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.check}>
                        <CustomCheckbox label={t("label.showPassword")} value="show" check={viewPassword} onChange={() => {setViewPassword(!viewPassword)}}/>
                    </div>
                    <div className={style.input}>
                        <div className={style.label}>{t("label.oldPassword")}</div>
                        <input type={viewPassword ? "text" : "password"} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        <div className={style.error}>{errorOld}</div>
                    </div>
                    <div className={style.input}>
                        <div className={style.label}>{t("label.newPassword")}<span className={style.guide}>{t("guidance.passwordRule") }</span></div>
                        <input type={viewPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                        <div className={style.error}>{errorNew}</div>
                    </div>
                    <div className={style.input}>
                        <div className={style.label}>{t("label.newPasswordConfirm")}</div>
                        <input type={viewPassword ? "text" : "password"} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                        <div className={style.error}>{errorConfirm}</div>
                    </div>
                    {
                        apiError ? (
                            <div className={style.errorReport}>
                                {t("msgErrorOccured")}
                            </div>
                        ) : (
                            <div className={style.report}></div>       
                        )
                    }
                    <div className={style.button}>
                        <Button name={t("button.changePassword")}
                            styleType={StyleType.Primary}
                            label={t("button.changePassword")}
                            onClick={handleSubmit}
                            disabled={wait}
                        />
                    </div>
                </div>
                <OverlaySpinner waiting={wait} spinerStyle={SpinerStyleType.ForManagement} />
                <CustomDialog
                    requestOpen={dialogOpen} width={640} height={200}
                    guideMessage={t("header.changePassword")}
                    onCancel={handleCancel}
                    onAccept={handleAccept}
                    buttonType={AcceptButtonType.NoCancel}
                >
                    <div>{t("changePassword.success")}</div>
                </CustomDialog>
            </div>
        </BaseFrame>
    )
}