import React, { ChangeEvent, useEffect, useState, useRef, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"

import { COOKIE_SESSION_KEY, useAuthUserContext } from "../../providers"
import { Button, StyleType } from "../../component/button/Button";
import { Header } from "../common/Header"
import { OverlaySpinner, SpinerStyleType } from "../../component/overlaySpinner/OverlaySpinner";
import { ResLoginInfo } from "../../api/data/login/LoginInfo";
//import { useManagementDataContext } from "../../providers/ManagementData";

import style from "./Login.module.css";
import { Footer } from "../common/Footer";

const USER_ID_ERR: string = "msgCrrectLoginId"
const PASS_WD_ERR: string = "msgEnterPassword"

const MockUsers = ['a', 'root', 'head', 'aman', 'sman', 'jman', 'staff', 'stfJr']

type AuthValueType = {
    userInfo: ResLoginInfo | null | undefined
    session: boolean | undefined
}

type CustomLocation = {
    state: { from: { pathname: string } }
}

export const LoginStatusType = {
    Idle: "idle",
    Wait: "wait",
    Success: "suc",
    Error: "err"
} as const
export type LoginStatus = typeof LoginStatusType[keyof typeof LoginStatusType]

interface FormType  {
    userid: string | null
    password: string | null
    errorMsg: string | null | undefined
    wait: boolean
}

interface Props {
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const usePrevious = (value: AuthValueType) => {
    const ref = useRef<AuthValueType | null>(null)
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const Login: React.FC<Props> = (props) => {
    const sendDataToGTM = useGTMDispatch()
    const { t } = useTranslation()
    const [forms, setForms] = useState<FormType>({ userid: null, password: null, errorMsg: undefined, wait: false })    

    const navigate = useNavigate()
    const location = useLocation()
    console.log("location:", location)
    const fromPathName: string = (location.state?.from?.pathname) ? location.state.from.pathname : "/"
    const fromState = (location.state?.from.state) ? location.state.from.state : null

    const { userInfo, signin, authStatus, timedOut } = useAuthUserContext()
    const [cookies] = useCookies([COOKIE_SESSION_KEY])

    //const { initManagementData } = useManagementDataContext()

    const refFirst = useRef(true)

    const session = useMemo(() => {
        if (mockOn) return true
        console.log("cookies:", cookies)
        if (cookies) {
            const v = cookies[COOKIE_SESSION_KEY]
            if (v) return true
        }
        //return (cookies && cookies[COOKIE_SESSION_KEY])
        return false
    }, [cookies])

    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"
    
    // 前の値を保存
    const prevAuthValue = usePrevious({userInfo: userInfo, session: session})

    const submit = async () => {
        if (mockOn) {
            // モック動作
            if (forms.userid === null || !MockUsers.includes(forms.userid)) {
                // エラーメッセージを表示
                const newSt = { ...forms }
                newSt.errorMsg = t(USER_ID_ERR)
                setForms(newSt)
                return
            }
            if (!forms.password || forms.password.trim().length < 1) {
                // エラーメッセージを表示
                const newSt = { ...forms }
                newSt.errorMsg = t(PASS_WD_ERR)
                setForms(newSt)
                return
            }
        } else {
            // バリデーション
            if (!forms.userid || forms.userid.trim().length < 5 || forms.userid.trim().indexOf('@') < 0) {
                // エラーメッセージを表示
                const newSt = { ...forms }
                newSt.errorMsg = t(USER_ID_ERR)
                setForms(newSt)
                return
            }
            if (!forms.password || forms.password.trim().length < 3) {
                // エラーメッセージを表示
                const newSt = { ...forms }
                newSt.errorMsg = t(PASS_WD_ERR)
                setForms(newSt)
                return
            }
        }
        const newFm = { ...forms }
        newFm.errorMsg = null
        newFm.wait = true
        setForms(newFm)

        // GTM イベント送信
        const host = window.location.host
        console.log("host:", host)
        const regex = new RegExp("(localhost|192.168.1.0)")
        if (!mockOn || !regex.test(host)) {
            sendDataToGTM({
                event: "login-click",
                event_value: userInfo?.user.login_id,
                page_title: "login",
                url_path: window.location.pathname,
                url_search: window.location.search,
                login_id: userInfo?.user.login_id
            })
        } else {
            console.log("SKIP sendDataToGTM ", "login")
        }

        if (mockOn) {
            // 3秒間スピナー表示させるために
            window.setTimeout(function () {
                //console.log("ログイン　APIコール")
                if (forms.userid && forms.password) signin(forms.userid, forms.password)
                return
            }, 3000)
        } else {
            //console.log("ログイン　APIコール")
            signin(forms.userid, forms.password)
            return
        }
    }

    // エンターキーでsubmit
    const handleSubmit = (event: React.KeyboardEvent) => {
        // 予測変換中でないことを確認して、Enterキーが押されたかを確認
        if (!event.nativeEvent.isComposing && event.key === "Enter") {
            event.preventDefault()
            submit()
        }
    }

    // ログインIDの入力値を保存
    const handleChangeId = (event: ChangeEvent) => {
        const val = (event.target as HTMLInputElement).value
        if (val !== forms.userid) {
            event.preventDefault()
            const newSt = { ...forms }
            newSt.userid = val
            newSt.errorMsg = null
            setForms(newSt)
        }
    }

    // パスワードの入力値を保存
    const handleChangePw = (event: ChangeEvent) => {
        const val = (event.target as HTMLInputElement).value
        if (val !== forms.password) {
            event.preventDefault()
            const newSt = { ...forms }
            newSt.password = val
            newSt.errorMsg = null
            setForms(newSt)
        }
    }

    /*const handleForgotPassword = () => {
        navigate(baseUrl + "forgot")
    }*/

    // リロード時の処理
    useEffect(() => {
        // リロード時
        if (userInfo && session && !timedOut) {
            // 前の値と比較して（リロード時はprevAuthValueがない）
            if (prevAuthValue?.userInfo === undefined && userInfo && session) {
                // 指定されたページへジャンプ
                console.log("userInfoとsessionが有効なので元のページへジャンプ from:",fromPathName)
                navigate(fromPathName, { state: fromState, replace: true })
            } else {
                console.log("prevAuthValue, userInfo, session:", prevAuthValue, userInfo, session)
            }
        }
    }, [session, userInfo, prevAuthValue, fromPathName, fromState, navigate, timedOut])
    
    // ログイン結果の監視
    useEffect(() => {
        // 初回レンダリング時
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                console.log("初回レンダリングSKIP")
                return
            }
        }
        //console.log("authStatus, forms:", authStatus, forms)
        if (authStatus.status === LoginStatusType.Error) {
            if (forms.errorMsg !== authStatus.errorMessage) {
                const newFm = { ...forms }
                newFm.errorMsg = authStatus.errorMessage
                if (newFm.wait) newFm.wait = false
                setForms(newFm)
            }
        } else if (authStatus.status === LoginStatusType.Wait) {
            if (forms.wait === false) {
                const newFm = { ...forms }
                newFm.wait = true
                setForms(newFm)
            }
        } else if (authStatus.status === LoginStatusType.Success) {
            if (userInfo) {
                // 指定されたページへジャンプ
                console.log("ログインが成功したので指定されたページへジャンプ fromPathName, fromState, session:", fromPathName, fromState, session)
                navigate(fromPathName, { state: fromState, replace: true })
            } else {
                console.error("APIはOKだが、userInfoなどが問題 userInfo,session:", userInfo, session)
                const newFm = { ...forms }
                newFm.errorMsg = "API success, but userInfo&session are wrong."
                if (newFm.wait) newFm.wait = false
                setForms(newFm)
            }
        } else {
            // authStatus.status === LoginStatusType.Idle の場合（未送信時）
            console.log("ログイン画面を出す。authStatus, timedOut:", authStatus, timedOut)
        }
    }, [authStatus, navigate, fromPathName, userInfo, session, forms, timedOut, fromState])

	const phPassword = t("password")
    if (isProduction) {
        // 本番用
        return (
            <div className={style.main}>
                <div className={style.background}>
                    <div className={style.empty_row}></div>
                    <div className={style.panel}>
                        <Header size="small"/>
                        <div className={style.subpanel}>
                            <div className={style.title}>{t("header.signin")}</div>
                            <form className={style.form}>
                                <div className={style.label}>ID</div>
                                <input type="text" className={style.frm_ctrl} placeholder="Email" onChange={(e) => handleChangeId(e)} onKeyDown={handleSubmit}></input>
                                <div className={style.label}>Password</div>
                                <input type="password" className={style.frm_pw} placeholder={phPassword} onChange={(e) => handleChangePw(e)} onKeyDown={handleSubmit}></input>
                                <div className={style.empty}></div>
                                <div className={style.startBtn}>
                                    <Button styleType={StyleType.Normal} name="startButton" label="logIn" onClick={() => submit()}/>
                                </div>
                                {(!forms.errorMsg)
                                    ? (
                                    <div className={style.noError}>&nbsp;</div>
                                    )
                                    : (
                                    <div className={style.hasError}>{forms.errorMsg}</div>
                                    )
                                }
                            </form>
                            <div className={style.bottom}>
                                <div className={style.pw_forget}><a href="/#/forgot">{t("forgotPassword")}</a></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <Footer withPrivacyPolicy={true} />
                    </div>
                    <OverlaySpinner waiting={forms.wait} spinerStyle={SpinerStyleType.ForLogin} />
                </div>
            </div>
        )
    } else {
        // 本番以外の開発向け（リンクなどが表示状態になっている）
        return (
            <div className={style.main}>
                <div className={style.background}>
                    <div className={style.empty_row}></div>
                    <div className={style.panel}>
                        <Header size="small"/>
                        <div className={style.subpanel}>
                            <div className={style.title}>{t("header.signin")}</div>
                            <form className={style.form}>
                                <div className={style.label}>ID</div>
                                <input type="text" className={style.frm_ctrl} placeholder="Email" onChange={(e) => handleChangeId(e)} onKeyDown={handleSubmit}></input>
                                <div className={style.label}>Password</div>
                                <input type="password" className={style.frm_pw} placeholder={phPassword} onChange={(e) => handleChangePw(e)} onKeyDown={handleSubmit}></input>
                                <div className={style.empty}></div>
                                <div className={style.startBtn}>
                                    <Button styleType={StyleType.Normal} name="startButton" label="logIn" onClick={() => submit()}/>
                                </div>
                                {(!forms.errorMsg)
                                    ? (
                                    <div className={style.noError}>&nbsp;</div>
                                    )
                                    : (
                                    <div className={style.hasError}>{forms.errorMsg}</div>
                                    )
                                }
                            </form>
                            <div className={style.bottom}>
                                <div className={style.pw_forget}><a href="/#/forgot">{t("forgotPassword")}</a></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <Footer withPrivacyPolicy={true}  />
                    </div>
                    <OverlaySpinner waiting={forms.wait} spinerStyle={SpinerStyleType.ForLogin} />
                </div>
            </div>
        )
    }
}