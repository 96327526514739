import axios, { Axios, AxiosResponse, AxiosRequestConfig } from "axios";
import { APIError } from "../../APIError";
import { IShop, ResCompany, ResGroup, ResLayoutSet, ResLoginInfo, ResPartialLayout, ResShop, ResUser } from "../../data/login/LoginInfo";
import { BaseInfoEditAPI } from "../../api_interface/edit/edit_base_info";
import { ReqCompany, ReqNewUser, ReqUpdateUser, ReqShop } from "../../data/edit/Req";
import { ReqLayout, ResAreaSet, ResCompAreaSet, ResLayout, ResLineSet } from "../../data/analysis/FullLayout";
import { ReqAreaDef } from "../../data/edit/ReqAreaDefs";
import { ReqLineDef } from "../../data/edit/ReqLineDef";
import { EpochMsUtil } from "./EpochMsUtil";
import { ConvUtil } from "./ConvUtil";

const axiosOptions: AxiosRequestConfig<any> = {
    "responseType": "json",
    "responseEncoding": "utf8",
    withCredentials: true,
}
export class EditAPI implements BaseInfoEditAPI {
    endpoint: string

    constructor(endpoint?: string) {
        if (endpoint != null) {
            this.endpoint = endpoint
        } else if (process.env.REACT_APP_API_ENDPOINT != null) {
            this.endpoint = process.env.REACT_APP_API_ENDPOINT
        } else {
            throw new APIError("適当なAPIエンドポイントが設定されていない")
        }
    }

    async get_companies(): Promise<ResCompany[]> {
        try {
            const res: AxiosResponse<ResCompany[]> = await axios.get(
                `${this.endpoint}/company`, axiosOptions)
            return res.data.map(datum => EpochMsUtil.toMilliCompany(datum))
        } catch (e: any) {
            throw new APIError("全企業取得時にエラー", e)
        }
    }

    async make_company(req: ReqCompany): Promise<ResCompany> {
        req = EpochMsUtil.toEpochCompany(req)
        try {
            const res: AxiosResponse<ResCompany> = await axios.post(
                `${this.endpoint}/company`, req, axiosOptions)
            return EpochMsUtil.toMilliCompany(res.data)
        } catch (e: any) {
            throw new APIError(`企業作成時にエラー: ${req}`, e)
        }
    }

    async update_company(id: number, req: ReqCompany): Promise<ResCompany> {
        req = EpochMsUtil.toEpochCompany(req)
        try {
            const res: AxiosResponse<ResCompany> = await axios.put(
                `${this.endpoint}/company/${id}`, req, axiosOptions)
            return EpochMsUtil.toMilliCompany(res.data)
        } catch (e: any) {
            throw new APIError(`企業情報更新時にエラー: id=${id}, req=${req}`, e);
        }
    }

    /*
     * ユーザ関連操作
     */

    async get_user_types(): Promise<[number, String][]> {
        interface ResUserType {
            id: number;
            name: string;
            inserttime: string;
        }
        try {
            const res: AxiosResponse<ResUserType[]> = await axios.get(`${this.endpoint}/user_types`, axiosOptions)
            return res.data.map(ut => [ ut.id, ut.name ]);
        } catch(e: any) {
            throw new APIError("ユーザ種別取得中にエラー", e);
        }
    }
    
    async get_users(company_id: number, user_id?: number | undefined, contains_expired?: boolean | undefined): Promise<Map<number, [ResUser, number[]]>> {
        try {
            // URL構築
            let url: string = `${this.endpoint}/user/company/${company_id}`
            let isFirst = true
            if (user_id != null) {
                url += `?user_id=${user_id}`
                isFirst = false
            }
            if (contains_expired != null) {
                url += `${isFirst ? '?' : '&'}contains_expired=${contains_expired}`
            }
            // データ取得
            const res: AxiosResponse<Record<string, { "user": ResUser, "visible_stores": number[] }>> = await axios.get(url, axiosOptions)
            const result: Map<number, [ResUser, number[]]> = new Map<number, [ResUser, number[]]>();
            for (let keyStr in res.data) {
                const key = parseInt(keyStr)
                result.set(key, [EpochMsUtil.toMilliUser(res.data[keyStr].user), res.data[keyStr].visible_stores])
            }
            return result
        } catch (e: any) {
            throw new APIError(
                `ユーザ一覧取得中にエラー: company_id=${company_id}, user_id=${user_id} contains_expired=${contains_expired}`,
                e)
        }
    }
    
    async make_user(company_id: number, user: ReqNewUser): Promise<ResUser> {
        try {
            const res: AxiosResponse<ResUser> = await axios.post(
                `${this.endpoint}/user/company/${company_id}`, user, axiosOptions)
            return EpochMsUtil.toMilliUser(res.data)
        } catch (e: any) {
            throw new APIError(
                `ユーザ作成中にエラー: company_id=${company_id}, user=${user}`,
                e)
        }
    }
    
    async update_user(users: ReqUpdateUser[]): Promise<ResUser[]> {
        try {
            const result: ResUser[] = [];
            for (let user of users) {
                const res: AxiosResponse<ResUser> = await axios.put(
                    `${this.endpoint}/user/${user.user_id}`, user, axiosOptions)
                result.push(EpochMsUtil.toMilliUser(res.data))
            }
            return result;
        } catch (e: any) {
            throw new APIError(`ユーザ情報更新中にエラー: user=${users}`, e)
        }
    }
    
    async stop_user(user_id: number): Promise<null> {
        try {
            await axios.post(
                `${this.endpoint}/user/${user_id}/stop`, {}, axiosOptions)
            return null;
        } catch (e: any) {
            throw new APIError(`ユーザ停止中にエラー: user_id=${user_id}`, e)
        }
    }
    
    async restart_user(user_id: number): Promise<ResUser[]> {
        try {
            const res: AxiosResponse<ResUser> = await axios.post(
                `${this.endpoint}/user/${user_id}/restart`, {}, axiosOptions)
            return [EpochMsUtil.toMilliUser(res.data)];
        } catch (e: any) {
            throw new APIError(`ユーザ再開処理中にエラー: user_id=${user_id}`, e)
        }
    }
    
    async set_user_order(ids: number[]): Promise<ResUser[]> {
        try {
            const res: AxiosResponse<ResUser[]> = await axios.post(
                `${this.endpoint}/user/order`, ids, axiosOptions)
            return res.data.map(v => EpochMsUtil.toMilliUser(v));
        } catch (e: any) {
            throw new APIError(`ユーザ順序指定中にエラー: ${ids}`, e)
        }
    }
    
    async bookmark(user_id: number, shop_ids_list: number[]): Promise<IShop[]> {
        try {
            const res: AxiosResponse<IShop[]> = await axios.post(
                `${this.endpoint}/user/${user_id}/bookmark`, {
                "shop_ids_list": shop_ids_list
            }, axiosOptions)
            return res.data.map(s => EpochMsUtil.toMilliIShop(s))
        } catch (e: any) {
            throw new APIError(
                `ブックマーク更新時にエラー: user_id=${user_id}, shop_ids=${shop_ids_list}`,
                e)
        }
    }

    /*
     * 店操作
     */
    
    async list_shop(company_id: number): Promise<ResShop[]> {
        try {
            const res: AxiosResponse<ResShop[]> = await axios.get(
                `${this.endpoint}/shop/company/${company_id}`, axiosOptions)
            return (res.data) ? res.data.map(v => EpochMsUtil.toMilliShop(v)) : []
        } catch (e: any) {
            throw new APIError(`店一覧取得中にエラー: ${company_id}`, e)
        }
    }

    async make_shop(company_id: number, shop: ReqShop): Promise<ResShop> {
        try {
            const res: AxiosResponse<ResShop> = await axios.post(
                `${this.endpoint}/shop/company/${company_id}`, shop, axiosOptions)
            return EpochMsUtil.toMilliShop(res.data)
        } catch (e: any) {
            throw new APIError(`店舗作成中にエラー: company_id=${company_id}, shop=${shop}`, e)
        }
    }

    async update_shop(id: number, shop: ReqShop): Promise<ResShop> {
        try {
            const res: AxiosResponse<ResShop> = await axios.put(
                `${this.endpoint}/shop/${id}`, shop, axiosOptions)
            return EpochMsUtil.toMilliShop(res.data)
        } catch (e: any) {
            throw new APIError(`店舗情報更新中にエラー: shop_id=${id}, shop=${shop}`, e)
        }
    }
    
    async set_shop_order(ids: number[]): Promise<ResShop[]> {
        try {
            const res: AxiosResponse<ResShop[]> = await axios.post(
                `${this.endpoint}/shop/order`, ids, axiosOptions)
            return res.data.map(v => EpochMsUtil.toMilliShop(v))
        } catch (e: any) {
            throw new APIError(`店舗の順序変更時にエラー: ${ids}`, e)
        }
    }

    /*
     * グループ関連
     */

    async get_groups(company_id: number, contains_deleted?: boolean | undefined): Promise<ResGroup[]> {
        try {
            let url = this.endpoint + `/groups/company/${company_id}`
            if (contains_deleted != null) {
                url += `?contains_deleted=true`
            }
            const groups: AxiosResponse<ResGroup[]> = await axios.get(url, axiosOptions)
            return groups.data.map(v => EpochMsUtil.toMilliGroup(v));
        } catch (e: any) {
            throw new APIError(
                `グループ取得時にエラー: company_id=${company_id}, contains_deleted=${contains_deleted}`,
                e)
        }
    }

    async make_group(company_id: number, name: string): Promise<ResGroup[]> {
        try {
            const res: AxiosResponse<ResGroup[]> = await axios.put(
                `${this.endpoint}/groups/company/${company_id}/${name}`, {}, axiosOptions)
            return res.data.map(v => EpochMsUtil.toMilliGroup(v));
        } catch (e: any) {
            throw new APIError(`グループ作成時にエラー: company_id=${company_id}, name=${name}`, e)
        }
    }

    async update_group(groups: ResGroup[]): Promise<ResGroup[]> {
        try {
            const res: AxiosResponse<ResGroup[]> = await axios.post(
                `${this.endpoint}/groups`, groups, axiosOptions)
            return res.data.map(v => EpochMsUtil.toMilliGroup(v));
        } catch (e: any) {
            throw new APIError(`グループ更新時にエラー: ${groups}`, e)
        }
    }

    async delete_group(group_id: number, force?: boolean | undefined): Promise<null> {
        try {
            await axios.post(
                `${this.endpoint}/groups/${group_id}/stop`, {}, axiosOptions)
            return null
        } catch (e: any) {
            throw new APIError(`グループ削除時にエラー: group_id=${group_id}, force=${force}`, e)
        }
    }

    async restart_group(group_id: number): Promise<ResGroup[]> {
        try {
            const res: AxiosResponse<ResGroup[]> = await axios.post(
                `${this.endpoint}/groups/${group_id}/restart`, {}, axiosOptions)
            return res.data.map(v => EpochMsUtil.toMilliGroup(v));
        } catch (e: any) {
            throw new APIError(`グループ再開処理中にエラー: group_id=${group_id}`, e)
        }
    }

    /*
     * レイアウトセット更新
     */

    async create_layout_set(shop_id: number, name: string): Promise<ResLayoutSet> {
        try {
            const res: AxiosResponse<ResLayoutSet> = await axios.put(
                `${this.endpoint}/shop/${shop_id}/layout-set/create`, {name: name}, axiosOptions)
            return EpochMsUtil.toMilliLayoutSet(res.data)
        } catch (e: any) {
            throw new APIError(`レイアウトセット作成中にエラー: shop_id=${shop_id}, name=${name}`, e)
        }
    }

    async get_layout_set(shop_id: number): Promise<ResLayoutSet[]> {
        try {
            const res: AxiosResponse<ResLayoutSet[]> = await axios.get(
                `${this.endpoint}/shop/${shop_id}/layout-set`, axiosOptions)
            return res.data.map(ls => EpochMsUtil.toMilliLayoutSet(ls))
        } catch (e: any) {
            throw new APIError(`レイアウトセット取得中にエラー: shop_id=${shop_id}`, e)
        }
    }

    async delete_layout_set(layout_set_id: number): Promise<ResLoginInfo> {
        try {
            const res: AxiosResponse<ResLoginInfo> = await axios.delete(
                `${this.endpoint}/shop/0/layout-set/${layout_set_id}`, axiosOptions)
            return EpochMsUtil.toMilliLoginInfo(res.data)
        } catch (e: any) {
            throw new APIError(`レイアウトセット削除中にエラー: layout_set_id=${layout_set_id}`, e)
        }
    }

    async update_layout_set_name(layout_set_id: number, name: string): Promise<ResLayoutSet> {
        try {
            const res: AxiosResponse<ResLayoutSet> = await axios.post(
                `${this.endpoint}/shop/0/layout-set/${layout_set_id}`, {name: name}, axiosOptions)
            return EpochMsUtil.toMilliLayoutSet(res.data)
        } catch (e: any) {
            throw new APIError(`レイアウトセット更新中にエラー: layout_set_id=${layout_set_id}`, e)
        }
    }

    async copy_layout_set(layout_set_id: number): Promise<ResLayoutSet> {
        try {
            const res: AxiosResponse<ResLayoutSet> = await axios.post(
                `${this.endpoint}/shop/0/layout-set/${layout_set_id}/copy`, {}, axiosOptions)
            return EpochMsUtil.toMilliLayoutSet(res.data)
        } catch (e: any) {
            throw new APIError(`レイアウトセットコピー中にエラー: layout_set_id=${layout_set_id}`, e)
        }
    }

    async copy_layout_set_to(source_layout_set_id: number, target_layout_set_id: number): Promise<ResLayoutSet> {
        try {
            const res: AxiosResponse<ResLayoutSet> = await axios.post(
                `${this.endpoint}/shop/0/layout-set/${source_layout_set_id}/copy/${target_layout_set_id}`, {}, axiosOptions)
            return EpochMsUtil.toMilliLayoutSet(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウトセットコピー中にエラー: from=${source_layout_set_id}, to=${target_layout_set_id}`,
                e)
        }
    }

    async set_hot_layout_set(layout_set_id: number): Promise<ResLoginInfo> {
        try {
            const res: AxiosResponse<ResLoginInfo> = await axios.post(
                `${this.endpoint}/shop/0/layout-set/${layout_set_id}/hot`, {}, axiosOptions)
            return EpochMsUtil.toMilliLoginInfo(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウトセットをhotにしようとしてエラー: layout_set_id=${layout_set_id}`, e)
        }
    }

    /*
     * レイアウト更新
     */

    async create_layout(layout_set_id: number, layout_info: ReqLayout): Promise<ResLayout> {
        // startをミリ秒から秒に変換
        layout_info = EpochMsUtil.toEpochFullLayout(layout_info)
        try {
            const res: AxiosResponse<ResLayout> = await axios.post(
                `${this.endpoint}/layout/create/${layout_set_id}`, layout_info, axiosOptions)
            // startを秒からミリ秒に変換
            return EpochMsUtil.toMilliFullLayout(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウト作成中にエラー: layout_set_id=${layout_set_id}, req=${layout_info}`,
                 e)
        }
    }

    async get_layouts(layout_set_id: number): Promise<ResLayout[]> {
        try {
            const res: AxiosResponse<ResLayout[]> = await axios.get(
                `${this.endpoint}/layout/all/${layout_set_id}`, axiosOptions)
            console.log("get_layouts res.data:", res.data)
            if (res.data == null) {
                return []
            }
            return res.data.map(fl => EpochMsUtil.toMilliFullLayout(fl))
        } catch (e: any) {
            throw new APIError(
                `レイアウト取得中にエラー: layout_set_id=${layout_set_id}`,
                 e)
        }
    }

    async update_layout(layout_id: number, layout_info: ReqLayout): Promise<ResLayout> {
        layout_info = EpochMsUtil.toEpochFullLayout(layout_info)
        // 必要ではないエリアセットを空にしてペイロードを小さくする
        layout_info.area_set = []
        try {
            const res: AxiosResponse<ResLayout> = await axios.put(
                `${this.endpoint}/layout/${layout_id}`, layout_info, axiosOptions)
            return EpochMsUtil.toMilliFullLayout(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウト更新中にエラー: layout_set_id=${layout_id}`,
                 e)
        }
    }

    async delete_layout(layout_id: number): Promise<ResLoginInfo> {
        try {
            const res: AxiosResponse<ResLoginInfo> = await axios.delete(
                `${this.endpoint}/layout/${layout_id}`, axiosOptions)
            return EpochMsUtil.toMilliLoginInfo(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウト削除中にエラー: layout_set_id=${layout_id}`,
                 e)
        }
    }

    async copy_layout(layout_id: number, start_date: string): Promise<ResLayout> {
        try {
            const res: AxiosResponse<ResLayout> = await axios.post(
                `${this.endpoint}/layout/${layout_id}/copy/${start_date}`, {}, axiosOptions)
            return EpochMsUtil.toMilliFullLayout(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウトコピー中にエラー: layout_set_id=${layout_id}`,
                 e)
        }
    }

    async copy_layout_to(layout_id: number, target_layout_id: number): Promise<ResLayout> {
        try {
            const res: AxiosResponse<ResLayout> = await axios.post(
                `${this.endpoint}/layout/${layout_id}/copy-to/${target_layout_id}`, {}, axiosOptions)
            return EpochMsUtil.toMilliFullLayout(res.data)
        } catch (e: any) {
            throw new APIError(
                `レイアウトコピー中にエラー: layout_set_id=${layout_id}`,
                 e)
        }
    }

    /*
     * エリア操作。レスポンスのミリ秒操作は基本必要ない
     */

    async make_areaset(layout_id: number, start: string, area_set: ResAreaSet): Promise<ResAreaSet[]> {
        try {
            // エリアセットのデータを圧縮形式に変換
            const compAreaSet: ResCompAreaSet = await ConvUtil.ConvertToCompAreaSet(area_set)
            console.log("make_areaset compAreaSet:", compAreaSet)
            const res: AxiosResponse<ResAreaSet[]> = await axios.post(
                `${this.endpoint}/areaset/create/${layout_id}/${start}`, compAreaSet, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `エリアセット作成中にエラー: layout_id=${layout_id}, start=${start}, defs=${area_set}`,
                 e)
        }
    }

    async update_areaset(layout_id: number, areaset_id: number, start: string, area_set: ResAreaSet): Promise<ResAreaSet> {
        try {
            // エリアセットのデータを圧縮形式に変換
            const compAreaSet: ResCompAreaSet = await ConvUtil.ConvertToCompAreaSet(area_set)
            console.log("update_areaset compAreaSet:", compAreaSet)
            const res: AxiosResponse<ResAreaSet> = await axios.post(
                `${this.endpoint}/areaset/${layout_id}/${areaset_id}/${start}`, compAreaSet, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `エリアセット更新中にエラー: layout_id=${layout_id}, start=${start}, defs=${area_set}`,
                 e)
        }
    }

    async delete_areaset(areaset_id: number): Promise<boolean> {
        try {
            const res: AxiosResponse<boolean> = await axios.delete(
                `${this.endpoint}/areaset/${areaset_id}`, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `エリアセット削除中にエラー: areaset_id=${areaset_id}`,
                 e)
        }
    }

    async make_lineset(layout_id: number, start: string, line_defs: ReqLineDef): Promise<ResLineSet> {
        try {
            const res: AxiosResponse<ResLineSet> = await axios.post(
                `${this.endpoint}/lineset/create/${layout_id}/${start}`, line_defs, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `ラインセット作成中にエラー: layout_id=${layout_id}, start=${start}, defs=${line_defs}`,
                 e)
        }
    }

    async get_linesets(layout_id: number): Promise<ResLineSet[]> {
        try {
            const res: AxiosResponse<ResLineSet[]> = await axios.get(
                `${this.endpoint}/lineset/${layout_id}`, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `ラインセット取得中にエラー: layout_id=${layout_id}`,
                 e)
        }
    }

    async update_lineset(layout_id: number, lineset_id: number, start: string, line_defs: ReqLineDef): Promise<ResLineSet> {
        try {
            const res: AxiosResponse<ResLineSet> = await axios.put(
                `${this.endpoint}/lineset/${layout_id}/${lineset_id}/${start}`, line_defs, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `ラインセット作成中にエラー: layout_id=${layout_id}, start=${start}, defs=${line_defs}`,
                 e)
        }
    }

    async delete_lineset(lineset_id: number): Promise<boolean> {
        try {
            const res: AxiosResponse<boolean> = await axios.delete(
                `${this.endpoint}/lineset/${lineset_id}`, axiosOptions)
            return res.data
        } catch (e: any) {
            throw new APIError(
                `ラインセット削除中にエラー: lineset_id=${lineset_id}`,
                 e)
        }
    }
}
