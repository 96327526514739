import React, { useMemo, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { EditModeType, ShopModel, UrlParameterType } from "../../types/Management"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { ModalFrame } from "../common/ModalFrame"
import { timezoneForOptions } from "../../lib/Timezone"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import DateUtil from "../../lib/DateUtil"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./ShopEdit.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {}

export const ShopEdit: React.FC<Props> = (props) => {
	const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { companyId, shopList, saveShop, deleteShop } = useManagementDataContext()

    const { ableToCreate4Shop, ableToRead4Shop, ableToUpdate4Shop, ableToDelete4Shop } = useAccessControlContext()

    // パラメーター
    const search = useLocation().search
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => { return queryParams.get(UrlParameterType.Mode) }, [queryParams])
    const shopId = useMemo(() => {return queryParams.get(UrlParameterType.ShopId) }, [queryParams])

    const [shopName, setShopName] = useState<string>("")
    const [errShopName, setErrShopName] = useState<string>("")
    const [timezoneMinute, setTimezoneMinute] = useState<number | undefined>(undefined)
    const [startDateNum, setStartDateNum] = useState<number | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const ref = React.useRef<HTMLDivElement>(null)

    // ユーザー権限
    const ableView = useMemo(() => { return (shopId) ? ableToRead4Shop(parseInt(shopId)) : false }, [ableToRead4Shop, shopId])
    const ableNew = useMemo(() => { return ableToCreate4Shop() }, [ableToCreate4Shop])
    const ableEdit = useMemo(() => { return (shopId) ? ableToUpdate4Shop(parseInt(shopId)) : false }, [ableToUpdate4Shop, shopId])
    const ableDelete = useMemo(() => { return (shopId) ? ableToDelete4Shop(parseInt(shopId)) : false }, [ableToDelete4Shop, shopId])    
    const canChange = useMemo(() => {return ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit)) }, [mode, ableNew, ableEdit])

    /**
     * 代表的なタイムゾーン値（i18nのロケールから求めている）
     * 例）jaなら540、enなら0
     *
    const typicalTimeZoneMinute = useMemo(() => {
        const tz = t("typicalTimeZoneMinute")
        return parseInt(tz)
    }, [t])*/

    /**
     * 選択された店舗情報
     */
    const shop = useMemo(() => {
        if (shopId) {
            const id = parseInt(shopId)
            const s = shopList.find(el => el.shop_id === id)
            if (s) return s
        }
        return undefined
    }, [shopId, shopList])

    // 初期表示設定
    useEffect(() => {
        if (mode && mode === EditModeType.New) {
            // 新規登録時
            const now = (new Date()).getTime()
            if (timezoneMinute === undefined) setTimezoneMinute(0)
            if (startDateNum === undefined) setStartDateNum(now)
        } else {
            // 編集時
            if (shop) {
                if (shopName === "") setShopName(shop.name)
                if (timezoneMinute === undefined) setTimezoneMinute(shop.timezone)
                if (startDateNum === undefined) {
                    // UTCからlocal timeへ
                    //const localStart = shop.start + typicalTimeZoneMinute * 60000
                    setStartDateNum(shop.start)
                }
            }
        }
    }, [mode, shop, shopName, timezoneMinute, startDateNum])

    // レイアウトがあるかどうか
    const hasLayout = useMemo(() => {
        if (shop) {
            if (shop.datasource_list.length > 0) {
                for (let layoutSet of shop.datasource_list) {
                    if (layoutSet.layout_list.length > 0) {
                        for (let layout of layoutSet.layout_list) {
                            // layout_id = 0 が存在するので要注意！
                            if (layout.layout_id > 0) return true
                        }
                    }
                }
            }
        }
        return false
    }, [shop])

    /**
     * クローズボタン押下時の処理
     */
    const handleClose = () => {
        navigate("/shop_list")
    }

    /**
     * キャンセルボタン押下時の処理
     */
    const handleCancel = () => {
        navigate("/shop_list")
    }

    /**
     * 入力チェック
     * @returns 
     */
    const validationCheck = () => {
        if (shopName && shopName.length > 0) {
            return true
        } else {
            // エラーを出す
            const msg = t("validation.errInputShopName")
            setErrShopName(msg)
            return false
        }
    }

    /**
     * 保存ボタン押下時の処理
     */
    const handleSave = () => {
        if (validationCheck()) {
            if (companyId) {
                // 新規登録時はID=0
                const id = (shopId) ? parseInt(shopId) : 0

                // 入力されたTZをつかってLocalTimeからUTCへ
                const tz = timezoneMinute === undefined ? 0 : timezoneMinute
                const now = (new Date()).getTime() - tz * 60000
                const startUtc = (startDateNum ? (startDateNum - tz * 60000) : now)

                const newShop: ShopModel = {
                    shop_id: id,
                    name: shopName,
                    start: startUtc,
                    timezone: tz,
                    created_at: now,
                    modified_at: now,
                    datasource_list: []
                }
                saveShop(companyId, newShop)
                navigate("/shop_list")
            }
        }
    }

    /**
     * 店舗の削除ボタン押下時の処理、削除確認モーダルを開く
     */
    const handleConfirmDelete = (event: React.MouseEvent) => {
        event.preventDefault()
        if (ableDelete) setDialogOpen(true)
    }
    
    /**
     * 店舗の削除確認ダイアローグでOK
     * @param event 
     */
    const handleConfirmDeleteAccepted = () => {
        if (!ableDelete) return
        setDialogOpen(false)
        if (shop) deleteShop(shop.shop_id)
        navigate("/shop_list")
    }
    
    /**
     * 店舗の削除確認ダイアローグでCancel
     * @param event 
     */
    const handleConfirmDeleteCanceled = () => {
        setDialogOpen(false)
    }

    // 店舗名変更時の処理
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShopName(event.target.value)
        if (errShopName) {
            if (event.target.value.length > 0) setErrShopName("")
        }
    }

    /**
     * タイムゾーン選択の変更時の処理
     * @param event 
     */
    const handleChangeTimezone = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const n = parseInt(v)
        console.log("timezoneMinute set n:", n)
        setTimezoneMinute(n)
    }

    /**
     * 開始日の変更時の処理
     * @param event 
     */
    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        if (event) {
            const n = (event as Date).getTime()
            setStartDateNum(n)
        }
    }

    /**
     * 開始日（Date型）
     */
    const startDate = useMemo(() => {
        if (startDateNum) {
            const dt = new Date(startDateNum)
            if (dt) return dt
        }
    }, [startDateNum])

    /**
     * タイムゾーン選択用Option配列
     */
    const timezoneOptions = useMemo(() => {
        const result = []
        for (let obj of timezoneForOptions) {
            const lbl = obj.label
            const val = obj.value
            const k = val * 60
            const itm = (<option key={k} value={k}>{lbl}</option>)
            result.push(itm)
        }
        return result
    }, [])

    /**
     * 画面タイトル文字
     */
    const strTitle = useMemo(() => {
        if (mode && mode === EditModeType.New) {
            return t("header.storeNewEntry")
        } else if (mode && mode === EditModeType.Edit) {
            return t("header.storeEdit")
        } else {
            return t("header.storeDetails")
        }
    }, [mode, t])

    /**
     * 店舗ID
     */
    const strShopId = useMemo(() => {
        if (mode && mode === EditModeType.New) {
            return t("auto")
        } else {
            if (shop) {
                return shop.shop_id
            }
        }
        return "-"
    }, [mode, shop, t])

    // 保持しているデータソース数
    const dataSourceCount = useMemo(() => {
        if (shop) {
            return shop.datasource_list.map(el => { return el.layout_set_id }).reduce((prev, curr) => ((curr === 0 || curr === undefined || curr == null) ? prev : prev + 1), 0)
        } else {
            return 0
        }
    }, [shop])

    const strPlaceholder = useMemo(() => {return t("msgInputShopName")}, [t])

    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    /**
     * レコード登録日時
     */
    const createdAt = useMemo(() => {
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View)) {
            if (shop) return DateUtil.epochtime2LocalYmdSlashHms(shop.created_at, shop.timezone)
        }
        // 新規登録時はデータがない
        return "-"
    }, [mode, shop])

    /**
     * レコード更新日時
     */
    const modifiedAt = useMemo(() => {
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View)) {
            if (shop) return DateUtil.epochtime2LocalYmdSlashHms(shop.modified_at, shop.timezone)
        }
        // 新規登録時はデータがない
        return "-"
    }, [mode, shop])

    return (
        <ModalFrame page={PageName.InfoShop} title={t("header.storeRegistrationEdit")} width={930} height={480}>
            <div className={style.main}>
                <div className={style.head}>
                    {isProduction ? (<TrackingPageView page_title="bsinfo-shop-edit" />) : (null)}
                    <div className={style.title}>{strTitle}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose}/></div>
                </div>
                {
                    ableView || ableNew ? (
                        <div className={style.panel}>
                            <div className={style.left}>
                                <table className={style.table}>
                                    <tbody>
                                        <tr>
                                            <td className={style.dtTitle}>{t("storeId")}</td>
                                            <td className={style.dtData}>{strShopId}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("storeName")}</td>
                                            <td className={style.dtInput}>
                                                <input type="text" value={shopName} onChange={handleChangeName} placeholder={strPlaceholder} disabled={!canChange} />
                                                {
                                                    (errShopName) ? (<p><span className={style.errorMessage}>{errShopName}</span></p>) : (null)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("startDate")}</td>
                                            <td className={style.dtData}>
                                                <div className={style.startDate}>
                                                    <FupDatepicker
                                                        type={DpType.Micro}
                                                        onChange={handleChangeStartDate}
                                                        divRef={ref}
                                                        selected={startDate}
                                                        locale={i18n.language}
                                                        dateFormat={datepickFormat}
                                                        dateFormatCalendar={datepickFormatCalendar}
                                                        disabled={!canChange}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("timeZone")}</td>
                                            <td className={style.dtData}>
                                                <select className={style.timezone} value={timezoneMinute} onChange={handleChangeTimezone} disabled={!canChange}>
                                                    {timezoneOptions}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("registrationDate")}</td>
                                            <td className={style.dtData}>{createdAt}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("modifiedDate")}</td>
                                            <td className={style.dtData}>{modifiedAt}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("mapsRegistered")}</td>
                                            <td className={style.dtData}>{dataSourceCount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={style.right}>
                                <div className={style.rightTop}>
                                    {
                                        (canChange) ? (<Button name="cancel" label="cancel" styleType={StyleType.Reverse} onClick={handleCancel} />) : (<></>)
                                    }
                                    <div className={style.btnSpace}></div>
                                    {
                                        (canChange) ? (<Button name="save" label="save" styleType={StyleType.Normal} onClick={handleSave} />) : (<></>)
                                    }
                                </div>
                                <div className={style.rightMid}>

                                </div>
                                <div className={style.rightBtm}>
                                    {
                                        (mode === EditModeType.Edit && ableDelete && !hasLayout) ? (<Button name="delete" label={t("deleteStore") + "　"} styleType={StyleType.DangerMini} onClick={handleConfirmDelete} />) : (null)
                                    }
                                </div>
                            </div>
                        </div>                    
                    ) : (<></>)
                }
            </div>
            <DeleteDialog
                paneSize={PaneSizeType.forShopEdit}
                requestOpen={dialogOpen}
                guideMessage={t("msgDeleteStore")}
                onCancel={handleConfirmDeleteCanceled}
                onDelete={handleConfirmDeleteAccepted}
            >
                <span className={style.confirm}>{t("storeName")}：{shop?.name}</span>
            </DeleteDialog>
        </ModalFrame>
    )
}