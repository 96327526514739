import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import { format, set } from "date-fns"

import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { AnalyzeParametersType } from "../../types/Analyze"
import { ContentsDistributor, MainCompoProps, PanelDesignType } from "./ContentsDistributor"
import { SeriesSwitcher, SwitcherFontType, BtnProp } from "../../component/series_switcher/SeriesSwitcher"
import { AnalyzeViewType, AnalysisParameterSetType } from "../../types/Analyze"
import { DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT } from "../../constants"
import { TrailMapDetail, DetailDataType } from "./TrailMapDetail"
import { useAuthUserContext } from "../../providers"
import { useMouseMove } from "../../lib/useMouseMove"
import { TrailMapCustomerList } from "./TrailMapCustomerList"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { ResLoginInfo } from "../../api/data/login/LoginInfo"
import { CustomerForTrail } from "../../api/data/analysis/AnalysisResult"
import { TxStatusType } from "../../types"
import TrailMapWriter from "../../lib/TrailMapWriter"
import DateUtil from "../../lib/DateUtil"
import Utils from "../../lib/Utils"
import { AreaInfoObject, FilterObject, TimeSpanObject } from "./TrailMapFilterSelector"
import { SortStatus, SortStatusType } from "../../component/list_sort_button/ListSortButton"
import { CheckedState, Checked } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import TrailWorker from "../../lib/TrailWorker"
import { RANKING_TOP_100 } from "../../api/data/core/Constants"
import { SearchSortOption, SortOrders, SortItems, SortItemType, SortOrderType } from "../../api/data/analysis/AnalysisRequest"
import { Coord } from "../../api/data/core/Coord"
import { ListViewModeType, TrailMapList } from "./TrailMapList"

import style from "./TrailMap.module.css"


// １ページに表示する顧客ID数デフォルト
export const CUSTOMER_PER_PAGE = 20

// １ページに表示する数の選択肢
export const LineNumberOfPage = [20, 50, 100]


const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

export const RankingType = {
    StayTime: "st",
    StayArea: "sa",
    BuyCount: "bc",
    BuyAmount: "ba",
    All: "al"
} as const
export type Ranking = typeof RankingType[keyof typeof RankingType]

export const SortColumnsType = {
    id: "id",
    startTime: "startTime",
    endTime: "endTime",
    stayTime: "stayTime",
    stayArea: "stayArea",
    buyOrNot: "buyOrNot"
} as const
export type SortColumns = typeof SortColumnsType[keyof typeof SortColumnsType]

export type SortingType = {
    id: SortStatus
    startTime: SortStatus
    endTime: SortStatus
    stayTime: SortStatus
    stayArea: SortStatus
    buyOrNot: SortStatus
}

export const DefaultSortingALL = {
    id: SortStatusType.Descending,
    startTime: SortStatusType.Release,
    endTime: SortStatusType.Release,
    stayTime: SortStatusType.Release,
    stayArea: SortStatusType.Release,
    buyOrNot: SortStatusType.Release
}

export const DefaultSortingStayTime = {
    id: SortStatusType.Release,
    startTime: SortStatusType.Release,
    endTime: SortStatusType.Release,
    stayTime: SortStatusType.Descending,
    stayArea: SortStatusType.Release,
    buyOrNot: SortStatusType.Release
}

export const DefaultSortingStayArea = {
    id: SortStatusType.Release,
    startTime: SortStatusType.Release,
    endTime: SortStatusType.Release,
    stayTime: SortStatusType.Release,
    stayArea: SortStatusType.Descending,
    buyOrNot: SortStatusType.Release
}

type SwitchItemType = {
    ranking: Ranking
    labelKeyword: string    // translate用キーワード
    labelAddTail: string    // 上の変換後文字列の後ろにつける文字列
    isMockOnly: boolean     // trueはMockで表示するが本番では非表示、falseは本番,Mock両方で表示
}

const SwitchItemList: SwitchItemType[] = [
    { ranking: RankingType.StayTime, labelKeyword: "topStayingTime", labelAddTail: "Top" + RANKING_TOP_100, isMockOnly: false },
    { ranking: RankingType.StayArea, labelKeyword: "topAreasToStay", labelAddTail: "Top" + RANKING_TOP_100, isMockOnly: false },
    { ranking: RankingType.BuyCount, labelKeyword: "topItemsPurchased", labelAddTail: "Top" + RANKING_TOP_100, isMockOnly: true },
    { ranking: RankingType.BuyAmount, labelKeyword: "topPurchasePrice", labelAddTail: "Top" + RANKING_TOP_100, isMockOnly: true },
    { ranking: RankingType.All, labelKeyword: "", labelAddTail: "ALL", isMockOnly: false }
]

/**
 * DummyTime（Hour*100+min）の時間帯を表示用(HH:mm)の時間帯にします。
 * ・検索条件のStartTime～EndTimeが下限～上限となる。
 * @param st 検索条件で指定されたStartTime
 * @param ed 検索条件で指定されたEndTime
 * @returns 
 */
const makeTimeSpan = (st: number | undefined, ed: number | undefined): TimeSpanObject => {
    if (st && ed) {
        const startTime = Utils.decodeDummyTime2Time(st)
        const endTime = Utils.decodeDummyTime2Time(ed)
        return {
            start: (startTime) ? startTime : "00:00",
            end: (endTime) ? endTime : "23:59",
            min: (startTime) ? startTime : "00:00",
            max: (endTime) ? endTime : "23:59",
        }
    }
    return { start: "00:00", end: "23:59" }
}

/**
 * 絞り込みフィルター用のエリア情報を作成します。
 * @param areaList 
 * @returns 
 */
const makeAreaList4Filter = (areaList: ResArea[] | null | undefined): AreaInfoObject[] => {
    const result: AreaInfoObject[] = []
    if (areaList) {
        areaList.forEach(el => {
            const itm = {
                id: el.area_id,
                name: el.area_number + "_" + el.name,
                checked: true
            }
            result.push(itm)
        })
    }
    return result
}

const mkDownloadHeader = async (layout: ResLayout, searches: AnalysisParameterSetType | undefined, userInfo: ResLoginInfo | null | undefined): Promise<string> => {
    let result = ""
    if (searches && userInfo) {
        result += "# ----------------------------------------\n"
        result += i18n.t("dlHeader.exportReport") + "\n"
        result += i18n.t("dlHeader.account") + userInfo.user.name + '\n'
        result += "# ----------------------------------------\n"
        const shop = userInfo.shops.find(el => el.shop_id === searches.shopId)
        if (shop) {
            result += i18n.t("dlHeader.storeName") + shop.name + " (" + shop.shop_id + ")\n"
        }
        result += i18n.t("dlHeader.map") + layout.name + " (" + layout.layout_id + ")\n"
        if (searches.startDate && searches.endDate) {
            result += i18n.t("dlHeader.date") + format(new Date(searches.startDate), i18n.t("dateFormat.ymd")) + "～" + format(new Date(searches.endDate), i18n.t("dateFormat.ymd")) + '\n'
        }
        if (searches.startTime && searches.endTime) {
            result += i18n.t("dlHeader.hour") + DateUtil.numTime2StrTime(searches.startTime) + "～" + DateUtil.numTime2StrTime(searches.endTime) + '\n'
        }
        result += i18n.t("dlHeader.dayOfWeek") + DateUtil.getWeekdaysText(searches.weekday) + '\n'
        result += i18n.t("dlHeader.flag")
        const buy = searches.buyOrNot[0]
        const notBuy = searches.buyOrNot[1]
        if (buy && !notBuy) {
            result += i18n.t("dlHeader.purchaser")
        } else if (!buy && notBuy) {
            result += i18n.t("dlHeader.nonPurchaser")
        } else {
            result += i18n.t("dlHeader.purchaserAndNonPurchaser")
        }
        if (searches.excludeClerk) {
            result += i18n.t("dlHeader.excludeStaff")
        } else {
            result += i18n.t("dlHeader.includeStaff")
        }
        result += '\n'
        result += i18n.t("dlHeader.areaDwellTimeThreshold") + Utils.formatSecToJikan(searches.threshold) + '\n\n'
    }
    return result
}

export type DetailType = {
    show: boolean
    data: DetailDataType
}

export const TrailMapCore: React.FC<MainCompoProps> = (props) => {

	const { t } = useTranslation()
    const { trailStatus, fetchTrail } = useAnalyticsDataContext()
    const { userInfo } = useAuthUserContext()

    const SwitchItems = useMemo(() => {
        const result: BtnProp[] = []
        for (let itm of SwitchItemList) {
            if (!itm.isMockOnly || (mockOn && itm.isMockOnly)) {
                const lb = (itm.labelKeyword) ? t(itm.labelKeyword) : ""
                const bp = { name: itm.ranking.toString(), label: lb + itm.labelAddTail }
                result.push(bp)
            }
        }
        return result
    }, [t])
    
    const [selectedRank, setSelectedRank] = useState<BtnProp | undefined>(() => { return SwitchItems.length > 0 ? SwitchItems[0] : undefined })
    const [customers, setCustomers] = useState<CustomerForTrail[] | undefined>(undefined)
    const [customerTotal, setCustomerTotal] = useState<number>(0)
    const [detail, setDetail] = useState<DetailType | undefined>(undefined)
    const [drawIdList, setDrawIdList] = useState<number[]>([])                              // 描画対象の顧客IDリスト
    const [downloadHeader, setDownloadHeader] = useState<string | undefined>(undefined)
    const [writer, setWriter] = useState<TrailMapWriter | undefined>(undefined)
    const [worker, setWorker] = useState<TrailWorker | undefined>(undefined)
    const [filter, setFilter] = useState<FilterObject>({
        isActive: false,
        enableTimeSpan: true,
        timeSpan: makeTimeSpan(props.request?.startTime, props.request?.endTime),
        enableThresholdTime: false,
        thresholdTime: { lower: 0, upper: undefined },
        areaBulk: CheckedState.Full,
        enableAreaList: false,
        areaList: makeAreaList4Filter((props.data && props.data.layout && props.data.layout.area_set && props.data.layout.area_set.length > 0) ? props.data.layout.area_set[0].area_defs : undefined),
        //enableBuyOrNot: false,
        //buyOrNot: [true, true],
    })
    const [sorting, setSorting] = useState<SortingType>(DefaultSortingStayTime)
    const [bulkCheckbox, setBulkCheckbox] = useState<Checked>(CheckedState.None)                        // チェックボックスの全選択状態
    const [checkedList, setCheckedList] = useState<Record<number, boolean> | undefined>(undefined)      // チェックボックスの状態
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [isBusyLoadingTrailLine, setIsBusyLoadingTrailLine] = useState<boolean>(false)        // プログレスバー表示中はtrue, この時は画面操作Cancelとする
    const [isLoadingCustomerList, setIsLoadingCustomerList] = useState<boolean>(false)    // 詳細データ取得中はtrue
    const [mouseHitList, setMouseHitList] = useState<number[]>([])
    const [linesOfPage, setLinesOfPage] = useState<number>(CUSTOMER_PER_PAGE)
    
    const ref = useRef<HTMLCanvasElement>(null)
    const bgImgRef = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const refFirst = useRef<boolean>(true)
    const refDonePrimaryOneTrail = useRef<boolean>(false)   // 初期表示のタイミングで動線ラインを１本だけ描画するためのフラグ
    const refDonwSecondaryOneTrail = useRef<boolean>(false) // ２回目の初期表示のタイミングで動線ラインを１本だけ描画するためのフラグ

    /**
     * 個別分析画面かどうか
     */
    const isSingle = useMemo(() => { return (props.view === AnalyzeViewType.Single) ? true : false }, [props.view])
   
    /**
     * Top100/ALLスイッチ切替
     * @param event 
     */
    const changeRanking: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (!isBusyLoadingTrailLine) {
            const itm: BtnProp | undefined = SwitchItems.find(el => el.name === nm)
            if (itm) {
                // SORTの初期化
                if (itm.name === RankingType.StayTime) {
                    setSorting(DefaultSortingStayTime)
                } else if (itm.name === RankingType.StayArea) {
                    setSorting(DefaultSortingStayArea)
                } else {
                    setSorting(DefaultSortingALL)
                }
                setSelectedRank(itm)
                setCurrentPage(0)
            }
        }
    }

    /**
     * 絞り込みフィルターの変更
     * @param newFilter 
     */
    const handleChangeFilter = (newFilter: FilterObject | undefined) => {
        if (!isBusyLoadingTrailLine) {
            if (newFilter) setFilter(newFilter)
        }
    }

    /**
     * 全顧客リスト取得用の並び替えオプションを作成します。
     */
    const searchSortOption = useMemo(() => {
        let sortItm: SortItems = SortItemType.HumanId
        let sortOrder: SortOrders = SortOrderType.Asc
        if (sorting.id === SortStatusType.Ascending) {
            sortItm = SortItemType.HumanId
            sortOrder = SortOrderType.Asc
        } else if (sorting.id === SortStatusType.Descending) {
            sortItm = SortItemType.HumanId
            sortOrder = SortOrderType.Desc
        } else if (sorting.startTime === SortStatusType.Ascending) {
            sortItm = SortItemType.EnterShopTime
            sortOrder = SortOrderType.Asc
        } else if (sorting.startTime === SortStatusType.Descending) {
            sortItm = SortItemType.EnterShopTime
            sortOrder = SortOrderType.Desc
        } else if (sorting.endTime === SortStatusType.Ascending) {
            sortItm = SortItemType.ExitShopTime
            sortOrder = SortOrderType.Asc
        } else if (sorting.endTime === SortStatusType.Descending) {
            sortItm = SortItemType.ExitShopTime
            sortOrder = SortOrderType.Desc
        } else if (sorting.stayTime === SortStatusType.Ascending) {
            sortItm = SortItemType.StayShopTime
            sortOrder = SortOrderType.Asc
        } else if (sorting.stayTime === SortStatusType.Descending) {
            sortItm = SortItemType.StayShopTime
            sortOrder = SortOrderType.Desc
        } else if (sorting.stayArea === SortStatusType.Ascending) {
            sortItm = SortItemType.NumberOfStayArea
            sortOrder = SortOrderType.Asc
        } else if (sorting.stayArea === SortStatusType.Descending) {
            sortItm = SortItemType.NumberOfStayArea
            sortOrder = SortOrderType.Desc
        } else if (sorting.buyOrNot === SortStatusType.Ascending) {
            sortItm = SortItemType.BuyOrNot
            sortOrder = SortOrderType.Asc
        } else if (sorting.buyOrNot === SortStatusType.Descending) {
            sortItm = SortItemType.BuyOrNot
            sortOrder = SortOrderType.Desc
        }
        let option: SearchSortOption = {
            from: currentPage * linesOfPage,
            size: linesOfPage,
            sortItem: sortItm,
            sortOrder: sortOrder,
        }
        if (filter && filter.isActive) {
            if (filter.enableTimeSpan) {
                option.enterShopTimeFrom = Utils.parseTimeAsDummyTime(filter.timeSpan.start)
                option.enterShopTimeTo = Utils.parseTimeAsDummyTime(filter.timeSpan.end)
            }
            if (filter.enableThresholdTime) {
                option.stayShopTimeFrom = filter.thresholdTime.lower
                option.stayShopTimeTo = filter.thresholdTime.upper
            }
            if (filter.enableAreaList) {
                option.enteredAreaList = filter.areaList.filter(el => el.checked).map(el => { return el.id })
            }
        }
        return option
    }, [filter, sorting, linesOfPage, currentPage])

    /**
     * 並べ替え条件が変更されたときの処理
     * @param newSorting 
     */
    const handleChangeSorting = (newSorting: SortingType) => {
        if (!isBusyLoadingTrailLine) {
            if (selectedRank && selectedRank.name === RankingType.All) {
                if (newSorting.stayArea !== SortStatusType.Release) {
                    // ALLの場合は、滞在エリア数の並び替えは解除する（OpenSearcch側の仕様）
                    newSorting.stayArea = SortStatusType.Release
                }
            }
            setSorting(newSorting)
        }
    }

    /**
     * チェックボックスの変更処理（子コンポーネントから）
     * @param newCheckList 
     */
    const handleChangeCheckedList = (newCheckList: Record<number, boolean>) => {
        if (!isBusyLoadingTrailLine) {
            if (refDonePrimaryOneTrail.current === false) {
                // 初期表示のタイミングで動線ラインを１本だけ描画する
                const keys = Object.keys(newCheckList)
                //console.log("初期表示のタイミングで動線ラインを１本だけ描画 keys:", keys)
                if (keys.length > 0) {
                    const id = parseInt(keys[0])
                    newCheckList[id] = true
                    refDonePrimaryOneTrail.current = true
                    //console.log("初期表示のタイミングで動線ラインを１本だけ描画 newCheckList:", newCheckList)
                    const drawList = [id]
                    setDrawIdList(drawList)
                    //console.log("初期表示のタイミングで・・・ drawList:", drawList)
                }
            } else if (refDonwSecondaryOneTrail.current === false) {
                const keys = Object.keys(newCheckList)
                //console.log("２回目の初期表示のタイミングで動線ラインを１本だけ描画 keys:", keys)
                if (keys.length > 0) {
                    const id = parseInt(keys[0])
                    newCheckList[id] = true
                    refDonwSecondaryOneTrail.current = true
                    //console.log("２回目の初期表示のタイミングで動線ラインを１本だけ描画 newCheckList:", newCheckList)
                    const drawList = [id]
                    setDrawIdList(drawList)
                    //console.log("２回目の初期表示のタイミングで・・・ drawList:", drawList)
                }
            }
            setCheckedList(newCheckList)
        }
    }

    /**
     * カレントページの変更処理（子コンポーネントから）
     * @param newPage 
     */
    const handleChangeCurrentPage = (newPage: number) => {
        if (!isBusyLoadingTrailLine) {
            setCurrentPage(newPage)
            // ページに合わせて動線ラインを描画

        }
    }

    /**
     * 顧客詳細画面(Modal)の非表示処理
     */
    const handleDetailHide = () => {
        if (detail && detail.show) {
            const newval = { ...detail }
            newval.show = false
            setDetail(newval)
        }
    }

    /**
     * 顧客詳細画面(Modal)の表示処理
     * @param newDetail 
     */
    const handleDetailShow = (newDetail: DetailType) => {
        if (!isBusyLoadingTrailLine) setDetail(newDetail)
    }

    /**
     * 動線ラインのハイライト処理
     * @param onId 
     */
    const handleHighlightTrail = async (onId: number | undefined) => {
        if (!isBusyLoadingTrailLine) {
            if (onId) {
                // IDがあるときは１本だけ描画
                await drawOneTrailLine(onId)
                // 顧客リストの発火処理
                setMouseHitList([onId])
            } else {
                // IDがないときは全動線を再描画
                await drawTrailLines()
                // 顧客リストの沈火処理
                setMouseHitList([])
            }
        }
    }

    /**
     * 子コンポーネントの顧客リストから、動線ラインの描画対象IDリストを受け取る
     * @param newList 
     */
    const handleDrawIdList = useCallback((newList: number[]) => {
        //console.log("handleDrawIdList newList:", newList)
        setDrawIdList(newList)
        // Workerに伝達
        if (worker) worker.updateTraceList(newList)
        // 発火中の行を消す
        setMouseHitList([])
    }, [worker])

    /**
     * 背景マップの描画（Busy設定）
     */
    const drawBackgroundMap = useCallback(async () => {
        //console.log("drawBackgroundMap")
        if (writer) {
            //setIsBusyLoadingTrailLine(true)
            if (bgImgRef && bgImgRef.current) {
                const bgCtx: CanvasRenderingContext2D | null | undefined = bgImgRef.current.getContext("2d")
                if (bgCtx) await writer.drawImageWithArea(bgCtx)
            }
        }
    }, [writer, bgImgRef])

    /**
     * ドラッグ用キャンバスをクリア（Busy解除）
     */
    const clearAnimationCanvas = useCallback(async () => {
        if (writer) {
            if (aniRef && aniRef.current) {
                const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
            }
        }
    }, [writer, aniRef])

    /**
     * チェック選択された動線をまとめて描画
     */
    const drawTrailLines = useCallback(async () => {
        if (trailStatus && trailStatus.result !== TxStatusType.Loading) {
            //console.log("drawTrailLines")
            if (ref && ref.current) {
                const ctx: CanvasRenderingContext2D | null | undefined = ref.current.getContext("2d")
                if (ctx && writer) {
                    await writer.clear(ctx)
                    if (customers && props.data && drawIdList && drawIdList.length > 0) {
                        for await (let id of drawIdList) {
                            const cust = customers.find(c => c.human_id === id)
                            if (cust) {
                                let trailAry: Coord[] | undefined = props.data.trailHash[id]
                                // 描画する
                                if (trailAry) {
                                    if (worker) worker.createMap(cust.human_id)
                                    await writer.drawHighLightOff(ctx, trailAry, cust.is_clerk)
                                }
                            }
                        } 
                    }
                }
            }
        }
    }, [trailStatus, ref, writer, customers, drawIdList, worker, props.data])

    /**
     * 動線１本を描画（ハイライト処理用）
     */
    const drawOneTrailLine = useCallback(async (highlightId: number) => {
        if (trailStatus && trailStatus.result === TxStatusType.Fulfilled) {
            //console.log("drawOneTrailLine")
            if (ref && ref.current) {
                const ctx: CanvasRenderingContext2D | null | undefined = ref.current.getContext("2d")
                if (ctx && writer && customers && props.data) {
                    const cust = customers.find(c => c.human_id === highlightId)
                    if (cust) {
                        let trailAry: Coord[] | undefined = props.data.trailHash[highlightId]
                        // ライン描画
                        if (trailAry) {
                            await writer.drawHighLightOn(ctx, trailAry, cust.is_clerk)
                        }
                    }
                }
            }
        }
    }, [customers, trailStatus, ref, writer, props.data])

    /**
     * マップと動線の全てを再描画
     */
    const refreshMapAndLines = useCallback(async () => {
        // 動線データのローディング中でないことを確認
        if (trailStatus === undefined || (trailStatus && trailStatus.result !== TxStatusType.Loading)) {
            //console.log("マップと動線の全てを再描画 refreshMapAndLines trailStatus:", trailStatus)
            // マップ（busy設定）
            await drawBackgroundMap()
            // 動線
            await drawTrailLines()
            // ドラッグ画像クリア（busy解除）
            await clearAnimationCanvas()
        }
    }, [trailStatus, drawBackgroundMap, drawTrailLines, clearAnimationCanvas])

    /**
     * 動線データ取得の進捗状況表示（busy設定する）
     */
    const drawProgress = useCallback(async () => {
        // 動線データのローディング中を確認
        if (trailStatus && trailStatus.result === TxStatusType.Loading) {
            // Busyフラグを設定して他の操作をキャンセル
            if (!isBusyLoadingTrailLine) setIsBusyLoadingTrailLine(true)
            // プログレスバー描画
            if (ref && ref.current) {
                const ctx: CanvasRenderingContext2D | null | undefined = ref.current.getContext("2d")
                if (ctx && writer) {
                    const val = (trailStatus.progress) ? trailStatus.progress : 0
                    await writer.drawProgress(ctx, t("msgDrawing"), val)
                }
            }
        } else if (trailStatus && trailStatus.result !== TxStatusType.Loading && isBusyLoadingTrailLine) {
            // Busyフラグを解除
            setIsBusyLoadingTrailLine(false)
        }
    }, [trailStatus, ref, writer, t, isBusyLoadingTrailLine])

    /**
     * マップのサイズを戻す処理
     */
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            refreshMapAndLines()
        }
    }

    /**
     * マップ拡大ボタンの処理
     */
    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            refreshMapAndLines()
        }
    }

    /**
     * マップ縮小ボタンの処理
     */
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            refreshMapAndLines()
        }
    }

    /**
     * 顧客IDリストの１ページ表示数変更
     * @param lines 
     */
    const handleChangeLinesOfPage = (lines: number) => {
        if (linesOfPage !== lines) setLinesOfPage(lines)
    }
    
    /**
     * ダウンロード用の顧客データを取得する処理
     * @returns 
     */
    const handleGetDownloadCustomers = async () => {
        if (props.data && selectedRank) {
            let rank: Ranking
            if (selectedRank.name === RankingType.StayTime) {
                rank = RankingType.StayTime
            } else if (selectedRank.name === RankingType.StayArea) {
                rank = RankingType.StayArea
            } else {
                rank = RankingType.All
            }
            const list = await props.data.get_download_customers(rank, searchSortOption)
            return list
        }
        return []
    }

    /**
     * チェックリストの変化から必要な動線点データを取得する処理
     */
    useEffect(() => {
        //console.log("useEffect drawIdList", drawIdList, trailStatus, props.data)
        if (drawIdList.length > 0) {
            // ローディング中は何もしない
            if (trailStatus && trailStatus.result === TxStatusType.Loading) {
                //console.log("useEffect ローディング中はなにもしない trailStatus:", trailStatus)
                return
            }
            // 動線データの不足リスト作成
            const shortageIdList: number[] = []
            drawIdList.forEach(id => {
                // trailHashにあるかどうかチェック
                const trailAry = (props.data) ? props.data.trailHash[id] : undefined
                if (trailAry === undefined) {
                    // なければ不足リストに追加
                    shortageIdList.push(id)
                }
            })
            console.log("不足リスト shortageIdList", shortageIdList)
            // 不足分データ取得
            if (shortageIdList.length > 0) {
                //console.log("useEffect props.request:", props.request)
                const shopId = props.request?.shopId
                if (shopId) {
                    if (!trailStatus || trailStatus.result !== TxStatusType.Loading) {
                        //console.log("fetchTrail")
                        if (props.data) {
                            // 動線ラインデータ一括取得
                            fetchTrail(shopId, shortageIdList, props.data)
                            // ローディング中フラグを立てる
                            setIsBusyLoadingTrailLine(true)
                        }
                    }
                }
            }
        } else {
            console.log("useEffect drawIdList is empty")
        }
    }, [drawIdList, fetchTrail, props, trailStatus])

    // ダウンロードファイルのヘッダー作成
    useEffect(() => {
        if (props.data) {
            if (downloadHeader === undefined && userInfo) {
                mkDownloadHeader(props.data.layout, props.request, userInfo).then(header => {
                    setDownloadHeader(header)
                })
            }
        }
    }, [props.data, props.request, userInfo, downloadHeader])

    // writer初期化
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP render")
                return
            }
        }

        if (props.data) {
            // writer初期化
            if (writer === undefined) {
                //console.log("writer init")
                const area_list = (props.data && props.data.layout && props.data.layout.area_set && props.data.layout.area_set.length > 0) ? props.data?.layout.area_set[0].area_defs : []
                const mapWriter = new TrailMapWriter(props.data.layout, area_list, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT)
                mapWriter.pathDepth = 1
                setWriter(mapWriter)
            } else {
                // 背景マップの描画
                drawBackgroundMap()
            }
            // worker初期化
            if (worker === undefined && props.data.layout && props.data.trailHash) {
                const w = props.data.layout.pixel_width
                const h = props.data.layout.pixel_height
                const x = props.data.layout.origin_x
                const y = props.data.layout.origin_y
                const m = props.data.layout.mm_per_pixel
                const worker = new TrailWorker(props.data.trailHash, w, h, x, y, m)
                setWorker(worker)
            }
        }
    }, [props.data, writer, customers, worker, drawBackgroundMap, refFirst])

    /**
     * マウス移動操作イベント処理
     */
    const mousePress = useMouseMove(aniRef, (event: MouseEvent) => {
        // 画像移動animation
        if (!isBusyLoadingTrailLine && writer) {
            const srcCanvas = ref.current
            const dstCanvas = aniRef.current
            const imgCanvas = bgImgRef.current
            if (srcCanvas && dstCanvas && imgCanvas) writer.mouseMove2(event, mousePress, srcCanvas, dstCanvas, imgCanvas, (camX, camY) => {
                // マウスのライン接触判定処理
                if (worker) worker.hitTest(camX, camY).then(hits => {
                    //console.log("hitTest:", hits)
                    setMouseHitList(hits)
                })
            })
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        if (!isBusyLoadingTrailLine && writer) {
            writer.mouseRelease()
            // 再描画
            refreshMapAndLines()
        }
    }, (event: MouseEvent) => {
        // マウスボタンを押したときの処理
        if (!isBusyLoadingTrailLine && writer) {
            writer.mousePress()
        }
    })

    /**
     * プログレスバー表示処理
     */
    useEffect(() => {
        // ローディング中
        if (isBusyLoadingTrailLine && writer) {
            drawProgress()
        }
    }, [isBusyLoadingTrailLine, drawProgress, writer])

    /**
     * マップ上の動線ラインの上をマウスが移動したときの色付け処理
     */
    useEffect(() => {
        if (!isBusyLoadingTrailLine) {
            if (mouseHitList.length === 0) {
                // 戻す
                drawTrailLines()
            } else {
                // ハイライト処理
                for (let h of mouseHitList) {
                    drawOneTrailLine(h)
                }
            }
        }
    }, [isBusyLoadingTrailLine, mouseHitList, drawTrailLines, drawOneTrailLine])

    /**
     * Top100/Allデータの切替による顧客データの取得処理
     */
    useEffect(() => {
        if (props.data) {
            console.log("データ取得 props.data", props.data)
            const numOfCustomers = props.data.get_num_of_customers()
            const rankName = selectedRank ? selectedRank.name : ""
            if (numOfCustomers === 0) {
                // 顧客データがないとき
                setCustomers([])
                setCustomerTotal(0)
            } else {
                if (rankName === RankingType.StayTime) {
                    setIsLoadingCustomerList(true)
                    props.data.get_customers_who_stayed_long(searchSortOption).then(result => {
                        console.log("get_customers_who_stayed_long result:", result)
                        if (result && result.length >= 0) {
                            setCustomers(result)
                            let numOfCust100 = props.data?.get_num_of_customers_for_top100()
                            setCustomerTotal((numOfCust100 !== undefined && numOfCust100 < RANKING_TOP_100) ? numOfCust100 : RANKING_TOP_100)
                            setCheckedList({})
                            setBulkCheckbox(CheckedState.None)
                            //setDrawIdList([])
                            // workerに顧客データを渡す
                            if (worker && props.data) worker.updateCustoemrList(props.data.trailHash)
                            //if (worker) worker.updateTraceList([])
                            //setMouseHitList([])
                            handleDrawIdList([])
                            setIsLoadingCustomerList(false)    // TrailMapListのスピナー解除
                        }
                
                    })
                } else if (rankName === RankingType.StayArea) {
                    setIsLoadingCustomerList(true)
                    props.data.get_customers_who_wents_most_area(searchSortOption).then(result => {
                        console.log("get_customers_who_wents_most_area result:", result)
                        if (result && result.length >= 0) {
                            setCustomers(result)
                            let numOfCust100 = props.data?.get_num_of_customers_for_top100()
                            setCustomerTotal((numOfCust100 !== undefined && numOfCust100 < RANKING_TOP_100) ? numOfCust100 : RANKING_TOP_100)
                            setCheckedList({})
                            setBulkCheckbox(CheckedState.None)
                            //setDrawIdList([])
                            // workerに顧客データを渡す
                            if (worker && props.data) worker.updateCustoemrList(props.data.trailHash)
                            //if (worker) worker.updateTraceList([])
                            //setMouseHitList([])
                            handleDrawIdList([])
                            setIsLoadingCustomerList(false)    // TrailMapListのスピナー解除
                        }
                    })
                } else {
                    // ALL表示の場合
                    setIsLoadingCustomerList(true)  // TrailMapListにスピナー表示
                    // 全顧客データ取得
                    props.data.get_all_customers(searchSortOption).then(result => {
                        console.log("get_all_customers result:", result)
                        if (result && result.data && result.data.length >= 0) {
                            setCustomers(result.data)
                            setCustomerTotal(result.totalValue)
                            setCheckedList({})
                            setBulkCheckbox(CheckedState.None)
                            //setDrawIdList([])
                            // workerに顧客データを渡す
                            if (worker && props.data) worker.updateCustoemrList(props.data.trailHash)
                            //if (worker) worker.updateTraceList([])
                            //setMouseHitList([])
                            handleDrawIdList([])
                            setIsLoadingCustomerList(false) // TrailMapListのスピナー解除
                        }
                    })
                }
            }
        }
    }, [props.data, selectedRank, filter, worker, searchSortOption, handleDrawIdList])

    const side = useMemo(() => {
        if (props.request) return props.request.side
        return "0"
    }, [props.request])

    return (
        <div className={style.content}>
            <div className={style.mainContents}>
                <div className={style.mapPane}>
                    <div className={style.trailErrs}>{(trailStatus && trailStatus.errors) ? trailStatus?.errors : "　"}</div>
                    <div className={style.canvasWrap}>
                        <canvas id={"backImgCanvas" + side} ref={bgImgRef} className={style.bgImgCanvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT} />
                        <canvas id={"trailMapCanvas" + side} ref={ref} className={style.canvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT} />
                        <canvas id={"aniCanvas" + side} ref={aniRef} className={style.aniCanvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT} />
                        <div className={style.zoomBtn}>
                            <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                        </div>
                    </div>
                </div>
                <div className={style.subList}>
                    <TrailMapList
                        viewMode={ListViewModeType.short}
                        linesOfPage={linesOfPage}
                        customers={customers}
                        changeIdList={handleDrawIdList}
                        changeHighlight={handleHighlightTrail}
                        isSingle={isSingle}
                        sorting={sorting}
                        changeSorting={handleChangeSorting}
                        bulkCheckbox={bulkCheckbox}
                        changeBulkCheckbox={v => setBulkCheckbox(v)}
                        checkedList={checkedList}
                        changeCheckedList={handleChangeCheckedList}
                        currentPage={currentPage}
                        isBusy={isBusyLoadingTrailLine}
                        isLoading={isLoadingCustomerList}
                        fireIds={mouseHitList}
                        noSortOfStayAreaCount={(selectedRank && selectedRank.name === RankingType.All) ? true : false}
                    />
                </div>
            </div>
            <div className={style.detail}>
                {
                    (detail) ? (<TrailMapDetail show={detail.show} hide={handleDetailHide} data={detail.data} />) : (null)
                }
            </div>
            <div className={style.ctrlPane}>
                <div className={style.switch}>
                    <SeriesSwitcher fontType={SwitcherFontType.Mulish} buttonList={SwitchItems} onClick={e => changeRanking(e)} activeName={selectedRank ? selectedRank.name : ""}/>
                </div>
            </div>
            <div className={(isSingle) ? style.listPaneSng : style.listPaneCmp}>
                <TrailMapCustomerList
                    numOfAllFiles={props.data?.get_num_of_customers()}
                    linesOfPage={linesOfPage}
                    changeLinesOfPage={handleChangeLinesOfPage}
                    selectedCustomers={customers}
                    selectedCustomerSize={customerTotal}
                    searchParam={props.request}
                    areaList={(props.data && props.data.layout && props.data.layout.area_set && props.data.layout.area_set.length > 0) ? props.data.layout.area_set[0].area_defs : undefined}
                    showDetail={handleDetailShow}
                    changeIdList={handleDrawIdList}
                    changeHighlight={handleHighlightTrail}
                    downloadHeader={downloadHeader}
                    isSingle={isSingle}
                    filter={filter}
                    changeFilter={handleChangeFilter}
                    sorting={sorting}
                    changeSorting={handleChangeSorting}
                    bulkCheckbox={bulkCheckbox}
                    changeBulkCheckbox={v => setBulkCheckbox(v)}
                    checkedList={checkedList}
                    changeCheckedList={handleChangeCheckedList}
                    currentPage={currentPage}
                    changeCurrentPage={handleChangeCurrentPage}
                    isBusy={isBusyLoadingTrailLine}
                    isLoading={isLoadingCustomerList}
                    fireIds={mouseHitList}   
                    isThroughList={(selectedRank && selectedRank.name === RankingType.All) ? true : false}
                    getDownloadCustomers={handleGetDownloadCustomers}
                />
            </div>
        </div>
    )
}

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const TrailMap: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={TrailMapCore} type={PanelDesignType.LightBlue} />
    )
}