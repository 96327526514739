import React from "react"
import { useTranslation } from "react-i18next"

import style from './Header.module.css'

// Union型で定義する
const HeaderSizeType = {
    Small: 'small',
    Large: 'large'
} as const
export type HeaderSize = typeof HeaderSizeType[keyof typeof HeaderSizeType]

type Props = {
    size?: HeaderSize
    disableLogout?: boolean
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

export const Header: React.FC<Props> = (props) => {
	
	const { t } = useTranslation()
    // デフォルトは"large"
    const size: HeaderSize = (props.size) ? props.size : HeaderSizeType.Large
    // デフォルトはfalse
    const disableLogout: boolean = (props.disableLogout) ? props.disableLogout : false

    let mainStyle = `${style.main} ${style.headerLarge}`
    if (mockOn) {
        if (size === HeaderSizeType.Small) {
            mainStyle = `${style.main} ${style.headerSmallMockOn}`
        } else {
            mainStyle = `${style.main} ${style.headerLargeMockOn}`
        }
    } else {
        if (size === HeaderSizeType.Small) {
            mainStyle = `${style.main} ${style.headerSmall}`
        } else {
            mainStyle = `${style.main} ${style.headerLarge}`
        }
    }

    return (
        <div className={mainStyle}
        >
            <div className={style.leftItem}>
                <img
                    className={
                        size === HeaderSizeType.Small ? style.logoLeftSmall : style.logoLeftLarge
                    }
                    src="/img/ATIKO_Logo_white.png"
                    alt="product-logo"
                />
            </div>
            <div className={style.centerItem}>
                {
                    mockOn ? (size === HeaderSizeType.Small ? <span className={style.mockMsgSmall}>{t("mock")}</span> : <span className={style.mockMsg}>{t("header.operatingMockData")}</span>) : (null)
                }
            </div>
            <div className={style.rightItem}>
                {
                    size === HeaderSizeType.Small || disableLogout ? (null) : (<a href="/#/logout" className={style.logout}>{t("logout")}</a>)
                }
            </div>
        </div>
    )
}