import { useEffect, useRef } from "react"


import AreaEditPicWriter from "./AreaEditPicWriter"
import { ResArea } from "../api/data/analysis/FullLayout"


/**
 * AreaEditPicWriterからセル修正イベントを取るための仕組み
 * 
 * @param writer 
 * @param areaId 
 * @param callback 
 */
export const useWriterCallback = (writer: AreaEditPicWriter | undefined, callback: (update: ResArea) => void) => {
    const refCallback = useRef(callback)

    useEffect(() => {
        const cellChange = (update: ResArea) => {
            // 登録したコールバックを実行する
            refCallback.current(update)
        }

        // イベント登録
        if (writer) writer.addListener(cellChange)

        // DOM削除時にイベント解除
        return () => {
            if (writer) writer.removeListener(cellChange)
        }
    }, [writer])
}
