import React from "react"

import style from "./ThreeStateCheckbox.module.css"

export const CheckedState = {
    Full: "full",
    PartOf: "partof",
    None: "none"
} as const
export type Checked = typeof CheckedState[keyof typeof CheckedState]

interface Props {
    checked: Checked
    label: string
    onClick: () => void
}

/**
 * ３ステートチェックボックス
 * 
 * 全て選択、全て解除ができ、一部選択中の表示も可能。
 * このコンポーネントでは、チェックの値を管理しないので、親コンポーネントで行うこと。
 * @param props 
 * @returns 
 */
export const ThreeStateCheckbox: React.FC<Props> = (props) => {

    if (!props.checked) {
        console.error("３ステートチェックボックスのchecks値が不明です。")
        return null
    }

    return (
        <div className={(props.checked===CheckedState.Full)? style.full : (props.checked===CheckedState.PartOf) ? style.partof:style.none} onClick={props.onClick}>
            <label className={style.label}>{props.label}</label>
        </div>
    )
}