import { FC, useCallback, useEffect, useRef, useState, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { PageName } from "../../types"
import { Slider } from "../../component/slider/Slider"
import { Stepper } from "../../component/stepper/Stepper"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { VerticalSlider } from "../../component/vertical_slider/VerticalSlider"
import { CustomDialog, AcceptButtonType } from "../../component/custom_dialog/CustomDialog"
import { ModalFrame } from "../common/ModalFrame"
import { useMouseMove } from "../../lib/useMouseMove"
import { ZOOM_CENTER } from "../../lib/CanvasWorker"

import style from "./LayoutRegiStep4.module.css"

interface Props {}

export const LayoutRegiStep4: FC<Props> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { worker } = useManagementDataContext()

    const [volume, setVolume] = useState<number>(ZOOM_CENTER)
    const [opacity, setOpacity] = useState<number>(5)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    
    const refBas = useRef<HTMLCanvasElement | null>(null)
    const refMov = useRef<HTMLCanvasElement | null>(null)
    const refCrs = useRef<HTMLCanvasElement | null>(null)
    const refFirst = useRef<boolean>(true)

    /**
     * ステップラベルのリスト
     */
    const stepperLabelList = useMemo(() => {
        const stp1 = t("label.layoutRegiStep1")
        const stp2 = t("label.layoutRegiStep2")
        const stp3 = t("label.layoutRegiStep3")
        const stp4 = t("label.layoutRegiStep4")
        const stp5 = t("label.layoutRegiStep5")
        return [stp1, stp2, stp3, stp4, stp5]
    }, [t])    
    
    const handleClose = () => {
        if (!modalOpen) setModalOpen(true)
    }

    const onCanceledClose = () => {
        setModalOpen(false)
    }

    const onAcceptedClose = () => {
        navigate("/layout_list")
    }

    const handleHistoryBack = () => {
        navigate(-1)
    }


    const onClickNext = () => {
        if (worker) {
            worker.neoCaliculateParameters()
            worker.printBasic()
            worker.printNeo()
        }
        navigate("/layout_regi_step5", { state: { from: location } })
    }

    const refreshMap = useCallback(() => {
        if (worker && refBas && refBas.current && refCrs && refCrs.current && refMov && refMov.current) {
            const ctx = refBas.current.getContext("2d")
            if (ctx) {
                worker.clearCanvas(ctx)
                worker.drawBasicFitImage(ctx)
            }
            const ct2 = refCrs.current.getContext("2d")
            if (ct2) {
                worker.clearCanvas(ct2)
                worker.drawNeoFitImage(ct2)
            }
            const ani = refMov.current.getContext("2d")
            if (ani) {
                worker.clearCanvas(ani)
            }
        }
    }, [worker])

    const onChangeVolume = (value: number) => {
        setVolume(value)
        if (worker) {
            worker.neoZoomInOut(value)
            refreshMap()
        }
    }

    const onChangeOpacity = (value: number) => {
        setOpacity(value)
        if (refCrs && refCrs.current) {
            const elm = refCrs.current
            if (elm) elm.style.opacity = String(value / 10)
        }
    }

    const mousePress = useMouseMove(refMov, (event: MouseEvent) => {
        if (worker) {
            const srcCanvas = refCrs.current
            const dstCanvas = refMov.current
            if (srcCanvas && dstCanvas) worker.neoMouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        refreshMap()
    }, (event: MouseEvent) => {
        // マウスボタンを押したときの処理
        if (worker) {
            worker.neoMousePress()
        }
    }
    )

    // 初回レンダー時の処理
    useEffect(() => {
        // 開発時のStrictModeで二重レンダーされるのを防ぐ
        if (process.env.NODE_ENV === "development") {
            if (refFirst.current) {
                refFirst.current = false
                console.log("SKIP first render")
                return
            }
        }

        //if (mousePress) {
            //if (worker) worker.neoMousePress()
        //} else {
            refreshMap()
        //}
    }, [])

    const guideMessage = useMemo(() => {
        if (i18n.language === "ja") {
            return (<div className={style.guide}>
                        <span className={style.strong}>原点の図面</span>と、
                        <span className={style.strong}>レイアウトデータの什器位置や壁のサイズ</span>が<span className={style.strong}>一致する</span>
                        ように縮尺と位置を調整してください。
                    </div>)
        } else {
            return (<div className={style.guide}>
                Please adjust the scale and position so that the original drawing and the furniture position and wall size of the layout data match.
            </div>)
        }
    }, [i18n])

    const anounceMessage = useMemo(() => {
        if (i18n.language === "ja") {
            return (<div className={style.messages}>
                        <div className={style.oneLine}>※新規レイアウト登録は全て下書きに保存されます。 </div>
                        <div className={style.oneLine}>※開始日が入力されているレイアウトについては開始日になり次第本番適用してよいか確認通知が表示されます。 </div>
                        <div className={style.oneLine}>※開始日が入力されていないレイアウトまたは、すぐに適用したいレイアウトについては </div>
                        <div className={style.oneLine}>　一覧画面から対象レイアウトの本番適用ボタンを押下してください。</div>
                    </div>)
        } else {
            return (<div className={style.messages}>
                        <div className={style.oneLine}>※All new layout registrations are saved in Drafts. </div>
                        <div className={style.oneLine}>※For layouts with a start date entered, a confirmation notice will be displayed as soon as the start date is reached. </div>
                        <div className={style.oneLine}>※For layouts for which the start date has not been entered or layouts that you want to apply immediately, </div>
                        <div className={style.oneLine}>　please press the actual application button of the target layout from the list screen.</div>
                    </div>)
        }
    }, [i18n])

    return (
        <ModalFrame page={PageName.InfoMap} title={t("menu.mapRegistrationEdit")} width={1110} height={770} >
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>{t("header.layoutNewEntry")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.indicator}>
                    <div className={style.steps}>
                        <Stepper activeNumber={4} labels={stepperLabelList}></Stepper>
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.subTitle}>{t("header.scaleAdjustment")}</div>
                    {
                        guideMessage
                    }
                    <div className={style.canvasWrap}>
                        <canvas className={style.canvasBas} width={753} height={435} ref={refBas}></canvas>
                        <canvas className={style.canvasCrs} width={753} height={435} ref={refCrs}></canvas>
                        <canvas className={style.canvasMov} width={753} height={435} ref={refMov}></canvas>
                    </div>
                    <div className={style.zoombar}>
                        <Slider
                            value={volume}
                            withBorder={true}
                            withButton={true}
                            max={ZOOM_CENTER * 2}
                            min={0}
                            onChange={onChangeVolume}
                            size="s"
                        />
                    </div>
                    {
                        anounceMessage
                    }
                    <div className={style.navi}>
                        <Button name="next" label={t("button.toTheNext")} styleType={StyleType.Normal} onClick={onClickNext}/>
                    </div>
                    <div className={style.sidebar}>
                        <VerticalSlider size="xs" value={opacity} max={9} min={1} step={1} onChange={onChangeOpacity} dirOfMin="down" withBorder={true} />
                    </div>
                    <div className={style.back}>
                        <Button name="back" label={t("back")} styleType={StyleType.MuliInact} onClick={handleHistoryBack}/>
                    </div>
                </div>
            </div>
            <CustomDialog
                requestOpen={modalOpen}
                width={500}
                height={170}
                guideMessage={t("guidance.cancelLayoutRegi")}
                buttonType={AcceptButtonType.Ok}
                onCancel={onCanceledClose}
                onAccept={onAcceptedClose}
            >
            </CustomDialog>
        </ModalFrame>
    )
}
