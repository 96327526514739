import React, { useCallback, useEffect, useState, useRef } from "react"
import { subDays, addDays, isValid } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"

import { StyleType, Button } from "../../component/button/Button";
import { SeriesSwitcher, BtnProp } from "../../component/series_switcher/SeriesSwitcher";
import { DpType, FupDatepicker } from "../../component/fup_datepicker/FupDatepicker";
import { BaseFrame } from "../common/BaseFrame";
import { useModal } from "../../component/modal/Modal"
import { PageName } from "../../types"
import { ThresholdTimepicker } from "../../component/threshold_timepicker/ThresholdTimepicker";
import { useAuthUserContext } from "../../providers";
import { useStatisticsContext } from "../../providers/StatisticsData";
import { TxStatusType, TxStatus } from "../../types";
import { DashboardLineChart } from "./DashboardLineChart";
import { DataChoiceType, StatisticsDatasetType } from "../../types/Statistics";
import { TimeUnitShortType } from "../../api/data/core/Enums";
import { choice2DataType, timeUnit2DisplayTerm } from "../../lib/DashboardUtil";
import { THRESHOLD_4_COMPANY } from "../../constants";
import { TrackingPageView } from "../common/TrackingPageView";

import style from './Dashboard.module.css'


// 分析期間の切替
export const DispTermType = {
    DayBefore: 'dayBefore',
    PrevWeek: 'prevWeek'
} as const
export type DisplayTerm = typeof DispTermType[keyof typeof DispTermType]

// ボタンの種類
export const BtnCtrlType = {
    PrevDay: "prevDay",
    NextDay: "nextDay",
    DispOrder: "dispOrder"
} as const
//type ButtonControl = typeof BtnCtrlType[keyof typeof BtnCtrlType]

// 分析データ切り替え器用のデータ
export const DataSelector: Array<BtnProp> = [
    { name: DataChoiceType.Visitors, label: "visitors" },
    { name: DataChoiceType.Buyers, label: "purchasers" },
    { name: DataChoiceType.SalesAmount, label: "sales" },
    { name: DataChoiceType.AvgNumOfArea, label: "averageDwellArea" }
]
// 分析データ切り替え器用のデータ(Mock用)
export const DataSelector4Mock: Array<BtnProp> = [
    { name: DataChoiceType.Visitors, label: "visitors" },
    { name: DataChoiceType.Buyers, label: "purchasers" },
    { name: DataChoiceType.BuyRatio, label: "purchaseRatio" },
    { name: DataChoiceType.AvgStayTime, label: "averageDwellTime" },
    { name: DataChoiceType.SalesAmount, label: "sales" },
    { name: DataChoiceType.AvgNumOfArea, label: "averageDwellArea" }
]


// 分析期間切り替え器用のデータ
export const TermSelector: Array<BtnProp> = [
    { name: TimeUnitShortType.Day, label: "tabDayToDayRatio" },
    { name: TimeUnitShortType.Week, label: "tabPrevWeekRatio" }
]

// 折れ線グラフのデータ数
//const NUM_OF_POINT = 8

// 閾値の最大値
const THRESHOLD_MAX = 300

// 閾値の刻み秒
const THRESHOLD_STEP = 5

const yesterday = subDays(new Date(), 1)

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn


interface ListProps {
    startingDate: Date
}

const ChartList: React.FC<ListProps> = (props) => {

    const navigate = useNavigate()
    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"

    const { dataset, status, fetch, choice, timeUnit } = useStatisticsContext()

    const { orderedShopList } = useAuthUserContext()
    
    const refFirst = useRef<boolean>(true)
    
    // リクエストキャンセル用
    const refAbCon = useRef<AbortController | null>(null)
    //console.log("★ChartList:refAbCon", refAbCon.current)

    const handleChartOnClick = useCallback((shopId: number) => {
        navigate(baseUrl + "dashboard/" + shopId)
    }, [baseUrl, navigate])

    const getListItems = useCallback(() => {
        if (orderedShopList === undefined) return []
        //console.log("orderedShopList", orderedShopList)
        //console.log("dataset:", dataset)
        const st: TxStatus = (status) ? status : TxStatusType.Loading
        const result = []
        for (let shop of orderedShopList) {
            const shopId = shop.shop_id
            const dskeys = (dataset) ? Object.keys(dataset) : []
            const ds: StatisticsDatasetType | undefined = (dataset && dskeys.length > 0) ? dataset[shopId.toString()] : undefined
            const key = choice + "_" + shopId
            result.push(
                <DashboardLineChart
                    dataType={choice2DataType(choice)}
                    termType={timeUnit2DisplayTerm(timeUnit)}
                    shopId={shopId}
                    shopName={shop.name}
                    labels={ds ? ds.label : []}
                    datas={ds ? ds.data : []}
                    status={st}
                    key={key}
                    onClick={handleChartOnClick}
                />
            )
        }
        return result
    }, [orderedShopList, choice, timeUnit, dataset, status, handleChartOnClick])

    //初期表示用データ取得
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                console.log("★refFirst SKIP ChartList")
                return
            }
        }
        if (dataset === undefined && status === undefined && refAbCon.current === null) {
            const abCon = new AbortController()
            refAbCon.current = abCon
            fetch(abCon, props.startingDate, timeUnit)
            console.log("★fetch")
        }

        return () => {
            if (refAbCon.current && status === TxStatusType.Loading) {
                console.log("★abort")
                refAbCon.current.abort()
                refAbCon.current = null
            }
        }
    }, [])

    return (
        <div className={style.listPane}>
            {getListItems()}
        </div>
    )
} 


export const Dashboard: React.FC = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { orderedShopList } = useAuthUserContext()
    const { status, fetch, choice, updateChoice, timeUnit, updateTimeUnit, thresholdTime, updateThreshold, startingDate, updateStartingDate } = useStatisticsContext()

    // モーダル用滞在時間しきい値
    const [modalThreshold, setModalThreshold] = useState<number>(thresholdTime ? thresholdTime : THRESHOLD_4_COMPANY)

    // カレンダー用Ref
    const ref = React.useRef<HTMLDivElement>(null)
    // リクエストキャンセル用
    const refAbCon = useRef<AbortController | null>(null)

    // Modalの設定
    const [Modal, open, close, isOpen] = useModal('root')

    const handleModalOpen = useCallback((event: React.MouseEvent) => {
        open()
    }, [open])

    const handleCancel = useCallback((event: React.MouseEvent) => {
        // モーダルの値を元に戻す
        setModalThreshold(thresholdTime ? thresholdTime : THRESHOLD_4_COMPANY)
        if (isOpen) close()
    }, [close, isOpen, thresholdTime])

    const handleEntry = useCallback((event: React.MouseEvent) => {
        // ここで閾値更新（APIコール）
        updateThreshold(modalThreshold)
        if (isOpen) close()
        // データ更新
        if (refAbCon.current && status === TxStatusType.Loading) {
            console.log("★abort")
            refAbCon.current.abort()
            refAbCon.current = null
        }
        const abCon = new AbortController()
        refAbCon.current = abCon
        fetch(refAbCon.current, startingDate, timeUnit)            
    }, [close, updateThreshold, fetch, isOpen, startingDate, modalThreshold, status, timeUnit])
    
    const handleChangeDate = (selectedDate: Date | [Date, Date] | null): void => {
        if (selectedDate && !Array.isArray(selectedDate) && isValid(selectedDate)) {
            updateStartingDate(selectedDate)
            // データ更新
            if (refAbCon.current && status === TxStatusType.Loading) {
                console.log("★abort")
                refAbCon.current.abort()
                refAbCon.current = null
            }
            const abCon = new AbortController()
            refAbCon.current = abCon
            fetch(refAbCon.current, selectedDate, timeUnit)
        }
    }

    const handleButton: React.MouseEventHandler<HTMLButtonElement> = event => {
        const nm: string = (event.target as HTMLButtonElement).name
        if (nm === BtnCtrlType.PrevDay) {
            const newDt = subDays(startingDate, 1)
            updateStartingDate(newDt)
            // データ更新
            if (refAbCon.current && status === TxStatusType.Loading) {
                console.log("★abort")
                refAbCon.current.abort()
                refAbCon.current = null
            }
            const abCon = new AbortController()
            refAbCon.current = abCon
            fetch(refAbCon.current, newDt, timeUnit)
        } else if (nm === BtnCtrlType.NextDay) {
            const newDt = addDays(startingDate, 1)
            if (newDt <= yesterday) {
                updateStartingDate(newDt)
                // データ更新
                if (refAbCon.current && status === TxStatusType.Loading) {
                    console.log("★abort")
                    refAbCon.current.abort()
                    refAbCon.current = null
                }
                const abCon = new AbortController()
                refAbCon.current = abCon
                fetch(refAbCon.current, newDt, timeUnit)
            }
        } else {
            const msg = t("msgUnknownButton")
            throw new Error(msg)
        }
    }

    const changeDataType: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === DataChoiceType.Visitors) {
            if (choice !== DataChoiceType.Visitors) updateChoice(DataChoiceType.Visitors)
        } else if (nm === DataChoiceType.Buyers) {
            if (choice !== DataChoiceType.Buyers) updateChoice(DataChoiceType.Buyers)
        } else if (nm === DataChoiceType.BuyRatio) {
            if (choice !== DataChoiceType.BuyRatio) updateChoice(DataChoiceType.BuyRatio)
        } else if (nm === DataChoiceType.AvgStayTime) {
            if (choice !== DataChoiceType.AvgStayTime) updateChoice(DataChoiceType.AvgStayTime)
        } else if (nm === DataChoiceType.SalesAmount) {
            if (choice !== DataChoiceType.SalesAmount) updateChoice(DataChoiceType.SalesAmount)
        } else if (nm === DataChoiceType.AvgNumOfArea) {
            if (choice !== DataChoiceType.AvgNumOfArea) updateChoice(DataChoiceType.AvgNumOfArea)
        } else {
            const msg = t("msgUnknownTab")
            throw new Error(msg)
        }
    }

    const changeTermType: React.MouseEventHandler<HTMLButtonElement> = event => {
        //console.log(event.target)
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === TimeUnitShortType.Day) {
            if (timeUnit !== TimeUnitShortType.Day) {
                updateTimeUnit(TimeUnitShortType.Day)
                // データ更新
                if (refAbCon.current && status === TxStatusType.Loading) {
                    console.log("★abort")
                    refAbCon.current.abort()
                    refAbCon.current = null
                }
                const abCon = new AbortController()
                refAbCon.current = abCon
                fetch(refAbCon.current, startingDate, TimeUnitShortType.Day)                
            }
        } else if (nm === TimeUnitShortType.Week) {
            if (timeUnit !== TimeUnitShortType.Week) {
                updateTimeUnit(TimeUnitShortType.Week)
                if (refAbCon.current && status === TxStatusType.Loading) {
                    console.log("★abort")
                    refAbCon.current.abort()
                    refAbCon.current = null
                }
                const abCon = new AbortController()
                refAbCon.current = abCon
                fetch(refAbCon.current, startingDate, TimeUnitShortType.Week)
            }
        } else {
            const msg = t("msgUnknownTerms")
            throw new Error(msg)
        }
    }

    const updateModalThreshold = (time: number) => {
        setModalThreshold(time)
    }

    useEffect(() => {
        // コンポーネント破棄時の処理
        return () => {
            // 時々スクロール不可能になるため、後処理を追加
            window.document.body.style.overflow = ''

            // リクエストキャンセル
            if (refAbCon.current) {
                console.log("abort")
                refAbCon.current.abort()
                refAbCon.current = null
            }
        }
    }, [])
   
    let errorMessage = null
    if (status === TxStatusType.Rejected) {
        errorMessage = <div className={style.error}>{t("msgErrorOccured")}</div>
    }
    const dateFormat = t("dateFormat.ymd_E")
    const dateFormatCalendar = t("dateFormat.y_L")
    return (
        <BaseFrame actPage={PageName.DashboardAll}>
            <div className={style.pane}>
                <div className={style.head}>
                    <div className={style.title}>
                        {t("header.dashboardAll")}
                        {isProduction ? (<TrackingPageView page_title="dashboard-all-shops" />) : (null)}
                    </div>
                    <div className={style.threshold}>
                        {
                            choice === DataChoiceType.AvgNumOfArea ? (
                                <span>
				                {t("msgDwellTimeThreshold",{sec: thresholdTime})}
				                </span>
                            ) : (
                                    null
                            )
                        }
                    </div>
                </div>
                <div className={style.dtControl}>
                    <div className={style.datepicker}>
                        <FupDatepicker
                            type={DpType.Large}
                            divRef={ref}
                            dateFormat={dateFormat}
                            dateFormatCalendar={dateFormatCalendar}
                            onChange={handleChangeDate}
                            selected={startingDate}
                            maxDate={yesterday}
                            locale={i18n.language}
                        />
                    </div>
                    <div className={style.dayWrap}>
                        <div className={style.prevBtn}>
                            <Button styleType={StyleType.Outline} name={BtnCtrlType.PrevDay} label="prevDay" onClick={ e => handleButton(e) }/>
                        </div>
                        <div className={style.nextBtn}>
                            <Button styleType={StyleType.Outline} name={BtnCtrlType.NextDay} label="nextDay" onClick={ e => handleButton(e) }/>
                        </div>
                    </div>
                    <div className={style.empty}>
                    </div>
                    <div className={style.dspOrder}>
                        {
                            (orderedShopList && orderedShopList.length > 1) ? (
                                <Button styleType={StyleType.OutWide} name={BtnCtrlType.DispOrder} label="changeDisplayOrder" onClick={() => navigate("/dashboard/sort")}/>    
                            ) : (null)
                        }                
                    </div>
                </div>
                <div className={style.selControl}>
                    <div className={style.tabWrap}>
                        <SeriesSwitcher buttonList={DataSelector} activeName={choice} onClick={ e => changeDataType(e) }/>
                    </div>
                    <div className={style.empty}></div>
                    <div className={style.swWrap}>
                        <SeriesSwitcher buttonList={TermSelector} activeName={timeUnit} onClick={ e => changeTermType(e) }/>
                    </div>
                </div>
                <div className={style.chartList}>
                    {errorMessage}
                    <ChartList startingDate={startingDate} />
                </div>
            </div>
            <Modal>
                <div className={style.modalMain}>
                    <div className={style.modalTitle}>
                        {t("changeDwellTimeThreshold")}
                    </div>
                    <div className={style.modalPicker}>
                        <ThresholdTimepicker
                            thresholdTime={modalThreshold}
                            onChange={updateModalThreshold}
                            max={THRESHOLD_MAX}
                            interval={THRESHOLD_STEP}
                        />
                    </div>
                    <div className={style.modalControls}>
                        <div className={style.empty}></div>
                        <div className={style.modalCancel}>
                            <Button
                                styleType={StyleType.Reverse}
                                name="modalCancel"
                                label="cancel"
                                onClick={(e) => handleCancel(e)}
                            />
                        </div>
                        <div className={style.modalUpdate}>
                            <Button
                                styleType={StyleType.Normal}
                                name="modalUpdate"
                                label="saveChanges"
                                onClick={(e) => handleEntry(e)}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </BaseFrame>
    )
}