import {Role, RoleType} from "../../api/data/core/Enums"

export type UserTypeModel = {
    user_type_id: number
    role: Role
}

export const userTypeList: UserTypeModel[] = [
    { user_type_id: 1, role: RoleType.Root },
    { user_type_id: 2, role: RoleType.HeadOffice },
    { user_type_id: 3, role: RoleType.AreaManager },
    { user_type_id: 4, role: RoleType.StoreManager },
    { user_type_id: 5, role: RoleType.JuniorManager },
    { user_type_id: 6, role: RoleType.Staff },
    //{ user_type_id: 7, role: RoleType.JuniorStaff },
    { user_type_id: 7, role: RoleType.Staff },
    { user_type_id: 8, role: RoleType.Developer },
    //{ user_type_id: 9, role: RoleType.Manager },
]

export const getUserType = (user_type_id: number): Role => {
    const um = userTypeList.find(el => el.user_type_id === user_type_id)
    if (um) {
        return um.role
    } else {
        throw new Error("unknown user_type_id:"+user_type_id)
    }
}

