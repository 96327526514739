import React, { ChangeEvent, useRef, useState } from "react"
import { ChevronDownIcon } from '@primer/octicons-react'
import { usePopper } from 'react-popper'
import { useTranslation } from "react-i18next"

import style from './thresholdTimepicker.module.css'
import { FupRange } from "../fup_range/FupRange"
import { Button, StyleType } from "../button/Button"
import { formatSec2MMSS } from "../TimeFormat"
import { FupNumberpicker } from "../fup_numberpicker/FupNumberpicker"
import { useClickAway } from "../useClickAway"
import { useKeypress } from "../useKeypress"


export const SelectorType = {
    List: "list",
    Cylinder: "cylinder"
} as const
export type SelectType = typeof SelectorType[keyof typeof SelectorType]

interface Props {
    thresholdTime: number
    onChange: (time: number) => void
    interval?: number
    max?: number
    min?: number
    type?: SelectType
}

interface StateObject {
    selected: string
    show: boolean
}

export const ThresholdTimepicker: React.FC<Props> = (props) => {

	const { t } = useTranslation()

    const [myState, setMyState] = useState<StateObject>({
        selected: "" + props.thresholdTime,
        show: false
    })

    const handleChangeSelected = (event: ChangeEvent<HTMLSelectElement>): void => {
        //console.log("handleChangeSelected")
        const val = event.target.value
        const newSt = { ...myState }
        newSt.selected = val
        setMyState(newSt)
    }

    const handleChangeTime = (value: number) => {
        props.onChange(value)
        hidePopper()
    }
    const handleEnter = () => {
        //console.log("handleEnter")
        const val = parseInt(myState.selected)
        props.onChange(val)
        hidePopper()
    }
    const handleCancel = () => {
        //console.log("handleCancel")
        hidePopper()
    }

    // Select-Option 秒数選択
    const st = props.min ? props.min : 0
    const ed = props.max ? props.max : 3600
    const stp = props.interval ? props.interval : 1
    const vals = []
    for (let i = st; i <= ed; i = i + stp) {
        vals.push(i)
    }
    let contents = (props.type && props.type === SelectorType.Cylinder) ? (
        <div className={style.numpic}>
            <FupNumberpicker enter={handleChangeTime} cancel={handleCancel} value={props.thresholdTime} step={props.interval} />
        </div>
    ) : (
        <div className={style.overlay}>
            <div>
                <select defaultValue={props.thresholdTime} className={style.select} size={7} onChange={e => handleChangeSelected(e)}>
                    {
                        vals.map(v => (<option key={v} value={v}>{formatSec2MMSS(v)}</option>))
                    }
                </select>
            </div>
            <div>
                <Button styleType={StyleType.OutMini} onClick={handleCancel} name="cancel" label="back" />
                <Button styleType={StyleType.OutMini} onClick={handleEnter} name="enter" label="enter" />
            </div>
        </div>
    )

    const referenceRef = useRef<HTMLDivElement | null>(null)
    const popperRef = useRef<HTMLDivElement | null>(null)

    // 数字選択プルダウンの表示位置調整 offset: [x, y]
    const { styles, attributes } = usePopper(
        referenceRef.current,
        popperRef.current,
        {
            placement: 'bottom',
            modifiers: [
                { name: 'offset', options: { offset: [0, 0], } }
            ]
        }
    )

    const showPopper = () => {
        // 表示してないとき
        if (!myState.show) {
            //console.log("showPopper")
            const newSt = { ...myState }
            newSt.show = true
            setMyState(newSt)
        }
    }

    const hidePopper = () => {
        if (myState.show) {
            //console.log("hidePopper")
            const newSt = { ...myState }
            newSt.show = false
            setMyState(newSt)
        }
    }

    useClickAway(popperRef, hidePopper)
    useKeypress('Escape', hidePopper)

    return (
        <div className={style.section}>
            <div className={style.pulldown}>
                <div className={style.border} ref={referenceRef} onClick={showPopper}>
                    <label className={style.label} >{t("areaDwellTimeThreshold")}</label>
                    <ChevronDownIcon size={16} className={style.icon} />
                    <div ref={popperRef} style={styles.popper} {...attributes.popper}>
                        {myState.show ? (<>{contents}</>) : null }
                    </div>
                </div>
            </div>
            <div className={style.slider}>
                <div className={style.space}></div>
                <FupRange
                    value={props.thresholdTime}
                    max={props.max}
                    min={props.min}
                    step={props.interval}
                    onChange={handleChangeTime}
                />
            </div>
        </div>
    )
}
