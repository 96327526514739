import i18n from 'i18next'

/**
 * 秒数を"MM分SS秒"形式にフォーマットする
 * 
 * @param sec 
 * @returns 
 */
export const formatSec2MMSS = (sec: number | undefined): string => {
    if (sec!==undefined) {
        let mm = Math.floor(sec / 60)
        let ss = Math.round(sec - (mm * 60))
        let res = ""
        if (mm > 0) {
            res += i18n.t("minutes",{min: mm})
            if (ss === 0) {
                return res
            }
        }
        if (mm > 0 && ss < 10) {
            res += i18n.t("seconds",{sec: "0" + ss})
        } else {
            res += i18n.t("seconds",{sec: ss})
        }
        return res
    }
    return "　"
}

/**
 * 与えられた秒数を"HH時MM分SS秒"にフォーマット
 * 
 * @param sec 
 * @returns 
 */
export const formatSec2Hms = (sec: number | undefined | null): string => {
    if (sec===null || sec===undefined) return "　"
    let hh = Math.floor(sec / 3600)
    let mod = sec - (hh * 3600)
    let mm = Math.floor(mod / 60)
    let ss = Math.round(mod - (mm * 60))
    let res = ""
    if (hh > 0) {
        res += i18n.t("hours",{hour: hh})
        if (mm === 0 && ss === 0) return res
    }
    if (mm > 0) {
        res += i18n.t("minutes",{min: mm})
        if (ss === 0) return res
    }
    if (mm > 0 && ss < 10) {
        res += i18n.t("seconds",{sec: "0" + ss})
    } else {
        res += i18n.t("seconds",{sec: ss})
    }
    return res
}