import React, { useCallback, useMemo, useEffect } from "react"
import { PencilIcon } from "@primer/octicons-react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { EditModeType, UrlParameterType } from "../../types/Management"
import { ListFrame } from "../common/ListFrame"
import DateUtil from "../../lib/DateUtil"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./ShopList.module.css"

const myPermission = PermissionTypes.ManageShop_View

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {}

export const ShopList: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { companyId, companyList, shopList, initManagementData } = useManagementDataContext()

    const {ableToCreate4Shop, ableToUpdate4Shop, accessableShops} = useAccessControlContext()
    
    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate4Shop() }, [ableToCreate4Shop])
        
    /**
     * 新規登録
     */
    const handleNewEntryShop = useCallback(() => {
        if (ableNew) {
            navigate("/shop_edit?" + UrlParameterType.Mode + "=" + EditModeType.New,
                { state: { Mode: EditModeType.New, from: location } })
        }
    }, [navigate, ableNew, location])

    /**
     * 編集（権限のない店舗は閲覧モード）
     */
    const handleEditShop = useCallback((event: React.MouseEvent, shopId: number) => {
        event.preventDefault()
        const acl = ableToUpdate4Shop(shopId)
        const mode = (acl) ? EditModeType.Edit : EditModeType.View
        navigate("/shop_edit?" + UrlParameterType.Mode + "=" + mode + "&" + UrlParameterType.ShopId + "=" + shopId,
            { state: { Mode: mode, ShopId: shopId, from: location } })
    }, [navigate, ableToUpdate4Shop, location])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.add(style.trOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.remove(style.trOver)
    }, [])

    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    const dataRows: React.ReactNode = useMemo(() => {
        const result: any = []
        if (companyId) {
            const sList = accessableShops(myPermission)
            if (shopList.length > 0) {
                shopList.forEach((shop, idx) => {
                    const active = sList.find(el => el.shop_id === shop.shop_id)
                    const itm = (
                        <tr key={idx} onMouseOver={handleRowOver} onMouseOut={handleRowOut} onClick={e => handleEditShop(e, shop.shop_id)}>
                            <td className={style.tdId}>{shop.shop_id}</td>
                            <td className={style.tdName}>{shop.name}</td>
                            <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(shop.created_at, 0)}</td>
                            <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(shop.modified_at, 0)}</td>
                            <td className={style.tdLink}>
                                {
                                    active ? (
                                        <div className={style.icon} onClick={e => handleEditShop(e, shop.shop_id)}><PencilIcon size={16} /></div>
                                ) : (
                                        <div className={style.iconDisabled}><PencilIcon size={16} /></div>
                                    )
                                }
                            </td>
                        </tr>
                    )
                    result.push(itm)
                })
            } else {
                const msg = t("msgHasNoStore")
                const itm = (
                    <tr key="999">
                        <td colSpan={5} className={style.tdName}>{msg}</td>
                    </tr>
                )
                result.push(itm)
            }
        }
        return result
    }, [companyId, handleEditShop, t, accessableShops, shopList, handleRowOver, handleRowOut])

    return (
        <ListFrame
            page={PageName.InfoShop}
            title={t("header.storeRegistrationEdit")}
            permission={myPermission}
            companySelect={true}
            shopSelect={false}
            newEntry={(companyId && ableNew) ? true : false}
            copyEntry={false}
            onClickNew={handleNewEntryShop}
        >
            {isProduction ? (<TrackingPageView page_title="bsinfo-shop-list" />) : (null)}
            <table className={style.table}>
                <thead>
                    <tr>
                        <th className={style.th_id}>{t("storeId")}</th>
                        <th className={style.th_name}>{t("storeName")}</th>
                        <th className={style.th_date}>{t("registrationDate")}</th>
                        <th className={style.th_date}>{t("modifiedDate")}</th>
                        <th className={style.th_link}></th>
                    </tr>
                </thead>
                <tbody>
                    {dataRows}
                </tbody>
            </table>
        </ListFrame>
    )
}