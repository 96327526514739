import axios, { AxiosResponse } from "axios";
import { APIError } from "../APIError";
import { AnalysisAPI } from "../api_interface/analysis/analysis_api";
import { AnalysisErrorResult, AnalysisResults } from "../data/analysis/AnalysisResult";
import { Coord } from "../data/core/Coord";
import { OneShopDashboardResult, AllShopDashboardResult, DashboardAreaCount } from "../data/dashboard/DashboardResult";
import { DefaultSearchSortOption, ResSearchQuery, SortOrderType } from "../data/analysis/AnalysisRequest";
import { DBPassingCount, OSResAreaCount, TrailJSON } from "./AnalysisDataImpl";
import { AnalysisResultImpl } from "./result/AnalysisResultImpl";
import { FullLayout } from "./result/FullLayoutImpl";
import { OpenSearch } from "./requester/OpenSearch";
import { ResArea, ResDashboardLayoutPair } from "../data/analysis/FullLayout";
import { CalcUtils } from "./result/calc_utils";
import { ResAllDashbord } from "../data/dashboard/AllDashboard";
import RemainTimeWatcher, { JobTypes } from "./RemainTimeWatcher";
import { CompareSide, CompareSideType } from "../../types/Analyze";
import { MAX_SEARCH_RESULT } from "../../constants";
import { axiosOptions } from "../data/core/Constants";

// サンプリングデータ取得の最大件数
const MAX_SAMPLE_SIZE = 10000

interface ResPosTimeline {
    "shop_id": number;
    "amount_of_paid": number;
    "count_of_purchasers": number;
}

export class AnalysiAPIImpl implements AnalysisAPI {
    endpoint: string
    opensearch: OpenSearch

    /**
     * コンストラクタ
     * @param endpoint 
     */
    constructor(endpoint?: string) {
        if (endpoint != null) {
            this.endpoint = endpoint
        } else if (process.env.REACT_APP_API_ENDPOINT != null) {
            this.endpoint = process.env.REACT_APP_API_ENDPOINT
        } else {
            throw new APIError("適当なAPIエンドポイントが設定されていない")
        }
        this.opensearch = new OpenSearch(this.endpoint)
    }

    /**
     * 個別店舗分析・比較店舗分析を実行する
     * 
     * @param query 
     * @param progres_cb 
     * @param abortController 
     * @returns 
     */
    async analysis(
        query: {
            single: ResSearchQuery;
            compare?: ResSearchQuery | undefined;
        },
        progres_cb: (percentage: number, remainMsTime: number | undefined) => void,
        abortController?: AbortController
    ): Promise<AnalysisResults | AnalysisErrorResult> {
        
        return new Promise((resolve, reject) => {
            // 残り時間表示用のウォッチャー
            let watch = new RemainTimeWatcher((query.compare !== null && query.compare !== undefined), progres_cb)
            watch.start()
            // 分析の実行
            if (query.compare === null || query.compare === undefined) {
                // 個別の場合
                this.analySingle(query.single, watch, abortController).then((singleResult) => {
                    watch.stop()
                    resolve(singleResult)
                }).catch((e) => {
                    console.error(e)
                    watch.stop()
                    reject({ error: e.message } as AnalysisErrorResult)
                })
            } else {
                // 比較の場合
                this.analyCompare(query.single, query.compare, watch, abortController).then((result) => {
                    watch.stop()
                    resolve(result)
                }).catch((e) => {
                    watch.stop()
                    reject({ error: e.message } as AnalysisErrorResult)
                })
            }
        })
    }
    
    async analySingle(query: ResSearchQuery, watch: RemainTimeWatcher, abortController?: AbortController): Promise<AnalysisResults> {
        // 顧客数とレイアウト取得を並列実行
        const [numOfCustomers, [layoutSingle, activeAreaListSingle]] = await Promise.all([
            this.get_number_of_customers(query, watch, CompareSideType.Primary),
            this.get_layout_and_active_area(query, watch, CompareSideType.Primary)        
        ]).catch((e) => {
            throw e
        })
        // 顧客数が多すぎる場合はエラー
        if (numOfCustomers > MAX_SEARCH_RESULT) {
            throw new Error("Too many customers. Please narrow down the search conditions.")
        }
        // 結果オブジェクトの生成
        const resultObject: AnalysisResultImpl = new AnalysisResultImpl(this.opensearch, query, layoutSingle, activeAreaListSingle, numOfCustomers)
        // 顧客数とレイアウト取得後の処理
        const res = await Promise.all([
            this.normalTrack(query, watch, CompareSideType.Primary, resultObject, numOfCustomers),
            this.trailTrack(query, watch, CompareSideType.Primary, numOfCustomers, resultObject),
            await this.areaCountTrack(query, watch, CompareSideType.Primary, resultObject, abortController)
        ])
        return { single: resultObject }
    }

    async analyCompare(querySingle: ResSearchQuery, queryCompare: ResSearchQuery, watch: RemainTimeWatcher, abortController?: AbortController): Promise<AnalysisResults> {
        // 顧客数とレイアウト取得を並列実行
        const [customersSingle, [layoutSingle, actAreasSingle], customersCompare, [layoutCompare, actAreasCompare]] = await Promise.all([
            this.get_number_of_customers(querySingle, watch, CompareSideType.Primary),
            this.get_layout_and_active_area(querySingle, watch, CompareSideType.Primary),
            this.get_number_of_customers(queryCompare, watch, CompareSideType.Secondary),
            this.get_layout_and_active_area(queryCompare, watch, CompareSideType.Secondary)
        ]).catch((e) => {
            throw e
        })
        // 顧客数が多すぎる場合はエラー
        if (customersSingle > MAX_SEARCH_RESULT || customersCompare > MAX_SEARCH_RESULT) {
            throw new Error("Too many customers. Please narrow down the search conditions.")
        }
        // 結果オブジェクトの生成
        const resultSingle: AnalysisResultImpl = new AnalysisResultImpl(this.opensearch, querySingle, layoutSingle, actAreasSingle, customersSingle)
        const resultCompare: AnalysisResultImpl = new AnalysisResultImpl(this.opensearch, queryCompare, layoutCompare, actAreasCompare, customersCompare)
        const res = await Promise.all([
            this.normalTrack(querySingle, watch, CompareSideType.Primary, resultSingle, customersSingle),
            this.trailTrack(querySingle, watch, CompareSideType.Primary, customersSingle, resultSingle),
            this.areaCountTrack(querySingle, watch, CompareSideType.Primary, resultSingle, abortController),
            this.normalTrack(queryCompare, watch, CompareSideType.Secondary, resultCompare, customersCompare),
            this.trailTrack(queryCompare, watch, CompareSideType.Secondary, customersCompare, resultCompare),
            this.areaCountTrack(queryCompare, watch, CompareSideType.Secondary, resultCompare, abortController)
        ])
        return { single: resultSingle, compare: resultCompare }
    }

    /**
     * 弦グラフ用サンプリングデータを取得する
     * 
     * @param result 
     */
    async analysis2(result: AnalysisResults): Promise<void> {
        const startTm = performance.now()
        const size = result.single.get_num_of_customers()
        const query = result.single.query
        if (size > MAX_SAMPLE_SIZE) {
            // １万件以上の場合は昇順と降順の両方を取得してMIXする
            Promise.all([this.opensearch.get_sample_customers(query, SortOrderType.Asc), this.opensearch.get_sample_customers(query, SortOrderType.Desc)]).then(res => {
                (result.single as AnalysisResultImpl).set_sample_customers(res[0], res[1]).then(() => {
                    const endTm = performance.now()
                    console.log("single 20000サンプリングデータ取得時間:", endTm - startTm, "ms")
                
                })
            })
        } else {
            // 1万件未満は全件取得
            this.opensearch.get_sample_customers(query, SortOrderType.Asc, size).then(res => {
                (result.single as AnalysisResultImpl).set_sample_customers(res, []).then(() => {
                    const endTm = performance.now()
                    console.log("single " + size + "サンプリングデータ取得時間:", endTm - startTm, "ms")
                })
            })
        }
        // 比較分析の場合
        const compare = result.compare
        if (compare !== null && compare !== undefined) {
            if (compare.query) {
                const sizeOfCompare = compare.get_num_of_customers()
                if (sizeOfCompare > MAX_SAMPLE_SIZE) {
                    Promise.all([this.opensearch.get_sample_customers(compare.query, SortOrderType.Asc), this.opensearch.get_sample_customers(compare.query, SortOrderType.Desc)]).then(res => {
                        (result.compare as AnalysisResultImpl).set_sample_customers(res[0], res[1]).then(() => {
                            const endTm = performance.now()
                            console.log("compare 20000サンプリングデータ取得時間:", endTm - startTm, "ms")
                        })
                    })
                } else {
                    this.opensearch.get_sample_customers(compare.query, SortOrderType.Asc, sizeOfCompare).then(res => {
                        (result.compare as AnalysisResultImpl).set_sample_customers(res, []).then(() => {
                            const endTm = performance.now()
                            console.log("compare " + sizeOfCompare + "サンプリングデータ取得時間:", endTm - startTm, "ms")
                        })
                    })
                }
            }
        }
    }

    async trailTrack(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, numOfCustomers: number, resultObject: AnalysisResultImpl): Promise<void> {
        const stTime: number = performance.now()
        let pvTime: number = stTime
        const allCustomers = await this.opensearch.get_page_of_customers(query, DefaultSearchSortOption)
        resultObject.set_all_customers(allCustomers)
        await watch.finishJob(JobTypes.Trails, side)
        let lpTime = performance.now()
        console.log("顧客リスト取得時間:", lpTime - pvTime, "ms")
    }

    async sampleAscTrack(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl): Promise<void> {
        const stTime: number = performance.now()
        const sampleCustAsc = await this.opensearch.get_sample_customers(query, SortOrderType.Asc)
        resultObject.set_sample_customers(sampleCustAsc, [])
        await watch.finishJob(JobTypes.SamplingAsc, side)
        const lpTime = performance.now()
        console.log("サンプルASC取得時間:", lpTime - stTime, "ms")
    }

    async sampleDescTrack(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl): Promise<void> {
        const stTime: number = performance.now()
        const sampleCustDesc = await this.opensearch.get_sample_customers(query, SortOrderType.Desc)
        resultObject.set_sample_customers([], sampleCustDesc)
        await watch.finishJob(JobTypes.SamplingDesc, side)
        const lpTime = performance.now()
        console.log("サンプルDESC取得時間:", lpTime - stTime, "ms")
    }

    async get_area_count(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl, abortController?: AbortController): Promise<OSResAreaCount> {
        let pvTime: number = performance.now()
        const areacount = await this.opensearch.get_area_count(query, query.stay_threshold, resultObject.layout, abortController)
        await watch.finishJob(JobTypes.AreaCount, side)
        let lpTime = performance.now()
        console.log("エリアカウント取得時間:", lpTime - pvTime, "ms")
        pvTime = lpTime
        return areacount
    }

    async get_area_count_zero(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl, abortController?: AbortController): Promise<OSResAreaCount> {
        let pvTime: number = performance.now()
        const areacountZero = await this.opensearch.get_area_count(query, 0, resultObject.layout, abortController)
        await watch.finishJob(JobTypes.AreaCountZero, side)
        let lpTime = performance.now()
        console.log("エリアカウントゼロ取得時間:", lpTime - pvTime, "ms")
        pvTime = lpTime
        return areacountZero
    }

    async areaCountTrack(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl, abortController?: AbortController): Promise<void> {
        let pvTime: number = performance.now()
        const [areaCount, areaCountZero] = await Promise.all([
            this.get_area_count(query, watch, side, resultObject, abortController),
            this.get_area_count_zero(query, watch, side, resultObject, abortController)
        ])
        resultObject.set_area_count(areaCount, areaCountZero)
        const lpTime = performance.now()
        console.log("areaCountTrack 完了:", lpTime - pvTime, "ms")
    }

    async normalTrack(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide, resultObject: AnalysisResultImpl, numberOfCustomers: number) {
        const stTime: number = performance.now()
        let lpTime: number
        let pvTime: number = stTime

        const longstayCustomers = await this.opensearch.get_longstay_customers(query, numberOfCustomers)
        resultObject.set_longstay_customers(longstayCustomers)
        await watch.finishJob(JobTypes.LongStay, side)
        lpTime = performance.now()
        console.log("長滞在取得時間:", lpTime - pvTime, "ms")
        pvTime = lpTime

        const manystayCustomers = await this.opensearch.get_manystay_customers(query, numberOfCustomers)
        resultObject.set_manystay_customers(manystayCustomers)
        await watch.finishJob(JobTypes.ManyStay, side)
        lpTime = performance.now()
        console.log("多滞在取得時間:", lpTime - pvTime, "ms")
        pvTime = lpTime
        console.log("normalTrack 完了:", lpTime - stTime, "ms")
    }

    async get_number_of_customers(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide): Promise<number> {
        return new Promise((resolve, reject) => {
            let stTime: number = performance.now()
            this.opensearch.get_number_of_customers(query).then((numOfCustomers) => {
                watch.setTotalCustomer(numOfCustomers, side)
                watch.finishJob(JobTypes.Total, side).then(() => {
                    const lpTime = performance.now()
                    console.log("顧客数取得時間:", lpTime - stTime, "ms")
                    resolve(numOfCustomers)
                })
            }).catch((e) => {
                reject(e)
            })
        })
    }

    async get_layout_and_active_area(query: ResSearchQuery, watch: RemainTimeWatcher, side: CompareSide): Promise<[FullLayout, ResArea[]]> {
        return new Promise((resolve, reject) => {
            let stTime: number = performance.now()
            this.opensearch.get_layout(query.layout_id).then((layout) => {
                const layoutSingle = new FullLayout(layout);
                layoutSingle.getActiveAreaDefs(query.start_date).then((activeAreaListSingle) => {
                    watch.finishJob(JobTypes.Layout, side).then(() => {
                        const idx = (side === CompareSideType.Primary) ? 0 : 1
                        watch.areaCount[idx] = activeAreaListSingle.length
                        const lpTime = performance.now()
                        console.log("レイアウト取得時間:", lpTime - stTime, "ms")
                        resolve([layoutSingle, activeAreaListSingle])                        
                    })
                }).catch((e) => {
                    reject(e)
                })
            }).catch((e) => {
                reject(e)
            })
        })
    }

    reset_analysis(): boolean {
        return true
    }

    async get_trails(shop_id: number, human_ids: number[], progres_cb: (percentage: number) => void): Promise<Record<string, Coord[]>> {
        const result: Record<string, Coord[]> = {};
        progres_cb(0)
        let success = 0
        for await (const human_id of human_ids) {
            const response: AxiosResponse<TrailJSON[]> = await axios.get(`/trails/v3/${shop_id}/${Math.floor(human_id/1_000_000)}/${human_id}.json`, {
                validateStatus: (status) => ((status >= 200 && status < 300) || (status >= 400 && status < 500)),
            });
            if (response.status === 200) {
                if (response.data.length > 0) {
                    result[human_id] = response.data.map(t => new Coord(t.x, t.y))
                } else {
                    console.log("動線が空っぽ human_id=", human_id, "response:", response.data)
                    const dmy = new Coord(0, 0)
                    result[human_id] = [dmy]
                }
            } else if (response.status !== 200) {
                console.error(`動線取得失敗 human_id=${human_id}, response:`, response)
                const dmy = new Coord(0, 0)
                result[human_id] = [dmy]
            }
            success++;
            progres_cb(success / human_ids.length)
        }
        return result;
    }

    async get_dashboard_info(shop_id: number, target_date: string, abortController: AbortController): Promise<OneShopDashboardResult> {
        const options = {signal: abortController.signal, ...axiosOptions}
        const layoutPair: AxiosResponse<ResDashboardLayoutPair> = await axios.get(
            this.endpoint + `/dashboard/layout/${shop_id}/${target_date}`, options)
        if (layoutPair.status !== 200) {
            throw new APIError(`単一ダッシュボード用レイアウト情報取得失敗: ${shop_id}, ${target_date}`);
        }
        const compare_date = layoutPair.data.compare_date;
        const dashboadrdInfo4 = Promise.all([
            axios.get(
                this.endpoint + `/dashboard/enter_count/day/${layoutPair.data.layout_set_id}/${target_date}/31`, options),
            axios.post(
                this.endpoint + `/dashboard/pos-timeline/day/${target_date}/31`, {
                "shop_ids": [shop_id]
            }, options),
            axios.get(
                this.endpoint + `/dashboard/enter_count/month/${layoutPair.data.layout_set_id}/${target_date}/31`, options),
            axios.post(
                this.endpoint + `/dashboard/pos-timeline/month/${target_date.substring(0, 4)}-12-31/12`, {
                "shop_ids": [shop_id]
            }, options)
        ]);
        const [currentTimelineRes, currentPosTimelineRes, yearlongTimelineRes, yearlongPosTimelineRes]: [
            AxiosResponse<DBPassingCount[]>,
            AxiosResponse<{ "pos": ResPosTimeline[] }>,
            AxiosResponse<DBPassingCount[]>,
            AxiosResponse<{ "pos": ResPosTimeline[] }>
        ] = await dashboadrdInfo4;
        console.log("currentTimelineRes", currentTimelineRes)
        const target_day_layout: FullLayout = new FullLayout(layoutPair.data.target_layout)
        const target_day_query: ResSearchQuery = {
            query_log_id: 0, query_saved_at: 0,
            shop_id:shop_id,
            layout_id: layoutPair.data.target_layout.layout_id,
            start_date: layoutPair.data.target_date,
            end_date: layoutPair.data.next_of_target_date,
            start_time: [0, 0], end_time: [24, 0],
            target_weekdays: {
                "Mon": true, "Tue": true, "Wed": true, "Thu": true, "Fri": true,
                "Sat": true, "Sun": true,
            },
            target_customer: "all",
            remove_clerk: false,
            stay_threshold: layoutPair.data.threshold,
        }
        const compare_day_layout: FullLayout = new FullLayout(layoutPair.data.target_layout)
        const compare_day_query: ResSearchQuery = {
            query_log_id: 0, query_saved_at: 0,
            shop_id:shop_id,
            layout_id: layoutPair.data.compare_layout.layout_id,
            start_date: layoutPair.data.compare_date,
            end_date: layoutPair.data.next_of_compare_date,
            start_time: [0, 0], end_time: [24, 0],
            target_weekdays: {
                "Mon": true, "Tue": true, "Wed": true, "Thu": true, "Fri": true,
                "Sat": true, "Sun": true,
            },
            target_customer: "all",
            remove_clerk: false,
            stay_threshold: layoutPair.data.threshold,
        }
        var target_day_areacount: OSResAreaCount = {} as OSResAreaCount
        var compare_day_areacount: OSResAreaCount = {} as OSResAreaCount
        await Promise.all([
            this.opensearch.get_area_count(target_day_query, 0, target_day_layout, abortController)
                .then((res) => target_day_areacount = res),
            this.opensearch.get_area_count(compare_day_query, 0, compare_day_layout, abortController)
                .then((res) => compare_day_areacount = res)
        ])
        if (!target_day_areacount.hasOwnProperty('aggregations')) {
            target_day_areacount.aggregations = {};
        }
        if (!compare_day_areacount.hasOwnProperty('aggregations')) {
            compare_day_areacount.aggregations = {};
        }
        const shop2counts: AxiosResponse<Record<string, ResAllDashbord>> = await axios.get(
            this.endpoint + `/dashboard/count/${shop_id}/day/${target_date}`, options)
        if (shop2counts.status != 200) {
            throw new APIError("平均滞在率取得失敗")
        }
        const area2stayTotal: Record<string, number> = {}
        if (shop2counts.data[shop_id.toString()].counts.hasOwnProperty(target_date)) {
            for (const area of shop2counts.data[shop_id.toString()].counts[target_date].area_count) {
                const aid = area.area_id.toString()
                area2stayTotal[aid] = area.stay_total
            }
        }
        const compareArea2stayTotal: Record<string, number> = {}
        if (shop2counts.data[shop_id.toString()].counts.hasOwnProperty(compare_date)) {
            for (const area of shop2counts.data[shop_id.toString()].counts[compare_date].area_count) {
                const aid = area.area_id.toString()
                compareArea2stayTotal[aid] = area.stay_total
            }
        }
        
        const pos_data = (currentPosTimelineRes.data.pos!= null ? currentPosTimelineRes.data.pos : (
            currentTimelineRes.data.map(t => { return {
                "shop_id": shop_id,
                "amount_of_paid": 0,
                "count_of_purchasers": 0,
            }})
        ));
        const yearlong_pos_data = (yearlongPosTimelineRes.data.pos != null ? yearlongPosTimelineRes.data.pos : (
            currentTimelineRes.data.map(t => { return {
                "shop_id": shop_id,
                "amount_of_paid": 0,
                "count_of_purchasers": 0,
            }})
        ));
        return {
            shop_id: shop_id,
            current_timeline: {
                "current_date_list": currentTimelineRes.data.map(t => t.date),
                "number_of_customers": currentTimelineRes.data.map(t => t.count),
                "amount_sold": pos_data.map(d => d.amount_of_paid),
                "number_of_purchasers": pos_data.map(d => d.count_of_purchasers),
            },
            target_day_areacount: {
                date: target_date,
                number_of_customers: target_day_areacount.hits.total.value,
                layout: target_day_layout,
                area_count: CalcUtils.removeNull(Object.keys(target_day_areacount.aggregations).map((key: string) => {
                    const m = key.match(/-(\d+)$/)
                    if (m == null) {return null}
                    const aid = m[1]
                    const acount = target_day_areacount.aggregations[key]
                    const v: DashboardAreaCount = {
                        "area": target_day_layout.getArea(aid),
                        "stay_count": acount.pass.doc_count,
                        "stay_time_total": (area2stayTotal.hasOwnProperty(aid) ? area2stayTotal[aid] : 0)
                    }
                    return v
                }))
            },
            week_ago__areacount: {
                date: "",
                number_of_customers: compare_day_areacount.hits.total.value,
                layout: compare_day_layout,
                area_count: CalcUtils.removeNull(Object.keys(compare_day_areacount.aggregations).map((key: string) => {
                    const m = key.match(/-(\d+)$/)
                    if (m == null) {return null}
                    const aid = m[1]
                    const acount = compare_day_areacount.aggregations[key]
                    const v: DashboardAreaCount = {
                        "area": compare_day_layout.getArea(aid),
                        "stay_count": acount.pass.doc_count,
                        "stay_time_total": (compareArea2stayTotal.hasOwnProperty(aid) ? compareArea2stayTotal[aid] : 0)
                    }
                    return v
                }))
            },
            yearlong_timeline: {
                number_of_customers: yearlongTimelineRes.data.map(t => t.count),
                amount_sold: yearlong_pos_data.map(d => d.amount_of_paid),
                number_of_purchasers: yearlong_pos_data.map(d => d.count_of_purchasers),
            }
        }
    }

    async get_all_dashboard_info(target_date: string, time_unit: "day" | "week", abortController: AbortController): Promise<AllShopDashboardResult[]> {
        const options = {signal: abortController.signal, ...axiosOptions}
        const shop2counts: AxiosResponse<Record<string, ResAllDashbord>> = await axios.get(
            this.endpoint + `/dashboard/count/all/${time_unit}/${target_date}`, options)
        if (shop2counts.status !== 200) {
            throw new APIError(`全店ダッシュボード時系列データ取得失敗: ${target_date}`);
        }
        const len  = (time_unit === "day") ? 31 : 8
        const entercount: Record<string, Record<string, number>> = {};
        const enter_counts = [];
        for (const shopID in shop2counts.data) {
            const shopid = shopID;
            enter_counts.push(axios.get(
                this.endpoint + `/dashboard/enter_count/${time_unit}/${shop2counts.data[shopID].layout_set_id}/${target_date}/${len}`, options).then(
                    (currentTimelineRes: AxiosResponse<DBPassingCount[]>) => {
                        entercount[shopid] = {}
                        if (currentTimelineRes.data && currentTimelineRes.data.length > 0) {
                            for (let c of currentTimelineRes.data) {
                                entercount[shopid][c.date] = c.count
                            }
                        }
            }));
        }
        await Promise.all(enter_counts);
        const currentPosTimelineRes: AxiosResponse<{"pos":ResPosTimeline[]}> = await axios.post(
            this.endpoint + `/dashboard/pos-timeline/${time_unit}/${target_date}/${len}`, {
                "shop_ids": Object.keys(shop2counts.data).map(v => parseInt(v))
            }, options)
        const result: AllShopDashboardResult[] = []
        for (let shopID in shop2counts.data) {
            const shopIdNum = parseInt(shopID)
            const date_list = Object.keys(shop2counts.data[shopID].counts)
            date_list.sort((d1, d2) => ((d1 < d2) ? -1 : ((d2 < d1) ? 1 : 0)))
            const number_of_customers = date_list.map(t => (entercount[shopID][t] || 0))
            const area_count_total = date_list.map(t => (
                (entercount[shopID][t] == null) ? 0 : (
                    (shop2counts.data[shopID].counts[t] == null) ? 0 : (
                        shop2counts.data[shopID].counts[t].area_count.reduce((sum, ac) => sum + ac.stay_humans, 0)
                    )
                )))
            const pos_data = (currentPosTimelineRes.data.pos != null ? currentPosTimelineRes.data.pos : (
                date_list.map(v => { return {
                    "shop_id": shopIdNum,
                    "amount_of_paid": 0,
                    "count_of_purchasers": 0
                }}
                )
            )).filter(d => d.shop_id === shopIdNum)
            const elem = {
                shop_id: parseInt(shopID),
                date_list: date_list,
                number_of_customers: number_of_customers,
                average_area_count: area_count_total.map((t, index) => {
                    if (number_of_customers[index] <= 0) {
                        return 0
                    }
                    return t/number_of_customers[index]
                }),
                amount_sold: pos_data.map(d => d.amount_of_paid),
                number_of_purchasers: pos_data.map(d => d.count_of_purchasers),
                amount_of_sold_items: date_list.map((e) => 0),
                average_stay_time: date_list.map((e) => 0),
            }
            result.push(elem)
        }
        return result
    }

    async set_dashboard_stay_threshold(stay_threshold_sec: number): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/dashboard/shop/threshold", {
            "threshold": stay_threshold_sec,
        }, axiosOptions)
        if (response.status !== 200) {
            throw new APIError("クエリ保存失敗")
        }
        return response.data
    }

    async set_all_dashboard_stay_threshold(stay_threshold_sec: number): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/dashboard/all/threshold", {
            "threshold": stay_threshold_sec,
        }, axiosOptions)
        if (response.status !== 200) {
            throw new APIError("クエリ保存失敗")
        }
        return response.data
    }

    async set_all_dashboard_order(ordered_shop_ids: number[]): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/analyze/log", {
            "shop_ids": ordered_shop_ids,
        }, axiosOptions)
        if (response.status !== 200) {
            throw new APIError("クエリ保存失敗")
        }
        return true
    }

    async save_queries(query: ResSearchQuery): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/analyze/log", query, axiosOptions)
        if (response.status !== 200) {
            throw new APIError("クエリ保存失敗")
        }
        return true
    }

    async get_saved_queries(): Promise<ResSearchQuery[]> {
        const response: AxiosResponse<ResSearchQuery[]> = await axios.get(this.endpoint + "/analyze/log", axiosOptions)
        if (response.status !== 200) {
            throw new APIError("クエリ保存失敗")
        }
        return response.data
    }
}