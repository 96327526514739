import MapWriter from "./MapWriter"
import { getAreaBorderColor } from "./ColorUtil"
import Utils from "../lib/Utils"
import { ResArea, ResLayout } from "../api/data/analysis/FullLayout"
import { AreaType } from "../api/data/core/Enums"

// バッジの高さ[px]
const PX_VALUE_HEIGHT = 1 + 12 + 1
// バッジの角アール
const ROUND_SIZE = 3
// 円マークの半径[px]
const PX_LABEL_RADIUS = 7
// 円マークのエリアはみ出し量[px]
const PX_CIRCLE_OUT = 7 - 2
// 円マークのエリア被り量[px]
const PX_CIRCLE_IN = 7 + 2
// 引き出し線の太さ[px]
const LEADER_LINE_WIDTH = 2
// 引き出し線の始点の円の半径[px]
const LEADER_CIRCLE_RADIUS = 2
// オブジェクトマップの単位サイズ
const MES_UNIT = 3  // 3px = 1mes

/**
 * 数値表示バッジの幅を取得します。
 * 
 * @param strlen 
 * @returns [px]
 */
const getPillBadgePxWidth = (strlen: number) => {
    if (strlen < 4) return (9 + 5 * 3 + 9)
    if (strlen < 6) return (7 + 5 * strlen + 7)
    return (5 + 5 * strlen + 5)
}

// エリアからの方向
const DirectionFromAreaType = {
    TopLeft: "tl",
    TopRight: "tr",
    BottomRight: "br",
    BottomLeft: "bl",
    Top: "top",
    Right: "right",
    Bottom: "bottom",
    Left: "left"
} as const
type DirectionFromArea = typeof DirectionFromAreaType[keyof typeof DirectionFromAreaType]

// マップ上の空き地探索結果
type SearchResultType = {
    suc: boolean
    x: number
    y: number
    reason?: string
    endof?: DirectionFromArea
}

// 指定位置の被り調査結果
type FittingResultType = {
    suc: boolean
    x: number
    y: number
    ary?: Array<Array<string | undefined>>
}

// 探索用のエリア情報
type AreaWorkType = {
    key: string
    id: number
    num: string
    x: number
    y: number
    w: number
    h: number
    value: string
    vX: number
    vY: number
    vW: number
    vH: number
    shadow?: Array<Array<string | undefined>>
    type: AreaType
}

/**
 * 描画管理クラス
 * 
 * 各描画オブジェクトが重ならないように配置する
 * メッシュを定義して、そこにオブジェクトをマーキングしていく。チェック時にマーキングとぶつからないかを検査する。
 * ３ピクセルをメッシュの大きさにしている。マーキングは、エリア域は"a"+id、エリア番号は"c"+id、値は"b"+id。
 */
class AreaMapWriter extends MapWriter {

    private mesMap: Array<Array<string | undefined>>
    private inspectionAreas: Record<string, AreaWorkType | undefined> = {}
    private completeAreas: Record<string, AreaWorkType> = {}
    private areaIds: Array<number> = []
    private rowSize: number     // メッシュの縦の数
    private colSize: number     // メッシュの横の数

    constructor(layout: ResLayout, areaList: ResArea[], canvasWidth: number, canvasHeigt: number) {
        super(layout, areaList, canvasWidth, canvasHeigt)
        // 全体マップ作成
        this.rowSize = Math.ceil(layout.pixel_height / MES_UNIT)
        this.colSize = Math.ceil(layout.pixel_width / MES_UNIT)
        this.mesMap = new Array(this.rowSize)
        for (let i = 0; i < this.rowSize; i++) {
            this.mesMap[i] = new Array(this.colSize)
        }
        // エリアを配置(単位：mes)
        //const list = layout.area_list
        if (areaList) {
            const outer = Math.ceil(PX_CIRCLE_OUT / MES_UNIT)
            const inner = Math.ceil(PX_CIRCLE_IN / MES_UNIT)
            areaList.forEach((area, idx) => {
                this.areaIds.push(area.area_id)
                const id = "a" + area.area_id
                let minX: number | undefined = undefined
                let minY: number | undefined = undefined
                let maxX: number | undefined = undefined
                let maxY: number | undefined = undefined
                let hasError = false
                // エリアのMap登録
                area.cell_ids.forEach(cel => {
                    const mesX = this.cel2mes(cel.x)
                    const mesY = this.cel2mes(cel.y)
                    if (mesX >= 0 && mesX < this.colSize && mesY >= 0 && mesY < this.rowSize) {
                        this.mesMap[mesY][mesX] = id
                        if (minX === undefined || (minX > mesX)) minX = mesX
                        if (minY === undefined || (minY > mesY)) minY = mesY
                        if (maxX === undefined || (maxX < mesX)) maxX = mesX
                        if (maxY === undefined || (maxY < mesY)) maxY = mesY
                    } else {
                        hasError = true
                    }
                })
                if (hasError) console.error("ラベルMap範囲を超えたエリア area:", area)
                // エリアの検査対象オブジェクト作成
                if (maxX !== undefined && maxY !== undefined && minX !== undefined && minY !== undefined && maxX >= minX && maxY >= minY) {
                    this.inspectionAreas[id] = {
                        key: id,
                        id: area.area_id,
                        num: "" + area.area_number,
                        x: minX,
                        y: minY,
                        w: maxX - minX,
                        h: maxY - minY,
                        value: "",
                        vX: 0,
                        vY: 0,
                        vW: 0,
                        vH: 0,
                        type: area.area_type
                    }
                } else {
                    console.error("エリアの検査対象オブジェクト作成に失敗 id,minX,minY,maxX,maxY:", id, minX, minY, maxX, maxY)
                }
                // 円マークのMap登録
                if (minX && minY && (minY >= outer) && (minX >= outer)) {
                    const v = "c" + area.area_id
                    for (let pty = minY - outer; pty < minY + inner; pty++) {
                        for (let ptx = minX - outer; ptx < minX + inner; ptx++) {
                            this.mesMap[pty][ptx] = v
                        }
                    }
                }
            })
        }
    }
    cel2mes(cel: number) {
        const px = cel * this.area_unitcell_pixel
        return Math.ceil(px / MES_UNIT)
    }
    px2mes(px: number) {
        return Math.ceil(px / MES_UNIT)
    }
    async getArrayArray(row: number, col: number) {
        const ary = []
        const nums = new Array(row)
        for await (let i of nums) {
            const a = new Array(col).fill(undefined)
            ary.push(a)
        }
        return ary
    }
    async fasterFitting(key: string, x: number, y: number, w: number, h: number): Promise<FittingResultType> {
        if ((this.mesMap[y][x] === undefined || this.mesMap[y][x] === key) &&
            (this.mesMap[y][x + w - 1] === undefined || this.mesMap[y][x + w - 1] === key) &&
            (this.mesMap[y + h - 1][x] === undefined || this.mesMap[y + h - 1][x] === key) &&
            (this.mesMap[y + h - 1][x + w - 1] === undefined || this.mesMap[y + h - 1][x + w - 1] === key))
            return await this.fittingCheck(key, x, y, w, h)
        return { suc: false, x: x, y: y }
    }
    async fittingCheck(key: string, x: number, y: number, w: number, h: number): Promise<FittingResultType> {
        let suc = true
        const ary = await this.getArrayArray(h, w)
        for (let py = 0; py < h; py++) {
            for (let px = 0; px < w; px++) {
                const p: string | undefined = this.mesMap[py + y][px + x]
                if (p !== undefined && p !== key) {
                    suc = false
                    ary[py][px] = p
                }
            }
        }
        //if (suc) console.log("fittingCheck success key,x,y:",key,x,y)
        return { suc: suc, ary: ary, x: x, y: y }
    }
    async getShiftVolume(ary: Array<Array<string | undefined>>, w: number, h: number) {
        // shift量算出
        let shiftX = 0
        let shiftY = 0
        for (let py = 0; py < h; py++) {
            let cx = 0
            for (let px = 0; px < w; px++) {
                const p: string | undefined = ary[py][px]
                if (p !== undefined) cx++
            }
            if (cx > 0) {
                shiftY++
                if (shiftX < cx) shiftX = cx
            }
        }
        return { x: shiftX, y: shiftY }
    }
    async getObliqueVolume(ary: Array<Array<string | undefined>>, w: number, h: number) {
        let x = 0
        let y = 0
        const axsX = new Array(w)
        const axsY = new Array(h)
        for (let py = 0; py < h; py++) {
            let cnt = 0
            for (let px = 0; px < w; px++) {
                const p: string | undefined = ary[py][px]
                if (p !== undefined) cnt++
            }
            axsY[py] = (cnt < w) ? cnt : undefined
            if (axsY[py] > x) x = axsY[py]
        }
        for (let px = 0; px < w; px++) {
            let cnt = 0
            for (let py = 0; py < h; py++) {
                const p: string | undefined = ary[py][px]
                if (p !== undefined) cnt++
            }
            axsX[px] = (cnt < h) ? cnt : undefined
            if (axsX[px] > y) y = axsX[px]
        }
        return { x: x, y: y }
    }
    async getDirectionalPosition(x: number, y: number) {
        const halfX = Math.round(this.colSize / 2)
        const halfY = Math.round(this.rowSize / 2)
        if (y < halfY) {
            return (x < halfX) ? DirectionFromAreaType.TopLeft : DirectionFromAreaType.TopRight
        } else {
            return (x < halfX) ? DirectionFromAreaType.BottomLeft : DirectionFromAreaType.BottomRight
        }
    }
    async horizontalSearch(key: string, startX: number, startY: number, length: number, w: number, h: number, reverse: boolean): Promise<SearchResultType> {
        if (startY < 0) return { suc: false, x: startX, y: startY, endof: DirectionFromAreaType.Top }
        if ((startY + h) > this.rowSize) return { suc: false, x: startX, y: startY, endof: DirectionFromAreaType.Bottom }
        const stX = (startX < 0) ? 0 : startX
        const edX = ((startX + length) > this.colSize) ? this.colSize - w - 1 : (startX + length - w)
        if (stX >= edX) return { suc: false, x: startX, y: startY, reason: "stX >= edX" }
        let prmAry: Promise<any>[] = []
        for (let i = stX; i <= edX; i++) {
            const p = this.fasterFitting(key, i, startY, w, h)
            prmAry.push(p)
        }
        const results = await Promise.all(prmAry)
        if (reverse) results.reverse()
        for (let el of results) {
            if (el.suc) return { suc: true, x: el.x, y: el.y }
        }
        return { suc: false, x: startX, y: startY, reason: "not found" }
    }
    async verticalSearch(key: string, startX: number, startY: number, length: number, w: number, h: number, reverse: boolean): Promise<SearchResultType> {
        if (startX < 0) return { suc: false, x: 0, y: 0, endof: DirectionFromAreaType.Left }
        if ((startX + w) > this.colSize) return { suc: false, x: 0, y: 0, endof: DirectionFromAreaType.Right }
        const stY = (startY < 0) ? 0 : startY
        const edY = ((startY + length) > this.rowSize) ? this.rowSize - h - 1 : (startY + length - h)
        if (stY >= edY) return { suc: false, x: startX, y: startY, reason: "stY >= edY" }
        let prmAry: Promise<any>[] = []
        for (let i = stY; i <= edY; i++) {
            const p = this.fasterFitting(key, startX, i, w, h)
            prmAry.push(p)
        }
        const results = await Promise.all(prmAry)
        if (reverse) results.reverse()
        for (let el of results) {
            if (el.suc) return { suc: true, x: el.x, y: el.y }
        }
        return { suc: false, x: startX, y: startY, reason: "not found" }
    }
    async searchTop(key: string, centerX: number, centerY: number, w: number, h: number, distance: number, reverse: boolean): Promise<SearchResultType> {
        //上辺検索
        const x = centerX - distance
        const y = centerY - distance
        const l = 1 + distance * 2
        const res1 = await this.horizontalSearch(key, x, y, l, w, h, reverse)
        return res1
    }
    async searchBottom(key: string, centerX: number, centerY: number, w: number, h: number, distance: number, reverse: boolean): Promise<SearchResultType> {
        //底辺検索
        const x = centerX - distance
        const y = centerY + distance
        const l = 1 + distance * 2
        const res4 = await this.horizontalSearch(key, x, y, l, w, h, reverse)
        return res4
    }
    async searchLeft(key: string, centerX: number, centerY: number, w: number, h: number, distance: number, reverse: boolean): Promise<SearchResultType> {
        //左辺検索
        const x = centerX - distance
        const y = centerY - distance + 1
        const l = 1 + (distance - 1) * 2
        const res2 = await this.verticalSearch(key, x, y, l, w, h, reverse)
        return res2
    }
    async searchRight(key: string, centerX: number, centerY: number, w: number, h: number, distance: number, reverse: boolean): Promise<SearchResultType> {
        //右辺検索
        const x = centerX + distance
        const y = centerY - distance + 1
        const l = 1 + (distance - 1) * 2
        const res3 = await this.verticalSearch(key, x, y, l, w, h, reverse)
        return res3
    }
    async search4VacantFromNearby(key: string, centerX: number, centerY: number, w: number, h: number, distance: number): Promise<SearchResultType> {
        const dir = await this.getDirectionalPosition(centerX, centerY)
        let res1: SearchResultType | undefined = undefined
        let res2: SearchResultType | undefined = undefined
        let res3: SearchResultType | undefined = undefined
        let res4: SearchResultType | undefined = undefined
        if (dir === DirectionFromAreaType.TopLeft) {
            res1 = await this.searchTop(key, centerX, centerY, w, h, distance, false)
            if (res1.suc) return res1
            res2 = await this.searchLeft(key, centerX, centerY, w, h, distance, false)
            if (res2.suc) return res2
            res3 = await this.searchRight(key, centerX, centerY, w, h, distance, false)
            if (res3.suc) return res3
            res4 = await this.searchBottom(key, centerX, centerY, w, h, distance, false)            
            if (res4.suc) return res4
        } else if (dir === DirectionFromAreaType.TopRight) {
            res1 = await this.searchTop(key, centerX, centerY, w, h, distance, true)
            if (res1.suc) return res1
            res3 = await this.searchRight(key, centerX, centerY, w, h, distance, false)
            if (res3.suc) return res3
            res2 = await this.searchLeft(key, centerX, centerY, w, h, distance, false)
            if (res2.suc) return res2
            res4 = await this.searchBottom(key, centerX, centerY, w, h, distance, true)            
            if (res4.suc) return res4
        } else if (dir === DirectionFromAreaType.BottomRight) {
            res4 = await this.searchBottom(key, centerX, centerY, w, h, distance, true)
            if (res4.suc) return res4
            res3 = await this.searchRight(key, centerX, centerY, w, h, distance, true)
            if (res3.suc) return res3
            res2 = await this.searchLeft(key, centerX, centerY, w, h, distance, true)
            if (res2.suc) return res2
            res1 = await this.searchTop(key, centerX, centerY, w, h, distance, true)
            if (res1.suc) return res1
        } else {
            res4 = await this.searchBottom(key, centerX, centerY, w, h, distance, false)            
            if (res4.suc) return res4
            res2 = await this.searchLeft(key, centerX, centerY, w, h, distance, true)
            if (res2.suc) return res2
            res3 = await this.searchRight(key, centerX, centerY, w, h, distance, true)
            if (res3.suc) return res3
            res1 = await this.searchTop(key, centerX, centerY, w, h, distance, false)
            if (res1.suc) return res1
        }
        // 継続か終了か判断
        if (res1 && res2 && res3 && res4 && res1.endof && res2.endof && res3.endof && res4.endof) {
            return { suc: false, x: -1, y: -1, reason: "fail" }
        } else {
            const result = await this.search4VacantFromNearby(key, centerX, centerY, w, h, distance + 1)
            return result
        }        
    }
    async markingValueBox(area: AreaWorkType) {
        for (let y = 0; y < area.vH; y++) {
            for (let x = 0; x < area.vW; x++) {
                this.mesMap[y + area.vY][x + area.vX] = "b" + area.id
            }
        }
    }
    async getStartPos(area : AreaWorkType) {
        const centerX = area.x + Math.floor(area.w / 2)
        const centerY = area.y + Math.floor(area.h / 2)
        const stX = centerX - Math.floor(area.vW / 2)
        const stY = centerY - Math.floor(area.vH / 2)
        return { stX: stX, stY: stY}
    }
    async planValuePlacementNormal(area: AreaWorkType) {
        // 通常の配置（エリアの中心）を試みる
        const { stX, stY } = await this.getStartPos(area)
        const result = await this.fittingCheck(area.key, stX, stY, area.vW, area.vH)
        if (result.suc) {
            area.vX = stX
            area.vY = stY
            await this.markingValueBox(area)
            this.completeAreas[area.key] = area
            this.inspectionAreas[area.key] = undefined
            //console.log("calc STEP1:通常の配置 area:",area)
            return true
        } else {
            if (result.ary) area.shadow = result.ary
        }
        return false
    }
    async shiftDoubleDirection(area: AreaWorkType, tl: boolean, tr: boolean, br: boolean, bl: boolean) {
        if (area.shadow) {
            const { stX, stY } = await this.getStartPos(area)
            // shift量算出
            const shift = await this.getShiftVolume(area.shadow, area.vW, area.vH)
            // 左右に動かしてみる
            const reStX: number = (tl || bl) ? stX + shift.x + 1 : stX - shift.x - 1
            const res1 = await this.fittingCheck(area.key, reStX, stY, area.vW, area.vH)
            if (res1.suc) {
                area.vX = reStX
                area.vY = stY
                await this.markingValueBox(area)
                this.completeAreas[area.key] = area
                this.inspectionAreas[area.key] = undefined
                return true
            }
            // 上下に動かしてみる
            const reStY: number = (tl || tr) ? stY + shift.y : stY - shift.y
            const res2 = await this.fittingCheck(area.key, stX, reStY, area.vW, area.vH)
            if (res2.suc) {
                area.vX = stX
                area.vY = reStY
                await this.markingValueBox(area)
                this.completeAreas[area.key] = area
                this.inspectionAreas[area.key] = undefined
                return true
            }
        }
        return false
    }
    async shiftSingleDirection(area: AreaWorkType, tl: boolean, tr: boolean, br: boolean, bl: boolean) {
        if (area.shadow) {
            const { stX, stY } = await this.getStartPos(area)
            // shift量算出
            const shift = await this.getShiftVolume(area.shadow, area.vW, area.vH)
            let restX = stX
            let restY = stY
            if (tl && tr) {
                restY = stY + shift.y
            } else if (bl && br) {
                restY = stY - shift.y
            } else if (tl && bl) {
                restX = stX + shift.x + 1
            } else if (tr && br) {
                restX = stX - shift.x - 1
            }
            const res3 = await this.fittingCheck(area.key, restX, restY, area.vW, area.vH)
            if (res3.suc) {
                area.vX = restX
                area.vY = restY
                await this.markingValueBox(area)
                this.completeAreas[area.key] = area
                this.inspectionAreas[area.key] = undefined
                return true
            }
        }
        return false
    }
    async shiftObliqueDirection(area: AreaWorkType, tl: boolean, tr: boolean, br: boolean, bl: boolean) {
        if (area.shadow) {
            const { stX, stY } = await this.getStartPos(area)
            //斜め方向へ
            const shift = await this.getObliqueVolume(area.shadow, area.vW, area.vH)
            let restX = stX
            let restY = stY
            if (!tl) {
                restX = stX - shift.x - 1
                restY = stY - shift.y
            } else if (!tr) {
                restX = stX + shift.x + 1
                restY = stY - shift.y
            } else if (!br) {
                restX = stX + shift.x + 1
                restY = stY + shift.y
            } else if (!bl) {
                restX = stX - shift.x - 1
                restY = stY + shift.y
            }
            const res4 = await this.fittingCheck(area.key, restX, restY, area.vW, area.vH)
            if (res4.suc) {
                area.vX = restX
                area.vY = restY
                await this.markingValueBox(area)
                this.completeAreas[area.key] = area
                this.inspectionAreas[area.key] = undefined
                return true
            }
        }
        return false
    }
    async planValuePlacementShift(area: AreaWorkType) {
        // すこしずらして配置を試みる
        if (area.shadow) {
            //const { stX, stY } = await this.getStartPos(area)
            // 被る場所
            const tl = (area.shadow[0][0] === undefined) ? false : true
            const tr = (area.shadow[0][area.vW - 1] === undefined) ? false : true
            const br = (area.shadow[area.vH - 1][area.vW - 1] === undefined) ? false : true
            const bl = (area.shadow[area.vH - 1][0] === undefined) ? false : true
            let sum = 0
            if (tl) sum++
            if (tr) sum++
            if (br) sum++
            if (bl) sum++
            // 被る数で場合分け
            switch (sum) {
                case 0:
                    //console.error("通常の配置が出来ているはずなのに。area:", area)
                    break
                case 1:
                    return await this.shiftDoubleDirection(area, tl , tr, br, bl)
                case 2:
                    return await this.shiftSingleDirection(area, tl , tr, br, bl)
                case 3:
                    return await this.shiftObliqueDirection(area, tl , tr, br, bl)
                default:
                    //console.log("ここでは対応不能 sum,area", sum, area)
            }
        }
        return false
    }
    
    /**
     * カウント値を他のオブジェクトと被らないように配置します。
     * 
     * @param counts 
     */
    async arrangement(counts: number[], percentage: boolean) {
        // Count値をセット
        let target: Array<AreaWorkType> = []
        let idx: number = 0
        for await (let a of this.areaIds) {
            const key = "a" + a
            let v: string = ""
            if (percentage) {
                if (counts[idx] === 0 || Number.isNaN(counts[idx])) {
                    v = "0"
                } else {
                    v = (Math.round(counts[idx] * 10000) / 100) + ""
                }
            } else {
                v = Utils.comma(counts[idx])
            }
            // 検査オブジェクトの中からAreaWorkを取り出す
            let area = this.inspectionAreas[key]
            // ２回目以降はundefinedだから、completeAreasから戻してやる
            if (area === undefined) {
                area = this.completeAreas[key]
                this.inspectionAreas[key] = area
            }
            if (area) {
                area.value = v
                area.vW = this.px2mes(getPillBadgePxWidth(v.length))
                area.vH = this.px2mes(PX_VALUE_HEIGHT)
                target.push(area)
                //console.log("area:", area)
            } else {
                console.log("key,v,area:", key, v , area)
            }
            idx++
        }
        // メッシュの数値が入っている部分をクリアする。
        for (let row = 0; row < this.rowSize; row++) {
            for (let col = 0; col < this.colSize; col++) {
                const v = this.mesMap[row][col]
                if (v && v.slice(0, 1) === "b") this.mesMap[row][col] = undefined
            }
        }
        //STEP1:通常配置を試みる
        for await (let area of target) {
            await this.planValuePlacementNormal(area)
        }

        // STEP2：被る分だけシフト
        target = []
        this.areaIds.forEach(el => {
            const key = "a" + el
            const area = this.inspectionAreas[key]
            if (area) target.push(area)
        })
        for await (let area of target) {
            await this.planValuePlacementShift(area)
        }

        // STEP3：マップの空き地を探索
        target = []
        this.areaIds.forEach(el => {
            const key = "a" + el
            const area = this.inspectionAreas[key]
            if (area) target.push(area)
        })
        for await (let area of target) {
            const x = Math.round(area.x + (area.w / 2))
            const y = Math.round(area.y + (area.h / 2))
            const res = await this.search4VacantFromNearby(area.key, x, y, area.vW, area.vH, 1)
            if (res.suc) {
                area.vX = res.x
                area.vY = res.y
                await this.markingValueBox(area)
                this.completeAreas[area.key] = area
                this.inspectionAreas[area.key] = undefined
            }            
        }
        //console.log("探索終了。this.inspactionAreas, completeAreas:", this.inspectionAreas, this.completeAreas)
    }

    async drawBorder(ctx: CanvasRenderingContext2D, areaList: ResArea[]) {
        for await (let area of areaList) {
            const lines = this.convertCells2DrawLines(area.cell_ids)
            const color = getAreaBorderColor(area.area_type)
            await this.drawAreaBorder(ctx, lines, color)
        }
    }

    async drawLabel(ctx: CanvasRenderingContext2D) {
        for (const id in this.completeAreas) {
            const area = this.completeAreas[id]
            const color = getAreaBorderColor(area.type)
            //let w = PX_LABEL_RADIUS
            const x = area.x * MES_UNIT + this.origin_x + this.vtLeft
            const y = area.y * MES_UNIT + this.origin_y + this.vtTop
            await this.drawAreaNumber(ctx, x, y, PX_LABEL_RADIUS, color, area.num)
        }
    }

    async drawLeaderLine(ctx: CanvasRenderingContext2D, bgCol: string) {
        for (const id in this.completeAreas) {
            const area = this.completeAreas[id]
            const x = area.vX * MES_UNIT + this.origin_x + this.vtLeft
            const y = area.vY * MES_UNIT + this.origin_y + this.vtTop
            const w = area.vW * MES_UNIT
            const h = area.vH * MES_UNIT
            const centX = Math.round((area.x + (area.w / 2)) * MES_UNIT + this.origin_x + this.vtLeft)
            const centY = Math.round((area.y + (area.h / 2)) * MES_UNIT + this.origin_y + this.vtTop)
            const vcX = Math.round(x + w / 2)
            const vcY = Math.round(y + h / 2)
            ctx.strokeStyle = bgCol;
            ctx.fillStyle = bgCol;
            // 引き出し線
            ctx.beginPath()
            ctx.arc(centX, centY, LEADER_CIRCLE_RADIUS, 0, 2 * Math.PI, false)
            ctx.fill()
            ctx.stroke()
            ctx.lineWidth = LEADER_LINE_WIDTH
            ctx.beginPath()
            ctx.moveTo(centX, centY)
            ctx.lineTo(vcX, vcY)
            ctx.stroke()
        }
    }
    async drawValue(ctx: CanvasRenderingContext2D, bgCol: string) {
        for (const id in this.completeAreas) {
            const area = this.completeAreas[id]
            const x = area.vX * MES_UNIT + this.origin_x + this.vtLeft
            const y = area.vY * MES_UNIT + this.origin_y + this.vtTop
            const w = area.vW * MES_UNIT
            const h = area.vH * MES_UNIT
            ctx.strokeStyle = bgCol;
            ctx.fillStyle = bgCol;
            // 背景BOX
            ctx.beginPath()
            ctx.moveTo(x + ROUND_SIZE, y)
            ctx.arcTo(x + w, y, x + w, y + ROUND_SIZE, ROUND_SIZE)
            ctx.arcTo(x + w, y + h, x, y + h, ROUND_SIZE)
            ctx.arcTo(x, y + h, x, y, ROUND_SIZE)
            ctx.arcTo(x, y, x + ROUND_SIZE, y, ROUND_SIZE)
            ctx.fill()
            // TEXT
            ctx.beginPath()
            ctx.strokeStyle = 'white';
            ctx.fillStyle = 'white';
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = "8pt Poppins"
            ctx.fillText(area.value, x + Math.round(w / 2), y + Math.round(h / 2), w);
            ctx.stroke();
        }
    }
    /**
     * エリアマップを描画します。
     * 
     * @param ctx 
     * @param areaList 
     * @param bgCol 
     */
    async draw(ctx: CanvasRenderingContext2D, areaList: ResArea[], bgCol: string) {
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        await this.drawImage(ctx)
        await this.drawBorder(ctx, areaList)
        await this.drawLabel(ctx)
        await this.drawLeaderLine(ctx, bgCol)
        await this.drawValue(ctx, bgCol)
        ctx.restore()
        //console.log("draw bgCol:",bgCol)
    }
}

export default AreaMapWriter