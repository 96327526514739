import React, { useMemo, useState } from "react"
import useCollapse from "react-collapsed"
import { useTranslation } from "react-i18next"
import { CopyIcon, UploadIcon, PencilIcon, TrashIcon } from "@primer/octicons-react"

import { useAccessControlContext } from "../../providers/AccessControler"
import { PermissionTypes } from "../../types/Permissions"
import { DtSrcStatus, DataSourceStatusType } from "../../types/Management"
import { DS_NO_NAME } from "../../constants"
import { DeleteDialog, PaneSizeType } from "../delete_dialog/DeleteDialog"
import { RenameDialog } from "../rename_dialog/RenameDialog"

import style from "./DatasourceAccordion.module.css"
import { TranscriptionDialog } from "../transcription_dialog/TranscriptionDialog"

const DialogType = {
    NameEdit: "edt",
    Delete: "del",
    Transcription: "trn"
} as const
type Dialog = typeof DialogType[keyof typeof DialogType]

interface AccordionProps {
    isExpanded: boolean
    setExpanded: (layoutSetId: number, expaned: boolean) => void
    layoutSetId: number
    name: string
    status: DtSrcStatus
    hasNoMap: boolean   // レイアウトセット内にレイアウト（平面図）がない=true
    hasNoDraft: boolean // 同じ店舗に下書きレイアウトセットがない=true
    aLotOfDraft: boolean // Draftレイアウトセットが2つ以上ある=true
    readyTranscription: boolean // レイアウトセットが２つでHotにはレイアウトがある=true
    updateName: (layoutSetId: number, newName: string) => void
    applyToProduction: (layoutSetId: number) => void
    onReplication: (layoutSetId: number) => void
    onDelete: (layoutSetId: number) => void
    onTranscription: () => void
    children: React.ReactNode
}

export const DatasourceAccordion: React.FC<AccordionProps> = (props) => {
    const { t } = useTranslation()
    
    const { ableToUpdate, ableToDelete} = useAccessControlContext()
    
    const isExpanded: boolean = useMemo(() => {return props.isExpanded}, [props.isExpanded])
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [dialogType, setDialogType] = useState<Dialog | undefined>(undefined)
    const isReady: boolean = useMemo(() => { return (props.status === DataSourceStatusType.Ready) }, [props])
    //const isMainLayoutSet: boolean = useMemo(() => { return (props.status === DataSourceStatusType.Active) }, [props])

    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageLayout_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageLayout_Delete) }, [ableToDelete])

    // コピー（複製）ボタンの表示判定
    const dispCopyBtn: boolean = useMemo(() => {
        // 本番であること、かつ、レイアウトがあること、かつ、編集可能なこと、かつ、下書きがないこと
        if (props.status === DataSourceStatusType.Active && !props.hasNoMap && ableEdit && props.hasNoDraft) {
            return true
        }
        return false
    }, [props, ableEdit])

    // 削除ボタンの表示判定
    const dispDeleteBtn: boolean = useMemo(() => {
        // メインレイアウトセットでないこと、かつ、削除可能、かつ、レイアウトセットが３つ以上あるここと
        if (props.status !== DataSourceStatusType.Active && ableDelete && props.aLotOfDraft) {
            return true
        }
        return false
    }, [props, ableDelete])

    // 転写ボタンの表示判定
    const dispTranscriptionBtn: boolean = useMemo(() => {
        // レイアウトセットが２つ、かつ、下書きであること、本番にレイアウトがあること、かつ、編集可能なこと、削除可能なこと
        if (props.status !== DataSourceStatusType.Active && ableDelete && ableEdit && props.readyTranscription) {
            return true
        }
        return false
    }, [props, ableEdit, ableDelete])

    const handleExpan = () => {
        props.setExpanded(props.layoutSetId, !isExpanded)
    }

    const handleNameEditOpen = () => {
        setDialogType(DialogType.NameEdit)
    }

    const handleCancelEdit = () => {
        setDialogType(undefined)
    }
    
    const handleChangeName = (rename: string) => {
        setDialogType(undefined)
        props.updateName(props.layoutSetId, rename)
    }

    const handleReplication = () => {
        props.onReplication(props.layoutSetId)
    }
    
    const handleApplyToProduction = () => {
        props.applyToProduction(props.layoutSetId)
    }

    const handleConfirmDelete = () => {
        setDialogType(DialogType.Delete)
    }

    const handleDeleteDataSource = () => {
        setDialogType(undefined)
        props.onDelete(props.layoutSetId)
    }
    
    const handleCancelDelete = () => {
        setDialogType(undefined)
    }

    const handleTranscription = () => {
        // 転写処理
        setDialogType(DialogType.Transcription)
    }

    const handleCancelTranscription = () => {
        setDialogType(undefined)
    }

    const handleAcceptTranscription = () => {
        // 転写処理
        setDialogType(undefined)
        props.onTranscription()
    }

    const btnStyle = useMemo(() => {
        if (props.status === DataSourceStatusType.Active) {
            return (props.isExpanded) ? style.productionExp : style.production
        } else if (props.status === DataSourceStatusType.Ready) {
            return (props.isExpanded) ? style.readyExp : style.ready
        } else {
            return (props.isExpanded) ? style.otherExp : style.other
        }
    }, [props])

    const btnTitle = useMemo(() => {
        if (props.status === DataSourceStatusType.Active) {
            return t("production")
        } else if (props.status === DataSourceStatusType.Ready) {
            return t("waitForProduction")
        } else {
            return t("draft")
        }
    }, [props, t])

    const btnName = useMemo(() => {
        let name = ""
        if (!props.isExpanded) {
            name = props.layoutSetId + "_"
            if ((props.name.length === 0) || (props.name === DS_NO_NAME)) {
                name = name + "（" + t("unregistered") + "）"
            } else {
                name = name + props.name
            }
        }
        return name
        //return (props.isExpanded) ? "" : props.layoutSetId + "_" + (props.name.length > 0 ? props.name : "（" + t("unregistered") + "）")
    }, [props, t])
    const btnTitleNameEdit = useMemo(() => { return t("button.editDataSourceName") }, [t])
    const btnTitleCopy = useMemo(() => { return t("button.dataSourceReplication") }, [t])
    const btnTitleApply = useMemo(() => { return t("button.applyToProduction") }, [t])
    const btnTitleDelete = useMemo(() => { return t("button.deleteDataSource") }, [t])
    const btnTitleTranscription = useMemo(() => { return t("button.transcriptionDescription") }, [t])
    const transcription = useMemo(() => { return t("button.transcription") }, [t])
    const datasourceName = useMemo(() => {
        if (props.name.length === 0 || props.name === DS_NO_NAME) {
            return "（" + t("unregistered") + "）"
        } else {
            return props.name
        }
        //return (props.name.length > 0 ? props.name : "（" + t("unregistered") + "）")
    }, [props, t])

    return (
        <div className={style.accordionItem}>
            <button {...getToggleProps({ onClick: handleExpan })} className={btnStyle}><span className={style.title}>{btnTitle}</span><span className={style.name}>{btnName}</span></button>
            <section {...getCollapseProps()}>
                <div className={style.accItems}>
                    <section>
                        <div className={style.dsHead} key={props.layoutSetId}>
                            <div className={style.dsLeft}>
                                <label className={style.dsHdLable}>ID: </label>{props.layoutSetId}
                            </div>
                            <div className={style.dsMid}>
                                <label className={style.dsHdLable}>{t("header.dataSourceName")}: </label>{datasourceName}
                                <span>　
                                    {
                                        (ableEdit) ? (<button className={style.iconBtn} title={btnTitleNameEdit} onClick={handleNameEditOpen}><PencilIcon size={16} /></button>): (null)
                                    }                                    
                                </span>
                            </div>
                            <div className={style.dsRight}>
                                {
                                    (!dispTranscriptionBtn) ? (null) : (
                                        <button className={style.iconBtn} title={btnTitleTranscription} onClick={handleTranscription}>{transcription}</button>
                                    )
                                }
                                {
                                    (!dispCopyBtn) ? (null) : (
                                        <button className={style.iconBtn} title={btnTitleCopy} onClick={handleReplication}><CopyIcon size={16} /></button>
                                    )
                                }
                                <span className={style.spc}> </span>
                                {
                                    (!dispDeleteBtn) ? (null) : (
                                        <button className={style.iconBtn} title={btnTitleDelete} onClick={handleConfirmDelete}><TrashIcon size={16} /></button>
                                    )
                                }
                                <span className={style.spc}> </span>
                                {
                                    (isReady && ableEdit) ? (
                                        <button className={style.iconBtnRed} title={btnTitleApply} onClick={handleApplyToProduction}><UploadIcon size={16} /></button>
                                    ) : (null)
                                }
                            </div>
                        </div>
                    </section>
                    {props.children}
                </div>
            </section>
            <RenameDialog
                requestOpen={dialogType === DialogType.NameEdit}
                name={props.name}
                guideMessage={t("button.editDataSourceName")}
                onCancel={handleCancelEdit}
                onChange={handleChangeName}
            />
            <DeleteDialog requestOpen={dialogType === DialogType.Delete} guideMessage={t("msgConfirmDeleteDataSource")} onCancel={handleCancelDelete} onDelete={handleDeleteDataSource} paneSize={PaneSizeType.forDatasource}>
                <table>
                    <tbody>
                        <tr>
                            <td className={style.dialogTitle}>ID</td>
                            <td className={style.dialogData}>{props.layoutSetId}</td>
                        </tr>
                        <tr>
                            <td className={style.dialogTitle}>{t("header.dataSourceName")}</td>
                            <td className={style.dialogData}>{props.name}</td>
                        </tr>
                    </tbody>
                </table>
            </DeleteDialog>
            <TranscriptionDialog
                requestOpen={dialogType === DialogType.Transcription}
                guideMessage={t("msgConfirmTranscription")}
                name={transcription}
                onCancel={handleCancelTranscription}
                onAccept={handleAcceptTranscription} />
        </div>
    )
}