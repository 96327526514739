import { addDays, addHours, addMonths, getDay } from "date-fns"
import { APIError } from "../../APIError"
import { TimeUnit, TimeUnitShortType, TimeUnitType } from "../../data/core/Enums"

export class CalcUtils {
    static matchingDicts<T>(d1: Record<string, T>, d2: Record<string, T>): Record<string, [T | null, T | null]> {
        const result: Record<string, [T | null, T | null]> = {}
        for (const k in d1) {
            result[k] = [ d1[k], null ]
        }
        for (const k in d2) {
            if (result.hasOwnProperty(k)) {
                result[k][1] = d2[k]
            } else {
                result[k] = [null, d2[k]]
            }
        }
        return result
    }

    static objVals<V>(d: Record<string, V>): V[] {
        const result: V[] = []
        for (const k in d) {
            result.push(d[k])
        }
        return result
    }

    static objValsNotNull<V>(d: Record<string, V | null>): V[] {
        const result: V[] = []
        for (const k in d) {
            const v = d[k]
            if (v != null) {
                result.push(v)
            }
        }
        return result
    }

    static list2dic<V>(l: V[], fn: (v: V) => string): Record<string, V> {
        const result: Record<string, V> = {}
        for (const v of l) {
            const k = fn(v)
            result[k] = v
        }
        return result
    }

    static keyUniqVals<V>(ds: Record<string, V>[]): V[] {
        const result: Record<string, V> = {}
        for (const d of ds) {
            for (const k in d) {
                if (!result.hasOwnProperty(k)) {
                    result[k] = d[k]
                }
            }
        }
        return CalcUtils.objVals(result)
    }

    static removeNull<T>(vs: (T | null)[]): T[] {
        const result: T[] = []
        for (let v  of vs) {
            if (v != null) {
                result.push(v)
            }
        }
        return result
    }

    static roundDate(target_date: string, time_unit: TimeUnit): number {
        const m = target_date.match(/^(\d+)-(\d+)-(\d+)$/)
        if (m == null) {
            throw new APIError(`不正な形式の日付: ${target_date}`)
        }
        const [year, month, date] = [m[1], m[2], m[3]]
        switch (time_unit) {
            case TimeUnitType.Hour:
            case TimeUnitType.Day:
                return new Date(`${target_date}T00:00:00Z`).valueOf()
            case TimeUnitType.Week:
                const baseDate = new Date(`${target_date}T00:00:00Z`).valueOf()
                const wday = getDay(baseDate)
                if (wday === 0) {
                    return addDays(baseDate, -6).valueOf()
                }
                return addDays(baseDate, -(wday - 1)).valueOf()
            case TimeUnitType.Month:
                return new Date(`${year}-${month}-01T00:00:00Z`).valueOf()
            default:
                throw new APIError(`想定外の時刻単位: ${time_unit}`)
        }
    }

    static timeStep(epoch: number, time_unit: TimeUnit): number {
        switch (time_unit) {
            case TimeUnitType.Hour:
                return addHours(epoch, 1).valueOf()
            case TimeUnitType.Day:
                return addDays(epoch, 1).valueOf()
            case TimeUnitType.Week:
                return addDays(epoch, 7).valueOf()
            case TimeUnitType.Month:
                return addMonths(epoch, 1).valueOf()
            default:
                throw new APIError(`想定外の時刻単位: ${time_unit}`)
        }
    }

    static listupDate(start_date: string, end_date: string, time_unit: TimeUnit): string[] {
        const start_epoch = CalcUtils.roundDate(start_date, time_unit)
        const end_epoch = CalcUtils.roundDate(end_date, time_unit)
        const result: string[] = []
        for (let t = start_epoch; t <= end_epoch; t = CalcUtils.timeStep(t, time_unit)) {
            switch (time_unit) {
                case TimeUnitType.Hour:
                    result.push(new Date(t).toISOString().substring(0, 13).replace('T', ' '))
                    break;
                case TimeUnitType.Day:
                case TimeUnitType.Week:
                    result.push(new Date(t).toISOString().substring(0, 10))
                    break;
                case TimeUnitType.Month:
                    result.push(new Date(t).toISOString().substring(0, 7))
                    break;
                default:
                    throw new APIError(`想定外の時刻単位: ${time_unit}`)
            }
        }
        return result
    }
}