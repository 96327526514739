import { AnalysisResult, AreaCount, ChordDataItem, CustomerForTrail, Heatmap, PurchaserTimeline, Timeline, AllCustomersResult } from "../../data/analysis/AnalysisResult";
import { TimeUnit, TimeUnitType } from "../../data/core/Enums";
import { ResArea } from "../../data/analysis/FullLayout";
import { OSResAreaCount, OSResAreaCountEntry, OSResHuman, OSResHumanList, OSResTimelines } from "../AnalysisDataImpl";
import { ResSearchQuery, DefaultSearchSortOption, SearchSortOption, SortItemType, SortOrderType } from "../../data/analysis/AnalysisRequest";
import { OpenSearch } from "../requester/OpenSearch";
import { CalcUtils } from "./calc_utils";
import { CustomerForTrailImpl } from "./CustomerForTrailImpl";
import { FullLayout } from "./FullLayoutImpl";
import { TimelineImpl } from "./TimelineImpl";
import { ChordalGraphCounter } from "./ChordalGraphCounter";
import { OPENSEARCH_SIZE_LIMIT, PAGING_SIZE } from "../../data/core/Constants";
import { Coord } from "../../data/core/Coord";
import Utils from "../../../lib/Utils";
import { format } from "date-fns";
import { Ranking, RankingType } from "../../../feature/analyze/TrailMap";

const TIME_UNITS_TO_FORMAT: Record<TimeUnit, string> = {
    "hour": "yyyy-MM-dd HH",
    "day": "yyyy-MM-dd",
    "week": "yyyy-MM-dd",
    "month": "yyyy-MM"
 }

function countAreas(areaCount: OSResAreaCount, areaCountZero: OSResAreaCount, layout: FullLayout): AreaCount[] {
    //const aid2count: Record<string, AreaCount | undefined> = {};
    const d1: Record<string, OSResAreaCountEntry> = {}
    //console.log('エリアカウント開始', areaCount, areaCountZero)
    for (let area_label in areaCount.aggregations) {
        const m = area_label.match(/-(\d+)$/);
        if (m != null) {
            d1[m[1]] = areaCount.aggregations[area_label]
        } else {
            console.log('ERR: エリア1発見できず:', area_label)
        }
    }
    const d2: Record<string, OSResAreaCountEntry> = {}
    for (let area_label in areaCountZero.aggregations) {
        const m = area_label.match(/-(\d+)$/);
        if (m != null) {
            d2[m[1]] = areaCountZero.aggregations[area_label]
        } else {
            console.log('ERR: エリア2発見できず:', area_label)
        }
    }
    const aid2a_a0 = CalcUtils.matchingDicts(d1, d2)
    const result: Record<string, AreaCount> = {}
    for (const aid in aid2a_a0) {
        const [a, a0] = aid2a_a0[aid]
        if (a == null && a0 == null) {
            continue
        } else if (a == null && a0 != null) {
            result[aid] = {
                "area": layout.getArea(aid),
                "stay_count": 0,
                "pass_count": a0.pass.doc_count,
                "total_count": a0.pass.doc_count,
                "stay_rate": 0,
                "pass_rate": 1,
            }
        } else if (a != null && a0 == null) {
            result[aid] = {
                "area": layout.getArea(aid),
                "stay_count": a.pass.doc_count,
                "pass_count": a.pass.doc_count,
                "total_count": a.pass.doc_count,
                "stay_rate": 1,
                "pass_rate": 0,
            }
        } else if (a != null && a0 != null) {
            result[aid] = {
                "area": layout.getArea(aid),
                "stay_count": a.pass.doc_count,
                "pass_count": a0.pass.doc_count - a.pass.doc_count,
                "total_count": a0.pass.doc_count,
                "stay_rate": 0,
                "pass_rate": 0,
            }
            result[aid].stay_rate = result[aid].stay_count / result[aid].total_count
            result[aid].pass_rate = result[aid].pass_count / result[aid].total_count
        }
    }
    return CalcUtils.objVals(result)
}

type CustomerCache = {
    data: CustomerForTrail[],
    totalValue: number,  // data.hits.total.value
    block: number,      // 100 件ｘ10 page = 1 block, startWith 0
    option: SearchSortOption
}

export class AnalysisResultImpl implements AnalysisResult {
    public query: ResSearchQuery;  // 検索条件
    public layout: FullLayout;     // レイアウト情報
    public trailHash: Record<string, Coord[]> = {} // 動線データのハッシュ、動線顧客IDをキーにして保持

    opensearch: OpenSearch  // OpenSearch API
    queryOption: SearchSortOption = DefaultSearchSortOption  // 動線顧客IDリスト用の追加検索条件＆ソート条件
    activeAreaList: ResArea[];  // アクティブエリアリスト
    areaCount: AreaCount[] = []; // エリア別滞在数
    unitAreaTimeline: { [key: string]: { [key: string]: TimelineImpl[] } } = {} // タイムライン
    _longStayCustomers: CustomerForTrail[] = []
    _manyStayCustomers: CustomerForTrail[] = []
    _numberOfCustomers: number = 0      // 顧客数
    _numberOfCustomers4Top100: number = 0 // 顧客数（Top100用）
    _areaChordalGraph: ChordDataItem[] = []
    _groupChordalGraph: ChordDataItem[] = []
    cache: CustomerCache[] = [] // 動線顧客リストのキャッシュ、 1 block = 1000 件 を配列で保持、検索オプションの違いも保持

    // コンストラクタ
    constructor(
        opensearch: OpenSearch,
        query: ResSearchQuery,
        layout: FullLayout,
        activeAreaList: ResArea[],
        numberOfCustomers: number   // 顧客数
    ) {
        this.opensearch = opensearch
        this.query = query;
        this.layout = layout
        this.activeAreaList = activeAreaList
        this._numberOfCustomers = numberOfCustomers
        this.unitAreaTimeline[TimeUnitType.Hour] = {}
        this.unitAreaTimeline[TimeUnitType.Day] = {}
        this.unitAreaTimeline[TimeUnitType.Week] = {}
        this.unitAreaTimeline[TimeUnitType.Month] = {}
    }

    set_longstay_customers(customers: OSResHumanList) {
        if (this.layout) {
            this._longStayCustomers = customers.hits.hits.map(
                c => new CustomerForTrailImpl(c._source, this.query.stay_threshold, this.layout))
        } else {
            throw new Error("layout is not set")
        }
    }

    set_manystay_customers(customers: OSResHumanList) {
        if (this.layout) {
            this._manyStayCustomers = customers.hits.hits.map(
                c => new CustomerForTrailImpl(c._source, this.query.stay_threshold, this.layout))
        } else {
            throw new Error("layout is not set")
        }
    }

    set_all_customers(customers: OSResHumanList) {
        if (this.layout) {
            let customerList = customers.hits.hits.map(c => new CustomerForTrailImpl(c._source, this.query.stay_threshold, this.layout))
            const cacheData: CustomerCache = {
                data: customerList,
                totalValue: customers.hits.total.value,
                block: 0,
                option: DefaultSearchSortOption
            }
            this.cache.push(cacheData)
        } else {
            throw new Error("layout is not set")
        }
    }

    async set_sample_customers(customersAsc: OSResHuman[], customersDesc: OSResHuman[]) {
        const customers: OSResHuman[] = [...customersAsc, ...customersDesc]
        // 弦グラフ用データ
        if (this.layout) {
            let [a, g] = new ChordalGraphCounter(this.layout, customers).calc()
            this._areaChordalGraph = a
            this._groupChordalGraph = g
        } else {
            throw new Error("layout is not set")
        }
    }

    set_area_count(areaCount: OSResAreaCount, areaCountZero: OSResAreaCount) {
        if (this.layout) {
            this.areaCount = countAreas(areaCount, areaCountZero, this.layout)
        } else {
            throw new Error("layout is not set")
        }
    }

    set_timeline(start_date: string, end_date: string, time_unit: TimeUnit, areaID: number | null, stayTl: OSResTimelines, passTl: OSResTimelines) {
        const areaKey = `${areaID == null ? "" : areaID}`
        const tls = TimelineImpl.mkTl(start_date, end_date, time_unit, areaKey, stayTl, passTl)
        if (! this.unitAreaTimeline.hasOwnProperty(time_unit)) {
            this.unitAreaTimeline[time_unit] = {}
        }
        this.unitAreaTimeline[time_unit][areaKey] = tls
    }

    /**
     * 顧客数を取得
     * @returns 
     */
    get_num_of_customers(): number {
        return this._numberOfCustomers
    }

    get_num_of_customers_for_top100(): number {
        return this._numberOfCustomers4Top100
    }

    /**
     * エリア別滞在数を取得
     * @returns 
     */
    get_area_count_list(): AreaCount[] {
        return this.areaCount
    }

    /**
     * タイムラインを取得
     * @param time_unit 
     * @param area_id 
     * @returns 
     */
    async get_timeline(time_unit: TimeUnit, area_id?: number | undefined): Promise<Timeline[]> {
        const akey = (area_id === undefined) ? "" : area_id
        // キャッシュがあればそこから取得
        if (this.unitAreaTimeline[time_unit].hasOwnProperty(akey)) {
            return this.unitAreaTimeline[time_unit][akey]
        }
        // タイムラインがない場合はOpenSearchから取得
        if (area_id === undefined) {
            // エリア指定なし＝全てのエリアの合計
            this.set_timeline(this.query.start_date, this.query.end_date, time_unit, null,
                await this.opensearch.get_timeline(this.query, this.query.stay_threshold, time_unit, TIME_UNITS_TO_FORMAT[time_unit], null),
                await this.opensearch.get_timeline(this.query, 0, time_unit, TIME_UNITS_TO_FORMAT[time_unit], null),
            )
        } else {
            this.set_timeline(this.query.start_date, this.query.end_date, time_unit, area_id,
                await this.opensearch.get_timeline(this.query, this.query.stay_threshold, time_unit, TIME_UNITS_TO_FORMAT[time_unit], area_id),
                await this.opensearch.get_timeline(this.query, 0, time_unit, TIME_UNITS_TO_FORMAT[time_unit], area_id),
            )

        }
        return this.unitAreaTimeline[time_unit][akey]
    }

    get_heatmap(cell_length: number, width: number): Heatmap {
        return {
            counts: [],
            max: 0,
            min: 0
        }
    }

    /**
     * 店舗滞在時間が長い顧客を取得
     * @param limit 
     * @returns 
     */
    async get_customers_who_stayed_long(option: SearchSortOption): Promise<CustomerForTrail[]> {
        const sortFunction = this.getSortFunction(option)
        const newCustomers = this._longStayCustomers.filter(aCust => this.passTheFilter(aCust, option)).sort(sortFunction)
        //console.log("get_customers_who_stayed_long", newCustomers, option)
        this._numberOfCustomers4Top100 = newCustomers.length
        return newCustomers.slice(option.from, option.from + option.size)
    }

    /**
     * 滞在エリア数が多い顧客を取得
     * @param limit 
     * @returns 
     */
    async get_customers_who_wents_most_area(option: SearchSortOption): Promise<CustomerForTrail[]> {
        const sortFunction = this.getSortFunction(option)    
        const newCustomers = this._manyStayCustomers.filter(aCust => this.passTheFilter(aCust, option)).sort(sortFunction)
        //console.log("get_customers_who_wents_most_area", newCustomers, option)
        this._numberOfCustomers4Top100 = newCustomers.length
        return newCustomers.slice(option.from, option.from + option.size)
    }

    /**
     * 購入点数が多い顧客を取得
     * @param limit 
     * @returns 
     */
    async get_customers_who_bought_most(option: SearchSortOption): Promise<CustomerForTrail[]> {
        // TODO: あとで
        return []
    }

    /**
     * 購入金額が多い顧客を取得
     * @param limit 
     * @returns 
     */
    async get_customers_who_paied_most(option: SearchSortOption): Promise<CustomerForTrail[]> {
        // TODO: あとで
        return []
    }

    /**
     * 全顧客情報を取得
     * @param option 
     * @returns 
     */
    async get_all_customers(option: SearchSortOption): Promise<AllCustomersResult> {
        // オプションの修正
        const repaireOption = { ...option }
        if (repaireOption.sortItem === SortItemType.NumberOfStayArea) {
            console.log('滞在エリア数でソートはできませんので、IDでソートに変更します')
            repaireOption.sortItem = SortItemType.HumanId
        }
        // キャッシュを検索
        console.log('キャッシュ検索', this.cache)
        if (this.cache.length > 0) {
            for await (let c of this.cache) {
                // 同じオプションを探す
                if (await this.isSameOptions(c.option, repaireOption)) {
                    // ブロックに含まれているか
                    const reqBlk = Math.floor(repaireOption.from / PAGING_SIZE)
                    if (c.block === reqBlk) {
                        const st = repaireOption.from - reqBlk * PAGING_SIZE
                        const ed = st + repaireOption.size
                        return {data: c.data.slice(st, ed), totalValue: c.totalValue}
                    }
                }
            }
        }
        console.log("キャッシュにないので検索 option:", repaireOption)
        // API用のオプションを作成
        const newOption = {...repaireOption}
        // from は 1000 件単位で指定する
        const blk = Math.floor(repaireOption.from / PAGING_SIZE)
        newOption.from = blk * PAGING_SIZE
        newOption.size = PAGING_SIZE
        // (from + size)は最大顧客数を超えないようにする
        if (newOption.from + newOption.size > OPENSEARCH_SIZE_LIMIT) {
            newOption.size = OPENSEARCH_SIZE_LIMIT - newOption.from
        }
        // キャッシュにない場合は検索
        const customers = await this.opensearch.get_page_of_customers(this.query, newOption)
        if (this.layout) {
            console.log("customers:", customers)
            if (!customers || !customers.hits || !customers.hits.hits || customers.hits.hits.length === 0) {
                console.error('検索結果が0件です')
                return { data: [], totalValue: 0 }
            }
            let custmersList = customers.hits.hits.map(c => new CustomerForTrailImpl(c._source, this.query.stay_threshold, this.layout))
            const cacheData: CustomerCache = {
                data: custmersList,
                totalValue: customers.hits.total.value,
                block: Math.floor(newOption.from / PAGING_SIZE),
                option: newOption
            }
            this.cache.push(cacheData)
            const st = repaireOption.from - cacheData.block * PAGING_SIZE
            const ed = (st + repaireOption.size > this._numberOfCustomers) ? st + newOption.size : st + repaireOption.size
            return { data: custmersList.slice(st, ed), totalValue: customers.hits.total.value }
        } else {
            throw new Error("layout is not set")
        }
    }

    /**
     * ダウンロード用の顧客情報を取得
     * @param rankingType 
     * @param option 
     * @returns 
     */
    async get_download_customers(rankingType: Ranking, option: SearchSortOption): Promise<CustomerForTrail[]> {
        if (rankingType === RankingType.StayTime) {
            // 店舗滞在時間Top100
            const sortFunction = this.getSortFunction(option)
            const newCustomers = this._longStayCustomers.filter(aCust => this.passTheFilter(aCust, option)).sort(sortFunction)
            return newCustomers
        } else if (rankingType === RankingType.StayArea) {
            // 滞在エリア数Top100
            const sortFunction = this.getSortFunction(option)    
            const newCustomers = this._manyStayCustomers.filter(aCust => this.passTheFilter(aCust, option)).sort(sortFunction)
            return newCustomers
        } else {
            // ALL
            // キャッシュを検索
            console.log('キャッシュ検索', this.cache)
            if (this.cache.length > 0) {
                for await (let c of this.cache) {
                    // 同じオプションを探す
                    if (await this.isSameOptions(c.option, option)) {
                        // ブロックに含まれているか
                        const reqBlk = Math.floor(option.from / PAGING_SIZE)
                        if (c.block === reqBlk) {
                            console.log("キャッシュデータを返す", c.data.length, c.totalValue, c.option)
                            if (c.totalValue < PAGING_SIZE || c.data.length === c.totalValue) {
                                // 1000 件未満の場合又はデータ数とtotalValueが同じ場合はキャッシュデータを返す
                                return c.data
                            }
                        }
                    }
                }
            }
            // キャッシュが無い場合、全件取得（ただし、ElasticSearch上限まで）
            const newOption = { ...option }
            newOption.from = 0
            newOption.size = OPENSEARCH_SIZE_LIMIT
            console.log("キャッシュにないので検索 option:", newOption)
            const customers = await this.opensearch.get_page_of_customers(this.query, newOption)
            if (this.layout) {
                console.log("api result customers:", customers)
                if (!customers || !customers.hits || !customers.hits.hits || customers.hits.hits.length === 0) {
                    console.error('検索結果が0件です')
                    throw new Error('検索結果が0件です')
                }
                let custmersList = customers.hits.hits.map(c => new CustomerForTrailImpl(c._source, this.query.stay_threshold, this.layout))
                const cacheData: CustomerCache = {
                    data: custmersList,
                    totalValue: customers.hits.total.value,
                    block: 0,
                    option: newOption
                }
                this.cache.push(cacheData)
                return custmersList
            } else {
                throw new Error("layout is not set")
            }
        }           
    }

    /**
     * 弦グラフ（エリア用）データを取得
     * @returns 
     */
    get_area_chordal_graph(): ChordDataItem[] {
        return this._areaChordalGraph
    }

    /**
     * 弦グラフ（グループ用）データを取得
     * @returns 
     */
    get_group_chordal_graph(): ChordDataItem[] {
        return this._groupChordalGraph
    }

    /**
     * 購入者のタイムラインを取得
     * @param time_unit 
     * @returns 
     */
    get_purchaser_timeline(time_unit: TimeUnit): PurchaserTimeline[] {
        // TODO: あとで
        return []
    }

    /**
     * 追加検索条件が変わっているかどうかを判定
     * @param a 
     * @param b 
     * @returns 同じならtrue
     */
    async isSameOptions(a: SearchSortOption, b: SearchSortOption): Promise<boolean> {
        let sortMatch = false
        let enterMatch = false
        let stayMatch = false
        let areaMatch = false
        if (a.sortItem === b.sortItem && a.sortOrder === b.sortOrder) {
            sortMatch = true
        }
        if (a.enterShopTimeFrom === undefined && b.enterShopTimeFrom === undefined && a.enterShopTimeTo === undefined && b.enterShopTimeTo === undefined) {
            enterMatch = true
        } else if (a.enterShopTimeFrom === b.enterShopTimeFrom && a.enterShopTimeTo === b.enterShopTimeTo) {
            enterMatch = true
        }
        if (a.stayShopTimeFrom === undefined && b.stayShopTimeFrom === undefined && a.stayShopTimeTo === undefined && b.stayShopTimeTo === undefined) {
            stayMatch = true
        } else if (a.stayShopTimeFrom === b.stayShopTimeFrom && a.stayShopTimeTo === b.stayShopTimeTo) {
            stayMatch = true
        }
        if (a.enteredAreaList === undefined && b.enteredAreaList === undefined) {
            areaMatch = true
        } else if (a.enteredAreaList && b.enteredAreaList && a.enteredAreaList.length === b.enteredAreaList.length) {
            areaMatch = true
            for (let id of a.enteredAreaList) {
                if (!b.enteredAreaList.includes(id)) {
                    areaMatch = false
                    break
                }
            }
        }
        console.log('isSameOptions', sortMatch, enterMatch, stayMatch, areaMatch)
        return (sortMatch && enterMatch && stayMatch && areaMatch)
    }

    /**
     * 顧客リストのソート関数を取得
     * @returns 
     */
    getSortFunction(option: SearchSortOption): (a: CustomerForTrail, b: CustomerForTrail) => number {
        if (option.sortItem === SortItemType.EnterShopTime) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.enter_time - b.enter_time }
            } else {
                return function (a, b) { return b.enter_time - a.enter_time }
            }
        } else if (option.sortItem === SortItemType.ExitShopTime) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.exit_time - b.exit_time }
            } else {
                return function (a, b) { return b.exit_time - a.exit_time }
            }
        } else if (option.sortItem === SortItemType.StayShopTime) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.stay_seconds - b.stay_seconds }
            } else {
                return function (a, b) { return b.stay_seconds - a.stay_seconds }
            }
        } else if (option.sortItem === SortItemType.NumberOfStayArea) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.stay_area_count - b.stay_area_count }
            } else {
                return function (a, b) { return b.stay_area_count - a.stay_area_count }
            }
        } else if (option.sortItem === SortItemType.AreaStayTimeMax) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.stay_seconds - b.stay_seconds }
            } else {
                return function (a, b) { return b.stay_seconds - a.stay_seconds }
            }
        } else if (option.sortItem === SortItemType.BuyOrNot) {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.is_buying ? -1 : 1 }
            } else {
                return function (a, b) { return b.is_buying ? -1 : 1 }
            }
        } else {
            if (option.sortOrder === SortOrderType.Asc) {
                return function (a, b) { return a.human_id - b.human_id }
            } else {
                return function (a, b) { return b.human_id - a.human_id }
            }
        }
    }

    /**
     * 動線絞り込みフィルター
     * 顧客データが指定されたフィルター条件を満たすかどうかを判定
     * @param cust 
     * @param option 
     * @returns true: フィルター条件を満たす
     */
    passTheFilter(cust: CustomerForTrail, option: SearchSortOption): boolean {
        if (option.enterShopTimeFrom !== undefined || option.enterShopTimeTo !== undefined) {
            const enterDmTm = Utils.parseTimeAsDummyTime(format(new Date(cust.enter_time), "HH:mm"))
            if (option.enterShopTimeFrom !== undefined && enterDmTm <= option.enterShopTimeFrom) {
                return false
            }
            if (option.enterShopTimeTo !== undefined && option.enterShopTimeTo < enterDmTm) {
                return false
            }
        }
        // cust.stay_seconds はミリ秒
        if (option.stayShopTimeFrom !== undefined || option.stayShopTimeTo !== undefined) {
            if (option.stayShopTimeFrom !== undefined && cust.stay_seconds < option.stayShopTimeFrom * 1000) {
                return false
            }
            if (option.stayShopTimeTo !== undefined && option.stayShopTimeTo * 1000 < cust.stay_seconds) {
                return false
            }
        }
        if (option.enteredAreaList !== undefined && option.enteredAreaList.length === 0) {
            // どのエリアにも入ってない人が対象＝どこか１つでも入っていたらfalse
            if (cust.area_count_list.length > 0) {
                return false
            }
        } else if (option.enteredAreaList !== undefined && option.enteredAreaList.length > 0) {
            // 指定されたエリアに入った人＝指定されたエリアに入っていない人は対象外
            const res = cust.area_count_list.filter(a => (option.enteredAreaList && option.enteredAreaList.includes(a.area.area_id) && a.total_stay_time > 0))
            if (res && res.length === 0) {
                return false
            }
        }
        return true
    }
}