import React, { useState, useCallback, useMemo } from "react";
import { DateRangePicker, NullableDateChangeHandler, TimePicker } from "react-next-dates";
import { useTranslation } from "react-i18next"
import { format, subDays } from "date-fns"
import { enUS, ja } from 'date-fns/locale'
import 'react-next-dates/dist/style.css'

import { Array7, Array2 } from "../../component";
import { WeekdaysSelector } from "../../component/weekdays_selector/WeekdaysSelector"
import { ExcludeClerkCheckbox } from "../../component/exlude_clerk_checkbox/ExcludeClerkCheckbox"
import { ThresholdTimepicker } from "../../component/threshold_timepicker/ThresholdTimepicker"
import { BuyOrNotCheckbox } from "../../component/buy_or_not_checkbox/BuyOrNotCheckbox";
import { useAuthUserContext } from "../../providers";
import { useAnalyticsDataContext } from "../../providers/AnalyticsData";
import DateUtil from "../../lib/DateUtil";
import { ListOfSavedSearches } from "./ListOfSavedSearches";
import { AnalyzeView, AnalyzeViewType, CompareSide, CompareSideType, AnalysisParameterSetType } from "../../types/Analyze";
import { ResPartialLayout } from "../../api/data/login/LoginInfo";
import { STR_YMD_FORMAT } from "../../constants";

import style from "./AnalyzeParameter.module.css"

interface Props {
    view: AnalyzeView
    side: CompareSide
    saved?: boolean
}

export type TimeErrorType = {
    message: string | undefined
}

export const ERR_REVERSE_TIME = "msgErrReverseTime"

const yesterday = subDays(new Date(), 1)

export const AnalyzeParameter: React.FC<Props> = (props) => {

	const { t, i18n } = useTranslation()
    const { userInfo } = useAuthUserContext()
    const { parameters, updateParameter } = useAnalyticsDataContext()

    const [timeError, setTimeError] = useState<TimeErrorType>({message: undefined})

    const ownParameter = useMemo((): AnalysisParameterSetType => {
        return (props.side === CompareSideType.Primary) ? parameters.single : parameters.compare
    }, [parameters, props])

    const getCloneOwnParameter = useCallback((): AnalysisParameterSetType => {
        const param: AnalysisParameterSetType = (props.side === CompareSideType.Primary) ? parameters.single : parameters.compare
        return { ...param }
    }, [parameters, props])

    const isPrimaryOfCompare = useMemo(() => {
        return props.view === AnalyzeViewType.Compare && props.side === CompareSideType.Primary
    }, [props])

    const startDate = useMemo((): Date | undefined => {
        //return (ownParameter.startDate) ? new Date(ownParameter.startDate) : undefined
        return DateUtil.parseNumberOrString(ownParameter.startDate)
    }, [ownParameter])

    const endDate = useMemo((): Date | undefined => {
        //return (ownParameter.endDate) ? new Date(ownParameter.endDate) : undefined
        return DateUtil.parseNumberOrString(ownParameter.endDate)
    }, [ownParameter])

    const selectedShopId = useMemo(() => {
        return (ownParameter.shopId === undefined) ? 0 : ownParameter.shopId
    }, [ownParameter])

    const handleSetParameter = (param: AnalysisParameterSetType) => {
        const newParam = { ...param }
        newParam.side = props.side
        newParam.valid = validationCheck(param)
        updateParameter(newParam)
    }
    
    const handleChangeStartDate: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const newParam = getCloneOwnParameter()
            //newParam.startDate = format(dt, t("dateFormat.ymd_hy"))
            newParam.startDate = format(dt, STR_YMD_FORMAT)
            updateParameter(newParam)
        }
    }

    const handleChangeEndDate: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const newParam = getCloneOwnParameter()
            //newParam.endDate = format(dt, t("dateFormat.ymd_hy"))
            newParam.endDate = format(dt, STR_YMD_FORMAT)
            updateParameter(newParam)
        }
    }

    const handleChangeStartTime: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const newParam = getCloneOwnParameter()
            // ？なぜかUTCのままパラメーターにつけている。本当は日本なら-9時間すべきでは？
            const jstNumTime = DateUtil.utcDate2UtcNumTime(dt)
            newParam.startTime = jstNumTime
            newParam.valid = validationCheck(newParam)
            updateParameter(newParam)
        }
    }

    const handleChangeEndTime: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const newParam = getCloneOwnParameter()
            // ？なぜかUTCのままパラメーターにつけている。本当は日本なら-9時間すべきでは？
            const jstNumTime = DateUtil.utcDate2UtcNumTime(dt)
            newParam.endTime = jstNumTime
            newParam.valid = validationCheck(newParam)
            updateParameter(newParam)
        }
    }

    const handleChangeWeekdays = (weekdays: Array7<boolean>): void => {
        const newParam = getCloneOwnParameter()
        newParam.weekday = weekdays
        updateParameter(newParam)
    }

    const handleChangeClerk = (exClerk: boolean): void => {
        const newParam = getCloneOwnParameter()
        newParam.excludeClerk = exClerk
        updateParameter(newParam)
    }

    const handleChangeBuyOrNot = (buyOrNot: Array2<boolean>): void => {
        const newParam = getCloneOwnParameter()
        newParam.buyOrNot = buyOrNot
        updateParameter(newParam)
    }

    const handleChangeThreshold = (time: number): void => {
        const newParam = getCloneOwnParameter()
        newParam.threshold = time
        updateParameter(newParam)
    }

    const handleShopSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        const newParam = getCloneOwnParameter()
        newParam.shopId = parseInt(val)
        newParam.layoutId = undefined
        newParam.valid = validationCheck(newParam)
        updateParameter(newParam)
    }

    const handleLayoutSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        const newParam = getCloneOwnParameter()
        newParam.layoutId = parseInt(val)
        // 日付範囲を変更する
        if (newParam.shopId) {
            const layoutList = getPartialLayoutList(newParam.shopId)
            if (layoutList && layoutList.length > 0) {
                const layout = layoutList.find(el => el.layout_id === newParam.layoutId)
                if (layout) {
                    const yesterdayMs = yesterday.getTime()
                    const stMs = layout.start
                    // ENDは本日以降の未来は選択できなくする
                    const edMs = (layout.end && layout.end < yesterdayMs) ? layout.end : yesterdayMs
                    if (stMs) newParam.startDate = format(new Date(stMs), "yyyy-MM-dd")
                    if (edMs) newParam.endDate = format(new Date(edMs), "yyyy-MM-dd")                    
                }
            }
        }
        newParam.valid = validationCheck(newParam)
        console.log("newParam:", newParam)
        updateParameter(newParam)
    }

    // 店舗選択のオプションを取得
    const getShopSelectOptions = useCallback(() => {
        const elm = (<option key="shop-select-x" value={0}>{t("msgSelectStore")}</option>)
        const res = [elm]
        if (userInfo && userInfo.shops && userInfo.shops.length > 0) {
            for (let shop of userInfo.shops) {
                // Hotレイアウトセットにレイアウトが設定されている店舗のみ表示
                if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                    const id = shop.shop_id
                    res.push(<option key={'shop-select-' + id} value={id}>{shop.name}</option>)
                }
            }
        }
        return res
    }, [userInfo, t])

    const getPartialLayoutList = useCallback((shopId: number): ResPartialLayout[] | undefined => {
        if (userInfo && userInfo.shops && userInfo.shops.length > 0) {
            const shop = userInfo.shops.find(el => el.shop_id === shopId)
            if (shop) {
                if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                    return shop.hot_layout_set.layout
                }
            }
        }
        return undefined
    }, [userInfo])

    const validationCheck = useCallback((param: AnalysisParameterSetType): boolean => {
        //バリデーションチェック
        let checkOk = true
        // 店舗とレイアウトが選択されていること
        if (!param.shopId || !param.layoutId) {
            checkOk = false
        }
        // 有効な店舗IDとレイアウトIDであること
        if (param.shopId && userInfo && userInfo.shops && userInfo.shops.length > 0) {
            const shop = userInfo.shops.find(el => el.shop_id === param.shopId)
            if (!shop) {
                checkOk = false
            } else {
                const list = getPartialLayoutList(param.shopId)
                if (list && list.length > 0) {
                    const layout = list.find(el => el.layout_id === param.layoutId)
                    if (!layout) {
                        checkOk = false
                    }
                } else {
                    checkOk = false
                }
            }
        }
        // 時間範囲のスタートとエンドの関係
        if (param.startTime && param.endTime && param.startTime > param.endTime) {
            checkOk = false
            const msg = t(ERR_REVERSE_TIME)
            setTimeError({ message: msg })
        } else {
            setTimeError({ message: undefined })
        }
        return checkOk
    }, [t, getPartialLayoutList, userInfo])

    const getLayoutSelectOptions = useCallback(() => {
        const elm = (<option key="layout-select-x" value={0}>{t("msgSelectMap")}</option>)
        if (ownParameter.shopId) {
            const list = getPartialLayoutList(ownParameter.shopId)
            if (list && list.length > 0) {
                const ops = list.map((el, i) => {
                    // ★ start/end がstringなので。定義numberと異なる。
                    return (<option key={'layout-select-' + (i + 1)} value={el.layout_id}>{el.name}</option>)
                })
                if (ops) return [elm, ...ops]
            }
        }
        return [elm]
    }, [ownParameter, t, getPartialLayoutList])

    const selectedLayoutId = useMemo(() => {
        if (ownParameter.shopId) {
            const list = getPartialLayoutList(ownParameter.shopId)
            if (list && list.length > 0) {
                const target = list.find(el => el.layout_id === ownParameter.layoutId)
                if (target) return target.layout_id
            }
        }
        return 0
    }, [ownParameter, getPartialLayoutList])

    const minDate = useMemo(() => {
        if (ownParameter.shopId && ownParameter.layoutId) {
            const list = getPartialLayoutList(ownParameter.shopId)
            if (list && list.length > 0) {
                const layout = list.find(el => el.layout_id === ownParameter.layoutId)
                if (layout) {
                    const st = layout.start
                    if (st) return new Date(st) 
                }
            }
        }
        return undefined
    }, [ownParameter, getPartialLayoutList])

    const maxDate = useMemo(() => {
        const yMs = yesterday.getTime()
        if (ownParameter.shopId && ownParameter.layoutId) {
            const list = getPartialLayoutList(ownParameter.shopId)
            if (list && list.length > 0) {
                const layout = list.find(el => el.layout_id === ownParameter.layoutId)
                if (layout) {
                    const ed = layout.end
                    if (ed && ed < yMs) return new Date(ed)
                    else return yesterday
                }
            }
        }
        return undefined
    }, [ownParameter, getPartialLayoutList])


    let title: React.ReactElement
    if (props.view === AnalyzeViewType.Compare) {
        title = props.side === CompareSideType.Secondary ? <span><span className={style.greenCircle}></span>{t("comparitiveData")}&#9313;</span> : <span><span className={style.blueCircle}></span>{t("comparitiveData")}&#9312;</span>
    } else {
        title = <span></span>
    }

	const dateRangeFormat = t("dateFormat.ymd_comma")
    return (
        <div className={style.pane}>
            <div className={style.subs}>
                <div className={style.subtitle}>{title}</div>
            </div>
            <div className={isPrimaryOfCompare ? style.main2 : style.main}>
                <div className={style.history}>
                    <ListOfSavedSearches handleChoice={handleSetParameter} viewSide={props.view} />
                </div>
                <div className={style.shopSel}>
                    <select className={style.customSelect} value={selectedShopId} onChange={handleShopSelected}>
                        {getShopSelectOptions()}
                    </select>
                </div>
                <div className={style.mapSel}>
                    <select className={style.customSelect} value={selectedLayoutId} onChange={handleLayoutSelected}>
                        {getLayoutSelectOptions()}
                    </select>
                </div>
                <div className={style.dateRange}>
                    <DateRangePicker
                        locale={i18n.language=== "ja" ? ja : enUS}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        format={dateRangeFormat}
                        onStartDateChange={handleChangeStartDate}
                        onEndDateChange={handleChangeEndDate}
                    >
                        {({ startDateInputProps, endDateInputProps }) => (
                            <>
                                <input {...startDateInputProps} className={style.startDateInput} />
                                <div className={style.connect}>－</div>
                                <input {...endDateInputProps} className={style.endDateInput} />
                            </>
                        )}
                    </DateRangePicker>
                </div>
                <div className={style.timeRange}>
                    <TimePicker
                        locale={ja}
                        date={DateUtil.jstNumTime2UtcDate(ownParameter.startTime)}
                        onChange={handleChangeStartTime}
                    >
                        {({ inputProps }) => <input {...inputProps} className={style.startTimeInput} />}
                    </TimePicker>
                    <div className={style.connect}>－</div>
                    <TimePicker
                        locale={ja}
                        date={DateUtil.jstNumTime2UtcDate(ownParameter.endTime)}
                        onChange={handleChangeEndTime}
                    >
                        {({ inputProps }) => <input {...inputProps} className={style.endTimeInput} />}
                    </TimePicker>
                </div>
                <div className={style.timeErrMsg}>
                    {
                        (timeError.message)
                            ? (
                                <div>{timeError.message}</div>
                            )
                            : (
                                <></>
                            )
                    }
                </div>
                <div className={style.weekSel}>
                    <WeekdaysSelector
                        weekdays={ownParameter.weekday} onChange={handleChangeWeekdays}
                    />
                </div>
                <div className={style.checkSeries}>
                    <div className={style.buyOrNot}>
                        <BuyOrNotCheckbox
                            buyOrNot={ownParameter.buyOrNot} onChange={handleChangeBuyOrNot}
                        />
                    </div>
                    <div className={style.staff}>
                        <ExcludeClerkCheckbox
                            exClerk={ownParameter.excludeClerk} onChange={handleChangeClerk}
                        />
                    </div>
                </div>
                <div className={style.threshold}>
                    <ThresholdTimepicker
                        thresholdTime={ownParameter.threshold}
                        interval={5}
                        min={0}
                        max={300}
                        onChange={handleChangeThreshold}
                    />
                </div>
                <div className={style.saveMsg}>
                    {
                        (props.saved !== undefined && props.saved === true)
                            ?
                            (
                                <div className={style.saveBorder}>
                                    <span className={style.saveTxt}>{t("msgSaved")}</span>
                                </div>                            
                            )
                            :
                            (
                                <div className={style.saveDummy}></div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}