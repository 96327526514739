import { useState, useEffect } from "react"

export type WindowSizeType = {
    width: number | undefined
    height: number | undefined
    outerWidth?: number | undefined
    outerHeight?: number | undefined
}

export const useWindowSize = () => {

    const [windowSize, setWindowSize] = useState<WindowSizeType>({
        width: undefined,
        height: undefined,
        outerWidth: undefined,
        outerHeight: undefined
    })

    useEffect(() => {
        // リサイズハンドラー
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                outerWidth: window.outerWidth,
                outerHeight: window.outerHeight
            })
        }
        // イベント登録
        window.addEventListener("resize", handleResize)
        // 初期設定
        handleResize()

        // DOM消去時のイベント解除
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowSize
}