import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useModal, StyleType as ModalStyleType } from "../../component/modal/Modal"
import { Button, StyleType } from "../../component/button/Button"

import style from "./WarningDialog.module.css"
import { MAX_SEARCH_RESULT } from "../../constants"

export const DialogMessageType = {
    TooManyCustomers: "tooManyCustomers",
    TooLongTerm: "tooLongTerm"
} as const
export type DialogMessage = typeof DialogMessageType[keyof typeof DialogMessageType]

interface Props {
    requestOpen: boolean        // Modal制御、open=true, close=false
    messageType: DialogMessage  // ダイアログの種類
    onAccept: () => void        // Acceptボタン押下
}

export const WarningDialog: React.FC<Props> = (props: Props) => {

    const { t, i18n } = useTranslation()

    const [Modal, open, close, isOpen] = useModal('root', { preventScroll: false, closeOnOverlayClick: false, styleType: ModalStyleType.Dialog })

/**
     * 承認ボタン押下時の処理
     * @param event 
     */
    const handleAccept = (event: React.MouseEvent) => {
        event.preventDefault()
        close()
        props.onAccept()
    }

    /**
     * ダイアローグを開きます
     */
    useEffect(() => {
        if (!isOpen && props.requestOpen) open()        
    }, [isOpen, props.requestOpen, open])
    
    const dialogHeader = useMemo(() => {
        if (props.messageType === DialogMessageType.TooManyCustomers) {
            return t("error.maximumResult")
        } else if (props.messageType === DialogMessageType.TooLongTerm) {
            return t("error.longPeriod")
        } else {
            return ""
        }
    }, [props.messageType, t])

    const dialogBodyMessage = useMemo(() => {
        if (props.messageType === DialogMessageType.TooManyCustomers) {
            const max = (i18n.language === "ja") ? MAX_SEARCH_RESULT / 10000 : MAX_SEARCH_RESULT
            return t("msgTooManyCustomers", { max: max })
        } else if (props.messageType === DialogMessageType.TooLongTerm) {
            return t("msgTooLongTerm")
        } else {
            return ""
        }
    }, [props.messageType, t])

    return (
        <Modal>
            <div className={style.pane}>
                <div className={style.dialogHead}>
                    <div className={style.icon}>
                        <span className="material-symbols-outlined">warning</span>
                    </div>
                    <div className={style.title}>
                        <span className={style.header}>{dialogHeader}</span>
                    </div>
                </div>
                <div className={style.dialogBody}>
                    {dialogBodyMessage}
                </div>
                <div className={style.dialogButton}>
                    <Button label="O K　" name="accepted" styleType={StyleType.Normal} onClick={handleAccept} />
                </div>
            </div>
        </Modal>
    )
}

