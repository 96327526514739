import { LineDefType, LineModel } from "../types/Management";
import { AREA_BORDER_COLOR } from "./ColorUtil";
import MapWriter from "./MapWriter";

// 編集中のエリアの色
export const EDIT_AREA_COLOR = "rgb(30,94,255)" //"#1E5EFF" 100%

const FONT_FAMILY_9 = "9pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"
const FONT_FAMILY_11 = "11pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"


class LineDetailPicWriter extends MapWriter {

    public lines: LineModel[] | undefined

    /**
     * 指定された入店（退店・パス）ラインを１本描きます。
     * 
     * @param ctx 
     * @param lineId 
     * @param color 
     * @returns 
     */
    drawStartGoalLine(ctx: CanvasRenderingContext2D, itm: LineModel, color: string) {
        return new Promise((resolve, reject) => {
            if (itm) {
                // mm -> px
                const x1 = itm.left.x / this.mm_per_pixel
                const x2 = itm.right.x / this.mm_per_pixel
                const y1 = itm.left.y / this.mm_per_pixel
                const y2 = itm.right.y / this.mm_per_pixel
                ctx.beginPath()
                ctx.strokeStyle = color
                ctx.lineWidth = 2
                ctx.moveTo(x1 + this.vtLeft + this.origin_x, y1 + this.vtTop + this.origin_y)
                ctx.lineTo(x2 + this.vtLeft + this.origin_x, y2 + this.vtTop + this.origin_y)
                ctx.stroke()
                let txt = "▲ IN ▲"
                if (itm.type === LineDefType.Exit) txt = "▲ OUT ▲"
                if (itm.type === LineDefType.Pass) txt = "▲ PAS ▲"
                //ctx.restore()
                // 斜めラインに対応させるため中心を求め回転させる
                ctx.save()
                //ctx.scale(this.imageScale, this.imageScale)
                const px = (x1 + x2) / 2
                const py = (y1 + y2) / 2
                const angle = Math.atan2((y2 - y1), (x2 - x1))
                //console.log("angle:", angle)
                ctx.font = FONT_FAMILY_9
                ctx.textBaseline = "middle"
                ctx.textAlign = "center"
                ctx.strokeStyle = color
                ctx.fillStyle = color
                ctx.translate(px + this.vtLeft + this.origin_x, py + this.vtTop + this.origin_y)
                ctx.rotate(angle)
                ctx.translate((px + this.vtLeft + this.origin_x) * -1, (py + this.vtTop + this.origin_y) * -1)
                ctx.fillText(txt, px + this.vtLeft + this.origin_x, py + this.vtTop + this.origin_y - 10)
                ctx.restore()
            }
            resolve(true)
        })
    }

    /**
     * 平面図と入店ラインを描画します。（スケール調整あり）
     * @param ctx 
     */
    async drawImageAndLines(ctx: CanvasRenderingContext2D) {
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        this.clearCanvas(ctx)
        await this.drawImage(ctx)
        if (this.lines) {
            //console.log("lines:", this.lines)
            for await (let ln of this.lines) {
                const color = (ln.isAct) ? EDIT_AREA_COLOR : AREA_BORDER_COLOR
                await this.drawStartGoalLine(ctx, ln, color)
            }
        }
        ctx.restore()
    }

    /**
     * 指定されたラインをハイライト描画します。（スケール調整あり）
     * @param ctx 
     * @param line 
     */
    async drawActiveLine(ctx: CanvasRenderingContext2D, line: LineModel) {
        //if (this.lines) {
            ctx.save()
            ctx.scale(this.imageScale, this.imageScale)
            this.clearCanvas(ctx)
            this.drawStartGoalLine(ctx, line, EDIT_AREA_COLOR)
            ctx.restore()
        //}
    }
}
export default LineDetailPicWriter