import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { TailSpin } from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import style from "./FileUpload.module.css"

export const FileUploadIconType = {
    Up: "up",
    Down: "down"
} as const
export type FileUploadIcon = typeof FileUploadIconType[keyof typeof FileUploadIconType]

interface Props {
    iconType: FileUploadIcon
    guideOn: boolean        // 左上にバッジを表示するフラグ
    isLoading: boolean      // ローディング中の表示フラグ
    errorMessage: string | undefined    // エラーメッセージ
    upload: (file: File) => void
    disabled?: boolean
}

const isValid = (file: File) => {
    const ext = file.name.split('.').pop()
    if (ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "gif") return true
    return false
}

export const FileUpload: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const [errMsg, setErrMsg] = useState<string>("")

	const errFileUpload = t("msgErrFileUpload")
    const onDrop = useCallback((acceptedFiles: any) => {
        console.log(acceptedFiles)
        const file = (acceptedFiles as FileList)[0]
        if (file) {
            if (isValid(file)) {
                props.upload(file)
                setErrMsg("")
            } else {
                setErrMsg(errFileUpload)
            }
        }
    }, [props, errFileUpload])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: props.disabled })
    
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        const file = (target.files as FileList)[0]
        if (file) {
            props.upload(file)
            setErrMsg("")
        }
    }

    if (props.isLoading) {
        return (
            <div className={style.main}>
                <div className={style.spinner}>
                    <TailSpin color="#1E5EFF" width={48} height={48} />
                </div>
            </div>
        )
    }

    const fileFormat = "JPEG/GIF/PNG"
    return (
        <div className={props.disabled ? style.mainDisabled : style.main} {...getRootProps({ onClick: event => {event.stopPropagation()}})} >
            {
                props.guideOn ? (
                    <div className={style.badge}>{t("registerNewMap")}</div>
                ) : (
                    <div className={style.empty}></div>
                )
            }
            <div className={style.lineTop}>{t(isDragActive ? "fileDrop" : "fileDragDrop")}</div>
            <div className={style.lineMid}>{isDragActive ? "" : t("msgOr")}</div>
            <div className={props.disabled ? style.buttonDisabled : style.button} >
                <label htmlFor="fileUploadInput" className={props.disabled ? style.labelDisabled : style.label}>
                    <img className={props.disabled ? style.iconDisabled : style.icon} src={props.iconType === FileUploadIconType.Up ? "/img/fileup.png" : "/img/filedown.png"} alt="file_upload_icon" />
                    {t("addFile")}
                </label>
                <input
                    type="file"
                    id="fileUploadInput"
                     {...getInputProps()}
                    className={style.input}
                    onChange={e => { handleChange(e) }}
                    accept=".jpg, .jpeg, .gif, .png"
                    disabled={props.disabled}
                />
            </div>
            <div className={style.error}>{errMsg}</div>
            <div className={style.error}>{props.errorMessage}</div>
            <div className={style.lineBottom}>{t("msgUploadFileFormat",{format: fileFormat})}</div>
        </div>
    )
}