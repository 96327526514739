import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { LineDefType, LineModel } from "../../types/Management"
import { Button, StyleType } from "../../component/button/Button"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./LineEdit.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {
    line: LineModel | undefined
    updateLine: (newLineModel: LineModel) => void
    onCancel: () => void
    onDone: () => void
}

export const LineEdit: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const ref = useRef<HTMLCanvasElement>(null)

    const handleChangeLeftX = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        try {
            const x = parseInt(v)
            if (!Number.isNaN(x)) {
                if (props.line) {
                    const newLine = { ...props.line }
                    newLine.left.x = x
                    props.updateLine(newLine)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleChangeLeftY = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        try {
            const y = parseInt(v)
            if (!Number.isNaN(y)) {
                if (props.line) {
                    const newLine = { ...props.line }
                    newLine.left.y = y
                    props.updateLine(newLine)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleChangeRightX = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        try {
            const x = parseInt(v)
            if (!Number.isNaN(x)) {
                if (props.line) {
                    const newLine = { ...props.line }
                    newLine.right.x = x
                    props.updateLine(newLine)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleChangeRightY = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        try {
            const y = parseInt(v)
            if (!Number.isNaN(y)) {
                if (props.line) {
                    const newLine = { ...props.line }
                    newLine.right.y = y
                    props.updateLine(newLine)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        try {
            if (props.line) {
                const newLine = { ...props.line }
                if (v === LineDefType.Enter) newLine.type = LineDefType.Enter
                if (v === LineDefType.Exit) newLine.type = LineDefType.Exit
                if (v === LineDefType.Pass) newLine.type = LineDefType.Pass
                props.updateLine(newLine)
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (ref && ref.current) {
            const ctx = ref.current.getContext("2d")
            if (ctx) {
                ctx.beginPath()
                ctx.strokeStyle = "rgb(30,94,255)"
                ctx.lineWidth = 2
                ctx.moveTo(40, 14)
                ctx.lineTo(230, 14)
                ctx.stroke()
                ctx.font = "9pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"
                ctx.textBaseline = "middle"
                ctx.textAlign = "center"
                ctx.strokeStyle = "rgb(30,94,255)"
                ctx.fillStyle = "rgb(30,94,255)"
                ctx.fillText("×", 40, 14)
                ctx.fillText("×", 230, 14)
                ctx.fillText("▲", 130, 5)
                ctx.fillText("▲", 130, 22)
            }
        }
    }, [])

    if (props.line === undefined) return (<></>)
    
    return (
        <div className={style.main}>
            <div className={style.title}>
                {t("header.entryLineDetails")}
                {isProduction ? (<TrackingPageView page_title="bsinfo-line-edit" />) : (null)}
            </div>
            <div className={style.body}>
                <div className={style.points}>
                    <table className={style.table}>
                        <tbody>
                            <tr>
                                <td colSpan={2} className={style.tdLbl}><label>{t("leftEdge")}</label></td>
                                <td className={style.tdSpc}></td>
                                <td colSpan={2} className={style.tdLbl}><label>{t("rightEdge")}</label></td>
                            </tr>
                            <tr>
                                <td className={style.tdXY}><label className={style.label}>X:</label></td>
                                <td className={style.tdInp}>
                                    <input type="text" className={style.input} value={props.line.left.x} onChange={handleChangeLeftX} />
                                </td>
                                <td className={style.tdSpc}></td>
                                <td className={style.tdXY}><label className={style.label}>X:</label></td>
                                <td className={style.tdInp}>
                                    <input type="text" className={style.input} value={props.line.right.x} onChange={handleChangeRightX} />
                                </td>
                            </tr>
                            <tr>
                                <td className={style.tdXY}><label className={style.label}>Y:</label></td>
                                <td className={style.tdInp}>
                                    <input type="text" className={style.input} value={props.line.left.y} onChange={handleChangeLeftY} />
                                </td>
                                <td className={style.tdSpc}><label className={style.direction}>{t("direction")}</label></td>
                                <td className={style.tdXY}><label className={style.label}>Y:</label></td>
                                <td className={style.tdInp}>
                                    <input type="text" className={style.input} value={props.line.right.y} onChange={handleChangeRightY} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={style.picture}>
                    <canvas ref={ref} className={style.canvas} width={280} height={30}></canvas>
                </div>
                <div className={style.choice}>
                    <label className={style.attribute}>{t("type")}</label>
                    <select className={style.select} value={props.line.type} onChange={handleChangeType}>
                        <option value={LineDefType.Enter}>{t("enterShop")}</option>
                        <option value={LineDefType.Exit}>{t("exitShop")}</option>
                        <option value={LineDefType.Pass}>{t("passageway")}</option>
                    </select>
                </div>
            </div>
            <div className={style.bottom}>
                <Button label="button.cancel" name="lineEditCancel" styleType={StyleType.OutMini} onClick={props.onCancel} />
                <div className={style.spc}></div>
                <Button label="button.decide" name="lineEditOk" styleType={StyleType.OutMini} onClick={props.onDone} />
            </div>
        </div>
    )
}