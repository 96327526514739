import React from "react"
import i18n from "i18next"

import style from "./Pagination.module.css"

interface Props {
    currentPage: number
    maxPage: number
    handleChangePage: (page: number) => void
}
/**
 * [~<][1][~>]  1pageのみ
 * [<][1][2][>]
 * [<][1][2][3][>]
 * [<][1][2][3][4][>]
 * [<][1][2][3][4][5][>]
 * [<][1][2][3][...][6][>]
 * [<][1][2][3][...][99][>]
 * [<][1][...][n][n+1][n+2][...][99][>]
 * [<][1][...][97][98]99][>]
 * 
 */

export const Pagination: React.FC<Props> = (props) => {
    if (!props.currentPage || !props.maxPage) {
        return null
    }
    
    const prevPage: number | undefined = (props.currentPage > 1) ? props.currentPage - 1 : undefined
    const nextPage: number | undefined = (props.currentPage < props.maxPage) ? props.currentPage + 1 : undefined
    const lastPage: number | undefined = (props.maxPage > 1) ? props.maxPage : undefined
    let minusPage: number | undefined = undefined
    let centerPage: number | undefined = undefined
    let plusPage: number | undefined = undefined
    if (props.maxPage === 3) {
        centerPage = 2
    } else if (props.maxPage === 4) {
        minusPage = 2
        centerPage = 3
    } else if (props.maxPage > 4) {
        if (props.currentPage === 1 || props.currentPage === 2) {
            minusPage = 2
            centerPage = 3
        } else if (props.currentPage === (props.maxPage - 1) || props.currentPage === props.maxPage) {
            minusPage = props.maxPage - 2
            centerPage = props.maxPage - 1
        } else {
            minusPage = props.currentPage - 1
            centerPage = props.currentPage
            plusPage = props.currentPage + 1
        }
    }

    const handleGoto = (page: number | undefined) => {
        if (page) {
            if (props.handleChangePage) {
                props.handleChangePage(page)
            }
        }
    }

	const prev = i18n.t("prevPage")
	const next = i18n.t("nextPage")
    const prevNode = (prevPage) ? (<div className={style.prev} onClick={e => handleGoto(prevPage)}>{prev}</div>) : (<div className={style.disablePrev}>{prev}</div>)
    const nextNode = (nextPage) ? (<div className={style.next} onClick={e => handleGoto(nextPage)}>{next}</div>) : (<div className={style.disableNext}>{next}</div>) 
    const firstNode = (props.currentPage !== 1) ? (<div className={style.page} onClick={e => handleGoto(1)}>1</div>) : (<div className={style.active}>1</div>)
    let lastNode = null
    if (lastPage) {
        lastNode = (props.maxPage > 1 && props.currentPage !== props.maxPage) ? (<div className={style.page} onClick={e => handleGoto(props.maxPage)}>{props.maxPage}</div>) : (<div className={style.active}>{props.maxPage}</div>)
    }
    const ellipsisNodeFront = (props.maxPage > 4 && props.currentPage > 3) ? (<div className={style.ellipsis}>...</div>) : (null)
    const ellipsisNodeBack = (props.maxPage > 4 && props.currentPage < (props.maxPage - 2)) ? (<div className={style.ellipsis}>...</div>) : (null)
    let minusNode = null
    if (minusPage) {
        minusNode = (minusPage !== props.currentPage) ? (<div className={style.page} onClick={e => handleGoto(minusPage)}>{minusPage}</div>) : (<div className={style.active}>{minusPage}</div>)
    }
    let centerNode = null
    if (centerPage) {
        centerNode = (centerPage !== props.currentPage) ? (<div className={style.page} onClick={e => handleGoto(centerPage)}>{centerPage}</div>) : (<div className={style.active}>{centerPage}</div>)
    }
    let plusNode = null
    if (plusPage) {
        plusNode = (plusPage !== props.currentPage) ? (<div className={style.page} onClick={e => handleGoto(plusPage)}>{plusPage}</div>) : (<div className={style.active}>{plusPage}</div>)
    }

    return (
        <div className={style.pagination}>
            {prevNode}
            {firstNode}
            {ellipsisNodeFront}
            {minusNode}
            {centerNode}
            {plusNode}
            {ellipsisNodeBack}
            {lastNode}
            {nextNode}
        </div>
    )
}