import { createContext, ReactNode, useContext, useEffect, useState, useCallback } from "react"
import { format } from "date-fns"

import { ResLayout } from "../api/data/analysis/FullLayout"
import { AreaCount } from "../api/data/analysis/AnalysisResult"
import { AnalysiAPIImpl } from "../api/api_impl/AnalysisAPIImple"
import { TimeUnitShort, TimeUnitShortType } from "../api/data/core/Enums"
import { AllShopDashboardResult, OneShopDashboardResult } from "../api/data/dashboard/DashboardResult"
import ApiMock from "../api_mock/ApiMock"
import { STR_YMD_FORMAT } from "../constants"
import { TxStatusType, TxStatus } from '../types'
import { DataChoice, DataChoiceType, StatisticsDatasetType } from "../types/Statistics"
import Utils from "../lib/Utils"
import { useAuthUserContext } from "./AuthUser"
import { IndividualChart } from "../feature/dashboard/IndividualLineChart"

/**
 * 単一店舗ダッシュボードのグラデーションチャート用データ
 */
export type IndividualStockDataType = {
    label: string[]     // チャートX軸ラベル
    data: number[]      // チャート用データ
    values: number[]
}

/**
 * エリア平均滞在の単位データ
 */
export type AreaStayAtomData = {
    order: number   // 並び順
    id: number      // エリアID
    name: string    // エリア名
    value: number
}

/**
 * エリア平均滞在時間と滞在率
 */
export type AverageAreaStayDatas = {
    time: Array<AreaStayAtomData[]>
    ratio: Array<AreaStayAtomData[]>
}

/**
 * 単一店舗ダッシュボード用のデータ
 */
export type IndividualDatasetType = {
    shopId: number
    layout: ResLayout
    stock: Record<IndividualChart, IndividualStockDataType>
    areaCountsDay: AreaCount[] | undefined
    areaCountsWeek: AreaCount[] | undefined
    averageAreaStays: AverageAreaStayDatas | undefined
    monthry: Record<IndividualChart, number[]>
}

/**
 * ダッシュボード用のデータを提供します。
 */
export type StatisticsDataContextType = {

    dataset: Record<string, StatisticsDatasetType> | undefined
    status: TxStatus | undefined
    fetch: (abCon: AbortController, baseDate: Date, time_unit: TimeUnitShort) => void

    choice: DataChoice
    updateChoice: (choice: DataChoice) => void

    timeUnit: TimeUnitShort
    updateTimeUnit: (unit: TimeUnitShort) => void

    thresholdTime: number | undefined
    updateThreshold: (time: number) => void

    startingDate: Date
    updateStartingDate: (date: Date) => void

    indivDataset: OneShopDashboardResult | undefined
    indivStatus: TxStatus | undefined
    fetchIndividual: (abCon: AbortController, shopId: number, baseDate: Date) => void
    clearIndividual: () => void
}
const StatisticsDataContext = createContext<StatisticsDataContextType>({} as StatisticsDataContextType)

export const useStatisticsContext = (): StatisticsDataContextType => {
    return useContext<StatisticsDataContextType>(StatisticsDataContext)
}

type Props = {
    children: ReactNode
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const yesterday = Utils.yesterday()

const DEFAULT_DATACHOICE = DataChoiceType.Visitors
const DEFAULT_TIMEUNIT = TimeUnitShortType.Day

export const StatisticsDataProvider = (props: Props) => {

    const { userInfo, orderedShopList } = useAuthUserContext()

    const [apiResults, setApiResults] = useState<AllShopDashboardResult[]|undefined>(undefined)
    const [dataset, setDataset] = useState<Record<string, StatisticsDatasetType> | undefined>(undefined)
    const [status, setStatus] = useState<TxStatus | undefined>(undefined)
    const [choice, setChoice] = useState<DataChoice>(DEFAULT_DATACHOICE)
    const [timeUnit, setTimeUnit] = useState<TimeUnitShort>(DEFAULT_TIMEUNIT)
    const [thresholdTime, setThresholdTime] = useState<number | undefined>(undefined)
    const [startingDate, setStartingDate] = useState<Date>(yesterday)

    const [indivDataset, setIndivDataset] = useState<OneShopDashboardResult | undefined>(undefined)
    const [indivStatus, setIndivStatus] = useState<TxStatus | undefined>(undefined)

    /**
     * 店舗IDから店舗名を取得します。
     */
    const getShopName = useCallback((shopId: number): string => {
        if (orderedShopList === undefined) return ""
        const shop = orderedShopList.find(el => el.shop_id === shopId)
        if (shop) return shop.name
        return ""
    }, [orderedShopList])

    /**
     * APIのデータをグラデーションチャート用データセットに変換します。
     */
    const createChartDataset = useCallback(async (apiRes: AllShopDashboardResult[], mChoice: DataChoice) => {
        const shopIdList = (userInfo) ? userInfo.shops.map(el => el.shop_id) : []
        //console.log("createChartDataset apiRes, mChoice, shopIdList:", apiRes, mChoice, shopIdList)
        const newDataset: Record<string, StatisticsDatasetType> = {}
        for await (let sid of shopIdList) {
            if (apiRes) {
                const k = sid.toString()
                const itm = apiRes?.find(el => el.shop_id === sid)
                if (itm) {
                    let data: number[] = []
                    if (mChoice === DataChoiceType.Visitors) {
                        data = itm.number_of_customers
                    } else if (mChoice === DataChoiceType.Buyers) {
                        data = itm.number_of_purchasers
                    } else if (mChoice === DataChoiceType.SalesAmount) {
                        data = itm.amount_sold
                    } else if (mChoice === DataChoiceType.AvgStayTime) {
                        data = itm.average_stay_time
                    } else if (mChoice === DataChoiceType.AvgNumOfArea) {
                        data = itm.average_area_count
                    } else if (mChoice === DataChoiceType.BuyRatio) {
                        for (let i = 0; i < itm.number_of_customers.length; i++) {
                            const v = Math.round(itm.number_of_purchasers[i] / itm.number_of_customers[i] * 1000) / 10
                            data.push(v)
                        }
                    }
                    const sds: StatisticsDatasetType = {
                        label: itm.date_list,
                        data: data,
                        shopId: sid,
                        shopName: getShopName(sid)
                    }
                    newDataset[k] = sds
                    //console.log("k, sds:", k, sds)
                }
            }
        }
        //console.log("createChartDataset newDataset:", newDataset)
        return newDataset
    }, [userInfo, getShopName])

    /**
     * 全店ダッシュボードデータ取得
     * @param baseDate 
     * @param time_unit 
     */
    const fetch = async (abCon: AbortController, baseDate: Date, time_unit: TimeUnitShort): Promise<void> => {
        try {
            // ステータス更新
            setStatus(TxStatusType.Loading)

            // データ取得
            //const targetDate = format(baseDate, i18n.t("dateFormat.ymd_hy"))
            const targetDate = format(baseDate, 'yyyy-MM-dd')
            let res: AllShopDashboardResult[] = []
            if (isProduction) {
                const api = new AnalysiAPIImpl()
                res = await api.get_all_dashboard_info(targetDate, time_unit, abCon)
            } else {
                res = await ApiMock.instance.get_all_dashboard_info(targetDate, time_unit, abCon)
            }

            // 表示用加工
            const newDataset = await createChartDataset(res, choice)
            //console.log("newDataset:", newDataset)
            setDataset(newDataset)
            setApiResults(res)

            // ステータス更新
            setStatus(TxStatusType.Fulfilled)
            //console.log("fetch status:", status)
        } catch (err) {
            console.error(err)
            setStatus(TxStatusType.Rejected)
        }
    }

    /**
     * 単一店舗ダッシュボードデータ取得
     * @param shopId 
     * @param baseDate 
     */
    const fetchIndividual = async (abCon: AbortController, shopId: number, baseDate: Date) => {
        try {
            console.log("▼fetchIndividual shopId, baseDate:", shopId, baseDate)
            setIndivStatus(TxStatusType.Loading)
            // APIコール
            let result: OneShopDashboardResult | undefined = undefined
            if (isProduction) {
                const api = new AnalysiAPIImpl()
                result = await api.get_dashboard_info(shopId, format(baseDate, STR_YMD_FORMAT), abCon)
            } else {
                result = await ApiMock.instance.get_dashboard_info(shopId, format(baseDate, STR_YMD_FORMAT), abCon)
            }
            console.log("api result:", result)
            setIndivDataset(result)
            setIndivStatus(TxStatusType.Fulfilled)
        } catch (err: any) {
            if (err.name === "CanceledError" || err.code === "ERR_CANCELED") {
                console.log(err)
            } else {
                console.error(err)
            }
            setIndivStatus(TxStatusType.Rejected)
        }
    }

    const clearIndividual = () => {
        setIndivStatus(undefined)
        setIndivDataset(undefined)
    }

    /**
     * データクリア処理
     */
    useEffect(() => {
        if (userInfo === undefined || userInfo === null) {
            if (dataset !== undefined) {
                console.log("UserInfoが無くなったのでダッシュボード用データを削除")
                setDataset(undefined)
            }
            if (apiResults !== undefined) setApiResults(undefined)
            if (status !== undefined) setStatus(undefined)
            if (indivDataset !== undefined) setIndivDataset(undefined)
            if (indivStatus !== undefined) setIndivStatus(undefined)
        }
    }, [userInfo, dataset, status, apiResults, indivStatus, indivDataset])

    /**
     * 滞在時間しきい値の初期化
     */
    useEffect(() => {
        if (thresholdTime === undefined && userInfo) {
            const threshold = userInfo.company.all_dashboard_threshold
            //console.log("滞在時間しきい値の初期化 threshold:", threshold)
            if (threshold) setThresholdTime(threshold)
        } 
    }, [userInfo, thresholdTime])

    const updateChoice = (choice: DataChoice): void => {
        setChoice(choice)
        if (apiResults) {
            createChartDataset(apiResults, choice).then(result => {
                setDataset(result)
            })
        }
    }

    const updateTimeUnit = (unit: TimeUnitShort): void => {
        setTimeUnit(unit)
    }

    const updateThreshold = (time: number): void => {
        try {
            if (mockOn) {
                // APIコール：閾値更新
                ApiMock.instance.set_all_dashboard_stay_threshold(time).then(res => {
                    if (!res) throw new Error("閾値の更新に失敗")
                    // データ更新
                    setThresholdTime(time)
                })
            } else {
                const api = new AnalysiAPIImpl()
                api.set_all_dashboard_stay_threshold(time).then(res => {
                    if (!res) throw new Error("閾値の更新に失敗")
                    // データ更新
                    setThresholdTime(time)
                })
            }
        } catch (err) {
            console.error(err)
            setStatus(TxStatusType.Rejected)
        }
    }

    const updateStartingDate = (date: Date): void => {
        setStartingDate(date)
    }

    const value: StatisticsDataContextType = {
        dataset,
        status,
        fetch,
        choice,
        updateChoice,
        timeUnit,
        updateTimeUnit,
        thresholdTime,
        updateThreshold,
        startingDate,
        updateStartingDate,
        indivDataset,
        indivStatus,
        fetchIndividual,
        clearIndividual,
    }

    return (
        <StatisticsDataContext.Provider value={value}>
            {props.children}
        </StatisticsDataContext.Provider>
    )
}