import React, { useEffect, useRef } from "react"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { useLocation } from "react-router-dom"
import { useAuthUserContext } from "../../providers"

interface Props {
    page_title: string
}

export const TrackingPageView: React.FC<Props> = (props) => {

    const refFirst = useRef<boolean>(true)
    const refUrl = useRef<string>("")
    const refPage = useRef<string>("")
    const location = useLocation()
    const sendDataToGTM = useGTMDispatch()
    const { userInfo } = useAuthUserContext()    

    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP TrackingPageView ", props.page_title)
                return
            }
        }
        // ページ遷移時にのみ実行
        if (refUrl.current === location.pathname) {
            //console.log("refUrl SKIP TrackingPageView ", props.page_title)
            return
        }
        if (refPage.current === props.page_title) {
            //console.log("refPage SKIP TrackingPageView ", props.page_title)
            return
        }
        // GTMにページビューを通知
        sendDataToGTM({
            event: "page_view",
            page_title: props.page_title,
            url_path: location.pathname,
            url_search: location.search,
            event_value: "",
            login_id: userInfo?.user.login_id
        })
        // refに記録
        refUrl.current = location.pathname
        refPage.current = props.page_title
        console.log("TrackingPageView ", props.page_title, location)
    }, [sendDataToGTM, props, location, userInfo])

    return null
}