import { FC, useMemo, ChangeEvent } from "react"
import { DashIcon, PlusIcon } from "@primer/octicons-react"

import style from "./Slider.module.css"

interface Props {
    value: number
    min?: number
    max?: number
    step?: number
    onChange: (value: number) => void
    withButton?: boolean
    withBorder?: boolean
    buttonStep?: number
    size?: "m" | "s"
}

export const Slider: FC<Props> = (props) => {

    const btnStp = useMemo(() => {
        return (props.buttonStep) ? props.buttonStep : 1
    }, [props])

    const handleMinus = () => {
        props.onChange(props.value - btnStp)
    }
    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        const newValue = parseInt(v)
        props.onChange(newValue)
    }
    
    const handlePlus = () => {
        props.onChange(props.value + btnStp)
    }

    if (props.size === "s") {
        return (
            <div className={props.withBorder ? style.paneS : style.noPaneS}>
                {
                    props.withButton ? (
                        <span className={style.iconLeftS} onClick={handleMinus} >
                            <DashIcon size={16} />
                        </span>                
                    ): (null)
                }
                <span className={style.mainS}>
                    <input type="range" className={style.rangeS} onChange={handleChange} value={props.value} step={props.step} min={props.min} max={props.max} />
                </span>
                {
                    props.withButton ? (
                        <span className={style.iconRightS} onClick={handlePlus} >
                            <PlusIcon size={16} />
                        </span>                
                    ) : (null)
                }
            </div>
        )
    }

    return (
        <div className={props.withBorder ? style.pane : style.noPane}>
            {
                props.withButton ? (
                    <span className={style.iconLeft} onClick={handleMinus} >
                        <DashIcon size={24} />
                    </span>                
                ): (null)
            }
            <span className={style.main}>
                <input type="range" className={style.range} onChange={handleChange} value={props.value} step={props.step} min={props.min} max={props.max} />
            </span>
            {
                props.withButton ? (
                    <span className={style.iconRight} onClick={handlePlus} >
                        <PlusIcon size={24} />
                    </span>                
                ) : (null)
            }
        </div>
    )
}