import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"

import { ResSearchQuery } from "../../api/data/analysis/AnalysisRequest";
import { QueryLogType, TxStatusType, PageName, TxStatus } from "../../types";
import { AnalyzeParametersType, AnalyzeViewType, CompareSideType } from "../../types/Analyze";
import { SeriesSwitcher, BtnProp } from "../../component/series_switcher/SeriesSwitcher";
import { MAX_ANALYSIS_PERIOD } from "../../constants";
import { BaseFrame } from "../common/BaseFrame"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData";
import { Progress } from "./Progress";
import { AnalyzeButtons } from "./AnalyzeButtons";
import { AnalyzeParameter } from "./AnalyzeParameter"
import { ResultPanel } from "./ResultPanel";
import Utils from "../../lib/Utils";
import { analysisParamSet2ResSearchQuery } from "../../lib/ModelUtil"
import { TrackingPageView } from "../common/TrackingPageView";
import { useAuthUserContext } from "../../providers"
import { DialogMessage, DialogMessageType, WarningDialog } from "./WarningDialog";

import style from "./Analyze.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {
    param?: string
}

export const ConditionControlType = {
    Fresh: "fresh",         // [＾開始] 
    Ready: "ready",         // [開始]
    Running: "running",     // [！開始]
    Done: "done",           // [保存][＾再検索][リセット]
    Saved: "saved",         // [＾保存][＾再検索][リセット]
    Changed: "changed",     // [＾保存][再検索][リセット]
    Miss: "miss",           // [＾保存][＾再検索][リセット]
    Searching: "searching"  // [！保存][！再検索][！リセット]
} as const
export type ConditionControl = typeof ConditionControlType[keyof typeof ConditionControlType]

/**
 * 分析方法の表示区分データ
 */
export const CompSelector: Array<BtnProp> = [
    { name: AnalyzeViewType.Single, label: "tabUnit" },
    { name: AnalyzeViewType.Compare, label: "tabComparison" }
]

export const Analyze: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()
    const { userInfo } = useAuthUserContext()

    const { dataset, status, analyze, parameters, setViewType, reset, saveAnalyzeQuery, trailReset, clearError } = useAnalyticsDataContext()

    const [condition, setCondition] = useState<ConditionControl>(ConditionControlType.Fresh)

    const [saved, setSaved] = useState<boolean | undefined>(undefined)

    const [prevStatus, setPrevStatus] = useState<TxStatus | undefined>(undefined)
    
    const [timeSt, setTimeSt] = useState<number>(0)

    const [timeEd, setTimeEd] = useState<number>(0)

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    // エラーダイアログの確認済みフラグ
    const [errorConfirmed, setErrorConfirmed] = useState<boolean>(false)

    const [dialogType, setDialogType] = useState<DialogMessage>(DialogMessageType.TooManyCustomers)

    // 検索実行時の条件をキャッシュ
    const searches: AnalyzeParametersType | undefined = useMemo(() => {
        if (dataset && dataset.view && dataset.single && dataset.request) {
            return { ...dataset.request }
        } else {
            return undefined
        }
    }, [dataset])
    
    useEffect(() => {
        // サイドメニュー上の単独・比較切り替えをProviderに反映
        if (props.param && props.param === "compare") {
            setViewType(AnalyzeViewType.Compare)
        } else {
            setViewType(AnalyzeViewType.Single)
        }
    }, [props.param])

    const validationCheck = useCallback((): boolean => {
        if (parameters.view === AnalyzeViewType.Single) {
            if (parameters.single.valid) return true
        } else {
            if (parameters.single.valid && parameters.compare.valid) return true
        }
        return false
    }, [parameters])

    /**
     * 検索実行時の条件と現時点の条件を比較します。
     */
    const isParameterNoChanged = useCallback(() => {
        let result = true
        if (searches) {
            if (searches.view !== parameters.view) result = false
            if (searches.single.shopId !== parameters.single.shopId) result = false
            if (searches.single.layoutId !== parameters.single.layoutId) result = false
            if (searches.single.startDate !== parameters.single.startDate) result = false
            if (searches.single.endDate !== parameters.single.endDate) result = false
            if (searches.single.startTime !== parameters.single.startTime) result = false
            if (searches.single.endTime !== parameters.single.endTime) result = false
            if (!Utils.isSameArray(searches.single.weekday, parameters.single.weekday)) result = false
            if (!Utils.isSameArray(searches.single.buyOrNot, parameters.single.buyOrNot)) result = false
            if (searches.single.excludeClerk !== parameters.single.excludeClerk) result = false
            if (searches.single.threshold !== parameters.single.threshold) result = false
            if (searches.view === AnalyzeViewType.Compare) {
                if (searches.compare.shopId !== parameters.compare.shopId) result = false
                if (searches.compare.layoutId !== parameters.compare.layoutId) result = false
                if (searches.compare.startDate !== parameters.compare.startDate) result = false
                if (searches.compare.endDate !== parameters.compare.endDate) result = false
                if (searches.compare.startTime !== parameters.compare.startTime) result = false
                if (searches.compare.endTime !== parameters.compare.endTime) result = false
                if (!Utils.isSameArray(searches.compare.weekday, parameters.compare.weekday)) result = false
                if (!Utils.isSameArray(searches.compare.buyOrNot, parameters.compare.buyOrNot)) result = false
                if (searches.compare.excludeClerk !== parameters.compare.excludeClerk) result = false
                if (searches.compare.threshold !== parameters.compare.threshold) result = false
            } 
        }
        return result
    }, [searches, parameters])

    useEffect(() => {
        if (dataset?.single?.layout) {
            // 結果があるとき
            if (status?.result === TxStatusType.Loading) {
                // ローディング中
                if (condition!==ConditionControlType.Searching) setCondition(ConditionControlType.Searching)
            } else {
                if (!isParameterNoChanged()) {
                    // パラメーターが変わったとき
                    if (validationCheck()) {
                        setCondition(ConditionControlType.Changed)
                    } else {
                        setCondition(ConditionControlType.Miss)
                    }
                } else {
                    if (saved === undefined) {
                        setCondition(ConditionControlType.Done)
                    } else {
                        setCondition(ConditionControlType.Saved)
                    }
                }
            }
        } else {
            // 結果がないとき(これは、初回の検索時のみとなる。再検索時は結果が残ったままである。)
            if (status?.result === TxStatusType.Rejected) {
                console.error("エラー発生", status.errors)
                // 件数が多すぎる場合はダイアローグを表示
                if (typeof status.errors === "string" && status.errors.startsWith("Too many customers")) {
                    if (isDialogOpen === false) {
                        setDialogType(DialogMessageType.TooManyCustomers)
                        setIsDialogOpen(true)
                        if (errorConfirmed === true) setErrorConfirmed(false)
                    }
                    setCondition(ConditionControlType.Miss)
                }
            } else if (status?.result === TxStatusType.Loading) {
                if (condition!==ConditionControlType.Running) setCondition(ConditionControlType.Running)
            } else {
                if (validationCheck()) {
                    // チェックOK
                    setCondition(ConditionControlType.Ready)
                } else {
                    setCondition(ConditionControlType.Fresh)
                }
            }
        }
    }, [dataset, status, validationCheck, isParameterNoChanged, saved, isDialogOpen, errorConfirmed])

    // 検索時間を表示
    useEffect(() => {
        if (isProduction) {
            // データと開始・終了時刻があるとき
            if (timeSt > 0 && timeEd > 0 && dataset && dataset.single) {
                const time = Math.round(timeEd - timeSt) / 1000
                let count = dataset.single.get_num_of_customers()
                if (dataset.compare) {
                    count += dataset.compare.get_num_of_customers()
                }
                console.log("検索時間: " + time + "秒, 全顧客数: " + count + "件")
                //alert("検索時間: " + time + "秒 \n 全顧客数: " + count + "件")
                setTimeSt(0)
                setTimeEd(0)
            }
        }
    }, [timeSt, timeEd, dataset])

    // 検索終了時にその時間を計測し、エラー発生時にはダイアローグを表示
    useEffect(() => {
        if (isProduction && timeSt > 0) {
            if (status && status.result === TxStatusType.Loading && prevStatus !== status.result) {
                // 検索が始まったら、今のステータスを記憶
                setPrevStatus(status.result)
            } else if (status && status.result === TxStatusType.Fulfilled && prevStatus === TxStatusType.Loading) {
                // LoadingからFulfilledに変わったとき、終了時間を記録
                setTimeEd(performance.now())
                setPrevStatus(undefined)
            } else if (status && status.result === TxStatusType.Rejected && prevStatus === TxStatusType.Loading) {
                // LoadingからRejectedに変わったとき、終了時間を記録
                setTimeEd(performance.now())
                setPrevStatus(undefined)
                // 件数が多すぎる場合はダイアローグを表示
                if (status.errors && typeof status.errors === "string" && status.errors.startsWith("Too many customers")) {
                    if (isDialogOpen === false) {
                        setDialogType(DialogMessageType.TooManyCustomers)
                        setIsDialogOpen(true)
                        if (errorConfirmed === true) setErrorConfirmed(false)
                    }
                }
            }
        }
    }, [status, prevStatus, timeSt, isDialogOpen, errorConfirmed])

    const handle: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === AnalyzeViewType.Single) {
            if (parameters.view === AnalyzeViewType.Compare) navigate("/analyze")
        } else if (nm === AnalyzeViewType.Compare) {
            if (parameters.view === AnalyzeViewType.Single) navigate("/analyze_cmp")
        }
    }

    /**
     * 検索期間が長すぎるかどうかを判定します。
     * @returns 
     */
    const isTooLongTerm = () => {
        if (parameters.single.startDate && parameters.single.endDate) {
            const start = new Date(parameters.single.startDate)
            const end = new Date(parameters.single.endDate)
            const diff = end.getTime() - start.getTime()
            if (diff > 1000 * 60 * 60 * 24 * MAX_ANALYSIS_PERIOD) {
                return true
            }
        }
        if (parameters.compare.startDate && parameters.compare.endDate) {
            const start = new Date(parameters.compare.startDate)
            const end = new Date(parameters.compare.endDate)
            const diff = end.getTime() - start.getTime()
            if (diff > 1000 * 60 * 60 * 24 * MAX_ANALYSIS_PERIOD) {
                return true
            }
        }
        return false
    }

    const handleStart = () => {
        if (isTooLongTerm()) {
            console.log("検索期間が長すぎます")
            setDialogType(DialogMessageType.TooLongTerm)
            setIsDialogOpen(true)
            return
        }
        // 検索開始時間を計測
        if (isProduction) setTimeSt(performance.now())
        // 検索実行
        analyze()
        // 保存済みフラグをリセット
        setSaved(undefined)
        // エラー確認をリセット
        if (errorConfirmed === true) setErrorConfirmed(false)
        // GTM イベント送信(=> GA4 search)
        if (isProduction) {
            sendDataToGTM({
                event: "analyze-click",
                event_value: (parameters.view===AnalyzeViewType.Single) ? parameters.single.shopId : (parameters.single.shopId + " vs " + parameters.compare.shopId),
                page_title: (parameters.view===AnalyzeViewType.Single) ? "analyze-single" : "analyze-compare",
                url_path: "",
                url_search: "",
                login_id: userInfo?.user.login_id
            })
        }
    }

    /**
     * 条件を保存します。
     */
    const handleSaveConditions = () => {
        let log: QueryLogType = { id: 0, insertAt: 0, query: parameters.single }
        let query = analysisParamSet2ResSearchQuery(log)
        let logCmp: QueryLogType = { id: 0, insertAt: 0, query: parameters.compare }
        let queryCmp: ResSearchQuery | undefined = undefined
        if (parameters.view === AnalyzeViewType.Compare) {
            queryCmp = analysisParamSet2ResSearchQuery(logCmp)
        }
        saveAnalyzeQuery(query, queryCmp)
        setSaved(true)
        setCondition(ConditionControlType.Saved)
    }

    /**
     * 条件をリセットします。
     */
    const handleReset = () => {
        reset()
        trailReset()
        setSaved(undefined)
    }

    /**
     * ダイアローグを閉じます。
     */
    const handleCloseDialog = () => {
        if (status && status.result === TxStatusType.Rejected) clearError()
        if (isDialogOpen) setIsDialogOpen(false)
        if (errorConfirmed === false) setErrorConfirmed(true)
    }
    
    if (parameters.view === AnalyzeViewType.Single) {
        // 単一店舗分析の場合
        return (
            <BaseFrame actPage={PageName.AnalyzeSingle}>
                <TrackingPageView page_title="analyze-single" />
                <div className={style.contents}>
                    <div className={style.select}>
                        <div className={style.pane}>
                            <div className={style.head}>
                                <div className={style.title}>{t("header.detailAnalysis_Unit")}</div>
                                <div className={style.cmpSwitch}>
                                    <SeriesSwitcher buttonList={CompSelector} activeName={parameters.view} onClick={e => handle(e)}/>
                                </div>
                            </div>
                            <div className={style.main}>
                                <AnalyzeParameter
                                    view={parameters.view}
                                    side={CompareSideType.Primary}
                                    saved={condition===ConditionControlType.Saved ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <AnalyzeButtons
                        view={parameters.view}
                        condition={condition}
                        onStart={handleStart}
                        onSave={handleSaveConditions}
                        onSearch={handleStart}
                        onReset={handleReset}
                    />
                    {
                        (status && status.result === TxStatusType.Loading) ? (
                            <div className={style.waiting}>
                                <Progress progress={status.progress} remainMsTime={status.remainMsTime}></Progress>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (status && status.result === TxStatusType.Rejected && isDialogOpen === false && errorConfirmed === false) ? (
                            <div className={style.error}>{t("msgErrorOccured")}
                                <div className={style.errorBody}>{status.errors}</div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (status && status.result === TxStatusType.Fulfilled) ? (
                            <div className={style.result}>
                                <ResultPanel searches={searches}/>
                            </div>
                        ) : (<></>)
                    }
                </div>
                <WarningDialog requestOpen={isDialogOpen} messageType={dialogType} onAccept={handleCloseDialog} />
            </BaseFrame>
        )
    } else {
        // 比較店舗分析の場合
        return (
            <BaseFrame actPage={PageName.AnalyzeCompare}>
                <TrackingPageView page_title="analyze-compare" />
                <div className={style.contents}>
                    <div className={style.pane2}>
                        <div className={style.select}>
                            <div className={style.head}>
                                <div className={style.title}>{t("header.detailAnalysis_Comparison")}</div>
                                <div className={style.cmpSwitch2}>
                                    <SeriesSwitcher buttonList={CompSelector} activeName={parameters.view} onClick={e => handle(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className={style.main2}>
                            <AnalyzeParameter
                                view={parameters.view}
                                side={CompareSideType.Primary}
                                saved={condition===ConditionControlType.Saved ? true : false}
                            />
                            <AnalyzeParameter
                                view={parameters.view}
                                side={CompareSideType.Secondary}
                                saved={condition===ConditionControlType.Saved ? true : false}
                            />
                        </div>
                    </div>
                    <AnalyzeButtons
                        view={parameters.view}
                        condition={condition}
                        onStart={handleStart}
                        onSave={handleSaveConditions}
                        onSearch={handleStart}
                        onReset={handleReset}
                    />
                    {
                        (status && status.result === TxStatusType.Loading) ? (
                            <div className={style.waiting}>
                                <Progress progress={status.progress} remainMsTime={status.remainMsTime}></Progress>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (status && status.result === TxStatusType.Rejected && isDialogOpen === false && errorConfirmed === false) ? (
                            <div className={style.error}>{t("msgErrorOccured")}
                                <div className={style.errorBody}>{status.errors}</div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (status && status.result === TxStatusType.Fulfilled) ? (
                            <div className={style.result2}>
                                <ResultPanel searches={searches}/>
                            </div>
                        ) : (<></>)
                    }
                </div>
                <WarningDialog requestOpen={isDialogOpen} messageType={dialogType} onAccept={handleCloseDialog} />
           </BaseFrame>
        )
    }
}