import React, { useCallback, useMemo, useEffect, useState } from "react"
import { ChevronRightIcon, PlusIcon, CopyIcon, PencilIcon } from "@primer/octicons-react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { addDays, format, parse } from "date-fns"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { DataSourceStatusType, EditModeType, UrlParameterType } from "../../types/Management"
import { STR_YMD_FORMAT } from "../../constants"
import { DatasourceAccordion } from "../../component/datasource_accordion/DatasourceAccordion"
import { Button, StyleType } from "../../component/button/Button"
import { DisplayNameType, NotificationDialog, NotificationObject } from "../../component/notification_dialog/NotificationDialog"
import { CopyDialog } from "../../component/copy_dialog/CopyDialog"
import { TrackingPageView } from "../common/TrackingPageView"
import { ListFrame } from "../common/ListFrame"
import DateUtil from "../../lib/DateUtil"

import style from "./LineList.module.css"

type CopyItemType = {
    layoutId: number
    srcLinePackId: number
}

const myPermission = PermissionTypes.ManageLine_View

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props { }

export const LineList: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { companyList, shopId, shopList, dataSourceList, layoutList, accordionItems, notification, setNotification, changeExpanded, changeDataSource, updateDatasourceName, copyDataSource, deleteDataSource, changeLayout, changeHot, copyLinePack, initManagementData } = useManagementDataContext()

    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [item, setItem] = useState<CopyItemType | undefined>(undefined)

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageLine_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageLine_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageLine_Delete) }, [ableToDelete])

    /**
     * タイムゾーン（分）
     */
    const timezoneMinute: number = useMemo(() => {
        if (shopId && shopList) {
            const shop = shopList.find(el => el.shop_id === shopId)
            if (shop) return shop.timezone
        }
        return 0
    }, [shopId, shopList])

    /**
     * 指定されたデータソースに平面図情報がまったくないときtrue
     */
    const hasNoMap = useCallback((layoutSetId: number) => {
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                for (let lay of ds.layout_list) {
                    if (lay.mapping && lay.mapping.image) return false
                }
            }
        }
        return true
    }, [dataSourceList])

    // 下書きレイアウトセットが２つ以上あるときtrue
    const aLotOfDraft = useMemo(() => {
        let cnt = 0
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) {
                    cnt++
                }
            }
            return (cnt > 1)
        }
        return false
    }, [dataSourceList])

    /**
     * 指定されたデータソース（レイアウトセット）が本番かどうか調べます。本番ならtrue
     */
    const isActiveDataSource = useCallback((layoutSetId: number) => {
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds && ds.status === DataSourceStatusType.Active) return true
        }
        return false
    }, [dataSourceList])

    /**
     * データソース（レイアウトセット）一覧に下書きがないときtrue
     */
    const hasNoDraft = useMemo(() => {
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) return false
            }
        }
        return true
    }, [dataSourceList])

    /**
     * データソース名称が変更された時の処理
     */
    const handleChangeDataSourceName = useCallback((layoutSetId: number, newName: string) => {
        //console.log("newName:", newName)
        updateDatasourceName(layoutSetId, newName)
    }, [updateDatasourceName])
    
    /**
     * 入店ライン詳細画面に遷移します。
     */
    const handleOpenDetail = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number, lineSetId: number) => {
        event.preventDefault()
        changeDataSource(layoutSetId)
        changeLayout(layoutId)
        const mode = (!isActiveDataSource(layoutSetId) && ableEdit) ? EditModeType.Edit : EditModeType.View
        navigate("/line_detail?" + UrlParameterType.Mode + "=" + mode
            + "&" + UrlParameterType.LineSetId + "=" + lineSetId,
            { state: { Mode: mode, LineSetId: lineSetId, from: location }})
    }, [ableEdit, navigate, changeDataSource, changeLayout, isActiveDataSource, location])

    /**
     * 入店ライン新規登録画面に遷移します。
     */
    const handleOpenNewEdit = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number) => {
        event.preventDefault()
        if (!ableNew) return
        changeDataSource(layoutSetId)
        changeLayout(layoutId)
        navigate("/line_detail?" + UrlParameterType.Mode + "=" + EditModeType.New,
            { state: { Mode: EditModeType.New, from: location } })
    }, [ableNew, navigate, changeDataSource, changeLayout, location])

    /**
     * 入店ラインパックの複製ボタン押下時の処理
     */
    const handleLineCopy = useCallback((event: React.MouseEvent, layoutId: number, lineSetId: number) => {
        event.preventDefault()
        setItem({ layoutId: layoutId, srcLinePackId: lineSetId } as CopyItemType)
        setDialogOpen(true)
    }, [])

    /**
     * 本番適用ボタン押下時の処理
     * @param id 
     */
    const handleApplyToProduction = useCallback((id: number) => {
        changeHot(id)
    }, [changeHot])

    /**
     * データソースの複製ボタン押下時の処理
     * @param id 
     */
    const handleDataSourceReplication = useCallback((id: number) => {
        const notific: NotificationObject = {
            isActive: true,
            from: DisplayNameType.LineList,
            to: DisplayNameType.LineList,
            message: t("msgDuplicatedLayoutSet")
        }
        copyDataSource(id, notific)
    }, [copyDataSource, t])

    /**
     * 通知を確認した後の処理
     */
    const handleConfirmNotification = () => {
        // 通知をクリア
        setNotification({
            isActive: false,
            from: DisplayNameType.None,
            to: DisplayNameType.None,
            message: ""
        })
    }

    /**
     * データソースの削除ボタン押下時の処理
     */
    const handleDataSourceDelete = useCallback((id: number) => {
        if (ableDelete) deleteDataSource(id)
    }, [deleteDataSource, ableDelete])

    /**
     * ラインパック複製ダイアローグのCancelボタン押下時の処理
     */
    const handleCopyCancel = useCallback(() => {
        //console.log("Canceled")
        setItem(undefined)
        setDialogOpen(false)
    }, [])

    /**
     * ラインパック複製ダイアローグのOKボタン押下時の処理
     */
    const handleCopyDone = useCallback((id: number, start: number) => {
        //console.log("Accepted item:", item)
        if (item) copyLinePack(item.layoutId, item.srcLinePackId, start)
        setItem(undefined)
        setDialogOpen(false)
    }, [copyLinePack, item])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.add(style.rowOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent) => {
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.remove(style.rowOver)
    }, [])

    const handleTranscription = useCallback(() => {
    }, [])

    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    /**
     * ラインパック複製ダイアローグ表示
     */
    const linePackCopyDialog = useMemo(() => {
        if (item && dialogOpen) {
            // 開始日は他のエリアパックと被らないように
            let dt = new Date()
            let ymd = format(dt, STR_YMD_FORMAT)
            const lay = layoutList.find(el => el.layout_id === item.layoutId)
            if (lay && lay.mapping) {
                const lp = lay.mapping.line_packs.find(el => el.line_set_id === item.srcLinePackId)
                if (lp) {
                    // 他の開始日
                    const excludeYmds = lay.mapping.line_packs.map(el => { return el.startYmd })
                    // 被っていたらその翌日にスライド
                    while (excludeYmds.includes(ymd)) {
                        dt = addDays(dt, 1)
                        ymd = format(dt, STR_YMD_FORMAT)
                    }
                    const excludeDates = excludeYmds.map(el => { return parse(el, STR_YMD_FORMAT, new Date()) })
                    const start = dt.getTime()
                    console.log("start:", start)
                    const layoutStartDate = new Date(lay.start)
                    return (
                        <CopyDialog requestOpen={true} id={item.layoutId} start={start} onCancel={handleCopyCancel} onChange={handleCopyDone} excludeDates={excludeDates} minStartDate={layoutStartDate} title={t("header.duplicateLine")} />
                    )
                }
            }
        }
        return (<></>)
    }, [item, dialogOpen, layoutList, t, handleCopyCancel, handleCopyDone])

    const dispAddLine = useMemo(() => { return t("button.addLine") }, [t])
    const dispLineDup = useMemo(() => { return t("button.lineDuplication") }, [t])
    const dispLineEdit = useMemo(() => { return t("button.editLine") }, [t])

    const getItemRows = useCallback((layoutSetId: number) => {
        const result: any = []
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                if (ds.layout_list.length > 0) {
                    ds.layout_list.forEach(lay => {
                        if (lay.mapping) {
                            const lineLen = lay.mapping.line_packs.length
                            if (lineLen === 0) {
                                // 入店ラインが未登録の場合
                                const key = layoutSetId + "_" + lay.layout_id + "_0"
                                const itm = (
                                    <tr key={key}>
                                        <td className={style.tdId}>{lay.layout_id}</td>
                                        <td className={style.tdName}>{lay.name}</td>
                                        <td className={style.tdDate}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                        <td colSpan={4}>
                                            {
                                                (ableNew) ? (<Button styleType={StyleType.MuliAct} name="lineRegistration" label={t("button.newLineRegistration")} onClick={e => handleOpenNewEdit(e, layoutSetId, lay.layout_id)} />) : (null)
                                            }
                                        </td>
                                    </tr>
                                )
                                result.push(itm)
                            } else {
                                const sortedLines = lay.mapping.line_packs.sort((a, b) => {
                                    const tzm = timezoneMinute ? timezoneMinute : 0
                                    const aTm = DateUtil.localYmd2Epochtime(a.startYmd, tzm)
                                    const bTm = DateUtil.localYmd2Epochtime(b.startYmd, tzm)
                                    //console.log("times:", aTm, bTm)
                                    return ((aTm ? aTm : 0) < (bTm ? bTm : 0)) ? 1 : -1
                                })
                                //console.log("sorted:", sortedLines)
                                for (let i = -1; i < lineLen; i++) {
                                    const isProduction = isActiveDataSource(layoutSetId)
                                    const a = sortedLines[i]
                                    if (i === -1) {
                                        // -1は、追加ボタン「＋」を表示（本番は不要＆新規登録権限必要）
                                        if (!isProduction && ableNew) {
                                            const key = layoutSetId + "_" + lay.layout_id + "_0"
                                            const itm = (
                                                <tr key={key}>
                                                    <td className={style.tdId} rowSpan={lineLen + 1}>{lay.layout_id}</td>
                                                    <td className={style.tdName} rowSpan={lineLen + 1}>{lay.name}</td>
                                                    <td className={style.tdDate} rowSpan={lineLen + 1}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                                    <td colSpan={4}>
                                                        <button title={dispAddLine} onClick={e => handleOpenNewEdit(e, layoutSetId, lay.layout_id)} className={style.iconBtn}><PlusIcon size={16} /></button>
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        }
                                    } else {
                                        const key = layoutSetId + "_" + lay.layout_id + "_" + a.line_set_id
                                        if ((isProduction || !ableNew) && i === 0) {
                                            // 本番または新規登録権限なしの時、１行目はレイアウト情報も出す
                                            const itm = (
                                                <tr key={key} onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
                                                    <td className={style.tdId} rowSpan={lineLen}>{lay.layout_id}</td>
                                                    <td className={style.tdName} rowSpan={lineLen}>{lay.name}</td>
                                                    <td className={style.tdDate} rowSpan={lineLen}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                                    <td className={style.tdId}>{a.line_set_id}</td>
                                                    <td className={style.tdDate}>{a.startYmd}</td>
                                                    <td className={style.tdLink}>
                                                        <div className={style.icon} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.line_set_id)}><ChevronRightIcon size={16} /></div>
                                                    </td>
                                                    <td className={style.tdLink}>
                                                    
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        } else {
                                            // ひとつのレイアウト内に複数のラインセットがあるとき、ラインセット２つ目以降
                                            const itm = (
                                                <tr key={key} onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
                                                    <td className={style.tdId}>{a.line_set_id}</td>
                                                    <td className={style.tdDate}>{a.startYmd}</td>
                                                    <td className={style.tdLink}>
                                                        <button title={dispLineEdit} className={style.iconBtn} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.line_set_id)}>{isProduction ? (<ChevronRightIcon size={16} />) : (<PencilIcon size={16} />)}</button>
                                                    </td>
                                                    <td className={style.tdLink}>
                                                        {
                                                            (isProduction || !ableEdit) ? (null) : (
                                                                <button title={dispLineDup} onClick={e => handleLineCopy(e, lay.layout_id, a.line_set_id)} className={style.iconBtn}><CopyIcon size={16} /></button>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        }
                                    }
                                }
                            }
                        }
                    })
                } else {
                    // レイアウトが未登録の場合
                    const itm = (
                        <tr key="0_0_0">
                            <td colSpan={7} className={style.tdName}>
                                {t("layoutNotExist")}
                            </td>
                        </tr>
                    )
                    result.push(itm)
                }
            }
        }
        return result
    }, [dataSourceList, dispAddLine, dispLineDup, timezoneMinute, ableNew, ableEdit, t, handleOpenDetail, handleOpenNewEdit, handleLineCopy, isActiveDataSource, handleRowOver, handleRowOut])

    const accordionList = () => {
        if (accordionItems) {
            return accordionItems.map(el => {
                const itemRows = getItemRows(el.id)
                return (
                    <DatasourceAccordion
                        key={el.id}
                        isExpanded={el.isExpanded}
                        layoutSetId={el.id}
                        name={el.name}
                        status={el.status}
                        setExpanded={changeExpanded}
                        hasNoMap={hasNoMap(el.id)}
                        hasNoDraft={hasNoDraft}
                        updateName={handleChangeDataSourceName}
                        applyToProduction={handleApplyToProduction}
                        onReplication={handleDataSourceReplication}
                        onDelete={handleDataSourceDelete}
                        aLotOfDraft={aLotOfDraft}
                        readyTranscription={false}
                        onTranscription={handleTranscription}
                    >
                        <div className={style.accItems}>
                            {
                                (hasNoMap(el.id)) ? (
                                    <div className={style.noMapBtn}>
                                        <span>{t("msgMapRegistrationRequired")}</span>
                                    </div>
                                ) : (
                                    <table className={style.table}>
                                        <thead>
                                            <tr>
                                                <th colSpan={3} className={style.thOver}><div className={style.overHead}><span>{t("table.layout")}</span></div></th>
                                                <th colSpan={6} className={style.thOver}><div className={style.overHead}><span>{t("table.lineSet")}</span></div></th>
                                            </tr>
                                            <tr className={style.thRow}>
                                                <th className={style.th_id}>ID</th>
                                                <th className={style.th_name}>{t("name")}</th>
                                                <th className={style.th_date}>{t("table.startDate")}</th>
                                                <th className={style.th_id}>ID</th>
                                                <th className={style.th_date}>{t("table.startDate")}</th>
                                                <th className={style.th_link}></th>
                                                <th className={style.th_link}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemRows}
                                        </tbody>
                                    </table>                                        
                                )
                            }
                        </div>
                    </DatasourceAccordion>
                )
            })
        }
        return null
    }

    return (
        <ListFrame
            page={PageName.InfoLine}
            title={t("header.entryLineRegistrationEdit")}
            permission={myPermission}
            companySelect={true}
            shopSelect={true}
            newEntry={false}
            copyEntry={false}
        >
            <div className={style.bodyLabel}>
                {t("header.dataSourceList")}
                {isProduction ? (<TrackingPageView page_title="bsinfo-line-list" />) : (null)}
            </div>
            <div className={style.lsetList}>
                {accordionList()}
            </div>
            <NotificationDialog notification={notification} myName={DisplayNameType.LineList} onAction={handleConfirmNotification} />
            {linePackCopyDialog}
        </ListFrame>
    )
}
