import { AnalysisParameterSetType } from "./Analyze"
import { RoleType } from "../api/data/core/Enums"

export const AllRoleType = Object.values(RoleType)

// バックエンド交信ステータス
export const TxStatusType = {
    Loading: 'loading',         // ロード中
    Fulfilled: 'fulfilled',     // 正常終了
    Rejected: 'rejected'        // 失敗
} as const
export type TxStatus = typeof TxStatusType[keyof typeof TxStatusType]

export type StatusWithError = {
    status: TxStatus
    errorMessage: any
}

// 期間データ
// formatが'yyyy-MM-dd'の文字列で表現する
// スタート日、ストップ日は、共にデータが存在する。
export type DateSpan = {
    start: string
    stop: string
}

export type MsTimeSpan = {
    start: number
    stop: number
}

export type YmdSpan = {
    start: string
    stop: string
}

// 各ページ名のENUM（サイドメニューハイライト用）
export const PageName = {
    DashboardShop: "dash_shop",
    DashboardAll: "dash_all",
    AnalyzeSingle: "single",
    AnalyzeCompare: "compare",
    AnalyzeFunnel: "funnel",
    AnalyzeBuyOrNot: "buy_not",
    AnalyzeArea: "analyze_area",
    InfoCom: "info_com",
    InfoShop: "info_shop",
    InfoMap: "info_map",
    InfoArea: "info_area",
    InfoLine: "info_line",
    InfoGroup: "info_group",
    InfoCategory: "info_categ",
    InfoAccount: "info_acc",
    Logout: "logout",
    Login: "login",
    Dummy: "dummy",
    UnderConstruction: "uc",
    ManageArea: "manage_area",
    ChangePassword: "change_password",
} as const
export type Pages = typeof PageName[keyof typeof PageName]

// 検索条件ログ情報
export type QueryLogType = {
    id: number
    insertAt: number
    query: AnalysisParameterSetType
}
