import React from "react"
import { useTranslation } from "react-i18next"

import style from "./FilterButton.module.css"

//export const FLT_ACTIVE = "active"
//export const FLT_DEACT = "normal"

export const FilterBtnType = {
    Active: "active",
    Deactive: "normal"
} as const
export type FilterBtn = typeof FilterBtnType[keyof typeof FilterBtnType]

interface Props {
    styleType: FilterBtn
    onClick: () => void
}

export const FilterButton: React.FC<Props> = (props) => {
    
	const { t } = useTranslation()
    return (
        <div onClick={props.onClick} className={style.border}>
            <img src={(props.styleType === FilterBtnType.Active) ? "/img/active_filter80x68.png" : "/img/filter32x32.png"} className={style.iconFilter} alt="active"/>
            <label className={style.label}>{t("filter")}</label>
        </div>
    )
}