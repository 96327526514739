import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { useAuthUserContext } from "../../providers"
import { AnalyzeParametersType } from "../../types/Analyze"
import { ContentsDistributor, MainCompoProps, PanelDesignType } from "./ContentsDistributor"
import { SeriesSwitcher, BtnProp, SwitcherFontType } from "../../component/series_switcher/SeriesSwitcher"
import { AnalyzeViewType } from "../../types/Analyze"
import { CHORD_COLORS } from "../../lib/ColorUtil"
import { ChordAtomData, D3ChordGraph } from "../../component/d3_chord_graph/D3ChordGraph"
import { CheckedState, ThreeStateCheckbox, Checked } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { ResArea } from "../../api/data/analysis/FullLayout"
import { ResGroup } from "../../api/data/login/LoginInfo"
import { CHORD_WIDTH, CHORD_HEIGHT } from "../../constants"
import { ChordDataItem } from "../../api/data/analysis/AnalysisResult"

import style from "./ChordGraph.module.css"


type CheckItem = {
    id: number
    num: string
    name: string
    parent?: { id: number, name: string }
    check: boolean
    order: number
}

type CheckListType = {
    bulk: Checked
    items: CheckItem[]
}

export type ChordFullDataType = {
    area: ChordDataItem[]
    group: ChordDataItem[]
}

const DisplayUnitType = {
    Area: "area",
    Group: "group",
} as const
type DisplayUnit = typeof DisplayUnitType[keyof typeof DisplayUnitType]

const SwitchItems: BtnProp[] = [
    { name: DisplayUnitType.Area, label: "area"},
    { name: DisplayUnitType.Group, label: "group" },
]


interface ItemListFilterProps {
    dispUnit: DisplayUnit
    list: CheckListType | undefined
    update: (newList: CheckListType) => void
    isSingle: boolean
}

const ItemListFilter: React.FC<ItemListFilterProps> = (props) => {

	const { t } = useTranslation()
    const handleBulkClick = () => {
        if (props.list) {
            const newList: CheckListType = { ...props.list }
            if (props.list.bulk === CheckedState.None) {
                // 全てONにする
                for (let itm of newList.items) {
                    itm.check = true
                }
                newList.bulk = CheckedState.Full
            } else {
                // 全てOFFにする
                for (let itm of newList.items) {
                    itm.check = false
                }
                newList.bulk = CheckedState.None
            }
            props.update(newList)
        }
    }

    const handleItemClick = (event:CustomCheckboxEvent): void => {
        const id = parseInt(event.target.value)
        if (props.list) {
            const newList = { ...props.list }
            let onCnt = 0
            for (let itm of newList.items) {
                if (itm.id === id) itm.check = !itm.check
                if (itm.check) onCnt++
            }
            if (onCnt === 0) {
                newList.bulk = CheckedState.None
            } else if (onCnt === newList.items.length) {
                newList.bulk = CheckedState.Full
            } else {
                newList.bulk = CheckedState.PartOf
            }
            props.update(newList)
        }
    }

    const getFilterTitle = () => {
        return (props.dispUnit === DisplayUnitType.Area) ? "filterArea" : "filterGroup"
    }

    const getTableHead = () => {
        if (props.list === undefined) return null
        let myName: string =t((props.dispUnit === DisplayUnitType.Area) ? "area" : "group")
        return (
            <tr className={style.tableRow}>
                <th className={style.colCheck}>
                    <ThreeStateCheckbox
                        label={t("select")}
                        checked={props.list.bulk}
                        onClick={handleBulkClick}
                    />
                </th>
                <th className={style.colId}>{myName}ID</th>
                <th className={style.colName}>{t("target")}{myName}</th>
                {
                    (props.dispUnit === DisplayUnitType.Area) ? (<th className={style.colParent}>{t("groupName")}</th>) : (null)
                }
            </tr>
        )
    }

    const getTableRows = () => {
        if (props.list === undefined) return null

        let result = []
        for (let itm of props.list.items) {
            const elm = (
                <tr key={itm.id}>
                    <td className={style.checkCol}>
                        <CustomCheckbox
                            label={""}
                            value={"" + itm.id}
                            check={itm.check}
                            onChange={handleItemClick}
                        />
                    </td>
                    <td className={props.isSingle ? "" : style.idColCmp}>{itm.num}</td>
                    <td className={props.isSingle ? style.dataCol : style.dataColCmp}>{itm.name}</td>
                    {
                        (props.dispUnit === DisplayUnitType.Area) ? (<td className={props.isSingle ? style.dataCol : style.dataColCmp}>{(itm.parent) ? itm.parent.name : ""}</td>) : (null)
                    }                    
                </tr>
            )
            result.push(elm)
        }
        return result
    }

    // リストが空の時
    if (!props.list || !props.list.items|| !props.list.items.length) return null

    return (
        <div className={style.filter}>
            <div className={props.isSingle ? style.filtername : style.filterNameCmp}>
                {t(getFilterTitle())}
            </div>
            <div className={props.isSingle ? style.list : style.listCmp}>
                <div></div>
                <table className={style.table}>
                    <thead>
                        {getTableHead()}
                    </thead>
                    <tbody>
                        {getTableRows()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const ChordGraphCore: React.FC<MainCompoProps> = (props) => {

	const { t } = useTranslation()
    const { userInfo } = useAuthUserContext()

    const [dispUnit, setDispUnit] = useState<BtnProp>(SwitchItems[0])
    const [filteredData, setFilteredData] = useState<ChordAtomData[] | undefined>(undefined)
    const [areaList, setAreaList] = useState<CheckListType | undefined>(undefined)
    const [groupList, setGroupList] = useState<CheckListType | undefined>(undefined)

    const isSingle: boolean = (props.view === AnalyzeViewType.Single) ? true : false

    const filtering = async (src: ChordDataItem[], list: CheckListType | undefined): Promise<ChordAtomData[] | undefined> => {
        const result: ChordAtomData[] = []
        if (list) {
            for await (let item of src) {
                const s = list.items.find(el => el.id === item.src_id)
                const t = list.items.find(el => el.id === item.dst_id)
                if ((s && t) && (s.id !== t.id) && (item.value > 0) && (s.check && t.check)) {
                    const atom: ChordAtomData = {
                        source: s.num + "_" + s.name,
                        target: t?.num + "_" + t?.name,
                        value: item.value
                    }
                    result.push(atom)
                }
            }
            return result        
        } else {
            console.log("filtering list is empty. return undefined. list:", list)
            return undefined
        }
    }

    /**
     * チェックリストに合わせた弦グラフ用データ作成
     */
    const makeFilteredData = useCallback(async (unitType: string, list: CheckListType): Promise<ChordAtomData[] | undefined> => {
        if (props.data) {
            const src = (unitType === DisplayUnitType.Area) ? props.data.get_area_chordal_graph() : props.data.get_group_chordal_graph()
            const data = await filtering(src, list)
            return data
        }
    }, [props.data])
    
    const getParent = useCallback((gid: number | undefined | null): { id: number, name: string } | undefined => {
        const group_list = userInfo?.groups
        if (group_list) {
            const grp = group_list.find(el => el.group_id === gid)
            if (grp) return { id: grp.group_id, name: grp.name }
        }
        return undefined
    }, [userInfo])

    const itemBuilder = useCallback(async (id: number, items: CheckItem[], areaList: ResArea[]): Promise<void> => {
        const m = items.find(el => el.id === id)
        if (m === undefined) {
            const area = areaList.find(el => el.area_id === id)
            if (area) {
                const itm: CheckItem = {
                    id: area.area_id,
                    num: "" + area.area_number,
                    name: area.name,
                    parent: getParent(area.group_id),
                    check: true,
                    order: 0
                }
                items.push(itm)
            }
        }        
    }, [getParent])

    /**
     * グラフデータからエリアのチェックリスト作成
     */
    const makeAreaList = useCallback(async (): Promise<CheckListType | undefined> => {
        const items: CheckItem[] = []
        // ★エリアリストの取得はこれで正しいのか？
        const area_list = (props.data && props.data && props.data.layout && props.data.layout.area_set && props.data.layout.area_set.length > 0) ? props.data?.layout.area_set[0].area_defs : undefined
        const data = props.data?.get_area_chordal_graph()
        if (data) {
            if (area_list && data && data.length) {
                for await (let itm of data) {
                    await itemBuilder(itm.src_id, items, area_list)
                    await itemBuilder(itm.dst_id, items, area_list)
                }
                const result: CheckListType = { bulk: CheckedState.Full, items: items }
                return result
            }
        }
        return undefined
    }, [props.data, itemBuilder])

    const itemBuilderOfGroup = async (id: number, items: CheckItem[], groupList: ResGroup[]): Promise<void> => {
        const m = items.find(el => el.id === id)
        if (m === undefined) {
            const grp = groupList.find(el => el.group_id === id)
            if (grp) {
                const itm: CheckItem = {
                    id: grp.group_id,
                    num: "" + id,
                    name: grp.name,
                    check: true,
                    order: grp.display_order
                }
                items.push(itm)
            }
        }
    }

    /**
     * グラフデータからグループのチェックリスト作成
     */
    const makeGroupList = useCallback(async (): Promise<CheckListType | undefined> => {
        const group_list = userInfo?.groups
        let items: CheckItem[] = []
        const data = props.data?.get_group_chordal_graph()
        if (data) {
            if (group_list && data.length) {
                for await (let itm of data) {
                    await itemBuilderOfGroup(itm.src_id, items, group_list)
                    await itemBuilderOfGroup(itm.dst_id, items, group_list)
                }
                items = items.sort((a, b) => { return (a.order < b.order) ? -1 : 0 })
                const result: CheckListType = { bulk: CheckedState.Full, items: items }
                console.log("makeGroupList list:", result)
                return result
            }
        }
        return undefined
    }, [userInfo, props.data])

    /**
     * エリアとグループの切替処理
     * @param event 
     */
    const changeDisplayRange: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === DisplayUnitType.Area) {
            setDispUnit(SwitchItems[0])
            if (areaList) {
                // グラフデータ再作成
                makeFilteredData(nm, areaList).then(data => {
                    if (data) setFilteredData(data)
                })
            }
        } else {
            setDispUnit(SwitchItems[1])
            if (groupList) {
                makeFilteredData(nm, groupList).then(data => {
                    if (data) setFilteredData(data)
                })
            }
        }
    }

    /**
     * エリアのチェック変更処理
     * @param newList 
     */
    const updateAreaList = (newList: CheckListType): void => {
        if (newList && newList.items && newList.items.length > 0) {
            makeFilteredData(dispUnit.name, newList).then(data => {
                setAreaList(newList)
                if (data) setFilteredData(data)
            })
        }
    }

    /**
     * グループのチェック変更処理
     * @param newList 
     */
    const updateGroupList = (newList: CheckListType): void => {
        if (newList && newList.items && newList.items.length > 0) {
            makeFilteredData(dispUnit.name, newList).then(data => {
                setGroupList(newList)
                if (data) setFilteredData(data)
            })
        }
    }

    /**
     * エリアデータの初期化
     */
    const initializeAreaData = useCallback(async () => {
        const list = await makeAreaList()
        if (list) {
            if (filteredData === undefined && dispUnit.name === DisplayUnitType.Area) {
                const data = await makeFilteredData(dispUnit.name, list)
                setAreaList(list)
                if (data) setFilteredData(data)
            } else {
                setAreaList(list)
            }
        }
    }, [makeAreaList, filteredData, dispUnit, makeFilteredData])

    /**
     * グループデータの初期化
     */
    const initializeGroupData = useCallback(async () => {
        const list = await makeGroupList()
        if (list) {
            if (filteredData === undefined && dispUnit.name === DisplayUnitType.Group) {
                const data = await makeFilteredData(dispUnit.name, list)
                setGroupList(list)
                if (data) setFilteredData(data)
            } else {
                setGroupList(list)
            }
        }
    }, [makeGroupList, filteredData, dispUnit, makeFilteredData])
    
    // コンポーネント初期化
    useEffect(() => {
        //console.log("effect")
        if (areaList === undefined) initializeAreaData()
        if (groupList === undefined) initializeGroupData()
    }, [areaList, groupList, initializeAreaData, initializeGroupData])

    return (
        <div className={style.content}>
            <div className={style.head}>
                <div className={style.legend}>
                </div>
                <div className={isSingle ? style.dummy : style.dummyCmp}></div>
                <div className={style.switch}>
                    <SeriesSwitcher
                        fontType={SwitcherFontType.Mulish}
                        buttonList={SwitchItems}
                        onClick={e => changeDisplayRange(e)}
                        activeName={dispUnit.name}
                    />
                </div>
            </div>
            <div className={style.comment}></div>
            <div className={style.body}>
                <div className={isSingle ? style.graph : style.graphCmp}>
                    {
                        (filteredData && filteredData.length) ? (
                            <D3ChordGraph
                                uid={props.request ? props.request.side : "0"}
                                width={CHORD_WIDTH}
                                height={CHORD_HEIGHT}
                                data={filteredData}
                                colors={CHORD_COLORS}
                            />
                        ) : (
                            <div className={style.message}>{t("msgNoFound")}</div>
                        )
                    }
                </div>
                {
                    (dispUnit.name === DisplayUnitType.Area)
                        ?
                        (<ItemListFilter list={areaList} update={updateAreaList} dispUnit={DisplayUnitType.Area} isSingle={isSingle} />)
                        :
                        (<ItemListFilter list={groupList} update={updateGroupList} dispUnit={DisplayUnitType.Group} isSingle={isSingle} />)
                }
            </div>
        </div>
    )
}

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const ChordGraph: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={ChordGraphCore} type={PanelDesignType.LightBlue} />
    )
}