import React, { useMemo, useEffect, useState, useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"

import { Role, RoleType } from "../../api/data/core/Enums"
import { useAuthUserContext } from "../../providers"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { doYouHaveHighPriorityThanMe } from "../../types/Permissions"
import { EditModeType, UrlParameterType, UserModel } from "../../types/Management"
import { Button, StyleType } from "../../component/button/Button"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { ThreeStateCheckbox, Checked, CheckedState } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import { CheckBoxStyle, CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { ModalFrame } from "../common/ModalFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import Password from "../../lib/Password"
import DateUtil from "../../lib/DateUtil"

import style from "./AccountEdit.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const REGEX_MAIL = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

type CheckObject = {
    id: number
    name: string
    checked: boolean
}

type CheckBoxType = {
    bulk: Checked
    list: CheckObject[]
}

interface Props {}

export const AccountEdit: React.FC<Props> = (props) => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { userInfo } = useAuthUserContext()

    const { companyId, companyList, shopList, userList, userTypes, saveUser, deleteUser, reactivateUser } = useManagementDataContext()

    const { ableToCreate4User, ableToRead4User, ableToUpdate4User, ableToDelete4User } = useAccessControlContext()

    // パラメーター
    const search = useLocation().search
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => { return queryParams.get(UrlParameterType.Mode) }, [queryParams])
    const userId = useMemo(() => { return queryParams.get(UrlParameterType.UserId) }, [queryParams])

    // 自分の所属店舗IDリスト
    const myShopIdList = useMemo(() => {
        if (userInfo && userInfo.user && userList) {
            const me = userList.find(el => el.user_id === userInfo.user.user_id)
            if (me) return me.bookmarks
        }
        return undefined
    }, [userInfo, userList])

    // ユーザー権限
    const ableView = useMemo(() => { return userId ? ableToRead4User(parseInt(userId)) : false }, [ableToRead4User, userId])
    const ableNew = useMemo(() => { return ableToCreate4User() }, [ableToCreate4User])
    const ableEdit = useMemo(() => { return userId ? ableToUpdate4User(parseInt(userId)) : false }, [ableToUpdate4User, userId])
    const ableDelete = useMemo(() => { return userId ? ableToDelete4User(parseInt(userId)) : false }, [ableToDelete4User, userId])
    const canChange = useMemo(() => { return ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit)) }, [mode, ableNew, ableEdit])

    // 表示・入力項目
    const [userName, setUserName] = useState<string>("")
    const [loginId, setLoginId] = useState<string>("")
    const [passWord, setPassWord] = useState<string>("")
    const [userTypeId, setUserTypeId] = useState<number>(0)
    const [checkboxes, setCheckboxes] = useState<CheckBoxType | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [errUserName, setErrUserName] = useState<string>("")
    const [errLoginId, setErrLoginId] = useState<string>("")
    const [errPassWord, setErrPassWord] = useState<string>("")
    const [errUserType, setErrUserType] = useState<string>("")
    const [errBookmarks, setErrBookmarks] = useState<string>("")

    const refMain = useRef<HTMLDivElement>(null)
    const refAddH = useRef<HTMLDivElement>(null)

    /**
     * 代表的なタイムゾーン値（i18nのロケールから求めている）
     * 例）jaなら540、enなら0
     *
    const typicalTimeZoneMinute = useMemo(() => {
        const tz = t("typicalTimeZoneMinute")
        return parseInt(tz)
    }, [t])*/

    // 選択済みの企業
    const company = useMemo(() => {
        if (companyId && companyList) {
            const com = companyList.find(el => el.company_id === companyId)
            if (com) return com
        }
    }, [companyId, companyList])

    /**
     * 削除済みのアカウントかどうか
     */
    const isDeletedUser = useMemo(() => {
        if (userId && userList) {
            const id = parseInt(userId)
            const user = userList.find(el => el.user_id === id)
            if (user) return !(user.expired_at === undefined || user.expired_at === null)
        }
        return false
    }, [userId, userList])

    /**
     * 選択中の利用者種別は複数の店舗を選択できるかどうか
     */
    const canRoleHaveManyShops = useMemo(() => {
        if (userTypes && userTypeId !== 0) {
            for (let i = 0; i < userTypes.length; i++) {
                if (userTypes[i].id === userTypeId) {
                    const myRole = userTypes[i].role
                    if (myRole === RoleType.Root || myRole === RoleType.HeadOffice || myRole === RoleType.AreaManager) return true
                }
            }
        }
        return false
    }, [userTypes, userTypeId])

    /**
     * 入力チェック
     * @returns 
     */
    const validationCheck = (): boolean => {
        setErrUserName("")
        setErrLoginId("")
        setErrPassWord("")
        setErrUserType("")
        setErrBookmarks("")
        let bkCnt = 0
        if (checkboxes && checkboxes.list.length > 0) {
            for (let i = 0; i < checkboxes.list.length; i++) {
                if (checkboxes.list[i].checked) bkCnt++
            }
        }
        let bkOk = canRoleHaveManyShops ? bkCnt > 0 : bkCnt === 1
        let idOk = REGEX_MAIL.test(loginId)
        if (userName && loginId && idOk && passWord && passWord.length > 4 && userTypeId !== 0 && bkOk) {
            console.log("validation check ok")
            return true
        }
        if (!(userName && userName.length > 0)) {
            const msg = t("validation.errInputUserName")
            setErrUserName(msg)
        }
        if (!loginId || loginId.length === 0) {
            const msg = t("validation.errInputLoginId")
            setErrLoginId(msg)
        } else if (loginId.length < 5) {
            const msg = t("validation.errAtLeast5characters")
            setErrLoginId(msg)
        } else if (!idOk) {
            const msg = t("validation.errInputValidEmail")
            setErrLoginId(msg)
        }
        if (!passWord || passWord.length === 0) {
                const msg = t("validation.errInputPassword")
                setErrPassWord(msg)
        } else if (passWord.length < 5) {
            const msg = t("validation.errAtLeast5characters")
            setErrPassWord(msg)
        }
        if (userTypeId === 0) {
            const msg = t("validation.msgNotSelected")
            setErrUserType(msg)
        }
        if (!bkOk) {
            let msg = ""
            if (bkCnt === 0) {
                msg = t("validation.msgNotSelected")
            } else {
                msg = t("validation.msgUpToOneSelection")
            }
            setErrBookmarks(msg)
        }
        console.error("validation check error")
        return false
    }
    
    // キャンセルボタン押下時の処理
    const handleCancel = () => {
        navigate("/account_list")
    }
    
    // 保存ボタン押下時の処理
    const handleSave = () => {
        if (validationCheck()) {
            if (userTypes && userList) {
                console.log("userTypes, userTypeId:", userTypes, userTypeId)
                // 新規の時はidは0にする
                const id = userId ? parseInt(userId) : 0
                const user = (id) ? userList.find(el => el.user_id === id) : undefined
                const utype = userTypes.find(el => el.id === userTypeId)
                const ids = (checkboxes) ? checkboxes.list.filter(el => el.checked).map(el => el.id) : []
                const um: UserModel = {
                    user_id: id,
                    login_id: loginId,
                    password: passWord,
                    name: userName,
                    user_type_id: userTypeId,
                    user_type: (utype) ? utype.role : RoleType.Staff,
                    display_order: (user) ? user.display_order : 0,
                    dashboard_order: (user) ? user.dashboard_order : [],
                    expired_at: (user) ? user.expired_at : undefined,
                    created_at: (user) ? user.created_at : 0,
                    modified_at: (user) ? user.modified_at : 0,
                    bookmarks: ids
                }
                saveUser(um)
                navigate("/account_list")
            }
        }
    }
    
    // この画面を閉じて前のページに戻る
    const handleClose = () => {
        navigate("/account_list")
    }

    /**
     * アカウント削除ボタン押下時の処理
     */
    const handleConfirmDelete = () => {
        if (ableDelete) setDialogOpen(true)
    }
    
    /**
     * 削除確認ダイアローグのOK
     */
    const handleConfirmDeleteAccepted = () => {
        if (!ableDelete) return
            setDialogOpen(false)
        if (userId) {
            const id = parseInt(userId)
            deleteUser(id)
        }
        navigate("/account_list")
    }
    
    /**
     * 削除確認ダイアローグのCancel
     */
    const handleConfirmDeleteCanceled = () => {
        setDialogOpen(false)
    }
    
    // チェックボックスをすべてONにする
    const checkAllOn = async () => {
        if (checkboxes) {
            const newChecks = { ...checkboxes }
            newChecks.bulk = CheckedState.Full
            if (newChecks.list) {
                for await (let chk of newChecks.list) {
                    chk.checked = true
                }
            }
            setCheckboxes(newChecks)
            if (canRoleHaveManyShops) {
                setErrBookmarks("")
            } else {
                setErrBookmarks("validation.msgUpToOneSelection")
            }
        }
    }

    // チェックボックスをすべてOFFにする
    const checkAllOff = async () => {
        if (checkboxes) {
            const newChecks = { ...checkboxes }
            newChecks.bulk = CheckedState.None
            if (newChecks.list) {
                for await (let chk of newChecks.list) {
                    chk.checked = false
                }
            }
            setCheckboxes(newChecks)
            setErrBookmarks(t("validation.msgNotSelected"))
        }
    }

    // 店舗チェックボックスの一括処理
    const handleChangeBulkAll = () => {
        if (checkboxes) {
            if (checkboxes.bulk === CheckedState.None) {
                //全てONにする
                checkAllOn()
            } else {
                //全てOFFにする
                checkAllOff()
            }
        }
    }

    // チェックボックスの変更を反映します。
    const changeCheckbox = useCallback(async (id: number) => {
        if (checkboxes) {
            const newChecks = { ...checkboxes }
            let onCnt = 0
            if (canRoleHaveManyShops) {
                // 複数チェックを許可する場合
                // 対象のフラグを反転
                const target = newChecks.list.find(el => el.id === id)
                if (target) target.checked = !target.checked
                // 一括チェックボックスの変更
                if (newChecks.list) {
                    for await (let chk of newChecks.list) {
                        if (chk.checked) onCnt++
                    }
                }
                if (onCnt === newChecks.list.length) {
                    newChecks.bulk = CheckedState.Full
                } else if (onCnt === 0) {
                    newChecks.bulk = CheckedState.None
                } else {
                    newChecks.bulk = CheckedState.PartOf
                }
            } else {
                // チェックは1つのみの場合
                for (let i = 0; i < newChecks.list.length; i++) {
                    newChecks.list[i].checked = (newChecks.list[i].id === id)
                }
                newChecks.bulk = CheckedState.PartOf
                onCnt = 1
            }
            setCheckboxes(newChecks)

            // エラーメッセージの変更
            if (onCnt === 0) {
                setErrBookmarks(t("validation.msgNotSelected"))
            } else if (onCnt > 1 && !canRoleHaveManyShops) {
                setErrBookmarks(t("validation.msgUpToOneSelection"))
            } else {
                setErrBookmarks("")
            }
        }
    }, [checkboxes, t, canRoleHaveManyShops])

    /**
     * ある店舗のチェックボックスを変更したときの処理
     */
    const handleChangeCheck = useCallback((event: CustomCheckboxEvent) => {
        const id = parseInt(event.target.value)
        changeCheckbox(id)
    }, [changeCheckbox])
    
    // 名前変更時の処理
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value)
        if (errUserName) {
            if (event.target.value.length > 0) setErrUserName("")
        }
    }
    
    // ログインID変更時の処理
    const handleChangeLoginId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginId(event.target.value)
        if (errLoginId) {
            if (event.target.value.length > 0) setErrLoginId("")
        }
    }

    // パスワード変更時の処理（新規のみ）
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassWord(event.target.value)
        if (errPassWord) {
            if (event.target.value.length > 0) setErrPassWord("")
        }
    }

    // ユーザータイプ変更時の処理
    const handleChangeUserType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        setUserTypeId(id)
        // エラーメッセージのクリア
        if (errUserType && id !== 0) setErrUserType("")
        // 所属店舗のエラーチェック
        let alotof = false
        if (userTypes && id !== 0) {
            for (let i = 0; i < userTypes.length; i++) {
                if (userTypes[i].id === id) {
                    const myRole = userTypes[i].role
                    if (myRole === RoleType.Root || myRole === RoleType.HeadOffice || myRole === RoleType.AreaManager) alotof = true
                }
            }
        }
        if (checkboxes && checkboxes.list.length > 0) {
            let cnt = 0
            for (let i = 0; i < checkboxes.list.length; i++) {
                if (checkboxes.list[i].checked) cnt++
            }
            if (cnt === 0) {
                setErrBookmarks(t("validation.msgNotSelected"))
            } else if (cnt > 1 && !alotof) {
                setErrBookmarks(t("validation.msgUpToOneSelection"))
            } else {
                console.log("handleChangeUserType canRoleHaveManyShops, cnt:", canRoleHaveManyShops, cnt)
                setErrBookmarks("")
            }
        }
    }

    // パスワード生成ボタン押下時の処理
    const handleCreatePassword = () => {
        const gen = new Password()
        const pw = gen.createPassword()
        setPassWord(pw)
        setErrPassWord("")
    }

    /**
     * 削除済みのアカウントを復活させる処理
     */
    const handleRestore = () => {
        if (userId) {
            const id = parseInt(userId)
            reactivateUser(id)
        }
    }

    /**
     * 画面初期表示の際に、ユーザー情報を準備します。
     */
    useEffect(() => {
        // 編集・閲覧モード時
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View) && userId && userList && shopList) {
            const id = parseInt(userId)
            const user = userList.find(el => el.user_id === id)
            if (user) {
                setUserName(user.name)
                setLoginId(user.login_id)
                setPassWord("************")
                if (userTypes) {
                    const typ = userTypes.find(el => el.id === user.user_type_id)
                    if (typ) setUserTypeId(typ.id)
                }
                const books = user.bookmarks
                let blk: Checked = (books.length === shopList.length) ? CheckedState.Full : ((books.length === 0) ? CheckedState.None : CheckedState.PartOf)
                const cbox: CheckBoxType = { bulk: blk, list: [] }
                shopList.forEach(shop => {
                    const chk = (books.includes(shop.shop_id))
                    const obj: CheckObject = { id: shop.shop_id, name: shop.name, checked: chk }
                    cbox.list.push(obj)
                })
                setCheckboxes(cbox)
            }
        }
        // 新規モード時
        if (mode && mode === EditModeType.New && !checkboxes && shopList) {
            const cbox: CheckBoxType = { bulk: CheckedState.Full, list: [] }
            shopList.forEach(shop => {
                const obj: CheckObject = { id: shop.shop_id, name: shop.name, checked: true }
                cbox.list.push(obj)
            })
            setCheckboxes(cbox)
        }
    }, [mode, userId, userList, shopList, userTypes])

    // ユーザー種別のセレクトオプション生成
    const userTypeSelectOptions = useMemo(() => {
        const result: any = []
        const ownRole = userInfo?.user.user_type
        const notSel = (<option key="0" value="0">{t("msgPleaseSelect")}</option>)
        result.push(notSel)
        if (userTypes) {
            userTypes.forEach((el, i) => {
                if (!ownRole || !doYouHaveHighPriorityThanMe(ownRole, el.name as Role)) {
                    const nm = t("userTypeName." + el.name)
                    const itm = (
                        <option key={i + 1} value={el.id}>{nm}</option>
                    )
                    result.push(itm)
                }
            })
        }
        return result
    }, [userTypes, t, userInfo])

    // 店舗チェックボックス一覧の生成
    const items = useMemo(() => {
        const result: any = []
        if (checkboxes) {
            checkboxes.list.forEach((el, i) => {
                const isOwn = myShopIdList ? myShopIdList.includes(el.id) : true
                const itm = (
                    <tr key={i}><td><CustomCheckbox type={CheckBoxStyle.Circle} label={el.name} value={el.id.toString()} check={el.checked} onChange={(e) => handleChangeCheck(e)} disabled={!canChange || !isOwn} /></td></tr>
                )
                result.push(itm)
            })
        }
        return result
    }, [checkboxes, handleChangeCheck, canChange, myShopIdList])

    /**
     * アカウント有効期限
     */
    const expired = useMemo(() => {
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View) && userId && userList) {
            const id = parseInt(userId)
            const user = userList.find(el => el.user_id === id)
            if (user && user.expired_at) {
                return DateUtil.epochtime2LocalYmdSlashHms(user.expired_at, 0)
            }
        }
        return "-"
    }, [mode, userId, userList])

    /**
     * レコード作成日時
     */
    const created = useMemo(() => {
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View) && userId && userList) {
            const id = parseInt(userId)
            const user = userList.find(el => el.user_id === id)
            if (user) {
                return DateUtil.epochtime2LocalYmdSlashHms(user.created_at, 0)
            }
        }
        return "-"
    }, [mode, userId, userList])

    /**
     * レコード更新日時
     */
    const modified = useMemo(() => {
        if (mode && (mode === EditModeType.Edit || mode === EditModeType.View) && userId && userList) {
            const id = parseInt(userId)
            const user = userList.find(el => el.user_id === id)
            if (user) {
                return DateUtil.epochtime2LocalYmdSlashHms(user.modified_at, 0)
            }
        }
        return "-"
    }, [mode, userId, userList])

    const strAuto = useMemo(() => { return t("auto") }, [t])
    const msgName = useMemo(() => { return t("msgInputUserName") }, [t])
    const msgEmail = useMemo(() => { return t("msgInputLoginId") }, [t])
    const msgPw = useMemo(() => { return t("msgInputPassword") }, [t])
    const storeNotExist = useMemo(() => { return t("storeNotExist") }, [t])
    const dispBulkCheckbox = useMemo(() => { 
        if (userTypes && userTypeId) {
            for (let i = 0; i < userTypes.length; i++) {
                if (userTypes[i].id === userTypeId) {
                    if (userTypes[i].role === RoleType.Root || userTypes[i].role === RoleType.HeadOffice || userTypes[i].role === RoleType.AreaManager) {
                        return canChange
                    }
                }
            }
        }
        return false
    }, [userTypes, userTypeId, canChange])
    // ダイアログの高さ増量
    const addDialogHeight = useMemo(() => {
        let dh = 0
        if (checkboxes && checkboxes.list.length > 1) dh += (checkboxes.list.length - 1) * 30
        if (dispBulkCheckbox) dh += 30
        if (errUserName) dh += 20
        if (errLoginId) dh += 20
        if (errPassWord) dh += 20
        if (errUserType) dh += 20
        if (errBookmarks) dh += 20
        return dh
    }, [checkboxes, dispBulkCheckbox, errUserName, errLoginId, errPassWord, errUserType, errBookmarks])
    // ダイアローグ内の高さを調整
    useEffect(() => {
        if (refAddH.current) refAddH.current.style.height = (250 + addDialogHeight) + "px"
        if (refMain.current) refMain.current.style.height = (500 + addDialogHeight) + "px"
    }, [addDialogHeight, refAddH])

    return (
        <ModalFrame page={PageName.InfoAccount} title={t("header.accountList")} width={920} height={500 + addDialogHeight}>
            <div className={style.main} ref={refMain}>
                <div className={style.head}>
                    <div className={style.title}>
                        {canChange ? t("header.accountEdit") : t("header.accountDetail")}
                        {isProduction ? (<TrackingPageView page_title="bsinfo-account-edit" />) : (null)}
                    </div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose}/></div>
                </div>
                {
                    ableView || ableNew ? (
                        <div className={style.panel}>
                            <div className={style.left}>
                                <table className={style.table}>
                                    <tbody>
                                        <tr>
                                            <td className={style.dtTitle}>ID</td>
                                            <td className={style.dtText}>{(mode && mode === EditModeType.New) ? strAuto : userId}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("companyName")}</td>
                                            <td className={style.dtText}>{company ? company.name : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("userName")}</td>
                                            <td className={style.dtInput}>
                                                <input type="text" value={userName} placeholder={msgName} onChange={handleChangeName} disabled={!canChange} />
                                                {
                                                    (errUserName) ? (<p><span className={style.errorMessage}>{errUserName}</span></p>) : (null)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("loginId")}</td>
                                            <td className={style.dtInput}>
                                                <input type="text" value={loginId} placeholder={msgEmail} onChange={handleChangeLoginId} disabled={!canChange} />
                                                {
                                                    (errLoginId) ? (<p><span className={style.errorMessage}>{errLoginId}</span></p>) : (null)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("password")}</td>
                                            <td className={style.dtInput}>
                                                {
                                                    (mode && mode === EditModeType.New) ?
                                                        (
                                                            <section>
                                                                <input type="text" value={passWord} placeholder={msgPw} onChange={handleChangePassword} disabled={!canChange} />
                                                                <Button styleType={StyleType.OutMini} label={t("passwordGenerate")} name="createPassword" onClick={handleCreatePassword} />
                                                                {
                                                                    (errPassWord) ? (<p><span className={style.errorMessage}>{errPassWord}</span></p>) : (null)
                                                                }
                                                            </section>
                                                    ) : (
                                                        passWord
                                                    )}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("userType")}</td>
                                            <td className={style.dtSelect}>
                                                <select className={style.select} value={userTypeId} onChange={handleChangeUserType} disabled={!canChange} >{userTypeSelectOptions}</select>
                                                {
                                                    (errUserType) ? (<p><span className={style.errorMessage}>{errUserType}</span></p>) : (null)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("assignedShop")}</td>
                                            <td className={style.dtChecks}>
                                                {
                                                    (shopList && shopList.length > 0) ? (
                                                        <div>
                                                            <div>
                                                                {
                                                                    dispBulkCheckbox ? (<ThreeStateCheckbox checked={checkboxes ? checkboxes.bulk : CheckedState.None} label={t("bulkCheckbox")} onClick={handleChangeBulkAll} />) : (<></>)
                                                                }
                                                            </div>
                                                            <div className={style.chkList}>
                                                                <table className={style.chkTable}>
                                                                    <tbody>
                                                                        {items}
                                                                    </tbody>
                                                                    <caption>
                                                                        {
                                                                            (errBookmarks) ? (<p><span className={style.errorMessage}>{errBookmarks}</span></p>) : (null)
                                                                        }
                                                                    </caption>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ) : (<>{storeNotExist}</>)
                                                }
                                            </td>                                            
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("expiredAt")}</td>
                                            <td className={style.dtText}>{expired}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("registrationDate")}</td>
                                            <td className={style.dtText}>{created}</td>
                                        </tr>
                                        <tr>
                                            <td className={style.dtTitle}>{t("modifiedDate")}</td>
                                            <td className={style.dtText}>{modified}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={style.right}>
                                {
                                    (isDeletedUser) ? (
                                        <div>
                                            {canChange ? (<Button name="restore" label="button.restore" styleType={StyleType.Normal} onClick={handleRestore} />) : (null)}
                                        </div>
                                    ) : (
                                        <div className={style.rightTop}>
                                            {canChange ? (<Button name="cancel" label="cancel" styleType={StyleType.Reverse} onClick={handleCancel} />) : (null)}
                                            <div className={style.spc}></div>
                                            {canChange ? (<Button name="save" label="save" styleType={StyleType.Normal} onClick={handleSave} />) : (null)}
                                        </div>                                        
                                    )
                                }
                                <div className={style.rightMid} ref={refAddH}>
                                </div>
                                <div className={style.rightBtm}>
                                    {
                                        (canChange && ableDelete && !isDeletedUser && !(mode && mode === EditModeType.New)) ? (<Button name="delete" label={t("deleteWithSpace")} styleType={StyleType.DangerMini} onClick={handleConfirmDelete} />) : (null)
                                    }
                                </div>
                            </div>
                        </div>                    
                    ) : (<></>)
                }                
            </div>
            <DeleteDialog
                paneSize={PaneSizeType.forAccountEdit}
                guideMessage={t("msgDeleteUser")}
                requestOpen={dialogOpen}
                onCancel={handleConfirmDeleteCanceled}
                onDelete={handleConfirmDeleteAccepted}
            >
                <span className={style.confirm}>{t("userName")}：{userName}</span>
            </DeleteDialog>
        </ModalFrame>
    )
}