import React, { useState, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"
import { TailSpin } from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Header } from "../common/Header"
import { Button, StyleType } from "../../component/button/Button"
import { useAuthUserContext } from "../../providers"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./ForgotPassword.module.css"

const REGEX_MAIL = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn


interface Props {}

export const ForgotPassword: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"

    const { passwordReissueProcedure } = useAuthUserContext()

    // メールアドレス
    const [email, setEMail] = useState<string | undefined>(undefined)
    // メールアドレス入力エラー
    const [hasError, setHasError] = useState<boolean>(false)
    // 存在しないメールアドレスエラー
    const [unknownIdError, setUnknownIdError] = useState<boolean>(false)
    // 送信完了
    const [success, setSuccess] = useState<boolean>(false)
    // 送信ボタンロック
    const [isLocked, setIsLocked] = useState<boolean>(true)
    // スピナー表示
    const [isWaiting, setIsWaiting] = useState<boolean>(false)

    const handleClose = () => {
        navigate(baseUrl + "login")
    }

    const handleChaneMail = (event: ChangeEvent) => {
        const val = (event.target as HTMLInputElement).value
        setEMail(val)
        setUnknownIdError(false)
        if (REGEX_MAIL.test(val)) {
            if (hasError) {
                // エラーメッセージクリア
                setHasError(false)
            }
            if (isLocked) {
                // 送信ボタンロック解除
                setIsLocked(false)
            }
        } else {
            if (!isLocked) {
                // 送信ボタンロック
                setIsLocked(true)
            }
        }
    }
   
    const handleSubmit = () => {
        if (email) {
            if (REGEX_MAIL.test(email)) {
                // 送信ボタンロック
                setIsLocked(true)
                // スピナー表示
                setIsWaiting(true)
                // API送信
                passwordReissueProcedure(email).then(res => {
                    if (res) {
                        // スピナー解除
                        setIsWaiting(false)
                        // 完了画面
                        setSuccess(true)                        
                    } else {
                        console.error("入力されたメールアドレスは存在しません。")
                        // スピナー解除
                        setIsWaiting(false)
                        // 存在しないメールアドレスエラー
                        setUnknownIdError(true)
                    }
                }).catch(err => {
                    console.error("passwordReissueProcedureでエラーが発生しました.", err)
                    // スピナー解除
                    setIsWaiting(false)
                    window.alert("API呼び出しでエラーが発生しました。")
                    handleClose()
                })
            } else {
                // 入力エラー
                setHasError(true)
            }
        } else {
            // 入力エラー
            setHasError(true)
        }
    }

    // これはMock確認用。正式にはメールに添付されるURLをクリックすることになるので不要となる。
    const handleReset = () => {
        navigate(baseUrl + "reset/hogefuga")
    }

    const getContents = () => {
        if (success) {
            return (
                <div className={style.message}>
                    <span>{t("msgTransmission")}</span>
                    <br></br>
                    <span>{t("msgCheckEmail")}</span>
                </div>
            )
        } else {
			const placeholder = t("msgRegisterEmail")
            return (
                <div className={style.form}>
                    {
                        (hasError) ? (
                            <div className={style.error}>{t("msgIncorrectEmail")}</div>            
                        ): (
                            <div className={style.guide}>{t("msgURLForReissue")}</div>
                        )
                    }
                    <div className={style.input}>
                        <input type="text" id="mail" className={style.inp_ctrl}  placeholder={placeholder} onChange={handleChaneMail} />
                    </div>
                    <div className={style.apiError}>
                        {
                            (unknownIdError) ? (
                                <div className={style.notExist}>{t("error.emailNotExist")}</div>            
                            ): (
                                <div className={style.msgSpace}>　</div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={style.main}>
            <Header />
            <div className={style.pane}>
                <div className={style.head}>
                    <div className={style.title}>
                        {t("header.forgotPassword")}
                        {isProduction ? (<TrackingPageView page_title="forgot-password" />) : (null)}
                    </div>
                    <div className={style.close}>
                        {
                            (success) ? (
                                null
                            ) : (
                                <CircleCloseButton onClose={handleClose} />
                            )
                        }
                    </div>
                </div>
                <div className={style.body}>
                    {
                        getContents()
                    }
                    <div className={style.button}>
                        {
                            (success) ? (
                                <Button
                                    label={t("backLogin")}
                                    name="login"
                                    onClick={handleClose}
                                    styleType={StyleType.Normal}
                                />                            
                            ): (
                                <Button
                                    label={t("issueURL")}
                                    name="login"
                                    onClick={handleSubmit}
                                    styleType={StyleType.Normal}
                                    disabled={(isLocked) ? true : false}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            {
                (isWaiting) ? (
                    <div className={style.spinner}>
                        <div className={style.overlay}></div>
                        <div className={style.waiting}>
                            <TailSpin color="#1E5EFF" width={25} height={25} />
                        </div>                        
                    </div>
                ): (
                    null
                )
            }
            {
                (mockOn && success) ? (
                    <div className={style.reset}>
                        <Button
                                    label={t("passwordReset")}
                                    name="reset"
                                    onClick={handleReset}
                                    styleType={StyleType.OutMini}
                        />{t("msgMayFallRandom")}
                    </div>) : (null)
            }
        </div>
    )
}