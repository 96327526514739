import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { AnalysisResult } from "../../api/data/analysis/AnalysisResult"
import { ResShop } from "../../api/data/login/LoginInfo"
import { useAuthUserContext } from "../../providers"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { AnalyzeParametersType } from "../../types/Analyze"
import { AnalyzeView, AnalyzeViewType, AnalysisParameterSetType } from "../../types/Analyze"

import style from "./ContentsDistributor.module.css"

export const PanelDesignType = {
    NoBorder: "none",
    Shadow: "shadow",
    LightBlue: "light"
} as const
export type PanelDesign = typeof PanelDesignType[keyof typeof PanelDesignType]

export type MainCompoProps = {
    data: AnalysisResult | undefined
    request: AnalysisParameterSetType | undefined
    view: AnalyzeView
}

interface Props {
    searches: AnalyzeParametersType | undefined
    mainConponent: React.FC<MainCompoProps>
    type?: PanelDesign
}

export const ContentsDistributor: React.FC<Props> = ({ searches, mainConponent: MainCompo, type = PanelDesignType.NoBorder }) => {

	const { t } = useTranslation()
    const { userInfo } = useAuthUserContext()
    const { dataset } = useAnalyticsDataContext()

    const getPrimaryShop = useCallback((): ResShop | undefined => {
        if (searches && userInfo) {
            const id = searches.single.shopId
            const shop = userInfo.shops.find(el => el.shop_id === id)
            if (shop) return shop
        }
        return undefined
    }, [searches, userInfo])
    const [primaryShop, setPrimaryShop] = useState<ResShop | undefined>(getPrimaryShop())

    useEffect(() => {
        const shop = getPrimaryShop()
        if (shop !== primaryShop) {
            setPrimaryShop(shop)
        }
    }, [getPrimaryShop, primaryShop])

    const getPrimaryShopName = (): string => {        
        if (primaryShop) return primaryShop.name
        return ""
    }

    if (searches?.view === AnalyzeViewType.Single) {
        return (
            <div className={(type === PanelDesignType.Shadow) ? style.mainSngShadow : style.mainSng}>
                <div className={style.subtitle}>
                    {getPrimaryShopName()}
                </div>
                <MainCompo data={dataset?.single} request={dataset?.request.single} view={AnalyzeViewType.Single} />
            </div>
        )
    } else if (searches?.view === AnalyzeViewType.Compare) {
        return (
            <section>
                <div className={(type === PanelDesignType.LightBlue) ? style.mainCmpLightBlue : style.mainCmpShadow}>
                    <div className={style.subtitle}>{t("comparitiveData")}&#9312;</div>
                    <MainCompo data={dataset?.single} request={dataset?.request.single} view={AnalyzeViewType.Compare} />
                </div>
                <div className={(type === PanelDesignType.LightBlue) ? style.mainCmpLightBlue : style.mainCmpShadow}>
                    <div className={style.subtitle}>{t("comparitiveData")}&#9313;</div>
                    <MainCompo data={dataset?.compare} request={dataset?.request.compare} view={AnalyzeViewType.Compare} />
                </div>
            </section>
        )
    } else {
        return null
    }
}