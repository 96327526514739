export type AreaGroupType = {
    area_id: number,
    group_id: number,
}

export const groupList = [
    { group_id: 1, name: "グループA", display_order: 1 },
    { group_id: 2, name: "グループB_特定商品購買用_特別長い名称にしてみる_50", display_order: 2 },
    { group_id: 3, name: "グループC", display_order: 3 },
    { group_id: 4, name: "グループD", display_order: 4 },
    { group_id: 5, name: "グループE", display_order: 5 },
    { group_id: 6, name: "カテゴリ＿目玉", display_order: 6, deleted_at: 1684713335766 },
    { group_id: 999, name: "サービスエリア", display_order: 999 }
]

export const getGroupAry = () => {
    return groupList
}

export const getGroupList = (shopId: number): AreaGroupType[] => {
        if (shopId === 22) {
            return [
                { area_id: 302, group_id: 1 }, //A: 200
                { area_id: 308, group_id: 1 }, //A: 8
                { area_id: 314, group_id: 1 }, //A: 14
                { area_id: 301, group_id: 2 }, //B: 100
                { area_id: 307, group_id: 2 }, //B: 7
                { area_id: 313, group_id: 2 }, //B: 13
                { area_id: 314, group_id: 3 }, //C: 4
                { area_id: 309, group_id: 3 }, //C: 9
                { area_id: 312, group_id: 3 }, //C: 12
                { area_id: 305, group_id: 4 }, //D: 5
                { area_id: 310, group_id: 4 }, //D: 10
                { area_id: 318, group_id: 4 }, //D: 18
                { area_id: 306, group_id: 5 }, //E: 6
                { area_id: 311, group_id: 5 }, //E: 11
                { area_id: 316, group_id: 5 }, //E: 16
            ]
        } else if (shopId === 21) {
            return [
                { area_id: 706, group_id: 3 },
                { area_id: 709, group_id: 1 },
                { area_id: 712, group_id: 2 },
                { area_id: 715, group_id: 3 },
                { area_id: 718, group_id: 1 },
                { area_id: 721, group_id: 4 },
                { area_id: 725, group_id: 1 },
                { area_id: 729, group_id: 1 },
                { area_id: 732, group_id: 5 },
                { area_id: 735, group_id: 1 },
                { area_id: 740, group_id: 2 },
            ]
        } else if (shopId === 1) {
            return [
                { area_id: 741, group_id: 3 },
                { area_id: 742, group_id: 2 },
                { area_id: 743, group_id: 1 },
                { area_id: 1777, group_id: 999 },
            ]
        } else {
            return []
        }
}