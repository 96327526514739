import React, { useState, useCallback, useMemo,  useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { format, addDays } from "date-fns"
import { PencilIcon, CopyIcon, PlusIcon, TrashIcon, ChevronRightIcon } from "@primer/octicons-react"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { CollapseItem, DataSourceStatusType, EditModeType, UrlParameterType } from "../../types/Management"
import { STR_YMD_FORMAT } from "../../constants"
import { Button, StyleType } from "../../component/button/Button"
import { DatasourceAccordion } from "../../component/datasource_accordion/DatasourceAccordion"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { CopyDialog } from "../../component/copy_dialog/CopyDialog"
import { DisplayNameType, NotificationDialog, NotificationObject } from "../../component/notification_dialog/NotificationDialog"
import { ListFrame } from "../common/ListFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import DateUtil from "../../lib/DateUtil"

import style from "./LayoutList.module.css"

/**
 * この画面で利用するモーダルの種類
 */
const ModalDisplayType = {
    DatasourceRename: "ds_ren", // データソース名変更
    MapDelete: "map_del",       // レイアウト削除
    MapCopy: "map_cpy"          // レイアウトコピー
} as const
type ModalDisplay = typeof ModalDisplayType[keyof typeof ModalDisplayType]

type TargetItemType = {
    dsId: number
    layId: number
    name: string
    start: number
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {}

export const LayoutList: React.FC<Props> = (props) => {    
	const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { companyList, shopId, shopList, dataSourceList, accordionItems, notification, setNotification, changeExpanded, changeDataSource, changeHot, copyDataSource, deleteDataSource, updateDatasourceName, copyLayout, deleteLayout, initManagementData, transcription } = useManagementDataContext()

    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()

    const [modalDisplay, setModalDisplay] = useState<ModalDisplay | undefined>(undefined)
    const [targetItem, setTargetItem] = useState<TargetItemType | undefined>(undefined)

    // ユーザー権限
    const ableNew = useMemo(() => {
        if (dataSourceList && dataSourceList.length > 2) {
            // レイアウトセットが3つ以上ある場合は新規登録ボタンを表示しない
            return false
        }
        return ableToCreate(PermissionTypes.BsInfLayout_New)
    }, [ableToCreate, dataSourceList])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageLayout_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageLayout_Delete) }, [ableToDelete])

    /**
     * タイムゾーン（分）
     */
    const timezoneMinute: number = useMemo(() => {
        if (shopId && shopList) {
            const shop = shopList.find(el => el.shop_id === shopId)
            if (shop) {
                return shop.timezone
            }
        }
        return 0
    }, [shopId, shopList])

    /**
     * 指定されたデータソース内の各レイアウトの開始日のリストを取得します。(Timezone考慮済み)
     */
    const excludeDates = useCallback((layoutSetId: number) => {
        const result: Date[] = []
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                for (let lay of ds.layout_list) {
                    const localTime = lay.start + timezoneMinute * 60 * 1000
                    const dt = new Date(localTime)
                    result.push(dt)
                }
            }
        }
        return result
    }, [dataSourceList, timezoneMinute])
    
    /**
     * 指定されたデータソースに平面図情報がまったくないときtrue
     */
    const hasNoMap = useCallback((layoutSetId: number) => {
        if (dataSourceList && dataSourceList.length > 0) {
            //console.log("dataSourceList:", dataSourceList)
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                for (let lay of ds.layout_list) {
                    if (lay.mapping && lay.mapping.image) return false
                }
            }
        }
        return true
    }, [dataSourceList])

    // 下書きレイアウトセットが２つ以上あるときtrue
    const aLotOfDraft = useMemo(() => {
        let cnt = 0
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) {
                    cnt++
                }
            }
            return (cnt > 1)
        }
        return false
    }, [dataSourceList])

    // 本番ではないこと、かつ本番には(id=0を除く)レイアウトがあること、かつ下書きが１つであること
    const readyTranscription = (layoutSetId: number) => {
        if (dataSourceList) {
            // 本番を探す
            const ds = dataSourceList.find(el => el.status === DataSourceStatusType.Active && el.layout_set_id !== layoutSetId)
            if (ds && ds.layout_list && ds.layout_list.length > 0) {
                // id=0を除く
                const list = ds.layout_list.filter(el => el.layout_id !== 0)
                if (list.length > 0) {
                    console.log("本番あり、レイアウトあり", ds, layoutSetId)
                    // 下書きを探す
                    const draft = dataSourceList.find(el => el.status !== DataSourceStatusType.Active && el.layout_set_id === layoutSetId)
                    if (draft) {
                        console.log("〇 readyTranscription　転写可能　", draft, layoutSetId)
                        return true
                    }
                }
            }
        }
        console.log("✘ readyTranscription　転写不可　", layoutSetId)
        return false
    }

    /**
     * 指定されたデータソース（レイアウトセット）が本番かどうか調べます。本番ならtrue
     */
    const isActiveDataSource = useCallback((layoutSetId: number) => {
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds && ds.status === DataSourceStatusType.Active) return true
        }
        return false
    }, [dataSourceList])

    /**
     * データソース（レイアウトセット）一覧に下書きがないときtrue
     */
    const hasNoDraft = useMemo(() => {
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) return false
            }
        }
        return true
    }, [dataSourceList])    
    
    /**
     * レイアウトのリストのData欄「＞」リンク押下時の処理
     */
    const handleViewOrEdit = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number) => {
        event.preventDefault()
        // 本番の場合はDetailモードで表示
        // 本番以外はEditモードで開く
        changeDataSource(layoutSetId)
        const mode = (isActiveDataSource(layoutSetId) || !ableEdit) ? EditModeType.View : EditModeType.Edit
        navigate("/layout_edit?" + UrlParameterType.Mode + "=" + mode
                + "&" + UrlParameterType.LayoutSetId + "=" + layoutSetId
            + "&" + UrlParameterType.LayoutId + "=" + layoutId,
            { state: { Mode: mode, LayoutSetId: layoutSetId, LayoutId: layoutId, from: location } })
    }, [navigate, isActiveDataSource, changeDataSource, ableEdit, location])

    
    const handleMapAddition = useCallback((event: React.MouseEvent, layoutSetId: number) => {
        // レイアウトの登録画面に遷移する
        changeDataSource(layoutSetId)
        navigate("/layout_edit?" + UrlParameterType.Mode + "=" + EditModeType.New
            + "&" + UrlParameterType.LayoutSetId + "=" + layoutSetId,
            { state: { Mode: EditModeType.New, LayoutSetId: layoutSetId, from: location } })
    }, [navigate, changeDataSource, location])

    /**
     * 平面図の登録ボタン押下時の処理（このボタンはレイアウトセット内にレイアウトがない場合に有効となる）
     */
    const handleMapNewEntry = useCallback((event: React.MouseEvent, layoutSetId: number) => {
        changeDataSource(layoutSetId)
        navigate("/layout_edit?" + UrlParameterType.Mode + "=" + EditModeType.New
            + "&" + UrlParameterType.LayoutSetId + "=" + layoutSetId,
            { state: { Mode: EditModeType.New, LayoutSetId: layoutSetId, from: location } })
    }, [navigate, changeDataSource, location])

    /**
     * レイアウト新規登録（ナビゲーション）へ進む
     */
    const handleGoToNewEntry = useCallback(() => {
        navigate("/layout_regi_step1", { state: { from: location } })
    }, [navigate, location])

    /**
     * データソースの複製ボタン押下時の処理
     * @param id 
     */
    const handleDataSourceReplication = useCallback((layoutSetId: number) => {
        const notific: NotificationObject = {
            isActive: true,
            from: DisplayNameType.LayoutList,
            to: DisplayNameType.LayoutList,
            message: t("msgDuplicatedLayoutSet")
        }
        copyDataSource(layoutSetId, notific)
    }, [copyDataSource, t])

    /**
     * 通知を確認した後の処理
     */
    const handleConfirmNotification = () => {
        // 通知をクリア
        setNotification({
            isActive: false,
            from: DisplayNameType.None,
            to: DisplayNameType.None,
            message: ""
        })
    }

    /**
     * 本番適用ボタン押下時の処理
     * @param id 
     */
    const handleApplyToProduction = useCallback((layoutSetId: number) => {
        changeHot(layoutSetId)
    }, [changeHot])

    /**
     * レイアウトコピーボタンを押下されたので、日付を入力するダイアローグを開きます。
     */
    const handleMapCopy = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number, name: string, start: number) => {
        event.preventDefault()
        // モーダルを開いて、名称と開始日を入力させる。
        setTargetItem({dsId: layoutSetId, layId: layoutId, name: name, start: start})
        setModalDisplay(ModalDisplayType.MapCopy)
        //open()
    }, [])

    const handleMapCopyDone = useCallback((layoutId: number, newStart: number) => {
        const localTime = newStart + timezoneMinute * 60 * 1000
        const startYmd = format(new Date(localTime), STR_YMD_FORMAT)
        copyLayout(layoutId, startYmd)
        //close()
        setModalDisplay(undefined)
        setTargetItem(undefined)
    }, [copyLayout, timezoneMinute])

    const handleMapCopyCancel = useCallback(() => {
        //close()
        setModalDisplay(undefined)
    }, [])

    /**
     * レイアウト削除ボタンを押下されたので確認ダイアローグを開きます。
     */
    const handleMapDelete = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number, name: string, start: number) => {
        event.preventDefault()
        // モーダルを開いて削除前の確認をする
        setTargetItem({dsId: layoutSetId, layId: layoutId, name: name, start: start})
        setModalDisplay(ModalDisplayType.MapDelete)
        //open()
    }, [])

    /**
     * レイアウトの削除実行時の処理
     */
    const handleMapDeleteDone = useCallback((layout_id: number) => {
        deleteLayout(layout_id)
        //close()
        setModalDisplay(undefined)
        setTargetItem(undefined)
    }, [deleteLayout])

    /**
     * 削除確認ダイアローグを閉じます。
     */
    const handleMapDeleteCancel = useCallback(() => {
        //close()
        setModalDisplay(undefined)
    }, [])

    /**
     * データソース名の変更処理
     */
    const handleDataSourceNameDone = useCallback((layoutSetId: number, newName: string) => {
        updateDatasourceName(layoutSetId, newName)
    }, [updateDatasourceName])

    /**
     * データソースの削除処理
     */
    const handleDataSourceDelete = useCallback((layoutSetId: number) => {
        deleteDataSource(layoutSetId)
    }, [deleteDataSource])
    
    /**
     * 画像の読み込みエラー時の処理
     */
    const handleImageLoadError = useCallback((event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        console.error("image load error")
        const target = event.target as HTMLImageElement
        target.src = "/img/noimage.png"
    }, [])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        const target = event.target as HTMLElement
        const tr = target.closest("tr")
        if (tr) tr.classList.add(style.rowOver)
    }, [])
    
    const handleRowOut = useCallback((event: React.MouseEvent) => {
        const target = event.target as HTMLElement
        const tr = target.closest("tr")
        if (tr) tr.classList.remove(style.rowOver)
    }, [])

    // 転写します
    const handleTranscription = useCallback(() => {
        if (shopId) transcription(shopId)
    }, [transcription, shopId])
    
    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化 companyList:", companyList)
            initManagementData()
        }
    }, [companyList, initManagementData])
    
    const titleMapDuplication = useMemo(() => { return t("button.mapDuplication") }, [t])
    const titleDeleteMap = useMemo(() => { return t("button.deleteMap") }, [t])
    const titleAddMap = useMemo(() => { return t("button.addMap") }, [t])
    const titleEditMap = useMemo(() => { return t("button.editMap") }, [t])

    /**
     * アコーディオンの中身（レイアウトのリスト）を作成
     */
    const getItemRows = (layoutSetId: number) => {
        const result: any = []
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            const isProductionDS = isActiveDataSource(layoutSetId)
            //console.log("find Datasource:", ds)
            if (ds) {
                // レイアウト追加ボタン行（本番以外で出す）
                if (!isProductionDS) {
                    const addkey = layoutSetId + "_0"
                    const add = (
                        <tr key={addkey}>
                            <td className={style.tdLink} colSpan={8}>
                                {
                                    (ableNew) ? (<button className={style.iconBtn} title={titleAddMap} onClick={e => handleMapAddition(e, layoutSetId)}><PlusIcon size={16} /></button>) : (<></>)
                                }                                
                            </td>
                        </tr>
                    )
                    result.push(add)
                }
                // データ行の生成（コピーと削除は本番以外で出す）
                ds.layout_list.forEach(lay => {
                    if (lay.mapping) {                        
                        const key = layoutSetId + "_" + lay.layout_id
                        const itm = (
                            <tr key={key} onMouseOver={handleRowOver} onMouseOut={handleRowOut} >
                                <td className={style.tdId}>{lay.layout_id}</td>
                                <td className={style.idName}>{lay.name}</td>
                                <td className={style.tdDate}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                <td className={style.tdId}><img src={lay.mapping.image} width={200} height={150} alt="Thumbnail display of floor plan" onError={handleImageLoadError}/></td>
                                <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(lay.created_at, timezoneMinute)}</td>
                                <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(lay.modified_at, timezoneMinute)}</td>
                                <td className={style.tdLink}>
                                    {
                                        isProductionDS ? (
                                            <div className={style.icon} onClick={e => handleViewOrEdit(e, layoutSetId, lay.layout_id)}><ChevronRightIcon size={16} /></div>
                                        ): (
                                            <button className={style.iconBtn} title={titleEditMap} onClick={e => handleViewOrEdit(e, layoutSetId, lay.layout_id)}><PencilIcon size={16} /></button>            
                                        )
                                    }
                                    
                                </td>
                                <td>
                                    {
                                        isProductionDS ? (null) : (
                                            <section>
                                                {
                                                    (ableEdit) ? (<button className={style.iconBtn} title={titleMapDuplication} onClick={e => handleMapCopy(e, layoutSetId, lay.layout_id, lay.name, lay.start)}><CopyIcon size={16} /></button>) : (<></>)
                                                }
                                                <span className={style.spc}></span>
                                                {
                                                    (ableDelete) ? (<button className={style.iconBtn} title={titleDeleteMap} onClick={e => handleMapDelete(e, layoutSetId, lay.layout_id, lay.name, lay.start)}><TrashIcon size={16} /></button>) : (<></>)
                                                }
                                            </section>
                                        )
                                    }
                                </td>
                            </tr>
                        )
                        result.push(itm)
                    }
                })
            }
        }
        return result
    }

    const getNewEntryButtonRow = (item: CollapseItem) => {
        if (item.status === DataSourceStatusType.Active) return (<div><span>{t("layoutNotExist")}</span></div>)
        return (
            <div className={style.noMapBtn}>
                <Button styleType={StyleType.Normal} name="mapNew" label={titleMapRegistration} onClick={e => handleMapNewEntry(e, item.id)}/>
            </div>                                            
        )
    }

    const titleMapRegistration = useMemo(() => { return t("button.mapRegistration") }, [t])

    /**
     * データセットアコーディオンのリストを作成
     */
    const accordionList = () => {
        if (accordionItems) {
            return accordionItems.map(el => {
                console.log("accordion item:", el)
                // テーブルのデータ行生成
                const itemRows = getItemRows(el.id)
                return (
                    <DatasourceAccordion
                        key={el.id}
                        isExpanded={el.isExpanded}
                        layoutSetId={el.id}
                        name={el.name}
                        status={el.status}
                        hasNoMap={hasNoMap(el.id)}
                        hasNoDraft={hasNoDraft}
                        updateName={handleDataSourceNameDone}
                        setExpanded={changeExpanded}
                        applyToProduction={handleApplyToProduction}
                        onReplication={handleDataSourceReplication}
                        onDelete={handleDataSourceDelete}
                        aLotOfDraft={aLotOfDraft}
                        readyTranscription={readyTranscription(el.id)}
                        onTranscription={handleTranscription}
                    >
                        <div className={style.accItems}>
                            {
                                (hasNoMap(el.id)) ? (
                                    getNewEntryButtonRow(el)
                                ) : (
                                    <section>
                                        <table className={style.table}>
                                            <thead>
                                                <tr>
                                                    <th className={style.th_id}>ID</th>
                                                    <th className={style.th_name}>{t("name")}</th>
                                                    <th className={style.th_date}>{t("table.startDate")}</th>
                                                    <th className={style.th_id}>{t("thumbnail")}</th>
                                                    <th className={style.th_date}>{t("table.createdAt")}</th>
                                                    <th className={style.th_date}>{t("table.modifiedAt")}</th>
                                                    <th className={style.th_link}></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemRows}
                                            </tbody>
                                        </table>
                                    </section>
                                )
                            }
                        </div>
                    </DatasourceAccordion>
                )
            })
        }
        return null
    }

    const shopStartDate = useMemo(() => {
        if (shopList && shopId) {
            const shop = shopList.find(el => el.shop_id === shopId)
            if (shop) {
                return new Date(shop.start)
            }
        }
        return new Date()
    }, [shopList, shopId])

    const mapCopyDialog = useMemo(() => {
        if (modalDisplay === ModalDisplayType.MapCopy) {
            let layoutId = 0
            //let name: string = ""
            let start: number = 0
            let exDs: Date[] = []
            if (targetItem) {
                layoutId = targetItem.layId
                //name = targetItem.name
                // 日付は、他のレイアウトで設定された開始日の中で最大日の翌日または本日のどちらか大きい方とする。
                const now = new Date()
                exDs = excludeDates(targetItem.dsId)
                if (exDs.length > 2) exDs = exDs.sort((a, b) => (a < b) ? 1 : -1)
                const next = addDays(exDs[0], 1)
                if (next > now) {
                    start = next.getTime()
                } else {
                    start = now.getTime()
                }
                //console.log("exDs, next, start", exDs, next, start)
            }
            return (
                <CopyDialog requestOpen={true} id={layoutId} start={start} onCancel={handleMapCopyCancel} onChange={handleMapCopyDone} excludeDates={exDs} minStartDate={shopStartDate} title={t("header.duplicateLayout")} />
            )
        } else {
            return (<></>)
        }
    }, [modalDisplay, targetItem, t, handleMapCopyCancel, handleMapCopyDone, excludeDates])

    const mapDeleteDialog = useMemo(() => {
        if (modalDisplay === ModalDisplayType.MapDelete) {
            let layoutId = 0
            let layoutName = ""
            let startDate = ""
            if (targetItem) {
                layoutId = targetItem.layId
                layoutName = targetItem.name
                const dt = DateUtil.epochtime2LocalYmdSlashHms(targetItem.start, timezoneMinute)
                if (dt) startDate = dt
            }
            return (
                <DeleteDialog requestOpen={true} guideMessage={t("msgConfirmLayoutDelete")} paneSize={PaneSizeType.forLayout} onCancel={handleMapDeleteCancel} onDelete={() => handleMapDeleteDone(layoutId)}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={style.dialogTitle}>ID</td>
                                <td className={style.dialogData}>{layoutId}</td>
                            </tr>
                            <tr>
                                <td className={style.dialogTitle}>{t("layoutName")}</td>
                                <td className={style.dialogData}>{layoutName}</td>
                            </tr>
                            <tr>
                                <td className={style.dialogTitle}>{t("table.startDate")}</td>
                                <td className={style.dialogData}>{startDate}</td>
                            </tr>
                        </tbody>
                    </table>
                </DeleteDialog>
            )
        } else {
            return (<></>)
        }
    }, [modalDisplay, targetItem, timezoneMinute, t, handleMapDeleteCancel, handleMapDeleteDone])

    console.log ("shopId, ableNew:", shopId, ableNew)
    return (
        <ListFrame
            page={PageName.InfoMap}
            title={t("menu.mapRegistrationEdit")}
            companySelect={true}
            shopSelect={true}
            newEntry={(ableNew) ? true : false}
            copyEntry={false}
            onClickNew={handleGoToNewEntry}
            permission={PermissionTypes.ManageLayout_View}
        >
            <div className={style.subTitle}>
                {t("header.dataSourceList")}
                {isProduction ? (<TrackingPageView page_title="bsinfo-layout-list" />) : (null)}
            </div>
            <div className={style.listview}>
                {accordionList()}
            </div>
            {mapCopyDialog}
            {mapDeleteDialog}
            <NotificationDialog notification={notification} myName={DisplayNameType.LayoutList} onAction={handleConfirmNotification}/>
        </ListFrame>
    )
}