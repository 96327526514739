import { createContext, ReactNode, useContext, useState, useCallback } from "react"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

import { useAuthUserContext } from "./AuthUser"
import { ResLayoutSet, ResShop, ResUser } from "../api/data/login/LoginInfo"
import { ResAreaSet, ResLayout } from "../api/data/analysis/FullLayout"
import { PermissionTypes } from "../types/Permissions"
import { AreaPackModel, CollapseItem, CompanyModel, DataSourceModel, DataSourceStatusType, GroupModel, LayoutModel, LineDefType, LinePackModel, ShopModel, UserModel } from "../types/Management"
import { ReqNewUser, ReqUpdateUser } from "../api/data/edit/Req"
import { AreaSet2PackModel, CompanyAryRes2Model, CompanyModel2Req, CompanyRes2Model, FullLayoutRes2Model, LayoutModel2Req, LayoutRes2MapModel, LineModel2ReqLineDef, ShopAryRes2Model, ShopModel2Req, ShopRes2Model, LineSet2PackModel, convertLineObject2LineModel, LayoutSet2DataSourceModel, GroupRes2Model, GroupModel2Res, AreaPackModel2ResAreaSet, Transcription2Model } from "../lib/ModelUtil"
import { Role } from "../api/data/core/Enums"
import { EditAPI } from "../api/api_impl/edit/EditAPI"
import { BaseInfoEditAPI } from "../api/api_interface/edit/edit_base_info"
import { DS_NO_NAME } from "../constants"
import { LINE_ID_ENT, LINE_ID_EXT, LINE_ID_PAS } from "../feature/basic_info/LineDetail"
import { DisplayNameType, NotificationObject } from "../component/notification_dialog/NotificationDialog"
import Utils from "../lib/Utils"
import { EditAPIMock } from "../api_mock/EditAPIMock"
import { STR_YMD_FORMAT } from "../constants"
import { ReqLineDef } from "../api/data/edit/ReqLineDef"
import CanvasWorker from "../lib/CanvasWorker"
import { CreateShopPayload } from "../api/data/edit/RequestModel"
import { EditV2API } from "../api/api_impl/edit/EditV2API"

/**
 * ユーザータイプ情報
 */
export type UserTypeObject = {
    id: number
    name: string
    role: Role
}

/**
 * useManagementData() で利用できる全ての変数・関数の定義
 */
export type ManagementDataContextType = {
    //企業
    companyId: number | undefined
    changeCompany: (id: number | undefined) => void
    companyList: CompanyModel[]
    saveCompany: (company: CompanyModel) => void
    // アカウント
    userList: UserModel[] | undefined
    userTypes: UserTypeObject[] | undefined
    saveUser: (user: UserModel) => void
    deleteUser: (userId: number) => void
    reactivateUser: (userId: number) => void
    //店舗
    shopId: number | undefined
    changeShop: (id: number | undefined) => void
    shopList: ShopModel[]
    saveShop: (companyId: number, shop: ShopModel) => void
    deleteShop: (id: number) => void
    // データソース
    dataSourceId: number | undefined
    changeDataSource: (id: number | undefined) => void
    dataSourceList: DataSourceModel[]
    updateDatasourceName: (layoutSetId: number, name: string) => Promise<boolean>
    copyDataSource: (srcId: number, notification: NotificationObject) => void
    changeHot: (id: number) => void
    deleteDataSource: (dsId: number) => void
    accordionItems: CollapseItem[] | undefined
    changeExpanded: (id: number, expan: boolean) => void
    transcription: (shopId: number) => void     // 転写（HotからColdへ上書きコピー）
    //レイアウト（平面図）
    layoutId: number | undefined
    changeLayout: (id: number | undefined) => void
    layoutList: LayoutModel[]
    saveLayout: (layoutSetId: number, layout: LayoutModel) => void
    copyLayout: (layoutId: number, startDate: string) => void
    deleteLayout: (layoutId: number) => void
    // 新レイアウト登録
    editingLayout: LayoutModel | undefined      // 編集中レイアウト
    updateEditingLayout: (newLayout: LayoutModel | undefined) => void   // 編集中レイアウトの内容更新
    //copyHotAndSaveLayout: (layout: LayoutModel, notification: NotificationObject) => void
    createNewLayout: (layout: LayoutModel, notification: NotificationObject) => void
    // エリアパック
    areaPackId: number | undefined
    changeAreaPack: (id: number | undefined) => void
    areaPackList: AreaPackModel[]
    deleteAreaPack: (areaPackId: number) => void
    editingAreaPack: AreaPackModel | undefined
    updateEditingAreaPack: (newAreaPack: AreaPackModel) => void
    saveAreaPack: () => void
    copyAreaPack: (layoutId: number, srcAreaPackId: number, start: number) => void
    isModifiedAP: boolean
    // ラインパック
    linePackId: number | undefined
    changeLinePack: (id: number | undefined) => void
    linePackList: LinePackModel[]
    deleteLinePack: (linePackId: number) => void
    editingLinePack: LinePackModel | undefined
    updateEditingLinePack: (newLinePack: LinePackModel) => void
    saveLinePack: () => void
    copyLinePack: (layoutId: number, srcLinePackId: number, start: number) => void
    // グループ
    //groupId: number | undefined
    //changeGroup: (id: number | undefined) => void
    groupList: GroupModel[]
    saveGroup: (group: GroupModel) => void
    deleteGroup: (gid: number) => void
    reactivateGroup: (gid: number) => void
    changeGroupOrder: (fromIdx: number, toIdx: number) => void
    saveGroupList: () => void
    // その他
    clearManagementData: () => void
    initManagementData: () => void
    waitingForReturn: boolean
    errorOccurred: string
    notification: NotificationObject
    setNotification: (notification: NotificationObject) => void
    worker: CanvasWorker | undefined
    setWorker: (worker: CanvasWorker | undefined) => void
}
const ManagementDataContext = createContext<ManagementDataContextType>({} as ManagementDataContextType)

export const useManagementDataContext = (): ManagementDataContextType => {
    return useContext<ManagementDataContextType>(ManagementDataContext)
}

type Props = {
    children: ReactNode
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

export const ManagementDataProvider = (props: Props) => {
    const { t } = useTranslation()
    // ログインユーザー
    const { userInfo, updateLoginInfo, ownAccessCheck, otherAccessCheck, unlimitedAccessCheck } = useAuthUserContext()
    // 企業
    const [companyId, setCompanyId] = useState<number | undefined>(undefined)
    const [companyList, setCompanyList] = useState<CompanyModel[]>([])
    // アカウント
    const [userList, setUserList] = useState<UserModel[] | undefined>(undefined)
    const [userTypes, setUserTypes] = useState<UserTypeObject[] | undefined>(undefined)
    // 店舗
    const [shopId, setShopId] = useState<number | undefined>(undefined)
    const [shopList, setShopList] = useState<ShopModel[]>([])
    // データソース
    const [dataSourceId, setDataSourceId] = useState<number | undefined>(undefined)
    const [dataSourceList, setDataSourceList] = useState<DataSourceModel[]>([])
    const [accordionItems, setAccordionItems] = useState<CollapseItem[] | undefined>(undefined)
    // レイアウト
    const [layoutId, setLayoutId] = useState<number | undefined>(undefined)
    const [layoutList, setLayoutList] = useState<LayoutModel[]>([])
    const [editingLayout, setEditingLayout] = useState<LayoutModel | undefined>(undefined) // 新レイアウト登録要
    // エリアパック
    const [areaPackId, setAreaPackId] = useState<number | undefined>(undefined)
    const [areaPackList, setAreaPackList] = useState<AreaPackModel[]>([])
    const [editingAreaPack, setEditingAreaPack] = useState<AreaPackModel | undefined>(undefined)   // エリア編集用
    const [isModifiedAP, setIsModifiedAP] = useState<boolean>(false)  // エリアパック編集中フラグ
    // ラインパック
    const [linePackId, setLinePackId] = useState<number | undefined>(undefined)
    const [linePackList, setLinePackList] = useState<LinePackModel[]>([])
    const [editingLinePack, setEditingLinePack] = useState<LinePackModel | undefined>(undefined)
    // グループ
    const [groupList, setGroupList] = useState<GroupModel[]>([])
    // その他
    const [waitingForReturn, setWaitingForReturn] = useState<boolean>(false)
    const [errorOccurred, setErrorOccurred] = useState<string>("")
    const [notification, setNotification] = useState<NotificationObject>(() => {
        return {
            isActive: false, from: DisplayNameType.None, to: DisplayNameType.None, message: ""
        } as NotificationObject
    })
    const [worker, setWorker] = useState<CanvasWorker | undefined>(undefined)
    // API
    const [editApi, setEditApi] = useState<BaseInfoEditAPI | undefined>(() => {
        if (mockOn) {
            const mm = new EditAPIMock(userInfo)
            return mm as BaseInfoEditAPI
        } else {
            const a = new EditAPI()
            return a as BaseInfoEditAPI
        }
    })

    // 自身のBookmark
    /*const myBookmark = useMemo(() => {
        if (userInfo) {
            // 最新情報はAPIで取得したユーザーにあり
            if (userList) {
                const user = userList.find(el => el.user_id === userInfo.user.user_id)
                if (user) return user.bookmarks
            }
            // なければログイン情報の店舗
            return userInfo.shops.map(el => el.shop_id)
        }
        return undefined
    }, [userInfo, userList])*/

    /**********************************************************
     * private methods
     */


    /**
     * データソースのアコーディオン用データを作成します。
     * 
     * @param list 
     * @param actId 
     * @returns 
     */
    const makeAccordionItems = (list: DataSourceModel[], actId: number | undefined) => {
        if (list && list.length > 0) {
            // id=0のゴミを除去する
            const ids = list.map(el => { return el.layout_set_id })
            const zeroCnt = ids.reduce((prev, curr) => ((curr === 0 || curr === undefined || curr === null) ? prev + 1 : prev), 0)
            const numsCnt = ids.reduce((prev, curr) => ((curr === 0 || curr === undefined || curr === null) ? prev : prev + 1), 0)
            const filtered = (zeroCnt > 0 && numsCnt > 0) ? list.filter(el => (el.layout_set_id !== 0 && el.layout_set_id !== undefined && el.layout_set_id !== null)) : [...list]
            // Activeがあるかどうか
            let hasAct = false
            for (let i = 0; i < filtered.length; i++) {
                if (filtered[i].status === DataSourceStatusType.Active) {
                    hasAct = true
                    break
                }
            }
            const items = filtered.map((el, i) => {
                // 初期展開の設定
                let exp = false
                if (actId !== undefined && el.layout_set_id === actId) {
                    // actIdが指定されている場合はそれを展開する
                    exp = true
                } else if (actId === undefined && hasAct && el.status === DataSourceStatusType.Active) {
                    // actIdが指定されていない場合でActiveがある場合は、Activeを展開する
                    exp = true
                    setDataSourceId(actId)
                } else if (actId === undefined && !hasAct && i === 0) {
                    // actIdが指定されていない場合でActiveがない場合は最初のものを展開する
                    exp = true
                    setDataSourceId(el.layout_set_id)
                }
                return { id: el.layout_set_id, name: el.name, status: el.status, isExpanded: exp } as CollapseItem
            })
            return items
        }
    }
    
    /**
     * あるデータセットの全レイアウトモデルに関連するレイアウトの情報を取り込みます。
     * @param modelList 
     * @param resList 
     * @returns 
     */
    const mergeLayoutList = useCallback(async (modelList: LayoutModel[], resList: ResLayout[]): Promise<LayoutModel[]> => {
        const result: LayoutModel[] = []
        for await (let lay of modelList) {
            const id = lay.layout_id
            const resLay = resList.find(el => el.layout_id === id)
            if (resLay) {
                //console.log("find resLayout", resLay)
                const map = LayoutRes2MapModel(resLay)
                lay.mapping = map
            } else {
                console.error("not found layout, layout_id:", id)
            }
            result.push(lay)
        }
        return result
    }, [])

    /**
     * 指定された店舗のデータソース（レイアウトセット）の中の平面図情報を更新します
     * @param shop 
     * @throws Error
     */
    const dressUpDataSouces = useCallback(async (shop: ShopModel) => {
        setWaitingForReturn(true)
        if (editApi) {
            for await (let ds of shop.datasource_list) {
                if (!ds.isFull) {
                    let layCnt = 0
                    if (ds.layout_set_id && ds.layout_list && ds.layout_list.length > 0) {
                        ds.layout_list.forEach(async lay => {
                            if (lay.layout_id) layCnt++
                        })
                    }
                    if (layCnt > 0) {
                        const fullLays = await editApi.get_layouts(ds.layout_set_id)
                        console.log("fullLayouts:", fullLays)
                        const newLayList = await mergeLayoutList(ds.layout_list, fullLays)
                        ds.layout_list = newLayList
                        ds.isFull = true
                    } else {
                        console.log("no layout in datasource")
                    }
                }
            }
        }
        // データソースリストを設定
        setDataSourceList(shop.datasource_list)
        // アコーディオンデータ作成
        const accItems = makeAccordionItems(shop.datasource_list, undefined)
        setAccordionItems(accItems)
        const ds = shop.datasource_list.find(el => el.status === DataSourceStatusType.Active)
        if (ds) {
            setDataSourceId(ds.layout_set_id)
            // レイアウトリストを設定
            setLayoutList(ds.layout_list)
        }
        setWaitingForReturn(false)
    }, [editApi, mergeLayoutList])

    /**
     * 店舗リストの中の指定された店舗情報を差し替えます。
     * @param shop 
     * @returns 
     */
    const renewalShopList = useCallback(async (shop: ResShop): Promise<ShopModel[]> => {
        const model = await ShopRes2Model(shop)
        const newList = []
        for await (let sh of shopList) {
            (sh.shop_id === shop.shop_id) ? newList.push(model) : newList.push(sh)
        }
        return newList
    }, [shopList])

    const delDataSourceInShops = useCallback((dsId: number) => {
        return new Promise((resolve, reject) => {
            const newShopList = [...shopList]
            if (shopId) {
                const shop = newShopList.find(el => el.shop_id === shopId)
                if (shop) {
                    const newDsList = shop.datasource_list.filter(el => el.layout_set_id !== dsId)
                    shop.datasource_list = newDsList
                    setDataSourceList(newDsList)
                    // アコーディオンデータ作成
                    const accItems = makeAccordionItems(newDsList, undefined)
                    setAccordionItems(accItems)
                }
            }
            setShopList(newShopList)
            setDataSourceList([])
            setDataSourceId(undefined)
            setAccordionItems(undefined)
            setLayoutId(undefined)
            setAreaPackId(undefined)
            setAreaPackList([])
            setLinePackId(undefined)
            setLinePackList([])
            setEditingAreaPack(undefined)
            setIsModifiedAP(false)
            resolve(true)
        })
    }, [shopId, shopList])

    /**
     * 指定された企業の店舗一覧を更新します。
     * 
     * @param companyId 
     */
    const updateShopList = useCallback((companyId: number) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                setWaitingForReturn(true)
                editApi.list_shop(companyId).then(res => {
                    console.log("list_shop res:", res)
                    //setWaitingForReturn(false)
                    if (res && res.length > 0) {
                        ShopAryRes2Model(res, companyId).then(list => {
                            setShopList(list)
                            if (list.length === 1) {
                                // １件だけなら選択中店舗にする
                                const sid = list[0].shop_id
                                setShopId(sid)
                                console.log("１件だけ sid:", sid)
                                // 続けて、データソースも設定する。
                                dressUpDataSouces(list[0]).then(() => {
                                    setWaitingForReturn(false)
                                }).catch(e => {
                                    setWaitingForReturn(false)
                                    const errMsg = e.message ? e.message : e.toString()
                                    setErrorOccurred(errMsg)
                                    console.error(e)
                                })
                            } else {
                                setShopId(undefined)
                                setWaitingForReturn(false)
                            }
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    } else {
                        setShopId(undefined)
                        setShopList([])
                    }
                    setWaitingForReturn(false)
                }).catch(err => {
                    setWaitingForReturn(false)
                    const errMsg = err.message ? err.message : err.toString()
                    setErrorOccurred(errMsg)
                    console.error(err)
                })
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }, [editApi, dressUpDataSouces])    

    /**
     * 指定された企業のアカウントリストを更新します。
     * @param companyId 
     */
    const updateUserList = useCallback((companyId: number) => {
        try {
            const own = ownAccessCheck(PermissionTypes.ManageAccount_View)
            const oth = otherAccessCheck(PermissionTypes.ManageAccount_View)
            const unl = unlimitedAccessCheck(PermissionTypes.ManageAccount_View)
            setErrorOccurred("")
            if (editApi) {
                // 権限がまったくない場合は自分のアカウントのみとする（bookmarkが必要となるため）
                const uid = (userInfo) ? userInfo.user.user_id : undefined
                const paramUserId = (!own && !oth && !unl) ? uid : undefined
                editApi.get_users(companyId, paramUserId, true).then(usersMap => {
                    console.log("get_users usersMap:", usersMap)
                    // データ変換
                    const list: UserModel[] = []
                    const vals = usersMap.values()
                    for (let i = 0; i < usersMap.size; i++) {
                        const one = vals.next().value
                        const u = one[0] as ResUser
                        const b = one[1] as number[]
                        const ud: UserModel = {
                            user_id: u.user_id,
                            login_id: u.login_id,
                            name: u.name,
                            user_type_id: u.user_type_id,
                            user_type: u.user_type,
                            display_order: u.display_order,
                            dashboard_order: u.dashboard_order,
                            expired_at: u.expired_at,
                            created_at: u.inserttime,
                            modified_at: u.updatetime,
                            bookmarks: b
                        }
                        list.push(ud)
                    }
                    setUserList(list)
                }).catch(err => {
                    const errMsg = err.message ? err.message : err.toString()
                    setErrorOccurred(errMsg)
                    console.error(err)                    
                })                
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }, [userInfo, editApi, ownAccessCheck, otherAccessCheck, unlimitedAccessCheck])

    /**
     * 指定された企業のグループリストを更新します。
     * 
     * @param companyId 
     */
    const updateGroupList = useCallback((companyId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.get_groups(companyId, false).then(groups => {
                    console.log("get_groups groups:", groups)
                    if (groups && groups.length > 0) {
                        // 降順にする
                        const sorted = groups.sort((a, b) => a.display_order > b.display_order ? 1 : -1)
                        const newList = sorted.map(el => { return GroupRes2Model(el) })
                        setGroupList(newList)
                        setWaitingForReturn(false)
                    } else {
                        setGroupList([])
                        setWaitingForReturn(false)
                    }
                }).catch(err => {
                    setWaitingForReturn(false)
                    const errMsg = err.message ? err.message : err.toString()
                    setErrorOccurred(errMsg)
                    console.error(err)
                 })                
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }, [editApi])

    /**
     * 企業リストを更新します。
     */
    const updateCompanyList = useCallback(() => {
        try {
            setErrorOccurred("")
            if (editApi) {
                editApi.get_companies().then(res => {
                    console.log("get_companies results:", res)
                    const list = CompanyAryRes2Model(res)
                    setCompanyList(list)
                    if (list.length === 1) {
                        //１件だけなら選択中企業として設定
                        const cid = list[0].company_id
                        setCompanyId(cid)
                        // 企業が１件だけのときは、店舗とアカウントとグループのリストを更新します。
                        updateShopList(cid)
                        updateUserList(cid)
                        updateGroupList(cid)
                    }
                }).catch(err => { 
                    const errMsg = err.message ? err.message : err.toString()
                    setErrorOccurred(errMsg)
                    console.error(err)                 
                })
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }, [editApi, updateShopList, updateUserList, updateGroupList])

    /**
     * 指定されたデータソースのレイアウトのマッピング情報を更新します。
     * @param layoutSetId 
     * @param layoutList 
     * @returns 
     * @throws Error
     */
    const fillMapping = (layoutSetId: number, layoutList: LayoutModel[]): Promise<LayoutModel[]> => {
        return new Promise((resolve, reject) => {
            if (layoutList.length === 0) {
                resolve(layoutList)
            } else {
                if (editApi) {
                    editApi.get_layouts(layoutSetId).then(fulls => {
                        mergeLayoutList(layoutList, fulls).then(newLays => {
                            resolve(newLays)
                        }).catch(e => { throw e })
                    }).catch(err => { 
                        const errMsg = err.message ? err.message : err.toString()
                        setErrorOccurred(errMsg)
                        console.error(err)
                        reject(err)
                    })                    
                }
            }
        })
    }


    /**********************************************************
     * public methods
     */

    /**
     * 選択された企業を設定します。
     * @param id 
     */
    const changeCompany = (id: number | undefined) => {
        setCompanyId(id)
        if (id) {
            try {
                // 店舗リスト更新
                updateShopList(id)
                // アカウントリスト更新
                updateUserList(id)
                // グループリスト更新
                updateGroupList(id)
            } catch (e) {
                console.error(e)
            }
        } else {
            setShopList([])
            setUserList(undefined)
        }
        setDataSourceList([])
        setDataSourceId(undefined)
        setAccordionItems(undefined)
        setLayoutList([])
        setLayoutId(undefined)
        setAreaPackList([])
        setAreaPackId(undefined)
        setLinePackList([])
        setLinePackId(undefined)
    }

    /**
     * 企業情報の更新/新規登録を行います。
     * @param company 
     */
    const saveCompany = (company: CompanyModel) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                const req = CompanyModel2Req(company)
                //console.log("saveCompany req:", req)
                if (company.company_id) {
                    //更新
                    editApi.update_company(company.company_id, req).then(res => {
                        console.log("company updated.", res)
                        if (companyList) {
                            updateCompanyList()
                        }
                    }).catch(e => { 
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                     })
                } else {
                    //新規
                    editApi.make_company(req).then(res => {
                        console.log("company created.", res)
                        if (companyList) {
                            updateCompanyList()
                        }
                    }).catch(e => { 
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                     })                    
                }
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定したユーザーの更新、あるいはユーザー登録を行います。
     * （user_idが0のときは新規登録）
     * @param user 
     */
    const saveUser = (user: UserModel) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                if (user.user_id) {
                    // 更新
                    const rUser = new ReqUpdateUser(user.user_id, user.login_id, user.name, user.user_type_id, user.dashboard_order)
                    const users: ReqUpdateUser[] = [rUser]
                    console.log("saveUser|update_user users:", users)
                    editApi.update_user(users).then(res => {
                        console.log("update_user res:", res)
                        console.log("bookmark user_id, bookmarks:", user.user_id, user.bookmarks)
                        editApi.bookmark(user.user_id, user.bookmarks).then(res2 => {
                            console.log("bookmark res:", res2)
                            // ユーザー情報リストを更新
                            if (userList && companyId) {
                                updateUserList(companyId)
                            }
                        }).catch(e => { 
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                         })
                    }).catch(e => {
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })
                } else {
                    if (companyId) {
                        // 新規
                        const nUser: ReqNewUser = {
                            login_id: user.login_id,
                            password: user.password ? user.password : "",
                            name: user.name,
                            user_type_id: user.user_type_id
                        }
                        console.log("saveUser|add nUser:", nUser)
                        editApi.make_user(companyId, nUser).then(res => {
                            console.log("make_user res:", res)
                            console.log("bookmark user_id, bookmarks:", res.user_id, user.bookmarks)
                            editApi.bookmark(res.user_id, user.bookmarks).then(res2 => {
                                console.log("bookmark res:", res2)
                                // ユーザー情報リストを更新
                                if (userList && companyId) {
                                    updateUserList(companyId)
                                }
                            }).catch(e => {
                                const errMsg = e.message ? e.message : e.toString()
                                setErrorOccurred(errMsg)
                                console.error(e)
                            })
                        }).catch(e => {
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    }
                }
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }
    
    /**
     * 指定したユーザーを論理削除します。
     * @param userId 
     */
    const deleteUser = (userId: number) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                editApi.stop_user(userId).then(res => {
                    if (userList) {
                        // ユーザーリスト更新
                        if (userList && companyId) {
                            updateUserList(companyId)
                        }
                    }
                }).catch(e => {
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定したユーザーを復活させます。
     * @param userId 
     */
    const reactivateUser = (userId: number) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                editApi.restart_user(userId).then(newList => {
                    // アカウントリストを更新（✘この方法だとBookmarkが空となる）
                    //const list = newList.map(el => { return UserRes2Model(el) })
                    //setUserList(list)
                    // ユーザー情報リストを更新
                    if (userList && companyId) updateUserList(companyId)
                    
                }).catch(e => {
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定された店舗を設定します
     * @param id 
     */
    const changeShop = (id: number | undefined) => {
        setShopId(id)
        console.log("shopId, shopList:", id, shopList)
        if (id) {
            setWaitingForReturn(true)
            setErrorOccurred("")
            try {
                if (shopList) {
                    // 店舗のデータソースリスト更新（平面図情報を追加する）
                    const newShopList = [...shopList]
                    const shop = newShopList.find(el => el.shop_id === id)
                    if (shop) {
                        // 平面図情報追加と、dataSourceList, dataSourceId, layoutListを更新
                        dressUpDataSouces(shop).then(() => {
                                setWaitingForReturn(false)
                            }
                        ).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    }
                }
                setWaitingForReturn(false)
            } catch (err: any) {
                setWaitingForReturn(false)
                const errMsg = err.message ? err.message : err.toString()
                setErrorOccurred(errMsg)
                console.error(err)
            }
        } else {
            // shopが未選択時はデータソースリスト以下も空にする
            setDataSourceId(undefined)
            setDataSourceList([])
            setLayoutList([])
            setAccordionItems(undefined)
        }
        setLayoutId(undefined)
        setAreaPackId(undefined)
        setAreaPackList([])
        setLinePackId(undefined)
        setLinePackList([])
        setEditingAreaPack(undefined)
        setIsModifiedAP(false)
    }

    /**
     * 店舗情報を新規登録・更新します。
     * @param companyId 
     * @param shop 
     */
    const saveShop = async (companyId: number, shop: ShopModel) => {
        try {
            setErrorOccurred("")
            if (editApi) {
                const req = ShopModel2Req(shop)
                console.log("shop_id, req:", shop.shop_id, req)
                if (shop.shop_id) {
                    // 更新
                    setWaitingForReturn(true)
                    editApi.update_shop(shop.shop_id, req).then(res => {
                        renewalShopList(res).then(newList => {
                            setWaitingForReturn(false)
                            setShopList(newList)
                        })
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })
                } else {
                    // 新規
                    const reqPayload: CreateShopPayload = {
                        company_id: companyId,
                        name: shop.name,
                        start: format(new Date(shop.start), STR_YMD_FORMAT),
                        timezone: shop.timezone,
                        hot_layout_set_name: t("default.layoutSetB"),
                        cold_layout_set_name: t("default.layoutSetA")
                    }
                    setWaitingForReturn(true)
                    const v2api = new EditV2API()
                    v2api.createShop(reqPayload).then(res => {
                        console.log("createShop API res:", res)
                        const shop: ShopModel = {
                            shop_id: res.shop.shop_id,
                            company_id: res.shop.company_id,
                            name: res.shop.name,
                            start: new Date(res.shop.start).getTime(),
                            timezone: res.shop.timezone,
                            designator: res.shop.shop_designator,
                            datasource_list: [],
                            created_at: res.shop.inserttime,
                            modified_at: res.shop.updatetime
                        }
                        const hot: DataSourceModel = {
                            shop_id: res.hot.shop_id,
                            layout_set_id: res.hot.layout_set_id,
                            name: res.hot.name,
                            status: DataSourceStatusType.Active,
                            isFull: false,
                            layout_list: []
                        }
                        const cold: DataSourceModel = {
                            shop_id: res.cold.shop_id,
                            layout_set_id: res.cold.layout_set_id,
                            name: res.cold.name,
                            status: DataSourceStatusType.Draft,
                            isFull: false,
                            layout_list: []
                        }
                        shop.datasource_list = [hot, cold]
                        const newList = [...shopList, shop]
                        setShopList(newList)
                        setWaitingForReturn(false)                        
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })
                    
                    /*editApi.make_shop(companyId, req).then(res => {
                        // データソースの新規登録
                        const id = res.shop_id
                        if (id) {
                            ShopRes2Model(res).then(newShop => {
                                console.log("店舗登録に続きレイアウトセット作成 shop:", newShop)
                                editApi.create_layout_set(id, DS_NO_NAME).then(result => {
                                    setWaitingForReturn(false)
                                    console.log("create_layout_set result:", result)
                                    //const newDs = LayoutSet2DataSourceModel(id, result, true, false)
                                    // ★APIの返す値が間違っているので修正する
                                    const dmy = result as any
                                    const truthResult = (dmy.status !== undefined) ? dmy.status as ResLayoutSet : result
                                    const isHot = false
                                    const isReady = false
                                    LayoutSet2DataSourceModel(id, truthResult, isHot, isReady).then(newDs => {
                                        if (newDs) newShop.datasource_list = [newDs]
                                        // 店舗リスト更新
                                        const newShopList = [...shopList, newShop]
                                        setShopList(newShopList)
                                    }).catch(e => {
                                        setWaitingForReturn(false)
                                        const errMsg = e.message ? e.message : e.toString()
                                        setErrorOccurred(errMsg)
                                        console.error(e)
                                    })
                                }).catch(e => {
                                    setWaitingForReturn(false)
                                    const errMsg = e.message ? e.message : e.toString()
                                    setErrorOccurred(errMsg)
                                    console.error(e)
                                })
                            }).catch(e => {
                                setWaitingForReturn(false)
                                const errMsg = e.message ? e.message : e.toString()
                                setErrorOccurred(errMsg)
                                console.error(e)
                            })
                        }
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })*/
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定された店舗を削除します。
     * @param id 
     */
    const deleteShop = (id: number) => {
        try {
            setErrorOccurred("")
            setWaitingForReturn(true)
            const v2api = new EditV2API()
            v2api.deleteShop(id).then(res => {
                const newList = shopList.filter(el => el.shop_id !== id)
                setShopList(newList)
                setShopId(undefined)
                setDataSourceList([])
                setDataSourceId(undefined)
                setAccordionItems(undefined)
                setLayoutId(undefined)
                setAreaPackId(undefined)
                setAreaPackList([])
                setLinePackId(undefined)
                setLinePackList([])
                setEditingAreaPack(undefined)
                setIsModifiedAP(false)
                setWaitingForReturn(false)
            }).catch(e => {
                setWaitingForReturn(false)
                const errMsg = e.message ? e.message : e.toString()
                setErrorOccurred(errMsg)
                console.error(e)
            })
            /*if (editApi) {
                setWaitingForReturn(true)
                editApi.delete_shop(id).then(res => {
                    const newList = shopList.filter(el => el.shop_id !== id)
                    setShopList(newList)
                    setWaitingForReturn(false)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }*/
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * データソースIDを更新します。
     * @param id 
     */
    const changeDataSource = (id: number | undefined) => {
        setDataSourceId(id)
        if (id !== undefined) {
            if (dataSourceList) {
                // レイアウトリストを設定
                const ds = dataSourceList.find(el => el.layout_set_id === id)
                if (ds) setLayoutList(ds.layout_list)
            }
        } else {
            setLayoutList([])
        }
        setLayoutId(undefined)
        setAreaPackList([])
        setAreaPackId(undefined)
        setLinePackList([])
        setLinePackId(undefined)
        setEditingAreaPack(undefined)
        setIsModifiedAP(false)
    }

    /**
     * 指定されたデータソース（レイアウトセット）の名称を更新します。
     * @param layoutSetId 
     * @param name 
     */
    const updateDatasourceName = async (layoutSetId: number, rename: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (editApi) {
                setErrorOccurred("")
                if (layoutSetId) {
                    setWaitingForReturn(true)
                    editApi.update_layout_set_name(layoutSetId, rename).then(res => {
                        // ShopListの中身を更新
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            const newDsList = [...shop.datasource_list]
                            const ds = newDsList.find(el => el.layout_set_id === layoutSetId)
                            if (ds) {
                                ds.name = rename
                                shop.datasource_list = newDsList
                                // データソースリスト更新
                                setDataSourceList(newDsList)
                                // アコーディオンデータ作成
                                const accItems = makeAccordionItems(newDsList, layoutSetId)
                                setAccordionItems(accItems)
                            }
                        }
                        setShopList(newShopList)
                        setWaitingForReturn(false)
                        resolve(true)
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                        reject(e)
                    })
                // レイアウトセットの名称変更で、layoutSetIdがない場合などありえない！
                /*} else {
                    // レイアウトセットが無い場合は作成する。
                    if (shopId && shopList) {
                        setWaitingForReturn(true)
                        console.log("レイアウトセットがないので作成")
                        editApi.create_layout_set(shopId, rename).then(result => {
                            console.log("create_layout_set result:", result)
                            // ★APIの返す値が間違っているので修正する
                            const dmy = result as any
                            const truthResult = (dmy.status !== undefined) ? dmy.status as ResLayoutSet : result
                            const isHot = false
                            const isReady = false
                            LayoutSet2DataSourceModel(shopId, truthResult, isHot, isReady).then(newDs => {                                
                                if (newDs) {
                                    const newShopList = [...shopList]
                                    const shop = newShopList.find(el => el.shop_id === shopId)
                                    if (shop) {
                                        const newDsList = [newDs]
                                        setDataSourceList(newDsList)
                                        // アコーディオンを開く
                                        setDataSourceId(newDs.layout_set_id)
                                        // レイアウトはまだない

                                        // アコーディオンデータ作成
                                        const accItems = makeAccordionItems(newDsList, newDs.layout_set_id)
                                        setAccordionItems(accItems)
                                    }
                                    setShopList(newShopList)
                                    setWaitingForReturn(false)
                                    resolve(true)
                                }
                            }).catch(e => {
                                setWaitingForReturn(false)
                                const errMsg = e.message ? e.message : e.toString()
                                setErrorOccurred(errMsg)
                                console.error(e)
                                reject(e)
                            })
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                            reject(e)
                        })
                    }*/
                }
            }
        })
    }

    /**
     * 指定されたデータソース（レイアウトセット）を複製します。
     * @param dsId 
     */
    const copyDataSource = (dsId: number, notification: NotificationObject) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.copy_layout_set(dsId).then(res => {
                    console.log("copy_layout_set res:", res)
                    if (shopId && shopList) {
                        // 店舗のデータソースを更新
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            const ds = shop.datasource_list.find(el => el.layout_set_id === dsId)
                            if (ds) {
                                if (mockOn) {
                                    const cloneDs: DataSourceModel = {
                                        layout_set_id: res.layout_set_id,
                                        name: res.name,
                                        shop_id: ds.shop_id,
                                        layout_list: [...ds.layout_list],
                                        status: DataSourceStatusType.Draft,
                                        isFull: ds.isFull
                                    }
                                    // リストに追加
                                    const newDsList = [...shop.datasource_list, cloneDs]
                                    shop.datasource_list = newDsList
                                    setDataSourceList(newDsList)
                                    setDataSourceId(res.layout_set_id)
                                    // アコーディオンデータ作成
                                    const accItems = makeAccordionItems(newDsList, res.layout_set_id)
                                    setAccordionItems(accItems)
                                    setWaitingForReturn(false)
                                    // 通知
                                    setNotification(notification)
                                } else {
                                    // データソースを作り
                                    // ★APIの返す値が間違っているので修正する
                                    const dmy = res as any
                                    const truthResult = (dmy.status !== undefined) ? dmy.status as ResLayoutSet : res
                                    LayoutSet2DataSourceModel(shopId, truthResult, false, false).then(newDs => {
                                        if (newDs) {
                                            // 中のレイアウトにmappingを追加
                                            editApi.get_layouts(newDs.layout_set_id).then(fulls => {
                                                mergeLayoutList(newDs.layout_list, fulls).then(newLays => {
                                                    newDs.layout_list = newLays
                                                    const newDsList = [...shop.datasource_list, newDs]
                                                    shop.datasource_list = newDsList
                                                    setDataSourceList(newDsList)
                                                    setDataSourceId(newDs.layout_set_id)
                                                    setShopList(newShopList)
                                                    // アコーディオンデータ作成
                                                    const accItems = makeAccordionItems(newDsList, newDs.layout_set_id)
                                                    setAccordionItems(accItems)
                                                    setWaitingForReturn(false)
                                                    // 通知
                                                    setNotification(notification)
                                                })
                                            }).catch(e => {
                                                setWaitingForReturn(false)
                                                const errMsg = e.message ? e.message : e.toString()
                                                setErrorOccurred(errMsg)
                                                console.error(e)
                                            })
                                        }
                                    }).catch(e => {
                                        setWaitingForReturn(false)
                                        const errMsg = e.message ? e.message : e.toString()
                                        setErrorOccurred(errMsg)
                                        console.error(e)
                                    })
                                }
                            }
                        }
                        if (mockOn) setShopList(newShopList)
                    }
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたデータソース（レイアウトセット）を本番にします。
     * @param dsId 
     */
    const changeHot = (dsId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.set_hot_layout_set(dsId).then(resLoginInfo => {
                    if (shopId && shopList) {
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            // ステータスを更新して
                            const hot = shop.datasource_list.find(el => el.layout_set_id === dsId)
                            const coldList = shop.datasource_list.filter(el => el.layout_set_id !== dsId).map(el => {
                                if (el.status === DataSourceStatusType.Active) el.status = DataSourceStatusType.Draft
                                return el
                            })
                            if (hot) {
                                hot.status = DataSourceStatusType.Active
                                // 並べ替える
                                const newDsList = [hot, ...coldList]
                                shop.datasource_list = newDsList
                                setDataSourceList(newDsList)
                                // アコーディオンデータ作成
                                const accItems = makeAccordionItems(newDsList, undefined)
                                setAccordionItems(accItems)
                                setWaitingForReturn(false)
                                // アコーディオンは本番を開く
                                setDataSourceId(hot.layout_set_id)
                            }
                        }
                        setShopList(newShopList)
                    }
                    // ログイン情報の書き換え
                    updateLoginInfo(resLoginInfo)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたデータソース（レイアウトセット）を削除します。
     * @param dsId 
     */
    const deleteDataSource = (dsId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                //const api = new EditAPI()
                editApi.delete_layout_set(dsId).then(res => {
                    setWaitingForReturn(false)
                    delDataSourceInShops(dsId)
                    // ログイン情報の書き換え
                    updateLoginInfo(res)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    const changeExpanded = (id: number, expan: boolean) => {
        if (expan) {
            // アコーディオンOpen
            changeDataSource(id)
            // 開くのはひとつだけ
            if (dataSourceList) {
                // id=0のゴミを取り除く
                const ids = dataSourceList.map(el => { return el.layout_set_id })
                const zeroCnt = ids.reduce((prev, curr) => ((curr === 0 || curr === undefined || curr === null) ? prev + 1 : prev), 0)
                const numsCnt = ids.reduce((prev, curr) => ((curr === 0 || curr === undefined || curr === null) ? prev : prev + 1), 0)
                const cloneList = [...dataSourceList]
                const filteredList = (zeroCnt > 0 && numsCnt > 0) ? cloneList.filter(el => (el.layout_set_id !== 0 && el.layout_set_id !== undefined && el.layout_set_id !== null)) : cloneList
                const items = filteredList.map(el => {
                    if (el.layout_set_id === id) {
                        return { id: el.layout_set_id, name: el.name, status: el.status, isExpanded: true } as CollapseItem
                    } else {
                        return { id: el.layout_set_id, name: el.name, status: el.status, isExpanded: false } as CollapseItem
                    }
                })
                setAccordionItems(items)
            }
        } else {
            // アコーディオンClose
            changeDataSource(undefined)
            if (accordionItems) {
                const newItems = [...accordionItems]
                const itm = newItems.find(el => el.id === id)
                if (itm) itm.isExpanded = false
                setAccordionItems(newItems)
            }
        }
    }

    const transcription = (shopId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            const apiV2 = new EditV2API()
            if (apiV2) {
                apiV2.transcriptionLayoutSet(shopId).then(res => {
                    console.log("transcription res:", res)
                    Transcription2Model(res).then(newDs => {
                        setWaitingForReturn(false)
                        if (shopId && shopList) {
                            const shopModel = shopList.find(el => el.shop_id === shopId)
                            if (shopModel && shopModel.datasource_list && shopModel.datasource_list.length > 0) {
                                const coldId = res.layout_set.layout_set_id
                                const list = shopModel.datasource_list.filter(el => el.layout_set_id !== coldId)
                                shopModel.datasource_list = [...list, newDs]
                                setDataSourceList(shopModel.datasource_list)
                                // アコーディオンデータ作成
                                const accItems = makeAccordionItems(shopModel.datasource_list, coldId)
                                setAccordionItems(accItems)
                            }
                        }
                    }).catch (er => {
                        setWaitingForReturn(false)
                        const errMsg = er.message ? er.message : er.toString()
                        setErrorOccurred(errMsg)
                        console.error(er)
                    })
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * レイアウトIDを更新します。
     * @param id 
     */
    const changeLayout = (id: number | undefined) => {
        setLayoutId(id)
        if (id) {
            if (layoutList) {
                // エリアパックを設定
                const lay = layoutList.find(el => el.layout_id === id)
                if (lay && lay.mapping) {
                    setAreaPackList(lay.mapping.area_packs)
                    setLinePackList(lay.mapping.line_packs)
                }
            }
        } else {
            setAreaPackList([])
            setLinePackList([])
        }
    }

    /**
     * レイアウト情報を登録・更新します。
     * @param layoutSetId 
     * @param layout 
     */
    const saveLayout = (layoutSetId: number, layout: LayoutModel) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            const layoutId = layout.layout_id
            //const req: ReqLayout = LayoutModel2Req(layout)
            LayoutModel2Req(layout).then(req => {
                if (editApi) {
                    if (layoutId) {
                        // 更新
                        editApi.update_layout(layoutId, req).then(res => {
                            // shop以下の関連データを更新
                            const map = LayoutRes2MapModel(res)
                            const newShopList = [...shopList]
                            const shop = newShopList.find(el => el.shop_id === shopId)
                            if (shop) {
                                const newDsList = [...shop.datasource_list]
                                const ds = newDsList.find(el => el.layout_set_id === layoutSetId)
                                if (ds) {
                                    const newLayList = [...ds.layout_list]
                                    const lay = newLayList.find(el => el.layout_id === layoutId)
                                    if (lay) {
                                        // 項目内容を更新
                                        lay.mapping = map
                                        lay.name = res.name
                                        lay.start = res.start
                                        lay.modified_at = (new Date()).getTime()
                                    }
                                    setLayoutList(newLayList)
                                }
                                setDataSourceList(newDsList)
                                // アコーディオンデータ作成
                                const accItems = makeAccordionItems(newDsList, layoutSetId)
                                setAccordionItems(accItems)
                                
                            }
                            setShopList(newShopList)
                            setWaitingForReturn(false)
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    } else {
                        // 新規追加
                        if (layoutSetId === 0) {
                            // レイアウトセットがない場合
                            if (shopId && shopList) {
                                console.log("レイアウトセットがないのでlayout登録の前に作成")
                                editApi.create_layout_set(shopId, DS_NO_NAME).then(result => {
                                    console.log("create_layout_set result:", result)
                                    //const newDs = LayoutSet2DataSourceModel(shopId, result, true, false)
                                    // ★APIの返す値が間違っているので修正する
                                    const dmy = result as any
                                    const truthResult = (dmy.status !== undefined) ? dmy.status as ResLayoutSet : result
                                    const isHot = false
                                    const isReady = false
                                    LayoutSet2DataSourceModel(shopId, truthResult, isHot, isReady).then(newDs => {
                                        if (newDs) {
                                            const id = newDs.layout_set_id
                                            editApi.create_layout(id, req).then(res => {
                                                setWaitingForReturn(false)
                                                console.log("create_layout req, res:", req, res)
                                                const lay = FullLayoutRes2Model(res)
                                                const newShopList = [...shopList]
                                                const shop = newShopList.find(el => el.shop_id === shopId)
                                                if (shop) {
                                                    const newDsList = [newDs]
                                                    const newLayList = [lay]
                                                    newDs.layout_list = newLayList
                                                    setLayoutList(newLayList)
                                                    setDataSourceList(newDsList)
                                                    // アコーディオンデータ作成
                                                    const accItems = makeAccordionItems(newDsList, layoutSetId)
                                                    setAccordionItems(accItems)
                                                }
                                                setShopList(newShopList)
                                                setWaitingForReturn(false)
                                            }).catch(e => {
                                                setWaitingForReturn(false)
                                                const errMsg = e.message ? e.message : e.toString()
                                                setErrorOccurred(errMsg)
                                                console.error(e)
                                            })
                                        }
                                    }).catch(e => {
                                        setWaitingForReturn(false)
                                        const errMsg = e.message ? e.message : e.toString()
                                        setErrorOccurred(errMsg)
                                        console.error(e)
                                    })
                                }).catch(e => {
                                    setWaitingForReturn(false)
                                    const errMsg = e.message ? e.message : e.toString()
                                    setErrorOccurred(errMsg)
                                    console.error(e)
                                })
                            }
                        } else {
                            //通常（指定されたレイアウトセットの中に追加する）
                            editApi.create_layout(layoutSetId, req).then(res => {
                                console.log("create_layout req, res:", req, res)
                                const lay = FullLayoutRes2Model(res)
                                const newShopList = [...shopList]
                                const shop = newShopList.find(el => el.shop_id === shopId)
                                if (shop) {
                                    const newDsList = [...shop.datasource_list]
                                    const ds = newDsList.find(el => el.layout_set_id === layoutSetId)
                                    if (ds) {
                                        const newLayList = [...ds.layout_list, lay].sort((a, b) => a.start < b.start ? 1 : -1)
                                        ds.layout_list = newLayList
                                        setLayoutList(newLayList)
                                    }
                                    setDataSourceList(newDsList)
                                    // アコーディオンデータ作成
                                    const accItems = makeAccordionItems(newDsList, layoutSetId)
                                    setAccordionItems(accItems)
                                }
                                setShopList(newShopList)
                                setWaitingForReturn(false)
                            }).catch(e => {
                                setWaitingForReturn(false)
                                const errMsg = e.message ? e.message : e.toString()
                                setErrorOccurred(errMsg)
                                console.error(e)
                            })
                        }
                    }
                }
            }).catch(e => {
                setWaitingForReturn(false)
                const errMsg = e.message ? e.message : e.toString()
                setErrorOccurred(errMsg)
                console.error(e)
            })
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたレイアウトを複製します。
     * @param layoutId 
     * @param startDate 
     */
    const copyLayout = (layoutId: number, startDate: string) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            console.log("copyLayout layoutId, startDate:", layoutId, startDate)
            if (editApi) {
                editApi.copy_layout(layoutId, startDate).then(res => {
                    // 変更を反映
                    const lay = FullLayoutRes2Model(res)
                    const newShopList = [...shopList]
                    const shop = newShopList.find(el => el.shop_id === shopId)
                    if (shop) {
                        const newDsList = [...shop.datasource_list]
                        const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                        if (ds) {
                            const newLayList = [...ds.layout_list, lay].sort((a, b) => a.start < b.start ? 1 : -1)
                            ds.layout_list = newLayList
                            setLayoutList(newLayList)
                        }
                        setDataSourceList(newDsList)
                        // アコーディオンデータ作成
                        const accItems = makeAccordionItems(newDsList, dataSourceId)
                        setAccordionItems(accItems)
                    }
                    setShopList(newShopList)
                    setWaitingForReturn(false)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定したレイアウトを削除します。
     * @param layoutId 
     */
    const deleteLayout = (layoutId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.delete_layout(layoutId).then(resLoginInfo => {
                    const newShopList = [...shopList]
                    const shop = newShopList.find(el => el.shop_id === shopId)
                    if (shop) {
                        const newDsList = [...shop.datasource_list]
                        const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                        if (ds) {
                            const newLayList = ds.layout_list.filter(el => el.layout_id !== layoutId)
                            ds.layout_list = newLayList
                            setLayoutList(newLayList)
                        }
                        setDataSourceList(newDsList)
                        // アコーディオンデータ作成
                        const accItems = makeAccordionItems(newDsList, dataSourceId)
                        setAccordionItems(accItems)
                    }
                    setShopList(newShopList)
                    // ログイン情報の書き換え
                    updateLoginInfo(resLoginInfo)
                    setWaitingForReturn(false)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    const updateEditingLayout = (newLayout: LayoutModel | undefined) => {
        setEditingLayout(newLayout)
    }

    const createNewLayout = (layout: LayoutModel, notification: NotificationObject) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                //const req: ReqLayout = LayoutModel2Req(layout)
                LayoutModel2Req(layout).then(req => {
                    // データソースを探す
                    if (shopId && shopList) {
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            // 下書きレイアウトセットを数える
                            let draftCnt = 0
                            shop.datasource_list.forEach(ds => {
                                if (ds.layout_set_id) {
                                    if (ds.status !== DataSourceStatusType.Active) {
                                        draftCnt++
                                    }
                                }
                            })
                            if (draftCnt === 0) {
                                // データソースを作成
                                editApi.create_layout_set(shopId, DS_NO_NAME).then(resDs => {
                                    // Modelに変換（下書きとして）
                                    const isHot = false
                                    const isReady = false
                                    LayoutSet2DataSourceModel(shopId, resDs, isHot, isReady).then(newDs => {
                                        if (newDs) {
                                            // レイアウトを保存
                                            editApi.create_layout(newDs.layout_set_id, req).then(resLay => {
                                                // レイアウトをModelに変換
                                                const lay = FullLayoutRes2Model(resLay)
                                                // レイアウトを追加
                                                newDs.layout_list = [lay]
                                                // 店舗に追加
                                                shop.datasource_list.push(newDs)
                                                // 店舗リスト更新
                                                setShopList(newShopList)
                                                // データソース更新
                                                setDataSourceList(shop.datasource_list)
                                                setDataSourceId(newDs.layout_set_id)
                                                // アコーディオン更新
                                                const accItems = makeAccordionItems(shop.datasource_list, newDs.layout_set_id)
                                                setAccordionItems(accItems)
                                                // 通知
                                                setNotification(notification)
                                                // wait解除
                                                setWaitingForReturn(false)
                                            }).catch(e => {
                                                setWaitingForReturn(false)
                                                const errMsg = e.message ? e.message : e.toString()
                                                setErrorOccurred(errMsg)
                                                console.error(e)
                                            })
                                        } else {
                                            setWaitingForReturn(false)
                                            throw new Error("Convert result to DataSourceModel failed.")
                                        }
                                    }).catch(e => {
                                        setWaitingForReturn(false)
                                        const errMsg = e.message ? e.message : e.toString()
                                        setErrorOccurred(errMsg)
                                        console.error(e)
                                    })
                                }).catch(e => {
                                    setWaitingForReturn(false)
                                    const errMsg = e.message ? e.message : e.toString()
                                    setErrorOccurred(errMsg)
                                    console.error(e)
                                })
                            } else if (draftCnt === 1) {
                                // レイアウトを追加する
                                const ds = shop.datasource_list.find(el => el.status !== DataSourceStatusType.Active)
                                if (ds) {
                                    if (ds.layout_list && ds.layout_list.length > 0) {
                                        ds.layout_list.forEach(lay => {
                                            // レイアウトが空なら削除
                                            if (lay.layout_id === 0) {
                                                const idx = ds.layout_list.indexOf(lay)
                                                if (idx >= 0) {
                                                    ds.layout_list.splice(idx, 1)
                                                }
                                            }
                                        })
                                    }
                                    // レイアウトを保存
                                    editApi.create_layout(ds.layout_set_id, req).then(resLay => {
                                        // レイアウトをModelに変換
                                        const lay = FullLayoutRes2Model(resLay)
                                        // レイアウトを追加
                                        ds.layout_list = [...ds.layout_list, lay].sort((a, b) => a.start < b.start ? 1 : -1)
                                        // 店舗に反映
                                        shop.datasource_list = [...shop.datasource_list]
                                        // 店舗リスト更新
                                        setShopList(newShopList)
                                        // データソース更新
                                        setDataSourceList(shop.datasource_list)
                                        setDataSourceId(ds.layout_set_id)
                                        // アコーディオン更新
                                        const accItems = makeAccordionItems(shop.datasource_list, ds.layout_set_id)
                                        setAccordionItems(accItems)
                                        // 通知
                                        setNotification(notification)
                                        // wait解除
                                        setWaitingForReturn(false)
                                    }).catch(e => {
                                        setWaitingForReturn(false)
                                        const errMsg = e.message ? e.message : e.toString()
                                        setErrorOccurred(errMsg)
                                        console.error(e)
                                    })
                                } else {
                                    setWaitingForReturn(false)
                                    throw new Error("レイアウトを追加する下書きレイアウトセットが見つからなかった。")
                                }
                            } else {
                                throw new Error("下書きレイアウトセットが複数(" + draftCnt + ")ある。")
                            }
                        } else {
                            setWaitingForReturn(false)
                            throw new Error("Shop not found.")
                        }
                    } else {
                        setWaitingForReturn(false)
                        throw new Error("Shop is not selected.")
                    }

                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたエリアパックIDを更新します。
     * @param id 
     */
    const changeAreaPack = (id: number | undefined) => {
        setAreaPackId(id)
    }

    /**
     * エリア編集用のエリアパックを更新します。
     * @param newAreaPack 
     */
    const updateEditingAreaPack = (newAreaPack: AreaPackModel) => {
        if (!editingAreaPack) {
            console.log("initialize editingAreaPack")
        } else {
            console.log("before update editingAreaPack:", editingAreaPack)
        }
        setEditingAreaPack(newAreaPack)
        // areaPackListと比較して変更があればフラグを立てる
        if (areaPackList && areaPackId && newAreaPack) {
            const ap = areaPackList.find(el => el.area_set_id === areaPackId)
            if (ap) {
                console.log("ap, newAp:", ap, newAreaPack)
                // 内容比較
                if (ap.startYmd !== newAreaPack.startYmd) {
                    console.log("startYmd is different.")
                    setIsModifiedAP(true)
                    return
                }
                if (ap.area_list.length !== newAreaPack.area_list.length) {
                    setIsModifiedAP(true)
                    console.log("area_list length is different.")
                    return
                } else {
                    const sortedSrc = [...ap.area_list].sort((a, b) => a.area_id < b.area_id ? 1 : -1)
                    const sortedNew = [...newAreaPack.area_list].sort((a, b) => a.area_id < b.area_id ? 1 : -1)
                    for (let i = 0; i < sortedSrc.length; i++) {
                        const src = sortedSrc[i]
                        const newEl = sortedNew[i]
                        /*if (src.area_id !== newEl.area_id) {
                            setIsModifiedAP(true)
                            console.log("area_id is different.", src.area_id, newEl.area_id)
                            return
                        }*/
                        if (src.area_type !== newEl.area_type) {
                            setIsModifiedAP(true)
                            console.log("area_type is different.")
                            return
                        }
                        if (src.group_id !== newEl.group_id) {
                            setIsModifiedAP(true)
                            console.log("group_id is different.")
                            return
                        }
                        if (src.name !== newEl.name) {
                            setIsModifiedAP(true)
                            console.log("name is different.")
                            return
                        }
                        if (src.cell_ids.length !== newEl.cell_ids.length) {
                            setIsModifiedAP(true)
                            console.log("cell_ids length is different.")
                            return
                        } else {
                            const sortedSrc2 = [...src.cell_ids].sort((a, b) => a.y < b.y ? 1 : -1).sort((a, b) => a.x < b.x ? 1 : -1)
                            const sortedNew2 = [...newEl.cell_ids].sort((a, b) => a.y < b.y ? 1 : -1).sort((a, b) => a.x < b.x ? 1 : -1)
                            for (let j = 0; j < sortedSrc2.length; j++) {
                                const src2 = sortedSrc2[j]
                                const newEl2 = sortedNew2[j]
                                if (src2.x !== newEl2.x) {
                                    setIsModifiedAP(true)
                                    console.log("area.x is different.")
                                    return
                                }
                                if (src2.y !== newEl2.y) {
                                    setIsModifiedAP(true)
                                    console.log("area.y is different.")
                                    return
                                }
                            }
                        }
                    }
                }
                console.log("差異が認められない")
            } else {
                console.log("ap がない？")
            }
        } else {
            console.log("areaPackList && areaPackId && newAreaPack がない ", areaPackList, areaPackId, newAreaPack)
            // 新規の場合はareaPackListがないし、areaPackId=0になるので、newAreaPackにareaが入っているか確認することになる。
            if (newAreaPack.area_list.length > 0) setIsModifiedAP(true)
        }
    }

    /**
     * 新しいエリアパックを登録します。
     * @param srcLayoutId 
     * @param startYmd 
     * @param areaDefs 
     */
    const createAreaPack = (srcLayoutId: number, startYmd: string, areaSet: ResAreaSet) => {
        try {
            if (editApi) {
                setWaitingForReturn(true)
                setErrorOccurred("")
                console.log("createAreaPack srcLayoutId, startYmd, areaDefs:", srcLayoutId, startYmd, areaSet)
                editApi.make_areaset(srcLayoutId, startYmd, areaSet).then(res => {
                    console.log("api.make_areaset res:", res)
                    // ShopListに追加
                    const newAreaList = res.map(el => AreaSet2PackModel(el))
                    if (shopId && shopList && dataSourceId) {
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            const newDsList = [...shop.datasource_list]
                            const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                            if (ds) {
                                const newLayList = [...ds.layout_list]
                                const lay = newLayList.find(el => el.layout_id === srcLayoutId)
                                if (lay && lay.mapping) {
                                    lay.mapping.area_packs = newAreaList
                                    const newLineList = [...lay.mapping.line_packs]
                                    setAreaPackList(newAreaList)
                                    setLinePackList(newLineList)
                                    setEditingAreaPack(undefined)
                                    setIsModifiedAP(false)
                                }
                                setLayoutList(newLayList)
                            }
                            setDataSourceList(newDsList)
                            const accItems = makeAccordionItems(newDsList, dataSourceId)
                            setAccordionItems(accItems)
                        }
                        setShopList(newShopList)
                        setWaitingForReturn(false)
                    }
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.log(err)
        }
    }

    /**
     * 編集されたエリアパック(editingAreaPack)を保存します。
     */
    const saveAreaPack = () => {
        try {
            setErrorOccurred("")
            //let api: BaseInfoEditAPI
            if (editingAreaPack && layoutId) {
                const asid = editingAreaPack.area_set_id
                // APIリクエストデータ作成（area_idは全て無視される）
                //const list = AreaModelList2ReqAreaDefs(editingAreaPack)
                const resAreaSet = AreaPackModel2ResAreaSet(editingAreaPack)
                if (asid) {
                    if (editApi) {
                        // 更新
                        editApi.update_areaset(layoutId, asid, editingAreaPack.startYmd, resAreaSet).then(res => {
                            console.log("update_areaset res:", res)
                            // ShopList更新
                            if (shopId && shopList && dataSourceId) {
                                const newShopList = [...shopList]
                                const shop = newShopList.find(el => el.shop_id === shopId)
                                if (shop) {
                                    const newDsList = [...shop.datasource_list]
                                    const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                                    if (ds) {
                                        const newLayList = [...ds.layout_list]
                                        const lay = newLayList.find(el => el.layout_id === layoutId)
                                        if (lay && lay.mapping) {
                                            const newAreaList = [...lay.mapping.area_packs]
                                            const newLineList = [...lay.mapping.line_packs]
                                            const areaPk = newAreaList.find(el => el.area_set_id === asid)
                                            if (areaPk) {
                                                areaPk.startYmd = res.start
                                                areaPk.area_list = res.area_defs
                                                areaPk.created_at = res.inserttime
                                                areaPk.modified_at = res.updatetime
                                            }
                                            setAreaPackList(newAreaList)
                                            setLinePackList(newLineList)
                                            // 保存したらクリアする
                                            setEditingAreaPack(undefined)
                                            setIsModifiedAP(false)
                                        }
                                        setLayoutList(newLayList)
                                    }
                                    setDataSourceList(newDsList)
                                    // アコーディオンデータ作成
                                    const accItems = makeAccordionItems(newDsList, dataSourceId)
                                    setAccordionItems(accItems)
                                }
                                setShopList(newShopList)
                                setWaitingForReturn(false)
                            }
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    }
                } else {
                    // 新規
                    createAreaPack(layoutId, editingAreaPack.startYmd, resAreaSet)
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたエリアパックを複製します。
     * @param srcLayoutId 
     * @param srcAreaPackId 
     * @param start 
     */
    const copyAreaPack = (srcLayoutId: number, srcAreaPackId: number, start: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (shopId && shopList) {
                const shop = shopList.find(el => el.shop_id === shopId)
                if (shop) {
                    // localタイムからUTCに変換
                    const timezoneMinute = shop.timezone
                    const tm = start - timezoneMinute * 60000
                    const startYmd = format(new Date(tm), STR_YMD_FORMAT)
                    if (layoutList) {
                        const lay = layoutList.find(el => el.layout_id === srcLayoutId)
                        if (lay && lay.mapping) {
                            const ap = lay.mapping.area_packs.find(el => el.area_set_id === srcAreaPackId)
                            if (ap) {
                                //const defs = AreaModelList2ReqAreaDefs(ap)
                                const resAreaSet = AreaPackModel2ResAreaSet(ap)
                                createAreaPack(srcLayoutId, startYmd, resAreaSet)
                            }
                        }
                    } 
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.log(err)
        }
    }

    /**
     * 指定されたエリアパックを削除します。
     * @param areaPackId 
     */
    const deleteAreaPack = (areaPackId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            //const api = new EditAPI()
            if (editApi) {
                editApi.delete_areaset(areaPackId).then(res => {
                    console.log("delete_areaset res:", res)
                    // ShopList更新
                    if (shopId && shopList && dataSourceId && layoutId) {
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            const newDsList = [...shop.datasource_list]
                            const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                            if (ds) {
                                const newLayList = [...ds.layout_list]
                                const lay = newLayList.find(el => el.layout_id === layoutId)
                                if (lay && lay.mapping) {
                                    const newAreaList = [...lay.mapping.area_packs].filter(el => el.area_set_id !== areaPackId)
                                    const newLineList = [...lay.mapping.line_packs]
                                    lay.mapping.area_packs = newAreaList
                                    setAreaPackList(newAreaList)
                                    setLinePackList(newLineList)
                                }
                                setLayoutList(newLayList)
                            }
                            setDataSourceList(newDsList)
                            // アコーディオンデータ作成
                            const accItems = makeAccordionItems(newDsList, dataSourceId)
                            setAccordionItems(accItems)
                        }
                        setShopList(newShopList)
                        setWaitingForReturn(false)
                    }
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }


    /**
     * 指定されたラインパックIDを更新します。
     * @param id 
     */
    const changeLinePack = (id: number | undefined) => {
        setLinePackId(id)
    }

    /**
     * ライン編集用のラインパックを更新します。
     * @param newLinePack 
     */
    const updateEditingLinePack = (newLinePack: LinePackModel) => {
        setEditingLinePack(newLinePack)
    }

    /**
     * 新しいラインパックを登録します。
     * @param srcLayoutId 
     * @param startYmd 
     * @param lineDef 
     */
    const createLinePack = (srcLayoutId: number, startYmd: string, lineDef: ReqLineDef) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.make_lineset(srcLayoutId, startYmd, lineDef).then(res => {
                    console.log("api.make_lineset res:", res)
                    const newLinePack = LineSet2PackModel(res)
                    if (shopId && shopList && dataSourceId) {
                        console.log("newLinePack:", newLinePack)
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            //console.log("shop:", shop)
                            const newDsList = [...shop.datasource_list]
                            const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                            if (ds) {
                                console.log("ds:", ds)
                                const newLayList = [...ds.layout_list]
                                const lay = newLayList.find(el => el.layout_id === srcLayoutId)
                                if (lay && lay.mapping) {
                                    console.log("lay:", lay)
                                    lay.mapping.line_packs.push(newLinePack)
                                    const newAreaList = [...lay.mapping.area_packs]
                                    console.log("lay.mapping:", lay.mapping)
                                    setLinePackList(lay.mapping.line_packs)
                                    setAreaPackList(newAreaList)
                                    setEditingLinePack(undefined)
                                }
                                setLayoutList(newLayList)
                            }
                            setDataSourceList(newDsList)
                            const accItems = makeAccordionItems(newDsList, dataSourceId)
                            setAccordionItems(accItems)
                        }
                        setShopList(newShopList)
                        setWaitingForReturn(false)
                    }
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 編集したラインを保存します。
     */
    const saveLinePack = () => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editingLinePack && layoutId) {
                const def = LineModel2ReqLineDef(editingLinePack)
                console.log("saveLinePack def:", def)
                const lsid = editingLinePack.line_set_id
                if (lsid) {
                    if (editApi) {
                        editApi.update_lineset(layoutId, lsid, editingLinePack.startYmd, def).then(res => {
                            console.log("update_lineset result:", res)
                            const enters = res.enter_lines ? res.enter_lines.map((el, idx) => { return convertLineObject2LineModel(LINE_ID_ENT, idx, LineDefType.Enter, el) }) : []
                            const exits = res.exit_lines ? res.exit_lines.map((el, idx) => { return convertLineObject2LineModel(LINE_ID_EXT, idx, LineDefType.Exit, el) }) : []
                            const passes = res.pass_lines ? res.pass_lines.map((el, idx) => { return convertLineObject2LineModel(LINE_ID_PAS, idx, LineDefType.Pass, el) }) : []
                            if (shopId && shopList && dataSourceId) {
                                const newShopList = [...shopList]
                                const shop = newShopList.find(el => el.shop_id === shopId)
                                if (shop) {
                                    const newDsList = [...shop.datasource_list]
                                    const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                                    if (ds) {
                                        const newLayList = [...ds.layout_list]
                                        const lay = newLayList.find(el => el.layout_id === layoutId)
                                        if (lay && lay.mapping) {
                                            const newLineList = [...lay.mapping.line_packs]
                                            const linePk = newLineList.find(el => el.line_set_id === lsid)
                                            if (linePk) {
                                                linePk.startYmd = res.start
                                                linePk.lines = [...enters, ...exits, ...passes]
                                            }
                                            setLinePackList(newLineList)
                                            setEditingLinePack(undefined)
                                        }
                                        setLayoutList(newLayList)
                                    }
                                    setDataSourceList(newDsList)
                                    // アコーディオンデータ作成
                                    const accItems = makeAccordionItems(newDsList, dataSourceId)
                                    setAccordionItems(accItems)
                                }
                                setShopList(newShopList)
                                setWaitingForReturn(false)
                            }
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    }
                } else {
                    // 新規
                    createLinePack(layoutId, editingLinePack.startYmd, def)
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたエリアパックを複製します。
     * @param srcLayoutId 
     * @param srcLinePackId 
     * @param start 
     */
    const copyLinePack = (srcLayoutId: number, srcLinePackId: number, start: number) => {
        try {
            //console.log("copy 1")
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (shopId && shopList) {
                const shop = shopList.find(el => el.shop_id === shopId)
                if (shop) {
                    //console.log("copy 2")
                    const timezoneMinute = shop.timezone
                    const tm = start - timezoneMinute * 60000
                    const startYmd = format(new Date(tm), STR_YMD_FORMAT)
                    if (layoutList) {
                        //console.log("copy 3")
                        const lay = layoutList.find(el => el.layout_id === srcLayoutId)
                        if (lay && lay.mapping) {
                            //console.log("copy 4 lay.mapping.line_packs:", lay.mapping.line_packs)
                            const lp = lay.mapping.line_packs.find(el => el.line_set_id === srcLinePackId)
                            if (lp) {
                                const lineDef = LineModel2ReqLineDef(lp)
                                //console.log("startYmd, lineDef:", startYmd, lineDef)
                                createLinePack(srcLayoutId, startYmd, lineDef)
                            }
                        }
                    }
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定されたラインパックを削除します。
     * @param linePackId 
     */
    const deleteLinePack = (linePackId: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.delete_lineset(linePackId).then(res => {
                    if (shopId && shopList && dataSourceId && layoutId) {
                        const newShopList = [...shopList]
                        const shop = newShopList.find(el => el.shop_id === shopId)
                        if (shop) {
                            const newDsList = [...shop.datasource_list]
                            const ds = newDsList.find(el => el.layout_set_id === dataSourceId)
                            if (ds) {
                                const newLayList = [...ds.layout_list]
                                const lay = newLayList.find(el => el.layout_id === layoutId)
                                if (lay && lay.mapping) {
                                    const newLineList = [...lay.mapping.line_packs].filter(el => el.line_set_id !== linePackId)
                                    lay.mapping.line_packs = newLineList
                                    setLinePackList(newLineList)
                                }
                                setLayoutList(newLayList)
                            }
                            setDataSourceList(newDsList)
                            // アコーディオンデータ作成
                            const accItems = makeAccordionItems(newDsList, dataSourceId)
                            setAccordionItems(accItems)
                        }
                        setShopList(newShopList)
                        setWaitingForReturn(false)
                    }
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定したグループの更新、あるいはグループの登録を行います。
     * @param group 
     */
    const saveGroup = (group: GroupModel) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                if (group.group_id) {
                    // 更新
                    const grp = GroupModel2Res(group)                    
                    editApi.update_group([grp]).then(res => {
                        console.log("api.update_group res:", res)
                        if (companyId) updateGroupList(companyId)
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })
                } else {
                    // 新規
                    if (companyId) {
                        editApi.make_group(companyId, group.name).then(res => {
                            console.log("api.make_group res:", res)
                            updateGroupList(companyId)
                        }).catch(e => {
                            setWaitingForReturn(false)
                            const errMsg = e.message ? e.message : e.toString()
                            setErrorOccurred(errMsg)
                            console.error(e)
                        })
                    }
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * 指定したグループを論理削除します。
     * @param gid 
     */
    const deleteGroup = (gid: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.delete_group(gid, undefined).then(res => {
                    console.log("api.delete_group res:", res)
                    if (companyId) updateGroupList(companyId)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }
    
    /**
     * 指定したグループを復活させます。
     * @param gid 
     */
    const reactivateGroup = (gid: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (editApi) {
                editApi.restart_group(gid).then(res => {
                    console.log("api.restart_group res:", res)
                    if (companyId) updateGroupList(companyId)
                }).catch(e => {
                    setWaitingForReturn(false)
                    const errMsg = e.message ? e.message : e.toString()
                    setErrorOccurred(errMsg)
                    console.error(e)
                })
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * グループリストの並べ替えを行います。（DB保存なし）
     * @param fromIdx 
     * @param toIdx 
     */
    const changeGroupOrder = (fromIdx: number, toIdx: number) => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (groupList.length > 0) {
                // fromを抜き出して
                const cloneGroups = [...groupList]
                const deletedItem = cloneGroups.splice(fromIdx, 1)[0]
                // toに挿入
                cloneGroups.splice(toIdx, 0, deletedItem)
                // displayOrderの書き換え
                const newGroups = cloneGroups.map((el, idx) => {
                    el.display_order = idx + 1
                    return el
                })
                // リスト更新
                setGroupList(newGroups)
                setWaitingForReturn(false)
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }

    /**
     * グループリストをDBに保存します。
     */
    const saveGroupList = () => {
        try {
            setWaitingForReturn(true)
            setErrorOccurred("")
            if (groupList.length > 0) {
                if (editApi) {
                    const reqGroups = groupList.map(el => { return GroupModel2Res(el) })
                    editApi.update_group(reqGroups).then(res => {
                        console.log("api.update_group res:", res)
                        setWaitingForReturn(false)
                    }).catch(e => {
                        setWaitingForReturn(false)
                        const errMsg = e.message ? e.message : e.toString()
                        setErrorOccurred(errMsg)
                        console.error(e)
                    })
                }
            }
        } catch (err: any) {
            setWaitingForReturn(false)
            const errMsg = err.message ? err.message : err.toString()
            setErrorOccurred(errMsg)
            console.error(err)
        }
    }
    
    /**
     * すべての保持しているデータを初期状態に戻します。
     */
    const clearManagementData = () => {
        setCompanyId(undefined)
        setCompanyList([])
        setUserList(undefined)
        setUserTypes(undefined)
        setShopId(undefined)
        setShopList([])
        setDataSourceId(undefined)
        setDataSourceList([])
        setAccordionItems(undefined)
        setLayoutId(undefined)
        setLayoutList([])
        setEditingLayout(undefined)
        setAreaPackId(undefined)
        setAreaPackList([])
        setEditingAreaPack(undefined)
        setIsModifiedAP(false)
        setLinePackId(undefined)
        setLinePackList([])
        setEditingLinePack(undefined)
        setGroupList([])
    }

    /**
     * 管理画面用データを初期化します。
     */
    const initManagementData = useCallback(() => {
        console.log("initialize 1 userInfo", userInfo)
        console.log("initialize 2 companyList", companyList)
        console.log("initialize 3 userTypes", userTypes)
        if (userInfo && companyList.length === 0) {
            // STEP1:企業が0件の場合（必ず１件以上存在する）
            // 多分、root権限を持つ場合
            if (ownAccessCheck(PermissionTypes.Provider_AllCompany)) {
                // 企業リスト取得
                updateCompanyList()
            } else {
                // 権限のないときは、自分の所属する会社のみ
                const com = userInfo.company
                if (com) {
                    const model = CompanyRes2Model(com)
                    setCompanyList([model])
                    const cid = model.company_id
                    setCompanyId(cid)
                    // 企業が１件だけのときは、店舗とアカウントとグループのリストを更新します。
                    updateShopList(cid)
                    updateUserList(cid)
                    updateGroupList(cid)
                }
            }
        }
        // もしもUserTypesが空なら
        if (userTypes === undefined) {
            try {
                setErrorOccurred("")
                if (editApi) {
                    editApi.get_user_types().then(types => {
                        console.log("get_user_types results:", types)
                        if (types && types.length > 0) {
                            const list = types.map(el => {
                                const userType = el[1] as string
                                const role: Role = Utils.userType2Role(userType)
                                return { id: el[0], name: el[1], role: role } as UserTypeObject
                            })
                            setUserTypes(list)
                        }
                    }).catch(err => {
                        const errMsg = err.message ? err.message : err.toString()
                        setErrorOccurred(errMsg)
                        console.error(err)
                    })                    
                }
            } catch (err: any) {
                const errMsg = err.message ? err.message : err.toString()
                setErrorOccurred(errMsg)
                console.error(err)
            }
        }
    }, [editApi, userInfo, companyList, userTypes, ownAccessCheck, updateCompanyList, updateShopList, updateUserList, updateGroupList])

    const value: ManagementDataContextType = {
        // 企業
        companyId, changeCompany, companyList, saveCompany,
        // アカウント
        userList, userTypes, deleteUser, saveUser, reactivateUser,
        // 店舗
        shopId, changeShop, shopList, saveShop, deleteShop,
        // データソース
        dataSourceId, changeDataSource, dataSourceList, updateDatasourceName, copyDataSource, changeHot, deleteDataSource,
        accordionItems, changeExpanded, transcription,
        // レイアウト
        layoutId, changeLayout, layoutList, saveLayout, deleteLayout, copyLayout,
        editingLayout, updateEditingLayout, createNewLayout,
        // エリアパック
        areaPackId, changeAreaPack, areaPackList, saveAreaPack, copyAreaPack, deleteAreaPack, editingAreaPack, updateEditingAreaPack, isModifiedAP,
        // ラインパック
        linePackId, changeLinePack, linePackList, saveLinePack, copyLinePack, deleteLinePack, editingLinePack, updateEditingLinePack,
        // グループ
        groupList, saveGroup, deleteGroup, reactivateGroup, changeGroupOrder, saveGroupList,
        // その他
        clearManagementData, initManagementData, waitingForReturn, errorOccurred, notification, setNotification,
        worker, setWorker
    }

    return (
        <ManagementDataContext.Provider value={value}>
            {props.children}
        </ManagementDataContext.Provider>
    )
}
