import { ResArea, ResAreaSet, ResLayout, ResLineSet } from "../../data/analysis/FullLayout";

export const DEFAULT_RES_AREA: ResArea = {
    area_id: 0,
    area_number: 0,
    name: "エリア情報なし",
    cell_ids: [],
    area_type: "normal",
    group_id: null
}

export class FullLayout implements ResLayout {
    layout_id: number;
    name: string;
    start: number;
    image: string;
    pixel_width: number;
    pixel_height: number;
    origin_x: number;
    origin_y: number;
    mm_per_pixel: number;
    area_unitcell_pixel: number;
    area_set: ResAreaSet[];
    line_set: ResLineSet[];

    private _areaID2area: Record<string, ResArea | undefined>

    constructor(layout: ResLayout) {
        this.layout_id = layout.layout_id
        this.name = layout.name
        this.start = layout.start
        this.image = layout.image
        this.pixel_width = layout.pixel_width
        this.pixel_height = layout.pixel_height
        this.origin_x = layout.origin_x
        this.origin_y = layout.origin_y
        this.mm_per_pixel = layout.mm_per_pixel
        this.area_unitcell_pixel = layout.area_unitcell_pixel
        this.area_set = layout.area_set
        this.line_set = layout.line_set

        this._areaID2area = {}
        for (const as of this.area_set) {
            for (const area of as.area_defs) {
                this._areaID2area[area.area_id] = area
            }
        }
    }

    getArea(areaID: string): ResArea {
        const result = this._areaID2area[areaID]
        if (result  == null) {
            return DEFAULT_RES_AREA
        }
        return result
    }

    async getActiveAreaDefs(start_date: string): Promise<ResArea[]> {
        let singleActiveAreaList: ResArea[] = [];
        {
            let areaSet: ResAreaSet | null = null;
            for await (let as of this.area_set) {
                if (as.start <= start_date) {
                    if (areaSet != null) {
                        if (areaSet.start < as.start) {
                            areaSet = as
                        }
                    } else {
                        areaSet = as
                    }
                }
            }
            if (areaSet != null) {
                singleActiveAreaList = areaSet.area_defs
            }
        }
        return singleActiveAreaList
    }
}