import React from "react"
import { useTranslation } from "react-i18next"

import style from "./LegendStayPass.module.css"

export const LegendColorType = {
    Primary: "1",
    Secondary: "2"
} as const
export type LegendType = typeof LegendColorType[keyof typeof LegendColorType]

interface Props {
    type: LegendType
    addMsgStay: string
    addMsgPass: string
}

export const LegendStayPass: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    let stayClz: any
    let passClz: any
    if (props.type === LegendColorType.Primary) {
        stayClz = style.stay1
        passClz = style.pass1
    } else if (props.type === LegendColorType.Secondary) {
        stayClz = style.stay2
        passClz = style.pass2
    }

    return (
        <div className={style.main}>
            <div className={style.block}>
                <span className={stayClz}></span><span className={style.text}>{t("personStaying")}{props.addMsgStay}</span>
            </div>
            <div className={style.space}></div>
            <div className={style.block}>
                <span className={passClz}></span><span className={style.text}>{t("passengers")}{props.addMsgPass}</span>
            </div>
        </div>
    )
}