import { useEffect, useRef } from "react"

/**
 * キーボードの処理
 * @param key 
 * @param handler コールバック
 */
export function useKeypress(key: string, handler: () => void) {

    const savedHandler = useRef(handler)

    // 初期化
    useEffect(() => {
        // コールバックを登録
        savedHandler.current = handler
    }, [handler])

    // DOM構築後
    useEffect(() => {
        const onKeyup = (event: KeyboardEvent) => {
            // 指定キーが押されたら
            if (event.key === key) {
                // コールバックを実行
                savedHandler.current()
            }
        }
        // キーイベント登録
        document.addEventListener('keyup', onKeyup)

        // イベント解除処理を返す
        return () => document.removeEventListener('keyup', onKeyup)
    }, [])
}