import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import Utils from "../../lib/Utils"
import { HorizontalBarGraph, HorizontalBar, HorizontalBarType  } from "../../component/horizontal_bar_graph/HorizontalBarGraph"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { AnalyzeViewType } from "../../types/Analyze"
import { AnalyzeParametersType } from "../../types/Analyze"
import { AreaCount } from "../../api/data/analysis/AnalysisResult"

import style from "./AreaGraph.module.css"

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const AreaGraph: React.FC<Props> = (props) => {

	const { t } = useTranslation()

    const { dataset } = useAnalyticsDataContext()

    const isSingle = (props.searches?.view === AnalyzeViewType.Single) ? true : false

    const rowNormal = (data: AreaCount, barType: HorizontalBar, key: number, trafficAreaMax: number) => {
        const stayRatio: number = Math.round(1000 * data.stay_rate) / 10
        const notStayRatio: number = Math.round(1000 * data.pass_rate) / 10
        
        let dispAreaName: string = Utils.textEllipsis(data.area.name, 24)
        const dspTrafRatio: string = (data.total_count === 0) ? "(―)" : (isSingle) ? "" : "(100)"
        const dspStayRatio = (data.total_count === 0) ? "(―)" : "(" + Utils.formatCommaPointOne(stayRatio) + ")"
        const dspNotStayRatio = (data.total_count === 0) ? "(―)" : "(" + Utils.formatCommaPointOne(notStayRatio) + ")"
        return (
            <tr key={key}>
                <td className={isSingle ? style.tdNameSng : style.tdNameCmp}>{dispAreaName}</td>
                <td className={style.tdData}>{Utils.comma(data.total_count)}</td>
                {
                    isSingle ? (null) : (
                <td className={style.tdData}>{dspTrafRatio}</td>
                    )
                }
                <td></td>
                <td className={style.tdData}>{Utils.comma(data.stay_count)}</td>
                <td className={style.tdData}>{dspStayRatio}</td>
                <td></td>
                <td className={style.tdData}>{Utils.comma(data.pass_count)}</td>
                <td className={style.tdData}>{dspNotStayRatio}</td>
                <td></td>
                <td><HorizontalBarGraph type={barType} value={data.total_count} maxValue={trafficAreaMax} stayRatio={stayRatio / 100}/></td>
            </tr>
        )        
    }

    const rowNoData = (key: number): ReactNode => {
        return (
            <tr key={key}>
                <td className={isSingle ? style.tdNameSng : style.tdNameCmp}>―</td>
                <td className={style.tdData}>―</td>
                <td className={style.tdData}>( ― )</td>
                <td></td>
                <td className={style.tdData}>―</td>
                <td className={style.tdData}>( ― )</td>
                <td></td>
                <td className={style.tdData}>―</td>
                <td className={style.tdData}>( ― )</td>
                <td></td>
                <td></td>
            </tr>
        )
    }

    const rowOfDiff = (diffTraf: number, diffStay: number, diffNots: number, dTrafRt: string, dStayRt: string, dNotsRt: string, key:number): ReactNode => {
        return (
            <tr key={key}>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>{Utils.formatSignCommaInteger(diffTraf)}</td>
                <td className={style.tdDiff}>{dTrafRt}</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>{Utils.formatSignCommaInteger(diffStay)}</td>
                <td className={style.tdDiff}>{dStayRt}</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>{Utils.formatSignCommaInteger(diffNots)}</td>
                <td className={style.tdDiff}>{dNotsRt}</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}></td>
            </tr>
        )
    }

    const rowOfDiffEmpty = (key: number): ReactNode => {
        return (
            <tr key={key}>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>―</td>
                <td className={style.tdDiff}>( ― )</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>―</td>
                <td className={style.tdDiff}>( ― )</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}>―</td>
                <td className={style.tdDiff}>( ― )</td>
                <td className={style.tdDiff}></td>
                <td className={style.tdDiff}></td>
            </tr>
        )
    }

    const rowOfSpace = (key: number): ReactNode => {
        return (
            <tr key={key} className={style.spaceLine}>
                <td colSpan={11} className={style.spacer}></td>
            </tr>
        )
    }

    const getRows = (): ReactNode => {
        if (props.searches?.view === AnalyzeViewType.Single) {
            const result = []
            const area_counts = dataset?.single?.get_area_count_list()
            if (area_counts) {
                const trafficMaxByArea = Math.max(...(area_counts.map(el => el.total_count)))
                for (let dt of area_counts) {
                    const content = rowNormal(dt, HorizontalBarType.Single, dt.area.area_id, trafficMaxByArea)
                    result.push(content)
                }
            }
            return result
        } else if (props.searches?.view === AnalyzeViewType.Compare) {
            const result = []
            const count1 = dataset?.single?.get_area_count_list()
            const count2 = dataset?.compare?.get_area_count_list()
            if (count1 && count2) {
                const len = Math.max(count1.length, count2.length)
                const max = Math.max(...(count1.map(el => el.total_count)), ...(count2.map(el => el.total_count)))                
                for (let i = 0; i < len; i++) {
                    if (i < count1.length) {
                        const content1 = rowNormal(count1[i], HorizontalBarType.Single, i * 4, max)
                        result.push(content1)
                    } else {
                        // 空行
                        const emp = rowNoData(i * 4)
                        result.push(emp)
                    }
                    // 比較（２）の行
                    if (i < count2.length) {
                        const content2 = rowNormal(count2[i], HorizontalBarType.Compare, i * 4 + 1, max)
                        result.push(content2)
                    } else {
                        // 空行
                        const emp = rowNoData(i * 4 + 1)
                        result.push(emp)
                    }
                    // 集計行
                    if (i < count1.length && i < count2.length) {
                        const difTraf = count2[i].total_count - count1[i].total_count //data2.traf - data1.traf
                        const difStay = count2[i].stay_count - count1[i].stay_count //data2.stay - data1.stay
                        const difNots = count2[i].pass_count - count1[i].pass_count //data2.nots - data1.nots
                        const difTrafRt = (count1[i].total_count) ? "(" + Utils.formatSignCommaPointOne(difTraf / count1[i].total_count * 100) + ")" : ""
                        const difStayRt = (count1[i].stay_count) ? "(" + Utils.formatSignCommaPointOne(difStay / count1[i].stay_count * 100) + ")" : ""
                        const difNotsRt = (count1[i].pass_count) ? "(" + Utils.formatSignCommaPointOne(difNots / count1[i].pass_count * 100) + ")" : ""
                        const content3 = rowOfDiff(difTraf, difStay, difNots, difTrafRt, difStayRt, difNotsRt, i * 4 + 2)
                        result.push(content3)
                        // 隙間調整行
                        result.push(rowOfSpace(i * 4 + 3))
                    } else {
                        // 片側データがない行
                        const emp = rowOfDiffEmpty(i * 4 + 2)
                        result.push(emp)
                        // 隙間調整行
                        result.push(rowOfSpace(i * 4 + 3))
                    }
                }
            }
            return result
        } else {
            return null
        }
    }

    return (
        <div className={style.main}>
            <table className={style.table}>
                <thead>
                    <tr>
                        <th className={style.thAreaName}>{t("areaName")}</th>
                        <th className={style.thTotal}>{t("stopBy")}</th>
                        {
                            isSingle ? (null): (
                        <th className={style.thRatio}>（％）</th>                            
                            )
                        }
                        <th className={style.thSpc}>　</th>
                        <th className={style.thData}>{t("personStaying")}</th>
                        <th className={style.thRatio}>（％）</th>
                        <th className={style.thSpc}>　</th>
                        <th className={style.thData}>{t("passengers")}</th>
                        <th className={style.thRatio}>（％）</th>
                        <th className={style.thSpc}>　</th>
                        <th className={style.thGraph}></th>
                    </tr>
                </thead>
                <tbody>
                    {getRows()}
                </tbody>
            </table>
        </div>
    )
}