import React, { useCallback, useEffect, useRef } from "react"

import style from "./HorizontalBarGraph.module.css"

const BAR_WIDTH = 360
const BAR_HEIGHT = 16
const BAR_ROUND = 4

const BAR_NARROW_WIDTH = 190

const COLOR_BLUE = "#007BFF"
const COLOR_GREEN = "#29CB97"
const COLOR_RED = "#DC3545"
const COLOR_WHITE = "#FFFFFF"
const COLOR_LITE_GRAY = "#E9ECEF"
const COLOR_LITE_BLUE = "#67B0FF"
const COLOR_LITE_GREEN = "#7EE0C0" // 60%

const FONT_FAMILY_9 = "9pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"
const FONT_FAMILY_11 = "11pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"

export const HBarWidthType = {
    Normal: "normal",
    Narrow: "narrow"
} as const
export type HBarWidth = typeof HBarWidthType[keyof typeof HBarWidthType]

export const HorizontalBarType = {
    Single: "single",   // エリア別の個別
    Compare: "compare", // エリア別の比較
    Trail: "trail",     // 動線詳細
    FunnelBlue: "blue", // ファネル別CVR
    FunnelRed: "red",   // ファネル別CVR（非購買）
    Funnel: "white"     // ファネル別エリアランキング
} as const
export type HorizontalBar = typeof HorizontalBarType[keyof typeof HorizontalBarType]

interface Props {
    type: HorizontalBar
    value: number
    maxValue: number
    stayRatio?: number
    widthType?: HBarWidth
    dispUnit?: string       // 表示する単位（デフォルトは"sec")
}

type PosAndColorType = {
    x: number,
    y: number,
    color: string
}

export const HorizontalBarGraph: React.FC<Props> = (props) => {

    const ref = useRef<HTMLCanvasElement>(null)
    const ratio = Math.round(props.value / props.maxValue * 100)
    const isNarrow: boolean = (props.widthType && props.widthType === HBarWidthType.Narrow) ? true : false
    const width = (isNarrow) ? BAR_NARROW_WIDTH : BAR_WIDTH

    const txtStartPositionColor = useCallback((foreColor: string): PosAndColorType => {
        if (isNarrow) {
            if (ratio > 50) {
                return { x: (width * (ratio / 100)) / 2 - 20, y: 8, color: COLOR_WHITE}
            } else {
                return { x: width * (ratio / 100) + 10, y: 8, color: foreColor}
            }
        } else {
            if (ratio > 30) {
                return { x: (width * (ratio / 100)) / 2 - 50, y: 8, color: COLOR_WHITE}
            } else {
                return { x: width * (ratio / 100) + 20, y: 8, color: foreColor}
            }
        }
    }, [ratio, width, isNarrow])

    const drawNoBaseBar = useCallback((): void => {
        if (ref) {
            const ctx: CanvasRenderingContext2D | undefined | null = ref.current?.getContext("2d")
            if (ctx) {
                // 棒を描く
                ctx.lineWidth = 1
                ctx.strokeStyle = COLOR_BLUE
                ctx.fillStyle = COLOR_BLUE
                ctx.beginPath()
                ctx.moveTo(BAR_ROUND, 0)
                const px = Math.round(width * ratio / 100)
                ctx.arcTo(px , 0, px, BAR_HEIGHT, BAR_ROUND)
                ctx.arcTo(px, BAR_HEIGHT, 0, BAR_HEIGHT, BAR_ROUND)
                ctx.arcTo(0, BAR_HEIGHT, 0, 0, BAR_ROUND)
                ctx.arcTo(0, 0, width, 0, BAR_ROUND)
                ctx.fill()
                // 文字を描く
                const text = props.value + ""
                const poscol = txtStartPositionColor(COLOR_BLUE)
                ctx.font = isNarrow ? FONT_FAMILY_9 : FONT_FAMILY_11
                ctx.textBaseline = "middle"
                ctx.fillStyle = poscol.color
                ctx.fillText(text, poscol.x, poscol.y)
            }
        }
    }, [ref, width, ratio, isNarrow, props.value, txtStartPositionColor])

    /**
     * 動線詳細とファネル別で利用する
     */
    const drawGrayBaseBar = useCallback((color: string, addTxt: string = ""): void => {
        if (ref) {
            const ctx: CanvasRenderingContext2D | undefined | null = ref.current?.getContext("2d")
            if (ctx) {
                // 棒を描く
                ctx.lineWidth = 1
                ctx.strokeStyle = color
                ctx.fillStyle = color
                ctx.beginPath()
                ctx.moveTo(BAR_ROUND, 0)
                if (ratio === 100) {
                    ctx.arcTo(width , 0, width, BAR_HEIGHT, BAR_ROUND)
                    ctx.arcTo(width, BAR_HEIGHT, 0, BAR_HEIGHT, BAR_ROUND)
                    ctx.arcTo(0, BAR_HEIGHT, 0, 0, BAR_ROUND)
                    ctx.arcTo(0, 0, width, 0, BAR_ROUND)
                    ctx.fill()
                } else {
                    const px = Math.round(width * ratio / 100)
                    ctx.lineTo(px, 0)
                    ctx.lineTo(px, BAR_HEIGHT)
                    ctx.arcTo(0, BAR_HEIGHT, 0, 0, BAR_ROUND)
                    ctx.arcTo(0, 0, width, 0, BAR_ROUND)
                    ctx.fill()
                    // 棒の灰色部分
                    ctx.strokeStyle = COLOR_LITE_GRAY
                    ctx.fillStyle = COLOR_LITE_GRAY
                    ctx.beginPath()
                    ctx.moveTo(px, 0)
                    ctx.arcTo(width, 0, width, BAR_HEIGHT, BAR_ROUND)
                    ctx.arcTo(width, BAR_HEIGHT, px + 1, BAR_HEIGHT, BAR_ROUND)
                    ctx.lineTo(px, BAR_HEIGHT)
                    ctx.lineTo(px, 0)
                    ctx.fill()
                }
                // 文字を描く
                const text = props.value + addTxt
                const poscol = txtStartPositionColor(color)
                ctx.font = (isNarrow || props.type === HorizontalBarType.Trail) ? FONT_FAMILY_9 : FONT_FAMILY_11
                ctx.textBaseline = "middle"
                ctx.fillStyle = poscol.color
                ctx.fillText(text, poscol.x, poscol.y)
            }
        }
    }, [ref, width, ratio, isNarrow, props, txtStartPositionColor])

    /**
     * エリア別滞在時間分析画面で使うバーを描く
     */
    const drawBar4Area = useCallback((): void => {
        if (ref) {
            const ctx: CanvasRenderingContext2D | undefined | null = ref.current?.getContext("2d")            
            if (ctx) {
                if (props.stayRatio !== undefined) {
                    const baseColor = (props.type === "single") ? COLOR_BLUE : COLOR_GREEN
                    const liteColor = (props.type === "single") ? COLOR_LITE_BLUE : COLOR_LITE_GREEN
                    ctx.lineWidth = 1
                    ctx.strokeStyle = baseColor
                    ctx.fillStyle = baseColor
                    const px = width * ratio * props.stayRatio / 100
                    ctx.fillRect(0, 0, px, BAR_HEIGHT)
                    // 薄い部分
                    const nx = width * ratio / 100
                    ctx.strokeStyle = liteColor
                    ctx.fillStyle = liteColor
                    ctx.beginPath()
                    ctx.moveTo(px, 0)
                    ctx.arcTo(nx, 0, nx, BAR_HEIGHT, BAR_ROUND)
                    ctx.arcTo(nx, BAR_HEIGHT, px, BAR_HEIGHT, BAR_ROUND)
                    ctx.lineTo(px, BAR_HEIGHT)
                    ctx.lineTo(px, 0)
                    ctx.fill()
                    // 濃い部分の数字
                    const str = "" + Math.round(props.stayRatio * 1000) / 10
                    ctx.font = FONT_FAMILY_9
                    ctx.textBaseline = "middle"
                    ctx.fillStyle = "white"
                    if (px > 40) {
                        ctx.fillText(str, px / 2 - 13, 8)
                    }
                    // 薄い部分の数字
                    const txt = "" + Math.round(1000 - props.stayRatio * 1000) / 10
                    if ((nx - px) > 40) {
                        ctx.fillText(txt, px + (nx - px) / 2 - 13, 8)
                    }
                }
            }
        }
    }, [ref, width, ratio, props.stayRatio, props.type])

    useEffect(() => {
        if (props.type === HorizontalBarType.FunnelBlue) {
            drawGrayBaseBar(COLOR_BLUE)            
        }  else if (props.type === HorizontalBarType.FunnelRed) {
            drawGrayBaseBar(COLOR_RED)
        } else if (props.type === HorizontalBarType.Funnel) {
            drawNoBaseBar()
        } else if (props.type === HorizontalBarType.Trail) {
            // 動線詳細画面用の棒グラフを描く
            const unitDisp = (props.dispUnit) ? props.dispUnit : "sec"
            drawGrayBaseBar(COLOR_BLUE, unitDisp)
        } else {
            // エリア分析用の棒グラフを描く
            drawBar4Area()
        }
    }, [props.type, props.dispUnit, drawBar4Area, drawGrayBaseBar, drawNoBaseBar])

    return (
        <div className={style.main}>
            <canvas className={style.canvas} ref={ref} width={width} height={BAR_HEIGHT}></canvas>
        </div>
    )
}