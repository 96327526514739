import { Role } from "../api/data/core/Enums";
import {
    AllPermissions,
    Permissions,
    RootPermissions,
    HeadOfficePermissions,
    JuniorManagerPermissions,
    StaffPermissions,
    StoreManagerPermOwn,
    StoreManagerPermOther,
    AreaManagerPermOwn,
    AreaManagerPermOther
} from "../types/Permissions";

export type Relations = {
    static: {
        ownShop: Array<Permissions>     // 自分の店舗に対する権限     
        otherShop: Array<Permissions>   // 他店舗に対する権限
        outer?: Array<Permissions>      // 他企業に対する権限
    }
    dynamic?: Record<Permissions, (params: any) => boolean> // 権限判定が必要な時に関数を呼び出すもの
}

/**
 * ひとつの権限のルール
 */
export type Rule = Record<Role, Relations>

/**
 * ルールテーブル：ロールと権限の関係を記述します。
 * 
 * staticに定義されている権限が各機能の実行（作成、閲覧、更新、削除）を可能とする。権限がない場合は実行できない。
 * 
 */
export const rules: Rule = {
    root: {
        static: { ownShop: AllPermissions, otherShop: AllPermissions, outer: AllPermissions }
    },
    developer: {
        static: { ownShop: AllPermissions, otherShop: AllPermissions, outer: AllPermissions }
    },
    headoffice: {
        static: { ownShop: HeadOfficePermissions, otherShop: HeadOfficePermissions }
    },
    areamanager: {
        static: { ownShop: AreaManagerPermOwn, otherShop: AreaManagerPermOther }
    },
    storemanager: {
        static: { ownShop: StoreManagerPermOwn, otherShop: StoreManagerPermOther }
    },
    juniormanager: {
        static: { ownShop: JuniorManagerPermissions, otherShop: [] }
    },
    staff: {
        static: { ownShop: StaffPermissions, otherShop: [] }
    },
    //juniorstaff: {
    //    static: { ownShop: StaffPermissions, otherShop: [] }
    //},
}