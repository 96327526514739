import React, { useEffect, useCallback, useRef, useState, useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { subDays, addDays, isValid } from "date-fns"
import { useTranslation } from "react-i18next"

import { DashboardAreaCount } from "../../api/data/dashboard/DashboardResult"
import { useAuthUserContext } from "../../providers"
import { BaseFrame } from "../common/BaseFrame"
import { PageName } from "../../types"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { SeriesSwitcher, BtnProp } from "../../component/series_switcher/SeriesSwitcher"
import { Button, StyleType } from "../../component/button/Button"
import { AreaStayAtomData, AverageAreaStayDatas, useStatisticsContext } from "../../providers/StatisticsData"
import { ChartDataUnitType, IndividualChart, IndividualChartType, IndividualLineChart } from "./IndividualLineChart"
import { TxStatusType } from "../../types"
import Utils from "../../lib/Utils"
import { IndividualHeatmap } from "./IndividualHeatmap"
import { IndividualAverageAreaTable, AvgAreaStayDataType } from "./IndividualAverageAreaTable"
import { IndividualMonthryLineGraph } from "./IndividualMonthryLineGraph"
import { useScroll } from "../../component/useScroll"
import { CurrentTimeline4Mock, YearlongTimeline4Mock } from "../../api_mock/data/DashboardResult"
import { TrackingPageView } from "../common/TrackingPageView"
import { useModal, StyleType as ModalType } from "../../component/modal/Modal"
import { CircleCloseButton, CloseStyleType } from "../../component/circle_close_button/CircleCloseButton"

import style from "./IndividualDashboard.module.css"


const yesterday = Utils.yesterday()
const lastWeek = Utils.lastWeek()

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

/**
 * 個別ダッシュボードの上段ミニグラデーションチャートの並び順
 */
const MiniChartUpper: IndividualChart[] = [
    IndividualChartType.ShopSales,
    IndividualChartType.NumOfBuyers,
    IndividualChartType.NumOfVisitors
]
/*const MiniChartUpper4Mock: IndividualChart[] = [
    IndividualChartType.ShopSales,
    IndividualChartType.NumOfBuyers,
    IndividualChartType.AvgCustomerPrice,
    IndividualChartType.AvgNumOfPurchases
]*/
/**
 * 個別ダッシュボードの下段ミニグラデーションチャートの並び順
 *
const MiniChartLower: IndividualChart[] = []
const MiniChartLower4Mock: IndividualChart[] = [
    IndividualChartType.NumOfVisitors,
    IndividualChartType.AvgInShopTime,
    IndividualChartType.PurchasingRate
]*/

const AllOrIndividualType = {
    All: "all",
    Individual: "ind"
} as const
//type AllOrIndividual = typeof AllOrIndividualType[keyof typeof AllOrIndividualType]

const ViewModeSelector: BtnProp[] = [
    { name: AllOrIndividualType.All, label: "tabAllStore" },
    { name: AllOrIndividualType.Individual, label: "tabIndividual" }
]

const MoveButtonType = {
    PrevDay: "prevDay",
    NextDay: "nextDay",
    PrevShop: "prevShop",
    NextShop: "nextShop"
} as const
//type MoveButton = typeof MoveButtonType[keyof typeof MoveButtonType]

interface Props {

}

export const BaseDateDistanceType = {
    Yesterday: "yesterday",
    LastWeek: "lastWeek"
} as const
export type BaseDateDistance = typeof BaseDateDistanceType[keyof typeof BaseDateDistanceType]

export type BaseDateLabelType = {
    type: BaseDateDistance
    label: string
}

export const BaseDateLabels: BaseDateLabelType[] = [
    { type: BaseDateDistanceType.Yesterday, label: "appointedDay" },
    { type: BaseDateDistanceType.LastWeek, label: "daysAgo" }
]

export const IndividualDashboard: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"

    const { userInfo, orderedShopList } = useAuthUserContext()
    const { startingDate, updateStartingDate, indivDataset, indivStatus, fetchIndividual, clearIndividual } = useStatisticsContext()

    const { pageYOffset } = useScroll()
    const headRef = useRef<HTMLDivElement>(null)
    const [isScroll, setIsScroll] = useState<boolean>(false)

    // API呼び出しのキャンセル用
    const refAbCon = useRef<AbortController | null>(null)

    // 二重レンダリング対策用
    const refFirst = useRef<boolean>(true)

    const [Modal, open, close, isOpen, resize ] = useModal('root', { preventScroll: false, closeOnOverlayClick: true, styleType: ModalType.Dialog })

    // カレンダー用Ref
    const ref = React.useRef<HTMLDivElement>(null)

    const fixedShopOrder = useMemo(() => {
        const result = []
        if (orderedShopList) {
            for (let shop of orderedShopList) {
                result.push(shop.shop_id)
            }
        }
        return result
    }, [orderedShopList])

    const shopId: number = useMemo(() => {
        let id: number = 0
        if (params.shop_id) id = parseInt(params.shop_id)
        // 店舗IDが指定されていないときは店舗IDを指定しなおす。
        if (id === 0 && fixedShopOrder && fixedShopOrder.length > 0) id = fixedShopOrder[0]
        console.log("shopId, fixedShopOrder:", id, fixedShopOrder)
        return id
    }, [params.shop_id, fixedShopOrder])

    const shopName: string = useMemo(() => {
        let id: number = 0
        if (params.shop_id) id = parseInt(params.shop_id)
        if (userInfo && userInfo.shops) {
            const shop = userInfo.shops.find(el => el.shop_id === id)
            if (shop) return shop.name
        }
        return ""
    }, [params.shop_id, userInfo])

    const hasManyShops = useMemo(() => {
        if (orderedShopList) {
            return (orderedShopList.length > 1)
        }
        console.log("hasManyShops false")
        return false
    }, [orderedShopList])

    const orderIndex: number = useMemo(() => {
        if (fixedShopOrder && fixedShopOrder.length > 0 && shopId > 0) return fixedShopOrder.findIndex(el => el === shopId)
        return -1
    }, [fixedShopOrder, shopId])

    const getLayout = useCallback((dist: BaseDateDistance) => {
        if (indivDataset) {
            return (dist === BaseDateDistanceType.Yesterday) ? indivDataset.target_day_areacount.layout : indivDataset.week_ago__areacount.layout
        }
        return undefined
    }, [indivDataset])

    const getShopSelectOptions = () => {
        const elm = (<option key="shop-select-x" value={0}>{t("selectStoreName")}</option>)
        const result = [elm]
        if (orderedShopList && orderedShopList.length > 0) {
            for (let shop of orderedShopList) {
                if (shop.hot_layout_set && shop.hot_layout_set.layout && shop.hot_layout_set.layout.length > 0) {
                    result.push(<option key={'shop-select-' + shop.shop_id} value={shop.shop_id}>{shop.name}</option>)
                }
            }
        }
        return result
    }

    const getStockData = (type: IndividualChart): number[] => {
        if (indivStatus && indivStatus === TxStatusType.Fulfilled) {
            if (indivDataset) {
                let data: number[] = []
                if (isProduction) {
                    if (type === IndividualChartType.NumOfVisitors) {
                        data = indivDataset.current_timeline.number_of_customers
                    } else if (type === IndividualChartType.NumOfBuyers) {
                        data = indivDataset.current_timeline.number_of_purchasers
                    } else if (type === IndividualChartType.ShopSales) {
                        data = indivDataset.current_timeline.amount_sold
                    } else {
						const msg = t("msgUnknownData",{type: type})
                        throw new Error(msg)
                    }
                } else {
                    const timeline = indivDataset.current_timeline as CurrentTimeline4Mock
                    if (type === IndividualChartType.AvgCustomerPrice) {
                        data = timeline.average_customer_price
                    } else if (type === IndividualChartType.AvgInShopTime) {
                        data = timeline.average_stay_time
                    } else if (type === IndividualChartType.AvgNumOfPurchases) {
                        data = timeline.average_num_of_goods
                    } else if (type === IndividualChartType.NumOfBuyers) {
                        data = timeline.number_of_purchasers
                    } else if (type === IndividualChartType.NumOfVisitors) {
                        data = timeline.number_of_customers
                    } else if (type === IndividualChartType.PurchasingRate) {
                        data = timeline.purchasing_rate
                    } else if (type === IndividualChartType.ShopSales) {
                        data = timeline.amount_sold
                    } else {
                        const msg = t("msgUnknownData",{type: type})
                        throw new Error(msg)
                    }
                }
                return data
            }
        }
        return []
    }

    const getLineChartN = (ary: IndividualChart[], startIndex: number): React.ReactNode => {
        return ary.map((typ, idx) => {
            const sts = (indivStatus) ? indivStatus : TxStatusType.Loading
            const data = getStockData(typ)
            const label = (indivDataset) ? indivDataset.current_timeline.current_date_list : []
            const len = data.length
            const values = (len > 1) ? [data[len - 1], data[len - 2], 0] : []
            const key = idx + startIndex
            return (<IndividualLineChart key={key} type={typ} shopId={shopId} status={sts} labels={label} datas={data} values={values} />)
        })        
    }

    const areaCountOfYesterday = useMemo((): DashboardAreaCount[] => {
        //console.log("getAreaCountOfYesterday indivStatus:",indivStatus)
        if (indivStatus && indivStatus === TxStatusType.Fulfilled) {
            if (indivDataset) return indivDataset.target_day_areacount.area_count
        }
        return []
    }, [indivDataset, indivStatus])
    
    const areaCountOfLastWeek = useMemo((): DashboardAreaCount[] => {
        if (indivStatus && indivStatus === TxStatusType.Fulfilled) {
            if (indivDataset) return indivDataset.week_ago__areacount.area_count
        }
        return []
    }, [indivDataset, indivStatus])

    const getMonthryData = useCallback((type: IndividualChart): number[] => {
        if (indivStatus && indivStatus === TxStatusType.Fulfilled) {
            if (indivDataset) {
                if (isProduction) {
                    if (type === IndividualChartType.NumOfVisitors) {
                        return indivDataset.yearlong_timeline.number_of_customers
                    } else if (type === IndividualChartType.NumOfBuyers) {
                        return indivDataset.yearlong_timeline.number_of_purchasers
                    } else if (type === IndividualChartType.ShopSales) {
                        return indivDataset.yearlong_timeline.amount_sold
                    } else {
                        const msg = t("msgUnknownData",{type: type})
                        throw new Error(msg)
                    }
                } else {
                    const timeline = indivDataset.yearlong_timeline as YearlongTimeline4Mock
                    if (type === IndividualChartType.AvgCustomerPrice) {
                        return timeline.average_customer_price
                    } else if (type === IndividualChartType.AvgInShopTime) {
                        return timeline.average_stay_time
                    } else if (type === IndividualChartType.AvgNumOfPurchases) {
                        return timeline.average_num_of_goods
                    } else if (type === IndividualChartType.NumOfBuyers) {
                        return timeline.number_of_purchasers
                    } else if (type === IndividualChartType.NumOfVisitors) {
                        return timeline.number_of_customers
                    } else if (type === IndividualChartType.PurchasingRate) {
                        return timeline.purchasing_rate
                    } else if (type === IndividualChartType.ShopSales) {
                        return timeline.amount_sold
                    } else {
                        const msg = t("msgUnknownData",{type: type})
                        throw new Error(msg)
                    }
                }
            }
        }
        return []
    }, [indivDataset, indivStatus, t])

    const getAverageTimeOf = useCallback((areaCount: DashboardAreaCount[]): AreaStayAtomData[] => {
        const data = areaCount.map(el => {
            return {
                order: 0,
                id: el.area.area_id,
                name: el.area.name,
                value: (el.stay_count) ? Math.round(el.stay_time_total / el.stay_count) : 0
            }
        })
        const sorted = data.sort((a, b) => (a.value > b.value) ? -1 : 1)
        for (let i = 0; i < sorted.length; i++) {
            sorted[i].order = i + 1;
        }
        return sorted
    }, [])

    const getAverageRatioOf = useCallback((areaCount: DashboardAreaCount[], visitors: number) => {
        const data = areaCount.map(el => {
            return {
                order: 0,
                id: el.area.area_id,
                name: el.area.name,
                value: (visitors) ? (el.stay_count / visitors ) : 0
            }
        })
        const sorted = data.sort((a, b) => (a.value > b.value) ? -1 : 1)
        for (let i = 0; i < sorted.length; i++) {
            sorted[i].order = i + 1;
        }
        return sorted
    }, [])

    const averageAreaStays = useMemo((): AverageAreaStayDatas | undefined => {
        if (indivStatus && indivStatus === TxStatusType.Fulfilled) {
            if (indivDataset) {
                return {
                    time: [
                        getAverageTimeOf(indivDataset.target_day_areacount.area_count),
                        getAverageTimeOf(indivDataset.week_ago__areacount.area_count),
                    ],
                    ratio: [
                        getAverageRatioOf(indivDataset.target_day_areacount.area_count, indivDataset.target_day_areacount.number_of_customers),
                        getAverageRatioOf(indivDataset.week_ago__areacount.area_count, indivDataset.week_ago__areacount.number_of_customers),
                    ]
                }
            }
        }
    }, [indivDataset, indivStatus, getAverageTimeOf, getAverageRatioOf])

    /**
     * 全店ダッシュボード押下時の処理
     */
    const handleChangeViewMode = () => {
        clearIndividual()
        if (refAbCon.current) {
            refAbCon.current.abort()
            refAbCon.current = null
            console.log("★ abort")
        }
        navigate(baseUrl + "dashboard")
    }

    /**
     * カレンダーの変更処理
     * @param selectedDate 
     */
    const handleChangeDate = (selectedDate: Date | [Date, Date] | null): void => {
        if (selectedDate && !Array.isArray(selectedDate) && isValid(selectedDate)) {
            updateStartingDate(selectedDate)
            // データ更新
            if (refAbCon.current && indivStatus === TxStatusType.Loading) {
                refAbCon.current.abort()
                refAbCon.current = null
                console.log("★ abort")
            }
            const abCon = new AbortController()
            refAbCon.current = abCon
            if (shopId) fetchIndividual(refAbCon.current, shopId, selectedDate)
        }
    }

    /**
     * 店舗選択の変更処理
     * @param event 
     */
    const handleShopSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        const newShopId = parseInt(val)
        if (shopId !== newShopId) {
            clearIndividual()
            if (refAbCon.current) {
                refAbCon.current.abort()
                refAbCon.current = null
                console.log("★ abort")
            }
            navigate(baseUrl + "dashboard/" + newShopId)
        }
    }

    // 前日ボタン押下時の処理
    const handlePrevDay = () => {
        const newDt = subDays(startingDate, 1)
        updateStartingDate(newDt)
        // データ更新
        if (refAbCon.current && indivStatus === TxStatusType.Loading) {
            refAbCon.current.abort()
            refAbCon.current = null
            console.log("★ abort")
        }
        const abCon = new AbortController()
        refAbCon.current = abCon
        if (shopId) fetchIndividual(refAbCon.current, shopId, newDt)
    }
    
    // 翌日ボタン押下時の処理
    const handleNextDay = () => {
        const newDt = addDays(startingDate, 1)
        if (newDt <= yesterday) {
            updateStartingDate(newDt)
            // データ更新
            if (refAbCon.current && indivStatus === TxStatusType.Loading) {
                refAbCon.current.abort()
                refAbCon.current = null
                console.log("★ abort")
            }
            const abCon = new AbortController()
            refAbCon.current = abCon
            if (shopId) fetchIndividual(refAbCon.current, shopId, newDt)
        }
    }

    // 前店舗ボタン押下時の処理
    const handleNextShop = () => {
        if (fixedShopOrder && fixedShopOrder.length > 0) {
            let idx = orderIndex + 1
            if (idx > fixedShopOrder.length - 1) idx = 0
            clearIndividual()
            navigate(baseUrl + "dashboard/" + fixedShopOrder[idx])
        }
    }

    // 翌店舗ボタン押下時の処理
    const handlePrevShop = () => {
        if (fixedShopOrder && fixedShopOrder.length > 0) {
            let idx = orderIndex - 1
            if (idx < 0) idx = fixedShopOrder.length - 1
            clearIndividual()
            navigate(baseUrl + "dashboard/" + fixedShopOrder[idx])
        }
    }

    // モーダル表示
    const handleModalOpen = () => {
        if (!isOpen) open()
        // リサイズイベントを発生させてスクロールバーを表示させる
        const size = { width: 1040, height: 572 }
        resize(size)
    }

    // スクロール時の処理（ヘッド部分のフローティング処理）
    useEffect(() => {
        if (pageYOffset && headRef) {
            if (pageYOffset > 66) {
                if (isScroll===false) setIsScroll(true)
            } else {
                if (isScroll) setIsScroll(false)
            }
        }
    }, [pageYOffset, isScroll])

    // 初期表示時と店舗切替時
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                console.log("refFirst SKIP IndividualDashboard")
                return
            }
        }
        if (indivDataset === undefined && indivStatus === undefined) {
            if (refAbCon.current) {
                refAbCon.current.abort()
                refAbCon.current = null
                console.log("★ abort")
            }
            const abCon = new AbortController()
            refAbCon.current = abCon
            if (shopId) fetchIndividual(refAbCon.current, shopId, startingDate)
        }

        return () => {
            if (refAbCon.current && indivStatus === TxStatusType.Loading) {
                refAbCon.current.abort()
                refAbCon.current = null
                console.log("★IndividualDashboard useEffect abort")
            }
        }
    }, [shopId, indivDataset, indivStatus, startingDate, fetchIndividual])
    
    let errorMessage = null
    if (indivStatus === TxStatusType.Rejected) {
        errorMessage = (<div className={style.error}>{t("msgErrorOccured")}</div>)
    }
    const dateFormat = t("dateFormat.ymd_E");
    const dateFormatCalendar = t("dateFormat.y_L");
    return (
        <BaseFrame actPage={PageName.DashboardShop}>
            <div className={style.pane}>
                <div className={(isScroll) ? style.headScrolling : style.head }>
                    <div className={style.headTop}>
                        <div className={style.title}>
                            {t("header.dashboard")}
                            {isProduction ? (<TrackingPageView page_title={"dashboard-individual-" + shopName} />) : (null)}                            
                        </div>
                        <div className={style.viewSel}>
                            <SeriesSwitcher activeName={AllOrIndividualType.Individual} buttonList={ViewModeSelector} onClick={handleChangeViewMode} />
                        </div>
                    </div>
                    <div className={style.headMid}>
                        <div className={style.datepicker}>
                            <FupDatepicker
                                type={DpType.Large}
                                divRef={ref}
                                dateFormat={dateFormat}
                                dateFormatCalendar={dateFormatCalendar}
                                onChange={handleChangeDate}
                                selected={startingDate}
                                maxDate={yesterday}
                                locale={i18n.language}
                            />
                        </div>
                        <div className={style.prevBtn}>
                            <button className={`${style.customButton} ${style.customNoIcon}`} name={MoveButtonType.PrevDay} onClick={handlePrevDay}><span className={style.customButtonName}>{t("prevDay")}</span></button>
                        </div>
                        <div className={style.nextBtn}>
                            <button className={`${style.customButton} ${style.customNoIcon}`} name={MoveButtonType.NextDay} onClick={handleNextDay} disabled={startingDate >= yesterday}><span className={style.customButtonName}>{t("nextDay")}</span></button>
                        </div>
                    </div>
                    <div className={style.headBottom}>
                        <div className={style.shopSel}>
                            <select className={style.customSelect} onChange={handleShopSelected} value={shopId}>
                                {getShopSelectOptions()}
                            </select>
                        </div>
                        <div className={style.prevBtn}>
                            <button className={`${style.customButton} ${style.customIconLeft}`} name={MoveButtonType.PrevShop} onClick={handlePrevShop} disabled={!hasManyShops }><span className="material-symbols-outlined">navigate_before</span><span className={style.customButtonName}>{t("prevStore")}</span></button>
                        </div>
                        <div className={style.nextBtn}>
                            <button className={`${style.customButton} ${style.customIconRight}`} name={MoveButtonType.NextShop} onClick={handleNextShop} disabled={!hasManyShops }><span className={style.customButtonName}>{t("nextStore")}</span><span className="material-symbols-outlined">navigate_next</span></button>
                        </div>
                    </div>
                </div>
                <div className={style.body}>
                    {errorMessage}
                    <div className={style.chart4}>
                        {getLineChartN(MiniChartUpper, 0)}
                    </div>
                    <div className={style.mixMargin}>
                        <div className={style.holHeatmap}>
                            <div className={style.hmTitle}>{t("heatMap")}</div>
                            <div className={style.hmFlex} onClick={handleModalOpen}>
                                <IndividualHeatmap type={BaseDateDistanceType.Yesterday} shopId={shopId} baseDate={yesterday} data={areaCountOfYesterday} layout={getLayout(BaseDateDistanceType.Yesterday)} status={(indivStatus) ? indivStatus : TxStatusType.Loading} />
                                <IndividualHeatmap type={BaseDateDistanceType.LastWeek} shopId={shopId} baseDate={lastWeek} data={areaCountOfLastWeek} layout={getLayout(BaseDateDistanceType.LastWeek)} status={(indivStatus) ? indivStatus : TxStatusType.Loading} />
                            </div>
                        </div>
                        <div className={style.areaStay}>
                            <div className={style.stayTime}>
                                <div className={style.lsTitle}>{t("averageDwellTimeArea")}</div>
                                <IndividualAverageAreaTable labelType={BaseDateDistanceType.Yesterday} dataType={AvgAreaStayDataType.StayTime} datas={averageAreaStays} />
                                <IndividualAverageAreaTable labelType={BaseDateDistanceType.LastWeek} dataType={AvgAreaStayDataType.StayTime} datas={averageAreaStays} />
                            </div>
                            <div className={style.stayRatio}>
                                <div className={style.lsTitle}>{t("averageDwellRateArea")}(％)</div>
                                <IndividualAverageAreaTable labelType={BaseDateDistanceType.Yesterday} dataType={AvgAreaStayDataType.StayRatio} datas={averageAreaStays} />
                                <IndividualAverageAreaTable labelType={BaseDateDistanceType.LastWeek} dataType={AvgAreaStayDataType.StayRatio} datas={averageAreaStays} />
                            </div>
                        </div>
                        <div className={style.space}>
                        </div>
                    </div>
                    <div className={style.lineGraphs}>
                        <section>
                            <IndividualMonthryLineGraph type={IndividualChartType.ShopSales} title="storeSales" data={getMonthryData(IndividualChartType.ShopSales)} unit={ChartDataUnitType.money } />
                            <IndividualMonthryLineGraph type={IndividualChartType.NumOfBuyers} title="purchasers" data={getMonthryData(IndividualChartType.NumOfBuyers)} unit={ChartDataUnitType.person} />
                            <IndividualMonthryLineGraph type={IndividualChartType.NumOfVisitors} title="visitors" data={getMonthryData(IndividualChartType.NumOfVisitors)} unit={ChartDataUnitType.person} />
                        </section>
                    </div>
                </div>
            </div>
            <Modal>
                <div className={style.modalMain}>
                    <div className={style.modalTop}>
                        <div className={style.modalTitle}>{t("heatMap")}</div>
                        <div className={style.modalClose}>
                            <CircleCloseButton onClose={close} styleType={CloseStyleType.SmallWhite} />
                        </div>
                    </div>
                    <div className={style.modalBody}>
                        <IndividualHeatmap type={BaseDateDistanceType.Yesterday} shopId={shopId} baseDate={yesterday} data={areaCountOfYesterday} layout={getLayout(BaseDateDistanceType.Yesterday)} status={(indivStatus) ? indivStatus : TxStatusType.Loading} zoomUp={true} />
                        <IndividualHeatmap type={BaseDateDistanceType.LastWeek} shopId={shopId} baseDate={lastWeek} data={areaCountOfLastWeek} layout={getLayout(BaseDateDistanceType.LastWeek)} status={(indivStatus) ? indivStatus : TxStatusType.Loading} zoomUp={true} />
                    </div>
                </div>                
            </Modal>
        </BaseFrame>
    )
}