import React from "react"
import { Role } from "../api/data/core/Enums"
import { useAuthUserContext } from "."
import { Navigate, useLocation, useSearchParams } from "react-router-dom"

type Props = {
    component: React.ReactNode,
    redirect: string,
    allowroles?: Role[]
}

/**
 * ログイン状態をチェックして、未ログインならログイン画面へリダイレクトする
 */
export const RouteAuthGuard: React.FC<Props> = (props) => {
    const { userInfo, timedOut } = useAuthUserContext()
    const [searchParams] = useSearchParams()
    const reset_keyword = searchParams.get("reset")

    const location = useLocation()
    const stateObj = { from: location }
    if (reset_keyword) {
        // パスワードリセットへ
        console.log("パスワードリセット。reest_keyword:", reset_keyword)
        return (<Navigate to={"/reset/" + reset_keyword} />)
    } else if (!userInfo) {
        console.log("未ログイン状態です。redirect:", props.redirect)
        return <Navigate to={props.redirect} state={stateObj} replace={false} />
    } else if (timedOut) {
        console.log("タイムアウト状態です。redirect:", props.redirect)
        return <Navigate to={props.redirect} state={stateObj} replace={false} />
    }
    // コンポーネントの表示を許可する
    return <>{props.component}</>
}