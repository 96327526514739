import React from "react"
import { useTranslation } from "react-i18next"

import style from "./HoverEffectButton.module.css"

interface Props {
    labelBefore: string
    labelAfter: string
    onClick: () => void
}

export const HoverEffectButton: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    return (
        <div className={style.efctBtn} onClick={props.onClick}>
            <span>{t(props.labelBefore)}</span>
            <span>{props.labelAfter}</span>
        </div>
    )
}