import axios, { Axios, AxiosResponse, AxiosRequestConfig } from "axios";
import { ManagementAPI } from "../../api_interface/edit/ManagementAPI";
import { CreateShopPayload } from "../../data/edit/RequestModel";
import { ResultShopCreated, ResultTranscriptionLayoutSet } from "../../data/edit/ResultModel";
import { APIError } from "../../APIError";

const axiosOptions: AxiosRequestConfig<any> = {
    responseType: "json",
    responseEncoding: "utf8",
    withCredentials: true,
}

export class EditV2API implements ManagementAPI{
    private axiosInstance: Axios;
    private endpoint = "/v1";

    constructor() {
        this.axiosInstance = axios.create(axiosOptions);
    }

    /**
     * 店舗を作成します。同時にレイアウトセット２つを作成します。
     * @param payload 
     * @returns 
     */
    async createShop(payload: CreateShopPayload): Promise<ResultShopCreated> {
        try {
            const res: AxiosResponse<ResultShopCreated> = await this.axiosInstance.post(
                `${this.endpoint}/shop/create`, payload)
            return res.data
        } catch (e: any) {
            throw new APIError(`店舗作成エラー: payload=${payload}`, e)
        }
    }

    /**
     * 店舗を削除します。
     * @param shopId 
     */
    async deleteShop(shopId: number): Promise<void> {
        try {
            await this.axiosInstance.delete(`${this.endpoint}/shop/${shopId}`)
        } catch (e: any) {
            throw new APIError(`店舗削除エラー: shop_id=${shopId}`, e)
        }
    }

    /**
     * 指定された店舗の本番レイアウトセット（Hot, Active）をドラフトレイアウトセット(Cold)に上書きコピーします。（ActiveからDraftへ転写）
     * レイアウトセットの中身（レイアウト、エリアセット、ラインセット）も全てコピーされます。
     * 
     * @param shop_id 
     */
    async transcriptionLayoutSet(shopId: number): Promise<ResultTranscriptionLayoutSet> {
        try {
            const res: AxiosResponse<ResultTranscriptionLayoutSet> = await this.axiosInstance.put(
                `${this.endpoint}/shop/${shopId}/layout-set/transcription`)
            return res.data
        } catch (e: any) {
            throw new APIError(`レイアウトセット転写エラー: shop_id=${shopId}`, e)
        }
    }
}