import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { CircleSlashIcon, GrabberIcon, IssueReopenedIcon, PencilIcon, TrashIcon } from "@primer/octicons-react"

import { PageName } from "../../types"
import { GroupModel } from "../../types/Management"
import { PermissionTypes } from "../../types/Permissions"
import { ListFrame } from "../common/ListFrame"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { AcceptButtonType, CustomDialog } from "../../component/custom_dialog/CustomDialog"
import { RenameDialog } from "../../component/rename_dialog/RenameDialog"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./GroupList.module.css"

const myPermission = PermissionTypes.ManageGroup_View

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props { }

export const GroupList: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const { companyList, groupList, saveGroup, saveGroupList, changeGroupOrder, deleteGroup, reactivateGroup, initManagementData } = useManagementDataContext()
    
    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()

    // 「削除を含む」チェックボックス用
    const [includingDeleted, setIncludingDeleted] = useState<boolean>(false)
    // 新規作成ダイアローグ表示
    const [createDialog, setCreateDialog] = useState<boolean>(false)
    // 名称変更ダイアローグ表示
    const [renameDialog, setRenameDialog] = useState<number | undefined>(undefined)
    // 削除確認ダイアローグ表示
    const [deleteDialog, setDeleteDialog] = useState<number | undefined>(undefined)
    // 復活ダイアローグ表示
    const [restoreDialog, setRestoreDialog] = useState<number | undefined>(undefined)
    // ドラッグ中行番号
    const [dragIndex, setDragIndex] = useState<number | undefined>(undefined)
    // 名称編集用
    const [editName, setEditName] = useState<string>("")

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageGroup_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageGroup_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageGroup_Delete) }, [ableToDelete])
    
    /**
     * 新規作成ボタン押下時の処理
     */
    const handleCreateGroup = () => {
        if (createDialog === false && renameDialog === undefined && deleteDialog === undefined && restoreDialog === undefined) {
            if (ableNew) {
                setCreateDialog(true)
                setEditName("")
            }
        }
    }

    /**
     * 新規作成ダイアローグを閉じる
     */
    const handleCreateGroupCanceled = useCallback(() => {
        setCreateDialog(false)
    }, [])
    
    /**
     * 新規作成ダイアローグの完了時の処理
     */
    const handleCreateGroupAccepted = useCallback(() => {
        if (!ableNew) return
        const g: GroupModel = {
            group_id: 0,
            name: editName,
            display_order: 0
        }
        saveGroup(g)
        setCreateDialog(false)
        setEditName("")
    }, [editName, saveGroup, ableNew])

    /**
     * 編集中の名称が変更されたときの処理
     * @param event 
     */
    const handleChangeEditName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value
        if (ableEdit) setEditName(newName)
    }, [ableEdit])

    /**
     * チェックボックスが変更されたときの処理
     * @param event 
     */
    const handleChangeCheckbox = (event: CustomCheckboxEvent) => {
        setIncludingDeleted(!includingDeleted)
    }
    
    /**
     * 編集ボタン押下時の処理
     */
    const handleRenameOpen = useCallback((gid: number) => {
        if (createDialog === false && renameDialog === undefined && deleteDialog === undefined && restoreDialog === undefined) {
            if (ableEdit && groupList) {
                const target = groupList.find(el => el.group_id === gid)
                if (target) {
                    setEditName(target.name)
                    setRenameDialog(gid)
                }
            }
        }
    }, [createDialog, renameDialog, deleteDialog, restoreDialog, groupList, ableEdit])

    /**
     * 編集ダイアローグを閉じる
     */
    const handleRenameCanceled = useCallback(() => {
        setRenameDialog(undefined)
    }, [])
    
    /**
     * 編集ダイアローグの完了処理
     */
    const handleRenameDone = useCallback((newName: string) => {
        if (renameDialog && groupList && ableEdit) {
            const target = groupList.find(el => el.group_id === renameDialog)
            if (target) {
                const g: GroupModel = {
                    group_id: target.group_id,
                    name: newName,
                    display_order: target.display_order
                }
                saveGroup(g)
                setEditName("")
            }
            setRenameDialog(undefined)
        }
    }, [groupList, renameDialog, saveGroup, ableEdit])
    
    /**
     * 削除ボタン押下時の処理
     */
    const handleDeleteOpen = useCallback((gid: number) => {
        if (createDialog === false && renameDialog === undefined && deleteDialog === undefined && restoreDialog === undefined) {
            if (ableDelete) setDeleteDialog(gid)
        }
    }, [createDialog, renameDialog, deleteDialog, restoreDialog, ableDelete])

    /**
     * 削除確認ダイアローグを閉じる
     */
    const handleDeleteCanceled = useCallback(() => {
        setDeleteDialog(undefined)
    }, [])
    
    /**
     * 削除確認ダイアローグの完了処理
     */
    const handleDeleteAccepted = useCallback(() => {
        if (!ableDelete) return
        if (deleteDialog) deleteGroup(deleteDialog)
        setDeleteDialog(undefined)
    }, [deleteDialog, deleteGroup, ableDelete])

    /**
     * 復活ボタン押下時の処理
     */
    const handleRestoreOpen = useCallback((gid: number) => {
        if (createDialog === false && renameDialog === undefined && deleteDialog === undefined && restoreDialog === undefined) {
            if (ableEdit) setRestoreDialog(gid)
        }
    }, [createDialog, renameDialog, deleteDialog, restoreDialog, ableEdit])

    /**
     * 復活確認ダイアローグを閉じる
     */
    const handleRestoreCanceled = useCallback(() => {
        setRestoreDialog(undefined)
    }, [])
    
    /**
     * 復活確認ダイアローグの完了処理
     */
    const handleRestoreAccepted = useCallback(() => {
        if (!ableEdit) return
        if (restoreDialog) reactivateGroup(restoreDialog)
        setRestoreDialog(undefined)
    }, [restoreDialog, reactivateGroup, ableEdit])

    /**
     * ドラッグ開始
     */
    const handleDragStart = useCallback((idx: number) => {
        setDragIndex(idx)
    }, [])

    /**
     * ドラッグで行間移動時
     */
    const handleDragEnter = useCallback((idx: number) => {
        if (idx === dragIndex) return
        if (dragIndex !== undefined) {
            changeGroupOrder(dragIndex, idx)
            setDragIndex(idx)
        }
    }, [dragIndex, changeGroupOrder])

    /**
     * ドラッグ終了
     */
    const handleDragEnd = useCallback(() => {
        saveGroupList()
        setDragIndex(undefined)
    }, [saveGroupList])

    const handleRowOver = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const target = event.currentTarget
        target.classList.add(style.rowOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const target = event.currentTarget
        target.classList.remove(style.rowOver)
    }, [])

    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    /**
     * 新規作成ダイアローグ表示
     */
    const createGroupDialog = useMemo(() => {
        if (createDialog) {
            const disabled: boolean = (editName.length === 0)
            const strPlaceholder = t("unregistered")
            return (
                <CustomDialog
                    requestOpen={true}
                    width={520}
                    height={210}
                    guideMessage={t("msgInputCategoryName")}
                    onCancel={handleCreateGroupCanceled}
                    onAccept={handleCreateGroupAccepted}
                    buttonType={AcceptButtonType.Ok}
                    buttonDisabled={disabled}
                >
                    <span className={style.nameInput}><input name="categoryName" type="text" value={editName} onChange={handleChangeEditName} placeholder={strPlaceholder} /></span>
                </CustomDialog>
            )
        }
        return (<></>)
    }, [t, createDialog, editName, handleCreateGroupCanceled, handleCreateGroupAccepted, handleChangeEditName])

    /**
     * 編集ダイアローグ表示
     */
    const renameInputDialog = useMemo(() => {
        if (renameDialog !== undefined && editName) {
            return (
                <RenameDialog
                    requestOpen={true}
                    name={editName}
                    guideMessage={t("msgRenameCategory")}
                    onCancel={handleRenameCanceled}
                    onChange={handleRenameDone}
                />
            )
        }
        return (<></>)
    }, [t, renameDialog, editName, handleRenameCanceled, handleRenameDone])

    /**
     * 削除確認ダイアローグ表示
     */
    const deleteConfirmDialog = useMemo(() => {
        if (groupList && deleteDialog !== undefined) {
            const target = groupList.find(el => el.group_id === deleteDialog)
            if (target) {
                return (
                    <DeleteDialog
                        requestOpen={true}
                        guideMessage={t("msgConfirmDeleteCategory")}
                        paneSize={PaneSizeType.forGroup}
                        onCancel={handleDeleteCanceled}
                        onDelete={handleDeleteAccepted}
                    >
                        <span className={style.deleteConfirm}>{t("categoryName")}：{target.name}</span>
                    </DeleteDialog>
                )
            }
        }
        return (<></>)
    }, [t, groupList, deleteDialog, handleDeleteCanceled, handleDeleteAccepted])

    /**
     * 復活確認ダイアローグ表示
     */
    const restoreGroupDialog = useMemo(() => {
        if (groupList && restoreDialog !== undefined) {
            const target = groupList.find(el => el.group_id === restoreDialog)
            if (target) {
                return (
                    <CustomDialog
                        requestOpen={true}
                        guideMessage={t("msgRestoreCategory")}
                        width={580}
                        height={210}
                        buttonType={AcceptButtonType.Ok}
                        onCancel={handleRestoreCanceled}
                        onAccept={handleRestoreAccepted}
                    >
                        <span className={style.restoreConfirm}>{t("categoryName")}：{target.name}</span>
                    </CustomDialog>
                )
            }
        }
        return (<></>)
    }, [t, groupList, restoreDialog, handleRestoreCanceled, handleRestoreAccepted])

    /**
     * データ行の作成
     */
    const items: React.ReactNode = useMemo(() => {
        const result: any = []
        const titleRename = t("button.edit")
        const titleDelete = t("button.delete")
        const titleRestore = t("button.restore")
        const titleSorting = t("button.sorting")
        if (groupList) {
            groupList.forEach((group, idx) => {
                if (!group.delete_at) {
                    // 生きているGroup
                    const itm = (
                        <tr key={idx}
                            draggable={ableEdit}
                            onDragStart={() => handleDragStart(idx)}
                            onDragEnter={() => handleDragEnter(idx)}
                            onDragOver={event => event.preventDefault()}
                            onDragEnd={handleDragEnd}
                            onMouseOver={handleRowOver}
                            onMouseOut={handleRowOut}
                            className={idx === dragIndex ? style.dragging : ""}
                        >
                            <td className={style.tdId}>
                                { (ableEdit) ? (<button className={style.sorting} title={titleSorting} ><GrabberIcon size={16} /></button>) : (<></>) }
                            </td>
                            <td className={style.tdId}>{group.group_id}</td>
                            <td className={style.tdName}><span className={style.space}>　</span>{group.name}</td>
                            <td className={style.tdId}>
                                { (ableEdit) ? (<button className={style.button} title={titleRename} onClick={() => handleRenameOpen(group.group_id)}><PencilIcon size={16} /></button>) : (<></>) }
                            </td>
                            <td className={style.tdId}>
                                { (ableEdit) ? (<button className={style.button} title={titleDelete} onClick={() => handleDeleteOpen(group.group_id)}><TrashIcon size={16} /></button>) : (<></>) }
                            </td>
                            <td className={style.tdId}></td>
                        </tr>
                    )
                    result.push(itm)
                } else {
                    // 死んでいるGroup
                    if (includingDeleted) {
                        const itm = (
                            <tr key={idx}
                                draggable={ableEdit}
                                onDragStart={() => handleDragStart(idx)}
                                onDragEnter={() => handleDragEnter(idx)}
                                onDragOver={event => event.preventDefault()}
                                onDragEnd={handleDragEnd}
                                className={idx === dragIndex ? style.dragging : style.deline}
                            >
                                <td className={style.tdId}>
                                    { (ableEdit) ? (<span className={style.grabber}><GrabberIcon size={16} /></span>) : (<></>) }
                                </td>
                                <td className={style.tdId}>{group.group_id}</td>
                                <td className={style.tdName}><span className={style.deleted}><CircleSlashIcon size={16} /></span>{group.name}</td>
                                <td className={style.tdId}></td>
                                <td className={style.tdId}></td>
                                <td className={style.tdId}>
                                    { (ableEdit) ? (<button className={style.button} title={titleRestore} onClick={() => handleRestoreOpen(group.group_id)}><IssueReopenedIcon size={16} /></button>) : (<></>) }
                                </td>
                            </tr>
                        )
                        result.push(itm)
                    }
                }
            })
        }
        return result
    }, [dragIndex, groupList, includingDeleted, t, handleDragStart, handleDragEnter, handleDragEnd, handleRenameOpen, handleDeleteOpen, handleRestoreOpen, ableEdit, handleRowOver, handleRowOut])

    return (
        <ListFrame
            page={PageName.InfoGroup}
            title={t("header.categoryEdit")}
            permission={myPermission}
            companySelect={true}
            shopSelect={false}
            newEntry={ableNew}
            copyEntry={false}
            onClickNew={handleCreateGroup}
        >
            <div className={style.main}>
                {isProduction ? (<TrackingPageView page_title="bsinfo-group-list" />) : (null)}
                <div className={style.ctrlRow}>
                    <div className={style.startCol}></div>
                    <div className={style.checkCol}>
                        <CustomCheckbox label="includingDeleted" value="incDel" check={includingDeleted} onChange={handleChangeCheckbox} />
                    </div>
                    <div className={style.endCol}></div>
                </div>
                <table className={style.table}>
                    <thead>
                        <tr>
                            <th className={style.thLink}></th>
                            <th className={style.thId}>ID</th>
                            <th className={style.thName}>{t("categoryName")}</th>
                            <th className={style.thLink}></th>
                            <th className={style.thLink}></th>
                            <th className={style.thLink}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
            {createGroupDialog}
            {renameInputDialog}
            {deleteConfirmDialog}
            {restoreGroupDialog}
        </ListFrame>
    )
}