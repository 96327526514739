import React from "react"
import { TriangleUpIcon, TriangleDownIcon } from '@primer/octicons-react';

import style from "./ListSortButton.module.css"

export const SortStatusType = {
    Release: "rels",
    Ascending: "asc",
    Descending: "desc"
} as const
export type SortStatus = typeof SortStatusType[keyof typeof SortStatusType]

interface Props {
    status: SortStatus
    name: string
    onChange: (newStatus: SortStatus, name: string) => void
}

export const ListSortButton: React.FC<Props> = (props) => {

    const handleAsc = () => {
        if (props.status === SortStatusType.Ascending) {
            props.onChange(SortStatusType.Release, props.name)
        } else {
            props.onChange(SortStatusType.Ascending, props.name)
        }
    }
    
    const handleDesc = () => {
        if (props.status === SortStatusType.Descending) {
            props.onChange(SortStatusType.Release, props.name)
        } else {
            props.onChange(SortStatusType.Descending, props.name)
        }
    }

    return (
        <div className={style.main}>
            <div className={(props.status===SortStatusType.Ascending) ? style.actUp : style.nmlUp} onClick={handleAsc}><TriangleUpIcon size={14} /></div>
            <div className={(props.status===SortStatusType.Descending) ? style.actDwn : style.nmlDwn} onClick={handleDesc}><TriangleDownIcon size={14} /></div>
        </div>
    )
}