import React from 'react'
import { useAuthUserContext } from '../../providers'
import { Permissions } from '../../types/Permissions'

interface Props {
    children: any
    permission?: Permissions
}

export const BeAbleToAccess: React.FC<Props> = (props) => {
    const { summarizeAccessCheck } = useAuthUserContext()

    const check = (props.permission) ? summarizeAccessCheck(props.permission) : true

    if (check) {
        return props.children
    } else {
        return null
    }
}