import React, { useEffect, useState, useRef, useMemo } from "react"
import type { ChartData, ChartArea, ChartOptions } from "chart.js"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, LineController } from 'chart.js'
import { Chart } from "react-chartjs-2"
import { TailSpin } from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import { TxStatus, TxStatusType } from "../../types"
import { StatisticsOfShop, StatisticsOfShopType } from "../../types/Statistics"
import { DisplayTerm, DispTermType } from "./Dashboard"
import Utils from "../../lib/Utils"


import style from "./DashboardLineChart.module.css"

const LINE_COLOR_BLUE = '#1E5EFF'
const LINE_COLOR_GREEN = '#1FD286'

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
)

interface Props {
    dataType: StatisticsOfShop
    termType: DisplayTerm
    shopId: number
    shopName: string
    labels: string[]
    datas: number[]
    status: TxStatus
    onClick: (shopId: number) => void
}

const getNames = (dataType: StatisticsOfShop, termType: DisplayTerm, errMsg: string): string[] => {
    const result = new Array(3)
    if (dataType === StatisticsOfShopType.Visitors) {
        result[0] = "visitors"
    } else if (dataType === StatisticsOfShopType.Buyers) {
        result[0] = "purchasers"
    } else if (dataType === StatisticsOfShopType.BuyRatio) {
        result[0] = "purchaseRatio"
    } else if (dataType === StatisticsOfShopType.SpentTime) {
        result[0] = "averageDwellTime"
    } else if (dataType === StatisticsOfShopType.SalesAmount) {
        result[0] = "sales"
    } else if (dataType === StatisticsOfShopType.NumOfArea) {
        result[0] = "averageDwellArea"
    } else {
        throw new Error(errMsg, dataType)
    }
    result[1] = (termType === DispTermType.DayBefore) ? "prevDay" : "prevWeek"
    result[2] = "％"
    return result
}

const getDefaultValues = (): string[] => {
    return ["-", "-", "-"]
}

const analyzeValueFormat = (dataType: StatisticsOfShop, value: number): string => {
    if (dataType === StatisticsOfShopType.Visitors || dataType === StatisticsOfShopType.Buyers || dataType === StatisticsOfShopType.SalesAmount) {
        // 整数
        return Utils.comma(Math.round(value * 1) / 1)
    } else if (dataType === StatisticsOfShopType.NumOfArea) {
        // カンマあり小数点１位フォーマット
        return Utils.formatCommaPointOne(value)    
    } else if (dataType === StatisticsOfShopType.SpentTime) {
        // 符号付き時間フォーマット
        return Utils.millisecToSignMMSS(value)
    } else {
        // 符号ありカンマあり小数点１位フォーマット
        return Utils.formatSignCommaPointOne(value)
    }
}

const upDownValueFormat = (dataType: StatisticsOfShop, value: number): string => {
    if (dataType === StatisticsOfShopType.Visitors || dataType === StatisticsOfShopType.Buyers || dataType === StatisticsOfShopType.SalesAmount) {
        return Utils.comma(Math.round(value * 1) / 1)
    } else if (dataType === StatisticsOfShopType.NumOfArea) {
        // カンマあり小数点１位フォーマット
        return Utils.formatCommaPointOne(value)    
    } else if (dataType === StatisticsOfShopType.SpentTime) {
        return Utils.millisecToSignMMSS(value)
    } else {
        return Utils.formatSignCommaPointOne(value)
    }
}

const ratioValueFormat = (dataType: StatisticsOfShop, value: number): string => {
    if (dataType === StatisticsOfShopType.Visitors || dataType === StatisticsOfShopType.Buyers) {
        return Utils.comma(Math.round(value * 10) / 10)
    } else {
        return Utils.formatSignCommaPointOne(value)
    }
}

export const DashboardLineChart: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    const chartRef = useRef<ChartJS<'line', number[], string>>(null)
    const [chartData, setChartData] = useState<ChartData<'line'>>({ datasets: [] })
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const graphRef = useRef<HTMLDivElement>(null)

    const options: ChartOptions<'line'> = useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: { display: false },
            },
            interaction: {
                mode: 'index',
                intersect: false
            },
            layout: {
                padding: { left: 5, right: 0, top: 10, bottom: 10 }
            },
            elements: {
                point: { radius: 0 },
                line: { borderWidth: 1, tension: 0 }
            },
            scales: {
                x: {
                    ticks: { display: false },
                    grid: { display: false, drawBorder: false },
                },
                y: {
                    ticks: { display: false },
                    grid: { display: false, drawBorder: false },
                }
            }
        }
    }, [])
	const errMsg = t("msgUnknownDataType")
    let isHotColor: boolean = true
    let [analyzeName, upDownName, ratioName] = useMemo(() => { return getNames(props.dataType, props.termType, errMsg) }, [props, errMsg])
    let [analyzeValue, upDownValue, ratioValue] = useMemo(() => { return getDefaultValues() }, [])
    if (props.status === TxStatusType.Fulfilled) {
        const len = props.datas.length
        if (len > 0) {
            const last = props.datas[len - 1]
            const prev = props.datas[len - 2]
            analyzeValue = analyzeValueFormat(props.dataType, last)
            const diff = last - prev
            if (diff < 0) isHotColor = false
            upDownValue = upDownValueFormat(props.dataType, prev)
            if (prev) ratioValue = ratioValueFormat(props.dataType, (diff / prev) * 100)
        }
        if (isLoading) setIsLoading(false)
    } else {
        if (!isLoading) setIsLoading(true)
    }

    const handleClick = () => {
        props.onClick(props.shopId)
    }

    const handleHoverZoomUp = () => {
        if (graphRef.current) {
            graphRef.current.style.transform = "scale(1.1)"
            graphRef.current.style.transition = "transform 0.5s"
        }
    }

    const handleHoverZoomReset = () => {
        if (graphRef.current) {
            graphRef.current.style.transform = "scale(1)"
            graphRef.current.style.transition = "transform 0.5s"
        }
    }

    useEffect(() => {
        const chart = chartRef.current
        if (chart) {
            let isHot: boolean = true
            if (props.status === TxStatusType.Fulfilled) {
                const len = props.datas.length
                if (len > 0) {
                    const last = props.datas[len - 1]
                    const prev = props.datas[len - 2]
                    if (last < prev) isHot = false
                }
            }
            const ctx: CanvasRenderingContext2D = chart.ctx
            const area: ChartArea = chart.chartArea
            const halfWidth =  (area.right - area.left) / 2
            const gradient = ctx.createLinearGradient(halfWidth, area.top, halfWidth, area.bottom)
            const borderCol: string = (isHot) ? LINE_COLOR_GREEN : LINE_COLOR_BLUE
            gradient.addColorStop(0, borderCol)
            gradient.addColorStop(1, 'white')
            const chartDts = {
                labels: props.labels,
                datasets: [{
                    label: t(analyzeName),
                    backgroundColor: gradient,
                    borderColor: borderCol,
                    data: props.datas,
                    fill: true,
                }]
            }
            setChartData(chartDts)
        }
    }, [analyzeName, props.status, props.datas, props.labels, chartRef, t])

    return (
        <div className={style.main} onClick={handleClick} onMouseOver={handleHoverZoomUp} onMouseOut={handleHoverZoomReset} ref={graphRef}>
            <div className={style.title}>{Utils.textEllipsis(props.shopName,24)}</div>
            <div className={style.table}>
                <table className={style.tableMain}>
                    <tbody>
                        <tr>
                            <td className={style.dataName}>{t(analyzeName)}</td>
                            <td className={style.dataValue}>{analyzeValue}</td>
                        </tr>
                        <tr>
                            <td className={style.dataName}>{t(upDownName)}</td>
                            <td className={(isHotColor) ? style.dataValueHot : style.dataValueCold}>{upDownValue}</td>
                        </tr>
                        <tr>
                            <td className={style.dataName}>{ratioName}</td>
                            <td className={(isHotColor) ? style.dataValueHot : style.dataValueCold}>{ratioValue}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                (isLoading) ? (
                    <div className={style.spiner}>
                        <TailSpin color="#1E5EFF" width={50} height={50} />
                    </div>
                ) : (
                    <div className={style.graph}>
                        <Chart ref={chartRef} type="line" data={chartData} options={options} />
                    </div>
                )
            }            
        </div>
    )
}