/**
 * エリアの種類を表す型。
 * normal - 通常のエリア
 * clerk - 店員エリア。ここに立ち入ると店員とみなされる
 * cacher - 購入エリア。この中に一定時間以上継続して存在すると購入者とみなされる
 * changingroom - 更衣室エリア。更衣室そのものに限らず、カメラが設置できないエリア
 */
export const AreaCountingType = {
    Normal: "normal",
    Clerk: "clerk",
    Casher: "casher",
    ChangingRoom: "changingroom",
} as const
export type AreaType = typeof AreaCountingType[keyof typeof AreaCountingType]

/**
 * ラインの種別を表す型。
 * enter - 入店ライン
 * exit - 退店ライン
 * pass - 通過ライン
 */
export const LineCountingType = {
    Enter: "enter",
    Exit: "exit",
    Pass: "pass",
} as const
export type LineType = typeof LineCountingType[keyof typeof LineCountingType]

export const TimeUnitType = {
    Hour: "hour",
    Day: "day",
    Week: "week",
    Month: "month",
} as const
export type TimeUnit = typeof TimeUnitType[keyof typeof TimeUnitType]

export const TimeUnitShortType = {
    Day: "day",
    Week: "week",
} as const
export type TimeUnitShort = typeof TimeUnitShortType[keyof typeof TimeUnitShortType]

export const TargetCustomerType = {
    Buying: "buying",
    NotBuying: "not_buying",
    All: "all",
} as const
export type TargetCustomer = typeof TargetCustomerType[keyof typeof TargetCustomerType]

// DBのuerTypeと同じ（ただしDeveloperはmock用）
export const RoleType = {
    Root: 'root',                   // DataSection開発者
    Developer: 'developer',         // DataSectionの開発者
    HeadOffice: 'headoffice',       // 企業側管理者
    AreaManager: 'areamanager',     // エリアマネージャー
    StoreManager: 'storemanager',   // シニア店長
    JuniorManager: 'juniormanager', // ジュニア店長
    Staff: 'staff',                 // 店員
    //JuniorStaff: 'juniorstaff'      // ジュニア店員
} as const
export type Role = typeof RoleType[keyof typeof RoleType]

// 曜日
export type weekdaysType = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
export const WEEKDAYS: weekdaysType[]= [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
