import React, { useState, useMemo } from "react"
import { DateRangePicker, NullableDateChangeHandler, TimePicker } from "react-next-dates";
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { ja } from 'date-fns/locale'

import { Array7 } from "../../component";
import { WeekdaysSelector } from "../../component/weekdays_selector/WeekdaysSelector"
import { ThresholdTimepicker } from "../../component/threshold_timepicker/ThresholdTimepicker"
import { useAuthUserContext } from "../../providers"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { AnalyzeView, AnalyzeViewType, CompareSide, CompareSideType } from "../../types/Analyze"
import { FunnelParamSetType } from "./Funnel"
import { TimeErrorType, ERR_REVERSE_TIME } from "./AnalyzeParameter"
import DateUtil from "../../lib/DateUtil"
import { STR_YMD_FORMAT } from "../../constants";

import style from "./FunnelSelector.module.css"

interface Props {
    view: AnalyzeView
    side: CompareSide
}

export const FunnelSelector: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const { userInfo } = useAuthUserContext()
    const { funnelParams, funnelUpdateParam } = useAnalyticsDataContext()

    const [timeError, setTimeError] = useState<TimeErrorType>({message: undefined})

    const ownParam = useMemo(() => { return (props.side === CompareSideType.Primary) ? funnelParams.single : funnelParams.compare }, [props.side, funnelParams])
    const cloneParam = useMemo(() => { return { ...ownParam } }, [ownParam])
    const shop = useMemo(() => { return (userInfo && userInfo.shops && ownParam.shopId) ? userInfo.shops.find(el => el.shop_id === ownParam.shopId) : undefined }, [userInfo, ownParam.shopId])
    //const layout = useMemo(() => { return (shop && ownParam.layoutId) ? shop.layout_set.hot.layout.find(el => el.layout_id === ownParam.layoutId) : undefined }, [shop, ownParam.layoutId])
    const startDate = useMemo(() => { return ownParam.startDate ? new Date(ownParam.startDate) : undefined }, [ownParam.startDate])
    const endDate = useMemo(() => { return ownParam.endDate ? new Date(ownParam.endDate) : undefined }, [ownParam.endDate])
    const isPrimaryOfCompare = useMemo(() => {
        return props.view === AnalyzeViewType.Compare && props.side === CompareSideType.Primary
    }, [props.view, props.side])
    const shopSelectOptions = useMemo(() => {
        const elm = (<option key="shop-select-x" value={0}>{t("msgSelectStore")}</option>)
        if (userInfo) {
            const ops = userInfo.shops?.map((el, i) => {
                return (<option key={'shop-select-' + (i + 1)} value={el.shop_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [userInfo, t])
    const selectedShopId = useMemo(() => { return (ownParam.shopId === undefined) ? 0 : ownParam.shopId }, [ownParam.shopId])
    const layoutSelectOptions = useMemo(() => {
        const elm = (<option key="layout-select-x" value={0}>{t("msgSelectMap")}</option>)
        if (shop && shop.hot_layout_set) {
            const ops =  shop.hot_layout_set.layout.map((el, i) => {
                return (<option key={'layout-select-' + (i + 1)} value={el.layout_id}>{el.name + ' ' + format(new Date(el.start), t("dateFormat.ymd_hy")) + ' to ' + format(new Date(el.end), t("dateFormat.ymd_hy"))}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [shop, t])
    const selectedLayoutId = useMemo(() => { return (ownParam.layoutId === undefined) ? 0 : ownParam.layoutId }, [ownParam.layoutId])
    const areaSelectOptions = useMemo(() => {
        const elm = (<option key="area-select-x" value={0}>{t("msgSelectProductAreaTracking")}</option>)
        /*if (layout) {
            const ops = layout.area_list?.map((el, i) => {
                return (<option key={'area-select-' + (i + 1)} value={el.area_id}>{el.area_number + "_" + el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }*/
        return [elm]
    }, [t])
    const selectedAreaId = useMemo(() => { return (ownParam.areaId === undefined) ? 0 : ownParam.areaId }, [ownParam.areaId])
    const productSelectOptions = useMemo(() => {
        const elm = (<option key="product-x" value={0}>{t("msgSelectProductNameTracking")}</option>)
        return [elm]
    }, [t])
    const selectedProductName = useMemo(() => { return (ownParam.productName === undefined) ? 0 : ownParam.productName }, [ownParam.productName])
    
    const validationCheck = (param: FunnelParamSetType):boolean => {
        //バリデーションチェック
        let checkOk = true
        // 店舗とレイアウトが選択されていること
        //console.log(param)
        if (!param.shopId || !param.layoutId) {
            checkOk = false
        }
        // 時間範囲のスタートとエンドの関係
        if (param.startTime && param.endTime && param.startTime > param.endTime) {
            checkOk = false
			const msg = t(ERR_REVERSE_TIME)
            setTimeError({message: msg})
        } else {
            setTimeError({message: undefined})
        }
        //console.log("validationCheck checkOk:", checkOk)
        return checkOk
    }

    const handleChangeStartDate: NullableDateChangeHandler = (dt) => {
        if (dt) {
            //cloneParam.startDate = format(dt, t("dateFormat.ymd_hy"))
            cloneParam.startDate = format(dt, STR_YMD_FORMAT)
            funnelUpdateParam(cloneParam)
        }
    }

    const handleChangeEndDate: NullableDateChangeHandler = (dt) => {
        if (dt) {
            //cloneParam.endDate = format(dt, t("dateFormat.ymd_hy"))
            cloneParam.endDate = format(dt, STR_YMD_FORMAT)
            funnelUpdateParam(cloneParam)
        }
    }

    const handleChangeStartTime: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const jstNumTime = DateUtil.utcDate2UtcNumTime(dt)
            cloneParam.startTime = jstNumTime
            cloneParam.valid = validationCheck(cloneParam)
            funnelUpdateParam(cloneParam)
        }
    }

    const handleChangeEndTime: NullableDateChangeHandler = (dt) => {
        if (dt) {
            const jstNumTime = DateUtil.utcDate2UtcNumTime(dt)
            cloneParam.endTime = jstNumTime
            cloneParam.valid = validationCheck(cloneParam)
            funnelUpdateParam(cloneParam)
        }
    }

    const handleChangeWeekdays = (weekdays: Array7<boolean>): void => {
        cloneParam.weekday = weekdays
        funnelUpdateParam(cloneParam)
    }

    const handleChangeThreshold = (time: number): void => {
        cloneParam.threshold = time
        funnelUpdateParam(cloneParam)
    }

    const handleShopSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        cloneParam.shopId = parseInt(val)
        cloneParam.layoutId = undefined
        cloneParam.valid = validationCheck(cloneParam)
        funnelUpdateParam(cloneParam)
    }

    const handleLayoutSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        cloneParam.layoutId = parseInt(val)
        // 日付範囲を変更する
        if (cloneParam.shopId) {
            const shop = userInfo?.shops.find(el => el.shop_id === cloneParam.shopId)
            if (shop && shop.hot_layout_set) {
                const layout = shop?.hot_layout_set.layout?.find(el => el.layout_id === cloneParam.layoutId)
                const stMs = layout?.start
                const edMs = layout?.end
                if (stMs) {
                    cloneParam.startDate = DateUtil.epocMilli2JstYmd(stMs)
                }
                if (edMs) {
                    cloneParam.endDate = DateUtil.epocMilli2JstYmd(edMs)
                }
            }
        }
        cloneParam.valid = validationCheck(cloneParam)
        funnelUpdateParam(cloneParam)
    }

    const handleAreaSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        cloneParam.areaId = parseInt(val)
        funnelUpdateParam(cloneParam)
    }

    const handleProductSelected = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const val = event.target.value
        cloneParam.productName = val
        funnelUpdateParam(cloneParam)
    }

    let title: React.ReactElement
    if (props.view === AnalyzeViewType.Compare) {
        title = props.side === CompareSideType.Secondary ? <span><span className={style.greenCircle}></span>{t("comparitiveData")}&#9313;</span> : <span><span className={style.blueCircle}></span>{t("comparitiveData")}&#9312;</span>
    } else {
        title = <span></span>
    }

	const dateRangeFormat = t("dateFormat.ymd_comma")
    return (
        <div className={style.pane}>
            {
                (props.view === AnalyzeViewType.Compare) ? (
                <div className={style.subs}>
                    <div className={style.subtitle}>{title}</div>
                </div>
                ): (
                <div></div>
                )
            }
            <div className={(isPrimaryOfCompare) ? style.main2 : style.main}>
                <div className={style.shopSel}>
                    <select className={style.customSelect} value={selectedShopId} onChange={handleShopSelected}>
                        {shopSelectOptions}
                    </select>
                </div>
                <div className={style.mapSel}>
                    <select className={style.customSelect} value={selectedLayoutId} onChange={handleLayoutSelected}>
                        {layoutSelectOptions}
                    </select>
                </div>
                <div className={style.dateRange}>
                    <DateRangePicker
                        locale={ja}
                        startDate={startDate}
                        endDate={endDate}
                        format={dateRangeFormat}
                        onStartDateChange={handleChangeStartDate}
                        onEndDateChange={handleChangeEndDate}
                    >
                        {({ startDateInputProps, endDateInputProps }) => (
                            <>
                                <input {...startDateInputProps} className={style.startDateInput} />
                                <div className={style.connect}>－</div>
                                <input {...endDateInputProps} className={style.endDateInput} />
                            </>
                        )}
                    </DateRangePicker>
                </div>
                <div className={style.areaSel}>
                    <select className={style.customSelect} value={selectedAreaId} onChange={handleAreaSelected}>
                        {areaSelectOptions}
                    </select>
                </div>
                <div className={style.productSel}>
                    <select className={style.customSelect} value={selectedProductName} onChange={handleProductSelected}>
                        {productSelectOptions}
                    </select>
                </div>
                <div className={style.timeRange}>
                    <TimePicker
                        locale={ja}
                        date={DateUtil.jstNumTime2UtcDate(ownParam.startTime)}
                        onChange={handleChangeStartTime}
                    >
                        {({ inputProps }) => <input {...inputProps} className={style.startTimeInput} />}
                    </TimePicker>
                    <div className={style.connect}>－</div>
                    <TimePicker
                        locale={ja}
                        date={DateUtil.jstNumTime2UtcDate(ownParam.endTime)}
                        onChange={handleChangeEndTime}
                    >
                        {({ inputProps }) => <input {...inputProps} className={style.endTimeInput} />}
                    </TimePicker>
                </div>
                <div className={style.timeErrMsg}>
                    {
                        (timeError.message)
                            ? (
                                <div>{timeError.message}</div>
                            )
                            : (
                                <></>
                            )
                    }
                </div>
                <div className={style.weekSel}>
                    <WeekdaysSelector
                        weekdays={ownParam.weekday} onChange={handleChangeWeekdays}
                    />
                </div>
                <div className={style.threshold}>
                    <ThresholdTimepicker
                        thresholdTime={ownParam.threshold}
                        interval={5}
                        min={0}
                        max={300}
                        onChange={handleChangeThreshold}
                    />
                </div>
            </div>
        </div>
    )
}