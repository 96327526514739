import { getAreaList } from "./AreaData";
import { ResLayout } from "../../api/data/analysis/FullLayout";

const getLayout01 = ():ResLayout => {
    const areas = getAreaList(1)
    return {
        layout_id: 1,
        name: "原宿店のレイアウトNew",
        start: 0,
        //end: "",
        image: "./layout-image/harajuku.2022-09-07.jpg",
        pixel_width: 600,
        pixel_height: 600,
        origin_x: 58,
        origin_y: 49,
        mm_per_pixel: 38.24,
        area_unitcell_pixel: 3.9748954,
        /*cell_length_mm:152,*/
        //display_order: 1,
        area_set: [{ area_set_id: 1000, start: "2022/05/01", area_defs: areas, inserttime: 1651390268000, updatetime: 1651390268000 }],
        line_set: [],
    }
}

const getLayout10 = ():ResLayout => {
    const areas = getAreaList(1)
    return {
        layout_id: 10,
        name: "原宿店のレイアウトOld",
        start: 0,
        //end: "",
        image: "./layout-image/harajuku.2022-09-07.jpg",
        pixel_width: 600,
        pixel_height: 600,
        origin_x: 58,
        origin_y: 49,
        mm_per_pixel: 38.24,
        area_unitcell_pixel: 3.9748954,
        /*cell_length_mm:152,*/
        //display_order: 1,
        area_set: [{ area_set_id: 1001, start: "2022/03/01", area_defs: areas, inserttime: 1646119868000, updatetime: 1646119868000 }],
        line_set: [],
    }
}

const getLayout03 = ():ResLayout => {
    const areas = getAreaList(21)
    return {
        layout_id: 3,
        name: "博多店のレイアウト",
        start: 0,
        //end: "",
        image: "./layout-image/hakata.2022-09-07.jpg",
        pixel_width: 720,
        pixel_height: 506,
        origin_x: 37,
        origin_y: 32,
        mm_per_pixel: 33.64,
        area_unitcell_pixel: 3.983353,
        /*cell_length_mm: 134,*/
        //display_order: 11,
        area_set: [{ area_set_id: 1002, start: "2022/05/01", area_defs: areas, inserttime: 1651367280000, updatetime: 1651367280000 }],
        line_set: [],
    }
}

const getLayout31 = ():ResLayout => {
    const areas = getAreaList(21)
    return {
        layout_id: 31,
        name: "博多店のレイアウトLast",
        start: 0,
        //end: "",
        image: "./layout-image/hakata.2022-09-07.jpg",
        pixel_width: 720,
        pixel_height: 506,
        origin_x: 37,
        origin_y: 32,
        mm_per_pixel: 33.64,
        area_unitcell_pixel: 3.983353,
        /*cell_length_mm: 134,*/
        //display_order: 11,
        area_set: [{ area_set_id: 1003, start: "2022/01/01", area_defs: areas, inserttime: 1640999280000, updatetime: 1640999280000 }],
        line_set: [],
    }
}

const getLayout02 = ():ResLayout => {
    const areas = getAreaList(22)
    return {
        layout_id: 2,
        name: "池袋店の初期レイアウト2205",
        start: 0,
        //end: "",
        image: "./layout-image/ikebukuro.2022-07-27.png",
        pixel_width: 1134,
        pixel_height: 615,
        origin_x: 83,
        origin_y: 61,
        mm_per_pixel: 37,
        area_unitcell_pixel: 3.027027,
        /*cell_length_mm: 112,*/
        //display_order: 8,
        area_set: [{ area_set_id: 1000, start: "2022/05/01", area_defs: areas, inserttime: 1651367280000, updatetime: 1651367280000 }],
        line_set: [],
    }
}

const getLayout20 = ():ResLayout => {
    //const areas = getAreaList(22)
    return {
        layout_id: 20,
        name: "池袋店の初期レイアウト2207",
        start: 0,
        //end: "",
        image: "./layout-image/ikebukuro.2022-07-27.png",
        pixel_width: 1134,
        pixel_height: 615,
        origin_x: 83,
        origin_y: 61,
        mm_per_pixel: 37,
        area_unitcell_pixel: 3.027027,
        /*cell_length_mm: 112,*/
        //display_order: 8,
        area_set: [],
        line_set: [],
    }
}

export const get_full_layout = (layout_id: number): ResLayout => {
    switch (layout_id) {
        case 1:
            return getLayout01()
        case 2:
            return getLayout02()
        case 3:
            return getLayout03()
        case 10:
            return getLayout10()
        case 20:
            return getLayout20()
        case 31:
            return getLayout31()
        default:
            throw new Error("未定義のlayout_idです。[" + layout_id + "]")
    }
}

export const getLayoutList = (shopId: number): ResLayout[] => {
    //const areas = getAreaList(shopId)
    if (shopId === 1) {
        return [getLayout01(), getLayout10()]
        
    } else if (shopId === 21) {
        return [getLayout03(), getLayout31()]
        
    } else if (shopId === 22) {
        return [getLayout02(), getLayout20()]
        
    } else {
        return []

    }
}