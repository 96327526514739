import MapWriter from "./MapWriter"
import { HEATMAP_COLORS, getAreaBorderColor } from "./ColorUtil"
import { PX_LABEL_RADIUS } from "./MapWriter"
import { AreaCount, Heatmap } from "../api/data/analysis/AnalysisResult"
import { DashboardAreaCount } from "../api/data/dashboard/DashboardResult"


class HeatMapWriter extends MapWriter {

    /**
     * ヒートマップの色を取得します。
     * @param value 
     * @param maxValue 
     * @returns 
     */
    getColor(value: number, maxValue: number): string {
        if (Number.isFinite(value) && Number.isFinite(maxValue)) {
            if (value > maxValue || maxValue === 0) {
                return ""
            } else {
                const step = maxValue / HEATMAP_COLORS.length
                if (step > 0) {
                    let idx = Math.floor(value / step)
                    if (idx === HEATMAP_COLORS.length) idx = HEATMAP_COLORS.length - 1
                    return HEATMAP_COLORS[idx]
                } else {
                    return ""
                }
            }
        }
        return ""
    }

    /**
     * エリア番号を描画します。
     * @param ctx 
     * @param mmX 
     * @param mmY 
     * @param text 
     */
    async drawLabel(ctx: CanvasRenderingContext2D, mmX: number | undefined, mmY: number | undefined, color: string, text: string) {
        if (mmX !== undefined && mmY !== undefined) {
            //console.log("drawLabel")
            const pt = this.toPixelCoord(mmX, mmY)
            await this.drawAreaNumber(ctx, pt.x, pt.y, PX_LABEL_RADIUS, color, text)
        }
    }

    /**
     * 四角形を描画します。
     * @param ctx 
     * @param minX 
     * @param minY 
     * @param maxX 
     * @param maxY 
     * @param color 
     */
    async paintRect(ctx: CanvasRenderingContext2D, minX: number | undefined, minY: number | undefined, maxX: number | undefined, maxY: number | undefined, color: any) {
        if (color === "") return
        if (minX !== undefined && minY !== undefined && maxX !== undefined && maxY !== undefined) {
            ctx.fillStyle = color
            ctx.beginPath()
            const stPt = this.toPixelCoord(minX, minY)
            const edPt = this.toPixelCoord(maxX, maxY)
            ctx.fillRect(stPt.x, stPt.y, edPt.x - stPt.x, edPt.y - stPt.y)
        }
    }

    /**
     * 全てのエリアのヒートマップを描画します。
     * @param ctx 
     * @param counter 
     */
    async drawAreaHeatMap(ctx: CanvasRenderingContext2D, counter: AreaCount[]) {
        const maxVal = (counter.length > 0) ? counter.map(el => el.stay_count).reduce((a, b) => (a > b) ? a : b) : 1000
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        await this.drawImage(ctx)
        for await (let ac of counter) {
            const lines = this.convertCells2DrawLines(ac.area.cell_ids)
            if (lines.length > 0) {
                const val = ac.stay_count
                const color = this.getColor(val, maxVal)
                const borderColor = getAreaBorderColor(ac.area.area_type)
                const [minX, minY, maxX, maxY] = await this.getFourCorners(lines)
                await this.paintRect(ctx, minX, minY, maxX, maxY, color)
                await this.drawAreaBorder(ctx, lines, borderColor)
                await this.drawLabel(ctx, minX, minY, borderColor, "" + ac.area.area_number)
            }            
        }
        ctx.restore()
    }

    /**
     * 全てのエリアのヒートマップを描画します。（個別ダッシュボード用）
     * @param ctx 
     * @param counter 
     */
    async drawAreaHeatMap4Dashboard(ctx: CanvasRenderingContext2D, counter: DashboardAreaCount[]) {
        const areaCounts = counter.map(el => {
            return {
                area: el.area,
                stay_count: el.stay_count,
                pass_count: 0,
                total_count: 0,
                stay_rate: 0,
                pass_rate: 0,
            } as AreaCount
        })
        this.drawAreaHeatMap(ctx, areaCounts)
    }

    /**
     * タイル状に描画します。
     * @param ctx 
     * @param datas 
     */    
    async paintTiles(ctx: CanvasRenderingContext2D, datas: Heatmap, colSize: number) {
        //console.log("datas",datas)
        const rowSize = datas.counts.length
        for (let py = 0; py < rowSize; py++) {
            for (let px = 0; px < colSize; px++) {
                //console.log("py,px:",py,px)
                const dt = datas.counts[py][px]
                const color = this.getColor(dt, datas.max)
                if (color !== "") {
                    const x1 = px * this.area_unitcell_pixel + this.origin_x + this.vtLeft
                    const y1 = py * this.area_unitcell_pixel + this.origin_y + this.vtTop
                    ctx.beginPath()
                    ctx.strokeStyle = color
                    ctx.fillStyle = color
                    ctx.fillRect(x1, y1, this.area_unitcell_pixel, this.area_unitcell_pixel)
                    ctx.stroke()
                }
            }
        }
    }

    /**
     * マップ全域のヒートマップを描画します。
     * @param ctx 
     * @param datas 
     */
    async drawFullRangeHeatMap(ctx: CanvasRenderingContext2D, datas: Heatmap, colSize: number) {
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        await this.drawImage(ctx)
        // ヒートマップ描画
        await this.paintTiles(ctx, datas, colSize)
        if (this.area_list !== null) {
            for await (let area of this.area_list) {
                const lines = this.convertCells2DrawLines(area.cell_ids)
                if (lines.length > 0) {
                    const [minX, minY] = await this.getFourCorners(lines)
                    const color = getAreaBorderColor(area.area_type)
                    await this.drawAreaBorder(ctx, lines, color)
                    await this.drawLabel(ctx, minX, minY, color, "" + area.area_number)
                }
            }
        }
        ctx.restore()
    }
}

export default HeatMapWriter
