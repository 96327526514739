import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { TailSpin } from "react-loader-spinner"
import { format } from "date-fns"
import { ChevronRightIcon } from "@primer/octicons-react"

import { CustomerForTrail } from "../../api/data/analysis/AnalysisResult"
import { Checked, CheckedState, ThreeStateCheckbox } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { ListSortButton, SortStatus, SortStatusType } from "../../component/list_sort_button/ListSortButton"
import { SortingType, SortColumnsType } from "./TrailMap"
import Utils from "../../lib/Utils"

import style from "./TrailMapList.module.css"

export const ListViewModeType = {
    short: "short",
    long: "long"
} as const
export type ListViewMode = typeof ListViewModeType[keyof typeof ListViewModeType]

interface ListProps {
    viewMode: ListViewMode                      // 表示モード
    linesOfPage: number                         // 1ページあたりの表示行数
    customers: CustomerForTrail[] | undefined   // 表示する顧客リスト
    changeIdList: (idList: number[]) => void    // 動線描画IDリストの変更
    changeHighlight: (onId: number | undefined) => void   // ハイライトIDの変更
    isSingle: boolean                           // シングルモード（スタイル変更目的）
    sorting: SortingType                        // ソート状態
    changeSorting: (sorting: SortingType) => void   // ソート状態の変更
    bulkCheckbox: Checked                       // ３ステートチェックボックスの状態
    changeBulkCheckbox: (blk: Checked) => void  // ３ステートチェックボックスの状態変更
    checkedList: Record<number, boolean> | undefined    // チェックリスト
    changeCheckedList: (checkList: Record<number, boolean>) => void // チェックリストの変更
    currentPage: number                         // 現在のページ
    isBusy: boolean                             // 親コンポーネントの処理中
    isLoading: boolean                          // 顧客リストのデータ取得中
    fireIds: number[]                           // 赤色表示する顧客IDリスト
    noSortOfStayAreaCount: boolean              // 滞在エリア数のソートを無効にする(ALLの場合,OpenSearchのインデックスの仕様上の制約)
    showDetail?: (humanId: number) => void      // 顧客詳細画面を表示する(viewMode=longの場合のみ)
}

export const TrailMapList: React.FC<ListProps> = (props) => {
    const { t } = useTranslation()

    /**
     * 顧客の詳細画面を表示します。
     * @param id 
     */
    const handleOpenDetail = useCallback((id: number) => {
        if (props.showDetail) props.showDetail(id)
    }, [props])
    
    /**
     * ソートボタンのクリックイベント
     * @param newStatus 
     * @param name 
     */
    const handleSortAction = useCallback((newStatus: SortStatus, name: string) => {
        let newSorting: SortingType = {
            id: SortStatusType.Release,
            startTime: SortStatusType.Release,
            endTime: SortStatusType.Release,
            stayTime: SortStatusType.Release,
            stayArea: SortStatusType.Release,
            buyOrNot: SortStatusType.Release
        }
        if (name === SortColumnsType.id) {
            newSorting.id = newStatus
        } else if (name === SortColumnsType.startTime) {
            newSorting.startTime = newStatus
        } else if (name === SortColumnsType.endTime) {
            newSorting.endTime = newStatus
        } else if (name === SortColumnsType.stayTime) {
            newSorting.stayTime = newStatus
        } else if (name === SortColumnsType.stayArea) {
            newSorting.stayArea = newStatus
        } else if (name === SortColumnsType.buyOrNot) {
            newSorting.buyOrNot = newStatus
        }
        //console.log("handleSortAction: newStatus=", newStatus, ", name=", name)
        props.changeSorting(newSorting)
    }, [props])
    
    /**
     * 選択中の動線をハイライト処理
     * @param id 
     */
    const handleHighLight = useCallback((id: number) => {
        if (props.checkedList) {
            // 親へ伝達
            if (props.checkedList[id]) props.changeHighlight(id)
        }
    }, [props])

    /**
     * ハイライト処理の解除
     * @param id 
     */
    const handleLowLight = useCallback((id: number) => {
        if (props.checkedList) {
            // 親へ伝達
            if (props.checkedList[id]) props.changeHighlight(undefined)
        }
    }, [props])

    /**
     * 指定されたチェックリストから動線描画IDリストを生成します。
     * @param checked 
     * @returns 
     */
    const getDrawIdList = useCallback(async (checked: Record<number, boolean>) => {
        const idList: number[] = []
        if (props.customers) {
            const ks = props.customers.map(el => el.human_id as number)
            if (ks) {
                for await (let id of ks) {
                    if (checked[id] === true) idList.push(id)
                }
            }
        }
        return idList
    }, [props.customers])
    
    /**
     * 指定されたページのCheckboxを調べてスリーステートチェックの値を取得します。
     * @param page 
     * @returns 
     */
    const getBulkStatus = useCallback(async (page: number, chkList: Record<number, boolean>): Promise<Checked> => {
        if (props.customers && props.customers.length > 0) {
            const idls = props.customers.map(elm => { return elm.human_id })
            let cnt: number = 0
            for await (let id of idls) {
                if (chkList[id]) cnt++
            }
            if (cnt === idls.length) {
                return CheckedState.Full
            } else if (cnt === 0) {
                return CheckedState.None
            } else {
                return CheckedState.PartOf
            }
        }
        return CheckedState.None
    }, [props.customers])

    /**
     * 指定されたチェックボックスの更新処理
     * @param event 
     */
    const handleCheckbox = useCallback((event: CustomCheckboxEvent) => {
        if (!props.isBusy) {
            // チェックリストの更新・伝達
            const id = parseInt(event.target.value)
            const neoCheckedList = { ...props.checkedList }
            neoCheckedList[id] = (!!!neoCheckedList[id])
            props.changeCheckedList(neoCheckedList)
            // ３ステートチェックボックスの更新
            getBulkStatus(props.currentPage, neoCheckedList).then(bulk => {
                props.changeBulkCheckbox(bulk)
                //console.log("handleCheckbox: bulk=", bulk)
            })
            // 動線描画IDリストの更新・伝達
            getDrawIdList(neoCheckedList).then(idList => {
                props.changeIdList(idList)
                //console.log("handleCheckbox: idList=", idList)
            })
        }
    }, [props, getBulkStatus, getDrawIdList])

    /**
     * 表示されている全てのチェックボックスを指定された状態(on/off)にします。
     * ・チェックリストを更新・伝達
     * ・動線描画IDリストを更新・伝達
     * @param newState 
     */
    const checkboxSwitchAllOfPage = useCallback(async (newState: boolean): Promise<void> => {
        if (props.customers && props.checkedList) {
            // チェックリストを再作成
            const neoCheckedList = { ...props.checkedList }
            //const idls = await getIdListWith(props.currentPage)
            const idls = props.customers.map(elm => { return elm.human_id })
            for await (let id of idls) {
                neoCheckedList[id] = newState
            }
            //すべてのチェックボックスを変更
            props.changeCheckedList(neoCheckedList)
            // 動線描画するIDリストを更新・伝達
            const idList = await getDrawIdList(neoCheckedList)
            props.changeIdList(idList)
        }
    }, [props, getDrawIdList])

    /**
     * スリーステートチェックボックス押下処理
     * ・チェックリストを更新（動線描画IDリストの更新）
     */
    const handleBulkClick = useCallback(() => {
        if (!props.isBusy && props.customers) {
            if (props.bulkCheckbox === CheckedState.None) {
                // 全てONにする
                checkboxSwitchAllOfPage(true)
                props.changeBulkCheckbox(CheckedState.Full)
            } else {
                // 全てOFFにする
                checkboxSwitchAllOfPage(false)
                props.changeBulkCheckbox(CheckedState.None)
            }
        }
    }, [props])

    /**
     * 顧客IDリストテーブルのヘッダー
     * @returns 
     */
    const tableHeader = useMemo(() => {
        if (props.customers === undefined) return null
        if (props.viewMode === ListViewModeType.short) {
            return (
                <tr className={style.tableRow}>
                    <th className={style.thBulk}>
                        <ThreeStateCheckbox
                            label={t("select")}
                            checked={props.bulkCheckbox}
                            onClick={handleBulkClick}
                        />
                    </th>
                    <th className={style.thId}>
                        <div className={style.thPack}>
                            <div className={style.thLabel}>{t("customerId")}</div>
                            <ListSortButton name={SortColumnsType.id} status={props.sorting.id} onChange={handleSortAction} />
                        </div>
                    </th>
                </tr>
            )
        }
        return (
            <tr className={style.tableRow}>
                <th className={style.thBulk}>
                    <ThreeStateCheckbox
                        label={t("select")}
                        checked={props.bulkCheckbox}
                        onClick={handleBulkClick}
                    />
                </th>
                <th className={style.thId}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("customerId")}</div>
                        <ListSortButton name={SortColumnsType.id} status={props.sorting.id} onChange={handleSortAction} />
                    </div>
                </th>
                <th className={style.thDatetime}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("entryTime")}</div>
                        <ListSortButton name={SortColumnsType.startTime} status={props.sorting.startTime} onChange={handleSortAction} />
                    </div>
                </th>
                <th className={style.thDatetime}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("exitTime")}</div>
                        <ListSortButton name={SortColumnsType.endTime} status={props.sorting.endTime} onChange={handleSortAction} />
                    </div>
                </th>
                <th className={style.thTime}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("stayingTime")}</div>
                        <ListSortButton name={SortColumnsType.stayTime} status={props.sorting.stayTime} onChange={handleSortAction} />
                    </div>
                </th>
                <th className={style.thNum}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("areasToStay")}</div>
                        {props.noSortOfStayAreaCount ? null : (
                            <ListSortButton name={SortColumnsType.stayArea} status={props.sorting.stayArea} onChange={handleSortAction} />
                        )}
                    </div>
                </th>
                <th className={style.thBuy}>
                    <div className={style.thPack}>
                        <div className={style.thLabel}>{t("procurement")}</div>
                        <ListSortButton name={SortColumnsType.buyOrNot} status={props.sorting.buyOrNot} onChange={handleSortAction} />
                    </div>
                </th>
                <th className={style.thLink}>Data</th>
            </tr>
        )
    }, [props, handleBulkClick, handleSortAction, t])

    /**
     * 顧客IDリストテーブルのデータ行
     * @returns 
     */
    const tableItemRows = useMemo(() => {
        //console.log("tableItemRows")
        if (props.customers === undefined) return null
        if (props.checkedList === undefined) {
            // チェックリストが未設定の場合、初期化
            const newCheckedList: Record<number, boolean> = {}
            props.customers.forEach(cust => {
                newCheckedList[cust.human_id] = false
            })
            props.changeCheckedList(newCheckedList)
            //console.log("チェックリストが未設定の場合、初期化 : newCheckedList=", newCheckedList)
            return null
        }
        if (props.viewMode === ListViewModeType.short) {
            //console.log("tableItemRows: short")
            return props.customers.map(cust => {
                const isFire = props.fireIds.includes(cust.human_id)
                const styleClass = `${cust.is_clerk ? style.tableClerkRow : style.tableDataRow} ${isFire ? style.tableFireRow : ""}`
                return (
                    <tr className={styleClass} key={cust.human_id} onMouseOver={e => handleHighLight(cust.human_id)} onMouseOut={e => handleLowLight(cust.human_id)}>
                        <td className={style.tdChk}>
                            <CustomCheckbox label="" value={"" + cust.human_id} check={props.checkedList ? props.checkedList[cust.human_id] : false} onChange={handleCheckbox} />
                        </td>
                        <td className={style.tdHid}>{cust.human_id}</td>
                    </tr>
                )
            })
        }
        //console.log("tableItemRows: long")
        return props.customers.map(cust => {
            const buycode = t(cust.is_buying ? "yes" : "no")
            const isFire = props.fireIds.includes(cust.human_id)
            //console.log("props.fireIds, isFire:", props.fireIds, isFire)
            const styleClass = `${cust.is_clerk ? style.tableClerkRow : style.tableDataRow} ${isFire ? style.tableFireRow : ""}`
            //console.log("styleClass:", styleClass)
            return (
                <tr className={styleClass} key={cust.human_id} onMouseOver={e => handleHighLight(cust.human_id)} onMouseOut={e => handleLowLight(cust.human_id)}>
                    <td className={style.tdChk}>
                        <CustomCheckbox label="" value={"" + cust.human_id} check={props.checkedList ? props.checkedList[cust.human_id] : false} onChange={handleCheckbox} />                        
                    </td>
                    <td className={style.tdHid}>{cust.human_id}</td>
                    <td className={style.tdStr}>{cust.enter_time ? format(cust.enter_time, t("dateFormat.ymd_hy_hms")) : ""}</td>
                    <td className={style.tdEnd}>{cust.exit_time ? format(cust.exit_time, t("dateFormat.ymd_hy_hms")) : ""}</td>
                    <td className={style.tdTme}>{cust.stay_seconds ? Utils.formatMillisecToTime(cust.stay_seconds) : ""}</td>
                    <td className={style.tdCnt}>{cust.stay_area_count}</td>
                    <td className={style.tdBuy}>{buycode}</td>
                    <td className={style.tdDat}><div className={style.dtIcon} onClick={e => handleOpenDetail(cust.human_id)}><ChevronRightIcon size={16} /></div></td>
                </tr>
            )
        })
    }, [t, props, handleCheckbox, handleHighLight, handleLowLight, handleOpenDetail])

    const tableStyle = useMemo(() => {
        if (props.viewMode === ListViewModeType.long) {
            return (props.isSingle) ? style.tablePane : style.tablePaneCmp
        } else {
            return (props.isSingle) ? style.tablePaneShort : style.tablePaneCmpShort
        }
    }, [props.viewMode, props.isSingle])

    return (
        <div className={style.listMain}>
            {
                props.viewMode === ListViewModeType.short ? (
                    <div className={style.head}>
                        <div className={style.title}>
                            <div className={style.titleText}>{t("selectCustomerId")}</div>
                        </div>
                    </div>
                ) : (null)
            }
            <div className={tableStyle}>
                {
                    props.isLoading ? (
                        <div className={style.spiner}>
                            <TailSpin color="#1E5EFF" width={50} height={50} />
                        </div>
                    ): (
                        <table className={style.table}>
                            <thead>
                                {tableHeader}
                            </thead>
                            <tbody>
                                {tableItemRows}
                            </tbody>
                        </table>                            
                    )
                }
                {(props.customers?.length === 0) ? (<div className={style.noData}>{t("msgNoFound")}</div>) : (null)}
            </div>
        </div>
    )
}