import { CompareSide, CompareSideType } from "../../types/Analyze"

// 処理の種類
export const JobTypes = {
    Total: "total",         // 顧客合計人数
    Layout: "layout",       // レイアウト
    AreaCount: "areaCount",         // エリア別滞在数
    AreaCountZero: "areaCountZero", // エリア別滞在数（閾値なし）
    Trails: "trails",       // 動線（顧客リスト）
    SamplingAsc: "samplingAsc",   // サンプリング
    SamplingDesc: "samplingDesc",   // サンプリング
    LongStay: "longStay",   // エリア滞在時間別
    ManyStay: "manyStay",   // エリア滞在数別
    TimeLineHour: "timeLineHour",   // タイムライン（時間）
    TimeLineDay: "timeLineDay",     // タイムライン（日）
    TimeLineWeek: "timeLineWeek",   // タイムライン（週）
    TimeLineMonth: "timeLineMonth", // タイムライン（月）
} as const
export type Jobs = typeof JobTypes[keyof typeof JobTypes]

const TrackIdType = {
    Primary: 0,
    AreaCount: 1,
    Trail: 2,
    SampleA: 3,
    SampleB: 4,
    Normal: 5
} as const
type TrackId = typeof TrackIdType[keyof typeof TrackIdType]

type JobDefinitonType = {
    track: TrackId
    job: Jobs
    weightBase: number    // 基本重み
    weightSlope: number     // 顧客数比の重み
    concurrent?: boolean      // 同一トラック内のJOBを並列化する場合true
}

const JobDefiniton: JobDefinitonType[] = [
    { track: TrackIdType.Primary, job: JobTypes.Total, weightBase: 300, weightSlope: 0 },
    { track: TrackIdType.Primary, job: JobTypes.Layout, weightBase: 1500, weightSlope: 0 },
    { track: TrackIdType.AreaCount, job: JobTypes.AreaCount, weightBase: 300, weightSlope: 0.001, concurrent: true},
    { track: TrackIdType.AreaCount, job: JobTypes.AreaCountZero, weightBase: 600, weightSlope: 0.002, concurrent: true},
    { track: TrackIdType.Trail, job: JobTypes.Trails, weightBase: 2000, weightSlope: 0 },
    { track: TrackIdType.Normal, job: JobTypes.LongStay, weightBase: 1500, weightSlope: 0 },
    { track: TrackIdType.Normal, job: JobTypes.ManyStay, weightBase: 3000, weightSlope: 0 },
]

const MagnificationSingle = 1   // シングルの重み倍率
const MagnificationCompare = 1  // 比較の重み倍率

// ジョブの管理
class Job {
    type: Jobs
    weight: number | undefined
    done: boolean = false
    time: number = 0
    concurrent: boolean = false
    constructor(type: Jobs, concurrent: boolean = false) {
        this.type = type
        if (concurrent) {
            this.concurrent = true
        }
    }
    setWeight(base: number, slope: number, numberOfCustomers: number, multi: number = MagnificationSingle) {
        this.weight = (base + slope * numberOfCustomers) * multi
    }
    setDone() {
        this.done = true
    }
}

// トラックの管理
class Track {
    side: CompareSide           // 所属するサイド：Single or Compare
    id: TrackId                 // トラックID
    palallel: boolean = false   // 比較分析の場合、並列処理となるのでtrueとする
    jobs: Job[] = []            // 所属するジョブを管理
    myWeight: number = 0        // 全ジョブの重み合計
    doneWeight: number = 0      // 完了ジョブの重み合計
    lapTime: number = 0         // 前回の重み変動時間

    // コンストラクタ
    constructor(side: CompareSide, track: TrackId, jobDefs: JobDefinitonType[], palallel: boolean = false) {
        this.side = side
        this.id = track
        for (let j of jobDefs) {
            if (j.track === track) {
                // ジョブを生成
                const obj = (j.concurrent) ? new Job(j.job, true) : new Job(j.job)
                // 不完全だがTotal-Jobの重みを計算しておきたい
                obj.setWeight(j.weightBase, j.weightSlope, 0, palallel ? MagnificationCompare : MagnificationSingle)
                this.jobs.push(obj)
                if (obj.weight) this.myWeight += obj.weight
            }
        }
        this.palallel = palallel
    }

    // 処理開始
    start() {
        // 時間を記録
        this.lapTime = performance.now()
    }

    // 重み計算
    async calcWeight(JobDefiniton: JobDefinitonType[], numberOfCustomers: number) {
        // ジョブごとに重みを計算
        this.myWeight = 0
        for (let j of this.jobs) {
            let def = JobDefiniton.find(d => d.job === j.type)
            if (def) {
                j.setWeight(def.weightBase, def.weightSlope, numberOfCustomers, (this.palallel) ? MagnificationCompare : MagnificationSingle)
                if (j.weight) {
                    // 重み合計を計算
                    this.myWeight += j.weight
                }
            }
        }
    }

    // Jobの完了処理
    async doneJob(type: Jobs) {
        // 完了したジョブを検索
        const target = this.jobs.find(j => j.type === type)
        const jobCount = this.jobs.length
        const doneCount = this.jobs.filter(j => j.done).length
        if (target) {
            target.setDone()
            // 処理時間算出
            const currentTime = performance.now()
            const diffTime = currentTime - this.lapTime
            target.time = diffTime
            // 完了したジョブの重みを加算、スピードを算出
            if (target.weight) {
                if (target.concurrent && jobCount === 2 && doneCount === 0) {
                    // ２ジョブ並列の場合、重みを２倍にする
                    this.doneWeight += target.weight * 2
                    if (this.doneWeight > this.myWeight) {
                        this.doneWeight = this.myWeight
                    }
                } else {
                    this.doneWeight += target.weight
                }
            }
        }
    }
}

class RemainTimeWatcher {
    hasCompare: boolean = false
    displayCallback: (progressRate: number, remainingTime: number | undefined) => void
    tracks: [Track[], Track[]] = [[],[]]
    timer: NodeJS.Timeout | undefined = undefined
    heavyTrack: [CompareSide, TrackId] = [CompareSideType.Primary, 0]
    totalCustomer: [number, number] = [-1, -1]
    areaCount: [number, number] = [0, 0]
    startTime: number = 0
    stopTime: number = 0
    progressRate: number = 0
    remainingTime: number | undefined = undefined   // 残り時間(ms)

    constructor(hasCompare: boolean, onUpdated: (progressRate: number, remainingTime: number | undefined) => void) {
        this.hasCompare = hasCompare
        this.displayCallback = onUpdated
        for (let id of Object.values(TrackIdType)) {
            this.tracks[0].push(new Track(CompareSideType.Primary, id, JobDefiniton))
            if (hasCompare) {
                this.tracks[1].push(new Track(CompareSideType.Secondary, id, JobDefiniton))
            }
        }
    }

    start() {
        this.startTime = performance.now()
        // 全トラックの処理を開始
        for (let id of Object.values(TrackIdType)) {
            this.tracks[0][id].start()
            if (this.hasCompare) {
                this.tracks[1][id].start()
            }
        }
        // タイマーを開始
        this.timer = setInterval(async () => {
            await this.update()
        }, 1000)
    }

    stop() {
        this.stopTime = performance.now()
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.toPrint()
    }

    async update() {
        // 残り時間を-1秒する
        if (this.remainingTime !== undefined) {
            this.remainingTime -= 1000
            if (this.remainingTime < 0) {
                this.remainingTime = 0
            }
        }
        // 表示の更新
        this.displayCallback(this.progressRate, this.remainingTime)
        console.log(`■update remainingTime:${this.remainingTime}`)
    }

    async finishJob(type: Jobs, side: CompareSide) {
        // Jobの完了処理
        const s = (side === CompareSideType.Primary) ? 0 : 1
        for await (let t of this.tracks[s]) {
            await t.doneJob(type)
        }
        // 重みの合計を算出
        if (type === JobTypes.Total) {
            if (this.totalCustomer[s] >= 0) {
                for await (let t of this.tracks[s]) {
                    await t.calcWeight(JobDefiniton, this.totalCustomer[s])
                }
            } else {
                console.error("totalCustomer is not set")
            }
            // 残り時間を算出
            if (this.remainingTime === undefined) {
                const now = performance.now()
                const y = 3500 - (now - this.startTime) 
                if (this.hasCompare) {
                    if (this.totalCustomer[0] >= 0 && this.totalCustomer[1] >= 0) {
                        this.remainingTime = Math.round((y + 0.078 * (this.totalCustomer[0] + this.totalCustomer[1])) * 100) / 100
                    }
                } else {
                    if (this.totalCustomer[0] >= 0) {
                        this.remainingTime = Math.round((y + 0.078 * this.totalCustomer[0]) * 100) / 100 
                    }
                }
                if (this.remainingTime) console.log(`★残り時間:${this.remainingTime}`)
            }
        }
        // 進捗率を算出
        let totalWeight = 0
        let doneWeight = 0
        for await (let t of this.tracks[0]) {
            totalWeight += t.myWeight
            doneWeight += t.doneWeight
        }
        if (this.hasCompare) {
            for await (let t of this.tracks[1]) {
                totalWeight += t.myWeight
                doneWeight += t.doneWeight
            }
        }
        // 表示の更新
        this.progressRate = doneWeight / totalWeight
        this.displayCallback(this.progressRate, this.remainingTime)
    }

    setTotalCustomer(total: number, side: CompareSide) {
        if (side === CompareSideType.Primary) {
            this.totalCustomer[0] = total
        } else {
            this.totalCustomer[1] = total
        }
    }

    toPrint() {
        const t = this.hasCompare ? 2 : 1
        const a = this.areaCount[0] + this.areaCount[1]
        const c = this.totalCustomer[0] + this.totalCustomer[1]
        const r = (this.stopTime - this.startTime) / 1000
        console.log(`★ T:${t},A:${a},C:${c},R:${r} ★`)
    }
}

export default RemainTimeWatcher