import { createContext, ReactNode, useContext, useState, useEffect } from "react"
import { format, subDays } from "date-fns"
import { useTranslation } from "react-i18next"

import { QueryLogType, TxStatus, TxStatusType } from "../types"
import { AnalyzeView, AnalyzeViewType, CompareSide, CompareSideType, AnalysisParameterSetType, AnalyzeParametersType } from "../types/Analyze"
import { FunnelParametersType, FunnelParamSetType, FunnelDatasetType } from "../feature/analyze/Funnel"
import { useAuthUserContext } from "./AuthUser"
import { ResSearchQuery } from "../api/data/analysis/AnalysisRequest"
import { AnalysisResult } from "../api/data/analysis/AnalysisResult"
import { analysisParamSet2ResSearchQuery, resSearchQuery2AnalysisParamSet } from "../lib/ModelUtil"
import ApiMock from "../api_mock/ApiMock"
import Utils from "../lib/Utils"
import { Coord } from "../api/data/core/Coord"
import { AnalysiAPIImpl } from "../api/api_impl/AnalysisAPIImple"
import { END_DUMY_TIME, START_DUMY_TIME, STAY_TIME_THRESHOLD, STR_YMD_FORMAT } from "../constants"
import { AnalysisResultImpl } from "../api/api_impl/result/AnalysisResultImpl"


/**
 * 進行状況付きステータス
 */
type statusWithProgress = {
    result: TxStatus | undefined
    progress: number | undefined        // 進行状況 0～1
    remainMsTime?: number | undefined   // 残り時間 (ms)
    errors: any | undefined
}

type DatasetType = {
    view: AnalyzeView
    request: AnalyzeParametersType
    single: AnalysisResult | undefined
    compare: AnalysisResult | undefined
}

type TrailDataType = {
    shopId: number
    humanId: number,
    points: Coord[]
}

/**
 * 分析データコンテキスト定義
 */
export type AnalyticsDataContextType = {
    // 個別・比較分析
    dataset: DatasetType | undefined
    status: statusWithProgress | undefined
    analyze: () => void
    clearError: () => void
    parameters: AnalyzeParametersType
    setViewType: (val: AnalyzeView) => void
    updateParameter: (param: AnalysisParameterSetType) => void
    reset: () => void

    // 分析条件履歴
    analyzeQuerys: QueryLogType[] | undefined
    fetchAnalyzeQuerys: () => void
    saveAnalyzeQuery: (query: ResSearchQuery, queryCmp?: ResSearchQuery) => void

    // 動線点データ
    trailStatus: statusWithProgress | undefined
    //trailDataset: TrailDataType[] | undefined
    fetchTrail: (shopId: number, humanIdList: number[], result: AnalysisResult) => void
    trailReset: () => void

    // 特定商品購買者分析
    funnelDataset: FunnelDatasetType | undefined
    funnelStatus: statusWithProgress | undefined
    funnelAnalyze: () => void
    funnelParams: FunnelParametersType
    funnelSetView: (val: AnalyzeView) => void
    funnelUpdateParam: (param: FunnelParamSetType) => void
    funnelReset: () => void
}
const AnalyticsDataContext = createContext<AnalyticsDataContextType>({} as AnalyticsDataContextType)

/**
 * プロバイダーからデータを提供するためのI/F関数
 * @returns 
 */
export const useAnalyticsDataContext = (): AnalyticsDataContextType => {
    return useContext<AnalyticsDataContextType>(AnalyticsDataContext)
}

type Props = {
    children: ReactNode
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const yesterday = Utils.yesterday()
const lastweek = subDays(yesterday, 7)

/**
 * 分析条件のデフォルト設定
 * @param side 
 * @returns 
 */
const getDefaultPrameter = (side: CompareSide):AnalysisParameterSetType => {
    return {
        shopId: undefined,
        layoutId: undefined,
        startDate: format(lastweek, STR_YMD_FORMAT),
        endDate: format(yesterday, STR_YMD_FORMAT),
        startTime: START_DUMY_TIME,
        endTime: END_DUMY_TIME,
        weekday: [true, true, true, true, true, true, true],
        buyOrNot: [true, true],
        excludeClerk: false,
        threshold: STAY_TIME_THRESHOLD,
        side: side,
        valid: false
    }
}
/**
 * 特定商品購買者分析の分析条件のデフォルト設定
 * @param side 
 * @returns 
 */
const getFunnelDefaultParameter = (side: CompareSide): FunnelParamSetType => {
    return {
        shopId: undefined,
        layoutId: undefined,
        startDate: format(lastweek, STR_YMD_FORMAT),
        endDate: format(yesterday, STR_YMD_FORMAT),
        startTime: START_DUMY_TIME,
        endTime: END_DUMY_TIME,
        areaId: undefined,
        productName: undefined,
        weekday: [true, true, true, true, true, true, true],
        threshold: STAY_TIME_THRESHOLD,
        side: side,
        valid: false
    }
}

/**
 * 動線分析のデータを提供するプロバイダー
 */
export const AnalyticsDataProvider = (props: Props) => {

	const { t } = useTranslation()
    const { userInfo } = useAuthUserContext()

    const [dataset, setDataset] = useState<DatasetType | undefined>(undefined)
    const [status, setStatus] = useState<statusWithProgress | undefined>(undefined)
    const [parameters, setParameters] = useState<AnalyzeParametersType>({
        view: AnalyzeViewType.Single,
        single: getDefaultPrameter(CompareSideType.Primary),
        compare: getDefaultPrameter(CompareSideType.Secondary)
    })
    const [analyzeQuerys, setAnalyzeQuerys] = useState<QueryLogType[] | undefined>(undefined)
    //const [trailDataset, setTrailDataset] = useState<TrailDataType[] | undefined>(undefined)
    const [trailStatus, setTrailStatus] = useState<statusWithProgress | undefined>(undefined)
    const [funnelDataset, setFunnelDataset] = useState<FunnelDatasetType | undefined>(undefined)
    const [funnelStatus, setFunnelStatus] = useState<statusWithProgress | undefined>(undefined)
    const [funnelParams, setFunnelParams] = useState<FunnelParametersType>({
        view: AnalyzeViewType.Single,
        single: getFunnelDefaultParameter(CompareSideType.Primary),
        compare: getFunnelDefaultParameter(CompareSideType.Secondary)
    })

    /**
     * 進行状況の数値を更新します。
     * 
     * @param progressRate
     * @param remainMsTime
     */
    const updateProgress = (progressRate: number, remainMsTime: number | undefined): void => {
        if (status && status.result !== TxStatusType.Rejected) {
            setStatus({ result: TxStatusType.Loading, progress: Math.round(progressRate * 100), remainMsTime: remainMsTime, errors: undefined })
        } else {
            console.log("RejectなのにupdateProgress()が呼ばれた！")
        }
    }

    const updateFunnelProgress = (value: number): void => {
        setFunnelStatus({result: TxStatusType.Loading, progress: Math.round(value * 100), errors: undefined})
    }

    const updateTrailProgress = (value: number): void => {
        setTrailStatus({ result: TxStatusType.Loading, progress: Math.round(value * 100), errors: undefined })
    }

    /**
     * 指定された分析条件で検索を行います。
     */
    const analyze = (): void => {
        setStatus({
            result: TxStatusType.Loading,
            progress: 0,
            errors: undefined
        })
        trailReset()
        let query = undefined
        const shop_id = parameters.single.shopId
        const layout_id = parameters.single.layoutId
        const start_dt = parameters.single.startDate
        const end_dt = parameters.single.endDate
        if (shop_id && layout_id && start_dt && end_dt) {
            const log: QueryLogType = { id: 0, insertAt: 0, query: parameters.single }
            const single: ResSearchQuery = analysisParamSet2ResSearchQuery(log)
            if (parameters.view === AnalyzeViewType.Compare) {
                const cshop_id = parameters.compare.shopId
                const clayout_id = parameters.compare.layoutId
                const cstart_dt = parameters.compare.startDate
                const cend_dt = parameters.compare.endDate
                if (cshop_id && clayout_id && cstart_dt && cend_dt) {
                    const log2: QueryLogType = { id: 0, insertAt: 0, query: parameters.compare }
                    const compare: ResSearchQuery = analysisParamSet2ResSearchQuery(log2)
                    query = { single: single, compare: compare }
                }
            } else {
                query = { single: single }
            }
            if (query) {
                if (mockOn) {
                    ApiMock.instance.analysis(query, updateProgress).then(res => {
                        const ds: DatasetType = { view: parameters.view, request: parameters, single: res.single, compare: res.compare }
                        setDataset(ds)
                        setStatus({ result: TxStatusType.Fulfilled, progress: undefined, errors: undefined })
                    }).catch(err => {
                        console.error(err)
                        if (err.message) {
                            // Axiosのエラー
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                        } else {
                            const errmsg = t("msgErrorOccured")
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                        }
                    })
                } else {
                    const api = new AnalysiAPIImpl()
                    api.analysis(query, updateProgress).then(res => {
                        console.log("res:", res)
                        let result = res as any
                        if (result && result.single) {
                            const ds: DatasetType = { view: parameters.view, request: parameters, single: result.single, compare: result.compare }
                            setDataset(ds)
                            setStatus({ result: TxStatusType.Fulfilled, progress: undefined, errors: undefined })
                            // 遅延データ取得（弦グラフ用のサンプルデータ）
                            api.analysis2(result).then(() => {
                                console.log("analysis2() success!")
                            }).catch(err => {
                                console.error("analysis2() error!", err)
                            })
                        } else {
                            console.error("analysis() error!", result)
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: result.error })
                        }
                    }).catch(err => {
                        console.error(err)
                        if (err.message) {
                            // Axiosのエラー
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                        } if (err.error) {
                            // 件数が多すぎる場合など
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.error })
                        } else {
                            const errmsg = t("msgErrorOccured")
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                        }
                    })
                }
            }
        }
    }

    const clearError = (): void => {
        setStatus(undefined)
    }

    /**
     * 指定された分析条件でファネル検索を行います。
     */
    const funnelAnalyze = (): void => {
        setFunnelStatus({
            result: TxStatusType.Loading,
            progress: 0,
            errors: undefined
        })
        if (mockOn) {
            ApiMock.instance.funnelAnalyze(funnelParams, updateFunnelProgress).then(res => {
                const v = (res.parameter && res.parameter.view) ? res.parameter.view : funnelParams.view
                setFunnelDataset({ view: v, single: res.single, compare: res.compare })
                setFunnelStatus({ result: TxStatusType.Fulfilled, progress: undefined, errors: undefined })
            }).catch(err => {
                console.error(err)
                if (err.message) {
                    // Axiosのエラー
                    setFunnelStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                } else {
                    const errmsg = t("msgErrorOccured")
                    setFunnelStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                }    
            })
        } else {
            setFunnelStatus({ result: TxStatusType.Rejected, progress: undefined, errors: "★ API funnelAnalyze(仮称) 未実装" })
        }
    }

    /**
     * 一店舗分の分析条件の更新を行います。
     * 
     * @param paramSet 
     */
    const updateParameter = (paramSet: AnalysisParameterSetType): void => {
        //console.log("updateParameter", paramSet)
        const newParam = { ...parameters }
        if (paramSet.side === CompareSideType.Primary) {
            newParam.single = paramSet
        } else {
            newParam.compare = paramSet
        }
        setParameters(newParam)
    }

    const funnelUpdateParam = (paramSet: FunnelParamSetType): void => {
        const newParam = { ...funnelParams }
        if (paramSet.side === CompareSideType.Primary) {
            newParam.single = paramSet
        } else {
            newParam.compare = paramSet
        }
        setFunnelParams(newParam)
    }

    /**
     * 分析条件とステータス、データセットをクリアします。
     */
    const reset = () => {
        if (mockOn) {
            const res = ApiMock.instance.reset_analysis()
            if (!res) {
                console.error("Api.reset_analysis ERROR!")
                setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: "Api.reset_analysis ERROR!" })
            }
        } else {
            const api = new AnalysiAPIImpl()
            const res = api.reset_analysis()
            console.log("res:", res)
            if (!res) {
                console.error("Api.reset_analysis ERROR!")
                setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: "Api.reset_analysis ERROR!" })
            }
        }
        const newParam: AnalyzeParametersType = {
            view: parameters.view,
            single: getDefaultPrameter(CompareSideType.Primary),
            compare: getDefaultPrameter(CompareSideType.Secondary)                        
        }
        setParameters(newParam)
        setDataset(undefined)
        setStatus(undefined)
    }

    const funnelReset = () => {
        const newParam: FunnelParametersType = {
            view: funnelParams.view,
            single: getFunnelDefaultParameter(CompareSideType.Primary),
            compare: getFunnelDefaultParameter(CompareSideType.Secondary)
        }
        setFunnelParams(newParam)
        setFunnelDataset(undefined)
        setFunnelStatus(undefined)
    }

    const trailReset = () => {
        //setTrailDataset(undefined)
        setTrailStatus(undefined)
    }

    /**
     * 個別分析と比較分析の区分を設定します。
     * 
     * @param value 
     */
    const setViewType = (value: AnalyzeView) => {
        const newParam = { ...parameters }
        newParam.view = value
        setParameters(newParam)
    }

    const funnelSetView = (value: AnalyzeView) => {
        const newParam = { ...funnelParams }
        newParam.view = value
        setFunnelParams(newParam)
    }

    /**
     * 分析条件の一覧を取得します。
     * @returns 
     */
    const fetchAnalyzeQuerys = () => {
        try {
            if (mockOn) {
                ApiMock.instance.get_saved_queries().then(res => {
                    const qlogs = res.map(el => {
                        const ql: QueryLogType = {
                            id: el.query_log_id,
                            insertAt: el.query_saved_at,
                            query: resSearchQuery2AnalysisParamSet(el, CompareSideType.Primary)
                        }
                        return ql
                    })
                    setAnalyzeQuerys(qlogs)
                }).catch(err => {
                    console.error(err)
                    console.log("fetchAnalyzeQuerys ERROR!")
                    setAnalyzeQuerys([])
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        const errmsg = t("msgErrorOccured")
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                    }
                })
            } else {
                const api = new AnalysiAPIImpl()
                api.get_saved_queries().then(res => {
                    console.log("get_saved_queries res:", res)
                    if (res && res.length > 0 && res[0].query_log_id > 0) {
                        const qlogs = res.map(el => {
                            const ql: QueryLogType = {
                                id: el.query_log_id,
                                insertAt: el.query_saved_at,
                                query: resSearchQuery2AnalysisParamSet(el, CompareSideType.Primary)
                            }
                            return ql
                        })
                        setAnalyzeQuerys(qlogs)
                    } else {
                        setAnalyzeQuerys([])
                    }
                }).catch(err => {
                    console.error(err)
                    console.log("fetchAnalyzeQuerys ERROR!")
                    setAnalyzeQuerys([])
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        const errmsg = t("msgErrorOccured")
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                    }
                })
            }

        } catch (err) {
            console.log(err)
        }
    }

    /**
     * 分析条件を保存します。
     */
    const saveAnalyzeQuery = (query: ResSearchQuery, queryCmp?: ResSearchQuery): void => {
        const api = new AnalysiAPIImpl()
		const errMsg = t("msgErrFaildAnalysis")
        if (queryCmp) {
            //console.log("saveAnalyzeQuery compare")
            if (isProduction) {
                api.save_queries(query).then(res => {
                    console.log("save_queries res:", res)
                    if (res) {
                        api.save_queries(queryCmp).then(resCmp => {
                            if (resCmp) {
                                fetchAnalyzeQuerys()
                            } else {
                                console.error(errMsg)
                                setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                            }
                        }).catch(err => {
                            console.error(err)
                            setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err })
                        })
                    } else {
                        console.error(errMsg)
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    }
                }).catch(err => {
                    console.error(err)
                    console.log("saveAnalyzeQuery ERROR!")
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    }
                })
            } else {
                ApiMock.instance.save_queries(query).then(res => {
                    if (!res) {
                        console.error(errMsg)
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    } else {
                        fetchAnalyzeQuerys()
                    }
                }).catch(err => {
                    console.error(err)
                    console.log("saveAnalyzeQuery ERROR!")
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    }
                })
            }
        } else {
            if (isProduction) {
                api.save_queries(query).then(res => {
                    console.log("res:", res)
                    if (!res) {
                        console.error(errMsg)
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    } else {
                        fetchAnalyzeQuerys()
                    }
                }).catch(err => {
                    console.error(err)
                    console.log("saveAnalyzeQuery ERROR!")
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    }
                })
            } else {
                ApiMock.instance.save_queries(query).then(res => {
                    if (!res) {
                        console.error(errMsg)
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    } else {
                        fetchAnalyzeQuerys()
                    }
                }).catch(err => {
                    console.error(err)
                    console.log("saveAnalyzeQuery ERROR!")
                    if (err.message) {
                        // Axiosのエラー
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                    } else {
                        setStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errMsg })
                    }
                })
            }
        }
    }

    /**
     * 動線の点データを取得します。
     * 
     * @param shopId 
     * @param humanId 
     * @returns 
     */
    const fetchTrail = (shopId: number, humanIdList: number[], result: AnalysisResult): void => {
        console.log("fetchTrail start")
        //setTrailDataset(undefined)
        setTrailStatus({
            result: TxStatusType.Loading,
            progress: 0,
            errors: undefined
        })
        if (mockOn) {
            ApiMock.instance.get_trails(shopId, humanIdList, updateTrailProgress).then(res => {
                const keys = Object.keys(res)
                for (let k of keys) {
                    const val = res[k]
                    result.trailHash[k] = val
                }
                /*const results = keys.map(k => {
                    const humanId = parseInt(k)
                    const itm: TrailDataType = {
                        shopId: shopId,
                        humanId: humanId,
                        points: res[k]
                    }
                    return itm
                })*/
                //setTrailDataset(results)
                setTrailStatus({ result: TxStatusType.Fulfilled, progress: undefined, errors: undefined })
                //console.log("fetchTrail finish.")
            }).catch(err => {
                console.error(err)
                console.log("fetchTrail ERROR!")
                if (err.message) {
                    // Axiosのエラー
                    setTrailStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                } else {
                    const errmsg = t("msgErrorOccured")
                    setTrailStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                }
            })
        } else {
            const api = new AnalysiAPIImpl()
            api.get_trails(shopId, humanIdList, updateTrailProgress).then(res => {
                //console.log("api.get_trails res:", res)
                let hasEmpty: boolean = false
                const keys = Object.keys(res)
                for (let k of keys) {
                    const val = res[k]
                    result.trailHash[k] = val
                    if (val.length < 2) hasEmpty = true
                }
                /*const results = keys.map(k => {
                    const humanId = parseInt(k)
                    const itm: TrailDataType = {
                        shopId: shopId,
                        humanId: humanId,
                        points: res[k]
                    }
                    if (res[k].length < 2) hasEmpty = true //console.error("api.get_trails() result is empty! humanId:", humanId)
                    return itm
                })
                setTrailDataset(results)*/
                //console.log("TrailDataset:", results)
                if (hasEmpty) {
                    //console.log("api.get_trails() result has empty!")
                    const errmsg = t("msgErrorOccured")
                    setTrailStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                } else {
                    setTrailStatus({ result: TxStatusType.Fulfilled, progress: undefined, errors: undefined })
                }
            }).catch(err => {
                // 多分、ここにくることはない
                console.error(err)
                console.log("fetchTrail ERROR!")
                // useEffectループしないように
                /*const results = humanIdList.map(id => {
                    const itm: TrailDataType = {
                        shopId: shopId,
                        humanId: id,
                        points: []
                    }
                    return itm
                })*/
                //setTrailDataset(results)
                if (err.message) {
                    // Axiosのエラー
                    setTrailStatus({ result: TxStatusType.Rejected, progress: undefined, errors: err.message })
                } else {
                    const errmsg = t("msgErrorOccured")
                    setTrailStatus({ result: TxStatusType.Rejected, progress: undefined, errors: errmsg })
                }
            })
        }
    }

    useEffect(() => {
        // ログアウトなどでUserInfoが無くなったときは、分析データを削除する
        if (userInfo === null || userInfo === undefined) {
            console.log("Lost UserInfo. All data clear.")
            if (parameters.single.shopId !== undefined) {
                //console.log("UserInfoがなくなった為、分析条件を削除")
                const newParam: AnalyzeParametersType = {
                    view: parameters.view,
                    single: getDefaultPrameter(CompareSideType.Primary),
                    compare: getDefaultPrameter(CompareSideType.Secondary)                        
                }
                setParameters(newParam)
            }
            if (dataset !== undefined) {
                //console.log("UserInfoがなくなった為、分析データを削除")
                setDataset(undefined)
            }
            if (status !== undefined) {
                //console.log("UserInfoがなくなった為、ステータス削除")
                setStatus(undefined)
            }
            if (funnelParams.single.shopId !== undefined) {
                const newParam: FunnelParametersType = {
                    view: funnelParams.view,
                    single: getFunnelDefaultParameter(CompareSideType.Primary),
                    compare: getFunnelDefaultParameter(CompareSideType.Secondary)
                }
                setFunnelParams(newParam)
            }
            if (funnelDataset !== undefined) {
                setFunnelDataset(undefined)
            }
            if (funnelStatus !== undefined) {
                setFunnelStatus(undefined)
            }
            if (analyzeQuerys !== undefined) {
                setAnalyzeQuerys(undefined)
            }
        }
    }, [userInfo, dataset, status, parameters, funnelParams, funnelDataset, funnelStatus, analyzeQuerys])

    const value: AnalyticsDataContextType = {
        dataset,
        status,
        analyze,
        clearError,
        parameters,
        setViewType,
        updateParameter,
        reset,
        analyzeQuerys,
        fetchAnalyzeQuerys,
        saveAnalyzeQuery,
        //trailDataset,
        trailStatus,
        fetchTrail,
        trailReset,
        funnelDataset,
        funnelStatus,
        funnelAnalyze,
        funnelParams,
        funnelSetView,
        funnelUpdateParam,
        funnelReset
    }
    return (
        <AnalyticsDataContext.Provider value={value}>
            {props.children}
        </AnalyticsDataContext.Provider>
    )
}