import React, { useCallback, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

import { ResArea } from "../../api/data/analysis/FullLayout"
import { CustomerForTrail, HumanAreaCount } from "../../api/data/analysis/AnalysisResult"
import { Checked, CheckedState } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import { Pagination } from "../../component/pagination/Pagination"
import { TrailMapFilterSelector, FilterObject } from "./TrailMapFilterSelector"
import { DetailAreaInfoRowType, DetailRankingType, DetailDataType } from "./TrailMapDetail"
import { DetailType, SortingType } from "./TrailMap"
import { AnalysisParameterSetType } from "../../types/Analyze"
import Utils from "../../lib/Utils"
import { LineNumberOfPage } from "./TrailMap"
import { ListViewModeType, TrailMapList } from "./TrailMapList"
import { OPENSEARCH_SIZE_LIMIT } from "../../api/data/core/Constants"

import style from "./TrailMapCustomerList.module.css"


interface ListProps {
    numOfAllFiles: number | undefined                   // 全ファイル数
    linesOfPage: number                                 // 1ページに表示する行数
    changeLinesOfPage: (lines: number) => void          // 1ページに表示する行数を変更する
    selectedCustomers: CustomerForTrail[] | undefined   // 一覧表示する顧客IDリスト
    selectedCustomerSize: number | undefined            // 一覧表示する顧客IDリストのサイズ
    searchParam: AnalysisParameterSetType | undefined   // 分析条件
    areaList: ResArea[] | null | undefined              // エリアリスト
    showDetail: (detail: DetailType) => void            // 顧客詳細画面を表示する
    changeIdList: (idList: number[]) => void            // 動線表示する顧客IDリストを変更する
    changeHighlight: (onId: number | undefined) => void // 動線をハイライト化する顧客ID
    downloadHeader: string | undefined                  // ダウンロードファイルのヘッダー
    isSingle: boolean                                   // 個別分析か（^比較分析か）どうか
    filter: FilterObject                                // フィルター状態
    changeFilter: (filter: FilterObject | undefined) => void
    sorting: SortingType                                // ソート状態
    changeSorting: (sorting: SortingType) => void
    bulkCheckbox: Checked                               // 一括選択チェックボックス
    changeBulkCheckbox: (checked: Checked) => void
    checkedList: Record<number, boolean> | undefined    // 個別選択チェックボックス
    changeCheckedList: (checkList: Record<number, boolean>) => void
    currentPage: number                                 // 現在のページ
    changeCurrentPage: (page: number) => void
    isBusy: boolean                                     // 親コンポーネントの処理中
    isLoading: boolean                                  // 顧客リストのデータ取得中
    fireIds: number[]                                   // マウスがラインに接触中の顧客IDリスト
    isThroughList: boolean                              // 表示がALLかどうか。ALLの場合はTrue
    getDownloadCustomers: () => Promise<CustomerForTrail[]> // ダウンロード用の顧客リストを取得する
}

export const TrailMapCustomerList: React.FC<ListProps> = (props) => {

	const { t } = useTranslation()

    const anchorRef = useRef<HTMLAnchorElement>(null)

    /**
     * 最大ページ数
     */
    const maxPage = useMemo(() => {
        if (props.selectedCustomerSize && props.linesOfPage > 0) {
            if (props.selectedCustomerSize > OPENSEARCH_SIZE_LIMIT) {
                return Math.ceil(OPENSEARCH_SIZE_LIMIT / props.linesOfPage)
            }
            const pg = Math.ceil(props.selectedCustomerSize / props.linesOfPage)
            return pg
        }
        return 0
    }, [props.selectedCustomerSize, props.linesOfPage])

    /**
     * 一覧に表示する顧客IDリストに店員が含まれているかどうか
     */
    const hasClerk = useMemo(() => {
        if (props.selectedCustomers === undefined) return false
        const st = props.currentPage * props.linesOfPage
        const len = (st + props.linesOfPage < props.selectedCustomers.length) ? props.linesOfPage : props.selectedCustomers.length - st
        const pageCustomers = props.selectedCustomers.slice(st, st + len)
        const res = pageCustomers.find(el => el.is_clerk)
        return (res)
    }, [props.currentPage, props.selectedCustomers, props.linesOfPage])

    const pageSelectOptions = useMemo(() => {
        const perPage = " " + t("perPage")
        const ops = LineNumberOfPage.map(el => {
            if (el === props.linesOfPage) {
                return (<option key={'per-page-' + el} value={el} defaultChecked>{el + perPage}</option>)
            } else {
                return (<option key={'per-page-' + el} value={el}>{el + perPage}</option>)
            }
        })
        return ops
    }, [props.linesOfPage, t])

    /**
     * ダウンロードデータを作成します。
     * ・ヘッダー上部の検索条件項目は上位コンポーネントからpropsで渡される。
     * @param areaList 
     * @param customers 
     * @param downloadHeader 
     * @returns 
     */
    const mkDownloadUrl = useCallback(async (areaList: ResArea[] | null | undefined, customers: CustomerForTrail[], downloadHeader: string | undefined) => {
            console.log("mkDownloadUrl()")
            //ダウンロードするCSVファイル名を指定する
            let data = downloadHeader + t("customerId") + ',' + t("enterTime") +',' + t("exitTime") +',' + t("stayingTime") + ',' + t("areasToStay");
            if (areaList) {
                for await (const a of areaList) {
                    data += `,${a.name}`;
                }
            }
            data += "\n";
            for await (const c of customers) {
                let row = "" + c.human_id + "," + Utils.epochMilliToJtime(c.enter_time) + "," + Utils.epochMilliToJtime(c.exit_time) + "," + Utils.formatMillisecToTime(c.stay_seconds) + "," + c.stay_area_count
                if (areaList) {
                    for await (let a of areaList) {
                        const dt = c.area_count_list.find(el => el.area.area_id === a.area_id)
                        if (dt) {
                            row += "," +  Utils.formatMillisecToTime(dt.total_stay_time)
                        } else {
                            row += ","
                        }
                    }
                }
                data += (row + "\n");
            }
            //BOMを付与する（Excelでの文字化け対策）
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            //Blobでデータを作成する
            const blob = new Blob([bom, data], { type: "text/csv" });
            // const blob = new Blob([data], { type: "text/csv" });
            const url = (window.URL || window.webkitURL).createObjectURL(blob);
            return url;
    }, [t])

    /**
     * 詳細ページ用のエリア別情報を作成します。
     * @param elasticSrc 
     * @param areaList 
     * @param threshold 
     * @returns 
     */
    const createAreaInfo = useCallback(async (area_count_list: HumanAreaCount[]): Promise<DetailAreaInfoRowType[]> => {
        const info: DetailAreaInfoRowType[] = [{ id: 0, num: null, name: "エリア滞在時間合計", time: 0, count: -1 }]
        for await (let hac of area_count_list) {
            const  itm: DetailAreaInfoRowType = {
                id: hac.area.area_id,
                num: "" + hac.area.area_number,
                name: hac.area.name,
                time: hac.total_stay_time,
                count: hac.stay_count,
            }
            info.push(itm)
        }
        return info
    }, [])

    /**
     * 詳細ページ用のエリアトップテンを作成します。
     * @param stayShopTime 
     * @param info 
     * @returns 
     */
    const createTop10 = useCallback(async (stayShopTime: number, info: HumanAreaCount[]): Promise<DetailRankingType[]> => {
        const top10: DetailRankingType[] = [{ name: t("stayingTime"), count: -1, time: stayShopTime }]
        const result = info.filter(el => el.stay_count >= 0 && el.total_stay_time > 0).map(el => {
            return { key: el.total_stay_time, val: el }
        }).sort((a, b) => { return (a.key > b.key) ? -1 : 1 })
        for (let i = 0; i < Math.min(9, result.length); i++) {
            const name = (!(result[i].val.area.area_number)) ? result[i].val.area.name : result[i].val.area.area_number + "_" + result[i].val.area.name
            const item: DetailRankingType = { name: name, count: result[i].val.stay_count, time: result[i].val.total_stay_time }
            top10.push(item)
        }
        return top10
    }, [t])

    /**
     * ページ変更処理
     * ・チェックリストクリアする＞動線を消す
     * ・選択チェックボックスをクリアする
     * @param newPage 
     */
    const handleChangePage = (newPage: number) => {
        if (!props.isBusy) {
            if (maxPage && newPage <= maxPage) {
                console.log("newPage:", newPage)
                props.changeCheckedList({})
                props.changeIdList([])
                props.changeCurrentPage(newPage - 1)
                props.changeBulkCheckbox(CheckedState.None)
            }
        }
    }

    /**
     * 絞り込みフィルターの変更処理
     * @param newFilter 
     */
    const handleUpdateFilter = (newFilter: FilterObject | undefined) => {
        props.changeFilter(newFilter)
    }

    /**
     * 詳細画面を表示
     * @param humanId 
     * @param threshold 
     */
    const handleOpenDetail = useCallback((humanId: number) => {
        if (!props.isBusy) {
            const cust: CustomerForTrail | undefined = props.selectedCustomers?.find(c => c.human_id === humanId)
            if (cust && props.areaList) {
                createAreaInfo(cust.area_count_list).then(areaInfo => {
                    createTop10(cust.stay_seconds, cust.area_count_list).then(topTen => {
                        const data: DetailDataType = {
                            humanId: cust.human_id,
                            exitShopTime: cust.exit_time,
                            enterShopTime: cust.enter_time,
                            stayShopTime: cust.stay_seconds,
                            areaInfo: areaInfo,
                            stayTimeTop10: topTen
                        }
                        props.showDetail({ show: true, data: data })
                    })
                })
            }
        }
    }, [props, createAreaInfo, createTop10])

    const handleChangeLinesPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (!props.isBusy) {
            const lines = parseInt(event.target.value)
            if (props.linesOfPage !== lines) {
                props.changeLinesOfPage(lines)
                props.changeCurrentPage(0)
            }
        }
    }

    /**
     * CSVファイルをダウンロードボタン押下処理
     */
    const handleDownloadCsvFile = useCallback(async () => {
        // 対象の件数を確認する
        if (props.isThroughList &&
            ((props.filter.isActive && props.selectedCustomerSize && props.selectedCustomerSize > OPENSEARCH_SIZE_LIMIT)
                || (!props.filter.isActive && props.numOfAllFiles && props.numOfAllFiles > OPENSEARCH_SIZE_LIMIT))) {
            // ALL選択中、かつ、件数が１万件を超える場合は、ダウンロードできません。
            alert(t("msgOverDownload"))
        } else {
            const link = anchorRef.current
            if (link) {
                const list = await props.getDownloadCustomers()
                const url = await mkDownloadUrl(props.areaList, list, props.downloadHeader)
                link.href = url
                link.download = "customers.csv"
                link.click()
            }
        }
    }, [mkDownloadUrl, props, t])

    // 顧客リストが更新されたら、ひとつだけチェックを入れ、ひとつだけ動線を描く。
    /*useEffect(() => {
        if (props.selectedCustomers && props.selectedCustomers.length > 0) {
            const cust = props.selectedCustomers[0]
            const newCheckList: Record<number, boolean> = {}
            newCheckList[cust.human_id] = true
            const newDrawIds = [cust.human_id]
            props.changeCheckedList(newCheckList)
            props.changeIdList(newDrawIds)
            props.changeBulkCheckbox(CheckedState.PartOf)
        }
        //※ このuseEffectは、props.selectedCustomersが変更されたときだけ実行される。これ以外を入れると、無限ループになる。
    }, [props])*/

    return (
        <div className={style.listMain}>
            <div className={style.head}>
                <div className={style.title}>
                    <div className={style.titleText}>{t("selectCustomerId")}</div>
                    <div className={style.mid}></div>
                    <div className={style.note}>{t("msgPurchase")}</div>
                </div>
                <div className={style.control}>
                    <div className={style.ctrlLeft}>
                        <div className={style.ctrlLabel}>{t("allFile")}：</div>
                        <div className={style.ctrlCount}>&emsp;{Utils.comma(props.numOfAllFiles)}</div>
                        {
                            props.filter.isActive ? (<>
                                <div className={style.ctrlSpc}></div>
                                <div className={style.ctrlLabel}>{t("filteredCount")}：</div>
                                <div className={style.ctrlCount}>&emsp;{Utils.comma(props.selectedCustomerSize)}</div>
                            </>) : (null)
                        }
                    </div>
                    <div className={style.mid}>
                        {
                            hasClerk ? (<div className={style.greenTxt}>{t("msgStaffLine")}</div>) : (null)
                        }                        
                    </div>
                    <div className={style.ctrlBtns}>
                        <div className={style.filterBtn}>
                            <TrailMapFilterSelector filter={props.filter} handleFilter={handleUpdateFilter} isBusy={props.isBusy} />
                        </div>
                        <div className={style.spacing}></div>
                        <div className={style.downloadBtn}>
                            <button className={style.downloadButton} onClick={handleDownloadCsvFile}>{t("download")}</button>
                            <a className={style.hiddenLink} ref={anchorRef}></a>
                        </div>
                    </div>
                </div>
            </div>
            <TrailMapList
                viewMode={ListViewModeType.long}
                linesOfPage={props.linesOfPage}
                customers={props.selectedCustomers}
                changeIdList={props.changeIdList}
                changeHighlight={props.changeHighlight}
                isSingle={props.isSingle}
                sorting={props.sorting}
                changeSorting={props.changeSorting}
                bulkCheckbox={props.bulkCheckbox}
                changeBulkCheckbox={props.changeBulkCheckbox}
                checkedList={props.checkedList}
                changeCheckedList={props.changeCheckedList}
                currentPage={props.currentPage}
                isBusy={props.isBusy}
                isLoading={props.isLoading}
                fireIds={props.fireIds}
                noSortOfStayAreaCount={props.isThroughList}
                showDetail={handleOpenDetail}
            />
            <div className={style.listFoot}>
                <div className={style.pagination}>
                    <Pagination
                        currentPage={props.currentPage + 1}
                        maxPage={maxPage}
                        handleChangePage={handleChangePage}
                    />
                </div>
                <div className={style.mid}></div>
                <div className={style.numOfPage}>
                    <label htmlFor="numOfPage">{t("numOfPage")}</label>
                    <select id="numOfPage" className={style.numSelect} value={props.linesOfPage} onChange={e => handleChangeLinesPerPage(e)}>
                        {pageSelectOptions}
                    </select>
                </div>
            </div>
        </div>
    )
}
