import React, { useMemo, useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PencilIcon, TrashIcon } from "@primer/octicons-react"

import { AreaCountingType, AreaType } from "../../api/data/core/Enums"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { AreaModel, EditModeType, UrlParameterType } from "../../types/Management"
import { AREA_ID_SHIFT_VALUE } from "../../constants"
import { Button, StyleType } from "../../component/button/Button"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { BaseFrame, ContentsFrameType } from "../common/BaseFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import { AreaRegiSelector } from "./AreaRegiSelector"
import { AreaRegiMapper } from "./AreaRegiMapper"

import style from "./AreaRegiEdit.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

type CsvDataRow = {
    area_number: number
    name: string
    area_type: string
    found: boolean      // 画面リストに同じ番号が存在するかどうか
    updateName: boolean // エリア名更新済みフラグ
    updateType: boolean // エリアタイプ更新済みフラグ
    hasMap: boolean     // 画面にマップデータが存在するかどうか
    errMessage: string  // エラーメッセージ
}

interface Props {
}

export const AreaRegiEdit: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { companyList, editingAreaPack, updateEditingAreaPack, initManagementData } = useManagementDataContext()

    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()

    const [editAreaId, setEditAreaId] = useState<number>(0)
    const [selectedType, setSelectedType] = useState<AreaType>(AreaCountingType.Normal)
    const [areaNumber, setAreaNumber] = useState<string>("1")
    const [areaName, setAreaName] = useState<string>("")
    const [csvData, setCsvData] = useState<CsvDataRow[] | null>(null)

    const refCsv = useRef<HTMLInputElement>(null)
    
    // URLパラメーター
    const search = useLocation().search
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => { return queryParams.get(UrlParameterType.Mode) }, [queryParams])
    
    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageArea_Delete) }, [ableToDelete])
    
    // Providerデータ初期化
    useEffect(() => {
        // 企業は必ず１件以上ある。ない時はAPIからデータを取得する
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])
    
    // Closeボタン押下時の処理
    const handleClose = () => {
        // 編集途中のエリアがあれば元に戻す
        //recoveryEditingArea(false).then(res => {
            // 前のページに戻る
            navigate("/area_regi_list")
        //})
    }

    /**
     * エリア編集ボタン押下時の処理
     */
    const handleAreaReEdit = useCallback((event: React.MouseEvent<HTMLButtonElement>, areaId: number) => {
        event.preventDefault()
        console.log("◆エリア編集")
        if (editingAreaPack && editingAreaPack.area_list) {
            const area = editingAreaPack.area_list.find(el => el.area_id === areaId)
            if (area) {
                setAreaNumber(area.area_number.toString())
                setAreaName(area.name)
                setSelectedType(area.area_type)
            }
        }
        setEditAreaId(areaId)
    }, [editingAreaPack])

    /**
     * エリア削除ボタン押下時の処理
     */
    const handleAreaDelete = useCallback((event: React.MouseEvent<HTMLButtonElement>, areaId: number) => {
        event.preventDefault()
        if (editingAreaPack) {
            const cloneAp = { ...editingAreaPack }
            const newAreaList = cloneAp.area_list.filter(el => el.area_id !== areaId)
            cloneAp.area_list = newAreaList
            updateEditingAreaPack(cloneAp)
        }
    }, [editingAreaPack, updateEditingAreaPack])

    /**
     * マウスオーバー時の処理
     */
    const handleMouseOver = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        event.currentTarget.style.backgroundColor = "#f0f0f0"
    }, [])

    /**
     * マウスアウト時の処理
     */
    const handleMouseOut = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        event.currentTarget.style.backgroundColor = "#ffffff"
    }, [])

    /**
     * テンプレートダウンロードボタン押下時の処理
     */
    const handleDownload = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        console.log("◆テンプレートダウンロード")
        
        // text content
        let text = ["エリア番号,エリア名,エリア属性\n"]
        if (editingAreaPack && editingAreaPack.area_list) {
            editingAreaPack.area_list.forEach(el => {
                if (el.area_id > 0) {
                    const itm = `${el.area_number},${el.name},${el.area_type}\n`
                    text.push(itm)
                }
            })
        }
        // 中身がないときは100件分のテンプレートを作成する
        if (text.length === 1) {
            for (let i = 1; i <= 100; i++) {
                let typ: string = AreaCountingType.Normal
                if (i > 90) {
                    typ = AreaCountingType.Casher
                } else if (i > 80) {
                    typ = AreaCountingType.ChangingRoom
                } else if (i > 70) {
                    typ = AreaCountingType.Clerk
                }
                const itm = `${i},エリア${i},${typ}\n`
                text.push(itm)
            }
        }
        
        // file object
        const file = new Blob(text, { type: 'text/plain' })

        // anchor link
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = 'area_template-' + Date.now() + '.csv'
        
        // simulate click
        document.body.appendChild(element)
        element.click()

    }, [editingAreaPack])

    /**
     * CSVインポートボタン押下時の処理
     */
    const handleImport = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        // ファイル選択ダイアログを開く
        if (refCsv.current) {
            refCsv.current.click()
        }
    }, [])

    /**
     * CSVファイルから読み込んだ文字列をエリア属性に変換します。解読不能な場合はNormalを返します。
     * @param typ 
     * @returns 
     */
    const getAreaCountingType = (typ: string) => {
        if (typ === AreaCountingType.Casher) {
            return AreaCountingType.Casher
        } else if (typ === AreaCountingType.ChangingRoom) {
            return AreaCountingType.ChangingRoom
        } else if (typ === AreaCountingType.Clerk) {
            return AreaCountingType.Clerk
        }
        return AreaCountingType.Normal
    }

    /**
     * CSVファイル変更時の処理
     */
    const handleChangeCsvFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        console.log("◆CSVファイル変更")
        if (csvData) setCsvData(null)
        const file = event.target.files?.item(0)
        if (file) {
            console.log(file)
            const reader = new FileReader()
            reader.onload = (e) => {
                const text = e.target?.result
                console.log(text)
                if (text && editingAreaPack) {
                    const lines = text.toString().split("\n")
                    console.log(lines)
                    const data: CsvDataRow[] = []
                    const cloneAreaPack = { ...editingAreaPack }
                    if (cloneAreaPack.area_list === undefined) cloneAreaPack.area_list = []
                    lines.forEach((el) => {
                        // カラム分解
                        const cols = el.split(",")
                        if (cols.length >= 3) {
                            if (cols[0] !== "エリア番号") { // ヘッダー行は除外する
                                const num = parseInt(cols[0])
                                const name = cols[1]
                                const typ = cols[2]
                                const itm: CsvDataRow = { area_number: num, name: name, area_type: typ, found: false, updateName: false, updateType: false, hasMap: false, errMessage: "" }
                                // エリアを探す
                                const area = cloneAreaPack.area_list.find(el => el.area_number === num)
                                if (area) {
                                    // エリアが見つかった場合
                                    itm.found = true
                                    // エリア名を更新する
                                    if (area.name !== name) {
                                        area.name = name
                                        itm.updateName = true
                                    }
                                    // エリアタイプを更新する
                                    if (area.area_type !== typ) {
                                        area.area_type = getAreaCountingType(typ) 
                                        itm.updateType = true
                                    }
                                    // マップデータがあるかどうか
                                    if (area.cell_ids.length > 0) {
                                        itm.hasMap = true
                                    }
                                    // 一覧に表示させるためにIDを設定する
                                    if (area.area_id < 0 && cloneAreaPack.area_list) {
                                        const newId = cloneAreaPack.area_list.filter(el => el.area_id < AREA_ID_SHIFT_VALUE).reduce((a, b) => Math.max(a, b.area_id), 0) + 1
                                        if (newId) area.area_id = newId
                                    }
                                } else {
                                    // エリアが見つからなかった場合
                                    itm.errMessage = "エリアが見つかりません"
                                    // 登録済みエリアリストに追加する
                                    const newId = cloneAreaPack.area_list.filter(el => el.area_id < AREA_ID_SHIFT_VALUE).reduce((a, b) => Math.max(a, b.area_id), 0) + 1
                                    const newArea: AreaModel = { group_id: null, area_id: newId, area_number: num, name: name, area_type: getAreaCountingType(typ), cell_ids: [] }
                                    cloneAreaPack.area_list.push(newArea)
                                }
                                data.push(itm)
                            }
                        }
                    })
                    setCsvData(data)
                    updateEditingAreaPack(cloneAreaPack)
                    console.log(data, cloneAreaPack)
                    if (refCsv && refCsv.current) refCsv.current.value = ""
                    // AreaRegiMapperのエリア名を更新する
                    if (data.length > 0) {
                        const itm = data.find(el => el.area_number === parseInt(areaNumber))
                        if (itm && itm.updateName) {
                            setAreaName(itm.name)
                        }
                        if (itm && itm.updateType) {
                            setSelectedType(getAreaCountingType(itm.area_type))
                        }
                        const area = cloneAreaPack.area_list.find(el => el.area_number === parseInt(areaNumber))
                        if (area) setEditAreaId(area.area_id)                        
                    }
                }
            }
            reader.readAsText(file)
        }
    }, [csvData, editingAreaPack, updateEditingAreaPack])

    const handleChangeNumber = useCallback((newNumber: string) => { setAreaNumber(newNumber) }, [])

    const handleChangeName = useCallback((newName: string) => { setAreaName(newName) }, [])

    const handleChangeType = useCallback((newType: AreaType) => { setSelectedType(newType) }, [])

    // ボタンタイトル（編集）
    const btnTitleNameEdit = useMemo(() => { return t("edit") }, [t])

    // ボタンタイトル（削除）
    const btnTitleDelete = useMemo(() => { return t("delete") }, [t])

    /**
     * 登録済みエリアの一覧データを作成します。
     */
    const items = useMemo(() => {
        if (editingAreaPack && editingAreaPack.area_list) {
            // Writer内で編集中エリアはIDがマイナスになるので除外する
            return editingAreaPack.area_list.filter(el => el.area_id > 0).map((el, i) => {
                const hasMap: boolean = el.cell_ids.length > 0
                return (
                    <tr key={i} className={style.tdRow} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        <td className={style.tdId}>{el.area_number}</td>
                        <td className={style.tdName}>{el.name}</td>
                        <td className={hasMap ? style.tdMap : style.tdNone}>{hasMap ? "選択済み" : "未選択"}</td>
                        <td className={style.tdType}>{t("areaType." + el.area_type)}</td>
                        <td className={style.tdLink}>
                            <button className={style.iconBtn} title={btnTitleNameEdit} onClick={(e) => handleAreaReEdit(e, el.area_id)}><PencilIcon size={16} /></button>
                            <span className={style.iconSpace}>　</span>
                            <button className={style.iconBtn} title={btnTitleDelete} onClick={(e) => handleAreaDelete(e, el.area_id)}><TrashIcon size={16} /></button>
                        </td>
                    </tr>
                )
            })
        }
        return undefined
    }, [editingAreaPack, handleAreaDelete, handleAreaReEdit, btnTitleNameEdit, btnTitleDelete, t, handleMouseOver, handleMouseOut])

    return (
        <BaseFrame actPage={PageName.InfoArea} type={ContentsFrameType.Basic}>
            <div className={style.panel}>
                <div className={style.head}>
                    {isProduction ? (<TrackingPageView page_title="beinfo-area-regist-edit" />) : (null)}
                    <div className={style.title}>{t("areaEntry")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.body}>
                    <div className={style.subtitle}>店舗・レイアウト情報選択</div>
                    <div className={style.baseRow}>
                        <AreaRegiSelector mode={EditModeType.New} />
                    </div>
                    <AreaRegiMapper
                        mode={(mode === EditModeType.New) ? EditModeType.New : EditModeType.Edit}
                        areaId={editAreaId}
                        areaNumber={areaNumber}
                        areaName={areaName}
                        selectedType={selectedType}
                        onChangeNumber={handleChangeNumber}
                        onChangeName={handleChangeName}
                        onChangeType={handleChangeType}
                    />
                    <div className={style.lotitle}>登録済みエリア</div>
                    <div className={style.csvBlock}>
                        <Button styleType={StyleType.Outline} name="download" label="テンプレートをダウンロード" onClick={handleDownload} />
                        <span className={style.spacer}>　</span>
                        <Button styleType={StyleType.Outline} name="import" label="CSVインポート" onClick={handleImport} />
                        <input type="file" className={style.fileInput} onChange={handleChangeCsvFile} ref={refCsv} />
                    </div>
                    <div className={style.list}>
                        <table className={style.table}>
                            <thead>
                                <tr className={style.thRow}>
                                    <th className={style.thId}>{t("area")}{t("number")}</th>
                                    <th className={style.thName}>{t("areaName")}</th>
                                    <th className={style.thMap}>エリア選択</th>
                                    <th className={style.thType}>{t("area")}{t("type")}</th>
                                    <th className={style.thLink}>Act</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </BaseFrame>
    )
}