import React from "react"
import { useTranslation } from "react-i18next"

import style from "./HeatMapColorBar.module.css"
import { HEATMAP_COLORS } from "../../lib/ColorUtil"

interface Props {}

export const HeatMapColorBar: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const len = HEATMAP_COLORS.length

    const getColorBoxes = () => {
        let result = []
        for (let i = 0; i < len; i++) {
            //スペース
            if (i !== 0) result.push(<div key={i*2} className={style.space}></div>)
            //色つきBOX
            const color = HEATMAP_COLORS[i]
            const text = (i === 0) ? t("few") : (i === len - 1) ? t("many") : "　"
            const elm = (
                <div key={i*2+1} className={style.box} style={{ backgroundColor: color }}>
                    {text}
                </div>
            )
            result.push(elm)
        }
        return result
    }
    
    return (
        <div className={style.main}>
            {
                getColorBoxes()
            }            
        </div>
    )
}