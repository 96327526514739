import { FC, useEffect, useRef, useState, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { PageName } from "../../types"
import { Stepper } from "../../component/stepper/Stepper"
import { Button, StyleType } from "../../component/button/Button"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { FileUpload, FileUploadIconType } from "../../component/file_upload/FileUpload"
import { CustomDialog, AcceptButtonType } from "../../component/custom_dialog/CustomDialog"
import { BaseFrame, ContentsFrameType } from "../common/BaseFrame"

import style from "./LayoutRegiStep3.module.css"
import { ModalFrame } from "../common/ModalFrame"

interface Props {}

export const LayoutRegiStep3: FC<Props> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { worker } = useManagementDataContext()    

    const [file, setFile] = useState<File | undefined>(undefined)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const ref = useRef<HTMLImageElement>(null)

    /**
     * ステップラベルのリスト
     */
    const stepperLabelList = useMemo(() => {
        const stp1 = t("label.layoutRegiStep1")
        const stp2 = t("label.layoutRegiStep2")
        const stp3 = t("label.layoutRegiStep3")
        const stp4 = t("label.layoutRegiStep4")
        const stp5 = t("label.layoutRegiStep5")
        return [stp1, stp2, stp3, stp4, stp5]
    }, [t])

    const handleClose = () => {
        if (!modalOpen) setModalOpen(true)
    }

    const onCanceledClose = () => {
        setModalOpen(false)
    }

    const onAcceptedClose = () => {
        navigate("/layout_list")
    }

    const handleHistoryBack = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        if (worker) worker.printNeo()
        navigate("/layout_regi_step4", { state: { from: location } })
    }

    const handleFileChanged = (file: File) => {
        setFile(file)
        // 選択ファイルを読み込んで表示する
        const reader = new FileReader()
        reader.onload = (theFile) => {
            if (theFile.target) {
                viewImage(theFile.target.result)
            }
        }
        reader.readAsDataURL(file)
    }

    /**
     * 画面に画像を表示する
     * @param src 
     */
    const viewImage = (src: string | ArrayBuffer | null) => {
        if (ref) {
            const elm: HTMLImageElement = ref.current as HTMLImageElement
            if (elm) {
                if (src && typeof src === 'string') elm.src = src
            }
        }
    }

    useEffect(() => {
        if (file && worker) {
            worker.neoImageLoad(file).then(res => {
                console.log('neoImage loaded.')
            })
        }
    }, [file, worker])
    
    return (
        <ModalFrame page={PageName.InfoMap} title={t("menu.mapRegistrationEdit")} width={1110} height={700} >
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>{t("header.layoutNewEntry")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.indicator}>
                    <div className={style.steps}>
                        <Stepper activeNumber={3} labels={stepperLabelList}></Stepper>
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.subTitle}>{t("header.layoutUpload")}</div>
                    <div className={style.uploader}>
                        {
                            file ? (
                                <section>
                                    <img alt={file.name} ref={ref} className={style.preview}></img>
                                </section>
                            ) : (
                                <FileUpload
                                    iconType={FileUploadIconType.Down}
                                    guideOn={false}
                                    upload={handleFileChanged}
                                    errorMessage=""
                                    isLoading={false}
                                />                                    
                            )
                        }
                    </div>
                    <div className={style.navi}>
                        <Button name="next" label={t("button.toTheNext")} styleType={StyleType.Normal} onClick={onClickNext}/>
                    </div>
                    <div className={style.back}>
                        <Button name="back" label={t("back")} styleType={StyleType.MuliInact} onClick={handleHistoryBack}/>
                    </div>
                </div>
            </div>
            <CustomDialog
                requestOpen={modalOpen}
                width={500}
                height={170}
                guideMessage={t("guidance.cancelLayoutRegi")}
                buttonType={AcceptButtonType.Ok}
                onCancel={onCanceledClose}
                onAccept={onAcceptedClose}
            >
            </CustomDialog>
        </ModalFrame>
    )
}