import { AxiosRequestConfig } from "axios";

// ランキング取得数
export const RANKING_TOP_100 = 100

// 動線ページングサイズ
export const PAGING_SIZE = 1000

// サンプリング取得数
export const SAMPLING_HALF_SIZE = 5000

export const axiosOptions: AxiosRequestConfig<any> = {
    responseType: "json",
    responseEncoding: "utf8",
    withCredentials: true,
}

// ElasticSearchの検索結果取得の仕様上限
export const OPENSEARCH_SIZE_LIMIT = 10000
