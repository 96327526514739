import { useState, useEffect } from "react"

export type OffsetType = {
    pageYOffset: number | undefined
}

export const useScroll = () => {

    const [offset, setOffset] = useState<OffsetType>({
        pageYOffset: undefined
    })

    useEffect(() => {
        // スクロールイベントの処理ハンドラー
        const handleScroll = () => {
            setOffset({ pageYOffset: window.pageYOffset})
        }
        // イベント登録
        window.addEventListener("scroll", handleScroll)
        // 初期設定
        handleScroll()
        // DOM消去時のイベント解除
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return offset
}