import MapWriter, { AREA_LINE_WIDTH } from "./MapWriter"
import { ResLayout, ResArea } from "../api/data/analysis/FullLayout"
import { AREA_BORDER_COLOR } from "./ColorUtil"
import { AreaCountingType } from "../api/data/core/Enums"
import { Coord } from "../api/data/core/Coord"
import { EditMode, EditModeType } from "../types/Management"
import { NEW_AREA_ID, NEW_NUMBER } from "../constants"
import { PictType, createDetectionMap } from "../lib/WriterUtil"
import { Array2 } from "../component"
import { create } from "sortablejs"
import { start } from "repl"

export type CellType = {
    row: number     // Meshu上の列位置（カメラ原点基準）
    col: number     // 　〃     行位置
    st_x: number    // scaledImage上の左上座標X[px]
    ed_x: number    // 　〃　　　　　　右下座標X[px]
    st_y: number    // scaledImage上の左上座標Y[px]
    ed_y: number    // 　〃　　　　　　右下座標Y[px]
    area_id: number | undefined         // エリアID undefined=未選択
    //connectCheck?: ConnectionCheckType  // 接続チェック結果
}

/*export type ConnectionCheckType = {
    delete: boolean   // 削除対象フラグ
    times: number     // 探索回数
}*/

export const DirectionType = {
    Top: "top",
    Right: "right",
    Bottom: "bottom",
    Left: "left"
} as const
type Directions = typeof DirectionType[keyof typeof DirectionType]

/*export type SearchReservedType = {
    parentCell: CellType
    direction: Directions
}*/

export type SearchReservedType = {
    parent: PictType
    direction: Directions
}


/*
export type DirectionCheckType = {
    isLive: boolean    // つながっているかどうか
    isDone: boolean    // ４方向探索済みかどうか
    isSuccess: boolean // 探索成功かどうか
    top?: boolean      // 上の探索結果 true=つながっている
    bottom?: boolean   // 下の探索結果
    left?: boolean     // 左の探索結果
    right?: boolean    // 右の探索結果
}

export type PolarizationCheckType = {
    xy: Coord
    check: DirectionCheckType
}

export const ActorDirectionType = {
    Top: "top",
    Right: "right",
    Bottom: "bottom",
    Left: "left"
} as const
type ActorDirection = typeof ActorDirectionType[keyof typeof ActorDirectionType]

export type ReservedCellType = {
    x: number
    y: number
    parentCell: PolarizationCheckType
    direction: ActorDirection
}
*/

// メッシュの色（原点）
const MESH_ORG_COLOR = "rgba(250,0,0,20%)"
// メッシュの色
const MESH_COLOR = "rgba(0,0,0,20%)"
// メッシュの線太さ
const MESH_LINE_WIDTH = 1

// エリア番号の円半径[px]
const NUM_LABEL_RADIUS = 10

// マウスマーカーの色
const MARKER_COLOR_BLUE = "rgba(30,94,255,50%)" // "#1E5EFF 50%"
//const MARKER_COLOR_RED = "rgba(255,94,30,50%)"
const MARKER_COLOR_GREEN = "rgba(30,255,30,30%)"
const MARKER_COLOR_GRAY = "rgba(120,120,120,10%)"

// 編集中のエリアの色
export const EDIT_AREA_COLOR = "rgb(30,94,255)" //"#1E5EFF" 100%

class AreaEditPicWriter extends MapWriter {

    public cellMatrix: CellType[][] // セルのマッピング行列
    public cellStartRow: number     // セルのスタート行番号（カメラ原点が0なのでマイナス値）
    public cellStartCol: number     // 　〃　　　　　列番号　〃
    public cellEndRow: number       // セルのエンド行番号
    public cellEndCol: number       // 　〃　　　　列番号
    public detectionMap: PictType[][] // 削除検出マップ（画像表示している部分とその周辺の情報）

    public pxDispStartX: number = 0 // 画像表示座標の左上X[px]（仮想キャンバス上の値）
    public pxDispStartY: number = 0 // 　〃　　　　　　　Y[px]
    public pxDispEndX: number = 0   // 画像表示座標の右下X[px]
    public pxDispEndY: number = 0   // 　〃　　　　　　　Y[px]

    // セル編集関連
    public pointerEditArea: ResArea | undefined // 編集中のエリアのポインター
    public editAreaId: number = NEW_AREA_ID // 編集中のエリアID
    public editMode: EditMode       // 編集モード
    public areaChangeCallback: any  // エリア更新時のコールバック
    public pointedCell: CellType | undefined = undefined // マウスが当たっているセル
    public editStartRow: number | undefined = undefined // 編集中エリアの開始セル行番号
    public editStartCol: number | undefined = undefined // 　〃　　　　　列番号
    public editEndRow: number | undefined = undefined   // 編集中エリアの終了セル行番号
    public editEndCol: number | undefined = undefined   // 　〃　　　　　列番号

    /**
     * コンストラクタ
     * @param layout 
     * @param areaList sy
     * @param canvasWidth 
     * @param canvasHeight 
     */
    constructor(layout: ResLayout, areaList: ResArea[], canvasWidth: number, canvasHeight: number, mode: EditMode, targetAreaId: string | null) {
        super(layout, areaList, canvasWidth, canvasHeight)
        //console.log("AreaEditPicWriter initialize .............")
        // セル初期化
        this.cellStartCol = Math.ceil(this.origin_x / this.area_unitcell_pixel) * -1
        this.cellStartRow = Math.ceil(this.origin_y / this.area_unitcell_pixel) * -1
        this.cellEndCol = Math.ceil(this.pixel_width / this.area_unitcell_pixel) + this.cellStartCol
        this.cellEndRow = Math.ceil(this.pixel_height / this.area_unitcell_pixel) + this.cellStartRow
        this.cellMatrix = []
        this.detectionMap = []
        this.editMode = mode
        // View/Editモード時はエリアIDを設定、Newモード時はNEW_AREA_ID（デフォルト値）
        if (this.editMode === EditModeType.View || this.editMode === EditModeType.Edit) {
            if (targetAreaId) {
                const id = parseInt(targetAreaId)
                this.editAreaId = id
            }
        }
        // 編集対象エリアを設定
        if (this.area_list) {
            // 対象を探して設定
            const area = this.area_list.find(el => el.area_id === this.editAreaId)
            if (area) {
                this.pointerEditArea = area
                // 左上と右下のセルを設定
                this.setEditAreaCorner()
            } else {
                if (this.editAreaId === NEW_AREA_ID) {
                    // 空のResAreaを追加する
                    const empty: ResArea = {
                        area_id: this.editAreaId,
                        area_number: NEW_NUMBER,
                        name: "",
                        cell_ids: [],
                        area_type: AreaCountingType.Normal,
                        group_id: null
                    }
                    this.area_list.push(empty)
                    this.pointerEditArea = empty
                }
            }
        }
        // セル編集イベントのコールバックを設定
        this.areaChangeCallback = null
    }

    /**
     * セルとImageの末端の誤差を返します。
     * （カメラ原点位置をセルの基点としているので、画像の左上のセルがいくらかズレている。それがここでいう誤差）
     * @returns X[px], Y[px]
     */
    missBetweenImageAndEndCell(): Array2<number> {
        const misX = (this.origin_x) + (this.cellStartCol * this.area_unitcell_pixel)
        const misY = (this.origin_y) + (this.cellStartRow * this.area_unitcell_pixel)
        return [misX, misY]
    }

    /**
     * 画面に表示されているセルの左上と右下のセルを返します。（カメラ原点基準）
     */
    getViewportCell(): Array2<Coord> {
        const [misX, misY] = this.missBetweenImageAndEndCell()
        const stX = Math.floor((((this.pxDispStartX) / this.imageScale) - this.origin_x - misX) / this.area_unitcell_pixel)
        const stY = Math.floor((((this.pxDispStartY) / this.imageScale) - this.origin_y - misY) / this.area_unitcell_pixel)
        const edX = Math.ceil((((this.pxDispEndX + 1) / this.imageScale) - this.origin_x - misX) / this.area_unitcell_pixel)
        const edY = Math.ceil((((this.pxDispEndY + 1) / this.imageScale) - this.origin_y - misY) / this.area_unitcell_pixel)
        return [{ x: stX, y: stY }, { x: edX, y: edY }]
    }

    /**
     * Matrix上のセルからdetectinMap上のセルオブジェクトを取得します。
     * @param cel 
     * @returns 
     */
    getDetectionMapCellFromMatrixCell(cel: CellType): PictType | undefined {
        if (this.detectionMap.length === 0) return undefined
        const [{ x: stX, y: stY }, { x: edX, y: edY }] = this.getViewportCell()
        const y = cel.row - stY + 1 
        const x = cel.col - stX + 1
        //console.log("getDetectionMapCellFromMatrixCell cel,result:", cel, this.detectionMap[y][x])
        return this.detectionMap[y][x]
    }

    /**
     * 編集用セルマップ作成
     * @returns 
     */
    private createCellMatrix() {
        //console.log("create matrix")
        return new Promise((resolve, reject) => {
            this.cellMatrix = []
            //セルとImageの末端の誤差
            //const misX = (this.origin_x) + (this.cellStartCol * this.area_unitcell_pixel)
            //const misY = (this.origin_y) + (this.cellStartRow * this.area_unitcell_pixel)
            const [misX, misY] = this.missBetweenImageAndEndCell()
            for (let r = this.cellStartRow; r < this.cellEndRow; r++) {
                const one_row: CellType[] = []
                const idxRow = r - this.cellStartRow
                const sy = (idxRow * this.area_unitcell_pixel + misY) * this.imageScale
                const ey = ((idxRow + 1) * this.area_unitcell_pixel + misY) * this.imageScale - 1
                for (let c = this.cellStartCol; c < this.cellEndCol; c++) {
                    const idxCol = c - this.cellStartCol
                    const sx = (idxCol * this.area_unitcell_pixel + misX) * this.imageScale
                    const ex = ((idxCol + 1) * this.area_unitcell_pixel + misX) * this.imageScale - 1
                    const cell: CellType = { row: r, col: c, st_x: sx, st_y: sy, ed_x: ex, ed_y: ey, area_id: undefined }
                    one_row.push(cell)
                }
                this.cellMatrix.push(one_row)
            }
            for (let area of this.area_list) {
                for (let cel of area.cell_ids) {
                    const matrixCel = this.cellMatrix[cel.y - this.cellStartRow][cel.x - this.cellStartCol]
                    matrixCel.area_id = area.area_id
                    //console.log("cel, oneCel:", cel, matrixCel)
                }
            }
            resolve(true)
        })
    }

    /**
     * 大きい窓の表示位置変更時に関連パラメーターを再計算
     */
    private async updateEditParams() {
        this.pxDispStartX = this.vtLeft * this.imageScale * -1
        this.pxDispStartY = this.vtTop * this.imageScale * -1
        this.pxDispEndX = this.pxDispStartX + this.canvasWidth
        this.pxDispEndY = this.pxDispStartY + this.canvasHeight
        // 削除検出マップ作成
        /*if (this.cellMatrix.length > 0 && this.editAreaId && this.pointerEditArea && this.pointerEditArea.cell_ids.length > 0 && this.editStartCol !== undefined && this.editStartRow !== undefined && this.editEndCol !== undefined && this.editEndRow !== undefined) {
            const [{ x: stX, y: stY }, { x: edX, y: edY }] = this.getViewportCell()
            console.log("create detection map start", performance.now())
            createDetectionMap(this.cellMatrix, this.editAreaId, stX, stY, edX, edY, this.editStartCol, this.editStartRow, this.editEndCol, this.editEndRow).then(res => {
                console.log("create detection map done", performance.now())
                this.detectionMap = res
            })
        }*/
    }

    /**
     * 親である虫眼鏡の大きさに合わせる。
     * @param magWidth 虫眼鏡の幅
     * @param magHeight 虫眼鏡の高さ
     * @param posX 画像の左上端からの距離X(pixel)
     * @param posY 　〃　　　　　　　　　Y
     */
    public setMagScale(magWidth: number, magHeight: number, posX: number, posY: number) {
        const xScale = this.canvasWidth / magWidth
        const yScale = this.canvasHeight / magHeight
        this.fitScale = Math.min(xScale, yScale)
        //console.log("recalc fitScale:", this.fitScale)
        this.imageScale = this.fitScale
        this.vtCvsWidth = this.canvasWidth / this.fitScale
        this.vtCvsHeight = this.canvasHeight / this.fitScale
        this.vtTop = posY * -1
        this.vtLeft = posX * -1
        this.memoLeft = this.vtLeft
        this.memoTop = this.vtTop
        // セルマップ作成
        //console.log("create cell matrix start", performance.now())
        this.createCellMatrix().then(res => {
            //console.log("create cell matrix done", performance.now())
            // 編集ウィンドウパラメーター更新
            this.updateEditParams()
        })
    }

    /**
     * 親から画像の移動を受け取る処理
     * @param posX 画像左上端からの相対距離X
     * @param posY 　〃　　　　　　　　　　Y
     */
    public moveSlave(posX: number, posY: number) {
        this.vtLeft = posX * -1
        this.vtTop = posY * -1
        //console.log("move slave:", this.vtLeft, this.vtTop)
        this.updateEditParams()
        // 削除検出マップ作成
        if (this.cellMatrix.length > 0 && this.editAreaId && this.pointerEditArea && this.editStartCol !== undefined && this.editStartRow !== undefined && this.editEndCol !== undefined && this.editEndRow !== undefined) {
            const [{ x: stX, y: stY }, { x: edX, y: edY }] = this.getViewportCell()
            //console.log("create detection map start", performance.now())
            createDetectionMap(this.cellMatrix, this.editAreaId, stX, stY, edX, edY, this.editStartCol, this.editStartRow, this.editEndCol, this.editEndRow).then(res => {
                //console.log("create detection map done", performance.now())
                this.detectionMap = res
                //console.log("detectionMap[0,0], [max,max]:", this.detectionMap[0][0], this.detectionMap[this.detectionMap.length - 1][this.detectionMap[0].length - 1])
            })
        }
    }

    /**
     * 大きい窓にメッシュを描画します。
     * @param ctx 
     */
    async drawUnitMesh(ctx: CanvasRenderingContext2D) {
        // 大きい窓の座標は、カメラ原点位置が(0,0)となっている。
        // vtLeftとvtTopはマップ上ではマイナスとなっている。
        const org_x = this.origin_x * this.imageScale
        const org_y = this.origin_y * this.imageScale
        const st_x = (this.area_unitcell_pixel * this.cellStartCol + this.origin_x) * this.imageScale
        const ed_x = (this.area_unitcell_pixel * this.cellEndCol + this.origin_x) * this.imageScale
        const st_y = (this.area_unitcell_pixel * this.cellStartRow + this.origin_y) * this.imageScale
        const ed_y = (this.area_unitcell_pixel * this.cellEndRow + this.origin_y) * this.imageScale
        const top = (st_y <= this.pxDispStartY) ? 0 : (st_y - this.pxDispStartY)
        const btm = (this.pxDispEndY <= ed_y) ? this.canvasHeight : this.canvasHeight - (this.pxDispEndY - ed_y)
        const left = (st_x <= this.pxDispStartX) ? 0 : (st_x - this.pxDispStartX)
        const right = (this.pxDispEndX <= ed_x) ? this.canvasWidth : this.canvasWidth - (this.pxDispEndX - ed_x)
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
        // 原点クロスライン（赤）
        if (this.pxDispStartX < org_x && org_x < this.pxDispEndX) {
            const px = org_x - this.pxDispStartX
            ctx.beginPath()
            ctx.strokeStyle = MESH_ORG_COLOR
            ctx.lineWidth = MESH_LINE_WIDTH
            ctx.moveTo(px, top)
            ctx.lineTo(px, btm)
            ctx.stroke()
        }
        if (this.pxDispStartY < org_y && org_y < this.pxDispEndY) {
            const py = org_y - this.pxDispStartY
            ctx.beginPath()
            ctx.strokeStyle = MESH_ORG_COLOR
            ctx.lineWidth = MESH_LINE_WIDTH
            ctx.moveTo(left, py)
            ctx.lineTo(right, py)
            ctx.stroke()
        }
        // 原点の前後のライン
        for (let i = this.cellStartCol; i <= this.cellEndCol; i++) {
            if (i !== 0) {
                const pt = (this.area_unitcell_pixel * i + this.origin_x) * this.imageScale
                if (this.pxDispStartX < pt && pt < this.pxDispEndX) {
                    const px = pt - this.pxDispStartX
                    ctx.beginPath()
                    ctx.strokeStyle = MESH_COLOR
                    ctx.lineWidth = MESH_LINE_WIDTH
                    ctx.moveTo(px, top)
                    ctx.lineTo(px, btm)
                    ctx.stroke()
                }
            }
        }
        for (let i = this.cellStartRow; i <= this.cellEndRow; i++) {
            if (i !== 0) {
                const pt = (this.area_unitcell_pixel * i + this.origin_y) * this.imageScale
                if (this.pxDispStartY < pt && pt < this.pxDispEndY) {
                    const py = pt - this.pxDispStartY
                    ctx.beginPath()
                    ctx.strokeStyle = MESH_COLOR
                    ctx.lineWidth = MESH_LINE_WIDTH
                    ctx.moveTo(left, py)
                    ctx.lineTo(right, py)
                    ctx.stroke()
                }
            }
        }
    }

    /**
     * 大きい窓にエリア枠とエリア番号を描画します。
     * @param ctx 
     */
    async drawArea(ctx: CanvasRenderingContext2D) {
        if (this.area_list) {
            //this.activeAreas = []
            for await (let area of this.area_list) {
                //const color = (area.area_id === NEW_AREA_ID) ? EDIT_AREA_COLOR : AREA_BORDER_COLOR
                const color = (area.area_id === this.editAreaId) ? EDIT_AREA_COLOR : AREA_BORDER_COLOR
                const lines = this.convertCells2DrawLines(area.cell_ids)
                let minX, minY
                if (lines.length > 0) {
                    for await (let ln of lines) {
                        const lnstx = (ln[0] * this.area_unitcell_pixel + this.origin_x) * this.imageScale
                        const lnedx = (ln[1] * this.area_unitcell_pixel + this.origin_x) * this.imageScale
                        const lnsty = (ln[2] * this.area_unitcell_pixel + this.origin_y) * this.imageScale
                        const lnedy = (ln[3] * this.area_unitcell_pixel + this.origin_y) * this.imageScale
                        if (minX === undefined || lnstx < minX) minX = lnstx
                        if (minX === undefined || lnedx < minX) minX = lnedx
                        if (minY === undefined || lnsty < minY) minY = lnsty
                        if (minY === undefined || lnedy < minY) minY = lnedy
                        let x1, x2, y1, y2
                        // 横ライン
                        if (lnsty === lnedy && this.pxDispStartY <= lnsty && lnsty <= this.pxDispEndY) {
                            y1 = lnsty
                            y2 = lnedy
                            if (this.pxDispStartX <= lnstx && lnedx <= this.pxDispEndX) {
                                // 窓の内側
                                x1 = lnstx
                                x2 = lnedx
                            } else if (lnstx < this.pxDispStartX && lnedx <= this.pxDispEndX) {
                                // スタートがはみ出し
                                x1 = this.pxDispStartX
                                x2 = lnedx
                            } else if (this.pxDispStartX <= lnstx && this.pxDispEndX < lnedx) {
                                // エンドがはみ出し
                                x1 = lnstx
                                x2 = this.pxDispEndX
                            } else if (lnstx < this.pxDispStartX && this.pxDispEndX < lnedx) {
                                // 窓より長い
                                x1 = this.pxDispStartX
                                x2 = this.pxDispEndX
                            }
                        }
                        // 縦ライン
                        if (lnstx === lnedx && this.pxDispStartX < lnstx && lnstx < this.pxDispEndX) {
                            x1 = lnstx
                            x2 = lnedx
                            if (this.pxDispStartY <= lnsty && lnedy <= this.pxDispEndY) {
                                y1 = lnsty
                                y2 = lnedy
                            } else if (lnsty < this.pxDispStartY && lnedy <= this.pxDispEndY) {
                                y1 = this.pxDispStartY
                                y2 = lnedy
                            } else if (this.pxDispStartY <= lnsty && this.pxDispEndY < lnedy) {
                                y1 = lnsty
                                y2 = this.pxDispEndY
                            } else if (lnsty < this.pxDispStartY && this.pxDispEndY < lnedy) {
                                y1 = this.pxDispStartY
                                y2 = this.pxDispEndY
                            }
                        }
                        if (x1 && x2 && y1 && y2) {
                            ctx.strokeStyle = color
                            ctx.lineWidth = AREA_LINE_WIDTH
                            ctx.beginPath()
                            ctx.moveTo(x1 - this.pxDispStartX, y1 - this.pxDispStartY)
                            ctx.lineTo(x2 - this.pxDispStartX, y2 - this.pxDispStartY)
                            ctx.stroke()
                        }
                    }
                }
                // エリア番号
                if (minX && minY) {
                    const strNum = (area.area_number === NEW_NUMBER) ? "?" : area.area_number.toString()
                    if (this.pxDispStartX < minX && minX < this.pxDispEndX && this.pxDispStartY < minY && minY < this.pxDispEndY) await this.drawArcNumber(ctx, minX + this.vtLeft * this.imageScale, minY + this.vtTop * this.imageScale, strNum, color)
                }
            }
        }
    }

    /**
     * エリア番号ラベルを描画します。
     * @param ctx 
     * @param x 
     * @param y 
     * @param num 
     */
    async drawArcNumber(ctx: CanvasRenderingContext2D, x: number, y: number, num: string, color: string) {
        // 円
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.fillStyle = color;
        ctx.arc(x + 2, y + 2, NUM_LABEL_RADIUS, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.stroke();
        // テキスト
        ctx.beginPath();
        ctx.strokeStyle = 'white';
        ctx.fillStyle = 'white';
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(num, x + 2, y + 2, NUM_LABEL_RADIUS * 2);
        ctx.stroke(); 
    }

    /**
     * 窓の上のマウス位置を示す青いマーカーを消します。
     * @param aniCtx 
     */
    async clearMarker(aniCtx: CanvasRenderingContext2D) {
        aniCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    }

    /**
     * マウス位置にマーカーを描きます。
     * @param aniCtx 
     * @param cel 
     * @param color 
     */
    drawCellMarker(aniCtx: CanvasRenderingContext2D, cel: CellType, color: string) {
        aniCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
        const px = cel.st_x - this.pxDispStartX
        const py = cel.st_y - this.pxDispStartY
        const w = (cel.ed_x - cel.st_x)
        const h = (cel.ed_y - cel.st_y)
        aniCtx.beginPath()
        aniCtx.fillStyle = color
        aniCtx.fillRect(px, py, w, h)
        aniCtx.stroke()
    }

    /**
     * 大きい窓に背景画像とエリアを描画します。
     * @param imgCtx 
     * @param drwCtx 
     */
    async drawImage4Editor(imgCtx: CanvasRenderingContext2D, drwCtx: CanvasRenderingContext2D) {
        imgCtx.save()
        imgCtx.scale(this.imageScale, this.imageScale)
        await this.drawImage(imgCtx)
        await this.drawUnitMesh(drwCtx)
        await this.drawArea(drwCtx)
        imgCtx.restore()
    }

    /**
     * マウスのキャンバス上の位置を算出・記憶します。
     * @param event 
     */
    async setMouseMoveXY(event: MouseEvent) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            this.mouseMoveX = (event.clientX - rect.left)
            this.mouseMoveY = (event.clientY - rect.top)
        }
    }

    /**
     * マウスの当たっているセルを取得します。
     * @returns 
     */
    async getCellOnMouse() {
        // 窓内のマウス位置を拡大Image上の座標に変換
        const mpX = this.mouseMoveX + this.pxDispStartX
        const mpY = this.mouseMoveY + this.pxDispStartY
        // マトリックスから探す
        for (let row = this.cellStartRow; row < this.cellEndRow; row++) {
            for (let col = this.cellStartCol; col < this.cellEndCol; col++) {
                const cel = this.cellMatrix[row - this.cellStartRow][col - this.cellStartCol]
                if (cel.st_x < mpX && cel.st_y < mpY && mpX < cel.ed_x && mpY < cel.ed_y) {
                    // セルの範囲に合致
                    return cel
                }
            }
        }
        return undefined
    }

    /**
     * 指定されたセルの上下左右に新規エリアがあるかどうかをチェックします。
     * @param cel 
     * @returns 
     */
    async fourWayCellCheck(cel: CellType) {
        if (!this.pointerEditArea || this.pointerEditArea.cell_ids.length === 0) return true
        const col = cel.col - this.cellStartCol
        const row = cel.row - this.cellStartRow
        const top = (row > 0) ? this.cellMatrix[row - 1][col] : undefined
        const bottom = (row + 1 < this.cellMatrix.length) ? this.cellMatrix[row + 1][col] : undefined
        const left = (col > 0) ? this.cellMatrix[row][col - 1] : undefined
        const right = (col + 1 < this.cellMatrix[0].length) ? this.cellMatrix[row][col + 1] : undefined
        if ((top && top.area_id === this.editAreaId)
            || (bottom && bottom.area_id === this.editAreaId)
            || (left && left.area_id === this.editAreaId)
            || (right && right.area_id === this.editAreaId)) return true
        return false
    }

    /**
     * セル削除前チェック
     * 指定されたセルを削除しても良いかどうかをチェックします。セル同士は上下左右の４方向でつながっている必要があります。
     * 削除することでエリアが分断される場合は削除できません。
     * 
     * @param cel 
     * @returns 削除可能ならtrue
     */
    async checkBeforeCellDeletion(cel: CellType): Promise<boolean> {
        if (this.pointerEditArea) {
            const len = this.pointerEditArea.cell_ids.length
            // エリアのセルがない場合は削除不可
            if (len === 0) return false
            // もしもdetectionMapがなければ作成する（エリアの追加時に生じる事象対策）
            if (this.detectionMap.length === 0) {
                // 削除検出マップ作成
                if (this.cellMatrix.length > 0 && this.editAreaId && this.pointerEditArea && this.editStartCol !== undefined && this.editStartRow !== undefined && this.editEndCol !== undefined && this.editEndRow !== undefined) {
                    const [{ x: stX, y: stY }, { x: edX, y: edY }] = this.getViewportCell()
                    //console.log("create detection map start", performance.now())
                    createDetectionMap(this.cellMatrix, this.editAreaId, stX, stY, edX, edY, this.editStartCol, this.editStartRow, this.editEndCol, this.editEndRow).then(res => {
                        console.log("create detection map done", performance.now())
                        this.detectionMap = res
                        //console.log("detectionMap[0,0], [max,max]:", this.detectionMap[0][0], this.detectionMap[this.detectionMap.length - 1][this.detectionMap[0].length - 1])
                    })
                }
            }
            // エリアの全セル数が２個まで
            if (len === 1 || len === 2) {
                return true
            }
            // ３セル以上の場合
            //console.log("checkBeforeCellDeletion cel:", cel)
            if (this.cellMatrix && this.cellMatrix.length > 0 && this.detectionMap && this.detectionMap.length > 0) {
                // MapのconnectCheckを初期化
                const height = this.detectionMap.length
                const width = this.detectionMap[0].length
                const seriY = [...Array(height)].map((_, i) => i)
                const seriX = [...Array(width)].map((_, i) => i)
                for await (let row of seriY) {
                    for await (let col of seriX) {
                        const cel = this.detectionMap[row][col]
                        cel.connectCheck = undefined
                    }
                }
                // Mapの位置を取得
                const mtxRow = cel.row - this.cellStartRow
                const mtxCol = cel.col - this.cellStartCol
                //console.log("checkBeforeCellDeletion mtxRow, mtxCol:", mtxRow, mtxCol)
                let mapRow: number | undefined = undefined
                for await (let i of seriY) {
                    if (this.detectionMap[i][0].matY === mtxRow) {
                        mapRow = i
                        break
                    }
                }
                let mapCol: number | undefined = undefined
                for await (let i of seriX) {
                    if (this.detectionMap[0][i].matX === mtxCol) {
                        mapCol = i
                        break
                    }
                }
                if (mapRow === undefined || mapCol === undefined) return false
                // 削除対象のマーキング
                this.detectionMap[mapRow][mapCol].connectCheck = { delete: true, times: 0 }
                //console.log("checkBeforeCellDeletion target:", this.detectionMap[mapRow][mapCol])
                // 直近４セルをチェック
                const tt = this.detectionMap[mapRow - 1] ? this.detectionMap[mapRow - 1][mapCol] : undefined
                const top = (tt && tt.id === this.editAreaId) ? tt : undefined
                const bb = this.detectionMap[mapRow + 1] ? this.detectionMap[mapRow + 1][mapCol] : undefined
                const bottom = (bb && bb.id === this.editAreaId) ? bb : undefined
                const ll = this.detectionMap[mapRow] ? this.detectionMap[mapRow][mapCol - 1] : undefined
                const left = (ll && ll.id === this.editAreaId) ? ll : undefined
                const rr = this.detectionMap[mapRow] ? this.detectionMap[mapRow][mapCol + 1] : undefined
                const right = (rr && rr.id === this.editAreaId) ? rr : undefined
                let directionCount = 0
                if (top !== undefined) directionCount++
                if (bottom !== undefined) directionCount++
                if (left !== undefined) directionCount++
                if (right !== undefined) directionCount++
                //console.log("checkBeforeCellDeletion editAreaId, directionCount, t, b, l ,r:", this.editAreaId, directionCount, top, bottom, left, right)
                if (directionCount < 2) return true
                // ４方向のセルの中間セルで接続チェック
                const topRight = (top && right) ? this.detectionMap[mapRow - 1][mapCol + 1] : undefined
                const rightBottom = (right && bottom) ? this.detectionMap[mapRow + 1][mapCol + 1] : undefined
                const bottomLeft = (bottom && left) ? this.detectionMap[mapRow + 1][mapCol - 1] : undefined
                const leftTop = (left && top) ? this.detectionMap[mapRow - 1][mapCol - 1] : undefined
                if (directionCount === 2) {
                    // 隣り合うセルとその中間セルがあれば削除OK
                    if (top && right && topRight && topRight.id === this.editAreaId) return true
                    if (right && bottom && rightBottom && rightBottom.id === this.editAreaId) return true
                    if (bottom && left && bottomLeft && bottomLeft.id === this.editAreaId) return true
                    if (left && top && leftTop && leftTop.id === this.editAreaId) return true
                } else if (directionCount === 3) {
                    // 隣り合うセルとその中間セルがあれば削除OK
                    if (top && right && bottom && topRight && rightBottom && topRight.id === this.editAreaId && rightBottom.id === this.editAreaId) return true
                    if (right && bottom && left && rightBottom && bottomLeft && rightBottom.id === this.editAreaId && bottomLeft.id === this.editAreaId) return true
                    if (bottom && left && top && bottomLeft && leftTop && bottomLeft.id === this.editAreaId && leftTop.id === this.editAreaId) return true
                    if (left && top && right && leftTop && topRight && leftTop.id === this.editAreaId && topRight.id === this.editAreaId) return true
                } else if (directionCount === 4) {
                    // 中間セル３つがあれば削除OK
                    if (topRight && rightBottom && bottomLeft && topRight.id === this.editAreaId && rightBottom.id === this.editAreaId && bottomLeft.id === this.editAreaId) return true
                    if (rightBottom && bottomLeft && leftTop && rightBottom.id === this.editAreaId && bottomLeft.id === this.editAreaId && leftTop.id === this.editAreaId) return true
                    if (bottomLeft && leftTop && topRight && bottomLeft.id === this.editAreaId && leftTop.id === this.editAreaId && topRight.id === this.editAreaId) return true
                    if (leftTop && topRight && rightBottom && leftTop.id === this.editAreaId && topRight.id === this.editAreaId && rightBottom.id === this.editAreaId) return true
                }
                // より複雑な探索
                const unknownStack: PictType[] = [] // 未チェックセルのスタック
                const goalStack: PictType[] = []   // 確定したセルのスタック
                if (top && top.id === this.editAreaId) unknownStack.push(top)
                if (bottom && bottom.id === this.editAreaId) unknownStack.push(bottom)
                if (left && left.id === this.editAreaId) unknownStack.push(left)
                if (right && right.id === this.editAreaId) unknownStack.push(right)
                //console.log("checkBeforeCellDeletion unknownStack:", unknownStack)
                // 未チェックセルには２～４個のセルがある
                let timesCount = 0
                while (unknownStack.length > 0) {
                    const searchReservations: SearchReservedType[] = [] // 探索予約
                    const startCell = unknownStack.shift()
                    if (!startCell) continue
                    timesCount++
                    if (!startCell.connectCheck || !startCell.connectCheck.isDone) {
                        // 探索予約
                        const findTop = { parent: startCell, direction: DirectionType.Top }
                        const findRight = { parent: startCell, direction: DirectionType.Right }
                        const findBottom = { parent: startCell, direction: DirectionType.Bottom }
                        const findLeft = { parent: startCell, direction: DirectionType.Left }
                        // POP時に右手探索となるように格納する
                        if (startCell === top) {
                            searchReservations.push(findBottom)
                            searchReservations.push(findLeft)
                            searchReservations.push(findTop)
                            searchReservations.push(findRight)
                        } else if (startCell === right) {
                            searchReservations.push(findLeft)
                            searchReservations.push(findTop)
                            searchReservations.push(findRight)
                            searchReservations.push(findBottom)
                        } else if (startCell === bottom) {
                            searchReservations.push(findTop)
                            searchReservations.push(findRight)
                            searchReservations.push(findBottom)
                            searchReservations.push(findLeft)
                        } else if (startCell === left) {
                            searchReservations.push(findRight)
                            searchReservations.push(findBottom)
                            searchReservations.push(findLeft)
                            searchReservations.push(findTop)
                        }
                    }
                    // 探索開始
                    if (startCell.connectCheck) {
                        startCell.connectCheck.times = timesCount
                        // 探索予約したらisDoneをtrueにする
                        startCell.connectCheck.isDone = true
                    } else {
                        startCell.connectCheck = { delete: false, times: timesCount, isDone: true } 
                    }
                    const goal: PictType | undefined = await this.searchGoalByRightHandMethod(searchReservations, unknownStack, goalStack, timesCount)
                    if (goal) {
                        //console.log("checkBeforeCellDeletion goal:", goal)
                        // 探索成功
                        goalStack.push(startCell)
                        if (!(goalStack.length > 0 && goalStack.find(el => el === goal))) {
                            goalStack.push(goal)
                            unknownStack.splice(unknownStack.indexOf(goal), 1)
                        }
                    } else {
                        return false
                    }
                }
                // unknownStackが空になったら探索終了
                return true
            }
        }
        return false
    }

    /**
     * 予約されたセル探索を実行します。
     * 予約スタックが空になるまで繰り返します。予約スタックが空になったら探索失敗となります。
     * 探索は右手法で行います。
     * 予約にあるセルが、unknownStackかgoalStackにあるセルに到達するまであるいは途中経路に当たるまで探します。
     * 
     * @param searchReservations 
     * @param unknownStack 
     * @param goalStack 
     * @param timesCount 
     * @returns 成功したらGoalしたセルを返します。失敗したらundefinedを返します。
     */
    async searchGoalByRightHandMethod(searchReservations: SearchReservedType[], unknownStack: PictType[], goalStack: PictType[], timesCount: number): Promise<PictType | undefined> {
        // 探索予約がなくなるまで繰り返す
        while (searchReservations.length > 0) {
            const act = searchReservations.pop()
            if (!act) return undefined
            let matY = act.parent.matY
            let matX = act.parent.matX
            if (act.direction === DirectionType.Top) {
                matY--
            } else if (act.direction === DirectionType.Right) {
                matX++
            } else if (act.direction === DirectionType.Bottom) {
                matY++
            } else if (act.direction === DirectionType.Left) {
                matX--
            }
            const mapRow = this.detectionMap.find(el => el[0].matY === matY)
            const target = mapRow ? mapRow.find(el => el.matX === matX) : undefined
            if (target && target.id === this.editAreaId) {
                if (target.connectCheck) {
                    // 削除対象はスキップ
                    if (target.connectCheck.delete) continue
                    // 探索済み
                    if (target.connectCheck.times > 0 && target.connectCheck.times < timesCount) {
                        if (goalStack.length > 0) {
                            // とりあえず、どれかを返す
                            return goalStack[0]
                        } else {
                            throw new Error(`探索失敗:cell.connectCheck.timesが大きい targetCell=${target}, timesCount:${timesCount}`)
                        }
                    } else if (target.connectCheck.times === timesCount) {
                        // ループしたら次の探索予約へすすむ
                        continue
                    } else {
                        // 探索したのでtimesを設定
                        target.connectCheck.times = timesCount
                    }
                }
                // Goal判定
                // unknownStackの中から探す
                let stopCell = unknownStack.find(el => el === target)
                if (stopCell) return stopCell
                // goalStackの中から探す
                stopCell = goalStack.find(el => el === target)
                if (stopCell) return stopCell
                // 探索予約
                if (!target.connectCheck || !target.connectCheck.isDone) {
                    const findTop = { parent: target, direction: DirectionType.Top }
                    const findRight = { parent: target, direction: DirectionType.Right }
                    const findBottom = { parent: target, direction: DirectionType.Bottom }
                    const findLeft = { parent: target, direction: DirectionType.Left }
                    // POP時に右手探索となるように格納する
                    if (act.direction === DirectionType.Top) {
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                    } else if (act.direction === DirectionType.Right) {
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                    } else if (act.direction === DirectionType.Bottom) {
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                    } else if (act.direction === DirectionType.Left) {
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                    }
                    // スタックしたらisDoneを設定
                    if (target.connectCheck) {
                        target.connectCheck.isDone = true
                    } else {
                        target.connectCheck = { delete: false, times: timesCount, isDone: true }
                    }
                }
            }
        }
        return undefined
    }

    /**
     * セル削除前チェック
     * 
     * @param cel 
     * @returns 
     * /
    async cellDeletionPreCheck(cel: CellType) {
        if (this.pointerEditArea && this.cellMatrix && this.cellMatrix.length > 0) {
            // セル数が２個まで
            const len = this.pointerEditArea.cell_ids.length
            if (len === 0) {
                return false
            } else if (len === 1 || len === 2) {
                return true
            }
            // ３セル以上の場合
            if (this.editStartRow && this.editStartCol && this.editEndRow && this.editEndCol) {
                // CelMatrixの範囲内のセルのconnectCheckを初期化
                for (let r = this.editStartRow; r <= this.editEndRow; r++) {
                    for (let c = this.editStartCol; c <= this.editEndCol; c++) {
                        const tc = this.cellMatrix[r - this.cellStartRow][c - this.cellStartCol]
                        if (cel.area_id === this.editAreaId) {
                            // 削除対象エリアのセル？
                            const del = (tc === cel) ? true : false
                            const ck: ConnectionCheckType = { delete: del, times: 0 }
                            tc.connectCheck = ck
                        } else {
                            // 対象外は不要
                            if (tc.connectCheck) tc.connectCheck = undefined
                        }
                    }
                }
                // 直近４セルをチェック
                const t = (cel.row > this.cellStartRow) ? this.cellMatrix[cel.row - 1 - this.cellStartRow][cel.col - this.cellStartCol] : undefined
                const top = (t && t.area_id === this.editAreaId) ? t : undefined
                const b = (cel.row < this.cellEndRow) ? this.cellMatrix[cel.row + 1 - this.cellStartRow][cel.col - this.cellStartCol] : undefined
                const bottom = (b && b.area_id === this.editAreaId) ? b : undefined
                const l = (cel.col > this.cellStartCol) ? this.cellMatrix[cel.row - this.cellStartRow][cel.col - 1 - this.cellStartCol] : undefined
                const left = (l && l.area_id === this.editAreaId) ? l : undefined
                const r = (cel.col < this.cellEndCol) ? this.cellMatrix[cel.row - this.cellStartRow][cel.col + 1 - this.cellStartCol] : undefined
                const right = (r && r.area_id === this.editAreaId) ? r : undefined
                let directionCount = 0
                if (top && top.area_id === this.editAreaId) directionCount++
                if (bottom && bottom.area_id === this.editAreaId) directionCount++
                if (left && left.area_id === this.editAreaId) directionCount++
                if (right && right.area_id === this.editAreaId) directionCount++
                if (directionCount < 2) return true
                // ４方向のセルの中間セルで接続チェック
                const topRight = (top && right) ? this.cellMatrix[top.row - this.cellStartRow][right.col - this.cellStartCol] : undefined
                const rightBottom = (right && bottom) ? this.cellMatrix[bottom.row - this.cellStartRow][right.col - this.cellStartCol] : undefined
                const bottomLeft = (bottom && left) ? this.cellMatrix[bottom.row - this.cellStartRow][left.col - this.cellStartCol] : undefined
                const leftTop = (left && top) ? this.cellMatrix[top.row - this.cellStartRow][left.col - this.cellStartCol] : undefined
                if (directionCount === 2) {
                    // 隣り合うセルとその中間セルがあれば削除OK
                    if (top && right && topRight && topRight.area_id === this.editAreaId) return true
                    if (right && bottom && rightBottom && rightBottom.area_id === this.editAreaId) return true
                    if (bottom && left && bottomLeft && bottomLeft.area_id === this.editAreaId) return true
                    if (left && top && leftTop && leftTop.area_id === this.editAreaId) return true
                } else if (directionCount === 3) {
                    // 隣り合うセルとその中間セルがあれば削除OK
                    if (top && right && bottom && topRight && rightBottom && topRight.area_id === this.editAreaId && rightBottom.area_id === this.editAreaId) return true
                    if (right && bottom && left && rightBottom && bottomLeft && rightBottom.area_id === this.editAreaId && bottomLeft.area_id === this.editAreaId) return true
                    if (bottom && left && top && bottomLeft && leftTop && bottomLeft.area_id === this.editAreaId && leftTop.area_id === this.editAreaId) return true
                    if (left && top && right && leftTop && topRight && leftTop.area_id === this.editAreaId && topRight.area_id === this.editAreaId) return true
                } else {
                    // 中間セル３つがあれば削除OK
                    if (topRight && rightBottom && bottomLeft && topRight.area_id === this.editAreaId && rightBottom.area_id === this.editAreaId && bottomLeft.area_id === this.editAreaId) return true
                    if (topRight && rightBottom && leftTop && topRight.area_id === this.editAreaId && rightBottom.area_id === this.editAreaId && leftTop.area_id === this.editAreaId) return true
                    if (topRight && bottomLeft && leftTop && topRight.area_id === this.editAreaId && bottomLeft.area_id === this.editAreaId && leftTop.area_id === this.editAreaId) return true
                    if (rightBottom && bottomLeft && leftTop && rightBottom.area_id === this.editAreaId && bottomLeft.area_id === this.editAreaId && leftTop.area_id === this.editAreaId) return true
                }
                // 複雑系の探索
                // 　4方向のセル同士がつながっているかどうかをチェック
                // 　繋がったセル同士をGoalとする。全てGoalになるまで探索を繰り返す。探索した経路はtimesCountを記録する。
                // 　探索途中にGoalにならないセルがあれば削除不可とみなす。
                const unknownStack: CellType[] = []     // 未チェックセル
                const goalStack: CellType[] = []        // 確定セル
                if (top && top.area_id === this.editAreaId) unknownStack.push(top)
                if (right && right.area_id === this.editAreaId) unknownStack.push(right)
                if (bottom && bottom.area_id === this.editAreaId) unknownStack.push(bottom)
                if (left && left.area_id === this.editAreaId) unknownStack.push(left)
                // 未チェックせるには３個ないし４個入っているはず。
                let timesCount = 0
                while (unknownStack.length > 0) {
                    const searchReservations: SearchReservedType[] = [] // 探索予約
                    const startCell = unknownStack.shift()
                    if (!startCell) continue
                    timesCount++
                    // 探索予約
                    const findTop = { parentCell: startCell, direction: DirectionType.Top }
                    const findRight = { parentCell: startCell, direction: DirectionType.Right }
                    const findBottom = { parentCell: startCell, direction: DirectionType.Bottom }
                    const findLeft = { parentCell: startCell, direction: DirectionType.Left }
                    // popした時に右手探索となるように格納する
                    if (startCell === top) {
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                    } else if (startCell === right) {
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                    } else if (startCell === bottom) {
                        searchReservations.push(findTop)
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                    } else if (startCell === left) {
                        searchReservations.push(findRight)
                        searchReservations.push(findBottom)
                        searchReservations.push(findLeft)
                        searchReservations.push(findTop)
                    }
                    // 探索開始
                    if (startCell.connectCheck) {
                        startCell.connectCheck.times = timesCount
                    } else {
                        startCell.connectCheck = { delete: false, times: timesCount }
                    }
                    const goal: CellType | undefined = await this.searchGoalCell(searchReservations, unknownStack, goalStack, timesCount)
                    if (goal) {
                        // 探索成功
                        goalStack.push(startCell)
                        if (!(goalStack.length > 0 && goalStack.find(el => el === goal))) {
                            goalStack.push(goal)
                            unknownStack.splice(unknownStack.indexOf(goal), 1)
                        }
                    } else {
                        return false
                    }                
                }
                // unknownStackが空になったら探索終了
                return true
            }
        }
        return false
    }*/

    /**
     * 予約された探索を実行しながらGoalを探します。
     * 探索失敗時はundefinedを返します。
     * 
     * @param searchReservations 
     * @param unknownStack 
     * @param goalStack 
     * @param timesCount 
     * @returns 
     * /
    async searchGoalCell(searchReservations: SearchReservedType[], unknownStack: CellType[], goalStack: CellType[], timesCount: number) {
        // 予約がなくなるまで探索
        while (searchReservations.length > 0) {
            const act = searchReservations.pop()
            if (!act) return undefined
            const targetCell = await this.asyncGetCell(act)
            if (targetCell) {
                if (targetCell.connectCheck) {
                    // 削除対象はスキップ
                    if (targetCell.connectCheck.delete) continue
                    // 探索済み
                    if (targetCell.connectCheck.times > 0 && targetCell.connectCheck.times < timesCount) {
                        if (goalStack.length > 0) {
                            // とりあえず、どれかを返す
                            return goalStack[0]
                        } else {
                            throw new Error(`探索失敗:cell.connectCheck.timesが大きい targetCell=${targetCell}, timesCount:${timesCount}`)
                        }
                    } else if (targetCell.connectCheck.times === timesCount) {
                        // ループした
                        continue
                    } else {
                        targetCell.connectCheck.times = timesCount
                    }
                }
                // Goal判定
                if (timesCount === 1) {
                    const stopCell = unknownStack.find(el => el === targetCell)
                    if (stopCell) return stopCell
                } else {
                    const stopCell = goalStack.find(el => el === targetCell)
                    if (stopCell) return stopCell
                }
                // 探索予約
                const findTop = { parentCell: targetCell, direction: DirectionType.Top }
                const findRight = { parentCell: targetCell, direction: DirectionType.Right }
                const findBottom = { parentCell: targetCell, direction: DirectionType.Bottom }
                const findLeft = { parentCell: targetCell, direction: DirectionType.Left }
                // popした時に右手探索となるように格納する
                if (act.direction === DirectionType.Top) {
                    searchReservations.push(findBottom)
                    searchReservations.push(findLeft)
                    searchReservations.push(findTop)
                    searchReservations.push(findRight)
                } else if (act.direction === DirectionType.Right) {
                    searchReservations.push(findLeft)
                    searchReservations.push(findTop)
                    searchReservations.push(findRight)
                    searchReservations.push(findBottom)
                } else if (act.direction === DirectionType.Bottom) {
                    searchReservations.push(findTop)
                    searchReservations.push(findRight)
                    searchReservations.push(findBottom)
                    searchReservations.push(findLeft)
                } else if (act.direction === DirectionType.Left) {
                    searchReservations.push(findRight)
                    searchReservations.push(findBottom)
                    searchReservations.push(findLeft)
                    searchReservations.push(findTop)
                }
            }
        }
        return undefined
    }*/

    /**
     *  CelMatrixから指定された方向のセルを取得します。
     *　/ 
    async asyncGetCell(act: SearchReservedType) {
        const x = act.parentCell.col
        const y = act.parentCell.row
        const col = x - this.cellStartCol
        const row = y - this.cellStartRow
        if (act.direction === DirectionType.Top) {
            if (row > 0) {
                const cel = this.cellMatrix[row - 1][col]
                if (cel.area_id === this.editAreaId) return cel
            }
        } else if (act.direction === DirectionType.Right) {
            if (col + 1 < this.cellMatrix[0].length) {
                const cel = this.cellMatrix[row][col + 1]
                if (cel.area_id === this.editAreaId) return cel
            }
        } else if (act.direction === DirectionType.Bottom) {
            if (row + 1 < this.cellMatrix.length) {
                const cel = this.cellMatrix[row + 1][col]
                if (cel.area_id === this.editAreaId) return cel
            }
        } else if (act.direction === DirectionType.Left) {
            if (col > 0) {
                const cel = this.cellMatrix[row][col - 1]
                if (cel.area_id === this.editAreaId) return cel
            }
        }
        return undefined
    }*/

    /**
     * マウスが大きい窓内のエリアの上にあれば、cbIntoArea()を実行します。そうでなければcbNoArea()を実行します。
     * @param event 
     * @param callback 
     */
    async mouseOnArea(event: MouseEvent, cbIntoArea: (areaId: number) => void, cbNoArea: () => void, aniCtx: CanvasRenderingContext2D) {
        // マウスの位置を記録
        await this.setMouseMoveXY(event)
        // 対象セルを取得
        const cel = await this.getCellOnMouse()
        if (cel) {
            if (cel.area_id === undefined) {
                // エリア以外の空き
                if (this.pointerEditArea) {
                    // エリア編集作業中
                    const fw = await this.fourWayCellCheck(cel)
                    this.drawCellMarker(aniCtx, cel, (fw) ? MARKER_COLOR_BLUE : MARKER_COLOR_GRAY)
                } else {
                    this.drawCellMarker(aniCtx, cel, MARKER_COLOR_BLUE)
                }
                cbNoArea()
            //} else if (cel.area_id === NEW_AREA_ID) {
            } else if (cel.area_id === this.editAreaId) {
                if (this.pointedCell !== cel) {
                    this.pointedCell = cel
                    // 編集中エリア
                    const del = await this.checkBeforeCellDeletion(cel)
                    //const del = await this.cellDeletionPreCheck(cel)
                    //const del = await this.checkIfTheCellCanBeDeleted(cel)
                    //console.log("cellPolarizationCheck マーカー色 del:", del)
                    this.drawCellMarker(aniCtx, cel, (del) ? MARKER_COLOR_GREEN : MARKER_COLOR_GRAY)
                    //cbIntoArea(cel.area_id)
                }
            } else {
                // エリア内
                this.drawCellMarker(aniCtx, cel, MARKER_COLOR_GRAY)
                cbIntoArea(cel.area_id)
            }
        }
    }

    /**
     * マウスボタンを押したまま移動させたとき
     * @param event 
     * @param drwCtx 
     */
    async mouseDrawing(event: MouseEvent, drwCtx: CanvasRenderingContext2D) {
        // マウスの位置を記録
        await this.setMouseMoveXY(event)
        // 対象セルを取得
        const cel = await this.getCellOnMouse()
        // もしもエリア以外の場所なら、四隅に新規エリアがあるかどうかチェックし、あればエリア追加する
        if (cel && cel.area_id === undefined) {
            const f = await this.fourWayCellCheck(cel)
            if (f && this.pointerEditArea) {
                // セル追加
                const cd = new Coord(cel.col, cel.row)
                this.pointerEditArea.cell_ids.push(cd)
                // マップに記録
                cel.area_id = this.editAreaId
                const dtct = this.getDetectionMapCellFromMatrixCell(cel)
                if (dtct) dtct.id = this.editAreaId
                // エリア枠を再描画する
                drwCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
                await this.drawUnitMesh(drwCtx)
                await this.drawArea(drwCtx)
                // 親コンポーネントに伝達
                if (this.areaChangeCallback) this.areaChangeCallback(this.pointerEditArea)
                // エリアの範囲を更新
                if (this.editStartCol === undefined || this.editStartCol > cel.col) this.editStartCol = cel.col
                if (this.editStartRow === undefined || this.editStartRow > cel.row) this.editStartRow = cel.row
                if (this.editEndCol === undefined || this.editEndCol < cel.col) this.editEndCol = cel.col
                if (this.editEndRow === undefined || this.editEndRow < cel.row) this.editEndRow = cel.row
            }
        }
    }

    /**
     * 空きセル上でマウスが押されたときの処理
     * @param event 
     * @param drwCtx 
     * @param aniCtx 
     */
    async mouseDown(event: MouseEvent, drwCtx: CanvasRenderingContext2D, aniCtx: CanvasRenderingContext2D) {
        // マウスの位置を記録
        await this.setMouseMoveXY(event)
        // 対象セルを取得
        const cel = await this.getCellOnMouse()
        if (cel) {
            if (cel.area_id === undefined) {
                const cd = new Coord(cel.col, cel.row)
                // セルがundefinedであるとき
                if (this.pointerEditArea) {
                    if (this.pointerEditArea.cell_ids.length > 0) {
                        // ２つ目以降は隣り合うかをチェックする
                        const f = await this.fourWayCellCheck(cel)
                        if (f) {
                            // セル追加
                            this.pointerEditArea.cell_ids.push(cd)
                            // マップに記録
                            //cel.area_id = NEW_AREA_ID
                            cel.area_id = this.editAreaId
                            const dtct = this.getDetectionMapCellFromMatrixCell(cel)
                            if (dtct) dtct.id = this.editAreaId
                            
                            // 親コンポーネントに伝達
                            if (this.areaChangeCallback) this.areaChangeCallback(this.pointerEditArea)
                            // エリアの範囲を更新
                            if (this.editStartCol === undefined || this.editStartCol > cel.col) this.editStartCol = cel.col
                            if (this.editStartRow === undefined || this.editStartRow > cel.row) this.editStartRow = cel.row
                            if (this.editEndCol === undefined || this.editEndCol < cel.col) this.editEndCol = cel.col
                            if (this.editEndRow === undefined || this.editEndRow < cel.row) this.editEndRow = cel.row
                        }
                    } else {
                        // 一つ目の場合、かつ、すでにthis.pointerEditAreaがある場合
                        // セル追加
                        this.pointerEditArea.cell_ids.push(cd)
                        // マップに記録
                        //cel.area_id = NEW_AREA_ID
                        cel.area_id = this.editAreaId
                        const dtct = this.getDetectionMapCellFromMatrixCell(cel)
                        if (dtct) dtct.id = this.editAreaId
                        
                        // 親コンポーネントに伝達
                        if (this.areaChangeCallback) this.areaChangeCallback(this.pointerEditArea)
                        // エリアの範囲を更新
                        if (this.editStartCol === undefined || this.editStartCol > cel.col) this.editStartCol = cel.col
                        if (this.editStartRow === undefined || this.editStartRow > cel.row) this.editStartRow = cel.row
                        if (this.editEndCol === undefined || this.editEndCol < cel.col) this.editEndCol = cel.col
                        if (this.editEndRow === undefined || this.editEndRow < cel.row) this.editEndRow = cel.row
                    }
                } else {
                    // 一つ目の登録だからエリアを追加
                    const area: ResArea = { area_id: NEW_AREA_ID, area_number: NEW_NUMBER, name: "新規", cell_ids: [cd], area_type: AreaCountingType.Normal, group_id: null }
                    this.area_list.push(area)
                    this.pointerEditArea = area
                    // マップに記録
                    cel.area_id = this.editAreaId
                    
                    // 親コンポーネントに伝達
                    if (this.areaChangeCallback) this.areaChangeCallback(this.pointerEditArea)
                }
                // マーカーを消す
                await this.clearMarker(aniCtx)
            //} else if (cel.area_id === NEW_AREA_ID) {
            } else if (cel.area_id === this.editAreaId) {
                // 新規エリア内のクリックは削除動作
                const f = await this.checkBeforeCellDeletion(cel)
                //const f = await this.cellDeletionPreCheck(cel)
                //const f = await this.checkIfTheCellCanBeDeleted(cel)
                //console.log("cellPolarizationCheck 削除可能？:", f)
                if (f) {
                    //console.log("DEL cel:", cel)
                    if (this.pointerEditArea) {
                        const newList = this.pointerEditArea.cell_ids.filter(el => !(el.x === cel.col && el.y === cel.row))
                        if (newList) this.pointerEditArea.cell_ids = newList
                    }
                    cel.area_id = undefined
                    const dtct = this.getDetectionMapCellFromMatrixCell(cel)
                    if (dtct) dtct.id = undefined
                    
                    // マーカーを上書き描画
                    await this.drawCellMarker(aniCtx, cel, MARKER_COLOR_BLUE)
                    // 親コンポーネントに伝達
                    if (this.areaChangeCallback) this.areaChangeCallback(this.pointerEditArea)
                }                
            }
            // エリア枠を再描画する
            drwCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
            await this.drawUnitMesh(drwCtx)
            await this.drawArea(drwCtx)
        }
    }

    /**
     * マウスボタンを放したとき
     * @param event 
     * @param drwCtx 
     * @param aniCtx 
     */
    async mouseUp(event: MouseEvent, drwCtx: CanvasRenderingContext2D, aniCtx: CanvasRenderingContext2D) {
        // エリア枠を再描画する
        //console.log("mouseUp エリア枠再描画")
        drwCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
        await this.drawUnitMesh(drwCtx)
        await this.drawArea(drwCtx)
    }

    /**
     * 編集中エリアの番号を更新します。
     * @param newNumber 
     */
    updateAreaNumber(newNumber: number | undefined) {
        if (this.pointerEditArea) {
            if (newNumber) {
                this.pointerEditArea.area_number = newNumber
            } else {
                this.pointerEditArea.area_number = NEW_NUMBER
            }
        }
    }

    /**
     * セル編集イベントの登録
     * @param areaId 
     * @param func 
     */
    addListener(func: (update: ResArea) => void) {
        this.areaChangeCallback = func        
    }

    /**
     * セル編集イベントの削除
     * @param areaId 
     * @param func 
     */
    removeListener(func: (update: ResArea) => void) {
        this.areaChangeCallback = null
    }

    /**
     * 新規登録中のエリアを指定されたIDで更新し、次の新規エリア作成可能状態にします。
     */
    oneStepUp(upAreaId: number) {
        for(let row of this.cellMatrix) {
            for(let cel of row) {
                if (cel.area_id === NEW_AREA_ID) cel.area_id = upAreaId
            }
        }
        const emptyNewArea: ResArea = {
            area_id: NEW_AREA_ID,
            area_number: NEW_NUMBER,
            name: "新規",
            cell_ids: [],
            area_type: AreaCountingType.Normal,
            group_id: null
        }
        this.area_list.push(emptyNewArea)
        this.pointerEditArea = emptyNewArea
    }

    /**
     * 編集途中のエリアを削除します。
     */
    eraseSelectedArea() {
        for(let row of this.cellMatrix) {
            for(let cel of row) {
                if (cel.area_id === NEW_AREA_ID) cel.area_id = undefined
            }
        }
        if (this.pointerEditArea) this.pointerEditArea.cell_ids = []
        this.editStartCol = undefined
        this.editStartRow = undefined
        this.editEndCol = undefined
        this.editEndRow = undefined
    }

    /**
     * 編集するエリアを設定します。
     * @param areaId 
     */
    setEditArea(areaId: number) {
        // エリアIDを設定
        this.editAreaId = areaId
        // エリアリストを設定
        const area = this.area_list.find(el => el.area_id === areaId)
        this.pointerEditArea = area
        // エリアの角を設定
        this.setEditAreaCorner()
    }

    /**
     * 編集中のエリアの左上と右下のセルを設定します。
     */
    setEditAreaCorner() {
        if (this.pointerEditArea) {
            if (this.pointerEditArea.cell_ids.length === 0) return
            let minX: number | undefined = undefined
            let minY: number | undefined = undefined
            let maxX: number | undefined = undefined
            let maxY: number | undefined = undefined
            for (let i = 0; i < this.pointerEditArea.cell_ids.length; i++) {
                const cd = this.pointerEditArea.cell_ids[i]
                if (minX === undefined || minX > cd.x) minX = cd.x
                if (minY === undefined || minY > cd.y) minY = cd.y
                if (maxX === undefined || maxX < cd.x) maxX = cd.x
                if (maxY === undefined || maxY < cd.y) maxY = cd.y
            }
            this.editStartCol = minX
            this.editStartRow = minY
            this.editEndCol = maxX
            this.editEndRow = maxY
        }
    }

}
export default AreaEditPicWriter