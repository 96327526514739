import React, { useEffect } from "react"
import { format } from "date-fns"
import { ja, enUS } from 'date-fns/locale'
import i18n from "../../i18n/configs"
import { useTranslation } from "react-i18next"

import Utils from '../../lib/Utils'
import style from "./TrailMapDetail.module.css"
import { useModal } from "../../component/modal/Modal"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { HorizontalBarGraph, HorizontalBarType } from "../../component/horizontal_bar_graph/HorizontalBarGraph"
import { TrackingPageView } from "../common/TrackingPageView"

const LIST_SIZE = 50

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export type DetailAreaInfoRowType = {
    id: number
    num: string | null
    name: string
    time: number
    count: number
}

export type DetailRankingType = {
    name: string
    time: number
    count: number
}

export type DetailDataType = {
    humanId: number
    enterShopTime: number
    exitShopTime: number
    stayShopTime: number
    areaInfo: DetailAreaInfoRowType[]
    stayTimeTop10: DetailRankingType[]
}

interface Props {
    show: boolean
    hide: () => void
    data: DetailDataType
}

export const TrailMapDetail: React.FC<Props> = (props) => {

    const { t } = useTranslation()
    const [Modal, open, close, isOpen, resize] = useModal('root', { preventScroll: false, closeOnOverlayClick: false })
   
    const handleClose = () => {
        if (props.show) props.hide()
    }

    const getTableItems = (stIdx: number) => {
        // 注意：props.data.areaInfo[0] には合計滞在時間が入っているので、それは表示しない
        const list = props.data.areaInfo
        const len = list.length
        let items: React.ReactNode[] = []
        for (let i = stIdx; i < (stIdx + LIST_SIZE); i++) {
            if (i < len) {
                const itm = (
                    <tr key={i} className={style.listRow}>
                        <td className={style.tdID}>{list[i].num}</td>
                        <td className={style.tdName}>{list[i].name}</td>
                        <td className={style.tdTime}>{Utils.formatMillisecToTime(list[i].time)}</td>
                        <td className={style.tdCount}>{Utils.comma(list[i].count)}</td>
                    </tr>
                )
                items.push(itm)
            }
        }
        return items
    }

    const getHalfTable = (startNo: number) => {
        const lbl = (startNo !== 1) ? (startNo + "-" + (startNo + LIST_SIZE - 1)) : (`${startNo}-${props.data.areaInfo.length - 1}`)
        return (
            <table className={style.table}>
                <caption className={style.cap}>{lbl}</caption>
                <thead>
                    <tr>
                        <th scope="col" className={style.thID}></th>
                        <th scope="col" className={style.thName}>{t("areaName")}</th>
                        <th scope="col" className={style.thTime}>{t("totalStayTime")}</th>
                        <th scope="col" className={style.thCount}>{t("countStayTimes")}</th>
                    </tr>
                </thead>
                <tbody>
                    {getTableItems(startNo)}
                </tbody>
            </table>
        )
    }

    const getList4Left = () => {
        return getHalfTable(1)
    }
    
    const getList4Right = () => {
        if (props.data.areaInfo.length > LIST_SIZE) return getHalfTable(LIST_SIZE + 1)
        return (<div></div>)
    }

    const getRankingList = () => {
        const top10 = props.data.stayTimeTop10
        let result = []
        const max = Math.ceil(top10[0].time / 1000)
        for (let i = 0; i < top10.length; i++) {
            const dispUnit = t("unit.second")
            const itm = (
                <tr key={i}>
                    <td className={style.rkdName}>{top10[i].name}</td>
                    <td className={style.rkdCount}>{(top10[i].count < 0) ? "—" : Utils.comma(top10[i].count)}</td>
                    <td className={style.rkdTime}>{Utils.formatMillisecToMMSS(top10[i].time)}</td>
                    <td className={style.rkdGraph}>
                        <HorizontalBarGraph type={HorizontalBarType.Trail} value={Math.ceil(top10[i].time / 1000)} maxValue={max} dispUnit={dispUnit} />
                    </td>
                </tr>
            )
            result.push(itm)
        }
        return result
    }

    const getCustomerInOutTime = () => {
        const locale = (i18n.language === 'ja') ? ja : enUS
        const dtStr = format(new Date(props.data.enterShopTime), t("dateFormat.ymd_E") + '　HH:mm　-　', { locale: locale })
        const edStr = format(new Date(props.data.exitShopTime), 'HH:mm')
        return dtStr + edStr
    }

    useEffect(() => {
        if (isOpen) {
            if (!props.show) {
                close()
            }
        } else {
            if (props.show) {
                open()
                resize({ width: 1120, height: 1900})
            }
        }
    }, [isOpen, props.show, close, open, resize])

    useEffect(() => {
        return () => {
            window.document.body.style.overflow = ''
            console.log("clean up")
        }
    }, [])

    return (
        <section>
            <Modal>
                <div className={style.main}>
                    <div className={style.head}>
                        <div className={style.info}>
                            <div className={style.custId}>
                                <label className={style.custLabel}>{t("customerId")}：</label><span>{props.data.humanId}</span>
                            </div>
                            <div className={style.custTime}>
                                <label className={style.custLabel}>Date：</label><span>{getCustomerInOutTime()}</span>
                            </div>
                            {isProduction ? (<TrackingPageView page_title="analyze-*-trailmap-detail" />) : (null)}
                        </div>
                        <div className={style.posRegi}>
                            <div className={style.amount}>
                                <label className={style.buyLabel}></label><span></span>
                            </div>
                            <div className={style.itemCnt}>
                                <label></label><span></span>
                            </div>
                        </div>
                        <div className={style.close}>
                            <CircleCloseButton onClose={handleClose}/>
                        </div>
                    </div>
                    <div className={style.list}>
                        <div className={style.listLeft}>
                            {getList4Left()}
                        </div>
                        <div className={style.listRight}>
                            {getList4Right()}
                        </div>
                    </div>
                    <div className={style.rank}>
                        <table className={style.rankTbl}>
                            <thead>
                                <tr>
                                    <th scope="col" className={style.rkhName}>{t("areaName")}</th>
                                    <th scope="col" className={style.rkhCount}>{t("stopByCount")}</th>
                                    <th scope="col" className={style.rkhTime}>{t("stopByTime")}</th>
                                    <th scope="col" className={style.rkhGraph}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRankingList()}
                            </tbody>
                        </table>
                    </div>
                    <div className={style.memo}></div>
                </div>
            </Modal>
        </section>
    )
}