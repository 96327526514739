import { Coord } from "../api/data/core/Coord";

// 位置把握マップのメッシュのサイズ[mm]
const MESH_SIZE = 1000

// マウス位置の検出半径[px]
const MOUSE_TOUCH_RADIUS = 2

// 動線位置把握マップに格納するライン情報
type Tag = {
    hid: number // 顧客のID
    idx: number // 動線Pointリストのインデックス
}

class Vector2D {
    public x: number
    public y: number
    public len: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
        this.len = Math.sqrt(x * x + y * y)
    }

    // 単位ベクトルを求める
    public normalize(): Vector2D {
        return new Vector2D(this.x / this.len, this.y / this.len)
    }

    // 内積を求める
    public innerProduct(v: Vector2D): number {
        return this.x * v.x + this.y * v.y
    }

    // 外積を求める
    public outerProduct(v: Vector2D): number {
        return this.x * v.y - this.y * v.x
    }

    public add(v: Vector2D): Vector2D {
        return new Vector2D(this.x + v.x, this.y + v.y)
    }

    public sub(v: Vector2D): Vector2D {
        return new Vector2D(this.x - v.x, this.y - v.y)
    }

    public mul(s: number): Vector2D {
        return new Vector2D(this.x * s, this.y * s)
    }

    public div(s: number): Vector2D {
        return new Vector2D(this.x / s, this.y / s)
    }

    // ２点間の距離を求める
    public dist(v: Vector2D): number {
        return Math.sqrt((this.x - v.x) ** 2 + (this.y - v.y) ** 2)
    }
}

class TrailWorker {
    public trailHash: Record<string, Coord[]> = {}
    public pixel_width: number = 0
    public pixel_height: number = 0
    public origin_x: number = 0
    public origin_y: number = 0
    public mm_per_px: number = 1
    public detectionWidth: number = 0
    public detectionHeight: number = 0
    public traceMap: Tag[][][] = []
    public touchRadiusMm: number = 1
    public activeCustomers: number[] = []   // 動線を描画した顧客のIDリスト
    public createdList: number[] = []    // Map作成済み顧客のIDリスト

    constructor(trailHash: Record<string, Coord[]>, px_width: number, px_height: number, origin_x: number, origin_y: number, mm_per_px: number) {
        this.trailHash = trailHash;
        this.pixel_width = px_width;
        this.pixel_height = px_height;
        this.origin_x = origin_x;
        this.origin_y = origin_y;
        this.mm_per_px = mm_per_px;
        // 動線位置把握マップの初期化
        this.detectionWidth = (this.pixel_width - this.origin_x) * this.mm_per_px;
        this.detectionHeight = (this.pixel_height - this.origin_y) * this.mm_per_px;
        const sizeX = Math.ceil(this.detectionWidth / MESH_SIZE);
        const sizeY = Math.ceil(this.detectionHeight / MESH_SIZE);
        this.traceMap = new Array(sizeX);
        for (let x = 0; x < sizeX; x++) {
            this.traceMap[x] = new Array(sizeY);
            for (let y = 0; y < sizeY; y++) {
                this.traceMap[x][y] = [];
            }
        }
        this.touchRadiusMm = MOUSE_TOUCH_RADIUS * this.mm_per_px;
    }

    /**
     * 検索(Sortやフィルターではない)などにより顧客リストが更新されたタイミングで呼び出す
     * @param customerList 
     */
    updateCustoemrList(trailHash: Record<string, Coord[]>) {
        this.trailHash = trailHash;
        this.activeCustomers = [];
        this.createdList = [];
        const sizeX = this.traceMap.length;
        const sizeY = this.traceMap[0].length;
        for (let x = 0; x < sizeX; x++) {
            for (let y = 0; y < sizeY; y++) {
                this.traceMap[x][y] = []
            }
        }
    }

    /**
     * 動線表示チェックボックスの状態が変更されたタイミングで呼び出す
     * @param idList 
     */
    updateTraceList(idList: number[]) {
        this.activeCustomers = idList;
    }

    /**
     * 動線を描画したタイミングで呼び出す
     * @param humanId 
     * @returns 
     */
    createMap(humanId: number) {
        // 顧客データが存在しない場合は何もしない
        const rawPoints = this.trailHash[humanId.toString()];
        if (rawPoints === undefined || rawPoints.length < 2) return;
        // 既にMap作成済みの場合は何もしない
        if (this.createdList.includes(humanId)) return;
        // 描画対象になっていない場合は描画済みリストに追加
        if (!this.activeCustomers.includes(humanId)) {
            this.activeCustomers.push(humanId);
        }
        if (rawPoints) {
            for (let i = 0; i < rawPoints.length - 1; i++) {
                const p1 = rawPoints[i];
                const p2 = rawPoints[i + 1];
                if (p1.x < 0 || p1.y < 0 || p2.x < 0 || p2.y < 0) continue;
                if (p1.x > this.detectionWidth || p1.y > this.detectionHeight || p2.x > this.detectionWidth || p2.y > this.detectionHeight) continue;
                const sx = Math.floor(p1.x / MESH_SIZE);
                const sy = Math.floor(p1.y / MESH_SIZE);
                const ex = Math.floor(p2.x / MESH_SIZE);
                const ey = Math.floor(p2.y / MESH_SIZE);
                if (sx === ex && sy === ey) {
                    // ひとつのメッシュ内の場合
                    const tag: Tag = { hid: humanId, idx: i };
                    this.traceMap[sx][sy].push(tag);
                } else if (sx === ex) {
                    // x方向に１行メッシュの場合
                    const start = (sy < ey) ? sy : ey
                    const end = (sy < ey) ? ey : sy
                    for (let y = start; y <= end; y++) {
                        const tag: Tag = { hid: humanId, idx: i };
                        this.traceMap[sx][y].push(tag);
                    }
                } else if (sy === ey) {
                    // y方向に１行メッシュの場合
                    const start = (sx < ex) ? sx : ex
                    const end = (sx < ex) ? ex : sx
                    for (let x = start; x <= end; x++) {
                        const tag: Tag = { hid: humanId, idx: i };
                        this.traceMap[x][sy].push(tag);
                    }
                } else {
                    // x,y 方向にまたがる場合
                    const dx = Math.abs(ex - sx);
                    const dy = Math.abs(ey - sy);
                    const start_x = (sx < ex) ? sx : ex;
                    const end_x = (sx < ex) ? ex : sx;
                    const start_y = (sy < ey) ? sy : ey;
                    const end_y = (sy < ey) ? ey : sy;
                    if (dx >= dy) {
                        // x方向に長い場合（x軸から斜め45度）
                        let err = 2 * dy - dx
                        this.traceMap[start_x][start_y].push({ hid: humanId, idx: i })
                        let y = start_y
                        for (let x = start_x + 1; x < end_x; x++) {
                            if (err > 0) {
                                y = y + 1
                                this.traceMap[x][y].push({ hid: humanId, idx: i })
                                err = err + (2 * dy - 2 * dx)
                            } else {
                                this.traceMap[x][y].push({ hid: humanId, idx: i })
                                err = err + (2 * dy)
                            }
                        }
                    } else {
                        // y方向に長い場合（y軸から斜め45度）
                        let err = 2 * dx - dy
                        this.traceMap[start_x][start_y].push({ hid: humanId, idx: i })
                        let x = start_x
                        for (let y = start_y + 1; y < end_y; y++) {
                            if (err > 0) {
                                x = x + 1
                                this.traceMap[x][y].push({ hid: humanId, idx: i })
                                err = err + (2 * dx - 2 * dy)
                            } else {
                                this.traceMap[x][y].push({ hid: humanId, idx: i })
                                err = err + (2 * dx)
                            }
                        }
                    }
                }
            }
        }
        this.createdList.push(humanId);
    }

    /**
     * マウスの位置にあるラインの顧客IDを返す
     * @param x 
     * @param y 
     * @returns 
     */
    async hitTest(x: number, y: number): Promise<number[]> {
        const ret: number[] = [];
        // マップ上の座標に変換
        const sx = Math.floor(x / MESH_SIZE);
        const sy = Math.floor(y / MESH_SIZE);
        // マップ外の場合は空配列を返す
        if (sx < 0 || sy < 0 || sx > this.traceMap.length - 1 || sy > this.traceMap[0].length - 1) return ret;
        const tags = this.traceMap[sx][sy];
        if (tags.length > 0) {
            for (let t of tags) {
                // 重複チェック
                if (ret.includes(t.hid)) continue;
                // 有効な顧客かチェック
                if (this.activeCustomers.includes(t.hid)) {
                    // 顧客の軌跡を取得
                    const points = this.trailHash[t.hid.toString()];
                    if (points) {
                        const p1 = points[t.idx]
                        const p2 = points[t.idx + 1]
                        if (this.isCollision(p1, p2, x, y)) ret.push(t.hid)
                    }
                }
            }
        }
        return ret;
    }

    /**
     * ラインと円（マウス位置）の当たり判定を行う
     * @param p1 
     * @param p2 
     * @param camX 
     * @param camY 
     * @returns hit=true
     */
    private isCollision(p1: Coord, p2: Coord, camX: number, camY: number): boolean {
        const vecAB: Vector2D = new Vector2D(p2.x - p1.x, p2.y - p1.y)
        const vecAP: Vector2D = new Vector2D(camX - p1.x, camY - p1.y)
        const vecBP: Vector2D = new Vector2D(camX - p2.x, camY - p2.y)
        // 単位ベクトルを求める
        const normalAB: Vector2D = vecAB.normalize()
        // 内積からPのAB上の射影長さを求める
        const lenAX: number = normalAB.innerProduct(vecAP)
        let shortest: number = 0
        if (lenAX < 0) {
            shortest = vecAP.len
        } else if (lenAX > vecAP.len) {
            shortest = vecBP.len
        } else {
            // PがAB上にある場合
            shortest = Math.abs(normalAB.outerProduct(vecAP))
        }
        return shortest < this.touchRadiusMm
    }
}
export default TrailWorker