import React, { useCallback } from "react"
import * as d3 from 'd3'

import style from './D3ChordGraph.module.css'

/**
 * sourceが始点側の名前(表示用エリア名)
 * targetが終点側の名前(表示用エリア名)
 * valueが動線の数
 */
export interface ChordAtomData {
    source: string
    target: string
    value: number
}

interface Props {
    uid: string
    data: Array<ChordAtomData>
    width: number
    height: number
    colors: Array<string>
}

export const D3ChordGraph: React.FC<Props> = (props) => {

    //console.log("D3ChordGraph")

    const myId: string = 'chord-graph-' + props.uid

    /**
     * ChordData[]からmatrixデータを取得します。
     * 
     * @returns 
     */
    const getMatrix = useCallback((names: string[]): number[][] => {
        const index = new Map(names.map((name, i) => [name, i]))
        //console.log("index:", index)
        const matrix = Array.from(index, () => new Array(names.length).fill(0))
        for (const { source, target, value } of props.data) {
            const fm = index.get(source)
            const to = index.get(target)
            //console.log("fm,to, value",fm, to, value)
            if (fm!==undefined && to!==undefined) {
                matrix[fm][to] += value
            }
        }
        ////console.log("matrix:", matrix)
        return matrix
    }, [props.data])

    /**
     * 弦グラフを描画します。
     */
    const drawChordGraph = React.useCallback(() => {
        //console.log("drawChordGraph myId:", myId)

        // 円の内周半径
        const innerRadius: number = Math.min(props.width, props.height) * 0.5 - 40
        // 円の外周半径
        const outerRadius: number = innerRadius + 6
        // 数値フォーマット
        const formatValue = (x: number): string => `${x.toFixed(0)}`
        // ラベルの配列
        const names: string[] = Array.from(new Set(props.data.flatMap(d => [d.source, d.target])))
        // ラベルの幅
        const labelWidth: number[] = new Array(names.length).fill(0)
        // 色見本
        const color = d3.scaleOrdinal(names, props.colors)
        // 矢印型の弦
        const ribbon = d3.ribbonArrow().radius(innerRadius)
        // 方向つきの弦グラフ
        const chord = d3.chordDirected().sortSubgroups(d3.ascending).sortChords(d3.ascending)
        // 円
        const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius)

        const id: string = '#' + myId

        //console.log("outerRadius", outerRadius)

        // 古いエレメントを削除
        d3.select(id).selectAll(".chord-remove-mark").remove()

        const svg = d3.select(id).append("svg")
            .attr("viewBox", [-props.width / 2, -props.height / 2, props.width, props.height])
            .attr("class", "chord-remove-mark")
        
        const matrix = getMatrix(names)
        const chords = chord(matrix)
        const textId = props.uid + "-chord-label-text"
        // 下地(エリア名の表示用Pathになる)
        svg.append("path").attr("id", textId).attr('fill', "none").attr("d", d3.arc()({ outerRadius, startAngle: 0, endAngle: 2 * Math.PI } as any))
        // 弦（リボン）の作成
        svg.append("g").attr("fill-opacity", 0.75)
            .selectAll("g")
            .data(chords)
            .join("path").attr("d", ribbon as any).attr("fill", d => color(names[d.source.index]) as any).style("mix-blend-mode", "multiply")
            .append("title").text(d => `${names[d.source.index]} → ${names[d.target.index]}: ${formatValue(d.source.value)}`)
        //グループとラベルの作成
        if (names.length > 15) {
            // エリア数が多い時は、文字は外に向け短くする
            svg.append("g").attr("font-family", "Noto Sans JP").attr("font-size", 7)
                .selectAll("g")
                .data(chords.groups)
                .join("g")
                .call(g => g.append("path").attr("d", arc as any).attr("fill", d => color(names[d.index]) as any).attr("stroke", "#fff"))
                .call(g => g.append("text").attr("dy", 0).attr("transform", function(d,i) { // angle
                    return "rotate(" + (((d.startAngle + d.endAngle) / 2) * 180 / Math.PI) + ")" + 
                        "translate(0," + -1.0 * (outerRadius + 1) + ") rotate(315)"
                }).text(d => names[d.index].slice(0,7) + "..."))
                .call(g => g.append("title").text(d => `${names[d.index]} in:${formatValue(d3.sum(matrix[d.index]))} out:${formatValue(d3.sum(matrix, row => row[d.index]))}`))
        } else {
            // 文字は円周に沿って表示
            svg.append("g").attr("font-family", "Noto Sans JP").attr("font-size", 7)
                .selectAll("g")
                .data(chords.groups)
                .join("g")
                .call(g => g.append("path")
                    .attr("d", arc as any)
                    .attr("fill", d => {
                        // あらかじめラベルの幅を計算
                        const w = Math.floor((d.endAngle - d.startAngle) / (2 * Math.PI) * (6 * outerRadius))
                        labelWidth[d.index] = w
                        return color(names[d.index]) as any
                    })
                    .attr("stroke", "#fff"))
                .call(g => g.append("text")
                    .attr("dy", -2)
                    .append("textPath")
                    .attr("xlink:href", '#' + textId)
                    .attr("startOffset", d => d.startAngle * outerRadius)
                    .append("tspan")
                    .attr("class", "textEllipsis")
                    .text(d => names[d.index]))
                .call(g => g.append("title").text(d => `${names[d.index]} in:${formatValue(d3.sum(matrix[d.index]))} out:${formatValue(d3.sum(matrix, row => row[d.index]))}`))
            // 長いラベル文字のはみ出しは三点リーダー化
            d3.selectAll("tspan.textEllipsis").nodes().forEach((el, i) => {
                const tspan = el as SVGTextElement
                const width = labelWidth[i]
                let orgText = tspan.innerHTML
                let textLength = tspan.getComputedTextLength()
                while (textLength > width && orgText.length > 0) {
                    orgText = orgText.slice(0, -1)
                    tspan.innerHTML = orgText + "..."
                    textLength = tspan.getComputedTextLength() 
                }
             })
        }
    }, [myId, props, getMatrix])
    
    React.useEffect(() => {
        drawChordGraph()
    }, [drawChordGraph])

    return (
        <div id={myId}>
        </div>
    )
}