import React, { useEffect, RefObject } from "react"

import { Button, StyleType } from "../button/Button"
import style from './fupNumberpicker.module.css'
import { formatSec2MMSS } from "../TimeFormat"

interface Props {
    enter: (val: number) => void
    cancel: () => void
    value: number
    max?: number
    min?: number
    step?: number
}

export const FupNumberpicker: React.FC<Props> = (props) => {

    const [selected, setSelected] = React.useState(props.value)
    const upRef = React.useRef(null)
    const downRef = React.useRef(null)
    const gearRef = React.useRef(null)

    // 取消ボタン処理
    const handleCancel = () => {
        console.log("取消")
        props.cancel()
    }
    // 設定ボタン処理
    const handleEnter = () => {
        console.log("設定")
        props.enter(selected)
    }

    // 表示する値の計算
    const st = props.min ? props.min : 0
    const ed = props.max ? props.max : 3600
    const stp = props.step ? props.step : 1
    const vals: Array<number> = []
    let ptr: number|undefined
    let sml: number|undefined
    let smlst: number|undefined
    let big: number|undefined 
    let bigst: number|undefined 
    for (let i = st; i <= ed; i = i + stp) {
        vals.push(i)
    }
    if (selected <= vals[0]) {
        smlst = undefined
        sml = undefined
        ptr = 0
        big = vals[1]
        bigst = vals[2]
    } else if (selected <= vals[1]) {
        smlst = undefined
        sml = vals[0]
        ptr = 1
        big = vals[2]
        bigst = vals[3]
    } else if (selected >= vals[vals.length - 1]) {
        ptr = vals.length - 1
        smlst = vals[ptr - 2]
        sml = vals[ptr - 1]
        big = undefined
        bigst = undefined
    } else if (selected >= vals[vals.length - 2]) {
        ptr = vals.length - 2
        smlst = vals[ptr - 2]
        sml = vals[ptr - 1]
        big = vals[ptr + 1]
        bigst = undefined
    } else {
        for (let i = 2; i < vals.length - 2; i++) {
            smlst = vals[i - 2]
            sml = vals[i - 1]
            if (sml < selected && selected <= vals[i]) {
                ptr = i
                big = vals[i + 1]
                bigst = vals[i + 2]
                break
            }
        }
    }

    const changeSelectTime = (val: number): void => {
        if (ptr !== undefined) {
            const newPtr = ptr + val
            if (newPtr >= 0 && newPtr < vals.length) {
                setSelected(vals[newPtr])
            }
        }        
    }
    const increment = () => {
        changeSelectTime(1)
    }
    const decrement = () => {
        changeSelectTime(-1)
    }
    // 数字部でのホイール操作を処理する
    const numRef = React.useRef(null)
    useEffect(() => {
        const wheelUpDown = (event: WheelEvent): void => {
            event.preventDefault()
            changeSelectTime(event.deltaY / 100)
        }
        // イベント登録
        if (numRef.current) {
            (numRef.current as HTMLElement).addEventListener("wheel", wheelUpDown)
        }

        return () => {
            if (numRef.current) {
                (numRef.current as HTMLElement).removeEventListener("wheel", wheelUpDown)
            }
        }
    },[vals, ptr, numRef, selected])

    return (
        <div className={style.outer}>
            <div className={style.header}></div>
            <div className={style.body}>
                <div className={style.numbers} ref={numRef}>
                    <div className={style.smallest}><span>{formatSec2MMSS(smlst)}</span></div>
                    <div className={style.smaller}><span>{formatSec2MMSS(sml)}</span></div>
                    <div className={style.target}><span>{formatSec2MMSS(selected)}</span></div>
                    <div className={style.bigger}><span>{formatSec2MMSS(big)}</span></div>
                    <div className={style.biggest}><span>{formatSec2MMSS(bigst)}</span></div>
                </div>
                <div className={style.gear} ref={gearRef}>
                    <div className={style.dec2}><span>　</span></div>
                    <div className={style.dec}><span onClick={decrement}>▲</span></div>
                    <div className={style.non}><span>　</span></div>
                    <div className={style.inc}><span onClick={increment}>▼</span></div>
                    <div className={style.inc2}><span>　</span></div>
                </div>
            </div>
            <div className={style.footer}>
                <Button name="cancel" label="back" styleType={StyleType.OutMini} onClick={e => handleCancel()} />
                &emsp;
                <Button name="enter" label="enter" styleType={StyleType.OutMini} onClick={e => handleEnter()} />
            </div>
        </div>
    )
}