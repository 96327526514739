import { useEffect, useState, useRef, RefObject } from "react"

export const useMouseClick = <E extends Event = Event>(
    ref: RefObject<HTMLCanvasElement | null>,
    mouseMove: (event: E) => void,
    mouseOut: (event: E) => void,
    mouseDown: (event: E) => void,
    mouseUp: (event: E) => void
) => {

    const savedCallback = useRef(mouseMove)
    const savedCallbackUp = useRef(mouseUp)
    const savedCallbackOut = useRef(mouseOut)
    const savedCallbackDown = useRef(mouseDown)
    const [press, setPress] = useState<boolean>(false)

    useEffect(() => {
        savedCallback.current = mouseMove
        savedCallbackUp.current = mouseUp
        savedCallbackOut.current = mouseOut
        savedCallbackDown.current = mouseDown
    }, [mouseMove, mouseOut])

    useEffect(() => {
        const handleMove = (event: any) => {
            const { current: el } = ref
            if (el) savedCallback.current(event)
        }
        const handleDown = (event: any) => {
            const { current: el } = ref
            if (el) savedCallbackDown.current(event)
            setPress(true)
        }
        const handleUp = (event: any) => {
            const { current: el } = ref
            if (el) savedCallbackUp.current(event)
            setPress(false)
        }
        const handleOut = (event: any) => {
            const { current: el } = ref
            if (el) savedCallbackOut.current(event)
            setPress(false)
        }
        const handleOver = (event: any) => {
            setPress(false)
        }

        const canvas = ref.current
        if (canvas) {
            canvas.addEventListener('mousedown', handleDown)
            canvas.addEventListener('mouseup', handleUp)
            canvas.addEventListener('mouseout', handleOut)
            canvas.addEventListener('mousemove', handleMove)
            canvas.addEventListener('mouseover', handleOver)
        }

        return () => {
            //const canvas = ref.current
            if (canvas) {
                canvas.removeEventListener('mousedown', handleDown)
                canvas.removeEventListener('mouseup', handleUp)
                canvas.removeEventListener('mouseout', handleOut)
                canvas.removeEventListener('mousemove', handleMove)
                canvas.removeEventListener('mouseover', handleOver)
            }
        }
    }, [ref])

    return press
}