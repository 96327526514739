import React from "react"
import { To, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Permissions, PermissionTypes } from "../../types/Permissions";
import { Pages, PageName } from "../../types"
import { useAuthUserContext  } from "../../providers";

import style from './SideMenu.module.css'

//const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

const ItemType = {
    Title: "title",
    Item: "item",
    Empty: "empty"
} as const
type Items = typeof ItemType[keyof typeof ItemType]

type MenuItem = {
    type: Items,
    dispName: string,
    pageName?: Pages
    href?: string
    permission?: Permissions
}

// メニューリスト（アクセスコントロール付きは、permissionが設定されているもの。Permissionがないものは、誰でも閲覧可能。）
const menuItems: Array<MenuItem> = [
    { type: ItemType.Title, dispName: "menu.dashboard" },
    { type: ItemType.Item, dispName: "menu.individualStore", pageName: PageName.DashboardShop, href: "/dashboard/0" },
    { type: ItemType.Item, dispName: "menu.allStore", pageName: PageName.DashboardAll, href: "/dashboard" },
    { type: ItemType.Title, dispName: "menu.detailedAnalysis" },
    { type: ItemType.Item, dispName: "menu.individualAnalysis", pageName: PageName.AnalyzeSingle, href: "/analyze" },
    { type: ItemType.Item, dispName: "menu.comparativeAnalysis", pageName: PageName.AnalyzeCompare, href: "/analyze_cmp" },
    //{ type: ItemType.Item, dispName: "menu.purchaserAnalysis", pageName: PageName.AnalyzeFunnel, href: "/funnel", permission: PermissionTypes.Develop_View },
    //{ type: ItemType.Item, dispName: "purchaser/Non-PurchaserAnalysis", permission: PermissionTypes.Develop_View  },
    //{ type: ItemType.Item, dispName: "areaAnalysis", permission: PermissionTypes.Develop_View  },
    { type: ItemType.Title, dispName: "menu.basicInformationRegistrationEdit" },
    { type: ItemType.Item, dispName: "menu.companyRegistrationEdit", pageName: PageName.InfoCom, href: "/company_list", permission: PermissionTypes.ManageCompany_View },
    { type: ItemType.Item, dispName: "menu.storeRegistrationEdit", pageName: PageName.InfoShop, href: "/shop_list", permission: PermissionTypes.ManageShop_View },
    { type: ItemType.Item, dispName: "menu.accountRegistrationEdit", pageName: PageName.InfoAccount, href: "/account_list", permission: PermissionTypes.ManageAccount_View },
    { type: ItemType.Item, dispName: "menu.mapRegistrationEdit", pageName: PageName.InfoMap, href: "/layout_list", permission: PermissionTypes.ManageLayout_View },
    { type: ItemType.Item, dispName: "menu.areaRegistrationEdit", pageName: PageName.InfoArea, href: "/area_list", permission: PermissionTypes.ManageArea_View },
    { type: ItemType.Item, dispName: "menu.entryLineRegistrationEdit", pageName: PageName.InfoLine, href: "/line_list", permission: PermissionTypes.ManageLine_View },
    { type: ItemType.Item, dispName: "menu.categoryRegistrationEdit", pageName: PageName.InfoGroup, href: "/group_list", permission: PermissionTypes.ManageGroup_View },
    //{ type: ItemType.Item, dispName: "menu.relationAreaAndCategory", pageName: PageName.InfoCategory, href: "/area_category", permission: PermissionTypes.Develop_View },
    { type: ItemType.Item, dispName: "underConstruction", pageName: PageName.ManageArea, href: "/area_regi_list", permission: PermissionTypes.Develop_View },
    { type: ItemType.Item, dispName: "menu.changePassword", pageName: PageName.ChangePassword, href: "/change_password" },
    { type: ItemType.Empty, dispName: "" },
]


type Props = {
    actPage: Pages
}

export const SideMenu: React.FC<Props> = (props) => {

    const { t, i18n } = useTranslation()

    const navigate = useNavigate()

    const { summarizeAccessCheck } = useAuthUserContext()
    
    /**
     * 言語の切替
     * @param event 
     */
    const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        i18n.changeLanguage(v)
        document.documentElement.lang = v
    }

    let menuContents: Array<React.ReactNode> = []
    //const items: Array<MenuItem> = mockOn ? menuItems4Mock : menuItems
    const items: Array<MenuItem> = menuItems
    items.forEach((elm, id) => {
        let itm: React.ReactNode
        let dispName = t(elm.dispName)
        if (elm.type === ItemType.Title) {
            // タイトル行
            itm = (<div className={style.title} key={id}>{dispName}</div>)
        } else if (elm.type === ItemType.Empty) {
            // 空行
            itm = (<div className={style.item1} key={id}>&emsp;</div>)
        } else {
            if (elm.pageName && elm.href) {
                // リンク行
                const to = elm.href as To
                if (elm.pageName === props.actPage) {
                    // 現在表示中のページはリンク無し
                    itm = (<div className={style.item2_act} key={id}>{dispName}</div>)
                } else {
                    // 他のページはリンクあり
                    if (elm.permission) {
                        // アクセスコントロールが必要な場合
                        if (summarizeAccessCheck(elm.permission)) {
                            itm = (<div className={style.item2} onClick={() => navigate(to)} key={id}>{dispName}</div>)
                        }
                    } else {
                        // 誰でもOKのもの
                        itm = (<div className={style.item2} onClick={() => navigate(to)} key={id}>{dispName}</div>)
                    }
                }
            } else {
                // ？多分、ここに来たものは設定ミスだ。
                itm = (<div className={style.item2} key={id}>{dispName}</div>)
            }
        }
        menuContents.push(itm)
    });

    return (
        <div className={style.menu}>
            <div className={style.langSw}>
                <label htmlFor="language">{t('header.language')}&nbsp;</label>
                <select id="language" onChange={handleChangeLanguage} value={i18n.language}>
                    <option value="ja">日本語</option>
                    <option value="en">English</option>
                </select>
            </div>
            {menuContents}
        </div>
    )
}