import React, { useMemo, useState, useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { AreaModel, AreaPackModel, UrlParameterType, ViewMode, ViewModeType } from "../../types/Management"
import { AREA_ID_SHIFT_VALUE } from "../../constants"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { TrackingPageView } from "../common/TrackingPageView"
import { UNRELATED_AREA_GROUP } from "../../constants"
import { useMouseMove } from "../../lib/useMouseMove"
import CategoryPicWriter from "../../lib/CategoryPicWriter"
import { ModalFrame } from "../common/ModalFrame"

import style from "./CategoryDetailModal.module.css"


const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface FrameProps {
    viewMode: ViewMode
    children: any
    onClose?: () => void
}

/**
 * エリアカテゴリの関連付け画面のフレーム枠コンポーネント
 * 　・エリア詳細から来る場合は、ダイアローグ風に
 * 　・サイドメニューから開くときは、通常ページ風に
 * するためのもの
 * 
 * @param props 
 * @returns 
 */
const CategoryDetailFrame: React.FC<FrameProps> = (props) => {
    const { t } = useTranslation()

    const { companyList, companyId, shopList, shopId, dataSourceList, dataSourceId, layoutList, layoutId, areaPackList, areaPackId } = useManagementDataContext()

    const handleClose = () => {
        if (props.onClose) props.onClose()
    }

    const title = useMemo(() => { return t("menu.relationAreaAndCategory") }, [t])

    const companyName = useMemo(() => {
        if (companyList && companyId) {
            const c = companyList.find(el => el.company_id === companyId)
            if (c) return c.name
        }
        return undefined
    }, [companyId, companyList])

    const shopName = useMemo(() => {
        if (shopId && shopList) {
            const s = shopList.find(el => el.shop_id === shopId)
            if (s) return s.name
        }
        return undefined
    }, [shopId, shopList])

    const areaCrumb = useMemo(() => {
        let result = ""
        if (dataSourceList && dataSourceId) {
            const ds = dataSourceList.find(el => el.layout_set_id === dataSourceId)
            if (ds) result += ds.name
            result += " ＞ "
        }
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) result += lay.name
            result += " ＞ "
        }
        if (areaPackId && areaPackList) {
            const pk = areaPackList.find(el => el.area_set_id === areaPackId)
            if (pk) result += pk.startYmd
        }
        return result
    }, [dataSourceId, dataSourceList, layoutId, layoutList, areaPackId, areaPackList])

    return (
        <ModalFrame page={PageName.InfoCategory} title={t("header.areaRegistrationEdit")} width={1120} height={688}>
            <div className={style.panel}>
                <div className={style.top}>
                    <div className={style.title}>{title}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.heading}>
                    <div className={style.row}><span className={style.txtLabel}>{t("companyName") }：</span><span className={style.name}>{companyName}</span></div>
                    <div className={style.row}><span className={style.txtLabel}>{t("storeName") }：</span><span className={style.name}>{shopName}</span></div>
                    <div className={style.row}><span className={style.txtLabel}>{t("area")}：</span><span className={style.name}>{areaCrumb}</span></div>
                </div>
                {props.children}
            </div>
        </ModalFrame>
    )
}


const CANVAS_WIDtH = 450
const CANVAS_HEIGHT = 350

interface Props { }

export const CategoryDetailModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { companyList, groupList, layoutId, layoutList, areaPackId, areaPackList, initManagementData, updateEditingAreaPack, saveAreaPack } = useManagementDataContext()
    const { ableToUpdate, ableToDelete } = useAccessControlContext()

    const location = useLocation()
    const search = location.search
    const state = location.state
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const viewMode = useMemo(() => {
        const m = queryParams.get(UrlParameterType.Mode)
        if (m) return m
        return state ? state.Mode : ViewModeType.Page
    }, [queryParams, state])

    const [writer, setWriter] = useState<CategoryPicWriter | undefined>(undefined)
    const [groupId, setGroupId] = useState<number>(0)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const ref = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)

    // ユーザー権限
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageAreaCateg_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageAreaCateg_Delete) }, [ableToDelete])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // 選択されたエリアリスト
    const areaList = useMemo(() => {
        if (areaPackId && areaPackList) {
            const pk = areaPackList.find(el => el.area_set_id === areaPackId)
            if (pk) return pk.area_list
        }
        return undefined
    }, [areaPackId, areaPackList])

    /**
     * 編集ボタン押下時の処理
     */
    const handleGoEdit = () => {
        if (!ableEdit) return
        if (groupId) {
            navigate("/area_category_edit?" + UrlParameterType.GroupId + "=" + groupId,
                { state: { GroupId: groupId, from: location } })
        }
    }

    /**
     * 閉じるボタン押下時の処理
     */
    const handleClose = () => {
        // ダイアローグの時はエリア詳細に戻る前に編集中のエリアパックを設定する
        if (viewMode === ViewModeType.Dialog) {
            if (areaPackId && areaPackList) {
                const ap = areaPackList.find(el => el.area_set_id === areaPackId)
                if (ap) updateEditingAreaPack(ap)
            }
        }
        navigate(-1)
    }

    /**
     * 全クリアボタン押下時の処理
     */
    const handleAllClear = () => {
        if (!ableDelete) return
        // 全クリアの前準備
        if (areaPackId && areaPackList) {
            const ap = areaPackList.find(el => el.area_set_id === areaPackId)
            if (ap) {
                // AreaPackModelをクローン
                const cloneAp: AreaPackModel = {
                    area_set_id: areaPackId,                    
                    startYmd: ap.startYmd,
                    area_list: ap.area_list.map(el => {
                        const area: AreaModel = {
                            area_id: el.area_id + AREA_ID_SHIFT_VALUE,
                            area_number: el.area_number,
                            name: el.name,
                            cell_ids: [...el.cell_ids],
                            area_type: el.area_type,
                            group_id: null
                        }
                        return area
                    }),
                    created_at: (new Date()).getTime(),
                    modified_at: (new Date()).getTime()
                }
                // 編集中に設定
                updateEditingAreaPack(cloneAp)
                // ダイアローグ表示
                setDialogOpen(true)
            }
        }
    }

    /**
     * 全クリア確認ダイアローグのキャンセルボタン押下時の処理
     */
    const handleClearCanceled = () => {
        setDialogOpen(false)
    }
    
    /**
     * 全クリア確認ダイアローグのOKボタン押下時の処理
     */
    const handleClearAccepted = () => {
        if (ableDelete) {
            saveAreaPack()
            setDialogOpen(false)
        }
    }

    /**
     * 平面図の再描画
     */
    const refreshMap = useCallback(() => {
        if (writer && ref && ref.current && aniRef && aniRef.current) {
            const ctx = ref.current.getContext("2d")
            const ani = aniRef.current.getContext("2d")
            if (ctx) writer.drawImageWithArea(ctx)
            if (ani) {
                if (groupId && areaList) {
                    const list = areaList.filter(el => el.group_id === groupId)
                    if (list) {
                        const ids = list.map(el => { return el.area_id })
                        writer.activeAreasDraw(ani, ids)
                    }                    
                } else {
                    // グループ未選択時はCanvasクリアする
                    writer.clearCanvas(ani)
                }
            }
        }        
    }, [groupId, writer, areaList])

    /**
     * 拡大ボタン押下時の処理
     */
    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            refreshMap()
        }
    }

    /**
     * 縮小ボタン押下時の処理
     */
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            refreshMap()
        }
    }

    /**
     * 元に戻すボタン押下時の処理
     */
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            refreshMap()
        }
    }

    /**
     * エリアカテゴリの選択・切替時の処理
     * @param event 
     */
    const handleGroupChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        if (id || id === 0) setGroupId(id)
        //refreshMap()
    }

    // グループ選択時の再描画
    useEffect(() => {
        refreshMap()
    }, [groupId])
    
    const handleRowOver = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.add(style.rowOver)
    }

    const handleRowOut = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.remove(style.rowOver)
    }

    // マウス操作のイベント登録
    const mousePress = useMouseMove(
        aniRef,
        (event: MouseEvent) => {
            // マウスがエリア上にあるときの処理
            const mouseInArea = (area: ResArea, posX: number, posY: number) => { }
            // マウスがエリア外にある時の処理
            const mouseOutArea = () => {}
            //マウスドラッグ中の処理
            if (writer) {
                const srcCanvas = ref.current
                const dstCanvas = aniRef.current
                if (srcCanvas && dstCanvas) writer.mouseMoveWithArea(event, mousePress, srcCanvas, dstCanvas, mouseInArea, mouseOutArea)
            }
        }, (event: MouseEvent) => {
            // マウスボタンを離したときの処理
            if (writer) writer.mouseRelease()
            // 再描画
            //if (groupId) {
                refreshMap()
            //} else {
                // グループ未選択時はCanvasクリアする
                //if (aniRef && aniRef.current) {
                    //const ctx = aniRef.current.getContext("2d")
                    //if (ctx && writer) writer.clearCanvas(ctx)
                //}                    
            //}
        }, (event: MouseEvent) => {
            // マウスボタンを押したときの処理
            if (writer) writer.mousePress()
        }
    )
    
    /**
     * エリアカテゴリの選択Option生成
     */
    const groupSelectOption = useMemo(() => {
        const elm = (<option key="group-select-x" value={0}>{t("msgSelectAreaCategory")}</option>)
        const uc = (areaList) ? areaList.filter(area => (!area.group_id || area.group_id === -1)).length : 0
        const unElm = (<option key="l-group-select-u" value={UNRELATED_AREA_GROUP}>《{t("options.notBelongCategory") }》{uc ? "［" + uc + "］" : ""}</option>)
        if (groupList) {
            const ops = groupList.map((el, i) => {
                const cnt = (areaList) ? areaList.filter(area => area.group_id === el.group_id).length : 0
                return (<option key={'group-select-' + i + 1} value={el.group_id}>{el.name}{cnt ? "［" + cnt + "］" : ""}</option>)
            })
            if (ops) return [elm, ...ops, unElm]
        }
        return [elm]
    }, [groupList, t, areaList])

    // Writerの生成と削除
    useEffect(() => {
        if (layout && layout.mapping && areaList) {
            if (writer === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                const w = new CategoryPicWriter(resLay, areaList, CANVAS_WIDtH, CANVAS_HEIGHT)
                setWriter(w)
            } else {
                // writerが設定されたら描画する
                refreshMap()
            }
        } else {
            // 平面図をクリアし、Writerを削除する
            if (writer && ref && ref.current) {
                const ctx = ref.current.getContext("2d")
                if (ctx) writer.clearCanvas(ctx)
                setWriter(undefined)
            }
            // エリアカテゴリの選択を解除
            setGroupId(0)
        }
    }, [layout, areaList, writer])

    /**
     * マウス押下時の処理
     * /
    useEffect(() => {
        if (mousePress) {
            if (writer) writer.mousePress()
        } else {
            // 再描画
            if (groupId) {
                refreshMap()
            } else {
                // グループ未選択時はCanvasクリアする
                if (aniRef && aniRef.current) {
                    const ctx = aniRef.current.getContext("2d")
                    if (ctx && writer) writer.clearCanvas(ctx)
                }                    
            }
        }
    }, [writer, ref, aniRef, mousePress, refreshMap, groupId])*/

    // データ初期化
    useEffect(() => {
        // 企業は必ず１件以上ある。ない時はAPIからデータを取得する
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    /**
     * エリア一覧の内容生成
     */
    const items: React.ReactNode = useMemo(() => {
        const resutl: any = []
        if (groupId) {
            // グループ選択時
            if (areaList) {
                areaList.forEach((area, idx) => {
                    if ((area.group_id === groupId) || (groupId === UNRELATED_AREA_GROUP && !area.group_id)) {
                        const itm = (
                            <tr key={idx} onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
                                <td className={style.tdChk}></td>
                                <td className={style.tdNo}>{area.area_number}</td>
                                <td className={style.tdName}>{area.name}</td>
                            </tr>
                        )
                        resutl.push(itm)
                    }
                })
            }
        } else {
            // グループ未選択時
            const itm = (
                <tr key={0}>
                    <td className={style.tdColsp} colSpan={3}>（{t("msgHaveUnselected") }）</td>
                </tr>
            )
            resutl.push(itm)
        }
        return resutl
    }, [areaList, groupId, t])

    /**
     * ボタン制御
     */
    const buttonDisabled = useMemo(() => {
        if (groupId > 0 && areaList) return false
        return true
    }, [groupId, areaList])

    return (
        <CategoryDetailFrame
            viewMode={viewMode === ViewModeType.Dialog ? ViewModeType.Dialog : ViewModeType.Page}
            onClose={handleClose}
        >
            <div className={style.body}>
                {isProduction ? (<TrackingPageView page_title="bsinfo-category-detail" />) : (null)}
                <div className={style.left}>
                    <div className={style.choice}>
                        <label className={style.label} htmlFor="group_select">{t("label.areaCategorySelection") }：</label>
                        <select id="group_select" className={style.groupSelect} value={groupId} onChange={handleGroupChanged}>
                            {groupSelectOption}
                        </select>
                    </div>
                    <div className={style.subtitle}>{t("areaView") }</div>
                    <div className={style.arealist}>
                        {groupId ? (
                            <table className={style.table}>
                                <thead>
                                    <tr className={style.thRow}>
                                        <th className={style.thChk}></th>
                                        <th className={style.thNo}>{t("number") }</th>
                                        <th className={style.thName}>{t("areaName") }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </table>                        
                        ) : (null)}
                    </div>
                </div>
                <div className={style.right}>
                    <div className={style.buttons}>
                        <div className={style.centerBtn}>{
                            ableEdit ? (<Button name={"edit"} label={t("edit")} styleType={StyleType.Normal} onClick={handleGoEdit} disabled={buttonDisabled} />) : (<>　</>)
                        }</div>
                    </div>
                    <div className={style.map}>
                        <div className={style.wrap}>
                            <canvas className={style.canvas} ref={ref} width={CANVAS_WIDtH} height={CANVAS_HEIGHT} />
                            <canvas className={style.aniCanvas} ref={aniRef} width={CANVAS_WIDtH} height={CANVAS_HEIGHT} />
                            <div className={style.zoomBtn}>
                                <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                            </div>
                        </div>
                    </div>
                    <div className={style.lowerBtn}>
                        <div>
                            {
                                ableDelete ? (<Button name={"allclear"} label={t("label.clearAllAreaCategories")} styleType={StyleType.Danger} onClick={handleAllClear} disabled={buttonDisabled} />) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DeleteDialog requestOpen={dialogOpen} guideMessage={t("msgConfirmClearAllCategories")} paneSize={PaneSizeType.forGroup} onCancel={handleClearCanceled} onDelete={handleClearAccepted}></DeleteDialog>
        </CategoryDetailFrame>
    )
}