import React, { useMemo, useRef, useEffect } from "react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js'
import { useTranslation } from "react-i18next"

import Utils from "../../lib/Utils"
import { IndividualChart, IndividualChartType, ChartDataUnit, ChartDataUnitType } from "./IndividualLineChart"

import style from "./IndividualMonthryLineGraph.module.css"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
)

interface Props {
    type: IndividualChart
    title: string
    data: number[]
    unit: ChartDataUnit
}

export const IndividualMonthryLineGraph: React.FC<Props> = (props) => {

    const { t, i18n } = useTranslation()
    
    const refList = useRef<HTMLDivElement>(null)
    const refGraph = useRef<HTMLDivElement>(null)

    const min = useMemo(() => {
        if (props.data && props.data.length > 0) {
            let mi = props.data.reduce((a, b) => { return Math.min(a, b) }, Infinity)
            return mi
        }
        return 0
    }, [props.data])

    const max = useMemo(() => {
        if (props.data && props.data.length > 0) {
            let mx = props.data.reduce((a, b) => { return Math.max(a, b) }, -Infinity)
            return mx
        }
        return 0
    }, [props.data])

    // 単位
    const unit = useMemo(() => {
        if (props.unit === ChartDataUnitType.money) {
            return t("unit.moneyHeader")
        } else if (props.unit === ChartDataUnitType.number) {
            return t("unit.number")
        } else if (props.unit === ChartDataUnitType.person) {
            return t("unit.person")
        } else if (props.unit === ChartDataUnitType.time) {
            return t("unit.second")
        } else if (props.unit === ChartDataUnitType.rate) {
            return t("unit.percent")
        }
        return ""
    }, [t, props.unit])

/*    const yAxisTitle = useMemo(() => {
        // 日本語の場合は表示しない
        // 金額はいまのところ円しかないので表示しない
        if (i18n.language === "ja" || props.unit === ChartDataUnitType.money) {
            return { display: false }
        } else {
            return { display: true, text: unit }
        }
    }, [props.unit, i18n.language, unit])*/

    /*const pluginPeopleTitle = {
        id: 'pluginPeopleTitle',
        afterDraw: (chart: any) => {
            console.log("★★★afterDraw★★★")
            const ctx = chart.ctx
            ctx.save()
            ctx.fillStyle = "#7E84A3"
            const x = 20
            const y = 0
            ctx.textAlign = 'left'
            ctx.fillText('(peaple)', x, y)
            ctx.restore()
        },        
    }*/

    const options = useMemo(() => {
        const afterTick2Label = (axis: LinearScale) => {
            // グラフの縦軸の数字をフォーマットする
            if (props.type === IndividualChartType.AvgInShopTime) {
                axis.ticks.forEach(el => {
                    el.label = Utils.formatMillisecToTime(el.value)
                })
            } else if (props.type === IndividualChartType.ShopSales) {
                axis.ticks.forEach(el => {
                    el.label = "￥" + Utils.formatCommaInteger(el.value)
                })
            } else if (props.type === IndividualChartType.NumOfBuyers || props.type === IndividualChartType.NumOfVisitors) {
                if (i18n.language === "ja") {
                    axis.ticks.forEach(el => {
                        el.label = Utils.formatCommaInteger(el.value) + unit
                    })
                }
            }            
        }
        const opt = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: { display: false },
                tooltip: {
                    callbacks: {
                        beforeLabel: (context: any) => {
                            // グラフのツールチップの数字をフォーマットする
                            const idx = context.dataIndex
                            const val = context.dataset.data[idx]
                            if (props.type === IndividualChartType.AvgInShopTime) {
                                context.formattedValue = Utils.formatMillisecToTime(val)
                            } else if (props.type === IndividualChartType.ShopSales) {
                                context.formattedValue = "￥" + Utils.formatCommaInteger(val)
                            } else if (props.type === IndividualChartType.NumOfBuyers || props.type === IndividualChartType.NumOfVisitors) {
                                if (i18n.language === "ja") context.formattedValue = Utils.formatCommaInteger(val) + unit
                            }
                            return ""
                        }
                    }
                },
            },
            elements: {
                point: { radius: 5 },
                line: { borderWidth: 3, tension: 0 }
            },
            scales: {
                x: {
                    ticks: { display: true },
                    grid: { display: false, drawBorder: false },
                },
                y: {}
            },
            animation: {
                onComplete: (animation: any) => {
                    const axiswidth = (animation.chart.scales.y.width + animation.chart.scales.y.left) * 0.85
                    if (refList && refList.current) {
                        // グラフの縦軸の数字の幅を考慮して、表の位置を調整する
                        refList.current.style.paddingLeft = axiswidth + "px"
                    }
                }
            }
        }
        if (min === 0 && max === 0) {
            // データがない時は縦軸目盛りは0～1にする
            opt.scales.y = {
                suggestedMin: 0,
                suggestedMax: 10,
                beginAtZero: true,
                grid: { drawBorder: false },
                afterTickToLabelConversion: afterTick2Label,
            }
        } else {
            opt.scales.y = {
                grid: { drawBorder: false },
                afterTickToLabelConversion: afterTick2Label,
                title: { display: false }
            }
        }
        return opt
    }, [props, min, max, unit, i18n.language])

    // データの桁数にあわせてグラフの幅を調整する
    useEffect(() => {
        if (refList.current && refGraph.current) {
            if (max === 0) {
                // データがない時
                refList.current.style.width = "920px"
                refGraph.current.style.width = "900px"
            } else if (max < 1000) {
                refList.current.style.width = "900px"
                refGraph.current.style.width = "900px"
            } else if (max < 100000) {
                refList.current.style.width = "1100px"
                refGraph.current.style.width = "1100px"
            } else if (max < 1000000000) {
                refList.current.style.width = "1200px"
                refGraph.current.style.width = "1200px"
            } else {
                refList.current.style.width = "1300px"
                refGraph.current.style.width = "1300px"
            }
        }
    }, [max])

    const year = new Date().getFullYear().toString()
    const data = {
        labels: [year + "-01", year + "-02", year + "-03", year + "-04", year + "-05", year + "-06", year + "-07", year + "-08", year + "-09", year + "-10", year + "-11", year + "-12"],
        datasets: [
            {
                label: t(props.title),
                data: props.data,
                borderColor: 'rgb(0, 98, 255)',
                backgroundColor: 'rgb(255, 255, 255)',    
            }
        ]
    }

    const getDataRow = () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => {
            let value = ""
            if (props.data.length >= m) {
                const v = props.data[m - 1] 
                if (props.type === IndividualChartType.AvgInShopTime) {
                    value = Utils.formatMillisecToTime(v)
                } else if (props.type === IndividualChartType.PurchasingRate) {
                    value = Utils.formatSignCommaPointOne(v) + unit
                } else if (props.type ===  IndividualChartType.AvgNumOfPurchases) {
                    value = Utils.formatCommaPointOne(v) + unit
                } else if (props.type === IndividualChartType.AvgCustomerPrice) {
                    value = Utils.formatCommaPointOne(v) + unit
                } else {
                    if (props.unit === ChartDataUnitType.money) {
                        value = "￥" + Utils.formatCommaInteger(v)
                    } else {
                        if (i18n.language === "ja") {
                            value = Utils.formatCommaInteger(v) + unit
                        } else {
                            value = Utils.formatCommaInteger(v)
                        }
                    }
                }
            }
            const k = props.type + "-" + m
            return (
                <td key={k}>{value}</td>
            )
        })
    }

    return (
        <div className={style.main}>
            <div className={style.head}>{t(props.title)}</div>
            <div className={style.scrollable}>
                <div className={style.graph} ref={refGraph}>
                    {
                        (i18n.language === "en" && props.unit === ChartDataUnitType.person) ? (<div className={style.unitLeft}>({unit})</div>) : (null)
                    }                
                    <Line options={options} data={data} />
                </div>
                <div className={style.list} ref={refList}>
                    {
                        (i18n.language === "en" && props.unit === ChartDataUnitType.person) ? (<div className={style.unitRight}>({unit})</div>) : (null)
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>{t("jan")}</th>
                                <th>{t("feb")}</th>
                                <th>{t("mar")}</th>
                                <th>{t("apr")}</th>
                                <th>{t("may")}</th>
                                <th>{t("jun")}</th>
                                <th>{t("jul")}</th>
                                <th>{t("aug")}</th>
                                <th>{t("sep")}</th>
                                <th>{t("oct")}</th>
                                <th>{t("nov")}</th>
                                <th>{t("dec")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {getDataRow()}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}