// 分析画面のマップサイズ
export const DEFAULT_CANVAS_WIDTH = 740
export const DEFAULT_CANVAS_HEIGHT = 440

// 弦グラフの横幅
export const CHORD_WIDTH = 610
// 弦グラフの縦長さ
export const CHORD_HEIGHT = 410

// タイムゾーン
export const TIME_ZONE_JP = 'Asia/Tokyo'
export const TIME_ZONE_JP_N = 9

// ダッシュボードの滞在時間しきい値デフォルト値
export const THRESHOLD_4_COMPANY = 15

// タイムアウト時間(ms)
export const TIMEOUT_MINUTE = 30 * 60 * 1000

// 画面操作系イベント
export const DEFAULT_EVENTS = ["mousedown", "touchstart"]

// 分析条件のデフォルト値（開始時間）
export const START_DUMY_TIME = 800

// 分析条件のデフォルト値（終了時間）
export const END_DUMY_TIME = 2200

// 分析条件のデフォルト値（滞在時間しきい値）
export const STAY_TIME_THRESHOLD = 15

// 時系列グラフの横幅（通常時）
export const TIMELINE_WIDTH_NORMAL = 800

// 時系列グラフの横幅（横スクロール時）
export const TIMELINE_WIDTH_SCROLL = 7000

// 時系列グラフの縦長さ
export const TIMELINE_HEIGHT = 280

// 時系列グラフの横スクロール適用データ数
export const TIMELINE_ITEMS_BORDER = 50

// 企業情報のENDの初期加算値(８年)
export const COMPANY_TERM_LEN = 8 * 365 * 24 * 60 * 60 * 1000

// データソースの名称（初期値）
export const DS_NO_NAME = "_no_name_"

// 文字列型の日付フォーマット
export const STR_YMD_FORMAT = "yyyy-MM-dd"

// レイアウト登録のキャンバスサイズ
export const WORK_CANVAS_WIDTH = 768
export const WORK_CANVAS_HEIGHT = 480

// レイアウト平面図の標準サイズ(px)
export const IMAGE_FILE_WIDTH = 640 * 2
export const IMAGE_FILE_HEIGHT = 400 * 2

// エリア編集
// エリア追加編集時にエリアID（1～9999）を確保するために既存エリアIDをシフトする値
export const AREA_ID_SHIFT_VALUE = 10000

// エリア編集
// 新規（未登録）エリアのID
export const NEW_AREA_ID = -999

// エリア編集
// 新規エリア番号
export const NEW_NUMBER = -1

// 未登録エリアカテゴリのID
export const UNRELATED_AREA_GROUP = -1

// 検索結果の顧客数上限値
export const MAX_SEARCH_RESULT = 200000

// 分析期間の上限日数
export const MAX_ANALYSIS_PERIOD = 7
