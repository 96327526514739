import React, { useState, useRef, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { AreaType, AreaCountingType } from "../../api/data/core/Enums"
import { Coord } from "../../api/data/core/Coord"
import { ResLayout, ResArea } from "../../api/data/analysis/FullLayout"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { AreaPackModel, EditMode, EditModeType } from "../../types/Management"
import { PermissionTypes } from "../../types/Permissions"
import { AREA_ID_SHIFT_VALUE, NEW_AREA_ID, STR_YMD_FORMAT } from "../../constants"
import AreaEditPicWriter from "../../lib/AreaEditPicWriter"
import AreaMagGlassPicWriter from "../../lib/AreaMagGlassPicWriter"
import { useMouseMove } from "../../lib/useMouseMove"
import { useMouseClick } from "../../lib/useMouseClick"
import { useWriterCallback } from "../../lib/useWriterCallback"
import { Button, StyleType } from "../../component/button/Button"
import { AreaMagControler } from "./AreaMagControler"

import style from "./AreaRegiMapper.module.css"

// 大きい窓のキャンバスサイズ
const CANVAS_WIDTH_LG = 650
const CANVAS_HEIGHT_LG = 470

// 小さい窓のキャンバスサイズ
const CANVAS_WIDTH_SM = 300
const CANVAS_HEIGHT_SM = 217


interface Props {
    mode: EditMode
    areaId: number
    areaNumber: string
    areaName: string
    selectedType: AreaType
    onChangeNumber: (newNumber: string) => void
    onChangeName: (newName: string) => void
    onChangeType: (newType: AreaType) => void
}

export const AreaRegiMapper: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    const { editingAreaPack, updateEditingAreaPack, layoutId, layoutList, areaPackId, changeAreaPack } = useManagementDataContext()
        
    const { ableToCreate, ableToUpdate } = useAccessControlContext()
    
    const [lgWriter, setLgWriter] = useState<AreaEditPicWriter | undefined>(undefined)
    const [smWriter, setSmWriter] = useState<AreaMagGlassPicWriter | undefined>(undefined)
    const [currentLayoutId, setCurrentLayoutId] = useState<number | undefined>(undefined)
    const [currentAreaId, setCurrentAreaId] = useState<number | undefined>(undefined)
    ////const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined)
    //const [selectedType, setSelectedType] = useState<AreaType>(AreaCountingType.Normal)
    //const [areaNumber, setAreaNumber] = useState<string>("1")
    //const [areaName, setAreaName] = useState<string>("")
    const [errMsg4Number, setErrMsg4Number] = useState<string>("")
    const [errMsg4Name, setErrMsg4Name] = useState<string>("")
    const [errMsg4Cells, setErrMsg4Cells] = useState<string>("")
    const [cells, setCells] = useState<Coord[]>([])
    
    const imgRef = useRef<HTMLCanvasElement>(null)
    const drwRef = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const smlRef = useRef<HTMLCanvasElement>(null)
    const glsRef = useRef<HTMLCanvasElement>(null)
    const movRef = useRef<HTMLCanvasElement>(null)

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])

    // editingAreaPack初期化
    useEffect(() => {
        if (props.mode === EditModeType.New && props.areaId === 0 && editingAreaPack === undefined && areaPackId === undefined) {
            // 新規モード
            let dt = new Date()
            let ymd = format(dt, STR_YMD_FORMAT)
            const tm = dt.getTime()
            changeAreaPack(0)
            const ap: AreaPackModel = {
                area_set_id: 0,
                startYmd: ymd,
                area_list: [],
                created_at: tm,
                modified_at: tm
            }
            updateEditingAreaPack(ap)
            setCurrentAreaId(0)
            console.log("新規モードの初期化")
        } else if (props.mode === EditModeType.Edit && props.areaId !== 0) {
            // 編集モードの時は、areaPackIdは設定されているはず
        }
    }, [props, areaPackId, editingAreaPack, changeAreaPack, updateEditingAreaPack])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutId && layoutList) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // エリアリスト
    const areaList = useMemo(() => {
        if (editingAreaPack) {
            return editingAreaPack.area_list
        }
        return undefined
    }, [editingAreaPack])

    // 編集不可能フラグ
    const disableEdit = useMemo(() => {
        if (props.mode === EditModeType.View) return true
        return false
    }, [props.mode])

    const typeSelectOptions = useMemo(() => {
        const ops = Object.values(AreaCountingType).map((el, i) => {
            const k = "areaType." + el
            const str = t(k)
            return (<option key={"t-select-" + (i + 1)} value={el}>{str}</option>)
        })
        return ops
    }, [t])
    
    const handleZoomOut = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magEnlarge(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleZoomReset = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magReset(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleZoomIn = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magSmaller(gls, mov, imgCtx, drwCtx)
            }
        }
    }

    const handleMoveUp = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveUp(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveRight = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveRight(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveDown = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveDown(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveLeft = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveLeft(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipUp = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipUp(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipRight = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipRight(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipDown = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipDown(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipLeft = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipLeft(gls, mov, imgCtx, drwCtx)
            }
        }
    }

    // 入力チェック
    const validationCheck = () => {
        // Num Check
        let numOk = (props.areaNumber && props.areaNumber.length > 0)
        // Num Duplicate Check
        if (!numOk) {
            const msg = t("validation.errNoInput")
            setErrMsg4Number(msg)
        } else if (editingAreaPack) {
            const num = parseInt(props.areaNumber)
            const dup = editingAreaPack.area_list.filter(el => el.area_number === num)
            if (dup.length > 1) {
                const msg = t("validation.errDupAreaNumber")
                setErrMsg4Number(msg)
                numOk = false
            }
        }
        // Name Check
        let nameOk = (props.areaName && props.areaName.length > 0)
        if (!nameOk) {
            const msg = t("validation.errInputAreaName")
            setErrMsg4Name(msg)
        }
        // Cell Check
        let cellOk = (cells && cells.length > 0)
        if (!cellOk) {
            const msg = t("validation.errNoCells")
            setErrMsg4Cells(msg)
        }
        if (numOk && nameOk && cellOk) {
            setErrMsg4Cells("")
            setErrMsg4Name("")
            setErrMsg4Number("")
            return true
        }
        return false
    }

    // 完了ボタン押下時の処理
    const handleFinish = () => {
        if (editingAreaPack) {
            if (validationCheck()) {
                // 更新用にクローン
                const newPack = { ...editingAreaPack }
                // 新しいエリアIDを決める
                const newId: number = newPack.area_list.map(el => {return el.area_id}).filter(el => (el > 0 && el < AREA_ID_SHIFT_VALUE)).reduce((a, b) => { return Math.max(a, b) }, 0) + 1
                if (props.areaId !== 0 && props.mode === EditModeType.Edit) {
                    // エリアIDが0でなく、編集モードのとき
                    // エリアを探して更新
                    const ta = newPack.area_list.find(el => el.area_id === props.areaId)
                    if (ta) {
                        ta.area_number = parseInt(props.areaNumber)
                        ta.name = props.areaName
                        ta.area_type = props.selectedType
                        ta.cell_ids = cells
                        ta.group_id = null
                    }
                } else {
                    // エリアID=0 新規モード（エリアIDがマイナスはありえない前提）
                    // 新規作成エリアを探す
                    const area = newPack.area_list.find(el => el.area_id === NEW_AREA_ID)
                    // データを更新
                    if (area) {
                        area.area_id = newId
                        area.area_number = parseInt(props.areaNumber)
                        area.name = props.areaName
                        area.area_type = props.selectedType
                        area.group_id = null
                    }
                }
                // エリアパックを更新
                updateEditingAreaPack(newPack)

                //　------
                // 次の新規エリアを準備

                // エリア番号は最大値+1
                const newNumber = newPack.area_list.map(el => {return el.area_number}).reduce((a, b) => { return Math.max(a, b) }, 0) + 1
                props.onChangeNumber(newNumber.toString())
                props.onChangeName("")
                setCells([])
                // 平面図のエリア枠を再描画
                if (imgRef && imgRef.current && drwRef && drwRef.current) {
                    const imgCtx = imgRef.current.getContext("2d")
                    const drwCtx = drwRef.current.getContext("2d")
                    if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                }
                // Writerのマップとエリア番号を更新
                if (lgWriter) {
                    lgWriter.oneStepUp(newId)
                    lgWriter.updateAreaNumber(newNumber)
                }
                // エラーメッセージクリア
                if (errMsg4Cells) setErrMsg4Cells("")
                if (errMsg4Name) setErrMsg4Name("")
                if (errMsg4Number) setErrMsg4Number("")
            }
        }
    }

    /**
     * 選択したエリアを未選択の状態に戻します。
     */
    const handleReset = () => {
        if (lgWriter) {
            lgWriter.eraseSelectedArea()
            setCells([])
            if (imgRef && imgRef.current && drwRef && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
            }
        }
    }
    
    const handleChangeAreaNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            // 数字チェック
            const regex = new RegExp(/^[1-9]+\d*$/)
            const res = regex.test(event.target.value)
            if (res) {
                // 重複チェック
                if (editingAreaPack) {
                    const num = parseInt(event.target.value)
                    const dup = editingAreaPack.area_list.find(el => el.area_number === num)
                    if (dup) {
                        const msg = t("validation.errDupAreaNumber")
                        setErrMsg4Number(msg)
                        //return
                    }
                }
                // エリア番号を更新
                props.onChangeNumber(event.target.value)
                if (errMsg4Number) setErrMsg4Number("")
                if (lgWriter) {
                    const num = parseInt(event.target.value)
                    lgWriter.updateAreaNumber(num)
                }
            } else {
                const msg = t("validation.errNotNumber")
                setErrMsg4Number(msg)
            }
        } else {
            // 空文字列の場合
            props.onChangeNumber(event.target.value)
            if (lgWriter) {
                lgWriter.updateAreaNumber(undefined)
            }
        }
    }
        
    const handleChangeAreaName = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChangeName(event.target.value)
        if (event.target.value && event.target.value.length > 0) {
            setErrMsg4Name("")
        }
    }

    const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        if (v === AreaCountingType.Clerk) {
            props.onChangeType(AreaCountingType.Clerk)
        } else if (v === AreaCountingType.Casher) {
            props.onChangeType(AreaCountingType.Casher)
        } else if (v === AreaCountingType.ChangingRoom) {
            props.onChangeType(AreaCountingType.ChangingRoom)
        } else {
            props.onChangeType(AreaCountingType.Normal)
        }
    }
        
    // Writer初期化
    useEffect(() => {
        if (layout && layout.mapping && props.mode) {
            if (lgWriter === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                // 大きい窓用のWriter作成
                let modeType: EditMode = EditModeType.New
                if (props.mode === EditModeType.Edit) {
                    modeType = EditModeType.Edit
                } else if (props.mode === EditModeType.View) {
                    modeType = EditModeType.View
                }
                const lg_writer = new AreaEditPicWriter(resLay, (areaList ? areaList : []), CANVAS_WIDTH_LG, CANVAS_HEIGHT_LG, modeType, null)
                lg_writer.pathDepth = 1
                setLgWriter(lg_writer)
                setCurrentLayoutId(layoutId)
                if (props.areaNumber) lg_writer.updateAreaNumber(parseInt(props.areaNumber))
                if (smWriter === undefined) {
                    // 小さい窓用のWriter作成
                    const writer = new AreaMagGlassPicWriter(resLay, (areaList ? areaList : []), CANVAS_WIDTH_SM, CANVAS_HEIGHT_SM)
                    writer.pathDepth = 1
                    // Writerの親子関係構築
                    writer.setSlaveWriter(lg_writer)
                    setSmWriter(writer)
                    // 小さい窓の背景画像を描画（一度だけ）
                    //if (smlRef && smlRef.current) {
                        // Edit/ViewモードでエリアIDが指定されていた場合は虫眼鏡の位置をエリア中央にする。
                        //if ((props.mode === EditModeType.Edit || props.mode === EditModeType.View) && areaId) writer.setPosition(parseInt(areaId))
                    //}
                }
            } else {
                // 再描画
                if (smWriter && glsRef && glsRef.current && movRef && movRef.current) {
                    smWriter.drawMagnifyingGlass(glsRef.current, movRef.current).then(none => {
                        if (smWriter.imgObject === undefined && smlRef && smlRef.current) {
                            const smCtx: CanvasRenderingContext2D | null | undefined = smlRef.current.getContext("2d")
                            if (smCtx) smWriter.drawFitImage(smCtx)
                        }
                        // 大きい窓の背景を描画
                        if (imgRef && imgRef.current && drwRef && drwRef.current) {
                            const imgCtx = imgRef.current.getContext("2d")
                            const drwCtx = drwRef.current.getContext("2d")
                            if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                        }
                    })
                }
            }
        }
    }, [layout, layoutId, areaList, smlRef, props.mode, props.areaNumber, lgWriter, smWriter, setLgWriter, setSmWriter, t])

    // 対象レイアウトの変化を監視
    useEffect(() => {
        if (((layoutId === undefined) || (layoutId && layoutId !== currentLayoutId)) && lgWriter && smWriter) {
            // レイアウトが変わったらWriterを再構築
            if (imgRef && imgRef.current && drwRef && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) {
                    imgCtx.clearRect(0, 0, CANVAS_WIDTH_LG, CANVAS_HEIGHT_LG)
                    drwCtx.clearRect(0, 0, CANVAS_WIDTH_LG, CANVAS_HEIGHT_LG)
                }
            }
            if (smlRef && smlRef.current && glsRef && glsRef.current) {
                const smlCtx = smlRef.current.getContext("2d")
                const glsCtx = glsRef.current.getContext("2d")
                if (smlCtx && glsCtx) {
                    smlCtx.clearRect(0, 0, CANVAS_WIDTH_SM, CANVAS_HEIGHT_SM)
                    glsCtx.clearRect(0, 0, CANVAS_WIDTH_SM, CANVAS_HEIGHT_SM)
                }
            }
            setLgWriter(undefined)
            setSmWriter(undefined)
        }
    }, [layoutId, currentLayoutId, lgWriter, smWriter])

    useEffect(() => {
        if (props.areaId !== currentAreaId && lgWriter && smWriter) {
            // エリアが変わったら編集対象を変更する
            if (lgWriter.editAreaId === -999) {
                // Mapの-999を消す
                lgWriter.eraseSelectedArea()
                // 編集エリアを変更
                lgWriter.setEditArea(props.areaId)
            }

        }
    }, [currentAreaId, props.areaId, lgWriter, smWriter])

    /**
     * 小さい窓のマウス処理
     */
    const mousePress = useMouseMove(movRef, (event: MouseEvent) => {
        // 虫眼鏡移動
        if (smWriter) {
            const srcCanv = glsRef.current
            const dstCanv = movRef.current
            // ドラッグ中はアニメーション層に描画
            if (srcCanv && dstCanv) smWriter.mouseMoveOnGlassMagnifying(event, mousePress, srcCanv, dstCanv)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したとき
        if (smWriter) {
            smWriter.mouseRelease()
            // 再描画
            if (glsRef && glsRef.current && movRef && movRef.current) {
                smWriter.drawMagnifyingGlass(glsRef.current, movRef.current).then(none => {
                    if (smWriter.imgObject === undefined && smlRef && smlRef.current) {
                        const smCtx: CanvasRenderingContext2D | null | undefined = smlRef.current.getContext("2d")
                        if (smCtx) smWriter.drawFitImage(smCtx)
                    }
                    // 大きい窓の背景を描画
                    if (imgRef && imgRef.current && drwRef && drwRef.current) {
                        const imgCtx = imgRef.current.getContext("2d")
                        const drwCtx = drwRef.current.getContext("2d")
                        if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                    }
                })
            }
        }
    }, (event: MouseEvent) => {
        // マウスボタンを押したとき
        if (smWriter) {
            smWriter.mousePress()
        }
    
    })

    /**
     * 大きい窓のマウス処理
     */
    const mouseClick = useMouseClick(aniRef,
        (event: MouseEvent) => {
            // マウスMOVEイベント
            if (lgWriter && props.mode !== EditModeType.View) {
                if (aniRef && aniRef.current && drwRef && drwRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (aniCtx && drwCtx) {
                        if (mouseClick) {
                            // マウスを押したまま動かしているとき
                            lgWriter.mouseDrawing(event, drwCtx)
                        } else {
                            // マウスを動かしているだけ
                            lgWriter.mouseOnArea(event, (areaId: number) => {
                                // セル上のマーカーを消す
                                if (aniRef && aniRef.current) {
                                    const aniCtx = aniRef.current.getContext("2d")
                                    if (aniCtx) lgWriter.clearMarker(aniCtx)
                                }
                            }, () => {
                                // エリアでないセル
                            }, aniCtx)
                        }
                    }
                }
            }
        },
        (event: MouseEvent) => {
            // マウスOUTイベント
            if (lgWriter && props.mode !== EditModeType.View) {
                // セル上のマーカーを消す
                if (aniRef && aniRef.current) {
                    const aniCtx = aniRef.current.getContext("2d")
                    if (aniCtx) lgWriter.clearMarker(aniCtx)
                }
            }
        },
        (event: MouseEvent) => {
            // マウスDOWNイベント
            if (lgWriter && props.mode !== EditModeType.View) {
                if (drwRef && aniRef && drwRef.current && aniRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (drwCtx && aniCtx) {
                        lgWriter.mouseDown(event, drwCtx, aniCtx)
                    }
                }
            }
        },
        (event: MouseEvent) => {
            // マウスUPイベント
            if (lgWriter && props.mode !== EditModeType.View) {
                if (drwRef && aniRef && drwRef.current && aniRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (drwCtx && aniCtx) {
                        lgWriter.mouseUp(event, drwCtx, aniCtx)
                    }
                }
            }
        }
    )

    /**
     * AreaEditPicWriterのセル編集イベントを処理します。
     * @param update 
     */
    const cellEditCallback = (update: ResArea) => {
        setCells(update.cell_ids)
        if (update.cell_ids.length > 0 && errMsg4Cells.length > 0) setErrMsg4Cells("")
    }

    // Writerのセル編集イベントを処理するコールバックを登録
    useWriterCallback(lgWriter, cellEditCallback)

    /*useEffect(() => {
        if (mousePress) {
            // マウスでつまんだ瞬間の位置を記録
            if (smWriter) smWriter.mousePress()
        } else {
            // マウスをはなした直後に再描画
            if (smWriter) {
                if (glsRef && glsRef.current && movRef && movRef.current) {
                    smWriter.drawMagnifyingGlass(glsRef.current, movRef.current).then(none => {
                        if (smWriter.imgObject === undefined && smlRef && smlRef.current) {
                            const smCtx: CanvasRenderingContext2D | null | undefined = smlRef.current.getContext("2d")
                            if (smCtx) smWriter.drawFitImage(smCtx)
                        }
                        // 大きい窓の背景を描画
                        if (imgRef && imgRef.current && drwRef && drwRef.current) {
                            const imgCtx = imgRef.current.getContext("2d")
                            const drwCtx = drwRef.current.getContext("2d")
                            if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                        }
                    })
                }
            }
        }
    }, [smWriter, lgWriter, mousePress, glsRef])*/

    const placeholderNumber = useMemo(() => { return t("number") }, [t])

    const placeholderAreaName = useMemo(() => { return t("msgInputAreaName") }, [t])

    return (
        <div className={style.entry}>
            <div className={style.intitle}>{t("areaEntry")}</div>
            <div className={style.infoRow}>
                <div className={style.infoStart}>
                    <div className={style.label}>{t("area")}{t("number")}</div>
                    <div className={style.numberInput}><input type="text" placeholder={placeholderNumber} value={props.areaNumber} onChange={handleChangeAreaNumber} disabled={disableEdit} /></div>
                    <div className={style.errMsg}><span>{errMsg4Number }</span></div>
                </div>
                <div className={style.space}></div>
                <div className={style.info2nd}>
                    <div className={style.label}>{t("areaName")}</div>
                    <div className={style.nameInput}><input type="text" placeholder={placeholderAreaName} value={props.areaName} onChange={handleChangeAreaName} disabled={disableEdit} /></div>
                    <div className={style.errMsg}><span>{errMsg4Name }</span></div>
                </div>
                <div className={style.space}></div>
                <div className={style.infoEnd}>
                    <div className={style.label}>{t("area")}{t("type")}</div>
                    <div className={style.typSelector}>
                        <select className={style.typeSelect} value={props.selectedType} onChange={handleChangeType} disabled={disableEdit}>{typeSelectOptions}</select>
                    </div>
                </div>
            </div>
            <div className={style.intitle}>エリア選択と紐づけ</div>
            <div className={style.main}>
                <div className={style.largeMap}>
                    <div className={style.screen}>
                        <div className={style.canvasWrap}>
                            <canvas className={style.bgImgCanvas} ref={imgRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                            <canvas className={style.drawCanvas} ref={drwRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                            <canvas className={style.animeCanvas} ref={aniRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                        </div>
                    </div>
                    {
                        errMsg4Cells ? (<div className={style.errMsg}><span>{errMsg4Cells}</span></div>) : (null)
                    }
                </div>
                <div className={style.smallMap}>
                    <div className={style.sideWrap}>
                        <canvas className={style.glassCanvas} ref={glsRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                        <canvas className={style.smallCanvas} ref={smlRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                        <canvas className={style.moveCanvas} ref={movRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                    </div>
                    <div className={style.buttons}>
                        <div className={style.rowTop}>
                            <AreaMagControler
                                onSkipUp={handleSkipUp}
                                onSkipDown={handleSkipDown}
                                onSkipRight={handleSkipRight}
                                onSkipLeft={handleSkipLeft}
                                onMoveUp={handleMoveUp}
                                onMoveDown={handleMoveDown}
                                onMoveRight={handleMoveRight}
                                onMoveLeft={handleMoveLeft}
                                onZoomIn={handleZoomIn}
                                onZoomOut={handleZoomOut}
                                onZoomReset={handleZoomReset}
                            />
                        </div>
                        <div className={style.rowBottom}>
                            <span className={style.spc2}>&nbsp;</span>
                        </div>
                    </div>
                    <div className={style.control}>
                        {
                            ((props.mode === EditModeType.New && ableNew) || (props.mode === EditModeType.Edit && ableEdit)) ? (
                                <section>
                                    <Button label={t("reset")} name="reset" styleType={StyleType.Reverse} onClick={handleReset} />
                                    <span className={style.spc}></span>
                                    <Button label={t("entry")} name="entry" styleType={StyleType.Normal} onClick={handleFinish} />
                                </section>
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

