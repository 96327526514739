import { Role, RoleType } from "../api/data/core/Enums"

/**
 * Role-Based Access Control 権限のENUM
 */
export const PermissionTypes = {
    Dashboard_View: "dashboard:view",       // ダッシュボード表示
    Analyze_View: "analyze:view",           // 分析画面表示
    Develop_View: "dev:view",               // 開発中画面の表示（特定商品購買者分析など）
    ManageCompany_View: "company:view",     // 企業情報の表示
    ManageCompany_New: "company:new",       // 　〃　　　新規登録
    ManageCompany_Edit: "company:edit",     // 　〃　　　編集
    ManageCompany_Delete: "company:delete", // 　〃　　　削除 
    ManageShop_View: "shop:view",           // 店舗情報の表示
    ManageShop_New: "shop:new",             // 　〃　　　新規登録
    ManageShop_Edit: "shop:edit",           // 　〃　　　編集
    ManageShop_Delete: "shop:delete",       // 　〃　　　削除
    ManageLayout_View: "layout:view",       // レイアウトの表示
    BsInfLayout_New: "layout:new",          // レイアウトの新規登録作成(FigmaDesign)
    ManageLayout_Edit: "layout:edit",       // 
    ManageLayout_Delete: "layout:delete",   // 
    ManageArea_View: "area:view",           // エリアの表示
    ManageArea_New: "area:new",             // 
    ManageArea_Edit: "area:edit",           // 
    ManageArea_Delete: "area:delete",       // 
    ManageLine_View: "line:view",           // 入店ラインの表示
    ManageLine_New: "line:new",             // 
    ManageLine_Edit: "line:edit",           // 
    ManageLine_Delete: "line:delete",       // 
    ManageGroup_View: "group:view",         // グループの表示
    ManageGroup_New: "group:new",           // 
    ManageGroup_Edit: "group:edit",         // 
    ManageGroup_Delete: "group:delete",     // 
    ManageAreaCateg_View: "category:view",  // エリアカテゴリの表示
    ManageAreaCateg_New: "category:new",    // 
    ManageAreaCateg_Edit: "category:edit",  // 
    ManageAreaCateg_Delete: "category:delete",  //
    ManageAccount_View: "account:view",     // アカウントの表示
    ManageAccount_New: "account:new",       // 
    ManageAccount_Edit: "account:edit",     // 
    ManageAccount_Delete: "account:delete", //
    Provider_AllCompany: "provider:company",// データプロバイダーの企業情報；これがあれば、全企業を操作可能。なければ、所属企業のみとなる。
    Provider_AllShops: "provider:shop",     // データプロバイダーの店舗情報：これがあれば、全店舗を操作可能。なければ、bookmarkされた店舗のみとなる。
} as const
export type Permissions = typeof PermissionTypes[keyof typeof PermissionTypes]

// 全てOK、制限なし (開発用)
export const AllPermissions = Object.values(PermissionTypes)

// 【Root】
// 対象：全企業の全店舗
// 機能：全ての権限（開発中を除く）
export const RootPermissions = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    PermissionTypes.ManageCompany_View,
    PermissionTypes.ManageCompany_New,
    PermissionTypes.ManageCompany_Edit,
    PermissionTypes.ManageCompany_Delete,
    PermissionTypes.ManageShop_View ,
    PermissionTypes.ManageShop_New ,
    PermissionTypes.ManageShop_Edit ,
    PermissionTypes.ManageShop_Delete ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.BsInfLayout_New,
        PermissionTypes.ManageLayout_Edit ,
        PermissionTypes.ManageLayout_Delete ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageArea_New ,
        PermissionTypes.ManageArea_Edit ,
        PermissionTypes.ManageArea_Delete,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageLine_New,
        PermissionTypes.ManageLine_Edit,
        PermissionTypes.ManageLine_Delete,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageGroup_New,
        PermissionTypes.ManageGroup_Edit,
        PermissionTypes.ManageGroup_Delete,
        PermissionTypes.ManageAreaCateg_View,
        PermissionTypes.ManageAreaCateg_New,
        PermissionTypes.ManageAreaCateg_Edit,
        PermissionTypes.ManageAreaCateg_Delete,*/
    PermissionTypes.ManageAccount_View,
    PermissionTypes.ManageAccount_New,
    PermissionTypes.ManageAccount_Edit ,
    PermissionTypes.ManageAccount_Delete,
    PermissionTypes.Provider_AllCompany ,
    PermissionTypes.Provider_AllShops,
]

// 【本部】
// 対象：自社の全店舗
// 機能：自社の全店舗の「店舗・レイアウト・エリア・アカウント」の登録・閲覧・更新・削除
export const HeadOfficePermissions = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View, //企業閲覧は非表示
    //PermissionTypes.ManageCompany_New,
    //PermissionTypes.ManageCompany_Edit,
    //PermissionTypes.ManageCompany_Delete,
    PermissionTypes.ManageShop_View ,
    PermissionTypes.ManageShop_New ,
    PermissionTypes.ManageShop_Edit ,
    PermissionTypes.ManageShop_Delete ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.BsInfLayout_New,
        PermissionTypes.ManageLayout_Edit ,
        PermissionTypes.ManageLayout_Delete ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageArea_New ,
        PermissionTypes.ManageArea_Edit ,
        PermissionTypes.ManageArea_Delete ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageLine_New,
        PermissionTypes.ManageLine_Edit,
        PermissionTypes.ManageLine_Delete,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageGroup_New,
        PermissionTypes.ManageGroup_Edit,
        PermissionTypes.ManageGroup_Delete,
        PermissionTypes.ManageAreaCateg_View,
        PermissionTypes.ManageAreaCateg_New,
        PermissionTypes.ManageAreaCateg_Edit,
        PermissionTypes.ManageAreaCateg_Delete,*/
    PermissionTypes.ManageAccount_View,
    PermissionTypes.ManageAccount_New,
    PermissionTypes.ManageAccount_Edit ,
    PermissionTypes.ManageAccount_Delete,
    PermissionTypes.Provider_AllShops,
]

// 【エリアマネージャー】
// 対象：自社の特定担当店舗(Bookmarkで設定)
// 機能：特定担当店舗の「店舗・レイアウト・エリア・アカウント」の登録・閲覧・更新・削除
export const AreaManagerPermOwn = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    //PermissionTypes.ManageCompany_New,
    //PermissionTypes.ManageCompany_Edit,
    //PermissionTypes.ManageCompany_Delete,
    PermissionTypes.ManageShop_View ,
    PermissionTypes.ManageShop_New ,
    PermissionTypes.ManageShop_Edit ,
    PermissionTypes.ManageShop_Delete ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.BsInfLayout_New,
        PermissionTypes.ManageLayout_Edit ,
        PermissionTypes.ManageLayout_Delete ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageArea_New ,
        PermissionTypes.ManageArea_Edit ,
        PermissionTypes.ManageArea_Delete ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageLine_New,
        PermissionTypes.ManageLine_Edit,
        PermissionTypes.ManageLine_Delete,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageGroup_New,
        PermissionTypes.ManageGroup_Edit,
        PermissionTypes.ManageGroup_Delete,
        PermissionTypes.ManageAreaCateg_View,
        PermissionTypes.ManageAreaCateg_New,
        PermissionTypes.ManageAreaCateg_Edit,
        PermissionTypes.ManageAreaCateg_Delete,*/
    PermissionTypes.ManageAccount_View,
    PermissionTypes.ManageAccount_New,
    PermissionTypes.ManageAccount_Edit ,
    PermissionTypes.ManageAccount_Delete,
]
// 機能：他店舗の「店舗・レイアウト・エリア」の閲覧
export const AreaManagerPermOther = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    PermissionTypes.ManageShop_View ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageAreaCateg_View,*/
    PermissionTypes.ManageAccount_View,
]

// 【シニア店長】
// 対象：所属店舗
// 機能：所属店舗の「店舗・レイアウト・エリア・アカウント」の登録・閲覧・更新・削除
export const StoreManagerPermOwn = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    //PermissionTypes.ManageCompany_New,
    //PermissionTypes.ManageCompany_Edit,
    //PermissionTypes.ManageCompany_Delete,
    PermissionTypes.ManageShop_View ,
    PermissionTypes.ManageShop_New ,
    PermissionTypes.ManageShop_Edit ,
    PermissionTypes.ManageShop_Delete ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.BsInfLayout_New,
        PermissionTypes.ManageLayout_Edit ,
        PermissionTypes.ManageLayout_Delete ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageArea_New ,
        PermissionTypes.ManageArea_Edit ,
        PermissionTypes.ManageArea_Delete ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageLine_New,
        PermissionTypes.ManageLine_Edit,
        PermissionTypes.ManageLine_Delete,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageGroup_New,
        PermissionTypes.ManageGroup_Edit,
        PermissionTypes.ManageGroup_Delete,
        PermissionTypes.ManageAreaCateg_View,
        PermissionTypes.ManageAreaCateg_New,
        PermissionTypes.ManageAreaCateg_Edit,
        PermissionTypes.ManageAreaCateg_Delete,*/
    PermissionTypes.ManageAccount_View,
    PermissionTypes.ManageAccount_New,
    PermissionTypes.ManageAccount_Edit ,
    PermissionTypes.ManageAccount_Delete ,
]
// 機能：他店舗の「店舗・レイアウト・エリア・アカウント」の閲覧
export const StoreManagerPermOther = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    PermissionTypes.ManageShop_View ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageAreaCateg_View,*/
    //PermissionTypes.ManageAccount_View,
]

// 【店長】
// 対象：所属店舗
// 機能：所属店舗の「店舗・レイアウト・エリア・アカウント」の閲覧と「レイアウト・エリア・アカウント」の登録・更新・削除
export const JuniorManagerPermissions = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    //PermissionTypes.ManageCompany_New,
    //PermissionTypes.ManageCompany_Edit,
    //PermissionTypes.ManageCompany_Delete,
    PermissionTypes.ManageShop_View ,
    //PermissionTypes.ManageShop_New ,
    //PermissionTypes.ManageShop_Edit ,
    //PermissionTypes.ManageShop_Delete ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.BsInfLayout_New,
        PermissionTypes.ManageLayout_Edit ,
        //PermissionTypes.ManageLayout_Delete ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageArea_New ,
        PermissionTypes.ManageArea_Edit ,
        //PermissionTypes.ManageArea_Delete ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageLine_New,
        PermissionTypes.ManageLine_Edit,
        //PermissionTypes.ManageLine_Delete,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageGroup_New,
        PermissionTypes.ManageGroup_Edit,
        //PermissionTypes.ManageGroup_Delete,
        PermissionTypes.ManageAreaCateg_View,
        PermissionTypes.ManageAreaCateg_New,
        PermissionTypes.ManageAreaCateg_Edit,
        //PermissionTypes.ManageAreaCateg_Delete,*/
    PermissionTypes.ManageAccount_View,
    PermissionTypes.ManageAccount_New,
    PermissionTypes.ManageAccount_Edit ,
    //PermissionTypes.ManageAccount_Delete ,
]

// 【店員】
// 対象：所属店舗
// 機能：「店舗・レイアウト・エリア」の閲覧
export const StaffPermissions = [
    PermissionTypes.Dashboard_View,
    PermissionTypes.Analyze_View,
    //PermissionTypes.ManageCompany_View,
    PermissionTypes.ManageShop_View ,
        /*PermissionTypes.ManageLayout_View ,
        PermissionTypes.ManageArea_View ,
        PermissionTypes.ManageLine_View,
        PermissionTypes.ManageGroup_View,
        PermissionTypes.ManageAreaCateg_View,*/
    //PermissionTypes.ManageAccount_View,
]

/**
 * ２つの権限の優先度を比較する
 * 
 * @param me 
 * @param you 
 * @returns 
 */
export const doYouHaveHighPriorityThanMe = (me: Role, you: Role): boolean => {
    const priority = [
        RoleType.Root,
        RoleType.Developer,
        RoleType.HeadOffice,
        RoleType.AreaManager,
        RoleType.StoreManager,
        RoleType.JuniorManager,
        RoleType.Staff,
        //RoleType.JuniorStaff,
    ]
    let myIdx: number = priority.length
    let otherIdx: number = priority.length
    for (let i = 0; i < priority.length; i++) {
        if (priority[i] === me) {
            myIdx = i
            break
        }
    }
    for (let i = 0; i < priority.length; i++) {
        if (priority[i] === you) {
            otherIdx = i
            break
        }
    }
    return (myIdx > otherIdx)
}
