import { ReqLayout, ResLayout } from "../../data/analysis/FullLayout"
import { ReqCompany, ReqShop } from "../../data/edit/Req"
import { ResCompany, ResUser, ResShop, ResLayoutSet, ResPartialLayout, ResGroup, ResLoginInfo, ILoginInfo, IShop } from "../../data/login/LoginInfo"

export class EpochMsUtil {
    static toMilliCompany(c: ResCompany): ResCompany {
        c.start = c.start * 1000
        c.end = c.end * 1000
        // c.all_dashboard_threshold = c.all_dashboard_threshold * 1000
        // c.shop_dashboard_threshold = c.shop_dashboard_threshold * 1000
        c.inserttime *= 1000
        c.updatetime *= 1000
        return c
    }

    static toEpochCompany(c: ReqCompany): ReqCompany {
        // c.all_dashboard_threshold = c.all_dashboard_threshold / 1000
        // c.shop_dashboard_threshold = c.shop_dashboard_threshold / 1000
        return c
    }

    static toMilliUser(c: ResUser): ResUser {
        c.inserttime *= 1000
        c.updatetime *= 1000
        if (c.expired_at != null) {
            c.expired_at! *= 1000
        }
        return c
    }

    static toMilliShop(c: ResShop): ResShop {
        c.start *= 1000
        c.inserttime *= 1000
        c.updatetime *= 1000
        EpochMsUtil.toMilliLayoutSet(c.hot_layout_set)
        if (c.cold_layout_set != null) {
            c.cold_layout_set = c.cold_layout_set.map(ls => EpochMsUtil.toMilliLayoutSet(ls))
        }
        return c
    }

    static toMilliIShop(c: IShop): IShop {
        c.start *= 1000
        c.inserttime *= 1000
        c.updatetime *= 1000
        EpochMsUtil.toMilliLayoutSet(c.hot_layout_set)
        if (c.cold_layout_set != null) {
            c.cold_layout_set = c.cold_layout_set.map(ls => EpochMsUtil.toMilliLayoutSet(ls))
        }
        return c
    }

    static toMilliLayoutSet(c: ResLayoutSet): ResLayoutSet {
        if (c.layout != null) {
            c.layout = c.layout.map(l => EpochMsUtil.toMilliPartialLayout(l))
        }
        return c
    }

    static toMilliPartialLayout(c: ResPartialLayout): ResPartialLayout {
        c.start *= 1000
        c.end *= 1000
        c.inserttime *= 1000
        c.updatetime *= 1000
        return c
    }

    static toEpochFullLayout(c: ReqLayout): ReqLayout {
        c.start = Math.floor(c.start / 1000)
        return c
    }

    static toMilliFullLayout(c: ResLayout): ResLayout {
        c.start *= 1000
        return c
    }

    static toMilliGroup(c: ResGroup): ResGroup {
        if (c.inserttime != null) {
            c.inserttime *= 1000
        }
        if (c.deleted_at != null) {
            c.deleted_at *= 1000
        }
        return c
    }

    static toMilliLoginInfo(c: ResLoginInfo): ResLoginInfo {
        c.user = this.toMilliUser(c.user)
        c.company = this.toMilliCompany(c.company)
        c.shops = c.shops.map(s => this.toMilliShop(s))
        c.groups = c.groups.map(s => this.toMilliGroup(s))
        return c
    }
}