import React, { useState, useEffect, ReactNode } from "react"
import useCollapse from "react-collapsed"
import Select, { ActionMeta, SingleValue } from "react-select"
import { useTranslation } from "react-i18next"
import i18n from 'i18next'

import { FilterButton, FilterBtnType } from "../../component/filter_button/FilterButton"
import { useModal } from "../../component/modal/Modal"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { ThreeStateCheckbox, CheckedState, Checked } from "../../component/three_state_checkbox/ThreeStateCheckbox"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { Button, StyleType } from "../../component/button/Button"
import { SlideSwitch } from "../../component/slide_switch/SlideSwitch"
import { formatSec2Hms } from "../../component/TimeFormat"
import Utils from "../../lib/Utils"

import style from "./TrailMapFilterSelector.module.css"

interface CollasProps {
    isOpen: boolean
    parentOpen: boolean
    children: ReactNode
}


/**
 * 子要素のCollapseを実現するためのコンポーネント
 * @param props 
 * @returns 
 */
const Collapsible = (props: CollasProps) => {
    const { getCollapseProps, isExpanded, setExpanded } = useCollapse()

    useEffect(() => {
        // 親のCollapseと同期させてレンダリング
        if (props.parentOpen) {
            // Collapse動作をisOpenに合わせる
            if (props.isOpen !== isExpanded) {
                setExpanded(props.isOpen)
            }
        } else {
            // 親がClose時は強制的にCloseとする。
            setExpanded(false)
        }
    }, [props.isOpen, props.parentOpen, isExpanded, setExpanded])

    return (
        <section {...getCollapseProps()}>
            {props.children}
        </section>
    )
}

const formatSec2UpperHms = (sec: number | undefined) => {
    return (sec !== undefined) ? formatSec2Hms(sec) : i18n.t("overHour")
}

export const makeLowerOptions = (selection: Array<number | undefined>, max: number | undefined): SelectOptionType[] => {
    let filtered: Array<number | undefined> = [...selection]
    if (max && max > 0 && max <= 3600) filtered = filtered.filter(el => (el !== undefined && el <= max))
    return filtered.map(el => { return { value: el, label: formatSec2Hms(el) } })
}

export const makeUpperOptions = (selection: Array<number | undefined>, min: number | undefined): SelectOptionType[] => {
    let filtered: Array<number | undefined> = [...selection]
    if (min && min >= 0) filtered = filtered.filter(el => (el && el >= min))
    filtered.push(undefined)
    return filtered.map(el => { return { value: el, label: formatSec2UpperHms(el) } })
}

export const getNaturalNumberArray = (size: number) => {
    const ary = new Array(size)
    for (let i = 0; i < size; i++) {
        ary[i] = i
    }
    return ary
}

export type TimeSpanObject = {
    start: string
    end: string
    min?: string // 選択可能な下限"hh:mm"
    max?: string // 選択可能な上限"hh:mm"
}

export type FilterObject = {
    isActive: boolean
    enableTimeSpan: boolean
    timeSpan: TimeSpanObject
    enableThresholdTime: boolean
    thresholdTime: {
        lower: number | undefined        
        upper: number | undefined
    }
    enableAreaList: boolean
    areaBulk: Checked
    areaList: AreaInfoObject[]
    //enableBuyOrNot: boolean
    //buyOrNot: Array2<boolean>
}

export type AreaInfoObject = {
    id: number
    name: string
    checked: boolean
}

type CheckBoxesType = {
    bulk: Checked
    list: AreaInfoObject[]
}

type SelectOptionType = {
    label: string
    value: number | undefined
}

interface TrailProps {
    filter: FilterObject
    handleFilter: (filter: FilterObject | undefined) => void
    isBusy: boolean
}
export const TrailMapFilterSelector: React.FC<TrailProps> = (props) => {

	const { t } = useTranslation()
    if (!props || !props.filter) {
        console.error("TrailMapFilterSelectorで必要なPropsが見つかりません")
    }
    //console.log("props.filter:", props.filter)

    const [Modal, open, close, isOpen, resize] = useModal('root', { preventScroll: false, closeOnOverlayClick: false })
    const { getCollapseProps, isExpanded, setExpanded } = useCollapse()

    const [isActive, setIsActive] = useState<boolean>(props.filter.isActive)
    const [enableTimeSpan, setEnableTimeSpan] = useState<boolean>((props?.filter?.enableTimeSpan) ? props.filter.enableTimeSpan : true)
    const [startTime, setStartTime] = useState<string>((props?.filter?.timeSpan?.start) ? props.filter.timeSpan.start : "00:00")
    const [endTime, setEndTime] = useState<string>((props?.filter?.timeSpan?.end) ? props.filter.timeSpan.end : "23:59")
    const [enableThresholdTime, setEnableThresholdTime] = useState<boolean>((props?.filter?.enableThresholdTime) ? props.filter.enableThresholdTime : true)
    const selectLower: Array<number | undefined> = getNaturalNumberArray(60).map(n => n * 60) //[...Array(60).keys()].map(n => n*60)
    const selectUpper: Array<number | undefined> = getNaturalNumberArray(60).map(i => ++i).map(n => n*60) //[...Array(60).keys()].map(i => ++i).map(n => n*60)
    const [thresholdUpper, setThresholdUpper] = useState<number | undefined>((props?.filter?.thresholdTime?.upper) ? props.filter.thresholdTime.upper : undefined)
    const [thresholdLower, setThresholdLower] = useState<number | undefined>((props?.filter?.thresholdTime?.lower) ? props.filter.thresholdTime.lower : 0)
    const [lowerOption, setLowerOption] = useState<SelectOptionType[]>(makeLowerOptions(selectLower, (props?.filter?.thresholdTime?.upper) ? (props.filter.thresholdTime.upper - 59) : undefined))
    const [upperOption, setUpperOption] = useState<SelectOptionType[]>(makeUpperOptions(selectUpper, ((props?.filter?.thresholdTime?.lower) ? props.filter.thresholdTime.lower : 0) + 59))
    const [enableAreaList, setEnableAreaList] = useState<boolean>((props?.filter?.enableAreaList) ? props.filter.enableAreaList : true)
    const [checkboxes, setCheckboxes] = useState<CheckBoxesType>({
        bulk: (props?.filter?.areaBulk) ? props.filter.areaBulk : CheckedState.Full,
        list: (props?.filter?.areaList) ? props.filter.areaList : []
    })
    //const [enableBuyOrNot, setEnableBuyOrNot] = useState<boolean>((props?.filter?.enableBuyOrNot) ? props.filter.enableBuyOrNot : true)
    //const [buyOrNot, setBuyOrNot] = useState<Array2<boolean>>((props?.filter?.buyOrNot) ? props.filter.buyOrNot : [true, true])
    
    // モーダルにサイズ変更を知らせるための数値
    //const [modalSize, setModalSize] = useState(0)

    // Collapseなどの変化を監視
    useEffect(() => {
        const calcModalSize = () => {
            let result = 0
            if (isOpen) {
                result += 173
                if (isActive) {
                    result += 127  // 300
                    if (enableTimeSpan) result += 41 // 340
                    if (enableThresholdTime) result += 41 // 382
                    if (enableAreaList) result += (48 + (Math.ceil(checkboxes.list.length / 4)) * 30) // 730 // 1 + 10Row => 31/row  Math.ceil(checkboxes.list.length / 4) + 1
                }
            }
            //console.log("calcModalSize result:", result)
            return result
        }
        const val = calcModalSize()
        //setModalSize(val)
        // モーダルにサイズを伝える(widthは未使用)
        resize({ width: undefined, height: val })
    }, [isOpen, isActive, enableTimeSpan, enableThresholdTime, enableAreaList, checkboxes.list.length, resize])
    
    /**
     * 絞り込みの有効・無効（スライドスイッチON/OFF）
     */
    const toggleOnOff = () => {
        setIsActive(!isActive)
        setExpanded(!isActive)
    }
    /**
     * 絞り込みボタン（モーダルを開く）
     */
    const handleFilterButton = () => {
        if (!props.isBusy) open()
    }
    /**
     * モーダルを閉じる
     */
    const handleClosing = () => {
        // キャンセルまたは閉じる押下時
        close()
        // 絞り込みのON/OFFを元に戻す
        if (isActive !== props.filter.isActive) {
            setIsActive(!isActive)
        }
        if (isExpanded !== props.filter.isActive) {
            setExpanded(!isExpanded)
        }
    }
    const validateTimeSpan = (startTm: string, endTm: string) => {
        const s = Utils.parseTimeAsDummyTime(startTm)
        const e = Utils.parseTimeAsDummyTime(endTm)
        return (s < e)
    }
    const handleTsStartHour = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target?.value
        if (val) {
            const newTm = ("00" + val).slice(-2) + startTime.slice(2)
            if (validateTimeSpan(newTm, endTime)) {
                setStartTime(newTm)
            } else {
                setStartTime(endTime)
                setEndTime(newTm)
            }
        }
    }
    const handleTsStartMinute = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target?.value
        if (val) {
            const newTm = startTime.slice(0, 3) + ("00" + val).slice(-2)
            if (validateTimeSpan(newTm, endTime)) {
                setStartTime(newTm)
            } else {
                setStartTime(endTime)
                setEndTime(newTm)
            }
        }
    }
    const handleTsEndHour = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target?.value
        if (val) {
            const newTm = ("00" +val).slice(-2) + endTime.slice(2)
            if (validateTimeSpan(startTime, newTm)) {
                setEndTime(newTm)
            } else {
                setStartTime(newTm)
                setEndTime(startTime)
            }
        }
    }
    const handleTsEndMinute =  (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target?.value
        if (val) {
            const newTm = endTime.slice(0, 3) + ("00" +val).slice(-2)
            if (validateTimeSpan(startTime, newTm)) {
                setEndTime(newTm)
            } else {
                setStartTime(newTm)
                setEndTime(startTime)
            }
        }
    }

    const handleChangeThresholdUpper = (selectedOption: SingleValue<SelectOptionType>, meta: ActionMeta<SelectOptionType>) => {
        if (selectedOption) {
            const v = selectedOption.value
            if (thresholdUpper !== v) {
                setThresholdUpper(v)
                if (v !== undefined) {
                    const lowerOps = makeLowerOptions(selectLower, v - 59)
                    setLowerOption(lowerOps)
                }
            }
        }
    }
    const handleChangeThresholdLower = (selectedOption: SingleValue<SelectOptionType>, meta: ActionMeta<SelectOptionType>) => {
        if (selectedOption) {
            const v = selectedOption.value
            if (thresholdLower !== v) {
                setThresholdLower(v)
                if (v !== undefined) {
                    const upperOps = makeUpperOptions(selectUpper, v + 59)
                    setUpperOption(upperOps)
                }
            }
        }
    }
    const checkAllOn = () => {
        if (checkboxes.list.length > 0) {
            const newVal = [ ...checkboxes.list ]
            newVal.forEach(el => {
                el.checked = true
            })
            return newVal
        } else {
            return []
        }
    }
    const checkAllOff = () => {
        if (checkboxes.list.length > 0) {
            const newVal = [ ...checkboxes.list ]
            newVal.forEach(el => {
                el.checked = false
            })
            return newVal
        } else {
            return []
        }
    }
    const handleBulkCheck = () => {
        if (checkboxes.bulk === CheckedState.None) {
            setCheckboxes({
                bulk: CheckedState.Full,
                list: checkAllOn()
            })
        } else {
            setCheckboxes({
                bulk: CheckedState.None,
                list: checkAllOff()
            })
        }
    }
    const handleCheck = (event: CustomCheckboxEvent) => {
        const v = event.target.value
        const id = parseInt(v)
        let cnt = 0
        const newList = [ ...checkboxes.list ] 
        newList.forEach(el => {
            if (el.id === id) el.checked = !el.checked
            if (el.checked) cnt++
        })
        if (cnt === 0) {
            setCheckboxes({bulk: CheckedState.None, list: newList})
        } else if (cnt === checkboxes.list.length) {
            setCheckboxes({bulk: CheckedState.Full, list: newList})
        } else {
            setCheckboxes({bulk: CheckedState.PartOf, list: newList})
        }
    }
    const handleEnterButton = () => {
        close()
        if (!props.handleFilter) {
            console.error("handleFilterがないためフィルターの設定値を親に渡せません")
            return
        }
        const newFilter: FilterObject = {
            isActive: isActive,
            enableTimeSpan: enableTimeSpan,
            timeSpan: {
                start: startTime,
                end: endTime,
                min: props.filter.timeSpan.min,
                max: props.filter.timeSpan.max
            },
            enableThresholdTime: enableThresholdTime,
            thresholdTime: {
                upper: thresholdUpper,
                lower: thresholdLower
            },
            enableAreaList: enableAreaList,
            areaBulk: checkboxes.bulk,
            areaList: checkboxes.list,
            //enableBuyOrNot: enableBuyOrNot,
            //buyOrNot: buyOrNot
        }
        props.handleFilter(newFilter)
    }
    const handleEnableTimeSpan = () => {
        setEnableTimeSpan(!enableTimeSpan)
    }
    const handleEnableThresholdTime = () => {
        setEnableThresholdTime(!enableThresholdTime)
    }
    const handleEnablleAreaList = () => {
        setEnableAreaList(!enableAreaList)
    }

    /*const handleEnableBuyOrNot = () => {
        setEnableBuyOrNot(!enableBuyOrNot)
    }

    const handleBuyOrNot = (buyNot: Array2<boolean>) => {
        setBuyOrNot(buyNot)
    }*/

    const makeOptions = (data: Array<number | undefined>, keyName: string) => {
        const item = data.map((el, i) => {
            const v = ("00" + el).slice(-2)
            return (<option key={keyName + i} value={v}>{v}</option>)
        })
        //console.log("makeOption item:", item)
        return item
    }
    const getOptionsTsStartHour = () => {
        // 始まりはmin（=検索条件のStart）とする
        let min = 0
        if (props?.filter?.timeSpan?.min) {
            const h = parseInt(props.filter.timeSpan.min.slice(0, 2))
            const m = parseInt(props.filter.timeSpan.min.slice(3))
            min = h + ((m >= 55) ? 1 : 0)
        }
        // 終わりはendとする
        let max = 23
        const eh = parseInt(endTime.slice(0, 2))
        const em = parseInt(endTime.slice(3))
        max = eh - ((em === 0) ? 1 : 0)
        const data = (min < max) ? getNaturalNumberArray(24).filter(n => n >= min).filter(n => n <= max) : getNaturalNumberArray(24)
        return makeOptions(data, "sh")
    }
    const getOptionsTsStartMinute = () => {
        let min = 0
        const curH = parseInt(startTime.slice(0, 2))
        if (props?.filter?.timeSpan?.min) {
            const h = parseInt(props.filter.timeSpan.min.slice(0, 2))
            const m = parseInt(props.filter.timeSpan.min.slice(3))
            if (curH === h) {
                min = m
            }
        }
        let max = 55
        const eh = parseInt(endTime.slice(0, 2))
        const em = parseInt(endTime.slice(3))
        if (curH === eh) {
            max = em
        }
        const data = (min < max) ? getNaturalNumberArray(12).map(n => n * 5).filter(n => n >= min).filter(n => n <= max) : getNaturalNumberArray(12).map(n => n * 5)
        return makeOptions(data, "sm")
    }
    const getOptionsTsEndHour = () => {
        // リスト終わりはmaxとする
        let max = 24
        if (props?.filter?.timeSpan?.max) {
            max = parseInt(props.filter.timeSpan.max.slice(0, 2))
        }
        // 始まりは、startとする
        let min = 0
        const sh = parseInt(startTime.slice(0, 2))
        const sm = parseInt(startTime.slice(3))
        min = sh + ((sm >= 55) ? 1 : 0)
        const data = (min < max) ? getNaturalNumberArray(25).filter(n => n >= min).filter(n => n <= max) : getNaturalNumberArray(25)
        return makeOptions(data, "eh")
    }
    const getOptionsTsEndMinute = () => {
        let max = 55
        const curH = parseInt(endTime.slice(0, 2))
        if (props?.filter?.timeSpan?.max) {
            const h = parseInt(props.filter.timeSpan.max.slice(0, 2))
            const m = parseInt(props.filter.timeSpan.max.slice(3))
            if (curH === h) {
                max = m
            }
        }
        let min = 0
        const sh = parseInt(startTime.slice(0, 2))
        const sm = parseInt(startTime.slice(3))
        if (curH === sh) {
            min = sm
        }
        const data = (min < max) ? getNaturalNumberArray(12).map(n => n * 5).filter(n => n >= min).filter(n => n <= max) : getNaturalNumberArray(12).map(n => n * 5)
        return makeOptions(data, "em")
    }

    const textOverflowEllipsis = (text: string) => {
        if (text && text.length > 15) {
            const newText = text.slice(0, 15) + "…"
            return newText
        }
        return text
    }

    const getOneColumn = (colData: AreaInfoObject | undefined) => {
        if (colData) {
            return(
                <CustomCheckbox
                    label={textOverflowEllipsis(colData.name)}
                    value={"" + colData.id}
                    check={colData.checked}
                    onChange={handleCheck}
                />
            )
        }
        return null
    }

    const getTableRow = (key: number, col1st: AreaInfoObject, col2nd: AreaInfoObject | undefined, col3rd: AreaInfoObject | undefined, col4th: AreaInfoObject | undefined) => {
            return (
                <tr key={key}>
                    <td className={style.dataCol}>{getOneColumn(col1st)}</td>
                    <td className={style.emptyCol}></td>
                    <td className={style.dataCol}>{getOneColumn(col2nd)}</td>
                    <td className={style.emptyCol}></td>
                    <td className={style.dataCol}>{getOneColumn(col3rd)}</td>
                    <td className={style.emptyCol}></td>
                    <td className={style.dataCol}>{getOneColumn(col4th)}</td>
                </tr>
            )
    }

    useEffect(() => {
        if (isExpanded !== isActive) {
            // CollapseとisActiveを同期させる
            setExpanded(isActive)
        }
        
        return () => {
            // スクロール不能の解除
            window.document.body.style.overflow = ''
            console.log("clean up")
        }
    },[])

    let items: ReactNode[] = []
    if (checkboxes.list.length > 0) {
        // 各カラムに均等割り付けする
        const rowCnt = Math.floor(checkboxes.list.length / 4)
        const rowMod = checkboxes.list.length % 4
        // 各カラムのサイズを計算
        let colsize = new Array(4).fill(rowCnt)
        if (rowMod > 0) {
            colsize[0]++
            if (rowMod > 1) {
                colsize[1]++
                if (rowMod > 2) {
                    colsize[2]++
                }
            }
        }
        // 各カラムにインデックス番号を割り当て
        const ary1st = []
        const ary2nd = []
        const ary3rd = []
        const ary4th = []
        for (let i = 0; i < checkboxes.list.length; i++) {
            if (i < colsize[0]) {
                ary1st.push(i)
            } else if (i < (colsize[0] + colsize[1])) {
                ary2nd.push(i)
            } else if (i < (colsize[0] + colsize[1] + colsize[2])) {
                ary3rd.push(i)
            } else {
                ary4th.push(i)
            }
        }
        // TableのRowを作る
        for (let i = 0; i < colsize[0]; i++) {
            const col1st: AreaInfoObject = checkboxes.list[ary1st[i]]
            const col2nd: AreaInfoObject | undefined = (ary2nd[i]) ? checkboxes.list[ary2nd[i]] : undefined
            const col3rd: AreaInfoObject | undefined = (ary3rd[i]) ? checkboxes.list[ary3rd[i]] : undefined
            const col4th: AreaInfoObject | undefined = (ary4th[i]) ? checkboxes.list[ary4th[i]] : undefined
            const itm = getTableRow(i, col1st, col2nd, col3rd, col4th)
            items.push(itm)
        }
    } else {
        console.error("エリアのリストが空です")
    }

    //console.log("default startTime.slice(0,2),endTime.slice(0,2):",startTime.slice(0,2),endTime.slice(0,2))

    /**
     * ThresholdPickerはCollapseと相性が悪く、Popperの上に表示させているプルダウン選択が原点位置に吹き飛んでしまい、通常は見えなくなる。
     * Collapseの非表示時にchildrenのDOMを無視してしまうため children内のPopperが親エレメントを発見できなくなることで起きる現象と思われる。
     * よって、isActive && enableThresholdTime の時に、ThresholdPicker をまるまる描画・表示するようにした。暫定的な処置。Collapseを交換するしかないか。
     */
    return (
        <section>
            <FilterButton styleType={(props.filter.isActive) ? FilterBtnType.Active : FilterBtnType.Deactive} onClick={handleFilterButton} />
            <Modal>
                <div className={style.modalMain}>
                    <div className={style.top}>
                        <div className={style.title}>
                            <label>{t("filterFunction")}</label>
                        </div>
                        <div className={style.onOff}>
                            <SlideSwitch isOn={isActive} onChange={toggleOnOff} />
                        </div>
                        <div className={style.onOff}>
                        </div>
                        <div className={style.closer}>
                            <CircleCloseButton onClose={handleClosing} />
                        </div>
                    </div>
                </div>
                <div className={style.body}>
                    <section {...getCollapseProps()}>
                        <div className={style.timeSect}>
                            <div className={style.timeLabel}>
                                <CustomCheckbox
                                    label={"msgFilterEnterTime"}
                                    value="enTmSpn"
                                    check={enableTimeSpan}
                                    onChange={handleEnableTimeSpan}
                                />                                
                            </div>
                            <Collapsible isOpen={enableTimeSpan} parentOpen={isActive}>
                                <div className={style.timespan}>
                                    <div className={style.tmpickStart}>
                                        <select className={style.tpStHour} onChange={handleTsStartHour} value={startTime.slice(0,2)}>
                                            {
                                                getOptionsTsStartHour()
                                            }
                                        </select>
                                        <span className={style.tmColon}>：</span>
                                        <select className={style.tpStMinute} onChange={handleTsStartMinute} value={startTime.slice(-2)}>
                                            {
                                                getOptionsTsStartMinute()
                                            }
                                        </select>
                                    </div>
                                    <div className={style.tmWave}>～</div>
                                    <div className={style.tmpickEnd}>
                                        <select className={style.tpEdHour} onChange={handleTsEndHour} value={endTime.slice(0,2)}>
                                            {
                                                getOptionsTsEndHour()
                                            }
                                        </select>
                                        <span className={style.tmColon}>：</span>
                                        <select className={style.tpEdMinute} onChange={handleTsEndMinute} value={endTime.slice(-2)}>
                                            {
                                                getOptionsTsEndMinute()
                                            }
                                        </select>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>
                        <div className={style.thresholdSect}>
                            <div className={style.thresholdLabel}>
                                <CustomCheckbox
                                    label={"msgFilterStayTime"}
                                    value="enThrshldTm"
                                    check={enableThresholdTime}
                                    onChange={handleEnableThresholdTime}
                                />
                            </div>
                            {
                                (enableThresholdTime && isActive) ? (
                                    <div className={style.thresholdTime}>
                                        <div className={style.lower}>
                                            <Select
                                                options={lowerOption}
                                                value={{ value: thresholdLower, label: formatSec2Hms(thresholdLower) }}
                                                onChange={handleChangeThresholdLower}
                                                className={style.selectLower}
                                                classNamePrefix="react-select"
                                                
                                            />
                                        </div>
                                        <div className={style.wave}>〜</div>
                                        <div className={style.upper}>
                                            <Select
                                                options={upperOption}
                                                value={{ value: thresholdUpper, label: formatSec2UpperHms(thresholdUpper) }}
                                                onChange={handleChangeThresholdUpper}
                                                className={style.selectUpper}
                                                classNamePrefix="react-select"
                                                
                                            />
                                        </div>
                                    </div>
                                ): (
                                    <div className = {style.hideThreshold}></div>
                                )
                            }
                        </div>
                        <div className={style.areaSect}>
                            <div className={style.areaLabel}>
                                <CustomCheckbox
                                    label={"msgFilterArea"}
                                    value="enArLs"
                                    check={enableAreaList}
                                    onChange={handleEnablleAreaList}
                                />
                            </div>
                            <Collapsible isOpen={enableAreaList} parentOpen={isActive}>
                                <div className={style.areaFrame}>
                                    <div className={style.areaTop}>
                                        <ThreeStateCheckbox checked={checkboxes.bulk} onClick={handleBulkCheck} label={t("all")}/>
                                    </div>
                                    <div className={style.areaList}>
                                        <table className={style.table}>
                                            <tbody>
                                                {items}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>
                    </section>
                </div>
                <div className={style.footer}>
                    <div className={style.left}></div>
                    <div className={style.center}>
                        <Button styleType={StyleType.Reverse} name="cancel" label={t("cancel")} onClick={handleClosing} />
                        <div className={style.space}></div>
                        <Button styleType={StyleType.Normal} name="ok" label={t("execution")} onClick={handleEnterButton} />
                    </div>
                    <div className={style.right}></div>
                </div>
            </Modal>
        </section>
    )
}
