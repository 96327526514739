import { ResCompany } from "../../api/data/login/LoginInfo"
import { THRESHOLD_4_COMPANY } from "../../constants"
import { RoleType, Role } from "../../api/data/core/Enums"
import { CompanyAryRes2Model } from "../../lib/ModelUtil"

const istyle: ResCompany = {
    company_id: 1,
    name: "istyle",
    start: 1635728900000,
    end: 4102412400000,
    all_dashboard_threshold: THRESHOLD_4_COMPANY,
    shop_dashboard_threshold: THRESHOLD_4_COMPANY,
    settings: {},
    company_designator: "istyle",
    inserttime: 1633050500000,
    updatetime: 1633050500000,
}

const datasection: ResCompany = {
    company_id: 2,
    name: "株式会社データセクション",
    start: 1638320900000,
    end: 4102412400000,
    all_dashboard_threshold: THRESHOLD_4_COMPANY,
    shop_dashboard_threshold: THRESHOLD_4_COMPANY,
    settings: {},
    company_designator: "datasection",
    inserttime: 1635728900000,
    updatetime: 1635728900000,
}

const momo: ResCompany = {
    company_id: 3,
    name: "株式会社ももたろう",
    start: 1638320900000,
    end: 4102412400000,
    all_dashboard_threshold: THRESHOLD_4_COMPANY,
    shop_dashboard_threshold: THRESHOLD_4_COMPANY,
    settings: {},
    company_designator: "momotaro",
    inserttime: 1635728900000,
    updatetime: 1635728900000,
}

export const companyList = [datasection, istyle, momo]

export const companyModelList = CompanyAryRes2Model(companyList)

export const getCompanyAry = () => {
    return [datasection, istyle, momo]
}

export const getCompanyBy = (type: Role) => {
    if (type === RoleType.Root) {
        return datasection
    } else {
        return istyle
    }
}