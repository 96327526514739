import React, { useState, useMemo, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import GridLayout, { ItemCallback, Layout } from "react-grid-layout"
import { useTranslation } from "react-i18next"

import { Button, StyleType } from "../../component/button/Button"
import { useAuthUserContext } from "../../providers"
import { LoginStatusType } from "../auth/Login"
import { ModalFrame } from "../common/ModalFrame"
import { PageName } from "../../types"
import { useStatisticsContext } from "../../providers/StatisticsData"
import { DashboardLineChart } from "./DashboardLineChart"
import { TxStatusType } from "../../types"
import { choice2DataType, timeUnit2DisplayTerm } from "../../lib/DashboardUtil"

import style from "./ShopDisplayOrderChange.module.css"

// グラデーションチャートのカラム数
const COL_SIZE = 5

export const ShopDisplayOrderChange = () => {

	const { t } = useTranslation()
    const navigate = useNavigate()

    const { orderedShopList, updateDashbordDisplayOrder, orderStatus, orderStatusClear } = useAuthUserContext()
    const { dataset, choice, timeUnit } = useStatisticsContext()

    const [displayOrder, setDisplayOrder] = useState<number[]>(orderedShopList ? orderedShopList.map(el => el.shop_id) : [])

    const colSize = COL_SIZE
    const width = (215 + 5 * 2) * colSize
    const rwHight = 200 + 1 + 2

    const displayTerm = useMemo(() => {
        return timeUnit2DisplayTerm(timeUnit)
    }, [timeUnit])

    const statisticsDataType = useMemo(() => {
        return choice2DataType(choice)
    }, [choice])

    // 並べ替えパネルの高さ
    const winHight = useMemo(() => {
        if (displayOrder) {
            const row = Math.ceil(displayOrder.length / colSize)
            return (rwHight + 5) * row + 100
        }
        return 525
    }, [displayOrder, colSize, rwHight])

    const getLayoutFromDisplayOrder = (): Layout[] => {
        return displayOrder.map((sid, i) => {
            const x = i % colSize
            const y = Math.floor(i / colSize)
            return { i: sid.toString(), x: x, y: y, w: 1, h: 1, isResizable: false} as Layout
        })
    }

    const getNewOrderFromLayout = (oldItem: Layout, newItem: Layout): number[] => {
        const p = newItem.x + newItem.y * colSize
        const n = parseInt(newItem.i)
        const ext = displayOrder.filter(el => oldItem.i !== el.toString())
        return [...ext.slice(0, p), n, ...ext.slice(p)]
    }

    const handleCancel = (event: React.MouseEvent) => {
        orderStatusClear()
        navigate("/dashboard")
    }

    const handleSave = (event: React.MouseEvent) => {
        // APIコール
        updateDashbordDisplayOrder(displayOrder)
        //navigate("/dashboard")
    }

    // 保存成功時
    useEffect(() => {
        if (orderStatus.status === LoginStatusType.Success) {
            orderStatusClear()
            navigate("/dashboard")
        }
    }, [orderStatus, navigate, orderStatusClear ])

    // エラーメッセージ表示
    const errorMessage = useMemo(() => {
        if (orderStatus.status === LoginStatusType.Error) {
            return (<div className={style.error}>{t("msgErrorOccured")}</div>)
        }
        return null
    }, [orderStatus, t])

    // チャートクリック時の処理（何もしない）
    const handleClick = () => {}

    const getShopItems = (order: number[] | undefined): React.ReactNode[] => {
        if (order) {
            return order.map(sid => {
                if (dataset && dataset[sid]) {
                    const data = dataset[sid].data
                    const label = dataset[sid].label
                    const name = dataset[sid].shopName
                    return (
                        <div className={style.box} key={sid}>
                            <DashboardLineChart
                                dataType={statisticsDataType}
                                termType={displayTerm}
                                shopId={sid}
                                shopName={name}
                                labels={label}
                                datas={data}
                                status={TxStatusType.Fulfilled}
                                onClick={handleClick}
                            />
                        </div>
                    )
                } else {
                    return (null)
                }
            })
        }
        return []
    }

    const handleOnDragStop:ItemCallback = (layout, oldItem, newItem) => {
        console.log(layout, oldItem, newItem)
        const newOrder = getNewOrderFromLayout(oldItem, newItem)
        console.log("new displayOrder is ", newOrder)
        setDisplayOrder(newOrder)
    }

    return (
        <ModalFrame page={PageName.DashboardAll} title={t("header.dashboardAll")} width={1100} height={winHight}>
            <div className={style.pane}>
                <div className={style.title}>{t("changeStoreDisplayOrder")}</div>
                <div className={style.control}>
                    <div className={style.cancel}>
                        <Button
                            styleType={StyleType.Reverse}
                            name="sortCancel"
                            label="cancel"
                            onClick={(e) => handleCancel(e)}
                        />
                    </div>
                    <div className={style.update}>
                        <Button
                            styleType={StyleType.Normal}
                            name="modalUpdate"
                            label="saveChanges"
                            onClick={(e) => handleSave(e)}
                            disabled={orderStatus.status === LoginStatusType.Error ? true : false}
                        />
                    </div>
                    {errorMessage}
                </div>
                <GridLayout
                    className={style.layout}
                    layout={getLayoutFromDisplayOrder()}
                    cols={colSize}
                    rowHeight={rwHight}
                    width={width}
                    compactType='horizontal'
                    onDragStop={handleOnDragStop}
                >
                    {getShopItems(displayOrder)}
                </GridLayout>
            </div>
        </ModalFrame>
    )
}