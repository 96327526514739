import React, { useEffect, RefObject, ReactNode, useCallback, useState, useRef } from "react"
import { createPortal } from "react-dom"
import disableScroll from 'disable-scroll'
import { useWindowSize, WindowSizeType } from "../useWindowSize"

import style from "./Modal.module.css"


export const StyleType = {
    Dialog: "dialog",
    Page: "page"
} as const
export type ModalStyle = typeof StyleType[keyof typeof StyleType]

interface ModalProps {
    children: React.ReactNode
    isOpen: boolean
    onOverlayClick: React.MouseEventHandler<HTMLDivElement>
    elementId: 'root' | string
    handleClose: () => void
    styleType: ModalStyle
    resize: WindowSizeType
}

const useKeypress = (isOpen: boolean, close: () => void, ref: RefObject<HTMLDivElement>):void => {
    useEffect(() => {
        if (!isOpen) {
            return
        }

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                close()
                return
            }
            if (event.key === 'Tab') {
                event.preventDefault();
                if (ref.current === null) {
                    return
                }
                const focusables: HTMLElement[] = Array.from(
                    ref.current.querySelectorAll(
                        'button, a[href], input:not([type="hidden"]), select, details > summary:first-child, textarea, [tabindex]:not([tabindex="-1"]), [contenteditable]'
                    )
                )
                const currentIndex = focusables.findIndex((elm) => elm === document.activeElement)
                if (currentIndex === -1) {
                    focusables[0].focus()
                    return
                }
                const nextIndex = currentIndex + (event.shiftKey ? -1 : 1)
                if (nextIndex === -1) {
                    focusables[focusables.length - 1].focus()
                    return
                }
                if (nextIndex === focusables.length) {
                    focusables[0].focus()
                    return
                }
                focusables[nextIndex].focus()
                return
            }
        }

        document.body.addEventListener('keydown', onKeyDown)

        return () => {
            document.body.removeEventListener('keydown', onKeyDown)
        }
    }, [isOpen, close, ref])
}

const Modal: React.FC<ModalProps> = ({ children, isOpen = false, onOverlayClick, elementId = 'root', handleClose, styleType, resize }) => {
    const ref = useRef<HTMLDivElement>(null)
    const overlayRef = useRef<HTMLDivElement>(null)
    const windowSize = useWindowSize()

    useEffect(() => {
        //console.log("Modal resize event...")
        const changeHeight = resize.height && resize.height > 0 && windowSize.height && resize.height > windowSize.height * 0.8
        const changeWidth = resize.width && resize.width > 0 && windowSize.width && resize.width > windowSize.width * 0.8
        const ctElm = ref.current
        //console.log("Modal resize event. changeHeight:", changeHeight, "changeWidth:", changeWidth, "ctElm.style:", ctElm?.style)
        // リサイズイベント発生
        if (changeHeight || changeWidth) {
            if (ctElm) {
                if (changeHeight && windowSize.height) {
                    ctElm.style.height = (windowSize.height * 0.8) + 'px'
                }
                if (changeWidth && windowSize.width) {
                    ctElm.style.width = (windowSize.width * 0.8) + 'px'
                }
                ctElm.style.overflow = 'scroll'
            }
        } else {
            // スクロールバー解除
            if (ctElm) {
                ctElm.style.height = ''
                ctElm.style.overflow = ''
            }
        }
        
    }, [resize, windowSize, ref])

    // キー入力監視
    useKeypress(isOpen, handleClose, ref)

    // モーダルCLOSE時
    if (isOpen === false) {
        return null
    }
    // モーダルOPEN時
    const childElm = (
        <div role="dialog" aria-modal className={style.dialog}>
            <div className={(styleType === StyleType.Dialog) ? style.overlay : style.overlayPage}
                onClick={onOverlayClick}
                ref={overlayRef}
            ></div>
            <div ref={ref} className={(styleType===StyleType.Dialog) ? style.container : style.containerPage}>
                {children}
            </div>
        </div>
    )
    const containerElm = document.getElementById(elementId) as HTMLElement
    return createPortal(childElm, containerElm)
}

interface ModalOptions {
    preventScroll?: boolean
    closeOnOverlayClick?: boolean
    styleType?: ModalStyle
}

export type UseModalType = (elementId: string, options?: ModalOptions) => [
    ModalWrapper: React.FC<{ children: ReactNode }>,
    open: () => void,
    close: () => void,
    isOpen: boolean,
    resize: (size: WindowSizeType) => void
]

export const useModal: UseModalType = (elementId = "root", options = {}) => {
    const { preventScroll = false, closeOnOverlayClick = false, styleType = StyleType.Dialog } = options
    
    const [isOpen, setOpen] = useState<boolean>(false)
    const [size, setSize] = useState<WindowSizeType>({ width: undefined, height: undefined })

    //console.log("useModal isOpen:", isOpen)

    const open = useCallback(() => {
        //console.log("Modal do open")
        setOpen(true)
        if (preventScroll) {
            //console.log("disableScroll.on()")
            disableScroll.on()
        }
        // 下の画面を固定
        document.body.style.overflow = 'hidden'
        //console.log("Modal open body.overflow='hidden'")
    }, [setOpen, preventScroll])

    const close = useCallback(() => {
        //console.log("Modal do close")
        setOpen(false)
        if (preventScroll) {
            //console.log("disableScroll.off()")
            disableScroll.off()
        }
        // 下の画面の固定解除
        document.body.style.overflow = ''
        //console.log("Modal close body.overflow=''")
    }, [setOpen, preventScroll])

    const resize = useCallback((size: WindowSizeType) => {
        //console.log("Modal resizing. size:", size)
        setSize(size)
    }, [])

    const handleOverlayClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        //console.log("handleOverlayClick event", event)
        event.stopPropagation()
        if (closeOnOverlayClick) {
            close()
        }
    }, [closeOnOverlayClick, close])
    
    const InnerModalWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
        //console.log("InternalModalWrapper")
        return (
            <Modal
                isOpen={isOpen}
                onOverlayClick={handleOverlayClick}
                elementId={elementId}
                handleClose={close}
                styleType={styleType}
                resize={size}
            >
                {children}
            </Modal>
        )
    }
    const ModalWrapper = useCallback(InnerModalWrapper, [isOpen, handleOverlayClick, elementId, close, styleType, size])

    return [ModalWrapper, open, close, isOpen, resize]
}