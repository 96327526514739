import React, {ReactNode, useEffect, useMemo} from "react"
import { useTranslation } from "react-i18next"

import { useModal, StyleType as ModalStyleType } from "../modal/Modal"
import { Button, StyleType } from "../button/Button"

import style from "./CustomDialog.module.css"

/**
 * ボタンの種類
 */
export const AcceptButtonType = {
    Delete: "del",  // 削除する、赤色
    Copy: "cpy",    // 複製する、青色
    Save: "save",   // 変更を保存、青色
    Ok: "ok",        // OK、青色
    NoCancel: "nocan", // OKのみ、Candelなし、青色
    NoCancelRightSide: "ncrs"   // OKのみ、Candelなし、右寄せ、青色
} as const
export type AcceptButton = typeof AcceptButtonType[keyof typeof AcceptButtonType]

type Props = {
    requestOpen: boolean        // Modal制御、open=true, close=false
    width: number               // Panel幅
    height: number              // Panel高さ
    guideMessage: string        // １行目の文字｜メッセージ
    onCancel: () => void        // Cancelボタン押下
    onAccept: () => void        // Acceptボタン押下
    buttonType: AcceptButton    // ボタンの種類
    children?: ReactNode        // ２行目～ コンテンツ
    buttonDisabled?: boolean    // AcceptButtonのDisable化=true
}

export const CustomDialog: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    
    const [Modal, open, close, isOpen] = useModal('root', { preventScroll: false, closeOnOverlayClick: false, styleType: ModalStyleType.Dialog })

    /**
     * キャンセルボタン押下時の処理
     * @param event 
     */
    const handleCancel = (event: React.MouseEvent) => {
        event.preventDefault()
        close()
        props.onCancel()
    }
    
    /**
     * 承認ボタン押下時の処理
     * @param event 
     */
    const handleAccept = (event: React.MouseEvent) => {
        event.preventDefault()
        close()
        props.onAccept()
    }

    /**
     * ダイアローグを開きます
     */
    useEffect(() => {
        if (!isOpen && props.requestOpen) open()        
    }, [isOpen, props.requestOpen, open])

    /**
     * AcceptButtonのラベル名
     */
    const acceptLabel = useMemo(() => {
        if (props.buttonType === AcceptButtonType.Delete) {
            return t("deleteWithSpace")
        } else if (props.buttonType === AcceptButtonType.Copy) {
            return t("button.copy")
        } else if (props.buttonType === AcceptButtonType.Save) {
            return t("saveChanges")
        } else if (props.buttonType === AcceptButtonType.Ok || props.buttonType === AcceptButtonType.NoCancel || props.buttonType === AcceptButtonType.NoCancelRightSide) {
            return "O K　"
        } else {
            throw new Error("Unknown buttonType:" + props.buttonType)
        }
    }, [t, props])

    /**
     * AcceptButtonの色
     */
    const acceptStyle = useMemo(() => {
        if (props.buttonType === AcceptButtonType.Delete) {
            return StyleType.Danger
        } else {
            return StyleType.Normal
        }
    }, [props])
    
    const paneSize: React.CSSProperties = useMemo(() => {
        const obj = { width: props.width + "px", height: props.height + "px", borderRadius: "6px" }
        //console.log("paneSize:", obj)
        return obj
    }, [props])

    const isSingle = useMemo(() => {
        return (props.buttonType === AcceptButtonType.NoCancel || props.buttonType === AcceptButtonType.NoCancelRightSide) ? true : false
    }, [props])

    const isRightSide = useMemo(() => {
        return (props.buttonType === AcceptButtonType.NoCancelRightSide) ? true : false
    }, [props])

    return (
        <Modal>
            <div style={paneSize}>
                <div className={isRightSide ? style.dialogHeadRight : style.dialogHead}>
                    <span>{props.guideMessage}</span>
                </div>
                <div className={style.dialogBody}>
                    {props.children}
                </div>
                {isSingle ? (
                    <div className={isRightSide ? style.dialogBottomRight : style.digBtmSng}>
                        <Button label={acceptLabel} name="accepted" styleType={acceptStyle} onClick={(e) => handleAccept(e)} />
                    </div>
                ) : (
                    <div className={style.dialogBottom}>
                        <Button label={t("cancel")} name="canceled" styleType={StyleType.Reverse} onClick={(e) => handleCancel(e)} />
                        <span className={style.spc}></span>
                        <Button label={acceptLabel} name="accepted" styleType={acceptStyle} disabled={props.buttonDisabled} onClick={(e) => handleAccept(e)} />
                    </div>
                )}
            </div>
        </Modal>
    )
}
