import React from "react"
import { useTranslation } from "react-i18next"
import { Oval } from "react-loader-spinner"
import { formatSec2MMSS } from "../../component/TimeFormat"

import style from "./Progress.module.css"

export type Props = {
    progress: number | undefined   // 進捗率 0-100[%]
    remainMsTime: number | undefined    // 残り時間[ms]
}

export const Progress: React.FC<Props> = (props: Props) => {

	const { t } = useTranslation()

    let remain_str = "---"
    if (props.remainMsTime !== undefined) {
        remain_str = t("msgDisplayed", { sec: formatSec2MMSS(props.remainMsTime / 1000) })
    }
    let progress_str = ""
    if (props.progress !== undefined) {
        progress_str = t("msgDataAcquired", { par: props.progress })
    } else {
        progress_str = t("msgDataAcquired", { par: 0 })
    }

    return (
        <div className={style.pane}>
            <div className={style.primary}><span>{progress_str}</span></div>
            <div className={style.secondary}><span>{remain_str}</span></div>
            <div className={style.spinner}>
                <Oval
                    color="#FFFFFF"
                    width={26}
                    height={26}
                    strokeWidth={7}
                    strokeWidthSecondary={7}
                    secondaryColor="#FFFFFF"
                />
            </div>
        </div>
    )
}