
import MapWriter from "./MapWriter";

// 始点色： #007BFF
const START_POINT_COLOR = "rgb(0, 123, 255)"

// 終点色： #29CB97
const GOAL_POINT_COLOR = "rgb(41, 203, 151)"

// 動線色: #4A8DD8
const TRAIL_COLOR = 'rgb(74, 141, 216)'
const CLERK_TRAIL_COLOR = 'rgb(74, 200, 130)'

// ハイライトされた動線の色
const TRAIL_HI_COLOR = "rgb(220, 20, 60)" //DC143C
const CLERK_TRAIL_HI_COLOR = "rgb(220, 20, 126)"

// プログレスバーの設定
const PROGRESS_HEIGHT = 24
const PROGRESS_COLOR = "#8A8AFF"
const PROGRESS_BG_COLOR = "#D7DBEC"
const PROGRESS_ROUND = 12

type TrailPoint = {
    x: number
    y: number
}

class TrailMapWriter extends MapWriter {

    /**
     * １つの動線の始点|終点を描きます。
     * @param ctx 
     * @param color 
     * @param text 
     * @param pos 
     */
    async writeTerminalMark(ctx: CanvasRenderingContext2D, color: string, text: string, pos: TrailPoint) {
        //console.log("writeTerminalMark pos:", pos)
        // 円描画
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.fillStyle = color
        ctx.arc(pos.x, pos.y, 9, 0, Math.PI * 2)
        ctx.fill()
        // 文字描画
        ctx.beginPath()
        ctx.fillStyle = "white"
        ctx.textAlign = "center"
        ctx.textBaseline = "middle"
        ctx.font = "500 12px 'Noto Sans JP', Arial"
        ctx.fillText(text, pos.x, pos.y, 8)
        ctx.stroke()
    }

    /**
     * １つの動線のラインを描きます。
     * @param ctx 
     * @param points 
     * @param color 
     */
    async drawTrailLine(ctx: CanvasRenderingContext2D, rawPoints: TrailPoint[], color: string) {
        if (this.scaleGuard === undefined) {
            this.scaleGuard = performance.now()
            // 座標変換
            const points = rawPoints.map(pt => this.toPixelCoord(pt.x, pt.y))
            // Canvas要素を保存
            ctx.save()
            // 拡大縮小
            ctx.scale(this.imageScale, this.imageScale)
            // 線部分の描画
            ctx.beginPath()
            ctx.strokeStyle = color
            for (let i: number = 0; i < points.length; i++) {
                const pt: TrailPoint = points[i];
                (i === 0) ? ctx.moveTo(pt.x, pt.y) : ctx.lineTo(pt.x, pt.y)
            }
            ctx.stroke()
            // 始点S(tart)と終点G(oal)
            if (points.length >= 2) {
                this.writeTerminalMark(ctx, START_POINT_COLOR, "S", points[0])
                this.writeTerminalMark(ctx, GOAL_POINT_COLOR, "G", points[points.length - 1])
            }
            // 元に戻す
            ctx.restore()
            this.scaleGuard = undefined
        }
    }

    async drawProgress(ctx: CanvasRenderingContext2D, message: string, value: number) {
        const posY = this.canvasHeight - PROGRESS_HEIGHT - 1
        // Canvas要素を保存
        //ctx.save()
        // 拡大縮小
        //ctx.scale(this.fitScale, this.fitScale)
        if (value === 0) {
            //灰色部分
            ctx.lineWidth = 1
            ctx.strokeStyle = PROGRESS_BG_COLOR
            ctx.fillStyle = PROGRESS_BG_COLOR
            ctx.beginPath()
            ctx.moveTo(0, posY)
            ctx.lineTo(this.canvasWidth, posY)
            ctx.lineTo(this.canvasWidth, this.canvasHeight - 1)
            ctx.lineTo(0, this.canvasHeight - 1)
            ctx.lineTo(0, posY)
            ctx.fill()
        } else {
            const barWidth = (this.canvasWidth - PROGRESS_ROUND) * value / 100
            //灰色部分
            ctx.lineWidth = 1
            ctx.strokeStyle = PROGRESS_BG_COLOR
            ctx.fillStyle = PROGRESS_BG_COLOR
            ctx.beginPath()
            ctx.moveTo(barWidth, posY)
            ctx.lineTo(this.canvasWidth, posY)
            ctx.lineTo(this.canvasWidth, this.canvasHeight - 1)
            ctx.lineTo(barWidth, this.canvasHeight - 1)
            ctx.lineTo(barWidth, posY)
            ctx.fill()
            //バー
            ctx.strokeStyle = PROGRESS_COLOR
            ctx.fillStyle = PROGRESS_COLOR
            ctx.beginPath()
            ctx.moveTo(0, posY)
            //ctx.lineTo(barWidth, posY)
            ctx.arcTo(barWidth + PROGRESS_ROUND, posY, barWidth + PROGRESS_ROUND, posY + PROGRESS_ROUND, PROGRESS_ROUND)
            ctx.arcTo(barWidth + PROGRESS_ROUND, this.canvasHeight - 1, barWidth, this.canvasHeight - 1, PROGRESS_ROUND)
            ctx.lineTo(0, this.canvasHeight - 1)
            ctx.lineTo(0, posY)
            ctx.fill()
        }
        //文字
        const per = Math.round(value)
        const text = (message) ? message + "(" + per + "%)" : per + "%"
        ctx.font = "11pt 'Noto Sans JP',Arial,sans-serif"
        ctx.textBaseline = "middle"
        ctx.fillStyle = "#FFFFFF"
        ctx.fillText(text, 5, posY + 10)
        // 元に戻す
        //ctx.restore()
    }

    /**
     * キャンバスクリア
     * @param ctx 
     */
    async clear(ctx: CanvasRenderingContext2D) {
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        this.clearCanvas(ctx)
        ctx.restore()
    }

    async drawHighLightOn(ctx: CanvasRenderingContext2D, points: TrailPoint[], isClerk: boolean): Promise<void> {
        //console.log("drawHighLightOn")
        const color = isClerk ? CLERK_TRAIL_HI_COLOR : TRAIL_HI_COLOR
        await this.drawTrailLine(ctx, points, color)
        return
    }

    async drawHighLightOff(ctx: CanvasRenderingContext2D, points: TrailPoint[], isClerk: boolean): Promise<void> {
        //console.log("drawHighLightOff")
        const color = isClerk ? CLERK_TRAIL_COLOR : TRAIL_COLOR
        this.drawTrailLine(ctx, points, color)
        return
    }

    /**
     * マップをつまんだままマウスの移動に合わせてマップを再描画します。
     *  1.dstCanvasを白で塗りつぶしてsrcCanvasを見えなくする。
     *  2.bgCanvasの背景画像を移動する。
     *  3.動線の描かれたsrcCanvasからdstCanvasに絵をコピーして移動したように見せる。
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     * @param bgCanvas 
     * @param mouseCamAxix
     */
    public mouseMove2(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined = undefined, mouseCamAxix: (camX: number, camY: number) => void) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // ドラッグ処理
                this.mouseDragX = (event.clientX - rect.left)
                this.mouseDragY = (event.clientY - rect.top)
                const moveX = (this.mouseDragX - this.mouseMoveX)
                const moveY = (this.mouseDragY - this.mouseMoveY)
                this.vtLeft = this.memoLeft + moveX / this.imageScale
                this.vtTop = this.memoTop + moveY / this.imageScale
                // Imageの移動(Canvas間Copy)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.canvasWidth - Math.abs(moveX)
                    const h = this.canvasHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#fff"
                    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
                    if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)
                    //console.log("drag Map")
                }
                this.isMousePress = true
            } else {
                // 移動座標の記録（Canvas上のマウス座標）
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
                // マウスの位置をカメラ座標にする
                const camX = ((this.mouseMoveX / this.imageScale) - this.vtLeft - this.origin_x) * this.mm_per_pixel
                const camY = ((this.mouseMoveY / this.imageScale) - this.vtTop - this.origin_y) * this.mm_per_pixel
                // マウスの位置を親に通知する
                mouseCamAxix(camX, camY)
            }
        }
    }



}

export default TrailMapWriter