import React, { useState, useRef, MutableRefObject, useEffect, EventHandler, ReactEventHandler, ChangeEvent, CSSProperties } from "react"
import { usePopper } from "react-popper"

import style from './fupRange.module.css'
import { formatSec2MMSS } from "../TimeFormat"

// スライダーのバーのサイズ（実デザインと合わせること！）
// 250(width) - 16(circle)
const BAR_WIDTH = 234

interface Props {
    value: number
    max?: number
    min?: number
    step?: number
    onChange: (value: number) => void
}

interface Refs {
    reference: MutableRefObject<HTMLDivElement | null>
    popper: MutableRefObject<HTMLDivElement | null>
    arrow: MutableRefObject<HTMLDivElement | null>
}

export const FupRange: React.FC<Props> = (props) => {

    // 値が変わったら、親コンポーネントに伝達
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        props.onChange(parseInt(val))
    }
    
    // RefをStateにして、画面更新させる
    const reference = useRef(null)
    const popperRef = useRef(null)
    const arrowRef = useRef(null)
    const [refs, setRefs] = useState<Refs>({
        reference: reference,
        popper: popperRef,
        arrow: arrowRef
    })

    // 表示用分秒
    const dispVal: string = formatSec2MMSS(props.value)
    const dispLen = dispVal.length

    // 補正値（文字数が多い時は少し右へ）
    const dx = (dispLen > 4) ? 10 : 0
    // 表示位置の計算
    const max = props.max ? props.max : 100
    const min = props.min ? props.min : 0
    const len = max - min
    const val = props.value - min
    const pos = Math.floor(BAR_WIDTH * (val / len)) - (BAR_WIDTH / 2) + dx + 8

    // Popper登録（Arrowは独自にした）
    const { styles, attributes } = usePopper(refs.reference.current, refs.popper.current, {
        placement: "top",
        modifiers: [
            { name: "offset", options: { offset: [pos, 0] } }
        ]
    })

    // DOM描画後にRefを再登録
    useEffect(() => {
        setRefs({reference: reference, popper: popperRef, arrow: arrowRef})
    }, [reference, popperRef, arrowRef])

    return (
        <div className={style.outer} ref={reference}>
            <input type="range" value={props.value} max={props.max} min={props.min} step={props.step} onChange={handleChange} className={style.range} />
            <div ref={popperRef} style={styles.popper} {...attributes.popper} className={style.tooltip}>
                <div className={style.content}>{dispVal}</div>
                <div className={style.arrow}></div>
            </div>
        </div>
    )
}