import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { RouteAuthGuard } from './providers/RouteAuthGuard';
import { AuthUserProvider  } from './providers';
import { StatisticsDataProvider } from './providers/StatisticsData';
import { AnalyticsDataProvider } from './providers/AnalyticsData';
import { ManagementDataProvider } from './providers/ManagementData';
import { AccessControlProvider } from './providers/AccessControler';
import { Dashboard } from './feature/dashboard/Dashboard';
import { IndividualDashboard } from './feature/dashboard/IndividualDashboard';
import { ShopDisplayOrderChange } from './feature/dashboard/ShopDisplayOrderChange';
import { Analyze } from "./feature/analyze/Analyze"
import { Funnel } from './feature/analyze/Funnel';
import { Login } from "./feature/auth/Login"
import { Logout } from "./feature/auth/Logout"
import { ForgotPassword } from './feature/auth/ForgotPassword';
import { PasswordReset } from './feature/auth/PasswordReset';
import { ProtectedLayout } from './providers/ProtectedLayout';
import { CompanyList } from './feature/basic_info/CompanyList';
import { CompanyEdit } from './feature/basic_info/CompanyEdit';
import { ShopList } from './feature/basic_info/ShopList';
import { ShopEdit } from "./feature/basic_info/ShopEdit"
import { AccountList } from './feature/basic_info/AccountList';
import { AccountEdit } from './feature/basic_info/AccountEdit';
import { LayoutList } from "./feature/basic_info/LayoutList"
import { LayoutEdit } from "./feature/basic_info/LayoutEdit"
import { AreaList } from "./feature/basic_info/AreaList"
import { AreaDetail } from './feature/basic_info/AreaDetail';
import { AreaEdit } from './feature/basic_info/AreaEdit';
import { LineList } from './feature/basic_info/LineList';
import { LineDetail } from './feature/basic_info/LineDetail';
import { GroupList } from './feature/basic_info/GroupList';
import { CategoryDetailModal } from './feature/basic_info/CategoryDetailModal';
import { CategoryEditModal } from './feature/basic_info/CategoryEditModal';
import { LayoutRegiStep1 } from './feature/basic_info/LayoutRegiStep1';
import { LayoutRegiStep2 } from './feature/basic_info/LayoutRegiStep2';
import { LayoutRegiStep3 } from './feature/basic_info/LayoutRegiStep3';
import { LayoutRegiStep4 } from './feature/basic_info/LayoutRegiStep4';
import { LayoutRegiStep5 } from './feature/basic_info/LayoutRegiStep5';
import { AreaRegiList } from './feature/basic_info/AreaRegiList';
import { AreaRegiEdit } from './feature/basic_info/AreaRegiEdit';
import { ChangePassword } from './feature/auth/ChangePassword';

import './App.css';

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

if (process.env.REACT_APP_LOGROCKET_INIT != null && process.env.REACT_APP_LOGROCKET_INIT !== '') {
  setupLogRocketReact(LogRocket);
  LogRocket.init(process.env.REACT_APP_LOGROCKET_INIT);
}

const App: React.FC = () => {

    const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"

    // GTMの初期化
    let gtmId = 'GTM-XXXXXX'    // ダミーのIDを設定 
    if (isProduction && process.env.REACT_APP_GTM_ID !== undefined && process.env.REACT_APP_GTM_ID !== null && process.env.REACT_APP_GTM_ID !== '') {
        // 本番の場合は環境変数から正しいIDを取得
        gtmId = process.env.REACT_APP_GTM_ID
    } else {
        console.log("***** GTM is not initialized *****")
    }
    const gtmParams = {
        id: gtmId
    }

    // 事前にログインが必要なページは、ProtectedLayoutで囲むこと。こうすることでタイムアウトが機能する。
    return (
        <GTMProvider state={gtmParams}>
            <AuthUserProvider>
                <StatisticsDataProvider>
                    <AnalyticsDataProvider>
                        <ManagementDataProvider>
                            <AccessControlProvider>
                                <HashRouter>
                                    <Routes>
                                        <Route path={baseUrl} element={<ProtectedLayout />} >
                                            <Route index element={<Dashboard/>} />
                                            <Route path="dashboard" element={<Dashboard />} />
                                            <Route path="dashboard/:shop_id" element={<IndividualDashboard />} />
                                            <Route path="dashboard/sort" element={<ShopDisplayOrderChange />} />
                                            <Route path="analyze" element={<Analyze param="single" />} />
                                            <Route path="analyze_cmp" element={<Analyze param="compare" />} />
                                            <Route path="funnel" element={<Funnel param="single" />} />
                                            <Route path="funnel_cmp" element={<Funnel param="compare" />} />
                                            <Route path="company_list" element={<CompanyList />} />
                                            <Route path="company_edit" element={<CompanyEdit />} />
                                            <Route path="shop_list" element={<ShopList />} />
                                            <Route path="shop_edit" element={<ShopEdit />} />
                                            <Route path="layout_list" element={<LayoutList />} />
                                            <Route path="layout_edit" element={<LayoutEdit />} />
                                            <Route path="area_list" element={<AreaList />} />
                                            <Route path="area_detail" element={<AreaDetail />} />
                                            <Route path="area_edit" element={<AreaEdit />} />
                                            <Route path="line_list" element={<LineList />} />
                                            <Route path="line_detail" element={<LineDetail />} />
                                            <Route path="group_list" element={<GroupList />} />
                                            <Route path="area_category" element={<CategoryDetailModal />} />
                                            <Route path="area_category_edit" element={<CategoryEditModal />} />
                                            <Route path="account_list" element={<AccountList />} />
                                            <Route path="account_edit" element={<AccountEdit />} />
                                            <Route path="change_password" element={<ChangePassword />} />
                                            <Route path={"layout_regi_step1"} element={<LayoutRegiStep1 />} />
                                            <Route path={"layout_regi_step2"} element={<LayoutRegiStep2 />} />
                                            <Route path={"layout_regi_step3"} element={<LayoutRegiStep3 />} />
                                            <Route path={"layout_regi_step4"} element={<LayoutRegiStep4 />} />
                                            <Route path={"layout_regi_step5"} element={<LayoutRegiStep5 />} />
                                            <Route path={"area_regi_list"} element={<AreaRegiList />} />
                                            <Route path={"area_regi_edit"} element={<AreaRegiEdit />} />
                                        </Route>
                                        <Route path={baseUrl + "login"} element={<Login />}/>
                                        <Route path={baseUrl + "logout"} element={<Logout />} />
                                        <Route path={baseUrl + "forgot"} element={<ForgotPassword />} />
                                        <Route path={baseUrl + "reset"} element={<PasswordReset />}>
                                            <Route path=":certification_code" element={<PasswordReset />} />
                                        </Route>                                        
                                        <Route path={baseUrl + "*"} element={
                                            <RouteAuthGuard component={<Dashboard />} redirect={baseUrl + "login"} />
                                        } />
                                    </Routes>
                                </HashRouter>
                            </AccessControlProvider>
                        </ManagementDataProvider>
                    </AnalyticsDataProvider>
                </StatisticsDataProvider>
            </AuthUserProvider>
        </GTMProvider>
    );
}

export default App;
