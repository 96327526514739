import React from "react"

import style from "./CircleCloseButton.module.css"

export const CloseStyleType = {
    Normal: 'normal',
    SmallWhite: 'small_white',
} as const
export type CloseButtonType = typeof CloseStyleType[keyof typeof CloseStyleType]

interface Props {
    styleType?: CloseButtonType
    onClose: () => void
}

export const CircleCloseButton: React.FC<Props> = (props) => {

    let clsType = style.icony
    if (props.styleType && props.styleType === CloseStyleType.SmallWhite) {
        clsType = style.smallWhite
    }
    let inrClsType = style.inner
    if (props.styleType && props.styleType === CloseStyleType.SmallWhite) {
        inrClsType = style.smallWhiteInner
    }

    return (
        <div className={clsType} onClick={props.onClose} >
            <div className={inrClsType}>
                <img src="/img/icon-close.png" alt="icon_close" />
            </div>
        </div>
    )
}