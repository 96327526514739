import { useState } from "react"

// 保存時間用キー
const SAVE_TIME = "created_at"

// ２４時間（ミリ秒）
const ONE_DAY_TIME = 24 * 60 * 60 * 1000

interface Props {
    keyName: string
    defaultValue?: any
}

/**
 * ローカルストレージを非同期化します
 */
const asyncLocalStorage = {
    setItem: async (key: string, value: any):Promise<void> => {
        await Promise.resolve()
        return window.localStorage.setItem(key, value)
    },
    getItem: async (key: string): Promise<any> => {
        await Promise.resolve()
        return localStorage.getItem(key)
    },
    removeItem: async (key: string): Promise<any> => {
        await Promise.resolve()
        return localStorage.removeItem(key)
    },
    clear: async (): Promise<any> => {  
        await Promise.resolve()
        return localStorage.clear()
    }
}

/**
 * ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
 * コメント：
 * この関数は動線のUserInfoのみを扱うもので、他の用途は考慮していない。
 * CreateAtは、UserInfoの時間切れ監視のために後から追加した。
 * よって、他の目的で利用する場合には上記のことを考慮して改造すること。
 * ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
 * 
 * @param props 
 * @returns 
 */
export const useLocalStorage = (props: Props) => {
    //console.log("useLocalStorage")

    // 初回読込前はundefinedで、データがないときがnull
    const [storedValue, setStoredValue] = useState<any | null | undefined>(undefined)
    // 保存時刻
    //const [createdAt, setCreatedAt] = useState<any | null | undefined>(undefined)

    // stateの初期化処理（ストレージが遅いので、後から設定する。初期設定がおわるまでstoredValue=undefinedとなる。）
    const initialize = async () => {
        // storedValueをstateに格納するため、その値を取得する。
        try {
            if (storedValue === undefined) {
                // ストレージの値を取得
                const value = await asyncLocalStorage.getItem(props.keyName)
                if (value) {
                    //console.log("useLocalStorage.initialize UserInfoがありました。")
                    const json = JSON.parse(value)
                    const time = await asyncLocalStorage.getItem(SAVE_TIME)
                    if (time) {
                        const now = (new Date()).getTime()
                        const diff = now - time
                        // 設定時間内であればOK
                        if (diff < ONE_DAY_TIME) {
                            setStoredValue(json)
                            //setCreatedAt(time)
                        } else {
                            console.error("The storage save time has expired.")
                            setStoredValue(null)
                            //setCreatedAt(null)
                        }
                    } else {
                        // 時間が設定されていないとき
                        setStoredValue(json)
                    }
                } else {
                    if (props.defaultValue) {
                        // 空の時はデフォルト値を設定
                        await asyncLocalStorage.setItem(props.keyName, JSON.stringify(props.defaultValue))
                        setStoredValue(props.defaultValue)
                    } else {
                        // 特別な値としてnullを設定（ログインへ進めるため）
                        setStoredValue(null)
                    }
                }                
            }
        } catch (err) {
            console.error(err)
            return props.defaultValue
        }
    }
    initialize()
    
    const setValue = (newValue: any) => {
        try {
            // ストレージに保存
            asyncLocalStorage.setItem(props.keyName, JSON.stringify(newValue)).then(() => {
                // stateを更新
                setStoredValue(newValue)
                // 時間を記録
                const dt = new Date()
                const tm = dt.getTime()
                asyncLocalStorage.setItem(SAVE_TIME, tm).then(() => {
                    //setCreatedAt(tm)
                }).catch(e => {throw e})
            }).catch(error => {throw error})
        } catch (err) {
            console.error(err)
        }
    }

    const removeValue = () => {
        try {
            // ストレージから削除
            asyncLocalStorage.removeItem(props.keyName).then(() => {
                // stateをnullに
                setStoredValue(null)
                // 時間を削除
                asyncLocalStorage.removeItem(SAVE_TIME).then(() => {
                    //setCreatedAt(null)
                }).catch(e => {throw e})
            }).catch(error => {throw error})
        } catch (err) {
            console.error(err)
        }
    }
    return [storedValue, setValue, removeValue]
}