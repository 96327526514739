import React, { useRef, useState, useEffect } from "react"
import type { ChartData, ChartArea, ChartOptions } from "chart.js"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js'
import { Chart } from "react-chartjs-2"
import { TailSpin } from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import { TxStatus, TxStatusType } from "../../types"
import Utils from "../../lib/Utils"

import style from "./IndividualLineChart.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const LINE_COLOR_BLUE = '#1E5EFF'
const LINE_COLOR_GREEN = '#1FD286'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
)

export const IndividualChartType = {
    ShopSales: "sales",             //店舗売上
    NumOfBuyers: "buyers",          //購買者数
    AvgCustomerPrice: "avg_price",  //平均客単価
    AvgNumOfPurchases: "avg_goods", //平均購入点数
    NumOfVisitors: "visitors",      //来店者数
    AvgInShopTime: "avg_time",      //平均店舗滞在時間
    PurchasingRate: "buy_rate"      //購買率
} as const
export type IndividualChart = typeof IndividualChartType[keyof typeof IndividualChartType]

export const ChartDataUnitType = {
    money: "money",         // 金額：円
    person: "person",       // 人数：人
    number: "number",       // 数量：個
    time: "time",           // 時間：秒
    rate: "rate",           // 率：％
} as const
export type ChartDataUnit = typeof ChartDataUnitType[keyof typeof ChartDataUnitType]

type IndividualLabelDataType = {
    type: IndividualChart
    title: string
    top: string
    mid: string
    rate: string
    bottom: string
    unit: ChartDataUnit        // 単位：円、人、点、秒、％など
}

const IndividualLabels: IndividualLabelDataType[] = [
    { type: IndividualChartType.ShopSales, title: "storeSales", top: "appointedDay", mid: "prevMonth", rate: "％", bottom: "prevYear", unit: ChartDataUnitType.money },
    { type: IndividualChartType.NumOfBuyers, title: "purchasers", top: "appointedDay", mid: "prevMonth", rate: "％", bottom: "prevYear", unit: ChartDataUnitType.person },
    { type: IndividualChartType.AvgCustomerPrice, title: "averageTransaction", top: "appointedDay", mid: "prevMonth", rate: "％", bottom: "prevYear", unit: ChartDataUnitType.money },
    { type: IndividualChartType.AvgNumOfPurchases, title: "itemsPerTransaction", top: "appointedDay", mid: "prevMonth", rate: "％", bottom: "prevYear", unit: ChartDataUnitType.number },
    { type: IndividualChartType.NumOfVisitors, title: "visitors", top: "appointedDay", mid: "monthAgo", rate: "％", bottom: "", unit: ChartDataUnitType.number },
    { type: IndividualChartType.AvgInShopTime, title: "averageDwellTimeShop", top: "appointedDay", mid: "monthAgo", rate: "％", bottom: "", unit: ChartDataUnitType.time },
    { type: IndividualChartType.PurchasingRate, title: "purchaseRatePar", top: "appointedDay", mid: "monthAgo", rate: "％", bottom: "", unit: ChartDataUnitType.rate },
]

interface Props {
    type: IndividualChart
    shopId: number
    values: number[]
    labels: string[]
    datas: number[]
    status: TxStatus
}

const formatValues = (type: IndividualChart, value: number | undefined | null) => {
    if (value) {
        if (type === IndividualChartType.AvgInShopTime) {
            // 時間(hh:mm:ss)
            return Utils.formatMillisecToTime(value)
        } else if (type === IndividualChartType.PurchasingRate) {
            // 符号付き小数点第一位まで(±99.9)
            return Utils.formatSignCommaPointOne(value)
        } else if (type === IndividualChartType.AvgCustomerPrice) {
            //  小数点第一位まで(99.9)
            return Utils.formatCommaPointOne(value)
        } else {
            // 整数(9,999)
            return Utils.formatCommaInteger(value)
        }
    } else {
        return "-"
    }
}

export const IndividualLineChart: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const chartRef = useRef<ChartJS<'line', number[], string>>(null)
    const [chartData, setChartData] = useState<ChartData<'line'>>({ datasets: [] })
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const graphRef = useRef<HTMLDivElement>(null)

    const options: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: { display: false },
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: { left: 5, right: 0, top: 10, bottom: 10 }
        },
        elements: {
            point: { radius: 0 },
            line: { borderWidth: 1, tension: 0 }
        },
        scales: {
            x: {
                ticks: { display: false },
                grid: { display: false, drawBorder: false },
            },
            y: {
                ticks: { display: false },
                grid: { display: false, drawBorder: false },
            }
        }
    }
    let mainStyle: string = style.main4line
    let isHotColor: boolean = true
    let strVals: string[] = (props.type === IndividualChartType.NumOfVisitors
        || props.type === IndividualChartType.AvgInShopTime
        || props.type === IndividualChartType.PurchasingRate) ? ["-", "-", "-"] : ["-", "-", "-", "-",] 
    if (props.status === TxStatusType.Fulfilled) {
        const len = props.values.length
        if (len > 1) {
            const curr = props.values[0]
            strVals[0] = formatValues(props.type, curr)
            const prev = props.values[1]
            strVals[1] = formatValues(props.type, prev)
            const diff = curr - prev
            if (diff < 0) isHotColor = false
            if (prev) strVals[2] = Utils.formatSignCommaPointOne((diff / prev) * 100)
            strVals[3] = (len > 2) ? formatValues(props.type, props.values[2]) : ""
            if (len < 3) mainStyle = style.main3line
        }
        if (isLoading) setIsLoading(false)
    } else {
        if (!isLoading) setIsLoading(true)
    }
    const titles = IndividualLabels.find(el => el.type === props.type)

    const handleHoverZoomUp = () => {
        if (graphRef.current) {
            graphRef.current.style.transform = "scale(1.1)"
            graphRef.current.style.transition = "transform 0.5s"
        }
    }

    const handleHoverZoomReset = () => {
        if (graphRef.current) {
            graphRef.current.style.transform = "scale(1)"
            graphRef.current.style.transition = "transform 0.5s"
        }
    }

    useEffect(() => {
        const chart = chartRef.current
        if (chart) {
            let isHot: boolean = true
            if (props.status === TxStatusType.Fulfilled) {
                const len = props.datas.length
                if (len > 0) {
                    const last = props.datas[len - 1]
                    const prev = props.datas[len - 2]
                    if (last < prev) isHot = false
                }
            }
            const ctx: CanvasRenderingContext2D = chart.ctx
            const area: ChartArea = chart.chartArea
            const halfWidth =  (area.right - area.left) / 2
            const gradient = ctx.createLinearGradient(halfWidth, area.top, halfWidth, area.bottom)
            const borderCol: string = (isHot) ? LINE_COLOR_GREEN : LINE_COLOR_BLUE
            gradient.addColorStop(0, borderCol)
            gradient.addColorStop(1, 'white')
            const chartDts = {
                labels: props.labels,
                datasets: [{
                    label: titles ? t(titles.title) : "",
                    backgroundColor: gradient,
                    borderColor: borderCol,
                    data: props.datas,
                    fill: true,
                }]
            }
            setChartData(chartDts)
        }
    }, [props.status, props.datas, props.labels, chartRef, titles, t])


    //console.log("props.status, isLoading:",props.status,isLoading)
    return (
        <div className={mainStyle} onMouseOver={handleHoverZoomUp} onMouseOut={handleHoverZoomReset} ref={graphRef}>
            <div className={style.title}>{titles ? t(titles.title) : ""}</div>
            <div className={style.table}>
                <table className={style.tableMain}>
                    <tbody>
                        <tr>
                            <td className={style.dataName}>{titles ? t(titles.top) : ""}</td>
                            <td className={style.dataValue}>{strVals[0]}</td>
                        </tr>
                        <tr>
                            <td className={style.dataName}>&nbsp;</td>
                            <td className={style.dataValue}>&nbsp;</td>
                        </tr>
                    </tbody>                    
                </table>
            </div>
            {
                (isLoading) ? (
                    <div className={style.spiner}>
                        <TailSpin color="#1E5EFF" width={50} height={50} />
                    </div>
                ) : (
                    <div className={style.graph}>
                        <Chart ref={chartRef} type="line" data={chartData} options={options} />
                    </div>
                )
            }            
        </div>
    )
}