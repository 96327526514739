import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ContentsDistributor, MainCompoProps, PanelDesignType } from "./ContentsDistributor"
import { BtnProp, SeriesSwitcher, SwitcherFontType } from "../../component/series_switcher/SeriesSwitcher"
import AreaMapWriter from "../../lib/AreaMapWriter"
import { DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT } from "../../constants"
import { STAY_COLOR, NOT_STAY_COLOR, STAY_COLOR_GR, NOT_STAY_COLOR_GR } from "../../lib/ColorUtil"
import Utils from "../../lib/Utils"
//import { useMouseWheel } from "../../lib/useMouseWheel"
import { useMouseMove } from "../../lib/useMouseMove"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { AnalyzeParametersType, CompareSideType } from "../../types/Analyze"
import { ResArea } from "../../api/data/analysis/FullLayout"

import style from "./AreaMap.module.css"


const DispDataType = {
    Stay: "stay",
    StayRatio: "stayratio",
    NotStay: "nots",
    NotStayRatio: "notsratio"
} as const
//type DispDataEnum = typeof DispDataType[keyof typeof DispDataType]

const SwitchItems: BtnProp[] = [
    { name: DispDataType.Stay, label: "personStaying" },
    { name: DispDataType.StayRatio, label: "stayRatio"},
    { name: DispDataType.NotStay, label: "passengers" },
    { name: DispDataType.NotStayRatio, label: "passageRatio"},
]

const AreaMapCore: React.FC<MainCompoProps> = (props) => {

    //console.log("props.data:", props.data)

	const { t } = useTranslation()
    const [dispType, setDispType] = useState<BtnProp>(SwitchItems[0])
    const ref = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const [writer, setWriter] = useState<AreaMapWriter | undefined>(undefined)
    const refFirst = useRef(true)

    const side = useMemo(() => {
        if (props.request) {
            if (props.request.side) {
                return props.request.side
            }
        }
        return "0"
    }, [props.request])

    /**
     * 表示データの切替ボタン処理
     * 
     * @param event 
     */
    const changeDataType: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        //console.log("changeDataType nm:", nm)
        switch (nm) {
            case DispDataType.Stay:
                setDispType(SwitchItems[0])
                break
            case DispDataType.StayRatio:
                setDispType(SwitchItems[1])
                break
            case DispDataType.NotStay:
                setDispType(SwitchItems[2])
                break
            case DispDataType.NotStayRatio:
                setDispType(SwitchItems[3])
                break
            default:
				const msg = t("msgUnknownButton")
                throw new Error(msg + " nm:" + nm)
        }
    }

    /**
     * カウントデータを作成する
     */
    const createCountData = useCallback((): number[] | undefined => {
        if (props.data) {
            const countList = props.data.get_area_count_list()
            //console.log(countList)
            let counts = countList.map(el => {
                if (dispType.name === DispDataType.Stay) {
                    return el.stay_count
                } else if (dispType.name === DispDataType.NotStay) {
                    return el.pass_count
                } else if (dispType.name === DispDataType.StayRatio) {
                    return el.stay_rate
                } else if (dispType.name === DispDataType.NotStayRatio) {
                    return el.pass_rate
                } else {
                    return 0
                }
            })
            return counts
        }
        return undefined
    }, [dispType.name, props.data])

    const getAreaList = useCallback((): ResArea[] | undefined => {
        if (props.data) {
            const countList = props.data.get_area_count_list()
            return countList.map(el => {
                return el.area
            })
        }
    }, [props.data])

    const handleZoomReset = () => {
        if (writer) {
            const counts = createCountData()
            writer.zoomReset()
            if (counts) draw(writer, counts)    
        }        
    }

    const handleZoomIn = () => {
        if (writer) {
            const counts = createCountData()
            writer.zoomIn()
            if (counts) draw(writer, counts)
        }
    }

    const handleZoomOut = () => {
        if (writer) {
            const counts = createCountData()
            writer.zoomOut()
            if (counts) draw(writer, counts)
        }
    }

    const getBgColor = useCallback((dispType: BtnProp) => {
        if (dispType.name === DispDataType.Stay || dispType.name === DispDataType.StayRatio) {
            if (side === CompareSideType.Primary) {
                return STAY_COLOR
            } else {
                return STAY_COLOR_GR
            }
        } else {
            if (side === CompareSideType.Primary) {
                return NOT_STAY_COLOR
            } else {
                return NOT_STAY_COLOR_GR
            }
        }
    }, [side])

    const draw = useCallback((writer: AreaMapWriter, counts: number[]) => {
        console.log("draw counts:", counts)
        if (writer) {
            const perFlag = (dispType.name === DispDataType.StayRatio || dispType.name === DispDataType.NotStayRatio)
            writer.arrangement(counts, perFlag).then(res => {
                const ctx: CanvasRenderingContext2D | null | undefined = ref.current?.getContext("2d")
                if (ctx) {
                    // 描画する
                    const bgcol = getBgColor(dispType)
                    const area_list = getAreaList()
                    if (area_list) writer.draw(ctx, area_list, bgcol)
                }
            })
        }
    }, [dispType, getBgColor, getAreaList])

    // writer初期化
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP render")
                return
            }
        }

        //if (props.layout) {
        if (props.request && props.data) {
            //const id = props.request.layoutId
            const layout = props.data.layout
            const area_list = getAreaList()
            if (layout && area_list) {
                if (writer === undefined) {
                    if (props.data) {
                        const mapWriter = new AreaMapWriter(layout, area_list, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT)
                        mapWriter.pathDepth = 1
                        setWriter(mapWriter)
                        const counts = createCountData()
                        if (counts) draw(mapWriter, counts)
                    }
                }
            }
        }
    }, [props.data, props.request, writer, draw, createCountData, getAreaList])

    /*useMouseWheel(aniRef, (event: WheelEvent) => {
        // 拡大縮小処理
        if (writer) {
            const counts = createCountData()
            if (counts) {
                writer.canvasZoom(event)
                draw(writer, counts)
            }
        }
    })*/

    const mousePress = useMouseMove(aniRef, (event: MouseEvent) => {
        // 画像移動animation
        if (writer) {
            const srcCanvas = ref.current
            const dstCanvas = aniRef.current
            if (srcCanvas && dstCanvas) writer.mouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスリリース処理
        if (writer) {
            writer.mouseRelease()
            const counts = createCountData()
            if (counts) {
                // 再描画
                draw(writer, counts)
                // ドラッグ領域をクリア
                const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current?.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
            }
        }
    }, (event: MouseEvent) => {
        // マウス押下処理
        if (writer) {
            writer.mousePress()
        }
    })

    /*useEffect(() => {
        //console.log("effect 2")
        if (mousePress) {
            if (writer) writer.mousePress()
        } else {
            const counts = createCountData()
            if (writer && counts) {
                // 再描画
                draw(writer, counts)
                // ドラッグ領域をクリア
                const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current?.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
            }
        }
    }, [mousePress, createCountData, writer, draw])*/

    //const side = (props.request) ? props.request.side : "0"
    
    let stayClz = style.stay1
    let passClz = style.pass1
    if (side === CompareSideType.Secondary) {
        stayClz = style.stay2
        passClz = style.pass2
    }

    let comment: string
    if (dispType.name === DispDataType.NotStay || dispType.name === DispDataType.Stay) {
        comment = t("unitPersons")
    } else {
        comment = t("unitRoundedOff")
    }
    const threshold = props.request?.threshold
    const addMsgStay = (threshold) ? "（" + t("more", {time: Utils.formatSecToJikan(threshold)}) + "）" : ""
    const addMsgPass = (threshold) ? "（" + t("lessThan", {time:Utils.formatSecToJikan(threshold)}) + "）" : ""

    return (
        <div className={style.content}>
            <div className={style.head}>
                <div className={style.legend}>
                    <div className={style.block}>
                        <span className={style.yellow}>99</span><span className={style.text}>{t("areaName")}</span>
                    </div>
                    <div className={style.space}></div>
                    <div className={style.block}>
                        <span className={stayClz}>99999</span><span className={style.text}>{t("stay")}{addMsgStay}</span>
                    </div>
                    <div className={style.space}></div>
                    <div className={style.block}>
                        <span className={passClz}>99999</span><span className={style.text}>{t("passage")}{addMsgPass}</span>
                    </div>
                </div>
                <div className={style.dummy}></div>
                <div className={style.switch}>
                    <SeriesSwitcher fontType={SwitcherFontType.Mulish} buttonList={SwitchItems} onClick={e => changeDataType(e)} activeName={dispType.name}/>
                </div>
            </div>
            <div className={style.comment}>{comment}</div>
            <div className={style.body}>
                <div className={style.canvasWrap}>
                    <canvas id={"areaMapCanvas" + side} ref={ref} className={style.canvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT}></canvas>
                    <canvas id={"aniCanvas" + side} ref={aniRef} className={style.aniCanvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT}></canvas>
                    <div className={style.zoomBtn}>
                        <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const AreaMap: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={AreaMapCore} type={PanelDesignType.Shadow} />
    )
}