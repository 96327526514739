/**
 * 座標を表すクラス
 */
export class Coord {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export class Line {
    left: Coord;
    right: Coord;

    constructor(left: Coord, right: Coord) {
        this.left = left;
        this.right = right;
    }
}

/**
 * Coordにwidth要素を加えたクラス
 */
export class CoordAndWidth {
    x: number
    y: number
    w: number

    constructor(x: number, y: number, w: number) {
        this.x = x
        this.y = y
        this.w = w
    }
}