import { TargetCustomer } from "../core/Enums"
import { PAGING_SIZE } from "../core/Constants"

/**
 * 検索条件
 */
export interface ResSearchQuery {
	/** クエリログのID。保存時は何が指定されていても無視される */
	query_log_id: number;
	/** クエリログの保存時刻のエポック秒。保存時は何が指定されていても無視される */
	query_saved_at: number;
	/** 店舗ID */
	shop_id: number;
	/** レイアウトID */
	layout_id: number;
	/** 開始日。yyyy-MM-dd形式 */
	start_date: string;
	/** 終了日。yyyy-MM-dd形式 */
	end_date: string;
	/** 開始時刻。2要素の配列は、それぞれ時刻と分 */
	start_time: [number, number];
	/** 開始時刻。2要素の配列は、それぞれ時刻と分 */
	end_time: [number, number];
	/** 対象となる曜日 */
	target_weekdays: { 
		"Mon": boolean,
		"Tue": boolean,
		"Wed": boolean,
		"Thu": boolean,
		"Fri": boolean,
		"Sat": boolean,
		"Sun": boolean,
	};
	/** 購買・非購買の絞り込みフラグ */
	target_customer: TargetCustomer;
	/** 店員除外であれば真 */
	remove_clerk: boolean;
	/** 滞在時間しきい値 */
	stay_threshold: number;
}

/**
 * 動線顧客IDリストの並べ替え項目
 */
export const SortItemType = {
    HumanId: "humanId",
    EnterShopTime: "enterShopTime",
    ExitShopTime: "exitShopTime",
    StayShopTime: "stayShopTime",
    AreaStayTimeMax: "areaStayTimeMax",
    NumberOfStayArea: "enteredArea",
    BuyOrNot: "buying",
} as const;
export type SortItems = typeof SortItemType[keyof typeof SortItemType];

/**
 * 並べ替えの昇順と降順
 */
export const SortOrderType = {
    Asc: "asc",
    Desc: "desc",
} as const;
export type SortOrders = typeof SortOrderType[keyof typeof SortOrderType];

/**
 * 動線顧客IDリストの絞り込み検索条件とページ指定
 */
export type SearchSortOption = {
    from: number
    size: number
    sortItem: SortItems
    sortOrder: SortOrders
    enterShopTimeFrom?: number // エポックミリ秒。指定なし=分析条件と同じ、指定あり=指定時間以降を抽出
    enterShopTimeTo?: number // エポックミリ秒。指定なし=分析条件と同じ、指定あり=指定時間以前を抽出
    stayShopTimeFrom?: number // エポックミリ秒。指定なし=分析条件と同じ、指定あり=指定時間以上を抽出
    stayShopTimeTo?: number // エポックミリ秒。指定なし=分析条件と同じ、指定あり=指定時間以下を抽出
    enteredAreaList?: number[]  // 指定なし=全て、指定あり=指定エリアを抽出
}

export const DefaultSearchSortOption: SearchSortOption = {
    from: 0,
    size: PAGING_SIZE,
    sortItem: SortItemType.HumanId,
    sortOrder: SortOrderType.Desc,
}

