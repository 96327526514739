import React, { useMemo, useCallback } from "react"

import { Button, StyleType } from "../../component/button/Button"
import { AnalyzeView, AnalyzeViewType } from "../../types/Analyze"
import { ButtonControl, ButtonControlType, ButtonsModeType } from "./AnalyzeButtons"
import { ConditionControl, ConditionControlType } from "./Funnel"

import style from "./FunnelButtons.module.css"

const ButtonItemType = {
    start: "start",
    search: "search",
    reset: "reset"
} as const
type ButtonItem = typeof ButtonItemType[keyof typeof ButtonItemType]

type AllItems = {
    start: ButtonControl,
    search: ButtonControl,
    reset: ButtonControl
}
type ConditionPatternType = Record<ConditionControl, AllItems>

/**
 *  画面の状態遷移とボタン表示の関係
 */
const CaseOfItems: ConditionPatternType = {
    "fresh": { start: ButtonControlType.Disabled, search: ButtonControlType.None, reset: ButtonControlType.None },
    "ready": { start: ButtonControlType.Active, search: ButtonControlType.None, reset: ButtonControlType.None },
    "running": { start: ButtonControlType.Restriction, search: ButtonControlType.None, reset: ButtonControlType.None },
    "done": { start: ButtonControlType.None, search: ButtonControlType.Disabled, reset: ButtonControlType.Active },
    "changed":{ start: ButtonControlType.None, search: ButtonControlType.Active, reset: ButtonControlType.Active },
    "miss": { start: ButtonControlType.None, search: ButtonControlType.Disabled, reset: ButtonControlType.Active },
    "searching": { start: ButtonControlType.None, search: ButtonControlType.Restriction, reset: ButtonControlType.Restriction }
}

interface Props {
    view: AnalyzeView
    condition: ConditionControl
    onStart: () => void
    onSearch: () => void
    onReset: () => void
}

export const FunnelButtons: React.FC<Props> = (props) => {

    const mode = useMemo(() => { return (props.condition === ConditionControlType.Fresh || props.condition === ConditionControlType.Ready || props.condition === ConditionControlType.Running) ? ButtonsModeType.Start : ButtonsModeType.Others }, [props.condition])

    const getBtnDisabled = useCallback((its: ButtonItem) => {
        const cur = CaseOfItems[props.condition]
        const ctrl = cur[its]
        return (ctrl === ButtonControlType.Disabled || ctrl === ButtonControlType.Restriction) ? true : false
    }, [props.condition])

    if (mode === ButtonsModeType.Start) {
        if (props.view === AnalyzeViewType.Single) {
            return (
                <div className={style.startBtn}>
                    <Button
                        onClick={props.onStart}
                        name="start"
                        label="startAnalysis"
                        styleType={StyleType.Normal}
                        disabled={getBtnDisabled(ButtonItemType.start)}
                    />
                    <div className={style.space}></div>
                    <div className={style.space}></div>
                </div>
            )
        } else {
            return (
                <div className={style.startBtn}>
                    <Button
                        onClick={props.onStart}
                        name="start"
                        label="startAnalysis"
                        styleType={StyleType.Normal}
                        disabled={getBtnDisabled(ButtonItemType.start)}
                    />
                </div>
            )
        }
    } else {
        if (props.view === AnalyzeViewType.Single) {
            return (
                <div className={style.updateBtn}>
                    <Button
                        onClick={props.onSearch}
                        name="search"
                        label="reSearch"
                        styleType={StyleType.Normal}
                        disabled={getBtnDisabled(ButtonItemType.search)}
                    />
                    <div className={style.space}></div>
                    <Button
                        onClick={props.onReset}
                        name="reset"
                        label="reset"
                        styleType={StyleType.Reverse}
                        disabled={getBtnDisabled(ButtonItemType.reset)}
                    />
                    <div className={style.dummy}></div>
                </div>
            )
        } else {
            return (
                <div className={style.updateBtn}>
                    <div className={style.centering}>
                        <Button
                            onClick={props.onSearch}
                            name="search"
                            label="reSearch"
                            styleType={StyleType.Normal}
                            disabled={getBtnDisabled(ButtonItemType.search)}
                        />
                        <div className={style.space}></div>
                        <Button
                            onClick={props.onReset}
                            name="reset"
                            label="reset"
                            styleType={StyleType.Reverse}
                            disabled={getBtnDisabled(ButtonItemType.reset)}
                        />
                    </div>
                </div>
            )
        }
    }
}