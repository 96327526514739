import { AreaType, AreaCountingType } from "../api/data/core/Enums";

// エリア枠の色
export const AREA_BORDER_COLOR = "rgb(255, 199, 0)"
export const CLERK_AREA_BORDER_COLOR = "rgb(0, 160, 0)"
export const REGISTER_AREA_BORDER_COLOR = "rgb(255, 100, 0)"
export const CHANGEING_AREA_BORDER_COLOR = "rgb(255, 0, 180)"

// エリアマップ
// バッジの色（滞在）
export const STAY_COLOR = "rgb(0, 123, 255)";
// バッジの色（通過）
export const NOT_STAY_COLOR = "rgb(103, 176, 255)";
// タイムライン用
export const STAY_COLOR_GR = "rgb(41, 203, 151)";
export const NOT_STAY_COLOR_GR = "rgb(126, 224, 192)";
export const LEGEND_COLORS = [
    [STAY_COLOR, NOT_STAY_COLOR],
    [STAY_COLOR_GR, NOT_STAY_COLOR_GR]
]

// 折れ線グラフ色
export const LINE_COLOR_BLUE = '#1E5EFF'
export const LINE_COLOR_GREEN = '#1FD286'


// ヒートマップカラーRGBA（１１色）
export const HEATMAP_COLORS = [
    "rgba(180, 180, 255, 0.8)",
    "rgba(180, 208, 255, 0.8)",
    "rgba(180, 238, 255, 0.8)",
    "rgba(180, 255, 239, 0.8)",
    "rgba(180, 255, 208, 0.8)",
    "rgba(180, 255, 180, 0.8)",
    "rgba(208, 255, 180, 0.8)",
    "rgba(238, 255, 180, 0.8)",
    "rgba(255, 238, 180, 0.8)",
    "rgba(255, 208, 180, 0.8)",
    "rgba(255, 180, 180, 0.8)",
]

// 弦グラフ用カラー
export const CHORD_COLORS = [
    "#45b08cff",
    "#ff69b4ff",
    "#e0b0ffff",
    "#89cff0ff",
    "#fdfd96ff",
    "#77dd77ff",
    "#eae86fff",
    "#9aceebff",
    "#e6e6faff",
    "#0476d0ff",
    "#ffa500ff",
    "#c0c2c9ff",
    "#f5f5dcff",
    "#0d98baff",
    "#e97451ff",
    "#ffff00ff",
    "#e32227ff",
    "#ace1afff",
    "#cc5500ff",
    "#7b3f00ff",
    "#d2691eff",
    "#9a463dff",
    "#8c92acff",
    "#555d50ff",
    "#00ffffff",
    "#ff7f50ff",
    "#32cd32ff",
    "#a49393ff",
    "#50c878ff",
    "#7df9ffff",
    "#36454fff",
    "#228b22ff",
    "#f6c324ff",
    "#6699ccff",
    "#00ff00ff",
    "#808080ff",
    "#ff00ffff",
    "#f0e68cff",
    "#4b0082ff",
    "#add8e6ff",
    "#c8a2c8ff",
    "#191970ff",
    "#429e9dff",
    "#3eb489ff",
    "#a0afb7ff",
    "#800000ff",
    "#f7e7ceff",
    "#000080ff",
    "#39ff14ff",
    "#e3bc9aff",
    "#f5f5f5ff",
    "#bab86cff",
    "#da70d6ff",
    "#808000ff",
    "#aec6cfff",
    "#ff6961ff",
    "#ffe5b4ff",
    "#e9eaecff",
    "#ccccffff",
    "#a020f0ff",
    "#ff5349ff",
    "#7d7098ff",
    "#cc8899ff",
    "#7fffd4ff",
    "#ffc0cbff",
    "#ff0000ff",
    "#b76e79ff",
    "#ffbf00ff",
    "#ff033eff",
    "#f7cac9ff",
    "#edaec0ff",
    "#ff007fff",
    "#bcb88aff",
    "#b2ac88ff",
    "#4169e1ff",
    "#c2b280ff",
    "#f7d6d0ff",
    "#ff2400ff",
    "#93e9beff",
    "#f28500ff",
    "#0000ffff",
    "#c0c0c0ff",
    "#d2b48cff",
    "#b3cee5ff",
    "#483c32ff",
    "#006d5bff",
    "#db8780ff",
    "#0abab5ff",
    "#008080ff",
    "#30d5c8ff",
    "#8f00ffff",
    "#9acd32ff",
    "#ffae42ff",
    "#362624ff",
    "#5a4fcfff",
    "#800020ff",
    "#dfff00ff",
    "#e34234ff",
    "#dcae96ff",
    "#fffdd0ff",
    "#964b00ff",
    "#00008bff",
    "#dc143cff",
    "#fa8072ff",
    "#b57170ff",
    "#8d918dff",
    "#0a0a0aff",
    "#fffff0ff",
    "#4cbb17ff",
    "#ffd700ff",
    "#c98d26ff",
    "#bacc81ff",
    "#ffca4bff"
]

export const getAreaBorderColor = (type: AreaType) => {
    if (type === AreaCountingType.Clerk) {
        return CLERK_AREA_BORDER_COLOR
    } else if (type === AreaCountingType.Casher) {
        return REGISTER_AREA_BORDER_COLOR
    } else if (type === AreaCountingType.ChangingRoom) {
        return CHANGEING_AREA_BORDER_COLOR
    } else {
        return AREA_BORDER_COLOR
    }
}

