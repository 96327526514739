import axios, { AxiosResponse } from "axios";
import { APIError } from "../APIError";
import { LoginAPI } from "../api_interface/login/login_api";
import { ILoginInfo, ResLoginInfo } from "../data/login/LoginInfo";
import { LoginInfo } from "./LoginInfoImpl";
import { EpochMsUtil } from "./edit/EpochMsUtil";


type passRes = {
    "password": string
}

export class LoginAPIImpl implements LoginAPI {
    endpoint: string

    constructor(endpoint?: string) {
        if (endpoint != null) {
            this.endpoint = endpoint
        } else if (process.env.REACT_APP_API_ENDPOINT != null) {
            this.endpoint = process.env.REACT_APP_API_ENDPOINT
        } else {
            throw new APIError("適当なAPIエンドポイントが設定されていない")
        }
    }

    async login(login_id: string, password: string): Promise<ILoginInfo> {
        const response: AxiosResponse<ResLoginInfo> = await axios.post(this.endpoint + "/login", {
            "username": login_id,
            "password": password,
        }, {
            "responseType": "json",
            "responseEncoding": "utf8",
            withCredentials: true,
        });
        if (response.status !== 200) {
            throw new APIError("ログインに失敗")
        }
        return new LoginInfo(EpochMsUtil.toMilliLoginInfo(response.data))
    }

    async logout(): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/logout", {
        }, {
            "responseType": "json",
            "responseEncoding": "utf8",
            withCredentials: true,
        });
        if (response.status !== 200) {
            throw new APIError("ログアウトに失敗")
        }
        return true
    }

    async requireResetPassword(login_id: string): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/request-password-reset", {
            "login_id": login_id,
        }, {
            "responseType": "json",
            "responseEncoding": "utf8",
            "validateStatus": (status) => ((status >= 200 && status < 300) || status === 404),
            withCredentials: true,
        });
        if (response.status === 404) {
            // ログインIDが存在しない場合
            return false
        }
        return true
    }

    async resetPassword(pass_reset_key: string): Promise<string> {
        const response: AxiosResponse<string> = await axios.post(this.endpoint + "/reset-password", {
            "reset_key": pass_reset_key,
        }, {
            "responseType": "json",
            "responseEncoding": "utf8",
            withCredentials: true,
        });
        if (response.status !== 200) {
            throw new APIError("リセットに失敗")
        }
        return response.data
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        const response: AxiosResponse<any> = await axios.post(this.endpoint + "/change-password", {
            "old_password": oldPassword,
            "new_password": newPassword,
        }, {
            "responseType": "json",
            "responseEncoding": "utf8",
            "validateStatus": (status) => ((status >= 200 && status < 300) || status === 406),
            withCredentials: true,
        });
        if (response.status === 406) {
            // 現在のパスワードが間違っている場合
            return false
        } else if (response.status !== 200) {
            throw new APIError("パスワード変更に失敗")
        }
        return true
    }
}