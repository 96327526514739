//import moment from 'moment'
import { subDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz"
import i18n from 'i18next'
import { RoleType } from "../api/data/core/Enums";

class Utils {
    static epochMilliToJtime(epochMilliSec) {
        //let t = moment.utc(epochMilliSec).utcOffset(540)
        //return t.format('YYYY-MM-DD HH:mm:ss')
        const utcDate = new Date(epochMilliSec)
        const jstDate = utcToZonedTime(utcDate, 'Asia/Tokyo')
        return format(jstDate, i18n.t("dateFormat.ymd_hy_hms"))
    }

    /**
     * ミリ秒を時間に整形する
     * @param {number} ミリ秒
     * @return {string} H時間M分s秒 という形式の文字列
     */
    static formatMillisecToTime(millisec) {
        let v = millisec;
        let nv = Math.floor(v / 1000);
        // const milli = v - nv * 1000;
        v = nv;
        nv = Math.floor(v / 60);
        const sec = v - nv * 60;
        v = nv;
        nv = Math.floor(v / 60);
        const min = v - nv * 60;
        const hour = nv;
        if (hour === 0 && min === 0 && sec === 0) {
            return '00:00:00'
        }
        let hourStr = (hour < 10 ? `0${hour}` : `${hour}`);
        let minStr = (min < 10 ? `0${min}` : `${min}`);
        let secStr = (sec < 10 ? `0${sec}` : `${sec}`);
        return `${hourStr}:${minStr}:${secStr}`
    }

    /**
     * ミリ秒を時間に整形する
     * @param {number} ミリ秒
     * @return {string} M分s秒 という形式の文字列
     */
    static formatMillisecToMMSS(millisec) {
        let sec = Math.floor(millisec / 1000);
        // const milli = v - nv * 1000;
        const min = Math.floor(sec / 60);
        sec = sec - min * 60;
        let result = '';
        if (min > 0) {
            result += i18n.t("minutes",{min: min})
        }
        if (sec < 10) {
            result += i18n.t("seconds",{sec: "0" + sec})
        } else {
            result += i18n.t("seconds",{sec: sec})
        }
        return result;
    }

    /**
     * ダッシュボート用　ミリ秒を「± 分：秒」に整形する
     * パラメーターが数値以外の時はそのまま戻す
     * @param {*} millisec 
     */
    static millisecToSignMMSS(millisec) {
        if (Number.isFinite(millisec)) {
            if (millisec === 0) {
                return "00:00"
            } else {
                let res = (millisec < 0) ? "-" : "+"
                let absVal = Math.abs(millisec)
                // ミリ秒を時、分、秒に
                let h = Math.floor(absVal / (60 * 60 * 1000))
                let a = absVal % (60 * 60 * 1000)
                let m = Math.floor(a / (60 * 1000))
                let t = a % (60 * 1000)
                let s = Math.floor(t / 1000)
                //let ms = t % 1000
                if (h > 0) {
                    res = res + ("00" + h).slice(-2) + ":"
                }
                res = res + ("00" + m).slice(-2) + ":" + ("00" + s).slice(-2)
                return res
            }
        } else {
            return millisec
        }
    }

    /**
     * 秒を日本語時間に整形する
     * @param {number} ミリ秒
     * @return {string} H時間M分s秒 という形式の文字列
     */
    static formatSecToJikan(sec) {
        let min = Math.round(Math.floor(sec / 60));
        const seconds = sec - min * 60;
        let hours = Math.round(Math.floor(min / 60));
        const minutes = min - hours * 60;
        if (hours === 0 && minutes === 0 && seconds === 0) {
            return i18n.t("seconds",{sec :0})
        }
        let result = '';
        if (hours > 0) {
            result += i18n.t("hours", {hour: hours})
        }
        if (minutes > 0) {
            result += i18n.t("minutes",{min: minutes})
        }
        if (seconds > 0) {
            result += i18n.t("seconds",{sec: seconds})
        }
        return result;
    }

    /**
     * @param {string} s hh:mm 形式の文字列
     * @return {number} hh * 100 + mm を返す
     */
    static parseTimeAsDummyTime(s) {
        const pattern = /^(\d+):(\d+)$/
        const m = s.match(pattern)
        if (m) {
            return (parseInt(m[1]) * 100 + parseInt(m[2]))
        }
        //let m = null;
        //if (m = (/^(\d+):(\d+)$/).exec(s)) {
        //    return (parseInt(m[1]) * 100 + parseInt(m[2]));
        //}
        return null;
    }

    /**
     * DummyTimeを"HH:mm"形式の時間表現に戻す。
     * @param {number} dummyTime hh*100+mm
     * @return {string} "hh:mm"形式
     */
    static decodeDummyTime2Time(dummyTime) {
        if (dummyTime!==undefined && dummyTime!==null && Number.isInteger(dummyTime)) {
            const h = Math.floor(dummyTime / 100)
            const m = dummyTime % 100
            const res = ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2)
            return res
        }
        return undefined
    }

    static comma(n) {
        let result = `${n}`;
        while (result.search(/(\d)(\d\d\d)(,|$)/) !== -1) {
            result = result.replace(/(\d)(\d{3})(,|$)/, "$1,$2$3")
        }
        return result
    }

    static sign(v) {
        if (v === 0) {
            return `±0`
        }
        if (v > 0) {
            return `+${v}`
        }
        return `${v}`
    }

    /**
     * ダッシュボード用 数字を符号付きで、カンマあり、小数点１桁までに整形します。
     * 数字以外の引数は、そのまま返却します。
     * ゼロのときは、"±0.0"
     * 
     * @param {*} num 
     * @returns 
     */
    static formatSignCommaPointOne(num) {
        if (Number.isFinite(num)) {
            if (num === 0) {
                return "0.0"
            } else {
                const sign = (num < 0) ? "-" : "+"
                if (Math.abs(num) < 0.05) {
                    return sign + "0.0"
                } else {
                    const absPt1 = Math.round(Math.abs(num) * 10) / 10
                    const numAry = ("" + absPt1).split(/\./)
                    const integ = Utils.comma(numAry[0])
                    let point
                    if (numAry.length === 2) {
                        point = "." + numAry[1]
                    } else {
                        point = ".0"
                    }
                    return sign + integ + point
                }
            }
        } else {
            return num
        }
    }

    /**
     * エリア別滞在者数用 数字をカンマあり、小数点１桁までに整形します。
     * 数字以外の引数は、そのまま返却します。
     * ゼロのときは、"0.0"
     * 
     * @param {*} num 
     * @returns 
     */
    static formatCommaPointOne(num) {
        if (Number.isFinite(num)) {
            if (num === 0) {
                return "0.0"
            } else {
                const pt1 = Math.round(num * 10) / 10
                const numAry = ("" + pt1).split(/\./)
                const integ = Utils.comma(numAry[0])
                let point
                if (numAry.length === 2) {
                    point = "." + numAry[1]
                } else {
                    point = ".0"
                }
                return integ + point
            }
        } else {
            return num
        }
    }

    /**
     * エリア別滞在者数用 数字を符号付き、カンマあり、整数に整形します。
     * 数字以外の引数は、そのまま返却します。
     * ゼロのときは、"±0"
     * 
     * @param {*} num 
     * @returns 
     */
    static formatSignCommaInteger(num) {
        if (Number.isFinite(num)) {
            if (num === 0) {
                return "±0"
            } else {
                const sign = (num < 0) ? "-" : "+"
                if (Math.abs(num) < 0.5) {
                    return sign + "0"
                } else {
                    const absVal = Math.round(Math.abs(num))
                    const integ = Utils.comma(absVal)
                    return sign + integ
                }
            }
        } else {
            return num
        }
    }

    static formatCommaInteger(num) {
        if (Number.isFinite(num)) {
            if (num === 0) {
                return "0"
            } else {
                const sign = (num < 0) ? "-" : ""
                if (Math.abs(num) < 0.5) {
                    return sign + "0"
                } else {
                    const absVal = Math.round(Math.abs(num))
                    const integ = Utils.comma(absVal)
                    return sign + integ
                }
            }
        } else {
            return num
        }
    }

    /**
     * 与えられたDateを月曜日を週の始めとして丸めた'yyyy-MM-dd'形式を取得します。
     * 
     * @param {Date} date 
     * @returns string 'yyyy-MM-dd'形式
     */
    static weekLabel(date) {
        const dayofweek = date.getDay()
        let delta = dayofweek - 1
        if (delta === -1) delta = 6
        const newDate = subDays(date, delta)
        const fmt = i18n.t("dateFormat.ymd_hy")
        return format(newDate, fmt)
    }

    /**
     * テキストが指定したバイト数を超えた場合に超過部分を三点リーダーにします
     * 
     * @param {*} text 
     * @param {*} length 
     * @returns 
     */
    static textEllipsis(text, length) {
        let len = 0
        for (let i = 0; i < text.length; i++) {
            (text[i].match(/[ -~]/)) ? len += 1 : len += 2
            if (len > length) {
                return text.slice(0, i) + "..."
            }
        }
        return text
    }

    static yesterday() {
        //return new Date(Date.now() - (24 * 60 * 60 * 1000) + (9 * 60 * 60 * 1000)).toISOString().substring(0, 10);
        const utcNow = new Date()
        const jstNow = utcToZonedTime(utcNow)
        return subDays(jstNow, 1)
    }

    static lastWeek() {
        const utcNow = new Date()
        const jstNow = utcToZonedTime(utcNow)
        return subDays(jstNow, 7)
    }

    static dayBeforeYestarday() {
        //return new Date(Date.now() - (2 * 24 * 60 * 60 * 1000) + (9 * 60 * 60 * 1000)).toISOString().substring(0, 10);
        const utcNow = new Date()
        const jstNow = utcToZonedTime(utcNow)
        return subDays(jstNow, 2)
    }

    static rippleOrder(num) {
        let result = []
        const mod = (num + 1) % 2
        const half = Math.floor((num + 1) / 2)
        const mae = [...Array(half).keys()].map(n => n++)
        const ato = [...Array(half).keys()].map(n => n++).map(n => n + half + mod)
        if (mod === 1) {
            result.push(half)
        }
        for (let i = 0; i < half; i++) {
            result.push(mae.pop())
            result.push(ato.shift())
        }
        return result
    }

    static isSameArray(a, b) {
        let result = true
        const len = a.length
        for (let i = 0; i < len; i++) {
            if (a[i] !== b[i]) {
                result = false
                break
            }
        }
        return result
    }

    /**
     * UserTypeをRoleTypeに変換します
     * @param {string} userType
     * @returns Role 
     */
    static userType2Role(userType) {
        switch (userType) {
            case "root":
                return RoleType.Root
            case "headoffice":
                return RoleType.HeadOffice
            case "areamanager":
                return RoleType.AreaManager
            case "storemanager":
                return RoleType.StoreManager
            case "juniormanager":
                return RoleType.JuniorManager
            case "staff":
                return RoleType.Staff
            //case "juniorstaff":
                //return RoleType.JuniorStaff
            case "developer":
                return RoleType.Developer
            default:
                throw new Error("userType2Role: unknown userType:" + userType)
        }
    }

    /**
     * ファイル名のチェック
     * 半角英数字、アンダースコア、ハイフン、ピリオドのみ許可
     * @param {*} fileName 
     * @returns 
     */
    static validFileName(fileName) {
        const pattern = /^[A-Za-z0-9_.-]+$/;
        return pattern.test(fileName)
    }

    /**
     * UUIDのチェック
     * 
     * @param {*} str 
     * @returns 
     */
    static hasUUID(str) {
        const pattern = /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;
        return pattern.test(str);
    }

    /**
     * UUID ver4 を生成する。
     * 
     * 参考：https://qiita.com/psn/items/d7ac5bdb5b5633bae165
     * 
     * @returns UUID: string
     */
    static async generateUuid() {
        // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
        // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++) {
            switch (chars[i]) {
                case "x":
                    chars[i] = Math.floor(Math.random() * 16).toString(16);
                    break;
                case "y":
                    chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                    break;
            }
        }
        return chars.join("");
    }

    /**
     * ファイル名から拡張子を取得します。
     * @param filename 
     * @returns 
     */
    static getFileExtension(filename) {
        return filename.split('.').pop()
    }

    /**
     * ファイル名から拡張子を除いた部分を取得します。
     * @param filename 
     * @returns 
     */
    static getFileNameNoExtension(filename) {
        if (filename.indexOf('/') > -1) {
            filename = filename.split('/').pop()
        }
        const parts = filename.split('.')
        parts.pop()
        return parts.join('.')
    }

    /**
     * ファイル名から拡張子とファイル名を分離したものを取得します。
     * @param filename 
     * @returns 
     */
    static separateFileNameAndExtension(filename) {
        if (filename.indexOf('/') > -1) {
            filename = filename.split('/').pop()
        }
        const parts = filename.split('.')
        const ext = parts.pop()
        const name = parts.join('.')
        return [name, ext]
    }

    /**
     * ファイル名からUUIDと拡張子を分離したものを取得します。
     * @param filename 
     * @returns 
     */
    static separateFileNameAndUUIDAndExtension(filename) {
        if (filename.indexOf('/') > -1) {
            filename = filename.split('/').pop()
        }
        const parts = filename.split('.')
        const ext = parts.pop()
        const uuid = parts.pop()
        const name = parts.join('.')
        return [name, uuid, ext]
    }

}

export default Utils;