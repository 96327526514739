import axios, { AxiosResponse } from "axios";

import { APIError } from "../../APIError";
import { TargetCustomerType, TimeUnit } from "../../data/core/Enums";
import { ResLayout } from "../../data/analysis/FullLayout";
import { ResSearchQuery, SearchSortOption, SortOrders, SortOrderType } from "../../data/analysis/AnalysisRequest";
import { OSResAreaCount, OSResAreastayTop100, OSResHuman, OSResHumanList, OSResTimelines } from "../AnalysisDataImpl";
import RemainTimeWatcher from "../RemainTimeWatcher";
import { CompareSide } from "../../../types/Analyze";
import { axiosOptions } from "../../data/core/Constants";
import { RANKING_TOP_100, SAMPLING_HALF_SIZE } from "../../data/core/Constants";
import { weekdaysType, WEEKDAYS } from "../../data/core/Enums";


export class OpenSearch {
    endpoint: string

    constructor(endpoint: string) {
        this.endpoint = endpoint
    }

    async get_layout(layout_id: number): Promise<ResLayout> {
        const layoutRes: AxiosResponse<ResLayout> = await axios.get(this.endpoint + `/layout/${layout_id}`, axiosOptions)
        if (layoutRes.status !== 200) {
            throw new APIError(`レイアウト取得エラー: ${layout_id}`)
        }
        layoutRes.data.start = layoutRes.data.start * 1000;
        return layoutRes.data;
    }

    static _date_to_epochms(dateStr: string) {
        const ms = Date.parse(`${dateStr}T00:00:00Z`)
        return ms - 9 * 60 * 60 * 1000
    }

    static _date_to_epochms_plusone(dateStr: string) {
        const ms = Date.parse(`${dateStr}T00:00:00Z`)
        return ms - 9 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
    }

    static _hhmm(hh_mm: number[]): number {
        if (hh_mm[1] < 10) {
            return parseInt(`${hh_mm[0]}0${hh_mm[1]}`)
        }
        return parseInt(`${hh_mm[0]}${hh_mm[1]}`)
    }

    /**
     * OpenSerchのクエリーを作成します。
     * 
     * @param query 
     * @returns 
     */
    async create_search_query(query: ResSearchQuery): Promise<any> {
        // クエリー作成（店舗ID、日付、時間の指定）
        let req: any = {
            "query": {
                "bool": {
                    "must": [
                        { "term": { "shopId": { "value": query.shop_id } } },
                        { "range": { "enterShopTime": {
                            "gte": OpenSearch._date_to_epochms(query.start_date),
                            "lt": OpenSearch._date_to_epochms_plusone(query.end_date) } } },
                        { "range": { "hourMinute": {
                            "gte": OpenSearch._hhmm(query.start_time),
                            "lt": OpenSearch._hhmm(query.end_time) } } },
                    ]
                }
            },
            "track_total_hits": true,
            "sort": [
                { "hash": { "order": "asc" } }
            ]
        };
        // 店員除外
        if (query.remove_clerk) {
            req.query.bool.must.push({"term": {"clerk": false}})
        }
        // 購買区分
        if (query.target_customer === TargetCustomerType.Buying) {
            req.query.bool.must.push({"term": {"buying": true}})
        } else if (query.target_customer === TargetCustomerType.NotBuying) {
            req.query.bool.must.push({"term": {"buying": false}})
        } else if (query.target_customer === TargetCustomerType.All) {
        }
        // 不要曜日除外
        const notTargetWeekdays: number[] = [];
        for (let i = 0; i < WEEKDAYS.length; i++) {
            const wd: weekdaysType = WEEKDAYS[i];
            if (!query.target_weekdays[wd]) {
                notTargetWeekdays.push(i)
            }
        }
        if (notTargetWeekdays.length > 0) {
            req.query.bool.must_not = notTargetWeekdays.map(v => (
                { "term": { "weekDay": { "value": v } } }
            ));
        }
        // エリア滞在時間しきい値を追加
        if (query.stay_threshold > 0) {
            req.query.bool.must.push({
                "nested": {
                    "path": "enteredAreaMax",
                    "query": {
                        "bool": {
                            "must": [
                                { "range": { "enteredAreaMax.stayTime": { "gte": query.stay_threshold * 1000 } } }
                            ]
                        }
                    }
                }
            })
        }
        return req
    }

    /**
     * 対象となる顧客数を取得します。
     * 
     * @param query 
     * @returns 
     */
    async get_number_of_customers(query: ResSearchQuery): Promise<number> {
        // クエリー作成
        const req = await this.create_search_query(query)
        // 顧客数取得
        const docSize = await axios.post(this.endpoint + `/opensearch/customers?size=0`, req, axiosOptions)
        if (docSize.status !== 200) {
            throw new APIError('顧客数取得エラー');
        }
        const docCount = docSize.data.hits.total.value
        console.log("▽docCount:", docCount)
        return docCount
    }

    /**
     * Optionで追加指定した条件で顧客情報リストを取得します。（動線顧客IDリスト用、Max 10,000件まで）
     * @param query 
     * @param option 
     * @returns 
     */
    async get_page_of_customers(query: ResSearchQuery, option: SearchSortOption): Promise<OSResHumanList> {
        // クエリー作成
        const req = await this.create_search_query(query)
        //　ページ指定
        req.from = option.from
        req.size = option.size
        // ソート順
        req.sort = [{ [option.sortItem]: { "order": option.sortOrder } }]
        // 入店時間指定
        if (option.enterShopTimeFrom && option.enterShopTimeTo) {
            req.query.bool.must[2].range.hourMinute.gte = option.enterShopTimeFrom
            req.query.bool.must[2].range.hourMinute.lt = option.enterShopTimeTo + 1
        }
        // 滞在時間指定
        if (option.stayShopTimeFrom && option.stayShopTimeTo) {
            req.query.bool.must.push({ "range": { "stayShopTime": { "gte": option.stayShopTimeFrom * 1000, "lt": (option.stayShopTimeTo * 1000 + 1000) } } })
        } else if (option.stayShopTimeFrom) {
            req.query.bool.must.push({ "range": { "stayShopTime": { "gte": option.stayShopTimeFrom * 1000 } } })
        } else if (option.stayShopTimeTo) {
            req.query.bool.must.push({ "range": { "stayShopTime": { "lt": (option.stayShopTimeTo * 1000 + 1000) } } })
        }
        // エリア指定
        if (option.enteredAreaList) {
            let subQuery: any = []
            for (const areaId of option.enteredAreaList) {
                subQuery.push({ "term": { "enteredArea.areaId": { "value": areaId } } })
            }
            req.query.bool.must.push({ "nested": { "path": "enteredArea", "query": { "bool": { "should": subQuery } } } })
        }
        // 顧客リスト取得
        console.log("▽get_page_of_customers req:", req)
        const result = await axios.post(this.endpoint + `/opensearch/customers`, req, axiosOptions)
        if (result.status !== 200) {
            throw new APIError('顧客取得エラー');
        }
        return result.data
    }

    /**
     * 動線分析用の顧客情報を取得します。（１万件以上取得用）
     * 
     * @param query 分析条件
     * @param numOfCustomers 顧客数
     * @param watch 時間計測用
     * @param side SingleSide or CompareSide
     * @returns 
     */
    async get_all_customers(query: ResSearchQuery, numOfCustomers: number, watch: RemainTimeWatcher, side: CompareSide): Promise<OSResHumanList> {
        let scrollId = null
        let count = 0
        let resData: OSResHumanList[] = []
        const req = await this.create_search_query(query)
        do {
            if (scrollId === null) {
                // Scroll開始
                console.log("▽scroll start:", req)
                const scrollRes: AxiosResponse<OSResHumanList> = await axios.post(
                    this.endpoint + `/opensearch-scroll/customers`, req, axiosOptions)
                console.log("▽scroll start res:", scrollRes.data)
                if (scrollRes.status !== 200) {
                    throw new APIError('顧客取得エラー');
                }
                scrollId = scrollRes.data._scroll_id
                const len = scrollRes.data.hits.hits.length
                count += len
                resData.push(scrollRes.data)
                console.log("▽scroll start count:", count)
                //watch.updateCount4Trail(len, side)
            } else {
                // Scroll継続
                console.log("▽scroll continue:", scrollId)
                const scrollRes: AxiosResponse<OSResHumanList> = await axios.post(
                    this.endpoint + `/opensearch-continue/${scrollId}`, { "scroll_id": scrollId }, axiosOptions)
                if (scrollRes.status !== 200) {
                    throw new APIError('顧客取得エラー');
                }
                scrollId = scrollRes.data._scroll_id
                const len = scrollRes.data.hits.hits.length
                count += len
                resData.push(scrollRes.data)
                console.log("▽scroll continue count:", count)
                //watch.updateCount4Trail(len, side)
            }
        } while (count < numOfCustomers)
        console.log("▽scroll end resData:", resData)
        return resData.reduce((prev, current) => {
            prev.hits.hits.push(...current.hits.hits)
            return prev
        })
    }

    /**
     * 動線の滞在時間TOP100を取得します。
     * @param query 
     * @returns 
     */
    async get_longstay_customers(query: ResSearchQuery, numOfCust: number): Promise<OSResHumanList> {
        const size = (numOfCust > 0 && numOfCust < RANKING_TOP_100) ? numOfCust : RANKING_TOP_100
        var req: any = {
            "query": {
                "bool": {
                    "must": [
                        { "term": { "shopId": { "value": query.shop_id } } },
                        { "range": { "enterShopTime": {
                            "gte": OpenSearch._date_to_epochms(query.start_date),
                            "lt": OpenSearch._date_to_epochms_plusone(query.end_date) } } },
                        { "range": { "hourMinute": {
                            "gte": OpenSearch._hhmm(query.start_time),
                            "lt": OpenSearch._hhmm(query.end_time) } } },
                    ]
                }
            },
            "track_total_hits": true,
            "size": size,
            "sort": [
                { "stayShopTime": { "order": "desc" } }
            ]
        };
        // 店員除外
        if (query.remove_clerk) {
            req.query.bool.must.push({"term": {"clerk": false}})
        }
        // 購買区分
        if (query.target_customer === TargetCustomerType.Buying) {
            req.query.bool.must.push({"term": {"buying": true}})
        } else if (query.target_customer === TargetCustomerType.NotBuying) {
            req.query.bool.must.push({"term": {"buying": false}})
        } else if (query.target_customer === TargetCustomerType.All) {
        }
        // 不要曜日除外
        const notTargetWeekdays: number[] = [];
        for (let i = 0; i < WEEKDAYS.length; i++) {
            const wd: weekdaysType = WEEKDAYS[i];
            if (!query.target_weekdays[wd]) {
                notTargetWeekdays.push(i)
            }
        }
        if (notTargetWeekdays.length > 0) {
            req.query.bool.must_not = notTargetWeekdays.map(v => (
                { "term": { "weekDay": { "value": v } } }
            ));
        }
        // リクエスト実行
        const customersRes: AxiosResponse<OSResHumanList> = await axios.post(
            this.endpoint + `/opensearch/customers`, req, axiosOptions)
        if (customersRes.status !== 200) {
            throw new APIError('長期滞在顧客取得エラー');
        }
        return customersRes.data;
    }

    /**
     * 動線のエリア滞在数TOP100を取得します。
     * @param query 
     * @returns 
     */
    async get_manystay_customers(query: ResSearchQuery, numOfCust: number): Promise<OSResHumanList> {
        const size = (numOfCust > 0 && numOfCust < RANKING_TOP_100) ? numOfCust : RANKING_TOP_100
        const manyStayReq: any = {
            "query": {
                "bool": {
                    "must": [
                        { "term": { "shopId": { "value": query.shop_id } } },
                        { "range": { "enterShopTime": {
                            "gte": OpenSearch._date_to_epochms(query.start_date),
                            "lt": OpenSearch._date_to_epochms_plusone(query.end_date) } } },
                        { "range": { "hourMinute": {
                            "gte": OpenSearch._hhmm(query.start_time),
                            "lt": OpenSearch._hhmm(query.end_time) } } },
                        { "range": { "stayTime": { "gte": query.stay_threshold * 1000 } } },
                    ]
                }
            },
            "track_total_hits": true,
            "aggs": {
                "count-areastay": {
                    "terms": {
                        "field": "humanId",
                        "size": size
                    }
                }
            }
        };
        // 店員除外
        if (query.remove_clerk) {
            manyStayReq.query.bool.must.push({"term": {"clerk": false}})
        }
        // 購買区分
        if (query.target_customer === TargetCustomerType.Buying) {
            manyStayReq.query.bool.must.push({"term": {"buying": true}})
        } else if (query.target_customer === TargetCustomerType.NotBuying) {
            manyStayReq.query.bool.must.push({"term": {"buying": false}})
        } else if (query.target_customer === TargetCustomerType.All) {
        }
        // 不要曜日除外
        const notTargetWeekdays: number[] = [];
        for (let i = 0; i < WEEKDAYS.length; i++) {
            const wd: weekdaysType = WEEKDAYS[i];
            if (!query.target_weekdays[wd]) {
                notTargetWeekdays.push(i)
            }
        }
        if (notTargetWeekdays.length > 0) {
            manyStayReq.query.bool.must_not = notTargetWeekdays.map(v => (
                { "term": { "weekDay": { "value": v } } }
            ));
        }
        // 滞在数が多い顧客のIDのみ取得
        const areastayRes: AxiosResponse<OSResAreastayTop100> = await axios.post(
            this.endpoint + `/opensearch/areastay`, manyStayReq, axiosOptions)
        if (areastayRes.status !== 200) {
            throw new APIError('滞在数が多い顧客の取得に失敗(areastay)')
        }
        if (areastayRes.data.aggregations) {
            const reqHumans: any = {
                "query": {
                    "bool": {
                        "must": [
                            { "term": { "shopId": { "value": query.shop_id } }, },
                            {
                                "bool": {
                                    "should": areastayRes.data.aggregations["count-areastay"].buckets.map(
                                        entry => ({ "term": { "humanId": { "value": entry.key } } }))
                                }
                            }
                        ]
                    }
                },
                "track_total_hits": true,
                "size": size,
            };
            const customerRes: AxiosResponse<OSResHumanList> = await axios.post(
                this.endpoint + `/opensearch/customers`, reqHumans, axiosOptions)
            if (customerRes.status !== 200) {
                throw new APIError('長期滞在顧客取得エラー');
            }
            return customerRes.data;
        } else {
            // 集計結果がない場合は空リストを返す
            return { "hits": { "total": { "value": 0 }, "hits": [] } }
        }
    }

    async get_area_count(query: ResSearchQuery, stay_threshold: number, layout: ResLayout, abCon: AbortController | undefined): Promise<OSResAreaCount> {
        const req: any = {
            "query": {
                "bool": {
                    "must": [
                        { "term": { "shopId": { "value": query.shop_id } } },
                        { "range": { "enterShopTime": {
                            "gte": OpenSearch._date_to_epochms(query.start_date),
                            "lt": OpenSearch._date_to_epochms_plusone(query.end_date) } } },
                        { "range": { "hourMinute": {
                            "gte": OpenSearch._hhmm(query.start_time),
                            "lt": OpenSearch._hhmm(query.end_time) } } }
                    ]
                }
            },
            "track_total_hits": true,
            "sort": [
                { "enterShopTime": { "order": "asc" } }
            ],
            "collapse": {
                "field": "humanId"
            },
            // エリアごとの集計
            "aggs": {}
        }
        // 集計部分を作成
        if (stay_threshold > 0) {
            for (const areaset of layout.area_set) {
                for (const area of areaset.area_defs) {
                    const key = `count-${area.area_id}`;
                    req.aggs[key] = {
                        "nested": { "path": "enteredAreaMax" },
                        "aggs": {
                            "pass": {
                                "filter": {
                                    "bool": {
                                        "must": [
                                            { "term": { "enteredAreaMax.areaId": area.area_id } },
                                            { "range": { "enteredAreaMax.stayTime": { "gte": stay_threshold * 1000 } } },
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            for (const areaset of layout.area_set) {
                for (const area of areaset.area_defs) {
                    const key = `count-${area.area_id}`;
                    req.aggs[key] = {
                        "nested": { "path": "enteredAreaMax" },
                        "aggs": {
                            "pass": {
                                "filter": {
                                    "bool": {
                                        "must": [
                                            { "term": { "enteredAreaMax.areaId": area.area_id } },
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 店員除外
        if (query.remove_clerk) {
            req.query.bool.must.push({"term": {"clerk": false}})
        }
        // 購買区分
        if (query.target_customer === TargetCustomerType.Buying) {
            req.query.bool.must.push({"term": {"buying": true}})
        } else if (query.target_customer === TargetCustomerType.NotBuying) {
            req.query.bool.must.push({"term": {"buying": false}})
        } else if (query.target_customer === TargetCustomerType.All) {
        }
        // 不要曜日除外
        const notTargetWeekdays: number[] = [];
        for (let i = 0; i < WEEKDAYS.length; i++) {
            const wd: weekdaysType = WEEKDAYS[i];
            if (!query.target_weekdays[wd]) {
                notTargetWeekdays.push(i)
            }
        }
        if (notTargetWeekdays.length > 0) {
            req.query.bool.must_not = notTargetWeekdays.map(v => (
                { "term": { "weekDay": { "value": v } } }
            ));
        }
        const options = abCon ? { signal: abCon.signal, ...axiosOptions } : axiosOptions
        const areaCountRes: AxiosResponse<OSResAreaCount> = await axios.post(
            this.endpoint + `/opensearch/customers`, req, options)
        if (areaCountRes.status !== 200) {
            throw new APIError('滞在数が多い顧客の取得に失敗(areastay)')
        }
        return areaCountRes.data
    }

    async get_timeline(query: ResSearchQuery, stay_threshold: number, calc_unit: TimeUnit, date_format: string, areaID: number | null): Promise<OSResTimelines> {
        const req: any = {
            "query": {
                "bool": {
                    "must": [
                        { "term": { "shopId": { "value": query.shop_id } } },
                        { "range": { "enterShopTime": {
                            "gte": OpenSearch._date_to_epochms(query.start_date),
                            "lt": OpenSearch._date_to_epochms_plusone(query.end_date) } } },
                        { "range": { "hourMinute": {
                            "gte": OpenSearch._hhmm(query.start_time),
                            "lt": OpenSearch._hhmm(query.end_time) } } },
                    ],
                }
            },
            "track_total_hits": true,
            "sort": [
                { "enterShopTime": { "order": "asc" } }
            ],
            "collapse": {
                "field": "humanId"
            },
            "aggs": {
                "timeline-histgram": {
                    "date_histogram": {
                        "field": "enterShopTime",
                        "calendar_interval": calc_unit,
                        "format": date_format,
                        "time_zone": "+0900"
                    }
                }
            }
        };
        // 不要曜日除外
        const notTargetWeekdays: number[] = [];
        for (let i = 0; i < WEEKDAYS.length; i++) {
            const wd: weekdaysType = WEEKDAYS[i];
            if (!query.target_weekdays[wd]) {
                notTargetWeekdays.push(i)
            }
        }
        if (notTargetWeekdays.length > 0) {
            req.query.bool.must_not = notTargetWeekdays.map(v => (
                { "term": { "weekDay": { "value": v } } }
            ));
        }
        if (areaID != null) {
            req.query.bool.must.push(
                {
                    "nested": {
                        "path": "enteredAreaMax",
                        "query": {
                            "bool": {
                                "must": [
                                    { "term": { "enteredAreaMax.areaId": { "value": areaID } } },
                                    { "range": { "enteredAreaMax.stayTime": { "gte": stay_threshold * 1000 } } }
                                ]
                            }
                        }
                    }
                }
            )
        }
        console.log("▽timeline req:", req)
        const timelineRes: AxiosResponse<OSResTimelines> = await axios.post(
            this.endpoint + `/opensearch/customers`, req, axiosOptions)
        if (timelineRes.status !== 200) {
            throw new APIError('滞在数が多い顧客の取得に失敗(areastay)')
        }
        return timelineRes.data
    }

    /**
     * Hash値を利用し顧客情報をサンプリング取得します。（弦グラフ用）
     * 
     * @param query 
     * @param sortOrder 
     * @param size
     * @returns 
     */
    async get_sample_customers(query: ResSearchQuery, sortOrder: SortOrders, size: number = SAMPLING_HALF_SIZE): Promise<OSResHuman[]> {
        var req: any = await this.create_search_query(query)
        // ソート順
        if (sortOrder === SortOrderType.Desc) {
            req.sort[0].hash.order = "desc"
        }
        // データサイズ
        req.size = size
        // リクエスト実行
        console.log("▽get_sample_customers req:", req)
        const customersRes: AxiosResponse<OSResHumanList> = await axios.post(this.endpoint + `/opensearch/customers`, req, axiosOptions)        
        if (customersRes.status !== 200) {
            throw new APIError('長期滞在顧客取得エラー')
        }
        return customersRes.data.hits.hits.map(h => h._source)
    }
}
