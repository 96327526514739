import React, { useCallback, useMemo, useEffect } from "react"
import { PencilIcon } from "@primer/octicons-react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { EditModeType, UrlParameterType } from "../../types/Management"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { ListFrame } from "../common/ListFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import DateUtil from "../../lib/DateUtil"

import style from "./CompanyList.module.css"

// この画面のアクセスコントロール
const myPermission = PermissionTypes.ManageCompany_View

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props { }

export const CompanyList: React.FC<Props> = (props) => {

	const { t } = useTranslation() 
    const navigate = useNavigate()
    const location = useLocation()

    const { companyList, initManagementData } = useManagementDataContext()

    const { canCreate4Company, ableToUpdate4Company } = useAccessControlContext()

    /**
     * 代表的なタイムゾーン値（i18nのロケールから求めている）
     * 例）jaなら540、enなら0
     *
    const typicalTimeZoneMinute = useMemo(() => {
        const tz = t("typicalTimeZoneMinute")
        return parseInt(tz)
    }, [t])*/

    /**
     * 新規登録ボタン押下時の処理
     */
    const handleCreateCompany = useCallback(() => {
        if (canCreate4Company) {
            navigate("/company_edit?" + UrlParameterType.Mode + "=" + EditModeType.New,
                { state: { Mode: EditModeType.New, from: location } })
        }
    }, [navigate, canCreate4Company, location])

    /**
     * 企業編集画面を開きます。
     */
    const handleOpenDetail = useCallback((event: React.MouseEvent, companyId: number) => {
        event.preventDefault()
        // 編集権限をチェック
        const acl = ableToUpdate4Company(companyId)
        const mode = acl ? EditModeType.Edit : EditModeType.View
        navigate("/company_edit?" + UrlParameterType.Mode + "=" + mode + "&" + UrlParameterType.CompanyId + "=" + companyId,
            { state: { Mode: mode, CompanyId: companyId, from: location } })
    }, [navigate, ableToUpdate4Company, location])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        const target = event.currentTarget
        target.classList.add(style.rowOver)
    }, [])
    
    const handleRowOut = useCallback((event: React.MouseEvent) => {
        const target = event.currentTarget
        target.classList.remove(style.rowOver)
    }, [])

    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    const dataRows: React.ReactNode = useMemo(() => {
        const result: any = []
        companyList.forEach((company, idx) => {
            const itm = (
                <tr key={idx} onMouseOver={handleRowOver} onMouseOut={handleRowOut} onClick={e => handleOpenDetail(e, company.company_id)}>
                    <td className={style.tdId}>{company.company_id}</td>
                    <td className={style.tdName}>{company.name}</td>
                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(company.created_at, 0)}</td>
                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(company.modified_at, 0)}</td>
                    <td className={style.tdLink}>
                        <div className={style.icon}><PencilIcon size={16} /></div>
                    </td>
                </tr>                
            )
            result.push(itm)
        })
        return result
    }, [companyList, handleOpenDetail, handleRowOver, handleRowOut])

    return (
        <ListFrame
            page={PageName.InfoCom}
            title={t("header.companyRegistrationEdit")}
            newEntry={canCreate4Company}
            copyEntry={false}
            onClickNew={handleCreateCompany}
            shopSelect={false}
            companySelect={false}
            permission={myPermission}
        >
            {isProduction ? (<TrackingPageView page_title="bsinfo-company-list" />) : (null)}
            <table className={style.table}>
                <thead>
                    <tr>
                        <th className={style.th_id}>{t("companyId")}</th>
                        <th className={style.th_name}>{t("companyName")}</th>
                        <th className={style.th_date}>{t("registrationDate")}</th>
                        <th className={style.th_date}>{t("modifiedDate")}</th>
                        <th className={style.th_link}></th>
                    </tr>
                </thead>
                <tbody>
                    {dataRows}
                </tbody>
            </table>
        </ListFrame>
    )
}