import React, { useEffect, useState } from "react"
import { PermissionTypes, Permissions } from "../types/Permissions"
import { useAuthUserContext } from "./AuthUser"
import { useManagementDataContext } from "./ManagementData"
import { ShopModel } from "../types/Management"

export type AccessControlContextType = {
    // ManagementDataProvider から取得するcompanyId, shopIdを利用するもの
    isMyCompany: boolean    // 対象が自分の所属する企業と同じかどうか
    isMyShop: boolean       // 対象が自分の所属する店舗と同じかどうか
    ableToCreate: (permission: Permissions) => boolean
    ableToRead: (permission: Permissions) => boolean
    ableToUpdate: (permission: Permissions) => boolean
    ableToDelete: (permission: Permissions) => boolean
    
    // 企業管理画面で利用するもの
    canCreate4Company: boolean
    ableToUpdate4Company: (targetCompanyId: number) => boolean
    ableToDelete4Company: (targetCompanyId: number) => boolean

    // 店舗管理画面で利用するもの
    ableToCreate4Shop: () => boolean
    ableToRead4Shop: (targetShopId: number) => boolean
    ableToUpdate4Shop: (targetShopId: number) => boolean
    ableToDelete4Shop: (targetShopId: number) => boolean
    accessableShops: (permission: Permissions) => ShopModel[]   // shopListを指定された権限のある店舗のみに絞り込んだもの

    // アカウント管理画面で利用するもの
    isSameShop: (targetUserId: number) => boolean
    ableToCreate4User: () => boolean
    ableToRead4User: (targetUserId: number) => boolean
    ableToUpdate4User: (targetUserId: number) => boolean
    ableToDelete4User: (targetUserId: number) => boolean
    canDelete4User: boolean
}
const AccessControlContext = React.createContext<AccessControlContextType>({} as AccessControlContextType)

export const useAccessControlContext = (): AccessControlContextType => {
    return React.useContext<AccessControlContextType>(AccessControlContext)
}

type Props = {
    children: React.ReactNode
}

/**
 * ユーザーのアクセス権限を管理するためのプロバイダー
 */
export const AccessControlProvider = ({ children }: Props) => {

    const { userInfo, ownAccessCheck, otherAccessCheck, unlimitedAccessCheck } = useAuthUserContext()
    
    const { companyId, shopId, userList, shopList } = useManagementDataContext()

    const [isMyCompany, setIsMyCompany] = useState<boolean>(false)    
    const [isMyShop, setIsMyShop] = useState<boolean>(false)
    const [canCreate4Company, setCanCreate4Company] = useState<boolean>(false)
    const [canDelete4User, setCanDelete4Company] = useState<boolean>(false)

    // isMyCompany の更新
    useEffect(() => {
        let res = false
        if (userInfo && companyId) {
            res = userInfo.company.company_id === companyId
        }
        setIsMyCompany(res)
    }, [userInfo, companyId])

    // isMyShop の更新
    useEffect(() => {
        let res = false
        if (userInfo && userList && shopId) {
            const me = userList.find(el => el.user_id === userInfo.user.user_id)
            if (me && me.bookmarks && me.bookmarks.length > 0) {
                res = me.bookmarks.includes(shopId)
            }
        }
        setIsMyShop(res)        
    }, [userInfo, userList, shopId])

    const ableToRead = (permission: Permissions): boolean => {
        if (isMyCompany) {
            return isMyShop ? ownAccessCheck(permission) : otherAccessCheck(permission)
        } else {
            return unlimitedAccessCheck(permission)
        }
    }

    const ableToUpdate = (permission: Permissions): boolean => {
        if (isMyCompany) {
            return isMyShop ? ownAccessCheck(permission) : otherAccessCheck(permission)
        } else {
            return unlimitedAccessCheck(permission)
        }
    }

    const ableToCreate = (permission: Permissions): boolean => {
        if (isMyCompany) {
            return isMyShop ? ownAccessCheck(permission) : otherAccessCheck(permission)
        } else {
            return unlimitedAccessCheck(permission)
        }
    }

    const ableToDelete = (permission: Permissions): boolean => {
        if (isMyCompany) {
            return isMyShop ? ownAccessCheck(permission) : otherAccessCheck(permission)
        } else {
            return unlimitedAccessCheck(permission)
        }
    }

    // canCreate4Company の更新
    useEffect(() => {
        // 新規の場合、自分の所属していない企業しかない。
        const res = unlimitedAccessCheck(PermissionTypes.ManageCompany_New)
        setCanCreate4Company(res)
    }, [unlimitedAccessCheck])

    /**
     * 指定された企業の編集権限があるかどうかを返します。
     * @param permission 
     * @param targetCompanyId 
     * @returns 
     */
    const ableToUpdate4Company = (targetCompanyId: number): boolean => {
        if (userInfo) {
            const myCompanyId = userInfo.company.company_id
            return (myCompanyId === targetCompanyId) ? ownAccessCheck(PermissionTypes.ManageCompany_Edit) : unlimitedAccessCheck(PermissionTypes.ManageCompany_Edit)
        }
        return false
    }

    /**
     * 指定された企業の削除権限があるかどうかを返します。
     * @param permission 
     * @param targetCompanyId 
     * @returns 
     */
    const ableToDelete4Company = (targetCompanyId: number): boolean => {
        if (userInfo) {
            const myCompanyId = userInfo.company.company_id
            return (myCompanyId === targetCompanyId) ? ownAccessCheck(PermissionTypes.ManageCompany_Delete) : unlimitedAccessCheck(PermissionTypes.ManageCompany_Delete)
        }
        return false
    }

    /**
     * 指定された店舗が自分の所属する店舗かどうかを返します。
     * @param permission 
     * @returns 
     */
    const isMyShop4Shop = (targetShopId: number): boolean => {
        if (userInfo && userList && targetShopId) {
            const me = userList.find(el => el.user_id === userInfo.user.user_id)
            if (me && me.bookmarks && me.bookmarks.length > 0) {
                return me.bookmarks.includes(targetShopId)
            }
        }
        return false
    }

    const ableToCreate4Shop = (): boolean => {
        if (isMyCompany) {
            // 新規の場合は、自分の所属しない店舗しかない
            return otherAccessCheck(PermissionTypes.ManageShop_New)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageShop_New)
        }
    }

    const ableToRead4Shop = (targetShopId: number): boolean => {
        if (isMyCompany) {
            return isMyShop4Shop(targetShopId) ? ownAccessCheck(PermissionTypes.ManageShop_View) : otherAccessCheck(PermissionTypes.ManageShop_View)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageShop_View)
        }
    }

    const ableToUpdate4Shop = (targetShopId: number): boolean => {
        if (isMyCompany) {
            return isMyShop4Shop(targetShopId) ? ownAccessCheck(PermissionTypes.ManageShop_Edit) : otherAccessCheck(PermissionTypes.ManageShop_Edit)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageShop_Edit)
        }
    }

    const ableToDelete4Shop = (targetShopId: number): boolean => {
        if (isMyCompany) {
            return isMyShop4Shop(targetShopId) ? ownAccessCheck(PermissionTypes.ManageShop_Delete) : otherAccessCheck(PermissionTypes.ManageShop_Delete)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageShop_Delete)
        }
    }

    /**
     * 指定された権限のある店舗のみに絞り込んだshopListを返します。
     * @param permission 
     * @returns 
     */
    const accessableShops = (permission: Permissions): ShopModel[] => {
        if (shopList && userInfo && userList) {
            if (isMyCompany) {
                if (otherAccessCheck(permission)) {
                    // 他店アクセス可能なら全て許可
                    return shopList
                } else {
                    // bookmarkの店舗のみ許可
                    const me = userList.find(el => el.user_id === userInfo.user.user_id)
                    if (me && me.bookmarks && me.bookmarks.length > 0) {
                        return shopList.filter(el => me.bookmarks.includes(el.shop_id))
                    }
                }
            } else {
                // 他社アクセス可能なら全て許可
                if (unlimitedAccessCheck(permission)) return shopList
            }
        }
        return []
    }

    /**
     * 同じ店舗に所属しているかどうかを返します。ブックマークは配列なので、積集合を取る必要があります。
     * @param targetUserId 
     * @returns 
     */
    const isSameShop = (targetUserId: number): boolean => {
        if (userList && userInfo && targetUserId) {
            // 自分の所属店舗を取得
            const me = userList.find(el => el.user_id === userInfo.user.user_id)
            if (me && me.bookmarks && me.bookmarks.length > 0) {
                // 相手の所属店舗を取得
                const target = userList.find(el => el.user_id === targetUserId)
                if (target && target.bookmarks && target.bookmarks.length > 0) {
                    // 自分の所属店舗と相手の所属店舗の積集合があるかどうか
                    for (let myShopId of me.bookmarks) {
                        if (target.bookmarks.includes(myShopId)) return true
                    }
                }
            }
        }
        return false
    }

    const ableToCreate4User = (): boolean => {
        if (isMyCompany) {
            return isSameShop(0) ? ownAccessCheck(PermissionTypes.ManageAccount_New) : otherAccessCheck(PermissionTypes.ManageAccount_New)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageAccount_New)
        }
    }

    const ableToRead4User = (targetUserId: number): boolean => {
        if (isMyCompany) {
            return isSameShop(targetUserId) ? ownAccessCheck(PermissionTypes.ManageAccount_View) : otherAccessCheck(PermissionTypes.ManageAccount_View)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageAccount_View)
        }
    }

    const ableToUpdate4User = (targetUserId: number): boolean => {
        if (isMyCompany) {
            return isSameShop(targetUserId) ? ownAccessCheck(PermissionTypes.ManageAccount_Edit) : otherAccessCheck(PermissionTypes.ManageAccount_Edit)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageAccount_Edit)
        }
    }

    const ableToDelete4User = (targetUserId: number): boolean => {
        if (isMyCompany) {
            return isSameShop(targetUserId) ? ownAccessCheck(PermissionTypes.ManageAccount_Delete) : otherAccessCheck(PermissionTypes.ManageAccount_Delete)
        } else {
            return unlimitedAccessCheck(PermissionTypes.ManageAccount_Delete)
        }
    }

    // canDelete4User の更新
    useEffect(() => {
        let res = false
        if (isMyCompany) {
            // 自社の場合は、自分の所属店舗に対する権限があるかどうかで判断
            res = ownAccessCheck(PermissionTypes.ManageAccount_Delete)
        } else {
            res = unlimitedAccessCheck(PermissionTypes.ManageAccount_Delete)
        }        
        setCanDelete4Company(res)
    }, [isMyCompany, ownAccessCheck, unlimitedAccessCheck])

    return (
        <AccessControlContext.Provider
            value={{
                // 共通
                isMyCompany,
                isMyShop,
                ableToRead,
                ableToUpdate,
                ableToCreate,
                ableToDelete,
                // 企業
                canCreate4Company,
                ableToUpdate4Company,
                ableToDelete4Company,
                // 店舗
                ableToCreate4Shop,
                ableToRead4Shop,
                ableToUpdate4Shop,
                ableToDelete4Shop,
                accessableShops,
                // アカウント
                isSameShop,
                ableToCreate4User,
                ableToRead4User,
                ableToUpdate4User,
                ableToDelete4User,
                canDelete4User
            }}
        >
            {children}
        </AccessControlContext.Provider>
    )
}