import { ChevronRightIcon, ChevronDownIcon } from "@primer/octicons-react"
import React, { useState } from "react"
import useCollapse from "react-collapsed"
import { useTranslation } from "react-i18next"

import { ResArea } from "../../api/data/analysis/FullLayout"
import { HBarWidthType, HorizontalBarGraph, HorizontalBarType } from "../../component/horizontal_bar_graph/HorizontalBarGraph"
import Utils from "../../lib/Utils"
import { useAuthUserContext } from "../../providers"
import { AnalyzeView, CompareSide, CompareSideType } from "../../types/Analyze"
import { FunnelDataType, FunnelParamSetType } from "./Funnel"

import style from "./FunnelResult.module.css"

const NUM_OF_RANKING = 9

interface CvrProps {
    data: FunnelDataType | undefined
    resWasCompare?: boolean    
}

export const FunnelCvr: React.FC<CvrProps> = (props) => {

	const { t } = useTranslation()
    const isSingle: boolean = (props.resWasCompare && props.resWasCompare === true) ? false : true
    
    const visi = props.data?.visitor
    if (visi) {
        // 実数
        const pass = props.data?.pass?.reduce((a, b) => { return a + b }, 0)
        const stay = props.data?.stay?.reduce((a, b) => { return a + b }, 0)
        const buy1st = props.data?.buyer1?.reduce((a, b) => { return a + b }, 0)
        const buyOth = props.data?.buyerOther?.reduce((a, b) => { return a + b }, 0)
        const buyTtl = (buy1st ? buy1st : 0) + (buyOth ? buyOth : 0)
        const not1st = props.data?.notBuy1?.reduce((a, b) => { return a + b }, 0)
        const notOth = props.data?.notBuyOther?.reduce((a, b) => { return a + b }, 0)
        const notTtl = (not1st ? not1st : 0) + (notOth ? notOth : 0)
        // 累計CVR
        const visiRt = 100
        const passRt = (pass) ? pass / visi * 100 : 0
        const stayRt = (stay) ? stay / visi * 100 : 0
        const buyRt = buyTtl / visi * 100
        const notRt = notTtl / visi * 100
        const buy1stRt = (buy1st) ? buy1st / visi * 100 : 0
        const buyOthRt = (buyOth) ? buyOth / visi * 100 : 0
        const not1stRt = (not1st) ? not1st / visi * 100 : 0
        const notOthRt = (notOth) ? notOth / visi * 100 : 0
        // 次項CVR
        const visiNx = (visiRt) ? passRt / visiRt * 100 : 0
        const passNx = (passRt) ? stayRt / passRt * 100 : 0
        const stayNx = (stayRt) ? buyRt / stayRt * 100 : 0
        const buy1stNx = (buyRt) ? buy1stRt / buyRt * 100 : 0
        const buyOthNx = (buyRt) ? buyOthRt / buyRt * 100 : 0
        const not1stNx = (notRt) ? not1stRt / notRt * 100 : 0
        const notOthNx = (notRt) ? notOthRt / notRt * 100 : 0

        return (
            <div>
                <div className={style.title}>{t("header.funnel",{target: "CVR"})}</div>
                <div className={isSingle ? style.tablePane : style.tablePaneW}>
                    <table className={isSingle ? style.table : style.tableW}>
                        <thead>
                            <tr>
                                <th className={isSingle ? style.thS : style.thW_1}>{t("stepName")}</th>
                                <th className={isSingle ? style.thS : style.thW}>{t("cumulativeTotal")}CVR</th>
                                <th className={isSingle ? style.thS : style.thW}>{t("nextItem")}CVR</th>
                                <th className={isSingle ? style.thS : style.thW_2}>{isSingle ? "" : t("persons") }</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>{t("visitors")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(visiRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(visiNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={Math.round(visi)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>{t(isSingle ? "passingFrontArea" : "passingArea")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(passRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(passNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={(pass ? Math.round(pass) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>{t(isSingle ? "stayingFrontArea": "stayingArea")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(stayRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(stayNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={(stay ? Math.round(stay) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>{t("productsPurchasers")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(buyRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}></span>-</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={Math.round(buyTtl)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>　　{t(isSingle ? "stopPurchaseFirstTime" : "stayOnlyFirstTime")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(buy1stRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(buy1stNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={(buy1st ? Math.round(buy1st) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>　　{t(isSingle ? "stopPurchaseAgain" : "stayMultiple")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(buyOthRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(buyOthNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelBlue}
                                        value={(buyOth ? Math.round(buyOth) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>{t("productsNonPurchasers")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(notRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}></span>-</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelRed}
                                        value={Math.round(notTtl)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>　　{t(isSingle ? "stopByAgain" : "stayOnlyFirstTime")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(not1stRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(not1stNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelRed}
                                        value={(not1st ? Math.round(not1st) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={isSingle ? style.tdLeft : style.tdLeftW}>　　{t(isSingle ? "notStopByAgain" : "stayMultiple")}</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(notOthRt)}</span>%</td>
                                <td className={isSingle ? style.tdRight : style.tdRightW}><span className={isSingle ? style.bold : style.regular}>{Utils.formatCommaPointOne(notOthNx)}</span>%</td>
                                <td className={isSingle ? style.tdGraph : style.tdGraphW}>
                                    <HorizontalBarGraph
                                        type={HorizontalBarType.FunnelRed}
                                        value={(notOth ? Math.round(notOth) : 0)}
                                        maxValue={Math.round(visi)}
                                        widthType={isSingle ? HBarWidthType.Normal : HBarWidthType.Narrow}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={style.title}>{t("header.funnel",{target: "CVR"})}</div>
            <div className={style.noData}>
                <div className={style.message}>{t("msgNoFound")}</div>
            </div>
        </div>        
    )
}

const CollapseBorderType = {
    Top: "top",
    Mid: "mid",
    Bottom: "bot"
} as const 
type CollapseBorder = typeof CollapseBorderType[keyof typeof CollapseBorderType]

interface CollapseProps {
    isExpaned: boolean
    isSingle: boolean
    name: string
    data: number[] | undefined
    areaIds: number[] | undefined
    areaList: ResArea[] | undefined | null
    borderType: CollapseBorder
    onClick: () => void
}

export const FunnelCollapse: React.FC<CollapseProps> = (props) => {

	const { t } = useTranslation()
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: props.isExpaned })

    let items: DataItemType[] = []
    if (props.areaIds) {
        props.areaIds.forEach((areaId, idx) => {
            const area = props.areaList?.find(el => el.area_id === areaId)
            if (area && props.data) {
                const itm: DataItemType = { id: areaId, name: area.area_number + "_" + area.name, value: props.data[idx] }
                items.push(itm)
            }
        })
    } else {
        // 来店者数の場合
        if (props.data) {
            const itm: DataItemType = {id:0,name:'-', value: props.data[0]}
            items.push(itm)
        }
    }

    const total = items.reduce((a, b) => { return a + b.value }, 0)
    const ranked = items.sort((a, b) => { return a.value > b.value ? -1 : 1 }).slice(0, NUM_OF_RANKING)

    const rows = ranked.map((itm, idx) => {
        const value = Utils.formatCommaInteger(itm.value)
        const ratio = Utils.formatCommaPointOne(Math.round(1000 * itm.value / total) / 10)
        if (props.isSingle) {
            return (
                <tr key={idx}>
                    <td className={style.tdDmy}></td>
                    <td className={style.tdLfBd}>{Utils.textEllipsis(itm.name, 28)}</td>
                    <td className={style.tdRtBd}><span className={style.bold}>{value}</span></td>
                    <td className={style.tdRtBd}><span className={style.bold}>{ratio}</span>%</td>
                    <td className={style.tdGrBd}>
                        <HorizontalBarGraph
                            type={HorizontalBarType.Funnel}
                            value={itm.value}
                            maxValue={total}
                        />
                    </td>
                    <td className={style.tdDmy}></td>
                </tr>
            )
        } else {
            return (
                <tr key={idx}>
                    <td className={style.tdTitle}>{Utils.textEllipsis(itm.name, 24)}</td>
                    <td className={style.tdRatio}><span className={style.regular}>{ratio}</span>%</td>
                    <td className={style.tdGraph}>
                        <HorizontalBarGraph
                            type={HorizontalBarType.Funnel}
                            value={itm.value}
                            maxValue={total}
                            widthType={HBarWidthType.Narrow}
                        />
                    </td>
                </tr>
            )
        }
    })
    
    if (props.isSingle) {
        let btnStyle: string = style.btnTop
        if (props.borderType === CollapseBorderType.Mid) btnStyle = style.btnMid
        if (props.borderType === CollapseBorderType.Bottom) btnStyle = style.btnBtm
        return (
            <div className={style.collMain}>
                <div className={btnStyle} {...getToggleProps({ onClick: () => props.onClick() })}>
                    <div className={style.btnName}>{props.name}</div>
                    <div className={style.btnArrow}>{props.isExpaned ? (<ChevronDownIcon size={16} />) : (<ChevronRightIcon size={16} />)}</div>
                </div>
                <div className={style.collapse} {...getCollapseProps()}>
                    <table className={style.collapseTable}>
                        <thead>
                            <tr>
                                <th className={style.colTh} colSpan={2}>{t("areaName")}</th>
                                <th className={style.colTh}>{t("persons")}</th>
                                <th className={style.colTh}>{t("componentRatio")}</th>
                                <th className={style.colTh} colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className={style.button} {...getToggleProps({ onClick: () => props.onClick() })}>
                    <div className={style.btnNameW}>{props.name}</div>
                    <div className={style.btnArrowBlue}>{props.isExpaned ? (<ChevronDownIcon size={16} />) : (<ChevronRightIcon size={16} />)}</div>
                </div>
                <div {...getCollapseProps()}>
                    <table className={style.collapseTblW}>
                        <thead>
                            <tr>
                                <th className={style.cTblTitle}>{t("areaName")}</th>
                                <th className={style.colThW}>{t("componentRatio")}</th>
                                <th className={style.colThW}>{t("persons")}</th>                                        
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

type DataItemType = {
    id: number
    name: string
    value: number
}

interface AreaCvrProps {
    data: FunnelDataType | undefined
    resWasCompare?: boolean
}

export const FunnelAreaCvr: React.FC<AreaCvrProps> = (props) => {

	const { t } = useTranslation()
    const isSingle: boolean = (props.resWasCompare && props.resWasCompare === true) ? false : true

    const { userInfo } = useAuthUserContext()
    const [isExpanedVis, setIsExpanedVis] = useState<boolean>(false)
    const [isExpanedPas, setIsExpanedPas] = useState<boolean>(false)
    const [isExpanedSty, setIsExpanedSty] = useState<boolean>(false)
    const [isExpanedBuy, setIsExpanedBuy] = useState<boolean>(false)
    const [isExpanedNot, setIsExpanedNot] = useState<boolean>(false)

    const handleVis = () => {
        setIsExpanedVis(!isExpanedVis)
        if (isExpanedPas) setIsExpanedPas(!isExpanedPas)
        if (isExpanedSty) setIsExpanedSty(!isExpanedSty)
        if (isExpanedBuy) setIsExpanedBuy(!isExpanedBuy)
        if (isExpanedNot) setIsExpanedNot(!isExpanedNot)
    }
    const handlePas = () => {
        setIsExpanedPas(!isExpanedPas)
        if (isExpanedVis) setIsExpanedVis(!isExpanedVis)
        if (isExpanedSty) setIsExpanedSty(!isExpanedSty)
        if (isExpanedBuy) setIsExpanedBuy(!isExpanedBuy)
        if (isExpanedNot) setIsExpanedNot(!isExpanedNot)
    }
    const handleSty = () => {
        setIsExpanedSty(!isExpanedSty)
        if (isExpanedVis) setIsExpanedVis(!isExpanedVis)
        if (isExpanedPas) setIsExpanedPas(!isExpanedPas)
        if (isExpanedBuy) setIsExpanedBuy(!isExpanedBuy)
        if (isExpanedNot) setIsExpanedNot(!isExpanedNot)
    }
    const handleBuy = () => {
        setIsExpanedBuy(!isExpanedBuy)
        if (isExpanedVis) setIsExpanedVis(!isExpanedVis)
        if (isExpanedPas) setIsExpanedPas(!isExpanedPas)
        if (isExpanedSty) setIsExpanedSty(!isExpanedSty)
        if (isExpanedNot) setIsExpanedNot(!isExpanedNot)
    }
    const handleNot = () => {
        setIsExpanedNot(!isExpanedNot)
        if (isExpanedVis) setIsExpanedVis(!isExpanedVis)
        if (isExpanedPas) setIsExpanedPas(!isExpanedPas)
        if (isExpanedSty) setIsExpanedSty(!isExpanedSty)
        if (isExpanedBuy) setIsExpanedBuy(!isExpanedBuy)
    }

    const title = t("header.funnel", {target: t(isSingle ? "header.rankStopByArea" : "header.rankStayingInArea")} )

    if (props.data && props.data.areas && props.data.areas.length > 0) {
        //const shopId = props.data.request.shopId
        //const layoutId = props.data.request.layoutId
        //const shop = (userInfo) ? userInfo.shopList.find(el => el.shop_id === shopId) : undefined
        //const layout = (shop) ? shop.layout_list?.find(el => el.layout_id === layoutId) : undefined
        let len = (props.data.areas) ? props.data.areas.length : undefined
        let visitor: number[] = props.data.visitor ? [props.data.visitor] : []
        let buyers: number[] = []
        let notBuyers: number[] = []
        if (len) {
            for (let i = 0; i < len; i++) {
                // 購買者のデータを作成
                if (props.data.buyer1 && props.data.buyerOther) {
                    const b = props.data.buyer1[i] + props.data.buyerOther[i]
                    buyers.push(b)
                }
                // 非購買者のデータを作成
                if (props.data.notBuy1 && props.data.notBuyOther) {
                    const n = props.data.notBuy1[i] + props.data.notBuyOther[i]
                    notBuyers.push(n)
                }
            }
        }

        return (
            <div className={isSingle ? style.rankPanel : style.rankPanelW}>
                <div className={isSingle ? style.title : style.rankTitle}>{title}</div>
                <div className={style.collapsePane}>
                    <ul>
                        <li>
                            <FunnelCollapse
                                isExpaned={isExpanedVis}
                                isSingle={isSingle}
                                name={t("visitors")}
                                data={visitor}
                                areaIds={undefined}
                                areaList={undefined}
                                onClick={handleVis}
                                borderType={CollapseBorderType.Top}
                            />
                        </li>
                        <li>
                            <FunnelCollapse
                                isExpaned={isExpanedPas}
                                isSingle={isSingle}
                                name={t("passerbyInArea")}
                                data={props.data.pass}
                                areaIds={props.data.areas}
                                areaList={props.data.area_list}
                                onClick={handlePas}
                                borderType={CollapseBorderType.Mid}
                            />
                        </li>
                        <li>
                            <FunnelCollapse
                                isExpaned={isExpanedSty}
                                isSingle={isSingle}
                                name={t("visitorInArea")}
                                data={props.data.stay}
                                areaIds={props.data.areas}
                                areaList={props.data.area_list}
                                onClick={handleSty}
                                borderType={CollapseBorderType.Bottom}
                            />
                        </li>
                        <li>
                            <FunnelCollapse
                                isExpaned={isExpanedBuy}
                                isSingle={isSingle}
                                name={t("productsPurchaser")}
                                data={buyers}
                                areaIds={props.data.areas}
                                areaList={props.data.area_list}
                                onClick={handleBuy}
                                borderType={CollapseBorderType.Top}
                            />
                        </li>
                        <li>
                            <FunnelCollapse
                                isExpaned={isExpanedNot}
                                isSingle={isSingle}
                                name={t("productsNonPurchaser")}
                                data={notBuyers}
                                areaIds={props.data.areas}
                                areaList={props.data.area_list}
                                onClick={handleNot}
                                borderType={CollapseBorderType.Bottom}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={style.rankTitle}>{title}</div>
            <div className={style.noData}>
                <div className={style.message}>{t("msgNoFound")}</div>
            </div>
        </div>        
    )
}

interface Props {
    params: FunnelParamSetType | undefined
    view: AnalyzeView | undefined
    side: CompareSide
    data: FunnelDataType | undefined
    resWasCompare?: boolean
}

export const FunnelResult: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    // 保持している検索結果が「比較」かどうか
    const resultWasCompare = (props.resWasCompare !== undefined && props.resWasCompare === true) ? true : false
    //console.log("resultWasCompare:", resultWasCompare)

    if (props.data === undefined) return (<></>)
    if (!resultWasCompare) {
        return (
            <div className={style.sMain}>
                <div className={style.sCvr}>
                    <FunnelCvr data={props.data} />
                </div>
                <div className={style.sAreaCvr}>
                    <FunnelAreaCvr data={props.data} />
                </div>
            </div>
        )
    } else {
        return (
            <div className={style.wMain}>
                <div className={style.wPanel}>
                    <div className={style.caption}>
                        {props.side === CompareSideType.Primary ? (<span>{t("comparitiveData")}&#9312;</span>) : (<span>{t("comparitiveData")}&#9313;</span>)}
                    </div>
                    <div className={style.wCvr}>
                        <FunnelCvr data={props.data} resWasCompare={resultWasCompare} />
                    </div>
                    <div className={style.wAreaCvr}>
                        <FunnelAreaCvr data={props.data} resWasCompare={resultWasCompare} />
                    </div>
                </div>
            </div>
        )
    }
}