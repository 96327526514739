
/**
 * 全店ダッシュボードのデータ選択肢
 */
export const DataChoiceType = {
    Visitors: 'visitors',           // 来店者数
    Buyers: 'buyers',               // 購買者数
    BuyRatio: 'buy_ratio',          // 購買率
    AvgStayTime: 'stay_time',       // 平均滞在時間
    SalesAmount: 'sales_amount',    // 売上金額
    AvgNumOfArea: 'num_of_area'     // 平均滞在エリア数
} as const
export type DataChoice = typeof DataChoiceType[keyof typeof DataChoiceType]

/**
 * 全店ダッシュボードのグラデーションチャート用データ
 */
export type StatisticsDatasetType = {
    label: string[]
    data: number[]
    shopId: number
    shopName: string
}

// 全店ダッシュボードで表示できるデータの種類（ENUM）
// 
export const StatisticsOfShopType = {
    Visitors: 'visitors',       // 来店者数
    Buyers: 'buyers',           // 購買者数
    BuyRatio: 'buy_ratio',      // 購買率
    SpentTime: 'spent_time',    // 店舗滞在時間
    SalesAmount: 'sales_amount',// 売上金額
    NumOfArea: 'num_of_area'    // 滞在エリア数
} as const
export type StatisticsOfShop = typeof StatisticsOfShopType[keyof typeof StatisticsOfShopType]
export const AllDataType = Object.values(StatisticsOfShopType)

