import React, { useCallback, useMemo, useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChevronRightIcon } from "@primer/octicons-react"

import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { EditModeType, UrlParameterType } from "../../types/Management"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { Button, StyleType } from "../../component/button/Button"
import { BaseFrame, ContentsFrameType } from "../common/BaseFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import { useMouseMove } from "../../lib/useMouseMove"
import AreaDetailPicWriter from "../../lib/AreaDetailPicWriter"

import style from "./AreaRegiList.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

const CANVAS_WIDtH = 380
const CANVAS_HEIGHT = 310

interface Props {}

export const AreaRegiList: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    
    const { companyId, companyList, shopId, shopList, dataSourceList, dataSourceId, layoutList, layoutId, areaPackId, areaPackList, changeCompany, changeShop, changeDataSource, changeLayout, changeAreaPack, initManagementData } = useManagementDataContext()

    const { ableToCreate, ableToUpdate } = useAccessControlContext()
    
    const [writer, setWriter] = useState<AreaDetailPicWriter | undefined>(undefined)
    const [mouseOverAreaId, setMouseOverAreaId] = useState<number | undefined>(undefined)
    const ref = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
  
    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])

    /**
     * 企業がひとつしか存在しない
     */
    const onlyOneCompany = useMemo(() => {
        return (companyList && companyList.length === 1)
    }, [companyList])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // 選択されたエリアリスト
    const areaList = useMemo(() => {
        if (areaPackId && areaPackList) {
            const pk = areaPackList.find(el => el.area_set_id === areaPackId)
            if (pk) return pk.area_list
        }
        return undefined
    }, [areaPackId, areaPackList])

    const handleCompanyChanged = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const id = parseInt(value)
        changeCompany(id)
    }, [changeCompany])

    const handleShopChanged = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const id = parseInt(value)
        changeShop(id)
    }, [changeShop])

    const handleLayoutSetChanged = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const id = parseInt(value)
        changeDataSource(id)
    }, [changeDataSource])

    const handleLayoutChanged = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const id = parseInt(value)
        changeLayout(id)
    }, [changeLayout])

    const handleAreaSetChanged = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const id = parseInt(value)
        changeAreaPack(id)
    }, [changeAreaPack])

    const handleEdit = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>, areaId: number) => {
        e.preventDefault()
        const mode = ableEdit ? EditModeType.Edit : EditModeType.View
        navigate("/area_regi_edit?" + UrlParameterType.Mode + "=" + mode + "&" + UrlParameterType.AreaSetId + "=" + areaPackId + "&" + UrlParameterType.AreaId + "=" + areaId)
    }, [navigate, ableEdit, areaPackId])

    const handleClickNew = useCallback(() => {
        /*if (dataSourceId) {
            if (layoutId) {
                if (areaPackId) {*/
        navigate("/area_regi_edit?" + UrlParameterType.Mode + "=" + EditModeType.New + "&" + UrlParameterType.AreaSetId + "=" + areaPackId)
                /*} else {
                    alert("エリアセットを選択してください")
                }
            } else {
                alert("レイアウトを選択してください")
            }
        } else {
            alert("レイアウトセットを選択してください")
        }
        }, [navigate, dataSourceId, layoutId, areaPackId])*/
    }, [navigate, areaPackId])

    /**
     * 拡大ボタン押下時の処理
     */
    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            refreshMap()
        }
    }

    /**
     * 縮小ボタン押下時の処理
     */
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            refreshMap()
        }
    }

    /**
     * 元に戻すボタン押下時の処理
     */
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            refreshMap()
        }
    }

    const handleAreaHighlight = useCallback((areaId: number) => {
        if (writer && aniRef.current) {
            const ctx = aniRef.current.getContext("2d")
            if (ctx) writer.activeAreaDraw(ctx, areaId)
        }
    }, [writer])

    const handleAreaRemoveLight = useCallback((areaId: number) => {
        if (writer && aniRef.current) {
            const ctx = aniRef.current.getContext("2d")
            if (ctx) writer.clearCanvas(ctx)
        }
    }, [writer])

    const companySelectOption = useMemo(() => {
        const elm = (<option key="cm-select-x" value={0}>{t("msgSelectCompany")}</option>)
        if (companyList) {
            const ops = companyList.map((el, i) => {
                return (<option key={'cm-select-' + i + 1} value={el.company_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [companyList, t])

    const shopSelectOption = useMemo(() => {
        const elm = (<option key="sh-select-x" value={0}>{t("msgSelectStore")}</option>)
        if (shopList) {
            const ops = shopList.map((el, i) => {
                return (<option key={'sh-select-' + i + 1} value={el.shop_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [shopList, t])

    const layoutSetSelectOption = useMemo(() => {
        const elm = (<option key="bs-select-x" value={0}>{t("guidance.selectLayoutSet")}</option>)
        if (dataSourceList) {
            const ops = dataSourceList.map((el, i) => {
                return (<option key={'ds-select-' + i + 1} value={el.layout_set_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [dataSourceList, t])

    const layoutSelectOption = useMemo(() => {
        const elm = (<option key="lay-select-x" value={0}>{t("guidance.selectLayout") }</option>)
        if (layoutList) {
            const ops = layoutList.map((el, i) => {
                return (<option key={'lay-select-' + i + 1} value={el.layout_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [layoutList, t])

    const areaSetSelectOption = useMemo(() => {
        const elm = (<option key="ap-select-x" value={0}>{t("guidance.selectAreaSet")}</option>)
        if (areaPackList) {
            const ops = areaPackList.map((el, i) => {
                return (<option key={'ap-select-' + i + 1} value={el.area_set_id}>{el.startYmd}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [areaPackList, t])

    const mousePress = useMouseMove(aniRef, (event: MouseEvent) => {
        if (writer) {
            const srcCanvas = ref.current
            const dstCanvas = aniRef.current
            if (srcCanvas && dstCanvas) writer.mouseMoveWithArea(event, mousePress, srcCanvas, dstCanvas, (area: ResArea, px: number, py: number) => {
                // マウスがエリア上にあるときの処理
                setMouseOverAreaId(area.area_id)
                handleAreaHighlight(area.area_id)
            }, () => {
                // マウスはエリア外にあるときの処理
                setMouseOverAreaId(undefined)
                handleAreaRemoveLight(0)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) writer.clearCanvas(ctx)
            })
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        if (writer) {
            writer.mouseRelease()
            // 再描画
            refreshMap()
            //handleAreaRemoveLight(0)
        }
    }, (event: MouseEvent) => {
        // マウス押下処理
        if (writer) {
            writer.mousePress()
        }
    })

    const refreshMap = useCallback(() => {
        if (writer) {
            if (ref.current) {
                const ctx = ref.current.getContext("2d")
                if (ctx) writer.drawImageWithArea(ctx)
            }
            if (aniRef.current) {
                const ani = aniRef.current.getContext("2d")
                if (ani) writer.clearCanvas(ani)
            }
        }
    }, [writer])

    // Writerの生成と削除
    useEffect(() => {
        if (layout && layout.mapping && areaList) {
            if (writer === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                const w = new AreaDetailPicWriter(resLay, areaList, CANVAS_WIDtH, CANVAS_HEIGHT)
                setWriter(w)
                //console.log("◆Writer生成")
            } else {
                // Writerが生成されたらMap描画する
                refreshMap()                
            }
        } else {
            // 平面図をクリアし、Writerを削除する
            if (writer && ref && ref.current) {
                const ctx = ref.current.getContext("2d")
                if (ctx) writer.clearCanvas(ctx)
                setWriter(undefined)
                //console.log("◆Writer削除")
            }
        }
    }, [layout, areaList, writer, refreshMap])

     /*useEffect(() => {
        // 開発時のStrictModeで二重レンダーされるのを防ぐ
        if (process.env.NODE_ENV === "development") {
            if (refFirst.current) {
                refFirst.current = false
                console.log("SKIP first render")
                return
            }
        }

        if (mousePress) {
            if (writer) writer.mousePress()
        } else {
            refreshMap()
        }
    }, [mousePress, writer, refreshMap])*/
    
    // データ初期化
    useEffect(() => {
        // 企業は必ず１件以上ある。ない時はAPIからデータを取得する
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    const items = useMemo(() => {
        if (areaList) {
            return areaList.map((el, i) => {
                const isHighlight = mouseOverAreaId === el.area_id
                return (
                    <tr key={i} className={isHighlight ? style.tdRowHi : style.tdRow} onMouseOver={e => handleAreaHighlight(el.area_id)} onMouseOut={e => handleAreaRemoveLight(el.area_id)}>
                        <td className={style.tdId}>{el.area_number}</td>
                        <td className={style.tdName}>{el.name}</td>
                        <td className={style.tdLink}>
                            <div className={style.icon} onClick={(e) => handleEdit(e, el.area_id)} ><ChevronRightIcon size={16} /></div>
                        </td>
                    </tr>
                )
            })
        }
        return undefined
    }, [areaList, handleEdit, handleAreaHighlight, handleAreaRemoveLight, mouseOverAreaId])

    return (
        <BaseFrame
            actPage={PageName.ManageArea}
            type={ContentsFrameType.Basic}
        >
            <div className={style.panel}>
                <div className={style.top}>
                    {isProduction ? (<TrackingPageView page_title="bsinfo-area-regist-list" />) : (null)}
                    <div className={style.title}>{t("header.areaRegistrationEdit")}</div>
                    <div className={style.entry}>
                        {
                            ableNew ? (<Button label="areaEntry" name="areaEntry" styleType={StyleType.Normal} onClick={handleClickNew} />) : (null)
                        }
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.bodyLabel}>{t("areaView")}</div>
                    <div className={style.main}>
                        <div className={style.mainLeft}>
                            {
                                onlyOneCompany ? (null) : (
                                    <div className={style.choice}>
                                        <label className={style.label} htmlFor="cm_select">{t("selectCompany")}：</label>
                                        <select id="cm_select" className={`${style.cpSelect} ${style.comSelect}`} value={companyId} onChange={handleCompanyChanged}>
                                            {companySelectOption}
                                        </select>
                                    </div>
                                )
                            }
                            <div className={style.choice}>
                                <label className={style.label} htmlFor="sh_select">{t("selectStore")}：</label>
                                <select id="sh_select" className={`${style.shSelect} ${style.comSelect}`} value={shopId} onChange={handleShopChanged}>
                                    {shopSelectOption}
                                </select>
                            </div>
                            <div className={style.choice}>
                                <label className={style.label} htmlFor="ds_select">{t("selectLayoutSet")}：</label>
                                <select id="ds_select" className={`${style.dsSelect} ${style.comSelect}`} value={dataSourceId} onChange={handleLayoutSetChanged}>
                                    {layoutSetSelectOption}
                                </select>
                            </div>
                            <div className={style.choice}>
                                <label className={style.label} htmlFor="lay_select">{t("selectLayout")}：</label>
                                <select id="lay_select" className={`${style.dsSelect} ${style.comSelect}`} value={layoutId} onChange={handleLayoutChanged}>
                                    {layoutSelectOption}
                                </select>
                            </div>
                            <div className={style.choice}>
                                <label className={style.label} htmlFor="ap_select">{t("selectAreaSet")}：</label>
                                <select id="ap_select" className={`${style.apSelect} ${style.comSelect}`} value={areaPackId} onChange={handleAreaSetChanged}>
                                    {areaSetSelectOption}
                                </select>
                            </div>
                            <div className={style.list}>
                                <table className={style.table}>
                                    <thead>
                                        <tr className={style.thRow}>
                                            <th className={style.thId}>エリアNo</th>
                                            <th className={style.thName}>対象エリア</th>
                                            <th className={style.thLink}>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={style.mainRight}>
                            <div className={style.wrap}>
                                <canvas className={style.canvas} ref={ref} width={CANVAS_WIDtH} height={CANVAS_HEIGHT} />
                                <canvas className={style.aniCanvas} ref={aniRef} width={CANVAS_WIDtH} height={CANVAS_HEIGHT} />
                                <div className={style.zoomBtn}>
                                    <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseFrame>
    )
}
