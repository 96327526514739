
export const getTrails = () => {
    return [{id: '220802125816250', humanId: 10000999, points: [
            { ms: 1659412697313, onTime: 300, x: 7125, y: 125 },
            {ms: 1659412697513, onTime: 200, x: 7278, y: 132},
            {ms: 1659412697613, onTime: 100, x: 7369, y: 163},
            {ms: 1659412697713, onTime: 100, x: 7457, y: 197},
            {ms: 1659412697813, onTime: 100, x: 7610, y: 223},
            {ms: 1659412697913, onTime: 100, x: 7733, y: 220},
            {ms: 1659412698013, onTime: 100, x: 786, y: 215},
            {ms: 1659412698113, onTime: 100, x: 8013, y: 215},
            {ms: 1659412698213, onTime: 100, x: 816, y: 238},
            {ms: 1659412698313, onTime: 100, x: 8307, y: 266},
            {ms: 1659412698413, onTime: 100, x: 8493, y: 294},
            {ms: 1659412698613, onTime: 200, x: 8649, y: 341},
            {ms: 1659412698713, onTime: 100, x: 8867, y: 440},
            {ms: 1659412698813, onTime: 100, x: 8993, y: 496},
            {ms: 1659412698913, onTime: 100, x: 9125, y: 545},
            {ms: 1659412699113, onTime: 200, x: 9337, y: 630},
            {ms: 1659412699313, onTime: 200, x: 9554, y: 746},
            {ms: 1659412699413, onTime: 100, x: 9668, y: 848},
            {ms: 1659412699513, onTime: 100, x: 9745, y: 931},
            {ms: 1659412699613, onTime: 100, x: 9858, y: 1051},
            {ms: 1659412699713, onTime: 100, x: 9966, y: 1145},
            {ms: 1659412699813, onTime: 100, x: 9917, y: 1742},
            {ms: 1659412699913, onTime: 100, x: 1010, y: 1905},
            {ms: 1659412700013, onTime: 100, x: 10214, y: 1987},
            {ms: 1659412700113, onTime: 100, x: 10327, y: 2082}, 
            {ms: 1659412700213, onTime: 100, x: 10449, y: 2197},
            {ms: 1659412700313, onTime: 100, x: 1055, y: 2270},
            {ms: 1659412700413, onTime: 100, x: 10639, y: 2347},
            {ms: 1659412700513, onTime: 100, x: 1071, y: 2437},
            {ms: 1659412700613, onTime: 100, x: 10793, y: 2551},
            {ms: 1659412700713, onTime: 100, x: 10868, y: 2658},
            {ms: 1659412700813, onTime: 100, x: 10960, y: 2764},
            {ms: 1659412700913, onTime: 100, x: 11073, y: 2880},
            {ms: 1659412701013, onTime: 100, x: 10945, y: 2897},
            {ms: 1659412701113, onTime: 100, x: 11036, y: 2969},
            {ms: 1659412701213, onTime: 100, x: 11147, y: 3044},
            {ms: 1659412701313, onTime: 100, x: 11228, y: 3119},
            {ms: 1659412701413, onTime: 100, x: 1129, y: 3224},
            {ms: 1659412701513, onTime: 100, x: 11373, y: 3388},
            {ms: 1659412701613, onTime: 100, x: 11435, y: 3545},
            {ms: 1659412701713, onTime: 100, x: 11500, y: 3713},
            {ms: 1659412701813, onTime: 100, x: 11567, y: 3866},
            {ms: 1659412701913, onTime: 100, x: 11636, y: 3977},
            {ms: 1659412702013, onTime: 100, x: 11704, y: 4084},
            {ms: 1659412702113, onTime: 100, x: 11783, y: 4202},
            {ms: 1659412702213, onTime: 100, x: 11857, y: 4303}, 
            {ms: 1659412702313, onTime: 100, x: 1192, y: 4422}, 
            {ms: 1659412702413, onTime: 100, x: 11966, y: 4535}, 
            {ms: 1659412702513, onTime: 100, x: 12017, y: 4684}, 
            {ms: 1659412702613, onTime: 100, x: 12060, y: 4819}, 
            {ms: 1659412702713, onTime: 100, x: 12087, y: 4938}, 
            {ms: 1659412702813, onTime: 100, x: 1212, y: 5060}, 
            {ms: 1659412702913, onTime: 100, x: 12170, y: 5189}, 
            {ms: 1659412703013, onTime: 100, x: 12218, y: 5296}, 
            {ms: 1659412703113, onTime: 100, x: 12337, y: 5501}, 
            {ms: 1659412703213, onTime: 100, x: 12414, y: 5626}, 
            {ms: 1659412703413, onTime: 200, x: 125, y: 5809}, 
            {ms: 1659412703613, onTime: 200, x: 126, y: 5972}, 
            {ms: 1659412703713, onTime: 100, x: 12696, y: 6066}, 
            {ms: 1659412703813, onTime: 100, x: 12773, y: 6157}, 
            {ms: 1659412703913, onTime: 100, x: 12860, y: 6257}, 
            {ms: 1659412704013, onTime: 100, x: 12936, y: 6360}, 
            {ms: 1659412704113, onTime: 100, x: 1301, y: 6460}, 
            {ms: 1659412704313, onTime: 200, x: 13143, y: 6603}, 
            {ms: 1659412704513, onTime: 200, x: 13280, y: 6779}, 
            {ms: 1659412704613, onTime: 100, x: 1331, y: 6859}, 
            {ms: 1659412704713, onTime: 100, x: 13343, y: 6950}, 
            {ms: 1659412704913, onTime: 200, x: 13407, y: 7167}, 
            {ms: 1659412705113, onTime: 200, x: 13459, y: 7291}, 
            {ms: 1659412705613, onTime: 500, x: 12287, y: 7920}, 
            {ms: 1659412707713, onTime: 2100, x: 12317, y: 7809}, 
            {ms: 1659412710813, onTime: 3100, x: 12294, y: 7955}, 
            {ms: 1659412713013, onTime: 2200, x: 1222, y: 7866}, 
            {ms: 1659412713713, onTime: 700, x: 1218, y: 7773}, 
            {ms: 1659412716813, onTime: 3100, x: 11996, y: 7863}, 
            {ms: 1659412716913, onTime: 100, x: 11969, y: 7882}, 
            {ms: 1659412724013, onTime: 7100, x: 12138, y: 7859}, 
            {ms: 1659412724413, onTime: 400, x: 12369, y: 7829}, 
            {ms: 1659412724713, onTime: 300, x: 12376, y: 7699}, 
            {ms: 1659412724913, onTime: 200, x: 12470, y: 7652}, 
            {ms: 1659412727213, onTime: 2300, x: 1257, y: 7720}, 
            {ms: 1659412727913, onTime: 700, x: 12514, y: 7842}, 
            {ms: 1659412728213, onTime: 300, x: 12403, y: 7874}, 
            {ms: 1659412729013, onTime: 800, x: 12256, y: 7868}, 
            {ms: 1659412729213, onTime: 200, x: 12286, y: 7724}, 
            {ms: 1659412729513, onTime: 300, x: 12224, y: 7831}, 
            {ms: 1659412731913, onTime: 2400, x: 121, y: 7875}, 
            {ms: 1659412734513, onTime: 2600, x: 12219, y: 7712}, 
            {ms: 1659412734713, onTime: 200, x: 12187, y: 7845}, 
            {ms: 1659412734913, onTime: 200, x: 12107, y: 7953}, 
            {ms: 1659412735513, onTime: 600, x: 12127, y: 7824}, 
            {ms: 1659412741913, onTime: 6400, x: 12073, y: 7885},
            {ms: 1659412742213, onTime: 300, x: 12017, y: 7881}, 
            {ms: 1659412743013, onTime: 800, x: 11876, y: 7771}, 
            {ms: 1659412743413, onTime: 400, x: 11810, y: 7659}, 
            {ms: 1659412746213, onTime: 2800, x: 1172, y: 7715}, 
            {ms: 1659412772813, onTime: 26600, x: 1176, y: 7590}, 
            {ms: 1659412773513, onTime: 700, x: 11844, y: 7660}, 
            {ms: 1659412774113, onTime: 600, x: 11944, y: 7650}, 
            {ms: 1659412775613, onTime: 1500, x: 12049, y: 7685}, 
            {ms: 1659412775813, onTime: 200, x: 12117, y: 7692}, 
            {ms: 1659412776013, onTime: 200, x: 12184, y: 7748}, 
            {ms: 1659412777013, onTime: 1000, x: 12264, y: 7686}, 
            {ms: 1659412777913, onTime: 900, x: 12397, y: 7824}, 
            {ms: 1659412778713, onTime: 800, x: 123, y: 7918}, 
            {ms: 1659412790913, onTime: 12200, x: 12375, y: 8006}, 
            {ms: 1659412804813, onTime: 13900, x: 12269, y: 8014}, 
            {ms: 1659412807613, onTime: 2800, x: 12400, y: 7965}, 
            {ms: 1659412808213, onTime: 600, x: 12325, y: 7851}, 
            {ms: 1659412809913, onTime: 1700, x: 12069, y: 7275}, 
            {ms: 1659412810413, onTime: 500, x: 10258, y: 7911}, 
            {ms: 1659412895813, onTime: 85400, x: 10254, y: 8011}, 
            {ms: 1659412918013, onTime: 22200, x: 10319, y: 7935}, 
            {ms: 1659412955713, onTime: 37700, x: 1021, y: 7945}, 
            {ms: 1659412993113, onTime: 37400, x: 1010, y: 7858}, 
            {ms: 1659412993713, onTime: 600, x: 10215, y: 7931}, 
            {ms: 1659413151313, onTime: 157600, x: 10113, y: 7861}, 
            {ms: 1659413152213, onTime: 900, x: 10226, y: 7933}, 
            {ms: 1659413211913, onTime: 59700, x: 9988, y: 7943}, 
            {ms: 1659413212313, onTime: 400, x: 991, y: 7900}, 
            {ms: 1659413213213, onTime: 900, x: 9896, y: 7898}, 
            {ms: 1659413213613, onTime: 400, x: 9778, y: 7911}, 
            {ms: 1659413214713, onTime: 1100, x: 915, y: 8066}, 
            {ms: 1659413214913, onTime: 200, x: 901, y: 8070}, 
            {ms: 1659413215113, onTime: 200, x: 8914, y: 8098}, 
            {ms: 1659413215813, onTime: 700, x: 8868, y: 8000}, 
            {ms: 1659413215913, onTime: 100, x: 8845, y: 7990}, 
            {ms: 1659413216113, onTime: 200, x: 8758, y: 7974}, 
            {ms: 1659413216613, onTime: 500, x: 8765, y: 8086}, 
            {ms: 1659413216713, onTime: 100, x: 8844, y: 8148}, 
            {ms: 1659413217813, onTime: 1100, x: 877, y: 8227}, 
            {ms: 1659413218913, onTime: 1100, x: 8897, y: 8332}, 
            {ms: 1659413219713, onTime: 800, x: 88, y: 8251}, 
            {ms: 1659413220813, onTime: 1100, x: 8969, y: 8386}, 
            {ms: 1659413222113, onTime: 1300, x: 887, y: 8322}, 
            {ms: 1659413223613, onTime: 1500, x: 8803, y: 8154}, 
            {ms: 1659413225613, onTime: 2000, x: 9704, y: 8040}, 
            {ms: 1659413225913, onTime: 300, x: 983, y: 7976}, 
            {ms: 1659413226213, onTime: 300, x: 996, y: 7917}, 
            {ms: 1659413226313, onTime: 100, x: 9993, y: 7900}, 
            {ms: 1659413226513, onTime: 200, x: 10055, y: 7881}, 
            {ms: 1659413227013, onTime: 500, x: 10170, y: 7861}, 
            {ms: 1659413236413, onTime: 9400, x: 10276, y: 7826}, 
            {ms: 1659413236913, onTime: 500, x: 10303, y: 7831}, 
            {ms: 1659413242113, onTime: 5200, x: 10078, y: 7873}, 
            {ms: 1659413250113, onTime: 8000, x: 10224, y: 7852}, 
            {ms: 1659413253713, onTime: 3600, x: 10086, y: 7861}, 
            {ms: 1659413262213, onTime: 8500, x: 10205, y: 7864}, 
            {ms: 1659413262913, onTime: 700, x: 10097, y: 7892}, 
            {ms: 1659413329213, onTime: 66300, x: 10239, y: 7822}, 
            {ms: 1659413399313, onTime: 70100, x: 10154, y: 7886}, 
            {ms: 1659413405313, onTime: 6000, x: 10270, y: 7867}, 
            {ms: 1659413407513, onTime: 2200, x: 1017, y: 7898}, 
            {ms: 1659413407913, onTime: 400, x: 10039, y: 7905}, 
            {ms: 1659413408113, onTime: 200, x: 10008, y: 7895}, 
            {ms: 1659413410813, onTime: 2700, x: 10245, y: 7877}, 
            {ms: 1659413425913, onTime: 15100, x: 10008, y: 7873}, 
            {ms: 1659413433513, onTime: 7600, x: 10167, y: 7854}, 
            {ms: 1659413466513, onTime: 33000, x: 1006, y: 7892}, 
            {ms: 1659413552013, onTime: 85500, x: 9970, y: 7829}, 
            {ms: 1659413618213, onTime: 66200, x: 10096, y: 7874}, 
            {ms: 1659413647513, onTime: 29300, x: 9854, y: 7848}, 
            {ms: 1659413649113, onTime: 1600, x: 10120, y: 7807}, 
            {ms: 1659413689113, onTime: 40000, x: 9946, y: 7858}, 
            {ms: 1659413691013, onTime: 1900, x: 9389, y: 8086}, 
            {ms: 1659413691213, onTime: 200, x: 9226, y: 8067}, 
            {ms: 1659413691413, onTime: 200, x: 9085, y: 8071}, 
            {ms: 1659413691913, onTime: 500, x: 9000, y: 8156}, 
            {ms: 1659413692113, onTime: 200, x: 8865, y: 8183}, 
            {ms: 1659413692213, onTime: 100, x: 8796, y: 8194}, 
            {ms: 1659413692313, onTime: 100, x: 8737, y: 8205}, 
            {ms: 1659413693213, onTime: 900, x: 8648, y: 8272}, 
            {ms: 1659413694013, onTime: 800, x: 8727, y: 8171}, 
            {ms: 1659413694513, onTime: 500, x: 8866, y: 8035}, 
            {ms: 1659413695613, onTime: 1100, x: 9496, y: 8091}, 
            {ms: 1659413695813, onTime: 200, x: 9618, y: 8062}, 
            {ms: 1659413696013, onTime: 200, x: 9750, y: 7989}, 
            {ms: 1659413696113, onTime: 100, x: 9807, y: 7955}, 
            {ms: 1659413696213, onTime: 100, x: 9843, y: 7941}, 
            {ms: 1659413696513, onTime: 300, x: 9957, y: 7930}, 
            {ms: 1659413696913, onTime: 400, x: 10064, y: 7899}, 
            {ms: 1659413698213, onTime: 1300, x: 10129, y: 7843}, 
            {ms: 1659413732113, onTime: 33900, x: 10169, y: 7921}, 
            {ms: 1659413760013, onTime: 27900, x: 9698, y: 7901}, 
            {ms: 1659413768413, onTime: 8400, x: 9899, y: 7905}, 
            {ms: 1659413769213, onTime: 800, x: 10237, y: 7918}, 
            {ms: 1659413781813, onTime: 12600, x: 10039, y: 7885}, 
            {ms: 1659413845413, onTime: 63600, x: 10169, y: 7899}, 
            {ms: 1659413860913, onTime: 15500, x: 10058, y: 7958}, 
            {ms: 1659413861613, onTime: 700, x: 979, y: 8125}, 
            {ms: 1659413861813, onTime: 200, x: 9688, y: 8084}, 
            {ms: 1659413862313, onTime: 500, x: 949, y: 8093}, 
            {ms: 1659413869613, onTime: 7300, x: 9865, y: 8118}, 
            {ms: 1659413870813, onTime: 1200, x: 10088, y: 7995}, 
            {ms: 1659413871313, onTime: 500, x: 10258, y: 7920}, 
            {ms: 1659413883713, onTime: 12400, x: 10289, y: 8033}, 
            {ms: 1659413888613, onTime: 4900, x: 10357, y: 7807}, 
            {ms: 1659413900613, onTime: 12000, x: 10376, y: 7909}, 
            {ms: 1659413921513, onTime: 20900, x: 1029, y: 7844}, 
            {ms: 1659413922213, onTime: 700, x: 10135, y: 7890}, 
            {ms: 1659413926313, onTime: 4100, x: 1025, y: 7855}, 
            {ms: 1659413969613, onTime: 43300, x: 10015, y: 7898}, 
            {ms: 1659413983913, onTime: 14300, x: 10216, y: 7849}, 
            {ms: 1659413996713, onTime: 12800, x: 10043, y: 7867}, 
            {ms: 1659414015213, onTime: 18500, x: 997, y: 7968}, 
            {ms: 1659414018413, onTime: 3200, x: 10068, y: 7932}, 
            {ms: 1659414019313, onTime: 900, x: 10185, y: 7914}        , 
            {ms: 1659414019713, onTime: 400, x: 10125, y: 7997}        , 
            {ms: 1659414020413, onTime: 700, x: 10237, y: 7905}        , 
            {ms: 1659414050713, onTime: 30300, x: 10076, y: 7866}        , 
            {ms: 1659414087313, onTime: 36600, x: 10013, y: 7986}        , 
            {ms: 1659414087713, onTime: 400, x: 9920, y: 8023}        , 
            {ms: 1659414088013, onTime: 300, x: 9770, y: 8051}        , 
            {ms: 1659414088813, onTime: 800, x: 933, y: 8147}        , 
            {ms: 1659414089113, onTime: 300, x: 9128, y: 8153}        , 
            {ms: 1659414089413, onTime: 300, x: 8853, y: 8086}        , 
            {ms: 1659414089613, onTime: 200, x: 8736, y: 8107}        , 
            {ms: 1659414090313, onTime: 700, x: 8636, y: 8086}        , 
            {ms: 1659414091813, onTime: 1500, x: 8677, y: 8246}        , 
            {ms: 1659414095013, onTime: 3200, x: 7850, y: 8406}        , 
            {ms: 1659414095213, onTime: 200, x: 7727, y: 8485}        , 
            {ms: 1659414095413, onTime: 200, x: 7578, y: 8538}        , 
            {ms: 1659414095613, onTime: 200, x: 7407, y: 8587}        , 
            {ms: 1659414095813, onTime: 200, x: 7297, y: 8598}        , 
            {ms: 1659414095913, onTime: 100, x: 726, y: 8598}        , 
            {ms: 1659414096613, onTime: 700, x: 7195, y: 8579}        , 
            {ms: 1659414099313, onTime: 2700, x: 7295, y: 8632}        , 
            {ms: 1659414100413, onTime: 1100, x: 8016, y: 8316}        , 
            {ms: 1659414100613, onTime: 200, x: 818, y: 8223}        , 
            {ms: 1659414100713, onTime: 100, x: 8306, y: 8167}        , 
            {ms: 1659414100813, onTime: 100, x: 8429, y: 8118}        , 
            {ms: 1659414100913, onTime: 100, x: 855, y: 8082}        , 
            {ms: 1659414101013, onTime: 100, x: 8633, y: 8071}        , 
            {ms: 1659414101113, onTime: 100, x: 8710, y: 8068}        , 
            {ms: 1659414101313, onTime: 200, x: 888, y: 8084}        , 
            {ms: 1659414101413, onTime: 100, x: 8973, y: 8089}        , 
            {ms: 1659414101513, onTime: 100, x: 9073, y: 8096}        , 
            {ms: 1659414101713, onTime: 200, x: 9244, y: 8105}        , 
            {ms: 1659414101813, onTime: 100, x: 9316, y: 8103}        , 
            {ms: 1659414101913, onTime: 100, x: 9378, y: 8098}        , 
            { ms: 1659414102113, onTime: 200, x: 9554, y: 8076 }, 
            { ms: 1659414102213, onTime: 100, x: 9659, y: 8062 }, 
            { ms: 1659414102413, onTime: 200, x: 9838, y: 8012 }, 
            { ms: 1659414102613, onTime: 200, x: 9970, y: 7975 }, 
            { ms: 1659414102913, onTime: 300, x: 10115, y: 7949 }, 
            { ms: 1659414103113, onTime: 200, x: 10220, y: 7921 }, 
            { ms: 1659414103313, onTime: 200, x: 10275, y: 7903 }, 
            { ms: 1659414121613, onTime: 18300, x: 1028, y: 7821 }, 
            { ms: 1659414139213, onTime: 17600, x: 1016, y: 7814 }, 
            { ms: 1659414286413, onTime: 147200, x: 10024, y: 7949 }, 
            { ms: 1659414287713, onTime: 1300, x: 10215, y: 7945 }, 
            { ms: 1659414288713, onTime: 1000, x: 11136, y: 7989 }, 
            { ms: 1659414288813, onTime: 100, x: 1125, y: 7996 }, 
            { ms: 1659414288913, onTime: 100, x: 11375, y: 8012 }, 
            { ms: 1659414289013, onTime: 100, x: 11499, y: 8027 }, 
            { ms: 1659414289213, onTime: 200, x: 11694, y: 8010 }, 
            { ms: 1659414289413, onTime: 200, x: 11873, y: 7974 }, 
            { ms: 1659414289613, onTime: 200, x: 12030, y: 7956 }, 
            { ms: 1659414289713, onTime: 100, x: 12103, y: 8047 }, 
            { ms: 1659414289813, onTime: 100, x: 12198, y: 8083 }, 
            { ms: 1659414290013, onTime: 200, x: 12349, y: 8163 }, 
            { ms: 1659414290213, onTime: 200, x: 12503, y: 8246 }, 
            { ms: 1659414290513, onTime: 300, x: 12626, y: 8263 }, 
            { ms: 1659414290913, onTime: 400, x: 12849, y: 8035 }, 
            { ms: 1659414291913, onTime: 1000, x: 12658, y: 8052 }, 
            { ms: 1659414292713, onTime: 800, x: 13026, y: 7853 }]
        },{
            id: '220802155418358', humanId: 10000100, points: [
                {ms: 1659423259213, onTime: 200, x: 25013, y: 12852}, 
                {ms: 1659423259313, onTime: 100, x: 24911, y: 12831}, 
                {ms: 1659423259413, onTime: 100, x: 24796, y: 12827}, 
                {ms: 1659423259513, onTime: 100, x: 24693, y: 12822}, 
                {ms: 1659423259613, onTime: 100, x: 24584, y: 12807}, 
                {ms: 1659423259713, onTime: 100, x: 24459, y: 12797}, 
                {ms: 1659423259813, onTime: 100, x: 24316, y: 12778}, 
                {ms: 1659423260013, onTime: 200, x: 24037, y: 12687}, 
                {ms: 1659423260213, onTime: 200, x: 23818, y: 12659}, 
                {ms: 1659423260313, onTime: 100, x: 23709, y: 12659}, 
                {ms: 1659423260413, onTime: 100, x: 23602, y: 12647}, 
                {ms: 1659423260613, onTime: 200, x: 23456, y: 12562}, 
                {ms: 1659423260713, onTime: 100, x: 23408, y: 12565}, 
                {ms: 1659423260813, onTime: 100, x: 23355, y: 12573}, 
                {ms: 1659423260913, onTime: 100, x: 23264, y: 12617}, 
                {ms: 1659423261013, onTime: 100, x: 23167, y: 12591}, 
                {ms: 1659423261113, onTime: 100, x: 23093, y: 12508}, 
                {ms: 1659423261213, onTime: 100, x: 23032, y: 12425}, 
                {ms: 1659423261313, onTime: 100, x: 22944, y: 12359}, 
                {ms: 1659423261513, onTime: 200, x: 22805, y: 12355}, 
                {ms: 1659423261713, onTime: 200, x: 22637, y: 12274}, 
                {ms: 1659423261913, onTime: 200, x: 22484, y: 12297}, 
                {ms: 1659423262213, onTime: 300, x: 22373, y: 12207}, 
                {ms: 1659423262413, onTime: 200, x: 22241, y: 12179}, 
                {ms: 1659423262513, onTime: 100, x: 22163, y: 12160}, 
                {ms: 1659423262613, onTime: 100, x: 22089, y: 12137}, 
                {ms: 1659423262813, onTime: 200, x: 21932, y: 12063}, 
                {ms: 1659423263013, onTime: 200, x: 21766, y: 12054}, 
                {ms: 1659423263113, onTime: 100, x: 21649, y: 12070}, 
                {ms: 1659423263313, onTime: 200, x: 21350, y: 12134}, 
                {ms: 1659423263513, onTime: 200, x: 21214, y: 12075}, 
                {ms: 1659423263713, onTime: 200, x: 21039, y: 12000}, 
                {ms: 1659423263813, onTime: 100, x: 20931, y: 11940}, 
                {ms: 1659423263913, onTime: 100, x: 20851, y: 11873}, 
                {ms: 1659423264013, onTime: 100, x: 20791, y: 11812}, 
                {ms: 1659423264113, onTime: 100, x: 20708, y: 11781}, 
                {ms: 1659423264313, onTime: 200, x: 20565, y: 11690}, 
                {ms: 1659423264413, onTime: 100, x: 20515, y: 11642}, 
                {ms: 1659423264513, onTime: 100, x: 20458, y: 11590}, 
                {ms: 1659423264813, onTime: 300, x: 20699, y: 11385}, 
                {ms: 1659423265013, onTime: 200, x: 20611, y: 11268}, 
                {ms: 1659423265113, onTime: 100, x: 20641, y: 11168}, 
                {ms: 1659423265213, onTime: 100, x: 20274, y: 10937}, 
                {ms: 1659423265513, onTime: 300, x: 20269, y: 10796}, 
                {ms: 1659423265713, onTime: 200, x: 20518, y: 10617}, 
                {ms: 1659423266013, onTime: 300, x: 20443, y: 10520}, 
                {ms: 1659423266213, onTime: 200, x: 20458, y: 10409}, 
                {ms: 1659423266413, onTime: 200, x: 20405, y: 10270}, 
                {ms: 1659423266613, onTime: 200, x: 20094, y: 9857}, 
                {ms: 1659423266813, onTime: 200, x: 20090, y: 9729}, 
                {ms: 1659423267013, onTime: 200, x: 20090, y: 9595}, 
                {ms: 1659423267213, onTime: 200, x: 20078, y: 9420}, 
                {ms: 1659423267513, onTime: 300, x: 20068, y: 9225}, 
                {ms: 1659423267713, onTime: 200, x: 20088, y: 9117}, 
                {ms: 1659423267813, onTime: 100, x: 20410, y: 9177}, 
                {ms: 1659423268013, onTime: 200, x: 20287, y: 9088}, 
                {ms: 1659423268213, onTime: 200, x: 20224, y: 8967}, 
                {ms: 1659423268313, onTime: 100, x: 20231, y: 8872}, 
                {ms: 1659423268413, onTime: 100, x: 20156, y: 8554}, 
                {ms: 1659423268613, onTime: 200, x: 20162, y: 8398}, 
                {ms: 1659423268813, onTime: 200, x: 20141, y: 8278}, 
                {ms: 1659423268913, onTime: 100, x: 20110, y: 8230}, 
                {ms: 1659423269013, onTime: 100, x: 20095, y: 8165}, 
                {ms: 1659423269213, onTime: 200, x: 20087, y: 7981}, 
                {ms: 1659423269313, onTime: 100, x: 20089, y: 7883}, 
                {ms: 1659423269413, onTime: 100, x: 20084, y: 7797}, 
                {ms: 1659423269613, onTime: 200, x: 20036, y: 7678}, 
                {ms: 1659423269913, onTime: 300, x: 19931, y: 7692}, 
                {ms: 1659423270013, onTime: 100, x: 19865, y: 7653}, 
                {ms: 1659423270113, onTime: 100, x: 19810, y: 7621}, 
                {ms: 1659423270313, onTime: 200, x: 19677, y: 7568}, 
                {ms: 1659423270613, onTime: 300, x: 19591, y: 7501}, 
                {ms: 1659423271013, onTime: 400, x: 19424, y: 7396}, 
                {ms: 1659423271313, onTime: 300, x: 19312, y: 7379}, 
                {ms: 1659423271513, onTime: 200, x: 19157, y: 7392}, 
                {ms: 1659423271713, onTime: 200, x: 19275, y: 7490}, 
                {ms: 1659423271813, onTime: 100, x: 19045, y: 7409}, 
                {ms: 1659423271913, onTime: 100, x: 18982, y: 7431}, 
                {ms: 1659423272113, onTime: 200, x: 18943, y: 7445}, 
                {ms: 1659423272613, onTime: 500, x: 18829, y: 7421}, 
                {ms: 1659423272813, onTime: 200, x: 18994, y: 7465}, 
                {ms: 1659423272913, onTime: 100, x: 18667, y: 7351}, 
                {ms: 1659423273113, onTime: 200, x: 18552, y: 7308}, 
                {ms: 1659423273413, onTime: 300, x: 18463, y: 7253}, 
                {ms: 1659423273713, onTime: 300, x: 18361, y: 7192}, 
                {ms: 1659423273913, onTime: 200, x: 18248, y: 7158}, 
                {ms: 1659423274113, onTime: 200, x: 18141, y: 7120}, 
                {ms: 1659423274413, onTime: 300, x: 18079, y: 7041}, 
                {ms: 1659423274713, onTime: 300, x: 18070, y: 6889}, 
                {ms: 1659423274813, onTime: 100, x: 17876, y: 7078}, 
                {ms: 1659423275013, onTime: 200, x: 17888, y: 6941}, 
                {ms: 1659423275213, onTime: 200, x: 17908, y: 6812}, 
                {ms: 1659423275313, onTime: 100, x: 17912, y: 6748}, 
                {ms: 1659423275513, onTime: 200, x: 17919, y: 6596}, 
                {ms: 1659423275713, onTime: 200, x: 17919, y: 6454}, 
                {ms: 1659423275813, onTime: 100, x: 17916, y: 6364}, 
                {ms: 1659423275913, onTime: 100, x: 17915, y: 6274}, 
                {ms: 1659423276113, onTime: 200, x: 17903, y: 6126}, 
                {ms: 1659423276313, onTime: 200, x: 17894, y: 6004}, 
                {ms: 1659423276513, onTime: 200, x: 17881, y: 5836}, 
                {ms: 1659423276713, onTime: 200, x: 17872, y: 5652}, 
                {ms: 1659423276913, onTime: 200, x: 17868, y: 5489}, 
                {ms: 1659423277113, onTime: 200, x: 17840, y: 5350}, 
                {ms: 1659423277313, onTime: 200, x: 17809, y: 5158}, 
                {ms: 1659423277513, onTime: 200, x: 17786, y: 4989}, 
                {ms: 1659423277713, onTime: 200, x: 17782, y: 4818}, 
                {ms: 1659423277913, onTime: 200, x: 17789, y: 4640}, 
                {ms: 1659423278113, onTime: 200, x: 17806, y: 4467}, 
                {ms: 1659423278313, onTime: 200, x: 17806, y: 4330}, 
                {ms: 1659423278513, onTime: 200, x: 17790, y: 4198}, 
                {ms: 1659423278713, onTime: 200, x: 17738, y: 4040}, 
                {ms: 1659423278813, onTime: 100, x: 17722, y: 3967}, 
                {ms: 1659423278913, onTime: 100, x: 17721, y: 3893}, 
                {ms: 1659423279113, onTime: 200, x: 17454, y: 3688}, 
                {ms: 1659423279313, onTime: 200, x: 17486, y: 3577}, 
                {ms: 1659423279713, onTime: 400, x: 17306, y: 3450}, 
                {ms: 1659423280213, onTime: 500, x: 17535, y: 3418}, 
                {ms: 1659423280913, onTime: 700, x: 17373, y: 3612}, 
                {ms: 1659423281013, onTime: 100, x: 17556, y: 3459}, 
                {ms: 1659423281413, onTime: 400, x: 17372, y: 3609}, 
                {ms: 1659423281513, onTime: 100, x: 17576, y: 3440}, 
                {ms: 1659423282713, onTime: 1200, x: 17280, y: 3457}, 
                {ms: 1659423282913, onTime: 200, x: 17572, y: 3429}, 
                {ms: 1659423283013, onTime: 100, x: 17302, y: 3455}, 
                {ms: 1659423283513, onTime: 500, x: 17413, y: 3511}, 
                {ms: 1659423283813, onTime: 300, x: 17384, y: 3638}, 
                {ms: 1659423284113, onTime: 300, x: 17587, y: 3522}, 
                {ms: 1659423284213, onTime: 100, x: 17501, y: 3741}, 
                {ms: 1659423284413, onTime: 200, x: 17714, y: 3758}, 
                {ms: 1659423284613, onTime: 200, x: 17489, y: 3731}, 
                {ms: 1659423284913, onTime: 300, x: 17656, y: 3769}, 
                {ms: 1659423285213, onTime: 300, x: 17493, y: 3728}, 
                {ms: 1659423285313, onTime: 100, x: 17600, y: 3726}, 
                {ms: 1659423285413, onTime: 100, x: 17765, y: 3737}, 
                {ms: 1659423285713, onTime: 300, x: 17595, y: 3735}, 
                {ms: 1659423285813, onTime: 100, x: 17798, y: 3730}, 
                {ms: 1659423285913, onTime: 100, x: 17600, y: 3745}, 
                {ms: 1659423286113, onTime: 200, x: 17501, y: 3761}, 
                {ms: 1659423286213, onTime: 100, x: 17695, y: 3778}, 
                {ms: 1659423286313, onTime: 100, x: 17462, y: 3730}, 
                {ms: 1659423286413, onTime: 100, x: 17695, y: 3778}, 
                {ms: 1659423286513, onTime: 100, x: 17499, y: 3733}, 
                {ms: 1659423286913, onTime: 400, x: 17676, y: 3796}, 
                {ms: 1659423287013, onTime: 100, x: 17430, y: 3751}, 
                {ms: 1659423287813, onTime: 800, x: 17541, y: 3713}, 
                {ms: 1659423288313, onTime: 500, x: 17422, y: 3766}, 
                {ms: 1659423290513, onTime: 2200, x: 17526, y: 3753}, 
                {ms: 1659423291013, onTime: 500, x: 17765, y: 3792}, 
                {ms: 1659423293313, onTime: 2300, x: 17721, y: 3676}, 
                {ms: 1659423293913, onTime: 600, x: 17617, y: 3553}, 
                {ms: 1659423294213, onTime: 300, x: 17587, y: 3493}, 
                {ms: 1659423294813, onTime: 600, x: 17581, y: 3459}, 
                {ms: 1659423295513, onTime: 700, x: 17453, y: 3471}, 
                {ms: 1659423299013, onTime: 3500, x: 17588, y: 3331}, 
                {ms: 1659423299113, onTime: 100, x: 17799, y: 3240}, 
                {ms: 1659423300913, onTime: 1800, x: 17782, y: 3361}, 
                {ms: 1659423301513, onTime: 600, x: 17789, y: 3561}, 
                {ms: 1659423301813, onTime: 300, x: 17442, y: 3554}, 
                {ms: 1659423302313, onTime: 500, x: 17656, y: 3515}, 
                {ms: 1659423303113, onTime: 800, x: 17350, y: 3524}, 
                {ms: 1659423303413, onTime: 300, x: 17410, y: 3433}, 
                {ms: 1659423303813, onTime: 400, x: 17790, y: 3350}, 
                {ms: 1659423303913, onTime: 100, x: 17499, y: 3441}, 
                {ms: 1659423304713, onTime: 800, x: 17658, y: 3394}, 
                {ms: 1659423305013, onTime: 300, x: 17937, y: 3330}, 
                {ms: 1659423307013, onTime: 2000, x: 17642, y: 3340}, 
                {ms: 1659423307413, onTime: 400, x: 17601, y: 3379}, 
                {ms: 1659423307713, onTime: 300, x: 17552, y: 3449}, 
                {ms: 1659423307813, onTime: 100, x: 17822, y: 3367}, 
                {ms: 1659423310713, onTime: 2900, x: 17549, y: 3390}, 
                {ms: 1659423310813, onTime: 100, x: 17765, y: 3400}, 
                {ms: 1659423311313, onTime: 500, x: 17549, y: 3390}, 
                {ms: 1659423312413, onTime: 1100, x: 17605, y: 3295}, 
                {ms: 1659423312513, onTime: 100, x: 17880, y: 3249}, 
                {ms: 1659423312613, onTime: 100, x: 17628, y: 3269}, 
                {ms: 1659423312713, onTime: 100, x: 17912, y: 3271}, 
                {ms: 1659423313013, onTime: 300, x: 17606, y: 3280}, 
                {ms: 1659423313213, onTime: 200, x: 17503, y: 3335}, 
                {ms: 1659423313413, onTime: 200, x: 17781, y: 3256}, 
                {ms: 1659423314413, onTime: 1000, x: 17841, y: 3213}, 
                {ms: 1659423315413, onTime: 1000, x: 17840, y: 3170}, 
                {ms: 1659423316413, onTime: 1000, x: 17753, y: 3223}, 
                {ms: 1659423316613, onTime: 200, x: 17641, y: 3198}, 
                {ms: 1659423317113, onTime: 500, x: 17462, y: 3137}, 
                {ms: 1659423317513, onTime: 400, x: 17440, y: 2978}, 
                {ms: 1659423317813, onTime: 300, x: 17423, y: 2861}, 
                {ms: 1659423318413, onTime: 600, x: 17382, y: 2830}, 
                {ms: 1659423334013, onTime: 15600, x: 17394, y: 2762}, 
                {ms: 1659423334613, onTime: 600, x: 17496, y: 2722}, 
                {ms: 1659423335113, onTime: 500, x: 17541, y: 2632}, 
                {ms: 1659423341013, onTime: 5900, x: 17407, y: 2649}, 
                {ms: 1659423343713, onTime: 2700, x: 17456, y: 2803}, 
                {ms: 1659423344113, onTime: 400, x: 17444, y: 2933}, 
                {ms: 1659423344713, onTime: 600, x: 17554, y: 3051}, 
                {ms: 1659423346813, onTime: 2100, x: 17879, y: 3177}, 
                {ms: 1659423353313, onTime: 6500, x: 17778, y: 3220}, 
                {ms: 1659423353713, onTime: 400, x: 17661, y: 3221}, 
                {ms: 1659423354113, onTime: 400, x: 17554, y: 3223}, 
                {ms: 1659423359613, onTime: 5500, x: 17670, y: 3222}, 
                {ms: 1659423360313, onTime: 700, x: 17835, y: 3185}, 
                {ms: 1659423360813, onTime: 500, x: 18006, y: 3221}, 
                {ms: 1659423366813, onTime: 6000, x: 17901, y: 3161}, 
                {ms: 1659423372213, onTime: 5400, x: 18002, y: 3141}, 
                {ms: 1659423391013, onTime: 18800, x: 18102, y: 3125}, 
                {ms: 1659423391413, onTime: 400, x: 18127, y: 3018}, 
                {ms: 1659423392513, onTime: 1100, x: 18034, y: 2937}, 
                {ms: 1659423392713, onTime: 200, x: 18020, y: 2898}, 
                {ms: 1659423396413, onTime: 3700, x: 17911, y: 2931}, 
                {ms: 1659423405413, onTime: 9000, x: 18013, y: 2948}, 
                {ms: 1659423408013, onTime: 2600, x: 17908, y: 2943}, 
                {ms: 1659423411113, onTime: 3100, x: 18016, y: 2939}, 
                {ms: 1659423439413, onTime: 28300, x: 18234, y: 2965}, 
                {ms: 1659423441013, onTime: 1600, x: 18084, y: 2831}, 
                {ms: 1659423441913, onTime: 900, x: 17987, y: 2786}, 
                {ms: 1659423442513, onTime: 600, x: 17966, y: 2920}, 
                {ms: 1659423443013, onTime: 500, x: 18105, y: 2964}, 
                {ms: 1659423446013, onTime: 3000, x: 18000, y: 2893}, 
                {ms: 1659423456813, onTime: 10800, x: 18030, y: 2992}, 
                {ms: 1659423457213, onTime: 400, x: 18163, y: 3016}, 
                {ms: 1659423457513, onTime: 300, x: 17893, y: 2947}, 
                {ms: 1659423458613, onTime: 1100, x: 18000, y: 2960}, 
                {ms: 1659423480713, onTime: 22100, x: 17938, y: 2864}, 
                {ms: 1659423481013, onTime: 300, x: 18078, y: 2960}, 
                {ms: 1659423486613, onTime: 5600, x: 17975, y: 2923}, 
                {ms: 1659423519313, onTime: 32700, x: 18059, y: 2983}, 
                {ms: 1659423524213, onTime: 4900, x: 17963, y: 2940}, 
                {ms: 1659423564013, onTime: 39800, x: 18060, y: 2991}, 
                {ms: 1659423564513, onTime: 500, x: 18043, y: 3166}, 
                {ms: 1659423564913, onTime: 400, x: 17949, y: 3222}, 
                {ms: 1659423565413, onTime: 500, x: 17740, y: 3203}, 
                {ms: 1659423566013, onTime: 600, x: 17435, y: 3062}, 
                {ms: 1659423567713, onTime: 1700, x: 17020, y: 1936}, 
                {ms: 1659423567913, onTime: 200, x: 16951, y: 1787}, 
                {ms: 1659423568013, onTime: 100, x: 16654, y: 1686}, 
                {ms: 1659423568213, onTime: 200, x: 16583, y: 1575}, 
                {ms: 1659423568413, onTime: 200, x: 16498, y: 1485}, 
                {ms: 1659423568513, onTime: 100, x: 16432, y: 1450}, 
                {ms: 1659423568613, onTime: 100, x: 16374, y: 1429}, 
                {ms: 1659423568813, onTime: 200, x: 16264, y: 1382}, 
                {ms: 1659423569113, onTime: 300, x: 16169, y: 1349}, 
                {ms: 1659423569313, onTime: 200, x: 16151, y: 1337}, 
                {ms: 1659423583213, onTime: 13900, x: 16393, y: 1275}, 
                {ms: 1659423584213, onTime: 1000, x: 16195, y: 1282}, 
                {ms: 1659423584713, onTime: 500, x: 16087, y: 1283}, 
                {ms: 1659423588613, onTime: 3900, x: 15989, y: 1139}, 
                {ms: 1659423591213, onTime: 2600, x: 16062, y: 1238}, 
                {ms: 1659423592113, onTime: 900, x: 16107, y: 1342}, 
                {ms: 1659423593813, onTime: 1700, x: 16012, y: 1234}, 
                {ms: 1659423595413, onTime: 1600, x: 16031, y: 1378}, 
                {ms: 1659423614613, onTime: 19200, x: 16300, y: 1250}, 
                {ms: 1659423627413, onTime: 12800, x: 16347, y: 1349}, 
                {ms: 1659423627913, onTime: 500, x: 16358, y: 1456}, 
                {ms: 1659423628313, onTime: 400, x: 16432, y: 1491}, 
                {ms: 1659423628513, onTime: 200, x: 16537, y: 1525}, 
                {ms: 1659423629113, onTime: 600, x: 16831, y: 1802}, 
                {ms: 1659423629313, onTime: 200, x: 16887, y: 1903}, 
                {ms: 1659423629513, onTime: 200, x: 17158, y: 1933}, 
                {ms: 1659423629813, onTime: 300, x: 17178, y: 2297}, 
                {ms: 1659423630113, onTime: 300, x: 17254, y: 2426}, 
                {ms: 1659423630313, onTime: 200, x: 17311, y: 2549}, 
                {ms: 1659423630413, onTime: 100, x: 17337, y: 2621}, 
                {ms: 1659423630513, onTime: 100, x: 17361, y: 2691}, 
                {ms: 1659423630813, onTime: 300, x: 17408, y: 2810}, 
                {ms: 1659423631013, onTime: 200, x: 17441, y: 2920}, 
                {ms: 1659423631213, onTime: 200, x: 17468, y: 3056}, 
                {ms: 1659423631513, onTime: 300, x: 17533, y: 3144}, 
                {ms: 1659423631713, onTime: 200, x: 17606, y: 3173}, 
                {ms: 1659423631813, onTime: 100, x: 17655, y: 3182}, 
                {ms: 1659423632013, onTime: 200, x: 17766, y: 3192}, 
                {ms: 1659423632513, onTime: 500, x: 17868, y: 3187}, 
                {ms: 1659423632713, onTime: 200, x: 17911, y: 3176}, 
                {ms: 1659423633213, onTime: 500, x: 18002, y: 3190}, 
                {ms: 1659423635913, onTime: 2700, x: 17841, y: 3169}, 
                {ms: 1659423637713, onTime: 1800, x: 17949, y: 3199}, 
                {ms: 1659423638413, onTime: 700, x: 18049, y: 3192}, 
                {ms: 1659423639313, onTime: 900, x: 17807, y: 3092}, 
                {ms: 1659423640513, onTime: 1200, x: 18049, y: 3107}, 
                {ms: 1659423640713, onTime: 200, x: 18003, y: 3232}, 
                {ms: 1659423641313, onTime: 600, x: 17671, y: 3190}, 
                {ms: 1659423641513, onTime: 200, x: 17503, y: 3707}, 
                {ms: 1659423641713, onTime: 200, x: 17542, y: 3803}, 
                {ms: 1659423642613, onTime: 900, x: 18025, y: 3129}, 
                {ms: 1659423643113, onTime: 500, x: 17836, y: 3117}, 
                {ms: 1659423643913, onTime: 800, x: 17667, y: 3722}, 
                {ms: 1659423644713, onTime: 800, x: 17777, y: 3103}, 
                {ms: 1659423644813, onTime: 100, x: 17881, y: 3112}, 
                {ms: 1659423645413, onTime: 600, x: 18025, y: 3123}, 
                {ms: 1659423645813, onTime: 400, x: 17450, y: 3727}, 
                {ms: 1659423645913, onTime: 100, x: 18016, y: 3116}, 
                {ms: 1659423646513, onTime: 600, x: 18005, y: 3358}, 
                {ms: 1659423646713, onTime: 200, x: 17644, y: 3760}, 
                {ms: 1659423648013, onTime: 1300, x: 18144, y: 3099}, 
                {ms: 1659423648313, onTime: 300, x: 17568, y: 3779}, 
                {ms: 1659423649013, onTime: 700, x: 18046, y: 3160}, 
                {ms: 1659423649713, onTime: 700, x: 17940, y: 3126}, 
                {ms: 1659423650713, onTime: 1000, x: 18142, y: 3290}, 
                {ms: 1659423651213, onTime: 500, x: 18034, y: 3099}, 
                {ms: 1659423651713, onTime: 500, x: 17580, y: 3742}, 
                {ms: 1659423651813, onTime: 100, x: 17998, y: 3131}, 
                {ms: 1659423652213, onTime: 400, x: 17829, y: 3055}, 
                {ms: 1659423652613, onTime: 400, x: 17854, y: 3165}, 
                {ms: 1659423654213, onTime: 1600, x: 17712, y: 3246}, 
                {ms: 1659423654713, onTime: 500, x: 17844, y: 3149}, 
                {ms: 1659423655413, onTime: 700, x: 17670, y: 3647}, 
                {ms: 1659423655513, onTime: 100, x: 17711, y: 3236}, 
                {ms: 1659423655713, onTime: 200, x: 17852, y: 3091}, 
                {ms: 1659423656613, onTime: 900, x: 17641, y: 3677}, 
                {ms: 1659423656813, onTime: 200, x: 18020, y: 3112}, 
                {ms: 1659423656913, onTime: 100, x: 17614, y: 3675}, 
                {ms: 1659423657113, onTime: 200, x: 18020, y: 3112}, 
                {ms: 1659423657413, onTime: 300, x: 17971, y: 3201}, 
                {ms: 1659423657613, onTime: 200, x: 17639, y: 3684}, 
                {ms: 1659423657813, onTime: 200, x: 17971, y: 3201}, 
                {ms: 1659423657913, onTime: 100, x: 17661, y: 3677}, 
                {ms: 1659423658013, onTime: 100, x: 17827, y: 3159}, 
                {ms: 1659423658313, onTime: 300, x: 17676, y: 3678}, 
                {ms: 1659423658513, onTime: 200, x: 17892, y: 3088}, 
                {ms: 1659423658713, onTime: 200, x: 17601, y: 3654}, 
                {ms: 1659423658813, onTime: 100, x: 17891, y: 3125}, 
                {ms: 1659423658913, onTime: 100, x: 17683, y: 3693}, 
                {ms: 1659423659513, onTime: 600, x: 17805, y: 3019}, 
                {ms: 1659423659913, onTime: 400, x: 17660, y: 3698}, 
                {ms: 1659423660113, onTime: 200, x: 17970, y: 3143}, 
                {ms: 1659423660713, onTime: 600, x: 17824, y: 2942}, 
                {ms: 1659423660813, onTime: 100, x: 17921, y: 3026}, 
                {ms: 1659423661013, onTime: 200, x: 17943, y: 3149}, 
                {ms: 1659423661713, onTime: 700, x: 17988, y: 3054}, 
                {ms: 1659423662213, onTime: 500, x: 17638, y: 3690}, 
                {ms: 1659423663513, onTime: 1300, x: 17831, y: 3100}, 
                {ms: 1659423663913, onTime: 400, x: 17827, y: 3227}, 
                {ms: 1659423664613, onTime: 700, x: 17814, y: 3091}, 
                {ms: 1659423665313, onTime: 700, x: 17655, y: 3665}, 
                {ms: 1659423665513, onTime: 200, x: 17864, y: 3109}, 
                {ms: 1659423665713, onTime: 200, x: 17708, y: 3662}, 
                {ms: 1659423666713, onTime: 1000, x: 17631, y: 3752}, 
                {ms: 1659423667913, onTime: 1200, x: 18108, y: 3160}, 
                {ms: 1659423669813, onTime: 1900, x: 17540, y: 3764}, 
                {ms: 1659423671913, onTime: 2100, x: 18102, y: 3109}, 
                {ms: 1659423672113, onTime: 200, x: 17503, y: 3773}, 
                {ms: 1659423674213, onTime: 2100, x: 18071, y: 3161}, 
                {ms: 1659423674813, onTime: 600, x: 17489, y: 3797}, 
                {ms: 1659423675213, onTime: 400, x: 18080, y: 3150}, 
                {ms: 1659423675613, onTime: 400, x: 17590, y: 3826}, 
                {ms: 1659423675913, onTime: 300, x: 18080, y: 3150}, 
                {ms: 1659423676413, onTime: 500, x: 17591, y: 3821}, 
                {ms: 1659423676513, onTime: 100, x: 18073, y: 3188}, 
                {ms: 1659423677613, onTime: 1100, x: 17488, y: 3783}, 
                {ms: 1659423677913, onTime: 300, x: 18101, y: 3145}, 
                {ms: 1659423679313, onTime: 1400, x: 18035, y: 3051}, 
                {ms: 1659423679713, onTime: 400, x: 17743, y: 3736}, 
                {ms: 1659423680613, onTime: 900, x: 17860, y: 3632}, 
                {ms: 1659423681413, onTime: 800, x: 17819, y: 3540}, 
                {ms: 1659423682513, onTime: 1100, x: 17920, y: 3179}, 
                {ms: 1659423682713, onTime: 200, x: 17800, y: 3630}, 
                {ms: 1659423683413, onTime: 700, x: 17912, y: 3145}, 
                {ms: 1659423685513, onTime: 2100, x: 17818, y: 3494}, 
                {ms: 1659423687213, onTime: 1700, x: 17882, y: 2890}, 
                {ms: 1659423687413, onTime: 200, x: 17775, y: 3530}, 
                {ms: 1659423687613, onTime: 200, x: 17830, y: 2919}, 
                {ms: 1659423687913, onTime: 300, x: 17713, y: 3491}, 
                {ms: 1659423688213, onTime: 300, x: 17825, y: 2929}, 
                {ms: 1659423688413, onTime: 200, x: 17674, y: 3465}, 
                {ms: 1659423688613, onTime: 200, x: 17825, y: 2929}, 
                {ms: 1659423688713, onTime: 100, x: 17821, y: 2760}, 
                {ms: 1659423689413, onTime: 700, x: 17822, y: 2866}, 
                {ms: 1659423690313, onTime: 900, x: 17833, y: 2974}, 
                {ms: 1659423690813, onTime: 500, x: 17834, y: 2761}, 
                {ms: 1659423691313, onTime: 500, x: 17704, y: 3480}, 
                {ms: 1659423691613, onTime: 300, x: 17820, y: 2895}, 
                {ms: 1659423693413, onTime: 1800, x: 17753, y: 3474}, 
                {ms: 1659423694413, onTime: 1000, x: 17830, y: 2868}, 
                {ms: 1659423694613, onTime: 200, x: 17834, y: 3439}, 
                {ms: 1659423695113, onTime: 500, x: 17736, y: 3480}, 
                {ms: 1659423695213, onTime: 100, x: 17834, y: 2818}, 
                {ms: 1659423695313, onTime: 100, x: 17839, y: 2936}, 
                {ms: 1659423696113, onTime: 800, x: 17821, y: 2820}, 
                {ms: 1659423697513, onTime: 1400, x: 17834, y: 3032}, 
                {ms: 1659423698413, onTime: 900, x: 17824, y: 2809}, 
                {ms: 1659423700713, onTime: 2300, x: 17759, y: 3501}, 
                {ms: 1659423700813, onTime: 100, x: 17845, y: 2882}, 
                {ms: 1659423702813, onTime: 2000, x: 17957, y: 3006}, 
                {ms: 1659423703713, onTime: 900, x: 17838, y: 2845}, 
                {ms: 1659423705613, onTime: 1900, x: 17759, y: 3473}, 
                {ms: 1659423706013, onTime: 400, x: 17840, y: 2888}, 
                {ms: 1659423707113, onTime: 1100, x: 17835, y: 2777}, 
                {ms: 1659423708513, onTime: 1400, x: 17860, y: 2945}, 
                {ms: 1659423708913, onTime: 400, x: 17841, y: 2718}, 
                {ms: 1659423709913, onTime: 1000, x: 17737, y: 3520}, 
                {ms: 1659423710113, onTime: 200, x: 17876, y: 2977}, 
                {ms: 1659423710413, onTime: 300, x: 17765, y: 3519}, 
                {ms: 1659423710513, onTime: 100, x: 17876, y: 2977}, 
                {ms: 1659423710713, onTime: 200, x: 17794, y: 3490}, 
                {ms: 1659423713113, onTime: 2400, x: 18009, y: 3381}, 
                {ms: 1659423717113, onTime: 4000, x: 17981, y: 3280}, 
                {ms: 1659423723713, onTime: 6600, x: 18055, y: 3351}, 
                {ms: 1659423724013, onTime: 300, x: 18078, y: 3377}, 
                {ms: 1659423730313, onTime: 6300, x: 17957, y: 3301}, 
                {ms: 1659423732713, onTime: 2400, x: 18036, y: 3372}, 
                {ms: 1659423743913, onTime: 11200, x: 17704, y: 3514}, 
                {ms: 1659423744213, onTime: 300, x: 17788, y: 3598}, 
                {ms: 1659423744313, onTime: 100, x: 18009, y: 3471}, 
                {ms: 1659423744413, onTime: 100, x: 18011, y: 3637}, 
                {ms: 1659423744613, onTime: 200, x: 17748, y: 3598}, 
                {ms: 1659423745013, onTime: 400, x: 18008, y: 3605}, 
                {ms: 1659423745113, onTime: 100, x: 18006, y: 3445}, 
                {ms: 1659423745713, onTime: 600, x: 17767, y: 3556}, 
                {ms: 1659423746213, onTime: 500, x: 18011, y: 3601}, 
                {ms: 1659423746613, onTime: 400, x: 17752, y: 3506}, 
                {ms: 1659423746713, onTime: 100, x: 18024, y: 3478}, 
                {ms: 1659423747213, onTime: 500, x: 17957, y: 3348}, 
                {ms: 1659423753013, onTime: 5800, x: 18024, y: 3485}, 
                {ms: 1659423753613, onTime: 600, x: 18001, y: 3720}, 
                {ms: 1659423755513, onTime: 1900, x: 17991, y: 3596}, 
                {ms: 1659423756013, onTime: 500, x: 17985, y: 3462}, 
                {ms: 1659423756413, onTime: 400, x: 17972, y: 3362}, 
                {ms: 1659423758613, onTime: 2200, x: 17918, y: 3267}, 
                {ms: 1659423762213, onTime: 3600, x: 17949, y: 3397}, 
                {ms: 1659423763113, onTime: 900, x: 17900, y: 3876}, 
                {ms: 1659423764213, onTime: 1100, x: 17913, y: 3671}, 
                {ms: 1659423764513, onTime: 300, x: 17863, y: 3428}, 
                {ms: 1659423765713, onTime: 1200, x: 17943, y: 3347}, 
                {ms: 1659423766913, onTime: 1200, x: 18004, y: 3469}, 
                {ms: 1659423768613, onTime: 1700, x: 17906, y: 3429}, 
                {ms: 1659423769213, onTime: 600, x: 17706, y: 3465}, 
                {ms: 1659423769313, onTime: 100, x: 17953, y: 3361}, 
                {ms: 1659423769413, onTime: 100, x: 17677, y: 3472}, 
                {ms: 1659423771413, onTime: 2000, x: 17081, y: 3306}, 
                {ms: 1659423771613, onTime: 200, x: 17079, y: 3192}, 
                {ms: 1659423771713, onTime: 100, x: 17931, y: 3356}, 
                {ms: 1659423772113, onTime: 400, x: 17367, y: 3153}, 
                {ms: 1659423772213, onTime: 100, x: 17715, y: 3550}, 
                {ms: 1659423772313, onTime: 100, x: 17828, y: 3677}, 
                {ms: 1659423772413, onTime: 100, x: 17933, y: 3373}, 
                {ms: 1659423772513, onTime: 100, x: 17685, y: 3549}, 
                {ms: 1659423772613, onTime: 100, x: 17564, y: 3440}, 
                {ms: 1659423773113, onTime: 500, x: 17954, y: 3373}, 
                {ms: 1659423773213, onTime: 100, x: 17659, y: 3500}, 
                {ms: 1659423773513, onTime: 300, x: 17963, y: 3386}, 
                {ms: 1659423773613, onTime: 100, x: 17647, y: 3491}, 
                {ms: 1659423773813, onTime: 200, x: 17960, y: 3384}, 
                {ms: 1659423774113, onTime: 300, x: 17660, y: 3499}, 
                {ms: 1659423774513, onTime: 400, x: 17965, y: 3389}, 
                {ms: 1659423774713, onTime: 200, x: 17669, y: 3504}, 
                {ms: 1659423774913, onTime: 200, x: 17966, y: 3428}, 
                {ms: 1659423775013, onTime: 100, x: 17714, y: 3491}, 
                {ms: 1659423775313, onTime: 300, x: 17966, y: 3428}, 
                {ms: 1659423775713, onTime: 400, x: 18013, y: 3416}, 
                {ms: 1659423776313, onTime: 600, x: 18039, y: 3345}, 
                {ms: 1659423784513, onTime: 8200, x: 17932, y: 3310}, 
                {ms: 1659423784913, onTime: 400, x: 18004, y: 3388}, 
                {ms: 1659423788013, onTime: 3100, x: 18060, y: 3476}, 
                {ms: 1659423791613, onTime: 3600, x: 18047, y: 3350}, 
                {ms: 1659423799013, onTime: 7400, x: 18077, y: 3478}, 
                {ms: 1659423799613, onTime: 600, x: 18031, y: 3593}, 
                {ms: 1659423800813, onTime: 1200, x: 17997, y: 3468}, 
                {ms: 1659423801713, onTime: 900, x: 17983, y: 3313}, 
                {ms: 1659423802813, onTime: 1100, x: 18016, y: 3427}, 
                {ms: 1659423806513, onTime: 3700, x: 17993, y: 3318}, 
                {ms: 1659423831413, onTime: 24900, x: 17919, y: 3390}, 
                {ms: 1659423836413, onTime: 5000, x: 18023, y: 3297}, 
                {ms: 1659423838813, onTime: 2400, x: 17971, y: 3386}, 
                {ms: 1659423846313, onTime: 7500, x: 17990, y: 3216}, 
                {ms: 1659423847613, onTime: 1300, x: 18004, y: 3316}, 
                {ms: 1659423855613, onTime: 8000, x: 18006, y: 3531}, 
                {ms: 1659423858613, onTime: 3000, x: 17851, y: 3456}, 
                {ms: 1659423862113, onTime: 3500, x: 17878, y: 3571}, 
                {ms: 1659423862613, onTime: 500, x: 17930, y: 3700}, 
                {ms: 1659423862713, onTime: 100, x: 17938, y: 3708}, 
                {ms: 1659423863613, onTime: 900, x: 17911, y: 3557}, 
                {ms: 1659423864013, onTime: 400, x: 17882, y: 3450}, 
                {ms: 1659423864213, onTime: 200, x: 17913, y: 3398}, 
                {ms: 1659423864713, onTime: 500, x: 18005, y: 3376}, 
                {ms: 1659423867513, onTime: 2800, x: 17898, y: 3402}, 
                {ms: 1659423880613, onTime: 13100, x: 17860, y: 3510}, 
                {ms: 1659423881013, onTime: 400, x: 17896, y: 3611}, 
                {ms: 1659423881113, onTime: 100, x: 17918, y: 3699}, 
                {ms: 1659423881313, onTime: 200, x: 17864, y: 3709}, 
                {ms: 1659423884713, onTime: 3400, x: 17758, y: 3693}, 
                {ms: 1659423885313, onTime: 600, x: 17763, y: 3577}, 
                {ms: 1659423885713, onTime: 400, x: 17800, y: 3439}, 
                {ms: 1659423887213, onTime: 1500, x: 17898, y: 3479}, 
                {ms: 1659423888013, onTime: 800, x: 17964, y: 3562}, 
                {ms: 1659423890313, onTime: 2300, x: 17958, y: 3450}, 
                {ms: 1659423893013, onTime: 2700, x: 17944, y: 3385}, 
                {ms: 1659423893413, onTime: 400, x: 17929, y: 3347}, 
                {ms: 1659423896713, onTime: 3300, x: 17967, y: 3501}, 
                {ms: 1659423898213, onTime: 1500, x: 17964, y: 3393}, 
                {ms: 1659423904013, onTime: 5800, x: 17953, y: 3574}, 
                {ms: 1659423916513, onTime: 12500, x: 17868, y: 3521}
                , 
                {ms: 1659423916713, onTime: 200, x: 17775, y: 3597}
                , 
                {ms: 1659423917013, onTime: 300, x: 17671, y: 3698}
                , 
                {ms: 1659423917613, onTime: 600, x: 17594, y: 3596}
                , 
                {ms: 1659423918113, onTime: 500, x: 17702, y: 3691}
                , 
                {ms: 1659423920213, onTime: 2100, x: 17583, y: 3510}
                , 
                {ms: 1659423920313, onTime: 100, x: 17477, y: 3385}
                , 
                {ms: 1659423920713, onTime: 400, x: 17408, y: 3154}
                , 
                {ms: 1659423920813, onTime: 100, x: 17412, y: 3071}
                , 
                {ms: 1659423920913, onTime: 100, x: 17408, y: 2978}
                , 
                {ms: 1659423921013, onTime: 100, x: 17405, y: 2874}
                , 
                {ms: 1659423921213, onTime: 200, x: 17403, y: 2695}
                , 
                {ms: 1659423921413, onTime: 200, x: 17423, y: 2529}
                , 
                {ms: 1659423921613, onTime: 200, x: 17532, y: 2393}
                , 
                {ms: 1659423921813, onTime: 200, x: 17706, y: 2319}
                , 
                {ms: 1659423922013, onTime: 200, x: 17813, y: 2322}
                , 
                {ms: 1659423922113, onTime: 100, x: 17866, y: 2324}
                , 
                {ms: 1659423922313, onTime: 200, x: 17947, y: 2271}
                , 
                {ms: 1659423922513, onTime: 200, x: 17924, y: 2148}
                , 
                {ms: 1659423922613, onTime: 100, x: 17952, y: 2485}
                , 
                {ms: 1659423922713, onTime: 100, x: 17984, y: 2889}
                , 
                {ms: 1659423922813, onTime: 100, x: 18005, y: 3058}
                , 
                {ms: 1659423923113, onTime: 300, x: 17989, y: 2912}
                , 
                {ms: 1659424006213, onTime: 83100, x: 17914, y: 2838}
                , 
                {ms: 1659424007313, onTime: 1100, x: 17910, y: 2970}
                , 
                {ms: 1659424009913, onTime: 2600, x: 17743, y: 2710}
                , 
                {ms: 1659424010513, onTime: 600, x: 17899, y: 2894}
                , 
                {ms: 1659424011913, onTime: 1400, x: 17941, y: 3061}
                , 
                {ms: 1659424012413, onTime: 500, x: 17894, y: 2827}
                , 
                {ms: 1659424013213, onTime: 800, x: 17869, y: 2935}
                , 
                {ms: 1659424014013, onTime: 800, x: 17847, y: 2837}
                , 
                {ms: 1659424014513, onTime: 500, x: 17868, y: 2945}
                , 
                {ms: 1659424017713, onTime: 3200, x: 17741, y: 3475}
                , 
                {ms: 1659424018613, onTime: 900, x: 17863, y: 2895}
                , 
                {ms: 1659424020013, onTime: 1400, x: 17897, y: 3005}
                , 
                {ms: 1659424020213, onTime: 200, x: 17824, y: 3496}
                , 
                {ms: 1659424020413, onTime: 200, x: 17826, y: 2814}
                , 
                {ms: 1659424023513, onTime: 3100, x: 17862, y: 2948}
                , 
                {ms: 1659424025513, onTime: 2000, x: 17742, y: 3485}
                , 
                {ms: 1659424025913, onTime: 400, x: 17614, y: 2146}
                , 
                {ms: 1659424026113, onTime: 200, x: 17720, y: 2011}
                , 
                {ms: 1659424026313, onTime: 200, x: 17774, y: 1890}
                , 
                {ms: 1659424026613, onTime: 300, x: 17893, y: 1814}
                , 
                {ms: 1659424026813, onTime: 200, x: 18056, y: 1799}
                , 
                {ms: 1659424026913, onTime: 100, x: 18085, y: 1794}
                , 
                {ms: 1659424027313, onTime: 400, x: 18150, y: 1692}
                , 
                {ms: 1659424027513, onTime: 200, x: 18270, y: 1668}
                , 
                {ms: 1659424027613, onTime: 100, x: 18356, y: 1639}
                , 
                {ms: 1659424027713, onTime: 100, x: 18809, y: 1385}
                , 
                {ms: 1659424028013, onTime: 300, x: 18919, y: 1331}
                , 
                {ms: 1659424028213, onTime: 200, x: 19019, y: 1328}
                , 
                {ms: 1659424028413, onTime: 200, x: 19133, y: 1378}
                , 
                {ms: 1659424028613, onTime: 200, x: 19239, y: 1409}
                , 
                {ms: 1659424029213, onTime: 600, x: 19263, y: 1292}
                , 
                {ms: 1659424029913, onTime: 700, x: 18796, y: 1470}
                , 
                {ms: 1659424030013, onTime: 100, x: 19307, y: 1234}
                , 
                {ms: 1659424037113, onTime: 7100, x: 18813, y: 1497}
                , 
                {ms: 1659424039713, onTime: 2600, x: 19324, y: 1263}
                , 
                {ms: 1659424039813, onTime: 100, x: 18818, y: 1498}
                , 
                {ms: 1659424039913, onTime: 100, x: 19324, y: 1263}
                , 
                {ms: 1659424041713, onTime: 1800, x: 18822, y: 1496}
                , 
                {ms: 1659424042713, onTime: 1000, x: 19351, y: 1263}
                , 
                {ms: 1659424042813, onTime: 100, x: 18827, y: 1505}
                , 
                {ms: 1659424044713, onTime: 1900, x: 19319, y: 1270}
                , 
                {ms: 1659424048213, onTime: 3500, x: 18837, y: 1511}
                , 
                {ms: 1659424050113, onTime: 1900, x: 19324, y: 1278}
                , 
                {ms: 1659424053213, onTime: 3100, x: 18809, y: 1488}
                , 
                {ms: 1659424053813, onTime: 600, x: 19358, y: 1301}
                , 
                {ms: 1659424054813, onTime: 1000, x: 18820, y: 1510}
                , 
                {ms: 1659424055413, onTime: 600, x: 19348, y: 1318}
                , 
                {ms: 1659424055513, onTime: 100, x: 18841, y: 1508}
                , 
                {ms: 1659424057313, onTime: 1800, x: 19351, y: 1320}
                , 
                {ms: 1659424059813, onTime: 2500, x: 18836, y: 1493}
                , 
                {ms: 1659424060313, onTime: 500, x: 19314, y: 1314}
                , 
                {ms: 1659424062013, onTime: 1700, x: 18830, y: 1507}
                , 
                {ms: 1659424062213, onTime: 200, x: 19356, y: 1314}
                , 
                {ms: 1659424062413, onTime: 200, x: 18831, y: 1507}
                , 
                {ms: 1659424062613, onTime: 200, x: 19342, y: 1298}
                , 
                {ms: 1659424062713, onTime: 100, x: 18830, y: 1498}
                , 
                {ms: 1659424063913, onTime: 1200, x: 19385, y: 1314}
                , 
                {ms: 1659424064213, onTime: 300, x: 18840, y: 1514}
                , 
                {ms: 1659424064313, onTime: 100, x: 19352, y: 1275}
                , 
                {ms: 1659424065913, onTime: 1600, x: 18831, y: 1511}
                , 
                {ms: 1659424066413, onTime: 500, x: 19349, y: 1311}
                , 
                {ms: 1659424066513, onTime: 100, x: 18827, y: 1499}
                , 
                {ms: 1659424067013, onTime: 500, x: 19371, y: 1309}
                , 
                {ms: 1659424069213, onTime: 2200, x: 18824, y: 1500}
                , 
                {ms: 1659424069313, onTime: 100, x: 19397, y: 1287}
                , 
                {ms: 1659424072813, onTime: 3500, x: 18825, y: 1489}
                , 
                {ms: 1659424073113, onTime: 300, x: 19282, y: 1214}
                , 
                {ms: 1659424073213, onTime: 100, x: 18840, y: 1472}
                , 
                {ms: 1659424073613, onTime: 400, x: 19347, y: 1235}
                , 
                {ms: 1659424074013, onTime: 400, x: 18843, y: 1472}
                , 
                {ms: 1659424077013, onTime: 3000, x: 19306, y: 1258}
                , 
                {ms: 1659424077213, onTime: 200, x: 18775, y: 1468}
                , 
                {ms: 1659424079213, onTime: 2000, x: 19297, y: 1200}
                , 
                {ms: 1659424079813, onTime: 600, x: 18811, y: 1440}
                , 
                {ms: 1659424080113, onTime: 300, x: 19282, y: 1187}
                , 
                {ms: 1659424080313, onTime: 200, x: 18866, y: 1420}
                , 
                {ms: 1659424090513, onTime: 10200, x: 19413, y: 1126}
                , 
                {ms: 1659424091613, onTime: 1100, x: 19753, y: 972}
                , 
                {ms: 1659424094213, onTime: 2600, x: 19870, y: 1023}
                , 
                {ms: 1659424094813, onTime: 600, x: 19739, y: 1360}
                , 
                {ms: 1659424095813, onTime: 1000, x: 19745, y: 1260}
                , 
                {ms: 1659424097013, onTime: 1200, x: 19870, y: 1053}
                , 
                {ms: 1659424097613, onTime: 600, x: 19743, y: 1329}
                , 
                {ms: 1659424098413, onTime: 800, x: 19846, y: 1414}
                , 
                {ms: 1659424099713, onTime: 1300, x: 19887, y: 1559}
                , 
                {ms: 1659424100613, onTime: 900, x: 19912, y: 2196}
                , 
                {ms: 1659424100813, onTime: 200, x: 19955, y: 2334}
                , 
                {ms: 1659424101013, onTime: 200, x: 19993, y: 2476}
                , 
                {ms: 1659424101213, onTime: 200, x: 20006, y: 2581}
                , 
                {ms: 1659424101313, onTime: 100, x: 20034, y: 2707}
                , 
                {ms: 1659424101413, onTime: 100, x: 20038, y: 2808}
                , 
                {ms: 1659424101613, onTime: 200, x: 20069, y: 2911}
                , 
                {ms: 1659424101813, onTime: 200, x: 20262, y: 2851}
                , 
                {ms: 1659424101913, onTime: 100, x: 20416, y: 2771}
                , 
                {ms: 1659424102013, onTime: 100, x: 20530, y: 2748}
                , 
                {ms: 1659424102613, onTime: 600, x: 20772, y: 2453}
                , 
                {ms: 1659424102713, onTime: 100, x: 20988, y: 2415}
                , 
                {ms: 1659424102813, onTime: 100, x: 21230, y: 2508}
                , 
                {ms: 1659424102913, onTime: 100, x: 21747, y: 2403}
                , 
                {ms: 1659424103013, onTime: 100, x: 21976, y: 2396}
                , 
                {ms: 1659424103113, onTime: 100, x: 22102, y: 2307}
                , 
                {ms: 1659424103213, onTime: 100, x: 22142, y: 2668}
                , 
                {ms: 1659424103313, onTime: 100, x: 22157, y: 2804}
                , 
                {ms: 1659424103413, onTime: 100, x: 22046, y: 2793}
                , 
                {ms: 1659424103513, onTime: 100, x: 21895, y: 2641}
                , 
                {ms: 1659424103613, onTime: 100, x: 21774, y: 2480}
                , 
                {ms: 1659424103913, onTime: 300, x: 21554, y: 2667}
                , 
                {ms: 1659424104013, onTime: 100, x: 21286, y: 2904}
                , 
                {ms: 1659424104113, onTime: 100, x: 21190, y: 2981}
                , 
                {ms: 1659424104313, onTime: 200, x: 21335, y: 2967}
                , 
                {ms: 1659424104513, onTime: 200, x: 21435, y: 2950}
                , 
                {ms: 1659424104613, onTime: 100, x: 21361, y: 3022}
                , 
                {ms: 1659424104813, onTime: 200, x: 21613, y: 3102}
                , 
                {ms: 1659424105113, onTime: 300, x: 21438, y: 3114}
                , 
                {ms: 1659424105213, onTime: 100, x: 21747, y: 2878}
                , 
                {ms: 1659424105313, onTime: 100, x: 21811, y: 2833}
                , 
                {ms: 1659424105513, onTime: 200, x: 21840, y: 2825}
                , 
                {ms: 1659424105813, onTime: 300, x: 21954, y: 2870}
                , 
                {ms: 1659424106413, onTime: 600, x: 22055, y: 2853}
                , 
                {ms: 1659424106513, onTime: 100, x: 22341, y: 2779}
                , 
                {ms: 1659424109013, onTime: 2500, x: 22454, y: 2774}
                , 
                {ms: 1659424109213, onTime: 200, x: 22550, y: 2736}
                , 
                {ms: 1659424109713, onTime: 500, x: 22738, y: 2711}
                , 
                {ms: 1659424109913, onTime: 200, x: 22835, y: 2752}
                , 
                {ms: 1659424110613, onTime: 700, x: 23228, y: 2680}
                , 
                {ms: 1659424110813, onTime: 200, x: 23316, y: 2588}
                , 
                {ms: 1659424111113, onTime: 300, x: 23417, y: 2502}
                , 
                {ms: 1659424111213, onTime: 100, x: 23457, y: 2469}
                , 
                {ms: 1659424111313, onTime: 100, x: 23505, y: 2426}
                , 
                {ms: 1659424111513, onTime: 200, x: 23585, y: 2334}
                , 
                {ms: 1659424111813, onTime: 300, x: 23585, y: 2204}
                , 
                {ms: 1659424111913, onTime: 100, x: 23608, y: 2173}
                , 
                {ms: 1659424112113, onTime: 200, x: 23609, y: 2102}
                , 
                {ms: 1659424112713, onTime: 600, x: 23535, y: 2021}
                , 
                {ms: 1659424113013, onTime: 300, x: 23208, y: 1797}
                , 
                {ms: 1659424114213, onTime: 1200, x: 23164, y: 1607}
                , 
                {ms: 1659424114613, onTime: 400, x: 23104, y: 1496}
                , 
                {ms: 1659424115513, onTime: 900, x: 23311, y: 1863}
                , 
                {ms: 1659424115613, onTime: 100, x: 23106, y: 1513}
                , 
                {ms: 1659424115813, onTime: 200, x: 23287, y: 1828}
                , 
                {ms: 1659424116313, onTime: 500, x: 23117, y: 1531}
                , 
                {ms: 1659424116413, onTime: 100, x: 23290, y: 1866}
                , 
                {ms: 1659424116813, onTime: 400, x: 23156, y: 1620}
                , 
                {ms: 1659424117013, onTime: 200, x: 23199, y: 1714}
                , 
                {ms: 1659424118613, onTime: 1600, x: 23171, y: 1822}
                , 
                {ms: 1659424122113, onTime: 3500, x: 23232, y: 1741}
                , 
                {ms: 1659424122413, onTime: 300, x: 23445, y: 1661}
                , 
                {ms: 1659424122513, onTime: 100, x: 23456, y: 2079}
                , 
                {ms: 1659424122713, onTime: 200, x: 23436, y: 2154}
                , 
                {ms: 1659424122813, onTime: 100, x: 23432, y: 2181}
                , 
                {ms: 1659424123613, onTime: 800, x: 23311, y: 2487}
                , 
                {ms: 1659424123713, onTime: 100, x: 23444, y: 2217}
                , 
                {ms: 1659424123913, onTime: 200, x: 23313, y: 2472}
                , 
                {ms: 1659424124613, onTime: 700, x: 23299, y: 1956}
                , 
                {ms: 1659424124713, onTime: 100, x: 23313, y: 1923}
                , 
                {ms: 1659424125213, onTime: 500, x: 23517, y: 2329}
                , 
                {ms: 1659424126213, onTime: 1000, x: 23401, y: 1909}
                , 
                {ms: 1659424127713, onTime: 1500, x: 23512, y: 2342}
                , 
                {ms: 1659424128313, onTime: 600, x: 23453, y: 1919}
                , 
                {ms: 1659424128413, onTime: 100, x: 23508, y: 2377}
                , 
                {ms: 1659424130413, onTime: 2000, x: 23358, y: 2220}
                , 
                {ms: 1659424134813, onTime: 4400, x: 23224, y: 1819}
                , 
                {ms: 1659424136013, onTime: 1200, x: 23331, y: 2237}
                , 
                {ms: 1659424142513, onTime: 6500, x: 23234, y: 1833}
                , 
                {ms: 1659424146113, onTime: 3600, x: 23272, y: 2279}
                , 
                {ms: 1659424150313, onTime: 4200, x: 23227, y: 1827}
                , 
                {ms: 1659424151313, onTime: 1000, x: 23373, y: 2363}
                , 
                {ms: 1659424162713, onTime: 11400, x: 23274, y: 1906}
                , 
                {ms: 1659424162813, onTime: 100, x: 23337, y: 2356}
                , 
                {ms: 1659424162913, onTime: 100, x: 23275, y: 1906}
                , 
                {ms: 1659424163013, onTime: 100, x: 23339, y: 2370}
                , 
                {ms: 1659424163113, onTime: 100, x: 23275, y: 1906}
                , 
                {ms: 1659424163613, onTime: 500, x: 23344, y: 2350}
                , 
                {ms: 1659424165213, onTime: 1600, x: 23180, y: 1695}
                , 
                {ms: 1659424165413, onTime: 200, x: 23303, y: 2117}
                , 
                {ms: 1659424165713, onTime: 300, x: 23318, y: 1952}
                , 
                {ms: 1659424167913, onTime: 2200, x: 23122, y: 1509}
                , 
                {ms: 1659424168113, onTime: 200, x: 23318, y: 1919}
                , 
                {ms: 1659424168213, onTime: 100, x: 23313, y: 1781}
                , 
                {ms: 1659424168313, onTime: 100, x: 23117, y: 1348}
                , 
                {ms: 1659424168713, onTime: 400, x: 23300, y: 1714}
                , 
                {ms: 1659424168813, onTime: 100, x: 23135, y: 1355}
                , 
                {ms: 1659424169913, onTime: 1100, x: 23291, y: 1694}
                , 
                {ms: 1659424172613, onTime: 2700, x: 23435, y: 2096}
                , 
                {ms: 1659424178713, onTime: 6100, x: 23450, y: 1403}
                , 
                {ms: 1659424180113, onTime: 1400, x: 23504, y: 1843}
                , 
                {ms: 1659424180713, onTime: 600, x: 23226, y: 1685}
                , 
                {ms: 1659424185713, onTime: 5000, x: 23293, y: 1608}
                , 
                {ms: 1659424187013, onTime: 1300, x: 23580, y: 1843}
                , 
                {ms: 1659424187713, onTime: 700, x: 23274, y: 1646}
                , 
                {ms: 1659424189013, onTime: 1300, x: 23256, y: 1749}
                , 
                {ms: 1659424189913, onTime: 900, x: 23274, y: 1887}
                , 
                {ms: 1659424190613, onTime: 700, x: 23283, y: 1723}
                , 
                {ms: 1659424190713, onTime: 100, x: 23372, y: 2097}
                , 
                {ms: 1659424190813, onTime: 100, x: 23282, y: 1738}
                , 
                {ms: 1659424192813, onTime: 2000, x: 23423, y: 2117}
                , 
                {ms: 1659424193513, onTime: 700, x: 23325, y: 2166}
                , 
                {ms: 1659424195613, onTime: 2100, x: 23384, y: 2251}
                , 
                {ms: 1659424204213, onTime: 8600, x: 23194, y: 1772}
                , 
                {ms: 1659424204913, onTime: 700, x: 23287, y: 2217}
                , 
                {ms: 1659424207613, onTime: 2700, x: 23409, y: 2274}
                , 
                {ms: 1659424209813, onTime: 2200, x: 23277, y: 1838}
                , 
                {ms: 1659424210513, onTime: 700, x: 23559, y: 1894}
                , 
                {ms: 1659424210713, onTime: 200, x: 23582, y: 2045}
                , 
                {ms: 1659424210913, onTime: 200, x: 23295, y: 1867}
                , 
                {ms: 1659424211313, onTime: 400, x: 23584, y: 2046}
                , 
                {ms: 1659424212113, onTime: 800, x: 23580, y: 1920}
                , 
                {ms: 1659424212513, onTime: 400, x: 23603, y: 1797}
                , 
                {ms: 1659424214113, onTime: 1600, x: 23498, y: 2027}
                , 
                {ms: 1659424219513, onTime: 5400, x: 23308, y: 1673}
                , 
                {ms: 1659424224213, onTime: 4700, x: 23451, y: 2072}
                , 
                {ms: 1659424224313, onTime: 100, x: 23306, y: 1690}
                , 
                {ms: 1659424224713, onTime: 400, x: 23451, y: 2072}
                , 
                {ms: 1659424225913, onTime: 1200, x: 23298, y: 2118}
                , 
                {ms: 1659424226113, onTime: 200, x: 23538, y: 1852}
                , 
                {ms: 1659424227313, onTime: 1200, x: 23442, y: 2013}
                , 
                {ms: 1659424228913, onTime: 1600, x: 23295, y: 2309}
                , 
                {ms: 1659424235913, onTime: 7000, x: 23189, y: 1799}
                , 
                {ms: 1659424236913, onTime: 1000, x: 23329, y: 2244}
                , 
                {ms: 1659424241413, onTime: 4500, x: 23173, y: 1790}
                , 
                {ms: 1659424241513, onTime: 100, x: 23335, y: 2251}
                , 
                {ms: 1659424247213, onTime: 5700, x: 23203, y: 1800}
                , 
                {ms: 1659424248113, onTime: 900, x: 23324, y: 2239}
                , 
                {ms: 1659424249813, onTime: 1700, x: 23213, y: 1835}
                , 
                {ms: 1659424252413, onTime: 2600, x: 23166, y: 1724}
                , 
                {ms: 1659424252613, onTime: 200, x: 23308, y: 2159}
                , 
                {ms: 1659424252913, onTime: 300, x: 23175, y: 1707}
                , 
                {ms: 1659424253113, onTime: 200, x: 23316, y: 2145}
                , 
                {ms: 1659424261213, onTime: 8100, x: 23222, y: 1701}
                , 
                {ms: 1659424261613, onTime: 400, x: 23338, y: 2152}
                , 
                {ms: 1659424261713, onTime: 100, x: 23212, y: 1734}
                , 
                {ms: 1659424262013, onTime: 300, x: 23317, y: 2135}
                , 
                {ms: 1659424264513, onTime: 2500, x: 23199, y: 1724}
                , 
                {ms: 1659424264613, onTime: 100, x: 23307, y: 2131}
                , 
                {ms: 1659424264913, onTime: 300, x: 23200, y: 1723}
                , 
                {ms: 1659424265113, onTime: 200, x: 23327, y: 2165}
                , 
                {ms: 1659424265613, onTime: 500, x: 23204, y: 1725}
                , 
                {ms: 1659424265813, onTime: 200, x: 23311, y: 2155}
                , 
                {ms: 1659424267513, onTime: 1700, x: 23144, y: 1700}
                , 
                {ms: 1659424267913, onTime: 400, x: 23293, y: 2172}
                , 
                {ms: 1659424270413, onTime: 2500, x: 23412, y: 2406}
                , 
                {ms: 1659424270813, onTime: 400, x: 23386, y: 2521}
                , 
                {ms: 1659424271013, onTime: 200, x: 23359, y: 2632}
                , 
                {ms: 1659424271213, onTime: 200, x: 23323, y: 2755}
                , 
                {ms: 1659424271313, onTime: 100, x: 23328, y: 2845}
                , 
                {ms: 1659424271413, onTime: 100, x: 23331, y: 2876}
                , 
                {ms: 1659424271713, onTime: 300, x: 23326, y: 3027}
                , 
                {ms: 1659424271913, onTime: 200, x: 23357, y: 3171}
                , 
                {ms: 1659424272113, onTime: 200, x: 23397, y: 3310}
                , 
                {ms: 1659424272213, onTime: 100, x: 23250, y: 2946}
                , 
                {ms: 1659424272513, onTime: 300, x: 23400, y: 3514}
                , 
                {ms: 1659424272713, onTime: 200, x: 23377, y: 3656}
                , 
                {ms: 1659424272913, onTime: 200, x: 23364, y: 3785}
                , 
                {ms: 1659424273013, onTime: 100, x: 23370, y: 3825}
                , 
                {ms: 1659424273213, onTime: 200, x: 23401, y: 3895}
                , 
                {ms: 1659424273413, onTime: 200, x: 23483, y: 3971}
                , 
                {ms: 1659424273613, onTime: 200, x: 23569, y: 4042}
                , 
                {ms: 1659424273713, onTime: 100, x: 23601, y: 4080}
                , 
                {ms: 1659424273913, onTime: 200, x: 23652, y: 4142}
                , 
                {ms: 1659424274113, onTime: 200, x: 23734, y: 4212}
                , 
                {ms: 1659424274313, onTime: 200, x: 23859, y: 4298}
                , 
                {ms: 1659424274613, onTime: 300, x: 23984, y: 4436}
                , 
                {ms: 1659424274813, onTime: 200, x: 24093, y: 4460}
                , 
                {ms: 1659424275213, onTime: 400, x: 24576, y: 4339}
                , 
                {ms: 1659424275713, onTime: 500, x: 25046, y: 4379}
                , 
                {ms: 1659424275913, onTime: 200, x: 25231, y: 4392}
                , 
                {ms: 1659424276113, onTime: 200, x: 25332, y: 4418}
                , 
                {ms: 1659424276213, onTime: 100, x: 25359, y: 4420}
                , 
                {ms: 1659424276413, onTime: 200, x: 25472, y: 4404}
                , 
                {ms: 1659424276613, onTime: 200, x: 25597, y: 4374}
                , 
                {ms: 1659424276713, onTime: 100, x: 25673, y: 4373}
                , 
                {ms: 1659424276813, onTime: 100, x: 25713, y: 4392}
                , 
                {ms: 1659424277113, onTime: 300, x: 25852, y: 4465}
                , 
                {ms: 1659424277313, onTime: 200, x: 25999, y: 4518}
                , 
                {ms: 1659424277513, onTime: 200, x: 26108, y: 4560}
                , 
                {ms: 1659424277713, onTime: 200, x: 26208, y: 4585}
                , 
                {ms: 1659424277913, onTime: 200, x: 26322, y: 4620}
                , 
                {ms: 1659424278113, onTime: 200, x: 26473, y: 4693}
                , 
                {ms: 1659424278313, onTime: 200, x: 26592, y: 4740}
                , 
                {ms: 1659424278413, onTime: 100, x: 26632, y: 4775}
                , 
                {ms: 1659424278513, onTime: 100, x: 26657, y: 4820}
                , 
                {ms: 1659424278713, onTime: 200, x: 26711, y: 4956}
                , 
                {ms: 1659424278913, onTime: 200, x: 26787, y: 5096}
                , 
                {ms: 1659424279113, onTime: 200, x: 26896, y: 5207}
                , 
                {ms: 1659424279313, onTime: 200, x: 26934, y: 5319}
                , 
                {ms: 1659424279413, onTime: 100, x: 26965, y: 5377}
                , 
                {ms: 1659424279513, onTime: 100, x: 27008, y: 5415}
                , 
                {ms: 1659424279713, onTime: 200, x: 27067, y: 5533}
                , 
                {ms: 1659424280013, onTime: 300, x: 27056, y: 5681}
                , 
                {ms: 1659424280213, onTime: 200, x: 27016, y: 5814}
                , 
                {ms: 1659424280413, onTime: 200, x: 26992, y: 5933}
                , 
                {ms: 1659424280513, onTime: 100, x: 26984, y: 5978}
                , 
                {ms: 1659424280713, onTime: 200, x: 26985, y: 6062}
                , 
                {ms: 1659424280913, onTime: 200, x: 27002, y: 6177}
                , 
                {ms: 1659424281013, onTime: 100, x: 26849, y: 6189}
                , 
                {ms: 1659424281213, onTime: 200, x: 26892, y: 6311}
                , 
                {ms: 1659424281413, onTime: 200, x: 26923, y: 6422}
                , 
                {ms: 1659424281613, onTime: 200, x: 26921, y: 6535}
                , 
                {ms: 1659424281813, onTime: 200, x: 26900, y: 6658}
                , 
                {ms: 1659424282013, onTime: 200, x: 26900, y: 6792}
                , 
                {ms: 1659424282213, onTime: 200, x: 26934, y: 6904}
                , 
                {ms: 1659424282313, onTime: 100, x: 27009, y: 7008}
                , 
                {ms: 1659424282613, onTime: 300, x: 27179, y: 7199}
                , 
                {ms: 1659424282813, onTime: 200, x: 27227, y: 7259}
                , 
                {ms: 1659424282913, onTime: 100, x: 27258, y: 7298}
                , 
                {ms: 1659424283113, onTime: 200, x: 27328, y: 7419}
                , 
                {ms: 1659424283313, onTime: 200, x: 27397, y: 7522}
                , 
                {ms: 1659424283513, onTime: 200, x: 27571, y: 7653}
                , 
                {ms: 1659424284113, onTime: 600, x: 27844, y: 7635}
                , 
                {ms: 1659424284413, onTime: 300, x: 27742, y: 7729}
                , 
                {ms: 1659424285013, onTime: 600, x: 28822, y: 7466}
                , 
                {ms: 1659424285113, onTime: 100, x: 27817, y: 7782}
                , 
                {ms: 1659424285213, onTime: 100, x: 28833, y: 7459}
                , 
                {ms: 1659424286413, onTime: 1200, x: 28016, y: 7716}
                , 
                {ms: 1659424286513, onTime: 100, x: 28796, y: 7422}
                , 
                {ms: 1659424287713, onTime: 1200, x: 28937, y: 7426}
                , 
                {ms: 1659424288413, onTime: 700, x: 28984, y: 7334}
                , 
                {ms: 1659424289213, onTime: 800, x: 29030, y: 7442}
                , 
                {ms: 1659424293113, onTime: 3900, x: 28942, y: 7362}
                , 
                {ms: 1659424295013, onTime: 1900, x: 28843, y: 7446}
                , 
                {ms: 1659424295613, onTime: 600, x: 28635, y: 7488}
                , 
                {ms: 1659424295813, onTime: 200, x: 28963, y: 7369}
                , 
                {ms: 1659424297313, onTime: 1500, x: 29106, y: 7290}
                , 
                {ms: 1659424298313, onTime: 1000, x: 28983, y: 7343}
                , 
                {ms: 1659424300113, onTime: 1800, x: 29086, y: 7266}
                , 
                {ms: 1659424300313, onTime: 200, x: 28993, y: 7329}
                , 
                {ms: 1659424300813, onTime: 500, x: 28827, y: 7459}
                , 
                {ms: 1659424301913, onTime: 1100, x: 28721, y: 7440}
                , 
                {ms: 1659424305813, onTime: 3900, x: 28828, y: 7450}
                , 
                {ms: 1659424307813, onTime: 2000, x: 28717, y: 7427}
                , 
                {ms: 1659424327013, onTime: 19200, x: 28867, y: 7512}
                , 
                {ms: 1659424327513, onTime: 500, x: 28986, y: 7588}
                , 
                {ms: 1659424327813, onTime: 300, x: 28948, y: 7703}
                , 
                {ms: 1659424328313, onTime: 500, x: 28976, y: 7866}
                , 
                {ms: 1659424328913, onTime: 600, x: 28972, y: 8000}
                , 
                {ms: 1659424332613, onTime: 3700, x: 29079, y: 7991}
                , 
                {ms: 1659424333513, onTime: 900, x: 29191, y: 8009}
                , 
                {ms: 1659424333913, onTime: 400, x: 29060, y: 8048}
                , 
                {ms: 1659424334113, onTime: 200, x: 28903, y: 8057}
                , 
                {ms: 1659424339413, onTime: 5300, x: 28802, y: 7917}
                , 
                {ms: 1659424340613, onTime: 1200, x: 28892, y: 8012}
                , 
                {ms: 1659424346413, onTime: 5800, x: 28794, y: 7936}
                , 
                {ms: 1659424346913, onTime: 500, x: 28901, y: 8074}
                , 
                {ms: 1659424350513, onTime: 3600, x: 28869, y: 7968}
                , 
                {ms: 1659424355613, onTime: 5100, x: 28889, y: 8066}
                , 
                {ms: 1659424359413, onTime: 3800, x: 28887, y: 7888}
                , 
                {ms: 1659424359613, onTime: 200, x: 27955, y: 7746}
                , 
                {ms: 1659424360313, onTime: 700, x: 27603, y: 7745}
                , 
                {ms: 1659424360513, onTime: 200, x: 27955, y: 7746}
                , 
                {ms: 1659424360713, onTime: 200, x: 27420, y: 7727}
                , 
                {ms: 1659424360913, onTime: 200, x: 27308, y: 7739}
                , 
                {ms: 1659424361213, onTime: 300, x: 27159, y: 7809}
                , 
                {ms: 1659424361313, onTime: 100, x: 27099, y: 7836}
                , 
                {ms: 1659424361413, onTime: 100, x: 27047, y: 7851}
                , 
                {ms: 1659424361613, onTime: 200, x: 26949, y: 7807}
                , 
                {ms: 1659424362113, onTime: 500, x: 26805, y: 7932}
                , 
                {ms: 1659424363513, onTime: 1400, x: 26862, y: 7841}
                , 
                {ms: 1659424365313, onTime: 1800, x: 26938, y: 7763}
                , 
                {ms: 1659424365513, onTime: 200, x: 26988, y: 7852}
                , 
                {ms: 1659424367113, onTime: 1600, x: 26914, y: 7618}
                , 
                {ms: 1659424368113, onTime: 1000, x: 26914, y: 7729}
                , 
                {ms: 1659424370213, onTime: 2100, x: 27025, y: 7770}
                , 
                {ms: 1659424373113, onTime: 2900, x: 27141, y: 7830}
                , 
                {ms: 1659424373313, onTime: 200, x: 27278, y: 7780}
                , 
                {ms: 1659424373713, onTime: 400, x: 27040, y: 7727}
                , 
                {ms: 1659424374213, onTime: 500, x: 27620, y: 7708}
                , 
                {ms: 1659424375113, onTime: 900, x: 27752, y: 7738}
                , 
                {ms: 1659424377813, onTime: 2700, x: 27809, y: 7823}
                , 
                {ms: 1659424391613, onTime: 13800, x: 27740, y: 7718}
                , 
                {ms: 1659424393613, onTime: 2000, x: 27813, y: 7816}
                , 
                {ms: 1659424398513, onTime: 4900, x: 27771, y: 7719}
                , 
                {ms: 1659424400313, onTime: 1800, x: 27672, y: 7635}
                , 
                {ms: 1659424402913, onTime: 2600, x: 27766, y: 7734}
                , 
                {ms: 1659424404913, onTime: 2000, x: 27848, y: 7863}
                , 
                {ms: 1659424405713, onTime: 800, x: 27892, y: 7863}
                , 
                {ms: 1659424406613, onTime: 900, x: 27936, y: 7778}
                , 
                {ms: 1659424412213, onTime: 5600, x: 27904, y: 7636}
                , 
                {ms: 1659424412713, onTime: 500, x: 27900, y: 7941}
                , 
                {ms: 1659424412913, onTime: 200, x: 27782, y: 7799}
                , 
                {ms: 1659424414213, onTime: 1300, x: 27076, y: 7862}
                , 
                {ms: 1659424414513, onTime: 300, x: 26949, y: 7845}
                , 
                {ms: 1659424414813, onTime: 300, x: 26859, y: 7902}
                , 
                {ms: 1659424415313, onTime: 500, x: 27048, y: 7860}
                , 
                {ms: 1659424415913, onTime: 600, x: 26947, y: 7931}
                , 
                {ms: 1659424416013, onTime: 100, x: 26982, y: 7836}
                , 
                {ms: 1659424422413, onTime: 6400, x: 26936, y: 7747}
                , 
                {ms: 1659424424413, onTime: 2000, x: 26463, y: 8114}
                , 
                {ms: 1659424425713, onTime: 1300, x: 26581, y: 8091}
                , 
                {ms: 1659424427313, onTime: 1600, x: 26457, y: 8137}
                , 
                {ms: 1659424429613, onTime: 2300, x: 25861, y: 7739}
                , 
                {ms: 1659424429913, onTime: 300, x: 25979, y: 7689}
                , 
                {ms: 1659424430313, onTime: 400, x: 26097, y: 7636}
                , 
                {ms: 1659424430413, onTime: 100, x: 26141, y: 7607}
                , 
                {ms: 1659424430513, onTime: 100, x: 26178, y: 7576}
                , 
                {ms: 1659424430813, onTime: 300, x: 26303, y: 7460}
                , 
                {ms: 1659424431113, onTime: 300, x: 26348, y: 7290}
                , 
                {ms: 1659424431313, onTime: 200, x: 26399, y: 7202}
                , 
                {ms: 1659424431613, onTime: 300, x: 26513, y: 7114}
                , 
                {ms: 1659424431813, onTime: 200, x: 26530, y: 6982}
                , 
                {ms: 1659424432013, onTime: 200, x: 26590, y: 6844}
                , 
                {ms: 1659424432413, onTime: 400, x: 26611, y: 6734}
                , 
                {ms: 1659424432513, onTime: 100, x: 26590, y: 6685}
                , 
                {ms: 1659424432613, onTime: 100, x: 26563, y: 6628}
                , 
                {ms: 1659424432813, onTime: 200, x: 26535, y: 6491}
                , 
                {ms: 1659424433113, onTime: 300, x: 26544, y: 6377}
                , 
                {ms: 1659424433413, onTime: 300, x: 26577, y: 6253}
                , 
                {ms: 1659424433613, onTime: 200, x: 26603, y: 6119}
                , 
                {ms: 1659424433713, onTime: 100, x: 26620, y: 6063}
                , 
                {ms: 1659424433813, onTime: 100, x: 26641, y: 5995}
                , 
                {ms: 1659424434113, onTime: 300, x: 26664, y: 5880}
                , 
                {ms: 1659424434313, onTime: 200, x: 26671, y: 5743}
                , 
                {ms: 1659424434413, onTime: 100, x: 26692, y: 5615}
                , 
                {ms: 1659424434613, onTime: 200, x: 26733, y: 5504}
                , 
                {ms: 1659424434813, onTime: 200, x: 26760, y: 5405}
                , 
                {ms: 1659424435013, onTime: 200, x: 26792, y: 5267}
                , 
                {ms: 1659424435213, onTime: 200, x: 26828, y: 5141}
                , 
                {ms: 1659424435313, onTime: 100, x: 26847, y: 5088}
                , 
                {ms: 1659424435413, onTime: 100, x: 27498, y: 4304}
                , 
                {ms: 1659424435713, onTime: 300, x: 27433, y: 4134}
                , 
                {ms: 1659424436113, onTime: 400, x: 27384, y: 4041}
                , 
                {ms: 1659424436213, onTime: 100, x: 26935, y: 4655}
                , 
                {ms: 1659424436413, onTime: 200, x: 27338, y: 4007}
                , 
                {ms: 1659424437213, onTime: 800, x: 27457, y: 3960}
                , 
                {ms: 1659424437913, onTime: 700, x: 27515, y: 3757}
                , 
                {ms: 1659424439213, onTime: 1300, x: 27429, y: 3601}
                , 
                {ms: 1659424440813, onTime: 1600, x: 27591, y: 4283}
                , 
                {ms: 1659424441213, onTime: 400, x: 27441, y: 3621}
                , 
                {ms: 1659424441713, onTime: 500, x: 27738, y: 3829}
                , 
                {ms: 1659424441813, onTime: 100, x: 27441, y: 3621}
                , 
                {ms: 1659424442313, onTime: 500, x: 27733, y: 3916}
                , 
                {ms: 1659424442413, onTime: 100, x: 27416, y: 3587}
                , 
                {ms: 1659424442613, onTime: 200, x: 27728, y: 3828}
                , 
                {ms: 1659424443613, onTime: 1000, x: 27830, y: 3875}
                , 
                {ms: 1659424443913, onTime: 300, x: 27428, y: 3582}
                , 
                {ms: 1659424445213, onTime: 1300, x: 27422, y: 3757}
                , 
                {ms: 1659424446013, onTime: 800, x: 27336, y: 3943}
                , 
                {ms: 1659424447513, onTime: 1500, x: 27379, y: 3842}
                , 
                {ms: 1659424448213, onTime: 700, x: 27404, y: 3664}
                , 
                {ms: 1659424448413, onTime: 200, x: 27427, y: 3611}
                , 
                {ms: 1659424450113, onTime: 1700, x: 27364, y: 3482}
                , 
                {ms: 1659424451313, onTime: 1200, x: 27439, y: 3599}
                , 
                {ms: 1659424467613, onTime: 16300, x: 27422, y: 3982}
                , 
                {ms: 1659424469013, onTime: 1400, x: 27000, y: 5494}
                , 
                {ms: 1659424469213, onTime: 200, x: 26878, y: 5628}
                , 
                {ms: 1659424469313, onTime: 100, x: 26796, y: 5705}
                , 
                {ms: 1659424469413, onTime: 100, x: 26734, y: 5771}
                , 
                {ms: 1659424469513, onTime: 100, x: 26685, y: 5821}
                , 
                {ms: 1659424469713, onTime: 200, x: 26586, y: 5916}
                , 
                {ms: 1659424469913, onTime: 200, x: 26468, y: 5999}
                , 
                {ms: 1659424470513, onTime: 600, x: 26408, y: 6102}
                , 
                {ms: 1659424470713, onTime: 200, x: 26473, y: 6245}
                , 
                {ms: 1659424470913, onTime: 200, x: 26463, y: 6311}
                , 
                {ms: 1659424471013, onTime: 100, x: 26482, y: 6360}
                , 
                {ms: 1659424471113, onTime: 100, x: 26614, y: 6479}
                , 
                {ms: 1659424471313, onTime: 200, x: 26599, y: 6664}
                , 
                {ms: 1659424471413, onTime: 100, x: 26589, y: 6771}
                , 
                {ms: 1659424471513, onTime: 100, x: 26464, y: 6790}
                , 
                {ms: 1659424471713, onTime: 200, x: 26469, y: 6953}
                , 
                {ms: 1659424471813, onTime: 100, x: 26482, y: 7105}
                , 
                {ms: 1659424472113, onTime: 300, x: 26476, y: 7282}
                , 
                {ms: 1659424472213, onTime: 100, x: 26411, y: 7417}
                , 
                {ms: 1659424472413, onTime: 200, x: 26279, y: 7527}
                , 
                {ms: 1659424472513, onTime: 100, x: 26197, y: 7589}
                , 
                {ms: 1659424472713, onTime: 200, x: 26054, y: 7812}
                , 
                {ms: 1659424472813, onTime: 100, x: 25942, y: 7936}
                , 
                {ms: 1659424473013, onTime: 200, x: 25859, y: 8000}
                , 
                {ms: 1659424473113, onTime: 100, x: 25701, y: 7940}
                , 
                {ms: 1659424473213, onTime: 100, x: 25583, y: 7982}
                , 
                {ms: 1659424473313, onTime: 100, x: 25457, y: 8057}
                , 
                {ms: 1659424473413, onTime: 100, x: 25367, y: 8106}
                , 
                {ms: 1659424473513, onTime: 100, x: 25257, y: 8146}
                , 
                {ms: 1659424473613, onTime: 100, x: 25143, y: 8169}
                , 
                {ms: 1659424473713, onTime: 100, x: 25055, y: 8175}
                , 
                {ms: 1659424473813, onTime: 100, x: 24974, y: 8169}
                , 
                {ms: 1659424474013, onTime: 200, x: 24816, y: 8195}
                , 
                {ms: 1659424474113, onTime: 100, x: 24717, y: 8226}
                , 
                {ms: 1659424474213, onTime: 100, x: 24616, y: 8248}
                , 
                {ms: 1659424474413, onTime: 200, x: 24459, y: 8245}
                , 
                {ms: 1659424474513, onTime: 100, x: 24351, y: 8255}
                , 
                {ms: 1659424474613, onTime: 100, x: 24232, y: 8269}
                , 
                {ms: 1659424474813, onTime: 200, x: 24069, y: 8255}
                , 
                {ms: 1659424474913, onTime: 100, x: 23998, y: 8248}
                , 
                {ms: 1659424475013, onTime: 100, x: 23915, y: 8234}
                , 
                {ms: 1659424475213, onTime: 200, x: 23757, y: 8192}
                , 
                {ms: 1659424475313, onTime: 100, x: 23677, y: 8169}
                , 
                {ms: 1659424475413, onTime: 100, x: 23587, y: 8143}
                , 
                {ms: 1659424475613, onTime: 200, x: 23447, y: 8082}
                , 
                {ms: 1659424475713, onTime: 100, x: 23358, y: 8051}
                , 
                {ms: 1659424475813, onTime: 100, x: 23275, y: 8009}
                , 
                {ms: 1659424476013, onTime: 200, x: 22893, y: 8109}
                , 
                {ms: 1659424476113, onTime: 100, x: 22968, y: 8746}
                , 
                {ms: 1659424476213, onTime: 100, x: 22743, y: 8098}
                , 
                {ms: 1659424476313, onTime: 100, x: 22932, y: 8889}
                , 
                {ms: 1659424476513, onTime: 200, x: 22830, y: 8898}
                , 
                {ms: 1659424477113, onTime: 600, x: 22854, y: 8696}
                , 
                {ms: 1659424477913, onTime: 800, x: 22892, y: 8837}
                , 
                {ms: 1659424478213, onTime: 300, x: 22849, y: 8952}
                , 
                {ms: 1659424478513, onTime: 300, x: 22784, y: 9111}
                , 
                {ms: 1659424478613, onTime: 100, x: 22790, y: 9158}
                , 
                {ms: 1659424479613, onTime: 1000, x: 22878, y: 9662}
                , 
                {ms: 1659424479713, onTime: 100, x: 22734, y: 9649}
                , 
                {ms: 1659424479913, onTime: 200, x: 22816, y: 9829}
                , 
                {ms: 1659424480113, onTime: 200, x: 22656, y: 9929}
                , 
                {ms: 1659424480213, onTime: 100, x: 22798, y: 10043}
                , 
                {ms: 1659424480313, onTime: 100, x: 22683, y: 10017}
                , 
                {ms: 1659424480513, onTime: 200, x: 22799, y: 10223}
                , 
                {ms: 1659424480913, onTime: 400, x: 22789, y: 10345}
                , 
                {ms: 1659424481613, onTime: 700, x: 22798, y: 10199}
                , 
                {ms: 1659424481713, onTime: 100, x: 22619, y: 10125}
                , 
                {ms: 1659424481913, onTime: 200, x: 22561, y: 10217}
                , 
                {ms: 1659424482413, onTime: 500, x: 22572, y: 10086}
                , 
                {ms: 1659424482513, onTime: 100, x: 22566, y: 10015}
                , 
                {ms: 1659424482613, onTime: 100, x: 22572, y: 9926}
                , 
                {ms: 1659424482813, onTime: 200, x: 22583, y: 9794}
                , 
                {ms: 1659424483013, onTime: 200, x: 22641, y: 9707}
                , 
                {ms: 1659424483213, onTime: 200, x: 22692, y: 9533}
                , 
                {ms: 1659424483313, onTime: 100, x: 22683, y: 9423}
                , 
                {ms: 1659424483413, onTime: 100, x: 22661, y: 9340}
                , 
                {ms: 1659424483513, onTime: 100, x: 22653, y: 9284}
                , 
                {ms: 1659424483713, onTime: 200, x: 22761, y: 9088}
                , 
                {ms: 1659424483913, onTime: 200, x: 22752, y: 8999}
                , 
                {ms: 1659424484013, onTime: 100, x: 22737, y: 8943}
                , 
                {ms: 1659424484213, onTime: 200, x: 22700, y: 8828}
                , 
                {ms: 1659424484513, onTime: 300, x: 22718, y: 8695}
                , 
                {ms: 1659424484813, onTime: 300, x: 22769, y: 8509}
                , 
                {ms: 1659424485013, onTime: 200, x: 22810, y: 8388}
                , 
                {ms: 1659424485213, onTime: 200, x: 22876, y: 8282}
                , 
                {ms: 1659424485513, onTime: 300, x: 22904, y: 8122}
                , 
                {ms: 1659424485613, onTime: 100, x: 22912, y: 8079}
                , 
                {ms: 1659424485813, onTime: 200, x: 22972, y: 7976}
                , 
                {ms: 1659424486013, onTime: 200, x: 23023, y: 7863}
                , 
                {ms: 1659424486113, onTime: 100, x: 23038, y: 7799}
                , 
                {ms: 1659424486213, onTime: 100, x: 23048, y: 7733}
                , 
                {ms: 1659424486513, onTime: 300, x: 23241, y: 7579}
                , 
                {ms: 1659424486613, onTime: 100, x: 23261, y: 7540}
                , 
                {ms: 1659424486713, onTime: 100, x: 23283, y: 7486}
                , 
                {ms: 1659424486813, onTime: 100, x: 23332, y: 7369}
                , 
                {ms: 1659424487213, onTime: 400, x: 24203, y: 6538}
                , 
                {ms: 1659424488513, onTime: 1300, x: 25171, y: 6343}
                , 
                {ms: 1659424489813, onTime: 1300, x: 25084, y: 6410}
                , 
                {ms: 1659424495513, onTime: 5700, x: 25236, y: 6326}
                , 
                {ms: 1659424504113, onTime: 8600, x: 25148, y: 6374}
                , 
                {ms: 1659424506213, onTime: 2100, x: 25033, y: 6375}
                , 
                {ms: 1659424509613, onTime: 3400, x: 25112, y: 6441}
                , 
                {ms: 1659424510413, onTime: 800, x: 25296, y: 6320}
                , 
                {ms: 1659424518113, onTime: 7700, x: 25360, y: 6240}
                , 
                {ms: 1659424520913, onTime: 2800, x: 25225, y: 6223}
                , 
                {ms: 1659424522113, onTime: 1200, x: 25136, y: 6164}
                , 
                {ms: 1659424531513, onTime: 9400, x: 25216, y: 6239}
                , 
                {ms: 1659424531913, onTime: 400, x: 25339, y: 6273}
                , 
                {ms: 1659424532613, onTime: 700, x: 25397, y: 6355}
                , 
                {ms: 1659424566513, onTime: 33900, x: 25293, y: 6348}
                , 
                {ms: 1659424567413, onTime: 900, x: 25186, y: 6358}
                , 
                {ms: 1659424570613, onTime: 3200, x: 25257, y: 6430}
                , 
                {ms: 1659424571213, onTime: 600, x: 25356, y: 6335}
                , 
                {ms: 1659424571713, onTime: 500, x: 25408, y: 6238}
                , 
                {ms: 1659424572213, onTime: 500, x: 25311, y: 6187}
                , 
                {ms: 1659424572913, onTime: 700, x: 25226, y: 6125}
                , 
                {ms: 1659424585513, onTime: 12600, x: 25326, y: 6185}
                , 
                {ms: 1659424595813, onTime: 10300, x: 25149, y: 6208}
                , 
                {ms: 1659424596213, onTime: 400, x: 25036, y: 6231}
                , 
                {ms: 1659424606613, onTime: 10400, x: 24922, y: 6194}
                , 
                {ms: 1659424606813, onTime: 200, x: 24859, y: 6185}
                , 
                {ms: 1659424607113, onTime: 300, x: 24801, y: 6186}
                , 
                {ms: 1659424608413, onTime: 1300, x: 24838, y: 6092}
                , 
                {ms: 1659424621513, onTime: 13100, x: 24937, y: 6074}
                , 
                {ms: 1659424622713, onTime: 1200, x: 24812, y: 6101}
                , 
                {ms: 1659424623113, onTime: 400, x: 24678, y: 6110}
                , 
                {ms: 1659424624013, onTime: 900, x: 24569, y: 6124}
                , 
                {ms: 1659424624113, onTime: 100, x: 24562, y: 6121}
                , 
                {ms: 1659424630313, onTime: 6200, x: 24226, y: 6241}
                , 
                {ms: 1659424630713, onTime: 400, x: 24585, y: 6129}
                , 
                {ms: 1659424631213, onTime: 500, x: 24216, y: 6251}
                , 
                {ms: 1659424632913, onTime: 1700, x: 24815, y: 6131}
                , 
                {ms: 1659424633013, onTime: 100, x: 24238, y: 6288}
                , 
                {ms: 1659424633113, onTime: 100, x: 24623, y: 6088}
                , 
                {ms: 1659424633213, onTime: 100, x: 24965, y: 6032}
                , 
                {ms: 1659424633513, onTime: 300, x: 24916, y: 6135}
                , 
                {ms: 1659424633913, onTime: 400, x: 25024, y: 6134}
                , 
                {ms: 1659424634313, onTime: 400, x: 25138, y: 6173}
                , 
                {ms: 1659424634913, onTime: 600, x: 25331, y: 6167}
                , 
                {ms: 1659424635513, onTime: 600, x: 25560, y: 6122}
                , 
                {ms: 1659424635813, onTime: 300, x: 25686, y: 6171}
                , 
                {ms: 1659424636313, onTime: 500, x: 25834, y: 6244}
                , 
                {ms: 1659424636713, onTime: 400, x: 25945, y: 6233}
                , 
                {ms: 1659424636913, onTime: 200, x: 26035, y: 6188}
                , 
                {ms: 1659424637213, onTime: 300, x: 26140, y: 6144}
                , 
                {ms: 1659424641013, onTime: 3800, x: 26805, y: 5792}
                , 
                {ms: 1659424641813, onTime: 800, x: 26774, y: 5632}
                , 
                {ms: 1659424642113, onTime: 300, x: 26567, y: 5671}
                , 
                {ms: 1659424649913, onTime: 7800, x: 26645, y: 5606}
                , 
                {ms: 1659424650313, onTime: 400, x: 26726, y: 5539}
                , 
                {ms: 1659424651313, onTime: 1000, x: 26568, y: 5633}
                , 
                {ms: 1659424651913, onTime: 600, x: 26497, y: 5695}
                , 
                {ms: 1659424656113, onTime: 4200, x: 26670, y: 5637}
                , 
                {ms: 1659424657213, onTime: 1100, x: 26777, y: 5620}
                , 
                {ms: 1659424658513, onTime: 1300, x: 26802, y: 5634}
                , 
                {ms: 1659424662613, onTime: 4100, x: 26908, y: 5578}
                , 
                {ms: 1659424662913, onTime: 300, x: 26803, y: 5476}
                , 
                {ms: 1659424663413, onTime: 500, x: 27026, y: 5484}
                , 
                {ms: 1659424663613, onTime: 200, x: 26830, y: 5600}
                , 
                {ms: 1659424664013, onTime: 400, x: 26803, y: 5476}
                , 
                {ms: 1659424664213, onTime: 200, x: 26766, y: 5634}
                , 
                {ms: 1659424664313, onTime: 100, x: 26625, y: 5676}
                , 
                {ms: 1659424665213, onTime: 900, x: 26758, y: 5543}
                , 
                {ms: 1659424665613, onTime: 400, x: 26725, y: 5647}
                , 
                {ms: 1659424665913, onTime: 300, x: 26560, y: 5709}
                , 
                {ms: 1659424666113, onTime: 200, x: 26608, y: 5897}
                , 
                {ms: 1659424666313, onTime: 200, x: 26748, y: 5793}
                , 
                {ms: 1659424669213, onTime: 2900, x: 26812, y: 6291}
                , 
                {ms: 1659424669513, onTime: 300, x: 26922, y: 6328}
                , 
                {ms: 1659424669713, onTime: 200, x: 27042, y: 6393}
                , 
                {ms: 1659424669813, onTime: 100, x: 27150, y: 6424}
                , 
                {ms: 1659424670213, onTime: 400, x: 26655, y: 5904}
                , 
                {ms: 1659424670313, onTime: 100, x: 27162, y: 6566}
                , 
                {ms: 1659424670613, onTime: 300, x: 27151, y: 6706}
                , 
                {ms: 1659424670713, onTime: 100, x: 26980, y: 6719}
                , 
                {ms: 1659424671013, onTime: 300, x: 26969, y: 6803}
                , 
                {ms: 1659424671113, onTime: 100, x: 26972, y: 6820}
                , 
                {ms: 1659424672713, onTime: 1600, x: 26939, y: 7216}
                , 
                {ms: 1659424673113, onTime: 400, x: 26912, y: 7467}
                , 
                {ms: 1659424673313, onTime: 200, x: 26862, y: 7506}
                , 
                {ms: 1659424673413, onTime: 100, x: 26824, y: 7539}
                , 
                {ms: 1659424673713, onTime: 300, x: 26725, y: 7611}
                , 
                {ms: 1659424674013, onTime: 300, x: 26650, y: 7695}
                , 
                {ms: 1659424674113, onTime: 100, x: 26626, y: 7704}
                , 
                {ms: 1659424674613, onTime: 500, x: 26562, y: 7618}
                , 
                {ms: 1659424678813, onTime: 4200, x: 26501, y: 7519}
                , 
                {ms: 1659424683013, onTime: 4200, x: 26501, y: 7628}
                , 
                {ms: 1659424683613, onTime: 600, x: 26460, y: 7729}
                , 
                {ms: 1659424684213, onTime: 600, x: 26291, y: 7743}
                , 
                {ms: 1659424684813, onTime: 600, x: 26056, y: 7670}
                , 
                {ms: 1659424684913, onTime: 100, x: 26025, y: 7809}
                , 
                {ms: 1659424685013, onTime: 100, x: 26003, y: 7706}
                , 
                {ms: 1659424685113, onTime: 100, x: 25968, y: 7747}
                , 
                {ms: 1659424685213, onTime: 100, x: 25968, y: 7912}
                , 
                {ms: 1659424685313, onTime: 100, x: 25808, y: 7998}
                , 
                {ms: 1659424685513, onTime: 200, x: 25753, y: 8040}
                , 
                {ms: 1659424685713, onTime: 200, x: 25709, y: 8036}
                , 
                {ms: 1659424686113, onTime: 400, x: 25588, y: 8012}
                , 
                {ms: 1659424686713, onTime: 600, x: 25460, y: 7989}
                , 
                {ms: 1659424687013, onTime: 300, x: 25598, y: 7800}
                , 
                {ms: 1659424687413, onTime: 400, x: 25529, y: 7840}
                , 
                {ms: 1659424687513, onTime: 100, x: 25503, y: 7858}
                , 
                {ms: 1659424687813, onTime: 300, x: 25422, y: 7932}
                , 
                {ms: 1659424688113, onTime: 300, x: 25335, y: 8023}
                , 
                {ms: 1659424688613, onTime: 500, x: 25460, y: 7989}
                , 
                {ms: 1659424690413, onTime: 1800, x: 25004, y: 7902}
                , 
                {ms: 1659424691713, onTime: 1300, x: 25131, y: 7935}
                , 
                {ms: 1659424692513, onTime: 800, x: 25219, y: 7886}
                , 
                {ms: 1659424695013, onTime: 2500, x: 25488, y: 7865}
                , 
                {ms: 1659424695113, onTime: 100, x: 24647, y: 8839}
                , 
                {ms: 1659424695613, onTime: 500, x: 24381, y: 9241}
                , 
                {ms: 1659424695713, onTime: 100, x: 24467, y: 9393}, 
                {ms: 1659424696013, onTime: 300, x: 25130, y: 8320}, 
                {ms: 1659424696113, onTime: 100, x: 24876, y: 8204}, 
                {ms: 1659424696213, onTime: 100, x: 24697, y: 8258}, 
                {ms: 1659424696413, onTime: 200, x: 24589, y: 8256}, 
                {ms: 1659424696613, onTime: 200, x: 24414, y: 8216}, 
                {ms: 1659424696813, onTime: 200, x: 24319, y: 8222}, 
                {ms: 1659424696913, onTime: 100, x: 24289, y: 8224}, 
                {ms: 1659424697213, onTime: 300, x: 24138, y: 8210}, 
                {ms: 1659424697413, onTime: 200, x: 23998, y: 8192}, 
                {ms: 1659424697713, onTime: 300, x: 23879, y: 8175}, 
                {ms: 1659424698013, onTime: 300, x: 23737, y: 8147}, 
                {ms: 1659424698113, onTime: 100, x: 23681, y: 8134}, 
                {ms: 1659424698213, onTime: 100, x: 23627, y: 8126}, 
                {ms: 1659424698413, onTime: 200, x: 23446, y: 7981}, 
                {ms: 1659424698613, onTime: 200, x: 23337, y: 8105}, 
                {ms: 1659424698913, onTime: 300, x: 23222, y: 8068}, 
                {ms: 1659424699113, onTime: 200, x: 23135, y: 8017},
                {ms: 1659424699513, onTime: 400, x: 23037, y: 8073}, 
                {ms: 1659424699813, onTime: 300, x: 22919, y: 8061}, 
                {ms: 1659424700613, onTime: 800, x: 22873, y: 8179}, 
                {ms: 1659424700713, onTime: 100, x: 22997, y: 8265}, 
                {ms: 1659424702713, onTime: 2000, x: 22852, y: 8312}, 
                {ms: 1659424704813, onTime: 2100, x: 22950, y: 8359}, 
                {ms: 1659424705513, onTime: 700, x: 22762, y: 8460}, 
                {ms: 1659424706313, onTime: 800, x: 22594, y: 8413}, 
                {ms: 1659424706613, onTime: 300, x: 22748, y: 8435}, 
                {ms: 1659424707613, onTime: 1000, x: 22607, y: 8437}, 
                {ms: 1659424708313, onTime: 700, x: 22731, y: 8485}, 
                {ms: 1659424710013, onTime: 1700, x: 22559, y: 8524}, 
                {ms: 1659424710313, onTime: 300, x: 22628, y: 8439}, 
                {ms: 1659424711313, onTime: 1000, x: 22771, y: 8416}, 
                {ms: 1659424711513, onTime: 200, x: 22634, y: 8410}, 
                {ms: 1659424712613, onTime: 1100, x: 22757, y: 8447}, 
                {ms: 1659424712713, onTime: 100, x: 22615, y: 8432}, 
                {ms: 1659424714413, onTime: 1700, x: 22757, y: 8429}, 
                {ms: 1659424715013, onTime: 600, x: 22619, y: 8453}, 
                {ms: 1659424718913, onTime: 3900, x: 22707, y: 8510}, 
                {ms: 1659424720013, onTime: 1100, x: 22604, y: 8414}, 
                {ms: 1659424720813, onTime: 800, x: 22737, y: 8452}, 
                {ms: 1659424722613, onTime: 1800, x: 22703, y: 8344}, 
                {ms: 1659424723213, onTime: 600, x: 22651, y: 8251}, 
                {ms: 1659424725013, onTime: 1800, x: 22645, y: 8460}, 
                {ms: 1659424725413, onTime: 400, x: 22621, y: 8602}, 
                {ms: 1659424725713, onTime: 300, x: 22699, y: 8690}, 
                {ms: 1659424726013, onTime: 300, x: 22801, y: 8713}, 
                {ms: 1659424726313, onTime: 300, x: 22703, y: 8762}, 
                {ms: 1659424726813, onTime: 500, x: 22614, y: 8837}, 
                {ms: 1659424727613, onTime: 800, x: 22611, y: 8718}, 
                {ms: 1659424728313, onTime: 700, x: 22759, y: 8524}, 
                {ms: 1659424728813, onTime: 500, x: 22892, y: 8488}, 
                {ms: 1659424729113, onTime: 300, x: 22935, y: 8334}, 
                {ms: 1659424729413, onTime: 300, x: 23014, y: 8132}, 
                {ms: 1659424729713, onTime: 300, x: 23140, y: 8057}, 
                {ms: 1659424729813, onTime: 100, x: 23037, y: 7956}, 
                {ms: 1659424730013, onTime: 200, x: 23201, y: 7948}, 
                {ms: 1659424730213, onTime: 200, x: 23315, y: 7941}, 
                {ms: 1659424730513, onTime: 300, x: 23460, y: 7850}, 
                {ms: 1659424730713, onTime: 200, x: 23656, y: 7846}, 
                {ms: 1659424730913, onTime: 200, x: 23776, y: 7924}, 
                {ms: 1659424731313, onTime: 400, x: 23909, y: 8011}, 
                {ms: 1659424731813, onTime: 500, x: 24318, y: 8223}, 
                {ms: 1659424732013, onTime: 200, x: 24435, y: 8266}, 
                {ms: 1659424732313, onTime: 300, x: 24829, y: 8258}, 
                {ms: 1659424732413, onTime: 100, x: 24940, y: 8222}, 
                {ms: 1659424732813, onTime: 400, x: 24831, y: 8141}, 
                {ms: 1659424733013, onTime: 200, x: 24675, y: 8141}, 
                {ms: 1659424733313, onTime: 300, x: 24550, y: 8126}, 
                {ms: 1659424733513, onTime: 200, x: 24415, y: 8093}, 
                {ms: 1659424733713, onTime: 200, x: 24278, y: 8078}, 
                {ms: 1659424733813, onTime: 100, x: 24223, y: 8065}, 
                {ms: 1659424733913, onTime: 100, x: 24166, y: 8054}, 
                {ms: 1659424734113, onTime: 200, x: 23966, y: 8074}, 
                {ms: 1659424734313, onTime: 200, x: 23813, y: 8093}, 
                {ms: 1659424734513, onTime: 200, x: 23703, y: 8032}, 
                {ms: 1659424734613, onTime: 100, x: 23595, y: 8008}, 
                {ms: 1659424734713, onTime: 100, x: 23505, y: 8013}, 
                {ms: 1659424734813, onTime: 100, x: 22697, y: 7871}, 
                {ms: 1659424735013, onTime: 200, x: 22667, y: 8000}, 
                {ms: 1659424735213, onTime: 200, x: 22525, y: 8021}, 
                {ms: 1659424735413, onTime: 200, x: 22366, y: 8047}, 
                {ms: 1659424735613, onTime: 200, x: 22234, y: 8061}, 
                {ms: 1659424735813, onTime: 200, x: 22074, y: 8112}, 
                {ms: 1659424735913, onTime: 100, x: 21984, y: 8119}, 
                {ms: 1659424736013, onTime: 100, x: 21895, y: 8132}, 
                {ms: 1659424736213, onTime: 200, x: 21683, y: 8233}, 
                {ms: 1659424736313, onTime: 100, x: 21505, y: 8266}, 
                {ms: 1659424736513, onTime: 200, x: 21383, y: 8168}, 
                {ms: 1659424736713, onTime: 200, x: 21326, y: 8027}, 
                {ms: 1659424738413, onTime: 1700, x: 20466, y: 8086}, 
                {ms: 1659424738513, onTime: 100, x: 20358, y: 8063}, 
                {ms: 1659424738713, onTime: 200, x: 20199, y: 8033}, 
                {ms: 1659424738813, onTime: 100, x: 20140, y: 8023}, 
                {ms: 1659424738913, onTime: 100, x: 20072, y: 8008}, 
                {ms: 1659424739113, onTime: 200, x: 19915, y: 7941}, 
                {ms: 1659424739213, onTime: 100, x: 19833, y: 7907}, 
                {ms: 1659424739313, onTime: 100, x: 19694, y: 7887}, 
                {ms: 1659424739613, onTime: 300, x: 19479, y: 7830}, 
                {ms: 1659424739813, onTime: 200, x: 19281, y: 7811}, 
                {ms: 1659424740013, onTime: 200, x: 19135, y: 7786}, 
                {ms: 1659424740113, onTime: 100, x: 19372, y: 7942}, 
                {ms: 1659424740313, onTime: 200, x: 19284, y: 7874}, 
                {ms: 1659424740513, onTime: 200, x: 19045, y: 7625}, 
                {ms: 1659424740613, onTime: 100, x: 18861, y: 7399}, 
                {ms: 1659424740713, onTime: 100, x: 18747, y: 7318}, 
                {ms: 1659424740813, onTime: 100, x: 18546, y: 7666}, 
                {ms: 1659424741013, onTime: 200, x: 18436, y: 7637}, 
                {ms: 1659424741313, onTime: 300, x: 18276, y: 7595}, 
                {ms: 1659424741413, onTime: 100, x: 18190, y: 7580}, 
                {ms: 1659424741513, onTime: 100, x: 18122, y: 7562}, 
                {ms: 1659424741713, onTime: 200, x: 18008, y: 7503}, 
                {ms: 1659424741913, onTime: 200, x: 17888, y: 7429}, 
                {ms: 1659424742113, onTime: 200, x: 17742, y: 7389}, 
                {ms: 1659424742313, onTime: 200, x: 17601, y: 7347}, 
                {ms: 1659424742413, onTime: 100, x: 17562, y: 7325}, 
                {ms: 1659424742613, onTime: 200, x: 17493, y: 7206}, 
                {ms: 1659424742813, onTime: 200, x: 17404, y: 7045}, 
                {ms: 1659424743113, onTime: 300, x: 17382, y: 6906}, 
                {ms: 1659424743213, onTime: 100, x: 17283, y: 6804}, 
                {ms: 1659424743313, onTime: 100, x: 17225, y: 6745}, 
                {ms: 1659424743513, onTime: 200, x: 17194, y: 6704}, 
                {ms: 1659424743813, onTime: 300, x: 17094, y: 6640}, 
                {ms: 1659424744013, onTime: 200, x: 16968, y: 6577}, 
                {ms: 1659424744113, onTime: 100, x: 16835, y: 6312}, 
                {ms: 1659424744513, onTime: 400, x: 16768, y: 6339}, 
                {ms: 1659424744613, onTime: 100, x: 16702, y: 6337}, 
                {ms: 1659424744813, onTime: 200, x: 16513, y: 6380}, 
                {ms: 1659424745113, onTime: 300, x: 16417, y: 6342}, 
                {ms: 1659424745413, onTime: 300, x: 16289, y: 6392}, 
                {ms: 1659424745613, onTime: 200, x: 16106, y: 6381}, 
                {ms: 1659424746013, onTime: 400, x: 15992, y: 6393}, 
                {ms: 1659424746213, onTime: 200, x: 15867, y: 6391}, 
                {ms: 1659424746313, onTime: 100, x: 15786, y: 6364}, 
                {ms: 1659424746413, onTime: 100, x: 15735, y: 6351}, 
                {ms: 1659424746713, onTime: 300, x: 15603, y: 6341}, 
                {ms: 1659424747013, onTime: 300, x: 15090, y: 6346}, 
                {ms: 1659424747213, onTime: 200, x: 14983, y: 6333}, 
                {ms: 1659424747513, onTime: 300, x: 14798, y: 6311}, 
                {ms: 1659424747613, onTime: 100, x: 14751, y: 6316}, 
                {ms: 1659424747813, onTime: 200, x: 13907, y: 6392}, 
                {ms: 1659424748013, onTime: 200, x: 13748, y: 6412}, 
                {ms: 1659424748313, onTime: 300, x: 13627, y: 6432}, 
                {ms: 1659424748413, onTime: 100, x: 13578, y: 6422}, 
                {ms: 1659424748613, onTime: 200, x: 13506, y: 6375}, 
                {ms: 1659424748813, onTime: 200, x: 13405, y: 6287}, 
                {ms: 1659424749113, onTime: 300, x: 14056, y: 6099}, 
                {ms: 1659424749213, onTime: 100, x: 13252, y: 6210}, 
                {ms: 1659424749413, onTime: 200, x: 13187, y: 6128}, 
                {ms: 1659424749513, onTime: 100, x: 13685, y: 6279}, 
                {ms: 1659424749713, onTime: 200, x: 13545, y: 6296}, 
                {ms: 1659424749913, onTime: 200, x: 13405, y: 6324}, 
                {ms: 1659424750113, onTime: 200, x: 13280, y: 6339}, 
                {ms: 1659424750413, onTime: 300, x: 12368, y: 5769}, 
                {ms: 1659424750613, onTime: 200, x: 12222, y: 5709}, 
                {ms: 1659424750813, onTime: 200, x: 12054, y: 5642}, 
                {ms: 1659424751113, onTime: 300, x: 11939, y: 5557}, 
                {ms: 1659424751313, onTime: 200, x: 11830, y: 5558}, 
                {ms: 1659424751413, onTime: 100, x: 11761, y: 5506}, 
                {ms: 1659424751513, onTime: 100, x: 11708, y: 5467}, 
                {ms: 1659424751713, onTime: 200, x: 11592, y: 5380}, 
                {ms: 1659424751913, onTime: 200, x: 11491, y: 5313}, 
                {ms: 1659424752113, onTime: 200, x: 11354, y: 5240}, 
                {ms: 1659424752313, onTime: 200, x: 11266, y: 5171}, 
                {ms: 1659424752413, onTime: 100, x: 11241, y: 5143}, 
                {ms: 1659424752613, onTime: 200, x: 11156, y: 5047}, 
                {ms: 1659424752813, onTime: 200, x: 10999, y: 4865}, 
                {ms: 1659424753113, onTime: 300, x: 10917, y: 4774}, 
                {ms: 1659424753313, onTime: 200, x: 10798, y: 4695}, 
                {ms: 1659424753413, onTime: 100, x: 10728, y: 4652}, 
                {ms: 1659424753513, onTime: 100, x: 10679, y: 4626}, 
                {ms: 1659424753713, onTime: 200, x: 10562, y: 4547}, 
                {ms: 1659424753913, onTime: 200, x: 10460, y: 4478}, 
                {ms: 1659424754213, onTime: 300, x: 10303, y: 4464}, 
                {ms: 1659424754413, onTime: 200, x: 10145, y: 4517}, 
                {ms: 1659424754613, onTime: 200, x: 10009, y: 4586}, 
                {ms: 1659424754813, onTime: 200, x: 9891, y: 4639}, 
                {ms: 1659424754913, onTime: 100, x: 9840, y: 4673}, 
                {ms: 1659424755013, onTime: 100, x: 9759, y: 4703}, 
                {ms: 1659424755213, onTime: 200, x: 9557, y: 4778}, 
                {ms: 1659424755413, onTime: 200, x: 9406, y: 4892}, 
                {ms: 1659424755613, onTime: 200, x: 9325, y: 4965}, 
                {ms: 1659424755713, onTime: 100, x: 9271, y: 4999}, 
                {ms: 1659424755813, onTime: 100, x: 9215, y: 5035}, 
                {ms: 1659424756013, onTime: 200, x: 9113, y: 5098}, 
                {ms: 1659424756313, onTime: 300, x: 8982, y: 5157}, 
                {ms: 1659424756713, onTime: 400, x: 8879, y: 5223}, 
                {ms: 1659424757013, onTime: 300, x: 8876, y: 5333}, 
                {ms: 1659424757913, onTime: 900, x: 8876, y: 5441}, 
                {ms: 1659424758113, onTime: 200, x: 8751, y: 5352}, 
                {ms: 1659424758513, onTime: 400, x: 8884, y: 5427}, 
                {ms: 1659424763913, onTime: 5400, x: 8785, y: 5496}, 
                {ms: 1659424766913, onTime: 3000, x: 8693, y: 5454}, 
                {ms: 1659424767813, onTime: 900, x: 8791, y: 5506}, 
                {ms: 1659424800013, onTime: 32200, x: 8686, y: 5454}, 
                {ms: 1659424800213, onTime: 200, x: 8790, y: 5514}, 
                {ms: 1659424801013, onTime: 800, x: 8688, y: 5483}, 
                {ms: 1659424802213, onTime: 1200, x: 8798, y: 5522}, 
                {ms: 1659424805713, onTime: 3500, x: 8670, y: 5471}, 
                {ms: 1659424805913, onTime: 200, x: 8780, y: 5537}, 
                {ms: 1659424807713, onTime: 1800, x: 8707, y: 5455}, 
                {ms: 1659424824013, onTime: 16300, x: 8860, y: 5485}, 
                {ms: 1659424824213, onTime: 200, x: 8847, y: 5762}, 
                {ms: 1659424825113, onTime: 900, x: 9101, y: 5982}, 
                {ms: 1659424825313, onTime: 200, x: 9188, y: 6056}, 
                {ms: 1659424825513, onTime: 200, x: 9262, y: 6158}, 
                {ms: 1659424825613, onTime: 100, x: 9298, y: 6220}, 
                {ms: 1659424825713, onTime: 100, x: 9347, y: 6275}, 
                {ms: 1659424825913, onTime: 200, x: 9457, y: 6352}, 
                {ms: 1659424826113, onTime: 200, x: 9577, y: 6410}, 
                {ms: 1659424826313, onTime: 200, x: 9733, y: 6462}, 
                {ms: 1659424826513, onTime: 200, x: 9868, y: 6512}, 
                {ms: 1659424826713, onTime: 200, x: 10020, y: 6501}, 
                {ms: 1659424826813, onTime: 100, x: 10097, y: 6578}, 
                {ms: 1659424826913, onTime: 100, x: 10175, y: 6539}, 
                {ms: 1659424827013, onTime: 100, x: 10242, y: 6557}, 
                {ms: 1659424827113, onTime: 100, x: 10298, y: 6746}, 
                {ms: 1659424827313, onTime: 200, x: 10408, y: 6817}, 
                {ms: 1659424827513, onTime: 200, x: 10546, y: 6798}, 
                {ms: 1659424827713, onTime: 200, x: 10684, y: 6894}, 
                {ms: 1659424827813, onTime: 100, x: 10751, y: 6949}, 
                {ms: 1659424827913, onTime: 100, x: 10811, y: 7002}, 
                {ms: 1659424828113, onTime: 200, x: 10881, y: 7113}, 
                {ms: 1659424828313, onTime: 200, x: 10946, y: 7228}, 
                {ms: 1659424828613, onTime: 300, x: 11041, y: 7356}, 
                {ms: 1659424828813, onTime: 200, x: 11123, y: 7490}, 
                {ms: 1659424829013, onTime: 200, x: 11197, y: 7606}, 
                {ms: 1659424829313, onTime: 300, x: 11361, y: 7566}, 
                {ms: 1659424829413, onTime: 100, x: 11433, y: 7506}, 
                {ms: 1659424829513, onTime: 100, x: 11494, y: 7522}, 
                {ms: 1659424829613, onTime: 100, x: 11514, y: 7790}, 
                {ms: 1659424829713, onTime: 100, x: 11549, y: 7856}, 
                {ms: 1659424829813, onTime: 100, x: 11590, y: 7920}, 
                {ms: 1659424829913, onTime: 100, x: 11461, y: 7981}, 
                {ms: 1659424830113, onTime: 200, x: 11558, y: 8026}, 
                {ms: 1659424830313, onTime: 200, x: 11599, y: 8119}, 
                {ms: 1659424830413, onTime: 100, x: 11584, y: 8223}, 
                {ms: 1659424830513, onTime: 100, x: 11562, y: 8323}, 
                {ms: 1659424830713, onTime: 200, x: 11554, y: 8477}, 
                {ms: 1659424830813, onTime: 100, x: 11566, y: 8543}, 
                {ms: 1659424831013, onTime: 200, x: 11621, y: 8608}, 
                {ms: 1659424831213, onTime: 200, x: 11635, y: 8792}, 
                {ms: 1659424831313, onTime: 100, x: 11631, y: 8878}, 
                {ms: 1659424831413, onTime: 100, x: 11632, y: 8954}, 
                {ms: 1659424831613, onTime: 200, x: 11632, y: 9105}, 
                {ms: 1659424831713, onTime: 100, x: 11618, y: 9179}, 
                {ms: 1659424831813, onTime: 100, x: 11596, y: 9301}, 
                {ms: 1659424832013, onTime: 200, x: 11603, y: 9494}, 
                {ms: 1659424832213, onTime: 200, x: 11660, y: 9645}, 
                {ms: 1659424832413, onTime: 200, x: 11742, y: 9811}, 
                {ms: 1659424832613, onTime: 200, x: 11801, y: 9968}, 
                {ms: 1659424832813, onTime: 200, x: 11873, y: 10090}, 
                {ms: 1659424833013, onTime: 200, x: 11972, y: 10205}, 
                {ms: 1659424833213, onTime: 200, x: 12193, y: 10389}, 
                {ms: 1659424833313, onTime: 100, x: 12119, y: 10309}, 
                {ms: 1659424833513, onTime: 200, x: 12372, y: 10465}, 
                {ms: 1659424833913, onTime: 400, x: 12486, y: 10503}, 
                {ms: 1659424834013, onTime: 100, x: 12507, y: 10601}, 
                {ms: 1659424834213, onTime: 200, x: 12624, y: 10658}, 
                {ms: 1659424834413, onTime: 200, x: 12754, y: 10750}, 
                {ms: 1659424834613, onTime: 200, x: 12787, y: 10872}, 
                {ms: 1659424834813, onTime: 200, x: 12902, y: 11024}, 
                {ms: 1659424835013, onTime: 200, x: 13016, y: 11154}, 
                {ms: 1659424835213, onTime: 200, x: 13082, y: 11329}, 
                {ms: 1659424835413, onTime: 200, x: 13099, y: 11496}, 
                {ms: 1659424835613, onTime: 200, x: 13131, y: 11655}, 
                {ms: 1659424835813, onTime: 200, x: 13209, y: 11791}, 
                {ms: 1659424836013, onTime: 200, x: 13344, y: 11817}, 
                {ms: 1659424836113, onTime: 100, x: 13454, y: 12047}, 
                {ms: 1659424836413, onTime: 300, x: 13543, y: 12169}, 
                {ms: 1659424836513, onTime: 100, x: 13670, y: 12198}, 
                {ms: 1659424836613, onTime: 100, x: 13769, y: 12251}, 
                {ms: 1659424836913, onTime: 300, x: 13959, y: 12460}, 
                {ms: 1659424837013, onTime: 100, x: 14078, y: 12632}, 
                {ms: 1659424837113, onTime: 100, x: 14200, y: 12633}, 
                {ms: 1659424837313, onTime: 200, x: 14317, y: 12793}, 
                {ms: 1659424837413, onTime: 100, x: 14381, y: 12859}, 
                {ms: 1659424837513, onTime: 100, x: 14444, y: 12950}, 
                {ms: 1659424837713, onTime: 200, x: 14566, y: 13042}, 
                {ms: 1659424838013, onTime: 300, x: 14706, y: 13087}, 
                {ms: 1659424838113, onTime: 100, x: 14781, y: 13172}, 
                {ms: 1659424838313, onTime: 200, x: 14915, y: 13310}, 
                {ms: 1659424838513, onTime: 200, x: 15016, y: 13493}, 
                {ms: 1659424838613, onTime: 100, x: 15061, y: 13584}, 
                {ms: 1659424838813, onTime: 200, x: 15241, y: 13605}, 
                {ms: 1659424839013, onTime: 200, x: 15341, y: 13719}, 
                {ms: 1659424839213, onTime: 200, x: 15464, y: 13868}, 
                {ms: 1659424839313, onTime: 100, x: 15558, y: 14471}, 
                {ms: 1659424839413, onTime: 100, x: 15654, y: 14429}, 
                {ms: 1659424839613, onTime: 200, x: 15782, y: 14524}, 
                {ms: 1659424839713, onTime: 100, x: 15823, y: 14619}, 
                {ms: 1659424839913, onTime: 200, x: 15959, y: 14699}, 
                {ms: 1659424840013, onTime: 100, x: 16042, y: 14770}, 
                {ms: 1659424840113, onTime: 100, x: 16104, y: 14868}, 
                {ms: 1659424840213, onTime: 100, x: 16151, y: 14965}, 
                {ms: 1659424840413, onTime: 200, x: 16324, y: 14835}, 
                {ms: 1659424840513, onTime: 100, x: 16394, y: 14764}, 
                {ms: 1659424840613, onTime: 100, x: 16460, y: 14826}, 
                {ms: 1659424840813, onTime: 200, x: 16559, y: 15019}, 
                {ms: 1659424840913, onTime: 100, x: 16636, y: 15153}, 
                {ms: 1659424841013, onTime: 100, x: 16760, y: 15315}, 
                {ms: 1659424841113, onTime: 100, x: 16901, y: 15463}, 
                {ms: 1659424841213, onTime: 100, x: 17029, y: 15525}, 
                {ms: 1659424841313, onTime: 100, x: 17153, y: 15536}, 
                {ms: 1659424841513, onTime: 200, x: 17310, y: 15507}, 
                {ms: 1659424841613, onTime: 100, x: 17383, y: 15523}, 
                {ms: 1659424841713, onTime: 100, x: 17455, y: 15534}, 
                {ms: 1659424841913, onTime: 200, x: 17654, y: 15529}, 
                {ms: 1659424842013, onTime: 100, x: 17782, y: 15540}, 
                {ms: 1659424842113, onTime: 100, x: 17887, y: 15523}, 
                { ms: 1659424842213, onTime: 100, x: 18019, y: 15450 }]
        }, {
            humanId: 10000000, id: '220803184056142', points: [
                { ms: 1659519657196, onTime: 200, x: 29165, y: 7117 }, 
                {ms: 1659519657496, onTime: 300, x: 29027, y: 7117}, 
                {ms: 1659519657596, onTime: 100, x: 28969, y: 7097}, 
                {ms: 1659519657696, onTime: 100, x: 28917, y: 7079}, 
                {ms: 1659519657996, onTime: 300, x: 28814, y: 7056}, 
                {ms: 1659519659096, onTime: 1100, x: 28808, y: 7164}, 
                {ms: 1659519660996, onTime: 1900, x: 28572, y: 6203}, 
                {ms: 1659519661496, onTime: 500, x: 28432, y: 6229}, 
                {ms: 1659519661896, onTime: 400, x: 28498, y: 6323}, 
                {ms: 1659519662496, onTime: 600, x: 28579, y: 6575}, 
                {ms: 1659519662596, onTime: 100, x: 28434, y: 6798}, 
                {ms: 1659519662696, onTime: 100, x: 28442, y: 7034}, 
                {ms: 1659519662796, onTime: 100, x: 28005, y: 6954}, 
                {ms: 1659519663796, onTime: 1000, x: 27333, y: 5813}, 
                {ms: 1659519663896, onTime: 100, x: 27038, y: 5855}, 
                {ms: 1659519664096, onTime: 200, x: 27333, y: 5813}, 
                {ms: 1659519666196, onTime: 2100, x: 27049, y: 5900}, 
                {ms: 1659519666896, onTime: 700, x: 26564, y: 5223}, 
                {ms: 1659519667296, onTime: 400, x: 26504, y: 5037}, 
                {ms: 1659519667996, onTime: 700, x: 26526, y: 4835}, 
                {ms: 1659519668596, onTime: 600, x: 26646, y: 4578}, 
                {ms: 1659519668696, onTime: 100, x: 26665, y: 4540}, 
                {ms: 1659519668896, onTime: 200, x: 26733, y: 4440}, 
                {ms: 1659519669096, onTime: 200, x: 26809, y: 4335}, 
                {ms: 1659519669196, onTime: 100, x: 26845, y: 4297}, 
                {ms: 1659519669396, onTime: 200, x: 26895, y: 4250}, 
                {ms: 1659519669696, onTime: 300, x: 27018, y: 4155}, 
                {ms: 1659519669796, onTime: 100, x: 27090, y: 4111}, 
                {ms: 1659519669896, onTime: 100, x: 27147, y: 4075}, 
                {ms: 1659519670096, onTime: 200, x: 27230, y: 4018}, 
                {ms: 1659519670396, onTime: 300, x: 27329, y: 3917}, 
                {ms: 1659519670596, onTime: 200, x: 27390, y: 3810}, 
                {ms: 1659519670796, onTime: 200, x: 27467, y: 3696}, 
                {ms: 1659519670896, onTime: 100, x: 27416, y: 3617}, 
                {ms: 1659519670996, onTime: 100, x: 27319, y: 3577}, 
                {ms: 1659519671196, onTime: 200, x: 27379, y: 3426}, 
                {ms: 1659519671396, onTime: 200, x: 27496, y: 3380}, 
                {ms: 1659519671596, onTime: 200, x: 27514, y: 3286}, 
                {ms: 1659519671696, onTime: 100, x: 27548, y: 3211}, 
                {ms: 1659519671996, onTime: 300, x: 27503, y: 3079}, 
                {ms: 1659519672396, onTime: 400, x: 27408, y: 3003}, 
                {ms: 1659519673696, onTime: 1300, x: 25867, y: 1674}, 
                {ms: 1659519673796, onTime: 100, x: 25894, y: 1147}, 
                {ms: 1659519673896, onTime: 100, x: 26071, y: 1061}, 
                {ms: 1659519674096, onTime: 200, x: 26252, y: 993}, 
                {ms: 1659519674296, onTime: 200, x: 26360, y: 968}, 
                {ms: 1659519674496, onTime: 200, x: 26503, y: 912}, 
                {ms: 1659519674596, onTime: 100, x: 26566, y: 884}, 
                {ms: 1659519674696, onTime: 100, x: 26631, y: 842}, 
                {ms: 1659519675096, onTime: 400, x: 26692, y: 758}, 
                {ms: 1659519678296, onTime: 3200, x: 26491, y: 800}, 
                {ms: 1659519681296, onTime: 3000, x: 26594, y: 761}, 
                {ms: 1659519681696, onTime: 400, x: 26500, y: 802}, 
                {ms: 1659519682496, onTime: 800, x: 25795, y: 869}, 
                {ms: 1659519682596, onTime: 100, x: 25654, y: 875}, 
                {ms: 1659519682796, onTime: 200, x: 25471, y: 850}, 
                {ms: 1659519682896, onTime: 100, x: 25364, y: 832}, 
                {ms: 1659519682996, onTime: 100, x: 25251, y: 823}, 
                {ms: 1659519683096, onTime: 100, x: 25126, y: 817}, 
                {ms: 1659519683196, onTime: 100, x: 24999, y: 808}, 
                {ms: 1659519683296, onTime: 100, x: 24862, y: 802}, 
                {ms: 1659519683396, onTime: 100, x: 24762, y: 803}, 
                {ms: 1659519683496, onTime: 100, x: 24631, y: 805}, 
                {ms: 1659519683596, onTime: 100, x: 24521, y: 803}, 
                {ms: 1659519683696, onTime: 100, x: 24397, y: 794}, 
                {ms: 1659519683796, onTime: 100, x: 24282, y: 794}, 
                {ms: 1659519683896, onTime: 100, x: 24394, y: 927}, 
                {ms: 1659519683996, onTime: 100, x: 24288, y: 938}, 
                {ms: 1659519684196, onTime: 200, x: 24087, y: 984}, 
                {ms: 1659519684396, onTime: 200, x: 23902, y: 999}, 
                {ms: 1659519684496, onTime: 100, x: 23796, y: 985}, 
                {ms: 1659519684596, onTime: 100, x: 23666, y: 952}, 
                {ms: 1659519684696, onTime: 100, x: 23460, y: 890}, 
                {ms: 1659519684796, onTime: 100, x: 23336, y: 865}, 
                {ms: 1659519685096, onTime: 300, x: 22990, y: 845}, 
                {ms: 1659519685196, onTime: 100, x: 22886, y: 832}, 
                {ms: 1659519685296, onTime: 100, x: 22785, y: 820}, 
                {ms: 1659519685396, onTime: 100, x: 22681, y: 796}, 
                {ms: 1659519685596, onTime: 200, x: 22493, y: 742}, 
                {ms: 1659519685796, onTime: 200, x: 22338, y: 699}, 
                {ms: 1659519686096, onTime: 300, x: 22193, y: 758}, 
                {ms: 1659519686296, onTime: 200, x: 22085, y: 767}, 
                {ms: 1659519687296, onTime: 1000, x: 22033, y: 777}, 
                {ms: 1659519687496, onTime: 200, x: 21943, y: 814}, 
                {ms: 1659519688296, onTime: 800, x: 21308, y: 1048}, 
                {ms: 1659519688396, onTime: 100, x: 21155, y: 1073}, 
                {ms: 1659519688496, onTime: 100, x: 21016, y: 1092}, 
                {ms: 1659519688596, onTime: 100, x: 20709, y: 1188}, 
                {ms: 1659519688796, onTime: 200, x: 20411, y: 1303}, 
                {ms: 1659519688996, onTime: 200, x: 20226, y: 1351}, 
                {ms: 1659519689096, onTime: 100, x: 20101, y: 1361}, 
                {ms: 1659519689196, onTime: 100, x: 19980, y: 1364}, 
                {ms: 1659519689296, onTime: 100, x: 19849, y: 1375}, 
                {ms: 1659519689396, onTime: 100, x: 19711, y: 1393}, 
                {ms: 1659519689496, onTime: 100, x: 19572, y: 1413}, 
                {ms: 1659519689596, onTime: 100, x: 19387, y: 1428}, 
                {ms: 1659519689696, onTime: 100, x: 19286, y: 1427}, 
                {ms: 1659519689796, onTime: 100, x: 18804, y: 1641}, 
                {ms: 1659519689896, onTime: 100, x: 18683, y: 1631}, 
                {ms: 1659519689996, onTime: 100, x: 18586, y: 1601}, 
                {ms: 1659519690096, onTime: 100, x: 18476, y: 1567}, 
                {ms: 1659519690196, onTime: 100, x: 18359, y: 1532}, 
                {ms: 1659519690296, onTime: 100, x: 18246, y: 1500}, 
                {ms: 1659519690396, onTime: 100, x: 18177, y: 1487}, 
                {ms: 1659519690496, onTime: 100, x: 18102, y: 1468}, 
                {ms: 1659519690696, onTime: 200, x: 17993, y: 1323}, 
                {ms: 1659519690796, onTime: 100, x: 17873, y: 1202}, 
                {ms: 1659519690896, onTime: 100, x: 17778, y: 1161}, 
                {ms: 1659519691096, onTime: 200, x: 17619, y: 1176}, 
                {ms: 1659519691196, onTime: 100, x: 17546, y: 1199}, 
                {ms: 1659519691296, onTime: 100, x: 17474, y: 1216}, 
                {ms: 1659519691496, onTime: 200, x: 17350, y: 1206}, 
                {ms: 1659519691796, onTime: 300, x: 17205, y: 1140}, 
                {ms: 1659519691996, onTime: 200, x: 17011, y: 1219}, 
                {ms: 1659519692596, onTime: 600, x: 17101, y: 1123}, 
                {ms: 1659519693696, onTime: 1100, x: 16986, y: 1229}, 
                {ms: 1659519694196, onTime: 500, x: 17120, y: 1102}, 
                {ms: 1659519694296, onTime: 100, x: 16986, y: 1229}, 
                {ms: 1659519696196, onTime: 1900, x: 17083, y: 1100}, 
                {ms: 1659519698396, onTime: 2200, x: 17369, y: 1050}, 
                {ms: 1659519699296, onTime: 900, x: 17458, y: 978}, 
                {ms: 1659519700196, onTime: 900, x: 17407, y: 1074}, 
                {ms: 1659519700296, onTime: 100, x: 17241, y: 1154}, 
                {ms: 1659519700396, onTime: 100, x: 17387, y: 1109}, 
                {ms: 1659519702296, onTime: 1900, x: 17229, y: 1177}, 
                {ms: 1659519702396, onTime: 100, x: 17409, y: 1113}, 
                {ms: 1659519705096, onTime: 2700, x: 17521, y: 1158}, 
                {ms: 1659519706196, onTime: 1100, x: 17223, y: 1179}, 
                {ms: 1659519706396, onTime: 200, x: 17413, y: 1177}, 
                {ms: 1659519707096, onTime: 700, x: 17273, y: 1145}, 
                {ms: 1659519707196, onTime: 100, x: 17228, y: 1263}, 
                {ms: 1659519707296, onTime: 100, x: 17194, y: 1112}, 
                {ms: 1659519707896, onTime: 600, x: 17016, y: 1183}, 
                {ms: 1659519710196, onTime: 2300, x: 16975, y: 1064}, 
                {ms: 1659519710296, onTime: 100, x: 16949, y: 1042}, 
                {ms: 1659519711396, onTime: 1100, x: 16881, y: 1004}, 
                {ms: 1659519712696, onTime: 1300, x: 17034, y: 1108}, 
                {ms: 1659519713496, onTime: 800, x: 17250, y: 1195}, 
                {ms: 1659519713896, onTime: 400, x: 17347, y: 1133}, 
                {ms: 1659519714796, onTime: 900, x: 17428, y: 1040}, 
                {ms: 1659519715896, onTime: 1100, x: 17279, y: 1087}, 
                {ms: 1659519716396, onTime: 500, x: 17152, y: 1095}, 
                {ms: 1659519716796, onTime: 400, x: 17036, y: 1041}, 
                {ms: 1659519717196, onTime: 400, x: 16971, y: 1005}, 
                {ms: 1659519717696, onTime: 500, x: 16930, y: 1023}, 
                {ms: 1659519728196, onTime: 10500, x: 16959, y: 1230}, 
                {ms: 1659519728996, onTime: 800, x: 17193, y: 1120}, 
                {ms: 1659519732596, onTime: 3600, x: 17025, y: 1267}, 
                {ms: 1659519733696, onTime: 1100, x: 17162, y: 1132}, 
                {ms: 1659519733896, onTime: 200, x: 17043, y: 1243}, 
                {ms: 1659519734096, onTime: 200, x: 17173, y: 1139}, 
                {ms: 1659519734896, onTime: 800, x: 16972, y: 1429}, 
                {ms: 1659519734996, onTime: 100, x: 17176, y: 1130}, 
                {ms: 1659519736296, onTime: 1300, x: 16749, y: 1816}, 
                {ms: 1659519736396, onTime: 100, x: 17202, y: 1152}, 
                {ms: 1659519752296, onTime: 15900, x: 17939, y: 1441}, 
                {ms: 1659519752396, onTime: 100, x: 18103, y: 1428}, 
                {ms: 1659519752496, onTime: 100, x: 18232, y: 1425}, 
                {ms: 1659519752596, onTime: 100, x: 18460, y: 1453}, 
                {ms: 1659519752796, onTime: 200, x: 18748, y: 1500}, 
                {ms: 1659519752896, onTime: 100, x: 18877, y: 1500}, 
                {ms: 1659519752996, onTime: 100, x: 19004, y: 1480}, 
                {ms: 1659519753096, onTime: 100, x: 19108, y: 1469}, 
                {ms: 1659519753396, onTime: 300, x: 19858, y: 1396}, 
                {ms: 1659519753496, onTime: 100, x: 20055, y: 1220}, 
                {ms: 1659519753596, onTime: 100, x: 20171, y: 1196}, 
                {ms: 1659519753696, onTime: 100, x: 20290, y: 1169}, 
                {ms: 1659519753896, onTime: 200, x: 20435, y: 1083}, 
                {ms: 1659519753996, onTime: 100, x: 20485, y: 1017}, 
                {ms: 1659519754096, onTime: 100, x: 20551, y: 950}, 
                {ms: 1659519754296, onTime: 200, x: 20710, y: 854}, 
                {ms: 1659519754396, onTime: 100, x: 20814, y: 818}, 
                {ms: 1659519754496, onTime: 100, x: 20966, y: 774}, 
                {ms: 1659519754596, onTime: 100, x: 21065, y: 749}, 
                {ms: 1659519754696, onTime: 100, x: 21167, y: 727}, 
                {ms: 1659519754896, onTime: 200, x: 21262, y: 695}, 
                {ms: 1659519755096, onTime: 200, x: 21384, y: 688}, 
                {ms: 1659519755296, onTime: 200, x: 21505, y: 652}, 
                {ms: 1659519755596, onTime: 300, x: 21616, y: 617}, 
                {ms: 1659519756796, onTime: 1200, x: 21633, y: 729}, 
                {ms: 1659519757396, onTime: 600, x: 21445, y: 823}, 
                {ms: 1659519757796, onTime: 400, x: 21272, y: 876}, 
                {ms: 1659519757996, onTime: 200, x: 21159, y: 865}, 
                {ms: 1659519758396, onTime: 400, x: 20773, y: 867}, 
                {ms: 1659519758596, onTime: 200, x: 20596, y: 931}, 
                {ms: 1659519758796, onTime: 200, x: 20440, y: 1037}, 
                {ms: 1659519758996, onTime: 200, x: 20300, y: 1138}, 
                {ms: 1659519759196, onTime: 200, x: 20156, y: 1209}, 
                {ms: 1659519759396, onTime: 200, x: 19985, y: 1318}, 
                {ms: 1659519759496, onTime: 100, x: 19906, y: 1388}, 
                {ms: 1659519759596, onTime: 100, x: 19644, y: 1624}, 
                {ms: 1659519759796, onTime: 200, x: 19618, y: 1806}, 
                {ms: 1659519759996, onTime: 200, x: 19711, y: 1639}, 
                {ms: 1659519760096, onTime: 100, x: 19397, y: 1866}, 
                {ms: 1659519760296, onTime: 200, x: 19241, y: 1846}, 
                {ms: 1659519760496, onTime: 200, x: 19077, y: 1852}, 
                {ms: 1659519760696, onTime: 200, x: 18970, y: 1858}, 
                {ms: 1659519760796, onTime: 100, x: 19297, y: 1154}, 
                {ms: 1659519761396, onTime: 600, x: 18120, y: 1616}, 
                {ms: 1659519761596, onTime: 200, x: 17946, y: 1539}, 
                {ms: 1659519761796, onTime: 200, x: 17796, y: 1481}, 
                {ms: 1659519761896, onTime: 100, x: 17680, y: 1465}, 
                {ms: 1659519762096, onTime: 200, x: 17557, y: 1438}, 
                {ms: 1659519762296, onTime: 200, x: 17461, y: 1412}, 
                {ms: 1659519762396, onTime: 100, x: 17442, y: 1370}, 
                {ms: 1659519762596, onTime: 200, x: 17382, y: 1255}, 
                {ms: 1659519762696, onTime: 100, x: 17289, y: 1154}, 
                {ms: 1659519762896, onTime: 200, x: 17171, y: 1084}, 
                {ms: 1659519763396, onTime: 500, x: 17064, y: 1078}, 
                {ms: 1659519765996, onTime: 2600, x: 17166, y: 1062}, 
                {ms: 1659519766396, onTime: 400, x: 17044, y: 1042}, 
                {ms: 1659519767796, onTime: 1400, x: 17149, y: 1037}, 
                {ms: 1659519769496, onTime: 1700, x: 17253, y: 1010}, 
                {ms: 1659519770496, onTime: 1000, x: 17504, y: 1285}, 
                {ms: 1659519771196, onTime: 700, x: 17554, y: 1172}, 
                {ms: 1659519771396, onTime: 200, x: 17704, y: 1176}, 
                {ms: 1659519771596, onTime: 200, x: 17841, y: 1333}, 
                {ms: 1659519771796, onTime: 200, x: 17972, y: 1483}, 
                {ms: 1659519771996, onTime: 200, x: 18072, y: 1541}, 
                {ms: 1659519772196, onTime: 200, x: 18177, y: 1671}, 
                {ms: 1659519772296, onTime: 100, x: 18222, y: 1713}, 
                {ms: 1659519772396, onTime: 100, x: 18289, y: 1737}, 
                {ms: 1659519772596, onTime: 200, x: 18501, y: 1806}, 
                {ms: 1659519772696, onTime: 100, x: 18604, y: 1823}, 
                {ms: 1659519772796, onTime: 100, x: 18714, y: 1839}, 
                {ms: 1659519772896, onTime: 100, x: 18817, y: 1844}, 
                {ms: 1659519772996, onTime: 100, x: 18919, y: 1814}, 
                {ms: 1659519773196, onTime: 200, x: 19084, y: 1841}, 
                {ms: 1659519773296, onTime: 100, x: 19371, y: 1795}, 
                {ms: 1659519773396, onTime: 100, x: 18911, y: 1648}, 
                {ms: 1659519773496, onTime: 100, x: 18675, y: 1452}, 
                {ms: 1659519773596, onTime: 100, x: 18534, y: 1346}, 
                {ms: 1659519773896, onTime: 300, x: 18620, y: 1432}, 
                {ms: 1659519774696, onTime: 800, x: 18723, y: 1537}, 
                {ms: 1659519775096, onTime: 400, x: 18589, y: 1505}, 
                {ms: 1659519775196, onTime: 100, x: 18670, y: 1710}, 
                {ms: 1659519775296, onTime: 100, x: 18685, y: 2038}, 
                {ms: 1659519775596, onTime: 300, x: 18315, y: 2012}, 
                {ms: 1659519775696, onTime: 100, x: 18206, y: 1988}, 
                {ms: 1659519775896, onTime: 200, x: 18054, y: 1983}, 
                {ms: 1659519775996, onTime: 100, x: 17998, y: 1983}, 
                {ms: 1659519776096, onTime: 100, x: 17929, y: 1976}, 
                {ms: 1659519776296, onTime: 200, x: 17786, y: 1943}, 
                {ms: 1659519776396, onTime: 100, x: 17700, y: 1926}, 
                {ms: 1659519776496, onTime: 100, x: 17598, y: 1905}, 
                {ms: 1659519776596, onTime: 100, x: 17480, y: 1865}, 
                {ms: 1659519776696, onTime: 100, x: 17380, y: 1806}, 
                {ms: 1659519776896, onTime: 200, x: 17345, y: 1673}, 
                {ms: 1659519777096, onTime: 200, x: 17317, y: 1479}, 
                {ms: 1659519777196, onTime: 100, x: 17271, y: 1381}, 
                {ms: 1659519777396, onTime: 200, x: 17189, y: 1263}, 
                {ms: 1659519777496, onTime: 100, x: 17142, y: 1219}, 
                {ms: 1659519777596, onTime: 100, x: 17081, y: 1159}, 
                {ms: 1659519777896, onTime: 300, x: 16927, y: 1169}, 
                {ms: 1659519778296, onTime: 400, x: 17041, y: 1114}, 
                {ms: 1659519778496, onTime: 200, x: 17199, y: 1036}, 
                {ms: 1659519779596, onTime: 1100, x: 17125, y: 1115}, 
                {ms: 1659519780496, onTime: 900, x: 17147, y: 1250}, 
                {ms: 1659519781296, onTime: 800, x: 16796, y: 1919}, 
                {ms: 1659519781396, onTime: 100, x: 16960, y: 1854}, 
                {ms: 1659519781496, onTime: 100, x: 16771, y: 1972}, 
                {ms: 1659519781596, onTime: 100, x: 16948, y: 1856}, 
                {ms: 1659519781696, onTime: 100, x: 16810, y: 1955}, 
                {ms: 1659519782696, onTime: 1000, x: 16912, y: 1950}, 
                {ms: 1659519783096, onTime: 400, x: 17065, y: 1881}, 
                {ms: 1659519786196, onTime: 3100, x: 17321, y: 1895}, 
                {ms: 1659519786296, onTime: 100, x: 17044, y: 1823}, 
                {ms: 1659519786696, onTime: 400, x: 17128, y: 1764}, 
                {ms: 1659519786996, onTime: 300, x: 17294, y: 1865}, 
                {ms: 1659519787696, onTime: 700, x: 16799, y: 2206}, 
                {ms: 1659519788296, onTime: 600, x: 17296, y: 1733}, 
                {ms: 1659519788796, onTime: 500, x: 17386, y: 1784}, 
                {ms: 1659519789596, onTime: 800, x: 17157, y: 1822}, 
                {ms: 1659519789996, onTime: 400, x: 17261, y: 1883}, 
                {ms: 1659519790496, onTime: 500, x: 17364, y: 1966}, 
                {ms: 1659519791096, onTime: 600, x: 17593, y: 2040}, 
                {ms: 1659519791496, onTime: 400, x: 17491, y: 1969}, 
                {ms: 1659519791896, onTime: 400, x: 17637, y: 2031}, 
                {ms: 1659519792296, onTime: 400, x: 17491, y: 1969}, 
                {ms: 1659519792696, onTime: 400, x: 17638, y: 1987}, 
                {ms: 1659519795196, onTime: 2500, x: 17846, y: 2049}, 
                {ms: 1659519795396, onTime: 200, x: 17893, y: 2068}, 
                {ms: 1659519795896, onTime: 500, x: 17952, y: 2072}, 
                {ms: 1659519808196, onTime: 12300, x: 18807, y: 1696}, 
                {ms: 1659519808396, onTime: 200, x: 18976, y: 1640}, 
                {ms: 1659519808596, onTime: 200, x: 19165, y: 1605}, 
                {ms: 1659519808696, onTime: 100, x: 19287, y: 1586}, 
                {ms: 1659519808796, onTime: 100, x: 19396, y: 1559}, 
                {ms: 1659519808896, onTime: 100, x: 19506, y: 1522}, 
                {ms: 1659519808996, onTime: 100, x: 19618, y: 1490}, 
                {ms: 1659519809096, onTime: 100, x: 19731, y: 1471}, 
                {ms: 1659519809196, onTime: 100, x: 19858, y: 1457}, 
                {ms: 1659519809296, onTime: 100, x: 20088, y: 1409}, 
                {ms: 1659519809496, onTime: 200, x: 20232, y: 1371}, 
                {ms: 1659519809596, onTime: 100, x: 20345, y: 1362}, 
                {ms: 1659519809696, onTime: 100, x: 20537, y: 1325}, 
                {ms: 1659519809796, onTime: 100, x: 20655, y: 1271}, 
                {ms: 1659519809996, onTime: 200, x: 20895, y: 1184}, 
                {ms: 1659519810096, onTime: 100, x: 21055, y: 1155}, 
                {ms: 1659519810196, onTime: 100, x: 21176, y: 1123}, 
                {ms: 1659519810296, onTime: 100, x: 21297, y: 1084}, 
                {ms: 1659519810496, onTime: 200, x: 21469, y: 999}, 
                {ms: 1659519810696, onTime: 200, x: 21630, y: 905}, 
                {ms: 1659519810896, onTime: 200, x: 21765, y: 840}, 
                {ms: 1659519811096, onTime: 200, x: 21898, y: 812}, 
                {ms: 1659519811196, onTime: 100, x: 21938, y: 802}, 
                {ms: 1659519811396, onTime: 200, x: 21999, y: 798}, 
                {ms: 1659519814796, onTime: 3400, x: 21836, y: 816}, 
                {ms: 1659519815796, onTime: 1000, x: 21253, y: 978}, 
                {ms: 1659519815896, onTime: 100, x: 21150, y: 1000}, 
                {ms: 1659519816096, onTime: 200, x: 20928, y: 1075}, 
                {ms: 1659519816196, onTime: 100, x: 20819, y: 1105}, 
                {ms: 1659519816296, onTime: 100, x: 20699, y: 1129}, 
                {ms: 1659519816396, onTime: 100, x: 20596, y: 1162}, 
                {ms: 1659519816496, onTime: 100, x: 20499, y: 1193}, 
                {ms: 1659519816696, onTime: 200, x: 20318, y: 1213}, 
                {ms: 1659519816796, onTime: 100, x: 20131, y: 1231}, 
                {ms: 1659519816996, onTime: 200, x: 20013, y: 1230}, 
                {ms: 1659519817196, onTime: 200, x: 19857, y: 1234}, 
                {ms: 1659519817296, onTime: 100, x: 19740, y: 1248}, 
                {ms: 1659519817396, onTime: 100, x: 19620, y: 1253}, 
                {ms: 1659519817596, onTime: 200, x: 19366, y: 1239}, 
                {ms: 1659519817796, onTime: 200, x: 19152, y: 1212}, 
                {ms: 1659519817896, onTime: 100, x: 19029, y: 1198}, 
                {ms: 1659519818096, onTime: 200, x: 18508, y: 1427}, 
                {ms: 1659519818296, onTime: 200, x: 18357, y: 1432}, 
                {ms: 1659519818496, onTime: 200, x: 18184, y: 1425}, 
                {ms: 1659519818696, onTime: 200, x: 18040, y: 1399}, 
                {ms: 1659519818896, onTime: 200, x: 17880, y: 1334}, 
                {ms: 1659519819096, onTime: 200, x: 17734, y: 1273}, 
                {ms: 1659519819296, onTime: 200, x: 17591, y: 1237}, 
                {ms: 1659519819496, onTime: 200, x: 17450, y: 1206}, 
                {ms: 1659519819696, onTime: 200, x: 17332, y: 1163}, 
                {ms: 1659519819796, onTime: 100, x: 17297, y: 1114}, 
                {ms: 1659519819896, onTime: 100, x: 17277, y: 1072}, 
                {ms: 1659519820296, onTime: 400, x: 17165, y: 1013}, 
                {ms: 1659519828096, onTime: 7800, x: 17056, y: 1024}, 
                {ms: 1659519829596, onTime: 1500, x: 17051, y: 1127}, 
                {ms: 1659519830896, onTime: 1300, x: 17037, y: 1025}, 
                {ms: 1659519831796, onTime: 900, x: 17135, y: 993}, 
                {ms: 1659519832096, onTime: 300, x: 17152, y: 844}, 
                {ms: 1659519832396, onTime: 300, x: 17261, y: 1021}, 
                {ms: 1659519832596, onTime: 200, x: 16882, y: 1346}, 
                {ms: 1659519832996, onTime: 400, x: 17167, y: 981}, 
                {ms: 1659519833396, onTime: 400, x: 17295, y: 1009}, 
                {ms: 1659519833796, onTime: 400, x: 17240, y: 895}, 
                {ms: 1659519834196, onTime: 400, x: 17159, y: 986}, 
                {ms: 1659519834796, onTime: 600, x: 17243, y: 925}, 
                {ms: 1659519835096, onTime: 300, x: 17129, y: 963}, 
                {ms: 1659519835696, onTime: 600, x: 17244, y: 935}, 
                {ms: 1659519835896, onTime: 200, x: 17144, y: 951}, 
                {ms: 1659519836696, onTime: 800, x: 17250, y: 957}, 
                {ms: 1659519837396, onTime: 700, x: 17365, y: 961}, 
                {ms: 1659519837896, onTime: 500, x: 17207, y: 975}, 
                {ms: 1659519844396, onTime: 6500, x: 17103, y: 996}, 
                {ms: 1659519845196, onTime: 800, x: 17226, y: 960}, 
                {ms: 1659519860296, onTime: 15100, x: 17194, y: 1055}, 
                {ms: 1659519915996, onTime: 55700, x: 17275, y: 949}, 
                {ms: 1659519916796, onTime: 800, x: 17275, y: 1053}, 
                {ms: 1659519917796, onTime: 1000, x: 17206, y: 1152}, 
                {ms: 1659519918496, onTime: 700, x: 17358, y: 1100}, 
                {ms: 1659519919496, onTime: 1000, x: 17458, y: 1076}, 
                {ms: 1659519920496, onTime: 1000, x: 17334, y: 1088}, 
                {ms: 1659519923096, onTime: 2600, x: 17345, y: 972}, 
                {ms: 1659519924296, onTime: 1200, x: 17275, y: 1077}, 
                {ms: 1659519925196, onTime: 900, x: 17377, y: 986}, 
                {ms: 1659519927296, onTime: 2100, x: 17354, y: 1100}, 
                {ms: 1659519928096, onTime: 800, x: 17373, y: 1001}, 
                {ms: 1659519929496, onTime: 1400, x: 17388, y: 1104}, 
                {ms: 1659519930496, onTime: 1000, x: 17278, y: 1112}, 
                {ms: 1659519955796, onTime: 25300, x: 17383, y: 1091}, 
                {ms: 1659519956396, onTime: 600, x: 17237, y: 1137}, 
                {ms: 1659519957596, onTime: 1200, x: 17356, y: 1197}, 
                {ms: 1659519957996, onTime: 400, x: 17389, y: 1090}, 
                {ms: 1659519958996, onTime: 1000, x: 17389, y: 973}, 
                {ms: 1659519961296, onTime: 2300, x: 17349, y: 1068}, 
                {ms: 1659519962196, onTime: 900, x: 17461, y: 985}, 
                {ms: 1659519962796, onTime: 600, x: 17363, y: 964}, 
                {ms: 1659519964496, onTime: 1700, x: 17449, y: 1032}, 
                {ms: 1659519966996, onTime: 2500, x: 17379, y: 1108}, 
                {ms: 1659519969396, onTime: 2400, x: 17441, y: 1028}, 
                {ms: 1659519971096, onTime: 1700, x: 17332, y: 1070}, 
                {ms: 1659519973996, onTime: 2900, x: 17441, y: 1084}, 
                {ms: 1659519978996, onTime: 5000, x: 17304, y: 1027}, 
                {ms: 1659519983096, onTime: 4100, x: 17398, y: 1085}, 
                {ms: 1659519983496, onTime: 400, x: 17112, y: 1631}, 
                {ms: 1659519983696, onTime: 200, x: 17243, y: 1686}, 
                {ms: 1659519983996, onTime: 300, x: 17313, y: 1813}, 
                {ms: 1659519984096, onTime: 100, x: 17324, y: 1856}, 
                {ms: 1659519984296, onTime: 200, x: 17337, y: 1982}, 
                {ms: 1659519984496, onTime: 200, x: 17323, y: 2159}, 
                {ms: 1659519984796, onTime: 300, x: 17344, y: 2356}, 
                {ms: 1659519984996, onTime: 200, x: 17408, y: 2468}, 
                {ms: 1659519985096, onTime: 100, x: 17489, y: 2606}, 
                {ms: 1659519985296, onTime: 200, x: 17517, y: 2718}, 
                {ms: 1659519985396, onTime: 100, x: 17520, y: 2759}, 
                {ms: 1659519985596, onTime: 200, x: 17511, y: 2899}, 
                {ms: 1659519985796, onTime: 200, x: 17525, y: 3043}, 
                {ms: 1659519985896, onTime: 100, x: 17533, y: 3093}, 
                {ms: 1659519986196, onTime: 300, x: 17589, y: 3196}, 
                {ms: 1659519986396, onTime: 200, x: 17681, y: 3265}, 
                {ms: 1659519986596, onTime: 200, x: 17762, y: 3322}, 
                {ms: 1659519986696, onTime: 100, x: 17784, y: 3344}, 
                {ms: 1659519987096, onTime: 400, x: 17792, y: 3462}, 
                {ms: 1659519987396, onTime: 300, x: 17812, y: 3566}, 
                {ms: 1659519987596, onTime: 200, x: 17804, y: 3633}, 
                {ms: 1659519987896, onTime: 300, x: 17812, y: 3670}, 
                {ms: 1659519988396, onTime: 500, x: 17552, y: 3660}, 
                {ms: 1659519991096, onTime: 2700, x: 17566, y: 3514}, 
                {ms: 1659519993096, onTime: 2000, x: 17757, y: 3632}, 
                {ms: 1659519993596, onTime: 500, x: 17608, y: 3696}, 
                {ms: 1659519995496, onTime: 1900, x: 17668, y: 3593}, 
                {ms: 1659519996196, onTime: 700, x: 17768, y: 3515}, 
                {ms: 1659519997596, onTime: 1400, x: 17580, y: 3569}, 
                {ms: 1659519997696, onTime: 100, x: 17703, y: 3282}, 
                {ms: 1659519998696, onTime: 1000, x: 17708, y: 3390}, 
                {ms: 1659519998996, onTime: 300, x: 17749, y: 3556}, 
                {ms: 1659519999396, onTime: 400, x: 17713, y: 3583}, 
                {ms: 1659519999896, onTime: 500, x: 17669, y: 3631}, 
                {ms: 1659520004596, onTime: 4700, x: 17562, y: 3589}, 
                {ms: 1659520005296, onTime: 700, x: 17626, y: 3669}, 
                {ms: 1659520009996, onTime: 4700, x: 17746, y: 3596}, 
                {ms: 1659520010596, onTime: 600, x: 17657, y: 3535}, 
                {ms: 1659520010696, onTime: 100, x: 17853, y: 3555}, 
                {ms: 1659520010796, onTime: 100, x: 17657, y: 3535}, 
                {ms: 1659520010996, onTime: 200, x: 17854, y: 3527}, 
                {ms: 1659520011196, onTime: 200, x: 17767, y: 3610}, 
                {ms: 1659520011996, onTime: 800, x: 17657, y: 3537}, 
                {ms: 1659520012796, onTime: 800, x: 17752, y: 3592}, 
                {ms: 1659520012996, onTime: 200, x: 17627, y: 3547}, 
                {ms: 1659520014096, onTime: 1100, x: 17609, y: 3657}, 
                {ms: 1659520026496, onTime: 12400, x: 17819, y: 3667}, 
                {ms: 1659520026596, onTime: 100, x: 17621, y: 3644}, 
                {ms: 1659520027296, onTime: 700, x: 17815, y: 3678}, 
                {ms: 1659520029096, onTime: 1800, x: 17593, y: 3655}, 
                {ms: 1659520030596, onTime: 1500, x: 17861, y: 3670}, 
                {ms: 1659520031696, onTime: 1100, x: 17965, y: 3616}, 
                {ms: 1659520032696, onTime: 1000, x: 17780, y: 3547}, 
                {ms: 1659520033296, onTime: 600, x: 17944, y: 3610}, 
                {ms: 1659520034196, onTime: 900, x: 17751, y: 3606}, 
                {ms: 1659520034296, onTime: 100, x: 17957, y: 3596}, 
                {ms: 1659520034396, onTime: 100, x: 17745, y: 3596}, 
                {ms: 1659520034496, onTime: 100, x: 17969, y: 3604}, 
                {ms: 1659520034596, onTime: 100, x: 17745, y: 3596}, 
                {ms: 1659520044296, onTime: 9700, x: 17908, y: 3580}, 
                {ms: 1659520044396, onTime: 100, x: 17744, y: 3605}, 
                {ms: 1659520044896, onTime: 500, x: 17764, y: 3751}, 
                {ms: 1659520045396, onTime: 500, x: 17800, y: 3866}, 
                {ms: 1659520045496, onTime: 100, x: 17784, y: 3890}, 
                {ms: 1659520050296, onTime: 4800, x: 17964, y: 3883}, 
                {ms: 1659520050396, onTime: 100, x: 17903, y: 3779}, 
                {ms: 1659520050496, onTime: 100, x: 17957, y: 3914}, 
                {ms: 1659520050796, onTime: 300, x: 17735, y: 3875}, 
                {ms: 1659520060796, onTime: 10000, x: 17960, y: 3886}, 
                {ms: 1659520060896, onTime: 100, x: 17749, y: 3830}, 
                {ms: 1659520061096, onTime: 200, x: 17955, y: 3889}, 
                {ms: 1659520061196, onTime: 100, x: 17798, y: 3853}, 
                {ms: 1659520061796, onTime: 600, x: 17709, y: 3764}, 
                {ms: 1659520062196, onTime: 400, x: 17635, y: 3670}, 
                {ms: 1659520062496, onTime: 300, x: 17583, y: 3633}, 
                {ms: 1659520065996, onTime: 3500, x: 17555, y: 3368}, 
                {ms: 1659520066996, onTime: 1000, x: 17583, y: 3627}, 
                {ms: 1659520071596, onTime: 4600, x: 17861, y: 3621}, 
                {ms: 1659520071696, onTime: 100, x: 17889, y: 3631}, 
                {ms: 1659520071796, onTime: 100, x: 17615, y: 3616}, 
                {ms: 1659520071896, onTime: 100, x: 17588, y: 3613}, 
                {ms: 1659520072196, onTime: 300, x: 17879, y: 3629}, 
                {ms: 1659520072396, onTime: 200, x: 17613, y: 3615}, 
                {ms: 1659520073796, onTime: 1400, x: 17862, y: 3623}, 
                {ms: 1659520074896, onTime: 1100, x: 17560, y: 3609}, 
                {ms: 1659520076596, onTime: 1700, x: 17831, y: 3548}, 
                {ms: 1659520077696, onTime: 1100, x: 17720, y: 3638}, 
                {ms: 1659520077796, onTime: 100, x: 17866, y: 3624}, 
                {ms: 1659520077996, onTime: 200, x: 17697, y: 3652}, 
                {ms: 1659520078096, onTime: 100, x: 17598, y: 3623}, 
                {ms: 1659520081696, onTime: 3600, x: 17500, y: 3583}, 
                {ms: 1659520086996, onTime: 5300, x: 17776, y: 3609}, 
                {ms: 1659520087096, onTime: 100, x: 17576, y: 3658}, 
                {ms: 1659520090396, onTime: 3300, x: 17806, y: 3611}, 
                {ms: 1659520093896, onTime: 3500, x: 17772, y: 3716}, 
                {ms: 1659520093996, onTime: 100, x: 17578, y: 3674}, 
                {ms: 1659520102696, onTime: 8700, x: 17799, y: 3623}, 
                {ms: 1659520103996, onTime: 1300, x: 17551, y: 3697}, 
                {ms: 1659520106596, onTime: 2600, x: 17802, y: 3645}, 
                {ms: 1659520106796, onTime: 200, x: 17605, y: 3661}, 
                {ms: 1659520124396, onTime: 17600, x: 17807, y: 3634}, 
                {ms: 1659520124496, onTime: 100, x: 17601, y: 3667}, 
                {ms: 1659520124596, onTime: 100, x: 17799, y: 3631}, 
                {ms: 1659520125496, onTime: 900, x: 17615, y: 3676}, 
                {ms: 1659520125596, onTime: 100, x: 17821, y: 3644}, 
                {ms: 1659520125696, onTime: 100, x: 17596, y: 3676}, 
                {ms: 1659520144896, onTime: 19200, x: 17786, y: 3624}, 
                {ms: 1659520149696, onTime: 4800, x: 17784, y: 3732}, 
                {ms: 1659520149996, onTime: 300, x: 17584, y: 3703}, 
                {ms: 1659520152896, onTime: 2900, x: 17468, y: 3682}, 
                {ms: 1659520154396, onTime: 1500, x: 17642, y: 3682}, 
                {ms: 1659520154596, onTime: 200, x: 17536, y: 3664}, 
                {ms: 1659520164596, onTime: 10000, x: 17801, y: 3619}, 
                {ms: 1659520166496, onTime: 1900, x: 17637, y: 3560}, 
                {ms: 1659520166696, onTime: 200, x: 17790, y: 3608}, 
                {ms: 1659520166796, onTime: 100, x: 17588, y: 3600}, 
                {ms: 1659520166896, onTime: 100, x: 17790, y: 3608}, 
                {ms: 1659520171196, onTime: 4300, x: 17599, y: 3673}, 
                {ms: 1659520172696, onTime: 1500, x: 17816, y: 3730}, 
                {ms: 1659520173596, onTime: 900, x: 17785, y: 3600}, 
                {ms: 1659520174296, onTime: 700, x: 17690, y: 3790}, 
                {ms: 1659520174396, onTime: 100, x: 17567, y: 3719}, 
                {ms: 1659520174596, onTime: 200, x: 17484, y: 3613}, 
                {ms: 1659520174996, onTime: 400, x: 17598, y: 3651}, 
                {ms: 1659520202896, onTime: 27900, x: 17803, y: 3640}, 
                {ms: 1659520203096, onTime: 200, x: 17662, y: 3611}, 
                {ms: 1659520210596, onTime: 7500, x: 17591, y: 3698}, 
                {ms: 1659520212196, onTime: 1600, x: 17689, y: 3670}, 
                {ms: 1659520219096, onTime: 6900, x: 17596, y: 3600}, 
                {ms: 1659520220996, onTime: 1900, x: 17794, y: 3665}, 
                {ms: 1659520221996, onTime: 1000, x: 17636, y: 3661}, 
                {ms: 1659520223596, onTime: 1600, x: 17788, y: 3638}, 
                {ms: 1659520223796, onTime: 200, x: 17675, y: 3653}, 
                {ms: 1659520223896, onTime: 100, x: 17797, y: 3637}, 
                {ms: 1659520224096, onTime: 200, x: 17656, y: 3647}, 
                {ms: 1659520224596, onTime: 500, x: 17797, y: 3688}, 
                {ms: 1659520224696, onTime: 100, x: 17630, y: 3675}, 
                {ms: 1659520228296, onTime: 3600, x: 17734, y: 3664}, 
                {ms: 1659520229396, onTime: 1100, x: 17624, y: 3730}, 
                {ms: 1659520229796, onTime: 400, x: 17803, y: 3659}, 
                {ms: 1659520229996, onTime: 200, x: 17698, y: 3745}, 
                {ms: 1659520230096, onTime: 100, x: 17792, y: 3657}, 
                {ms: 1659520230296, onTime: 200, x: 17623, y: 3705}, 
                {ms: 1659520230496, onTime: 200, x: 17792, y: 3657}, 
                {ms: 1659520230796, onTime: 300, x: 17670, y: 3659}, 
                {ms: 1659520231296, onTime: 500, x: 17566, y: 3666}, 
                {ms: 1659520233496, onTime: 2200, x: 17676, y: 3613}, 
                {ms: 1659520233696, onTime: 200, x: 17833, y: 3554}, 
                {ms: 1659520234696, onTime: 1000, x: 17583, y: 3562}, 
                {ms: 1659520235796, onTime: 1100, x: 17681, y: 3603}, 
                {ms: 1659520236696, onTime: 900, x: 17630, y: 3493}, 
                {ms: 1659520238096, onTime: 1400, x: 17803, y: 3400}, 
                {ms: 1659520238696, onTime: 600, x: 17578, y: 3476}, 
                {ms: 1659520239596, onTime: 900, x: 17801, y: 3406}, 
                {ms: 1659520239896, onTime: 300, x: 17622, y: 3651}, 
                {ms: 1659520240396, onTime: 500, x: 17738, y: 3599}, 
                {ms: 1659520240896, onTime: 500, x: 17595, y: 3621}, 
                {ms: 1659520241496, onTime: 600, x: 17784, y: 3628}, 
                {ms: 1659520241596, onTime: 100, x: 17577, y: 3612}, 
                {ms: 1659520242996, onTime: 1400, x: 17807, y: 3571}, 
                {ms: 1659520243796, onTime: 800, x: 17527, y: 3774}, 
                {ms: 1659520244496, onTime: 700, x: 17638, y: 3750}, 
                {ms: 1659520249696, onTime: 5200, x: 17836, y: 3747}, 
                {ms: 1659520249796, onTime: 100, x: 17708, y: 3811}, 
                {ms: 1659520250096, onTime: 300, x: 17612, y: 3726}, 
                {ms: 1659520250496, onTime: 400, x: 17838, y: 3734}, 
                {ms: 1659520250996, onTime: 500, x: 17635, y: 3753}, 
                {ms: 1659520251296, onTime: 300, x: 17843, y: 3738}, 
                {ms: 1659520251396, onTime: 100, x: 17611, y: 3723}, 
                {ms: 1659520251696, onTime: 300, x: 17835, y: 3736}, 
                {ms: 1659520252196, onTime: 500, x: 17588, y: 3703}, 
                {ms: 1659520253696, onTime: 1500, x: 17881, y: 3737}, 
                {ms: 1659520254596, onTime: 900, x: 17920, y: 3632}, 
                {ms: 1659520254896, onTime: 300, x: 17936, y: 3518}, 
                {ms: 1659520255596, onTime: 700, x: 17966, y: 3696}, 
                {ms: 1659520256696, onTime: 1100, x: 17686, y: 4493}, 
                {ms: 1659520256896, onTime: 200, x: 17610, y: 4591}, 
                {ms: 1659520257096, onTime: 200, x: 17543, y: 4711}, 
                {ms: 1659520257196, onTime: 100, x: 17507, y: 4751}, 
                {ms: 1659520257296, onTime: 100, x: 17484, y: 4792}, 
                {ms: 1659520257596, onTime: 300, x: 17465, y: 4927}, 
                {ms: 1659520257896, onTime: 300, x: 17430, y: 5028}, 
                {ms: 1659520257996, onTime: 100, x: 17418, y: 5040}, 
                {ms: 1659520260096, onTime: 2100, x: 17337, y: 5078}, 
                {ms: 1659520271296, onTime: 11200, x: 17390, y: 4980}, 
                {ms: 1659520286396, onTime: 15100, x: 17468, y: 5045}, 
                {ms: 1659520303096, onTime: 16700, x: 17361, y: 5062}, 
                {ms: 1659520304296, onTime: 1200, x: 17465, y: 5011}, 
                {ms: 1659520304796, onTime: 500, x: 17349, y: 5047}, 
                {ms: 1659520306096, onTime: 1300, x: 17157, y: 5106}, 
                {ms: 1659520306596, onTime: 500, x: 17309, y: 5082}, 
                {ms: 1659520306796, onTime: 200, x: 17408, y: 5048}, 
                {ms: 1659520306996, onTime: 200, x: 17251, y: 5085}, 
                {ms: 1659520307296, onTime: 300, x: 17358, y: 5036}, 
                {ms: 1659520308396, onTime: 1100, x: 17459, y: 5008}, 
                {ms: 1659520326496, onTime: 18100, x: 17368, y: 5074}, 
                {ms: 1659520327396, onTime: 900, x: 17252, y: 5025}, 
                {ms: 1659520328096, onTime: 700, x: 17194, y: 4875}, 
                {ms: 1659520329596, onTime: 1500, x: 17296, y: 4882}, 
                {ms: 1659520330496, onTime: 900, x: 17330, y: 4979}, 
                {ms: 1659520349596, onTime: 19100, x: 17441, y: 4994}, 
                {ms: 1659520359396, onTime: 9800, x: 17329, y: 4981}, 
                {ms: 1659520380796, onTime: 21400, x: 17423, y: 4940}, 
                {ms: 1659520400896, onTime: 20100, x: 17294, y: 4902}, 
                {ms: 1659520403096, onTime: 2200, x: 17346, y: 5041}, 
                {ms: 1659520403696, onTime: 600, x: 17426, y: 5517}, 
                {ms: 1659520404596, onTime: 900, x: 17379, y: 5611}, 
                {ms: 1659520404896, onTime: 300, x: 17442, y: 5510}, 
                {ms: 1659520406696, onTime: 1800, x: 17681, y: 4304}, 
                {ms: 1659520406896, onTime: 200, x: 17765, y: 4137}, 
                {ms: 1659520407096, onTime: 200, x: 17787, y: 4037}, 
                {ms: 1659520407196, onTime: 100, x: 17786, y: 3994}, 
                {ms: 1659520407396, onTime: 200, x: 17778, y: 3869}, 
                {ms: 1659520407596, onTime: 200, x: 17783, y: 3722}, 
                {ms: 1659520407696, onTime: 100, x: 17794, y: 3654}, 
                {ms: 1659520407796, onTime: 100, x: 17810, y: 3612}, 
                {ms: 1659520407996, onTime: 200, x: 17599, y: 3466}, 
                {ms: 1659520408096, onTime: 100, x: 17877, y: 3467}, 
                {ms: 1659520408196, onTime: 100, x: 17614, y: 3481}, 
                {ms: 1659520408296, onTime: 100, x: 17559, y: 3448}, 
                {ms: 1659520408496, onTime: 200, x: 17630, y: 3335}, 
                {ms: 1659520408596, onTime: 100, x: 17590, y: 3439}, 
                {ms: 1659520408996, onTime: 400, x: 17702, y: 3363}, 
                {ms: 1659520409396, onTime: 400, x: 17881, y: 3266}, 
                {ms: 1659520409696, onTime: 300, x: 17979, y: 3220}, 
                {ms: 1659520409996, onTime: 300, x: 17750, y: 3165}, 
                {ms: 1659520410296, onTime: 300, x: 17573, y: 3463}, 
                {ms: 1659520410396, onTime: 100, x: 17344, y: 3479}, 
                {ms: 1659520410596, onTime: 200, x: 17478, y: 3611}, 
                {ms: 1659520410696, onTime: 100, x: 17553, y: 3408}, 
                {ms: 1659520410796, onTime: 100, x: 17608, y: 3673}, 
                {ms: 1659520410896, onTime: 100, x: 18115, y: 3172}, 
                {ms: 1659520410996, onTime: 100, x: 17627, y: 3649}, 
                {ms: 1659520411196, onTime: 200, x: 17701, y: 3559}, 
                {ms: 1659520411496, onTime: 300, x: 17799, y: 3581}, 
                {ms: 1659520411896, onTime: 400, x: 18043, y: 2908}, 
                {ms: 1659520412196, onTime: 300, x: 17930, y: 2971}, 
                {ms: 1659520412396, onTime: 200, x: 17782, y: 3535}, 
                {ms: 1659520412496, onTime: 100, x: 17924, y: 2943}, 
                {ms: 1659520412696, onTime: 200, x: 17793, y: 3547}, 
                {ms: 1659520412796, onTime: 100, x: 17984, y: 2958}, 
                {ms: 1659520412996, onTime: 200, x: 17796, y: 3555}, 
                {ms: 1659520413096, onTime: 100, x: 17989, y: 2901}, 
                {ms: 1659520413296, onTime: 200, x: 17831, y: 3512}, 
                {ms: 1659520413696, onTime: 400, x: 17704, y: 3572}, 
                {ms: 1659520414296, onTime: 600, x: 17830, y: 2923}, 
                {ms: 1659520417696, onTime: 3400, x: 17844, y: 3073}, 
                {ms: 1659520417896, onTime: 200, x: 18017, y: 3112}, 
                {ms: 1659520418196, onTime: 300, x: 18010, y: 2905}, 
                {ms: 1659520419196, onTime: 1000, x: 17909, y: 2878}, 
                {ms: 1659520425096, onTime: 5900, x: 17823, y: 2992}, 
                {ms: 1659520425596, onTime: 500, x: 18048, y: 3070}, 
                {ms: 1659520426196, onTime: 600, x: 18169, y: 3030}, 
                {ms: 1659520426296, onTime: 100, x: 17982, y: 2947}, 
                {ms: 1659520426796, onTime: 500, x: 17819, y: 2934}, 
                {ms: 1659520431496, onTime: 4700, x: 17921, y: 3042}, 
                {ms: 1659520431996, onTime: 500, x: 17981, y: 2952}, 
                {ms: 1659520432996, onTime: 1000, x: 17839, y: 2912}, 
                {ms: 1659520433296, onTime: 300, x: 17943, y: 2874}, 
                {ms: 1659520436196, onTime: 2900, x: 17908, y: 2977}, 
                {ms: 1659520438296, onTime: 2100, x: 17885, y: 2879}, 
                {ms: 1659520443696, onTime: 5400, x: 17916, y: 2976}, 
                {ms: 1659520451096, onTime: 7400, x: 17977, y: 3129}, 
                {ms: 1659520451796, onTime: 700, x: 18002, y: 2991}, 
                {ms: 1659520451996, onTime: 200, x: 18020, y: 3097}, 
                {ms: 1659520452096, onTime: 100, x: 18001, y: 2898}, 
                {ms: 1659520452796, onTime: 700, x: 17890, y: 2901}, 
                {ms: 1659520467496, onTime: 14700, x: 17935, y: 3037}, 
                {ms: 1659520468396, onTime: 900, x: 17704, y: 2810}, 
                {ms: 1659520468796, onTime: 400, x: 17907, y: 2846}, 
                {ms: 1659520500596, onTime: 31800, x: 17910, y: 3004}, 
                {ms: 1659520500996, onTime: 400, x: 17883, y: 2900}, 
                {ms: 1659520506196, onTime: 5200, x: 17925, y: 3011}, 
                {ms: 1659520506596, onTime: 400, x: 17895, y: 2894}, 
                {ms: 1659520513096, onTime: 6500, x: 17922, y: 3005}, 
                {ms: 1659520513696, onTime: 600, x: 17885, y: 2910}, 
                {ms: 1659520515996, onTime: 2300, x: 17908, y: 3013}, 
                {ms: 1659520516996, onTime: 1000, x: 17888, y: 2887}, 
                {ms: 1659520522496, onTime: 5500, x: 17980, y: 3005}, 
                {ms: 1659520524096, onTime: 1600, x: 17303, y: 3645}, 
                {ms: 1659520524296, onTime: 200, x: 17257, y: 3498}, 
                {ms: 1659520524396, onTime: 100, x: 17897, y: 2915}, 
                {ms: 1659520524796, onTime: 400, x: 17232, y: 3242}, 
                {ms: 1659520524896, onTime: 100, x: 17918, y: 2939}, 
                {ms: 1659520525396, onTime: 500, x: 18104, y: 3566}, 
                {ms: 1659520525496, onTime: 100, x: 17903, y: 2921}, 
                {ms: 1659520530696, onTime: 5200, x: 17842, y: 3481}, 
                {ms: 1659520530896, onTime: 200, x: 17903, y: 2906}, 
                {ms: 1659520530996, onTime: 100, x: 17873, y: 3497}, 
                {ms: 1659520531496, onTime: 500, x: 17893, y: 2914}, 
                {ms: 1659520531896, onTime: 400, x: 17863, y: 3483}, 
                {ms: 1659520532696, onTime: 800, x: 17903, y: 2935}, 
                {ms: 1659520532996, onTime: 300, x: 17871, y: 3475}, 
                {ms: 1659520533396, onTime: 400, x: 17910, y: 2936}, 
                {ms: 1659520546396, onTime: 13000, x: 17882, y: 3494}, 
                {ms: 1659520547496, onTime: 1100, x: 17969, y: 3413}, 
                {ms: 1659520547596, onTime: 100, x: 17892, y: 2884}, 
                {ms: 1659520547696, onTime: 100, x: 17969, y: 3413}, 
                {ms: 1659520547896, onTime: 200, x: 17841, y: 3504}, 
                {ms: 1659520548496, onTime: 600, x: 17902, y: 2921}, 
                {ms: 1659520548696, onTime: 200, x: 17880, y: 3488}, 
                {ms: 1659520549196, onTime: 500, x: 17913, y: 2918}, 
                {ms: 1659520549496, onTime: 300, x: 17877, y: 3458}, 
                {ms: 1659520549596, onTime: 100, x: 17895, y: 2852}, 
                {ms: 1659520551796, onTime: 2200, x: 17972, y: 2742}, 
                {ms: 1659520552496, onTime: 700, x: 17843, y: 2786}, 
                {ms: 1659520553196, onTime: 700, x: 17910, y: 2900}, 
                {ms: 1659520563796, onTime: 10600, x: 17831, y: 2766}, 
                {ms: 1659520563996, onTime: 200, x: 17806, y: 2868}, 
                {ms: 1659520573296, onTime: 9300, x: 17900, y: 2921}, 
                {ms: 1659520583296, onTime: 10000, x: 17839, y: 3002}, 
                {ms: 1659520583896, onTime: 600, x: 17973, y: 2855}, 
                {ms: 1659520584096, onTime: 200, x: 17854, y: 2921}, 
                {ms: 1659520595996, onTime: 11900, x: 18089, y: 2927}, 
                {ms: 1659520596196, onTime: 200, x: 18193, y: 2968}, 
                {ms: 1659520596296, onTime: 100, x: 18092, y: 2943}, 
                {ms: 1659520596496, onTime: 200, x: 17914, y: 2923}, 
                {ms: 1659520602296, onTime: 5800, x: 17998, y: 2993}, 
                {ms: 1659520602796, onTime: 500, x: 17803, y: 2963}, 
                {ms: 1659520602996, onTime: 200, x: 17701, y: 2870}, 
                {ms: 1659520603496, onTime: 500, x: 17897, y: 2961}, 
                {ms: 1659520618996, onTime: 15500, x: 17970, y: 2890}, 
                {ms: 1659520619196, onTime: 200, x: 17982, y: 3003}, 
                {ms: 1659520619896, onTime: 700, x: 17924, y: 3085}, 
                {ms: 1659520620296, onTime: 400, x: 17862, y: 2903}, 
                {ms: 1659520621396, onTime: 1100, x: 17915, y: 3018}, 
                {ms: 1659520622196, onTime: 800, x: 17876, y: 2905}, 
                {ms: 1659520622496, onTime: 300, x: 17915, y: 3003}, 
                {ms: 1659520627596, onTime: 5100, x: 17883, y: 2881}, 
                {ms: 1659520628596, onTime: 1000, x: 17912, y: 2980}, 
                {ms: 1659520629196, onTime: 600, x: 17866, y: 2871}, 
                {ms: 1659520629396, onTime: 200, x: 17904, y: 2997}, 
                {ms: 1659520629796, onTime: 400, x: 17872, y: 2901}, 
                {ms: 1659520630096, onTime: 300, x: 17939, y: 3054}, 
                {ms: 1659520630596, onTime: 500, x: 17882, y: 2942}, 
                {ms: 1659520658296, onTime: 27700, x: 17873, y: 2838}, 
                {ms: 1659520659396, onTime: 1100, x: 17821, y: 1986}, 
                {ms: 1659520659896, onTime: 500, x: 17980, y: 2012}, 
                {ms: 1659520660396, onTime: 500, x: 17885, y: 2866}, 
                {ms: 1659520660696, onTime: 300, x: 18057, y: 1996}, 
                {ms: 1659520660796, onTime: 100, x: 17882, y: 2867}, 
                {ms: 1659520671196, onTime: 10400, x: 17931, y: 2967}, 
                {ms: 1659520672396, onTime: 1200, x: 17860, y: 2887}, 
                {ms: 1659520684096, onTime: 11700, x: 17908, y: 2977}, 
                {ms: 1659520686296, onTime: 2200, x: 17867, y: 2878}, 
                {ms: 1659520708396, onTime: 22100, x: 17938, y: 2953}, 
                {ms: 1659520709996, onTime: 1600, x: 17869, y: 2868}, 
                {ms: 1659520710396, onTime: 400, x: 17915, y: 2965}, 
                {ms: 1659520719596, onTime: 9200, x: 17879, y: 2870}, 
                {ms: 1659520719896, onTime: 300, x: 17927, y: 2978}, 
                {ms: 1659520720596, onTime: 700, x: 17882, y: 2876}, 
                {ms: 1659520723296, onTime: 2700, x: 17924, y: 2975}, 
                {ms: 1659520723496, onTime: 200, x: 17879, y: 2882}, 
                {ms: 1659520724396, onTime: 900, x: 17970, y: 3046}, 
                {ms: 1659520724996, onTime: 600, x: 17897, y: 2918}, 
                {ms: 1659520725296, onTime: 300, x: 17976, y: 3067}, 
                {ms: 1659520725696, onTime: 400, x: 17902, y: 2920}, 
                {ms: 1659520729296, onTime: 3600, x: 17836, y: 3361}, 
                {ms: 1659520729596, onTime: 300, x: 17897, y: 2919}, 
                {ms: 1659520729796, onTime: 200, x: 17972, y: 3062}, 
                {ms: 1659520729896, onTime: 100, x: 17784, y: 3236}, 
                {ms: 1659520732496, onTime: 2600, x: 17757, y: 3340}, 
                {ms: 1659520732696, onTime: 200, x: 17402, y: 3867}, 
                {ms: 1659520732796, onTime: 100, x: 17713, y: 3996}, 
                {ms: 1659520732996, onTime: 200, x: 17772, y: 4163}, 
                {ms: 1659520733096, onTime: 100, x: 17801, y: 4268}, 
                {ms: 1659520733196, onTime: 100, x: 17835, y: 4391}, 
                {ms: 1659520733296, onTime: 100, x: 17863, y: 4492}, 
                {ms: 1659520733496, onTime: 200, x: 17894, y: 4681}, 
                {ms: 1659520733596, onTime: 100, x: 17893, y: 4783}, 
                {ms: 1659520733796, onTime: 200, x: 17854, y: 4991}, 
                {ms: 1659520733896, onTime: 100, x: 17833, y: 5109}, 
                {ms: 1659520733996, onTime: 100, x: 17819, y: 5225}, 
                {ms: 1659520734196, onTime: 200, x: 17807, y: 5404}, 
                {ms: 1659520734396, onTime: 200, x: 17801, y: 5561}, 
                {ms: 1659520734496, onTime: 100, x: 17786, y: 5685}, 
                {ms: 1659520734596, onTime: 100, x: 17764, y: 5823}, 
                {ms: 1659520734696, onTime: 100, x: 17744, y: 5949}, 
                {ms: 1659520734796, onTime: 100, x: 17724, y: 6056}, 
                {ms: 1659520734896, onTime: 100, x: 17700, y: 6164}, 
                {ms: 1659520734996, onTime: 100, x: 17672, y: 6257}, 
                {ms: 1659520735096, onTime: 100, x: 17635, y: 6354}, 
                {ms: 1659520735196, onTime: 100, x: 17599, y: 6457}, 
                {ms: 1659520735296, onTime: 100, x: 17569, y: 6566}, 
                {ms: 1659520735496, onTime: 200, x: 17543, y: 6765}, 
                {ms: 1659520735596, onTime: 100, x: 17528, y: 6881}, 
                {ms: 1659520735696, onTime: 100, x: 17513, y: 7006}, 
                {ms: 1659520735996, onTime: 300, x: 17470, y: 7286}, 
                {ms: 1659520736096, onTime: 100, x: 17443, y: 7393}, 
                {ms: 1659520736196, onTime: 100, x: 17393, y: 7584}, 
                {ms: 1659520736396, onTime: 200, x: 17376, y: 7748}, 
                {ms: 1659520736596, onTime: 200, x: 17601, y: 7514}, 
                {ms: 1659520736696, onTime: 100, x: 17352, y: 7648}, 
                {ms: 1659520736796, onTime: 100, x: 17346, y: 7751}, 
                {ms: 1659520736896, onTime: 100, x: 17335, y: 7886}, 
                {ms: 1659520736996, onTime: 100, x: 17320, y: 7996}, 
                {ms: 1659520737196, onTime: 200, x: 17285, y: 8157}, 
                {ms: 1659520737296, onTime: 100, x: 17265, y: 8233}, 
                {ms: 1659520737496, onTime: 200, x: 17219, y: 8487}, 
                {ms: 1659520737696, onTime: 200, x: 17234, y: 8605}, 
                {ms: 1659520737996, onTime: 300, x: 17264, y: 8738}, 
                {ms: 1659520738396, onTime: 400, x: 17224, y: 8861}, 
                {ms: 1659520743796, onTime: 5400, x: 17298, y: 8754}, 
                {ms: 1659520744896, onTime: 1100, x: 17526, y: 8743}, 
                {ms: 1659520745396, onTime: 500, x: 17530, y: 8621}, 
                {ms: 1659520746496, onTime: 1100, x: 17491, y: 8513}, 
                {ms: 1659520751296, onTime: 4800, x: 17480, y: 8439}, 
                {ms: 1659520752096, onTime: 800, x: 17692, y: 7353}, 
                {ms: 1659520752296, onTime: 200, x: 17829, y: 7339}, 
                {ms: 1659520752496, onTime: 200, x: 17970, y: 7331}, 
                {ms: 1659520752596, onTime: 100, x: 18050, y: 7311}, 
                {ms: 1659520752696, onTime: 100, x: 18132, y: 7289}, 
                {ms: 1659520752896, onTime: 200, x: 18248, y: 7267}, 
                {ms: 1659520753096, onTime: 200, x: 18373, y: 7274}, 
                {ms: 1659520753196, onTime: 100, x: 18507, y: 7301}, 
                {ms: 1659520753496, onTime: 300, x: 18686, y: 7312}, 
                {ms: 1659520753696, onTime: 200, x: 18792, y: 7302}, 
                {ms: 1659520753896, onTime: 200, x: 18890, y: 7334}, 
                {ms: 1659520754096, onTime: 200, x: 19009, y: 7267}, 
                {ms: 1659520754196, onTime: 100, x: 19047, y: 7165}, 
                {ms: 1659520754396, onTime: 200, x: 19109, y: 7070}, 
                {ms: 1659520754596, onTime: 200, x: 19200, y: 7135}, 
                {ms: 1659520754796, onTime: 200, x: 19300, y: 7146}, 
                {ms: 1659520754996, onTime: 200, x: 19394, y: 7103}, 
                {ms: 1659520755196, onTime: 200, x: 19521, y: 7072}, 
                {ms: 1659520755296, onTime: 100, x: 19591, y: 7064}, 
                {ms: 1659520755396, onTime: 100, x: 19694, y: 7016}, 
                {ms: 1659520755596, onTime: 200, x: 19825, y: 6970}, 
                {ms: 1659520755696, onTime: 100, x: 19921, y: 7175}, 
                {ms: 1659520755796, onTime: 100, x: 19916, y: 7058}, 
                {ms: 1659520755996, onTime: 200, x: 19989, y: 7213}, 
                {ms: 1659520756096, onTime: 100, x: 20132, y: 7470}, 
                {ms: 1659520756196, onTime: 100, x: 20051, y: 7359}, 
                {ms: 1659520756296, onTime: 100, x: 20213, y: 7578}, 
                {ms: 1659520756496, onTime: 200, x: 20303, y: 7704}, 
                {ms: 1659520756596, onTime: 100, x: 20334, y: 7784}, 
                {ms: 1659520756696, onTime: 100, x: 20347, y: 7856}, 
                {ms: 1659520756896, onTime: 200, x: 20382, y: 8048}, 
                {ms: 1659520756996, onTime: 100, x: 20266, y: 8052}, 
                {ms: 1659520757096, onTime: 100, x: 20247, y: 8164}, 
                {ms: 1659520757196, onTime: 100, x: 20448, y: 8326}, 
                {ms: 1659520757296, onTime: 100, x: 20441, y: 8422}, 
                {ms: 1659520757396, onTime: 100, x: 20434, y: 8520}, 
                {ms: 1659520757596, onTime: 200, x: 20472, y: 8708}, 
                {ms: 1659520757696, onTime: 100, x: 20525, y: 8813}, 
                {ms: 1659520757896, onTime: 200, x: 20534, y: 9022}, 
                {ms: 1659520757996, onTime: 100, x: 20536, y: 9124}, 
                {ms: 1659520758096, onTime: 100, x: 20550, y: 9227}, 
                {ms: 1659520758196, onTime: 100, x: 20572, y: 9326}, 
                {ms: 1659520758296, onTime: 100, x: 20607, y: 9416}, 
                {ms: 1659520758396, onTime: 100, x: 20625, y: 9490}, 
                {ms: 1659520758496, onTime: 100, x: 20355, y: 9376}, 
                {ms: 1659520758696, onTime: 200, x: 20351, y: 9572}, 
                {ms: 1659520758796, onTime: 100, x: 20364, y: 9675}, 
                {ms: 1659520758996, onTime: 200, x: 20636, y: 10195}, 
                {ms: 1659520759196, onTime: 200, x: 20632, y: 10295}, 
                {ms: 1659520759496, onTime: 300, x: 20601, y: 10485}, 
                {ms: 1659520759596, onTime: 100, x: 20489, y: 10659}, 
                {ms: 1659520759696, onTime: 100, x: 20422, y: 10781}, 
                {ms: 1659520759996, onTime: 300, x: 20389, y: 11031}, 
                {ms: 1659520760096, onTime: 100, x: 20712, y: 11162}, 
                {ms: 1659520760196, onTime: 100, x: 20686, y: 11332}, 
                {ms: 1659520760296, onTime: 100, x: 20724, y: 11426}, 
                {ms: 1659520760396, onTime: 100, x: 20803, y: 11452}, 
                {ms: 1659520760496, onTime: 100, x: 20592, y: 11550}, 
                {ms: 1659520760796, onTime: 300, x: 20668, y: 11638}, 
                {ms: 1659520761096, onTime: 300, x: 20779, y: 11786}, 
                {ms: 1659520761296, onTime: 200, x: 20870, y: 11864}, 
                {ms: 1659520761596, onTime: 300, x: 20918, y: 11995}, 
                {ms: 1659520761696, onTime: 100, x: 20905, y: 12069}, 
                {ms: 1659520761796, onTime: 100, x: 20923, y: 12140}, 
                {ms: 1659520761996, onTime: 200, x: 21062, y: 12303}, 
                {ms: 1659520762096, onTime: 100, x: 21089, y: 12372}, 
                {ms: 1659520762196, onTime: 100, x: 21102, y: 12442}, 
                {ms: 1659520762396, onTime: 200, x: 21201, y: 12591}, 
                {ms: 1659520762496, onTime: 100, x: 22156, y: 12259}, 
                {ms: 1659520762696, onTime: 200, x: 22346, y: 12143}, 
                {ms: 1659520762996, onTime: 300, x: 22574, y: 11962}, 
                {ms: 1659520763096, onTime: 100, x: 22689, y: 11936}, 
                {ms: 1659520763296, onTime: 200, x: 22784, y: 11885}, 
                {ms: 1659520763596, onTime: 300, x: 22787, y: 11750}, 
                {ms: 1659520763796, onTime: 200, x: 22763, y: 11640}, 
                {ms: 1659520763896, onTime: 100, x: 22948, y: 11621}, 
                {ms: 1659520764196, onTime: 300, x: 22941, y: 11520}, 
                {ms: 1659520764296, onTime: 100, x: 22931, y: 11475}, 
                {ms: 1659520764496, onTime: 200, x: 22704, y: 11312}, 
                {ms: 1659520764596, onTime: 100, x: 22715, y: 11261}, 
                {ms: 1659520764996, onTime: 400, x: 22854, y: 11296}, 
                {ms: 1659520765396, onTime: 400, x: 22751, y: 11323}, 
                {ms: 1659520765696, onTime: 300, x: 22444, y: 11225}, 
                {ms: 1659520768096, onTime: 2400, x: 22403, y: 11262}, 
                {ms: 1659520768396, onTime: 300, x: 22634, y: 11313}, 
                {ms: 1659520768896, onTime: 500, x: 22519, y: 11242}, 
                {ms: 1659520772696, onTime: 3800, x: 22398, y: 11232}, 
                {ms: 1659520772896, onTime: 200, x: 22512, y: 11220}, 
                {ms: 1659520774596, onTime: 1700, x: 22641, y: 11330}, 
                {ms: 1659520776096, onTime: 1500, x: 22490, y: 11201}, 
                {ms: 1659520777996, onTime: 1900, x: 22789, y: 11414}, 
                {ms: 1659520778496, onTime: 500, x: 22933, y: 11524}, 
                {ms: 1659520779096, onTime: 600, x: 22853, y: 11597}, 
                {ms: 1659520779496, onTime: 400, x: 22775, y: 11702}, 
                {ms: 1659520779596, onTime: 100, x: 22528, y: 11655}, 
                {ms: 1659520780196, onTime: 600, x: 22618, y: 12036}, 
                {ms: 1659520780396, onTime: 200, x: 22509, y: 12109}, 
                {ms: 1659520780596, onTime: 200, x: 22329, y: 12211}, 
                {ms: 1659520780796, onTime: 200, x: 22210, y: 12294}, 
                {ms: 1659520780996, onTime: 200, x: 22079, y: 12306}, 
                {ms: 1659520781096, onTime: 100, x: 21974, y: 12315}, 
                {ms: 1659520781296, onTime: 200, x: 21805, y: 12306}, 
                {ms: 1659520781496, onTime: 200, x: 21660, y: 12340}, 
                {ms: 1659520781696, onTime: 200, x: 21467, y: 12245}, 
                {ms: 1659520781896, onTime: 200, x: 21328, y: 12093}, 
                {ms: 1659520781996, onTime: 100, x: 21240, y: 12038}, 
                {ms: 1659520782196, onTime: 200, x: 21058, y: 12058}, 
                {ms: 1659520782296, onTime: 100, x: 20979, y: 12043}, 
                {ms: 1659520782496, onTime: 200, x: 20738, y: 11845}, 
                {ms: 1659520782696, onTime: 200, x: 20577, y: 11722}, 
                {ms: 1659520782896, onTime: 200, x: 20437, y: 11572}, 
                {ms: 1659520782996, onTime: 100, x: 20664, y: 11527}, 
                {ms: 1659520783096, onTime: 100, x: 20579, y: 11440}, 
                {ms: 1659520783296, onTime: 200, x: 20470, y: 11358}, 
                {ms: 1659520783496, onTime: 200, x: 20333, y: 11309}, 
                {ms: 1659520783596, onTime: 100, x: 20227, y: 11284}, 
                {ms: 1659520783696, onTime: 100, x: 20131, y: 11252}, 
                {ms: 1659520783796, onTime: 100, x: 20015, y: 11222}, 
                {ms: 1659520783996, onTime: 200, x: 19859, y: 11184}, 
                {ms: 1659520784196, onTime: 200, x: 19649, y: 11119}, 
                {ms: 1659520784296, onTime: 100, x: 19526, y: 11062}, 
                {ms: 1659520784396, onTime: 100, x: 19435, y: 11008}, 
                {ms: 1659520784596, onTime: 200, x: 19287, y: 10936}, 
                {ms: 1659520784696, onTime: 100, x: 19216, y: 10922}, 
                {ms: 1659520784796, onTime: 100, x: 19109, y: 10906}, 
                {ms: 1659520784996, onTime: 200, x: 18923, y: 10894}, 
                {ms: 1659520785096, onTime: 100, x: 18837, y: 10881}, 
                {ms: 1659520785196, onTime: 100, x: 18745, y: 10880}, 
                {ms: 1659520785396, onTime: 200, x: 18592, y: 10816}, 
                {ms: 1659520785496, onTime: 100, x: 18505, y: 10750}, 
                {ms: 1659520785596, onTime: 100, x: 18428, y: 10657}, 
                {ms: 1659520785796, onTime: 200, x: 18330, y: 10524}, 
                {ms: 1659520785896, onTime: 100, x: 18257, y: 10490}, 
                {ms: 1659520785996, onTime: 100, x: 18161, y: 10469}, 
                {ms: 1659520786096, onTime: 100, x: 18061, y: 10454}, 
                {ms: 1659520786196, onTime: 100, x: 17856, y: 10414}, 
                {ms: 1659520786496, onTime: 300, x: 17642, y: 10347}, 
                {ms: 1659520786696, onTime: 200, x: 17516, y: 10282}, 
                {ms: 1659520786896, onTime: 200, x: 16990, y: 10203}, 
                {ms: 1659520787096, onTime: 200, x: 16834, y: 10169}, 
                {ms: 1659520787296, onTime: 200, x: 16667, y: 10144}, 
                {ms: 1659520787496, onTime: 200, x: 16480, y: 10145}, 
                {ms: 1659520787696, onTime: 200, x: 16328, y: 10173}, 
                {ms: 1659520787896, onTime: 200, x: 16226, y: 10145}, 
                {ms: 1659520787996, onTime: 100, x: 16087, y: 10108}, 
                {ms: 1659520788196, onTime: 200, x: 15908, y: 10068}, 
                {ms: 1659520788396, onTime: 200, x: 15761, y: 10035}, 
                {ms: 1659520788596, onTime: 200, x: 15642, y: 10028}, 
                {ms: 1659520788696, onTime: 100, x: 15577, y: 10023}, 
                {ms: 1659520788796, onTime: 100, x: 15516, y: 10028}, 
                {ms: 1659520788996, onTime: 200, x: 15384, y: 10038}, 
                {ms: 1659520789296, onTime: 300, x: 15279, y: 10008}, 
                {ms: 1659520789396, onTime: 100, x: 15276, y: 9991}, 
                {ms: 1659520798996, onTime: 9600, x: 15973, y: 9801}, 
                {ms: 1659520799896, onTime: 900, x: 16051, y: 9714}, 
                {ms: 1659520800496, onTime: 600, x: 16050, y: 9829}, 
                {ms: 1659520801096, onTime: 600, x: 15989, y: 10027}, 
                {ms: 1659520803896, onTime: 2800, x: 15253, y: 10061}, 
                {ms: 1659520805096, onTime: 1200, x: 15269, y: 9955}, 
                {ms: 1659520805796, onTime: 700, x: 15297, y: 10083}, 
                {ms: 1659520805996, onTime: 200, x: 15281, y: 10204}, 
                {ms: 1659520807696, onTime: 1700, x: 15347, y: 10066}, 
                {ms: 1659520807996, onTime: 300, x: 15305, y: 9952}, 
                {ms: 1659520818196, onTime: 10200, x: 15431, y: 9917}, 
                {ms: 1659520818596, onTime: 400, x: 15307, y: 9995}, 
                {ms: 1659520818796, onTime: 200, x: 15464, y: 9903}, 
                {ms: 1659520819496, onTime: 700, x: 15309, y: 9983}, 
                {ms: 1659520822296, onTime: 2800, x: 15459, y: 9956}, 
                {ms: 1659520823896, onTime: 1600, x: 15359, y: 9986}, 
                {ms: 1659520824396, onTime: 500, x: 15330, y: 10142}, 
                {ms: 1659520824896, onTime: 500, x: 15365, y: 9936}, 
                {ms: 1659520825496, onTime: 600, x: 15328, y: 9771}, 
                {ms: 1659520827596, onTime: 2100, x: 15348, y: 9922}, 
                {ms: 1659520828096, onTime: 500, x: 15344, y: 9802}, 
                {ms: 1659520829296, onTime: 1200, x: 15344, y: 9927}, 
                {ms: 1659520829896, onTime: 600, x: 15294, y: 10041}, 
                {ms: 1659520831896, onTime: 2000, x: 15302, y: 9918}, 
                {ms: 1659520832496, onTime: 600, x: 15268, y: 10111}, 
                {ms: 1659520833496, onTime: 1000, x: 15313, y: 9967}, 
                {ms: 1659520835596, onTime: 2100, x: 15263, y: 10098}, 
                {ms: 1659520837496, onTime: 1900, x: 15355, y: 9835}, 
                {ms: 1659520838596, onTime: 1100, x: 15317, y: 9978}, 
                {ms: 1659520838996, onTime: 400, x: 15266, y: 10081}, 
                {ms: 1659520839596, onTime: 600, x: 15303, y: 9970}, 
                {ms: 1659520842896, onTime: 3300, x: 15330, y: 9847}, 
                {ms: 1659520843496, onTime: 600, x: 15306, y: 9978}, 
                {ms: 1659520844996, onTime: 1500, x: 15255, y: 10066}, 
                {ms: 1659520846196, onTime: 1200, x: 15398, y: 10061}, 
                {ms: 1659520854696, onTime: 8500, x: 14786, y: 9873}, 
                {ms: 1659520855096, onTime: 400, x: 14651, y: 9918}, 
                {ms: 1659520855496, onTime: 400, x: 14531, y: 9931}, 
                {ms: 1659520856096, onTime: 600, x: 14684, y: 9901}, 
                {ms: 1659520856496, onTime: 400, x: 14897, y: 9913}, 
                {ms: 1659520856796, onTime: 300, x: 15010, y: 9926}, 
                {ms: 1659520857596, onTime: 800, x: 15098, y: 9902}, 
                {ms: 1659520857796, onTime: 200, x: 15177, y: 9918}, 
                {ms: 1659520859096, onTime: 1300, x: 15267, y: 10006}, 
                {ms: 1659520859696, onTime: 600, x: 15314, y: 10180}, 
                {ms: 1659520860696, onTime: 1000, x: 15286, y: 10035}, 
                {ms: 1659520867896, onTime: 7200, x: 15169, y: 10002}, 
                {ms: 1659520869896, onTime: 2000, x: 15395, y: 10170}, 
                {ms: 1659520875196, onTime: 5300, x: 15120, y: 10224}, 
                {ms: 1659520876196, onTime: 1000, x: 15021, y: 10257}, 
                {ms: 1659520883796, onTime: 7600, x: 15122, y: 10241}, 
                {ms: 1659520885796, onTime: 2000, x: 14993, y: 10046}, 
                {ms: 1659520887596, onTime: 1800, x: 14878, y: 9974}, 
                {ms: 1659520889096, onTime: 1500, x: 14790, y: 10029}, 
                {ms: 1659520889696, onTime: 600, x: 14687, y: 9979}, 
                {ms: 1659520892096, onTime: 2400, x: 14620, y: 9811}, 
                {ms: 1659520892696, onTime: 600, x: 14483, y: 9811}, 
                {ms: 1659520897896, onTime: 5200, x: 14602, y: 9858}, 
                {ms: 1659520898696, onTime: 800, x: 14696, y: 9899}, 
                {ms: 1659520901696, onTime: 3000, x: 14563, y: 9862}, 
                {ms: 1659520903696, onTime: 2000, x: 14676, y: 9878}, 
                {ms: 1659520904196, onTime: 500, x: 14817, y: 9942}, 
                {ms: 1659520918596, onTime: 14400, x: 14783, y: 9844}, 
                {ms: 1659520921096, onTime: 2500, x: 14773, y: 9952}, 
                {ms: 1659520921496, onTime: 400, x: 14768, y: 9837}, 
                {ms: 1659520922296, onTime: 800, x: 14813, y: 9930}, 
                {ms: 1659520924096, onTime: 1800, x: 14753, y: 9835}, 
                {ms: 1659520929096, onTime: 5000, x: 14841, y: 9916}, 
                {ms: 1659520930196, onTime: 1100, x: 14733, y: 9910}, 
                {ms: 1659520931196, onTime: 1000, x: 14834, y: 9892}, 
                {ms: 1659520931496, onTime: 300, x: 14903, y: 9937}, 
                {ms: 1659520931696, onTime: 200, x: 14969, y: 10002}, 
                {ms: 1659520933596, onTime: 1900, x: 14978, y: 9891}, 
                {ms: 1659520935996, onTime: 2400, x: 15061, y: 10011}, 
                {ms: 1659520936596, onTime: 600, x: 15064, y: 10371}, 
                {ms: 1659520939096, onTime: 2500, x: 15131, y: 10284}, 
                {ms: 1659520941996, onTime: 2900, x: 15066, y: 10379}, 
                {ms: 1659520944896, onTime: 2900, x: 15123, y: 10295}, 
                {ms: 1659520945496, onTime: 600, x: 15116, y: 10173}, 
                {ms: 1659520946296, onTime: 800, x: 15001, y: 10002}, 
                {ms: 1659520947696, onTime: 1400, x: 15112, y: 10093}, 
                {ms: 1659520948996, onTime: 1300, x: 15189, y: 10220}, 
                {ms: 1659520949196, onTime: 200, x: 15177, y: 10245}, 
                {ms: 1659520953196, onTime: 4000, x: 15282, y: 10269}, 
                {ms: 1659520954496, onTime: 1300, x: 15124, y: 10186}, 
                {ms: 1659520955196, onTime: 700, x: 15004, y: 9997}, 
                {ms: 1659520955996, onTime: 800, x: 15087, y: 10094}, 
                {ms: 1659520957696, onTime: 1700, x: 15000, y: 10043}, 
                {ms: 1659520958696, onTime: 1000, x: 15144, y: 10191}, 
                {ms: 1659520959696, onTime: 1000, x: 15210, y: 10252}, 
                {ms: 1659520960996, onTime: 1300, x: 15016, y: 10021}, 
                {ms: 1659520962396, onTime: 1400, x: 15231, y: 10231}, 
                {ms: 1659520964196, onTime: 1800, x: 15047, y: 10083}, 
                {ms: 1659520966296, onTime: 2100, x: 15003, y: 10001}, 
                {ms: 1659520967796, onTime: 1500, x: 15171, y: 10220}, 
                {ms: 1659520968196, onTime: 400, x: 15217, y: 10236}, 
                {ms: 1659520971396, onTime: 3200, x: 15081, y: 10131}, 
                {ms: 1659520972896, onTime: 1500, x: 15009, y: 10044}, 
                {ms: 1659520975296, onTime: 2400, x: 15244, y: 10271}, 
                {ms: 1659520975596, onTime: 300, x: 15106, y: 10194}, 
                {ms: 1659520977096, onTime: 1500, x: 15211, y: 10264}, 
                {ms: 1659520981996, onTime: 4900, x: 15295, y: 10186}, 
                {ms: 1659520985196, onTime: 3200, x: 15195, y: 10274}, 
                {ms: 1659520986396, onTime: 1200, x: 15259, y: 10181}, 
                {ms: 1659520987896, onTime: 1500, x: 15163, y: 9916}, 
                {ms: 1659520990196, onTime: 2300, x: 15060, y: 9958}, 
                {ms: 1659520992896, onTime: 2700, x: 15158, y: 9986}, 
                {ms: 1659521012196, onTime: 19300, x: 15095, y: 9908}, 
                {ms: 1659521013696, onTime: 1500, x: 15194, y: 9934}, 
                {ms: 1659521014096, onTime: 400, x: 15069, y: 9926}, 
                {ms: 1659521017296, onTime: 3200, x: 15180, y: 9995}, 
                {ms: 1659521017796, onTime: 500, x: 15039, y: 9945}, 
                {ms: 1659521018396, onTime: 600, x: 15178, y: 9964}, 
                {ms: 1659521018996, onTime: 600, x: 15074, y: 9921}, 
                {ms: 1659521022196, onTime: 3200, x: 15233, y: 9865}, 
                {ms: 1659521023196, onTime: 1000, x: 15062, y: 9886}, 
                {ms: 1659521023596, onTime: 400, x: 14969, y: 9932}, 
                {ms: 1659521025396, onTime: 1800, x: 14400, y: 10428}, 
                {ms: 1659521025596, onTime: 200, x: 14257, y: 10455}, 
                {ms: 1659521025796, onTime: 200, x: 14099, y: 10494}, 
                {ms: 1659521025996, onTime: 200, x: 13990, y: 10530}, 
                {ms: 1659521026996, onTime: 1000, x: 14019, y: 10635}, 
                {ms: 1659521027396, onTime: 400, x: 13943, y: 10724}, 
                {ms: 1659521030196, onTime: 2800, x: 14020, y: 10492}, 
                {ms: 1659521030696, onTime: 500, x: 14224, y: 10396}, 
                {ms: 1659521031696, onTime: 1000, x: 14577, y: 9979}, 
                {ms: 1659521031796, onTime: 100, x: 14688, y: 10061}, 
                {ms: 1659521032296, onTime: 500, x: 14800, y: 9877}, 
                {ms: 1659521032396, onTime: 100, x: 14949, y: 9860}, 
                {ms: 1659521032696, onTime: 300, x: 15069, y: 9782}, 
                {ms: 1659521032996, onTime: 300, x: 15173, y: 9759}, 
                {ms: 1659521033196, onTime: 200, x: 15237, y: 9845}, 
                {ms: 1659521033296, onTime: 100, x: 15253, y: 9847}, 
                {ms: 1659521036396, onTime: 3100, x: 15207, y: 9721}, 
                {ms: 1659521038696, onTime: 2300, x: 15262, y: 9805}, 
                {ms: 1659521052596, onTime: 13900, x: 15319, y: 9708}, 
                {ms: 1659521059796, onTime: 7200, x: 15232, y: 9769}, 
                {ms: 1659521071296, onTime: 11500, x: 15163, y: 9971}, 
                {ms: 1659521072196, onTime: 900, x: 15185, y: 9872}, 
                {ms: 1659521072496, onTime: 300, x: 15341, y: 9868}, 
                {ms: 1659521075296, onTime: 2800, x: 15423, y: 10484}, 
                {ms: 1659521079796, onTime: 4500, x: 15453, y: 10365}, 
                {ms: 1659521081296, onTime: 1500, x: 15367, y: 10132}, 
                {ms: 1659521081596, onTime: 300, x: 15308, y: 10036}, 
                {ms: 1659521086296, onTime: 4700, x: 15343, y: 10329}, 
                {ms: 1659521086896, onTime: 600, x: 15228, y: 10347}, 
                {ms: 1659521090096, onTime: 3200, x: 15229, y: 10400}, 
                {ms: 1659521091096, onTime: 1000, x: 15180, y: 10240}, 
                {ms: 1659521091796, onTime: 700, x: 15055, y: 10212}, 
                {ms: 1659521092696, onTime: 900, x: 14603, y: 10311}, 
                {ms: 1659521092896, onTime: 200, x: 14506, y: 10338}, 
                {ms: 1659521093096, onTime: 200, x: 14436, y: 10414}, 
                {ms: 1659521093396, onTime: 300, x: 14194, y: 10577}, 
                {ms: 1659521093496, onTime: 100, x: 14205, y: 10463}, 
                {ms: 1659521093596, onTime: 100, x: 14031, y: 10487}, 
                {ms: 1659521093696, onTime: 100, x: 13882, y: 10552}, 
                {ms: 1659521093796, onTime: 100, x: 13791, y: 10650}, 
                {ms: 1659521094496, onTime: 700, x: 13936, y: 10541}, 
                {ms: 1659521094696, onTime: 200, x: 13839, y: 10661}, 
                {ms: 1659521095996, onTime: 1300, x: 13887, y: 10553}, 
                {ms: 1659521099996, onTime: 4000, x: 14489, y: 10254}, 
                {ms: 1659521114496, onTime: 14500, x: 14594, y: 10357}, 
                {ms: 1659521117896, onTime: 3400, x: 13671, y: 10405}, 
                {ms: 1659521118196, onTime: 300, x: 13540, y: 10379}, 
                {ms: 1659521118496, onTime: 300, x: 13403, y: 10360}, 
                {ms: 1659521118596, onTime: 100, x: 13367, y: 10351}, 
                {ms: 1659521119296, onTime: 700, x: 13537, y: 10344}, 
                {ms: 1659521119496, onTime: 200, x: 13650, y: 10363}, 
                {ms: 1659521119596, onTime: 100, x: 13675, y: 10357}, 
                {ms: 1659521119896, onTime: 300, x: 13758, y: 10325}, 
                {ms: 1659521120096, onTime: 200, x: 13872, y: 10266}, 
                {ms: 1659521120196, onTime: 100, x: 13947, y: 10221}, 
                {ms: 1659521120296, onTime: 100, x: 14055, y: 10178}, 
                {ms: 1659521120696, onTime: 400, x: 14189, y: 10131}, 
                {ms: 1659521120896, onTime: 200, x: 14317, y: 10140}, 
                {ms: 1659521121196, onTime: 300, x: 14459, y: 10131}, 
                {ms: 1659521121596, onTime: 400, x: 14580, y: 10055}, 
                {ms: 1659521121696, onTime: 100, x: 14601, y: 9880}, 
                {ms: 1659521121796, onTime: 100, x: 14665, y: 9993}, 
                {ms: 1659521121896, onTime: 100, x: 14684, y: 9861}, 
                {ms: 1659521122096, onTime: 200, x: 14818, y: 9960}, 
                {ms: 1659521122396, onTime: 300, x: 14910, y: 9980}, 
                {ms: 1659521122496, onTime: 100, x: 14930, y: 9989}, 
                {ms: 1659521123196, onTime: 700, x: 15001, y: 9912}, 
                {ms: 1659521124396, onTime: 1200, x: 15180, y: 9895}, 
                {ms: 1659521124596, onTime: 200, x: 15242, y: 9863}, 
                {ms: 1659521126796, onTime: 2200, x: 15274, y: 9849}, 
                {ms: 1659521127196, onTime: 400, x: 15176, y: 9876}, 
                {ms: 1659521129396, onTime: 2200, x: 15220, y: 10020}, 
                {ms: 1659521129696, onTime: 300, x: 15255, y: 10137}, 
                {ms: 1659521130696, onTime: 1000, x: 15157, y: 10066}, 
                {ms: 1659521131196, onTime: 500, x: 14990, y: 10089}, 
                {ms: 1659521133796, onTime: 2600, x: 15706, y: 10101}, 
                {ms: 1659521134096, onTime: 300, x: 15851, y: 10041}, 
                {ms: 1659521134296, onTime: 200, x: 15904, y: 9909}, 
                {ms: 1659521134396, onTime: 100, x: 15929, y: 9889}, 
                {ms: 1659521134696, onTime: 300, x: 16027, y: 9893}, 
                {ms: 1659521134896, onTime: 200, x: 16182, y: 9940}, 
                {ms: 1659521134996, onTime: 100, x: 16259, y: 9935}, 
                {ms: 1659521135096, onTime: 100, x: 16320, y: 9931}, 
                {ms: 1659521135596, onTime: 500, x: 16403, y: 9856}, 
                {ms: 1659521136296, onTime: 700, x: 16504, y: 9822}, 
                {ms: 1659521138796, onTime: 2500, x: 16639, y: 9666}, 
                {ms: 1659521139196, onTime: 400, x: 16480, y: 9824}, 
                {ms: 1659521139596, onTime: 400, x: 16632, y: 9684}, 
                {ms: 1659521139796, onTime: 200, x: 16585, y: 9875}, 
                {ms: 1659521140496, onTime: 700, x: 16647, y: 9744}, 
                {ms: 1659521141196, onTime: 700, x: 16708, y: 9644}, 
                {ms: 1659521141896, onTime: 700, x: 16933, y: 9560}, 
                {ms: 1659521143196, onTime: 1300, x: 16818, y: 9574}, 
                {ms: 1659521143296, onTime: 100, x: 17039, y: 9344}, 
                {ms: 1659521143796, onTime: 500, x: 16819, y: 9537}, 
                {ms: 1659521145396, onTime: 1600, x: 17019, y: 9356}, 
                {ms: 1659521146196, onTime: 800, x: 16696, y: 9572}, 
                {ms: 1659521146996, onTime: 800, x: 17005, y: 9374}, 
                {ms: 1659521147496, onTime: 500, x: 16845, y: 9506}, 
                {ms: 1659521148496, onTime: 1000, x: 16947, y: 9381}, 
                {ms: 1659521149096, onTime: 600, x: 16719, y: 9576}, 
                {ms: 1659521149396, onTime: 300, x: 16781, y: 9458}, 
                {ms: 1659521149496, onTime: 100, x: 16848, y: 9600}, 
                {ms: 1659521150296, onTime: 800, x: 16933, y: 9362}, 
                {ms: 1659521150396, onTime: 100, x: 16795, y: 9572}, 
                {ms: 1659521150596, onTime: 200, x: 16918, y: 9346}, 
                {ms: 1659521150796, onTime: 200, x: 16835, y: 9521}, 
                {ms: 1659521151296, onTime: 500, x: 16891, y: 9349}, 
                {ms: 1659521153996, onTime: 2700, x: 16837, y: 9547}, 
                {ms: 1659521154296, onTime: 300, x: 16913, y: 9391}, 
                {ms: 1659521156796, onTime: 2500, x: 17053, y: 9349}, 
                {ms: 1659521157296, onTime: 500, x: 17334, y: 9274}, 
                {ms: 1659521157596, onTime: 300, x: 17427, y: 9222}, 
                {ms: 1659521157996, onTime: 400, x: 17493, y: 9136}, 
                {ms: 1659521160296, onTime: 2300, x: 17441, y: 9255}, 
                {ms: 1659521160796, onTime: 500, x: 17301, y: 9237}, 
                {ms: 1659521161096, onTime: 300, x: 17237, y: 9331}, 
                {ms: 1659521161196, onTime: 100, x: 17130, y: 9568}, 
                {ms: 1659521161396, onTime: 200, x: 17003, y: 9557}, 
                {ms: 1659521161896, onTime: 500, x: 16980, y: 9664}, 
                {ms: 1659521162296, onTime: 400, x: 17011, y: 9772}, 
                {ms: 1659521163096, onTime: 800, x: 17015, y: 9852}, 
                {ms: 1659521170496, onTime: 7400, x: 17076, y: 9633}, 
                {ms: 1659521172796, onTime: 2300, x: 17170, y: 9722}, 
                {ms: 1659521173196, onTime: 400, x: 17062, y: 9700}, 
                {ms: 1659521173496, onTime: 300, x: 16890, y: 10009}, 
                {ms: 1659521174596, onTime: 1100, x: 17008, y: 9984}, 
                {ms: 1659521175396, onTime: 800, x: 17205, y: 10186}, 
                {ms: 1659521175696, onTime: 300, x: 17749, y: 10470}, 
                {ms: 1659521175796, onTime: 100, x: 17205, y: 10186}, 
                {ms: 1659521175896, onTime: 100, x: 17771, y: 10459}, 
                {ms: 1659521176896, onTime: 1000, x: 17353, y: 10216}, 
                {ms: 1659521176996, onTime: 100, x: 17753, y: 10431}, 
                {ms: 1659521177896, onTime: 900, x: 17427, y: 10169}, 
                {ms: 1659521178896, onTime: 1000, x: 17458, y: 10001}, 
                {ms: 1659521180696, onTime: 1800, x: 17449, y: 9900}, 
                {ms: 1659521181096, onTime: 400, x: 18087, y: 9962}, 
                {ms: 1659521186196, onTime: 5100, x: 17683, y: 9774}, 
                {ms: 1659521187396, onTime: 1200, x: 17532, y: 9837}, 
                {ms: 1659521188096, onTime: 700, x: 17387, y: 9783}, 
                {ms: 1659521188296, onTime: 200, x: 17367, y: 9693}, 
                {ms: 1659521188896, onTime: 600, x: 17153, y: 9801}, 
                {ms: 1659521189496, onTime: 600, x: 17203, y: 9504}, 
                {ms: 1659521189896, onTime: 400, x: 16929, y: 9784}, 
                {ms: 1659521190096, onTime: 200, x: 17091, y: 9490}, 
                {ms: 1659521190196, onTime: 100, x: 16896, y: 9700}, 
                {ms: 1659521190396, onTime: 200, x: 16869, y: 9598}, 
                {ms: 1659521190796, onTime: 400, x: 16930, y: 9518}, 
                {ms: 1659521191096, onTime: 300, x: 17106, y: 9158}, 
                {ms: 1659521192896, onTime: 1800, x: 16903, y: 9504}, 
                {ms: 1659521193096, onTime: 200, x: 17147, y: 9166}, 
                {ms: 1659521193896, onTime: 800, x: 16876, y: 9514}, 
                {ms: 1659521193996, onTime: 100, x: 17150, y: 9158}, 
                {ms: 1659521194196, onTime: 200, x: 16844, y: 9544}, 
                {ms: 1659521194296, onTime: 100, x: 17152, y: 9165}, 
                {ms: 1659521194496, onTime: 200, x: 16902, y: 9521}, 
                {ms: 1659521194596, onTime: 100, x: 17153, y: 9171}, 
                {ms: 1659521194796, onTime: 200, x: 16862, y: 9532}, 
                {ms: 1659521194896, onTime: 100, x: 17178, y: 9202}, 
                {ms: 1659521194996, onTime: 100, x: 16854, y: 9540}, 
                {ms: 1659521195696, onTime: 700, x: 17178, y: 9202}, 
                {ms: 1659521195796, onTime: 100, x: 16882, y: 9552}, 
                {ms: 1659521197196, onTime: 1400, x: 17153, y: 9192}, 
                {ms: 1659521197296, onTime: 100, x: 16839, y: 9569}, 
                {ms: 1659521197696, onTime: 400, x: 17153, y: 9192}, 
                {ms: 1659521200496, onTime: 2800, x: 16948, y: 9220}, 
                {ms: 1659521200696, onTime: 200, x: 16864, y: 9541}, 
                {ms: 1659521201196, onTime: 500, x: 16959, y: 9509}, 
                {ms: 1659521201296, onTime: 100, x: 16986, y: 9262}, 
                {ms: 1659521201496, onTime: 200, x: 16951, y: 9503}, 
                {ms: 1659521202296, onTime: 800, x: 17039, y: 9204}, 
                {ms: 1659521204696, onTime: 2400, x: 16930, y: 9515}, 
                {ms: 1659521205196, onTime: 500, x: 17028, y: 9631}, 
                {ms: 1659521205796, onTime: 600, x: 17085, y: 9754}, 
                {ms: 1659521205996, onTime: 200, x: 17206, y: 9496}, 
                {ms: 1659521206696, onTime: 700, x: 17316, y: 9442}, 
                {ms: 1659521207596, onTime: 900, x: 17180, y: 9666}, 
                {ms: 1659521208096, onTime: 500, x: 17268, y: 9432}, 
                {ms: 1659521208296, onTime: 200, x: 17149, y: 9661}, 
                {ms: 1659521209496, onTime: 1200, x: 17288, y: 9435}, 
                {ms: 1659521209896, onTime: 400, x: 17182, y: 9679}, 
                {ms: 1659521211196, onTime: 1300, x: 17289, y: 9438}, 
                {ms: 1659521211596, onTime: 400, x: 17146, y: 9727}, 
                {ms: 1659521214296, onTime: 2700, x: 17276, y: 9421}, 
                {ms: 1659521214896, onTime: 600, x: 17126, y: 9701}, 
                {ms: 1659521215496, onTime: 600, x: 17244, y: 9401}, 
                {ms: 1659521215996, onTime: 500, x: 17027, y: 9715}, 
                {ms: 1659521217096, onTime: 1100, x: 16334, y: 10006}, 
                {ms: 1659521217296, onTime: 200, x: 16178, y: 9967}, 
                {ms: 1659521217596, onTime: 300, x: 16050, y: 10013}, 
                {ms: 1659521217696, onTime: 100, x: 16026, y: 10035}, 
                {ms: 1659521217796, onTime: 100, x: 15971, y: 10092}, 
                {ms: 1659521218096, onTime: 300, x: 15898, y: 10175}, 
                {ms: 1659521218596, onTime: 500, x: 15830, y: 10256}, 
                {ms: 1659521218696, onTime: 100, x: 15818, y: 10252}, 
                {ms: 1659521228896, onTime: 10200, x: 15961, y: 10219}, 
                {ms: 1659521229796, onTime: 900, x: 16032, y: 10117}, 
                {ms: 1659521230096, onTime: 300, x: 16091, y: 10120}, 
                {ms: 1659521230496, onTime: 400, x: 16206, y: 10136}, 
                {ms: 1659521231296, onTime: 800, x: 16423, y: 10165}, 
                {ms: 1659521231696, onTime: 400, x: 16423, y: 10292}, 
                {ms: 1659521231796, onTime: 100, x: 16449, y: 10161}, 
                {ms: 1659521232296, onTime: 500, x: 16407, y: 10380}, 
                {ms: 1659521232396, onTime: 100, x: 16414, y: 10204}, 
                {ms: 1659521232496, onTime: 100, x: 16540, y: 10315}, 
                {ms: 1659521232896, onTime: 400, x: 16795, y: 10508}, 
                {ms: 1659521233196, onTime: 300, x: 16679, y: 10586}, 
                {ms: 1659521233296, onTime: 100, x: 16791, y: 10460}, 
                {ms: 1659521233596, onTime: 300, x: 16952, y: 10461}, 
                {ms: 1659521233796, onTime: 200, x: 17042, y: 10406}, 
                {ms: 1659521233896, onTime: 100, x: 17090, y: 10369}, 
                {ms: 1659521233996, onTime: 100, x: 17139, y: 10338}, 
                {ms: 1659521234196, onTime: 200, x: 17379, y: 10326}, 
                {ms: 1659521234496, onTime: 300, x: 17739, y: 10319}, 
                {ms: 1659521234796, onTime: 300, x: 17779, y: 10111}, 
                {ms: 1659521234996, onTime: 200, x: 16756, y: 9925}, 
                {ms: 1659521235396, onTime: 400, x: 16898, y: 10002}, 
                {ms: 1659521235496, onTime: 100, x: 17000, y: 10034}, 
                {ms: 1659521235796, onTime: 300, x: 17081, y: 10038}, 
                {ms: 1659521235896, onTime: 100, x: 17125, y: 10034}, 
                {ms: 1659521236096, onTime: 200, x: 17247, y: 10045}, 
                {ms: 1659521236496, onTime: 400, x: 17724, y: 9854}, 
                {ms: 1659521236796, onTime: 300, x: 17710, y: 9980}, 
                {ms: 1659521237196, onTime: 400, x: 17799, y: 10095}
            ]
        }
    ]
}