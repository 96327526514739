import React from "react";

import DatePicker, { registerLocale } from 'react-datepicker'
import "./custom-react-datepicker.css"
import { ja, enUS } from 'date-fns/locale'
import { subDays } from 'date-fns'

import style from "./fupDatepicker.module.css"

// Datepicker日本語化
if (ja && ja.options) { ja.options.weekStartsOn = 0 }
registerLocale('ja', ja)
registerLocale('en', enUS)

export const DpType = {
    Micro: 'micro',
    Small: 'small',
    Large: 'large'
} as const
export type DatepickerType = typeof DpType[keyof typeof DpType]

interface CustomProps {
    type: DatepickerType
    customRef: React.Ref<HTMLDivElement>
    onClick?: React.MouseEventHandler<HTMLDivElement>
    value?: string | ReadonlyArray<string> | undefined
    placeholder?: string | undefined
}

const CustomeDatepicker = React.forwardRef<HTMLDivElement, CustomProps>((props, ref) => {
    return (
        <div
            onClick={props.onClick}
            ref={props.customRef}
            className={props.type===DpType.Small ? style.pickerSm : (props.type===DpType.Micro ? style.pickerMc : style.pickerLg)}
        >
            <label
                className={props.type===DpType.Small ? style.datelabelSm : (props.type===DpType.Micro ? style.datelabelMc : style.datelabelLg)}
            >
                {props.value || props.placeholder}
            </label>
            <span className={props.type===DpType.Small ? style.iconSm : (props.type===DpType.Micro ? style.iconMc : style.iconLg)}>
                <span className="material-symbols-outlined">date_range</span>
            </span>
        </div>
    )
})

interface Props {
    divRef: React.Ref<HTMLDivElement>
    onChange: (selectedDate: Date | [Date, Date] | null) => void
    dateFormat?: string | undefined
    dateFormatCalendar?: string | undefined
    maxDate?: Date | undefined
    minDate?: Date | undefined
    selected?: Date | undefined
    type: DatepickerType
    locale: string
    excludeDates?: Date[]
    disabled?: boolean
    showYearDropdown?: boolean
}

export const FupDatepicker: React.FC<Props> = (props) => {
    if (props.showYearDropdown) {
        return (
            <DatePicker
                dateFormat={props.dateFormat}
                dateFormatCalendar={props.dateFormatCalendar}
                locale={props.locale}
                selected={props.selected}
                maxDate={props.maxDate}
                minDate={props.minDate}
                onChange={props.onChange}
                excludeDates={props.excludeDates}
                disabled={props.disabled}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                customInput={
                    <CustomeDatepicker customRef={props.divRef} type={props.type}/>
                }
            />            
        )
    }
    return (
        <DatePicker
            dateFormat={props.dateFormat}
            dateFormatCalendar={props.dateFormatCalendar}
            locale={props.locale}
            selected={props.selected}
            maxDate={props.maxDate}
            minDate={props.minDate}
            onChange={props.onChange}
            excludeDates={props.excludeDates}
            disabled={props.disabled}
            customInput={
                <CustomeDatepicker customRef={props.divRef} type={props.type}/>
            }
        />
    )
}
