import { Array2, Array7 } from "../component"

/**
 * 分析条件
 */
export type AnalyzeParametersType = {
    view: AnalyzeView
    single: AnalysisParameterSetType
    compare: AnalysisParameterSetType
}

/**
 * 分析方法の種別ENUM（単独｜比較）
 */
export const AnalyzeViewType = {
    Single: "single",
    Compare: "compare"
} as const
export type AnalyzeView = typeof AnalyzeViewType[keyof typeof AnalyzeViewType]

/**
 * 分析パラメーターの入力画面区別ENUM（Primary:左側｜Secondary:右側）
 */
export const CompareSideType = {
    Primary: "1",
    Secondary: "2"
} as const
export type CompareSide = typeof CompareSideType[keyof typeof CompareSideType]

/**
 * 分析条件
 */
export interface AnalysisParameterSetType {
    shopId: number | undefined
    layoutId: number | undefined
    startDate: string | undefined   // format "yyyy-MM-dd"
    endDate: string | undefined
    startTime: number | undefined   // value = hh * 100 + mm
    endTime: number | undefined
    weekday: Array7<boolean>        // 0:sun,1:mon,2:tue ...
    buyOrNot: Array2<boolean>
    excludeClerk: boolean
    threshold: number
    side: CompareSide
    valid: boolean          // ヴァリデーションチェックの結果 true=OK
}

