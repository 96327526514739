import React, { useMemo, useState, useRef, useEffect, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { ResLayout, ResArea } from "../../api/data/analysis/FullLayout"
import { Coord } from "../../api/data/core/Coord"
import { AreaCountingType, AreaType } from "../../api/data/core/Enums"
import { PageName } from "../../types"
import { AreaModel, EditMode, EditModeType, UrlParameterType } from "../../types/Management"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { ModalFrame } from "../common/ModalFrame"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { useMouseMove } from "../../lib/useMouseMove"
import AreaEditPicWriter from "../../lib/AreaEditPicWriter"
import AreaMagGlassPicWriter from "../../lib/AreaMagGlassPicWriter"
import { useMouseClick } from "../../lib/useMouseClick"
import { AREA_ID_SHIFT_VALUE } from "../../constants"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { PermissionTypes } from "../../types/Permissions"
import { NEW_AREA_ID } from "../../constants"
import { useWriterCallback } from "../../lib/useWriterCallback"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./AreaEdit.module.css"

// 大きい窓のキャンバスサイズ
const CANVAS_WIDTH_LG = 650
const CANVAS_HEIGHT_LG = 470

// 小さい窓のキャンバスサイズ
const CANVAS_WIDTH_SM = 300
const CANVAS_HEIGHT_SM = 217

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {
}

export const AreaEdit: React.FC<Props> = (props) => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { layoutId, layoutList, editingAreaPack, updateEditingAreaPack, groupList } = useManagementDataContext()

    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()

    const [lgWriter, setLgWriter] = useState<AreaEditPicWriter | undefined>(undefined)
    const [smWriter, setSmWriter] = useState<AreaMagGlassPicWriter | undefined>(undefined)
    const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined)
    const [selectedType, setSelectedType] = useState<AreaType>(AreaCountingType.Normal)
    const [areaNumber, setAreaNumber] = useState<string>("")
    const [areaName, setAreaName] = useState<string>("")
    const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
    const [errMsg4Number, setErrMsg4Number] = useState<string>("")
    const [errMsg4Name, setErrMsg4Name] = useState<string>("")
    const [errMsg4Cells, setErrMsg4Cells] = useState<string>("")
    const [cells, setCells] = useState<Coord[]>([])
    const [areaBackup, setAreaBackup] = useState<AreaModel | undefined>(undefined)

    const imgRef = useRef<HTMLCanvasElement>(null)
    const drwRef = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const smlRef = useRef<HTMLCanvasElement>(null)
    const glsRef = useRef<HTMLCanvasElement>(null)
    const movRef = useRef<HTMLCanvasElement>(null)
    const refFirst = useRef<boolean>(true)
    //const refRelease = useRef<boolean>(false)

    //const search = useLocation().search
    const location = useLocation()
    //console.log("AreaEdit location:", location)
    const state = location.state
    const search = location.search
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => {
        const m = queryParams.get(UrlParameterType.Mode)
        if (m) return m
        return state ? state.Mode : undefined
    }, [queryParams, state])
    const prevMode = useMemo(() => {
        const pm = queryParams.get(UrlParameterType.PrvMode)
        if (pm) return pm
        return state ? state.PrvMode : undefined
    }, [queryParams, state])
    const areaSetId = useMemo(() => {
        const asi = queryParams.get(UrlParameterType.AreaSetId)
        if (asi) return asi
        return state ? state.AreaSetId : undefined
    }, [queryParams, state])
    const areaId = useMemo(() => {
        const ai = queryParams.get(UrlParameterType.AreaId)
        if (ai) return ai
        return state ? state.AreaId : undefined
    }, [queryParams, state])

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageArea_Delete) }, [ableToDelete])

    // editingAreaPackがない場合はエラーなので詳細画面に戻す
    if (editingAreaPack === undefined) {
        // locationがない場合はエラーなので一覧画面に戻す
        if (location.state && location.state.from) {
            navigate("/area_detail?" + UrlParameterType.Mode + "=" + prevMode + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                { state: { Mode: prevMode, AreaSetId: areaSetId, from: location.state.from.state.from } })
        } else {
            navigate("/area_list")
        }
    }
    
    // 初期化
    //  Case New：areaId = 0 で各編集項目は空（デフォルト）
    //  Case Edit|View：areaId が1以上、このときは、editingAreaPackの中から対象エリアを探して各項目にセット
    useEffect(() => {
        if (editingAreaPack && areaId) {
            const id = parseInt(areaId)
            const a = editingAreaPack.area_list.find(el => el.area_id === id)
            if (a) {
                const cloneCells = a.cell_ids ? [...a.cell_ids] : []
                const cloneA: AreaModel = {
                    area_id: id,
                    area_number: a.area_number,
                    name: a.name,
                    cell_ids: cloneCells,
                    area_type: a.area_type,
                    group_id: a.group_id
                }
                setAreaBackup(cloneA)
                setAreaName(a.name)
                setAreaNumber(a.area_number.toString())
                if (a.group_id) setSelectedGroupId(a.group_id)
                setSelectedType(a.area_type)
                setCells(cloneCells)
                //console.log("編集項目の初期化")
            }
        }
    }, [areaId, editingAreaPack])

    // 画面のタイトル名
    const pageTitle = useMemo(() => {
        if (mode && mode === EditModeType.New) {
            return t("header.areaEditAdd")
        } else if (mode && mode === EditModeType.Edit) {
            return t("header.areaEditModify")
        }
        return t("header.areaEditView")
    }, [mode, t])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutId && layoutList) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // エリアリスト
    const areaList = useMemo(() => {
        if (editingAreaPack) {
            return editingAreaPack.area_list
        }
        return undefined
    }, [editingAreaPack])

    // 編集不可能フラグ
    const disableEdit = useMemo(() => {
        if (mode === EditModeType.View) return true
        return false
    }, [mode])

    // 現在編集中のエリアを削除し前の状態に戻します。(isDelete=false時)
    // 現在編集中のエリアを削除します。(isDelete=true時)
    const recoveryEditingArea = async (isDelete: boolean) => {
        if (editingAreaPack) {
            const id = (areaId) ? parseInt(areaId) : NEW_AREA_ID
            console.log("recoveryEditingArea id, areaBackup:", id, areaBackup)
            const cloneAp = { ...editingAreaPack }
            // 編集中エリアを除外したリスト
            const newAreaList = cloneAp.area_list.filter(el => el.area_id !== id)
            if (!isDelete && areaBackup) {
                // バックアップを追加する
                newAreaList.push(areaBackup)
            }
            cloneAp.area_list = newAreaList
            updateEditingAreaPack(cloneAp)
        }
    }

    // Closeボタン押下時の処理
    const handleClose = () => {
        // 編集途中のエリアがあれば元に戻す(リカバリーしないとすでに書き変わったエリアのままになってしまう。)
        recoveryEditingArea(false).then(res => {
            // 前のページに戻る
            navigate("/area_detail?" + UrlParameterType.Mode + "=" + prevMode
                + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                { state: { Mode: prevMode, AreaSetId: areaSetId, from: location.state.from.state.from } })
            
        })
    }

    // 削除確認ダイアローグをOKしたときの処理
    const handleConfirmDeleteOk = () => {
        if (!ableDelete) return
        // このエリアを削除して
        recoveryEditingArea(true).then(res => {
            // 前のページに戻る
            navigate("/area_detail?" + UrlParameterType.Mode + "=" + prevMode
                + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                { state: { Mode: prevMode, AreaSetId: areaSetId, from: location.state.from.state.from } })
            
        })
    }

    // 削除確認ダイアローグを閉じる
    const handleConfirmDeleteCancel = () => {
        setIsConfirmOpen(false)
    }

    // 削除ボタン押下時の処理
    const handleDelete = () => {
        // 確認ダイアローグを出す
        if (!isConfirmOpen && ableDelete) setIsConfirmOpen(true)
    }

    // 入力チェック
    const validationCheck = () => {
        let numOk = (areaNumber && areaNumber.length > 0)
        let nameOk = (areaName && areaName.length > 0)
        let cellOk = (cells && cells.length > 0)
        console.log("cells:", cells)
        if (numOk && nameOk && cellOk) {
            return true
        }
        if (!numOk) {
            const msg = t("validation.errNoInput")
            setErrMsg4Number(msg)
        }
        if (!nameOk) {
            const msg = t("validation.errInputAreaName")
            setErrMsg4Name(msg)
        }
        if (!cellOk) {
            const msg = t("validation.errNoCells")
            setErrMsg4Cells(msg)
        }
        return false
    }

    // 完了ボタン押下時の処理
    const handleFinish = () => {
        if (editingAreaPack) {
            if (validationCheck()) {
                if (areaId) {
                    // エリアIDがあるときは、編集モード
                    const newPack = { ...editingAreaPack }
                    const id = parseInt(areaId)
                    const ta = newPack.area_list.find(el => el.area_id === id)
                    if (ta) {
                        ta.area_number = parseInt(areaNumber)
                        ta.name = areaName
                        ta.area_type = selectedType
                        ta.cell_ids = cells
                        ta.group_id = selectedGroupId ? selectedGroupId : null
                    }
                    updateEditingAreaPack(newPack)
                    navigate("/area_detail?" + UrlParameterType.Mode + "=" + prevMode
                        + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                        { state: { Mode: prevMode, AreaSetId: areaSetId, from: location.state.from.state.from } })
                    
                } else {
                    // エリアID=0 新規モード
                    const newPack = { ...editingAreaPack }
                    const idList = newPack.area_list.map(el => { return el.area_id }).filter(el => el < AREA_ID_SHIFT_VALUE)
                    let newId: number = 1
                    if (idList && idList.length > 0) {
                        for (let i of idList) {
                            if (i >= newId) newId = i + 1
                        }
                    }
                    const area = newPack.area_list.find(el => el.area_id === NEW_AREA_ID)
                    if (area) {
                        area.area_id = newId
                        area.area_number = parseInt(areaNumber)
                        area.name = areaName
                        area.area_type = selectedType
                        area.group_id = selectedGroupId ? selectedGroupId : null
                    } else {
                        console.error("新規area が見つかりません。")
                    }
                    updateEditingAreaPack(newPack)
                    navigate("/area_detail?" + UrlParameterType.Mode + "=" + prevMode
                        + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
                        { state: { Mode: prevMode, AreaSetId: areaSetId, from: location.state.from.state.from } })
                    
                }
            }
        }
    }

    const handleChangeAreaNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            const regex = new RegExp(/^[1-9]+\d*$/)
            const res = regex.test(event.target.value)
            if (res) {
                setAreaNumber(event.target.value)
                if (errMsg4Number) setErrMsg4Number("")
                if (lgWriter) {
                    const num = parseInt(event.target.value)
                    lgWriter.updateAreaNumber(num)
                }
            } else {
                const msg = t("validation.errNotNumber")
                setErrMsg4Number(msg)
            }
        } else {
            // 空文字列の場合
            setAreaNumber(event.target.value)
            if (lgWriter) {
                lgWriter.updateAreaNumber(undefined)
            }
        }
    }
    
    const handleChangeAreaName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAreaName(event.target.value)
        if (event.target.value && event.target.value.length > 0) {
            setErrMsg4Name("")
        }
    }

    const handleChangeGroup = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        setSelectedGroupId(id)
    }

    const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        if (v === AreaCountingType.Clerk) {
            setSelectedType(AreaCountingType.Clerk)
        } else if (v === AreaCountingType.Casher) {
            setSelectedType(AreaCountingType.Casher)
        } else if (v === AreaCountingType.ChangingRoom) {
            setSelectedType(AreaCountingType.ChangingRoom)
        } else {
            setSelectedType(AreaCountingType.Normal)
        }
    }

    const groupSelectOptions = useMemo(() => {
        const elm = (<option key="g-select-x" value={0}>{t("msgSelectAreaCategory")}</option>)
        if (groupList) {
            const ops = groupList.map((el, i) => {
                return (<option key={'g-select-' + (i + 1)} value={el.group_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [groupList, t])

    const typeSelectOptions = useMemo(() => {
        const ops = Object.values(AreaCountingType).map((el, i) => {
            const k = "areaType." + el
            const str = t(k)
            return (<option key={"t-select-" + (i + 1)} value={el}>{str}</option>)
        })
        return ops
    }, [t])

    const handleZoomOut = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magEnlarge(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleZoomReset = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magReset(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleZoomIn = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.magSmaller(gls, mov, imgCtx, drwCtx)
            }
        }
    }

    const handleMoveUp = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveUp(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveRight = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveRight(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveDown = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveDown(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleMoveLeft = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.moveLeft(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipUp = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipUp(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipRight = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipRight(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipDown = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipDown(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    const handleSkipLeft = () => {
        if (smWriter) {
            const gls = glsRef.current
            const mov = movRef.current
            if (gls && mov && imgRef && drwRef && imgRef.current && drwRef.current) {
                const imgCtx = imgRef.current.getContext("2d")
                const drwCtx = drwRef.current.getContext("2d")
                if (imgCtx && drwCtx) smWriter.skipLeft(gls, mov, imgCtx, drwCtx)
            }
        }
    }
    
    // Writer初期化
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP render")
                return
            }
        }

        if (layout && layout.mapping && areaList && mode) {
            if (lgWriter === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                // 大きい窓用のWriter作成
                let modeType: EditMode = EditModeType.New
                if (mode === EditModeType.Edit) {
                    modeType = EditModeType.Edit
                } else if (mode === EditModeType.View) {
                    modeType = EditModeType.View
                }
                const lg_writer = new AreaEditPicWriter(resLay, areaList, CANVAS_WIDTH_LG, CANVAS_HEIGHT_LG, modeType, areaId)
                lg_writer.pathDepth = 1
                setLgWriter(lg_writer)
                if (smWriter === undefined) {
                    // 小さい窓用のWriter作成
                    const writer = new AreaMagGlassPicWriter(resLay, areaList, CANVAS_WIDTH_SM, CANVAS_HEIGHT_SM)
                    writer.pathDepth = 1
                    // Writerの親子関係構築
                    writer.setSlaveWriter(lg_writer)
                    setSmWriter(writer)
                    // 小さい窓の背景画像を描画（一度だけ）
                    if (smlRef && smlRef.current) {
                        // Edit/ViewモードでエリアIDが指定されていた場合は虫眼鏡の位置をエリア中央にする。
                        if ((mode === EditModeType.Edit || mode === EditModeType.View) && areaId) writer.setPosition(parseInt(areaId))
                    }
                }
            } else {
                // Writerが設定出来たら描画する
                if (glsRef && glsRef.current && movRef && movRef.current) {
                    if (smWriter) smWriter.drawMagnifyingGlass(glsRef.current, movRef.current).then(none => {
                        if (smWriter.imgObject === undefined && smlRef && smlRef.current) {
                            const smCtx: CanvasRenderingContext2D | null | undefined = smlRef.current.getContext("2d")
                            if (smCtx) smWriter.drawFitImage(smCtx)
                            console.log("＠小さい窓の描画2")
                        }
                        // 大きい窓の背景を描画
                        if (imgRef && imgRef.current && drwRef && drwRef.current) {
                            const imgCtx = imgRef.current.getContext("2d")
                            const drwCtx = drwRef.current.getContext("2d")
                            if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                            console.log("★大きい窓の描画2")
                        }
                    })
                }
            }
        }
    }, [layout, areaList, smlRef, areaId, mode, smWriter, lgWriter, imgRef, drwRef, glsRef, movRef, t, refFirst])

    /**
     * 小さい窓のマウス処理
     */
    const mousePress = useMouseMove(movRef, (event: MouseEvent) => {
        // 虫眼鏡移動
        if (smWriter) {
            console.log("虫眼鏡移動")
            const srcCanv = glsRef.current
            const dstCanv = movRef.current
            // ドラッグ中はアニメーション層に描画
            if (srcCanv && dstCanv) smWriter.mouseMoveOnGlassMagnifying(event, mousePress, srcCanv, dstCanv)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        console.log("マウスボタンを離したときの処理 smWriter:", smWriter)
        /*if (refRelease.current === false) {
            refRelease.current = true
            return
        }*/
        if (smWriter) {
            console.log("glsRef.current:", glsRef.current)
            console.log("movRef.current:", movRef.current)
            smWriter.mouseRelease()
            // 再描画
            if (glsRef && glsRef.current && movRef && movRef.current) {
                console.log("再描画")
                smWriter.drawMagnifyingGlass(glsRef.current, movRef.current).then(none => {
                    console.log("＠小さい窓の描画0", none)
                    if (smWriter.imgObject === undefined && smlRef && smlRef.current) {
                        const smCtx: CanvasRenderingContext2D | null | undefined = smlRef.current.getContext("2d")
                        if (smCtx) smWriter.drawFitImage(smCtx)
                        console.log("＠小さい窓の描画")
                    }
                    // 大きい窓の背景を描画
                    if (imgRef && imgRef.current && drwRef && drwRef.current) {
                        const imgCtx = imgRef.current.getContext("2d")
                        const drwCtx = drwRef.current.getContext("2d")
                        if (imgCtx && drwCtx && lgWriter) lgWriter.drawImage4Editor(imgCtx, drwCtx)
                        console.log("★大きい窓の描画")
                    }
                })
            }
        }
    }, (event: MouseEvent) => {
        console.log("マウス押下時の処理 smWriter:", smWriter)
        // マウス押下時の処理
        if (smWriter) {
            // マウスでつまんだ瞬間の位置を記録
            smWriter.mousePress()
        }
    })

    /**
     * 大きい窓のマウス処理
     */
    const mouseClick = useMouseClick(aniRef,
        (event: MouseEvent) => {
            // マウスMOVEイベント
            if (lgWriter && mode !== EditModeType.View) {
                if (aniRef && aniRef.current && drwRef && drwRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (aniCtx && drwCtx) {
                        if (mouseClick) {
                            // マウスを押したまま動かしているとき
                            lgWriter.mouseDrawing(event, drwCtx)
                        } else {
                            // マウスを動かしているだけ
                            lgWriter.mouseOnArea(event, (areaId: number) => {
                                // セル上のマーカーを消す
                                if (aniRef && aniRef.current) {
                                    const aniCtx = aniRef.current.getContext("2d")
                                    if (aniCtx) lgWriter.clearMarker(aniCtx)
                                }
                            }, () => {
                                // エリアでないセル
                            }, aniCtx)
                        }
                    }
                }
            }
        },
        (event: MouseEvent) => {
            // マウスOUTイベント
            if (lgWriter && mode !== EditModeType.View) {
                // セル上のマーカーを消す
                if (aniRef && aniRef.current) {
                    const aniCtx = aniRef.current.getContext("2d")
                    if (aniCtx) lgWriter.clearMarker(aniCtx)
                }
            }
        },
        (event: MouseEvent) => {
            // マウスDOWNイベント
            if (lgWriter && mode !== EditModeType.View) {
                if (drwRef && aniRef && drwRef.current && aniRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (drwCtx && aniCtx) {
                        lgWriter.mouseDown(event, drwCtx, aniCtx)
                    }
                }
            }
        },
        (event: MouseEvent) => {
            // マウスUPイベント
            if (lgWriter && mode !== EditModeType.View) {
                if (drwRef && aniRef && drwRef.current && aniRef.current) {
                    const drwCtx = drwRef.current.getContext("2d")
                    const aniCtx = aniRef.current.getContext("2d")
                    if (drwCtx && aniCtx) {
                        lgWriter.mouseUp(event, drwCtx, aniCtx)
                    }
                }
            }
        }
    )

    /**
     * AreaEditPicWriterのセル編集イベントを処理します。
     * @param update 
     */
    const cellEditCallback = (update: ResArea) => {
        setCells(update.cell_ids)
        if (update.cell_ids.length > 0 && errMsg4Cells.length > 0) setErrMsg4Cells("")
    }

    // Writerのセル編集イベントを処理するコールバックを登録
    useWriterCallback(lgWriter, cellEditCallback)

    const placeholderNumber = useMemo(() => { return t("number") }, [t])
    const placeholderAreaName = useMemo(() => { return t("msgInputAreaName") }, [t])
    const lblSkipUp = useMemo(() => { return t("button.skipUp") }, [t])
    const lblSkipRight = useMemo(() => { return t("button.skipRight") }, [t])
    const lblSkipDown = useMemo(() => { return t("button.skipDown") }, [t])
    const lblSkipLeft = useMemo(() => { return t("button.skipLeft") }, [t])
    const lblMoveUp = useMemo(() => { return t("button.moveUp") }, [t])
    const lblMoveRight = useMemo(() => { return t("button.moveRight") }, [t])
    const lblMoveDown = useMemo(() => { return t("button.moveDown") }, [t])
    const lblMoveLeft = useMemo(() => { return t("button.moveLeft") }, [t])
    const lblZoomOut = useMemo(() => { return t("button.zoomOut") }, [t])
    const lblZoomReset = useMemo(() => { return t("button.zoomReset") }, [t])
    const lblZoomIn = useMemo(() => { return t("button.zoomIn") }, [t])
    
    return (
        <ModalFrame page={PageName.InfoArea} title={t("header.areaRegistrationEdit")} width={1170} height={690}>
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>{pageTitle}（{t("mapId")}：{layoutId}）</div>
                    {isProduction ? (<TrackingPageView page_title="bsinfo-area-edit" />) : (null)}
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.body}>
                    <div className={style.infoRow}>
                        <div className={style.infoStart}>
                            <div className={style.label}>{t("area")}{t("number")}</div>
                            <div className={style.numberInput}><input type="text" placeholder={placeholderNumber} value={areaNumber} onChange={handleChangeAreaNumber} disabled={disableEdit} /></div>
                            <div className={style.errMsg}><span>{errMsg4Number }</span></div>
                        </div>
                        <div className={style.space}></div>
                        <div className={style.info2nd}>
                            <div className={style.label}>{t("areaName")}</div>
                            <div className={style.nameInput}><input type="text" placeholder={placeholderAreaName} value={areaName} onChange={handleChangeAreaName} disabled={disableEdit} /></div>
                            <div className={style.errMsg}><span>{errMsg4Name }</span></div>
                        </div>
                        <div className={style.space}></div>
                        <div className={style.info3rd}>
                            <div className={style.label}>{t("categoryName")}</div>
                            <div className={style.grpSelector}>
                                <select className={style.groupSelect} value={selectedGroupId} onChange={handleChangeGroup} disabled={disableEdit}>{groupSelectOptions}</select>
                            </div>
                        </div>
                        <div className={style.space}></div>
                        <div className={style.infoEnd}>
                            <div className={style.label}>{t("area")}{t("type")}</div>
                            <div className={style.typSelector}>
                                <select className={style.typeSelect} value={selectedType} onChange={handleChangeType} disabled={disableEdit}>{typeSelectOptions}</select>
                            </div>
                        </div>
                    </div>
                    <div className={style.main}>
                        <div className={style.largeMap}>
                            <div className={style.screen}>
                                <div className={style.canvasWrap}>
                                    <canvas className={style.bgImgCanvas} ref={imgRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                                    <canvas className={style.drawCanvas} ref={drwRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                                    <canvas className={style.animeCanvas} ref={aniRef} width={CANVAS_WIDTH_LG} height={CANVAS_HEIGHT_LG}></canvas>
                                </div>
                            </div>
                            {
                                errMsg4Cells ? (<div className={style.errMsg}><span>{errMsg4Cells}</span></div>) : (null)
                            }
                            <div className={style.comment}>
                                {
                                    mode === EditModeType.View ? (null) : (
                                        "※" + t("msgAreaEditGuide")
                                    )
                                }
                            </div>
                        </div>
                        <div className={style.smallMap}>
                            <div className={style.control}>
                                {
                                    ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit)) ? (
                                        <section>
                                            <Button label={t("cancel")} name="cancel" styleType={StyleType.Reverse} onClick={handleClose} />
                                            <span className={style.spc}></span>
                                            <Button label={t("button.finish")} name="finish" styleType={StyleType.Normal} onClick={handleFinish} />
                                        </section>
                                    ) : (null)
                                }
                            </div>
                            <div className={style.sideWrap}>
                                <canvas className={style.glassCanvas} ref={glsRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                                <canvas className={style.smallCanvas} ref={smlRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                                <canvas className={style.moveCanvas} ref={movRef} width={CANVAS_WIDTH_SM} height={CANVAS_HEIGHT_SM}></canvas>
                            </div>
                            <div className={style.buttons}>
                                <div className={style.rowTop}>
                                    <div className={style.moving}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><button className={style.iconBtn} onClick={handleSkipUp} title={lblSkipUp}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVRoge2XQU7DMBBFu+EACLbcgF2apBJBccqyRRmz4wBF6gVYcil6hK4Ci56CE7BASAg8NOBCZcdppvFU8pcsVa3jec9xm+lgEBISEhLCMWkOWVrAIi3k40jcXPjmaZVEyIkabwr+cz3gPR5L6ZvLKdvwByRhhj8AiWZ4xhLu8AwlbPCJgIdElPdsJZrg9TyGEq7wej4jibbw+joGErvC6+s9SnSF1+t4kEjyErBAV/jf9dQ1JgmsRQo/vCzPUiFfqeB/YpRQtbAmmUBSwG1b+Ci6OxqNy+s4hym+biuBNckEhrk8TwV8uMLHV3Ci5ld6R6HC95wlVC2sSSaAUWdzpsZLPeYN8KvtYwErmwSuubH+jBTeNVk2OVawz8YeSElE+fTUC1xTGuE5SzjDc5Qwnvka1PaZ7TvRS6w7X++yyxy28LvMZQfPTqILiHcJCoD1GvDUuwTl7vUugc3Yn96GoCBeY/mJrWwNYOtgV7mP3bJJYE0yAWyJ9/UgMj0IsSYF+3fwdqr+fLl5iymfov/bb6xFeoQwrn9QuK4fEhISEhLCMl9BcMy+PAgdcgAAAABJRU5ErkJggg==" alt="skip up"></img></button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><button className={style.iconBtn} onClick={handleMoveUp} title={lblMoveUp}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVRoge2TQQ7BQBSGJVhwBAcQC1YOILErjYX04QAyM4mVEziCA7Bmw3kkLiCRcAXmaWmCiSpNR/xf8jZt53/f36aZDAAAAAAAAAAkQGcwKvGk7fE2dSHyDql5i9TJHznja2l7RYKIso6nFqG8P7rQ2voSJvmfKPFK3uoSUeWtLMEiLU+unsvKvT9P7ukzqZe4vHmSS5O8Q6LmdkVFP7Oz7ktEkb8+a12Jd+StKxFH3qoSWmAaRz48L2qmH5uzky/gyWNc+VuGoQRnJ+keLFebT+TDnMcSnJ2E891i1dTLDsHCbbunqnGz+CxnBAUOnP1NVyOuK4pOf1huNCa5T7M4g7OIxoVvuAEAAAAAAAD+hzPZq1wvYoLQLgAAAABJRU5ErkJggg==" alt="move up"></img></button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><button className={style.iconBtn} onClick={handleSkipLeft} title={lblSkipLeft}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVRoge2YTUrDQBiGu/EAoltv4C4/UzBlZurOtuSb7jyAgpeoh/IKrtRFewg9gQsRpJ0vbTBCZmIDToy+DwxkkQ+ed37I5BsMAAAAAPBJPMpPUk2XsTSn+9RF0fXBcJzPEklTfv4pPy+pzEko8yq0WQtFH0LT1XfqknM6su8/FHV22Am4Dx6ikNf0XkpsB7001e3kl1/rzJpXI4R3Qb28nUllnn11LnkevJU6ld+twE0bed5OQbaQneGJHW91EqmihasuyyaH9p0nh/wyktNjyEMe8pCHfPfyQlLm+kj55PkjVL3bdCJfBNDmbl95hu8xncszbQPwPeZXBBiq+VmvtxAj9PzCc4hvXXXbQ0yPjroVQiAEQvyxg40Q/zZEr3/qm0P4+0K+EEH7QkxdiKa+EOMKEawvVKXXrcWSXjd3AQAAgNBsAIuZzJodzvq0AAAAAElFTkSuQmCC" alt="skip left"></img></button></td>
                                                    <td><button className={style.iconBtn} onClick={handleMoveLeft} title={lblMoveLeft}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVRoge3YMQrCMBTG8YI66BE8gJNOHqDgllqc+tQDSBJw8gQewQs46+J9BI8giEfQpBBwSeyirw++H7z9H2hKX7MMAAAAAH5uqnVPkTm4eSqyVzcz7qbGfHxRmUtB9vUxj7LUA+62ryLx9ajVZsTdl5SMJ3vL832XuzEqFV+Quc+XdszdGEVEHXdZz7F4RXrC3RhVx1f2hPh/Qzwn90o8io1frLdDsfGe+AN4oh8hT/wl9nCItsAh2kL053QgeqEJRK+UQWypJ9r1udsaE/1bBQAAAKCl3hNoXDUIzEQLAAAAAElFTkSuQmCC" alt="move left"></img></button></td>
                                                    <td></td>
                                                    <td><button className={style.iconBtn} onClick={handleMoveRight} title={lblMoveRight}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVRoge3YwQnCMBQG4B70oCM4gCjoyQEK3hKDB+mzDiBJxJMTuIETOILOI7iAIIgj6PMiCFYLgskr/we5/z9Nmr4mCQAAAAD8RJEf8joocleV+c3A2nroTKUZY5ua/IXX7bkytxNTQuXz9kt4aSXSdF3j7XMUXWI09T1N7iy6hJnYDh/i07sS/IT2KPEvKBGLSpRQZPtFbycusQ2dr5RPJcazZSt0vq9EFxC9hXS+6BYd4uhvZ4QPpbLho7+4RH9Oix9oxI+URKuG6KH+QfRvFQAAAADB7sHTXFf6Ruq1AAAAAElFTkSuQmCC" alt="move right"></img></button></td>
                                                    <td><button className={style.iconBtn} onClick={handleSkipRight} title={lblSkipRight}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQklEQVRoge2YQU7CQBSG2XAAo1uP4Kp02sRCp7AEM2/ceQBJvADsuBRXYIUuvIScwBUJwXliNKadcZR0SuP/JSQseOH7yJBMX6cDAAAA/AeiaNpNh+omzmnC738zmw7UlSjortdXl3X5OWFhI7BKCr1/f0laxyM695lNCpqaz+8Oc/pV5Irq9i3Bv/yn/FfEs0+ECdh8n6Vt8Ag+NqUAzwgh9Ut5NnAEHyE+Nn+JMLIPlXOhI7JsfGa+9NEWEeWTC9uskGpmi4iHWiMCEe2N0E+IQAQiDrAky1oi1q4LoJC0sEUkOWUnEcH3KdesPUIvQ/k7A/g+5ZptPKCuI5TK2+va5Y/5E5tnhLlNPshlD/KQhzzkIf8jrX+or9wLech/BGwalWcq90Kei63yXqiBxdaRq8X7xleLTKuXuwAAAMCp8gbR9MyXzZaNVQAAAABJRU5ErkJggg==" alt="skip right"></img></button></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><button className={style.iconBtn} onClick={handleMoveDown} title={lblMoveDown}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABG0lEQVRoge2UQQ7BQBSGJVhwBAeQSFg5QBO7llj14QAyHbFyAjdwAdZWnEfiAhIJV2CemqSiVS2NqfxfMkk373/fn8m0UAAAAAAAAACABPR6omoPx3XLmpc+zeIMziKaVb7hFotNsuOQPKlzUd/77kA202bxLGdwFmdy9jddQ1FLdveF9+MdbRKt5DmixbPBLM7OwvlxseudHwskLxEmfyugsrN095e7cvFc4P0SUfJ+AbnIvAARFdWidZoSznDSsMk7hMqT3LaFKGdeIG0JY+TTlDBOPkkJY+U1LOC43iaqRNSD5Zmfy2te34Th8pq3S5gor4ktYbK8JrJEHuQ1LKr+MKvAY17mRj5IfzSt8fm1BwAAAAAAAOA/uQLkj1w94unuawAAAABJRU5ErkJggg==" alt="move down"></img></button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><button className={style.iconBtn} onClick={handleSkipDown} title={lblSkipDown}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVRoge2XT06DQBSHu/EARrcewRUWSEQZdKc1DN15gJp4And6KD2Cq+qiXsITuDAmjc5reUigM8OfV2eI80tImrT9ve9j6EBHIxcXFxcXl/8Qz7vZCc/Sq3HMJ/B6UP1Q6Cf8OUiy79XB+Hx8zveo+qELOrEfZpFKwJkp4H8lFhQSOfyi2g8zKdjXQ8Sy1gRyCS+e7Hfthe9ugocDZpIJwHKWl5hCQgUPs8h/B1F0uRsk/IVCYt2VvW5jVbcuYQy+iYTP+JsKwDh8Hwlr4LtIWAffRsJaeIzsRoSAqvco7+a9otydbD3z1TSWsBEeo5UwCS/AZuJ4z49b2eekvwnNNQ+dpf4ZKXx4mh4KgGVll7nXSMxL8MrHb+iqyC5hJpmAeD6/lmyVUommf1Bq8NgtZpIJHJ2kBwHLPtpK6CKDh1kwk0xgNSxOubg2v6gkpPBiBswihccEyfTCZ9mnROKhcQ/L7v4cvhjeU8IofAHRUcIK+AKmpYRV8AVUQwkr4TG63cnIbtM2qpWwHh7TXMJCeIxewmJ4jFxiAPCYusSA4DFBzCMB/iQEHkM2PTbN4+Li4uKyMT8IR8y4oTBZXwAAAABJRU5ErkJggg==" alt="skip down"></img></button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={style.zooming}>
                                        <div></div>
                                        <div><button className={style.iconBtn} onClick={handleZoomOut} title={lblZoomOut}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAABHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAcBYCzAAAAEnRSTlMB82dOuBAEAALihCjD1SmA5zknBORRAAAA8klEQVQ4y5WT2wKEIAhEqRS0+87/f+xiF7Wy2uXFhCPCSCSbEVO2JNu25JLL0RXQMNemAipT87I9AupoEa09ELTErQdMY0VsYwBvM4KWuDq73dMpnBEUEI9eP5nUtALp4WP1ujq9Xx28H+FwoI0piISBLsUD0QEcBWCpYeRkBvV+RDMYNHmCkKLRMxS7qLTok3gWVdYmED6SUOvuH+Dlitcif2jzKtQIuNRmUeopSV18rF4ennvU5x4ky3AdmH7AHInCyE0ic6jK3Qzt4veRKI19iESCij9ORpAUbSV4VeGeGB4AJXj4PAEHHcqmwj8Dq30BS6IfXp/BD7sAAAAASUVORK5CYII=" alt="zoom out"></img></button></div>
                                        <div><button className={style.iconBtn} onClick={handleZoomReset} title={lblZoomReset}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQlBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABz07T7AAAAFXRSTlMAxWcP4efxAgS5Wh2BONEo2E+GLYN8O6oYAAAA6klEQVQ4y52T247DIAxETQKxya1p0/n/X13TFSiGlq3WLwh0gsfMhOj7ErOYAsw58zuAeIgTMMVBUX4DPFHq0RAAH4CbF6JldoAfKwII8GfebdqpIvRalwUmsTt83eK3ypxBdfBHQIkNkP6jOQwkvTedEXvfMy2Y/jCmCPofULeImK1kK1JoaCVHOyZwu26FTrXv2nFNby+XZAWs1i3/Mkty4lzxLl8xagZueXsG4LCZUcID8T4Sjfeo3h8INaE6Sq1JREuU0L7OG4LMWJwIX2f3+uNwmsy3+TcXKiFdw5TY+5bKvlGf+Kp+APK0DLA+47EJAAAAAElFTkSuQmCC" alt="zoom reset"></img></button></div>
                                        <div><button className={style.iconBtn} onClick={handleZoomIn} title={lblZoomIn}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAABHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAcBYCzAAAAEnRSTlMB82dOuBAEAALihCjD1SmA5zknBORRAAAA+klEQVQ4y42T66KEIAiEpzLILnuZ93/Yg1pqJ7dd/hTDJwIqdDcIqk+x3cVUpAlXwMLSu47sXC/RPQMmzMw2nwjE+OhJN4yq4+BIP1YEYtzE5VAWgysCAfFc7TfUD6tAV/pcvX0n298EOZZIWDDnFIAKuaQ4mYiFlDwA0Z5uryYAUXfsj5SWwXHYvZTBnMHWIHfRWdGoAVhbXdVmksugKvRH4MsWX4v8oc3roB7kVNpsjvpZRt08rFVvjvthx71pleF6YdaNr0w0rtxT9RWqmj5c2qj7TLSufYhkAs2HUxH/3lHJEghJU/hMbDeAEbK974DTHNoG4B5I9gf80SAOyosuXgAAAABJRU5ErkJggg==" alt="zoom in"></img></button></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className={style.rowBottom}>
                                    <span className={style.spc2}>&nbsp;</span>
                                    {
                                        (mode === EditModeType.Edit && ableDelete) ? (
                                            <Button label={t("deleteWithSpace")} name="delete" styleType={StyleType.Danger} onClick={handleDelete} />
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteDialog guideMessage={t("msgConfirmDeleteArea")} paneSize={PaneSizeType.forAreaEdit} requestOpen={isConfirmOpen} onCancel={handleConfirmDeleteCancel} onDelete={handleConfirmDeleteOk} />
        </ModalFrame>
    )
}