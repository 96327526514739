import { useEffect, useState, useRef, RefObject } from "react"

export const useMouseMove = <E extends Event = Event>(
    ref: RefObject<HTMLCanvasElement | null>,
    mouseMove: (event: E) => void,  // マウスの移動イベント
    mouseButtonReleased: (event: E) => void,    // マウスのボタンが離されたときのイベント
    mouseButtonPressed: (event: E) => void  // マウスのボタンが押されたときのイベント
) => {

    const savedCallback = useRef(mouseMove)
    const savedCallbackReleased = useRef(mouseButtonReleased)
    const savedCallbackPressed = useRef(mouseButtonPressed)
    const [press, setPress] = useState<boolean>(false)

    useEffect(() => {
        savedCallback.current = mouseMove
    }, [mouseMove])

    useEffect(() => {
        savedCallbackReleased.current = mouseButtonReleased
    }, [mouseButtonReleased])

    useEffect(() => {
        savedCallbackPressed.current = mouseButtonPressed
    }, [mouseButtonPressed])

    useEffect(() => {
        const handleMove = (event: any) => {
            const { current: el } = ref
            if (el) savedCallback.current(event)
        }
        const handleDown = (evetn: any) => {
            setPress(true)
            const { current: el } = ref
            if (el) savedCallbackPressed.current(evetn)
        }
        const handleUp = (event: any) => {
            setPress(false)
            const { current: el } = ref
            if (el) savedCallbackReleased.current(event)
        }
        const handleOut = (event: any) => {
            setPress(false)
        }

        const canvas = ref.current
        if (canvas) {
            canvas.addEventListener('mousedown', handleDown)
            canvas.addEventListener('mouseup', handleUp)
            canvas.addEventListener('mouseout', handleOut)
            canvas.addEventListener('mousemove', handleMove)
        }

        return () => {
            //const canvas = ref.current
            if (canvas) {
                canvas.removeEventListener('mousedown', handleDown)
                canvas.removeEventListener('mouseup', handleUp)
                canvas.removeEventListener('mouseout', handleOut)
                canvas.removeEventListener('mousemove', handleMove)
            }
        }
    }, [ref])

    return press
}