import React from "react"
import { useTranslation } from "react-i18next"
import Utils from "../../lib/Utils"

import { AnalyzeView, AnalyzeViewType } from "../../types/Analyze"

import style from "./AreaGraphLegend.module.css"


interface Props {
    view: AnalyzeView
    primaryThreshold: number
    secondaryThreshold?: number
}

export const AreaGraphLegend: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const primaryTime = Utils.formatSecToJikan(props.primaryThreshold)
    const prmAddMsgStay = "（" + t("more", {time: primaryTime}) + "）"
    const prmAddMsgPass = "（" + t("lessThan", {time: primaryTime}) + "）"

    if (props.view === AnalyzeViewType.Single) {
        return (
            <div className={style.legendS}>
                <div className={style.legendS1}>
                    <span className={style.stay1}></span><span className={style.text}>{t("personStaying")}{prmAddMsgStay}</span>
                </div>
                <div className={style.spaceS}></div>
                <div className={style.legendS2}>
                    <span className={style.pass1}></span><span className={style.text}>{t("passengers")}{prmAddMsgPass}</span>
                </div>
            </div>
        )
    } else {
        const secondaryTime = Utils.formatSecToJikan(props.secondaryThreshold)

        return (
            <div className={style.legendW}>
                <table>
                    <tbody>
                        <tr>
                            <td className={style.title}>{t("comparitiveData")}&#9312;：</td>
                            <td>
                                <span className={style.stay1}></span><span className={style.text}>{t("personStaying")}</span>
                            </td>
                            <td className={style.time}>（{t("more",{time:primaryTime})}）</td>
                            <td>
                                <span className={style.pass1}></span><span className={style.text}>{t("passengers")}</span>
                            </td>
                            <td className={style.time}>（{t("lessThan", {time: primaryTime})}）</td>
                        </tr>
                        <tr>
                            <td className={style.title}>{t("comparitiveData")}&#9313;：</td>
                            <td>
                                <span className={style.stay2}></span><span className={style.text}>{t("personStaying")}</span>
                            </td>
                            <td className={style.time}>（{t("more",{time: secondaryTime})}）
                            </td>
                            <td>
                                <span className={style.pass2}></span><span className={style.text}>{t("passengers")}</span>
                            </td>
                            <td className={style.time}>（{t("lessThan", {time: secondaryTime})}）</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}