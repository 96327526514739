import { TimeUnitShort, TimeUnitShortType } from "../api/data/core/Enums"
import { StatisticsOfShop, StatisticsOfShopType } from "../types/Statistics"
import { DataChoice, DataChoiceType } from "../types/Statistics"
import { DisplayTerm, DispTermType } from "../feature/dashboard/Dashboard"


/**
 * 型変換
 * @param choice 
 * @returns 
 */
export const choice2DataType = (choice: DataChoice): StatisticsOfShop => {
    if (choice === DataChoiceType.Visitors) {
        return StatisticsOfShopType.Visitors
    } else if (choice === DataChoiceType.Buyers) {
        return StatisticsOfShopType.Buyers
    } else if (choice === DataChoiceType.BuyRatio) {
        return StatisticsOfShopType.BuyRatio
    } else if (choice === DataChoiceType.AvgNumOfArea) {
        return StatisticsOfShopType.NumOfArea
    } else if (choice === DataChoiceType.AvgStayTime) {
        return StatisticsOfShopType.SpentTime
    } else if (choice === DataChoiceType.SalesAmount) {
        return StatisticsOfShopType.SalesAmount
    } else {
        throw new Error("未定義の型です." + choice)
    }
}

/**
 * 型変換
 * @param timeUnit 
 * @returns 
 */
export const timeUnit2DisplayTerm = (timeUnit: TimeUnitShort): DisplayTerm => {
    if (timeUnit === TimeUnitShortType.Day) {
        return DispTermType.DayBefore
    } else {
        return DispTermType.PrevWeek
    }
}