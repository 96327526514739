import { WORK_CANVAS_HEIGHT, WORK_CANVAS_WIDTH } from "../constants";
import { MapModel } from "../types/Management";

const FONT_FAMILY = "11pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"
const COLOR_RED = "rgb(255,0,0)"
const COLOR_CYAN = "rgb(0,255,255)"
const ORIGIN_POS = 64
const CROSS_HALF = 32
const MESURE_STEP = 20  // 50[mm/px]となる

const ZOOM_STEP = 0.01  // 1%ずつ拡大・縮小
export const ZOOM_CENTER = 160 // Zoomの中央値

const CROP_WIDTH = 640
const CROP_HEIGHT = 400
const CROP_BORDER = 45

class CanvasWorker {
    // 原点基本ファイル
    public basFile: File | undefined
    public basImage: HTMLImageElement | undefined
    // 諸元
    public basOriginX: number = 0
    public basOriginY: number = 0
    public basMmPerPx: number = 1
    public basFitScale: number = 1
    public basImageScale: number = 1
    public basZmdWidth: number = WORK_CANVAS_WIDTH
    public basZmdHeight: number = WORK_CANVAS_HEIGHT
    public basZmdTop: number = 0
    public basZmdLeft: number = 0
    public basZoomVol: number = ZOOM_CENTER // 0...ZOOM_CENTER-1 | ZOOM_CENTER | ZOOM_CENTER+1...ZOOM_CENTER*2
    public basIsAdjusted: boolean = false   // 原点基本図面の原点位置が調整されているかどうか

    // 新規登録ファイル
    public neoFile: File | undefined
    public neoImage: HTMLImageElement | undefined
    // 諸元
    public neoOriginX: number = 0
    public neoOriginY: number = 0
    public neoMmPerPx: number = 1
    public neoFitScale: number = 1
    public neoImageScale: number = 1
    public neoZmdWidth: number = WORK_CANVAS_WIDTH
    public neoZmdHeight: number = WORK_CANVAS_HEIGHT
    public neoZmdTop: number = 0
    public neoZmdLeft: number = 0
    public neoZoomVol: number = ZOOM_CENTER // 0...ZOOM_CENTER-1 | ZOOM_CENTER | ZOOM_CENTER+1...ZOOM_CENTER*2
    
    // 表示・操作
    public cnvsWidth: number
    public cnvsHeight: number
    public mouseMoveX: number = 0
    public mouseMoveY: number = 0
    public memoLeft: number = 0
    public memoTop: number = 0

    /**
     * コンストラクタ
     * @param file 基準ファイル
     * @param canvasWidth 
     * @param canvasHeight 
     */
    constructor(baseFile: File | undefined, canvasWidth: number, canvasHeight: number) {
        this.basFile = baseFile
        this.cnvsWidth = canvasWidth
        this.cnvsHeight = canvasHeight
    }

    /**
     * 指定された量の倍率を取得します。
     * @param fitScale 
     * @param volume 
     * @returns 
     */
    private getZoomScale(fitScale: number, volume: number) {
        if (volume === ZOOM_CENTER || volume < 0 || volume > ZOOM_CENTER*2) {
            return fitScale
        } else if (volume < ZOOM_CENTER) {
            const n = -1 * volume + ZOOM_CENTER
            return fitScale * (1 - ZOOM_STEP) ** n
        } else {
            const n = volume - ZOOM_CENTER
            return fitScale * (1 + ZOOM_STEP) ** n
        }
    }

    /**
     * 原点基本図面のパラメータを初期化
     * @param model 
     * @returns 
     */
    public setBasicParameters(model: MapModel) {
        return new Promise((resolve) => {
            this.basMmPerPx = model.mm_per_pixel
            this.basOriginX = model.origin_x
            this.basOriginY = model.origin_y
            this.basImage = new Image()
            this.basImage.onload = () => {
                resolve(true)
            }
            this.basImage.src = model.image
            const scaleX = this.cnvsWidth / model.pixel_width
            const scaleY = this.cnvsHeight / model.pixel_height
            this.basFitScale = Math.min(scaleX, scaleY)
            this.basImageScale = this.basFitScale
            this.basZmdWidth = this.cnvsWidth / this.basFitScale
            this.basZmdHeight = this.cnvsHeight / this.basFitScale
            this.basZmdLeft = (this.basZmdWidth - model.pixel_width) / 2
            this.basZmdTop = (this.basZmdHeight - model.pixel_height) / 2
            this.basIsAdjusted = true
        })
    }

    /**
     * 画像ファイルを読み込んで原点基本図面のパラメータを初期化
     * @returns 
     */
    public basicImageLoad(file: File) {
        return new Promise((resolve) => {
            this.basFile = file
            const reader = new FileReader()
            reader.onload = () => {
                this.basImage = new Image()
                this.basImage.onload = () => {
                    if (this.basImage) {
                        const scaleX = this.cnvsWidth / this.basImage.width
                        const scaleY = this.cnvsHeight / this.basImage.height
                        this.basFitScale = Math.min(scaleX, scaleY)
                        this.basImageScale = this.basFitScale
                        this.basZmdWidth = this.cnvsWidth / this.basFitScale
                        this.basZmdHeight = this.cnvsHeight / this.basFitScale
                        this.basZmdLeft = (this.basZmdWidth - this.basImage.width) / 2
                        this.basZmdTop = (this.basZmdHeight - this.basImage.height) / 2
                        this.basIsAdjusted = false
                        resolve(true)
                    }
                }
                this.basImage.src = reader.result as string
            }
            reader.readAsDataURL(this.basFile)
        })
    }

    /**
     * 指定されたCanvasをクリアします。
     * @param ctx
     */
    public clearCanvas(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve) => {
            ctx.clearRect(0, 0, this.cnvsWidth, this.cnvsHeight)
            resolve(true)
        })
    }

    /**
     * 原点基本図面を描画します。
     * @param ctx
     */
    public drawBasicImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve) => {
            if (this.basImage) {
                this.clearCanvas(ctx)
                const sx = (this.basZmdLeft >= 0) ? 0 : this.basZmdLeft * -1
                const sy = (this.basZmdTop >= 0) ? 0 : this.basZmdTop * -1
                const sw = (this.basZmdLeft >= 0) ? this.basImage.width : this.basZmdWidth
                const sh = (this.basZmdTop >= 0) ? this.basImage.height : this.basZmdHeight
                const dx = (this.basZmdLeft >= 0) ? this.basZmdLeft : 0
                const dy = (this.basZmdTop >= 0) ? this.basZmdTop : 0
                const dw = sw
                const dh = sh
                ctx.drawImage(this.basImage, sx, sy, sw, sh, dx, dy, dw, dh)
                resolve(this.basImage)
            }
        })
    }

    /**
     * 原点基本図面を枠一杯に合わせて(basImageScale倍で)描画します。
     * @param ctx 
     * @returns 
     */
    public drawBasicFitImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.save()
            ctx.scale(this.basImageScale, this.basImageScale)
            this.drawBasicImage(ctx).then(res => {
                ctx.restore()
                resolve(res)
            }).catch(err => {
                ctx.restore()
                reject(err)
            })
        })
    }

    /**
     * キャンバス左上から64,640の位置に縦65px横65pxのsolid 1px #FF0000のラインを描画します。
     * @param ctx 
     */
    public drawCross(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.strokeStyle = COLOR_RED
            ctx.lineWidth = 1
            
            ctx.beginPath()
            ctx.moveTo(ORIGIN_POS - CROSS_HALF, ORIGIN_POS)
            ctx.lineTo(ORIGIN_POS + CROSS_HALF + 1, ORIGIN_POS)
            ctx.stroke()
            
            ctx.beginPath()
            ctx.moveTo(ORIGIN_POS, ORIGIN_POS - CROSS_HALF)
            ctx.lineTo(ORIGIN_POS, ORIGIN_POS + CROSS_HALF + 1)
            ctx.stroke()
            
            resolve(true)
        })
    }

    /**
     * 原点位置を基準にサイズ合わせ用のメッシュを描画します。
     * 20px = 1,000mmとする。600px = 30,000mm
     * @param ctx 
     * @returns 
     */
    public drawMesure(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.strokeStyle = COLOR_CYAN
            ctx.lineWidth = 1
            for (let py = ORIGIN_POS; py < WORK_CANVAS_HEIGHT - 2; py = py + MESURE_STEP) {
                ctx.beginPath()
                ctx.moveTo(ORIGIN_POS, py)
                ctx.lineTo(WORK_CANVAS_WIDTH - 3, py)
                ctx.stroke()
            }
            for (let px = ORIGIN_POS; px < WORK_CANVAS_WIDTH - 2; px = px + MESURE_STEP) {
                ctx.beginPath()
                ctx.moveTo(px, ORIGIN_POS)
                ctx.lineTo(px, WORK_CANVAS_HEIGHT - 3)
                ctx.stroke()
            }
            ctx.fillStyle = COLOR_CYAN
            ctx.font = FONT_FAMILY
            ctx.textAlign = "center"
            ctx.textBaseline = "middle"
            ctx.beginPath()
            ctx.fillText("0", ORIGIN_POS - 8, ORIGIN_POS - 9, 80)
            ctx.stroke()
            ctx.beginPath()
            ctx.fillText("5000", ORIGIN_POS - 21, ORIGIN_POS + MESURE_STEP * 5 - 0, 80)
            ctx.stroke()
            ctx.beginPath()
            ctx.fillText("10000", ORIGIN_POS - 25, ORIGIN_POS + MESURE_STEP * 10 - 0, 80)
            ctx.stroke()
            ctx.beginPath()
            ctx.fillText("15000", ORIGIN_POS - 25, ORIGIN_POS + MESURE_STEP * 15 - 0, 80)
            ctx.stroke()

            ctx.beginPath()
            ctx.fillText("5000", ORIGIN_POS + MESURE_STEP * 5 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("10000", ORIGIN_POS + MESURE_STEP * 10 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("15000", ORIGIN_POS + MESURE_STEP * 15 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("20000", ORIGIN_POS + MESURE_STEP * 20 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("25000", ORIGIN_POS + MESURE_STEP * 25 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("30000", ORIGIN_POS + MESURE_STEP * 30 - 0, ORIGIN_POS - 9, 80)
            ctx.fillText("35000", ORIGIN_POS + MESURE_STEP * 35 - 0, ORIGIN_POS - 9, 80)

            resolve(true)
        })
    }

    /**
     * 原点基本図面を指定された大きさで拡大・縮小します。
     * @param volume 
     */
    public basicZoomInOut(volume: number) {
        this.basZoomVol = volume
        const prevWidth = this.basZmdWidth
        const prevHeight = this.basZmdHeight
        this.basImageScale = this.getZoomScale(this.basFitScale, volume)
        this.basZmdWidth = this.cnvsWidth / this.basImageScale
        this.basZmdHeight = this.cnvsHeight / this.basImageScale
        this.basZmdLeft += (this.basZmdWidth - prevWidth) * 0.5
        this.basZmdTop += (this.basZmdHeight - prevHeight) * 0.5
    }

    /**
     * 原点基本図面を移動します。移動中はsrcCanvasをdstCanvasにコピーして表示します。
     * bgCanvasが指定されている場合は、bgCanvasも重ねてコピーします。
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     * @param bgCanvas 
     */
    public basicMouseMove(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined = undefined) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // dragging
                const mouseDragX = (event.clientX - rect.left)
                const mouseDragY = (event.clientY - rect.top)
                const moveX = (mouseDragX - this.mouseMoveX)
                const moveY = (mouseDragY - this.mouseMoveY)
                this.basZmdLeft = this.memoLeft + moveX / this.basImageScale
                this.basZmdTop = this.memoTop + moveY / this.basImageScale
                // moving image (copy canvas src to dst)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.cnvsWidth - Math.abs(moveX)
                    const h = this.cnvsHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#FFF"
                    ctx.fillRect(0, 0, this.cnvsWidth, this.cnvsHeight)
                    if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)    
                    console.log("moving image ...")
                }
            } else {
                // Record movement coordinates
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
                console.log("Record movement coordinates")
            }
        }
    }

    /**
     * 原点基本図面について、マウスが押された瞬間の位置を記録します。
     */
    public basicMousePress() {
        this.memoLeft = this.basZmdLeft
        this.memoTop = this.basZmdTop
        console.log("basicMousePress")
    }

    /**
     * 原点基本図面のパラメータ（originX, originY, mmPerPx）を算出します。
     */
    public basicCalculateParameters() {
        this.basMmPerPx = (1000 / MESURE_STEP) * this.basImageScale
        const vertualOrigin = ORIGIN_POS / this.basImageScale
        console.log("vertualOrigin:", vertualOrigin)
        this.basOriginX = vertualOrigin - this.basZmdLeft
        this.basOriginY = vertualOrigin - this.basZmdTop
        // スケールをFittingに戻す
        this.basImageScale = this.basFitScale
        this.basZmdWidth = this.cnvsWidth / this.basFitScale
        this.basZmdHeight = this.cnvsHeight / this.basFitScale
        if (this.basImage) {
            this.basZmdLeft = (this.basZmdWidth - this.basImage.width) / 2
            this.basZmdTop = (this.basZmdHeight - this.basImage.height) / 2
        } else {
            throw new Error("basicCalculateParameters: basImage is not loaded.")
        }
        // 調整済みとする
        this.basIsAdjusted = true
    }

    /**
     * 新規登録図面を読込みます。読み込んだ際にサイズやスケール、配置などのパラメーターを設定します。
     * @param file 
     * @returns 
     */
    public neoImageLoad(file: File) {
        return new Promise((resolve) => {
            this.neoFile = file
            const reader = new FileReader()
            reader.onload = () => {
                this.neoImage = new Image()
                this.neoImage.onload = () => {
                    if (this.neoImage) {
                        const scaleX = this.cnvsWidth / this.neoImage.width
                        const scaleY = this.cnvsHeight / this.neoImage.height
                        this.neoFitScale = Math.min(scaleX, scaleY)
                        this.neoImageScale = this.neoFitScale
                        this.neoZmdWidth = this.cnvsWidth / this.neoFitScale
                        this.neoZmdHeight = this.cnvsHeight / this.neoFitScale
                        this.neoZmdLeft = (this.neoZmdWidth - this.neoImage.width) / 2
                        this.neoZmdTop = (this.neoZmdHeight - this.neoImage.height) / 2
                        resolve(true)
                    }
                }
                this.neoImage.src = reader.result as string
            }
            reader.readAsDataURL(this.neoFile)
        })
    }

    /**
     * 新規登録図面を描画します。
     * @param ctx 
     * @returns 
     */
    public drawNeoImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve) => {
            if (this.neoImage) {
                this.clearCanvas(ctx)
                const sx = (this.neoZmdLeft >= 0) ? 0 : this.neoZmdLeft * -1
                const sy = (this.neoZmdTop >= 0) ? 0 : this.neoZmdTop * -1
                const sw = (this.neoZmdLeft >= 0) ? this.neoImage.width : this.neoZmdWidth
                const sh = (this.neoZmdTop >= 0) ? this.neoImage.height : this.neoZmdHeight
                const dx = (this.neoZmdLeft >= 0) ? this.neoZmdLeft : 0
                const dy = (this.neoZmdTop >= 0) ? this.neoZmdTop : 0
                const dw = sw
                const dh = sh
                ctx.drawImage(this.neoImage, sx, sy, sw, sh, dx, dy, dw, dh)
                resolve(this.neoImage)
            }
        })
    }

    /**
     * 新規登録図面を枠一杯に合わせて(neoImageScale倍で)描画します。
     * @param ctx 
     * @returns 
     */
    public drawNeoFitImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.save()
            ctx.scale(this.neoImageScale, this.neoImageScale)
            this.drawNeoImage(ctx).then(res => {
                ctx.restore()
                resolve(res)
            }).catch(err => {
                ctx.restore()
                reject(err)
            })
        })
    }

    /**
     * 新規登録図面を指定された大きさで拡大・縮小します。
     * @param volume 
     */
    public neoZoomInOut(volume: number) {
        this.neoZoomVol = volume
        const prevWidth = this.neoZmdWidth
        const prevHeight = this.neoZmdHeight
        this.neoImageScale = this.getZoomScale(this.neoFitScale, volume)
        this.neoZmdWidth = this.cnvsWidth / this.neoImageScale
        this.neoZmdHeight = this.cnvsHeight / this.neoImageScale
        this.neoZmdLeft += (this.neoZmdWidth - prevWidth) * 0.5
        this.neoZmdTop += (this.neoZmdHeight - prevHeight) * 0.5
    }

    /**
     * 新規登録図面を移動します。
     * 移動中はsrcCanvasをdstCanvasにコピーして表示します。
     * bgCanvasが指定されている場合は、bgCanvasも重ねてコピーします。
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     * @param bgCanvas 
     */
    public neoMouseMove(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined = undefined) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // dragging
                const mouseDragX = (event.clientX - rect.left)
                const mouseDragY = (event.clientY - rect.top)
                const moveX = (mouseDragX - this.mouseMoveX)
                const moveY = (mouseDragY - this.mouseMoveY)
                this.neoZmdLeft = this.memoLeft + moveX / this.neoImageScale
                this.neoZmdTop = this.memoTop + moveY / this.neoImageScale
                // moving image (copy canvas src to dst)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.cnvsWidth - Math.abs(moveX)
                    const h = this.cnvsHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#FFF"
                    ctx.fillRect(0, 0, this.cnvsWidth, this.cnvsHeight)
                    if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)    
                    console.log("moving image ...")
                }
            } else {
                // Record movement coordinates
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
                console.log("Record movement coordinates")
            }
        }
    }

    /**
     * 新規登録図面について、マウスが押された瞬間の位置を記録します。
     */
    public neoMousePress() {
        this.memoLeft = this.neoZmdLeft
        this.memoTop = this.neoZmdTop
        console.log("neoMousePress")
    }

    /**
     * 新規登録図面のパラメータ（originX, originY, mmPerPx）を算出します。
     */
    public neoCaliculateParameters() {
        const mmPerPx = this.basMmPerPx / this.basImageScale
        this.neoMmPerPx = mmPerPx * this.neoImageScale
        const rat = this.basImageScale / this.neoImageScale
        this.neoOriginX = (this.basZmdLeft + this.basOriginX) * rat - this.neoZmdLeft
        this.neoOriginY = (this.basZmdTop + this.basOriginY) * rat - this.neoZmdTop
    }

    /**
     * Canvasの寸法を変更します。合わせて、スケールなども再計算します。
     * ※Step2，4のサイズとStep5では異なるため。
     * @param width 
     * @param height 
     */
    public changeCanvasSize(width: number, height: number) {
        this.cnvsWidth = width
        this.cnvsHeight = height
        if (this.neoImage) {
            const sx = width / this.neoImage.width
            const sy = height / this.neoImage.height
            this.neoFitScale = Math.min(sx, sy)
            this.neoImageScale = this.neoFitScale
            this.neoZmdWidth = width / this.neoImageScale
            this.neoZmdHeight = height / this.neoImageScale
            this.neoZmdLeft = (this.neoZmdWidth - this.neoImage.width ) / 2
            this.neoZmdTop = (this.neoZmdHeight - this.neoImage.height ) / 2
            console.log("Change Canvas Size: ", width, height)
            this.printNeo()
        }
    }

    /**
     * トリミング後の平面図情報を作成します、
     * @returns 
     */
    public imageCrop(): Promise<MapModel> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas")
            canvas.width = CROP_WIDTH * 2;
            canvas.height = CROP_HEIGHT * 2;
            const ctx = canvas.getContext("2d")
            if (ctx && this.neoImage) {
                ctx.imageSmoothingEnabled = true
                ctx.imageSmoothingQuality = "high"
                //const scaleX = canvas.width / this.neoImage.width
                //const scaleY = canvas.height / this.neoImage.height
                //const fitScale = Math.min(scaleX, scaleY)
                const sx = CROP_BORDER / this.neoImageScale - this.neoZmdLeft
                const sy = CROP_BORDER / this.neoImageScale - this.neoZmdTop
                const sw = CROP_WIDTH / this.neoImageScale
                const sh = CROP_HEIGHT / this.neoImageScale
                const dx = 0
                const dy = 0
                const dw = CROP_WIDTH * 2
                const dh = CROP_HEIGHT * 2
                //はみ出し部分は白くする
                ctx.fillStyle = "#FFF"
                ctx.fillRect(0, 0, dw, dh)
                //1280x800のCanvasへスケール考慮して描画
                ctx.drawImage(this.neoImage, sx, sy, sw, sh, dx, dy, dw, dh)
                const mmPerPx = sw * this.neoMmPerPx / (CROP_WIDTH * 2)
                const x2Scale = this.neoImageScale * 2
                const orgX = Math.round((this.neoOriginX + this.neoZmdLeft - (CROP_BORDER / this.neoImageScale)) * x2Scale)
                const orgY = Math.round((this.neoOriginY + this.neoZmdTop - (CROP_BORDER / this.neoImageScale)) * x2Scale)
                const dataUrl = canvas.toDataURL("image/jpeg")
                const model: MapModel = {
                    image_data: dataUrl,
                    image: this.neoFile ? this.neoFile.name : "",
                    pixel_width: dw,
                    pixel_height: dh,
                    mm_per_pixel: mmPerPx,
                    origin_x: orgX,
                    origin_y: orgY,
                    area_unitcell_pixel: 0,
                    area_packs: [],
                    line_packs: [],
                    image_modified: true
                }
                resolve(model)
            }
        })        
    }
    
    public printBasic() {
        const imgW = (this.basImage ? this.basImage.width : 0)
        const imgH = (this.basImage ? this.basImage.height : 0)
        console.log(`BAS cnv: ${this.cnvsWidth} ${this.cnvsHeight}, imgW&H: ${imgW} ${imgH}, org: ${this.basOriginX} ${this.basOriginY}, mmPerPx: ${this.basMmPerPx}, fit: ${this.basFitScale}, scale: ${this.basImageScale}, w&h: ${this.basZmdWidth} ${this.basZmdHeight}, left: ${this.basZmdLeft}, top: ${this.basZmdTop}, volume: ${this.basZoomVol}`)
    }

    public printNeo() {
        const imgW = (this.neoImage ? this.neoImage.width : 0)
        const imgH = (this.neoImage ? this.neoImage.height : 0)
        console.log(`NEO cnv: ${this.cnvsWidth} ${this.cnvsHeight}, imgW&H: ${imgW} ${imgH}, org: ${this.neoOriginX} ${this.neoOriginY}, mmPerPx: ${this.neoMmPerPx}, fit: ${this.neoFitScale}, scale: ${this.neoImageScale}, w&h: ${this.neoZmdWidth} ${this.neoZmdHeight}, left: ${this.neoZmdLeft}, top: ${this.neoZmdTop}, volume: ${this.neoZoomVol}`)
    }

}
export default CanvasWorker