import { RefObject, useEffect, useRef } from "react"


const defaultEvents = ["mousedown", "touchstart"]
/**
 * マウスの処理
 * @param ref 
 * @param onClickAway コールバック
 * @param events 
 */
export const useClickAway = <E extends Event = Event>(
    ref: RefObject<HTMLElement | null>,
    onClickAway: (event: E) => void,
    events: string[] = defaultEvents
) => {

    const savedCallback = useRef(onClickAway)

    // 初期化
    useEffect(() => {
        // コールバックを登録
        savedCallback.current = onClickAway
    }, [onClickAway])

    // DOM構築後
    useEffect(() => {
        
        const handler = (event: any) => {
            // RefのElement以外でイベントが発生したか
            const { current: el } = ref
            //console.log("el, event.target : ", el, event.target)
            //console.log("el.children", el?.children)
            if (el && !el.contains(event.target)) {
                // コールバックを実行
                //console.log("コールバックを実行")
                savedCallback.current(event)
            }
        }
        // マウスイベント登録
        events.forEach(eventName => document.addEventListener(eventName, handler))

        // コールバック実行後処理を返す
        return () => {
            events.forEach(eventName => document.removeEventListener(eventName, handler))
        }

    }, [events, ref])
}
