import { CellType, DirectionType } from "./AreaEditPicWriter";
import { Coord } from "../api/data/core/Coord";

/*export const CompressDirectionType = {
    Row: "row",
    Column: "column"
} as const
export type CompressDirection = typeof CompressDirectionType[keyof typeof CompressDirectionType]*/

export type PictType = {
    x: number       // UnitTest用の配列位置X
    y: number
    matX: number    // 元になったMatrixの配列位置X
    matY: number
    id?: number
    compressed?: boolean    // 圧縮済み
    connectCheck?: ConnectionCheckType // 接続チェック結果
}

export type ConnectionCheckType = {
    delete: boolean   // 削除対象フラグ
    times: number     // 探索回数
    isDone?: boolean   // ４方向探索終了フラグ
}

/*export const SideType = {
    Top: "top",
    Right: "right",
    Bottom: "bottom",
    Left: "left"
} as const*/
export type CuttingSide = typeof DirectionType[keyof typeof DirectionType]
export type DirectionSide = typeof DirectionType[keyof typeof DirectionType]

export type PaintLine = {
    st: number
    ed: number
}

/**
 * テストデータ用のCellMatrixを作成する
 * @param width Matrixの幅
 * @param height Matrixの高さ
 * @param x エリアの左上座標
 * @param y エリアの左上座標
 * @param w エリアの幅
 * @param h エリアの高さ
 * @param id エリアID
 * @returns 
 * /
export const createMatrix = (width: number, height: number, x: number, y: number, w: number, h: number, id: number): CellType[][] => {
    const matrix: CellType[][] = []
    for (let iy = 0; iy < height; iy++) {
        const row: CellType[] = []
        for (let ix = 0; ix < width; ix++) {
            if (x <= ix && ix < x + w && y <= iy && iy < y + h) {
                row.push({ area_id: id, col: ix, row: iy, st_x: 0, st_y: 0, ed_x: 1, ed_y: 1 })
            } else {
                row.push({ area_id: undefined, col: ix, row: iy, st_x: 0, st_y: 0, ed_x: 1, ed_y: 1 })
            }
        }
        matrix.push(row)
    }
    return matrix
}*/

/**
 * テスト用のMatrixを作成する
 * 
 * @param startCol 開始列番号（マイナス値あり）
 * @param startRow 開始行番号（マイナス値あり）
 * @param width Matrixの幅
 * @param height Matrixの高さ
 * @param area_id エリアID
 * @param cellIds エリア定義（セルのX,Yリスト）
 * @returns 
 */
export const makeMatrix4Test = async (startCol: number, startRow: number, width: number, height: number, area_id: number, cellIds: Coord[]): Promise<CellType[][]> => {
    const matrix: CellType[][] = []
    const seriY = [...Array(height)].map((_, i) => startRow + i)
    const seriX = [...Array(width)].map((_, i) => startCol + i)
    for await (let iy of seriY) {
        const row: CellType[] = []
        for await (let ix of seriX) {
            const cel: CellType = { area_id: undefined, col: ix, row: iy, st_x: 0, st_y: 0, ed_x: 1, ed_y: 1 }
            for await (let p of cellIds) {
                if (p.x === ix && p.y === iy) {
                    cel.area_id = area_id
                    break
                }
            }
            row.push(cel)
        }
        matrix.push(row)
    }
    return matrix
}

/**
 * CellType[][]のmarixの中から指定したエリアをPictType[][]マップとして切り出す
 * @param matrix 
 * @param x1 
 * @param y1 
 * @param x2 
 * @param y2 
 * @returns 
 * /
export const createWindowAreaMap = (matrix: CellType[][], x1: number, y1: number, x2: number, y2: number): PictType[][] => {
    const map: PictType[][] = []
    if (x1 > x2) throw new Error("x1 > x2")
    if (y1 > y2) throw new Error("y1 > y2")
    let iy = 0
    for (let y = y1; y <= y2; y++) {
        const row: PictType[] = []
        let ix = 0
        for (let x = x1; x <= x2; x++) {
            const cel = matrix[y][x]
            row.push({ x: ix, y: iy, matX: x, matY: y, id: cel.area_id })
            ix++
        }
        map.push(row)
        iy++
    }
    return map
}*/

/**
 * 画面上に表示されているセルをPictType[][]マップとして切り出す
 * 
 * @param matrix 平面図上のエリアをセル単位で管理するマトリックス
 * @param startCol カメラ原点基準の画面上の左上セル列番号
 * @param startRow カメラ原点基準の画面上の左上セル行番号
 * @param endCol カメラ原点基準の画面上の右下セル列番号
 * @param endRow カメラ原点基準の画面上の右下セル行番号
 * @returns 
 */
export const makeViewWindowMap = async (matrix: CellType[][], startCol: number, startRow: number, endCol: number, endRow: number): Promise<PictType[][]> => {
    const map: PictType[][] = []
    const seriY = [...Array(endRow - startRow + 1)].map((_, i) => startRow + i)
    const seriX = [...Array(endCol - startCol + 1)].map((_, i) => startCol + i)
    const dx = matrix[0][0].col
    const dy = matrix[0][0].row
    let y = 0
    for await (let iy of seriY) {
        const row: PictType[] = []
        let x = 0
        for await (let ix of seriX) {
            if ((iy - dy) < 0 || (ix - dx) < 0 || (iy - dy) >= matrix.length || (ix - dx) >= matrix[0].length) {
                row.push({ x: x, y: y, matX: (ix - dx), matY: (iy - dy), id: undefined})
            } else {
                const cel = matrix[iy - dy][ix - dx]
                row.push({ x: x, y: y, matX: (ix - dx), matY: (iy - dy), id: cel.area_id })
            }
            x++
        }
        map.push(row)
        y++
    }
    return map
}

/**
 * マップの指定した位置から指定された方向に向かって、マップを切り出しサブマップとして返す。
 * サブマップは、指定された位置（行またはカラム）が一番上の行となるように座標変換される。
 * 例えば、方向がBottomの場合は、変換なしに切り取られるが、方向がTopの場合は上下が反転する。
 * 方向がRightの場合は、切り出したエリアの左→右が、上→下に変換される。
 * 方向がleftの場合は、切り出したエリアの右→左が、上→下に変換される。
 * @param map 
 * @param direction 
 * @param start 
 * @returns 
 */
export const createDirLessSubMap = async (map: PictType[][], direction: CuttingSide, start: number): Promise<PictType[][]> => {
    const subMap: PictType[][] = []
    if (direction === DirectionType.Top) {
        if (start < 0 || start >= map.length) throw new Error("start < 0 || start >= map.length")
        const seriY = [...Array(start + 1)].map((_, i) => start - i)
        const seriX = [...Array(map[0].length)].map((_, i) => i)
        for await (let y of seriY) {
            const row: PictType[] = []
            for await (let x of seriX) {
                const cel = { ...map[y][x] }
                row.push(cel)
            }
            subMap.push(row)
        }
    } else if (direction === DirectionType.Right) {
        if (start < 0 || start >= map[0].length) throw new Error("start < 0 || start >= map[0].length, start:" + start)
        const seriY = [...Array(map.length)].map((_, i) => i)
        const seriX = [...Array(map[0].length - start)].map((_, i) => start + i)
        for await (let x of seriX) {
            const row: PictType[] = []
            for await (let y of seriY) {
                const cel = { ...map[y][x] }
                row.push(cel)
            }
            subMap.push(row)
        }
    } else if (direction === DirectionType.Bottom) {
        if (start < 0 || start >= map.length) throw new Error("start < 0 || start >= map.length, start:" + start)
        const seriY = [...Array(map.length - start)].map((_, i) => start + i)
        const seriX = [...Array(map[0].length)].map((_, i) => i)
        for await (let y of seriY) {
            const row: PictType[] = []
            for await (let x of seriX) {
                const cel = { ...map[y][x] }
                row.push(cel)
            }
            subMap.push(row)
        }
    } else if (direction === DirectionType.Left) {
        if (start < 0 || start >= map[0].length) throw new Error("start < 0 || start >= map[0].length")
        const seriY = [...Array(map.length)].map((_, i) => i)
        const seriX = [...Array(start + 1)].map((_, i) => start - i)
        for await (let x of seriX) {
            const row: PictType[] = []
            for await (let y of seriY) {
                const cel = { ...map[y][x] }
                row.push(cel)
            }
            subMap.push(row)
        }
    }
    return subMap
}

/**
 * SubMapの行数を圧縮する。圧縮することで無駄な探索を減らすことができる。
 * 
 * @param subMap 
 * @param id 
 * @returns 
 */
export const compressSubMap = async (subMap: PictType[][], id: number): Promise<PictType[][]> => {
    if (!Array.isArray(subMap)) {
        console.log("subMap:", subMap)
        throw new Error("!subMap.isArray()")
    }
    if (subMap.length === 0) throw new Error("subMap.length === 0")
    if (subMap.length === 1) return subMap
    if (!Array.isArray(subMap[0])) throw new Error("!subMap[0].isArray()")
    // 単純にStartLineがつながっているケースは２行目以降を無視する。
    // 最初の一行目を解析、分断されていたら線分をstackに積む
    //console.log("subMap:", subMap[0])
    const lenX = subMap[0].length
    let count = 0
    let prev: number | undefined = undefined
    const stack: PaintLine[] = []
    let aLine: PaintLine | undefined = undefined
    const serial = [...Array(lenX)].map((_, i) => i)
    for await (let i of serial) {
        if (subMap[0][i].id === id) {
            count++
            if (prev !== id) {
                aLine = { st: i, ed: i }
            } else {
                if (aLine) aLine.ed = i
            }
        } else {
            if (aLine) {
                stack.push(aLine)
                aLine = undefined
            }
        }
        prev = subMap[0][i].id
    }
    if (aLine) stack.push(aLine)
    //console.log(stack)
    if (count === lenX || stack.length < 2) {
        // １行目をそのまま返す
        if (subMap.length === 1) return subMap
        const newMap: PictType[][] = subMap.slice(0, 1)
        const seriX = [...Array(lenX)].map((_, i) => i)
        for await (let x of seriX) {
            newMap[0][x].compressed = true
        }
        //console.log("１行目をそのままかえす")        
        return newMap
    }
    // ２行目以降を調べる
    // 切断されている箇所の出現パターンが変化するまで、２行目以降を無視する
    let stckIdx = 0
    const newStack: PaintLine[] = []
    const serial2 = [...Array(stack.length - 1)].map((_, i) => i)
    for await (let n of serial2) {
        const pt1 = stack[stckIdx].ed
        const pt2 = stack[stckIdx + 1].st
        let rowCnt = 1
        while (rowCnt < subMap.length) {
            if (subMap[rowCnt][pt1].id === id && subMap[rowCnt][pt2].id === id) {
                // 切断部分が同じかどうか
                let idCnt = pt2 - pt1 - 1
                for (let j = pt1 + 1; j < pt2; j++) {
                    if (subMap[rowCnt][j].id === id) {
                        idCnt--
                    }
                }
                if (idCnt === 0) {
                    // 繋がっているのでこの２つは１本とみなす。
                    newStack.push({ st: stack[stckIdx].st, ed: stack[stckIdx + 1].ed })
                    break
                } else if (idCnt === pt2 - pt1 - 1) {
                    // 同じパターンなので次の行へ進む
                    rowCnt++
                    if (rowCnt >= subMap.length) {
                        // これ以上の行はないので終了
                        newStack.push(stack[stckIdx])
                        newStack.push(stack[stckIdx + 1])
                        break
                    }
                } else {
                    // パターンが異なるので、右手ルール探査に切り替える。
                    const res = searchByRightHandRule(subMap, id, rowCnt - 1, pt1, pt2)
                    if (res) {
                        newStack.push({ st: stack[stckIdx].st, ed: stack[stckIdx + 1].ed })
                        break
                    } else {
                        newStack.push(stack[stckIdx])
                        newStack.push(stack[stckIdx + 1])
                        break
                    }
                }                
            } else {
                // パターンが異なる場合、右手ルール探査に切り替える。
                const res = searchByRightHandRule(subMap, id, rowCnt - 1, pt1, pt2)
                if (res) {
                    newStack.push({ st: stack[stckIdx].st, ed: stack[stckIdx + 1].ed })
                    break
                } else {
                    newStack.push(stack[stckIdx])
                    newStack.push(stack[stckIdx + 1])
                    break
                }
            }
        }
    }
    //console.log("newStack:", newStack)
    // 結果を作成
    const newMap: PictType[][] = []
    const newMapRow: PictType[] = []
    let curBlk: PaintLine | undefined = undefined
    if (newStack.length > 0) {
        curBlk = newStack.shift()
    }
    for (let i = 0; i < subMap[0].length; i++) {
        const cel = { ...subMap[0][i] }
        if (curBlk && i >= curBlk.st && i <= curBlk.ed) {
            if (!cel.id || cel.id !== id) {
                cel.id = id
            }
        } else {
            let notId: number | undefined = undefined
            for (let j = 0; j < subMap.length; j++) {
                const sm = subMap[j][i]
                if ((sm.id && sm.id !== id) || sm.id === undefined) {
                    notId = sm.id
                    break
                }
            }
            cel.id = notId
        }
        cel.compressed = true
        newMapRow.push(cel)
        if (curBlk && curBlk.ed === i) {
            if (newStack.length > 0) {
                curBlk = newStack.shift()
            } else {
                curBlk = undefined
            }
        }
    }
    newMap.push(newMapRow)
    return newMap
}

type searchWrapType = {
    x: number
    y: number
    direction?: DirectionSide    // 進行方向
    parent?: searchWrapType     // 一つ前の探索位置
    id?: number
    foot: boolean
}

/**
 * 指定されたstartとstopが接続されているかを右手ルールで探索する
 * 接続されていればtrueを返す
 * 　探索の終了条件
 * 　　・doneにぶつかったとき: false
 * 　　・startにぶつかったとき: false
 * 　　・stopにぶつかったとき: true
 * 　　・すべて探索したとき: false
 * @param subMap 
 * @param id 
 * @param rowCnt 
 * @param pt1 
 * @param pt2 
 */
export const searchByRightHandRule = (subMap: PictType[][], id: number, rowCnt: number, pt1: number, pt2: number): boolean => {
    // マップを作成
    const map: searchWrapType[][] = []
    let start: searchWrapType | undefined = undefined
    let stop: searchWrapType | undefined = undefined
    for (let y = rowCnt; y < subMap.length; y++) {
        const row: searchWrapType[] = []
        for (let x = 0; x < subMap[0].length; x++) {
            const sw = { x: x, y: y, id: subMap[y][x].id, foot: false}
            row.push(sw)
            if (x === pt2 && y === rowCnt) {
                start = sw
            } else if (x === pt1 && y === rowCnt) {
                stop = sw
            }
        }
        map.push(row)
    }
    const searchs: searchWrapType[] = []
    // 探索開始位置を追加する
    if (start && stop) {
        let target = (start.y < map.length - rowCnt) ? map[start.y + 1 - rowCnt][start.x] : undefined
        if (target) {
            target.direction = DirectionType.Bottom
            target.parent = start
            searchs.push(target)
        }
        target = (start.x < map[0].length) ? map[start.y - rowCnt][start.x + 1] : undefined
        if (target) {
            target.direction = DirectionType.Right
            target.parent = start
            searchs.push(target)
        }
        start.foot = true
    } else {
        throw new Error("start or stop is undefined. start:" + start + ", stop:" + stop)
    }
    // 探索開始
    while (searchs.length > 0) {
        const act = searchs.pop()
        //console.log("pop act:", act)
        if (!act) throw new Error("searchs is empty.")
        // 探索済みはスキップ
        if (act.foot) continue        
        // 探索終了条件
        if (act === stop) {
            //console.log("succ. ゴール到達")
            return true
        }
        if (act === start) {
            //console.log("fail. startに戻った")
            return false
        }
        act.foot = true
        if (act.id && act.id === id) {
            // 次の探索位置を追加する
            const searchTop = (act.y - rowCnt > 0) ? map[act.y - 1 - rowCnt][act.x] : undefined
            const searchRight = (act.x < map[0].length) ? map[act.y - rowCnt][act.x + 1] : undefined
            const searchBottom = (act.y + rowCnt < map.length - 1) ? map[act.y + 1 - rowCnt][act.x] : undefined
            const searchLeft = (act.x > 0) ? map[act.y - rowCnt][act.x - 1] : undefined
            if (searchTop !== undefined && searchTop.id === id) {
                searchTop.direction = DirectionType.Top
                searchTop.parent = act
            }
            if (searchRight !== undefined && searchRight.id === id) {
                searchRight.direction = DirectionType.Right
                searchRight.parent = act
            }
            if (searchBottom !== undefined && searchBottom.id === id) {
                searchBottom.direction = DirectionType.Bottom
                searchBottom.parent = act
            }
            if (searchLeft !== undefined && searchLeft.id === id) {
                searchLeft.direction = DirectionType.Left
                searchLeft.parent = act
            }
            // popした時に右手探索となるように格納する
            if (act.direction === DirectionType.Top) {
                if (searchBottom && !searchBottom.foot) searchs.push(searchBottom)
                if (searchLeft && !searchLeft.foot) searchs.push(searchLeft)
                if (searchTop && !searchTop.foot) searchs.push(searchTop)
                if (searchRight && !searchRight.foot) searchs.push(searchRight)
            } else if (act.direction === DirectionType.Right) {
                if (searchLeft && !searchLeft.foot) searchs.push(searchLeft)
                if (searchTop && !searchTop.foot) searchs.push(searchTop)
                if (searchRight && !searchRight.foot) searchs.push(searchRight)
                if (searchBottom && !searchBottom.foot) searchs.push(searchBottom)
            } else if (act.direction === DirectionType.Bottom) {
                if (searchTop && !searchTop.foot) searchs.push(searchTop)
                if (searchRight && !searchRight.foot) searchs.push(searchRight)
                if (searchBottom && !searchBottom.foot) searchs.push(searchBottom)
                if (searchLeft && !searchLeft.foot) searchs.push(searchLeft)
            } else if (act.direction === DirectionType.Left) {
                if (searchRight && !searchRight.foot) searchs.push(searchRight)
                if (searchBottom && !searchBottom.foot) searchs.push(searchBottom)
                if (searchLeft && !searchLeft.foot) searchs.push(searchLeft)
                if (searchTop && !searchTop.foot) searchs.push(searchTop)
            }
        }
    }
    //console.log("search end")
    return false
}

/**
 * 削除可能チェック用の検査マップを作成する
 * このマップは、Canvas上に表示されているセルとその上下左右１セルずつ加えたマップとなる。
 * 
 * @param matrix エリア管理用セルマトリックス
 * @param area_id 選択中のエリアID
 * @param x1 窓枠の左上セル座標X
 * @param y1 窓枠の左上セル座標Y
 * @param x2 窓枠の右下セル座標X
 * @param y2 窓枠の右下セル座標Y
 * @param areaMinX エリアの最小X座標
 * @param areaMinY エリアの最小Y座標
 * @param areaMaxX エリアの最大X座標
 * @param areaMaxY エリアの最大Y座標
 * @returns 
 */
export const createDetectionMap = async (matrix: CellType[][], area_id: number, x1: number, y1: number, x2: number, y2: number, areaMinX: number, areaMinY: number, areaMaxX: number, areaMaxY: number): Promise<PictType[][]> => {
    console.log("createDetectionMap: x1, y1, x2, y2:", x1, y1, x2, y2)
    console.log("createDetectionMap: areaMinX, areaMinY, areaMaxX, areaMaxY:", areaMinX, areaMinY, areaMaxX, areaMaxY)
    // 指定された窓枠
    const stX = (x1 < x2) ? x1 - 1 : x2 - 1
    const edX = (x1 < x2) ? x2 + 1 : x1 + 1
    const stY = (y1 < y2) ? y1 - 1 : y2 - 1
    const edY = (y1 < y2) ? y2 + 1 : y1 + 1
    // Matrixよりも１セル分大きいマップを作成可能とする
    if (stX < -1 || stY < -1) throw new Error("stX < -1 || stY < -1")
    if (edX > matrix[0].length + 1 || edY > matrix.length + 1) throw new Error("edX > matrix[0].length + 1 || edY > matrix.length + 1")
    // 指定されたエリアの最小・最大座標を求める
    /*let minX: number | undefined, minY: number | undefined, maxX: number | undefined, maxY: number | undefined
    for await (let p of cell_ids) {
        if (minX === undefined || minX > p.x) minX = p.x
        if (minY === undefined || minY > p.y) minY = p.y
        if (maxX === undefined || maxX < p.x) maxX = p.x
        if (maxY === undefined || maxY < p.y) maxY = p.y
    }
    if (minX === undefined || minY === undefined || maxX === undefined || maxY === undefined) throw new Error("minX === undefined || minY === undefined || maxX === undefined || maxY === undefined")*/
    // エリアが窓をはみ出している場合は、はみ出した部分を含めて抜き出したマップを作成する
    let psX = (stX < areaMinX) ? stX : areaMinX
    let peX = (edX > areaMaxX) ? edX : areaMaxX
    let psY = (stY < areaMinY) ? stY : areaMinY
    let peY = (edY > areaMaxY) ? edY : areaMaxY
    let map = await makeViewWindowMap(matrix, psX, psY, peX, peY) //createWindowAreaMap(matrix, psX, psY, peX, peY)
    // 下部はみ出しの圧縮
    //console.log("maxY, edY:", maxY, edY)
    if (areaMaxY > edY - 1) {
        //console.log("view window map:", map)
        const startLine = (edY - 1) - psY + 1
        //console.log("startLine, edY, psY:", startLine, edY, psY)
        const subMap = await createDirLessSubMap(map, DirectionType.Bottom, startLine)
        //console.log("bottom subMap:", subMap)
        const newMap = await compressSubMap(subMap, area_id)
        //console.log("bottom newMap:", newMap)
        map = [...map.slice(0, startLine), ...newMap]
        //console.log("bottom map:", map)
    }
    // 上部はみ出しの圧縮
    if (areaMinY < stY + 1) {
        //console.log("view window map:", map)
        const startLine = (stY + 1) - psY - 1
        //console.log("startLine, stY, psY, minY:", startLine, stY, psY, minY)
        const subMap = await createDirLessSubMap(map, DirectionType.Top, startLine)
        //console.log("top subMap:", subMap)
        const newMap = await compressSubMap(subMap, area_id)
        map = [...newMap, ...map.slice(startLine + 1)]
    }
    // 右部はみ出しの圧縮
    if (areaMaxX > edX - 1) {
        const startLine = (edX - 1) - psX + 1
        //console.log("startLine, edX, psX, maxX:", startLine, edX, psX, maxX)
        const subMap = await createDirLessSubMap(map, DirectionType.Right, startLine)
        //console.log("right subMap:", subMap)
        const newMap = await compressSubMap(subMap, area_id)
        //console.log("right newMap:", newMap)
        // newMapは縦一列なので各行の右端に追加していく
        let iy = 0
        for await (let cel of newMap[0]) {
            map[iy] = [...map[iy].slice(0, startLine), cel]
            iy++
        }
        //console.log("result right map:", map)
    }
    // 左部はみ出しの圧縮
    if (areaMinX < stX + 1) {
        const startLine = (stX + 1) - psX - 1
        const subMap = await createDirLessSubMap(map, DirectionType.Left, startLine)
        //console.log("left subMap:", subMap)
        const newMap = await compressSubMap(subMap, area_id)
        //console.log("left newMap:", newMap)
        let iy = 0
        for await (let cel of newMap[0]) {
            map[iy] = [cel, ...map[iy].slice(startLine + 1)]
            iy++
        }
    }
    //console.log("result map:", map)
    return map
}


/**
 * 編集対象となるエリアで画面上に表示されていない部分があれば、その部分を圧縮したマップを返します。
 * 
 * @param matrix 全エリアのマトリックス
 * @param area_id 圧縮対象のエリアID
 * @param x1 左上座標
 * @param y1 左上座標
 * @param x2 右下座標
 * @param y2 右下座標
 * @param dir 圧縮方向
 * @param start 開始位置
 * @param stop 終了位置
 *　/
export const areaCompress = (matrix: CellType[][], area_id: number, x1: number, y1: number, x2: number, y2: number, dir: CompressDirection, start: number, stop: number) => {
    const map: PictType[][] = []
    let targetCount = 0
    if (x1 > x2) throw new Error("x1 > x2")
    if (y1 > y2) throw new Error("y1 > y2")
    // コピーする
    if (dir === CompressDirectionType.Row) {
        let stY = (start < stop) ? start : stop
        let edY = (start < stop) ? stop : start
        if (stY < y1 || y2 < edY) throw new Error("start < y1 || stop > y2")
        let iy = 0
        for (let y = stY; y <= edY; y++) {
            const row: PictType[] = []
            let ix = 0
            for (let x = x1; x <= x2; x++) {
                const cel = matrix[y][x]
                if (cel.area_id === area_id) {
                    row.push({ x: ix, y: iy, matX: x, matY: y, id: cel.area_id })
                    targetCount++
                } else {
                    row.push({ x: ix, y: iy, matX: x, matY: y })
                }
                ix++
            }
            map.push(row)
            iy++
        }
    } else {
        let stX = (start < stop) ? start : stop
        let edX = (start < stop) ? stop : start
        if (stX < x1 || x2 < edX) throw new Error("start < x1 || stop > x2")
        let iy = 0
        for (let y = y1; y <= y2; y++) {
            const row: PictType[] = []
            let ix = 0
            for (let x = stX; x <= edX; x++) {
                const cel = matrix[y][x]
                if (cel.area_id === area_id) {
                    row.push({ x: ix, y: iy, matX: x, matY: y, id: cel.area_id })
                    targetCount++
                } else {
                    row.push({ x: ix, y: iy, matX: x, matY: y })
                }
                ix++
            }
            map.push(row)
            iy++
        }
    }
    // 塗りつぶす
    const stack: PictType[] = []
    stack.push(map[1][0])
    stack.push(map[0][1])
    let count = targetCount
    while (stack.length > 0) {
        const px = stack.pop()
        if (px === undefined) throw new Error("stack is empty")
        if (px.painted) continue
        px.painted = (px.id && px.id === area_id) ? true : false
        if (px.painted) count--
        if (px.y > 0) stack.push(map[px.y - 1][px.x])
        if (px.x < map[0].length) stack.push(map[px.y][px.x + 1])
        if (px.y < map.length) stack.push(map[px.y + 1][px.x])
        if (px.x > 0) stack.push(map[px.y][px.x - 1])
    }
    if (count > 0) {
        // もう一度塗りつぶしあり
    }
    // 結果を作成
    return map[0]
}*/