import { isConstructorDeclaration } from "typescript";
import { ResCompany, ResShop, ResUser } from "../login/LoginInfo";

export class ReqCompany {
	/**
	 * 
	 * @param name 企業の名前
	 * @param start 企業利用開始日。yyyy-MM-dd形式
	 * @param end 企業利用終了日。yyyy-MM-dd形式。この日のうちは利用可能
	 * @param settings 企業固有の設定。中に何が入るかは未定
	 * @param company_designator 企業識別子。企業を一意に識別するためのASCII文字列
	 * @param all_dashboard_threshold 全店ダッシュボードの滞在しきい値
	 * @param shop_dashboard_threshold 個店ダッシュボードの滞在しきい値
	 */
	constructor(
		public name: string,
		public start: string,
		public end: string,
		public settings: object,
		public company_designator: string,
		public all_dashboard_threshold: number,
		public shop_dashboard_threshold: number
	) {
	}

	static fromResCompany(c: ResCompany): ReqCompany {
		return new ReqCompany(
			c.name,
			new Date(c.start * 100).toISOString().substring(0, 10),
			new Date(c.end).toISOString().substring(0, 10),
			c.settings,
			c.company_designator,
			c.all_dashboard_threshold,
			c.shop_dashboard_threshold
		)
	}
}

export class ReqShop {
	/**
	 * 
	 * @param name 店名
	 * @param start 店のデータ取得開始日。yyyy-MM-dd形式
	 * @param timezone 店のタイムゾーン（分）
	 * @param shop_designator 店の識別子
	 * @param hot_layout_set_id 現在ホットなレイアウトセット
	 */
	constructor(
		public name: string,
		public start: string,
		public timezone: number,
		public shop_designator: string,
		public hot_layout_set_id: number
	) { }

	static fromResShop(s: ResShop): ReqShop {
		return new ReqShop(
			s.name,
			new Date(s.start * 1000).toISOString().substring(0, 10),
			s.timezone,
			s.shop_designator,
			(s.hot_layout_set == null ? 0 :s.hot_layout_set.layout_set_id)
		)
	}
}

export interface ReqNewUser {
	/** ログインID */
	login_id: string;
	/** パスワード */
	password: string;
	/** 表示用の名前 */
	name: string;
	/** ユーザの権限を表すID */
	user_type_id: number;
}

export class ReqUpdateUser {
	constructor(
		public user_id: number,
		public login_id: string,
		public name: string,
		public user_type_id: number,
		public dashboard_order: number[]
	) { }

	static fromResUser(u: ResUser): ReqUpdateUser {
		return new ReqUpdateUser(
			u.user_id,
			u.login_id,
			u.name,
			u.user_type_id,
			u.dashboard_order
		);
	}
}