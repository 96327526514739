import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import style from "./AreaMagControler.module.css"

interface Props {
    onSkipUp: () => void
    onSkipDown: () => void
    onSkipLeft: () => void
    onSkipRight: () => void
    onMoveUp: () => void
    onMoveDown: () => void
    onMoveLeft: () => void
    onMoveRight: () => void
    onZoomIn: () => void
    onZoomOut: () => void
    onZoomReset: () => void
}

export const AreaMagControler: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    const lblSkipUp = useMemo(() => { return t("button.skipUp") }, [t])
    const lblSkipRight = useMemo(() => { return t("button.skipRight") }, [t])
    const lblSkipDown = useMemo(() => { return t("button.skipDown") }, [t])
    const lblSkipLeft = useMemo(() => { return t("button.skipLeft") }, [t])
    const lblMoveUp = useMemo(() => { return t("button.moveUp") }, [t])
    const lblMoveRight = useMemo(() => { return t("button.moveRight") }, [t])
    const lblMoveDown = useMemo(() => { return t("button.moveDown") }, [t])
    const lblMoveLeft = useMemo(() => { return t("button.moveLeft") }, [t])
    const lblZoomOut = useMemo(() => { return t("button.zoomOut") }, [t])
    const lblZoomReset = useMemo(() => { return t("button.zoomReset") }, [t])
    const lblZoomIn = useMemo(() => { return t("button.zoomIn") }, [t])

    return (
        <div className={style.main}>
            <div className={style.moving}>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><button className={style.iconBtn} onClick={props.onSkipUp} title={lblSkipUp}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVRoge2XQU7DMBBFu+EACLbcgF2apBJBccqyRRmz4wBF6gVYcil6hK4Ci56CE7BASAg8NOBCZcdppvFU8pcsVa3jec9xm+lgEBISEhLCMWkOWVrAIi3k40jcXPjmaZVEyIkabwr+cz3gPR5L6ZvLKdvwByRhhj8AiWZ4xhLu8AwlbPCJgIdElPdsJZrg9TyGEq7wej4jibbw+joGErvC6+s9SnSF1+t4kEjyErBAV/jf9dQ1JgmsRQo/vCzPUiFfqeB/YpRQtbAmmUBSwG1b+Ci6OxqNy+s4hym+biuBNckEhrk8TwV8uMLHV3Ci5ld6R6HC95wlVC2sSSaAUWdzpsZLPeYN8KvtYwErmwSuubH+jBTeNVk2OVawz8YeSElE+fTUC1xTGuE5SzjDc5Qwnvka1PaZ7TvRS6w7X++yyxy28LvMZQfPTqILiHcJCoD1GvDUuwTl7vUugc3Yn96GoCBeY/mJrWwNYOtgV7mP3bJJYE0yAWyJ9/UgMj0IsSYF+3fwdqr+fLl5iymfov/bb6xFeoQwrn9QuK4fEhISEhLCMl9BcMy+PAgdcgAAAABJRU5ErkJggg==" alt="skip up"></img></button></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><button className={style.iconBtn} onClick={props.onMoveUp} title={lblMoveUp}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVRoge2TQQ7BQBSGJVhwBAcQC1YOILErjYX04QAyM4mVEziCA7Bmw3kkLiCRcAXmaWmCiSpNR/xf8jZt53/f36aZDAAAAAAAAAAkQGcwKvGk7fE2dSHyDql5i9TJHznja2l7RYKIso6nFqG8P7rQ2voSJvmfKPFK3uoSUeWtLMEiLU+unsvKvT9P7ukzqZe4vHmSS5O8Q6LmdkVFP7Oz7ktEkb8+a12Jd+StKxFH3qoSWmAaRz48L2qmH5uzky/gyWNc+VuGoQRnJ+keLFebT+TDnMcSnJ2E891i1dTLDsHCbbunqnGz+CxnBAUOnP1NVyOuK4pOf1huNCa5T7M4g7OIxoVvuAEAAAAAAAD+hzPZq1wvYoLQLgAAAABJRU5ErkJggg==" alt="move up"></img></button></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><button className={style.iconBtn} onClick={props.onSkipLeft} title={lblSkipLeft}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVRoge2YTUrDQBiGu/EAoltv4C4/UzBlZurOtuSb7jyAgpeoh/IKrtRFewg9gQsRpJ0vbTBCZmIDToy+DwxkkQ+ed37I5BsMAAAAAPBJPMpPUk2XsTSn+9RF0fXBcJzPEklTfv4pPy+pzEko8yq0WQtFH0LT1XfqknM6su8/FHV22Am4Dx6ikNf0XkpsB7001e3kl1/rzJpXI4R3Qb28nUllnn11LnkevJU6ld+twE0bed5OQbaQneGJHW91EqmihasuyyaH9p0nh/wyktNjyEMe8pCHfPfyQlLm+kj55PkjVL3bdCJfBNDmbl95hu8xncszbQPwPeZXBBiq+VmvtxAj9PzCc4hvXXXbQ0yPjroVQiAEQvyxg40Q/zZEr3/qm0P4+0K+EEH7QkxdiKa+EOMKEawvVKXXrcWSXjd3AQAAgNBsAIuZzJodzvq0AAAAAElFTkSuQmCC" alt="skip left"></img></button></td>
                            <td><button className={style.iconBtn} onClick={props.onMoveLeft} title={lblMoveLeft}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVRoge3YMQrCMBTG8YI66BE8gJNOHqDgllqc+tQDSBJw8gQewQs46+J9BI8giEfQpBBwSeyirw++H7z9H2hKX7MMAAAAAH5uqnVPkTm4eSqyVzcz7qbGfHxRmUtB9vUxj7LUA+62ryLx9ajVZsTdl5SMJ3vL832XuzEqFV+Quc+XdszdGEVEHXdZz7F4RXrC3RhVx1f2hPh/Qzwn90o8io1frLdDsfGe+AN4oh8hT/wl9nCItsAh2kL053QgeqEJRK+UQWypJ9r1udsaE/1bBQAAAKCl3hNoXDUIzEQLAAAAAElFTkSuQmCC" alt="move left"></img></button></td>
                            <td></td>
                            <td><button className={style.iconBtn} onClick={props.onMoveRight} title={lblMoveRight}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVRoge3YwQnCMBQG4B70oCM4gCjoyQEK3hKDB+mzDiBJxJMTuIETOILOI7iAIIgj6PMiCFYLgskr/we5/z9Nmr4mCQAAAAD8RJEf8joocleV+c3A2nroTKUZY5ua/IXX7bkytxNTQuXz9kt4aSXSdF3j7XMUXWI09T1N7iy6hJnYDh/i07sS/IT2KPEvKBGLSpRQZPtFbycusQ2dr5RPJcazZSt0vq9EFxC9hXS+6BYd4uhvZ4QPpbLho7+4RH9Oix9oxI+URKuG6KH+QfRvFQAAAADB7sHTXFf6Ruq1AAAAAElFTkSuQmCC" alt="move right"></img></button></td>
                            <td><button className={style.iconBtn} onClick={props.onSkipRight} title={lblSkipRight}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQklEQVRoge2YQU7CQBSG2XAAo1uP4Kp02sRCp7AEM2/ceQBJvADsuBRXYIUuvIScwBUJwXliNKadcZR0SuP/JSQseOH7yJBMX6cDAAAA/AeiaNpNh+omzmnC738zmw7UlSjortdXl3X5OWFhI7BKCr1/f0laxyM695lNCpqaz+8Oc/pV5Irq9i3Bv/yn/FfEs0+ECdh8n6Vt8Ag+NqUAzwgh9Ut5NnAEHyE+Nn+JMLIPlXOhI7JsfGa+9NEWEeWTC9uskGpmi4iHWiMCEe2N0E+IQAQiDrAky1oi1q4LoJC0sEUkOWUnEcH3KdesPUIvQ/k7A/g+5ZptPKCuI5TK2+va5Y/5E5tnhLlNPshlD/KQhzzkIf8jrX+or9wLech/BGwalWcq90Kei63yXqiBxdaRq8X7xleLTKuXuwAAAMCp8gbR9MyXzZaNVQAAAABJRU5ErkJggg==" alt="skip right"></img></button></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><button className={style.iconBtn} onClick={props.onMoveDown} title={lblMoveDown}><img width="20" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABG0lEQVRoge2UQQ7BQBSGJVhwBAeQSFg5QBO7llj14QAyHbFyAjdwAdZWnEfiAhIJV2CemqSiVS2NqfxfMkk373/fn8m0UAAAAAAAAACABPR6omoPx3XLmpc+zeIMziKaVb7hFotNsuOQPKlzUd/77kA202bxLGdwFmdy9jddQ1FLdveF9+MdbRKt5DmixbPBLM7OwvlxseudHwskLxEmfyugsrN095e7cvFc4P0SUfJ+AbnIvAARFdWidZoSznDSsMk7hMqT3LaFKGdeIG0JY+TTlDBOPkkJY+U1LOC43iaqRNSD5Zmfy2te34Th8pq3S5gor4ktYbK8JrJEHuQ1LKr+MKvAY17mRj5IfzSt8fm1BwAAAAAAAOA/uQLkj1w94unuawAAAABJRU5ErkJggg==" alt="move down"></img></button></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><button className={style.iconBtn} onClick={props.onSkipDown} title={lblSkipDown}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVRoge2XT06DQBSHu/EARrcewRUWSEQZdKc1DN15gJp4And6KD2Cq+qiXsITuDAmjc5reUigM8OfV2eI80tImrT9ve9j6EBHIxcXFxcXl/8Qz7vZCc/Sq3HMJ/B6UP1Q6Cf8OUiy79XB+Hx8zveo+qELOrEfZpFKwJkp4H8lFhQSOfyi2g8zKdjXQ8Sy1gRyCS+e7Hfthe9ugocDZpIJwHKWl5hCQgUPs8h/B1F0uRsk/IVCYt2VvW5jVbcuYQy+iYTP+JsKwDh8Hwlr4LtIWAffRsJaeIzsRoSAqvco7+a9otydbD3z1TSWsBEeo5UwCS/AZuJ4z49b2eekvwnNNQ+dpf4ZKXx4mh4KgGVll7nXSMxL8MrHb+iqyC5hJpmAeD6/lmyVUommf1Bq8NgtZpIJHJ2kBwHLPtpK6CKDh1kwk0xgNSxOubg2v6gkpPBiBswihccEyfTCZ9mnROKhcQ/L7v4cvhjeU8IofAHRUcIK+AKmpYRV8AVUQwkr4TG63cnIbtM2qpWwHh7TXMJCeIxewmJ4jFxiAPCYusSA4DFBzCMB/iQEHkM2PTbN4+Li4uKyMT8IR8y4oTBZXwAAAABJRU5ErkJggg==" alt="skip down"></img></button></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={style.zooming}>
                <div></div>
                <div><button className={style.iconBtn} onClick={props.onZoomOut} title={lblZoomOut}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAABHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAcBYCzAAAAEnRSTlMB82dOuBAEAALihCjD1SmA5zknBORRAAAA8klEQVQ4y5WT2wKEIAhEqRS0+87/f+xiF7Wy2uXFhCPCSCSbEVO2JNu25JLL0RXQMNemAipT87I9AupoEa09ELTErQdMY0VsYwBvM4KWuDq73dMpnBEUEI9eP5nUtALp4WP1ujq9Xx28H+FwoI0piISBLsUD0QEcBWCpYeRkBvV+RDMYNHmCkKLRMxS7qLTok3gWVdYmED6SUOvuH+Dlitcif2jzKtQIuNRmUeopSV18rF4ennvU5x4ky3AdmH7AHInCyE0ic6jK3Qzt4veRKI19iESCij9ORpAUbSV4VeGeGB4AJXj4PAEHHcqmwj8Dq30BS6IfXp/BD7sAAAAASUVORK5CYII=" alt="zoom out"></img></button></div>
                <div><button className={style.iconBtn} onClick={props.onZoomReset} title={lblZoomReset}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQlBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABz07T7AAAAFXRSTlMAxWcP4efxAgS5Wh2BONEo2E+GLYN8O6oYAAAA6klEQVQ4y52T247DIAxETQKxya1p0/n/X13TFSiGlq3WLwh0gsfMhOj7ErOYAsw58zuAeIgTMMVBUX4DPFHq0RAAH4CbF6JldoAfKwII8GfebdqpIvRalwUmsTt83eK3ypxBdfBHQIkNkP6jOQwkvTedEXvfMy2Y/jCmCPofULeImK1kK1JoaCVHOyZwu26FTrXv2nFNby+XZAWs1i3/Mkty4lzxLl8xagZueXsG4LCZUcID8T4Sjfeo3h8INaE6Sq1JREuU0L7OG4LMWJwIX2f3+uNwmsy3+TcXKiFdw5TY+5bKvlGf+Kp+APK0DLA+47EJAAAAAElFTkSuQmCC" alt="zoom reset"></img></button></div>
                <div><button className={style.iconBtn} onClick={props.onZoomIn} title={lblZoomIn}><img width="20" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAABHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAcBYCzAAAAEnRSTlMB82dOuBAEAALihCjD1SmA5zknBORRAAAA+klEQVQ4y42T66KEIAiEpzLILnuZ93/Yg1pqJ7dd/hTDJwIqdDcIqk+x3cVUpAlXwMLSu47sXC/RPQMmzMw2nwjE+OhJN4yq4+BIP1YEYtzE5VAWgysCAfFc7TfUD6tAV/pcvX0n298EOZZIWDDnFIAKuaQ4mYiFlDwA0Z5uryYAUXfsj5SWwXHYvZTBnMHWIHfRWdGoAVhbXdVmksugKvRH4MsWX4v8oc3roB7kVNpsjvpZRt08rFVvjvthx71pleF6YdaNr0w0rtxT9RWqmj5c2qj7TLSufYhkAs2HUxH/3lHJEghJU/hMbDeAEbK974DTHNoG4B5I9gf80SAOyosuXgAAAABJRU5ErkJggg==" alt="zoom in"></img></button></div>
                <div></div>
            </div>
        </div>
    )
}