import React, { useRef, useState, useEffect, useMemo } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from "react-chartjs-2"
import type { ChartData, ChartOptions } from "chart.js"
import Select, { SingleValue, ActionMeta } from "react-select"
import { useTranslation } from "react-i18next"

import { ContentsDistributor, MainCompoProps } from "./ContentsDistributor"
import { LegendColorType, LegendStayPass } from "../../component/legend_stay_pass/LegendStayPass"
import { BtnProp, SeriesSwitcher } from "../../component/series_switcher/SeriesSwitcher"
import { AnalyzeParametersType, CompareSideType } from "../../types/Analyze"
import Utils from "../../lib/Utils"
import { STAY_COLOR, NOT_STAY_COLOR, STAY_COLOR_GR, NOT_STAY_COLOR_GR } from "../../lib/ColorUtil"
import { TimeUnit, TimeUnitType } from "../../api/data/core/Enums"
import { Timeline } from "../../api/data/analysis/AnalysisResult"
import { TailSpin } from "react-loader-spinner"
import { TIMELINE_HEIGHT, TIMELINE_ITEMS_BORDER, TIMELINE_WIDTH_NORMAL, TIMELINE_WIDTH_SCROLL } from "../../constants"

import style from "./TimelineGraph.module.css"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

type SwitchItemType = {
    type: TimeUnit,
    label: string,
    format: string
}

export const SwitchItems: SwitchItemType[] = [
    { type: TimeUnitType.Hour, label: "Hours", format: "yyyy-MM-dd EEE HH" },
    { type: TimeUnitType.Day, label: "Days", format: "yyyy-MM-dd EEE" },
    { type: TimeUnitType.Week, label: "Weeks", format: "yyyy-MM-dd" },
    { type: TimeUnitType.Month, label: "Months", format: "yyyy-MM" }
]

export type TimelineAllLabels = {
    hour: string[]
    day: string[]
    week: string[]
    month: string[]
}

export type TimelineAllDatas = {
    stay: {
        hour: number[]
        day: number[]
        week: number[]
        month: number[]
    }
    notStay: {
        hour: number[]
        day: number[]
        week: number[]
        month: number[]
    }
}

export type TimelineAllSizes = {
        hour: number
        day: number
        week: number
        month: number
}

export type TimelineDataType = {
    labels: TimelineAllLabels
    counts: TimelineAllDatas
    sizes: TimelineAllSizes
}

const CPY_MARGIN_NORMAL = 1
const CPY_MARGIN_WIDE = 1

interface VbcProps {
    size: number
    timeUnit: TimeUnit
    chartData: ChartData<'bar'> | undefined
}

const VerticalBarChart: React.FC<VbcProps> = (props) => {

    const barRef = useRef<any>(null)
    const axesRef = useRef<HTMLCanvasElement>(null)

    const cpyMargin = useMemo(() => {
        return (props.timeUnit === TimeUnitType.Hour) ? CPY_MARGIN_WIDE : CPY_MARGIN_NORMAL
    }, [props.timeUnit])

    // 横スクロール用Y軸描画キャンバスをクリアする処理
    useEffect(() => {
        if (axesRef && axesRef.current) {
            const axesCtx: CanvasRenderingContext2D | null | undefined = axesRef.current.getContext("2d")
            if (axesCtx) {
                //console.log("clearRect")
                axesCtx.clearRect(0, 0, TIMELINE_WIDTH_NORMAL, TIMELINE_HEIGHT)
            }
        }
    }, [axesRef, props.timeUnit])

    if (props.chartData === undefined) return null

    const option: ChartOptions<'bar'> = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false }
        },
        scales: {
            x: { stacked: true },
            y: {
                suggestedMin: 0,
                suggestedMax: 100,
                beginAtZero: true,
                stacked: true
            }
        },
        animation: {
            onComplete: (animation) => {
                //console.log("VerticalBarChart animation complete. animation:", animation)
                if (props.size > TIMELINE_ITEMS_BORDER) {
                    // Chromeの実装：モニター解像度に合わせcanvasの大きさが変化する！？
                    console.log("barRef.current", barRef.current)
                    let scale = 1
                    if (barRef.current) {
                        // キャンバスの縦横比は変わらない前提
                        //const w = barRef.current.canvas.width
                        const h = barRef.current.canvas.height
                        scale = h / TIMELINE_HEIGHT
                        //console.log("w,h:", w, h)
                    }
                    console.log("scale:", scale)
                    // ChartからY軸部分だけコピー
                    const srcCanvas = animation.chart.canvas
                    //console.log("animation.chart.scales.y:", animation.chart.scales.y)
                    const cpyWidth = animation.chart.scales.y.width + animation.chart.scales.y.left + 1
                    const cpyHeight = animation.chart.scales.y.height + animation.chart.scales.y.top + 5
                    //console.log("cpyWidth, cpyHeight, cpyMargin:", cpyWidth, cpyHeight, cpyMargin)
                    const targetCtx: CanvasRenderingContext2D | null | undefined = axesRef.current?.getContext("2d")
                    if (targetCtx) {
                        targetCtx.canvas.width = cpyWidth
                        targetCtx.canvas.height = cpyHeight
                        targetCtx.clearRect(0, 0, cpyWidth, cpyHeight)
                        targetCtx.fillStyle = "#FFFFFF"
                        targetCtx.fillRect(0, 0, cpyWidth, cpyHeight)
                        targetCtx.drawImage(srcCanvas, 0, 0, cpyWidth * scale, cpyHeight * scale, 0, 0, cpyWidth, cpyHeight)
                    }
                }
            }
        }
    }

    if (props.size > TIMELINE_ITEMS_BORDER) {
        // 横スクロール付き
        return (
            <div className={style.chartWrapper}>
                <div className={style.chartAreaWrapper}>
                    <Bar width={TIMELINE_WIDTH_SCROLL} height={TIMELINE_HEIGHT} data={props.chartData} options={option} ref={barRef} />
                </div>
                <canvas id="axesCanvas" width={1000} height={TIMELINE_HEIGHT} ref={axesRef}></canvas>
            </div>
        )
    } else {
        // 固定タイプ
        return (
            <Bar width={TIMELINE_WIDTH_NORMAL} height={TIMELINE_HEIGHT} data={props.chartData} options={option}/>
        )
    }
}

type SelectOptionType = {
    value: any,
    label: string
}

const TimelineGraphCore: React.FC<MainCompoProps> = (props) => {

	const { t } = useTranslation()
    const [selectArea, setSelectArea] = useState<SelectOptionType | undefined>(undefined)
    const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnitType.Day)
    const [timelineData, setTimelineData] = useState<Timeline[] | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const side = useMemo(() => {
        if (props.request) {
            if (props.request.side) {
                return props.request.side
            }
        }
        return "0"
    }, [props.request])

    useEffect(() => {
        if (props.data) {
            const areaId = (selectArea === undefined || selectArea.value === "0") ? undefined : parseInt(selectArea.value)
            setIsLoading(true)
            props.data.get_timeline(timeUnit, areaId).then(res => {
                //if (areaId === undefined) console.log("api res:", res) // for DEBUG
                if (res !== timelineData) setTimelineData(res)
                setIsLoading(false)
            })
        }
    }, [props.data, timeUnit, selectArea])

    const areaSelectOptions = useMemo((): SelectOptionType[] => {
        //const label = t("visitors")
        //const defaultOption = {value: "0", label: label}
        let options: SelectOptionType[] = []
        console.log("props.data:", props.data)
        const area_list = (props.data && props.data.layout && props.data.layout.area_set && props.data.layout.area_set.length > 0) ? props.data?.layout.area_set[0].area_defs : undefined
        if (area_list) {
            options = area_list.map((el) => {
                return { value: el.area_id, label: el.name } as SelectOptionType
            })
            if (selectArea === undefined && options.length > 0) {
                setSelectArea(options[0])
            }
        }
        //return [defaultOption, ...options]
        return options
    }, [props])

    const handleChangeArea = (newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) => {
        const val: SelectOptionType = newValue as SelectOptionType
        setSelectArea(val)
    }

    const changeTimeUnit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        // ロード中はボタンを押せなくする
        if (!isLoading) {
            switch (nm) {
                case "hour":
                    setTimeUnit(TimeUnitType.Hour)
                    break
                case "day":
                    setTimeUnit(TimeUnitType.Day)
                    break
                case "week":
                    setTimeUnit(TimeUnitType.Week)
                    break
                case "month":
                    setTimeUnit(TimeUnitType.Month)
                    break
                default:
					const msg = t("msgUnknownButtonPressed")
                    throw new Error(msg + "nm:" + nm)
            }
        }
    }

    const getButtonList = ():BtnProp[] => {
        const list = SwitchItems.map((el, i) => {
            return {name: el.type, label:el.label} as BtnProp
        })
        return list
    }

    if (props.data === undefined) {
        return (<div className={style.content}>{t("msgNotData")}</div>)
    }

    const chartData: ChartData<'bar'> | undefined = (timelineData) ? {
        labels: timelineData.map(el => el.time_label),
        datasets: [
            {
                label: "personStaying",
                data: timelineData.map(el => el.stay_count),
                backgroundColor: (side===CompareSideType.Primary) ? STAY_COLOR : STAY_COLOR_GR,
                borderColor: (side===CompareSideType.Primary) ? STAY_COLOR : STAY_COLOR_GR
            },
            {
                label: "passengers",
                data: timelineData.map(el => el.pass_count),
                backgroundColor: (side===CompareSideType.Primary) ? NOT_STAY_COLOR : NOT_STAY_COLOR_GR,
                borderColor: (side===CompareSideType.Primary) ? NOT_STAY_COLOR : NOT_STAY_COLOR_GR
            }
        ]
    } : undefined
    const thresholdTime = props.request?.threshold
    const addMessageStay = (thresholdTime) ? "（" + t("more", {time: Utils.formatSecToJikan(thresholdTime)}) + "）" : ""
    const addMessagePass = (thresholdTime) ? "（" + t("lessThan", {time: Utils.formatSecToJikan(thresholdTime)}) + "）" : ""
    //const side = props.request ? props.request.side : "0"
    return (
        <div className={style.content}>
            <Select
                options={areaSelectOptions}
                onChange={handleChangeArea}
                className={style.select}
                classNamePrefix={"react-select"}
                value={selectArea}
            />
            <div className={style.head}>
                <div className={style.legend}>
                    <LegendStayPass
                        type={(side === CompareSideType.Primary || side === "0") ? LegendColorType.Primary : LegendColorType.Secondary}
                        addMsgStay={addMessageStay}
                        addMsgPass={addMessagePass}
                    />
                </div>
                <div className={style.dummy}></div>
                <div className={style.switch}>
                    <div className={style.comment}><span className={style.swNote}>{t("msgBeginningWeek")}</span></div>
                    <SeriesSwitcher buttonList={getButtonList()} onClick={e => changeTimeUnit(e)} activeName={timeUnit}/>
                </div>
            </div>
            <div className={style.body}>
                {
                    isLoading ? (
                        <div className={style.spiner}>
                            <TailSpin color="#1E5EFF" width={50} height={50} />
                        </div>
                    ): (
                            <VerticalBarChart chartData={chartData} size={timelineData ? timelineData.length : 0} timeUnit={timeUnit} />                
                    )
                }
            </div>
        </div>
    )
}

interface Props {
    searches: AnalyzeParametersType | undefined
}

export const TimelineGraph: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={TimelineGraphCore} />
    )
}
