import format from "date-fns/format";
import i18n from "i18next";

import { Coord } from "../api/data/core/Coord";
import { Line } from "../api/data/core/Coord";
import { ReqLineDef } from "../api/data/edit/ReqLineDef";
import { ReqCompany, ReqShop } from "../api/data/edit/Req";
import { ResSearchQuery } from "../api/data/analysis/AnalysisRequest";
import { TargetCustomer, TargetCustomerType } from "../api/data/core/Enums";
import { ReqLayout, ResAreaSet, ResLayout, ResLineSet, ResArea } from "../api/data/analysis/FullLayout";
import { ResCompany, ResGroup, ResLayoutSet, ResPartialLayout, ResShop, ResUser } from "../api/data/login/LoginInfo";

import { Array2 } from "../component";
import { QueryLogType } from "../types";
import { CompareSide, AnalysisParameterSetType } from "../types/Analyze";
import { AreaModel, AreaPackModel, CompanyModel, DataSourceModel, DataSourceStatusType, DtSrcStatus, GroupModel, LayoutModel, LineDef, LineDefType, LineModel, LinePackModel, MapModel, ShopModel, UserModel } from "../types/Management";
import { STR_YMD_FORMAT, THRESHOLD_4_COMPANY } from "../constants";
import DateUtil from "./DateUtil";
import Utils from "./Utils";
import { ResultTranscriptionLayoutSet } from "../api/data/edit/ResultModel";


const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

/**
 * yyyyMMddhhmmss000形式の日付データを修正します。
 * @param msec 
 * @param name 
 * @returns 
 */
const fixIrregularDatesEnteredManually = (msec: number, name: string) => {
    const strnum = msec.toString()
    if (strnum.length === 14 || strnum.length === 17) {
        const year = parseInt(strnum.substring(0, 4))
        if (year > 2020 && year < 2100) {
            const month = parseInt(strnum.substring(4, 6))
            const day = parseInt(strnum.substring(6, 8))
            const hour = parseInt(strnum.substring(8, 10))
            const min = parseInt(strnum.substring(10, 12))
            const sec = parseInt(strnum.substring(12, 14))
            const date = new Date(year, month - 1, day, hour, min, sec)
            const ret = date.getTime()
            console.log("It's bigint datetime.", strnum, name)
            if (ret) return ret
        }
    }
    return undefined
}

/**
 * BuyOrNotからTargetCustomerに変換します。
 * @param buynot 
 * @returns 
 */
const buyOrNot2tgCustomer = (buynot: Array2<boolean>): TargetCustomer => {
    if (buynot[0] && buynot[1]) {
        return TargetCustomerType.All
    } else if (buynot[0]) {
        return TargetCustomerType.Buying
    } else if (buynot[1]) {
        return TargetCustomerType.NotBuying
    } else {
		const msg = i18n.t("msgUnknownBuyOrNot", {buyOrNot: buynot})
        throw new Error(msg)
    }
}

/**
 * TargetCustomerからBuyOrNotに変換します。
 * @param target 
 * @returns 
 */
const tgCustomer2BuyOrNot = (target: TargetCustomer): Array2<boolean> => {
    if (target === TargetCustomerType.All) {
        return [true, true]
    } else if (target === TargetCustomerType.Buying) {
        return [true, false]
    } else if (target === TargetCustomerType.NotBuying) {
        return [false, true]
    } else {
        console.error("Unknown target:", target)
        return [true, true]
    }
}

/**
 * ResSearchQueryからAnalysisParameterSetに変換します。
 * @param query 
 * @param side 
 * @returns 
 */
export const resSearchQuery2AnalysisParamSet = (query: ResSearchQuery, side: CompareSide): AnalysisParameterSetType => {
    const paramset: AnalysisParameterSetType = {
        shopId: query.shop_id,
        layoutId: query.layout_id,
        startDate: query.start_date,   // format "yyyy-MM-dd"
        endDate: query.end_date,
        startTime: query.start_time[0] * 100 + query.start_time[1],   // value = hh * 100 + mm
        endTime: query.end_time[0] * 100 + query.end_time[1],
        weekday: DateUtil.weekObject2Array(query.target_weekdays),        // 0:sun,1:mon,2:tue ...
        buyOrNot: tgCustomer2BuyOrNot(query.target_customer),
        excludeClerk: query.remove_clerk,
        threshold: query.stay_threshold,
        side: side,
        valid: true          // ヴァリデーションチェックの結果 true=OK
    }
    return paramset
}

/**
 * AnalysisParameterSetからResSearchQueryに変換します。
 * @param paramset 
 * @returns 
 */
export const analysisParamSet2ResSearchQuery = (paramset: QueryLogType): ResSearchQuery => {
    const shop_id = paramset.query.shopId ? paramset.query.shopId : 0
    const layout_id = paramset.query.layoutId ? paramset.query.layoutId : 0
    const start_date = paramset.query.startDate ? paramset.query.startDate : ""
    const end_date = paramset.query.endDate ? paramset.query.endDate : ""
    const query: ResSearchQuery = {
        shop_id: shop_id,
        layout_id: layout_id,
        start_date: start_date,
        end_date: end_date,
        start_time: DateUtil.dummyTime2Array(paramset.query.startTime),
        end_time: DateUtil.dummyTime2Array(paramset.query.endTime),
        target_weekdays: DateUtil.weekArray2Object(paramset.query.weekday),
        target_customer: buyOrNot2tgCustomer(paramset.query.buyOrNot),
        remove_clerk: paramset.query.excludeClerk,
        stay_threshold: paramset.query.threshold,
        query_log_id: paramset.id,
        query_saved_at: paramset.insertAt,
    }
    return query
}

/**
 * UserModelからResUserに変換します。
 * ※ query_logは無視しています。
 * @param user 
 * @returns 
 */
export const UserModel2Res = (user: UserModel) => {
    const res: ResUser = {
        user_id: user.user_id,
        login_id: user.login_id,
        name: user.name,
        user_type_id: user.user_type_id,
        user_type: user.user_type,
        display_order: user.display_order,
        dashboard_order: user.dashboard_order,
        expired_at: user.expired_at,
        query_log: [],
        inserttime: user.created_at,
        updatetime: user.modified_at
    }
    return res
}

/**
 * ResUserからUserModelに変換します。
 * ※ bookmarkは無視しています。
 * @param user 
 * @returns 
 */
export const UserRes2Model = (user: ResUser) => {

    // 手作成のイレギュラーデータ対策
    let ins: number | undefined = fixIrregularDatesEnteredManually(user.inserttime, "登録日'user.inserttime'")
    let upd: number | undefined = fixIrregularDatesEnteredManually(user.updatetime, "更新日'user.updatetime'")

    const mdl: UserModel = {
        user_id: user.user_id,
        login_id: user.login_id,
        name: user.name,
        user_type_id: user.user_type_id,
        user_type: user.user_type,
        display_order: user.display_order,
        dashboard_order: user.dashboard_order,
        expired_at: user.expired_at,
        created_at: ins ? ins : user.inserttime,
        modified_at: upd ? upd : user.updatetime,
        bookmarks: []
    }
    return mdl
}

/**
 * CompanyModelからReqCompany(リクエスト形式)に変換します。
 * @param company 
 * @returns 
 */
export const CompanyModel2Req = (company: CompanyModel) => {
    const st = format(new Date(company.start), STR_YMD_FORMAT)
    const ed = format(new Date(company.end), STR_YMD_FORMAT)
    const req = new ReqCompany(
        company.name, st, ed,
        (company.settings ? company.settings : {}),
        (company.designator ? company.designator : ""),
        company.thresholdtime,
        (company.shopThresholdtime ? company.shopThresholdtime : THRESHOLD_4_COMPANY))
    return req
}

/**
 * CompanyModelからResCompanyに変換します。
 * @param company 
 * @returns 
 */
export const CompanyModel2Res = (company: CompanyModel) => {
    const res: ResCompany = {
        company_id: company.company_id,
        name: company.name,
        start: company.start,
        end: company.end,
        settings: company.settings ? company.settings : {},
        all_dashboard_threshold: company.thresholdtime,
        shop_dashboard_threshold: company.shopThresholdtime ? company.shopThresholdtime : THRESHOLD_4_COMPANY,
        company_designator: company.designator ? company.designator : "",
        inserttime: company.created_at,
        updatetime: company.modified_at
    }
    return res
}

/**
 * ResCompanyからCompanyModelに変換します。
 * @param company 
 * @returns 
 */
export const CompanyRes2Model = (company: ResCompany) => {
    
    // 手作成のイレギュラーデータ対策
    if (typeof company.inserttime !== "number") console.log("★ inserttimeがnumberではありません")
    let ins: number | undefined = fixIrregularDatesEnteredManually(company.inserttime, "登録日'company.inserttime'")
    let upd: number | undefined = fixIrregularDatesEnteredManually(company.updatetime, "更新日'company.updatetime'")

    const model: CompanyModel = {
        company_id: company.company_id,
        name: company.name,
        start: company.start,
        end: company.end,
        thresholdtime: company.all_dashboard_threshold,
        shopThresholdtime: company.shop_dashboard_threshold,
        designator: company.company_designator,
        settings: company.settings,
        created_at: (ins===undefined) ? company.inserttime : ins,
        modified_at: (upd===undefined) ? company.updatetime : upd,
    }
    return model
}

/**
 * ResCompany[] から CompanyModel[] に変換します。
 * @param list 
 * @returns 
 */
export const CompanyAryRes2Model = (list: ResCompany[]) => {
    return list.map(el => CompanyRes2Model(el))
}

/**
 * ShopModelからReqShop（リクエスト形式）に変換します。
 * @param shop 
 * @returns 
 */
export const ShopModel2Req = (shop: ShopModel) => {
    const st = format(new Date(shop.start), STR_YMD_FORMAT)
    const hot = shop.datasource_list.find(el => el.status === DataSourceStatusType.Active)
    const id = (hot) ? hot.layout_set_id : 0
    if (id===0) console.error("ShopModel2Req ★ hot_layout_set_id 設定できていません")
    const req = new ReqShop(shop.name, st, shop.timezone, (shop.designator ? shop.designator : ""), id)
    return req
}

/**
 * ShopModelからResShopに変換します。
 * @param shop 
 * @returns 
 */
export const ShopModel2Res = (shop: ShopModel) => {
    const hot = shop.datasource_list.find(el => el.status === DataSourceStatusType.Active)
    const hotId = (hot) ? hot.layout_set_id : 0
    const resHot: ResLayoutSet = {
        layout_set_id: hotId,
        name: (hot) ? hot.name : "",
        layout: (hot) ? hot.layout_list.map(el => LayoutModel2PartRes(el)) : [],
        is_ready: false
    }
    const coldList: ResLayoutSet[] = shop.datasource_list.filter(el => el.layout_set_id !== hotId).map(el => {
        const laySet: ResLayoutSet = {
            layout_set_id: el.layout_set_id,
            name: el.name,
            layout: el.layout_list.map(el => LayoutModel2PartRes(el)),
            is_ready: el.status===DataSourceStatusType.Ready
        }
        return laySet
    })
    const res: ResShop = {
        shop_id: shop.shop_id,
        name: shop.name,
        start: shop.created_at,
        timezone: shop.timezone,
        shop_designator: shop.designator ? shop.designator : "",
        display_order: shop.displayOrder ? shop.displayOrder : 0,
        hot_layout_set: resHot,
        cold_layout_set: coldList,
        inserttime: shop.created_at,
        updatetime: shop.modified_at
    }
    return res
}

/**
 * ResShopからShopModelに変換します。
 * @param shop 
 * @returns 
 */
export const ShopRes2Model = async (shop: ResShop) => {
    const list: DataSourceModel[] = []
    if (shop.hot_layout_set) {
        const hModel = await LayoutSet2DataSourceModel(shop.shop_id, shop.hot_layout_set, true, shop.hot_layout_set.is_ready)
        if (hModel) list.push(hModel)
    }
    if (shop.cold_layout_set) {
        for await (let ls of shop.cold_layout_set) {
            console.log("Cold", ls)
            const model = await LayoutSet2DataSourceModel(shop.shop_id, ls, false, ls.is_ready)
            console.log(model)
            if (model) list.push(model)
        }
    }
    // 手作成のイレギュラーデータ対策
    let ins: number | undefined = fixIrregularDatesEnteredManually(shop.inserttime, "登録日'shop.inserttime'")
    let upd: number | undefined = fixIrregularDatesEnteredManually(shop.updatetime, "更新日'shop.updatetime'")

    const model: ShopModel = {
        shop_id: shop.shop_id,
        name: shop.name,
        start: shop.start,
        timezone: shop.timezone,
        designator: shop.shop_designator,
        displayOrder: shop.display_order,
        created_at: ins ? ins : shop.inserttime,
        modified_at: upd ? upd : shop.updatetime,
        datasource_list: list,
        company_id: shop.company_id ? shop.company_id : undefined
    }
    return model
}

/**
 * ResShop[] から ShopModel[] に変換します。
 * @param list 
 * @returns 
 */
export const ShopAryRes2Model = async (list: ResShop[], companyId: number) => {
    const filteredList = list.filter(el => el.company_id ? (el.company_id === companyId) : true)
    let res = []
    for await (let shop of filteredList) {
        const model = await ShopRes2Model(shop)
        res.push(model)
    }
    return res
}

/**
 * レイアウトセットをデータソースモデルに変換します。
 * @param shopId 
 * @param layoutSet 
 * @param isHot 
 * @returns 
 */
export const LayoutSet2DataSourceModel = async (shopId: number, layoutSet: ResLayoutSet, isHot: boolean, isReady: boolean) => {
    const st: DtSrcStatus = (isHot) ? DataSourceStatusType.Active : (isReady ? DataSourceStatusType.Ready : DataSourceStatusType.Draft)
    console.log("LayoutSet2DataSourceModel", layoutSet, isHot, isReady, st)
    let layoutList: LayoutModel[] = []
    if (layoutSet.layout && layoutSet.layout.length > 0) {
        let tmpList: LayoutModel[] = []
        for await (let lay of layoutSet.layout) {
            const aLay = await PartLayoutRes2Model(lay)
            tmpList.push(aLay)
        }
        layoutList = tmpList.sort((a, b) => (a.start < b.start) ? 1 : -1)
        //const layoutList = layoutSet.layout.map(el => PartLayoutRes2Model(el)).sort((a, b) => (a.start < b.start) ? 1 : -1)
    } else {
        // 店舗が登録された直後のケース（マップが未登録状態）
        if (isHot) {
            const map: MapModel = {
                image: "",
                pixel_width: 0,
                pixel_height: 0,
                origin_x: 0,
                origin_y: 0,
                mm_per_pixel: 0.0,
                area_unitcell_pixel: 0,
                area_packs: [],
                line_packs: [],
                image_modified: false
            }
            const lay: LayoutModel = {
                layout_id: 0,
                name: "",
                start: 0,
                end: 0,
                mapping: map,
                created_at: 0,
                modified_at: 0
            }
            layoutList.push(lay)
        } else {
            console.log("LayoutSet2DataSourceModel: レイアウトが空です。", layoutSet)
        }
    }
    const model: DataSourceModel = {
        shop_id: shopId,
        layout_set_id: layoutSet.layout_set_id,
        name: layoutSet.name,
        layout_list: layoutList,
        status: st,
        isFull: false
    }
    return model

}

/**
 * データソースモデルからレイアウトセット（部分的レイアウト）に変換します。
 * @param model 
 * @returns 
 */
export const DataSourceModel2ResLayoutSet = (model: DataSourceModel): ResLayoutSet => {
    let list: ResPartialLayout[]
    if (model.layout_list.length > 0) {
        list = model.layout_list.map(el => {
            const lay: ResPartialLayout = {
                layout_id: el.layout_id,
                name: el.name,
                start: el.start,
                end: el.end,
                inserttime: el.created_at,
                updatetime: el.modified_at
            }
            return lay
        })
    } else {
        // レイアウトが空の場合
        const lay: ResPartialLayout = {
            layout_id: 0,
            name: "",
            start: 0,
            end: 0,
            inserttime: 0,
            updatetime: 0
        }
        list = [lay]
    }
    const res: ResLayoutSet = {
        layout_set_id: model.layout_set_id,
        name: model.name,
        layout: list,
        is_ready: false
    }
    return res
}

/**
 * 部分的なレイアウトからレイアウトモデルに変換します。（平面図情報はなし）
 * @param layout 
 * @returns 
 */
export const PartLayoutRes2Model = async (layout: ResPartialLayout): Promise<LayoutModel> => {
    const model: LayoutModel = {
        layout_id: layout.layout_id,
        name: layout.name,
        start: layout.start,
        end: layout.end,
        mapping: undefined,
        created_at: layout.inserttime,
        modified_at: layout.updatetime
    }
    return model
}

/**
 * 完全レイアウトから平面図モデルに変換します。
 * @param layout 
 * @returns 
 */
export const LayoutRes2MapModel = (layout: ResLayout): MapModel => {
    const areaPacks = (layout.area_set) ? layout.area_set.map(el => AreaSet2PackModel(el)) : []
    const linePacks = (layout.line_set) ? layout.line_set.map(el => LineSet2PackModel(el)) : []
    const map: MapModel = {
        //startYmd: layout.start,
        image: layout.image,
        pixel_width: layout.pixel_width,
        pixel_height: layout.pixel_height,
        origin_x: Math.round(layout.origin_x),
        origin_y: Math.round(layout.origin_y),
        mm_per_pixel: Math.round(layout.mm_per_pixel * 100000) / 100000,
        area_unitcell_pixel: Math.round(layout.area_unitcell_pixel),
        area_packs: areaPacks,
        line_packs: linePacks,
        image_modified: false
    }
    return map
}

/**
 * 完全レイアウトからレイアウトモデルに変換します。
 * ※ ただし、endは0となります。
 * @param layout 
 * @returns 
 */
export const FullLayoutRes2Model = (layout: ResLayout): LayoutModel => {
    const map = LayoutRes2MapModel(layout)
    const model: LayoutModel = {
        layout_id: layout.layout_id,
        name: layout.name,
        start: layout.start,
        end: 0,
        mapping: map,
        created_at: layout.inserttime ? layout.inserttime : 0,
        modified_at: layout.updatetime ? layout.updatetime : 0
    }
    return model
}

/**
 * レイアウトモデルから完全レイアウトに変換します。
 * @param model 
 * @returns 
 */
export const LayoutModel2Res = (model: LayoutModel) => {
    const areaSet = model.mapping ? model.mapping.area_packs.map(el => AreaPackModel2ResSet(el)) : []
    const lineSet = model.mapping ? model.mapping.line_packs.map(el => LinePackModel2ResSet(el)) : []
    const res: ResLayout = {
        layout_id: model.layout_id,
        name: model.name,
        start: model.start,
        image: model.mapping ? model.mapping.image : "",
        pixel_width: model.mapping ? model.mapping.pixel_width : 0,
        pixel_height: model.mapping ? model.mapping.pixel_height : 0,
        origin_x: model.mapping ? Math.round(model.mapping.origin_x) : 0,
        origin_y: model.mapping ? Math.round(model.mapping.origin_y) : 0,
        mm_per_pixel: model.mapping ? Math.round(model.mapping.mm_per_pixel * 100000)/100000 : 10,
        area_unitcell_pixel: model.mapping ? Math.round(model.mapping.area_unitcell_pixel) : 0,
        area_set: areaSet,
        line_set: lineSet
    }
    return res
}

export const LayoutModel2PartRes = (model: LayoutModel) => {
    const res: ResPartialLayout = {
        layout_id: model.layout_id,
        name: model.name,
        start: model.start,
        end: model.end,
        inserttime: model.created_at,
        updatetime: model.modified_at
    }
    return res
}

/**
 * レイアウトモデルからリクエスト用データを作成します。
 * @param model 
 * @returns 
 */
export const LayoutModel2Req = async (model: LayoutModel) => {
    if (model.mapping && model.mapping.image_data) {
        //onst img = (mockOn) ? model.mapping.image.replace("http://localhost:3000/", "./") : model.mapping.image
        let img = ""
        if (mockOn) {
            // ローカル開発環境の場合
            img = model.mapping.image.replace("http://localhost:3000/", "./")
        } else {
            if (Utils.hasUUID(model.mapping.image)) {
                // UUIDが含まれている場合はそのまま
                img = model.mapping.image
            } else {
                // UUIDを生成してファイル名に追加変更
                const fileParts = Utils.separateFileNameAndExtension(model.mapping.image)
                const uuid = await Utils.generateUuid()
                img = fileParts[0] + "." + uuid + "." + fileParts[1]
            }
        }
        const req: ReqLayout = {
            layout_id: model.layout_id,
            name: model.name,
            start: model.start,
            image: img,
            pixel_width: model.mapping.pixel_width,
            pixel_height: model.mapping.pixel_height,
            origin_x: Math.round(model.mapping.origin_x),
            origin_y: Math.round(model.mapping.origin_y),
            mm_per_pixel: Math.round(model.mapping.mm_per_pixel * 100000) / 100000,
            area_unitcell_pixel: Math.round(model.mapping.area_unitcell_pixel),
            area_set: model.mapping.area_packs.map(el => AreaPackModel2ResSet(el)),
            line_set: model.mapping.line_packs.map(el => LinePackModel2ResSet(el)),
            image_data: model.mapping.image_modified ? model.mapping.image_data : ""
        }
        return req
    } else {
        console.error(model)
        throw new Error("レイアウトの平面図情報が不足しています.")
    }
}

/**
 * エリアセットからエリアパックモデルに変換します。
 * @param areaSet 
 * @returns 
 */
export const AreaSet2PackModel = (areaSet: ResAreaSet): AreaPackModel => {
    const model: AreaPackModel = {
        area_set_id: areaSet.area_set_id,
        startYmd: areaSet.start,
        area_list: areaSet.area_defs,
        created_at: areaSet.inserttime,
        modified_at: areaSet.updatetime
    }
    return model
}

/**
 * エリアパックモデルからエリアセットに変換します。
 * @param model
 * @returns 
 */
export const AreaPackModel2ResSet = (model: AreaPackModel) => {
    const res: ResAreaSet = {
        area_set_id: model.area_set_id,
    	start: model.startYmd,
    	area_defs: model.area_list,
    	inserttime: model.created_at,
    	updatetime: model.modified_at
    }
    return res
}

export const Coord2Array2 = (cd: Coord): Array2<number> => {
    return [cd.x, cd.y]
}

/**
 * エリアセットの作成・更新API用のリクエストデータに変換します。(area_idなし)
 * @param areaModel 
 * @returns 
 * /
export const AreaModel2ReqAreaDef = (areaModel: AreaModel): ReqAreaDef => {
    const cellXYs: Array2<number>[] = areaModel.cell_ids.map(el => Coord2Array2(el))
    return new ReqAreaDef(
        areaModel.area_number,
        areaModel.name,
        areaModel.area_type,
        areaModel.group_id,
        cellXYs
    )
}

/ **
 * エリアパックを作成・更新APIに必要なリクエストデータ形式に変換します。（AreaModelのarea_idは無視される）
 * @param model 
 * @returns 
 * /
export const AreaModelList2ReqAreaDefs = (model: AreaPackModel): ReqAreaDef[] => {
    return model.area_list.map(el => AreaModel2ReqAreaDef(el))
}*/

export const AreaModel2ResArea = (model: AreaModel): ResArea => {
    return {
        area_id: model.area_id,
        area_number: model.area_number,
        name: model.name,
        area_type: model.area_type,
        group_id: model.group_id,
        cell_ids: model.cell_ids
    } as ResArea
}

export const AreaPackModel2ResAreaSet = (model: AreaPackModel): ResAreaSet => {
    const res: ResAreaSet = {
        area_set_id: model.area_set_id,
        start: model.startYmd,
        area_defs: model.area_list.map(el => AreaModel2ResArea(el)),
        inserttime: model.created_at,
        updatetime: model.modified_at
    }
    return res
}

export const ResArea2AreaModel = (res: ResArea): AreaModel => {
    const model: AreaModel = {
        area_id: res.area_id,
        area_number: res.area_number,
        name: res.name,
        area_type: res.area_type,
        group_id: res.group_id,
        cell_ids: res.cell_ids
    }
    return model
}

export const ResAreaSet2AreaPackModel = (res: ResAreaSet): AreaPackModel => {
    const model: AreaPackModel = {
        area_set_id: res.area_set_id,
        startYmd: res.start,
        area_list: res.area_defs.map(el => ResArea2AreaModel(el)),
        created_at: res.inserttime,
        modified_at: res.updatetime
    }
    return model
}

export const convertLineObject2LineModel = (id: number, idx: number, type: LineDef, line: Line) => {
    const model: LineModel = {
        id: id + idx,
        type: type,
        right: { x: line.right.x, y: line.right.y },
        left: { x: line.left.x, y: line.left.y }
    }
    return model
}

/**
 * ラインセットからラインパックモデルに変換します。
 * ※内部で特定ラインを編集しやすいように適当なIDを割り当てている。
 * @param lineSet 
 * @returns 
 */
export const LineSet2PackModel = (lineSet: ResLineSet): LinePackModel => {
    let id = 1000
    const inList = (lineSet.enter_lines && lineSet.enter_lines.length > 0) ? lineSet.enter_lines.map((el, idx) => {
        return convertLineObject2LineModel(id, idx, LineDefType.Enter, el)
    }) : []
    id = 2000
    const outList = (lineSet.exit_lines && lineSet.exit_lines.length > 0) ? lineSet.exit_lines.map((el, idx) => {
        return convertLineObject2LineModel(id, idx, LineDefType.Exit, el)
    }) : []
    id = 3000
    const pasList = (lineSet.pass_lines && lineSet.pass_lines.length > 0) ? lineSet.pass_lines.map((el, idx) => {
        return convertLineObject2LineModel(id, idx, LineDefType.Pass, el)
    }) : []
    const model: LinePackModel = {
        line_set_id: lineSet.line_set_id,
        startYmd: lineSet.start,
        lines: [...inList, ...outList, ...pasList]
    }
    return model
}

export const convertLineModel2LineObject = (model: LineModel) => {
    const right = new Coord(model.right.x, model.right.y)
    const left = new Coord(model.left.x, model.left.y)
    const line = new Line(left, right)
    return line
}

/**
 * ラインパックモデルからラインセットに変換します。
 * @param model 
 * @returns 
 */
export const LinePackModel2ResSet = (model: LinePackModel) => {
    const enters = model.lines.filter(el => el.type === LineDefType.Enter).map(el => {
        return convertLineModel2LineObject(el)
    })
    const exits = model.lines.filter(el => el.type === LineDefType.Exit).map(el => {
        return convertLineModel2LineObject(el)
    })
    const passes = model.lines.filter(el => el.type === LineDefType.Pass).map(el => {
        return convertLineModel2LineObject(el)
    })
    const res: ResLineSet = {
        line_set_id: model.line_set_id,
        start: model.startYmd,
        enter_lines: enters,
        exit_lines: exits,
        pass_lines: passes,
        inserttime: 0,
        updatetime: 0
    }
    return res
}

/**
 * ラインパックモデルから作成・更新に利用するリクエスト形式データを生成します。
 * @param model 
 * @returns 
 */
export const LineModel2ReqLineDef = (model: LinePackModel): ReqLineDef => {
    const enters = model.lines.filter(el => el.type === LineDefType.Enter).map(el => {
        return convertLineModel2LineObject(el)
    })
    const exits = model.lines.filter(el => el.type === LineDefType.Exit).map(el => {
        return convertLineModel2LineObject(el)
    })
    const passes = model.lines.filter(el => el.type === LineDefType.Pass).map(el => {
        return convertLineModel2LineObject(el)
    })
    const req: ReqLineDef = {
        enter: enters,
        exit: exits,
        pass: passes
    }
    return req
}

export const GroupModel2Res = (model: GroupModel) => {
    const res: ResGroup = {
        group_id: model.group_id,
        name: model.name,
        display_order: model.display_order,
        deleted_at: model.delete_at
    }
    return res
}

export const GroupRes2Model = (res: ResGroup) => {
    const model: GroupModel = {
        group_id: res.group_id,
        name: res.name,
        display_order: res.display_order,
        delete_at: res.deleted_at
    }
    return model
}

export const Transcription2Model = async (transcription: ResultTranscriptionLayoutSet) => {
    const ds: DataSourceModel = {
        shop_id: transcription.layout_set.shop_id,
        layout_set_id: transcription.layout_set.layout_set_id,
        name: transcription.layout_set.name,
        layout_list: [],
        status: DataSourceStatusType.Draft,
        isFull: true
    }
    for await (let lay of transcription.layouts) {
        const map: MapModel = {
            image: lay.image,
            pixel_width: lay.pixel_width,
            pixel_height: lay.pixel_height,
            origin_x: lay.origin_x,
            origin_y: lay.origin_y,
            mm_per_pixel: lay.mm_per_pixel,
            area_unitcell_pixel: lay.area_unitcell_pixel,
            area_packs: [],
            line_packs: [],
            image_modified: false
        }
        const layout: LayoutModel = {
            layout_id: lay.layout_id,
            name: lay.name,
            start: new Date(lay.start).getTime(),
            end: 0,
            mapping: map,
            created_at: lay.inserttime,
            modified_at: lay.updatetime
        }
        ds.layout_list.push(layout)
    }
    for await (let as of transcription.area_sets) {
        const id = as.layout_id
        const areaPack: AreaPackModel = {
            area_set_id: as.area_set_id,
            startYmd: as.start,
            area_list: [],
            created_at: as.inserttime,
            modified_at: as.updatetime
        }
        for await (let area of as.area_defs) {
            const areaModel: AreaModel = {
                area_id: area.area_id,
                area_number: area.area_number,
                name: area.name,
                area_type: area.area_type,
                group_id: area.group_id,
                cell_ids: []
            }
            for await (let [x, y, w] of area.cell_ids) {
                for (let i = 0; i < w; i++) {
                    areaModel.cell_ids.push(new Coord(x + i, y))
                }
            }
            areaPack.area_list.push(areaModel)
        }
        const layout = ds.layout_list.find(el => el.layout_id === id)
        if (layout && layout.mapping) {
           layout.mapping.area_packs.push(areaPack) 
        }
    }
    for await (let ls of transcription.line_sets) {
        const id = ls.layout_id
        const linePack: LinePackModel = {
            line_set_id: ls.line_set_id,
            startYmd: ls.start,
            lines: [],
            layoutId: id
        }
        let i = 0
        for await (let line of ls.line_defs.enter) {
            i++
            const model: LineModel = {
                id: i,
                type: LineDefType.Enter,
                right: { x: line[0].x, y: line[0].y },
                left: { x: line[1].x, y: line[1].y }
            }
            linePack.lines.push(model)
        }
        for await (let line of ls.line_defs.exit) {
            i++
            const model: LineModel = {
                id: i,
                type: LineDefType.Exit,
                right: { x: line[0].x, y: line[0].y },
                left: { x: line[1].x, y: line[1].y }
            }
            linePack.lines.push(model)
        }
        for await (let line of ls.line_defs.pass) {
            i++
            const model: LineModel = {
                id: i,
                type: LineDefType.Pass,
                right: { x: line[0].x, y: line[0].y },
                left: { x: line[1].x, y: line[1].y }
            }
            linePack.lines.push(model)
        }
        const layout = ds.layout_list.find(el => el.layout_id === id)
        if (layout && layout.mapping) {
            layout.mapping.line_packs.push(linePack)
        }
    }
    return ds
}