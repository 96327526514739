import { RoleType, Role } from "../../api/data/core/Enums"
import { ResShop, ResLayoutSet } from "../../api/data/login/LoginInfo"
import { ShopModel2Res } from "../../lib/ModelUtil"
import { ShopModel } from "../../types/Management"
import { areaPackList } from "./AreaPackV4"
import { dataSourceList } from "./DataSourcesV4"
import { linePackList } from "./LinePackV4"

const shopModelList: ShopModel[] = 
    [
        {
            shop_id: 1,
            name: "@cosme TOKYO",
            start: 1633878000000,
            timezone: 540,
            designator: "harajyuku",
            displayOrder: 1,
            created_at: 1614614000000,
            modified_at: 1655614000000,
            datasource_list: [], //getDataSource(101),
            company_id: 1
        },
        {
            shop_id: 21,
            name: "博多店",
            start: 1654614000000,
            timezone: 540,
            designator: "hakata",
            displayOrder: 2,
            created_at: 1650614000000,
            modified_at: 1658614000000,
            datasource_list: [], //getDataSource(201),
            company_id: 1
        },
        {
            shop_id: 22,
            name: "池袋店",
            start: 1577804400000,
            timezone: 540,
            designator: "ikebukuro",
            displayOrder: 2,
            created_at: 1570804400000,
            modified_at: 1579804400000,
            datasource_list: [], //getDataSource(301),
            company_id: 1
        },
        {
            shop_id: 40,
            name: "鬼が島店",
            start: 1577804400000,
            timezone: 540,
            designator: "oni",
            displayOrder: 2,
            created_at: 1577004400000,
            modified_at: 1578804400000,
            datasource_list: [], //getDataSource(40),
            company_id: 3
        }
    ]

export const getShopModelList = () => {
    // DatasourceにareaPackとlinePackを追加
    const cloneDsList = [...dataSourceList]
    for (let ds of cloneDsList) {
        for (let lay of ds.layout_list) {
            const areas = areaPackList.filter(area => area.layoutId === lay.layout_id)
            const lines = linePackList.filter(line => line.layoutId === lay.layout_id)
            if (lay.mapping) {
                lay.mapping.area_packs = areas
                lay.mapping.line_packs = lines
            }
        }
    }
    // shopにdataSourceを追加
    const cloneShopList = [...shopModelList]
    for (let shop of cloneShopList) {
        const dslist = cloneDsList.filter(ds => ds.shop_id === shop.shop_id)
        shop.datasource_list = dslist
    }
    return cloneShopList
}

/*
const layoutSet1: ResLayoutSet = {
    layout_set_id: 1,
    name: "Lset1",
    layout: [
        { layout_id: 1, name: "tokyo1", start: 1633878000000, end: 5633878000000, inserttime: 1633878000000, updatetime: 1633898000000 },
    ],
    is_ready: false
}
const layoutSet10: ResLayoutSet = {
    layout_set_id: 10,
    name: "Lset10",
    layout: [
        { layout_id: 10, name: "tokyo10", start: 1633878000000, end: 5633878000000, inserttime: 1633078000000, updatetime: 1633098000000 },
    ],
    is_ready: false
}
const layoutSet2: ResLayoutSet = {
    layout_set_id: 2,
    name: "Lset2",
    layout: [
        { layout_id: 2, name: "ikebukuro2", start: 1654614000000, end: 5654614000000, inserttime: 1654614000000, updatetime: 1654684000000 },
    ],
    is_ready: false
}
const layoutSet20: ResLayoutSet = {
    layout_set_id: 20,
    name: "Lset20",
    layout: [
        { layout_id: 20, name: "ikebukuro20", start: 1654614000000, end: 5654614000000, inserttime: 1654014000000, updatetime: 1654084000000 },
    ],
    is_ready: false
}
const layoutSet3: ResLayoutSet = {
    layout_set_id: 3,
    name: "Lset3",
    layout: [
        { layout_id: 3, name: "hakata3", start: 1654622000000, end: 5654614000000, inserttime: 1654622000000, updatetime: 1654625000000 },
    ],
    is_ready: false
}
const layoutSet30: ResLayoutSet = {
    layout_set_id: 31,
    name: "Lset31",
    layout: [
        { layout_id: 31, name: "hakata31", start: 1654622000000, end: 5654614000000, inserttime: 1654622000000, updatetime: 1655625000000 },
    ],
    is_ready: false
}
const layoutSet40: ResLayoutSet = {
    layout_set_id: 40,
    name: "（未登録）",
    layout: [],
    is_ready: false
}

const harajyuku: ResShop = {
    shop_id: 1,
    name: "@cosme TOKYO",
    start: 1633878000000,
    timezone: 540,
    shop_designator: "",
    display_order: 1, inserttime: 0, updatetime: 0,
    hot_layout_set: layoutSet1,
    cold_layout_set: [layoutSet10],
}

const hakata: ResShop = {
    shop_id: 21,
    name: "博多店",
    start: 1654614000000,
    timezone: 540,
    shop_designator: "",
    display_order: 2, inserttime: 0, updatetime: 0,
    hot_layout_set: layoutSet30,
    cold_layout_set: [layoutSet3],
}

const ikebukuro: ResShop = {
    shop_id: 22,
    name: "池袋店",
    start: 1577804400000,
    timezone: 540,
    shop_designator: "",
    display_order: 2, inserttime: 0, updatetime: 0,
    hot_layout_set: layoutSet2,
    cold_layout_set: [layoutSet20],
}

const oniga: ResShop = {
    shop_id: 40,
    name: "鬼が島店",
    start: 1577804400000,
    timezone: 540,
    shop_designator: "",
    display_order: 2, inserttime: 0, updatetime: 0,
    hot_layout_set: layoutSet2,
    cold_layout_set: [],
}
*/

export const getShopName = (shopId: number): string => {
    /*if (shopId === 1) {
        return "@cosme TOKYO"
    } else if (shopId === 21) {
        return "博多店"
    } else if (shopId === 22) {
        return "池袋店"
    } else if (shopId === 40) {
        return "鬼が島店"    
    } else {
        return ""
    }*/
    const shop = shopModelList.find(shop => shop.shop_id === shopId)
    if (shop) return shop.name
    return ""
}

/*export const getShops = (): ResShop[] => {
    return [harajyuku, hakata, ikebukuro]
}*/

export const getShopsBy = (type: Role): ResShop[] => {
    if (type === RoleType.Root) {
        //return [oniga]
        return shopModelList.map(shop => ShopModel2Res(shop))
    } else if (type === RoleType.Developer || type === RoleType.HeadOffice || type === RoleType.AreaManager || type === RoleType.StoreManager) {
        return shopModelList.filter(shop => shop.company_id === 1).map(shop => ShopModel2Res(shop))
        //return [harajyuku, hakata, ikebukuro]
    //} else if () {
        //return shopModelList.filter(shop => (shop.shop_id === 1 || shop.shop_id === 22)).map(shop => ShopModel2Res(shop))
        //return [harajyuku, ikebukuro]
    } else {
        //return [ikebukuro]
        return shopModelList.filter(shop => shop.shop_id === 22).map(shop => ShopModel2Res(shop))
    }
}

//export const getShopWith = (company_id: number): ResShop[] => {
    /*if (company_id === 1) {
        return [harajyuku, hakata, ikebukuro]
    } else if (company_id === 3) {
        return [oniga]
    } else {
        return []
    }*/
    //return shopModelList.filter(shop => shop.company_id === company_id).map(shop => ShopModel2Res(shop))
//}

