import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { DataSourceStatusType, EditModeType, LayoutModel, MapModel, ShopModel, UrlParameterType } from "../../types/Management"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { ModalFrame } from "../common/ModalFrame"
import { FileUpload, FileUploadIconType } from "../../component/file_upload/FileUpload"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { MapReplaceDialog } from "../../component/map_replace_dialog/MapReplaceDialog"
import { TrackingPageView } from "../common/TrackingPageView"
import { useMouseMove } from "../../lib/useMouseMove"
import LayoutEditPicWriter from "../../lib/LayoutEditPicWriter"
import Utils from "../../lib/Utils"

import style from "./LayoutEdit.module.css"

const MAP_WIDTH = 550
const MAP_HEIGHT = 440

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {
}

type EditItemType = {
    name: string
    start: number
    width: number
    height: number
    originX: number
    originY: number
    mmPerPx: number
    cellSize: number
    fileName: string
}

export const LayoutEdit: React.FC<Props> = (props) => {
	const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    
    const { shopId, shopList, dataSourceId, dataSourceList, saveLayout } = useManagementDataContext()

    const { ableToCreate, ableToUpdate } = useAccessControlContext()

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    
    const [writer, setWriter] = useState<LayoutEditPicWriter | undefined>(undefined)
    const [layoutName, setLayoutName] = useState<string>("")
    const [startDateNum, setStartDateNum] = useState<number | undefined>(undefined)
    const [imgWidth, setImgWidth] = useState<string>("-")
    const [imgHeight, setImgHeight] = useState<string>("-")
    const [originX, setOriginX] = useState<string>("-")
    const [originY, setOriginY] = useState<string>("-")
    const [mmPerPx, setMmPerPx] = useState<string>("-")
    const [cellSize, setCellSize] = useState<string>("-")
    const [file, setFile] = useState<File | undefined>(undefined)
    const [fileName, setFileName] = useState<string>("")
    const [cellView, setCellView] = useState<boolean>(false)
    const [errLayoutName, setErrLayoutName] = useState<string>("")
    //const [errFileName, setErrFileName] = useState<string>("")
    const [errOrigin, setErrOrigin] = useState<string>("")
    const [errScale, setErrScale] = useState<string>("")
    const [errCellSize, setErrCellSize] = useState<string>("")
    const [prevData, setPrevData] = useState<EditItemType | undefined>(undefined)
    const refLeft = useRef<HTMLCanvasElement>(null)
    const refCell = useRef<HTMLCanvasElement>(null)
    const refAnim = useRef<HTMLCanvasElement>(null)
    const refDpk = useRef<HTMLDivElement>(null)

    // パラメーター
    const location = useLocation()
    const search = location.search
    const state = location.state
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => {
        const m = queryParams.get(UrlParameterType.Mode)
        if (m) return m
        return state ? state.Mode : undefined
    }, [queryParams, state])
    const layoutSetId = useMemo(() => {
        const lsi = queryParams.get(UrlParameterType.LayoutSetId)
        if (lsi) return lsi
        return state ? state.LayoutSetId : undefined
    }, [queryParams, state])
    const layoutId = useMemo(() => {
        const li = queryParams.get(UrlParameterType.LayoutId)
        if (li) return li
        return state ? state.LayoutId : undefined
    }, [queryParams, state])

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.BsInfLayout_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.BsInfLayout_New) }, [ableToUpdate])

    /**
     * データソースの状態がActiveかどうか
     */
    const isHotLayout = useMemo(() => {
        if (dataSourceId && dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === dataSourceId)
            if (ds) return (ds.status === DataSourceStatusType.Active)
        }
        return false
    }, [dataSourceId, dataSourceList])

    /**
     * 入力項目の編集を抑制するフラグ
     */
    const disableEdit = useMemo(() => {
        return (mode === EditModeType.View || isHotLayout) ? true : false
    }, [mode, isHotLayout])

    /**
     * ファイル名の編集を抑制するフラグ
     * （新規モードのときと、編集モードかつfileがアップロードされたときに編集可能とする）
     * /
    const disableFileNameEdit = useMemo(() => {
        return (mode === EditModeType.New || (mode === EditModeType.Edit && file)) ? false : true
    }, [mode, file])*/

    /**
     * 店舗情報
     */
    const shop: ShopModel | undefined = useMemo(() => {
        if (shopId && shopList) {
            const s = shopList.find(el => el.shop_id === shopId)
            if (s) return s
        }
        return undefined
    }, [shopId, shopList])

    /**
     * レイアウト情報
     */
    const layout: LayoutModel | undefined = useMemo(() => {
        if (shop) {
            if (layoutId) {
                const id = parseInt(layoutId)
                for (let ds of shop.datasource_list) {
                    for (let lay of ds.layout_list) {
                        if (lay.layout_id === id) return lay
                    }
                }
            }
        }
        return undefined
    }, [shop, layoutId])

    /**
     * 登録済み平面図のファイル名リスト
     * /
    const usedFileNameList = useMemo(() => {
        const result: string[] = []
        if (shop && layoutId) {
            const id = parseInt(layoutId)
            console.log("usedFileNameList id:",id)
            for (let ds of shop.datasource_list) {
                for (let lay of ds.layout_list) {
                    if (lay.layout_id !== id) {
                        console.log("lay.layout_id:", lay.layout_id)
                        if (lay.mapping) {
                            const fAry = lay.mapping.image.split("/")
                            if (fAry.length > 0) {
                                const f = fAry[fAry.length - 1]
                                result.push(f)
                            }
                        }
                    }
                }
            }
        }
        return result
    }, [shop, layoutId])*/

    /**
     * 同じデータソース内の各レイアウトの開始日のリスト
     */
    const excludeDates = useMemo(() => {
        const result: Date[] = []
        if (shop) {
            const lsid = (layoutSetId) ? parseInt(layoutSetId) : 0
            for (let ds of shop.datasource_list) {
                if (ds.layout_set_id === lsid) {
                    const id = (layoutId) ? parseInt(layoutId) : 0
                    for (let lay of ds.layout_list) {
                        if (lay.layout_id !== id) {
                            const dt = new Date(lay.start)
                            //console.log("startDate:", dt)
                            result.push(dt)
                        }
                    }
                }
            }
        }
        return result
    }, [shop, layoutSetId, layoutId])

    /**
     * 開始日
     */
    const startDate: Date | undefined = useMemo(() => {
        if (startDateNum) {
            const dt = new Date(startDateNum)
            if (dt) return dt
        }
    }, [startDateNum])

    /**
     * 原点位置X（数値）
     */
    const originXNum = useMemo(() => {
        if (originX) {
            try {
                return parseInt(originX)
            } catch (e) {
                console.error(e)
            }
        }
        return 0
    }, [originX])

    /**
     * 原点位置Y（数値）
     */
    const originYNum = useMemo(() => {
        if (originY) {
            try {
                return parseInt(originY)
            } catch (e) {
                console.error(e)
            }
        }
        return 0
    }, [originY])

    /**
     * 縮尺（数値）
     */
    const mmPerPxNum = useMemo(() => {
        if (mmPerPx) {
            try {
                return parseFloat(mmPerPx)
            } catch (e) {
                console.error(e)
            }
        }
        return 0
    }, [mmPerPx])

    /**
     * セルサイズ（数値）
     */
    const cellSizeNum = useMemo(() => {
        if (cellSize) {
            try {
                return parseFloat(cellSize)
            } catch (e) {
                console.error(e)
            }
        }
        return 1
    }, [cellSize])

    /**
     * 項目に修正が入ったかどうか検査します
     */
    const isModified = useMemo(() => {
        if (mode === EditModeType.Edit && prevData) {
            if (prevData.name !== layoutName) return true
            if (prevData.fileName !== fileName) return true
            if (prevData.start !== startDateNum) return true
            if (prevData.width.toString() !== imgWidth) return true
            if (prevData.height.toString() !== imgHeight) return true
            if (prevData.originX !== originXNum) return true
            if (prevData.originY !== originYNum) return true
            if (prevData.mmPerPx !== mmPerPxNum) return true
            if (prevData.cellSize !== cellSizeNum) return true
        }
        return false
    }, [mode, prevData, layoutName, startDateNum, imgWidth, imgHeight, originXNum, originYNum, mmPerPxNum, cellSizeNum, fileName])

    /**
     * 保存ボタンを抑制するフラグ
     */
    const disableSave = useMemo(() => {
        if (mode === EditModeType.New) {
            return (file) ? false : true
        } else if (mode === EditModeType.Edit) {
            return isModified ? false : true
        }
    }, [mode, isModified, file])

    // 店舗の開始日
    const shopStartDate = useMemo(() => {
        if (shop) {
            return new Date(shop.start)
        }
    }, [shop])

    // アップロードしたいファイルが選択された時の処理（新規の場合）
    const handleFileChanged = (file: File) => {
        setFile(file)
        // Writerを作成
        if (writer === undefined) {
            const w = new LayoutEditPicWriter(file, MAP_WIDTH, MAP_HEIGHT)
            setWriter(w)
        }
    }

    // アップロードされた平面図を表示
    useEffect(() => {
        if (writer && refLeft && refLeft.current && refAnim && refAnim.current) {
            //console.log("effect drawfitimage")
            const ctx = refLeft.current.getContext("2d")
            const ani = refAnim.current.getContext("2d")
            if (ctx && ani) {
                writer.imageLoad().then(res => {
                    let mpp = 10
                    if (res && writer.image && file) {
                        // アップロード直後に各項目の初期値を設定
                        setImgWidth(writer.image.width.toString())
                        setImgHeight(writer.image.height.toString())
                        setFileName(file.name)
                        setOriginX("0")
                        setOriginY("0")
                        mpp = Math.round(2000000 / writer.image.width) / 100
                        setMmPerPx(mpp.toString())
                        setCellSize("5")
                        let msg = t("msgOriginCrossLine")
                        setErrOrigin(msg)
                        msg = t("msgScaleMesureing")
                        setErrScale(msg)
                        msg = t("msgAreaCellUnitSize")
                        setErrCellSize(msg)
                    }
                    if (res) writer.drawFitImage(ctx)
                    if (res) writer.drawCrossOrigin(ani, 0, 0)
                    if (res) writer.drawMesure(ani, mpp)
                    //console.log("effect at imageLoad")
                })
            }
        }
    }, [writer, refLeft, refAnim, file, t])

    /**
     * 原点クロスラインとメジャーを再描画
     */
    const refreshOriginAndMesure = useCallback(async () => {
        if (writer && refAnim && refAnim.current) {
            if (originXNum || originYNum || mmPerPxNum) {
                const ani = refAnim.current.getContext("2d")
                if (ani) {
                    await writer.clearCanvas(ani)
                    await writer.drawCrossOrigin(ani, originXNum, originYNum)
                    await writer.drawMesure(ani, mmPerPxNum)
                    //console.log("refreshOriginAndMesure -> drawCrossOrigin")
                }
            }
        }
    }, [writer, refAnim, originXNum, originYNum, mmPerPxNum])

    // 原点位置、縮尺の変更時に再描画させる
    useEffect(() => {
        refreshOriginAndMesure()
    }, [refreshOriginAndMesure])

    // 平面図を更新
    const refreshMap = useCallback(() => {
        if (writer && refLeft && refLeft.current) {
            const ctx = refLeft.current.getContext("2d")
            if (ctx) {
                writer.clearCanvas(ctx)
                writer.drawFitImage(ctx)
            }
        }
    }, [writer, refLeft])

    /**
     * 平面図上にセルメッシュを描画します。
     * @param onOff 
     */
    const refreshCellMesh = useCallback((onOff?: boolean) => {
        if (writer && refCell && refCell.current) {            
            const ctx = refCell.current.getContext("2d")
            if (ctx) {
                if (onOff !== undefined) {
                    onOff ? writer.drawCellMesh(ctx, cellSizeNum) : writer.clearCanvas(ctx)
                } else {
                    cellView ? writer.drawCellMesh(ctx, cellSizeNum) : writer.clearCanvas(ctx)
                }
            }
        }        
    }, [writer, refCell, cellSizeNum, cellView])

    // 初期表示設定
    useEffect(() => {
        if (mode && mode === EditModeType.New) {
            // 新規モード
            const now = (new Date()).getTime()
            if (startDateNum === undefined) setStartDateNum(now)
        } else {
            // 編集||Viewモード
            if (layout) {
                console.log("layout:", layout)
                if (layoutName === "") setLayoutName(layout.name)
                // layout.start = 0 のときは、2021-01-01 を仮に設定
                if (startDateNum === undefined) setStartDateNum(layout.start ? layout.start : 1609459200000)
                if (layout.mapping) {
                    if (fileName === "") {
                        const ary = Utils.separateFileNameAndExtension(layout.mapping.image)
                        const fn = ary[0] + "." + ary[1]
                        //const ary = layout.mapping.image.split("/")
                        //const fn = ary[ary.length - 1]
                        setFileName(fn)
                    }
                    if (imgWidth === "-") setImgWidth(layout.mapping.pixel_width.toString())
                    if (imgHeight === "-") setImgHeight(layout.mapping.pixel_height.toString())
                    if (originX === "-") setOriginX(layout.mapping.origin_x.toString())
                    if (originY === "-") setOriginY(layout.mapping.origin_y.toString())
                    if (mmPerPx === "-") setMmPerPx(layout.mapping.mm_per_pixel.toString())
                    if (cellSize === "-") setCellSize(layout.mapping.area_unitcell_pixel.toString())
                    if (writer === undefined) {
                        const w = new LayoutEditPicWriter(undefined, MAP_WIDTH, MAP_HEIGHT)
                        w.setMapParameters(layout.mapping).then(res => {
                        })
                        setWriter(w)
                    } else {
                        // writerがあるときは、Map描画する
                        refreshMap()
                    }
                    if (prevData === undefined) {
                        const ary = Utils.separateFileNameAndExtension(layout.mapping.image)
                        const fn = ary[0] + "." + ary[1]
                        //const fAry = layout.mapping.image.split("/")
                        //const fn = fAry[fAry.length - 1]
                        const prev: EditItemType = {
                            name: layout.name,
                            start: layout.start,
                            width: layout.mapping.pixel_width,
                            height: layout.mapping.pixel_height,
                            originX: layout.mapping.origin_x,
                            originY: layout.mapping.origin_y,
                            mmPerPx: layout.mapping.mm_per_pixel,
                            cellSize: layout.mapping.area_unitcell_pixel,
                            fileName: fn
                        }
                        setPrevData(prev)
                    }
                }
            }
        }
    }, [mode, layout, cellSize, imgHeight, imgWidth, layoutName, mmPerPx, originX, originY, prevData, startDateNum, writer, fileName, refreshMap])

    // 画面Closeボタン押下時の処理
    const handleClose = () => {
        if (file) setFile(undefined)
        navigate("/layout_list")
    }

    /**
     * 入力項目のヴァリデーションチェック
     * @returns 
     */
    const validationCheck = () => {
        if (mode === EditModeType.New) {
            //const res = (fileName.length > 0) ? Utils.validFileName(fileName) : false
            //const inc = (fileName.length > 0) ? usedFileNameList.includes(fileName) : false
            //console.log("usedFileNameList:" , usedFileNameList)
            if (layoutName && layoutName.length > 2 && file && startDate) {
                return true
            }
            if (!layoutName || layoutName.length < 3) {
                const msg = t("msgInputLayoutName")
                setErrLayoutName(msg)
            }
            /*if (fileName.length === 0) {
                const msg = t("msgInputFileName")
                setErrFileName(msg)
            } else if (!res) {
                const msg = t("msgInvalidFileName")
                setErrFileName(msg)
            } else if (inc) {
                const msg = t("msgExistSameFileName")
                setErrFileName(msg)
            }*/
            return false
        } else {
            if (layoutName && layoutName.length > 2 && startDate) {
                return true
            }
            if (!layoutName || layoutName.length < 3) {
                const msg = t("msgInputLayoutName")
                setErrLayoutName(msg)
            }
            return false
        }
    }

    /**
     * 保存ボタン押下時の処理
     */
    const handleSave = () => {
        if (validationCheck()) {
            // ファイル名は店舗ID＋UUIDとする。
            //const uuid = Utils.generateUuid()
            const extensions = (file) ? Utils.getFileExtension(file.name) : Utils.getFileExtension(layout?.mapping?.image)
            const file_name = "s" + shopId + "." + extensions
            // 新規登録時(layout_id=0とする)
            if (mode === EditModeType.New) {
                if (startDate && file && writer && writer.image) {
                    const now = (new Date()).getTime()
                    const map: MapModel = {
                        //startYmd: "",
                        image: file_name,
                        pixel_width: writer.image.width,
                        pixel_height: writer.image.height,
                        origin_x: originXNum,
                        origin_y: originYNum,
                        mm_per_pixel: mmPerPxNum,
                        area_unitcell_pixel: cellSizeNum,
                        area_packs: [],
                        line_packs: [],
                        image_data: writer.image.src,
                        image_modified: true
                    }
                    const data: LayoutModel = {
                        layout_id: 0,
                        name: layoutName,
                        start: startDate.getTime(),
                        end: 0,
                        mapping: map,
                        created_at: now,
                        modified_at: now
                    }
                    if (layoutSetId) {
                        const id = parseInt(layoutSetId)
                        saveLayout(id, data)
                        navigate("/layout_list?" + UrlParameterType.LayoutSetId + "=" + layoutSetId, { state: { LayoutSetId: layoutSetId, from: location.state.from } })
                    }
                }
            } else {
                // 更新時
                console.log("更新時 layout, layoutSetId", layout, layoutSetId)
                if (layout && layout.mapping && startDate && layoutSetId && writer && writer.image) {
                    let name = file_name
                    if (file === undefined) {
                        // ディレクトリ名を除いたファイル名を取得
                        const res = Utils.separateFileNameAndUUIDAndExtension(layout.mapping.image)
                        name = res[0] + "." + res[1] + "." + res[2]
                    }
                    const newLay = { ...layout }
                    const newMap: MapModel = {
                        image: name,
                        pixel_width: layout.mapping.pixel_width,
                        pixel_height: layout.mapping.pixel_height,
                        origin_x: originXNum,
                        origin_y: originYNum,
                        mm_per_pixel: mmPerPxNum,
                        area_unitcell_pixel: cellSizeNum,
                        area_packs: layout.mapping.area_packs,
                        line_packs: layout.mapping.line_packs,
                        image_data: writer.image.src,
                        image_modified: (file) ? true : false
                    }
                    console.log("newMap:", newMap)
                    newLay.start = startDate.getTime()
                    newLay.name = layoutName
                    newLay.mapping = newMap
                    const laySetId = parseInt(layoutSetId)
                    saveLayout(laySetId, newLay)
                    navigate("/layout_list?" + UrlParameterType.LayoutSetId + "=" + layoutSetId, { state: { LayoutSetId: layoutSetId, from: location.state.from } })
                }
            }
        }
    }

    /**
     * 平面図の差し替えボタン押下時の処理
     */
    const handleReplaceMap = () => {
        setDialogOpen(true)
    }

    /**
     * 平面図差し替えダイアローグを閉じる
     */
    const handleReplaceCancel = () => {
        setDialogOpen(false)
    }

    /**
     * 平面図の差し替えを実行します。
     * @param file 
     */
    const handleAcceptReplaceMap = (file: File) => {
        setDialogOpen(false)
        setFile(file)
        setFileName(file.name)
        // Writerを再作成
        const w = new LayoutEditPicWriter(file, MAP_WIDTH, MAP_HEIGHT)
        setWriter(w)
    }

    /**
     * レイアウト名の修正
     * @param event 
     */
    const handleChangeLayoutName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        setLayoutName(val)
        if (val && val.length > 2) {
            if (errLayoutName && errLayoutName.length > 0) setErrLayoutName("")
        }
    }

    /**
     * ファイル名の修正
     * @param event 
     * /
    const handleChangeFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        setFileName(val)
        if (val) {
            if (errFileName && errFileName.length > 0) setErrFileName("")
        }
    }*/

    /**
     * 開始日の修正
     * @param event 
     */
    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        if (event && !disableEdit) {
            const n = (event as Date).getTime()
            setStartDateNum(n)
        }
    }

    /**
     * 画像の横幅修正
     * @param event 
     */
    const handleChangeWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 整数
        const regexp = /^\d*$/
        if (regexp.test(val)) {
            setImgWidth(val)
        }
    }
    
    /**
     * 画像の縦長さ修正
     * @param event 
     */
    const handleChangeHeight = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 整数
        const regexp = /^\d*$/
        if (regexp.test(val)) {
            setImgHeight(val)
        }
    }
    
    /**
     * 原点位置Xの修正
     * @param event 
     */
    const handleChangeOriginX = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 整数
        const regexp = /^\d*$/
        if (regexp.test(val)) {
            setOriginX(val)
        }
    }
    
    /**
     * 原点位置Yの修正
     * @param event 
     */
    const handleChangeOriginY = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 整数
        const regexp = /^\d*$/
        if (regexp.test(val)) {
            setOriginY(val)
        }
    }
    
    /**
     * 縮尺の修正
     * @param event 
     */
    const handleChangeMmPerPx = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 符号なし少数
        const regexp = /^([1-9]\d*|0)(\.\d*)?$/
        if (regexp.test(val)) {
            setMmPerPx(val)
        }
    }

    /**
     * セルサイズの修正
     * @param event 
     */
    const handleChangeCellSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        // 整数
        const regexp = /^([1-9]\d*|0)$/
        if (regexp.test(val)) {
            setCellSize(val)
        }
    }

    /**
     * 平面図上にセル表示するチェックボックスの変更処理
     * @param event 
     */
    const handleChangeCellView = (event: CustomCheckboxEvent) => {
        console.log(event)
        refreshCellMesh(!cellView)
        setCellView(!cellView)
    }

    /**
     * 平面図の拡大
     */
    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            refreshMap()
            refreshOriginAndMesure()
            refreshCellMesh()
            //console.log("zoomIn")
        }        
    }
    
    /**
     * 平面図の縮小
     */
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            refreshMap()
            refreshOriginAndMesure()
            refreshCellMesh()
            //console.log("zoomOut")
        }
    }
    
    /**
     * 平面図を枠サイズに合わせる
     */
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            refreshMap()
            refreshOriginAndMesure()
            refreshCellMesh()
            //console.log("zoomReset")
        }
    }
    
    /**
     * 平面図上のマウス移動を検出
     */
    const mousePress = useMouseMove(refAnim, (event: MouseEvent) => {
        // マウスドラッグ中の処理
        //console.log("mouse dragging")
        if (writer) {
            const srcCanvas = refLeft.current
            const dstCanvas = refAnim.current
            if (srcCanvas && dstCanvas) writer.mouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        if (writer) {
            // マウスのドラッグが終わったら再描画
            refreshMap()
            refreshOriginAndMesure()
            refreshCellMesh()
        }
    }, (event: MouseEvent) => {
            // マウスボタンを押したときの処理
            if (writer) {
                writer.mousePress()
            }
        }
    )

    /**
     * マウスのON/OFF動作時の処理
     * /
    useEffect(() => {
        if (mousePress) {
            if (writer) writer.mousePress()
        } else {
            // マウスのドラッグが終わったら再描画
            refreshMap()
            refreshOriginAndMesure()
            refreshCellMesh()
            //console.log("effect mouse no-press")
        }
    }, [mousePress, writer, refreshMap, refreshOriginAndMesure, refreshCellMesh])*/

    const strPlaceholder = useMemo(() => { return t("msgInputLayoutName") }, [t])
    
    //const strPlaceholderFileName = useMemo(() => {return t("msgInputFileName")}, [t])

    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    const strTitle = useMemo(() => {
        if (mode === EditModeType.New) {
            return t("header.layoutNewEntry")
        } else if (mode === EditModeType.View) {
            return t("header.layoutDetail")
        } else {
            return t("header.layoutEdit")
        }
    }, [mode, t])

    /*const strCreateAt = useMemo(() => {
        return (layout) ? DateUtil.epochtime2LocalYmdSlashHms(layout.created_at, shop ? shop.timezone : 0) : "-"
    }, [layout, shop])

    const strModifiedAt = useMemo(() => {
        return (layout) ? DateUtil.epochtime2LocalYmdSlashHms(layout.modified_at, shop ? shop.timezone : 0) : "-"
    }, [layout, shop])*/

    return (
        <ModalFrame page={PageName.InfoMap} title={t("menu.mapRegistrationEdit")} width={1100} height={670}>
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>
                        {strTitle}
                        {isProduction ? (<TrackingPageView page_title="bsinfo-layout-edit" />) : (null)}
                    </div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.body}>
                    <div className={style.list}>
                        <table className={style.table}>
                            <tbody>
                                <tr>
                                    <td className={style.dtTitle}>{t("storeName")}</td>
                                    <td className={style.dtName}>{shop ? shop.name : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={style.mapArea}>
                        <div className={style.leftSide}>
                            <div className={style.canvasWrapper}>
                                <canvas id="canvasLeft" ref={refLeft} className={style.canvasLeft} width={MAP_WIDTH} height={MAP_HEIGHT} ></canvas>
                                <canvas id="canvasCell" ref={refCell} className={style.canvasCell} width={MAP_WIDTH} height={MAP_HEIGHT} ></canvas>
                                <canvas id="canvasAnim" ref={refAnim} className={style.canvasAnim} width={MAP_WIDTH} height={MAP_HEIGHT} ></canvas>
                                {
                                    (mode === EditModeType.New && file === undefined) ? (
                                        <div className={style.fileupload}>
                                            <FileUpload
                                                iconType={FileUploadIconType.Up}
                                                guideOn={false}
                                                upload={handleFileChanged}
                                                errorMessage=""
                                                isLoading={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className={style.zoomBtn}>
                                            <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                                        </div>
                                    )
                                }                                        
                            </div>
                        </div>
                        <div className={style.rightSide}>
                            <table className={style.rightTable}>
                                <tbody>
                                    <tr>
                                        <td className={style.dtTitle}>{t("layoutName")}</td>
                                        <td className={style.dtInput}>
                                            <input type="text" value={layoutName} onChange={handleChangeLayoutName} placeholder={strPlaceholder} disabled={disableEdit} />
                                            {
                                                (errLayoutName) ? (<p><span className={style.errorMessage}>{errLayoutName}</span></p>) : (null)
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.rtTitle}>{t("startDate")}</td>
                                        <td className={style.dtDate}>
                                            <div className={style.startDate}>
                                                <FupDatepicker
                                                    type={DpType.Micro}
                                                    onChange={handleChangeStartDate}
                                                    divRef={refDpk}
                                                    selected={startDate}
                                                    locale={i18n.language}
                                                    dateFormat={datepickFormat}
                                                    dateFormatCalendar={datepickFormatCalendar}
                                                    excludeDates={excludeDates}
                                                    showYearDropdown={true}
                                                    minDate={shopStartDate}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.rtTitle}>{t("imageSize")}</td>
                                        <td>
                                            <label>{t("width")}</label><input type="text" value={imgWidth} onChange={handleChangeWidth} disabled={true} className={style.numberInput} /><label>px</label>　×　
                                            <label>{t("height")}</label><input type="text" value={imgHeight} onChange={handleChangeHeight} disabled={true} className={style.numberInput} /><label>px</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.rtTitle}>{t("originPosition") }</td>
                                        <td>
                                            <div>
                                                <label>X{t("coordinate")}</label><input type="text" value={originX} onChange={handleChangeOriginX} className={style.numberInput} disabled={disableEdit} /><label>px</label>　×　
                                                <label>Y{t("coordinate")}</label><input type="text" value={originY} onChange={handleChangeOriginY} className={style.numberInput} disabled={disableEdit} /><label>px</label>
                                            </div>
                                            {
                                                (errOrigin) ? (<div><span className={style.eMessage}>{errOrigin}</span></div>) : (null)
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.rtTitle}>{t("scale")}</td>
                                        <td>
                                            <div>
                                                <input type="text" value={mmPerPx} onChange={handleChangeMmPerPx} className={style.numberInput} disabled={disableEdit} /><label>mm/px</label>
                                            </div>
                                            {
                                                (errScale) ? (<div><span className={style.eMessage}>{errScale}</span></div>) : (null)
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.rtTitle}>{t("cellSize")}</td>
                                        <td>
                                            <div>
                                                <input type="text" value={cellSize} onChange={handleChangeCellSize} className={style.numberInput} disabled={disableEdit} /><label>px</label>
                                            </div>
                                            {
                                                (errCellSize) ? (<div><span className={style.eMessage}>{errCellSize}</span></div>) : (null)
                                            }
                                            <div className={style.checkbox}><CustomCheckbox label={t("label.showCells")} value="cell_view" check={cellView} onChange={handleChangeCellView} /></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        (mode === EditModeType.View) ? (null) : (
                            <div className={style.buttons}>
                                <div className={style.saveBtn}>
                                    <div className={style.spc3}></div>
                                    {
                                        (mode === EditModeType.New || isHotLayout) ? (null) : (
                                            <Button name="reup" label="button.replaceMap" styleType={StyleType.Outline} onClick={handleReplaceMap} />
                                        )                                        
                                    }
                                    <div className={style.spc4}></div>
                                    <Button name="cancel" label="cancel" styleType={StyleType.Reverse} onClick={handleClose} />
                                    <div className={style.spc}></div>
                                    {
                                        ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit && !isHotLayout)) ? (<Button name="entry" label="save" disabled={disableSave} styleType={StyleType.Normal} onClick={handleSave} />) : (<div className={style.spc}></div>)
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <MapReplaceDialog
                requestOpen={dialogOpen}
                title={t("button.replaceMap")}
                currentFile={fileName}
                currentSrc={writer && writer.image ? writer.image.src : undefined}
                onCancel={handleReplaceCancel}
                onReplace={handleAcceptReplaceMap}
            />
        </ModalFrame>
    )
}