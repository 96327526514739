import { ChordDataItem } from "../../data/analysis/AnalysisResult";
import { OSResHuman } from "../AnalysisDataImpl";
import { FullLayout } from "./FullLayoutImpl";

export class ChordalGraphCounter {
    layout: FullLayout
    sampleCustomers: OSResHuman[];

    _areaFromID2toID2count: Record<number, Record<number, number>>
    _groupFromID2toID2count: Record<number, Record<number, number>>

    constructor(layout: FullLayout, sampleCustomers: OSResHuman[]) {
        this.layout = layout
        this.sampleCustomers = sampleCustomers
        this._areaFromID2toID2count = {}
        this._groupFromID2toID2count = {}
    }

    _null2num(n: number | null) {
        return (n == null) ? -1 : n
    }

    _inc(d: Record<number, Record<number, number>>,
        fromID: number | null, toID: number | null, count: number) {
        const fromKey: number = this._null2num(fromID)
        const toKey: number = this._null2num(toID)
        if (!d.hasOwnProperty(fromKey)) {
            d[fromKey] = {}
        }
        if (!d[fromKey].hasOwnProperty(toKey)) {
            d[fromKey][toKey] = count
        } else {
            d[fromKey][toKey] += count
        }
    }

    _dic2list(d: Record<number, Record<number, number>>): ChordDataItem[] {
        const result: ChordDataItem[] = []
        for (const fromID in d) {
            for (const toID in d[fromID]) {
                result.push({
                    "src_id": parseInt(fromID),
                    "dst_id": parseInt(toID),
                    "value": d[fromID][toID],
                })
            }
        }
        return result
    }

    calc(): [ChordDataItem[], ChordDataItem[]] {
        for (let c of this.sampleCustomers) {
            const as = c.enteredArea
            as.sort((a1, a2) => (a1.enterTime - a2.enterTime))
            as.map(a => [
                a.areaId,
                this._null2num(this.layout.getArea(a.areaId.toString()).group_id)
            ]).forEach((elem, index, ls) => {
                if (index < (ls.length - 1)) {
                    this._inc(this._areaFromID2toID2count, elem[0], ls[index+1][0], 1)
                    this._inc(this._groupFromID2toID2count, elem[1], ls[index+1][1], 1)
                }
            })
        }
        return [
            this._dic2list(this._areaFromID2toID2count),
            this._dic2list(this._groupFromID2toID2count),
        ]
    }
}