import React, { useEffect, useRef, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Button, StyleType } from "../../component/button/Button"
import { useAuthUserContext } from "../../providers"
import { useManagementDataContext } from "../../providers/ManagementData"
import { Header } from "../common/Header"
import { LoginStatusType } from "./Login"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./Logout.module.css"
import { Footer } from "../common/Footer"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export const Logout: React.FC = () => {

	const { t } = useTranslation()
    const navigate = useNavigate()
    const { userInfo, authStatus, signout } = useAuthUserContext()
    const { clearManagementData } = useManagementDataContext()
    

    const timerId = useRef<number | null>(null)
    
    //const baseUrl = (process.env["REACT_APP_API_WRAPPER"] === "design") ? "/issue69.html/" : "/"
    const baseUrl = "/"

    const handleLogin = () => {
        navigate(baseUrl + "login")
    }

    const errorMessage = useMemo(() => {
        if (authStatus.status === LoginStatusType.Error) {
            return (<div className={style.error}>{authStatus.errorMessage}</div>)
        }
        return null
    }, [authStatus])
    
    useEffect(() => {
        // AuthUserProviderのsignoutを実行(userInfoとcookie、localStorageを削除)
        if (userInfo) {
            signout(function () {
                // 管理画面系の全データを削除
                clearManagementData()
                // 5秒後にログイン画面へ
                timerId.current = window.setTimeout((): void => {
                    navigate(baseUrl + "login")
                }, 5000)
                // 古いTimeoutが残っていたら削除する。
                let oldId: number = timerId.current - 1
                while (oldId--) {
                    //console.log("古いTimeoutをクリア id:", oldId)
                    window.clearTimeout(oldId)
                }
            })
        }

        return () => { if (timerId.current) window.clearTimeout(timerId.current) }        
    }, [])

    return (
        <div className={style.main}>
            <Header disableLogout={true} />
            <div className={style.content}>
                <div className={style.pane}>
                    <div className={style.title}>{t("logout")}</div>
                    {isProduction ? (<TrackingPageView page_title="logout" />) : (null)}
                    <div className={style.body}>
                        {
                            (userInfo) ? (
                                <div className={style.text}>
                                    <span>{t("logoutInProgress")}</span>
                                    {errorMessage}
                                </div>
                            ) : (
                                <div className={style.text}>
                                    <span>{t("msgLogOut")}</span>
                                    <p></p>
                                        <span><small>{t("msgReturnLoginPage")}</small></span>
                                        <p>
                                        </p>
                                    <div className={style.button}>
                                        <Button
                                            label="reLogIn"
                                            name="login"
                                            onClick={handleLogin}
                                            styleType={StyleType.OutMini}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={style.foot}>
                <Footer />
            </div>
        </div>
    )
}