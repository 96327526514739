import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"

import { SeriesSwitcher, BtnProp, SwitcherFontType } from "../../component/series_switcher/SeriesSwitcher"
import { AreaGraph } from "./AreaGraph"
import { AreaMap } from "./AreaMap"
import { TimelineGraph } from "./TimelineGraph"
import { TrailMap } from "./TrailMap"
import { HeatMap } from "./HeatMap"
import { ChordGraph } from "./ChordGraph"
import { ServiceGraph } from "./ServiceGraph"
import { AnalyzeParametersType } from "../../types/Analyze"
import { AnalyzeViewType } from "../../types/Analyze"
import { AreaGraphLegend } from "./AreaGraphLegend"
import { useAuthUserContext } from "../../providers"

import style from './ResultPanel.module.css'

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

export const ResultSelectType = {
    AreaGraph: "areagraph",
    TimelineGraph: "timeline",
    AreaMap: "areamap",
    HeatMap: "heatmap",
    TrailMap: "trailmap",
    ChordGraph: "chordgraph",
    ServiceGraph: "servicegraph"
} as const
export type ResultSelect = typeof ResultSelectType[keyof typeof ResultSelectType]

// セレクター定義
const ButtonItems: BtnProp[] = [
    { name: ResultSelectType.AreaGraph, label: "areaGraph" },
    { name: ResultSelectType.TimelineGraph, label: "timelineGraph"},
    { name: ResultSelectType.AreaMap, label: "areaMap"},
    { name: ResultSelectType.HeatMap, label: "heatMap"},
    { name: ResultSelectType.TrailMap, label: "trailMap"},
    { name: ResultSelectType.ChordGraph, label: "chordGraph"},
]
// セレクター定義（Mock用）
const ButtonItems4Mock: BtnProp[] = [
    { name: ResultSelectType.AreaGraph, label: "areaGraph" },
    { name: ResultSelectType.TimelineGraph, label: "timelineGraph"},
    { name: ResultSelectType.AreaMap, label: "areaMap"},
    { name: ResultSelectType.HeatMap, label: "heatMap"},
    { name: ResultSelectType.TrailMap, label: "trailMap"},
    { name: ResultSelectType.ChordGraph, label: "chordGraph"},
    { name: ResultSelectType.ServiceGraph, label: "serviceGraph"}
]

type DispItemType = {
    type: ResultSelect
    title: string
}

// 表示タブ
const DispItems: DispItemType[] = [
    { type: ResultSelectType.AreaGraph, title: "staysAndPassageByArea" },
    { type: ResultSelectType.TimelineGraph, title: "timelineGraph" },
    { type: ResultSelectType.AreaMap, title: "areaMap" },
    { type: ResultSelectType.HeatMap, title: "heatMap" },
    { type: ResultSelectType.TrailMap, title: "trailMap" },
    { type: ResultSelectType.ChordGraph, title: "chordGraph" },
]
// 表示タブ（Mock用）
const DispItems4Mock: DispItemType[] = [
    { type: ResultSelectType.AreaGraph, title: "staysAndPassageByArea" },
    { type: ResultSelectType.TimelineGraph, title: "timelineGraph" },
    { type: ResultSelectType.AreaMap, title: "areaMap" },
    { type: ResultSelectType.HeatMap, title: "heatMap" },
    { type: ResultSelectType.TrailMap, title: "trailMap" },
    { type: ResultSelectType.ChordGraph, title: "chordGraph" },
    { type: ResultSelectType.ServiceGraph, title: "purchaseAndCustomerServiceRate" }    
]

interface StateObject {
    resultType: ResultSelect    // 検索実行時の条件
}
interface Props {
    searches: AnalyzeParametersType | undefined
}

export const ResultPanel: React.FC<Props> = (props) => {

    const { t } = useTranslation()
    
    const sendDataToGTM = useGTMDispatch()
    const { userInfo } = useAuthUserContext() 
    
    const [selection, setSelection] = useState<StateObject>({ resultType: ResultSelectType.AreaGraph })
    
    // 初期表示
    useEffect(() => {
        // GTM イベント送信(GA4-view)
        const pageTitle = (props.searches && props.searches.view === AnalyzeViewType.Single) ? "analyze-single-" + selection.resultType : "analyze-compare-" + selection.resultType
        if (!mockOn) {
            sendDataToGTM({
                event: "analyze-tab-click",
                event_value: selection.resultType,
                page_title: pageTitle,
                url_path: window.location.pathname,
                url_search: window.location.search,
                login_id: userInfo?.user.login_id
            })
        } else {
            console.log("SKIP sendDataToGTM ", pageTitle)
        }
    }, [])

    /**
     * 分析結果のタブ切替処理
     * @param event 
     */
    const changeResult: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === ResultSelectType.AreaGraph) {
            console.log(nm)
        } else if (nm === ResultSelectType.TimelineGraph) {
            console.log(nm)
        } else if (nm === ResultSelectType.AreaMap) {
            console.log(nm)
        } else if (nm === ResultSelectType.HeatMap) {
            console.log(nm)
        } else if (nm === ResultSelectType.TrailMap) {
            console.log(nm)
        } else if (nm === ResultSelectType.ChordGraph) {
            console.log(nm)
        } else if (nm === ResultSelectType.ServiceGraph) {
            console.log(nm)
        } else {
			const msg = t("msgUnknownButton")
            throw new Error(msg + " nm:" + nm)
        }
        // GTM イベント送信(GA4-view)
        const pageTitle = (props.searches && props.searches.view === AnalyzeViewType.Single) ? "analyze-single-" + nm : "analyze-compare-" + nm
        if (!mockOn) {
            sendDataToGTM({
                event: "analyze-tab-click",
                event_value: nm,
                page_title: pageTitle,
                url_path: window.location.pathname,
                url_search: window.location.search,
                login_id: userInfo?.user.login_id
            })
        } else {
            console.log("SKIP sendDataToGTM ", pageTitle)
        }

        const newSt = { ...selection }
        newSt.resultType = nm
        setSelection(newSt)
    }

    const getWarningMessage = () => {
        if (selection.resultType === ResultSelectType.AreaGraph) {
            if (props.searches) {
                if (props.searches.view === AnalyzeViewType.Compare) {
                    if (props.searches.single.shopId !== props.searches.compare.shopId || props.searches.single.layoutId !== props.searches.compare.layoutId) {
                        return (
                            <div className={style.warn}>
                                <span className={style.message}>{t("msgCannotDisplay")}</span>
                            </div>
                        )
                    }
                }
            }
        }
        return null
    }

    const getTitle = () => {
        const items = mockOn ? DispItems4Mock : DispItems
        const tg = items.find(el => el.type === selection.resultType)
        const title = (tg) ? t(tg.title) : ""
        return (
            <div className={style.title}>
                {title}
            </div>
        )
    }

    const getLegend = () => {
        if (selection.resultType === ResultSelectType.AreaGraph) {
            if (props.searches && props.searches.view === AnalyzeViewType.Single) {
                return (
                    <AreaGraphLegend
                        view={AnalyzeViewType.Single}
                        primaryThreshold={props.searches.single.threshold}
                    />
                )
            } else if (props.searches && props.searches.view === AnalyzeViewType.Compare) {
                return <AreaGraphLegend
                    view={AnalyzeViewType.Compare}
                    primaryThreshold={props.searches.single.threshold}
                    secondaryThreshold={props.searches.compare.threshold}
                />
            }
        }
        return null
    }

    let content: React.ReactNode
    if (selection.resultType === ResultSelectType.AreaGraph) {
        content = (<AreaGraph searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.TimelineGraph) {
        content = (<TimelineGraph searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.AreaMap) {
        content = (<AreaMap searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.HeatMap) {
        content = (<HeatMap searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.TrailMap) {
        content = (<TrailMap searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.ChordGraph) {
        content = (<ChordGraph searches={props.searches} />)
    } else if (selection.resultType === ResultSelectType.ServiceGraph) {
        content = (<ServiceGraph searches={props.searches} />)
    } else {
		const msg = t("msgUnknownPage")
        throw new Error(msg + " selection.resultType:" + selection.resultType)
    }

    return (
        <div className={style.pane}>
            <div className={style.switcher}>
                <SeriesSwitcher fontType={SwitcherFontType.Mulish} buttonList={ButtonItems} onClick={e => changeResult(e)} activeName={selection.resultType}/>
            </div>
            <div className={style.content}>
                {getWarningMessage()}
                <div className={style.head}>
                    {getTitle()}
                    {getLegend()}
                </div>
                {content}
            </div>
        </div>
    )
}