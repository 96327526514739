import React, { useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import i18n from "../../i18n/configs"
import { format } from "date-fns"

import { EditMode, EditModeType } from "../../types/Management"
import { useManagementDataContext } from "../../providers/ManagementData"
import { DpType, FupDatepicker } from "../../component/fup_datepicker/FupDatepicker"

import style from "./AreaRegiSelector.module.css"

interface Props {
    mode: EditMode
}

export const AreaRegiSelector: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const { companyId, companyList, shopId, shopList, dataSourceId, dataSourceList, layoutId, layoutList, areaPackId, areaPackList, editingAreaPack, changeCompany, changeShop, changeLayout, changeDataSource, changeAreaPack, updateEditingAreaPack } = useManagementDataContext()
    const ref = useRef<HTMLDivElement>(null)

    const handleCompanySelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        changeCompany(id)
    }

    const handleShopSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        changeShop(id)
    }

    const handleDataSourceSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        changeDataSource(id)
    }

    const handleLayoutSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        changeLayout(id)
    }

    const handleAreaSetChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        changeAreaPack(id)
    }

    const handleChangeStartDate = (date: Date | [Date, Date] | null) => {
        if (editingAreaPack) {
            const clone = { ...editingAreaPack }
            if (date === null) return
            if (Array.isArray(date)) return
            clone.startYmd = format(date, "yyyy-MM-dd")
            updateEditingAreaPack(clone)
        }
    }

    const companySelectOptions = useMemo(() => {
        const elm = (<option key="c-select-x" value={0}>{t("msgSelectCompany")}</option>)
        if (companyList) {
            const ops = companyList.map((el, i) => {
                return (<option key={'c-select-' + (i + 1)} value={el.company_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
    }, [t, companyList])

    const shopSelectOptions = useMemo(() => {
        const elm = (<option key="s-select-x" value={0}>{t("msgSelectStore")}</option>)
        if (shopList) {
            const ops = shopList.map((el, i) => {
                return (<option key={'s-select-' + (i + 1)} value={el.shop_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
    }, [t, shopList])

    const dataSourceSelectOptions = useMemo(() => {
        const elm = (<option key="d-select-x" value={0}>{t("guidance.selectLayoutSet")}</option>)
        if (dataSourceList) {
            const ops = dataSourceList.map((el, i) => {
                return (<option key={'d-select-' + (i + 1)} value={el.layout_set_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
    }, [t, dataSourceList])

    const layoutSelectOptions = useMemo(() => {
        const elm = (<option key="l-select-x" value={0}>{t("guidance.selectLayout")}</option>)
        if (layoutList) {
            const ops = layoutList.map((el, i) => {
                return (<option key={'l-select-' + (i + 1)} value={el.layout_id}>{el.name}</option>)
            })
            if (ops) return [elm, ...ops]
        }
    }, [t, layoutList])

    const areaSetSelectOptions = useMemo(() => {
        const elm = (<option key="a-select-x" value={0}>{t("guidance.selectAreaSet")}</option>)
        if (areaPackList) {
            const ops = areaPackList.map((el, i) => {
                return (<option key={'a-select-' + (i + 1)} value={el.area_set_id}>{el.startYmd}</option>)
            })
            if (ops) return [elm, ...ops]
        }
        return [elm]
    }, [areaPackList, t])

    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    return (
        <div className={style.contents}>
            <div className={style.main}>
                <div className={style.colCom}>
                    <div className={style.label}>{t("companyName") }</div>
                    <div className={style.select}>
                        <select className={style.companySelect} value={companyId} onChange={handleCompanySelected}>
                            {companySelectOptions}
                        </select>
                    </div>
                </div>
                <div className={style.colShop}>
                    <div className={style.label}>{t("storeName") }</div>
                    <div className={style.select}>
                        <select className={style.shopSelect} value={shopId} onChange={handleShopSelected}>
                            {shopSelectOptions}
                        </select>
                    </div>
                </div>
            </div>
            <div className={style.main}>
                <div className={style.colDs}>
                    <div className={style.label}>{t("header.dataSourceName")}</div>
                    <div className={style.select}>
                        <select className={style.dsSelect} value={dataSourceId} onChange={handleDataSourceSelected}>
                            {dataSourceSelectOptions}
                        </select>
                    </div>
                </div>
                <div className={style.colLay}>
                    <div className={style.label}>{t("layoutName")}</div>
                    <div className={style.select}>
                        <select className={style.layoutSelect} value={layoutId} onChange={handleLayoutSelected}>
                            {layoutSelectOptions}
                        </select>
                    </div>
                </div>
                {
                    props.mode === EditModeType.New ? (
                        <div className={style.colAs}>
                            <div className={style.label}>{t("table.areaSetStartDate")}</div>
                            <div>
                                <FupDatepicker
                                    onChange={handleChangeStartDate}
                                    type={DpType.Micro}
                                    locale={i18n.language}
                                    divRef={ref}
                                    dateFormat="yyyy-MM-dd"
                                    selected={(editingAreaPack && editingAreaPack.startYmd) ? new Date(editingAreaPack.startYmd) : undefined}
                                    dateFormatCalendar={datepickFormatCalendar}
                                    showYearDropdown={true}
                                />
                            </div>
                        </div>
                    
                    ): (
                        <div className={style.colAs}>
                            <div className={style.label}>{t("table.areaSetStartDate")}</div>
                            <div>
                                <select className={style.areaSetSelect} value={areaPackId} onChange={handleAreaSetChanged}>{areaSetSelectOptions}</select>
                            </div>
                        </div>
                            
                    )
                }
            </div>
        </div>
    )
}