import { ResArea, ResLayout } from "../api/data/analysis/FullLayout"
import { getAreaBorderColor } from "./ColorUtil"


// エリアの最小単位、セルの位置
type CellRecord = {
    x: number
    y: number
}

// ズーム最大値
export const MAX_SCALE = 5
// ズーム最小値
export const MIN_SCALE = 0.2
// ズーム刻み値
export const SCALE_STEP = 0.2
// エリア枠の線の太さ
export const AREA_LINE_WIDTH = 2
// 円マークの半径[px]
export const PX_LABEL_RADIUS = 7

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false


class MapWriter {
    public area_unitcell_pixel: number
    public mm_per_pixel: number
    public origin_x: number
    public origin_y: number
    public image: string
    public pixel_width: number
    public pixel_height: number
    public area_list: ResArea[]
    public imageScale: number
    public canvasWidth: number
    public canvasHeight: number
    public fitScale: number
    public vtCvsWidth: number   // 仮想（スケール考慮）キャンバス横幅
    public vtCvsHeight: number  // 　〃　　　　　　　　　　　　　縦長さ
    //public vtImgWidth: number   // 仮想キャンバス上の画像の横幅
    //public vtImgHeight: number  // 　〃　　　　　　　　　　縦長さ
    public vtTop: number        // 仮想キャンバス上余白
    public vtLeft: number       // 　〃　　　　　左余白
    public memoLeft: number     // マウス移動用上余白一時記録
    public memoTop: number      // 　〃　　　　左余白一時記録
    public mouseMoveX: number   // マウス総移動量X
    public mouseMoveY: number   // 　〃　　　　　Y
    public mouseDragX: number   // マウスドラッグ量X
    public mouseDragY: number   // 　〃　　　　　　Y
    public isMousePress: boolean    // マウスつまみ中フラグ
    public pathDepth: number    // URLの階層 0,1,2...
    public imgObject: any = undefined
    scaleGuard: number | undefined = undefined  // ズームガード：２重呼び出し防止用

    constructor(layout: ResLayout, areaList: ResArea[], canvasWidth: number, canvasHeight: number) {
        this.area_unitcell_pixel = layout.area_unitcell_pixel
        this.mm_per_pixel = layout.mm_per_pixel
        this.origin_x = layout.origin_x
        this.origin_y = layout.origin_y
        this.image = (mockOn) ? layout.image.slice(1) : layout.image
        this.pixel_width = layout.pixel_width
        this.pixel_height = layout.pixel_height   
        this.area_list = areaList
        this.canvasWidth = canvasWidth
        this.canvasHeight = canvasHeight
        const xScale = this.canvasWidth / this.pixel_width
        const yScale = this.canvasHeight / this.pixel_height
        this.fitScale = Math.min(xScale, yScale)
        this.imageScale = this.fitScale
        this.vtCvsWidth = this.canvasWidth / this.fitScale
        this.vtCvsHeight = this.canvasHeight / this.fitScale
        this.vtTop = (this.vtCvsHeight - this.pixel_height) / 2
        this.vtLeft = (this.vtCvsWidth - this.pixel_width) / 2
        this.memoLeft = this.vtLeft
        this.memoTop = this.vtTop
        this.mouseMoveX = 0
        this.mouseMoveY = 0
        this.mouseDragX = 0
        this.mouseDragY = 0
        this.isMousePress = false
        this.pathDepth = 0
        //console.log("fitScale,vtLeft,vtTop:", this.fitScale, this.vtLeft, this.vtTop)
    }

    public canvasZoom(event: WheelEvent) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            // Zoom前のスケール
            const prevScale = this.imageScale
            const prevWidth = this.vtCvsWidth
            const prevHeight = this.vtCvsHeight
            // 仮想スクリーン上のマウス位置(Zoom前)
            const prevMouseX = (event.clientX - rect.left) / prevScale
            const prevMouseY = (event.clientY - rect.top) / prevScale
            // 仮想スクリーン上位置からLeft/Topの割合を算出
            const ratioX = prevMouseX / this.vtCvsWidth
            const ratioY = prevMouseY / this.vtCvsHeight
            if (event.deltaY < 0) {
                // 拡大方向
                if (this.imageScale + SCALE_STEP < MAX_SCALE) {
                    this.imageScale += SCALE_STEP
                }
            } else {
                // 縮小方向
                if (this.imageScale - SCALE_STEP > MIN_SCALE) {
                    this.imageScale -= SCALE_STEP
                }
            }
            if (this.imageScale !== prevScale) {
                this.vtCvsWidth = this.canvasWidth / this.imageScale
                this.vtCvsHeight = this.canvasHeight / this.imageScale
                // 仮想スクリーンの増減量からLeft/Topの増減を算出
                this.vtLeft += (this.vtCvsWidth - prevWidth) * ratioX
                this.vtTop += (this.vtCvsHeight - prevHeight) * ratioY
            }
        }
    }

    public zoomReset() {
        this.imageScale = this.fitScale
        this.vtCvsWidth = this.canvasWidth / this.imageScale
        this.vtCvsHeight = this.canvasHeight / this.imageScale
        this.vtLeft = (this.vtCvsWidth - this.pixel_width) * 0.5
        this.vtTop = (this.vtCvsHeight - this.pixel_height) * 0.5
    }

    public zoomIn() {
        if (this.imageScale + SCALE_STEP < MAX_SCALE) {
            const prevWidth = this.vtCvsWidth
            const prevHeight = this.vtCvsHeight
            this.imageScale += SCALE_STEP
            this.vtCvsWidth = this.canvasWidth / this.imageScale
            this.vtCvsHeight = this.canvasHeight / this.imageScale
            this.vtLeft += (this.vtCvsWidth - prevWidth) * 0.5
            this.vtTop += (this.vtCvsHeight - prevHeight) * 0.5
        }
    }

    public zoomOut() {
        if (this.imageScale - SCALE_STEP > MIN_SCALE) {
            const prevWidth = this.vtCvsWidth
            const prevHeight = this.vtCvsHeight
            this.imageScale -= SCALE_STEP
            this.vtCvsWidth = this.canvasWidth / this.imageScale
            this.vtCvsHeight = this.canvasHeight / this.imageScale
            this.vtLeft += (this.vtCvsWidth - prevWidth) * 0.5
            this.vtTop += (this.vtCvsHeight - prevHeight) * 0.5
        }
    }

    /**
     * マップをつまんだままマウスの移動に合わせてマップを再描画します。
     *  1.dstCanvasを白で塗りつぶしてsrcCanvasを見えなくする。
     *  2.bgCanvasの背景画像を移動する。
     *  3.動線の描かれたsrcCanvasからdstCanvasに絵をコピーして移動したように見せる。
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     * @param bgCanvas 
     */
    public mouseMove(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined = undefined) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // ドラッグ処理
                this.mouseDragX = (event.clientX - rect.left)
                this.mouseDragY = (event.clientY - rect.top)
                const moveX = (this.mouseDragX - this.mouseMoveX)
                const moveY = (this.mouseDragY - this.mouseMoveY)
                this.vtLeft = this.memoLeft + moveX / this.imageScale
                this.vtTop = this.memoTop + moveY / this.imageScale
                // Imageの移動(Canvas間Copy)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.canvasWidth - Math.abs(moveX)
                    const h = this.canvasHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#fff"
                    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
                    if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)
                    //console.log("drag Map")
                }
                this.isMousePress = true
            } else {
                // 移動座標の記録（Canvas上のマウス座標）
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
            }
        }
    }

    /**
     * マウスが押された瞬間の位置を記録
     */
    public mousePress() {
        this.isMousePress = true
        this.memoLeft = this.vtLeft
        this.memoTop = this.vtTop
    }

    /**
     * マウスが離された瞬間の処理
     */
    public mouseRelease() {
        this.isMousePress = false
    }

    /**
     * カメラ座標を画面上のピクセル座標に変換します。
     * @param x 
     * @param y 
     * @returns 
     */
    public toPixelCoord(x: number, y: number) {
        return {
            'x': this.origin_x + Math.round(x / this.mm_per_pixel) + this.vtLeft,
            'y': this.origin_y + Math.round(y / this.mm_per_pixel) + this.vtTop,
        };
    }

    /**
     * エリアの四隅を取得します。
     * @param lines 
     * @returns 
     */
    async getFourCorners(lines: number[][]) {
        let maxX = undefined
        let maxY = undefined
        let minX = undefined
        let minY = undefined
        for await (let line of lines) {
            const cell_length_mm = this.area_unitcell_pixel * this.mm_per_pixel
            const [stX, edX, stY, edY] = line.map(e => e * cell_length_mm)
            if (minX === undefined || minX > stX) minX = stX
            if (minY === undefined || minY > stY) minY = stY
            if (maxX === undefined || maxX < edX) maxX = edX
            if (maxY === undefined || maxY < edY) maxY = edY
        }
        return [minX, minY, maxX, maxY]
    }

    /**
     * 画面上で見えているCanvas全体をクリア（矩形領域のピクセルを透明な黒 (rgba(0,0,0,0)) に設定）します。
     * @param ctx 
     */
    public clearCanvas(ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, this.canvasWidth / this.imageScale, this.canvasHeight / this.imageScale)
    }

    /**
     * レイアウト平面図を描画します。
     * @param ctx 
     * @returns 
     */
    public drawImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            if (ctx && this.pixel_width && this.pixel_height && this.image) {
                this.clearCanvas(ctx)
                const img = new Image()
                img.onload = () => {
                    // vtLeft,vtTopが正なら縮小表示、負なら拡大表示
                    // width,heightは縮小のときは画像の大きさを、拡大のときは画像部分がロード対象となる。
                    const sx = (this.vtLeft >= 0) ? 0 : this.vtLeft * -1
                    const sy = (this.vtTop >= 0) ? 0 : this.vtTop * -1
                    const sw = (this.vtLeft >= 0) ? this.pixel_width : this.vtCvsWidth
                    const sh = (this.vtTop >= 0) ? this.pixel_height : this.vtCvsHeight
                    const dx = (this.vtLeft >= 0) ? this.vtLeft : 0
                    const dy = (this.vtTop >= 0) ? this.vtTop : 0
                    const dw = sw
                    const dh = sh
                    ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh)
                    resolve(img)
                }
                img.src = this.getImageOfFixedPath()
                //console.log(img.src)
            } else {
                reject("drawImage error")
            }
        })
    }

    private getImageOfFixedPath() {
        if (this.pathDepth === 0) return this.image
        if (this.pathDepth === 1) {
            if (this.image.slice(0, 1) === "/") return ".." + this.image
            if (this.image.slice(0, 2) === "./") return "." + this.image
            return "../" + this.image
        }
        if (this.pathDepth === 2) {
            if (this.image.slice(0, 1) === "/") return "../.." + this.image
            if (this.image.slice(0, 2) === "./") return "../." + this.image
            return "../../" + this.image
        }
        if (this.pathDepth === 3) {
            if (this.image.slice(0, 1) === "/") return "../../.." + this.image
            if (this.image.slice(0, 2) === "./") return "../../." + this.image
            return "../../../" + this.image
        }
        throw new Error("パスが深いです。pathDepth:" + this.pathDepth)
    }

    public drawFitImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            if (this.scaleGuard === undefined) {
                this.scaleGuard = performance.now()
                ctx.save()
                ctx.scale(this.imageScale, this.imageScale)
                this.drawImage(ctx).then(res => {
                    this.imgObject = res
                    ctx.restore()
                    this.scaleGuard = undefined
                    resolve(res)
                }).catch(err => {
                    ctx.restore()
                    this.scaleGuard = undefined
                    reject(err)
                })
            } else {
                resolve("scaleGuard error")
            }
        })
    }

    async drawImageWithArea(ctx: CanvasRenderingContext2D) {
        if (this.scaleGuard !== undefined) return
        this.scaleGuard = performance.now()
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        await this.drawImage(ctx)
        // エリア枠描画
        if (this.area_list) {
            for await (let area of this.area_list) {
                const lines = this.convertCells2DrawLines(area.cell_ids)
                if (lines.length > 0) {
                    const borderColor = getAreaBorderColor(area.area_type)
                    const [minX, minY] = await this.getFourCorners(lines)
                    await this.drawAreaBorder(ctx, lines, borderColor)
                    if (minX !== undefined && minY !== undefined) {
                        const pt = this.toPixelCoord(minX, minY)
                        await this.drawAreaNumber(ctx, pt.x, pt.y, PX_LABEL_RADIUS, borderColor, "" + area.area_number)
                    }
                }
            }
        }
        ctx.restore()
        this.scaleGuard = undefined
    }


    async drawAreaNumber(ctx: CanvasRenderingContext2D, x: number, y: number, r: number, color: string, num: string) {
        // 円
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.fillStyle = color;
        ctx.arc(x + 2, y + 2, r, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.stroke();
        // テキスト
        ctx.beginPath();
        ctx.strokeStyle = 'white';
        ctx.fillStyle = 'white';
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(num, x + 2, y + 2, r * 2);
        ctx.stroke(); 
    }

    async drawAreaBorder(ctx: CanvasRenderingContext2D, lines: number[][], color: string, width?: number) {
        //console.log("drawAreaBorder lines:", lines)
        for await (let ln of lines) {
            ctx.strokeStyle = color
            ctx.lineWidth = (width) ? width : AREA_LINE_WIDTH
            ctx.beginPath()
            const cell_length_mm = this.area_unitcell_pixel * this.mm_per_pixel
            const [stX, edX, stY, edY] = ln.map(e => e * cell_length_mm)
            //console.log("cell_length_mm, stX, edX, stY, edY", cell_length_mm, stX, edX, stY, edY)
            let stPt = this.toPixelCoord(stX, stY);
            let edPt = this.toPixelCoord(edX, edY);
            //console.log("stPt, edPt:", stPt, edPt)
            ctx.moveTo(stPt.x, stPt.y)
            ctx.lineTo(edPt.x, edPt.y)
            ctx.stroke()
        }
    }

    find_outer_cells(cells: CellRecord[]) {
        // 全てのセルの位置を記録
        const x2y2exists: Record<number, Record<number, number | undefined> | undefined> = {};
        if (cells.length === 0) return [];
        for (let cell of cells) {
            const xObj = x2y2exists[cell.x]
            if (xObj === undefined) {
                const yObj: Record<number, number> = {}
                yObj[cell.y] = 1
                x2y2exists[cell.x] = yObj
            } else {
                xObj[cell.y] = 1;
            }
        }
        // 周りにセルがないセルのエッジを見つける
        const verticals: Record<number, number[][]> = {};
        const horizontals: Record<number, number[][]> = {};
        for (let cell of cells) {
            const xLeft = x2y2exists[cell.x - 1]
            const xCenter = x2y2exists[cell.x]
            const xRight = x2y2exists[cell.x + 1]
            // up
            if (xCenter && xCenter[cell.y - 1] === undefined) {
                if (horizontals[cell.y] === undefined) {
                    horizontals[cell.y] = [[cell.x, cell.x + 1]];
                } else {
                    horizontals[cell.y].push([cell.x, cell.x + 1]);
                }
            }
            // down
            if (xCenter && xCenter[cell.y + 1] === undefined) {
                if (horizontals[cell.y + 1] === undefined) {
                    horizontals[cell.y + 1] = [[cell.x, cell.x + 1]];
                } else {
                    horizontals[cell.y + 1].push([cell.x, cell.x + 1]);
                }
            }
            // left
            if (xLeft === undefined || xLeft[cell.y] === undefined) {
                if (verticals[cell.x] === undefined) {
                    verticals[cell.x] = [[cell.y, cell.y + 1]];
                } else {
                    verticals[cell.x].push([cell.y, cell.y + 1]);
                }
            }
            // right
            if (xRight === undefined || xRight[cell.y] === undefined) {
                if (verticals[cell.x + 1] === undefined) {
                    verticals[cell.x + 1] = [[cell.y, cell.y + 1]];
                } else {
                    verticals[cell.x + 1].push([cell.y, cell.y + 1]);
                }
            }
        }
        // console.log(`${(new Date().getTime())}\touter cells found:`, verticals, horizontals)
        const edgeList = [];
        for (let x in verticals) {
            verticals[x].sort((a, b) => ((a[0] === b[0]) ? (a[1] - b[1]) : (a[0] - b[0])));
            let startPoint = verticals[x].shift();
            let lastPoint = startPoint;
            let i = 1;
            while (verticals[x].length > 0) {
                if (startPoint && startPoint[0] === verticals[x][0][0] && startPoint[1] === verticals[x][0][1]) {
                    // 同じ点ならスキップ
                    continue;
                }
                if (startPoint && startPoint[0] + i === verticals[x][0][0]) {
                    lastPoint = verticals[x].shift();
                    i++;
                    continue;
                }
                if (startPoint && lastPoint) edgeList.push([parseInt(x), parseInt(x), startPoint[0], lastPoint[1]]);
                startPoint = verticals[x].shift();
                lastPoint = startPoint;
                i = 1;
            }
            if (startPoint && lastPoint) edgeList.push([parseInt(x), parseInt(x), startPoint[0], lastPoint[1]]);
        }
        for (let y in horizontals) {
            horizontals[y].sort((a, b) => ((a[0] === b[0]) ? (a[1] - b[1]) : (a[0] - b[0])));
            let startPoint = horizontals[y].shift();
            let lastPoint = startPoint;
            let i = 1;
            while (horizontals[y].length > 0) {
                if (startPoint && startPoint[0]=== horizontals[y][0][0] && startPoint[1] === horizontals[y][0][1]) {
                    // 同じ点ならスキップ
                    continue;
                }
                if (startPoint && startPoint[0] + i === horizontals[y][0][0]) {
                    lastPoint = horizontals[y].shift();
                    i++;
                    continue;
                }
                if (startPoint && lastPoint) edgeList.push([startPoint[0], lastPoint[1], parseInt(y), parseInt(y)]);
                startPoint = horizontals[y].shift();
                lastPoint = startPoint;
                i = 1;
            }
            if (startPoint && lastPoint) edgeList.push([startPoint[0], lastPoint[1], parseInt(y), parseInt(y)]);
        }
        return edgeList;
    }

    /**
     * 渡された１エリアのセル情報を枠線データに変換します。
     * @param cellIds 
     * @returns 
     */
    convertCells2DrawLines(cellIds: CellRecord[] | undefined | null) {
        if (cellIds === undefined || cellIds === null) return []
        return this.find_outer_cells(cellIds);
    }    
}

export default MapWriter