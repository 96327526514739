//import { ResArea } from "../api/data/FullLayout"
import { ResArea } from "../api/data/analysis/FullLayout"
import MapWriter from "./MapWriter"
import { PX_LABEL_RADIUS } from "./MapWriter"
import { EDIT_AREA_COLOR } from "./AreaEditPicWriter"

class AreaDetailPicWriter extends MapWriter {

    async activeAreaDraw(ctx: CanvasRenderingContext2D, areaId: number) {
        if (this.scaleGuard === undefined) {
            this.scaleGuard = performance.now()
            ctx.save()
            ctx.scale(this.imageScale, this.imageScale)
            this.clearCanvas(ctx)
            if (this.area_list) {
                for await (let area of this.area_list) {
                    if (area.area_id === areaId) {
                        const lines = this.convertCells2DrawLines(area.cell_ids)
                        if (lines.length > 0) {
                            //console.log("activeAreaDraw area_id:", areaId)
                            const [minX, minY] = await this.getFourCorners(lines)
                            await this.drawAreaBorder(ctx, lines, EDIT_AREA_COLOR)
                            if (minX && minY) {
                                const pt = this.toPixelCoord(minX, minY)
                                await this.drawAreaNumber(ctx, pt.x, pt.y, PX_LABEL_RADIUS, EDIT_AREA_COLOR, "" + area.area_number)
                            }
                        }
                    }
                }
            }
            ctx.restore()
            this.scaleGuard = undefined
        }
    }

    /**
     * マップをつまんだままマウスの移動に合わせてマップを再描画します。
     * マウスが平面図上のエリア枠の内側に入ったとき、外側に移動したときのコールバック関数を登録することができます。
     * 
     * @param event 
     * @param press 
     * @param srcCanvas 
     * @param dstCanvas 
     * @param mouseInArea 
     * @param mouseOutArea 
     */
    public mouseMoveWithArea(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, mouseInArea: (area: ResArea, posX: number, posY: number) => void, mouseOutArea: () => void) {
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // ドラッグ処理
                this.mouseDragX = (event.clientX - rect.left)
                this.mouseDragY = (event.clientY - rect.top)
                const moveX = (this.mouseDragX - this.mouseMoveX)
                const moveY = (this.mouseDragY - this.mouseMoveY)
                this.vtLeft = this.memoLeft + moveX / this.imageScale
                this.vtTop = this.memoTop + moveY / this.imageScale
                // Imageの移動(Canvas間Copy)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.canvasWidth - Math.abs(moveX)
                    const h = this.canvasHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#fff"
                    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
                    //if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)
                    //console.log("drag Map")
                }
                this.isMousePress = true
            } else {
                // 移動座標の記録
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
                // Mouse座標変換
                const divScaleMouseX = this.mouseMoveX / this.imageScale
                const divScaleMouseY = this.mouseMoveY / this.imageScale
                const mouseImgPosX = divScaleMouseX - this.vtLeft
                const mouseImgPosY = divScaleMouseY - this.vtTop
                //console.log("mouseImagePos:", mouseImgPosX, mouseImgPosY)
                // エリア枠の内外判定
                let intoArea = false
                for (let area of this.area_list) {
                    const lines = this.convertCells2DrawLines(area.cell_ids)
                    let cnt = 0
                    if (lines && lines.length > 0) {
                        for (let ln of lines) {
                            const x1 = (ln[0] * this.area_unitcell_pixel + this.origin_x)
                            const x2 = (ln[1] * this.area_unitcell_pixel + this.origin_x)
                            const y1 = (ln[2] * this.area_unitcell_pixel + this.origin_y)
                            const y2 = (ln[3] * this.area_unitcell_pixel + this.origin_y)
                            if (x1 === x2 && mouseImgPosX < x1) {
                                if ((y1 < y2 && y1 < mouseImgPosY && mouseImgPosY < y2)
                                    || (y2 < y1 && y2 < mouseImgPosY && mouseImgPosY < y1)) {
                                    cnt++
                                }
                            }
                        }
                    }
                    // 交差数が奇数ならエリア内
                    if ((cnt % 2) === 1) {
                        // エリア情報表示
                        mouseInArea(area, this.mouseMoveX, this.mouseMoveY)
                        intoArea = true
                        break
                    }
                }
                // エリア情報の非表示
                if (!intoArea) mouseOutArea()
            }
        }
    }

}

export default AreaDetailPicWriter
