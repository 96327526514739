import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"

import { AcceptButtonType, CustomDialog } from "../custom_dialog/CustomDialog"
import { ArrowRightIcon } from "@primer/octicons-react"
import { FileUpload, FileUploadIconType } from "../../component/file_upload/FileUpload"

import style from "./MapReplaceDialog.module.css"

interface Props {
    requestOpen: boolean
    title: string
    currentFile: string
    currentSrc: string | undefined
    onCancel: () => void
    onReplace: (file: File) => void
}

export const MapReplaceDialog: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    const [file, setFile] = useState<File | undefined>(undefined)
    const refL = useRef<HTMLImageElement>(null)
    const refR = useRef<HTMLImageElement>(null)

    // アップロードしたいファイルが選択された時の処理
    const handleFileChanged = (file: File) => {
        setFile(file)
        // 選択ファイルを読み込んで表示する
        const reader = new FileReader()
        reader.onload = (theFile) => {
            if (theFile.target) rightWindowOnImage(theFile.target.result)
        }
        reader.readAsDataURL(file)
    }

    // 左側窓にアップロード前の画像プレビューを出す
    const rightWindowOnImage = (src: string | ArrayBuffer | null) => {
        if (refR) {
            const elm: HTMLImageElement = refR.current as HTMLImageElement
            if (elm) {
                if (src && typeof src === 'string') elm.src = src
            }
        }
    }

    const handleCancel = () => {
        props.onCancel()
    }

    const handleReplace = () => {
        if (file) props.onReplace(file)
    }

    const width = 1100

    const height = 600

    return (
        <CustomDialog
            requestOpen={props.requestOpen}
            width={width}
            height={height}
            guideMessage={props.title}
            onCancel={handleCancel}
            onAccept={handleReplace}
            buttonType={AcceptButtonType.Ok}
        >
            <div className={style.map}>
                <div className={style.leftmap}>
                    <img alt={props.currentFile} ref={refL} className={style.preview} src={props.currentSrc}></img>
                    <div className={style.leftbadge}><label className={style.roundcorner}>{t("currentMap")}</label></div>
                </div>
                <div className={style.arrow}>
                    <ArrowRightIcon size={24}/>
                </div>
                <div className={style.rightmap}>
                    {
                        file ? (
                            <section>
                                <img alt={file.name} ref={refR} className={style.preview}></img>
                                <div className={style.rightbadge}><label className={style.badgeBlue}>{t("registerNewMap")}</label></div>
                            </section>
                        ) : (
                            <FileUpload
                                iconType={FileUploadIconType.Up}
                                guideOn={true}
                                upload={handleFileChanged}
                                errorMessage=""
                                isLoading={false}
                            />
                        )
                    }                            
                </div>
            </div>
        </CustomDialog>
    )
}