import React, { useEffect, useMemo, useState, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ArrowLeftIcon, ArrowRightIcon } from "@primer/octicons-react"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { AreaModel, AreaPackModel, UrlParameterType } from "../../types/Management"
import { AREA_ID_SHIFT_VALUE } from "../../constants"
import { Button, StyleType } from "../../component/button/Button"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { TrackingPageView } from "../common/TrackingPageView"
import { ModalFrame } from "../common/ModalFrame"

import style from "./CategoryEditModal.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export const UNRELATED_AREA_GROUP = -1

type CheckItemType = {
    areaId: number
    checked: boolean
}

interface Props {}

export const CategoryEditModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()


    const { companyId, companyList, shopId, shopList, dataSourceList, dataSourceId, layoutList, layoutId, areaPackList, areaPackId, groupList, editingAreaPack,
        updateEditingAreaPack, saveAreaPack } = useManagementDataContext()
    
    const { ableToUpdate } = useAccessControlContext()

    // パラメーター処理
    const location = useLocation()
    const search = location.search
    const state = location.state
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    // グループID初期化
    const [leftGroupId, setLeftGroupId] = useState<number>(() => {
        const g = queryParams.get(UrlParameterType.GroupId)
        if (g) return parseInt(g)
        return state ? state.groupId : 0
    })
    const [rightGroupId, setRightGroupId] = useState<number>(UNRELATED_AREA_GROUP)
    // チェックボックス用
    const [leftChecks, setLeftChecks] = useState<CheckItemType[]>([])
    const [rightChecks, setRightChecks] = useState<CheckItemType[]>([])

    // ユーザー権限
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageAreaCateg_Edit) }, [ableToUpdate])

    // データがないときは前のページに戻す
    if (!(companyId && shopId && dataSourceId && layoutId && areaPackId)) navigate(-1)

    // チェックボックス用リスト作成
    const createCheckItemLists = (ap: AreaPackModel) => {
        if (leftGroupId && rightGroupId) {
            const leftChecks: CheckItemType[] = []
            const rightChecks: CheckItemType[] = []
            for (let area of ap.area_list) {
                if (((leftGroupId > 0 || leftGroupId === -1) && area.group_id === leftGroupId) || (leftGroupId === UNRELATED_AREA_GROUP && !area.group_id)) {
                    const chk: CheckItemType = { areaId: area.area_id, checked: false }
                    leftChecks.push(chk)
                }
                if (((rightGroupId > 0 || rightGroupId === -1) && area.group_id === rightGroupId) || (rightGroupId === UNRELATED_AREA_GROUP && !area.group_id)) {
                    const chk: CheckItemType = { areaId: area.area_id, checked: false }
                    rightChecks.push(chk)
                }
            }
            setLeftChecks(leftChecks)
            setRightChecks(rightChecks)
        }
    }

    // 初期化(EditingAreaPackを設定し、編集結果を格納できるようにする)
    useEffect(() => {
        if (areaPackId && areaPackList) {
            const ap = areaPackList.find(el => el.area_set_id === areaPackId)
            if (ap) {
                const cloneAp: AreaPackModel = {
                    area_set_id: areaPackId,                    
                    startYmd: ap.startYmd,
                    area_list: ap.area_list.map(el => {
                        const area: AreaModel = {
                            area_id: el.area_id + AREA_ID_SHIFT_VALUE,
                            area_number: el.area_number,
                            name: el.name,
                            cell_ids: el.cell_ids ? [...el.cell_ids] : [],
                            area_type: el.area_type,
                            group_id: el.group_id
                        }
                        return area
                    }),
                    created_at: (new Date()).getTime(),
                    modified_at: (new Date()).getTime()
                }
                updateEditingAreaPack(cloneAp)
                //console.log("init")
                // チェックボックス作成
                createCheckItemLists(cloneAp)
            }
        }
    }, [])

    // 企業名
    const companyName = useMemo(() => {
        if (companyId && companyList) {
            const com = companyList.find(el => el.company_id === companyId)
            if (com) return com.name
        }
        return undefined
    }, [companyId, companyList])

    // 店舗名
    const shopName = useMemo(() => {
        if (shopId && shopList) {
            const sh = shopList.find(el => el.shop_id === shopId)
            if (sh) return sh.name
        }
        return undefined
    }, [shopId, shopList])

    // レイアウト～エリア
    const layoutAndAreas = useMemo(() => {
        let result = ""
        if (dataSourceList && dataSourceId) {
            const ds = dataSourceList.find(el => el.layout_set_id === dataSourceId)
            if (ds) result += ds.name
            result += " ＞ "
        }
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) result += lay.name
            result += " ＞ "
        }
        if (areaPackId && areaPackList) {
            const pk = areaPackList.find(el => el.area_set_id === areaPackId)
            if (pk) result += pk.startYmd
        }
        return result
    }, [dataSourceId, dataSourceList, layoutId, layoutList, areaPackId, areaPackList])

    // 閉じる/キャンセル押下時の処理
    const handleClose = () => {
        navigate(-1)
    }

    // 決定ボタン押下時の処理
    const handleAccept = () => {
        saveAreaPack()
        navigate(-1)
    }

    // 右側から左側へ移動ボタン押下処理
    const handleMoveToLeft = () => {
        if (editingAreaPack) {
            const newEditing = { ...editingAreaPack }
            let cnt = 0
            if (rightChecks && rightChecks.length > 0) {
                for (let itm of rightChecks) {
                    if (itm.checked) {
                        const a = newEditing.area_list.find(el => el.area_id === itm.areaId)
                        if (a) {
                            a.group_id = leftGroupId
                            cnt++
                        }
                    }
                }
                if (cnt) updateEditingAreaPack(newEditing)
                createCheckItemLists(newEditing)
            }
        }
    }
    
    // 左側から右側へ移動ボタン押下処理
    const handleMoveToRight = () => {
        if (editingAreaPack) {
            const newEditing = { ...editingAreaPack }
            let cnt = 0
            if (leftChecks && leftChecks.length > 0) {
                for (let itm of leftChecks) {
                    if (itm.checked) {
                        const a = newEditing.area_list.find(el => el.area_id === itm.areaId)
                        if (a) {
                            a.group_id = rightGroupId
                            cnt++
                        }
                    }
                }
                if (cnt) updateEditingAreaPack(newEditing)
                createCheckItemLists(newEditing)
            }
        }
    }

    // チェックボックス操作時の処理
    const handleCheckbox = useCallback((event: CustomCheckboxEvent) => {
        const v = event.target.value
        const id = parseInt(v)
        let cloneList = [...leftChecks]
        let chk = cloneList.find(el => el.areaId === id)
        if (chk) {
            const f: boolean = chk.checked
            chk.checked = !f
            setLeftChecks(cloneList)
            return
        }
        cloneList = [...rightChecks]
        chk = cloneList.find(el => el.areaId === id)
        if (chk) {
            const f: boolean = chk.checked
            chk.checked = !f
            setRightChecks(cloneList)
        }
    }, [leftChecks, rightChecks])
    
    // 左側グループセレクトの変更時処理
    const handleLeftGroupChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        if (id) setLeftGroupId(id)
        if (editingAreaPack) {
            // チェックボックス再作成
            const related: CheckItemType[] = []
            for (let area of editingAreaPack.area_list) {
                if ((id === UNRELATED_AREA_GROUP && !area.group_id) || (area.group_id === id)) {
                    const chk: CheckItemType = { areaId: area.area_id, checked: false }
                    related.push(chk)
                }
            }
            setLeftChecks(related)
        }
    }

    // 右側グループセレクトの変更時処理
    const handleRightGroupChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        if (id) setRightGroupId(id)
        if (editingAreaPack) {
            // チェックボックス再作成
            const related: CheckItemType[] = []
            for (let area of editingAreaPack.area_list) {
                if ((id === UNRELATED_AREA_GROUP && !area.group_id) || (area.group_id === id)) {
                    const chk: CheckItemType = { areaId: area.area_id, checked: false }
                    related.push(chk)
                }
            }
            setRightChecks(related)
        }
    }

    const handleLeftRowOver = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.add(style.rowOver)
    }

    const handleLeftRowOut = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.remove(style.rowOver)
    }

    const handleRightRowOver = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.add(style.rowOver)
    }

    const handleRightRowOut = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const tr = event.currentTarget
        tr.classList.remove(style.rowOver)
    }

    // 左側グループSelectのOptionリスト
    const leftGroupSelectOption = useMemo(() => {
        const elm = (<option key="l-group-select-x" value={0}>{t("msgSelectAreaCategory")}</option>)
        const unElm = (rightGroupId === UNRELATED_AREA_GROUP) ? (null) : (<option key="l-group-select-u" value={UNRELATED_AREA_GROUP}>《{t("options.notBelongCategory") }》</option>)
        if (groupList && editingAreaPack) {
            const filtered = groupList.filter(el => el.group_id !== rightGroupId)
            const ops = filtered.map((el, i) => {
                const cnt = (editingAreaPack.area_list) ? editingAreaPack.area_list.filter(area => area.group_id === el.group_id).length : 0
                return (<option key={'l-group-select-' + i + 1} value={el.group_id}>{el.name}{cnt ? "［" + cnt + "］" : ""}</option>)
            })
            if (ops) return [elm, ...ops, unElm]
        }
        return [elm]
    }, [groupList, t, rightGroupId, editingAreaPack])

    // 右側グループSelectのOptionリスト
    const rightGroupSelectOption = useMemo(() => {
        const elm = (<option key="r-group-select-x" value={0}>{t("msgSelectAreaCategory")}</option>)
        const unElm =  (leftGroupId === UNRELATED_AREA_GROUP) ? (null) : (<option key="r-group-select-u" value={UNRELATED_AREA_GROUP}>《{t("options.notBelongCategory") }》</option>)
        if (groupList && editingAreaPack) {
            const filtered = groupList.filter(el => el.group_id !== leftGroupId)
            const ops = filtered.map((el, i) => {
                const cnt = (editingAreaPack.area_list) ? editingAreaPack.area_list.filter(area => area.group_id === el.group_id).length : 0
                return (<option key={'r-group-select-' + i + 1} value={el.group_id}>{el.name}{cnt ? "［" + cnt + "］" : ""}</option>)
            })
            if (ops) return [elm, ...ops, unElm]
        }
        return [elm]
    }, [groupList, t, leftGroupId, editingAreaPack])

    // 左側（グループ所属エリアリスト）の作成
    const leftAreaList = useMemo(() => {
        const result: any = []
        if (editingAreaPack) {
            for (let area of editingAreaPack.area_list) {
                if (area.group_id === leftGroupId || (!area.group_id && leftGroupId === UNRELATED_AREA_GROUP)) {
                    const chk = leftChecks.find(el => el.areaId === area.area_id)
                    const itm = (
                        <tr key={area.area_id} onMouseOver={handleLeftRowOver} onMouseOut={handleLeftRowOut}>
                            <td className={style.tdChk}><CustomCheckbox label={""} value={area.area_id.toString()} check={chk ? chk.checked : false} onChange={handleCheckbox} /></td>
                            <td className={style.tdNo}>{area.area_number }</td>
                            <td className={style.tdName}>{area.name }</td>
                        </tr>
                    )
                    result.push(itm)
                }
            }
        }
        return result
    }, [editingAreaPack, leftGroupId, leftChecks, handleCheckbox])

    // 右側（未所属エリアリスト）の作成
    const rightAreaList = useMemo(() => {
        const result: any = []
        if (editingAreaPack) {
            for (let area of editingAreaPack.area_list) {
                if (area.group_id === rightGroupId || (!area.group_id && rightGroupId === UNRELATED_AREA_GROUP)) {
                    const chk = rightChecks.find(el => el.areaId === area.area_id)
                    const itm = (
                        <tr key={area.area_id} onMouseOver={handleRightRowOver} onMouseOut={handleRightRowOut}>
                            <td className={style.tdChk}><CustomCheckbox label={""} value={area.area_id.toString()} check={chk ? chk.checked : false} onChange={handleCheckbox} /></td>
                            <td className={style.tdNo}>{area.area_number }</td>
                            <td className={style.tdName}>{area.name }</td>
                        </tr>
                    )
                    result.push(itm)
                }
            }
        }
        return result
    }, [editingAreaPack, rightGroupId, rightChecks, handleCheckbox])

    const labelMoveToLeft = useMemo(() => { return t("button.moveFromRightToLeft") }, [t])
    
    const labelMoveToRight = useMemo(() => { return t("button.moveFromLeftToRight") }, [t])

    return (
        <ModalFrame page={PageName.InfoCategory} title={t("header.areaRegistrationEdit")} width={1120} height={780}>
            <div className={style.panel}>
                <div className={style.top}>
                    <div className={style.title}>{t("menu.relationAreaAndCategory")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                    {isProduction ? (<TrackingPageView page_title="bsinfo-category-edit" />) : (null)}
                </div>
                <div className={style.head}>
                    <div className={style.indicate}>
                        <div className={style.row}><span className={style.label}>{t("companyName")}：</span><span className={style.name}>{companyName}</span></div>
                        <div className={style.row}><span className={style.label}>{t("storeName")}：</span><span className={style.name}>{shopName}</span></div>
                        <div className={style.row}><span className={style.label}>{t("layout")}～{t("area")}：</span><span className={style.name}>{layoutAndAreas}</span></div>
                    </div>
                    <div className={style.control}>
                        <div className={style.btn1st}>
                            {
                                ableEdit ? (<Button name="cancel" label={t("cancel")} onClick={handleClose} styleType={StyleType.Reverse} />) : (null)
                            }
                        </div>
                        <div className={style.btn2nd}>
                            {
                                ableEdit ? (<Button name="done" label={t("enterWithSpace")} onClick={handleAccept} styleType={StyleType.Normal} />) : (null)
                            }
                        </div>
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.leftSide}>
                        <div className={style.leftGrpSel}>
                            <label className={style.label} htmlFor="left_group_select">{t("label.areaCategorySelection")}：</label>
                            <select id="left_group_select" className={style.groupSelect} value={leftGroupId} onChange={handleLeftGroupChanged}>
                                {leftGroupSelectOption}
                            </select>
                        </div>
                        <div className={style.leftList}>
                            <table className={style.table}>
                                <thead>
                                    <tr>
                                        <th className={style.thChk}></th>
                                        <th className={style.thNo}>{t("number")}</th>
                                        <th className={style.thName}>{t("areaName")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leftAreaList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={style.mid}>
                        <div className={style.btnMove}>{
                            ableEdit ? (<button className={style.btnArrow} title={labelMoveToLeft} onClick={handleMoveToLeft}><ArrowLeftIcon size={24} /></button>) : (null)
                        }</div>
                        <div className={style.btnMove}>{
                            ableEdit ? (<button className={style.btnArrow} title={labelMoveToRight} onClick={handleMoveToRight}><ArrowRightIcon size={24} /></button>) : (null)
                        }</div>
                    </div>
                    <div className={style.rightSide}>
                        <div className={style.rightGrpSel}>
                            <label className={style.label} htmlFor="right_group_select">{t("label.areaCategorySelection")}：</label>
                            <select id="right_group_select" className={style.groupSelect} value={rightGroupId} onChange={handleRightGroupChanged}>
                                {rightGroupSelectOption}
                            </select>                            
                        </div>
                        <div className={style.rightList}>
                            <table className={style.table}>
                                <thead>
                                    <tr>
                                        <th className={style.thChk}></th>
                                        <th className={style.thNo}>{t("number")}</th>
                                        <th className={style.thName}>{t("areaName")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rightAreaList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ModalFrame>
    )
}