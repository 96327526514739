import React, { useMemo, useEffect, useState, useRef, ChangeEvent } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { EditModeType, UrlParameterType } from "../../types/Management"
import { CompanyModel } from "../../types/Management"
import { THRESHOLD_4_COMPANY, COMPANY_TERM_LEN } from "../../constants"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { ThresholdTimepicker } from "../../component/threshold_timepicker/ThresholdTimepicker"
import { TrackingPageView } from "../common/TrackingPageView"
import { ModalFrame } from "../common/ModalFrame"
import { BeAbleToAccess } from "../auth/BeAbleToAccess"
import DateUtil from "../../lib/DateUtil"

import style from "./CompanyEdit.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

interface Props {}

export const CompanyEdit: React.FC<Props> = (props) => {

	const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { companyList, saveCompany } = useManagementDataContext()

    const { canCreate4Company, ableToUpdate4Company, ableToDelete4Company } = useAccessControlContext()
    
    // パラメーター
    const search = useLocation().search
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => { return queryParams.get(UrlParameterType.Mode) }, [queryParams])
    const companyId = useMemo(() => { return queryParams.get(UrlParameterType.CompanyId) }, [queryParams])    

    const [companyName, setCompanyName] = useState<string>("")
    const [errCompanyName, setErrCompanyName] = useState<string>("")
    const [startDateNum, setStartDateNum] = useState<number | undefined>(undefined)
    const [endDateNum, setEndDateNum] = useState<number | undefined>(undefined)
    const [thresholdTime, setThresholdTime] = useState<number | undefined>(undefined)
    const [companyDesignator, setCompanyDesignator] = useState<string>("")
    const [errCompanyDesignator, setErrCompanyDesignator] = useState<string>("")
    const refSt = useRef<HTMLDivElement>(null)
    const refEd = useRef<HTMLDivElement>(null)

    // ユーザー権限（今のところrootのみ他社へのCRUC権限が付与されているのでこれでも大丈夫。もしもroot以外で利用となった場合は修正が必要と思われる）
    const ableNew = useMemo(() => { return canCreate4Company }, [canCreate4Company])
    const ableEdit = useMemo(() => {
        if (companyId) return ableToUpdate4Company(parseInt(companyId))
        return false
    }, [ableToUpdate4Company, companyId])
    const ableDelete = useMemo(() => {
        if (companyId) return ableToDelete4Company(parseInt(companyId))
        return false
    }, [ableToDelete4Company, companyId])
    const canChange = useMemo(() => { return ((mode === EditModeType.New && ableNew) || (mode === EditModeType.Edit && ableEdit)) }, [mode, ableNew, ableEdit])

    /**
     * 代表的なタイムゾーン値（i18nのロケールから求めている）
     * 例）jaなら540、enなら0
     *
    const typicalTimeZoneMinute = useMemo(() => {
        const tz = t("typicalTimeZoneMinute")
        return parseInt(tz)
    }, [t])*/

    /**
     * 選択された企業情報
     */
    const company = useMemo(() => {
        if (companyId) {
            const id = parseInt(companyId)
            const c = companyList.find(el => el.company_id === id)
            if (c) return c
        }
        return undefined
    }, [companyId, companyList])

    /**
     * 他の企業で利用されている企業識別子のリスト
     */
    const usedDesignators = useMemo(() => {
        const results: string[] = []
        if (companyList) {
            const id = (companyId) ? parseInt(companyId) : 0
            for (let com of companyList) {
                if ((id && com.company_id !== id) || id === 0) {
                    if (com.designator) results.push(com.designator)
                }
            }
        }
        return results
    }, [companyId, companyList])

    // 初期値設定
    useEffect(() => {
        if (mode && mode === EditModeType.New) {
            // 新規登録時
            const now = (new Date()).getTime()
            const fut = now + COMPANY_TERM_LEN
            if (startDateNum === undefined) setStartDateNum(now)
            if (endDateNum === undefined) setEndDateNum(fut)
            if (thresholdTime === undefined) setThresholdTime(THRESHOLD_4_COMPANY)
        } else {
            // 編集時
            if (company) {
                if (companyName === "") setCompanyName(company.name)
                if (startDateNum === undefined) {
                    // UTCからlocal timeへ
                    const localStart = company.start + 0 * 60000
                    setStartDateNum(localStart)
                }
                if (endDateNum === undefined) {
                    // UTCからlocal timeへ
                    const localEnd = company.end + 0 * 60000
                    setEndDateNum(localEnd)
                }
                if (thresholdTime === undefined) setThresholdTime(company.thresholdtime)
                if (companyDesignator === "" && company.designator) setCompanyDesignator(company.designator)
            }
        }
    }, [mode, company])

    /**
     * 閉じるボタン押下時の処理
     */
    const handleClose = () => {
        navigate("/company_list")
    }

    /**
     * 入力チェック
     * @returns 
     */
    const validationCheck = (): boolean => {
        const regexp = new RegExp(/^[a-zA-Z][_a-zA-Z0-9]+$/)
        const res = (companyDesignator.length > 0) ? regexp.test(companyDesignator) : false
        const inc = (companyDesignator.length > 0) ? usedDesignators.includes(companyDesignator) : false
        if (companyName && companyName.length > 0 && companyDesignator && companyDesignator.length > 0 && res && !inc) {
            return true
        } else {
            if (!(companyName && companyName.length > 0)) {
                const msg = t("validation.errInputCompanyName")
                setErrCompanyName(msg)
            }
            if (!(companyDesignator && companyDesignator.length > 0)) {
                const msg = t("validation.errNoInput")
                setErrCompanyDesignator(msg)
            } else if (!res) {
                const msg = t("validation.errInvalidCharactor")
                setErrCompanyDesignator(msg)
            } else if (inc) {
                const msg = t("validation.errSameNameExists")
                setErrCompanyDesignator(msg)
            }
            return false
        }
    }

    /**
     * キャンセルボタン押下時の処理
     */
    const handleCancel = () => {
        navigate("/company_list")
    }

    /**
     * 保存ボタン押下時の処理
     */
    const handleSave = () => {
        if (validationCheck()) {
            // LocalTimeからUTCへ
            const startUtc = (startDateNum ? startDateNum : 0) - 0 * 60000
            const endUtc = (endDateNum ? endDateNum : 0) - 0 * 60000

            // 新規登録時はcompany_id = 0
            const id = companyId ? parseInt(companyId) : 0
            
            const com: CompanyModel = {
                company_id: id,
                name: companyName,
                start: startUtc,
                end: endUtc,
                thresholdtime: thresholdTime ? thresholdTime : THRESHOLD_4_COMPANY,
                designator: companyDesignator,
                created_at: 0,
                modified_at: 0
            }
            saveCompany(com)
            navigate("/company_list")
        }
    }

    /**
     * 名称の入力変更処理
     * @param event 
     */
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value)
        if (errCompanyName) {
            if (event.target.value.length > 0) setErrCompanyName("")
        }
    }
    
    /**
     * 開始日の入力変更処理
     * @param event 
     */
    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        if (event) {
            const n = (event as Date).getTime()
            setStartDateNum(n)
        }
    }

    /**
     * 終了日の入力変更処理
     * @param event 
     */
    const handleChangeEndDate = (event: Date | [Date, Date] | null) => {
        if (event) {
            const n = (event as Date).getTime()
            setEndDateNum(n)
        }
    }

    /**
     * 滞在時間しきい値の入力変更処理
     * @param time 
     */
    const handleChangeThreshold = (time: number) => {
        setThresholdTime(time)
    }

    /**
     * 企業指定子の入力変更処理
     * @param event 
     */
    const handleChangeDesignator = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyDesignator(event.target.value)
        if (errCompanyDesignator) {
            if (event.target.value.length > 0) setErrCompanyDesignator("")
        }
    }

    /**
     * 開始日
     */
    const startDate = useMemo(() => {
        if (startDateNum) {
            const dt = new Date(startDateNum)
            if (dt) return dt
        }
    }, [startDateNum])
    
    /**
     * 終了日
     */
    const endDate = useMemo(() => {
        if (endDateNum) {
            const dt = new Date(endDateNum)
            if (dt) return dt
        }
    }, [endDateNum])

    /**
     * タイトル表示文字
     */
    const title = useMemo(() => {
        if (mode && mode === EditModeType.New) {
            return t("header.companyNewEntry")
        } else {
            return t("header.companyDetails")
        }
    }, [t, mode])

    /**
     * 企業ID表示文字
     */
    const strCompanyId = useMemo(() => {
        if (mode && mode === EditModeType.New) {
            return t("auto")
        } else {
            if (company) return company.company_id
        }
        return "-"
    }, [mode, t, company])

    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    const msgName = useMemo(() => { return t("msgInputCompanyName") }, [t])

    const msgDesignator = useMemo(() => { return t("msgInputCompanyDesignator") }, [t])

    return (
        <ModalFrame page={PageName.InfoCom} title={t("header.companyRegistrationEdit")} width={980} height={500}      >
            <div className={style.main}>
                <div className={style.head}>
                    {isProduction ? (<TrackingPageView page_title="bsinfo-company-edit" />) : (null)}
                    <div className={style.title}>{title}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose}/></div>
                </div>
                <BeAbleToAccess permission={PermissionTypes.ManageCompany_View}>
                    <div className={style.panel}>
                        <div className={style.left}>
                            <table className={style.table}>
                                <tbody>
                                    <tr>
                                        <td className={style.dtTitle}>{t("companyId")}</td>
                                        <td className={style.dtData}>{strCompanyId}</td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("companyName")}</td>
                                        <td className={style.dtInput}>
                                            <input type="text" value={companyName} placeholder={msgName} onChange={handleChangeName} disabled={!canChange} />
                                            {
                                                (errCompanyName) ? (<p><span className={style.errorMessage}>{errCompanyName}</span></p>) : (null)
                                            }                                        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("startDate")}</td>
                                        <td className={style.dtDP}>
                                            <div className={style.datePick}>
                                                <FupDatepicker
                                                    type={DpType.Micro}
                                                    onChange={handleChangeStartDate}
                                                    divRef={refSt}
                                                    selected={startDate}
                                                    locale={i18n.language}
                                                    dateFormat={datepickFormat}
                                                    dateFormatCalendar={datepickFormatCalendar}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("endDate")}</td>
                                        <td className={style.dtDP}>
                                            <div className={style.datePick}>
                                                <FupDatepicker
                                                    type={DpType.Micro}
                                                    onChange={handleChangeEndDate}
                                                    divRef={refEd}
                                                    selected={endDate}
                                                    locale={i18n.language}
                                                    dateFormat={datepickFormat}
                                                    dateFormatCalendar={datepickFormatCalendar}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("dashboardThreshold")}</td>
                                        <td className={style.dtThreshold}>
                                            <div className={style.threshold}>
                                                <ThresholdTimepicker
                                                    thresholdTime={thresholdTime ? thresholdTime : THRESHOLD_4_COMPANY}
                                                    interval={5}
                                                    min={0}
                                                    max={300}
                                                    onChange={handleChangeThreshold}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("companyDesignator")}</td>
                                        <td className={style.dtInput}>
                                            <input type="text" value={companyDesignator} placeholder={msgDesignator} onChange={handleChangeDesignator} disabled={!canChange} />
                                            {
                                                (errCompanyDesignator) ? (<p><span className={style.errorMessage}>{errCompanyDesignator}</span></p>) : (null)
                                            }                                        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("registrationDate")}</td>
                                        <td className={style.dtData}>{company ? DateUtil.epochtime2LocalYmdSlashHms(company.created_at, 0) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className={style.dtTitle}>{t("modifiedDate")}</td>
                                        <td className={style.dtData}>{company ? DateUtil.epochtime2LocalYmdSlashHms(company.modified_at, 0) : "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={style.right}>
                            <div className={style.rightTop}>
                                {
                                    (canChange) ? (<Button name="cancel" label="cancel" styleType={StyleType.Reverse} onClick={handleCancel} />) : (<></>)
                                }
                                <div className={style.spc}></div>
                                {
                                    (canChange) ? (<Button name="save" label="save" styleType={StyleType.Normal} onClick={handleSave} />) : (<></>)
                                }
                            </div>
                            <div className={style.rightMid}>
                            </div>
                            <div className={style.rightBtm}>
                                {
                                    (mode===EditModeType.Edit && ableDelete) ? (<></>) : (null)
                                }
                            </div>
                        </div>
                    </div>
                </BeAbleToAccess>
            </div>
        </ModalFrame>
    )
}