import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { BaseDateDistance, BaseDateDistanceType } from "./IndividualDashboard"
import { AverageAreaStayDatas, AreaStayAtomData } from "../../providers/StatisticsData"
import Utils from "../../lib/Utils"

import style from "./IndividualAverageAreaTable.module.css"

export const AvgAreaStayDataType = {
    StayTime: "time",
    StayRatio: "ratio"
} as const
export type AvgAreaStayData = typeof AvgAreaStayDataType[keyof typeof AvgAreaStayDataType]

interface Props {
    labelType: BaseDateDistance
    dataType: AvgAreaStayData
    datas: AverageAreaStayDatas | undefined
}

export const IndividualAverageAreaTable: React.FC<Props> = (props) => {

	const { t } = useTranslation()

    const avg: AreaStayAtomData[] | undefined = useMemo(() => {
        if (props.dataType === AvgAreaStayDataType.StayTime) {
            return (props.labelType === BaseDateDistanceType.Yesterday) ? props.datas?.time[0] : props.datas?.time[1]
        } else {
            return (props.labelType === BaseDateDistanceType.Yesterday) ? props.datas?.ratio[0] : props.datas?.ratio[1]
        }        
    }, [props])
    
    const getTop5 = () => {
        return [0, 1, 2, 3, 4].map(i => {
            let order = i + 1
            let name = ""
            let value: string = ""
            if (avg && avg.length >= i + 1) {
                const atm = avg[i]
                name = atm.name
                if (props.dataType === AvgAreaStayDataType.StayTime) {
                    value = Utils.formatMillisecToTime(atm.value)
                } else {
                    const v = atm.value * 100
                    value = Utils.formatCommaPointOne(v)
                }
                return (
                    <tr key={order}>
                        <td className={style.order}>{order}.</td>
                        <td className={style.name}>{name}</td>
                        <td className={(props.dataType === AvgAreaStayDataType.StayTime) ? style.timeValue : style.ratioValue}>
                            {value}
                        </td>
                    </tr>
                )
            } else {
                return null
            }
        })
    }

    return (
        <div className={style.main}>
            <div className={style.title}>
                {
                    (props.labelType === BaseDateDistanceType.Yesterday) ? t("appointedDay") : t("daysAgo")
                }
            </div>
            <div className={style.body}>
                <table>
                    <tbody>
                        {getTop5()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}