import { ILayoutSet, ILoginInfo, IShop, ResCompany, ResGroup, ResLayoutSet, ResLoginInfo, ResPartialLayout, ResShop, ResUser } from "../data/login/LoginInfo";

export class LayoutSetImpl implements ILayoutSet {
    layout_set_id: number;
    name: string;
    layout: ResPartialLayout[];
    is_ready: boolean;

    _id2layout: Record<number, ResPartialLayout>;

    constructor(layoutSet: ResLayoutSet) {
        this.layout_set_id = layoutSet.layout_set_id
        this.name = layoutSet.name
        this.layout = layoutSet.layout
        this._id2layout = {}
        if (this.layout && this.layout.length > 0) {
            for (const l of this.layout) {
                this._id2layout[l.layout_id] = l;
            }
        }
        this.is_ready = layoutSet.is_ready;
    }

    get_layout(id: number): ResPartialLayout | null {
        if (this._id2layout.hasOwnProperty(id)) {
            return this._id2layout[id];
        }
        return null;
    }
}

export class ShopInfo implements IShop {
    shop_id: number;
    name: string;
    start: number;
    timezone: number;
    shop_designator: string;
    display_order: number;
    hot_layout_set: ResLayoutSet;
    cold_layout_set: ResLayoutSet[] | null
    inserttime: number;
    updatetime: number;

    _id2layout: Record<number, ILayoutSet>;
    _layout_id2pair: Record<number, [ILayoutSet, ResPartialLayout]>;

    constructor(shop: ResShop) {
        this.shop_id = shop.shop_id
        this.name = shop.name
        this.start = shop.start
        this.timezone = shop.timezone
        this.shop_designator = shop.shop_designator
        this.display_order = shop.display_order
        this.hot_layout_set = shop.hot_layout_set
        this.cold_layout_set = shop.cold_layout_set
        this.inserttime = shop.inserttime
        this.updatetime = shop.updatetime
        // IDからレイアウト情報を取得するためにマップに入れておく
        this._id2layout = {}
        this._layout_id2pair = {}
        this._id2layout[this.hot_layout_set.layout_set_id] = (new LayoutSetImpl(this.hot_layout_set));
        if (this.hot_layout_set && this.hot_layout_set.layout && this.hot_layout_set.layout.length > 0) {
            for (const l of this.hot_layout_set.layout) {
                this._layout_id2pair[l.layout_id] = [
                    new LayoutSetImpl(this.hot_layout_set), l]
            }
        }
        if (this.cold_layout_set != null) {
            for (const l of this.cold_layout_set) {
                this._id2layout[l.layout_set_id] = new LayoutSetImpl(l)
                for (const li of l.layout) {
                    this._layout_id2pair[li.layout_id] = [
                        new LayoutSetImpl(this.hot_layout_set), li]
                }
            }
        }
    }

    get_layout_set(layout_set_id: number): ILayoutSet | null {
        if (this._id2layout.hasOwnProperty(layout_set_id)) {
            return this._id2layout[layout_set_id];
        }
        return null;
    }

	get_layout(layout_id: number): [ILayoutSet, ResPartialLayout] | null {
        if (this._layout_id2pair.hasOwnProperty(layout_id)) {
            return this._layout_id2pair[layout_id];
        }
        return null;
    }
}

export class LoginInfo implements ILoginInfo {
    user: ResUser;
    company: ResCompany;
    shops: ShopInfo[];
    groups: ResGroup[];

    _id2shop: Record<number, ShopInfo>;
    _id2group: Record<number, ResGroup>;

    constructor(loginfo: ResLoginInfo) {
        this.user = loginfo.user
        this.company = loginfo.company
        this.shops = loginfo.shops.map(s => new ShopInfo(s))
        this.groups = loginfo.groups
        this._id2shop = {}
        for (const shop of this.shops) {
            this._id2shop[shop.shop_id] = shop;
        }
        this._id2group = {}
        for (const group of this.groups) {
            this._id2group[group.group_id] = group
        }
    }

    get_shop(id: number): IShop | null {
        if (this._id2shop.hasOwnProperty(id)) {
            return this._id2shop[id];
        }
        return null;
    }

    get_group(id: number): ResGroup | null {
        if (this._id2group.hasOwnProperty(id)) {
            return this._id2group[id];
        }
        return null;
    }
}