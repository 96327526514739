import { ResSearchQuery } from "../../api/data/analysis/AnalysisRequest";
import { QueryLogType } from "../../types"
import { CompareSideType } from "../../types/Analyze"
import { analysisParamSet2ResSearchQuery } from "../../lib/ModelUtil"

export const getResSearchQuerys = (): ResSearchQuery[] => {
    const logs = getQueryLogs()
    const res = logs.map(el => {
        return analysisParamSet2ResSearchQuery(el)
    })
    return res
}

export const getQueryLogs = (): QueryLogType[] => {
    return [
                {
                    id: 15,
                    insertAt: 1662946312,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-08-01",
                        endDate: "2022-08-31",
                        startTime: 800,
                        endTime: 2200,
                        weekday: [true, true, true, true, true, true, true],// "Su|M|Tu|W|Th|F|Sa" or "full"
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 14,
                    insertAt: 1662168712,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-07-01",
                        endDate: "2022-07-31",
                        startTime: 900,
                        endTime: 2100,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 13,
                    insertAt: 1661909512,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 12,
                    insertAt: 1661785200,
                    query: {
                        shopId: 22,
                        layoutId: 2,
                        startDate: "2022-05-01",
                        endDate: "2022-05-31",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, false, true, true, true, true, true],
                        buyOrNot: [false, true],
                        excludeClerk: true,
                        threshold: 60,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 11,
                    insertAt: 1661698800,
                    query: {
                        shopId: 22,
                        layoutId: 2,
                        startDate: "2022-05-01",
                        endDate: "2022-05-15",
                        startTime: 800,
                        endTime: 2200,
                        weekday: [true, false, true, false, true, true, true],
                        buyOrNot: [true, false],
                        excludeClerk: false,
                        threshold: 120,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 10,
                    insertAt: 1661612400,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, false, true, false, true, false, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 9,
                    insertAt: 1661526000,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, false, false, false, false, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 8,
                    insertAt: 1661439600,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [false, false, false, false, false, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 7,
                    insertAt: 1661353200,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [false, false, false, false, false, true, false],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 6,
                    insertAt: 1661266800,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 5,
                    insertAt: 1661180400,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 4,
                    insertAt: 1661094000,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 3,
                    insertAt: 1661007600,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 2,
                    insertAt: 1660921200,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }, {
                    id: 1,
                    insertAt: 1660834800,
                    query: {
                        shopId: 1,
                        layoutId: 1,
                        startDate: "2022-06-01",
                        endDate: "2022-06-30",
                        startTime: 700,
                        endTime: 2300,
                        weekday: [true, true, true, true, true, true, true],
                        buyOrNot: [true, true],
                        excludeClerk: false,
                        threshold: 30,
                        side: CompareSideType.Primary,
                        valid: true
                    }
                }]
}