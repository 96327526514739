import { FC, useEffect, useRef, useState, useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { PageName } from "../../types"
import { Stepper } from "../../component/stepper/Stepper"
import { Slider } from "../../component/slider/Slider"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { CustomDialog, AcceptButtonType } from "../../component/custom_dialog/CustomDialog"
import { ModalFrame } from "../common/ModalFrame"
import { useMouseMove } from "../../lib/useMouseMove"
import { ZOOM_CENTER } from "../../lib/CanvasWorker"

import style from "./LayoutRegiStep2.module.css"

interface Props { }

export const LayoutRegiStep2: FC<Props> = (props) => {
    const { t, i18n } = useTranslation()    
    const navigate = useNavigate()
    const location = useLocation()

    const { worker } = useManagementDataContext()

    const [volume, setVolume] = useState<number>(ZOOM_CENTER)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    
    const refBas = useRef<HTMLCanvasElement | null>(null)
    const refMov = useRef<HTMLCanvasElement | null>(null)
    const refCrs = useRef<HTMLCanvasElement | null>(null)
    const refFirst = useRef<boolean>(true)
    
    /**
     * ステップラベルのリスト
     */
    const stepperLabelList = useMemo(() => {
        const stp1 = t("label.layoutRegiStep1")
        const stp2 = t("label.layoutRegiStep2")
        const stp3 = t("label.layoutRegiStep3")
        const stp4 = t("label.layoutRegiStep4")
        const stp5 = t("label.layoutRegiStep5")
        return [stp1, stp2, stp3, stp4, stp5]
    }, [t])

    const handleClose = () => {
        if (!modalOpen) setModalOpen(true)
    }

    const onCanceledClose = () => {
        setModalOpen(false)
    }

    const onAcceptedClose = () => {
        navigate("/layout_list")
    }

    const handleHistoryBack = () => {
        navigate("/layout_regi_step1")
    }

    const onClickNext = () => {
        if (worker) {
            // mm_per_px, origin_x, origin_y を計算
            worker.basicCalculateParameters()
            worker.printBasic()
        }
        navigate("/layout_regi_step3", { state: { from: location } })
    }

    const refreshMap = useCallback(() => {
        if (worker) {
            if (refBas && refBas.current) {
                const ctx = refBas.current.getContext("2d")
                if (ctx) {
                    worker.clearCanvas(ctx)
                    worker.drawBasicFitImage(ctx)
                    //console.log("refresh")
                }
            }
            if (refCrs && refCrs.current) {
                const mes = refCrs.current.getContext("2d")
                if (mes) {
                    worker.clearCanvas(mes)
                    worker.drawMesure(mes).then(res => {
                        worker.drawCross(mes).then(res => {
                            //console.log("crossing.")
                        })
                    })
                }
            }
            if (refMov && refMov.current) {
                const ani = refMov.current.getContext("2d")
                if (ani) worker.clearCanvas(ani)
            }
        }
    }, [refBas, refCrs, refMov, worker])

    const onChangeVolume = (value: number) => {
        setVolume(value)
        if (worker) {
            if (worker.basZoomVol !== value) {
                console.log("zoom volume changed.")
                worker.basicZoomInOut(value)
                refreshMap()
            }
        }
    }

    const mousePress = useMouseMove(refMov, (event: MouseEvent) => {
        if (worker) {
            const srcCanvas = refBas.current
            const dstCanvas = refMov.current
            if (srcCanvas && dstCanvas) worker.basicMouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        refreshMap()
    }, (event: MouseEvent) => {
        // マウスボタンを押したときの処理
        if (worker) {
            worker.basicMousePress()
        }
    }
    )

    // 初回レンダー時の処理
    useEffect(() => {
        // 開発時のStrictModeで二重レンダーされるのを防ぐ
        if (process.env.NODE_ENV === "development") {
            if (refFirst.current) {
                refFirst.current = false
                console.log("SKIP first render")
                return
            }
        }

        //if (mousePress) {
            //if (worker) worker.basicMousePress()
        //} else {
            refreshMap()
        //}
    }, [])

    const message = useMemo(() => {
        if (i18n.language === "ja") {
            return (<div className={style.guide}>STEP1でアップロードした原点図面の<span className={style.strong}>原点位置</span>と、左上の<span className={style.strong}>赤十字の中央</span>が<span className={style.strong}>一致</span>するように、原点図面を動かしてください。</div>)
        } else {
            return (<div className={style.guide}>Move the origin drawing so that <span className={style.strong}>the origin position</span> of the origin drawing uploaded in STEP1 <span className={style.strong}>matches</span> the center of the upper left <span className={style.strong}>red cross</span>.</div>)
        }        
    }, [i18n])

    return (
        <ModalFrame page={PageName.InfoMap} title={t("menu.mapRegistrationEdit")} width={1110} height={750} >
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>{t("header.layoutNewEntry")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.indicator}>
                    <div className={style.steps}>
                        <Stepper activeNumber={2} labels={stepperLabelList}></Stepper>
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.subTitle}>{t("label.layoutRegiStep2") }</div>
                        {message}
                    <div className={style.canvasWrap}>
                        <canvas className={style.canvasBas} width={753} height={435} ref={refBas}></canvas>
                        <canvas className={style.canvasCrs} width={753} height={435} ref={refCrs}></canvas>
                        <canvas className={style.canvasMov} width={753} height={435} ref={refMov}></canvas>
                    </div>
                    <div className={style.zoombar}>
                        <Slider
                            value={volume}
                            withBorder={true}
                            withButton={true}
                            max={ZOOM_CENTER * 2}
                            min={0}
                            onChange={onChangeVolume}
                            size="s"
                        />
                    </div>
                    <div className={style.navi}>
                        <Button name="next" label={t("button.toTheNext")} styleType={StyleType.Normal} onClick={onClickNext}/>
                    </div>
                    <div className={style.back}>
                        <Button name="back" label={t("back")} styleType={StyleType.MuliInact} onClick={handleHistoryBack}/>
                    </div>
                </div>
            </div>
            <CustomDialog
                requestOpen={modalOpen}
                width={500}
                height={170}
                guideMessage={t("guidance.cancelLayoutRegi")}
                buttonType={AcceptButtonType.Ok}
                onCancel={onCanceledClose}
                onAccept={onAcceptedClose}
            >
            </CustomDialog>
        </ModalFrame>
    )
}