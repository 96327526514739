import React from "react"

import style from "./SlideSwitch.module.css"

interface Props {
    isOn: boolean
    onChange: React.MouseEventHandler<HTMLDivElement>
}

export const SlideSwitch: React.FC<Props> = (props) => {
    
    const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        props.onChange(event)
    }

    const handleDummy = () => {
        // Reactの defaultCheck() or checked 関係のエラー回避
        // ここに来ることはない
        console.log("checkbox dummy handler")
    }

    return (
        <div className={style.main} onClick={handleOnClick}>
            <input type="checkbox" id="slideswitch" checked={props.isOn} onChange={handleDummy} />
            <label htmlFor="slideswitch"><span></span></label>
            <div className={style.iconImg}></div>
        </div>
    )
}

