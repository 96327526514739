import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"

import HeatMapWriter from "../../lib/HeatMapWriter"
import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { BaseDateDistance, BaseDateDistanceType } from "./IndividualDashboard"
import { DashboardAreaCount } from "../../api/data/dashboard/DashboardResult"
import { TxStatus, TxStatusType } from "../../types"
import { TailSpin } from "react-loader-spinner"

import style from "./IndividualHeatmap.module.css"

interface Props {
    type: BaseDateDistance
    shopId: number
    baseDate: Date
    layout: ResLayout | undefined
    data: DashboardAreaCount[] | undefined
    status: TxStatus
    zoomUp?: boolean
}

const MINIHEATMAP_WIDTH = 333
const MINIHEATMAP_HEIGHT = 219

const ZOOM_UP_WIDTH = 500
const ZOOM_UP_HEIGHT = 460

export const IndividualHeatmap: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const ref = useRef<HTMLCanvasElement>(null)
    const refFirst = useRef(true)

    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current && !props.zoomUp) {
                refFirst.current = false
                return
            }
        }
        if (props.layout && props.data) {
            const areaList: ResArea[] = props.data.map(el => el.area)
            const writer = (!props.zoomUp) ? new HeatMapWriter(props.layout, areaList, MINIHEATMAP_WIDTH, MINIHEATMAP_HEIGHT) : new HeatMapWriter(props.layout, areaList, ZOOM_UP_WIDTH, ZOOM_UP_HEIGHT)
            writer.pathDepth = 2
            const ctx: CanvasRenderingContext2D | null | undefined = ref.current?.getContext("2d")
            if (ctx) {
                if (props.data) {
                    writer.drawAreaHeatMap4Dashboard(ctx, props.data).then(res => {
                        console.log("MiniHeatmap Drawn")
                    }).catch(err => {
                        console.error(err)
                    })
                }
            }
        }
    }, [props.layout, props.data, ref, props.zoomUp])

    return (
        <div className={!props.zoomUp ? style.main : style.mainLarge}>
            <div className={!props.zoomUp ? style.title : style.titleLarge}>
                {
                    (props.type === BaseDateDistanceType.Yesterday) ? t("appointedDay") : t("daysAgo")
                }
            </div>
            <div className={style.body}>
                {
                    (props.status === TxStatusType.Fulfilled) ? (
                        <canvas ref={ref} className={style.canvas} width={!props.zoomUp ? MINIHEATMAP_WIDTH : ZOOM_UP_WIDTH} height={!props.zoomUp ? MINIHEATMAP_HEIGHT : ZOOM_UP_HEIGHT}></canvas>
                    ) : (
                        <div className={style.spiner}>
                            <TailSpin color="#1E5EFF" width={50} height={50} />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
