import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import { Trans } from "react-i18next"

import { Header } from "./Header"
import { SideMenu } from "./SideMenu"
import { Pages, PageName } from "../../types"
import { useAuthUserContext } from "../../providers"
//import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { useModal, StyleType } from "../../component/modal/Modal"
import { Button, StyleType as BtnStyle } from "../../component/button/Button"
import { TIMEOUT_MINUTE, DEFAULT_EVENTS } from "../../constants"
import { OverlaySpinner, SpinerStyleType } from "../../component/overlaySpinner/OverlaySpinner"
import { useManagementDataContext } from "../../providers/ManagementData"
import { TrackingPageView } from "./TrackingPageView"
import { Footer } from "./Footer"

import style from "./BaseFrame.module.css"

/**
 * マウス操作を検出したらコールバックを実行
 * @param callback 
 * @param events 
 */
const useMouseClick = <E extends Event = Event>(
    callback: (event: E) => void,
    events: string[] = DEFAULT_EVENTS
) => {
    // Refにコールバックを保存
    const savedCallback = useRef(callback)
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
    useEffect(() => {
        // イベントが発生したらコールバックを実行
        const handler = (event: any) => {
            event.stopPropagation()
            savedCallback.current(event)
        }
        // リスナー登録
        events.forEach(eventName => document.addEventListener(eventName, handler))
        // 後始末
        return () => {
            events.forEach(eventName => document.removeEventListener(eventName, handler))
        }
    }, [events])
}

/**
 * キーボード入力を検出したらコールバックを実行
 * @param callback 
 */
const useKeypress = (callback:()=>void) => {
    const savedCallback = useRef(callback)
    useEffect(() => {
        const onKeyup = (event: KeyboardEvent) => {
            savedCallback.current()
        }
        document.addEventListener('keyup', onKeyup)
        return () => document.removeEventListener('keyup', onKeyup)
    }, [])
}

export const ContentsFrameType = {
    Basic: "basic",         // 基本：サイドメニューあり
    NoSideMenu: "noMenu",   // サイドメニューなし（左寄せ可能）
    HideMenu: "hideMenu"    // サイドメニュー非表示（左側にメニュー部分の余白ができる）
} as const
export type ContentsFrame = typeof ContentsFrameType[keyof typeof ContentsFrameType]

interface Props {
    children: React.ReactNode
    actPage: Pages
    type?: ContentsFrame
}

/**
 * 基本的な画面のフレーム構成を提供します。
 * また、マウスクリック操作を監視し、無操作状態が一定時間以上経過した場合にログアウトします。
 * 
 * @param props 
 * @returns 
 */
export const BaseFrame = (props: Props) => {

	//const { t } = useTranslation()
    const { timedOut, callTimeout } = useAuthUserContext()
    //const { reset } = useAnalyticsDataContext()
    const { waitingForReturn } = useManagementDataContext()

    //モーダル
    const [Modal, open, close, isOpen] = useModal('root', { styleType: StyleType.Dialog })
    //Timeoutオブジェクトの保存用
    const timeRef = useRef<number | null>(null)
    //タイムアウトフラグ
    const [isTimeout, setIsTimeout] = useState<boolean>(false)

    const allTimerClear = useCallback(() => {
        if (timeRef !== null && timeRef.current) {
            window.clearTimeout(timeRef.current)
            timeRef.current = null
        }
    }, [])

    // タイムアウトしたときの処理
    const intoTimeout = useCallback(() => {
        if (isTimeout) return
        if (timedOut) return
        // ダイアローグ表示
        open()
        setIsTimeout(true)
        allTimerClear()
    }, [open, isTimeout, allTimerClear, timedOut])

    // タイムアウト時間の再設定
    const updateTimer = useCallback(() => {
        if (isTimeout) return
        if (timedOut) return
        allTimerClear()
        timeRef.current = window.setTimeout(intoTimeout, TIMEOUT_MINUTE)
    }, [intoTimeout, isTimeout, allTimerClear, timedOut])

    // 再ログインを実行
    const handleReLogin = (event: any) => {
        // タイムアウトをAuthUserに伝達
        if (!timedOut) callTimeout()
        // ダイアローグを閉じる
        if (isOpen) close()
        // サインアウト処理（これでログイン画面に飛ばされる）
        //signout(() => { })
        // 検索結果クリア
        //reset()
        setIsTimeout(false)
    }

    // 初期化
    useEffect(() => {
        if (props.actPage !== PageName.Logout && props.actPage !== PageName.Login) {
            if (!isTimeout) updateTimer()
        }
    }, [props.actPage, isTimeout, updateTimer])

    // マウス監視
    useMouseClick(() => { updateTimer() }, DEFAULT_EVENTS)

    // キーボード監視
    useKeypress(() => { updateTimer() })

    useEffect(() => {
        return () => {
            // 全タイマー解除
            allTimerClear()
            // スクロール不能の解除
            window.document.body.style.overflow = ''
        }
    }, [allTimerClear])
    
    const sideMenu = useMemo(() => {
        if (props.type) {
            if (props.type === ContentsFrameType.NoSideMenu) {
                return (<></>)
            } else if (props.type === ContentsFrameType.HideMenu) {
                return (<div className={style.hidemenu}></div>)
            }
        }
        return (<SideMenu actPage={props.actPage} />)
    }, [props])

    return (
        <div className={style.app}>
            <div className={style.head}>
                <Header />
            </div>
            <div className={style.main}>
                <div className={style.menu}>
                    {sideMenu}
                </div>
                <div className={style.contents}>
                    {props.children}
                </div>
            </div>
            <div className={style.foot}>
                <Footer/>
            </div>
            <Modal>
                <div className={style.timeout}>
                    <TrackingPageView page_title="timeout-relogin" />
                    <div className={style.message}>
						<Trans i18nKey={"msgTimeOut"}/>
                    </div>
                    <Button onClick={handleReLogin} label="reLogIn" name="resign" styleType={BtnStyle.Normal} />
                </div>
            </Modal>
            <OverlaySpinner waiting={waitingForReturn} spinerStyle={SpinerStyleType.ForManagement} />
        </div>
    )
}