import AreaDetailPicWriter from "./AreaDetailPicWriter";
import { PX_LABEL_RADIUS } from "./MapWriter"
import { EDIT_AREA_COLOR } from "./AreaEditPicWriter"

class CategoryPicWriter extends AreaDetailPicWriter {
    async activeAreasDraw(ctx: CanvasRenderingContext2D, areaIds: number[]) {
        ctx.save()
        ctx.scale(this.imageScale, this.imageScale)
        this.clearCanvas(ctx)
        if (this.area_list) {
            for await (let id of areaIds) {
                const area = this.area_list.find(el => el.area_id === id)
                if (area) {
                    const lines = this.convertCells2DrawLines(area.cell_ids)
                    if (lines.length > 0) {
                        const [minX, minY] = await this.getFourCorners(lines)
                        await this.drawAreaBorder(ctx, lines, EDIT_AREA_COLOR)
                        if (minX && minY) {
                            const pt = this.toPixelCoord(minX, minY)
                            await this.drawAreaNumber(ctx, pt.x, pt.y, PX_LABEL_RADIUS, EDIT_AREA_COLOR, "" + area.area_number)
                        }
                    }
                }
            }
        }
        ctx.restore()
    }
}

export default CategoryPicWriter;