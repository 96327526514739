import React from "react"

import { Array2 } from ".."
import { CustomCheckbox, CheckBoxStyle } from "../custom_checkbox/CustomCheckbox"

import style from './buyOrNotCheckbox.module.css'

const BuyOrNotType = {
    Buy: "buy",
    NotBuy: "not"
} as const
type BuyOrNot = typeof BuyOrNotType[keyof typeof BuyOrNotType]

interface Props {
    buyOrNot: Array2<boolean>
    onChange: (buyNot: Array2<boolean>) => void
}

export const BuyOrNotCheckbox: React.FC<Props> = (props) => {

    const handleChange = (chgAt: BuyOrNot) => {
        // 両方OFFにはしない。必ずどちらかONとなる。
        const buy = props.buyOrNot[0]
        const not = props.buyOrNot[1]
        if (buy && not) {
            // 両方ONの時は、チェックされた方をOFFにする
            const newval = [...props.buyOrNot] as Array2<boolean>
            const idx = (chgAt === BuyOrNotType.Buy) ? 0 : 1
            newval[idx] = !props.buyOrNot[idx]
            props.onChange(newval)
        } else if (buy === false && not === true && chgAt === BuyOrNotType.Buy) {
            // BUYがOFFでBUYを押された
            props.onChange([true, true])
        } else if (buy === true && not === false && chgAt === BuyOrNotType.NotBuy) {
            // NOTがOFFでNOTを押された
            props.onChange([true, true])
        } else if (buy === false && not === true && chgAt === BuyOrNotType.NotBuy) {
            // どちらもOFFにはできないのでSWAPする
            props.onChange([true, false])
        } else if (buy === true && not === false && chgAt === BuyOrNotType.Buy) {
            // どちらもOFFにはできないのでSWAPする
            props.onChange([false, true])
        }
    }
    
    return (
        <div className={style.border}>
            <div className={style.series}>
                <CustomCheckbox
                    type={CheckBoxStyle.Circle}
                    label="purchaser"
                    value={BuyOrNotType.Buy}
                    check={props.buyOrNot[0]}
                    onChange={e => handleChange(BuyOrNotType.Buy)}
                />
            </div>
            <div className={style.series}>
                <CustomCheckbox
                    type={CheckBoxStyle.Circle}
                    label="nonPurchaser"
                    value={BuyOrNotType.NotBuy}
                    check={props.buyOrNot[1]}
                    onChange={e => handleChange(BuyOrNotType.NotBuy)}
                />
            </div>
        </div>
    )
}