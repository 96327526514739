import { format } from 'date-fns'
import { parse } from "date-fns"
//import { utcToZonedTime } from 'date-fns-tz'
import i18n from 'i18next'

import { Array7 } from '../component'
import { STR_YMD_FORMAT } from '../constants'

/**
 * Dateオブジェクトは、1970-01-01 00:00:00 を基点のUTC時刻としている。タイムゾーンに関する情報はない。
 * parse は ISO 8601 表記に対応するが、タイムゾーン識別子は無視される。
 * 
 * date-fns のパースについて、
 *  １）date-fns.parseISO() はDateのときと同じ（タイムゾーン識別子は無視）
 *  ２）date-fns.zonedTimeToUtc() で'Asia/Tokyo'のようなタイムゾーンを指定すれば正しいUTCが得られる
 * フォーマットについて
 * 　date-fns.format() と date-fns-tz.format() は結果が異なるので注意！
 *      date-fns-tz.format() の ３つめの引数に{ timeZone: 'Asia/Tokyo'} を入れても時間はUTCのまま変わらない。この引数は、zやxのような書式に対するものでしかない。ふつう使われることはないだろう。
 * 　では、UTCなDateをAsia/Tokyoの日次にフォーマットするには、
 * [1]date-fns-tz.utcToZonedTime(utcDate, 'Asia/Tokyo')で日本時間に変える
 * [2]変換後のDateをdate-fns.format()する
 * 
 * 参考：「JavaScript: date-fnsでタイムゾーンを扱う」
 * https://qiita.com/suin/items/296740d22624b530f93a
 * 
 */
export default class DateUtil {

    /**
     * 「0以上の整数のみ」を判定します。
     * @param val 
     * @returns 
     */
    static isNumber(val: any) {
        const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/)
        return regexp.test(val)
    }

    /**
     * number(unix_time)かstring(yyyy-MM-dd)がわからない値を解析してDateに変換します。
     * @param val 
     * @returns 
     */
    static parseNumberOrString(val: any) {
        if (val) {
            if (this.isNumber(val)) {
                return new Date(val)
            } else if (typeof val === "string") {
                if (val === "") return undefined
                const dt = parse(val, STR_YMD_FORMAT, new Date())
                return dt
            }
        }
        return undefined
    }

    /**
     * StrTime("HH:mm"形式)をNumTime(hh*100+mm値)に変換します。
     * @param {string} s hh:mm 形式の文字列
     * @return {number} hh * 100 + mm を返す
     */
    static strTime2NumTime(strTime: string): number | undefined {
        const match = (/^(\d+):(\d+)$/).exec(strTime)
        if (match) return parseInt(match[1]) * 100 + parseInt(match[2])
        return undefined
    }

    /**
     * NumTimeをStrTime("HH:mm"形式)の時間表現に変換します。
     * @param {number} numTime hh*100+mm
     * @return {string} "hh:mm"形式
     */
    static numTime2StrTime(numTime: number): string | undefined {
        if (numTime!==undefined && numTime!==null && Number.isInteger(numTime)) {
            const h = Math.floor(numTime / 100)
            if (h > 99) return undefined
            const m = numTime % 100
            if (m > 59) return undefined
            const res = ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2)
            return res
        }
        return undefined
    }

    static utcNumTime2JstDate(numTime: number | undefined): Date | undefined {
        if (numTime!==undefined && numTime!==null && Number.isInteger(numTime)) {
            const h = Math.floor(numTime / 100)
            const m = numTime % 100
            // ＋９時間する
            const dt = new Date(((h + 9) * 60 + m) * 60 * 1000)
            return dt
        }
        return undefined
    }

    static utcNumTime2UtcDate(numTime: number | undefined): Date | undefined {
        if (numTime!==undefined && numTime!==null && Number.isInteger(numTime)) {
            const h = Math.floor(numTime / 100)
            const m = numTime % 100
            // そのままで
            const dt = new Date((h * 60 + m) * 60 * 1000)
            //console.log("utcNumTime2UtcDate numTime,utcDate", numTime, dt)
            return dt
        }
        return undefined
    }

    static jstNumTime2UtcDate(numTime: number | undefined): Date | undefined {
        if (numTime!==undefined && numTime!==null && Number.isInteger(numTime)) {
            const h = Math.floor(numTime / 100)
            const m = numTime % 100
            // －９時間する
            const dt = new Date(((h - 9) * 60 + m) * 60 * 1000)
            return dt
        }
        return undefined
    }

    /*static localDate2NumTime(date: Date | undefined): number | undefined {
        if (date) {
            const localDate = addHours(date, 9)
            const h = localDate.getHours()
            const m = localDate.getMinutes()
            return h * 100 + m
        }
        return undefined
    }*/

    static utcDate2UtcNumTime(date: Date | undefined): number | undefined {
        if (date) {
            const h = date.getHours()
            const m = date.getMinutes()
            return h * 100 + m
        }
        return undefined
    }

    static utcDate2JstNumTime(date: Date | undefined | null): number | undefined {
        if (date) {
            const tm = date.getTime() + 9 * 60 * 60 * 1000
            const jstDate = new Date(tm)
            const h = jstDate.getHours()
            const m = jstDate.getMinutes()
            return h * 100 + m
        }
        return undefined
    }

    static epocMilli2JstYmd(ms: number | undefined): string | undefined {
        if (ms !== undefined && ms !== null && Number.isInteger(ms)) {
            //const utcDt = new Date(ms)
            //console.log("UTC ",utcDt)
            //const jstDt = utcToZonedTime(utcDt, 'Asia/Tokyo')
            //console.log("JST ",jstDt)
            //const ymd = format(jstDt, "yyyy-MM-dd")
            const jst = new Date(ms + 9 * 60 * 60 * 1000)
            let fmt = i18n.t("dateFormat.ymd_hy")
            if (fmt === undefined) fmt = "MM-dd-yyyy"   // testでundefinedになるからenフォーマットをデフォルトにする
            const ymd = format(jst, fmt)
            return ymd
        }
        return undefined
    }

    /**
     * 曜日指定の表示用文字列を取得（分析条件の保存で利用）
     * @param weeks 
     * @returns 
     */
    static getWeekdaysText(weeks: Array7<boolean>):string  {
        let trueCnt = 0
        let trueIdx = []
        let falseIdx = []
        for (let i = 0; i < 7; i++) {
            if (weeks[i] === true) {
                trueCnt++
                trueIdx.push(i)
            } else {
                falseIdx.push(i)
            }
        }
        if (trueCnt === 7) return i18n.t("all")
        const weekdays = ["sun","mon","tue","wed","thur","fri","sat"]
        if (trueCnt === 6) {
            return i18n.t("excludeFor",{weekdays: i18n.t(weekdays[falseIdx[0]])})
        }
        if (trueCnt === 5) {
            return i18n.t("excludeFor",{weekdays: i18n.t(weekdays[falseIdx[0]]) + i18n.t(weekdays[falseIdx[1]])})
        }
        let res = ""
        trueIdx.forEach(el => {
            res = res + i18n.t(weekdays[el])
        })
        return res
    } 

    static dummyTime2Array(dummyTime: number | undefined): [number, number] {
        if (dummyTime !== undefined && dummyTime !== null && Number.isInteger(dummyTime)) {
            const h = Math.floor(dummyTime / 100)
            const m = dummyTime % 100
            return [h, m]
        }
        return [0, 0]
    }

    static weekArray2Object(aryWeek: Array7<boolean>) {
        return {
            Mon: aryWeek[1],
            Tue: aryWeek[2],
            Wed: aryWeek[3],
            Thu: aryWeek[4],
            Fri: aryWeek[5],
            Sat: aryWeek[6],
            Sun: aryWeek[0],
        }
    }

    static weekObject2Array(objWeek: Record<string, boolean> | null): Array7<boolean> {
        if (objWeek !== null) {
            return [
                objWeek["Sun"],
                objWeek["Mon"],
                objWeek["Tue"],
                objWeek["Wed"],
                objWeek["Thu"],
                objWeek["Fri"],
                objWeek["Sat"],
            ]
        } else {
            console.error("target_weekdays が null です")
            return [true,true,true,true,true,true,true]
        }
    }

    /**
     * numberの(ミリ)秒をDateに変換します。
     * 
     * データはepoch(ミリ)秒で保存されている前提だが、yyyyMMddHHmmssSSS のパターンと yyyyMMddHHmmss のパターンも存在する。桁数と最初の４文字が年であるかどうかで判定する。
     * 20231024146831000 のパターンと 20231024146831 のパターンがある。桁数と最初の４文字が年であるかどうかで判定する。
     * 以上のパターンは１４桁と１７桁であり、通常のミリ秒は１３桁、秒は１０桁なので、１４桁はミリ秒、１７桁は秒と判定する。
     * そうでない場合は、通常の秒かミリ秒かを判断する。
     * 2000-01-01 00:00:00 =   946,652,400,000 ms =   946,652,400 sec [UTC]
     * 2020-01-01 00:00:00 = 1,577,836,800,000 ms = 1,577,836,800 sec [UTC]
     * 2100-01-01 00:00:00 = 4,102,444,800,000 ms = 4,102,444,800 sec [UTC]
     * この期間のミリ秒と秒は重ならないので、入力値がミリ秒なのか秒なのかを判定することができる。
     * 
     * @param epocMillSec 
     * @returns 
     */
    static numberTimeToDate(epocMillSec: number | undefined): Date | undefined {
        let time: number = 0
        try {
            if (epocMillSec) {
                const strtime = epocMillSec.toString()
                if (strtime.length === 14 || strtime.length === 17) {
                    // １４桁と１７桁の場合(bigint)
                    const year = parseInt(strtime.substring(0, 4))
                    if (year > 2020 && year < 2100) {
                        const month = parseInt(strtime.substring(4, 6))
                        const day = parseInt(strtime.substring(6, 8))
                        const hour = parseInt(strtime.substring(8, 10))
                        const minute = parseInt(strtime.substring(10, 12))
                        const second = parseInt(strtime.substring(12, 14))
                        const dt = new Date(year, month - 1, day, hour, minute, second)
                        console.log("This date is bigint:", epocMillSec)
                        return dt
                    }
                }
                const mul = (epocMillSec < 1577836800000) ? 1000 : 1
                time = time + epocMillSec * mul
                return new Date(time)
            }
        } catch (err) {
            console.log("time:", epocMillSec)
            console.error(err)
        }
    }

    static numberTimeToSlashYmd(epocMillSec: number | undefined): string | undefined {
        const dt = this.numberTimeToDate(epocMillSec)
        if (dt) {
            const fmt = i18n.t("dateFormat.ymd_hy")
            return format(dt, fmt)
        }
        return undefined
    }

    /**
     * UnixEpoch時間(Ms)をローカル日付（yyyy年MM月dd日|MM/dd/yyy|...）に変換します。
     * 
     * @param epochMilliSec 
     * @param timezoneMinite 
     * @returns 
     */
    static epochtime2LocalYmd(epochMilliSec: number, timezoneMinute: number) {
        const dt = this.numberTimeToDate(epochMilliSec)
        if (dt) {
            const fmt = i18n.t("dateFormat.ymd")
            return format(dt, fmt)
        }
    }

    /**
     * UnixEpoch時間(Ms)をローカル日付（yyyy-MM-dd/ hh:mm:ss|MM/dd/yyy hh:mm:ss|...）に変換します。
     * 
     * @param epochMilliSec 
     * @param timezoneMinite 
     * @returns 
     */
    static epochtime2LocalYmdSlashHms(epochMilliSec: number, timezoneMinute: number) {
        const dt = this.numberTimeToDate(epochMilliSec)
        if (dt) {
            const fmt = i18n.t("dateFormat.ymd_hy_hms")
            return format(dt, fmt)
        }
    }

    /**
     * 与えられた文字列が日付フォーマットかどうかを判定します。
     * @param dateStr 
     * @returns 
     */
    static isValidDate(dateStr: string) {
        try {
            if (dateStr.length !== 10) return false            
            const dt = new Date(dateStr)
            return !isNaN(dt.getTime())
        } catch (err) {
            return false
        }
    }

    /**
     * 与えられた文字列を解析して日付を生成します。エラー時はnullを返します。
     * 多言語化対応。
     * CASE1 year省略
     *  CASE1-a 区切りあり : 1/1 | 1.1 | 1,1 | 1-1 -> 2021-01-01
     *  CASE1-b 4桁数字 : 0521 -> 2023-05-21(today:～2023-05-20)
     * CASE2 yearあり
     *  CASE2-a 区切りあり ja : 2021-01-01 | 2021/01/01 | 2021.01.01 | 2021,01,01 -> 2021-01-01
     *  CASE2-b 区切りあり en : 08-31-2021 | 08/31/2021 | 08.31.2021 | 08,31,2021 -> 2021-08-31
     *  CASE2-c 8桁数字 ja : 20210521 -> 2021-05-21(today:～2021-05-20)
     *  CASE2-d 8桁数字 en : 05182021 -> 2021-05-18(today:～2021-05-17)
     * @param dateStr
     * @param lang ja|en
     * @returns
     */
    static parser(str: string, lang: string = "en") {
        const now = new Date()
        const year = now.getFullYear()
        const month = now.getMonth() + 1
        const day = now.getDate()
        // 区切りあり年省略形
        const regShort = /^([0-9]{1,2})[\/\-\.\,]([0-9]{1,2})$/
        //console.log(regShort)
        const match = str.match(regShort)
        //console.log(match)
        if (match !== null) {
            const m = Number(match[1])
            const d = Number(match[2])
            if (m > 0 && m < 13 && d > 0 && d < 32) {
                if (m < month || (m === month && d < day)) {
                    return new Date(year + 1, m - 1, d)
                } else {
                    return new Date(year, m - 1, d)
                }
            }
        }
        // 区切りなし年省略形
        const regNums = new RegExp(`^([0-9]{2})([0-9]{2})$`)
        const matchNums = str.match(regNums)
        if (matchNums !== null) {
            const m = Number(matchNums[1])
            const d = Number(matchNums[2])
            if (m > 0 && m < 13 && d > 0 && d < 32) {
                if (m < month || (m === month && d < day)) {
                    return new Date(year + 1, m - 1, d)
                } else {
                    return new Date(year, m - 1, d)
                }
            }
        }
        // 区切りあり年あり
        const regexp = (lang === "ja") ? new RegExp(/^([0-9]{4})[\/\-\.\,]([0-9]{1,2})[\/\-\.\,]([0-9]{1,2})$/) : new RegExp(/^([0-9]{1,2})[\/\-\.\,]([0-9]{1,2})[\/\-\.\,]([0-9]{4})$/)
        const match10 = str.match(regexp)
        if (match10 !== null) {
            const y = (lang === "ja") ? Number(match10[1]) : Number(match10[3])
            const m = (lang === "ja") ? Number(match10[2]) : Number(match10[1])
            const d = (lang === "ja") ? Number(match10[3]) : Number(match10[2])
            if (y > 2000 && y < 2100 && m > 0 && m < 13 && d > 0 && d < 32) {
                return new Date(y, m - 1, d)
            }
        }
        // 区切りなし年あり
        const regN10 = (lang === "ja") ? new RegExp(`^([0-9]{4})([0-9]{1,2})([0-9]{1,2})$`) : new RegExp(`^([0-9]{1,2})([0-9]{1,2})([0-9]{4})$`)
        const matchN10 = str.match(regN10)
        if (matchN10 !== null) {
            const y = (lang === "ja") ? Number(matchN10[1]) : Number(matchN10[3])
            const m = (lang === "ja") ? Number(matchN10[2]) : Number(matchN10[1])
            const d = (lang === "ja") ? Number(matchN10[3]) : Number(matchN10[2])
            if (y > 2000 && y < 2100 && m > 0 && m < 13 && d > 0 && d < 32) {
                return new Date(y, m - 1, d)
            }
        }
        return null
    }
    

    /**
     * ローカル日付の'yyyy-MM-dd'をUnixEpochミリ秒に変換します。
     * @param localYmd 
     * @param timezoneMinute 
     * @returns 
     */
    static localYmd2Epochtime(localYmd: string, timezoneMinute: number) {
        let time: number = timezoneMinute * 60 * 1000
        try {
            const dt = parse(localYmd, STR_YMD_FORMAT, new Date())
            if (isNaN(dt.getDate())) return undefined
            const tm = dt.getTime() - time
            //console.log(dt, tm)
            return tm
        } catch (err) {
            console.error(err)
        }
    }
    
}