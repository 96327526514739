import React from "react"
import { useTranslation } from "react-i18next"

import style from './Footer.module.css'

interface Props {
    withPrivacyPolicy?: boolean
}

export const Footer: React.FC<Props> = (props) => {

    const { t } = useTranslation()

    // プライバシーポリシーリンクのデフォルトは非表示
    const pplink = props.withPrivacyPolicy ? true : false

    return (
        <div className={style.footer}>
            <div className={style.footerLeft}>
            </div>
            <div className={style.footerCenter}>
                <span className={style.footerCenterText}>&copy; Datasection Inc.</span>
            </div>
            <div className={style.footerRight}>
                <span className={style.footerRightText}>
                    {
                        pplink ? (<a href="https://www.datasection.co.jp/company/privacy" target="_blank" rel="noreferrer">{t("privacyPolicy")}</a>) : (<></>)
                    }
                </span>
            </div>
        </div>
    )
}