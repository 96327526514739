import React from "react"
import { CustomCheckbox } from "../custom_checkbox/CustomCheckbox"

import style from './excludeClerkCheckbox.module.css'

interface Props {
    exClerk: boolean
    onChange: (check: boolean) => void
}

export const ExcludeClerkCheckbox: React.FC<Props> = (props) => {

    const handleChange = () => {
        props.onChange(!props.exClerk)
    }
    
    return (
        <div className={style.border}>
            <CustomCheckbox
                label="excludeStaff"
                value="exClerk"
                check={props.exClerk}
                onChange={e => handleChange()}
            />
        </div>
    )
}