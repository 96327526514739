import React, { useEffect, useRef, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { HistoryButton } from "../../component/history_button/HistoryButton"
import { StyleType, useModal } from "../../component/modal/Modal"
import { CircleCloseButton, CloseStyleType } from "../../component/circle_close_button/CircleCloseButton"
import { ChevronRightIcon } from "@primer/octicons-react"
import { AnalysisParameterSetType, AnalyzeView, AnalyzeViewType } from "../../types/Analyze"
import { useAuthUserContext } from "../../providers"
import Utils from "../../lib/Utils"
import DateUtil from "../../lib/DateUtil"
import { formatSec2MMSS } from "../../component/TimeFormat"
import { ResPartialLayout, ResShop } from "../../api/data/login/LoginInfo"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { QueryLogType } from "../../types"

import style from "./ListOfSavedSearches.module.css"

interface Props {
    handleChoice: (param: AnalysisParameterSetType) => void
    viewSide: AnalyzeView
}

export const ListOfSavedSearches: React.FC<Props> = (props) => {

	const { t } = useTranslation()

    const [Modal, open, close, isOpen, resize] = useModal('root', { preventScroll: false, closeOnOverlayClick: false, styleType: StyleType.Page })
    const { userInfo } = useAuthUserContext()
    const { analyzeQuerys, fetchAnalyzeQuerys } = useAnalyticsDataContext()

    const refFirst = useRef(true)

    // モーダルの高さ
    //const [modalHeight, setModalHeight] = useState(0)

    useEffect(() => {
        // モーダル高さ計算
        //console.log("モーダル高さ計算")
        const calcModalHeight = () => {
            if (isOpen) {
                const len = (analyzeQuerys) ? analyzeQuerys.length : 0
                return 66 + 37 * len
            }
            return 0
        }
        const h = calcModalHeight()
        //setModalHeight(h)
        resize({width: undefined, height: h})
    }, [isOpen, resize, analyzeQuerys])

    const searchShop = (id: number | undefined): ResShop | undefined => {
        if (id && userInfo && userInfo.shops) {
            return userInfo.shops.find(el => el.shop_id === id)
        }
        return undefined
    }

    const searchLayout = (id: number | undefined, shop: ResShop | undefined): ResPartialLayout | undefined => {
        if (id && shop && shop.hot_layout_set) {
            return shop.hot_layout_set.layout?.find(el => el.layout_id === id)
        }
        return undefined
    }

    const handleHistoryOpen = () => {
        if (!isOpen) open()
    }

    const handleClosing = () => {
        if (isOpen) close()
    }

    const handleSetSavedSearches = (id: number) => {
        if (analyzeQuerys) {
            const target = analyzeQuerys.find(el => el.id === id)
            if (target) {
                props.handleChoice(target.query)
                handleClosing()
            }
        }
    }

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.add(style.trOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.remove(style.trOver)
    }, [])

    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                console.log("SKIP first fetchAnalyzeQuerys")
                return
            }
        }
        if (analyzeQuerys === undefined && props.viewSide === AnalyzeViewType.Single) {
            // 単一の場合のみ、クエリ履歴を取得
            fetchAnalyzeQuerys()
        }
    }, [analyzeQuerys, fetchAnalyzeQuerys, props.viewSide])
    
    let items: any = []
    if (analyzeQuerys && analyzeQuerys.length > 0) {
        for (let i = 0; i < 14; i++) {
            if (i < analyzeQuerys.length) {
                const el: QueryLogType = analyzeQuerys[i]
                const shop = searchShop(el.query.shopId)
                const shopName = (shop) ? shop.name : ""
                const layout = searchLayout(el.query.layoutId, shop)
                const layoutName = (layout) ? layout.name : ""
                const startDate = (el.query.startDate) ? el.query.startDate : " - "
                const endDate = (el.query.endDate) ? el.query.endDate : " - "
                const startTime = (el.query.startTime) ? DateUtil.numTime2StrTime(el.query.startTime) : " - "
                const endTime = (el.query.endTime) ? DateUtil.numTime2StrTime(el.query.endTime) : " - "
                const week = el.query.weekday
                const row = (
                    <tr key={el.id} onMouseOver={handleRowOver} onMouseOut={handleRowOut} onClick={e => handleSetSavedSearches(el.id)}>
                        <td className={style.dataColEllipsis}>{Utils.epochMilliToJtime(el.insertAt * 1000).slice(0,10)}</td>
                        <td className={style.dataColEllipsis}>{shopName}</td>
                        <td className={style.dataColEllipsis}>{layoutName}</td>
                        <td className={style.dataColEllipsis}>{startDate + "〜" + endDate}</td>
                        <td className={style.dataColEllipsis}>{startTime + "〜" + endTime}</td>
                        <td className={style.dataColEllipsis}>{DateUtil.getWeekdaysText(week)}</td>
                        <td>{el.query.excludeClerk ? t("exclude") : t("include")}</td>
                        <td>{formatSec2MMSS(el.query.threshold)}</td>
                    </tr>
                )
                items.push(row)
            }
        }
    } else {
        const it = (
            <tr key="empty">
                <td colSpan={9}>{t("msgSearchFilteringDoNotExist")}</td>
            </tr>
        )
        items.push(it)
    }

    useEffect(() => {
        return () => {
            window.document.body.style.overflow = ''
            //console.log("clean up")
        }
    }, [])

    return (
        <section>
            <HistoryButton onClick={handleHistoryOpen} />
            <Modal>
                <div className={style.modalMain}>
                    <div className={style.top}>
                        <div className={style.title}>
                            <label>{t("listSearchFilter")}</label>
                        </div>
                        <div className={style.closer}>
                            <CircleCloseButton onClose={handleClosing} styleType={CloseStyleType.SmallWhite} />
                        </div>
                    </div>
                </div>
                <div className={style.body}>
                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th className={style.usedate}>{t("latestSearchDate")}</th>
                                <th className={style.shopname}>{t("storeName")}</th>
                                <th className={style.layoutname}>{t("targetMapName")}</th>
                                <th className={style.datespan}>{t("period")}</th>
                                <th className={style.timespan}>{t("timeFrame")}</th>
                                <th className={style.weekdays}>{t("dayOfTheWeek")}</th>
                                <th className={style.clerk}>{t("staff")}</th>
                                <th className={style.threshold}>{t("threshold")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </section>
    )
}