import React from "react"
import { CheckIcon } from "@primer/octicons-react"

import style from "./Rivet.module.css"


export const RivetStyleType = {
    Checked: "chk",
    Activated: "act",
    Deactivated: "dea"
} as const
export type RivetStyle = typeof RivetStyleType[keyof typeof RivetStyleType]


interface Props {
    style: RivetStyle
    value: number
}

export const Rivet: React.FC<Props> = (props) => {
    if (props.style === RivetStyleType.Checked) {
        return (
            <div className={style.wrapIcon}>
                <CheckIcon size={24} />
            </div>
        )
    } else if (props.style === RivetStyleType.Activated) {
        return (
            <div className={style.doubleCircle}>
                <div className={style.inner}>{props.value}</div>
            </div>
        )
    } else {
        return (
            <div className={style.normalCircle}>
                <div className={style.misty}>{props.value}</div>
            </div>
        )
    }
}