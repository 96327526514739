import { CustomerForTrail, HumanAreaCount } from "../../data/analysis/AnalysisResult";
import { Coord } from "../../data/core/Coord";
import { OSResHuman } from "../AnalysisDataImpl";
import { FullLayout } from "./FullLayoutImpl";

function undefzero(v: number | undefined): number {
    if (v == undefined) {
        return 0
    }
    return v
}

export class CustomerForTrailImpl implements CustomerForTrail {
    human_id: number;
    enter_time: number;
    exit_time: number;
    stay_seconds: number;
    stay_area_count: number;
    stay_area_total_seconds: number;
    area_count_list: HumanAreaCount[];
    is_clerk: boolean;
    is_buying: boolean;
    num_of_items: number;
    amount_of_pay: number;
    trails: Coord[] | null;

    constructor(c: OSResHuman, threshold: number, layout: FullLayout) {
        this.human_id = c.humanId
        this.enter_time = c.enterShopTime;
        this.exit_time = c.exitShopTime;
        this.stay_seconds = c.stayShopTime;
        this.stay_area_count = 0;
        this.stay_area_total_seconds = 0;
        this.area_count_list = [];
        c.enteredArea.sort((a1, a2) => a1.areaId - a2.areaId)
        const totalStay: Record<string, number | undefined> = {}
        const stayCount: Record<string, number | undefined> = {}
        for (const a of c.enteredArea) {
            if (a.stayTime >= threshold * 1000) {
                totalStay[a.areaId.toString()] = undefzero(totalStay[a.areaId.toString()]) + a.stayTime
                stayCount[a.areaId.toString()] = undefzero(stayCount[a.areaId.toString()]) + 1
            }
        }
        for (const areaID in totalStay) {
            this.stay_area_count++
            this.stay_area_total_seconds += c[areaID]
            const a = layout.getArea(areaID)
            this.area_count_list.push({
                "area": a,
                "stay_count": undefzero(stayCount[areaID]),
                "total_stay_time": undefzero(totalStay[areaID]),
            })
        }
        this.is_clerk = c.clerk;
        this.is_buying = c.buying;
        this.num_of_items = 0;
        this.amount_of_pay = 0;
        this.trails = null
    }
}