import React from "react"
import { useTranslation } from "react-i18next"

import style from "./CustomCheckbox.module.css"

export const CheckBoxStyle = {
    Rect: "rect",
    Circle: "circle"
} as const
export type CheckboxType = typeof CheckBoxStyle[keyof typeof CheckBoxStyle]

export type CustomCheckboxEvent = {
    target: { value: string }
}

type Props = {
    type?: CheckboxType
    label: string
    value: string
    check: boolean
    onChange: (event: CustomCheckboxEvent) => void
    disabled?: boolean
}

export const CustomCheckbox: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    let selectedStyle: any
    if (props.type && CheckBoxStyle.Circle) {
        if (props.disabled) {
            selectedStyle = (props.check) ? `${style.circleOn} ${style.notAlwCursor}` : `${style.circleOff}  ${style.notAlwCursor}`
        } else {
            selectedStyle = (props.check) ? `${style.circleOn} ${style.posCursor}` : `${style.circleOff} ${style.posCursor}`
        }
    } else {
        if (props.disabled) {
            selectedStyle = (props.check) ? `${style.rectOn} ${style.notAlwCursor}` : `${style.rectOff} ${style.notAlwCursor}`
        } else {
            selectedStyle = (props.check) ? `${style.rectOn} ${style.posCursor}` : `${style.rectOff} ${style.posCursor}`
        }
    }

    const handleOnClick = () => {
        if (props.onChange) {
            if (props.disabled) return
            const event: CustomCheckboxEvent = { target: { value: props.value } }
            props.onChange(event)
        }        
    }

    return (
        <div className={selectedStyle} onClick={handleOnClick}>
            <label className={(props.disabled) ? `${style.label} ${style.notAlwCursor}` : `${style.label} ${style.posCursor}`}>{t(props.label)}</label>
        </div>
    )
}