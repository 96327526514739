import React, { useEffect, useRef, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { ContentsDistributor, MainCompoProps, PanelDesignType } from "./ContentsDistributor"
import { AnalyzeParametersType } from "../../types/Analyze"
import { AnalyzeViewType } from "../../types/Analyze"
import { HeatMapColorBar } from "./HeatMapColorBar"
import { SeriesSwitcher, BtnProp, SwitcherFontType } from "../../component/series_switcher/SeriesSwitcher"
import { DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT } from "../../constants"
//import { useMouseWheel } from "../../lib/useMouseWheel"
import { useMouseMove } from "../../lib/useMouseMove"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import HeatMapWriter from "../../lib/HeatMapWriter"
import { ResArea } from "../../api/data/analysis/FullLayout"

import style from "./HeatMap.module.css"

// 座標X, Y, カウント値の３つ
export type Atom<T> = [T, T, T]

export type HeatMapFullDataType = {
    heatmap: Atom<number>[]
    maxValue: number
    lengthX: number
    lengthY: number
    unitSize: number
}

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

// ★ 「全範囲」ヒートマップを非表示にする ★
const hideFullRange = true

const DisplayRangeType = {
    Area: "area",
    Full: "full",
} as const
//type DisplayRange = typeof DisplayRangeType[keyof typeof DisplayRangeType]

const SwitchItems: BtnProp[] = [
    { name: DisplayRangeType.Area, label: "area"},
    { name: DisplayRangeType.Full, label: "fullRange" },
]

export const HeatMapCore: React.FC<MainCompoProps> = (props) => {

    //console.log("props.data:", props.data)

	const { t } = useTranslation()
    const [dispRange, setDispRange] = useState<BtnProp>(SwitchItems[0])
    const ref = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const [writer, setWriter] = useState<HeatMapWriter | undefined>(undefined)
    const refFirst = useRef(true)

    const isSingle = (props.view && props.view === AnalyzeViewType.Compare) ? false : true
    
    const getAreaList = useCallback((): ResArea[] | undefined => {
        if (props.data) {
            const countList = props.data.get_area_count_list()
            return countList.map(el => {
                return el.area
            })
        }
    }, [props.data])
    
    const changeDisplayRange: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === DisplayRangeType.Area) {
            setDispRange(SwitchItems[0])
        } else if (nm === DisplayRangeType.Full) {
            setDispRange(SwitchItems[1])
        } else {
			const msg = t("msgUnknownButton")
            throw new Error(msg + " nm:" + nm)
        }
    }

    const handleZoomReset = () => {
        if (writer) writer.zoomReset()
        draw()
    }

    const handleZoomIn = () => {
        if (writer) writer.zoomIn()
        draw()
    }

    const handleZoomOut = () => {
        if (writer) writer.zoomOut()
        draw()
    }

    const draw = useCallback(() => {
        if (writer) {
            const ctx: CanvasRenderingContext2D | null | undefined = ref.current?.getContext("2d")
            if (ctx && props.data) {
                if (dispRange.name === DisplayRangeType.Area) {
                    const counts = props.data.get_area_count_list()
                    writer.drawAreaHeatMap(ctx, counts)
                } else {
                    //const unitSize = props.data.layout.cell_length_mm
                    //const colSize = Math.ceil(props.data.layout.pixel_width / props.data.layout.mm_per_pixel / unitSize)
                    const unitSize = props.data.layout.area_unitcell_pixel * props.data.layout.mm_per_pixel
                    const colSize = Math.ceil(props.data.layout.pixel_width / props.data.layout.area_unitcell_pixel)
                    const heatmaps = props.data.get_heatmap(unitSize, colSize)
                    writer.drawFullRangeHeatMap(ctx, heatmaps, colSize)
                }
            }
        }
    }, [writer, props.data, dispRange.name])

    // writer初期化
    useEffect(() => {
        //StrictMode対策
        if (process.env.NODE_ENV === 'development') {
            if (refFirst.current) {
                refFirst.current = false
                //console.log("refFirst SKIP render")
                return
            }
        }
        
        if (props.data) {
            if (writer === undefined) {
                const area_list = getAreaList()
                if (area_list) {
                    const mapWriter = new HeatMapWriter(props.data.layout, area_list, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT)
                    mapWriter.pathDepth = 1
                    setWriter(mapWriter)
                }
            } else {
                // writerが設定出来たら描画する
                draw()
            }
        }
    }, [props.data, writer, draw, getAreaList, refFirst])

    /*useMouseWheel(aniRef, (event: WheelEvent) => {
        // 拡大縮小処理
        if (writer) {
            writer.canvasZoom(event)
            console.log("zooming")
            draw()
        }
    })*/
    
    const mousePress = useMouseMove(aniRef, (event: MouseEvent) => {
        // マウスドラッグ中の処理
        if (writer) {
            const srcCanvas = ref.current
            const dstCanvas = aniRef.current
            if (srcCanvas && dstCanvas) writer.mouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        if (writer) {
            writer.mouseRelease()
            // 再描画
            draw()
            // ドラッグ領域をクリア
            const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current?.getContext("2d")
            if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
        }
    }, (event: MouseEvent) => {
        // マウス押下処理
        if (writer) {
            writer.mousePress()
        }
    
    })

    /*useEffect(() => {
        if (mousePress) {
            if (writer) writer.mousePress()
        } else {
            // マウスのドラッグが終わったら再描画
            draw()
            if (writer) {
                // ドラッグ領域をクリア
                const aniCtx: CanvasRenderingContext2D | null | undefined = aniRef.current?.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)
            }
        }
    }, [mousePress, writer, draw])*/

    const side = props.request ? props.request.side : "0"

    return (
        <div className={style.content}>
            <div className={style.head}>
                <div className={style.legend}>
                    <HeatMapColorBar/>
                </div>
                <div className={style.dummy}></div>
                <div className={style.switch}>
                    {
                        hideFullRange ? (null) : (<SeriesSwitcher fontType={SwitcherFontType.Mulish} buttonList={SwitchItems} onClick={e => changeDisplayRange(e)} activeName={dispRange.name}/>)
                    }
                </div>
            </div>
            <div className={style.comment}></div>
            <div className={style.body}>
                <div className={isSingle ? style.canvasWrap : style.canvasWrapNoShadow}>
                    <canvas id={"heatMapCanvas" + side} ref={ref} className={style.canvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT}></canvas>
                    <canvas id={"aniCanvas" + side} ref={aniRef} className={style.aniCanvas} width={DEFAULT_CANVAS_WIDTH} height={DEFAULT_CANVAS_HEIGHT}></canvas>
                    <div className={style.zoomBtn}>
                        <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                    </div>
                </div>
            </div>
        </div>
    )
}


interface Props {
    searches: AnalyzeParametersType | undefined
}

export const HeatMap: React.FC<Props> = (props) => {
    return (
        <ContentsDistributor searches={props.searches} mainConponent={HeatMapCore} type={PanelDesignType.Shadow} />
    )
}