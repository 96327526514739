import { FC, useEffect, useRef, useState, useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

import { useManagementDataContext } from "../../providers/ManagementData"
import { PageName } from "../../types"
import { Slider } from "../../component/slider/Slider"
import { Stepper } from "../../component/stepper/Stepper"
import { Button, StyleType } from "../../component/button/Button"
import { ModalFrame } from "../common/ModalFrame"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { CustomDialog, AcceptButtonType } from "../../component/custom_dialog/CustomDialog"
import { OverlaySpinner, SpinerStyleType } from "../../component/overlaySpinner/OverlaySpinner"
import { NotificationObject, DisplayNameType } from "../../component/notification_dialog/NotificationDialog"
import { ZOOM_CENTER } from "../../lib/CanvasWorker"
import { useMouseMove } from "../../lib/useMouseMove"
import Utils from "../../lib/Utils"

import style from "./LayoutRegiStep5.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false

interface Props {}

export const LayoutRegiStep5: FC<Props> = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const { worker, editingLayout, updateEditingLayout, createNewLayout, waitingForReturn, shopId, shopList, notification, setNotification } = useManagementDataContext()

    const [completedOpen, setCompletedOpen] = useState<boolean>(false)
    const [volume, setVolume] = useState<number>(ZOOM_CENTER)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const refBas = useRef<HTMLCanvasElement | null>(null)
    const refMov = useRef<HTMLCanvasElement | null>(null)
    const refShw = useRef<HTMLCanvasElement | null>(null)
    const refDot = useRef<HTMLCanvasElement | null>(null)
    const refFirst = useRef<boolean>(true)
    
    /**
     * ステップラベルのリスト
     */
    const stepperLabelList = useMemo(() => {
        const stp1 = t("label.layoutRegiStep1")
        const stp2 = t("label.layoutRegiStep2")
        const stp3 = t("label.layoutRegiStep3")
        const stp4 = t("label.layoutRegiStep4")
        const stp5 = t("label.layoutRegiStep5")
        return [stp1, stp2, stp3, stp4, stp5]
    }, [t])

    const shopName = useMemo(() => {
        if (shopId && shopList) {
            const shop = shopList.find(el => el.shop_id === shopId)
            if (shop) return shop.name
        }
        return ""
    }, [shopId, shopList])

    const layoutName = useMemo(() => {
        if (editingLayout) return editingLayout.name
        return ""
    }, [editingLayout])

    const startDate = useMemo(() => {
        if (editingLayout) {
            const fmt = t("dateFormat.ymd_hy")
            const ymd = format(editingLayout.start, fmt)
            return ymd
        }
        return ""
    }, [editingLayout, t])

    const refreshMap = useCallback(() => {
        if (worker && refBas && refBas.current && refMov && refMov.current && refMov && refMov.current) {
            const ctx = refBas.current.getContext("2d")
            if (ctx) {
                worker.clearCanvas(ctx)
                worker.drawNeoFitImage(ctx)
            }
            const ct2 = refMov.current.getContext("2d")
            if (ct2) {
                worker.clearCanvas(ct2)
            }
        }
    }, [worker])

    const handleClose = () => {
        if (!modalOpen) setModalOpen(true)
    }

    const onCanceledClose = () => {
        setModalOpen(false)
    }

    const onAcceptedClose = () => {
        navigate("/layout_list")
    }

    const handleHistoryBack = () => {
        navigate(-1)
    }

    const onClickSave = () => {
        
        if (worker && editingLayout) {
            worker.imageCrop().then((model) => {
                const cloneEditingLayout = { ...editingLayout }
                // ファイル名を変更
                const imgFileName = "s" + shopId + "." + Utils.getFileExtension(model.image)
                model.image = imgFileName
                // マッピング情報を更新
                cloneEditingLayout.mapping = model
                updateEditingLayout(cloneEditingLayout)
                // 通知オブジェクトの作成
                const notific: NotificationObject = {
                    isActive: true,
                    from: DisplayNameType.LayoutRegiStep,
                    to: DisplayNameType.LayoutRegiStep,
                    message: t("msgLayoutRegistered")
                }
                // // レイアウトセットのコピーとレイアウトの登録
                // copyHotAndSaveLayout(cloneEditingLayout, notific)
                createNewLayout(cloneEditingLayout, notific)
                console.log("MapModel:", model)
            })
            worker.printBasic()
            worker.printNeo()
        }
        //if (!completedOpen) setCompletedOpen(true)
    }

    const onCanceledNotification = () => {}
    
    const onAcceptedNotification = () => {
        if (editingLayout) updateEditingLayout(undefined)
        if (completedOpen) {
            setCompletedOpen(false)
            setNotification({
                isActive: false,
                from: DisplayNameType.None,
                to: DisplayNameType.None,
                message: ""
            })
        }
        navigate("/layout_list")
    }

    const onChangeVolume = (value: number) => {
        setVolume(value)
        if (worker) {
            worker.neoZoomInOut(value)
            refreshMap()
        }
    }

    /**
     * マウスイベントの登録
     * 一番上のCanvas(refDot)でマウスイベントを取得する
     * 最下層のCanvas(refBas)で描画を行う
     * 中間のCanvas(refMov)でマウスイベントに合わせて移動中描画を行う
     */
    const mousePress = useMouseMove(refDot, (event: MouseEvent) => {
        if (worker) {
            const srcCanvas = refBas.current
            const dstCanvas = refMov.current
            if (srcCanvas && dstCanvas) worker.neoMouseMove(event, mousePress, srcCanvas, dstCanvas)
        }
    }, (event: MouseEvent) => {
        // マウスボタンを離したときの処理
        refreshMap()
    }, (event: MouseEvent) => {
        // マウスボタンを押したときの処理
        if (worker) worker.neoMousePress()
    })

    // 初回レンダー時のみ実行
    useEffect(() => {
        // 開発時のStrictModeで二重レンダーされるのを防ぐ
        if (process.env.NODE_ENV === "development") {
            if (refFirst.current) {
                refFirst.current = false
                console.log("SKIP first render")
                return
            }
        }
        
        //if (mousePress) {
            //if (worker) worker.neoMousePress()
        //} else {
            refreshMap()
        //}
    }, [])

    /**
     * Canvasの大きさが多少異なるので再調整（はじめだけ）
     */
    useEffect(() => {
        if (worker) worker.changeCanvasSize(730, 490)
    }, [])

    /**
     * 保存処理の完了通知を受け取ったらダイアログを表示する
     */
    useEffect(() => {
        if (notification) {
            if (notification.from === DisplayNameType.LayoutRegiStep) {
                if (notification.to === DisplayNameType.LayoutRegiStep) {
                    if (notification.message === t("msgLayoutRegistered")) {
                        if (!completedOpen) setCompletedOpen(true)
                    }
                }
            }
        }
    }, [completedOpen, notification, t])

    const downloadLink = useMemo(() => {
        if (worker && editingLayout && editingLayout.mapping) {
            const dataUrl = editingLayout.mapping.image_data
            return (<a id="img_dl" href={dataUrl} type="image/jpeg" download={"layout_image.jpg"} >download</a>)
        }
        return null
    }, [worker, editingLayout])

    return (
        <ModalFrame page={PageName.InfoMap} title={t("menu.mapRegistrationEdit")} width={1110} height={795} >
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>{t("header.layoutNewEntry")}</div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.indicator}>
                    <div className={style.steps}>
                        <Stepper activeNumber={5} labels={stepperLabelList}></Stepper>
                    </div>
                </div>
                <div className={style.body}>
                    <div className={style.subTitle}>{t("label.layoutRegiStep5")}</div>
                    <div className={style.guide}>
                        {t("guidance.trimming")}
                    </div>
                    <div className={style.canvasWrap}>
                        <canvas className={style.canvasBas} width={730} height={490} ref={refBas}></canvas>
                        <canvas className={style.canvasMov} width={730} height={490} ref={refMov}></canvas>
                        <canvas className={style.canvasShw} width={730} height={490} ref={refShw}></canvas>
                        <canvas className={style.canvasDot} width={640} height={400} ref={refDot}></canvas>
                    </div>
                    <div className={style.zoombar}>
                        <Slider
                            value={volume}
                            withBorder={true}
                            withButton={true}
                            max={ZOOM_CENTER * 2}
                            min={0}
                            onChange={onChangeVolume}
                            size="s"
                        />
                    </div>
                    <div className={style.navi}>
                        <Button name="next" label={t("button.saveDraft")} styleType={StyleType.Normal} onClick={onClickSave}/>
                    </div>
                    <div className={style.back}>
                        <Button name="back" label={t("back")} styleType={StyleType.MuliInact} onClick={handleHistoryBack}/>
                    </div>
                </div>
            </div>
            <OverlaySpinner waiting={waitingForReturn} spinerStyle={SpinerStyleType.ForManagement} />
            <CustomDialog
                requestOpen={completedOpen}
                width={610}
                height={224}
                guideMessage={t("msgLayoutRegistered")}
                onCancel={onCanceledNotification}
                onAccept={onAcceptedNotification}
                buttonType={AcceptButtonType.NoCancelRightSide}
            >
                <div className={style.information}>
                    <p>{t("storeName")}：{shopName}</p>
                    <p>{t("layoutName")}：{layoutName}</p>
                    <p>{t("table.layoutStartDate")}：{startDate}</p>
                    {
                        mockOn ? (downloadLink) : (null)
                    }
                </div>
            </CustomDialog>
            <CustomDialog
                requestOpen={modalOpen}
                width={500}
                height={170}
                guideMessage={t("guidance.cancelLayoutRegi")}
                buttonType={AcceptButtonType.Ok}
                onCancel={onCanceledClose}
                onAccept={onAcceptedClose}
            >
            </CustomDialog>
        </ModalFrame>
    )
}
