import { MapModel } from "../types/Management"
import { SCALE_STEP, MAX_SCALE, MIN_SCALE } from "./MapWriter"

const FONT_FAMILY = "11pt 'Helvetica Neue','Noto Sans JP',Arial,sans-serif"
const COLOR_RED = "rgb(255,0,0)"
const COLOR_MAGENDA = "rgb(255,0,130)"
// メッシュの色
const MESH_COLOR = "rgba(0,0,0,20%)"
// メッシュの線太さ
const MESH_LINE_WIDTH = 1

class LayoutEditPicWriter {
    public file: File | undefined
    public image: HTMLImageElement | undefined
    public cnvsWidth: number
    public cnvsHeight: number
    public originX: number = 0
    public originY: number = 0
    public mmPerPx: number = 1
    public fitScale: number = 1
    public imageScale: number = 1
    public vrtlCnvsWidth: number = 100
    public vrtlCnvsHeight: number = 100
    public vrtlTop: number = 0
    public vrtlLeft: number = 0
    public mouseMoveX: number = 0
    public mouseMoveY: number = 0
    public memoLeft: number = 0
    public memoTop: number = 0
    constructor(file: File | undefined, canvasWidth: number, canvasHeight: number) {
        this.file = file
        this.cnvsWidth = canvasWidth
        this.cnvsHeight = canvasHeight
    }

    /**
     * マップモデルでパラメーターの初期化をします
     * @param model 
     * @returns 
     */
    public setMapParameters(model: MapModel) {
        return new Promise((resolve, reject) => {
            this.mmPerPx = model.mm_per_pixel
            this.originX = model.origin_x
            this.originY = model.origin_y
            this.image = new Image()
            this.image.onload = () => {
                resolve(true)
            }
            this.image.src = model.image
            const scaleX = this.cnvsWidth / model.pixel_width
            const scaleY = this.cnvsHeight / model.pixel_height
            this.fitScale = Math.min(scaleX, scaleY)
            this.imageScale = this.fitScale
            this.vrtlCnvsWidth = this.cnvsWidth / this.fitScale
            this.vrtlCnvsHeight = this.cnvsHeight / this.fitScale
            this.vrtlLeft = (this.vrtlCnvsWidth - model.pixel_width) / 2
            this.vrtlTop = (this.vrtlCnvsHeight - model.pixel_height) / 2
        })
    }

    /**
     * アップロード予定のファイルを読み込んでパラメーターの初期化をします
     * @returns 
     */
    public imageLoad() {
        return new Promise((resolve, reject) => {
            if (this.file) {
                const reader = new FileReader()
                reader.onload = () => {
                    this.image = new Image()
                    this.image.onload = () => {
                        if (this.image) {
                            //console.log("image.src:", this.image.src)
                            const scaleX = this.cnvsWidth / this.image.width
                            const scaleY = this.cnvsHeight / this.image.height
                            this.fitScale = Math.min(scaleX, scaleY)
                            this.imageScale = this.fitScale
                            this.vrtlCnvsWidth = this.cnvsWidth / this.fitScale
                            this.vrtlCnvsHeight = this.cnvsHeight / this.fitScale
                            this.vrtlLeft = (this.vrtlCnvsWidth - this.image.width) / 2
                            this.vrtlTop = (this.vrtlCnvsHeight - this.image.height) / 2
                            //console.log("fitScale, vrtlLeft, vrtlTop, vrtlCnvsWidth", this.fitScale, this.vrtlLeft, this.vrtlTop, this.vrtlCnvsWidth)
                            resolve(true)
                        }
                    }
                    this.image.src = reader.result as string
                }
                reader.readAsDataURL(this.file)
            }
        })
    }

    public clearCanvas(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.clearRect(0, 0, this.cnvsWidth, this.cnvsHeight)
            resolve(true)
        })
    }

    public drawImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            if (this.image) {
                this.clearCanvas(ctx)
                const sx = (this.vrtlLeft >= 0) ? 0 : this.vrtlLeft * -1
                const sy = (this.vrtlTop >= 0) ? 0 : this.vrtlTop * -1
                const sw = (this.vrtlLeft >= 0) ? this.image.width : this.vrtlCnvsWidth
                const sh = (this.vrtlTop >= 0) ? this.image.height : this.vrtlCnvsHeight
                const dx = (this.vrtlLeft >= 0) ? this.vrtlLeft : 0
                const dy = (this.vrtlTop >= 0) ? this.vrtlTop : 0
                const dw = sw
                const dh = sh
                ctx.drawImage(this.image, sx, sy, sw, sh, dx, dy, dw, dh)
                resolve(this.image)
            }
        })
    }

    public drawFitImage(ctx: CanvasRenderingContext2D) {
        return new Promise((resolve, reject) => {
            ctx.save()
            ctx.scale(this.imageScale, this.imageScale)
            this.drawImage(ctx).then(res => {
                ctx.restore()
                resolve(res)
            }).catch(err => {
                ctx.restore()
                reject(err)
            })
        })
    }

    public drawCrossOrigin(ctx: CanvasRenderingContext2D, origin_x: number, origin_y: number) {
        return new Promise((resolve, reject) => {
            ctx.save()
            ctx.scale(this.imageScale, this.imageScale)

            this.originX = origin_x
            this.originY = origin_y
            const px = (this.originX + this.vrtlLeft) 
            const py = (this.originY + this.vrtlTop) 
            //console.log("origin x,y, imageScale:", this.originX, this.originY, this.imageScale)
            //console.log("virtual left,top:", this.vrtlLeft, this.vrtlTop)
            //console.log("cross line px,py:", px, py)
            ctx.strokeStyle = COLOR_RED
            ctx.lineWidth = 1 * this.imageScale
            ctx.beginPath()
            ctx.moveTo(0, py)
            ctx.lineTo(this.vrtlCnvsWidth, py)
            ctx.stroke()
            ctx.beginPath()
            ctx.moveTo(px, 0)
            ctx.lineTo(px, this.vrtlCnvsHeight)
            ctx.stroke()

            ctx.restore()
            resolve(true)
        })
    }

    public drawMesure(ctx: CanvasRenderingContext2D, mmPerPx: number) {
        this.mmPerPx = mmPerPx
        const baseSize = (mmPerPx > 0 && mmPerPx < 10) ? 100 : ((mmPerPx < 100) ? 1000 : 10000)
        return new Promise((resolve, reject) => {
            ctx.save()
            ctx.scale(this.imageScale, this.imageScale)

            const len1m = baseSize / this.mmPerPx
            const h = len1m / 2
            const py = this.vrtlCnvsHeight - h
            const lw = 1 / this.imageScale
            ctx.strokeStyle = COLOR_MAGENDA
            ctx.fillStyle = COLOR_MAGENDA
            ctx.lineWidth = lw
            let px = 0
            let cnt = 0
            while (px < this.vrtlCnvsWidth && cnt < 41) {
                if (cnt === 0 || cnt === 2 || cnt === 4) {
                    ctx.fillStyle = COLOR_MAGENDA
                    ctx.fillRect(px, py, len1m, h)
                    if (cnt === 0) {
                        ctx.beginPath()
                        ctx.strokeStyle = COLOR_MAGENDA
                        ctx.fillStyle = COLOR_MAGENDA
                        ctx.font = FONT_FAMILY
                        ctx.textAlign = "center"
                        ctx.textBaseline = "middle"
                        const txt = (baseSize === 1000) ? "im" : ((baseSize === 100) ? "10cm" : "10m")
                        ctx.fillText(txt, px + len1m, py - h / 2, len1m)
                        ctx.stroke()
                    }
                    px = px + len1m
                } else if (cnt === 1 || cnt === 3) {
                    ctx.strokeStyle = COLOR_MAGENDA
                    ctx.lineWidth = lw
                    ctx.beginPath()
                    ctx.moveTo(px, py + lw)
                    ctx.lineTo(px + len1m, py + lw)
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.moveTo(px, py + h)
                    ctx.lineTo(px + len1m, py + h)
                    ctx.stroke()
                    px = px + len1m
                } else if (cnt === 9 || cnt === 29) {
                    ctx.strokeStyle = COLOR_MAGENDA
                    ctx.lineWidth = lw
                    ctx.beginPath()
                    ctx.moveTo(px, py + lw)
                    ctx.lineTo(px + len1m * 5, py + lw)
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.moveTo(px, py + h)
                    ctx.lineTo(px + len1m * 5, py + h)
                    ctx.stroke()
                    if (cnt === 9) {
                        ctx.beginPath()
                        ctx.strokeStyle = COLOR_MAGENDA
                        ctx.fillStyle = COLOR_MAGENDA
                        ctx.font = FONT_FAMILY
                        ctx.textAlign = "center"
                        ctx.textBaseline = "middle"
                        const txt = (baseSize === 1000) ? "5m" : ((baseSize === 100) ? "50cm" : "50m")
                        ctx.fillText(txt, px, py - h / 2, len1m)
                        ctx.stroke()
                    }
                    px = px + len1m * 5
                } else if (cnt === 19 || cnt === 39) {
                    ctx.fillStyle = COLOR_MAGENDA
                    ctx.fillRect(px, py, len1m * 5, h)
                    if (cnt === 19) {
                        ctx.beginPath()
                        ctx.strokeStyle = COLOR_MAGENDA
                        ctx.fillStyle = COLOR_MAGENDA
                        ctx.font = FONT_FAMILY
                        ctx.textAlign = "center"
                        ctx.textBaseline = "middle"
                        const txt = (baseSize === 1000) ? "10m" : ((baseSize === 100) ? "1m" : "100m")
                        ctx.fillText(txt, px, py - h / 2, len1m)
                        ctx.stroke()
                    }
                    px = px + len1m * 5
                } else if (cnt === 40) {
                    ctx.beginPath()
                    ctx.strokeStyle = COLOR_MAGENDA
                    ctx.fillStyle = COLOR_MAGENDA
                    ctx.font = FONT_FAMILY
                    ctx.textAlign = "center"
                    ctx.textBaseline = "middle"
                    const txt = (baseSize === 1000) ? "25m" : ((baseSize === 100) ? "2.5m" : "250m")
                    ctx.fillText("25m", px, py - h / 2, len1m)
                    ctx.stroke()
                    px = px + len1m
                }
                cnt++
            }

            ctx.restore()
            resolve(true)
        })
    }

    public zoomReset() {
        this.imageScale = this.fitScale
        this.vrtlCnvsWidth = this.cnvsWidth / this.imageScale
        this.vrtlCnvsHeight = this.cnvsHeight / this.imageScale
        if (this.image) {
            this.vrtlLeft = (this.vrtlCnvsWidth - this.image.width) * 0.5
            this.vrtlTop = (this.vrtlCnvsHeight - this.image.height) * 0.5
        }
    }

    public zoomIn() {
        if (this.imageScale + SCALE_STEP < MAX_SCALE) {
            const prevWidth = this.vrtlCnvsWidth
            const prevHeight = this.vrtlCnvsHeight
            this.imageScale += SCALE_STEP
            this.vrtlCnvsWidth = this.cnvsWidth / this.imageScale
            this.vrtlCnvsHeight = this.cnvsHeight / this.imageScale
            this.vrtlLeft += (this.vrtlCnvsWidth - prevWidth) * 0.5
            this.vrtlTop += (this.vrtlCnvsHeight - prevHeight) * 0.5
        }
    }

    public zoomOut() {
        if (this.imageScale - SCALE_STEP > MIN_SCALE) {
            const prevWidth = this.vrtlCnvsWidth
            const prevHeight = this.vrtlCnvsHeight
            this.imageScale -= SCALE_STEP
            this.vrtlCnvsWidth = this.cnvsWidth / this.imageScale
            this.vrtlCnvsHeight = this.cnvsHeight / this.imageScale
            this.vrtlLeft += (this.vrtlCnvsWidth - prevWidth) * 0.5
            this.vrtlTop += (this.vrtlCnvsHeight - prevHeight) * 0.5
        }
    }

    public mouseMove(event: MouseEvent, press: boolean, srcCanvas: HTMLCanvasElement, dstCanvas: HTMLCanvasElement, bgCanvas: HTMLCanvasElement | undefined = undefined) {
        //console.log("mouseMove")
        if (event && event.target) {
            let rect = (event.target as HTMLElement).getBoundingClientRect()
            if (press) {
                // ドラッグ処理
                const mouseDragX = (event.clientX - rect.left)
                const mouseDragY = (event.clientY - rect.top)
                const moveX = (mouseDragX - this.mouseMoveX)
                const moveY = (mouseDragY - this.mouseMoveY)
                this.vrtlLeft = this.memoLeft + moveX / this.imageScale
                this.vrtlTop = this.memoTop + moveY / this.imageScale
                // Imageの移動(Canvas間Copy)
                const ctx = dstCanvas.getContext("2d")
                if (ctx) {
                    this.clearCanvas(ctx)
                    const w = this.cnvsWidth - Math.abs(moveX)
                    const h = this.cnvsHeight - Math.abs(moveY)
                    const sx = (moveX > 0) ? 0 : moveX * -1
                    const sy = (moveY > 0) ? 0 : moveY * -1
                    const dx = (moveX > 0) ? moveX : 0
                    const dy = (moveY > 0) ? moveY : 0
                    ctx.fillStyle = "#fff"
                    ctx.fillRect(0, 0, this.cnvsWidth, this.cnvsHeight)
                    if (bgCanvas) ctx.drawImage(bgCanvas, sx, sy, w, h, dx, dy, w, h)
                    ctx.drawImage(srcCanvas, sx, sy, w, h, dx, dy, w, h)
                    //console.log("drag Map")
                }
                //this.isMousePress = true
            } else {
                // 移動座標の記録
                this.mouseMoveX = (event.clientX - rect.left)
                this.mouseMoveY = (event.clientY - rect.top)
            }
        }
    }

    /**
     * マウスが押された瞬間の位置を記録
     */
    public mousePress() {
        //console.log("mousePress")
        //this.isMousePress = true
        this.memoLeft = this.vrtlLeft
        this.memoTop = this.vrtlTop
    }

    /**
     * 指定された大きさでメッシュを描きます。
     * @param ctx 
     * @param areaUnitcellPixel 
     */
    public drawCellMesh(ctx: CanvasRenderingContext2D, areaUnitcellPixel: number) {
        if (this.image) {
            const cellStartCol = Math.ceil(this.originX / areaUnitcellPixel) * -1
            const cellStartRow = Math.ceil(this.originY / areaUnitcellPixel) * -1
            const cellEndCol = Math.ceil(this.image.width / areaUnitcellPixel) + cellStartCol
            const cellEndRow = Math.ceil(this.image.height / areaUnitcellPixel) + cellStartRow
            const pxDispStartX = this.vrtlLeft * this.imageScale * -1
            const pxDispStartY = this.vrtlTop * this.imageScale * -1
            const pxDispEndX = pxDispStartX + this.cnvsWidth
            const pxDispEndY = pxDispStartY + this.cnvsHeight
            // 大きい窓の座標は、カメラ原点位置が(0,0)となっている。
            // vtLeftとvtTopはマップ上ではマイナスとなっている。
            const st_x = (areaUnitcellPixel * cellStartCol + this.originX) * this.imageScale
            const ed_x = (areaUnitcellPixel * cellEndCol + this.originX) * this.imageScale
            const st_y = (areaUnitcellPixel * cellStartRow + this.originY) * this.imageScale
            const ed_y = (areaUnitcellPixel * cellEndRow + this.originY) * this.imageScale
            const top = (st_y <= pxDispStartY) ? 0 : (st_y - pxDispStartY)
            const btm = (pxDispEndY <= ed_y) ? this.cnvsHeight : this.cnvsHeight - (pxDispEndY - ed_y)
            const left = (st_x <= pxDispStartX) ? 0 : (st_x - pxDispStartX)
            const right = (pxDispEndX <= ed_x) ? this.cnvsWidth : this.cnvsWidth - (pxDispEndX - ed_x)
            ctx.clearRect(0, 0, this.cnvsWidth, this.cnvsHeight)
            // 原点の前後のライン
            for (let i = cellStartCol; i <= cellEndCol; i++) {
                if (i !== 0) {
                    const pt = (areaUnitcellPixel * i + this.originX) * this.imageScale
                    if (pxDispStartX < pt && pt < pxDispEndX) {
                        const px = pt - pxDispStartX
                        ctx.beginPath()
                        ctx.strokeStyle = MESH_COLOR
                        ctx.lineWidth = MESH_LINE_WIDTH
                        ctx.moveTo(px, top)
                        ctx.lineTo(px, btm)
                        ctx.stroke()
                    }
                }
            }
            for (let i = cellStartRow; i <= cellEndRow; i++) {
                if (i !== 0) {
                    const pt = (areaUnitcellPixel * i + this.originY) * this.imageScale
                    if (pxDispStartY < pt && pt < pxDispEndY) {
                        const py = pt - pxDispStartY
                        ctx.beginPath()
                        ctx.strokeStyle = MESH_COLOR
                        ctx.lineWidth = MESH_LINE_WIDTH
                        ctx.moveTo(left, py)
                        ctx.lineTo(right, py)
                        ctx.stroke()
                    }
                }
            }
        }
    }
}

export default LayoutEditPicWriter
