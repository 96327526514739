import React, { useMemo, useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { PencilIcon } from "@primer/octicons-react"

import { RoleType } from "../../api/data/core/Enums"
import { PageName } from "../../types"
import { PermissionTypes, doYouHaveHighPriorityThanMe } from "../../types/Permissions"
import { EditModeType, UrlParameterType } from "../../types/Management"
import { CustomCheckbox, CustomCheckboxEvent } from "../../component/custom_checkbox/CustomCheckbox"
import { useAuthUserContext } from "../../providers"
import { UserTypeObject, useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { ListFrame } from "../common/ListFrame"
import { TrackingPageView } from "../common/TrackingPageView"
import DateUtil from "../../lib/DateUtil"

import style from "./AccountList.module.css"

const myPermission = PermissionTypes.ManageAccount_View

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export const AccountList = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { userInfo } = useAuthUserContext()
    
    const { companyId, companyList, userList, userTypes, initManagementData } = useManagementDataContext()

    const { isSameShop, ableToCreate4User, ableToRead4User, ableToUpdate4User, canDelete4User } = useAccessControlContext()

    const [includingDeleted, setIncludingDeleted] = useState<boolean>(false)

    // 新規登録権限
    const ableNew = useMemo(() => { return ableToCreate4User() }, [ableToCreate4User])

    /**
     * 代表的なタイムゾーン値（i18nのロケールから求めている）
     * 例）jaなら540、enなら0
     *
    const typicalTimeZoneMinute = useMemo(() => {
        const tz = t("typicalTimeZoneMinute")
        return parseInt(tz)
    }, [t])*/
    
    /**
     * 新規作成画面へ遷移する
     */
    const handleCreateAccount = () => {
        if (ableNew) navigate("/account_edit?" + UrlParameterType.Mode + "=" + EditModeType.New,
            { state: { Mode: EditModeType.New, from: location } })
    }

    /**
     * ユーザー詳細画面へ遷移する
     */
    const handleUserDetail = useCallback((event: React.MouseEvent, userId: number) => {
        event.preventDefault()
        const acl = ableToUpdate4User(userId)
        const mode = acl ? EditModeType.Edit : EditModeType.View
        navigate("/account_edit?" + UrlParameterType.Mode + "=" + mode + "&" + UrlParameterType.UserId + "=" + userId,
            { state: { Mode: mode, UserId: userId, from: location } })
    }, [navigate, ableToUpdate4User, location])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.add(style.trOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent) => {
        event.preventDefault()
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.remove(style.trOver)
    }, [])

    /**
     * 削除済みチェックボックスの変更イベント処理
     * @param event 
     */
    const handleChangeCheckbox = (event: CustomCheckboxEvent) => {
        //const v = event.target.value
        setIncludingDeleted(!includingDeleted)
    }

    // データ初期化
    useEffect(() => {
        // 企業は必ず１件以上ある。ない時はAPIからデータを取得する
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [companyList, initManagementData])

    /**
     * ユーザー情報を表示する行を作成する
     */
    const buildItem = useCallback((user: any, idx: number, isHigher: boolean, isSameShop: boolean) => {
        console.log("buildItem - user, isSameShop, isHigher:", user, isSameShop, isHigher)
        // 自分より権限が高いユーザーかどうか(高い人は詳細を非表示にする)
        const utid = user.user_type_id
        const uType = userTypes?.find(el => el.id === utid)
        const utNm = (uType) ? t("userTypeName." + uType.name) : ""
        const itm = (
            <tr key={idx} onMouseOver={handleRowOver} onMouseOut={handleRowOut} onClick={e => handleUserDetail(e, user.user_id)}>
                <td className={style.tdId}>{user.user_id}</td>
                <td className={style.tdName}>{user.name}</td>
                <td className={style.tdMail}>{user.login_id}</td>
                <td className={style.tdType}>{utNm}</td>
                <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(user.created_at, 0)}</td>
                <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(user.modified_at, 0)}</td>
                <td className={style.tdId}>
                    {
                        isHigher ? (
                            <div className={style.iconDisabled}><PencilIcon size={16} /></div>
                        ) : (
                            <div className={style.icon}><PencilIcon size={16} /></div>
                        )
                    }
                </td>
            </tr>
        )
        return itm
    }, [t, userTypes, handleUserDetail, handleRowOver, handleRowOut])

    /**
     * ユーザータイプIDから権限を取得する。デフォルトはRoot
     */
    const getUserRole = useCallback((userTypeId: number, userTypes: UserTypeObject[] | undefined) => {
        if (userTypes === undefined) return RoleType.Root
        const ut = userTypes.find(el => el.id === userTypeId)
        return (ut) ? ut.role : RoleType.Root
    }, [])
    
    const items: React.ReactNode = useMemo(() => {
        const result: any = []
        if (companyId && userList) {
            if (userList.length > 0) {
                const ownRole = userInfo?.user.user_type
                userList.forEach((user, idx) => {
                    // 期限切れのユーザーは表示しない
                    if (!user.expired_at || includingDeleted) {
                        if (ableToRead4User(user.user_id)) {
                            const same = isSameShop(user.user_id)
                            const yourUserType = (user.user_type) ? user.user_type : getUserRole(user.user_type_id, userTypes)
                            // 自分より権限が高いユーザーかどうか(高い人は詳細を非表示にする)
                            const isHigher = (ownRole) ? doYouHaveHighPriorityThanMe(ownRole, yourUserType) : true
                            const itm = buildItem(user, idx, isHigher, same)
                            result.push(itm)
                        }
                    }
                })
            } else {
                const msg = t("msgHasNoAccount")
                const itm = (
                    <tr key="999">
                        <td colSpan={7} className={style.tdName}>{msg}</td>
                    </tr>
                )
                result.push(itm)
            }
        }
        return result
    }, [userList, companyId, includingDeleted, t, userInfo, isSameShop, buildItem, getUserRole, userTypes, ableToRead4User])

    return (
        <ListFrame
            page={PageName.InfoAccount}
            title={t("header.accountList")}
            permission={myPermission}
            companySelect={true}
            shopSelect={false}
            newEntry={ableNew}
            onClickNew={handleCreateAccount}
            copyEntry={false}
        >
            <div className={style.main}>
                {isProduction ? (<TrackingPageView page_title="bsinfo-account-list" />) : (null)}
                <div className={style.ctrlRow}>
                    <div className={style.startCol}></div>
                    <div className={style.checkCol}>
                        {
                            canDelete4User ? (<CustomCheckbox label="includingDeleted" value="incDel" check={includingDeleted} onChange={handleChangeCheckbox} />) : (null)
                        }
                    </div>
                    <div className={style.endCol}></div>
                </div>
                <table className={style.table}>
                    <thead>
                        <tr>
                            <th className={style.thId}>ID</th>
                            <th className={style.thName}>{t("userName")}</th>
                            <th className={style.thMail}>{t("loginId")}</th>
                            <th className={style.thType}>{t("userType")}</th>
                            <th className={style.thDate}>{t("registrationDate")}</th>
                            <th className={style.thDate}>{t("modifiedDate")}</th>
                            <th className={style.thLink}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        </ListFrame>

    )
}