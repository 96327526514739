/**
 * パスワードの生成
 * （参考）https://dekfractal.com/3619.html
 */
class PasswordGenerator {
   
    // 指定された長さのパスワードを生成します
    password(length: number): string {
        let char = ''
        let validChars = []
        let password = ''

        // 使用する文字セットの準備
        for (let i = 33; i <= 126; i++) {
            char = String.fromCharCode(i)
            validChars.push(char)
        }
        // 乱数からパスワード文字列を作成
        while (password.length < length) {
            char = validChars[this.rand(0, validChars.length)]
            password = password + char
        }
        return password
    }

    // 乱数を生成します
    rand(min: number, max: number): number {        
        let arr = new Uint8Array(max)
        crypto.getRandomValues(arr)
        //条件を満たしている乱数が見つかったら返す
        for (let i = 0; i < arr.length; i++) {
            let value = arr[i]
            if (value >= min && value < max) return value
        }
        return this.rand(min, max)
    }
}

class Password {

    // パスワードの生成（１６文字）
    createPassword() {
        const PASSWORD_LENGTH = 12;
        let password:string = ''
        let passwordGen = new PasswordGenerator()

        while (!this.isStrongEnough(password)) {
            password = passwordGen.password(PASSWORD_LENGTH)
        }

        return password
    }

    /**
     * パスワードが条件を満たしているかチェック
     * 条件：「大文字の英字」「小文字の英字」「数字」「記号の'-'か'?'」を１文字以上含むこと
     * @param password 
     * @returns 
     */
    isStrongEnough(password: string) {
        let uppercaseMinCount = 1
        let lowercaseMinCount = 1
        let numberMinCount = 1
        let specialMinCount = 1

        const UPPERCASE_RE = /([A-Z])/g
        const LOWERCASE_RE = /([a-z])/g 
        const NUMBER_RE = /[\d]/g
        const SPECIAL_CHAR_RE = /[\-\?]/g

        let uc = password.match(UPPERCASE_RE)
        let lc = password.match(LOWERCASE_RE)
        let n = password.match(NUMBER_RE)
        let sc = password.match(SPECIAL_CHAR_RE)

        return (
            uc && uc.length >= uppercaseMinCount &&
            lc && lc.length >= lowercaseMinCount &&
            n && n.length >= numberMinCount &&
            sc && sc.length >= specialMinCount
        ) ? true : false
    }
}

export default Password
