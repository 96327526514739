import React from "react";
import { useTranslation } from "react-i18next";

import style from "./button.module.css"

export const StyleType = {
    Normal: 'normal',       // for login, modal(ok)
    Reverse: 'reverse',     //     modal(cancel)
    Primary: 'primary',     // for tab-switch-active
    Secondary: 'secondary', // for tab-switch-deactive
    Danger: 'danger',       // 削除用
    DangerMini: 'dng_min',  // 
    Outline: 'outline',     // for prev-day, next-day
    OutWide: 'outwide',     // for ordering
    OutMini: 'outmini',     // for threshold-time-setting
    MuliAct: 'muli_act',    // for data-type-switch-active muli font
    MuliInact: 'muli_ina',    //          〃          inactive
    MulishMiniAct: "mm_act",    //          〃     font-size 12px
    MulishMiniInact: "mm_ina",    //          〃     font-size 12px
} as const
export type ButtonType = typeof StyleType[keyof typeof StyleType]

interface Props {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    name: string
    label: string
    styleType: ButtonType
    disabled?: boolean
}

function getStyleKey (type: ButtonType): string {
    switch (type) {
        case StyleType.Primary:
            return style.primary
        
        case StyleType.Reverse:
            return style.reverse
            
        case StyleType.Secondary:
            return style.secondary
        
        case StyleType.Danger:
            return style.danger
        
        case StyleType.DangerMini:
            return style.dangerMini
            
        case StyleType.Outline:
            return style.outline
        
        case StyleType.OutWide:
            return style.outwide
        
        case StyleType.OutMini:
            return style.outmini
            
        case StyleType.MuliAct:
            return style.muliAct
        
        case StyleType.MuliInact:
            return style.muliInactive
        
        case StyleType.MulishMiniAct:
            return style.mmAct
        
        case StyleType.MulishMiniInact:
            return style.mmInact
        
        default:
            return style.normal
    }    
}

export const Button: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const clsType = getStyleKey(props.styleType)

    return (
        <button onClick={props.onClick} className={clsType} name={props.name} type="button" disabled={props.disabled ? props.disabled : false}>
            {t(props.label)}
        </button>
    )
}