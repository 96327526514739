import React, { useCallback, useMemo, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChevronRightIcon, CopyIcon, PlusIcon, PencilIcon } from "@primer/octicons-react"
import { addDays, format, parse } from "date-fns"

import { PageName } from "../../types"
import { DataSourceStatusType, EditModeType, UrlParameterType } from "../../types/Management"
import { useManagementDataContext } from "../../providers/ManagementData"
import DateUtil from "../../lib/DateUtil"
import { ListFrame } from "../common/ListFrame"
import { DatasourceAccordion } from "../../component/datasource_accordion/DatasourceAccordion"
import { Button, StyleType } from "../../component/button/Button"
import { DisplayNameType, NotificationDialog, NotificationObject } from "../../component/notification_dialog/NotificationDialog"
import { CopyDialog } from "../../component/copy_dialog/CopyDialog"
import { STR_YMD_FORMAT } from "../../constants"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PermissionTypes } from "../../types/Permissions"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./AreaList.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

type CopyItemType = {
    datasSourceId: number
    layoutId: number
    srcAreaPackId: number
}

interface Props {}

export const AreaList: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { companyList, shopId, shopList, dataSourceList, accordionItems, notification, setNotification, changeExpanded, changeDataSource, changeLayout, updateDatasourceName, copyDataSource, changeHot, deleteDataSource, initManagementData, copyAreaPack } = useManagementDataContext()

    const { ableToUpdate, ableToCreate } = useAccessControlContext()

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [item, setItem] = useState<CopyItemType | undefined>(undefined)
    
    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageArea_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageArea_Edit) }, [ableToUpdate])

    /**
     * タイムゾーン（分）
     */
    const timezoneMinute: number = useMemo(() => {
        if (shopId && shopList) {
            const shop = shopList.find(el => el.shop_id === shopId)
            if (shop) return shop.timezone
        }
        return 0
    }, [shopId, shopList])

    // 下書きレイアウトセットが２つ以上あるときtrue
    const aLotOfDraft = useMemo(() => {
        let cnt = 0
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) {
                    cnt++
                }
            }
            return (cnt > 1)
        }
        return false
    }, [dataSourceList])

    /**
     * 指定されたデータソースに平面図情報がまったくないときtrue
     */
    const hasNoMap = useCallback((layoutSetId: number) => {
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                for (let lay of ds.layout_list) {
                    if (lay.mapping && lay.mapping.image) return false
                }
            }
        }
        return true
    }, [dataSourceList])

    /**
     * 指定されたデータソース（レイアウトセット）が本番かどうか調べます。本番ならtrue
     */
    const isActiveDataSource = useCallback((layoutSetId: number) => {
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds && ds.status === DataSourceStatusType.Active) return true
        }
        return false
    }, [dataSourceList])

    /**
     * データソース（レイアウトセット）一覧に下書きがないときtrue
     */
    const hasNoDraft = useMemo(() => {
        if (dataSourceList) {
            for (let ds of dataSourceList) {
                if (ds.status !== DataSourceStatusType.Active) return false
            }
        }
        return true
    }, [dataSourceList])
    
    /**
     * データソース名称が変更された時の処理
     */
    const handleChangeDataSourceName = useCallback((layoutSetId: number, newName: string) => {
        //console.log("newName:", newName)
        updateDatasourceName(layoutSetId, newName)
    }, [updateDatasourceName])

    /**
     * エリア詳細画面に遷移します。
     */
    const handleOpenDetail = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number, areaSetId: number) => {
        event.preventDefault()
        changeDataSource(layoutSetId)
        changeLayout(layoutId)
        const mode = (isActiveDataSource(layoutSetId) || !ableEdit) ? EditModeType.View : EditModeType.Edit
        navigate("/area_detail?" + UrlParameterType.Mode + "=" + mode + "&" + UrlParameterType.AreaSetId + "=" + areaSetId,
            { state: { Mode: mode, AreaSetId: areaSetId, from: location } })
        
    }, [ableEdit, navigate, changeDataSource, changeLayout, isActiveDataSource, location])

    /**
     * エリア新規登録画面に遷移します。
     */
    const handleOpenNewEdit = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number) => {
        event.preventDefault()
        changeDataSource(layoutSetId)
        changeLayout(layoutId)
        navigate("/area_detail?" + UrlParameterType.Mode + "=" + EditModeType.New + "&" + UrlParameterType.AreaSetId + "=0",
            { state: { Mode: EditModeType.New, AreaSetId: 0, from: location } })
        
    }, [navigate, changeDataSource, changeLayout, location])

    /**
     * エリアパックの複製ボタン押下時の処理
     */
    const handleAreaCopy = useCallback((event: React.MouseEvent, layoutSetId: number, layoutId: number, areaSetId: number) => {
        event.preventDefault()
        setItem({datasSourceId: layoutSetId, layoutId: layoutId, srcAreaPackId: areaSetId} as CopyItemType)
        setDialogOpen(true)
    }, [])

    /**
     * 本番適用ボタン押下時の処理
     * @param id 
     */
    const handleApplyToProduction = useCallback((id: number) => {
        changeHot(id)
    }, [changeHot])
    
    /**
     * データソースの複製ボタン押下時の処理
     * @param id 
     */
    const handleDataSourceReplication = useCallback((id: number) => {
        const notific: NotificationObject = {
            isActive: true,
            from: DisplayNameType.AreaList,
            to: DisplayNameType.AreaList,
            message: t("msgDuplicatedLayoutSet")
        }
        copyDataSource(id, notific)
    }, [copyDataSource, t])

    /**
     * 通知を確認した後の処理
     */
    const handleConfirmNotification = () => {
        // 通知をクリア
        setNotification({
            isActive: false,
            from: DisplayNameType.None,
            to: DisplayNameType.None,
            message: ""
        })
    }

    /**
     * データソースの削除ボタン押下時の処理
     */
    const handleDataSourceDelete = useCallback((id: number) => {
        deleteDataSource(id)
    }, [deleteDataSource])

    /**
     * 複製ダイアローグのキャンセル押下時の処理
     */
    const handleCopyCancel = useCallback(() => {
        setItem(undefined)
        setDialogOpen(false)
    }, [])

    /**
     * 複製ダイアローグのOK押下時の処理
     * @param id 
     * @param start 
     */
    const handleCopyDone = useCallback((id: number, start: number) => {
        if (item) copyAreaPack(item.layoutId, item.srcAreaPackId, start)
        setItem(undefined)
        setDialogOpen(false)
    }, [copyAreaPack, item])

    const handleRowOver = useCallback((event: React.MouseEvent) => {
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.add(style.rowOver)
    }, [])

    const handleRowOut = useCallback((event: React.MouseEvent) => {
        const tr = event.currentTarget as HTMLTableRowElement
        tr.classList.remove(style.rowOver)
    }, [])

    // 転写します
    const handleTranscription = useCallback(() => {
    }, [])

    // データ初期化
    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            console.log("◆データ初期化")
            initManagementData()
        }
    }, [])

    /**
     * 複製ダイアローグ表示
     */
    const areaPackCopyDialog = useMemo(() => {
        if (item && dialogOpen) {
            // 開始日は他のエリアパックと被らないように
            let dt = new Date()
            let ymd = format(dt, STR_YMD_FORMAT)
            const ds = dataSourceList.find(el => el.layout_set_id === item.datasSourceId)
            if (ds) {
                const lay = ds.layout_list.find(el => el.layout_id === item.layoutId)
                if (lay && lay.mapping) {
                    const ap = lay.mapping.area_packs.find(el => el.area_set_id === item.srcAreaPackId)
                    if (ap) {
                        // 他の開始日
                        const excludeYmds = lay.mapping.area_packs.map(el => { return el.startYmd })
                        // 被っていたらその翌日にスライド
                        while (excludeYmds.includes(ymd)) {
                            dt = addDays(dt, 1)
                            ymd = format(dt, STR_YMD_FORMAT)
                        }
                        const excludeDates = excludeYmds.map(el => { return parse(el, STR_YMD_FORMAT, new Date()) })
                        const start = dt.getTime()
                        const layoutStartDate = new Date(lay.start)
                        return (
                            <CopyDialog requestOpen={true} id={item.layoutId} start={start} onCancel={handleCopyCancel} onChange={handleCopyDone} excludeDates={excludeDates} minStartDate={layoutStartDate} title={t("header.duplicateArea")} />
                        )
                    }
                }
            }
        } 
        return (<></>)
    }, [item, dialogOpen, dataSourceList, t, handleCopyDone, handleCopyCancel])

    const dispAddArea = useMemo(() => { return t("button.addArea") }, [t])
    const dispAreaDup = useMemo(() => { return t("button.areaDuplication") }, [t])
    const dispAreaEdit = useMemo(() => { return t("button.editArea") }, [t])

    /**
     * アコーディオンの中のテーブルRowを作成します。
     */
    const getItemRows = useCallback((layoutSetId: number) => {
        const result: any = []
        if (dataSourceList) {
            const ds = dataSourceList.find(el => el.layout_set_id === layoutSetId)
            if (ds) {
                if (ds.layout_list.length > 0) {
                    ds.layout_list.forEach(lay => {
                        if (lay.mapping) {
                            const areaLen = lay.mapping.area_packs.length
                            if (areaLen === 0) {
                                // エリアが未登録の場合
                                const key = layoutSetId + "_" + lay.layout_id + "_0"
                                const itm = (
                                    <tr key={key}>
                                        <td className={style.tdId}>{lay.layout_id}</td>
                                        <td className={style.tdName}>{lay.name}</td>
                                        <td className={style.tdDate}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                        <td colSpan={6}>
                                            <Button styleType={StyleType.MuliAct} name="areaRegistration" label={t("button.newAreaRegistration")} onClick={e => handleOpenNewEdit(e, layoutSetId, lay.layout_id)} disabled={ableNew ? false : true} />
                                        </td>
                                    </tr>
                                )
                                result.push(itm)
                            } else {
                                // 降順並びにする
                                const sortedList = lay.mapping.area_packs.sort((a, b) => {
                                    const tzm = timezoneMinute ? timezoneMinute : 0
                                    const aTm = DateUtil.localYmd2Epochtime(a.startYmd, tzm)
                                    const bTm = DateUtil.localYmd2Epochtime(b.startYmd, tzm)
                                    //console.log("times:", aTm, bTm)
                                    return ((aTm ? aTm : 0) < (bTm ? bTm : 0)) ? 1 : -1
                                })
                                //console.log("sorted:", sortedList)
                                for (let i = -1; i < areaLen; i++) {
                                    const isProductionDS = isActiveDataSource(layoutSetId)
                                    const a = sortedList[i]
                                    if (i === -1) {
                                        // -1は追加ボタン「＋」を表示（本番は不要）
                                        if (!isProductionDS) {
                                            const key = layoutSetId + "_" + lay.layout_id + "_0"
                                            const itm = (
                                                <tr key={key}>
                                                    <td className={style.tdId} rowSpan={areaLen + 1}>{lay.layout_id}</td>
                                                    <td className={style.tdName} rowSpan={areaLen + 1}>{lay.name}</td>
                                                    <td className={style.tdDate} rowSpan={areaLen + 1}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                                    <td colSpan={6}>
                                                        {
                                                            (ableNew) ? (<button title={dispAddArea} onClick={e => handleOpenNewEdit(e, layoutSetId, lay.layout_id)} className={style.iconBtn}><PlusIcon size={16} /></button>) : (<></>)
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        }
                                    } else {
                                        const key = layoutSetId + "_" + lay.layout_id + "_" + a.area_set_id
                                        if (isProductionDS && i === 0) {
                                            const itm = (
                                                <tr key={key} onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
                                                    <td className={style.tdId} rowSpan={areaLen}>{lay.layout_id}</td>
                                                    <td className={style.tdName} rowSpan={areaLen}>{lay.name}</td>
                                                    <td className={style.tdDate} rowSpan={areaLen}>{DateUtil.numberTimeToSlashYmd(lay.start)}</td>
                                                    <td className={style.tdId}>{a.area_set_id}</td>
                                                    <td className={style.tdDate}>{a.startYmd}</td>
                                                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(a.created_at, timezoneMinute)}</td>
                                                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(a.modified_at, timezoneMinute)}</td>
                                                    <td className={style.tdLink}>
                                                        {
                                                            isProductionDS ? (
                                                                <div className={style.icon} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.area_set_id)}><ChevronRightIcon size={16} /></div>
                                                            ): (
                                                                <button title={dispAreaEdit} className={style.icon} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.area_set_id)}><PencilIcon size={16} /></button>  
                                                            )
                                                        }
                                                        
                                                    </td>
                                                    <td className={style.tdLink}>
                                                    
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        } else {
                                            const itm = (
                                                <tr key={key} onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
                                                    <td className={style.tdId}>{a.area_set_id}</td>
                                                    <td className={style.tdDate}>{a.startYmd}</td>
                                                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(a.created_at, timezoneMinute)}</td>
                                                    <td className={style.tdDate}>{DateUtil.epochtime2LocalYmdSlashHms(a.modified_at, timezoneMinute)}</td>
                                                    <td className={style.tdLink}>
                                                        {
                                                            isProductionDS ? (
                                                                <div className={style.icon} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.area_set_id)}><ChevronRightIcon size={16} /></div>
                                                            ): (
                                                                <button title={dispAreaEdit} className={style.iconBtn} onClick={e => handleOpenDetail(e, layoutSetId, lay.layout_id, a.area_set_id)}><PencilIcon size={16} /></button>
                                                            )
                                                        }
                                                        
                                                    </td>
                                                    <td className={style.tdLink}>
                                                        {
                                                            (isProductionDS || !ableEdit) ? (null) : (
                                                                <button title={dispAreaDup} onClick={e => handleAreaCopy(e, layoutSetId, lay.layout_id, a.area_set_id)} className={style.iconBtn}><CopyIcon size={16} /></button>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                            result.push(itm)
                                        }
                                    }
                                }
                            }
                        }
                    })
                } else {
                    const itm = (
                        <tr key="0_0_0">
                            <td colSpan={9} className={style.tdName}>
                                {t("layoutNotExist")}
                            </td>
                        </tr>
                    )
                    result.push(itm)
                }
            }
        }
        return result
    }, [dataSourceList, dispAddArea, dispAreaDup, timezoneMinute, ableNew, ableEdit, t, handleOpenDetail, handleOpenNewEdit, handleAreaCopy, isActiveDataSource, handleRowOver, handleRowOut])

    const accordionList = () => {
        if (accordionItems) {
            return accordionItems.map(el => {
                const itemRows = getItemRows(el.id)
                return (
                    <DatasourceAccordion
                        key={el.id}
                        isExpanded={el.isExpanded}
                        layoutSetId={el.id}
                        name={el.name}
                        status={el.status}
                        setExpanded={changeExpanded}
                        hasNoMap={hasNoMap(el.id)}
                        hasNoDraft={hasNoDraft}
                        updateName={handleChangeDataSourceName}
                        applyToProduction={handleApplyToProduction}
                        onReplication={handleDataSourceReplication}
                        onDelete={handleDataSourceDelete}
                        aLotOfDraft={aLotOfDraft}
                        readyTranscription={false}
                        onTranscription={handleTranscription}
                    >
                        <div className={style.accItems}>
                            {
                                (hasNoMap(el.id)) ? (
                                    <div className={style.noMapBtn}>
                                        <span>{t("msgMapRegistrationRequired")}</span>
                                    </div>
                                ) : (
                                    <table className={style.table}>
                                        <thead>
                                            <tr>
                                                <th colSpan={3} className={style.thOver}><div className={style.overHead}><span>{t("table.layout")}</span></div></th>
                                                <th colSpan={6} className={style.thOver}><div className={style.overHead}><span>{t("table.areaSet")}</span></div></th>
                                            </tr>
                                            <tr className={style.thRow}>
                                                <th className={style.th_id}>ID</th>
                                                <th className={style.th_name}>{t("name")}</th>
                                                <th className={style.th_date}>{t("table.startDate")}</th>
                                                <th className={style.th_id}>ID</th>
                                                <th className={style.th_date}>{t("table.startDate")}</th>
                                                <th className={style.th_date}>{t("table.createdAt")}</th>
                                                <th className={style.th_date}>{t("table.modifiedAt")}</th>
                                                <th className={style.th_link}></th>
                                                <th className={style.th_link}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemRows}
                                        </tbody>
                                    </table>                                        
                                )
                            }
                        </div>
                    </DatasourceAccordion>
                )
            })
        }
        return null
    }

    return (
        <ListFrame
            page={PageName.InfoArea}
            title={t("header.areaRegistrationEdit")}
            companySelect={true}
            shopSelect={true}
            newEntry={false}
            copyEntry={false}
            permission={PermissionTypes.ManageArea_View}
        >
            {isProduction ? (<TrackingPageView page_title="bsinfo-area-list" />) : (null)}
            <div className={style.bodyLabel}>{t("header.dataSourceList")}</div>
            <div className={style.lsetList}>
                {accordionList()}
            </div>
            <NotificationDialog notification={notification} myName={DisplayNameType.AreaList} onAction={handleConfirmNotification} />
            {areaPackCopyDialog}
        </ListFrame>
    )
}