import { ResUser } from "../../api/data/login/LoginInfo"
import { Role } from "../../api/data/core/Enums"
import { UserModel2Res } from "../../lib/ModelUtil"
import { UserModel } from "../../types/Management"
import { getUserType } from "./UserTypeData"


export const userModelList: UserModel[] = [
    {
        user_id: 1,
        name: "管理者",
        login_id: "root@example.com",
        user_type: getUserType(1),
        user_type_id: 1,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [1,21,22,40],
        expired_at: undefined,
        password: undefined,
        company_id: 2
    },
    {
        user_id: 2,   
        name: "店長",
        login_id: "shopmanager@example.com",
        user_type: getUserType(4),
        user_type_id: 4,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 3,
        name: "店員Jr",
        login_id: "juniorstaff@example.com",
        user_type: getUserType(7),
        user_type_id: 7,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 4,
        name: "池袋店員",
        login_id: "ike@example.com",
        user_type: getUserType(6),
        user_type_id: 6,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1641998437000,
        bookmarks: [22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 5,
        name: "店長Jr",
        login_id: "jrmanager@example.com",
        user_type: getUserType(5),
        user_type_id: 5,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 6,
        name: "東部統括",
        login_id: "eastarea@example.com",
        user_type:  getUserType(3),
        user_type_id: 3,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [1,22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 116,
        name: "西部統括",
        login_id: "westarea@example.com",
        user_type:  getUserType(3),
        user_type_id: 3,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [21],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 7,
        name: "本部",
        login_id: "headquoter@example.com",
        user_type: getUserType(2),
        user_type_id: 2,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [1,21,22],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 8,
        name: "開発者",
        login_id: "developer@example.com",
        user_type: getUserType(8),
        user_type_id: 8,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [1,21,22,40],
        expired_at: undefined,
        password: undefined,
        company_id: 2
    },
    {
        user_id: 9,
        name: "マネージャー",
        login_id: "supervisor@example.com",
        user_type: getUserType(3),
        user_type_id: 3,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [40],
        expired_at: undefined,
        password: undefined,
        company_id: 3
    },
    {
        user_id: 101,
        name: "原宿店員",
        login_id: "hara@example.com",
        user_type: getUserType(6),
        user_type_id: 6,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1641998437000,
        expired_at: undefined,
        bookmarks: [1],
        password: undefined,
        company_id: 1
    },
    {
        user_id: 102,
        name: "博多店員",
        login_id: "hakata@example.com",
        user_type: getUserType(6),
        user_type_id: 6,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1641998437000,
        expired_at: undefined,
        bookmarks: [21],
        password: undefined,
        company_id: 1
    },
    {
        user_id: 122,   
        name: "博多店長",
        login_id: "hakatamanager@example.com",
        user_type: getUserType(4),
        user_type_id: 4,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [21],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 123,   
        name: "Tokyo店長",
        login_id: "tokyomanager@example.com",
        user_type: getUserType(4),
        user_type_id: 4,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1640998437000,
        bookmarks: [1],
        expired_at: undefined,
        password: undefined,
        company_id: 1
    },
    {
        user_id: 94,
        name: "✘店員",
        login_id: "lost@example.com",
        user_type: getUserType(6),
        user_type_id: 6,
        display_order: 1,
        dashboard_order: [],
        created_at: 1640998437000,
        modified_at: 1641998437000,
        expired_at: 1641998437000,
        bookmarks: [22],
        password: undefined,
        company_id: 1
    }

]

export const getUserList = (company_id: number): Map<number, [ResUser, number[]]> => {
    const list = userModelList.filter(user => user.company_id === company_id)
    const map = new Map<number, [ResUser, number[]]>()
    for (let user of list) {
        const book = user.bookmarks ? user.bookmarks : []
        const id = user.user_id
        map.set(id, [UserModel2Res(user), book])
    }
    return map
}

export const get_users = (company_id: number): ResUser[] => {
    return userModelList.filter(user => user.company_id === company_id).map(user => { return UserModel2Res(user) })
}

export const getUser = (type: Role): ResUser => {
    for (let user of userModelList) {
        if (user.user_type === type) {
            return UserModel2Res(user)
        }
    }
    throw new Error("未設定のロールです。:" + type)
}

