import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { AcceptButtonType, CustomDialog } from "../custom_dialog/CustomDialog"
import { DS_NO_NAME } from "../../constants"

import style from "./RenameDialog.module.css"

type Props = {
    requestOpen: boolean
    name: string
    guideMessage: string
    onCancel: () => void
    onChange: (newName: string) => void
}

export const RenameDialog: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    
    const [editName, setEditName] = useState<string>(() => {
        if (props.name === "" || props.name === DS_NO_NAME) {
            return ""
        } else {
            return props.name
        }
    })

    const handleCancel = () => {
        props.onCancel()
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        setEditName(val)
    }

    const handleSaveName = () => {
        if (editName.length === 0) {
            props.onChange(DS_NO_NAME)
        } else {
            props.onChange(editName)
        }
    }

    const strPlaceholder = useMemo(() => { return "（" + t("unregistered") + "）" }, [t])
    
    const width = 520

    const height = 208

    return (
        <CustomDialog
            requestOpen={props.requestOpen}
            width={width}
            height={height}
            guideMessage={props.guideMessage}
            onAccept={handleSaveName}
            onCancel={handleCancel}
            buttonType={AcceptButtonType.Save}
        >
            <span className={style.nameInput}><input name="DSName" type="text" value={editName} onChange={handleChangeName} placeholder={strPlaceholder} /></span>
        </CustomDialog>
    )
}