import React, { useMemo } from "react";

import { StyleType, Button, ButtonType } from "../button/Button";
import style from "./SeriesSwitcher.module.css"


export const SwitcherFontType = {
    Normal: "normal",   // 分析条件の 単一/比較 用 Font:Noto Suns 13px bold, H:39px
    Mulish: "mulish",   // 分析結果の タブ切替や表示内容切替 用 Font:Mulish 13px semibold, H:33px
    MulishMini: "mini", // Mulish 12px
} as const 
export type SwitcherFont = typeof SwitcherFontType[keyof typeof SwitcherFontType]

export interface BtnProp {
    name: string
    label: string
    active?: boolean | undefined
}

interface Props {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    buttonList: Array<BtnProp>
    activeName: string
    fontType?: SwitcherFont
}

export const SeriesSwitcher: React.FC<Props> = (props) => {
    
    const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        props.onClick(e)
    }

    const actStyle: ButtonType = useMemo(() => {
        if (props.fontType) {
            if (props.fontType === SwitcherFontType.Mulish) {
                return StyleType.MuliAct
            } else if (props.fontType === SwitcherFontType.MulishMini) {
                return StyleType.MulishMiniAct
            }
        }
        return StyleType.Primary
    }, [props.fontType])

    const inactStyle: ButtonType = useMemo(() => {
        if (props.fontType) {
            if (props.fontType === SwitcherFontType.Mulish) {
                return StyleType.MuliInact
            } else if (props.fontType === SwitcherFontType.MulishMini) {
                return StyleType.MulishMiniInact
            }
        }
        return StyleType.Secondary
    }, [props.fontType])

    const buttons = props.buttonList.map((el, i) => { 
        return (
            <Button
                styleType={(props.activeName === el.name ? actStyle : inactStyle)}
                name={el.name}
                label={el.label}
                onClick={e => { onClickHandler(e) }}
                key={i}                
            />
        )
    })

    return (
        <div className={style.border}>
            {buttons}
        </div>
    )
}