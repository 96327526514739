import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { format, addDays } from "date-fns"

import { ResLayout } from "../../api/data/analysis/FullLayout"
import { useManagementDataContext } from "../../providers/ManagementData"
import { useAccessControlContext } from "../../providers/AccessControler"
import { PageName } from "../../types"
import { PermissionTypes } from "../../types/Permissions"
import { EditModeType, LineDefType, LineModel, LinePackModel, UrlParameterType } from "../../types/Management"
import { STR_YMD_FORMAT } from "../../constants"
import { CircleCloseButton } from "../../component/circle_close_button/CircleCloseButton"
import { Button, StyleType } from "../../component/button/Button"
import { ZoomButtonSet } from "../../component/zoom_button_set/ZoomButtonSet"
import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { HoverEffectButton } from "../../component/hover_effect_button/HoverEffectButton"
import { DeleteDialog, PaneSizeType } from "../../component/delete_dialog/DeleteDialog"
import { TrackingPageView } from "../common/TrackingPageView"
import { ModalFrame } from "../common/ModalFrame"
import { LineEdit } from "./LineEdit"
import { useMouseMove } from "../../lib/useMouseMove"
import LineDetailPicWriter from "../../lib/LineDetailPicWriter"

import style from "./LineDetail.module.css"

export const LINE_ID_ENT = 1000
export const LINE_ID_EXT = 2000
export const LINE_ID_PAS = 3000
export const LINE_ID_ADD = 4000

// キャンバスの大きさ
const MAP_WIDTH = 620
const MAP_HEIGHT = 440

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn


interface Props {}

export const LineDetail: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    
    const { shopList, shopId, layoutId, layoutList, changeLinePack, linePackId, linePackList, saveLinePack, deleteLinePack, editingLinePack, updateEditingLinePack } = useManagementDataContext()
    
    const { ableToCreate, ableToUpdate, ableToDelete } = useAccessControlContext()
    
    const [writer, setWriter] = useState<LineDetailPicWriter | undefined>(undefined)
    // 選択中のラインID
    const [selectedLineId, setSelectedLineId] = useState<number>(0)
    // ダイアローグOpenフラグ(Open=true)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    // ライン編集中フラグ（編集中=true）
    const [lineEditing, setLineEditing] = useState<boolean>(false)
    // 編集中のラインデータ(LineEditコンポーネントに渡す)
    const [editData, setEditData] = useState<LineModel | undefined>(undefined)
    // 編集前のラインリストのバックアップ（編集をキャンセルしたときに戻すため）
    const [linesBuckup, setLinesBackup] = useState<LineModel[] | undefined>(undefined)
    // 選択されたラインの削除確認ダイアローグOpenフラグ
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)

    const ref = useRef<HTMLCanvasElement>(null)
    const edtRef = useRef<HTMLCanvasElement>(null)
    const aniRef = useRef<HTMLCanvasElement>(null)
    const refDpk = useRef<HTMLDivElement>(null)

    const location = useLocation()
    const search = location.search
    const state = location.state
    const queryParams = useMemo(() => { return new URLSearchParams(search) }, [search])
    const mode = useMemo(() => {
        const m = queryParams.get(UrlParameterType.Mode)
        if (m) return m
        return state ? state.Mode : undefined
    }, [queryParams, state])
    const lineSetId = useMemo(() => {
        const lsi = queryParams.get(UrlParameterType.LineSetId)
        if (lsi) return lsi
        return state ? state.LineSetId : undefined
    }, [queryParams, state])

    // ユーザー権限
    const ableNew = useMemo(() => { return ableToCreate(PermissionTypes.ManageLine_New) }, [ableToCreate])
    const ableEdit = useMemo(() => { return ableToUpdate(PermissionTypes.ManageLine_Edit) }, [ableToUpdate])
    const ableDelete = useMemo(() => { return ableToDelete(PermissionTypes.ManageLine_Delete) }, [ableToDelete])

    /**
     * 初期表示データの作成
     */
    useEffect(() => {
        if (linePackId === undefined && mode) {
            //日付の作成（他のLinePackと被らないように）
            let dt = new Date()
            let ymd = format(dt, STR_YMD_FORMAT)
            if (linePackList && linePackList.length > 0) {
                const excludes = linePackList.map(el => { return el.startYmd })
                while (excludes.includes(ymd)) {
                    dt = addDays(dt, 1)
                    ymd = format(dt, STR_YMD_FORMAT)
                }
            }
            //const tm = dt.getTime()
            if (mode === EditModeType.New) {
                //新規モード（LinePackを新規作成してeditingLinePackにする）
                changeLinePack(0)
                const lp: LinePackModel = {
                    line_set_id: 0,
                    startYmd: ymd,
                    lines: []
                }
                updateEditingLinePack(lp)
            } else if (mode === EditModeType.Copy && lineSetId) {
                //コピーモード
                const srcLineSetId = parseInt(lineSetId)
                changeLinePack(0)
                if (linePackList) {
                    const srcLp = linePackList.find(el => el.line_set_id === srcLineSetId)
                    if (srcLp) {
                        const dstLp: LinePackModel = {
                            line_set_id: 0,
                            startYmd: ymd,
                            lines: [...srcLp.lines]
                        }
                        updateEditingLinePack(dstLp)
                    }
                }
            } else if ((mode === EditModeType.Edit || mode === EditModeType.View) && lineSetId) {
                //console.log("編集モード lineSetId:", lineSetId)
                //編集モード
                const lsid = parseInt(lineSetId)
                changeLinePack(lsid)
                if (linePackList) {
                    const lp = linePackList.find(el => el.line_set_id === lsid)
                    if (lp) {
                        //対象をクローンしてeditingLinePackに設定
                        const cloneLp: LinePackModel = {
                            line_set_id: lsid,
                            startYmd: lp.startYmd,
                            lines: [...lp.lines]
                        }
                        //console.log("cloneLp:", cloneLp)
                        updateEditingLinePack(cloneLp)
                    }
                }
            }
        }
    }, [mode, lineSetId, linePackId, linePackList, changeLinePack, updateEditingLinePack])

    // 選択された店舗
    const shop = useMemo(() => {
        if (shopId && shopList) {
            const sh = shopList.find(el => el.shop_id === shopId)
            if (sh) return sh
        }
        return undefined
    }, [shopList, shopId])

    // 選択されたレイアウト
    const layout = useMemo(() => {
        if (layoutList && layoutId) {
            const lay = layoutList.find(el => el.layout_id === layoutId)
            if (lay) return lay
        }
        return undefined
    }, [layoutId, layoutList])

    // 保存ボタンのdisable制御
    const disableSaveButton = useMemo(() => {
        return lineEditing
    }, [lineEditing])

    // 開始日
    const startDate = useMemo(() => {
        if (editingLinePack) {
            const dt = new Date(editingLinePack.startYmd)
            return dt
        }
    }, [editingLinePack])

    // 除外すべき開始日リスト
    const excludeDates = useMemo(() => {
        const result: Date[] = []
        if (linePackId && linePackList) {
            for (let lp of linePackList) {
                if (lp.line_set_id !== linePackId) {
                    const st = new Date(lp.startYmd)
                    result.push(st)
                }
            }
        }
        return result
    }, [linePackId, linePackList])

    // 画面Closeボタン押下時の処理
    const handleClose = () => {
        // ひとつ前のページに戻る
        navigate("/line_list")
    }

    /**
     * 保存ボタン押下時の処理
     */
    const handleSave = () => {
        // editingLinePackを保存
        saveLinePack()
        navigate("/line_list")
    }

    /**
     * （選択したラインの）削除ボタン押下時の処理、確認ダイアローグを開く
     */
    const handleLineDelete = () => {
        if (!isOpenDeleteDialog && ableDelete) setIsOpenDeleteDialog(true)
    }

    /**
     * 削除確認ダイアローグのOK処理
     */
    const handleLineDeleteAccepted = () => {
        if (!ableDelete) return
        // editingLinePackから対象を削除
        if (editingLinePack && selectedLineId) {
            // Clone作成
            const newPack = { ...editingLinePack }
            // 対象Line抜き取り
            const newLines = newPack.lines.filter(el => el.id !== selectedLineId)
            newPack.lines = newLines
            // 更新
            updateEditingLinePack(newPack)
        }
        // ID=0として選択なしにする
        setSelectedLineId(0)
        // Close Dialog
        setIsOpenDeleteDialog(false)
    }
    
    /**
     * 削除確認ダイアローグのCancel処理
     */
    const handleLineDeleteCanceled = () => {
        setIsOpenDeleteDialog(false)
    }

    /**
     * ライン追加ボタン押下時の処理
     */
    const handleLineAdd = useCallback(() => {
        if (!ableEdit && !ableNew) return
        // ID=0として選択なしにする
        setSelectedLineId(0)
        if (editingLinePack) {
            // 選択中のラインを解除
            for (let ln of editingLinePack.lines) {
                ln.isAct = false
            }
            // 再描画
            if (writer && ref && ref.current) {
                const ctx = ref.current.getContext("2d")
                if (ctx) writer.drawImageAndLines(ctx)
            }
        }
        // IDを生成(最大値＋１)
        let newId = LINE_ID_ADD
        if (editingLinePack && editingLinePack.lines.length > 0) {
            newId = (editingLinePack.lines.reduce((a, b) => (a.id > b.id) ? a : b)).id + 1
        }
        // 新しいラインを用意
        const data: LineModel = {
            id: newId,
            type: LineDefType.Enter,
            left: { x: 0, y: 0 },
            right: { x: 100, y: 100 },
            isAct: false
        }
        setEditData(data)
        // 編集対象を描画
        if (writer && edtRef && edtRef.current) {
            //console.log("編集対象 data:", data)
            const ctx = edtRef.current.getContext("2d")
            if (ctx) writer.drawActiveLine(ctx, data)
        }        
        // 編集中フラグを立てる
        setLineEditing(true)
        // 追加なのでバックアップなし
        setLinesBackup(undefined)
    }, [writer, ref, editingLinePack, ableNew, ableEdit])

    /**
     * ライン編集ボタン押下時の処理
     */
    const handleSelectedLineEdit = useCallback(() => {
        if (!ableEdit) return 
        if (selectedLineId) {
            if (editingLinePack) {
                // 編集用データをクローン
                const lm = editingLinePack.lines.find(el => el.id === selectedLineId)
                if (lm) {
                    const data: LineModel = {
                        id: lm.id,
                        type: lm.type,
                        left: { x: lm.left.x, y: lm.left.y },
                        right: { x: lm.right.x, y: lm.right.y },
                        isAct: false
                    }
                    setEditData(data)
                    // 編集対象を描画
                    if (writer && edtRef && edtRef.current) {
                        //console.log("edtRef 7")
                        const ctx = edtRef.current.getContext("2d")
                        if (ctx) writer.drawActiveLine(ctx, data)
                    }
                }
                // バックアップ
                const bk = [...editingLinePack.lines]
                setLinesBackup(bk)
                // 編集対象ラインを抜く（描画対象から外すため）
                const newLines = [...editingLinePack.lines].filter(el => el.id !== selectedLineId)
                editingLinePack.lines = newLines
                // 再描画
                if (writer && ref && ref.current) {
                    const ctx = ref.current.getContext("2d")
                    writer.lines = editingLinePack.lines
                    if (ctx) writer.drawImageAndLines(ctx)
                }
            }
            // 編集中フラグを立てる
            setLineEditing(true)
        }
    }, [selectedLineId, editingLinePack, writer, ref, ableEdit])

    /**
     * 編集中のラインデータの更新処理
     * @param newData 
     */
    const handleChangeEditData = (newData: LineModel) => {
        setEditData(newData)
        // 編集対象を描画
        if (writer && edtRef && edtRef.current) {
            //console.log("edtRef 11")
            const ctx = edtRef.current.getContext("2d")
            //console.log(writer)
            if (ctx) writer.drawActiveLine(ctx, newData)
        }
    }
    
    /**
     * ラインの編集をキャンセル
     */
    const handleLineEditCancel = () => {
        if (linesBuckup) {
            // 編集モードの場合
            if (editingLinePack) {
                // バックアップをもどす。
                editingLinePack.lines = [...linesBuckup]
                // 選択解除
                //for (let ln of editingLinePack.lines) {
                //    ln.isAct = false
                //}
                // 再描画
                if (writer && ref && ref.current) {
                    const ctx = ref.current.getContext("2d")
                    writer.lines = editingLinePack.lines
                    if (ctx) writer.drawImageAndLines(ctx)
                }
            }
            // バックアップクリア
            setLinesBackup(undefined)
        }
        // 編集用キャンバスをクリア
        if (writer && edtRef && edtRef.current) {
            //console.log("edtRef 15 clear")
            const ctx = edtRef.current.getContext("2d")
            if (ctx) writer.clearCanvas(ctx)
        }
        // 編集中データの破棄
        setEditData(undefined)
        // 編集中フラグをさげる
        setLineEditing(false)
        // ID=0として選択なしにする
        setSelectedLineId(0)
    }
    
    /**
     * ラインの編集を決定
     */
    const handleLineEditDone = () => {
        // 編集したデータをeditingLinePackに反映
        if (editingLinePack && editData) {
            const newPack = { ...editingLinePack }
            //editData.isAct = false
            const newList = [...newPack.lines, editData]
            newPack.lines = newList
            updateEditingLinePack(newPack)
            //console.log("更新されたeditingLinePack:", newPack)
            // 再描画
            if (writer && ref && ref.current) {
                const ctx = ref.current.getContext("2d")
                writer.lines = newList
                if (ctx) writer.drawImageAndLines(ctx)
            }
        }
        // バックアップ破棄
        if (linesBuckup) setLinesBackup(undefined)
        // 編集用キャンバスをクリア
        if (writer && edtRef && edtRef.current) {
            //console.log("edtRef 19")
            const ctx = edtRef.current.getContext("2d")
            if (ctx) writer.clearCanvas(ctx)
        }
        // 編集中データの破棄
        setEditData(undefined)
        // 編集中フラグをさげる
        setLineEditing(false)
        // ID=0として選択なしにする
        setSelectedLineId(0)
    }
    

    // このラインパックを削除します。
    const handleDeleteAllLines = () => {
        if (lineSetId) {
            const id = parseInt(lineSetId)
            deleteLinePack(id)
            changeLinePack(undefined)
        }
        setDialogOpen(false)
        navigate("/line_list")
    }

    // 全ライン削除ボタン押下時の処理
    const handleOpenDialogDelete = () => {
        if (!dialogOpen) setDialogOpen(true)
    }

    // 全ライン削除の確認ダイアローグを閉じる
    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    /**
     * 開始日を変更します。
     * @param event 
     */
    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        if (event && editingLinePack) {
            const dt = (event as Date)
            const ymd = format(dt, STR_YMD_FORMAT)
            const clone = { ...editingLinePack }
            clone.startYmd = ymd
            updateEditingLinePack(clone)
        }
    }

    /**
     * 平面図を拡大します。
     */
    const handleZoomIn = () => {
        if (writer) {
            writer.zoomIn()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageAndLines(ctx)
            // 編集中のライン描画
            if (lineEditing && editData) {
                const eCtx = edtRef.current?.getContext("2d")
                if (eCtx) writer.drawActiveLine(eCtx, editData)
            }
        }
    }
    
    /**
     * 平面図を縮小します。
     */
    const handleZoomOut = () => {
        if (writer) {
            writer.zoomOut()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageAndLines(ctx)
            // 編集中のライン描画
            if (lineEditing && editData) {
                const eCtx = edtRef.current?.getContext("2d")
                if (eCtx) writer.drawActiveLine(eCtx, editData)
            }
        }
    }
    
    /**
     * 平面図を元のスケールに戻します。
     */
    const handleZoomReset = () => {
        if (writer) {
            writer.zoomReset()
            const ctx = ref.current?.getContext("2d")
            if (ctx) writer.drawImageAndLines(ctx)
            // 編集中のライン描画
            if (lineEditing && editData) {
                const eCtx = edtRef.current?.getContext("2d")
                if (eCtx) writer.drawActiveLine(eCtx, editData)
            }
        }
    }

    /**
     * セレクトの中からラインが選ばれたときの処理
     * @param event 
     */
    const handleChangeSelectedLine = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const v = event.target.value
        const id = parseInt(v)
        setSelectedLineId(id)
        //console.log("selected id:", id)
        if (editingLinePack) {
            for (let ln of editingLinePack.lines) {
                ln.isAct = (ln.id === id)
            }
        }
        if (writer && ref && ref.current) {
            //console.log("draw 8")
            const ctx = ref.current.getContext("2d")
            if (ctx) writer.drawImageAndLines(ctx)
        }
    }

    /**
     * 平面図上でのマウス操作イベント
     */
    const mousePress = useMouseMove(
        aniRef,
        (event: MouseEvent) => {
            // マウスドラッグ中の処理
            if (writer) {
                const srcCanvas = ref.current
                const dstCanvas = aniRef.current
                if (srcCanvas && dstCanvas) writer.mouseMove(event, mousePress, srcCanvas, dstCanvas)
            }
        }, (event: MouseEvent) => {
            // マウスボタンを離したときの処理
            if (writer) {
                // 再描画
                const ctx = ref.current?.getContext("2d")
                if (ctx) {
                    if (lineSetId && editingLinePack) {
                        writer.lines = editingLinePack.lines
                        writer.drawImageAndLines(ctx)
                        // 編集中のライン描画
                        if (lineEditing && editData) {
                            const eCtx = edtRef.current?.getContext("2d")
                            if (eCtx) writer.drawActiveLine(eCtx, editData)
                        }
                    } else {
                        writer.drawFitImage(ctx)
                    }
                }
                // ドラッグ領域をクリア
                const aniCtx = aniRef.current?.getContext("2d")
                if (aniCtx) aniCtx.clearRect(0, 0, writer.canvasWidth, writer.canvasHeight)

            }
        }, (event: MouseEvent) => {
            // マウスボタンを押したときの処理
            if (writer) writer.mousePress()
        }
    )

    useEffect(() => {
        // MapWriterをつくる
        if (layout && layout.mapping) {
            if (writer === undefined) {
                // ★ MapWriterが古いResLayoutを参照したままなのでここで変換が必要
                const resLay: ResLayout = {
                    layout_id: layout.layout_id,
                    name: layout.name,
                    start: layout.start,
	                image: layout.mapping.image,
                    pixel_width: layout.mapping.pixel_width,
                    pixel_height: layout.mapping.pixel_height,
                    origin_x: layout.mapping.origin_x,
                    origin_y: layout.mapping.origin_y,
                    mm_per_pixel: layout.mapping.mm_per_pixel,
                    area_unitcell_pixel: layout.mapping.area_unitcell_pixel,
                    area_set: [],
                    line_set: []
                }
                const pwriter = new LineDetailPicWriter(resLay, [], MAP_WIDTH, MAP_HEIGHT)
                setWriter(pwriter)
            } else {
                // Writerが設定できたら描画する
                const ctx = ref.current?.getContext("2d")
                if (ctx) {
                    if (lineSetId && editingLinePack) {
                        writer.lines = editingLinePack.lines
                        writer.drawImageAndLines(ctx)
                        // 編集中のライン描画
                        if (lineEditing && editData) {
                            const eCtx = edtRef.current?.getContext("2d")
                            if (eCtx) writer.drawActiveLine(eCtx, editData)
                        }
                    } else {
                        writer.drawFitImage(ctx)
                    }
                }
            }
        }
    }, [layout, setWriter, writer, editData, lineEditing, editingLinePack, lineSetId])

    /**
     * 入店ラインのSelectOption作成
     */
    const selectOptionEnters = useMemo(() => {
        const result: any = []
        let grp = false
        //console.log("editingLinePack", editingLinePack)
        if (editingLinePack) {
            for (let ln of editingLinePack.lines) {
                if (ln.type === LineDefType.Enter) {
                    const disp = "( " + ln.left.x + ", " + ln.left.y + " ) - ( " + ln.right.x + ", " + ln.right.y + " )"
                    const itm = (<option key={ln.id} value={ln.id}>{disp}</option>)
                    if (!grp) {
                        const txt = t("enterLine")
                        const g = (<optgroup label={txt} key="in"></optgroup>)
                        result.push(g)
                        grp = true
                    }
                    result.push(itm)
                }
            }
        }
        //console.log(result)
        return result
    }, [editingLinePack, t])

    /**
     * 退店ラインのSelectOption作成
     */
    const selectOptionExits = useMemo(() => {
        const result: any = []
        let grp = false
        if (editingLinePack) {
            for (let ln of editingLinePack.lines) {
                if (ln.type === LineDefType.Exit) {
                    const disp = "( " + ln.left.x + ", " + ln.left.y + " ) - ( " + ln.right.x + ", " + ln.right.y + " )"
                    const itm = (<option key={ln.id} value={ln.id}>{disp}</option>)
                    if (!grp) {
                        const txt = t("exitLine")
                        const g = (<optgroup label={txt} key="out"></optgroup>)
                        result.push(g)
                        grp = true
                    }
                    result.push(itm)
                }
            }
        }
        return result
    }, [editingLinePack, t])

    /**
     * パスラインのSelectOption作成
     */
    const selectOptionPasses = useMemo(() => {
        const result: any = []
        let grp = false
        if (editingLinePack) {
            for (let ln of editingLinePack.lines) {
                if (ln.type === LineDefType.Pass) {
                    const disp = "( " + ln.left.x + ", " + ln.left.y + " ) - ( " + ln.right.x + ", " + ln.right.y + " )"
                    const itm = (<option key={ln.id} value={ln.id}>{disp}</option>)
                    if (!grp) {
                        const txt = t("passLine")
                        const g = (<optgroup label={txt} key="pas"></optgroup>)
                        result.push(g)
                        grp = true
                    }
                    result.push(itm)
                }
            }
        }
        return result
    }, [editingLinePack, t])

    /**
     * ライン未選択の場合に表示するガイドメッセージ
     */
    const selectGuideMessage = useMemo(() => {
        if (editingLinePack && editingLinePack.lines.length > 0) {
            if (selectedLineId) t("msgSelectLine")
        }
        return ""
    }, [t, editingLinePack, selectedLineId])

    const layoutStartDate = useMemo(() => {
        if (layout) {
            const dt = new Date(layout.start)
            return dt
        }
    }, [layout])

    // DatePickerの日付フォーマット
    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    return (
        <ModalFrame page={PageName.InfoLine} title={t("header.entryLineRegistrationEdit")} width={1100} height={681}>
            <div className={style.panel}>
                <div className={style.head}>
                    <div className={style.title}>
                        {t("header.entryLineDetails")}
                        {isProduction ? (<TrackingPageView page_title="bsinfo-line-detail"/>) : (null)}
                    </div>
                    <div className={style.close}><CircleCloseButton onClose={handleClose} /></div>
                </div>
                <div className={style.body}>
                    <div className={style.leftside}>
                        <div className={style.lefttop}>
                            <table className={style.table}>
                                <tbody>
                                    <tr>
                                        <td className={style.lsHead}>{t("mapId")}</td>
                                        <td className={style.lsData}>{layoutId}</td>
                                    </tr>
                                    <tr>
                                        <td className={style.lsHead}>{t("storeName")}</td>
                                        <td className={style.lsData}>{shop ? shop.name : ""}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={style.leftbtm}>
                            <div className={style.section}>{t("registeredLine")}</div>
                            <div className={style.map}>
                                {
                                    (mode === EditModeType.New) ? (
                                        <div className={style.unregistered}>
                                            <span className={style.text}>{t("unregisterdLine")}</span>
                                        </div>
                                    ): (
                                        <div className={style.legend}>
                                        </div>
                                    )
                                }
                                <div className={style.canvasWrap}>
                                    <canvas className={(mode === EditModeType.New) ? style.canvasBorder : style.canvas} width={MAP_WIDTH} height={MAP_HEIGHT} ref={ref}></canvas>
                                    <canvas className={style.edtCanvas} width={MAP_WIDTH} height={MAP_HEIGHT} ref={edtRef} ></canvas>
                                    <canvas className={style.aniCanvas} width={MAP_WIDTH} height={MAP_HEIGHT} ref={aniRef} ></canvas>
                                    <div className={style.zoomBtn}>
                                        <ZoomButtonSet onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onZoomReset={handleZoomReset} />
                                    </div>
                                    
                                </div>
                                <div className={style.memo}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.rightside}>
                        {
                            (mode === EditModeType.View) ? (
                                <div className={style.rightTop}></div>        
                            ) : (
                                <div className={style.rightTop}>
                                    <Button label={t("cancel")} name="cancel" styleType={StyleType.Reverse} onClick={handleClose} />
                                    <span className={style.spc}></span>
                                    <Button label={t("save")} name="save" styleType={StyleType.Normal} onClick={handleSave} disabled={disableSaveButton} />
                                </div>                                    
                            )
                        }
                        <div className={style.rightMid}>
                            <div className={style.startDate}>
                                <label className={style.startLabel}>{t("startDate")}</label>
                                <span className={style.startBorder}>
                                    <FupDatepicker 
                                        type={DpType.Micro}
                                        onChange={handleChangeStartDate}
                                        divRef={refDpk}
                                        selected={(startDate && layoutStartDate && startDate < layoutStartDate) ? layoutStartDate : startDate}
                                        locale={i18n.language}
                                        dateFormat={datepickFormat}
                                        dateFormatCalendar={datepickFormatCalendar}
                                        excludeDates={excludeDates}
                                        disabled={mode === EditModeType.View || lineEditing}
                                        minDate={layoutStartDate}
                                    />
                                </span>
                            </div>
                        </div>
                        {
                            lineEditing ? (
                                <div className={style.rightBottom}>
                                    <LineEdit
                                        line={editData}
                                        updateLine={handleChangeEditData}
                                        onCancel={handleLineEditCancel}
                                        onDone={handleLineEditDone}
                                    />
                                </div>
                            ): (
                                <div className={style.rightBottom}>
                                    <div className={style.selector}>
                                        <select className={style.lineSelect} size={10} onChange={handleChangeSelectedLine}>
                                            {selectOptionEnters}
                                            {selectOptionExits}
                                            {selectOptionPasses}
                                        </select>
                                        <div className={style.msgSelect}>{selectGuideMessage}</div>
                                    </div>
                                    <div className={style.buttons}>
                                        {
                                            mode === EditModeType.View ? (
                                                <div className={style.btnRow1st}>
                                                </div>
                                            ) : (
                                                <div className={style.btnRow1st}>
                                                    {
                                                        (selectedLineId) ? (
                                                            <Button label="delete" name="line_del" styleType={StyleType.OutMini} onClick={handleLineDelete} />
                                                        ) : (null)
                                                    }
                                                    <div className={style.space}></div>
                                                    {
                                                        (ableNew || ableEdit) ? (<Button label="addition" name="line_add" styleType={StyleType.OutMini} onClick={handleLineAdd} />) : (<></>)
                                                    }
                                                    <div className={style.space}></div>
                                                    {
                                                        (selectedLineId) ? (
                                                            <Button label="edit" name="line_edit" styleType={StyleType.OutMini} onClick={handleSelectedLineEdit} />
                                                        ) : (null)                                                    
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            ((mode === EditModeType.Edit || mode === EditModeType.Copy) && ableDelete) ? (
                                                <div className={style.btnRow2nd}>
                                                    <HoverEffectButton labelBefore="deleteLine" labelAfter="Danger" onClick={handleOpenDialogDelete} />
                                                </div>
                                            ) : (null)
                                        }                                  
                                    </div>
                                </div>                                
                            )
                        }
                    </div>
                </div>
            </div>
            <DeleteDialog paneSize={PaneSizeType.forLineEdit} requestOpen={dialogOpen} guideMessage={t("msgConfirmDeleteLine")} onCancel={handleDialogClose} onDelete={handleDeleteAllLines} ></DeleteDialog>
            <DeleteDialog paneSize={PaneSizeType.forLineEdit} requestOpen={isOpenDeleteDialog} guideMessage={t("msgLineDeleteConfirmation")} onCancel={handleLineDeleteCanceled} onDelete={handleLineDeleteAccepted} ></DeleteDialog>
        </ModalFrame>
    )
}