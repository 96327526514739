import React, { useMemo } from "react"

import { CustomDialog, AcceptButtonType } from "../custom_dialog/CustomDialog"

//import style from "./NotificationDialog.module.css"

// 通知を利用する画面名のEnum
export const DisplayNameType = {
    LayoutList: "layLst",
    LayoutEdit: "layEdt",
    LayoutRegiStep: "layRegiStep",
    AreaList: "areaLst",
    AreaDetail: "areaDtl",
    AreaEdit: "areaEdt",
    LineList: "lnLst",
    LineEdit: "lnEdt",
    LineDetail: "lnDtl",
    None: "none",
} as const
export type DisplayName = typeof DisplayNameType[keyof typeof DisplayNameType]

// 通知オブジェクト
export type NotificationObject = {
    isActive: boolean
    from: DisplayName
    to: DisplayName
    message: string
}

interface Props {
    notification: NotificationObject
    myName: DisplayName
    onAction: () => void
}

export const NotificationDialog: React.FC<Props> = (props) => {

    const handleOk = () => {
        props.onAction()
    }
    
    const handleCancel = () => { }

    const open = useMemo(() => {
        return (props.notification.isActive && props.myName === props.notification.to)
    }, [props])

    const width = 300

    const height = 180

    return (
        <CustomDialog
            requestOpen={open}
            guideMessage={props.notification.message}
            buttonType={AcceptButtonType.NoCancel}
            width={width}
            height={height}
            onAccept={handleOk}
            onCancel={handleCancel}
        >
        </CustomDialog>
    )
}