import React from "react";
import { useTranslation } from "react-i18next"

import style from "./historyButton.module.css"

interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>
}

export const HistoryButton: React.FC<Props> = (props) => {
	const { t } = useTranslation()
    return (
        <div className={style.border} onClick={props.onClick}>
            <label className={style.label}>{t("selectSearchFilter")}</label>
            <span className={style.icon}><span className="material-symbols-outlined">expand_more</span></span>
        </div>
    )
}