import React, { useMemo } from "react"

import {Rivet, RivetStyleType} from "./Rivet"

import style from "./Stepper.module.css"

interface Props {
    activeNumber: number
    labels: string[]
}

export const Stepper: React.FC<Props> = (props) => {
    if (props.activeNumber < 1 || props.activeNumber > 5) throw new Error("activeNumber error. hope 1-5, but:" + props.activeNumber)
    if (props.labels.length !== 5) throw new Error("labels count require 5. but:" + props.labels.length)

    const items = useMemo(() => {
        const result: any = []
        props.labels.forEach((elm, idx) => {
            const val: number = idx + 1
            const it = (
                <div className={style.block} key={val}>
                    {
                        (val === 1) ? (<div className={style.sideCenterLine}><div className={style.rightSideLine}></div></div>) : (null)
                    }
                    {
                        (val > 1 && val < 5) ? (<div className={style.centerLine}></div>) : (null)
                    }
                    {
                        (val === 5) ? (<div className={style.sideCenterLine}><div className={style.leftSideLine}></div></div>) : (null)
                    }
                    <div className={style.rivet}>
                        <Rivet style={(props.activeNumber === val) ? RivetStyleType.Activated : (props.activeNumber > val) ? RivetStyleType.Checked : RivetStyleType.Deactivated} value={val}></Rivet>
                    </div>
                    <div className={val===1 ? style.longLabel : style.label}>{elm}</div>
                </div>
            )
            result.push(it)
        })        
        return result
    }, [props])

    return (
        <div className={style.main}>
            <div className={style.sideSpace}></div>
            {items}
        </div>
    )
}