import React, { useMemo, useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { ResArea, ResLayout } from "../../api/data/analysis/FullLayout"
import { TxStatus, TxStatusType, PageName } from "../../types"
import { AnalyzeView, AnalyzeViewType, CompareSide, CompareSideType } from "../../types/Analyze"
import { Array7 } from "../../component"
import { BaseFrame } from "../common/BaseFrame"
import { useAnalyticsDataContext } from "../../providers/AnalyticsData"
import { SeriesSwitcher } from "../../component/series_switcher/SeriesSwitcher"
import { CompSelector } from "./Analyze"
import { FunnelButtons } from "./FunnelButtons"
import { FunnelSelector } from "./FunnelSelector"
import { FunnelResult } from "./FunnelResult"
import { Progress } from "./Progress"
import Utils from "../../lib/Utils"
import { TrackingPageView } from "../common/TrackingPageView"

import style from "./Funnel.module.css"

const mockOn = (process.env["REACT_APP_API_WRAPPER"] === "mock") ? true : false
const isProduction = !mockOn

export type FunnelParametersType = {
    view: AnalyzeView
    single: FunnelParamSetType
    compare: FunnelParamSetType
}

export type FunnelParamSetType = {
    shopId: number | undefined
    layoutId: number | undefined
    startDate: string | undefined   // format: "yyyy-MM-dd"
    endDate: string | undefined
    areaId: number | undefined
    productName: string | undefined // value: hh * 100 + mm  ex) 10:08 => 1008
    startTime: number | undefined
    endTime: number | undefined
    weekday: Array7<boolean>        // 0:sun,1:mon,2:tue ...
    threshold: number
    side: CompareSide
    valid: boolean          // ヴァリデーションチェックの結果 true=OK
}

export type FunnelResultType = {
    status: TxStatus | undefined
    errors: any | undefined
    parameter: FunnelParametersType | undefined
    single: FunnelDataType | undefined
    compare: FunnelDataType | undefined
}

export type FunnelDatasetType = {
    view: AnalyzeView
    single: FunnelDataType | undefined
    compare: FunnelDataType | undefined
}

export type FunnelDataType = {
    visitor: number | undefined
    pass: number[] | undefined
    stay: number[] | undefined
    buyer1: number[] | undefined
    buyerOther: number[] | undefined
    notBuy1: number[] | undefined
    notBuyOther: number[] | undefined
    areas: number[] | undefined
    request: FunnelParamSetType
    area_list: ResArea[] | undefined
    layout: ResLayout | undefined
}

export const ConditionControlType = {
    Fresh: "fresh",         // [＾開始] 
    Ready: "ready",         // [開始]
    Running: "running",     // [！開始]
    Done: "done",           // [＾再検索][リセット]
    Changed: "changed",     // [再検索][リセット]
    Miss: "miss",           // [＾再検索][リセット]
    Searching: "searching"  // [！再検索][！リセット]
} as const
export type ConditionControl = typeof ConditionControlType[keyof typeof ConditionControlType]


interface Props {
    param?: string
}

export const Funnel: React.FC<Props> = (props) => {

	const { t } = useTranslation()
    const navigate = useNavigate()

    const { funnelDataset, funnelStatus, funnelAnalyze, funnelParams, funnelSetView, funnelReset } = useAnalyticsDataContext()

    const [condition, setCondition] = useState<ConditionControl>(ConditionControlType.Fresh)
    
    const searchCache: FunnelParametersType | undefined = useMemo(() => {
        if (funnelDataset && funnelDataset.view && funnelDataset.single && funnelDataset.single.request && funnelDataset.compare && funnelDataset.compare.request) {
            return {
                view: funnelDataset.view,
                single: funnelDataset.single.request,
                compare: funnelDataset.compare.request
            }
        } else {
            return undefined
        }
    }, [funnelDataset])

    const validationCheck: boolean = useMemo(() => {
        if (funnelParams.view === AnalyzeViewType.Single) {
            if (funnelParams.single.valid) return true
        } else {
            if (funnelParams.single.valid && funnelParams.compare.valid) return true
        }
        return false
    }, [funnelParams])

    useEffect(() => {
        if (props.param && props.param === "compare") {
            funnelSetView(AnalyzeViewType.Compare)
        } else {
            funnelSetView(AnalyzeViewType.Single)
        }
    }, [props.param])

    const isParamsNoChange = useCallback(() => {
        let result = true
        if (searchCache) {
            if (searchCache.view !== funnelParams.view) result = false
            if (searchCache.single.shopId !== funnelParams.single.shopId) result = false
            if (searchCache.single.layoutId !== funnelParams.single.layoutId) result = false
            if (searchCache.single.startDate !== funnelParams.single.startDate) result = false
            if (searchCache.single.endDate !== funnelParams.single.endDate) result = false
            if (searchCache.single.areaId !== funnelParams.single.areaId) result = false
            if (searchCache.single.productName !== funnelParams.single.productName) result = false
            if (searchCache.single.startTime !== funnelParams.single.startTime) result = false
            if (searchCache.single.endTime !== funnelParams.single.endTime) result = false
            if (!Utils.isSameArray(searchCache.single.weekday, funnelParams.single.weekday)) result = false
            if (searchCache.single.threshold !== funnelParams.single.threshold) result = false
            if (searchCache.view === AnalyzeViewType.Compare) {
                if (searchCache.compare.shopId !== funnelParams.compare.shopId) result = false
                if (searchCache.compare.layoutId !== funnelParams.compare.layoutId) result = false
                if (searchCache.compare.startDate !== funnelParams.compare.startDate) result = false
                if (searchCache.compare.endDate !== funnelParams.compare.endDate) result = false
                if (searchCache.compare.areaId !== funnelParams.compare.areaId) result = false
                if (searchCache.compare.productName !== funnelParams.compare.productName) result = false
                if (searchCache.compare.startTime !== funnelParams.compare.startTime) result = false
                if (searchCache.compare.endTime !== funnelParams.compare.endTime) result = false
                if (!Utils.isSameArray(searchCache.compare.weekday, funnelParams.compare.weekday)) result = false
                if (searchCache.compare.threshold !== funnelParams.compare.threshold) result = false
            }
        }
        return result
    }, [searchCache, funnelParams])

    useEffect(() => {
        if (funnelDataset?.single?.visitor) {
            if (funnelStatus?.result === TxStatusType.Loading) {
                if (condition !== ConditionControlType.Searching) setCondition(ConditionControlType.Searching)
            } else {
                if (!isParamsNoChange()) {
                    //console.log("変化あり　validationCheck:", validationCheck)
                    if (validationCheck) {
                        setCondition(ConditionControlType.Changed)
                    } else {
                        setCondition(ConditionControlType.Miss)
                    }
                } else {
                    //console.log("変化なし")
                    setCondition(ConditionControlType.Done)
                }
            }
        } else {
            if (funnelStatus?.result === TxStatusType.Rejected) {
                console.error("エラー発生")
            } else if (funnelStatus?.result === TxStatusType.Loading) {
                if (condition !== ConditionControlType.Running) setCondition(ConditionControlType.Running)
            } else {
                if (validationCheck) {
                    setCondition(ConditionControlType.Ready)
                } else {
                    setCondition(ConditionControlType.Fresh)
                }
            }
        }
    }, [funnelDataset, funnelStatus, validationCheck, isParamsNoChange])

    const handle: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const nm: string = (event.currentTarget as HTMLButtonElement).name
        if (nm === AnalyzeViewType.Single) {
            if (funnelParams.view === AnalyzeViewType.Compare) navigate("/funnel")
        } else if (nm === AnalyzeViewType.Compare) {
            if (funnelParams.view === AnalyzeViewType.Single) navigate("/funnel_cmp")
        }
    }

    const handleStart = () => {
        funnelAnalyze()
    }

    const handleReset = () => {
        funnelReset()
    }

    const resWasCompare = (funnelDataset && funnelDataset.view && funnelDataset.view === AnalyzeViewType.Compare) ? true : false

    const getResultPanel = (): React.ReactNode => {
        if (funnelStatus && funnelStatus.result === TxStatusType.Fulfilled) {
            if (resWasCompare && funnelParams.view === AnalyzeViewType.Compare) {
                // 保持している検索結果が「比較」かつ画面が「比較」の場合
                return (
                    <div className={style.result2}>
                        <FunnelResult
                            params={searchCache?.single}
                            view={searchCache?.view}
                            side={CompareSideType.Primary}
                            data={funnelDataset?.single}
                            resWasCompare={resWasCompare}
                        />
                        <div className={style.space}>&nbsp;</div>
                        <FunnelResult
                            params={searchCache?.compare}
                            view={searchCache?.view}
                            side={CompareSideType.Secondary}
                            data={funnelDataset?.compare}
                            resWasCompare={resWasCompare}
                        />
                    </div>
                )
            } else {
                // 画面が「単一」の場合かつ保持しているデータがある場合
                return (
                    <div className={style.result}>
                        <FunnelResult
                            params={searchCache?.single}
                            view={searchCache?.view}
                            side={CompareSideType.Primary}
                            data={funnelDataset?.single}
                        />
                    </div>
                )
            }
        }
        // 保持しているデータがない時
        return (<></>)
    }


    if (funnelParams.view === AnalyzeViewType.Single) {
        return (
            <BaseFrame actPage={PageName.AnalyzeFunnel}>
                <div className={style.contents}>
                    <div className={style.select}>
                        <div className={style.pane}>
                            <div className={style.head}>
                                <div className={style.title}>{t("header.purchaserAnalysis")}</div>
                                <div className={style.cmpSwitch}>
                                    <SeriesSwitcher buttonList={CompSelector} activeName={funnelParams.view} onClick={e => handle(e)}/>
                                </div>
                            </div>
                            <div className={style.main}>
                                <FunnelSelector view={funnelParams.view} side={CompareSideType.Primary} />
                            </div>
                        </div>
                    </div>
                    <FunnelButtons
                        view={funnelParams.view}
                        condition={condition}
                        onStart={handleStart}
                        onSearch={handleStart}
                        onReset={handleReset}
                    />
                    {
                        (funnelStatus && funnelStatus.result === TxStatusType.Loading) ? (
                            <div className={style.waiting}>
                                <Progress progress={funnelStatus.progress} remainMsTime={funnelStatus.remainMsTime}></Progress>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (funnelStatus && funnelStatus.result === TxStatusType.Rejected) ? (
                            <div className={style.error}>{t("msgErrorOccured")}
                                <div className={style.errorBody}>{funnelStatus.errors}</div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        getResultPanel()
                    }
                </div>
            </BaseFrame>
        )
    } else {
        return (
            <BaseFrame actPage={PageName.AnalyzeFunnel}>
                <div className={style.contents}>
                    <div className={style.pane2}>
                        <div className={style.select}>
                            <div className={style.head}>
                                <div className={style.title}>
                                    {t("header.purchaserAnalysis")}
                                    {isProduction ? (<TrackingPageView page_title="analyze-funnel" />) : (null)}
                                </div>
                                <div className={style.cmpSwitch2}>
                                    <SeriesSwitcher buttonList={CompSelector} activeName={funnelParams.view} onClick={e => handle(e)}/>
                                </div>
                            </div>
                            <div className={style.main2}>
                                <FunnelSelector view={funnelParams.view} side={CompareSideType.Primary} />
                                <FunnelSelector view={funnelParams.view} side={CompareSideType.Secondary} />
                            </div>
                        </div>
                    </div>
                    <FunnelButtons
                        view={funnelParams.view}
                        condition={condition}
                        onStart={handleStart}
                        onSearch={handleStart}
                        onReset={handleReset}
                    />
                    {
                        (funnelStatus && funnelStatus.result === TxStatusType.Loading) ? (
                            <div className={style.waiting}>
                                <Progress progress={funnelStatus.progress} remainMsTime={funnelStatus.remainMsTime}></Progress>
                            </div>
                        ) : (<></>)
                    }
                    {
                        (funnelStatus && funnelStatus.result === TxStatusType.Rejected) ? (
                            <div>{t("errorOccured")}</div>
                        ) : (<></>)
                    }
                    {
                        getResultPanel()
                    }                    
                </div>
            </BaseFrame>
        )

    }
}