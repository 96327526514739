import React, { ReactNode, useMemo } from "react"

import { AcceptButtonType, CustomDialog } from "../custom_dialog/CustomDialog"

export const PaneSizeType = {
    forShopEdit: "567x208",
    forLineEdit: "600x180",
    forAreaDetail: "600x180",
    forAreaEdit: "450x170",
    forAccountEdit: "567x208",
    forDatasource: "520x250",
    forLayout: "520x280",
    forGroup: "580x210"
} as const
export type PaneSize = typeof PaneSizeType[keyof typeof PaneSizeType]

type Props = {
    requestOpen: boolean
    guideMessage: string
    onCancel: () => void
    onDelete: () => void
    children?: ReactNode
    paneSize: PaneSize
}

export const DeleteDialog: React.FC<Props> = (props) => {
   
    const width = useMemo(() => {
        switch (props.paneSize) {
            case PaneSizeType.forLineEdit:
                return 600
            case PaneSizeType.forShopEdit:
                return 567
            case PaneSizeType.forAreaDetail:
                return 600
            case PaneSizeType.forAreaEdit:
                return 450
            case PaneSizeType.forAccountEdit:
                return 567
            case PaneSizeType.forDatasource:
                return 520
            case PaneSizeType.forLayout:
                return 520
            case PaneSizeType.forGroup:
                return 580
            default:
                throw new Error("Unknown PaneSize:" + props.paneSize)
        }
    }, [props])

    const height = useMemo(() => {
        switch (props.paneSize) {
            case PaneSizeType.forLineEdit:
                return 180
            case PaneSizeType.forShopEdit:
                return 208
            case PaneSizeType.forAreaDetail:
                return 180
            case PaneSizeType.forAreaEdit:
                return 170
            case PaneSizeType.forAccountEdit:
                return 208
            case PaneSizeType.forDatasource:
                return 250
            case PaneSizeType.forLayout:
                return 280
            case PaneSizeType.forGroup:
                return 210
            default:
                throw new Error("Unknown PaneSize:" + props.paneSize)
        }
    }, [props])

    const handleCancel = () => {
        props.onCancel()
    }
    
    const handleDelete = () => {
        props.onDelete()
    }

    return (
        <CustomDialog
            requestOpen={props.requestOpen}
            width={width}
            height={height}
            guideMessage={props.guideMessage}
            onCancel={handleCancel}
            onAccept={handleDelete}
            buttonType={AcceptButtonType.Delete}
        >
            {props.children}
        </CustomDialog>
    )
}
