import React, { useState, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

import { FupDatepicker, DpType } from "../../component/fup_datepicker/FupDatepicker"
import { AcceptButtonType, CustomDialog } from "../custom_dialog/CustomDialog"

import style from "./CopyDialog.module.css"

interface Props {
    requestOpen: boolean    // Dialog open = true
    id: number              // 更新時に必要なID
    start: number           // 開始日(unix timestamp)
    excludeDates: Date[]    // 除外すべき日のリスト
    minStartDate: Date   // レイアウトの開始日
    onChange: (id: number, newStart: number) => void    // OKボタン押下イベント
    onCancel: () => void    // キャンセルボタン押下イベント
    title: string           // タイトル
}

/**
 * レイアウト複製時に表示されるダイアローグコンポーネント
 * 　複製に必要な開始日をカレンダーで選択する。
 * 
 * @param props 
 * @returns 
 */
export const CopyDialog: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation()
    const [editStart, setEditStart] = useState<number>(props.start)
    const refDpk = useRef<HTMLDivElement>(null)

    const handleSave = () => {
        props.onChange(props.id, editStart)
    }

    const handleCancel = () => {
        props.onCancel()
    }

    const handleChangeStartDate = (event: Date | [Date, Date] | null) => {
        const n = (event as Date).getTime()
        setEditStart(n)
    }
    
    const startDate = useMemo(() => { return new Date(editStart) }, [editStart])
    
    const datepickFormat = useMemo(() => { return t("dateFormat.ymd_E") }, [t])
    
    const datepickFormatCalendar = useMemo(() => { return t("dateFormat.y_L") }, [t])

    const width = 520

    const height = 220

    return (
        <CustomDialog
            requestOpen={props.requestOpen}
            width={width}
            height={height}
            guideMessage={props.title}
            onCancel={handleCancel}
            onAccept={handleSave}
            buttonType={AcceptButtonType.Copy}
        >
                <div className={style.dialogStDtPan}>
                    <span className={style.dialogStDtLbl}>{t("startDate")}</span>
                    <span className={style.dialogStDtPick}>
                        <FupDatepicker
                            type={DpType.Micro}
                            onChange={handleChangeStartDate}
                            divRef={refDpk}
                            selected={startDate}
                            locale={i18n.language}
                            dateFormat={datepickFormat}
                            dateFormatCalendar={datepickFormatCalendar}
                            excludeDates={props.excludeDates}
                            minDate={props.minStartDate}
                        />
                    </span>
                </div>
        </CustomDialog>
    )
}
