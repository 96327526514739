import React from "react"
import { TailSpin } from "react-loader-spinner"

import style from "./OverlaySpinner.module.css"

export const SpinerStyleType = {
    ForLogin: "login",
    ForManagement: "management"
} as const
export type SpinerStyle = typeof SpinerStyleType[keyof typeof SpinerStyleType]

interface Props {
    waiting: boolean
    spinerStyle: SpinerStyle
}

export const OverlaySpinner: React.FC<Props> = (props) => {

    if (props.waiting) {
        if (props.spinerStyle === SpinerStyleType.ForLogin) {
            // ログイン画面用
            return (
                <div className={style.spinner}>
                    <div className={style.overlay}></div>
                    <div className={style.waiting}>
                        <TailSpin color="#1E5EFF" width={16} height={16} />
                    </div>
                </div>
            )
        } else {
            // 管理画面用
            return (
                <div className={style.spinner}>
                    <div className={style.overlay}></div>
                    <div className={style.waiting4Man}>
                        <TailSpin color="#1E5EFF" width={32} height={32} />
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className={style.spinner}>
            </div>
        )
    }
}