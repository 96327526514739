import { Coord } from "../api/data/core/Coord"
import { Role, AreaType } from "../api/data/core/Enums"

// 管理画面の表示モードEnum
export const EditModeType = {
    View: "view",
    New: "new",
    Edit: "edit",
    Copy: "copy"
} as const
export type EditMode = typeof EditModeType[keyof typeof EditModeType]

// 管理画面のエリアカテゴリ表示モードEnum
export const ViewModeType = {
    Page: "pg",
    Dialog: "dlg"
} as const
export type ViewMode = typeof ViewModeType[keyof typeof ViewModeType]

// URLパラメーターのキーEnum
export const UrlParameterType = {
    PrvMode: "prvm",
    Mode: "mode",
    CompanyId: "cmpny",
    ShopId: "shp",
    LayoutSetId: "ls",
    LayoutId: "lyt",
    AreaSetId: "as",
    AreaId: "ai",
    UserId: "ui",
    LineSetId: "ns",
    LineIdx: "ix",
    GroupId: "gr"
} as const
export type UrlParameter = typeof UrlParameterType[keyof typeof UrlParameterType]

// ユーザー情報
// ResUser + bookmark
export type UserModel = {
    user_id: number
    login_id: string
    name: string
    user_type_id: number
    user_type: Role
    display_order: number
    dashboard_order: number[]
    expired_at?: number
    created_at: number
    modified_at: number
    bookmarks: number[]
    password?: string
    company_id?: number
}

// 企業情報
export type CompanyModel = {
    company_id: number
	name: string
	start: number
	end: number
    settings?: object
    designator?: string
	thresholdtime: number
    shopThresholdtime?: number
	created_at: number
    modified_at: number
}

// 店舗情報
export type ShopModel = {
    shop_id: number
    name: string
    start: number
    timezone: number
    designator?: string
    displayOrder?: number
    datasource_list: DataSourceModel[]
	created_at: number
    modified_at: number
    company_id?: number
}

// データソース（レイアウトセット）
export type DataSourceModel = {
    shop_id: number
    layout_set_id: number
    name: string // レイアウトセット名称
    layout_list: LayoutModel[]
    status: DtSrcStatus
    isFull: boolean         // PartialLayout（mappingなし）ならfalse, fullLayout(mappingあり)ならtrue
}

// レイアウト
export type LayoutModel = {
    layout_id: number
    name: string
    start: number
    end: number
    mapping: MapModel | undefined
    created_at: number
    modified_at: number
    dataSouceId?: number
}

// 平面図情報はResPartialLayoutにはない追加情報
export type MapModel = {
    //startYmd: string
    image: string
	pixel_width: number;
	pixel_height: number;
	origin_x: number;
	origin_y: number;
	mm_per_pixel: number;
	area_unitcell_pixel: number;
	area_packs: AreaPackModel[];
    line_packs: LinePackModel[];
    image_data?: string
    image_modified: boolean // 画像データが変更されたか(＝backendでimageの保存が必要かどうか)
}

// エリアパック
export type AreaPackModel = {
    area_set_id: number
    startYmd: string
    area_list: AreaModel[]
    created_at: number
    modified_at: number
    layoutId?: number
}

// エリア情報
export type AreaModel = {
    area_id: number
    area_number: number
    name: string
    cell_ids: Coord[]
    area_type: AreaType
    group_id: number | null
    packId?: number
}

// ライン属性
export const LineDefType = {
    Enter: "enter",
    Exit: "exit",
    Pass: "pass"
} as const
export type LineDef = typeof LineDefType[keyof typeof LineDefType]

// ラインパック
export type LinePackModel = {
    line_set_id: number
    startYmd: string
    lines: LineModel[]
    layoutId?: number
}

// ライン情報
export type LineModel = {
    id: number
    type: LineDef
    right: { x: number, y: number }
    left: { x: number, y: number }
    isAct?: boolean
    packId?: number
}

// グループ情報
export type GroupModel = {
    group_id: number
    name: string
    display_order: number
    delete_at?: number
    company_id?: number
}

// データソースの状態
export const DataSourceStatusType = {
    Active: "act",          // 本番適用中
    Ready: "rdy",           // 本番切替可能
    Caliculation: "cal",    // 計算中
    Copyed: "cpy",          // 複製・未保存
    NoEntry: "noe",         // 新規・未保存
    Draft: "drf"            // 下書き
} as const
export type DtSrcStatus = typeof DataSourceStatusType[keyof typeof DataSourceStatusType]

// データソースアコーディオン用オブジェクト
export type CollapseItem = {
    id: number
    name: string
    status: DtSrcStatus
    isExpanded: boolean
}